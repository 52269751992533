import React from 'react'
import type { ApolloError } from '@apollo/client'
import { Result } from 'antd'

import { useI18nContext } from '../../contexts/i18nContext/I18nContext'

export function prepareApolloError({ graphQLErrors, networkError }: ApolloError) {
  let errors = graphQLErrors ?? []
  if (networkError && 'result' in networkError && Array.isArray(networkError.result.errors)) {
    errors = errors.concat(networkError.result.errors)
  }
  errors = errors.map(e => ({
    ...e,
    name: e.name ?? 'unknown',
    message: e.message ?? 'Unexpected error',
  }))

  const isError = (name: string, ...rest: string[]) => {
    const names = [name, ...rest]
    return errors.some(e => names.includes(e.name))
  }

  const hasStatusCode = (status: number) => {
    return !!networkError && 'statusCode' in networkError && networkError.statusCode === status
  }

  const isHasuraClaimsError = errors.some(e => ['jwt-invalid-claims', 'invalid-headers'].includes(e.extensions?.code))

  const isAuthError = isHasuraClaimsError || hasStatusCode(401) || hasStatusCode(403)

  const errorMessage = errors.length > 0 ? errors.map(e => e.message).join('\n') : 'Unexpected error'
  return { errors, isAuthError, isError, errorMessage, hasStatusCode }
}

type Props = {
  error: ApolloError
}

export const ApolloErrorResult = ({ error }: Props) => {
  const { i18n } = useI18nContext()

  const { isAuthError, errorMessage } = prepareApolloError(error)

  if (isAuthError) {
    return (
      <Result
        status="403"
        title="Session expired"
        extra={<a href="/login">{i18n.text('user.login')}</a>}
      />
    )
  }

  return (
    <Result
      status="500"
      title="Operation Failed"
      subTitle={errorMessage}
    />
  )
}
