import React, { FC, useMemo } from 'react'
import { ArrowDropDownIcon, ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers'
import { Box, Button, Menu, MenuItem, styled, useTheme } from '@mui/material'

import { Option } from './types'

interface ICustomizedMenuProps {
  options: Option[]
  selectedOption: Option
  isOnTheRight?: boolean
  setSelectedOption: (option: Option, action: 'add' | 'sub' | null) => void
}

const MenuButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

const IconWrapper = styled(Box)({
  display: 'flex',
  cursor: 'pointer',
})

export const CustomizedMenu: FC<ICustomizedMenuProps> = ({
  options,
  selectedOption,
  isOnTheRight = false,
  setSelectedOption,
}) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const selectedIndex = useMemo(() => {
    return options.findIndex(option => option.key === selectedOption.key)
  }, [selectedOption, options])
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (option: Option, action: 'add' | 'sub' | null) => {
    setAnchorEl(null)
    setSelectedOption(option, action)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const showPreviousOption = () => {
    const newIndex = selectedIndex - 1 >= 0 ? selectedIndex - 1 : options.length - 1
    const action = selectedIndex - 1 >= 0 ? null : 'sub'
    handleMenuItemClick(options[newIndex], action)
  }

  const showNextOption = () => {
    const newIndex = selectedIndex + 1 <= options.length - 1 ? selectedIndex + 1 : 0
    const action = selectedIndex + 1 <= options.length - 1 ? null : 'add'
    handleMenuItemClick(options[newIndex], action)
  }

  return (
    <Box>
      <MenuButton sx={{ justifyContent: isOnTheRight ? 'flex-end' : 'flex-start' }}>
        <IconWrapper onClick={showPreviousOption}>
          <ArrowLeftIcon fontSize="small" />
        </IconWrapper>
        <Button
          endIcon={<ArrowDropDownIcon />}
          onClick={handleClickListItem}
          sx={{
            textTransform: 'capitalize',
            color: theme.palette.SFIGreyLight[600],
            fontWeight: 500,
            fontSize: '14px',
            width: theme.spacing(8),
            maxWidth: theme.spacing(8),
            [`.MuiButton-endIcon`]: {
              margin: 0,
            },
          }}
        >
          {selectedOption.option}
        </Button>
        <IconWrapper onClick={showNextOption}>
          <ArrowRightIcon fontSize="small" />
        </IconWrapper>
      </MenuButton>
      <Menu
        id="months-or-years-list"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          maxHeight: theme.spacing(50),
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.key}
            selected={option.key === selectedOption.key}
            onClick={() => handleMenuItemClick(option, null)}
          >
            {option.option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
