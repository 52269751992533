import { Button, useTheme } from '@mui/material'
import { FC } from 'react'

type CustomerSwitcherProps = {
  mini: boolean
  customerName: string
  handleClick: (e: React.MouseEvent<HTMLElement>) => void
}
export const CustomerSwitcherButton: FC<CustomerSwitcherProps> = ({ mini, customerName, handleClick }) => {
  const theme = useTheme()
  if (mini)
    // Mini button
    return (
      <Button
        variant="text"
        onClick={handleClick}
        sx={{
          paddingTop: 1,
          paddingLeft: 1.5,
          paddingRight: 1.5,
          fontWeight: 600,
          marginRight: 1,
          textTransform: 'none',
          border: `solid 1px ${theme.palette.SFIGreyLight[100]}`,
          color: 'inherit',
          minWidth: '83px',
          maxWidth: '90px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          display: 'block',
          textOverflow: 'ellipsis',
          '*': {
            marginLeft: 0.25,
            marginRight: 0.25,
          },
        }}
      >
        {customerName
          .split(' ')
          .map(s => s.substring(0, 1).toUpperCase())
          .join(' ')}
      </Button>
    )
  // Large button
  return (
    <Button
      variant="text"
      onClick={handleClick}
      sx={{
        paddingTop: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        fontWeight: 400,
        marginRight: 1,
        textTransform: 'none',
        border: `solid 1px ${theme.palette.SFIGreyLight[100]}`,
        color: 'inherit',
        minWidth: '150px',
        maxWidth: '170px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'block',
        textOverflow: 'ellipsis',
        '*': {
          marginLeft: 0.25,
          marginRight: 0.25,
        },
      }}
    >
      {customerName}
    </Button>
  )
}
