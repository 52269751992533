import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Collapse, Typography, useTheme } from '@mui/material'
import { FC, MouseEvent, useEffect, useState } from 'react'

import { AssetCardProps } from './utils/types'
import { AssetStatusChip, CardLastMeasurement, ExpandMore, StyledAssetCard } from './styled/StyledAssetCardComponents'
import { BellOffIcon } from '../icons/BellOffIcon'
import { BellOnIcon } from '../icons/BellOnIcon'
import { StyledTooltip } from '../MUIComponents/Tooltip'
import { getAssetStatusColors } from './utils/helpers'
import { useI18nContext } from '../../contexts/i18nContext/I18nContext'

/**
 * AssetCard component displays information about an asset in a form of a card with
 * multiple sections and options.
 *
 * @param assetStatusLabel - The status of the card, can be a custom component or a component with a text and icon.
 * @param assetStatusColor - The status color of the asset card also determines the color of the left border and the label.
 * @param isCollapsible - (optional) Determines if the card is collapsible. Default: false.
 * @param isExpanded - (optional) Determines if the card is initially expanded. Default: false.
 * @param onExpandedChange - (optional) Event handler for when the card is expanded or collapsed.
 * @param onClick - (optional) Event handler for when the card is clicked.
 * @param hasAlerts - (optional) Determines if the asset has alerts if false the bell icon will not be displayed. Default: false.
 * @param alerts - (optional) The alerts associated with the asset can be enabled or disabled. Default: false.
 * @param lastMeasurement - (optional) The last measurement of the asset.
 * @param primaryName - (optional) The primary name of the asset. (eg. Asset Name)
 * @param secondaryName - (optional) The secondary name of the asset. (eg. Sensor Location Name)
 * @param assetNameOverflow - (optional) The overflow of the name text.
 * @param isCardaExpanded - (optional) A prop to control expanding all cards. Default: false.
 * @param children - (optional) The additional content to be displayed inside the card.
 */
export const AssetCard: FC<AssetCardProps> = ({
  isCollapsible = false,
  onExpandedChange,
  onClick,
  hasAlerts = false,
  alerts = false,
  lastMeasurement,
  primaryName,
  secondaryName,
  assetNameOverflow,
  assetStatusLabel,
  assetStatusColor,
  isCardaExpanded = false,
  children,
}) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState(false)
  const { i18n } = useI18nContext()

  const { backgroundColor, statusColor, textColor, icon, text, stroke } = getAssetStatusColors(
    assetStatusColor,
    assetStatusLabel,
    theme
  )

  useEffect(() => {
    setExpanded(isCardaExpanded)
  }, [isCardaExpanded])

  const handleExpandClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setExpanded(!expanded)
  }

  // triggers the `onExpandedChange` callback function when the `expanded` state changes.
  useEffect(() => {
    if (onExpandedChange) {
      onExpandedChange(expanded)
    }
  }, [expanded, onExpandedChange])

  return (
    <StyledAssetCard
      data-testid="asset-card"
      onClick={onClick}
      sx={{
        borderLeft: `5px solid ${statusColor}`,
      }}
    >
      <Box
        onClick={() => setExpanded(!expanded)}
        sx={{
          display: 'grid',
          alignItems: 'center',
          width: '100%',
          gap: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {lastMeasurement && (
            <CardLastMeasurement data-testid="asset-card-last-update">
              <Typography
                fontSize={theme.typography.pxToRem(12)}
                fontWeight={500}
                color={theme.palette.SFIGreyLight[500]}
              >
                {lastMeasurement}
              </Typography>
            </CardLastMeasurement>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto',
            }}
          >
            {hasAlerts && (
              <Box data-testid="asset-notification-bell">
                {alerts ? (
                  <StyledTooltip
                    title={i18n.text('shared.alert.on')}
                    placement="top"
                    arrow
                  >
                    <BellOnIcon
                      sx={{ display: 'block', width: '1rem', height: '1rem', color: theme.palette.SFIGreyLight[500] }}
                    />
                  </StyledTooltip>
                ) : (
                  <StyledTooltip
                    title={i18n.text('shared.alert.off')}
                    placement="top"
                    arrow
                  >
                    <BellOffIcon
                      sx={{ display: 'block', width: '1rem', height: '1rem', color: theme.palette.SFIGreyLight[500] }}
                    />
                  </StyledTooltip>
                )}
              </Box>
            )}
            {isCollapsible && lastMeasurement && (
              <ExpandMore
                disableRipple
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                sx={{
                  padding: theme.spacing(0),
                }}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: assetNameOverflow ? 'flex-start' : 'center',
            height: assetNameOverflow ? theme.spacing(8.5) : 'auto',
            justifyContent: 'space-between',
          }}
        >
          <Box data-testid="asset-machine-name">
            <Typography
              sx={{
                fontStyle: 'normal',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                ...(assetNameOverflow && {
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 3, // Limit to three lines of text
                  WebkitBoxOrient: 'vertical',
                  textOverflow: 'ellipsis',
                  height: '4.5em', // Adjust the height based on the line-height and font-size
                }),
              }}
            >
              <Typography
                component="span"
                sx={{ fontWeight: '600' }}
              >
                {primaryName}
              </Typography>{' '}
              {secondaryName && <>{`| ${secondaryName}`}</>}
            </Typography>
          </Box>
          {isCollapsible && !hasAlerts && (
            <ExpandMore
              disableRipple
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              sx={{
                padding: theme.spacing(0),
              }}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          )}
        </Box>
        {assetStatusLabel && !assetStatusLabel.customStatusComponent ? (
          <AssetStatusChip
            stroke={stroke}
            label={text}
            icon={icon}
            backgroundColor={backgroundColor}
            textColor={textColor}
            statusColor={statusColor}
          />
        ) : (
          assetStatusLabel.customStatusComponent
        )}

        {isCollapsible && children && (
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            easing={'ease-in'}
          >
            {children}
          </Collapse>
        )}
      </Box>
    </StyledAssetCard>
  )
}
