import { createSvgIcon } from '@mui/material'

export const FeaturedInfoCircleIcon = createSvgIcon(
  <svg
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <path
        d="M6 19C6 11.8203 11.8203 6 19 6C26.1797 6 32 11.8203 32 19C32 26.1797 26.1797 32 19 32C11.8203 32 6 26.1797 6 19Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </g>
    <g opacity="0.1">
      <path
        d="M1 19C1 9.05888 9.05888 1 19 1C28.9411 1 37 9.05888 37 19C37 28.9411 28.9411 37 19 37C9.05888 37 1 28.9411 1 19Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </g>
    <g clipPath="url(#clip0_5427_78044)">
      <path
        d="M19 22.3333V19M19 15.6666H19.0083M27.3333 19C27.3333 23.6023 23.6024 27.3333 19 27.3333C14.3976 27.3333 10.6667 23.6023 10.6667 19C10.6667 14.3976 14.3976 10.6666 19 10.6666C23.6024 10.6666 27.3333 14.3976 27.3333 19Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>,
  'featured-info-circle-icon'
)
