import React, { FC, useState } from 'react'
import { Box, styled, useTheme } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import { FromToValues } from './types'
import { useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

interface IDatePickersRowProps {
  values: FromToValues
  isCompare?: boolean
  setValues: (dates: FromToValues) => void
}

const DatePickersWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1.75),
}))

export const DatePickersRow: FC<IDatePickersRowProps> = ({ values, isCompare, setValues }) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const datePickerFormat = 'dd/MM/yyyy'
  const { compareStatusesRef } = useDateTimeRangePickerContext()
  const [fromToValues, setFromToValues] = useState<FromToValues>({
    from: values.from,
    to: values.to,
  })

  const textFieldStyle = {
    sx: {
      height: theme.spacing(6),
      borderRadius: `${theme.shape.borderRadius}px`,
      color: theme.palette.SFIGreyLight[600],
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.SFIGreyLight[600]}`,
      },
      '& input': {
        height: theme.spacing(6),
        width: '100%',
        padding: theme.spacing(0, 0, 0, 2),
        color: theme.palette.SFIGreyLight[600],
      },
      '& label': {
        color: theme.palette.SFIGreyLight[600],
      },
    },
  }

  return (
    <DatePickersWrapper>
      <DatePicker
        label={i18n.text('datetimepicker.start-date')}
        value={fromToValues.from}
        disableOpenPicker
        onChange={newValue => setFromToValues({ ...fromToValues, from: newValue })}
        format={datePickerFormat}
        slotProps={{
          textField: {
            ...textFieldStyle,
            error: (fromToValues.to && fromToValues.from && fromToValues.to < fromToValues.from) || undefined,
            onBlur: () => setValues({ ...values, from: fromToValues.from }),
            onFocus: () => {
              compareStatusesRef.current = {
                from: isCompare ? false : true,
                to: isCompare ? false : true,
                compareFrom: true,
                compareTo: true,
              }
            },
          },
        }}
      />
      {' - '}
      <DatePicker
        label={i18n.text('datetimepicker.end-date')}
        value={fromToValues.to}
        disableOpenPicker
        onChange={newValue => setFromToValues({ ...values, to: newValue })}
        format={datePickerFormat}
        slotProps={{
          textField: {
            ...textFieldStyle,
            error: (fromToValues.to && fromToValues.from && fromToValues.to < fromToValues.from) || undefined,
            onBlur: () => setValues({ ...values, to: fromToValues.to }),
            onFocus: () => {
              compareStatusesRef.current = {
                from: false,
                to: isCompare ? false : true,
                compareFrom: isCompare ? false : true,
                compareTo: true,
              }
            },
          },
        }}
      />
    </DatePickersWrapper>
  )
}
