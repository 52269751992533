import React from 'react'
import { Box } from '@mui/material'
import { Redirect } from 'react-router-dom'
import { Route, Switch } from 'react-router'

import Configuration from '../../components/Settings/Configuration/Configuration'
import Error from '../../../Shared/components/MUIComponents/Error'
import Loader from '../../../Shared/components/MUIComponents/Loader'
import StopReasons from '../../components/Settings/StopReasons/StopReasons'
import MuiSecondaryNav, {
  LinkInfo,
  SecondaryNavLayout,
} from '../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'
import { Asset, useGetOeeAssetsByCommodityTypeQuery } from '../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const OEESettings = () => {
  const { i18n } = useI18nContext()

  const {
    data,
    loading,
    error,
    refetch: refetchAssets,
  } = useGetOeeAssetsByCommodityTypeQuery({
    variables: {
      commodityType: 'production-cycles',
    },
  })

  const secondaryLinks: LinkInfo[] = [
    {
      name: 'Stop reasons',
      i18n: i18n.text('settings.oee-settings.stop-reasons'),
      path: `/settings/oee/stop-reasons`,
    },
    {
      name: 'Configuration',
      i18n: i18n.text('settings.oee-settings.configuration'),
      path: `/settings/oee/configuration`,
    },
  ]

  const assets = (data?.myOrg?.assets || []) as Asset[]

  if (loading) return <Loader />

  if (error) return <Error />

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: '0.6rem', paddingTop: '1.25rem' }}>
      <MuiSecondaryNav
        layout={SecondaryNavLayout.Vertical}
        links={secondaryLinks}
      />
      <Switch>
        <Route
          path="/settings/oee/stop-reasons"
          render={() => (
            <StopReasons
              assets={assets}
              refetchAssets={refetchAssets}
            />
          )}
          exact
        />
        <Route
          path="/settings/oee/configuration"
          render={() => <Configuration />}
          exact
        />
        <Redirect to="/settings/oee/stop-reasons" />
      </Switch>
    </Box>
  )
}

export default OEESettings
