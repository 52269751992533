import React, { FC } from 'react'
import { Box, useTheme } from '@mui/material'
import { DateTime } from 'luxon'

import { useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'

export const DateTimeRangePickerShortcuts: FC = () => {
  const {
    activeShortcutIndex,
    setActiveShortcutIndex,
    setFromDate,
    setComparedFromDate,
    setComparedToDate,
    setToDate,
    setCurrentDate,
    shortcutsItems,
    compareStatusesRef,
  } = useDateTimeRangePickerContext()
  const theme = useTheme()

  const handleShortCutClick = (from: DateTime, to: DateTime) => {
    if (compareStatusesRef.current.from || compareStatusesRef.current.to) {
      setFromDate(from)
      setToDate(to)
    } else {
      setComparedFromDate(from)
      setComparedToDate(to)
    }
    setCurrentDate(to)
  }

  return (
    <Box
      sx={{
        gridColumn: 1,
        gridRow: 1,
        borderBottom: `1px solid ${theme.palette.SFIGreyLight[200]}`,
        padding: theme.spacing(1.5, 2, 7.5),
      }}
    >
      {shortcutsItems.map((item, index) => (
        <Box
          key={item.label}
          sx={{
            cursor: 'pointer',
            background: activeShortcutIndex === index ? theme.palette.SFIBrand?.[200] : 'transparent',
            color: activeShortcutIndex === index ? theme.palette.SFIGreyLight[800] : theme.palette.SFIGreyLight[700],
            padding: theme.spacing(1.25, 0, 1.25, 2),
            borderRadius: '6px',
            fontSize: '14px',
            lineHeight: '1.25rem',
            fontWeight: activeShortcutIndex === index ? 500 : 400,
            '&:hover': {
              background: theme.palette.SFIBrand?.[200],
              color: theme.palette.SFIGreyLight[800],
            },
          }}
          onClick={() => {
            setActiveShortcutIndex(index)
            handleShortCutClick(item.from, item.to)
          }}
        >
          {item.label}
        </Box>
      ))}
    </Box>
  )
}
