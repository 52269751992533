import getAssetValidationType from './getAssetValidationType'
import { ElectricityAssetStandbyValidationTypesQuery, StandbyAlertRuleFragment } from '../../Shared/graphql/codegen'
import { FlatAsset, FullGroupAsset, StandbyValidationEntries, StandbyValidationType } from '../types'
import { getAssetColor } from '../../Shared/utils'

/**
 * Add to assets colors, standby rules and validation types
 * @param rawFlatAssets
 */
export const processFlatAssets = (
  rawFlatAssets: FullGroupAsset[],
  standbyAlertRules: StandbyAlertRuleFragment[],
  fetchedStandbyValidationTypes?: ElectricityAssetStandbyValidationTypesQuery
) => {
  // Add colors
  const flatAssetsWithColors = rawFlatAssets.map((a, i) => ({
    ...a,
    color: getAssetColor(i),
  }))
  // Add standby rules
  const standbyAlertRulesMap = {} as Record<string, StandbyAlertRuleFragment>
  standbyAlertRules.forEach(r => {
    if (r.asset) standbyAlertRulesMap[r.asset.id] = r
  })

  const flatAssetsWithStandbyRules = flatAssetsWithColors.map(a => {
    const standbyRule = standbyAlertRulesMap[a.id]
    return {
      ...a,
      standbyEnabled: !!standbyRule,
    }
  })
  // Add validation types
  const flatAssetsWithStandbyValidationTypes = flatAssetsWithStandbyRules.map((a: FlatAsset) => {
    const assetValidationTypes = fetchedStandbyValidationTypes?.sfMlHasura?.validationTypes.filter(
      v => v.assetId === a.id
    )

    const validationType: StandbyValidationType =
      assetValidationTypes && assetValidationTypes.length > 0
        ? getAssetValidationType(assetValidationTypes as StandbyValidationEntries)
        : null

    if (!validationType) {
      return {
        ...a,
        standbyValidationType: a.standbyValidationType || null,
      }
    }
    return {
      ...a,
      standbyValidationType: validationType,
    }
  })
  return flatAssetsWithStandbyValidationTypes
}
