import React, { FC, useEffect, useState } from 'react'
import { Box, styled, useTheme } from '@mui/material'
import { DateTime, Interval } from 'luxon'

import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import { AirTemplateGraph } from '../../components/Graphs/TemplateGraph'
import { ApexWrapper } from '../../components/ApexWrapper'
import { GraphId, SwitchUnit } from '../../types/compressed-air_types'
import { NoDataFoundSlot } from '../../../Shared/components/MUIComponents/update/NoDataFoundSlot'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { getEfficiencyOptions } from '../../utils/getEfficiencyOptions'
import { useApexBeforeZoom } from '../../hooks/useApexBeforeZoom'
import { useCompressedAirContext } from '../../context/CompressedAirContext'
import { useEfficiencyContext } from '../../context/EfficiencyContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOnZoomChange } from '../../hooks/useOnZoomChange'

const GraphsGridWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  gridTemplateRows: 'min-content',
  gap: theme.spacing(1.5),
  height: 'fit-content',
  maxHeight: 'calc(100vh - 175px)',
}))

const FullLengthSectionBoxWrapper = styled(SectionBoxWrapper)({
  gridColumn: '1 / span 2',
  height: 'fit-content',
})

export const EfficiencyPage: FC = () => {
  const COMPRESSED_AIR_SETTINGS_LINK = '/settings/compressed-air'
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { sendEvent } = useAnalytics()
  const { fromToValues, queryParams, handleDatesChange } = useCompressedAirContext()
  const { efficiencySeries, efficiencyPowerSeries, mainPipesFlowSeries, isEfficiencyLoading } = useEfficiencyContext()
  const { from, to } = queryParams
  const interval: Interval =
    fromToValues.startDate && fromToValues.endDate
      ? Interval.fromDateTimes(fromToValues.startDate, fromToValues.endDate)
      : Interval.fromDateTimes(DateTime.now().startOf('day'), DateTime.now().endOf('day'))

  const hasEfficiencyData = efficiencySeries && efficiencySeries.length !== 0
  const hasPowerData = efficiencyPowerSeries && efficiencyPowerSeries.length !== 0
  const hasMainPipesData = mainPipesFlowSeries && mainPipesFlowSeries.length !== 0

  const beforeZoom = useApexBeforeZoom(interval)

  const onZoomChange = useOnZoomChange({
    fromToValues,
    handleDatesChange,
    sendEvent,
  })

  const getEfficiencyOptionsArgs = () => ({
    from,
    to,
    i18n,
    theme,
    beforeZoom,
    onZoomChange,
    sendEvent,
  })

  const [efficiencyFlowOptions, setEfficiencyFlowOptions] = useState(
    getEfficiencyOptions({ graphId: GraphId.EFFICIENCY_FLOW, unitName: SwitchUnit.m3m, ...getEfficiencyOptionsArgs() })
  )
  const [efficiencyPowerOptions, setEfficiencyPowerOptions] = useState(
    getEfficiencyOptions({ graphId: GraphId.EFFICIENCY_POWER, unitName: SwitchUnit.kw, ...getEfficiencyOptionsArgs() })
  )
  const [efficiencyMainOptions, setEfficiencyMainOptions] = useState(
    getEfficiencyOptions({
      graphId: GraphId.EFFICIENCY_MAIN,
      unitName: SwitchUnit.kwhm3,
      hasToolbar: true,
      isYaxisReversed: true,
      ...getEfficiencyOptionsArgs(),
    })
  )

  useEffect(() => {
    if (isEfficiencyLoading) {
      setEfficiencyFlowOptions(
        getEfficiencyOptions({
          graphId: GraphId.EFFICIENCY_FLOW,
          unitName: SwitchUnit.m3m,
          ...getEfficiencyOptionsArgs(),
        })
      )
      setEfficiencyPowerOptions(
        getEfficiencyOptions({
          graphId: GraphId.EFFICIENCY_POWER,
          unitName: SwitchUnit.kw,
          ...getEfficiencyOptionsArgs(),
        })
      )
      setEfficiencyMainOptions(
        getEfficiencyOptions({
          graphId: GraphId.EFFICIENCY_MAIN,
          unitName: SwitchUnit.kwhm3,
          hasToolbar: true,
          isYaxisReversed: true,
          ...getEfficiencyOptionsArgs(),
        })
      )
    }
  }, [isEfficiencyLoading])

  const getNoDataSlot = (hasNoData: boolean, subtitle: string, hasButton?: boolean) => {
    return hasNoData ? (
      <NoDataFoundSlot
        title={i18n.text('generic.no-data-found')}
        subtitle={subtitle}
        buttonText={hasButton ? i18n.text('settings.compressed-air.configure') : ''}
        redirectLink={hasButton ? COMPRESSED_AIR_SETTINGS_LINK : ''}
      />
    ) : undefined
  }

  return (
    <GraphsGridWrapper>
      <FullLengthSectionBoxWrapper>
        <ApexWrapper>
          <AirTemplateGraph
            chartData={{
              series: efficiencySeries,
              options: efficiencyMainOptions,
              type: 'line',
              id: GraphId.EFFICIENCY_MAIN,
              height: 250,
              isFlex: true,
            }}
            isLoading={isEfficiencyLoading}
            texts={{
              errorKey: 'error.sensor.data-fetch',
              header: `${i18n.text('router.multi.air.efficiency')} (${SwitchUnit.kwhm3})`,
            }}
            noDataSlot={getNoDataSlot(
              !hasEfficiencyData,
              !hasPowerData ? i18n.text('compressed-air.efficiency.no-compressors-text') : '',
              true
            )}
          />
        </ApexWrapper>
      </FullLengthSectionBoxWrapper>
      {!isEfficiencyLoading && (
        <>
          <SectionBoxWrapper
            sx={{
              height: '100%',
            }}
          >
            <AirTemplateGraph
              chartData={{
                series: efficiencyPowerSeries,
                options: efficiencyPowerOptions,
                type: 'line',
                id: GraphId.EFFICIENCY_POWER,
                height: 250,
                isFlex: true,
              }}
              texts={{
                errorKey: 'error.sensor.data-fetch',
                header: `${i18n.text('machine-details.power')} (${SwitchUnit.kw})`,
              }}
              hasToolbar={false}
              noDataSlot={getNoDataSlot(!hasPowerData, i18n.text('compressed-air.efficiency.no-compressors-text'))}
            />
          </SectionBoxWrapper>
          <SectionBoxWrapper
            sx={{
              height: '100%',
            }}
          >
            <AirTemplateGraph
              chartData={{
                series: mainPipesFlowSeries,
                options: efficiencyFlowOptions,
                type: 'line',
                id: GraphId.EFFICIENCY_FLOW,
                height: 250,
                isFlex: true,
              }}
              texts={{
                errorKey: 'error.sensor.data-fetch',
                header: `${i18n.text('router.multi.air.flow')} (${SwitchUnit.m3m})`,
              }}
              hasToolbar={false}
              noDataSlot={getNoDataSlot(!hasMainPipesData, i18n.text('compressed-air.efficiency.no-main-pipes-text'))}
            />
          </SectionBoxWrapper>
        </>
      )}
    </GraphsGridWrapper>
  )
}
