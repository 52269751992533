import React, { FC } from 'react'
import { Badge, useTheme } from '@mui/material'

import { AnnouncementBanner } from '../../../Shared/components/MUIComponents/update/AnnouncementBanner/AnnouncementBanner'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type ProductionStopsBannerProps = {
  unexplainedStopsCount: number
  onDrawerOpen: () => void
}

const ProductionStopsBanner: FC<ProductionStopsBannerProps> = ({ unexplainedStopsCount, onDrawerOpen }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()

  return (
    <AnnouncementBanner
      sx={{
        backgroundColor: theme.palette.SFIWarning?.[100],
        borderColor: theme.palette.SFIWarning?.[200],
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      }}
      variant="warning"
      showCtaButton={true}
      ctaColor="primary"
      ctaType="outlined"
      ctaText={i18n.text('oee.machine-view.announcement-banner.cta-label')}
      primaryText={i18n.text('oee.machine-view.announcement-banner.primary-text')}
      secondaryText={i18n.text('oee.machine-view.announcement-banner.secondary-text')}
      onCtaButtonClick={onDrawerOpen}
      customTextSlot={
        <Badge
          sx={{
            '.MuiBadge-badge': {
              border: `1px solid ${theme.palette.SFIWarning?.[200]}`,
              color: theme.palette.SFIBase.white,
              fontSize: '0.75rem',
            },
          }}
          badgeContent={unexplainedStopsCount}
          color="warning"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      }
    />
  )
}

export default ProductionStopsBanner
