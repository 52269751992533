import { ILocationSeries, ISeriesData, ITemperatureHumiditySeries, SeriesNames } from '../types'

export function mapDataToApexSeries(locationMeasurements: ITemperatureHumiditySeries, shouldUseRangeArea: boolean) {
  const { temperature, humidity } = locationMeasurements
  const temperatureSeries = getDataOfMeasurements(temperature, shouldUseRangeArea)
  const humiditySeries = getDataOfMeasurements(humidity, shouldUseRangeArea)
  return [...temperatureSeries, ...humiditySeries]
}

export function getDataOfMeasurements(location: ILocationSeries, shouldUseRangeArea: boolean) {
  if (!location) {
    return []
  }

  const rawSeries = location.measurements

  if (!rawSeries || rawSeries?.length === 0) {
    return []
  }

  const series: ApexAxisChartSeries = [
    {
      name: location.name,
      type: 'line',
      color: location.color,
      data: rawSeries.map(point => {
        return {
          x: new Date(point.time),
          y: point.value,
        }
      }),
    },
  ]

  let mixedFlowSeries: ApexAxisChartSeries = []

  if (shouldUseRangeArea) {
    const rangeAreaSeries: ApexAxisChartSeries = [
      {
        name: location.name + ' Range',
        type: 'rangeArea',
        color: location.color,
        data: rawSeries.map(point => {
          return {
            x: new Date(point.time),
            y: [point.min, point.max],
          }
        }),
      },
    ]
    mixedFlowSeries = series.concat(rangeAreaSeries)
  }

  return mixedFlowSeries.length > 0 ? mixedFlowSeries : series
}

export function mapSeriesData(locationMeasurements: ITemperatureHumiditySeries): ISeriesData[] {
  const { temperature, humidity } = locationMeasurements
  const temperatureSeries: ISeriesData[] = getSeriesData(temperature)
  const humiditySeries: ISeriesData[] = getSeriesData(humidity)
  return [...temperatureSeries, ...humiditySeries]
}

export function getSeriesData(location: ILocationSeries): ISeriesData[] {
  if (!location) {
    return []
  }

  const rawSeries = location.measurements

  if (!rawSeries || rawSeries?.length === 0) {
    return []
  }

  const resultArray: ISeriesData[] = [
    {
      id: location.id,
      name: location.name,
      color: location.color,
      data: rawSeries.map(point => {
        return {
          time: point.time.toString(),
          value: point.value || 0,
          min: point.min || 0,
          max: point.max || 0,
        }
      }),
    },
  ]
  return resultArray
}

export function getIsTemperatureSeries(itemName?: string) {
  if (itemName) {
    return itemName === SeriesNames.temperature
  }
  return false
}

export function getHasHumidity(apexSeries: ApexAxisChartSeries) {
  return apexSeries.findIndex(item => item.name === SeriesNames.humidity) !== -1
}
