import { range } from 'ramda'

import { DayName } from './types'

const timeformat = new Intl.NumberFormat('en-GB', { minimumIntegerDigits: 2 })

export const parseDay = (day: string) => dayOptions.indexOf(day as DayName)
export const parseTime = (time: string) => time.split(':')
export const mergeTime = (hour: string, minute: string) => `${hour}:${minute}`

export const dayOptions = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as DayName[]
export const hourOptions = range(0, 24).map(n => timeformat.format(n))
export const minuteOptions = ['00', '15', '30', '45']

export function getDayName(index: number | string) {
  if (dayOptions.length - 1 < +index) {
    throw new Error('Day index out of bounds')
  }
  return dayOptions[+index]
}
