import styled from 'styled-components'
import { DateTime } from 'luxon'

import { themeColors } from '../../../Shared/utils'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const BridgeId = styled.h2`
  margin: 0;
  display: flex;
  align-items: center;
`

const BridgeType = styled.h3`
  color: ${themeColors.grey};
`

const BridgeWrapper = styled.li<{ status: string }>`
  display: inline-block;
  border: 1px solid ${themeColors.grey};
  border-radius: 4px;
  padding: 1em;
  margin: 0 1em 1em 0;
  border-top: 5px solid ${themeColors.successDark};

  max-width: 30em;
  width: 100%;

  h2 {
    margin: 0;
  }
`

type TeltonikaBridgeProps = {
  serialNumber: string
  updatedAt: string
}

const TeltonikaBridge = ({ serialNumber, updatedAt }: TeltonikaBridgeProps) => {
  const { i18n } = useI18nContext()
  return (
    <BridgeWrapper status="online">
      <BridgeId>{serialNumber}</BridgeId>
      <BridgeType>Teltonika</BridgeType>
      <span>
        {i18n.text('pdm.last-updated')}: {DateTime.fromISO(updatedAt).toLocaleString(DateTime.DATETIME_SHORT)}
      </span>
    </BridgeWrapper>
  )
}

export default TeltonikaBridge
