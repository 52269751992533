import React, { FC, useState } from 'react'
import { Autocomplete, outlinedInputClasses } from '@mui/material'

import { ArrowDownIcon } from '../../../../../../Shared/components/icons'
import { AutocompleteOption } from '../../../../../../Shared/types/types'
import { GridBoxRow, Label } from '../..'
import { StyledAutocompleteTextField } from '../../../../../../Shared/components/MUIComponents/update/styledComponents/StyledAutocomplete'
import { useI18nContext } from '../../../../../../Shared/contexts/i18nContext/I18nContext'

interface CurrencyAutocompleteOption extends AutocompleteOption {
  key: string
}

interface ICurrencyAutocompleteInterface {
  selectedCurrency?: CurrencyAutocompleteOption | null
  currencyOptions: CurrencyAutocompleteOption[]
  onSelectCurrency: (option: CurrencyAutocompleteOption) => void
}

export const CurrencyAutocomplete: FC<ICurrencyAutocompleteInterface> = ({
  selectedCurrency,
  currencyOptions,
  onSelectCurrency,
}) => {
  const { i18n } = useI18nContext()
  const [isCurrencyAutocompleteOpen, setIsCurrencyAutocompleteOpen] = useState(false)
  return (
    <GridBoxRow>
      <Label>{i18n.text('settings.customer.currency')}</Label>
      <Autocomplete
        id="currency-autocomplete"
        open={isCurrencyAutocompleteOpen}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={currencyOptions}
        clearIcon={''}
        value={selectedCurrency}
        onOpen={() => setIsCurrencyAutocompleteOpen(true)}
        onClose={() => setIsCurrencyAutocompleteOpen(false)}
        onChange={(_, value) => {
          if (value) onSelectCurrency(value)
        }}
        popupIcon={
          <ArrowDownIcon
            sx={{
              width: '1rem',
              height: '1rem',
            }}
          />
        }
        renderInput={params => {
          return (
            <StyledAutocompleteTextField
              {...params}
              value={selectedCurrency}
              size="medium"
              disabled
              sx={{
                [`.${outlinedInputClasses.root}`]: {
                  boxShadow: isCurrencyAutocompleteOpen
                    ? '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #1d7afd3d'
                    : 'none',
                  maxWidth: '32rem',
                  width: '100%',
                },
              }}
            />
          )
        }}
      />
    </GridBoxRow>
  )
}
