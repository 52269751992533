import { Button, darken, styled } from '@mui/material'

const StyledChartToolsButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBlueDark[50],
  color: theme.palette.SFIGreyLight[950],
  boxShadow: 'none',
  fontWeight: 400,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.SFIBlueDark[50] && darken(theme.palette.SFIBlueDark[50], 0.1),
    color: theme.palette.SFIGreyLight[950],
  },
  width: '100%',
}))

export { StyledChartToolsButton }
