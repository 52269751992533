import { FC } from 'react'
import { useTheme } from '@mui/material'

import { AssetsAutocompleteProps } from '../types'
import { CrossIcon } from '../../../../../Shared/components/icons'
import {
  PaperComponent,
  RenderGroup,
  RenderInput,
  RenderOption,
  RenderTags,
  StyledAutocomplete,
} from './StyledAutocompleteComponents'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const AssetsSelectAutocomplete: FC<AssetsAutocompleteProps> = ({
  selectedAssets,
  assetsFiltered,
  handleAssetsChange,
  handleSelectAllAssets,
  selectAll,
}) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  return (
    <StyledAutocomplete
      disableCloseOnSelect
      filterSelectedOptions
      multiple
      value={selectedAssets}
      limitTags={10}
      options={assetsFiltered}
      getOptionLabel={option => option.assetName}
      onChange={handleAssetsChange}
      renderInput={params => {
        const { InputLabelProps, InputProps, ...rest } = params
        return (
          <RenderInput
            params={params}
            rest={rest}
            theme={theme}
            placeholderText={i18n.text('reports.tracking.configuration.placeholder.selectMachines')}
          />
        )
      }}
      renderOption={(props, option) => {
        const { ...optionProps } = props
        return (
          <RenderOption
            theme={theme}
            optionProps={optionProps}
            option={option}
          />
        )
      }}
      renderGroup={params => (
        <RenderGroup
          params={params}
          theme={theme}
        />
      )}
      clearIcon={<CrossIcon sx={{ width: '1rem', height: '1rem' }} />}
      renderTags={(value, getTagProps) =>
        value.map((option, index: number) => {
          const { key, ...tagProps } = getTagProps({ index })
          return (
            <RenderTags
              theme={theme}
              option={option}
              key={key}
              tagProps={tagProps}
            />
          )
        })}
      groupBy={option => option.groupName}
      freeSolo={false}
      isOptionEqualToValue={(option, value) => option.assetId === value.assetId}
      PaperComponent={paperProps => {
        const { children, ...restPaperProps } = paperProps
        return (
          <PaperComponent
            i18n={i18n}
            restPaperProps={restPaperProps}
            children={children}
            handleSelectAllAssets={handleSelectAllAssets}
            selectAll={selectAll}
            theme={theme}
          />
        )
      }}
    ></StyledAutocomplete>
  )
}
