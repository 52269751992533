import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'

import Error from '../../../Shared/components/MUIComponents/Error'
import Layout from '../../components/Layout'
import Loader from '../../../Shared/components/MUIComponents/Loader'
import { CAChartToolsContextProvider } from '../../context/CAChartToolsContext'
import { CompressedAirAssetTreeQuery, useCompressedAirAssetTreeQuery } from '../../../Shared/graphql/codegen'
import { CompressedAirContextProvider, useCompressedAirContext } from '../../context/CompressedAirContext'
import { EfficiencyContextProvider } from '../../context/EfficiencyContext'

const CompressedAirContent = ({ data }: { data: CompressedAirAssetTreeQuery }) => {
  const { queryParams, setUrlParams, sensorLocationIds, updateSelection } = useCompressedAirContext()
  const { sid } = queryParams
  const history = useHistory()

  const updateLocationSelection = useCallback(
    (ids: string[]) => {
      setUrlParams({ sid: ids })
      updateSelection(ids)
    },
    [updateSelection, setUrlParams]
  )

  // Auto selected location id when component is mounted.
  useEffect(() => {
    // check if the location id query params are valid, can only be done after we fetched the customer data
    const locationSelection = sid.every(id => id && sensorLocationIds.includes(id))
      ? { valid: true, ids: [...sid] as string[] }
      : { valid: false, ids: [] as string[] }

    if (sensorLocationIds.length > 0 && (!sid.length || !locationSelection.valid)) {
      const firstLocationId = sensorLocationIds[0]
      updateLocationSelection([firstLocationId])
      history.push({
        search: `?sid=${firstLocationId}`,
      })
    } else if (locationSelection.valid) {
      updateSelection(locationSelection.ids)
    }
  }, [])

  if (!data.myOrg?.groups) {
    return <></>
  }

  return (
    <Layout
      sid={sid}
      onSelectionUpdate={updateLocationSelection}
    />
  )
}

const CompressedAirContainer = () => {
  const { data, loading, error: loadAssetTreeError, refetch: refetchAssetTree } = useCompressedAirAssetTreeQuery()

  const sensorLocationIds = useMemo(
    () =>
      (data?.myOrg &&
        data.myOrg.groups.flatMap(group =>
          group.assets.flatMap(asset => asset.sensorLocations.flatMap(location => location.id))
        )) ||
      [],
    [data]
  )

  if (loading) {
    return <Loader />
  }

  if (loadAssetTreeError) {
    return <Error error={loadAssetTreeError} />
  }

  if (!data?.myOrg) {
    return <Error />
  }

  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <CAChartToolsContextProvider>
        <CompressedAirContextProvider
          data={data}
          sensorLocationIds={sensorLocationIds}
          refetchAssetTree={refetchAssetTree}
        >
          <EfficiencyContextProvider>
            <CompressedAirContent data={data} />
          </EfficiencyContextProvider>
        </CompressedAirContextProvider>
      </CAChartToolsContextProvider>
    </QueryParamProvider>
  )
}

export default CompressedAirContainer
