import { Box, FormControl, IconButton, SelectChangeEvent, Typography, styled } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import { DateTime } from 'luxon'

import MuiDateTimeRangePicker from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker'
import { DateRange } from '../../../Shared/types/analysis_types'
import { EBUBasicOeeAsset, EnergyByUnitOutputMetricUnits } from '../../types'
import { EBUTimepickerButton } from './EBUTimepickerButton'
import { FormHelperWrapper } from './FormHelperWrapper'
import { StyledFormHelperText } from './StyledFormHelperText'
import { StyledHelpIconAndTooltip } from './StyledHelpIconAndTooltip'
import { StyledInput } from '../../../Shared/components/MUIComponents/update/styledComponents/StyledInput'
import { StyledSelect } from '../../../Shared/components/MUIComponents/update/styledComponents/StyledSelect'
import { StyledSelectMenuItem } from '../../../Shared/components/MUIComponents/update/styledComponents/StyledSelectMenuItem'
import { TrashIcon } from '../../../Shared/components/icons/TrashIcon'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const StyledRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  alignItems: 'baseline',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(6),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const UnitConversionText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '20px',
  color: theme.palette.SFIGreyLight[600],
}))

interface OeeAssetFormRowProps {
  uniqueKey: string
  oeeMachines: EBUBasicOeeAsset[]
  selectedAssetId: string
  startDate: DateTime
  endDate: DateTime
  conversionRatio: number
  outputMetric: EnergyByUnitOutputMetricUnits
  onAssetChange: (key: string, event: SelectChangeEvent<string>) => void
  onDateChange: (key: string, startDate: DateTime, endDate: DateTime) => void
  onConversionRatioChange: (key: string, event: ChangeEvent<HTMLInputElement>) => void
  onAssetRemove: (key: string) => void
}

export const OeeAssetFormRow: FC<OeeAssetFormRowProps> = ({
  uniqueKey,
  oeeMachines,
  selectedAssetId,
  startDate,
  endDate,
  conversionRatio,
  outputMetric,
  onAssetChange,
  onDateChange,
  onConversionRatioChange,
  onAssetRemove,
}) => {
  const { i18n, userLocale } = useI18nContext()

  const handleAssetChange = (event: SelectChangeEvent<string>) => {
    return onAssetChange(uniqueKey, event)
  }

  const handleDateChange = (selection: DateRange) => {
    return onDateChange(uniqueKey, selection.startDate, selection.endDate)
  }

  const handleConversionRatioChange = (event: ChangeEvent<HTMLInputElement>) => {
    return onConversionRatioChange(uniqueKey, event)
  }

  const handleAssetRemove = () => {
    return onAssetRemove(uniqueKey)
  }

  return (
    <StyledRowContainer>
      <FormControl>
        <StyledFormHelperText sx={{ maxWidth: '269px' }}>{i18n.text('ebu.form.select-assets')}</StyledFormHelperText>
        <StyledSelect
          value={selectedAssetId}
          onChange={handleAssetChange}
          sx={{ width: '250px' }}
        >
          {oeeMachines.map((machine: any) => (
            <StyledSelectMenuItem
              key={machine.id}
              value={machine.id}
            >
              {machine.name}
            </StyledSelectMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      <Box>
        <StyledFormHelperText>{i18n.text('ebu.form.date')}</StyledFormHelperText>
        <MuiDateTimeRangePicker
          startDate={startDate}
          endDate={endDate}
          onDatesChange={handleDateChange}
          isCompareDatesOn={false}
          hasPrevNextPeriodButtons={false}
          showCompareDatesButton={false}
          isTimePicker={true}
          AnchorButton={EBUTimepickerButton}
          anchorButtonProps={{ startDate, endDate, locale: userLocale }}
        />
      </Box>
      <FormControl>
        <FormHelperWrapper>
          <StyledFormHelperText>{i18n.text('ebu.form.conversion-ratio')}</StyledFormHelperText>
          <StyledHelpIconAndTooltip title={i18n.text('ebu.form.conversion-ratio.tooltip')} />
        </FormHelperWrapper>
        <StyledInput
          type="number"
          inputProps={{
            min: 0,
            step: 0.1,
          }}
          value={conversionRatio}
          onChange={handleConversionRatioChange}
          disableUnderline
          sx={{ width: '135px' }}
        />
        <UnitConversionText>
          {i18n.text('ebu.form.set-unit.hint')} {i18n.text(`ebu.set-unit.units.${outputMetric}`)}
        </UnitConversionText>
      </FormControl>

      <Box sx={{ alignSelf: 'stretch', display: 'flex', alignItems: 'center' }}>
        <IconButton
          sx={{
            width: '20px',
            height: '20px',
          }}
          onClick={handleAssetRemove}
        >
          <TrashIcon />
        </IconButton>
      </Box>
    </StyledRowContainer>
  )
}
