import React, { FC } from 'react'
import { Box, useTheme } from '@mui/material'

import { polarToCartesian } from '../../utils/polarToCartesian'

interface SemiDonutProps {
  percentage: number
  size?: number
  strokeWidth?: number
  color?: string
}

/**
 * A React component that displays a percentage value as a semi-donut chart.
 *
 * This component renders a semi-donut (half-circle) chart to visually represent a percentage value.
 * The chart is customizable in terms of size, stroke width, and color. The percentage value is
 * displayed as a central text within the chart.
 *
 * @param {number} percentage - The percentage of the semi-donut chart to be filled.
 * @param {number} [size=160] - The diameter of the semi-donut chart in pixels.
 * @param {number} [strokeWidth=16] - The width of the semi-donut chart's stroke in pixels.
 * @param {string} [color='theme.palette.green[500]'] - The color of the semi-donut chart's stroke.
 */
const SemiDonut: FC<SemiDonutProps> = ({ percentage, size = 160, strokeWidth = 16, color }) => {
  const theme = useTheme()

  const radius = (size - strokeWidth) / 2
  const circumference = Math.PI * radius
  const offset = circumference - (circumference * percentage) / 100

  // function to describe the path of an arc in SVG path element
  const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {
    const start = polarToCartesian(x, y, radius, endAngle)
    const end = polarToCartesian(x, y, radius, startAngle)

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

    return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ')
  }

  return (
    <Box
      sx={{
        width: `${size}px`,
        height: `${size / 2}px`,
        margin: '0 auto',
      }}
    >
      <svg
        width={size}
        height={size / 2}
        viewBox={`0 0 ${size} ${size / 2 + strokeWidth / 2}`}
      >
        <path
          d={describeArc(size / 2, size / 2, radius, 180, 0)}
          fill="none"
          stroke={theme.palette.SFIGreyLight[200]}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        <path
          d={describeArc(size / 2, size / 2, radius, 180, 0)}
          fill="none"
          stroke={color ?? theme.palette.SFIGreen[500]}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={-offset}
        />
        <text
          x={size / 2}
          y={size / 2 - strokeWidth / 2}
          fontSize="24"
          fontWeight="bold"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {percentage}%
        </text>
      </svg>
    </Box>
  )
}

export default SemiDonut
