import { useEffect } from 'react'

import { AssetTreeMode } from '../types/Assets.types'
import { useOEEContext } from '../context/OEEContext'

const useAssetTreeMode = (mode: AssetTreeMode) => {
  const { assetTreeMode, selectedMachines, updateAssetTreeMode, addSelectedMachine, assets } = useOEEContext()

  useEffect(() => {
    if (assetTreeMode !== mode) {
      updateAssetTreeMode(mode)
    }

    if (selectedMachines.length === 0) {
      addSelectedMachine(assets[0].machines[0].id)
    }
  }, [addSelectedMachine, assetTreeMode, assets, mode, selectedMachines, updateAssetTreeMode])
}

export default useAssetTreeMode
