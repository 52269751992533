import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, accordionSummaryClasses, styled } from '@mui/material'
import { AccordionProps } from '@mui/material'
import { AccordionSummaryProps, Box, BoxProps, Checkbox, Typography, TypographyProps, useTheme } from '@mui/material'

import { ArrowIcon } from '../../../icons'

// TODO clean up this file
type ChildNode = {
  id: string
  name: string
}

interface IChildNodeProps<T> {
  parent: Readonly<T>
  isSelected: boolean
  hasCheckbox?: boolean
  handleClick?: () => void
  onCheckboxToggle?: () => void
  children: React.ReactNode
  isAllSelected?: boolean
  isIndeterminate?: boolean
  iconSlot?: React.ReactElement
}

interface IAssetTreeChildNodeWrapperProps extends BoxProps {
  isSelected: boolean
}

interface IAssetTreeChildNodeLabelProp extends TypographyProps {
  isSelected: boolean
}

interface IStyledAccordionSummaryProps extends AccordionSummaryProps {
  isSelected: boolean
}

interface IStyledAccordionSummaryTitleProps extends TypographyProps {
  isSelected: boolean
}

const IconSlotWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
}))

const CheckboxLabelWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
}))

export function AssetTreeChildWithChildren<T extends ChildNode>({
  parent,
  isSelected,
  isAllSelected,
  isIndeterminate,
  hasCheckbox,
  handleClick,
  onCheckboxToggle,
  children,
  iconSlot,
}: IChildNodeProps<T>) {
  const [expanded, setExpanded] = useState(true)
  const theme = useTheme()

  const handleParentClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setExpanded(!expanded)
  }

  const handleCheckboxChange = () => {
    if (!onCheckboxToggle) return
    onCheckboxToggle()
  }
  return (
    <StyledAccordion
      expanded={expanded}
      disableGutters
      elevation={0}
      data-testid="tree-asset-accordion"
    >
      <StyledAccordionSummary
        expandIcon={
          <ArrowIcon
            fontSize="inherit"
            sx={{ color: theme => theme.palette.SFIGreyLight[400] }}
            onClick={handleClick}
          />
        }
        aria-controls="tree-asset-content"
        id="tree-asset-header"
        isSelected={!!(isSelected || isAllSelected)}
        onClick={handleParentClick}
      >
        <CheckboxLabelWrapper>
          {hasCheckbox && (
            <Checkbox
              checked={isSelected}
              indeterminate={isIndeterminate}
              color="secondary"
              size="small"
              sx={{
                padding: 0,
              }}
              onClick={e => {
                e.stopPropagation()
              }}
              onChange={handleCheckboxChange}
            />
          )}
          <StyledAccordionSummaryTitle isSelected={!!(isSelected || isAllSelected)}>
            {parent.name}
          </StyledAccordionSummaryTitle>
        </CheckboxLabelWrapper>
        <IconSlotWrapper>{iconSlot}</IconSlotWrapper>
      </StyledAccordionSummary>

      <AccordionDetails sx={{ padding: 0, marginBottom: expanded ? theme.spacing(1) : 0 }}>{children}</AccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordion = styled(Accordion)<AccordionProps>(({ theme }) => ({
  border: 'none',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
}))

const StyledAccordionSummary = ({ isSelected, children, ...props }: IStyledAccordionSummaryProps) => (
  <AccordionSummary
    sx={{
      background: theme => (isSelected ? theme.palette.SFIBrand?.[100] : 'initial'),
      borderBottom: 'none',
      transform: 'rotate(0deg)',
      transition: 'transform 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      margin: theme => theme.spacing(0, 0, 0.5),
      padding: theme => theme.spacing(1, 1.5, 1, 3.25),
      color: theme => theme.palette.SFIGreyLight[700],
      borderRadius: '6px',
      minHeight: 0,
      [`.${accordionSummaryClasses.content}`]: {
        margin: 0,
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: theme => theme.spacing(1.5),
        gap: theme => theme.spacing(1.5),
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme => theme.palette.SFIBrand?.[100],
          color: theme => theme.palette.SFIBrand?.[800],
        },
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme => theme.palette.SFIBrand?.[100],
        color: theme => theme.palette.SFIBrand?.[800],
      },
    }}
    {...props}
  >
    {children}
  </AccordionSummary>
)

const StyledAccordionSummaryTitle = ({ isSelected, children, ...props }: IStyledAccordionSummaryTitleProps) => (
  <Typography
    sx={{
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme => (isSelected ? theme.palette.SFIBrand?.[800] : 'inherit'),
    }}
  >
    {children}
  </Typography>
)
