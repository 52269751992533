import React, { FC } from 'react'
import { Box, styled } from '@mui/material'

import { PlaceholderImageIcon } from '../../../icons/PlaceholderImageIcon'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

/**
 * @description - NoImagesData is a placeholder for the situation when there are no images
 * @param {string} title - A placeholder text
 */

interface INoImagesDataProps {
  title?: string
}

export const NoImagesData: FC<INoImagesDataProps> = ({ title }) => {
  const { i18n } = useI18nContext()
  return (
    <Wrapper>
      <IconWrapper>
        <PlaceholderImageIcon
          sx={{
            width: '2.5rem',
            height: '2.5rem',
          }}
        />
      </IconWrapper>
      <Title>{title || i18n.text('general.image.no-data')}</Title>
    </Wrapper>
  )
}

const Wrapper = styled(Box)({
  backgroundImage: 'url(/assets/backgroundPatternDecorative.svg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundRepeat: 'no-repeat',
  flexDirection: 'column',
})

const IconWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '12.5rem',
  background: `linear-gradient(180deg, ${theme.palette.SFIGreyLight[50]} 0%, #EDF0F3 100%)`,
  width: '6.5rem',
  height: '6.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1.25rem',
}))

const Title = styled('p')(({ theme }) => ({
  color: theme.palette.SFIGreyLight[800],
  textAlign: 'center',
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: '1.875rem',
  marginBottom: '2rem',
}))
