import { DateTime, Duration } from 'luxon'
import { Theme } from '@mui/material'

import { AssetProductionSpeedData, OeeAssetColors } from '../types/Assets.types'
import { AssetStatus } from '../../Shared/components/AssetCard/utils/types'
import { CycleTime, Measurement, Oee_Production_Speed_Unit_Enum } from '../../Shared/graphql/codegen'
import { ISODateTime, LocalDateTime } from '../../Shared/types/types'
import { SECTION_HEADER_DATE_FORMAT } from './constants'
import { convertFromSpeedCyclesPerHour } from './unitConversions'

export const getFormattedInterval = (start: LocalDateTime, end: LocalDateTime, locale: string) => {
  return {
    start: DateTime.fromISO(start).toFormat(SECTION_HEADER_DATE_FORMAT, {
      locale,
    }),
    end: DateTime.fromISO(end).toFormat(SECTION_HEADER_DATE_FORMAT, {
      locale,
    }),
  }
}

export const secondsToHumanReadable = (seconds: number): string => {
  const duration = Duration.fromObject({ seconds })

  return duration.toFormat('hh:mm:ss')
}

export const minutesToHumanReadable = (minutes: number): string => {
  const duration = Duration.fromObject({ minutes })

  return duration.toFormat('hh:mm:ss')
}

export const getFormattedDuration = (val: number): string => {
  const duration = Duration.fromObject({ minutes: val }).shiftTo('days', 'hours', 'minutes')
  const formattedString = []

  if (duration.days > 0) {
    formattedString.push(`${Math.round(duration.days)} d`)
  }
  if (duration.hours > 0) {
    formattedString.push(`${Math.round(duration.hours)} h`)
  }
  if (duration.minutes > 0) {
    formattedString.push(`${Math.round(duration.minutes)} min`)
  }

  return formattedString.join(' ')
}

export const getProductionSpeedSeriesData = (
  production: Partial<Measurement>,
  speedUnit: Oee_Production_Speed_Unit_Enum,
  assetCriticalStopTime: number
) => {
  const productionSpeedValue = convertFromSpeedCyclesPerHour(production?.value ?? 0, speedUnit)
  const isStopTime =
    speedUnit === Oee_Production_Speed_Unit_Enum.CycleTimeSeconds &&
    (productionSpeedValue >= assetCriticalStopTime || productionSpeedValue === 0)

  return {
    x: production?.time,
    y: !isStopTime ? productionSpeedValue : 0,
  } as AssetProductionSpeedData
}

export const getAssetStatusColors = (assetStatusColor: AssetStatus, theme: Theme): OeeAssetColors => {
  switch (assetStatusColor) {
    case AssetStatus.Success:
      return {
        background: theme.palette.SFIGreen?.[400],
        text: theme.palette.SFIBase.white,
        chip: {
          background: theme.palette.SFIGreen?.[500],
          text: theme.palette.SFIBase.white,
          icon: theme.palette.SFIBase.white,
          border: theme.palette.SFIGreen?.[500],
        },
      }
    case AssetStatus.Warning:
      return {
        background: theme.palette.SFIOrange?.[400],
        text: theme.palette.SFIBase.white,
        chip: {
          background: theme.palette.SFIOrange?.[600],
          text: theme.palette.SFIBase.white,
          icon: theme.palette.SFIBase.white,
          border: theme.palette.SFIOrange?.[600],
        },
      }
    case AssetStatus.Error:
      return {
        background: theme.palette.SFIOrange?.[400],
        text: theme.palette.SFIBase.white,
        chip: {
          background: theme.palette.SFIOrange?.[600],
          text: theme.palette.SFIBase.white,
          icon: theme.palette.SFIBase.white,
          border: theme.palette.SFIOrange?.[600],
        },
      }
    case AssetStatus.NoData:
    case AssetStatus.Disabled:
      return {
        background: theme.palette.SFIGreyLight?.[200],
        text: theme.palette.SFIGreyLight?.[700],
        chip: {
          background: theme.palette.SFIGreyLight?.[300],
          text: theme.palette.SFIGreyLight?.[700],
          icon: theme.palette.SFIGreyLight?.[400],
          border: theme.palette.SFIGreyLight?.[400],
        },
      }
    case AssetStatus.Off:
    default:
      return {
        background: theme.palette.SFIGreyLight?.[300],
        text: theme.palette.SFIGreyLight?.[700],
        chip: {
          background: theme.palette.SFIGreyLight?.[400],
          text: theme.palette.SFIGreyLight?.[700],
          icon: theme.palette.SFIGreyLight?.[500],
          border: theme.palette.SFIGreyLight?.[500],
        },
      }
  }
}

export const setTimeZone = (date: LocalDateTime, timeZone: string) =>
  DateTime.fromISO(date).setZone(timeZone).toISO() as ISODateTime

export const formatDuration = (seconds: number): string => {
  const duration = Duration.fromObject({ seconds }).shiftTo('hours', 'minutes', 'seconds')

  const hours = duration.hours ? `${duration.hours} h` : ''
  const minutes = duration.minutes ? `${duration.minutes} m` : ''
  const secs = duration.seconds ? `${duration.seconds} s` : ''

  return [hours, minutes, secs].filter(Boolean).join(' ')
}

export const getHasPerformanceWarnings = (targetCycleTimes: Partial<CycleTime>[]) => {
  return (
    targetCycleTimes.length === 0 || targetCycleTimes.some(targetCycleTime => targetCycleTime?.valueInSeconds === null)
  )
}
