import styled from 'styled-components'

const Clearfix = styled.div`
  *zoom: 1;

  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
`

export default Clearfix
