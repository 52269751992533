import { ApolloError, gql, useApolloClient } from '@apollo/client'
import { Box, SelectChangeEvent } from '@mui/material'
import { useState } from 'react'

import MUILoader from '../../../Shared/components/MUIComponents/Loader'
import {
  AssetDataCollection,
  AssetId,
  EBUBasicOeeAsset,
  EnergyAssetRowState,
  EnergyByUnitElectricityUnit,
  EnergyByUnitOutputMetricUnits,
  OeeAssetRowState,
  ProcessedAssetData,
  ProcessedAssetDataPerUnit,
} from '../../types'
import { EnergyByUnitChartContainer } from '../../components/EnergyByUnit/EnergyByUnitChartContainer'
import { EnergyByUnitForm } from '../../components/EnergyByUnit/EnergyByUnitForm'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useElectricityContext } from '../../context/ElectricityContext'
import { useGetBasicOeeAssetsQuery } from '../../../Shared/graphql/codegen'
import { useToastContext } from '../../../Shared/contexts/ToastContext'

const buildEnergyPerAssetQuery = (energyAssetRow: EnergyAssetRowState) => {
  const { selectedAssetId: assetId, startDate, endDate } = energyAssetRow
  const localStartDate = startDate.toISO({ includeOffset: false })
  const localEndDate = endDate.toISO({ includeOffset: false })
  return `
    id${assetId}: assets(where: {id: {_eq: "${assetId}" }}) {
      id
      name
      measurements(from: "${localStartDate}", to: "${localEndDate}", type: ENERGY, showCost: true) {
        time
        value
        cost
      }
     }
    `
}

const buildVolumePerAssetQuery = (oeeAssetRow: OeeAssetRowState, customerId: string) => {
  const { selectedAssetId: assetId, startDate, endDate } = oeeAssetRow
  const localStartDate = startDate.toISO({ includeOffset: false })
  const localEndDate = endDate.toISO({ includeOffset: false })
  return `
    id${assetId}: assets(where: {id: {_eq: "${assetId}" }}) {
      id
      name
      productionVolume(from: "${localStartDate}", to: "${localEndDate}") {
        time
        value
      }
     }
    `
}

const buildQueryDoc = (
  energyAssetRows: EnergyAssetRowState[],
  oeeAssetRows: OeeAssetRowState[],
  customerId: string
) => {
  const queryDocStart = `query GetEnergyAndVolumeData { 
  myOrg { `
  const queryDocEnd = `} }`
  const queryBodyEnergy = energyAssetRows.map(buildEnergyPerAssetQuery).join('\n')
  const queryBodyOee = oeeAssetRows.map(oar => buildVolumePerAssetQuery(oar, customerId)).join('\n')
  const result = queryDocStart + queryBodyEnergy + queryBodyOee + queryDocEnd
  return result
}

export const EnergyByUnitContainer = () => {
  const { data: fetchedOeeData, loading: fetchOeeDataLoading } = useGetBasicOeeAssetsQuery()
  const { machines, fetchAssetsLoading } = useElectricityContext()
  const { customerId } = useCurrentUser()
  const [outputMetricUnit, setOutputMetricUnit] = useState<EnergyByUnitOutputMetricUnits>(
    EnergyByUnitOutputMetricUnits.UNITS
  )
  const [selectedUnit, setSelectedUnit] = useState<EnergyByUnitElectricityUnit>('energy')
  const [isLoading, setIsLoading] = useState(false)
  const [processedAssetData, setProcessedAssetData] = useState<ProcessedAssetDataPerUnit[]>([])
  const client = useApolloClient()
  const { showToast } = useToastContext()

  const oeeMachines: EBUBasicOeeAsset[] = fetchedOeeData?.myOrg?.assets.length ? [...fetchedOeeData.myOrg.assets] : []

  const onOutputMetricUnitChange = (event: SelectChangeEvent<EnergyByUnitOutputMetricUnits>) => {
    return setOutputMetricUnit(event.target.value as EnergyByUnitOutputMetricUnits)
  }

  const onCalculate = async (energyAssetRows: EnergyAssetRowState[], oeeAssetRows: OeeAssetRowState[]) => {
    // set Loading state
    setIsLoading(true)
    // fetch measurements and production volume for each asset
    const queryDoc = gql(buildQueryDoc(energyAssetRows, oeeAssetRows, customerId))
    let rawData = {} as { data: AssetDataCollection }
    try {
      rawData = await client.query<AssetDataCollection>({ query: queryDoc })
    } catch (e) {
      if (e instanceof ApolloError) {
        showToast(e.message, 'error', 'Error', 10000)
      } else {
        showToast('An error occurred. Please try again', 'error', 'Error', 10000)
      }
      return setIsLoading(false)
    }

    const data = rawData?.data?.myOrg || ({} as AssetDataCollection)
    const processedAssetData = [] as ProcessedAssetData[]

    for (const assetId of Object.keys(data)) {
      if (assetId === '__typename') continue
      const realAssetId = assetId.replace('id', '')
      const asset = data[assetId as `id${AssetId}`][0]
      const measurements = asset?.measurements || []
      const isElectricityAsset = asset?.measurements !== undefined
      const productionVolume = asset?.productionVolume || []

      const energyContribution =
        energyAssetRows.find(({ selectedAssetId }) => selectedAssetId === realAssetId)?.percentageContributionValue || 0

      const conversionRatio =
        oeeAssetRows.find(({ selectedAssetId }) => selectedAssetId === realAssetId)?.conversionRatio || 1

      const totalEnergy = measurements.reduce((acc, { value }) => acc + value, 0)
      const proportionalEnergy = totalEnergy * energyContribution
      const totalCost = measurements.reduce((acc, { cost }) => acc + cost, 0)
      const proportionalCost = totalCost * energyContribution
      const totalVolume = productionVolume.reduce((acc, { value }) => acc + value, 0) * conversionRatio

      processedAssetData.push({
        assetId: realAssetId,
        name: asset.name,
        isElectricityAsset,
        proportionalEnergy,
        proportionalCost,
        totalEnergy,
        totalCost,
        totalVolume,
      })
    }

    const totalVolumeAcrossAssets = processedAssetData.reduce((acc, { totalVolume }) => acc + totalVolume, 0) || 1

    const processedAssetDataPerUnit: ProcessedAssetDataPerUnit[] = processedAssetData.map(assetData => {
      return {
        ...assetData,
        energyPerUnit: (assetData.proportionalEnergy || 0) / totalVolumeAcrossAssets,
        costPerUnit: (assetData.proportionalCost || 0) / totalVolumeAcrossAssets,
      }
    })

    setProcessedAssetData(processedAssetDataPerUnit)
    setIsLoading(false)
  }

  if (!machines.length || fetchAssetsLoading || fetchOeeDataLoading) {
    return (
      <Box>
        <MUILoader />
      </Box>
    )
  }
  return (
    <Box
      sx={theme => ({
        display: 'grid',
        gridTemplateColumns: '60% 40%',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        paddingRight: theme.spacing(4),
        alignItems: 'start',
        backgroundColor: theme.palette.background.default,
        height: '100%',
      })}
    >
      <SectionBoxWrapper
        data-testid="form-container"
        sx={{ overflowY: 'auto', maxHeight: '90%' }}
      >
        <EnergyByUnitForm
          machines={machines}
          oeeMachines={oeeMachines}
          outputMetricUnit={outputMetricUnit}
          onOutputMetricUnitChange={onOutputMetricUnitChange}
          onCalculate={onCalculate}
        />
      </SectionBoxWrapper>
      <SectionBoxWrapper
        data-testid="chart-container"
        sx={{ padding: 0 }}
      >
        <EnergyByUnitChartContainer
          isLoading={isLoading}
          processedAssetData={processedAssetData}
          selectedUnit={selectedUnit}
          outputMetric={outputMetricUnit}
          onUnitChange={(unit: EnergyByUnitElectricityUnit) => setSelectedUnit(unit)}
        />
      </SectionBoxWrapper>
    </Box>
  )
}
