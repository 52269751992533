import React, { FC } from 'react'
import { Chip, useTheme } from '@mui/material'

import { AlarmIcon, NoWiFiIcon, OffIcon, SuccessIcon, WarningIcon } from '../../../Shared/components/icons'
import { AssetStatus } from '../../../Shared/components/AssetCard/utils/types'
import { getAssetStatusColors } from '../../utils/helpers'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type AssetStatusContent = { [key in AssetStatus]: { text: string; icon: JSX.Element } }

const assetStatusContent: AssetStatusContent = {
  [AssetStatus.Success]: { text: 'oee.asset-status.success', icon: <SuccessIcon /> },
  [AssetStatus.Warning]: { text: 'oee.asset-status.warning', icon: <WarningIcon /> },
  [AssetStatus.Off]: { text: 'oee.asset-status.off', icon: <OffIcon /> },
  [AssetStatus.Error]: { text: 'oee.asset-status.error', icon: <AlarmIcon /> },
  [AssetStatus.Disabled]: { text: 'oee.asset-status.offline', icon: <NoWiFiIcon /> },
  [AssetStatus.NoData]: { text: 'oee.asset-status.no-data', icon: <OffIcon /> },
}

type AssetStatusLabelProps = {
  assetStatus: AssetStatus
}

const AssetStatusLabel: FC<AssetStatusLabelProps> = ({ assetStatus = AssetStatus.NoData }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()

  const assetLabelContent = assetStatusContent[assetStatus]
  const assetStatusColors = getAssetStatusColors(assetStatus, theme)

  return (
    <Chip
      sx={{
        backgroundColor: assetStatusColors?.chip?.background ?? theme.palette.SFIGreyLight[300],
        color: assetStatusColors?.chip?.text ?? theme.palette.SFIGreyLight[700],
        border: `1px solid ${assetStatusColors?.chip?.border ?? theme.palette.SFIGreyLight[400]}`,
        borderRadius: '1rem',
        height: 'auto',
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridColumnGap: '0.2rem',
        width: 'fit-content',
        alignItems: 'center',
        padding: '0 0.5rem',
        '& .MuiChip-icon': {
          display: 'flex',
          width: '0.8rem',
          height: '0.8rem',
          margin: 0,
          color: assetStatusColors?.chip?.icon ?? theme.palette.SFIGreyLight[400],
        },
        '& .MuiChip-label': {
          lineHeight: '1.4rem',
          padding: 0,
        },
      }}
      label={i18n.text(assetLabelContent.text)}
      icon={assetLabelContent.icon}
    />
  )
}

export default AssetStatusLabel
