import { FC, useEffect, useState } from 'react'

import AssetTreeItem from './AssetTreeItem'
import AssetTreeLink from './AssetTreeLink'
import { AssetTreeMode, Group } from '../../types/Assets.types'
import { AssetTreeParentNode } from '../../../Shared/components/MUIComponents/update/AssetTree/StyledAssetTreeParentNode'
import { useOEEContext } from '../../context/OEEContext'

type AssetTreeGroupProps = {
  group: Group
}

const AssetTreeGroup: FC<AssetTreeGroupProps> = ({ group }) => {
  const [isGroupFullySelected, setIsGroupFullySelected] = useState(false)
  const {
    assetTreeMode,
    selectedMachines,
    selectedMachine,
    updateSelectedMachine,
    addSelectedMachine,
    removeSelectedMachine,
  } = useOEEContext()

  const { machines } = group
  const isMultiSelect = assetTreeMode !== AssetTreeMode.SINGLE_SELECT

  const isGroupPartiallySelected = isMultiSelect
    ? selectedMachines.some(selectedMachine => machines.some(machine => machine.id === selectedMachine))
    : machines.some(machine => machine.id === selectedMachine)

  useEffect(() => {
    setIsGroupFullySelected(machines.every(machine => selectedMachines.includes(machine.id)))
  }, [isMultiSelect, machines, selectedMachine, selectedMachines])

  const handleMachineSelection = (machineId: string, isSelected: boolean) => {
    if (isSelected) {
      addSelectedMachine(machineId)
    } else {
      removeSelectedMachine(machineId)
    }
  }

  const selectGroup = () => {
    machines.forEach(machine => {
      if (!selectedMachines.includes(machine.id)) addSelectedMachine(machine.id)
    })
  }

  const deselectGroup = () => {
    machines.forEach(machine => {
      if (selectedMachines.includes(machine.id)) removeSelectedMachine(machine.id)
    })
  }

  const handleGroupSelection = () => {
    if (isGroupFullySelected) deselectGroup()
    else selectGroup()
  }

  return (
    <AssetTreeParentNode
      parent={{ id: group.id, name: group.name }}
      hasCheckbox={isMultiSelect}
      isSelected={isGroupPartiallySelected}
      isAllSelected={isMultiSelect && isGroupFullySelected}
      onCheckboxToggle={handleGroupSelection}
    >
      {machines.map(machine => {
        return isMultiSelect ? (
          <AssetTreeItem
            key={machine.id}
            id={machine.id}
            label={machine.name}
            onItemSelect={handleMachineSelection}
          />
        ) : (
          <AssetTreeLink
            key={machine.id}
            id={machine.id}
            label={machine.name}
            onClick={() => updateSelectedMachine(machine.id)}
          />
        )
      })}
    </AssetTreeParentNode>
  )
}

export default AssetTreeGroup
