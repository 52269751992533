import { FC, useMemo, useState } from 'react'

import CollapsibleAssetTreeWrapper from '../../../Shared/components/MUIComponents/update/AssetTree/CollapsibleAssetTreeWrapper'
import { AssetTreeGroup, AssetTreeTab, NavLikeToolbarItem } from '../../types'
import { ElectricityAssetTreeGroupsTab } from './ElectricityAssetTreeGroupsTab'
import { ElectricityAssetTreeMachineTab } from './ElectricityAssetTreeMachineTab'
import { NavLikeToolbar } from '../../../Shared/components/MUIComponents/update/NavLikeToolbar/NavLikeToolbar'
import { SearchBox } from '../../../Shared/components/MUIComponents/update/SearchBox/SearchBox'
import { SelectAllCheckbox } from './SelectAllCheckbox'
import { isoLocales } from '../../../Shared/utils'
import { useElectricityContext } from '../../context/ElectricityContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface ElectricityAssetTreeProps {
  groups: AssetTreeGroup[]
  onSelect: (assetId: string) => void
  onSelectAll: () => void
}
const ElectricityAssetTree: FC<ElectricityAssetTreeProps> = ({ groups, onSelect, onSelectAll }) => {
  const { i18n, userLocale } = useI18nContext()
  const { selectedAssets, areAllAssetsSelected, assetTreeTab, onTabNavigate, toggleGroupSelect } =
    useElectricityContext()
  const isIndeterminate = Object.keys(selectedAssets).length > 0 && !areAllAssetsSelected

  const [visibleSearchTerm, setVisibleSearchTerm] = useState('')
  const [cleanedSearchTerm, setCleanedSearchTerm] = useState('')

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawSearchTerm = e.target.value
    const cleanedSearchTerm = rawSearchTerm.trim().toLocaleLowerCase(isoLocales[userLocale])
    setVisibleSearchTerm(rawSearchTerm)
    setCleanedSearchTerm(cleanedSearchTerm)
  }

  const visibleGroups = useMemo(() => {
    if (!cleanedSearchTerm) return groups

    return groups.filter(group => {
      const groupNameFitsSearch = group.name.toLowerCase().includes(cleanedSearchTerm)
      if (groupNameFitsSearch) return true
      const machinesFitSearch = group.machines.some(machine => {
        const machineNameFitsSearch = machine.name.toLowerCase().includes(cleanedSearchTerm)
        if (machineNameFitsSearch) return true
        const componentsFitSearch = machine.components?.some(component => {
          return component.name.toLowerCase().includes(cleanedSearchTerm)
        })
        return componentsFitSearch
      })
      return machinesFitSearch
    })
  }, [groups, cleanedSearchTerm])

  const treeTabItems: NavLikeToolbarItem[] = useMemo(
    () => [
      {
        value: AssetTreeTab.MACHINE,
        title: i18n.text('assetselector.headers.machine'),
        isActive: assetTreeTab === AssetTreeTab.MACHINE,
      },
      {
        value: AssetTreeTab.GROUP,
        title: i18n.text('assetselector.headers.group'),
        isActive: assetTreeTab === AssetTreeTab.GROUP,
      },
    ],
    [assetTreeTab, i18n]
  )

  return (
    <CollapsibleAssetTreeWrapper>
      <NavLikeToolbar
        items={treeTabItems}
        onItemClick={t => onTabNavigate(t as AssetTreeTab)}
        wrapperProps={{ sx: { marginBottom: '1rem' } }}
      />
      <SearchBox
        value={visibleSearchTerm}
        showIcon={true}
        onChange={handleSearchChange}
        placeholder={i18n.text('assetselector.search.placeholder')}
        textFieldStyle={{ marginBottom: '1rem' }}
      />
      <SelectAllCheckbox
        onClick={onSelectAll}
        isIndeterminate={isIndeterminate}
        isSelected={areAllAssetsSelected}
      />
      {assetTreeTab === AssetTreeTab.MACHINE ? (
        <ElectricityAssetTreeMachineTab
          visibleGroups={visibleGroups}
          searchTerm={cleanedSearchTerm}
          onSelect={onSelect}
          onGroupSelect={toggleGroupSelect}
        />
      ) : (
        <ElectricityAssetTreeGroupsTab
          visibleGroups={visibleGroups}
          onSelect={onSelect}
          onGroupSelect={onSelect}
          searchTerm={cleanedSearchTerm}
        />
      )}
    </CollapsibleAssetTreeWrapper>
  )
}

export { ElectricityAssetTree }
