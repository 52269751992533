export enum Time {
  MillisecondsInSecond = 1000,
  MillisecondsInMinute = 60000,
  SecondsInMinute = 60,
  MinutesInHour = 60,
  SecondsInHour = SecondsInMinute * MinutesInHour, // 3600
  HoursInDay = 24,
  SecondsInDay = SecondsInHour * HoursInDay, // 86400
}

export enum DataIntervalMode {
  DAY = 'DAY_OEE',
  SHIFT = 'SHIFT_OEE',
}
