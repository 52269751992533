import { ProductionSpeedUnitInput } from '../../Shared/graphql/codegen'

export const OEE_STOP_LOGGING_DISABLED = 'OEE_STOP_LOGGING_DISABLED'
export const OEE_SHIFT_COMPARISON = 'OEE_SHIFT_COMPARISON'

export const SECTION_HEADER_DATE_FORMAT = 'dd LLL yyyy, HH:mm'
export const PRODUCTION_STATISTICS_DATE_FORMAT = 'dd MMM, HH:mm'
export const ASSET_CARD_SHORT_DATE_FORMAT = 'dd/MM, HH:mm'
export const SHIFT_EXTENDED_INTERVAL_FORMAT = 'ccc, d MMM HH:mm'
export const EXPORT_FILENAME_DATE_FORMAT = 'yyyy-MM-dd_HH-mm'
export const EXPORT_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss'

export const SPEED_UNIT_STRING: { [key in ProductionSpeedUnitInput as string]: string } = {
  [ProductionSpeedUnitInput.CyclesPerHour]: 'oee.production-speed.units.cycles-per-hour',
  [ProductionSpeedUnitInput.CyclesPerMinute]: 'oee.production-speed.units.cycles-per-minute',
  [ProductionSpeedUnitInput.CycleTimeSeconds]: 'oee.production-speed.units.seconds',
}

export const SPEED_UNIT_STRING_SHORT: { [key in ProductionSpeedUnitInput as string]: string } = {
  [ProductionSpeedUnitInput.CyclesPerHour]: 'oee.production-speed.units.short.cycles-per-hour',
  [ProductionSpeedUnitInput.CyclesPerMinute]: 'oee.production-speed.units.short.cycles-per-minute',
  [ProductionSpeedUnitInput.CycleTimeSeconds]: 'oee.production-speed.units.short.sec',
}
