import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ClockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_3)">
          <path
            d="M11 5.50001V11L14.6667 12.8333M20.1667 11C20.1667 16.0626 16.0626 20.1667 11 20.1667C5.9374 20.1667 1.83334 16.0626 1.83334 11C1.83334 5.9374 5.9374 1.83334 11 1.83334C16.0626 1.83334 20.1667 5.9374 20.1667 11Z"
            stroke="#667085"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
