import React from 'react'
import getFont from '@sensorfactdev/nucleus/utils/getFont'
import styled, { withTheme } from 'styled-components'

import { themeColors } from '../../utils'

const StyledButton = styled.button`
  border: 1px solid ${({ color }) => color};
  color: ${({
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selected' does not exist on type 'Pick<D... Remove this comment to see the full error message
    selected,
    color,
  }) => (selected ? themeColors.primary : color)};
  background-color: ${({
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selected' does not exist on type 'Pick<D... Remove this comment to see the full error message
    selected,
    color,
  }) => (selected ? color : 'transparent')};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-family: ${theme => getFont('primaryFont', theme)};
  outline: none;
  border-radius: 4px;
  font-size: 17px;
  padding: 0.8em 2em;
  line-height: 1;
  transition: all 0.2s ease-out;
  display: inline-block;
  margin: 0 1em 1em 0;
`

type OwnProps = {
  color?: string
  selected?: boolean
  disabled?: boolean
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ComponentButton.defaultProps

/** @deprecated do not use */
// @ts-expect-error ts-migrate(7022) FIXME: 'ComponentButton' implicitly has type 'any' becaus... Remove this comment to see the full error message
const ComponentButton = ({ color, selected, disabled }: Props) => (
  <StyledButton
    color={color}
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    selected={selected}
    disabled={disabled}
  />
)

ComponentButton.defaultProps = {
  selected: false,
  color: themeColors.black,
  disabled: false,
}

export default withTheme(ComponentButton)
