import React, { FC } from 'react'
import { Box, BoxProps, Breakpoint, Button, Dialog, DialogProps, Typography, styled, useTheme } from '@mui/material'

import MUILoader from '../Loader'
import { CrossIcon } from '../../icons'

type HeightType = 'fixed' | 'responsive'

interface IStyledDialogModalProps extends Omit<DialogProps, 'open'> {
  title?: string
  customTitle?: React.ReactNode
  subtitle?: string
  isLoading?: boolean
  onClose: () => void
  children: React.ReactNode
  icon?: React.ReactNode
  isOpen: boolean
  maxWidth?: Breakpoint | false
  height?: HeightType
}

/**
 * A styled dialog modal component. It has a title, subtitle (optional), and a close button.
 *
 * @param title - (optional) The title of the modal. Shown only if there is no custom title provided.
 * @param customTitle - (optional) A custom title component in case you need more than just a text (for example,
 *                      an icon or any other element).
 * @param subtitle - (optional) The subtitle of the modal.
 * @param isLoading - A boolean value to show the loading spinner.
 * @param onClose - A function to close the modal.
 * @param children - The content of the modal.
 * @param isOpen - The state of the modal.
 * @param icon - (optional) An icon to be displayed next to the title.
 * @param maxWidth - (optional) The maximum width of the modal.
 * @param height - (optional) The height of the modal -  "fixed" or "responsive" (depends on the content height).
 * @param sx - (optional) The style object.
 * @param props - (optional) The rest of the DialogProps that can be passed to the Dialog component.
 * */
export const StyledDialogModal: FC<IStyledDialogModalProps> = ({
  title,
  customTitle,
  subtitle,
  isLoading,
  children,
  icon,
  isOpen,
  maxWidth = 'sm',
  height = 'fixed',
  sx,
  onClose,
  ...props
}) => {
  const theme = useTheme()

  return (
    <Dialog
      {...props}
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth
      onClose={onClose}
      sx={sx}
    >
      <ModalBox
        heightType={height}
        className="modal-box"
      >
        <ModalBoxHeader sx={{ alignItems: subtitle ? 'start' : 'center', gap: '1rem' }}>
          <ModalBoxTitle>
            {!!icon && icon}
            <TextWrapper>
              {title && !customTitle && <Title>{title}</Title>}
              {!!customTitle && customTitle}
              {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
            </TextWrapper>
          </ModalBoxTitle>
          <CrossIconButton
            disableRipple
            onClick={onClose}
            sx={{ flexShrink: 0 }}
          >
            <CrossIcon sx={{ width: '0.8rem', height: '0.8rem', color: theme.palette.SFIGreyLight[400] }} />
          </CrossIconButton>
        </ModalBoxHeader>
        {isLoading ? (
          <MUILoader
            margin="auto 0"
            height={8}
          />
        ) : (
          children
        )}
      </ModalBox>
    </Dialog>
  )
}

interface IModalBoxProps extends BoxProps {
  heightType: HeightType
}

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: 600,
  color: theme.palette.SFIGreyLight[800],
  lineHeight: '1.75rem',
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
}))

const TextWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
})

const ModalBox = styled((props: IModalBoxProps) => {
  const { heightType, ...other } = props
  return <Box {...other} />
})(({ heightType, theme }) => ({
  background: theme.palette.SFIBase.white,
  borderRadius: theme.shape.borderRadius,
  boxShadow: `0px 8px 8px -4px ${theme.palette.SFIGreyLight[900]}, 0px 20px 24px -4px ${theme.palette.SFIGreyLight[900]}`,
  display: 'grid',
  gridRowGap: theme.spacing(2),
  padding: '1.5rem',
  height: '100%',
  gridTemplateRows: heightType === 'fixed' ? `auto ${theme.spacing(45)} auto` : 'auto 1fr auto',
}))

const ModalBoxHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ModalBoxTitle = styled(Box)({
  display: 'flex',
  gap: '1em',
})

export const ModalFooter = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  overflow: 'hidden',
  gap: '1em',
})

const CrossIconButton = styled(Button)(({ theme }) => ({
  display: 'grid',
  height: '2.75rem',
  width: '2.75rem',
  placeItems: 'center',
  cursor: 'pointer',
  minWidth: 0,
  '&:hover': {
    svg: {
      color: theme.palette.SFIGreyLight[500],
    },
  },
  '&:focus': {
    boxShadow: '0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
  },
}))
