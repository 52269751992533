import { ApexOptions } from 'apexcharts'
import { useMemo } from 'react'
import { useTheme } from '@mui/material'

import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface EnergyBalanceStandbyBarChartOptionsArgs {
  tooltipFormatter: (chart: any, options?: any) => string
  totalsFormatter: (v: string | undefined) => string
  categories: Array<string | null>
  onDataPointMouseEnter?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
  onDataPointMouseLeave?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
  showingCosts: boolean
  valuesModifier: { unit: string }
  decimal: number
  loading: boolean
}

export const useEnergyBalanceStandbyBarChartOptions = ({
  tooltipFormatter,
  categories,
  onDataPointMouseEnter,
  onDataPointMouseLeave,
  showingCosts,
  valuesModifier,
  decimal,
  loading,
  totalsFormatter,
}: EnergyBalanceStandbyBarChartOptionsArgs) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const energyBalanceStandbyBarChartOptions: ApexOptions = useMemo(
    () => ({
      noData: {
        text: loading ? i18n.text('app.loading') : i18n.text('analysis.energy-balance.no-data'),
      },
      fill: {
        opacity: 1,
      },
      chart: {
        id: 'energyBalanceStandby' + Math.random(),
        type: 'bar',
        background: 'white',
        stacked: true,
        toolbar: {
          show: false,
        },
        events: {
          dataPointMouseEnter: onDataPointMouseEnter,
          dataPointMouseLeave: onDataPointMouseLeave,
        },
      },
      dataLabels: {
        formatter: v => `${i18n.number(v, { maximumFractionDigits: decimal })}`,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: Math.min(10 * categories.length, 75) + '%',
          dataLabels: {
            total: {
              formatter: totalsFormatter,
              offsetX: 14,
            },
          },
        },
      },
      xaxis: {
        categories,
        axisBorder: {
          show: true,
          color: 'black',
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          style: {
            fontSize: '14px',
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
          color: 'black',
          offsetX: 0,
          offsetY: 0,
        },
        decimalsInFloat: 0,
        labels: {
          style: {
            fontSize: '14px',
          },
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: tooltipFormatter,
        },
      },
      legend: {
        fontFamily: theme.typography.fontFamily,
        showForSingleSeries: true,
        horizontalAlign: 'left',
        fontSize: '14px',
        offsetY: 10,
        itemMargin: {
          vertical: 10,
        },
      },
    }),
    [
      tooltipFormatter,
      totalsFormatter,
      onDataPointMouseEnter,
      onDataPointMouseLeave,
      categories,
      showingCosts,
      valuesModifier.unit,
      decimal,
    ]
  )

  return energyBalanceStandbyBarChartOptions
}
