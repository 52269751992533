import { Box, useTheme } from '@mui/material'
import { FC, ReactNode } from 'react'

import MuiSecondaryNav, {
  LinkInfo,
} from '../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'

type SecondaryNavHeaderProps = {
  links: LinkInfo[]
  children?: ReactNode
}

const SecondaryNavContainer: FC<SecondaryNavHeaderProps> = ({ children, links }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.SFIBase.white,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.SFIGreyLight[300]}`,
        padding: '1.5rem 1.5rem 0',
        '& > div': {
          borderBottom: 'none',
        },
      }}
    >
      <MuiSecondaryNav links={links} />
      {children}
    </Box>
  )
}

export default SecondaryNavContainer
