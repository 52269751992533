import { FC, useMemo } from 'react'

import MuiDateTimeRangePicker from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePickerControlled'
import { StartEndDatesValues } from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePickerControlled/types'
import { dateTimePickerCustomShortcuts } from './DateTimePickerCustomShortcuts'
import { useElectricityContext } from '../../context/ElectricityContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface DateTimePickerContainerProps {
  isOpen: boolean
  onToggleOpen: (newState: boolean) => void
}

export const DateTimePickerContainer: FC<DateTimePickerContainerProps> = ({ isOpen, onToggleOpen }) => {
  const {
    setFromToValues,
    onDateRangeChange,
    onCompareRangeChange,
    onCompareDatesToggle,
    previousPeriodFromToValues,
    fromToValues,
    showCompare,
    setShowCompare,
    selectedDateRange,
    setPreviousPeriodFromToValues,
  } = useElectricityContext()

  const handleCompareDateToggle = (isEnabled?: boolean) => {
    onCompareDatesToggle()
    setShowCompare(!!isEnabled)
  }

  const handleStartEndDates = (dates: StartEndDatesValues) => {
    setFromToValues(dates)
  }

  const handleDatesChange = (dateRange: StartEndDatesValues, zooming?: boolean) => {
    onDateRangeChange(
      {
        startDate: dateRange.startDate || selectedDateRange.startDate,
        endDate: dateRange.endDate || selectedDateRange.endDate,
      },
      true //  we pass true for zooming to respect time selection
    )
  }

  const handleCompareStartEndDates = (dates: StartEndDatesValues) => {
    setPreviousPeriodFromToValues(dates)
  }

  const { i18n } = useI18nContext()
  const datePickerShortcuts = useMemo(() => dateTimePickerCustomShortcuts(i18n), [i18n])

  return (
    <MuiDateTimeRangePicker
      fromToValues={fromToValues}
      handleStartEndDates={handleStartEndDates}
      handleCompareStartEndDates={handleCompareStartEndDates}
      onDatesChange={handleDatesChange}
      onCompareDatesChange={dateRange => onCompareRangeChange(dateRange, true)}
      onCompareDatesToggle={handleCompareDateToggle}
      compareFromToValues={previousPeriodFromToValues}
      isTimePicker={true}
      isCompareDatesOn={showCompare}
      shortcutsItems={datePickerShortcuts}
      isOpen={isOpen}
      onToggleOpen={onToggleOpen}
    />
  )
}
