import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  Divider,
  FormControlLabel,
  InputBase,
  Paper,
  PaperProps,
  Theme,
  styled,
} from '@mui/material'

import { CrossIcon } from '../../../../../Shared/components/icons'
import { StyledCheckbox } from '../../../../../Shared/components/MUIComponents/update/Checkbox/StyledCheckBox'

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  paddingTop: '0.375rem',
  paddingBottom: '0.375rem',
  paddingLeft: '0.875rem',
  borderRadius: '0.375rem',
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  boxShadow: `0px 1px 2px 0px ${theme.palette.SFIGreyLight[900]}0d`,
  color: theme.palette.SFIGreyLight[800],
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
  '&.Mui-focused': {
    boxShadow: `0px 0px 0px 4px ${theme.palette.SFIBrand[800]}3d`,
  },
})) as typeof Autocomplete

interface RenderTagsProps {
  theme: Theme
  option: { assetId: string; assetName: string; groupId: string; groupName: string; type: string }
  key: number
  tagProps: {
    className: string
    disabled: boolean
    'data-tag-index': number
    tabIndex: -1
    onDelete: (event: any) => void
  }
}

export const RenderTags = (props: RenderTagsProps) => {
  return (
    <Chip
      sx={{
        height: '1.5rem',
        alignItems: 'center',
        borderRadius: '0.375rem',
        border: `1px solid ${props.theme.palette.SFIGreyLight[300]}`,
        marginTop: '0.375rem',
      }}
      variant="outlined"
      size="small"
      label={props.option.assetName}
      key={props.key}
      {...props.tagProps}
      deleteIcon={
        <CrossIcon
          sx={{
            width: '0.5rem',
            height: '0.5rem',
            marginRight: 0,
          }}
        />
      }
    />
  )
}

interface PaperComponentProps {
  restPaperProps: PaperProps
  children: React.ReactNode
  handleSelectAllAssets: () => void
  i18n: $I18FixMe
  selectAll: boolean
  theme: Theme
}

export const PaperComponent = (props: PaperComponentProps) => {
  return (
    <Paper
      {...props.restPaperProps}
      sx={{
        boxShadow: `0px 12px 16px -4px ${props.theme.palette.SFIGreyLight[900]}14, 0px 4px 6px -2px ${props.theme.palette.SFIGreyLight[900]}08`,
      }}
    >
      <Box
        onMouseDown={e => e.preventDefault()}
        pl="0.75rem"
        py="0.25rem"
      >
        <FormControlLabel
          onClick={e => {
            e.preventDefault()
            props.handleSelectAllAssets()
          }}
          label={props.i18n.text('reports.tracking.configuration.module.selectAll')}
          control={<StyledCheckbox checked={props.selectAll} />}
        />
      </Box>

      <Divider />
      {props.children}
    </Paper>
  )
}

interface RenderGroupProps {
  params: AutocompleteRenderGroupParams
  theme: Theme
}

export const RenderGroup = (props: RenderGroupProps) => {
  return (
    <li key={props.params.key}>
      <Box
        sx={{
          position: 'sticky',
          top: '0.5rem',
          padding: '0.25rem 0.625rem',
          fontSize: '0.875rem',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '1.25rem',
          color: props.theme.palette.SFIGreyLight[700],
          backgroundColor: props.theme.palette.SFIBase.white,
        }}
      >
        {props.params.group}
      </Box>
      <ul style={{ padding: 0 }}>{props.params.children}</ul>
    </li>
  )
}

interface RenderOptionProps {
  theme: Theme
  optionProps: React.HTMLAttributes<HTMLLIElement>
  option: { assetId: string; assetName: string; groupId: string; groupName: string; type: string }
}
export const RenderOption = (props: RenderOptionProps) => {
  return (
    <Box
      component="li"
      {...props.optionProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
        margin: '0 0.375rem 0.063rem 0.375rem',
        '&:focus': {
          background: props.theme.palette.SFIGreyLight[100],
          borderRadius: '0.375rem ',
        },
        '&:hover': {
          background: props.theme.palette.SFIGreyLight[100],
          borderRadius: '0.375rem ',
        },
        '&:selected': {
          background: props.theme.palette.SFIGreyLight[100],
          borderRadius: '0.375rem ',
        },
      }}
    >
      {props.option.assetName}
    </Box>
  )
}

interface RenderInputProps {
  params: AutocompleteRenderInputParams
  rest: {
    id: string
    disabled: boolean
    fullWidth: boolean
    size: 'small' | undefined
    inputProps: React.InputHTMLAttributes<HTMLInputElement>
  }
  theme: Theme
  placeholderText: string
}
export const RenderInput = (props: RenderInputProps) => {
  return (
    <InputBase
      {...props.params.InputProps}
      {...props.rest}
      placeholder={props.placeholderText}
      sx={{
        '& ::placeholder': {
          color: props.theme.palette.SFIGreyLight[500],
          fontSize: '1rem',
          fontWeight: 400,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        root: {
          '&.Mui-focused fieldset': {
            border: `1px solid ${props.theme.palette.SFIOrange[300]}`,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
        },
        fieldset: {
          border: `1px solid ${props.theme.palette.SFIGreyLight[300]}`,
          borderRadius: '0.375rem',
          boxShadow: `0px 1px 2px 0px ${props.theme.palette.SFIGreyLight[900]}0d`,
          input: {
            border: `1px solid ${props.theme.palette.SFIOrange[300]}`,
            '&::placeholder': {
              color: props.theme.palette.SFIGreyLight[500],
              fontSize: '1rem',
              fontWeight: 400,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          },
        },
      }}
    />
  )
}
