import * as ReactDomServer from 'react-dom/server'
import { FC } from 'react'
import { styled } from '@mui/material'

import MuiThemeProvider from '../../../Shared/theme/MuiThemeProvider'
import { ISeriesData, SwitchUnit } from '../../types/compressed-air_types'
import { numberFormatOptions } from '../../../Shared/constants/numberFormatOptions'

interface ICustomTooltipProps {
  seriesData: ISeriesData
  dataPointIndex: number
  i18n: $I18FixMe
  switchUnit: SwitchUnit
  decimal: number
}

interface IRenderCustomTooltipArgs extends Omit<ICustomTooltipProps, 'seriesData'> {
  seriesIndex: number
  seriesData: ISeriesData[]
  shouldUseFirstDataIndex: boolean
  switchUnit: SwitchUnit
}

const TooltipHeader = styled('div')(({ theme }) => ({
  background: theme.palette.SFIGreyLight[200],
  borderBottom: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  padding: theme.spacing(0.75),
  marginBottom: theme.spacing(0.5),
  fontSize: theme.spacing(1.5),
}))

const TooltipTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.25),
  fontSize: theme.spacing(1.5),
  whiteSpace: 'break-spaces',
}))

const TooltipBody = styled('div')(({ theme }) => ({
  padding: `0 ${theme.spacing(1.25)} ${theme.spacing(0.5)}`,
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  width: theme.spacing(35),
}))

const TooltipContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: theme.spacing(0.5),
  fontSize: theme.spacing(1.5),
}))

const TooltipMarker = styled('div')(({ theme }) => ({
  minWidth: theme.spacing(1.5),
  minHeight: theme.spacing(1.5),
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
  borderRadius: '50%',
  display: 'flex',
}))

const BoldSpan = styled('span')(() => ({
  fontWeight: 500,
}))

export function renderCustomTooltip({
  shouldUseFirstDataIndex,
  seriesIndex,
  seriesData,
  dataPointIndex,
  i18n,
  switchUnit,
  decimal,
}: IRenderCustomTooltipArgs) {
  const notRangeSeriesIndex = shouldUseFirstDataIndex ? 0 : seriesIndex
  return ReactDomServer.renderToString(
    <CustomTooltip
      seriesData={seriesData[notRangeSeriesIndex]}
      dataPointIndex={dataPointIndex}
      i18n={i18n}
      switchUnit={switchUnit}
      decimal={decimal}
    />
  )
}

const CustomTooltip: FC<ICustomTooltipProps> = ({ seriesData, dataPointIndex, i18n, switchUnit, decimal }) => {
  const rawData = seriesData?.data?.[dataPointIndex]
  const dateTimePoint = rawData?.time ? new Date(rawData.time) : ''
  const monthWeekDayFormat = {
    month: 'short',
    weekday: 'short',
  }
  const dayTimeFormat = {
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }

  if (!rawData || rawData.value === null) {
    return null
  }

  return (
    <MuiThemeProvider>
      <div>
        <TooltipHeader>
          {dateTimePoint
            ? `${i18n.date(dateTimePoint, monthWeekDayFormat)} ${i18n.date(dateTimePoint, dayTimeFormat)}`
            : ''}
        </TooltipHeader>
        <TooltipBody>
          <TooltipTitle>
            <TooltipMarker style={{ backgroundColor: seriesData?.color }} />
            {seriesData?.name}
          </TooltipTitle>
          <TooltipContent>
            {rawData?.min !== null && (
              <p>
                min:{' '}
                <BoldSpan>
                  {i18n.number(rawData.min, numberFormatOptions(decimal))} {switchUnit}
                </BoldSpan>
              </p>
            )}
            <p>
              avg:{' '}
              <BoldSpan>
                {rawData?.value ? i18n.number(rawData.value, numberFormatOptions(decimal)) : 0} {switchUnit}
              </BoldSpan>
            </p>
            {rawData?.max !== null && (
              <p>
                max:{' '}
                <BoldSpan>
                  {i18n.number(rawData.max, numberFormatOptions(decimal))} {switchUnit}
                </BoldSpan>
              </p>
            )}
          </TooltipContent>
        </TooltipBody>
      </div>
    </MuiThemeProvider>
  )
}

export default CustomTooltip
