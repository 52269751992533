import React, { FC } from 'react'

import { StyledContainer, StyledLayoutHeader, StyledLogo, StyledTitle, StyledWaveWrapper, StyledWrapper } from './UnauthorizedPageLayout.styled'
import { SSFWave } from '../../../Shared/components/icons'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface IUnauthorizedPageLayoutProps {
  title?: string
  children: React.ReactNode
}

export const UnauthorizedPageLayout: FC<IUnauthorizedPageLayoutProps> = ({ title, children }) => {
  const { i18n } = useI18nContext()
  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledLayoutHeader hasTitle={!!title}>
          <StyledLogo color="#182D59" />
          {title && <StyledTitle>{i18n.text(title)}</StyledTitle>}
        </StyledLayoutHeader>
        {children}
      </StyledWrapper>
      <StyledWaveWrapper>
        <SSFWave />
      </StyledWaveWrapper>
    </StyledContainer>
  )
}
