import { DateTime } from 'luxon'

export const getDefaultQuickIntervals = () => [
  {
    label: 'oee.datetimepicker.predefined-ranges.last-week',
    start: DateTime.local().startOf('week').minus({ weeks: 1 }),
    end: DateTime.local().endOf('week').minus({ weeks: 1 }),
  },
  {
    label: 'oee.datetimepicker.predefined-ranges.yesterday',
    start: DateTime.local().startOf('day').minus({ days: 1 }),
    end: DateTime.local().endOf('day').minus({ days: 1 }),
  },
  {
    label: 'oee.datetimepicker.predefined-ranges.today',
    start: DateTime.local().startOf('day'),
    end: DateTime.local().endOf('day'),
  },
]

export const getStopsFilterIntervals = () => [
  {
    label: 'oee.datetimepicker.predefined-ranges.today',
    start: DateTime.local().startOf('day'),
    end: DateTime.local(),
  },
  {
    label: 'oee.datetimepicker.predefined-ranges.yesterday',
    start: DateTime.local().startOf('day').minus({ days: 1 }),
    end: DateTime.local().endOf('day').minus({ days: 1 }),
  },
  {
    label: 'oee.datetimepicker.predefined-ranges.this-week',
    start: DateTime.local().startOf('week'),
    end: DateTime.local().endOf('week'),
  },
  {
    label: 'oee.datetimepicker.predefined-ranges.last-week',
    start: DateTime.local().startOf('week').minus({ weeks: 1 }),
    end: DateTime.local().endOf('week').minus({ weeks: 1 }),
  },
  {
    label: 'oee.datetimepicker.predefined-ranges.this-month',
    start: DateTime.local().startOf('month'),
    end: DateTime.local().endOf('month'),
  },
  {
    label: 'oee.datetimepicker.predefined-ranges.last-month',
    start: DateTime.local().startOf('month').minus({ months: 1 }),
    end: DateTime.local().endOf('month').minus({ months: 1 }),
  },
]
