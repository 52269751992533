import { TableCellProps } from '@mui/material'

import { AssetStatus } from '../../Shared/components/AssetCard/utils/types'
import { ID } from '../../Shared/types/types'
import {
  OeeAssetFragment,
  OeeProductionStop,
  OffAsset,
  OfflineAsset,
  RunningAsset,
  StoppedAsset,
} from '../../Shared/graphql/codegen'

export type Machine = {
  name: string
  id: string
  payload?: OeeAssetFragment['payload']
  assetColor?: string
  enoceanPulseCounterSensorLocations?: OeeAssetFragment['enoceanPulseCounterSensorLocations']
}

type CriticalStopTime = {
  id: ID
  criticalStopTimeSeconds: number
}

export type MachineWithCriticalStopTime = {
  id: string
  name: string
  enoceanPulseCounterSensorLocations: CriticalStopTime
}

export type Group = {
  name: string
  id: string
  machines: Machine[]
}

export type AssetProductionSpeedData = {
  x: string
  y: number | null
}

export type AssetProductionSpeed = {
  name: string
  data: AssetProductionSpeedData[]
}

export enum AssetTreeMode {
  SINGLE_SELECT = 'singleSelect',
  MULTI_SELECT = 'multiSelect',
}

export type ProductionAssetStatus = {
  off: OffAsset[]
  offline: OfflineAsset[]
  running: RunningAsset[]
  stopped: StoppedAsset[]
}

export type OeeAssetColors = {
  background?: string
  text?: string
  chip?: {
    background?: string
    text?: string
    icon?: string
    border?: string
  }
}

export interface OeeRunningAsset extends RunningAsset {
  id: string
  status: AssetStatus
}

export interface OeeStoppedAsset extends StoppedAsset {
  id: string
  status: AssetStatus
}

export interface OeeOffAsset extends OffAsset {
  id: string
  status: AssetStatus
}

export interface OeeOfflineAsset extends OfflineAsset {
  id: string
  status: AssetStatus
}

export type OeeAsset = OeeRunningAsset | OeeStoppedAsset | OeeOffAsset | OeeOfflineAsset

export type AssetStopsCategorization = {
  unexplainedStops: OeeProductionStop[]
  explainedStops: OeeProductionStop[]
}

export type AssetStop = {
  id: string
  assetName: string
  startTime: string
  endTime: string | null
  duration: string | null
  feedback: string | null | undefined
  reason: string
}

export type TableHeadCell = {
  id: string
  label: string
  align: TableCellProps['align']
  unit?: string
}

export type StopReasonSummary = {
  reasonId: string
  reasonName: string
  totalDurationInMinutes: number
  count: number
  averageDurationInMinutes: number
}

export type AssetWithAssignedStopReasons = {
  locationId: string
  customerProductionStopReasonIds: string[]
}
