//creates draggable list of configurated modules
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { DraggableListItem } from './ModuleRow'
import { DraggableListProps } from './types'

const DraggableModuleList = ({ items, onDragEnd, reportId, handleChange }: DraggableListProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((item, index) => (
              <DraggableListItem
                key={item.id}
                index={index}
                item={item}
                reportId={reportId}
                moduleId={item.id || 'new'}
                enabled={item.enabled}
                handleChange={handleChange}
                numModules={items.length}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableModuleList
