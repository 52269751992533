import IconButton from '@mui/material/IconButton'
import React, { ChangeEvent, FC, useState } from 'react'
import { Box, TextField, Typography, useTheme } from '@mui/material'
import { Cancel, Edit, Save } from '@mui/icons-material'

import { ID } from '../../../Shared/types/types'
import { MachineWithCriticalStopTime } from '../../types/Assets.types'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../Shared/contexts/ToastContext'
import { useUpdateOeeCriticalStopTimeMutation } from '../../../Shared/graphql/codegen'

type AssetGroupProps = {
  machine: MachineWithCriticalStopTime
  onSave: () => void
}
const CriticalStopTimeAssetGroupRow: FC<AssetGroupProps> = ({ machine, onSave }) => {
  const theme = useTheme()
  const { showToast } = useToastContext()
  const { i18n } = useI18nContext()
  const [newStopTimeInSeconds, setNewStopTimeInSeconds] = useState<number>(
    machine.enoceanPulseCounterSensorLocations.criticalStopTimeSeconds
  )
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const [updateMachineCriticalStopTime] = useUpdateOeeCriticalStopTimeMutation({
    awaitRefetchQueries: true,
    onCompleted: async response => {
      onSave()
      setIsSaving(false)
      setError(null)
      setIsEditing(false)
      showToast(i18n.text('oee.critical-stop-time.update.success'), 'success', 'Success!')
    },
    onError: error => {
      setIsSaving(false)
      setError(error.message)
      showToast(i18n.text('oee.critical-stop-time.update.error'), 'error', 'Error')
    },
  })

  const isValidValue = (value: number): boolean => !(!value || isNaN(value) || value < 0)

  const handleActivateEditMode = () => {
    setIsEditing(true)
  }

  const handleCancelEditMode = () => {
    setNewStopTimeInSeconds(machine.enoceanPulseCounterSensorLocations.criticalStopTimeSeconds)
    setIsEditing(!isEditing)
  }

  const handleSaveClick = async () => {
    if (!isValidValue(newStopTimeInSeconds)) return

    setIsSaving(true)

    await updateMachineCriticalStopTime({
      variables: {
        id: machine.enoceanPulseCounterSensorLocations.id as ID,
        criticalStopTimeSeconds: newStopTimeInSeconds,
      },
    })
  }

  const handleCriticalStopTimeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewStopTimeInSeconds(Number(event.target.value))
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'minmax(min-content, 1fr) 1fr',
        alignItems: 'center',
        gap: '1rem',
      }}
    >
      <Typography sx={{ wordWrap: 'break-word' }}>{machine.name}:</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          sx={{ width: '14ch', color: theme.palette.SFIGreyLight[950] }}
          disabled={!isEditing || isSaving}
          id={`machine-${machine.id}`}
          value={newStopTimeInSeconds}
          onChange={handleCriticalStopTimeChange}
          InputProps={{ endAdornment: i18n.text('oee.production-speed.units.seconds') }}
          inputProps={{ min: 0 }}
          type="number"
          variant="standard"
          size="small"
          error={!isValidValue(newStopTimeInSeconds) || !!error}
          helperText={error || ''}
        />
        {!isEditing ? (
          <IconButton
            onClick={handleActivateEditMode}
            sx={{
              color: theme.palette.SFIGreyLight[950],
              '&:disabled': { color: theme.palette.SFIGreyLight[500] },
            }}
          >
            <Edit
              sx={{
                fontSize: '1.25rem',
              }}
            />
          </IconButton>
        ) : (
          <>
            <IconButton
              onClick={handleSaveClick}
              disabled={isSaving}
              sx={{
                color: theme.palette.SFIGreyLight[950],
                '&:disabled': { color: theme.palette.SFIGreyLight[500] },
              }}
            >
              <Save
                sx={{
                  fontSize: '1.25rem',
                }}
              />
            </IconButton>
            <IconButton
              onClick={handleCancelEditMode}
              disabled={isSaving}
              sx={{
                color: theme.palette.SFIGreyLight[950],
                '&:disabled': { color: theme.palette.SFIGreyLight[500] },
              }}
            >
              <Cancel
                sx={{
                  fontSize: '1.25rem',
                }}
              />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  )
}

export default CriticalStopTimeAssetGroupRow
