import { CommodityPrice, CommodityType_Enum } from '../graphql/codegen'
const DEFAULT_PRICES = [
  {
    commodity: CommodityType_Enum.ElectricEnergy,
    price: 0.075,
  },
  {
    commodity: CommodityType_Enum.NaturalGas,
    price: 0.0705,
  },
  {
    commodity: CommodityType_Enum.Water,
    price: 1.00,
  },
]

export function getLatestCommodityPrices(commodityPrices: CommodityPrice[] | undefined) {
  if (commodityPrices && commodityPrices.length > 0) {
    return {
      electricity: getCommodityPrice(CommodityType_Enum.ElectricEnergy, commodityPrices) || DEFAULT_PRICES[0],
      naturalGas: getCommodityPrice(CommodityType_Enum.NaturalGas, commodityPrices) || DEFAULT_PRICES[1],
      water: getCommodityPrice(CommodityType_Enum.Water, commodityPrices) || DEFAULT_PRICES[2],
    }
  }

  return {
    electricity: DEFAULT_PRICES[0],
    naturalGas: DEFAULT_PRICES[1],
    water: DEFAULT_PRICES[2],
  }
}

export function getCommodityPrice(commodity: CommodityType_Enum, commodityPrices: CommodityPrice[]) {
  const latestPrice = commodityPrices.find((commodityPrice) =>
      commodityPrice.to === null && commodityPrice.commodityType === commodity
  )
  if (latestPrice) {
    return {
      commodity,
      price: latestPrice.value,
    }
  }
}
