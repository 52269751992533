import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  stepClasses,
  stepConnectorClasses,
  styled,
} from '@mui/material'
import { useMemo } from 'react'

import IconFrame from '../../Shared/components/icons/IconFrame'
import { STAGES } from '../utils/constants'
import { SFIndigoMuiTheme as theme } from '../../Shared/theme/MuiThemeProvider'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'
import { useProgressContext } from '../context/ProgressContext'

interface TitleProps {
  active: boolean
}

export default function ProgressBar() {
  const { i18n } = useI18nContext()
  const { currentStage } = useProgressContext()

  const activeStep = useMemo(() => {
    return STAGES.findIndex(({ key }) => key === currentStage)
  }, [currentStage])

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<StyledConnector />}
      sx={{ margin: '1.5rem 0' }}
    >
      {STAGES.map(({ key, title, subtitle, icon }, index) => {
        const active = index === activeStep
        return (
          <Step key={key}>
            <StepLabel
              StepIconComponent={() => (
                <IconFrame
                  active={active}
                  Icon={icon}
                  sx={{
                    position: 'relative',
                    zIndex: 2,
                    backgroundColor: theme.palette.SFIBase.white,
                  }}
                />
              )}
              sx={{ [`& .${stepClasses.alternativeLabel}`]: { color: theme.palette.SFIGreyLight[700] } }}
            >
              <Title active={active}>{i18n.text(title)}</Title>
              <Subtitle active={active}>{i18n.text(subtitle)}</Subtitle>
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: '1.25rem',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.SFIGreyLight[700],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.SFIGreyLight[700],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: '0.1875rem',
    borderRadius: '0.0625rem',
    borderColor: theme.palette.SFIGreyLight[200],
  },
}))

const Title = styled((props: TitleProps) => {
  const { active, ...other } = props
  return <Typography {...other} />
})(({ active }) => ({
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1.25rem',
  marginTop: '0.75rem',
  color: active ? theme.palette.SFIBrand[900] : '',
}))

const Subtitle = styled((props: TitleProps) => {
  const { active, ...other } = props
  return <Typography {...other} />
})(({ active }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  color: active ? theme.palette.SFIBrand[700] : '',
}))
