import ReactApexChart from 'react-apexcharts'
import { FC, useCallback, useMemo } from 'react'
import { Typography, useTheme } from '@mui/material'

import MUILoader from '../../../Shared/components/MUIComponents/Loader'
import { getCurrencyFormatter } from '../../../Shared/utils/formatCurrency'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useEnergyByUnitChartOptions } from './useEnergyByUnitChartOptions'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface EnergyByUnitChartProps {
  series: ApexAxisChartSeries
  isLoading: boolean
  showingCosts: boolean
}
export const EnergyByUnitChart: FC<EnergyByUnitChartProps> = ({ series, isLoading, showingCosts }) => {
  const { i18n, userLocale } = useI18nContext()
  const theme = useTheme()
  const { customerCurrency } = useCurrentUser()
  const currencyFormatter = useMemo(() => getCurrencyFormatter(userLocale, customerCurrency.isocode), [userLocale])

  const totalsFormatter = useCallback(
    v => (showingCosts ? currencyFormatter(v, 2) : v ? i18n.number(v, { maximumFractionDigits: 2 }) : '0'),
    [showingCosts, i18n]
  )

  const options = useEnergyByUnitChartOptions(totalsFormatter, theme)

  if (isLoading) {
    return <MUILoader margin={'0'} />
  }

  if (!series.length) {
    return <Typography>{i18n.text('ebu.chart.no-data')}</Typography>
  }
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={450}
    />
  )
}
