import * as Sentry from '@sentry/react'

import { showToast } from '../actions/appActions'

export function handleGenericError(
  actionSymbol: $TSFixMe,
  error: $TSFixMe,
  dispatch: $TSFixMeDispatch,
  message?: string
) {
  const msg = message ?? `We encountered an error: ${error}`
  showToast('Uh-oh', msg, 'error')(dispatch)
  Sentry.captureException(error)
  dispatch({
    type: actionSymbol,
    payload: { error },
    error: true,
  })
}
