import React, { FC } from 'react'
import { Box, Typography, useTheme } from '@mui/material'

import SemiDonut from '../../../Shared/components/SemiDonut/SemiDonut'
import { StyledTooltip } from '../../../Shared/components/MUIComponents/Tooltip'
import { WarningIcon } from '../../../Shared/components/icons'

type AdditionalMetric = {
  label: string
  value: number | string
}

type OEEMetricItemProps = {
  title: string
  value: number
  color?: string
  additionalMetrics?: AdditionalMetric[]
  warningMessage?: string
}

const OEEMetricItem: FC<OEEMetricItemProps> = ({ title, value, color, additionalMetrics, warningMessage }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'grid',
        justifyContent: 'center',
        padding: '1.25rem',
        borderRadius: '0.75rem',
        border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      }}
    >
      <Typography
        sx={{
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '1.25rem',
          lineHeight: '1.875rem',
          marginBottom: '1.25rem',
        }}
      >
        {title}
        {warningMessage && (
          <StyledTooltip
            title={warningMessage}
            sx={{ whiteSpace: 'pre-line' }}
          >
            <WarningIcon sx={{ width: '1.25rem', height: '1.25rem', color: theme.palette.SFIWarning[500] }} />
          </StyledTooltip>
        )}
      </Typography>
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <SemiDonut
          percentage={Math.min(100, value)}
          color={color}
        />
      </Box>
      {additionalMetrics && (
        <Box sx={{ display: 'grid', rowGap: '0.5rem', padding: '1.5rem 0 1.1rem' }}>
          {additionalMetrics.map((metric, index) => (
            <Box
              key={`oee-metric-${index}`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                fontSize: '0.75rem',
                lineHeight: '1.125rem',
              }}
            >
              {metric.label}:{' '}
              <Typography sx={{ fontSize: '0.75rem', lineHeight: '1.125rem', fontWeight: 500 }}>
                {metric.value}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default OEEMetricItem
