import React from 'react'
import { Result } from 'antd'

type Props = {
  title?: React.ReactNode
  explanation?: React.ReactNode
}

export const NotFoundResult = ({ title, explanation }: Props) => {
  return (
    <Result
      status="404"
      title={title ?? 'Not found'}
      subTitle={explanation}
    />
  )
}
