import { Box, Link, Menu, useTheme } from '@mui/material'
import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import { AlertsListMini } from '../AlertsListMini/AlertsListMini'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { trackCheckAllAlertsEvent } from '../../utils/trackingEvents'

interface AlertsMenuProps {
  handleMenuClose: () => void
  menuAnchorEl: null | HTMLElement
}

const AlertsMenu: FC<AlertsMenuProps> = ({ menuAnchorEl, handleMenuClose }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { sendEvent } = useAnalytics()

  const handleAlertsLinkClick = () => {
    trackCheckAllAlertsEvent(sendEvent)
  }

  return (
    <Menu
      anchorEl={menuAnchorEl}
      open={!!menuAnchorEl}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 3,
        sx: {
          maxWidth: theme.breakpoints.values.md,
          maxHeight: '75vh',
          overflowY: 'auto',
          p: 1,
          mt: 3,
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Link
        component={RouterLink}
        to="/alerts"
        onClick={handleAlertsLinkClick}
      >
        {i18n.text('router.multi.alerts-popup')}
      </Link>
      <Box>
        <AlertsListMini />
      </Box>
    </Menu>
  )
}

export default AlertsMenu
