import React, { FC } from 'react'
import { ButtonGroup } from '@mui/material'

import { DataIntervalMode } from '../../types/Time.types'
import { OutlinedStyledButton } from '../../../Shared/components/MUIComponents/update/OutlinedStyledButton'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type DataIntervalModeSwitchProps = {
  hasShiftData: boolean
  dataIntervalMode: DataIntervalMode
  onIntervalChange: (mode: DataIntervalMode) => void
}

const DataIntervalModeSwitch: FC<DataIntervalModeSwitchProps> = ({
  hasShiftData,
  dataIntervalMode,
  onIntervalChange,
}) => {
  const { i18n } = useI18nContext()

  return (
    <ButtonGroup>
      <OutlinedStyledButton
        isActive={dataIntervalMode === DataIntervalMode.DAY}
        onClick={() => {
          onIntervalChange(DataIntervalMode.DAY)
        }}
      >
        {i18n.text('oee.machine-view.button-switch.day-oee')}
      </OutlinedStyledButton>
      <OutlinedStyledButton
        isActive={dataIntervalMode === DataIntervalMode.SHIFT}
        disabled={!hasShiftData}
        onClick={() => {
          onIntervalChange(DataIntervalMode.SHIFT)
        }}
      >
        {i18n.text('oee.machine-view.button-switch.shift-oee')}
      </OutlinedStyledButton>
    </ButtonGroup>
  )
}

export default DataIntervalModeSwitch
