import { useCallback } from 'react'

type CSVExportOptions<T> = {
  data: T[]
  headers?: (keyof T)[]
  filename?: string
  decimalSeparator?: string
  valueSeparator?: string
}

/**
 * Custom hook for exporting data to a CSV file.
 *
 * This hook generates a CSV file from an array of objects and provides a handler function to trigger the download.
 * It uses the specified headers to define the CSV columns and allows customization of the output filename.
 *
 * @template T - The generic type of the objects in the data array.
 *
 * @returns {(options: CSVExportOptions<T>) => void} - A function that takes export options and triggers the download of the CSV file.
 *
 * @example
 * // Example usage:
 * const data = [
 *   { id: 1, name: 'John Doe', email: 'john@example.com' },
 *   { id: 2, name: 'Jane Doe', email: 'jane@example.com' }
 * ];
 * const headers = ['id', 'name', 'email'];
 * const downloadCSV = useCSVExport<User>();
 *
 * // Trigger download
 * downloadCSV({ data, headers, filename: 'users.csv' });
 */
function useCSVExport<T>() {
  const createCSVContent = useCallback(
    ({ data, headers, decimalSeparator = '.', valueSeparator = ',' }: CSVExportOptions<T>) => {
      if (!data || data.length === 0) return ''

      const csvHeaders = headers ? headers : (Object.keys(data[0]) as (keyof T)[])

      const csvRows = data.map(csvRow =>
        csvHeaders
          .map(csvHeader => {
            const value = csvRow[csvHeader] ?? ''
            const formattedValue = typeof value === 'number' ? value.toString().replace('.', decimalSeparator) : value
            return `"${String(formattedValue).replace(/"/g, '""')}"`
          })
          .join(valueSeparator)
      )

      return [csvHeaders.join(valueSeparator), ...csvRows].join('\r\n')
    },
    []
  )

  return useCallback(
    ({ filename = 'data.csv', ...options }: CSVExportOptions<T>) => {
      const csvContent = createCSVContent(options)

      if (!csvContent) {
        console.error('No data to export.')
        return
      }

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.visibility = 'hidden'
      a.href = url
      a.download = filename
      a.click()

      URL.revokeObjectURL(url)
    },
    [createCSVContent]
  )
}

export default useCSVExport
