import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionSummary,
  Box,
  Typography,
  styled,
} from '@mui/material'
import { AccordionProps, AccordionSummaryProps } from '@mui/material'
import { ArrowDropDownOutlined, FactoryOutlined, PrecisionManufacturingOutlined } from '@mui/icons-material'
import { FC, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router'

import ProbeComponent from './ProbeComponent'
import { PdmOverviewFragment } from '../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const StyledAccordion = styled(Accordion)<AccordionProps>(({ theme }) => ({
  border: 'none',
  '&:before': {
    display: 'none',
  },
})) as typeof Accordion

interface StyledAccordionSummaryProps extends AccordionSummaryProps {
  highlight?: boolean
}

const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: propName => propName !== 'highlight',
})<StyledAccordionSummaryProps>(({ theme, highlight }) => ({
  marginTop: 3,
  ...(highlight && {
    backgroundColor: theme.palette.SFIBrand[200],
    borderLeft: `solid 3px ${theme.palette.SFIBrand[900]}`,
    color: theme.palette.SFIBrand[900],
  }),
  '&:hover': {
    backgroundColor: theme.palette.SFIBrand[200],
    color: theme.palette.SFIBrand[900],
  },
}))

const StyledAccordionDetails = styled(AccordionDetails)<AccordionDetailsProps>(({ theme }) => ({
  borderLeft: `dashed 3px ${theme.palette.SFIBrand[900]}`,
  marginLeft: '1.55rem',
  padding: 0,
}))

type PdMAssetTreeProps = {
  groups: PdmOverviewFragment['groups']
}

export type Machine = PdmOverviewFragment['groups'][number]['machines'][number]
/**
 * @deprecated This component will deprecated and will be removed later this quarter and will be replaced with src/Shared/components/MUIComponents/update/AssetTree/
 * @param groups - The groups of machines and probes.
 * @returns The PdMAssetTree component.
 */
const PdMAssetTree: FC<PdMAssetTreeProps> = ({ groups }) => {
  const [expanded, setExpanded] = useState<string[]>([])
  const { pathname } = useLocation()
  const machineId = pathname.split('/')[3]
  const probeId = pathname.split('/')[5]
  const { i18n } = useI18nContext()
  const history = useHistory()

  const selected = probeId || machineId

  const isExpanded = (key: string) => expanded.includes(key)
  const toggleExpanded = (key: string) => {
    if (isExpanded(key)) {
      return setExpanded(prev => [...prev.filter(e => e !== key)])
    }
    return setExpanded(prev => [...prev, key])
  }

  const containsSelectedAsset = useCallback(
    (machine: Machine) => {
      if (!selected) return false
      if (machine.id === selected) return true
      if (machine.probes && machine.probes.length) return machine.probes.some(probe => probe.id === selected)
    },
    [selected]
  )

  useEffect(() => {
    groups.forEach(group =>
      group.machines.forEach(machine => containsSelectedAsset(machine) && setExpanded(prev => [...prev, machine.id]))
    )
  }, [selected, groups, containsSelectedAsset])

  const handleMachineClick = (machine: Machine) => {
    if (machine.probes?.length === 1 && !isExpanded(machine.id)) {
      const [probe] = machine.probes
      history.push(`/predictive-maintenance/machines/${machine.id}/probes/${probe.id}/graphs`)
    } else {
      toggleExpanded(machine.id)
    }
  }

  return (
    <>
      <Box>
        {groups
          .filter(group =>
            group.machines.some(machine => machine.probes.some(probe => probe.sensors && probe.sensors.length > 0))
          )
          .map(group => (
            <Box
              sx={{ marginBottom: '2rem', minWidth: '300px' }}
              key={group.id}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <FactoryOutlined sx={{ marginRight: 1, fontSize: '1.75em' }} />
                <Typography
                  variant="h5"
                  fontWeight={400}
                  sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  data-testid="tree-group-name"
                >
                  {group.name}
                </Typography>
              </Box>
              {group.machines
                .filter(machine => machine.probes.some(probe => probe.sensors && probe.sensors.length > 0)) // Hiding assets without assign sensor locations.
                .map(machine => (
                  <StyledAccordion
                    disableGutters
                    elevation={0}
                    expanded={isExpanded(machine.id)}
                    onClick={() => toggleExpanded(machine.id)}
                    key={machine.id}
                    data-testid="tree-machine-container"
                  >
                    <StyledAccordionSummary
                      title={machine.name}
                      expandIcon={<ArrowDropDownOutlined />}
                      highlight={containsSelectedAsset(machine)}
                      onClick={() => handleMachineClick(machine)}
                    >
                      <Box display="flex">
                        <PrecisionManufacturingOutlined />
                        <Typography
                          variant="body2"
                          data-testid="tree-machine-name"
                          sx={{
                            fontSize: '1em',
                            fontWeight: '500',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '250px',
                            paddingLeft: '1rem',
                          }}
                        >
                          {machine.name}
                        </Typography>
                      </Box>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails test-id="tree-machine-details">
                      {machine.probes
                        .filter(probe => probe.sensors && probe.sensors.length > 0) // Hiding assets without assign sensor locations.
                        .map(probe => {
                          return (
                            <Link
                              key={probe.id}
                              to={`/predictive-maintenance/machines/${machine.id}/probes/${probe.id}/graphs`}
                              style={{ textDecoration: 'none' }}
                            >
                              <ProbeComponent
                                selected={selected}
                                probe={probe}
                              />
                            </Link>
                          )
                        })}
                    </StyledAccordionDetails>
                  </StyledAccordion>
                ))}
            </Box>
          ))}
      </Box>
    </>
  )
}

export default PdMAssetTree
