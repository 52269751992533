import Button from '@sensorfactdev/nucleus/Button'
import ButtonLabel from '@sensorfactdev/nucleus/ButtonLabel'
import Icon from '@sensorfactdev/nucleus/Icon'
import SortableCollapsableTable from '@sensorfactdev/nucleus/SortableCollapsableTable'
import Toggle from '@sensorfactdev/nucleus/Toggle'
import styled from 'styled-components'
import React, { Fragment, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { is, path, pathOr } from 'ramda'

import Clearfix from '../../components/Clearfix'
import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import { ID } from '../../../Shared/types/types'
import { Recipient } from '../../components/Recipient/types'
import { Rule } from './types'
import { createNotificationPreferenceInput, getSentencesFromRule, isPhoneNumber, normalizeRule } from './utils'
import { normalizeStandbyAlert } from './standby/utils'
import { themeColors } from '../../../Shared/utils'
import {
  useCreateRuleMutation,
  useDeleteRuleMutation,
  useDeleteStandbyAlertMutation,
  useGetRulesQuery,
  useGetStandbyAlertsQuery,
  useUpdateRuleMutation,
} from '../../../Shared/graphql/codegen'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const LocalWrapper = styled.div`
  text-align: left;
`
const WrapperInner = styled.div``

const StyledToggle = styled(Toggle)`
  float: left;
  margin-right: 1em;
`
const ButtonSpacer = styled.div`
  width: 40px;
  margin-right: 0.5em;
`
const ClearButton = styled(Button)`
  background-color: transparent;

  &:hover {
    background-color: ${(themeColors as $TSFixMe).secondaryLight};
  }

  & div:nth-of-type(1) {
    filter: invert(31%) sepia(1%) saturate(19%) hue-rotate(328deg) brightness(99%) contrast(86%);
  }
`
const StyledSubheading = styled.small`
  && {
    color: ${themeColors.grey};
  }
`
const ButtonWrapper = styled.div`
  float: right;
  display: flex;
  flex-direction: row;

  & > div {
    margin-right: 0.5em;
  }
`
const NewWrapper = styled.div`
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Section = styled.div`
  margin-top: 4em;
`

const ToolsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`
const preventEventPropagation = (e: React.MouseEvent) => e.stopPropagation()

const Rules = ({ history }: RouteComponentProps): JSX.Element => {
  const { i18n } = useI18nContext()
  const { selectedCustomer } = useCurrentUser()

  const { data, loading: rulesPending, refetch: refetchGetRules } = useGetRulesQuery()
  const {
    data: standbyAlertsData,
    loading: standbyAlertsPending,
    refetch: refetchGetStandbyAlerts,
  } = useGetStandbyAlertsQuery()
  const { sendEvent } = useAnalytics()

  const [createRuleMutation] = useCreateRuleMutation()
  const [deleteRuleMutation] = useDeleteRuleMutation()
  const [updateRuleMutation] = useUpdateRuleMutation()

  const [deleteStandbyAlertMutation] = useDeleteStandbyAlertMutation()

  useEffect(() => {
    refetchGetRules()
    refetchGetStandbyAlerts()
  }, [])
  async function duplicateRule(rule: Rule) {
    const _notificationPreferences = rule.notificationPreferences.map(createNotificationPreferenceInput)

    await createRuleMutation({
      variables: {
        customer: selectedCustomer.id as ID,
        name: rule.name,
        type: rule.type,
        payload: rule.payload,
        notificationPreferences: _notificationPreferences,
      },
    })
    refetchGetRules()
  }

  async function toggleMute(rule: Rule) {
    rule.notificationPreferences[0].muted = !rule.notificationPreferences[0].muted
    const _notificationPreferences = rule.notificationPreferences.map(createNotificationPreferenceInput)

    await updateRuleMutation({
      variables: {
        id: rule.id,
        name: rule.name,
        type: rule.type,
        payload: rule.payload,
        notificationPreferences: _notificationPreferences,
      },
    })
    refetchGetRules()
  }

  if (data == null || rulesPending || standbyAlertsPending) {
    return <div>{i18n.text('app.loading')}</div>
  }

  const gqlRules = data?.myOrg?.alertRules ?? []
  const rules = gqlRules.map(normalizeRule)

  const gqlStandbyAlerts = standbyAlertsData?.myOrg?.assets.flatMap(asset => asset.standbyAlertRules) ?? []
  const allStandbyAlerts = gqlStandbyAlerts.map(normalizeStandbyAlert)

  const enabledStandbyAlerts = allStandbyAlerts.filter(standbyAlert => standbyAlert.enabled)

  const cols = [
    { label: i18n.text('rules.headers.subject'), key: 'name' },
    { label: i18n.text('rules.headers.problem'), key: 'problem' },
    { label: i18n.text('rules.headers.message'), key: 'message' },
    { label: i18n.text('rules.headers.mute'), key: 'mute' },
    { label: '', key: 'actions' },
  ]

  const standbyCols = [
    { label: i18n.text('rules.headers.subject'), key: 'name' },
    { label: i18n.text('rules.headers.message'), key: 'message' },
    { label: '', key: 'actions' },
  ]

  const formattedRules = rules.map((rule, index) => ({
    id: rule.id,
    name: (
      <ul key={`assets-${rule.id}`}>
        {rule.assets.map(({ name }) => (
          <li key={`${rule.id}-asset-${name}`}>{name}</li>
        ))}
      </ul>
    ),
    problem: (
      <ul key={`problems-${rule.id}`}>
        {getSentencesFromRule(rule, i18n).map((problem, index) => (
          <li key={`${rule.id}-${problem}-${index}`}>{problem}</li>
        ))}
      </ul>
    ),
    message: path(['notificationPreferences', 0, 'message'], rule),
    mute: (
      <ToolsWrapper onClick={preventEventPropagation}>
        <div>
          {/* Disallow updating an invalid rule */}
          {rule.payload.thresholdsV2.length === 0 || is(Array, rule.payload.thresholdsV2[0].asset) ? (
            <span>⚠️</span>
          ) : (
            <StyledToggle
              id={`mute-rule-button-${index}`}
              onChange={() => toggleMute(rule)}
              checked={!pathOr(false, ['notificationPreferences', 0, 'muted'], rule)}
            />
          )}
        </div>
        <Clearfix />
      </ToolsWrapper>
    ),
    actions: (
      <ButtonWrapper>
        <div>
          <ClearButton
            id={`edit-rule-button-${index}`}
            tooltip={i18n.text('rules.tooltip.edit-rule')}
            onClick={() => {
              history.push(`/alerts/rules/${rule.id}`)
            }}
          >
            <Icon
              icon="create"
              size="medium"
            />
          </ClearButton>
        </div>
        <div>
          <ClearButton
            id={`delete-rule-button-${index}`}
            tooltip={i18n.text('rules.tooltip.delete-rule')}
            onClick={async () => {
              // eslint-disable-next-line no-alert
              const confirmation = window.confirm(i18n.text('rules.delete.confirm'))
              if (confirmation) {
                await deleteRuleMutation({
                  variables: {
                    id: rule.id,
                  },
                })
                refetchGetRules()
              }
            }}
          >
            <Icon
              icon="delete"
              size="medium"
            />
          </ClearButton>
        </div>
        <div>
          <ClearButton
            id={`duplicate-rule-button-${index}`}
            tooltip={i18n.text('rules.tooltip.duplicate-rule')}
            onClick={() => duplicateRule(rule)}
          >
            <Icon
              icon="add"
              size="medium"
            />
          </ClearButton>
        </div>
      </ButtonWrapper>
    ),
  }))

  const jawbones = rules.map((rule, id) => [
    {
      id: rule.id,
      title: i18n.text('rules.jawbones.notification-days'),
      node: rule.payload?.intervals?.[0]?.alwaysOn ? (
        <p>{i18n.text('rules.time.always-on')}</p>
      ) : (
        <ul style={{ listStyle: 'none', padding: 0, marginBottom: '1em' }}>
          {pathOr([], ['payload', 'intervals'], rule).map(({ from, to }) => (
            <li key={(path as $TSFixMe)(['day'], from) + (path as $TSFixMe)(['time'], from)}>
              {path(['day'], from)}
              &nbsp;
              {path(['time'], from)}
              &nbsp; - &nbsp;
              {path(['day'], to)}
              &nbsp;
              {path(['time'], to)}
            </li>
          ))}
        </ul>
      ),
    },
    {
      id: rule.id,
      title: i18n.text('rules.jawbones.participants'),
      node: (
        <ul
          style={{
            listStyle: 'none',
            padding: 0,
            marginBottom: '1em',
            overflowX: 'auto',
          }}
        >
          {pathOr<Recipient[]>([], ['notificationPreferences', 0, 'recipients'], rule).map(recipient => {
            const destination = isPhoneNumber(recipient.destination)
              ? `${recipient.destination.dialCode} ${recipient.destination.phoneNumber}`
              : recipient.destination
            return (
              <li key={destination}>
                {recipient.name} ({destination})
              </li>
            )
          })}
        </ul>
      ),
    },
  ])

  const formattedStandbyAlerts = enabledStandbyAlerts.map((standbyAlert, index) => ({
    id: standbyAlert.id,
    name: standbyAlert.asset.name,
    message: standbyAlert.message,
    actions: (
      <ButtonWrapper>
        <div>
          <ClearButton
            id={`edit-standby-rule-button-${index}`}
            tooltip={i18n.text('rules.tooltip.edit-rule')}
            onClick={() => {
              history.push(`/alerts/rules/standby/${standbyAlert.id}`)
            }}
          >
            <Icon
              icon="create"
              size="medium"
            />
          </ClearButton>
        </div>
        <div>
          <ClearButton
            id={`delete-standby-rule-button-${index}`}
            tooltip={i18n.text('rules.tooltip.delete-rule')}
            onClick={async () => {
              // eslint-disable-next-line no-alert
              const confirmation = window.confirm(i18n.text('rules.delete.confirm'))
              if (confirmation) {
                await deleteStandbyAlertMutation({
                  variables: {
                    id: standbyAlert.id,
                  },
                })
                refetchGetStandbyAlerts()
              }
            }}
          >
            <Icon
              icon="delete"
              size="medium"
            />
          </ClearButton>
        </div>
        <ButtonSpacer />
      </ButtonWrapper>
    ),
  }))

  const standbyJawbones = enabledStandbyAlerts.map((standbyAlert, id) => [
    {
      id: standbyAlert.id,
      title: i18n.text('rules.jawbones.participants'),
      node: (
        <ul
          style={{
            listStyle: 'none',
            padding: 0,
            marginBottom: '1em',
            overflowX: 'auto',
          }}
        >
          {standbyAlert.recipients.map(recipient => {
            return (
              <li key={id}>
                {recipient.name} ({recipient.destination})
              </li>
            )
          })}
        </ul>
      ),
    },
  ])

  return (
    <LocalWrapper>
      {/* We only show the standby alerts section if we have added at least one standby alert already for this customer,
          this alert can also be disabled, in which case it is not shown in the list.
          This allows us to gradually roll out the feature to new customers only, while we scale up the infrastructure
          to handle all customers. */}
      {allStandbyAlerts.length > 0 && (
        <Fragment>
          <NewWrapper>
            <Button
              id="create-rule-button"
              size="large"
              appearance="primary"
              onClick={() => 
                history.push('/alerts/rules/standby/new')}
            >
              <Icon
                icon="add"
                size="large"
                appearance="primary"
              />
              <ButtonLabel>{i18n.text('alerts.standby.create')}</ButtonLabel>
            </Button>
          </NewWrapper>
          <h1>
            {i18n.text('alerts.standby.list.title')}
            <StyledSubheading>{i18n.text('alerts.standby.list.description')}</StyledSubheading>
          </h1>
          <WrapperInner id="standby-rules-table">
            {formattedStandbyAlerts.length > 0 ? (
              <SortableCollapsableTable
                i18n={i18n}
                cols={standbyCols}
                data={formattedStandbyAlerts}
                highlight={[]}
                jawbones={standbyJawbones}
              />
            ) : (
              <p>{i18n.text('alerts.standby.list.empty')}</p>
            )}
          </WrapperInner>
          <Section />
        </Fragment>
      )}
      <NewWrapper>
        <Button
          id="create-rule-button"
          size="large"
          appearance="primary"
          onClick={() => history.push('/alerts/rules/new')}
        >
          <Icon
            icon="add"
            size="large"
            appearance="primary"
          />
          <ButtonLabel>{i18n.text('rules.new')}</ButtonLabel>
        </Button>
      </NewWrapper>
      <h1>
        {i18n.text('notifications.rules.title')}
        <StyledSubheading>{i18n.text('notifications.rules.description')}</StyledSubheading>
      </h1>
      <WrapperInner id="rules-table">
        {formattedRules.length > 0 ? (
          <SortableCollapsableTable
            i18n={i18n}
            cols={cols}
            data={formattedRules}
            highlight={['message']}
            jawbones={jawbones}
          />
        ) : (
          <p>{i18n.text('notification.rules.list.empty')}</p>
        )}
      </WrapperInner>
    </LocalWrapper>
  )
}

export default Rules
