import type { History } from 'history'

import { ACCESS_TOKEN_RECEIVED, GET_ACCESS_TOKEN } from './constants'
import type { AuthContext } from '../../../Shared/contexts/AuthContext'
import type { RootState } from '../../store/configureStore'

function getAccessToken(identification: string, password: string, login: AuthContext['login'], history: History) {
  return (dispatch: $TSFixMeDispatch, store: RootState) => {
    dispatch({ type: GET_ACCESS_TOKEN, payload: { name: identification, password } })
    login(identification, password)
      .then(() => {
        dispatch({ type: ACCESS_TOKEN_RECEIVED, payload: { token: 'DEPRECATED' } })
        history.replace('/')
      })
      .catch(error => {
        dispatch({
          type: ACCESS_TOKEN_RECEIVED,
          payload: { error },
          error: true,
        })
      })
  }
}

const actionCreators = {
  getAccessToken,
}

export default actionCreators
