import { Box } from '@mui/material'
import { useMemo } from 'react'

import AssetTreeLayout from '../Layouts/AssetTreeLayout'
import Error from '../../../Shared/components/MUIComponents/Error'
import Loader from '../../../Shared/components/MUIComponents/Loader'
import QuickIntervalButtons from '../../components/QuickIntervalButtons/QuickIntervalButtons'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import ShiftComparisonTable from '../../components/ShiftComparisonTable/ShiftComparisonTable'
import useAssetTreeMode from '../../hooks/useAssetTreeMode'
import { AssetTreeMode } from '../../types/Assets.types'
import { ID } from '../../../Shared/types/types'
import {
  ProductionShiftComparison,
  useGetOeeProductionShiftComparisonQuery,
  useGetOeeSensorLocationsQuery,
} from '../../../Shared/graphql/codegen'
import { TableBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { getDefaultQuickIntervals } from '../../utils/quickIntervals'
import { getFormattedInterval } from '../../utils/helpers'
import { transformShiftComparisonData } from '../../utils/dataTransformations'
import { useAuthContext } from '../../../Shared/contexts/AuthContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

const ShiftComparison = () => {
  const { interval, selectedMachines } = useOEEContext()
  const { i18n } = useI18nContext()
  const { currentCustomer } = useAuthContext()

  useAssetTreeMode(AssetTreeMode.MULTI_SELECT)

  const formattedInterval = getFormattedInterval(interval?.start, interval?.end, i18n.locale.replace('_', '-'))

  const {
    data: locationIdsData,
    loading: locationIdsLoading,
    error: locationIdsError,
  } = useGetOeeSensorLocationsQuery({
    variables: {
      assetIds: selectedMachines,
    },
  })

  const locationIds = useMemo(
    () => locationIdsData?.myOrg?.assets.map(asset => asset.enoceanPulseCounterSensorLocations?.[0].id) || [],
    [locationIdsData?.myOrg?.assets]
  )

  const {
    data: shiftComparisonData,
    loading: shiftComparisonLoading,
    error: shiftComparisonError,
  } = useGetOeeProductionShiftComparisonQuery({
    variables: {
      customerId: currentCustomer?.id as ID,
      locationIds: locationIds as ID[],
      from: interval.start,
      to: interval.end,
    },
  })

  const tableData = useMemo(
    () =>
      shiftComparisonData?.productionShiftComparison
        ? shiftComparisonData?.productionShiftComparison?.map(productionShiftComparison =>
            transformShiftComparisonData(productionShiftComparison as ProductionShiftComparison)
          )
        : [],
    [shiftComparisonData?.productionShiftComparison]
  )

  const renderFallback = () => {
    if (locationIdsLoading || shiftComparisonLoading) return <Loader />
    if (locationIdsError || shiftComparisonError || !locationIdsData || !shiftComparisonData) return <Error />
    return null
  }

  return (
    <AssetTreeLayout>
      {locationIdsData && shiftComparisonData ? (
        <Box sx={{ display: 'grid', rowGap: '1rem' }}>
          <SectionHeader
            title={`${i18n.text('oee.shift-comparison.title')}`}
            chipContent={`${formattedInterval.start} - ${formattedInterval.end}`}
          >
            <QuickIntervalButtons quickIntervals={getDefaultQuickIntervals()} />
          </SectionHeader>
          <TableBoxWrapper>
            <ShiftComparisonTable shiftComparisonTableData={tableData} />
          </TableBoxWrapper>
        </Box>
      ) : (
        renderFallback()
      )}
    </AssetTreeLayout>
  )
}

export default ShiftComparison
