import { getIfObjectsEqual } from '../../Shared/utils/getIfObjectsEqual'

export function getObjectsWithoutId(objects: Record<string, string>[]) {
  return objects.filter(item => !item.id)
}

export function getObjectsOnlyInFirstArray(initialArray: Record<string, string>[], newArray: Record<string, string>[]) {
  return initialArray.reduce<string[]>((acc, item) => {
    const obj = newArray.find(newArrayObj => newArrayObj.id === item.id)
    if (!obj) {
      acc.push(item.id)
    }
    return acc
  }, [])
}

export function getUpdatedObjects(initialArray: Record<string, string>[], newArray: Record<string, string>[]) {
  return initialArray.reduce<Record<string, string>[]>((acc, item) => {
    const obj = newArray.find(newArrayObj => newArrayObj.id === item.id)
    if (obj && !getIfObjectsEqual(obj, item)) {
      acc.push(obj)
    }
    return acc
  }, [])
}
