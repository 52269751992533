import { createSvgIcon } from '@mui/material'

export const OnlineIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 10"
    fill="none"
  >
    <circle
      cx="5"
      cy="5"
      r="5"
      fill="#36BFFA"
    />
  </svg>,
  'online-dot-icon'
)
