import { DateTime } from 'luxon'

import { SensorInsights } from '../../Shared/graphql/codegen'

enum VelViolationStatus {
  NoData = -2,
  Offline = -1,
  NotConfigured = -3,
}

export default function calculateVibrationStatus(
  velViolation: number | null | undefined,
  lastMeasurement?: SensorInsights['lastMeasurement'],
  lastMessage?: SensorInsights['lastMessage'],
  referenceFrame?: number
): number {
  const lastMeasurementMillis = DateTime.fromISO(lastMeasurement?.time ?? '').toMillis()
  const lastMessageMillis = DateTime.fromISO(lastMessage?.time ?? '').toMillis()
  const referenceFrameMillis = DateTime.local().minus({ minutes: referenceFrame }).toMillis()

  if (!lastMessage && !lastMeasurement) {
    return VelViolationStatus.Offline
  }

  if (lastMeasurementMillis < referenceFrameMillis) {
    if (lastMessageMillis > referenceFrameMillis) {
      return VelViolationStatus.NoData // No data
    } else {
      return VelViolationStatus.Offline // Offline
    }
  } else {
    if (velViolation === null || velViolation === undefined) {
      return VelViolationStatus.NotConfigured // Not configured
    } else {
      return velViolation
    }
  }
}
