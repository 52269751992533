import React, { FC } from 'react'
import { Box, Button, Chip, Typography, styled, useTheme } from '@mui/material'
import { DateTime, Interval } from 'luxon'

import { ChartToolsDropdown, ChartToolsItem } from '../../Shared/components/MUIComponents/update/ChartToolsDropdown'
import { StyledToggleButtonGroup } from '../../Shared/components/MUIComponents/update/StyledToggleButtonGroup'
import { YAxisUnits } from '../types'
import { ZoomInIcon } from '../../Shared/components/icons/ZoomInIcon'
import { ZoomOutIcon } from '../../Shared/components/icons/ZoomOutIcon'
import { calculateZoom } from '../../Shared/utils/calculateZoom'
import { useElectricityContext } from '../context/ElectricityContext'

interface IGraphToolbarProps {
  titleText: string
  chipContent?: string
  switchUnitValues?: { value: YAxisUnits; title: string }[]
  toolbarItems?: ChartToolsItem[]
  switchUnit?: YAxisUnits
  onUnitChange?: (unit: YAxisUnits) => void
  i18nTitle?: string
  showZoomButtons?: boolean
}

const ZoomWrapper = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  width: 'fit-content',
  overflow: 'hidden',
  cursor: 'pointer',
  maxHeight: theme.spacing(5),
  fontSize: '0.875rem',
  color: theme.palette.SFIGreyLight[700],
  fontWeight: 600,
  background: theme.palette.SFIBase.white,
}))

const ZoomIconButton = styled(Button)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  padding: theme.spacing(1.25, 1.75),
  alignItems: 'center',
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '1.25rem',
  color: theme.palette.SFIGreyLight[700],
  borderRadius: 0,
  minWidth: 'auto',
}))

export const GraphToolbar: FC<IGraphToolbarProps> = ({
  titleText,
  switchUnit,
  switchUnitValues,
  toolbarItems,
  chipContent,
  onUnitChange,
  showZoomButtons,
}) => {
  const theme = useTheme()
  const { onDateRangeChange, selectedDateRange, compareDateRange, onCompareRangeChange } = useElectricityContext()

  const onZoom = (isZoomIn: boolean) => {
    const interval = Interval.fromDateTimes(selectedDateRange.startDate, selectedDateRange.endDate)
    const { min, max } = calculateZoom(interval, isZoomIn).xaxis
    const newStartDate = DateTime.fromMillis(min)
    const newEndDate = DateTime.fromMillis(max)

    if (compareDateRange.startDate && compareDateRange.endDate) {
      const compareInterval = Interval.fromDateTimes(compareDateRange.startDate, compareDateRange.endDate)
      const { min: compareMin, max: compareMax } = calculateZoom(compareInterval, isZoomIn).xaxis
      const newCompareStartDate = DateTime.fromMillis(compareMin)
      const newCompareEndDate = DateTime.fromMillis(compareMax)
      onCompareRangeChange({ startDate: newCompareStartDate, endDate: newCompareEndDate }, true)
    }
    onDateRangeChange({ startDate: newStartDate, endDate: newEndDate }, true)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: titleText ? 'space-between' : 'flex-end',
        alignItems: 'center',
        padding: theme => theme.spacing(0, 0, 2),
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {titleText && (
          <Typography
            variant="h6"
            fontWeight={600}
          >
            {titleText}
          </Typography>
        )}
        {chipContent && (
          <Chip
            sx={theme => ({
              backgroundColor: theme.palette.SFIGreyLight[50],
              border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
              borderRadius: '1rem',
              width: 'fit-content',
              marginLeft: '0.5rem',
              padding: '0 0.5rem',
              '& .MuiChip-label': {
                lineHeight: '1.4rem',
                padding: 0,
              },
            })}
            label={chipContent}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        {(toolbarItems || switchUnitValues) && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(2),
            }}
          >
            {toolbarItems && <ChartToolsDropdown items={toolbarItems} />}
            {switchUnitValues && switchUnit && onUnitChange && (
              <StyledToggleButtonGroup
                items={switchUnitValues}
                selectedItem={switchUnit}
                onChange={value => onUnitChange(value as YAxisUnits)}
              />
            )}
          </Box>
        )}
        {showZoomButtons && onZoom && (
          <ZoomWrapper>
            <ZoomIconButton
              sx={{ borderRight: 'solid 1px', borderColor: theme.palette.SFIGreyLight[300] }}
              onClick={() => onZoom(true)}
            >
              <ZoomInIcon fontSize="small" />
            </ZoomIconButton>
            <ZoomIconButton onClick={() => onZoom(false)}>
              <ZoomOutIcon fontSize="small" />
            </ZoomIconButton>
          </ZoomWrapper>
        )}
      </Box>
    </Box>
  )
}
