import { DateTime } from 'luxon'

import { DateRangePickerShortCut } from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker/types'

// Need to use customer shortcuts for the startOf second change;
// Without startOf second the compare intervals will be different when selecting a shortcut
export const dateTimePickerCustomShortcuts: (i18n: $I18FixMe) => DateRangePickerShortCut[] = i18n => {
  const today = DateTime.now()

  return [
    {
      label: i18n.text('graph.quick-filter.yesterday'),
      from: today.minus({ days: 1 }).startOf('day').startOf('second'),
      to: today.minus({ days: 1 }).endOf('day').startOf('second'),
    },
    {
      label: i18n.text('graph.quick-filter.day'),
      from: today.startOf('day').startOf('second'),
      to: today.endOf('day').startOf('second'),
    },
    {
      label: i18n.text('graph.quick-filter.last-week'),
      from: today.minus({ days: 7 }).startOf('week').startOf('second'),
      to: today.minus({ days: 7 }).endOf('week').startOf('second'),
    },
    {
      label: i18n.text('graph.quick-filter.week'),
      from: today.startOf('week').startOf('second'),
      to: today.endOf('week').startOf('second'),
    },
    {
      label: i18n.text('graph.quick-filter.last-month'),
      from: today.startOf('month').minus({ days: 1 }).startOf('month').startOf('second'),
      to: today.startOf('month').minus({ days: 1 }).endOf('month').startOf('second'),
    },
    {
      label: i18n.text('graph.quick-filter.month'),
      from: today.startOf('month').startOf('second'),
      to: today.endOf('month').startOf('second'),
    },
    {
      label: i18n.text('graph.quick-filter.last-year'),
      from: today.minus({ years: 1 }).startOf('year').startOf('second'),
      to: today.minus({ years: 1 }).endOf('year').startOf('second'),
    },
    {
      label: i18n.text('graph.quick-filter.year'),
      from: today.startOf('year').startOf('second'),
      to: today.endOf('year').startOf('second'),
    },
  ]
}
