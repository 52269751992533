import React, { ChangeEvent, FC, useMemo } from 'react'
import { Box, FormHelperText, MenuItem, SelectProps, TextFieldProps, outlinedInputClasses, styled } from '@mui/material'
import { Control, Controller, FieldErrorsImpl, UseFormWatch } from 'react-hook-form'

import {
  CompressedAirAlertAssetOption,
  CompressedAirAlertFormVars,
  SwitchUnit,
} from '../../../../CompressedAir/types/compressed-air_types'
import {
  StyledFlexBox,
  StyledLabel,
} from '../../../../App/components/AlertConfigurationModal/AlertConfigurationModal.styled'
import { StyledSelect } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledSelect'
import { StyledTextField } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { ThresholdOperatorType } from '../../../../App/types/alertTypes'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

interface IAlertTriggerConditionsProps {
  control: Control<CompressedAirAlertFormVars>
  isCreate: boolean
  selectProps: SelectProps<string>
  assets: CompressedAirAlertAssetOption[]
  errors: FieldErrorsImpl<CompressedAirAlertFormVars>
  watch: UseFormWatch<CompressedAirAlertFormVars>
}

export const AlertTriggerConditions: FC<IAlertTriggerConditionsProps> = ({
  control,
  isCreate,
  selectProps,
  assets,
  errors,
  watch,
}) => {
  const { i18n } = useI18nContext()
  const thresholdOperatorType = watch('thresholdOperatorType')
  const asset = watch('assetId')

  const inputProps: TextFieldProps = {
    size: 'small',
    type: 'number',
    placeholder: i18n.text('alerts.configuration.type-value'),
    sx: {
      height: '100%',
      [`.${outlinedInputClasses.root}`]: {
        height: '100%',
        '& input': {
          height: '46px',
          boxSizing: 'border-box',
        },
      },
    },
    inputProps: {
      min: 0,
      step: 0.01,
    },
  }

  const conditions = [
    { key: ThresholdOperatorType.GREATER, option: i18n.text('rules.problem.conditions.above') },
    { key: ThresholdOperatorType.LESS, option: i18n.text('rules.problem.conditions.below') },
    { key: ThresholdOperatorType.BETWEEN, option: i18n.text('rules.problem.conditions.between') },
  ]

  const selectSystemPlaceholder = () => {
    if (!selectedAsset?.id) {
      return <em>{i18n.text('alerts.configuration.select-system')}</em>
    }
    return selectedAsset?.name
  }

  const selectSensorLocationPlaceholder = (selected: string) => {
    if (!selected) {
      return <em>{i18n.text('alerts.configuration.select-sensor-location')}</em>
    }
    return getLocation(selected)?.name
  }

  const selectedAsset = useMemo(() => assets.find(item => item.id === asset), [asset, assets])

  const sensorLocations = useMemo(() => {
    if (!selectedAsset) {
      return []
    }
    return selectedAsset.sensorLocations
  }, [selectedAsset])

  function getLocation(locationId: string) {
    return sensorLocations.find(location => location.id === locationId)
  }

  function handleValueChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: number) => void
  ) {
    if (!isNaN(+e.target.value)) {
      onChange(+e.target.value)
    }
  }

  return (
    <AlertTriggerConditionsGridBox>
      <StyledLabel sx={{ gridArea: 'title', textWrap: 'wrap' }}>
        {i18n.text('rules.alert-trigger-conditions')}
      </StyledLabel>
      <StyledFlexBox>
        <StyledLabel>{i18n.text('rules.alert.asset-ca-system')}</StyledLabel>
        <Controller
          name="assetId"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <StyledSelect
              {...selectProps}
              size="small"
              labelId="asset-label"
              id="asset-select"
              value={value}
              disabled={!assets.length || !isCreate}
              onChange={onChange}
              displayEmpty
              renderValue={selectSystemPlaceholder}
            >
              {assets.map(as => (
                <MenuItem
                  key={as.id}
                  value={as.id}
                >
                  {as.name}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        ></Controller>
      </StyledFlexBox>
      <StyledFlexBox>
        <StyledLabel>{i18n.text('notifications.messages.thresholds.table.sensor.location')}</StyledLabel>
        <Controller
          name="locationId"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <StyledSelect
              {...selectProps}
              size="small"
              labelId="sensor-location-label"
              id="sensor-location-select"
              value={value}
              placeholder={i18n.text('alerts.configuration.select-sensor-location')}
              disabled={!asset || !isCreate}
              displayEmpty
              error={!!errors.locationId}
              renderValue={selectSensorLocationPlaceholder}
              onChange={onChange}
            >
              {sensorLocations.map(location => (
                <MenuItem
                  key={location.id}
                  value={location.id}
                >
                  {location.name}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        ></Controller>
        {errors.locationId && (
          <FormHelperText
            sx={{
              color: theme => theme.palette.SFIOrange[600],
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.25rem',
              marginLeft: 0,
              marginTop: 0,
            }}
          >
            {errors.locationId.message}
          </FormHelperText>
        )}
      </StyledFlexBox>
      <StyledFlexBox>
        <StyledLabel>{i18n.text('rules.headers.condition')}</StyledLabel>
        <Controller
          name="thresholdOperatorType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledSelect
              {...selectProps}
              size="small"
              labelId="condition-label"
              id="condition-select"
              value={value}
              placeholder={i18n.text('alerts.configuration.select-condition')}
              onChange={onChange}
            >
              {conditions.map(condition => (
                <MenuItem
                  key={condition.key}
                  value={condition.key}
                >
                  {condition.option}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        ></Controller>
      </StyledFlexBox>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '1.31rem',
        }}
      >
        {(thresholdOperatorType === ThresholdOperatorType.GREATER ||
          thresholdOperatorType === ThresholdOperatorType.BETWEEN) && (
          <StyledFlexBox>
            <StyledLabel>{`${i18n.text('rules.headers.value')} (${SwitchUnit.m3m})`}</StyledLabel>
            <Controller
              name="lowerThresholdValue"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StyledTextField
                  value={value}
                  error={!!errors.lowerThresholdValue}
                  helperText={errors.lowerThresholdValue?.message}
                  onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleValueChange(e, onChange)}
                  {...inputProps}
                />
              )}
            ></Controller>
          </StyledFlexBox>
        )}
        {(thresholdOperatorType === ThresholdOperatorType.LESS ||
          thresholdOperatorType === ThresholdOperatorType.BETWEEN) && (
          <StyledFlexBox>
            <StyledLabel>{`${i18n.text('rules.headers.value')} (${SwitchUnit.m3m})`}</StyledLabel>
            <Controller
              name="upperThresholdValue"
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledTextField
                  value={value}
                  error={!!errors.upperThresholdValue}
                  helperText={errors.upperThresholdValue?.message}
                  onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleValueChange(e, onChange)}
                  {...inputProps}
                />
              )}
            ></Controller>
          </StyledFlexBox>
        )}
      </Box>
    </AlertTriggerConditionsGridBox>
  )
}

const AlertTriggerConditionsGridBox = styled(Box)({
  display: 'grid',
  gridTemplateAreas: `'title first-column second-column'
                      'title first-column second-column'`,
  gap: '1rem 2rem',
  justifyContent: 'flex-start',
  gridTemplateColumns: '10rem repeat(2, minmax(0, 1fr))',
})
