import { Propositions, SearchType } from '../types/alertTypes'

export function getAlertSearchType(selectedProposition: string, propositions: Propositions) {
  if (selectedProposition === propositions.oee.name) {
    return SearchType.PRODUCTION_SPEED
  }
  if (selectedProposition === propositions.electricity.name) {
    return SearchType.POWER
  }
  return SearchType.VOLUME
}
