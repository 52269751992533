import { FC } from 'react'

import { AssetStop } from '../../types/Assets.types'
import { StyledTableAccentCell, StyledTableCell, StyledTableRow } from '../StyledTable'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type StopOverviewTableRowProps = {
  stop: AssetStop
}

const StopOverviewTableRow: FC<StopOverviewTableRowProps> = ({ stop }) => {
  const { i18n } = useI18nContext()

  return (
    <StyledTableRow>
      <StyledTableAccentCell>{stop.assetName ?? i18n.text('oee.machine-name.unknown-machine')}</StyledTableAccentCell>
      <StyledTableCell align="center">{stop.startTime}</StyledTableCell>
      <StyledTableCell align="center">{stop.endTime ?? '-'}</StyledTableCell>
      <StyledTableCell align="center">
        {stop.duration ?? i18n.text('oee.stop-logging.drawer.item.modal.duration.ongoing')}
      </StyledTableCell>
      <StyledTableCell>{stop.reason}</StyledTableCell>
      <StyledTableCell>{stop.feedback || '-'}</StyledTableCell>
    </StyledTableRow>
  )
}

export default StopOverviewTableRow
