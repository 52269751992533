import { createSvgIcon } from '@mui/material'

export const BellIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.60901 18.0143C9.18019 19.6147 10.1299 21.2597 11.7303 21.6885C13.3307 22.1174 14.9757 21.1676 15.4046 19.5672M13.1085 5.74036C12.8043 5.19306 12.7041 4.53156 12.8789 3.87896C13.2363 2.5453 14.6071 1.75384 15.9408 2.1112C17.2745 2.46855 18.0659 3.83939 17.7086 5.17306C17.5337 5.82566 17.1162 6.34843 16.5791 6.6703M7.7641 9.4448C8.12104 8.11267 9.07397 6.9987 10.4132 6.34797C11.7525 5.69724 13.3684 5.56304 14.9055 5.9749C16.4426 6.38676 17.7749 7.31094 18.6094 8.54412C19.4439 9.77731 19.7122 11.2185 19.3552 12.5506C18.7646 14.7547 18.8793 16.5131 19.2523 17.8492C19.6773 19.3721 19.8899 20.1335 19.8324 20.2864C19.7668 20.4613 19.7192 20.5093 19.545 20.5768C19.3927 20.6358 18.7529 20.4644 17.4734 20.1216L5.60833 16.9423C4.32879 16.5995 3.68902 16.428 3.58663 16.3008C3.46949 16.1552 3.45235 16.0899 3.48292 15.9055C3.50965 15.7444 4.07442 15.1913 5.20396 14.085C6.19502 13.1143 7.17352 11.6489 7.7641 9.4448Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'bell-icon'
)
