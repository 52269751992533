import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { Box } from '@mui/material'
import { FC, useCallback, useMemo } from 'react'
import { Interval } from 'luxon'

import { SummedEnergyBalanceSeries, ValuesModifier } from '../../types'
import { getCurrencyFormatter } from '../../../Shared/utils/formatCurrency'
import { useChartToolsContext } from '../../context/ChartToolsContext'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useEnergyBalancePieChartOptions } from './energyBalancePieChartOptions'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type EnergyBalancePieChartProps = {
  chartWidth: string
  interval?: Interval
  data: SummedEnergyBalanceSeries
  valuesModifier: ValuesModifier
  showingCosts: boolean
  loading: boolean
  onDataPointMouseEnter?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
  onDataPointMouseLeave?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
}

const EnergyBalancePieChart: FC<EnergyBalancePieChartProps> = ({
  chartWidth,
  data,
  valuesModifier,
  showingCosts,
  loading,
  onDataPointMouseEnter,
  onDataPointMouseLeave,
}) => {
  const labels = useMemo(() => data.map(s => s.name), [data])
  const { decimal } = useChartToolsContext()
  const { userLocale } = useI18nContext()
  const { customerCurrency } = useCurrentUser()
  const currencyFormatter = useMemo(() => getCurrencyFormatter(userLocale, customerCurrency.isocode), [userLocale])

  const dataSeries: ApexNonAxisChartSeries = showingCosts
    ? [...data.map(s => s.cost ?? 0)]
    : [...data.map(s => +s.total.toFixed(decimal))]
  const colors = useMemo(() => data.map(s => s.color).filter(s => !!s) as string[], [data])
  const { i18n } = useI18nContext()

  const tooltipFormatter: (value: number | string) => string = useCallback(
    value => {
      return showingCosts
        ? currencyFormatter(Math.round(+value), 0)
        : `${value ? (+value).toFixed(decimal) : 0} ${valuesModifier.unit}`
    },
    [valuesModifier.unit, i18n, showingCosts, decimal]
  )
  const totalFormatter = useCallback(
    w => {
      const t = w.globals.seriesTotals.reduce((a: number, b: number) => {
        return a + b
      }, 0)
      return showingCosts ? currencyFormatter(Math.round(t), 0) : `${t.toFixed(decimal)} ${valuesModifier.unit}`
    },
    [valuesModifier.unit, showingCosts, i18n, decimal]
  )

  const options: ApexOptions = useEnergyBalancePieChartOptions({
    tooltipFormatter,
    totalFormatter,
    labels,
    colors,
    loading,
    decimal,
    onDataPointMouseEnter,
    onDataPointMouseLeave,
  })

  return (
    <Box
      sx={{
        '.apexcharts-canvas': {
          margin: 'auto',
        },
      }}
    >
      <ReactApexChart
        options={options}
        series={dataSeries}
        type="donut"
        height="650"
        width={chartWidth}
      />
    </Box>
  )
}

export { EnergyBalancePieChart }
