import { Box, lighten } from '@mui/material'
import { DateTime, Interval } from 'luxon'
import { WarningAmber } from '@mui/icons-material'
import { useCallback, useEffect, useMemo } from 'react'
import { useFeature } from 'flagged'

import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import {
  APEX_TOOLTIP_DATE_FORMAT_INTL,
  COMPARE_INTERVAL_NAME,
  GRAPH_TOOLBAR_DATE_CHIP_FORMAT,
  INITIAL_INTERVAL_NAME,
} from '../../constants'
import { ChartToolsItem } from '../../../Shared/components/MUIComponents/update/ChartToolsDropdown'
import { DEFAULT_TIMEZONE } from '../../../Shared/constants/timezone'
import { DataPointWithPrevTime, ElectricityDataSeries, SingleElectricityDataSeries, YAxisUnits } from '../../types'
import { EnergyChart } from '../../components/EnergyChart/EnergyChart'
import { GraphToolbar } from '../../components/GraphToolbar'
import { NonNegativeFloat } from '../../../Shared/types/types'
import { OOP_COLOR, STANDBY_COLOR } from '../../../Shared/theme/MuiThemeProvider'
import { PowerCsvExporter } from '../../components/PowerCsvExporter'
import { STANDBY_STRING } from '../ElectricityContainer/constants'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { adjustMeasurementToMagnitude, getPowerOrderOfMagnitude, getScaledEnergyPriceMap } from '../../../Shared/utils'
import { calculateNewCompareInterval } from '../../utils/calculateNewCompareInterval'
import { filterUpToNow } from '../../utils/filterSeriesToNow'
import { getApexElectricityChartType } from '../../utils/getApexChartType'
import { getCurrencySymbol } from '../../../Shared/utils/formatCurrency'
import { getLargestSeriesMeasurement } from '../../utils/getLargestSeriesMeasurement'
import { mapMeasurementsToXY, mapStandbyToXYEnergy } from '../../utils/mapLegacyMeasurements'
import { sendToolbarButtonClickElectricity } from '../../utils/analyticsEvents'
import { useChartToolsContext } from '../../context/ChartToolsContext'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useElectricityContext } from '../../context/ElectricityContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const EnergyChartContainer = () => {
  const { showWaste, setShowWaste, yAxisUnit, setYAxisUnit, decimal, setDecimal, showOop, setShowOop } =
    useChartToolsContext()
  const { selectedCustomer, hasElectricityStandbyEnabled, customerCurrency, commodityPrices } = useCurrentUser()
  const { sendEvent } = useAnalytics()
  const { i18n, userLocale } = useI18nContext()
  const {
    isSingleAssetSelected,
    areAssetsSelected,
    onDateRangeChange,
    selectedDateRange,
    measurements,
    analysisType,
    selectedAssets,
    fetchStandbyLoading,
    fetchMeasurementsLoading,
    doesAssetHaveStandbyEnabled,
    getAssetStandbyValidation,
    previousAssetMeasurements,
    showCompare,
    setShowCompare,
    compareDateRange,
    csvExportRequest,
    onCompareRangeChange,
  } = useElectricityContext()

  const interval = Interval.fromDateTimes(selectedDateRange.startDate, selectedDateRange.endDate)
  const customerTimeZone = selectedCustomer.timeZone || DEFAULT_TIMEZONE
  const loading = fetchStandbyLoading || fetchMeasurementsLoading

  const initialDataSeries: ElectricityDataSeries = useMemo(() => {
    const result: ElectricityDataSeries = []

    for (const asset of measurements) {
      const measurementsData = filterUpToNow(mapMeasurementsToXY(asset.measurements), customerTimeZone)
      result.push({
        assetId: asset.id,
        group: showCompare ? 'regular' : undefined,
        name: showCompare ? `${asset.name} ${INITIAL_INTERVAL_NAME}` : asset.name,
        type: getApexElectricityChartType(analysisType),
        data: measurementsData,
        mean: (measurementsData.reduce((acc, curr) => acc + (curr.y || 0), 0) /
          measurementsData.length) as NonNegativeFloat,
        color: asset.color,
      })
    }
    return result
  }, [measurements, analysisType, showCompare])

  const compareDataSeries: ElectricityDataSeries = useMemo(() => {
    const result: ElectricityDataSeries = []
    for (const asset of previousAssetMeasurements) {
      const measurementsData = filterUpToNow(mapMeasurementsToXY(asset.measurements), customerTimeZone)
      result.push({
        assetId: asset.id,
        group: 'previousPeriod',
        name: `${asset.name} ${COMPARE_INTERVAL_NAME}`,
        type: getApexElectricityChartType(analysisType),
        data: measurementsData,
        mean: (measurementsData.reduce((acc, curr) => acc + (curr.y || 0), 0) /
          measurementsData.length) as NonNegativeFloat,
        color: lighten(asset.color, 0.7),
        isPreviousPeriod: true,
      })
    }
    return result
  }, [previousAssetMeasurements, analysisType, showCompare])

  const wasteSeries: SingleElectricityDataSeries | null = useMemo(() => {
    if (!areAssetsSelected) return null
    const [selectedAssetId] = Object.keys(selectedAssets)
    const [asset] = measurements.filter(s => s.id === selectedAssetId)
    if (!asset || !asset.standby) return null

    const result: SingleElectricityDataSeries = {
      assetId: asset.id,
      group: showCompare ? 'regular' : undefined,
      name: `${asset.name} ${STANDBY_STRING}`,
      isWaste: true,
      type: 'bar',
      data: filterUpToNow(mapStandbyToXYEnergy(asset.standby), customerTimeZone),
      color: STANDBY_COLOR,
    }
    return result
  }, [selectedAssets, measurements])

  const oopSeries: SingleElectricityDataSeries | null = useMemo(() => {
    if (!areAssetsSelected) return null
    const [selectedAssetId] = Object.keys(selectedAssets)
    const [asset] = measurements.filter(s => s.id === selectedAssetId)
    if (!asset || !asset.oop) return null

    const result: SingleElectricityDataSeries = {
      assetId: asset.id,
      name: `${asset.name} ${i18n.text('chart.tooltip.oop-name')}`,
      isWaste: true,
      type: 'bar',
      data: filterUpToNow(mapStandbyToXYEnergy(asset.oop), customerTimeZone),
      color: OOP_COLOR,
    }
    return result
  }, [selectedAssets, measurements])

  const fullDataSeries: ElectricityDataSeries = useMemo(() => {
    let series: ElectricityDataSeries = []

    if ((showWaste && wasteSeries) || (showOop && oopSeries)) {
      let regularConsumptionSeries: SingleElectricityDataSeries = { ...initialDataSeries[0] }
      if (showWaste && wasteSeries) {
        regularConsumptionSeries = {
          ...regularConsumptionSeries,
          data: regularConsumptionSeries.data.map(d => {
            const standbyPoint = wasteSeries.data.find(w => w.x === d.x)
            // subtract the standby from the total so bar chart height is correct
            // we limit the data point to 0 to avoid processing bugs where standby > total energy
            // if standby is not available, we just use the regular consumption
            let pointWithSubtractedStandby = d.y && standbyPoint && standbyPoint.y ? d.y - standbyPoint.y : d.y || 0
            pointWithSubtractedStandby = pointWithSubtractedStandby < 0 ? 0 : pointWithSubtractedStandby
            return {
              ...d,
              y: pointWithSubtractedStandby as NonNegativeFloat,
            }
          }),
        }
        series.push(wasteSeries)
      }
      if (showOop && oopSeries) {
        regularConsumptionSeries = {
          ...regularConsumptionSeries,
          data: regularConsumptionSeries.data.map(d => {
            const oopPoint = oopSeries.data.find(w => w.x === d.x)
            // subtract the standby from the total so bar chart height is correct
            // we limit the data point to 0 to avoid processing bugs where standby > total energy
            // if standby is not available, we just use the regular consumption
            let pointWithSubtractedOop = d.y && oopPoint && oopPoint.y ? d.y - oopPoint.y : d.y || 0
            pointWithSubtractedOop = pointWithSubtractedOop < 0 ? 0 : pointWithSubtractedOop
            return {
              ...d,
              y: pointWithSubtractedOop as NonNegativeFloat,
            }
          }),
        }
        series.push(oopSeries)
      }
      series.unshift(regularConsumptionSeries)
    } else {
      series = [...initialDataSeries]
    }

    if (showCompare) {
      series = [...initialDataSeries, ...compareDataSeries]
    }

    return series
  }, [showWaste, initialDataSeries, wasteSeries, compareDataSeries, showCompare, oopSeries, showOop])

  const largestMeasurement = useMemo(() => getLargestSeriesMeasurement(fullDataSeries), [fullDataSeries])
  const valuesModifier = useMemo(() => getPowerOrderOfMagnitude(largestMeasurement, true), [largestMeasurement])

  const energyPrice = useMemo(
    () => getScaledEnergyPriceMap(commodityPrices, valuesModifier.unit).get('electric-energy'),
    [commodityPrices, valuesModifier.unit]
  )

  // adjust data to be in correct order of magnitude
  const scaleAdjustedDataSeries = useMemo(() => {
    if (yAxisUnit === 'cost') {
      return fullDataSeries.map(series => ({
        ...series,
        data: series.data.map(d => {
          return {
            ...d,
            y: (energyPrice * (adjustMeasurementToMagnitude(d.y, valuesModifier.decimal) ?? 0)) as NonNegativeFloat,
          }
        }),
      }))
    }
    return fullDataSeries.map(series => ({
      ...series,
      data: series.data.map(d => ({
        ...d,
        y: adjustMeasurementToMagnitude(d.y, valuesModifier.decimal),
      })),
    }))
  }, [yAxisUnit, fullDataSeries, valuesModifier, energyPrice, showCompare])

  const onWasteToggle = useCallback(() => {
    setShowWaste(showWaste => !showWaste)
    setShowCompare(false)
    sendToolbarButtonClickElectricity('toolbar_button_standby_waste', sendEvent)
  }, [setShowWaste, showWaste, setShowCompare, sendToolbarButtonClickElectricity, sendEvent])

  const onYAxisUnitChange = useCallback((unit: YAxisUnits) => setYAxisUnit(unit), [setYAxisUnit])

  const onZoomChange = useCallback(
    (chart: unknown, changed: { xaxis: { min: number; max: number } }) => {
      const {
        xaxis: { min, max },
      } = changed

      const startDate = DateTime.fromMillis(min)
      const endDate = DateTime.fromMillis(max)

      if (compareDateRange.startDate && compareDateRange.endDate && showCompare) {
        const { newCompareStartDate, newCompareEndDate } = calculateNewCompareInterval(
          selectedDateRange.startDate,
          compareDateRange.startDate,
          startDate,
          endDate
        )
        onCompareRangeChange({ startDate: newCompareStartDate, endDate: newCompareEndDate }, true)
      }
      onDateRangeChange({ startDate, endDate }, true)
    },
    [onDateRangeChange]
  )

  const tooltipFormatter: (value: number, options: any) => any = useCallback(
    (value, { series: chartSeries, seriesIndex: chartIndex, dataPointIndex }) => {
      const dataPoint = chartSeries[chartIndex][dataPointIndex]
      const previousPeriodSeriesIndexes = scaleAdjustedDataSeries
        .map((s, i) => (s.isPreviousPeriod ? i : null))
        .filter(i => i !== null)
      const previousPeriodSeries = scaleAdjustedDataSeries[chartIndex]
      const isPreviousPeriodSeries = previousPeriodSeriesIndexes.includes(chartIndex)
      const compareDataPoint = (previousPeriodSeries?.data[dataPointIndex] as DataPointWithPrevTime) || null
      const previousDateStampFormatted = compareDataPoint
        ? DateTime.fromISO(compareDataPoint.prevTime!, {
            zone: customerTimeZone as string,
          }).toFormat(APEX_TOOLTIP_DATE_FORMAT_INTL)
        : ''
      if (isPreviousPeriodSeries)
        return yAxisUnit === 'cost'
          ? i18n.currency(value, customerCurrency.isocode) + ` ${previousDateStampFormatted}`
          : ` ${value.toFixed(decimal)} ${valuesModifier.unit} ${previousDateStampFormatted}`
      return yAxisUnit === 'cost'
        ? i18n.currency(value, customerCurrency.isocode)
        : `${value.toFixed(decimal)} ${valuesModifier.unit}`
    },
    [valuesModifier.unit, i18n, yAxisUnit, decimal]
  )

  const isStandbyForAssetEnabled = useMemo(() => {
    if (!isSingleAssetSelected || !areAssetsSelected) return false
    const [selectedAssetId] = Object.keys(selectedAssets)
    return doesAssetHaveStandbyEnabled(selectedAssetId)
  }, [isSingleAssetSelected, selectedAssets, doesAssetHaveStandbyEnabled])

  const isStandbyButtonEnabled = useMemo(() => {
    return isSingleAssetSelected && isStandbyForAssetEnabled && !showCompare
  }, [isSingleAssetSelected, isStandbyForAssetEnabled, showCompare])

  const selectedAssetValidationType = useMemo(() => {
    if (!isSingleAssetSelected) return null
    const [selectedAssetId] = Object.keys(selectedAssets)
    return getAssetStandbyValidation(selectedAssetId)
  }, [isSingleAssetSelected, selectedAssets])

  const whyNoStandby = () => {
    if (!isSingleAssetSelected) {
      return i18n.text('graph.button.tools.show-waste-disabled:not-single-asset')
    }
    if (!isStandbyForAssetEnabled) {
      return i18n.text('graph.button.tools.show-waste-disabled:no-standby')
    }
    if (showCompare) {
      return 'Standby disabled while comparing periods' // TODO add i18n.text('graph.button.tools.show-waste-disabled:compare'
    }
    return i18n.text('graph.button.tools.show-waste')
  }

  const whyNoOop = () => {
    if (!isSingleAssetSelected) return i18n.text('graph.button.tools.show-oop-disabled:not-single-asset')
    if (showCompare) return 'Outside production schedule disabled while comparing periods' // TODO add i18n.text('graph.button.tools.show-oop-disabled:compare'

    return ''
  }

  // toggle chart tools buttons based on selected assets
  useEffect(() => {
    if (!isSingleAssetSelected) {
      setShowWaste(false)
      setShowOop(false)
    }
  }, [isSingleAssetSelected, setShowWaste, setShowOop])

  // toggle standby waste based on selected asset
  useEffect(() => {
    if (!isStandbyForAssetEnabled) setShowWaste(false)
  }, [isStandbyForAssetEnabled, setShowWaste])

  // togle standby waste, oop, based on compare period
  useEffect(() => {
    if (showCompare) {
      setShowWaste(false)
      setShowOop(false)
    }
  }, [showWaste, showCompare])

  const hasOop = useFeature('ELECTRICITY_OOP')
  const isDCValidation = useMemo(() => selectedAssetValidationType === 'DC', [selectedAssetValidationType])

  // We show a DC warning only if the standby button is enabled and DC is the validation
  // We don't show a DC warning if the button is disabled
  const shouldShowDCValidationWarning = isStandbyButtonEnabled && isDCValidation
  const dcValidationWarning = shouldShowDCValidationWarning
    ? i18n.text('graph.button.tools.show-waste-dc-validation-warning')
    : ''

  // We show a tooltip with "Show waste" if the button is enabled and there's no DC warning
  // We show a tooltip with the reason for the button being disabled if the button is disabled
  const shouldShowWasteButtonTooltip = !shouldShowDCValidationWarning

  const wasteButtonTooltip = shouldShowWasteButtonTooltip
    ? isStandbyButtonEnabled
      ? ''
      : whyNoStandby()
    : dcValidationWarning

  const title =
    `${i18n.text('graph.measurements.energy')} ` +
    (yAxisUnit === 'energy'
      ? ` (${valuesModifier.unit})`
      : `(${getCurrencySymbol(userLocale, customerCurrency.isocode)})`)
  const chipDates =
    selectedDateRange.startDate.toFormat(GRAPH_TOOLBAR_DATE_CHIP_FORMAT) +
    ' - ' +
    selectedDateRange.endDate.toFormat(GRAPH_TOOLBAR_DATE_CHIP_FORMAT)

  const switchUnitValues = [
    {
      value: 'energy' as YAxisUnits,
      title: valuesModifier.unit,
    },
    {
      value: 'cost' as YAxisUnits,
      title: getCurrencySymbol(userLocale, customerCurrency.isocode),
    },
  ]

  const toolbarItems: ChartToolsItem[] = useMemo(() => {
    const result: ChartToolsItem[] = [
      {
        label: `${i18n.text('chart.tools.decimal-label')} : ${decimal}`,
        values: [0, 1, 2, 3, 4],
        onValueToggle: (value: number) => setDecimal(value),
        selectedValue: decimal,
      },
    ]

    if (hasElectricityStandbyEnabled)
      result.unshift({
        label: i18n.text('graph.button.tools.show-waste'),
        onToggle: onWasteToggle,
        isChecked: showWaste,
        isDisabled: !isStandbyButtonEnabled,
        tooltip: {
          text: wasteButtonTooltip,
        },
        icon: shouldShowDCValidationWarning ? <WarningAmber fontSize="small" /> : undefined,
      })

    if (hasOop) {
      result.unshift({
        label: i18n.text('graph.button.tools.show-oop'),
        onToggle: () => setShowOop(showOop => !showOop),
        isChecked: showOop,
        isDisabled: !isSingleAssetSelected || showCompare,
        tooltip: {
          text: whyNoOop(),
        },
      })
    }
    return result
  }, [
    showWaste,
    showOop,
    decimal,
    onWasteToggle,
    wasteButtonTooltip,
    hasElectricityStandbyEnabled,
    shouldShowDCValidationWarning,
  ])

  return (
    <SectionBoxWrapper sx={{ height: '100%' }}>
      <GraphToolbar
        titleText={title}
        chipContent={chipDates}
        onUnitChange={onYAxisUnitChange}
        switchUnit={yAxisUnit}
        switchUnitValues={switchUnitValues}
        toolbarItems={toolbarItems}
        showZoomButtons={true}
      />
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ display: 'none' }}>
          <PowerCsvExporter
            measurements={measurements}
            interval={interval}
            unit="Wh"
            showDialog={csvExportRequest === 'energy'}
          />
        </Box>
        <Box id="analysis-graph">
          <EnergyChart
            valuesModifier={valuesModifier}
            largestMeasurement={largestMeasurement}
            showingCosts={yAxisUnit === 'cost'}
            data={scaleAdjustedDataSeries}
            interval={interval}
            handleZoomChange={onZoomChange}
            tooltipFormatter={tooltipFormatter}
            loading={loading}
            showCompare={showCompare}
            showWaste={showWaste}
          />
        </Box>
      </Box>
    </SectionBoxWrapper>
  )
}

export { EnergyChartContainer }
