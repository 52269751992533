import { FC, useMemo } from 'react'

import { AssetTreeGroup, AssetTreeMachine } from '../../types'
import { AssetTreeParentNode } from '../../../Shared/components/MUIComponents/update/AssetTree/StyledAssetTreeParentNode'
import { ElectricityAssetTreeMachine } from './ElectricityAssetTreeMachine'
import { useElectricityContext } from '../../context/ElectricityContext'

interface ElectricityAssetTreeGroupProps {
  group: AssetTreeGroup
  machines: AssetTreeMachine[]
  isExpanded: boolean
  searchTerm: string
  showIfEmpty?: boolean
  disableExpand?: boolean
  isSelectable?: boolean
  isIndeterminate?: boolean
  onSelect: (assetId: string) => void
  onGroupSelect: (groupId: string) => void
}

const ElectricityAssetTreeGroup: FC<ElectricityAssetTreeGroupProps> = ({
  group,
  machines,
  isExpanded,
  searchTerm,
  showIfEmpty = false,
  disableExpand = false,
  isSelectable = false,
  onSelect,
  onGroupSelect,
}) => {
  const { selectedAssets, areMachinesOrTheirComponentsSelected } = useElectricityContext()

  const selectedGroupMachines = machines.filter(machine => selectedAssets[machine.id])
  const isAnyMachineSelected = selectedGroupMachines.length > 0
  const areAllMachinesSelected = isAnyMachineSelected && selectedGroupMachines.length === machines.length

  const isSelected = isAnyMachineSelected || !!selectedAssets[group.id]
  const isIndeterminate =
    isSelectable && !isSelected && areMachinesOrTheirComponentsSelected(group.id) && !areAllMachinesSelected

  const visibleMachines = useMemo(() => {
    if (!searchTerm) return machines
    return machines.filter(machine => {
      const machineNameFitsSearch = machine.name.toLowerCase().includes(searchTerm)
      if (machineNameFitsSearch) return true
      const componentsFitSearch = machine.components?.some(component => {
        return component.name.toLowerCase().includes(searchTerm)
      })
      return componentsFitSearch
    })
  }, [machines, searchTerm])

  const hasMachines = !!visibleMachines.length
  const shouldShowItemTitle = hasMachines || showIfEmpty

  return (
    <>
      {shouldShowItemTitle && (
        <AssetTreeParentNode
          isExpanded={isExpanded}
          isSelected={isSelected}
          isAllSelected={hasMachines ? areAllMachinesSelected : isSelected}
          isIndeterminate={isIndeterminate}
          hasExpandIcon={hasMachines}
          disableExpand={disableExpand}
          parent={{ id: group.id, name: group.name }}
          hasCheckbox={true}
          onCheckboxToggle={() => onGroupSelect(group.id)}
        >
          {!!hasMachines &&
            visibleMachines.map(machine => (
              <ElectricityAssetTreeMachine
                key={machine.id}
                machine={machine}
                components={machine.components || []}
                onSelect={onSelect}
                searchTerm={searchTerm}
              />
            ))}
        </AssetTreeParentNode>
      )}
    </>
  )
}

export { ElectricityAssetTreeGroup }
