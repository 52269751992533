import React, { FC } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { useHistory } from 'react-router'

import { NoDataSlotProps } from '../../../../types/types'
import { SearchIcon } from '../../../icons/SearchIcon'
import { StyledPrimaryButton } from '../styledComponents/StyledButtons'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

/**
 * @description - NoDataFoundSlot is a slot that is shown when there is no data
 * @param {string} title - Main bold text under the icon
 * @param {string} subtitle - The explanation text under the title
 * @param {string} buttonText - Text that is required if you need a button
 * @param {string} redirectLink - Link to where clicking button should redirect
 * @param {JSX.Element} icon - Main icon, by default it uses SearchIcon
 * @param {NoDataSlotProps} slotProps - Additional props in case a developer wants to add something extra to some parts of the NoDataFoundSlot
 */

interface INoDataFoundSlotProps {
  title: string
  icon?: JSX.Element
  subtitle?: string
  buttonText?: string
  redirectLink?: string
  slotProps?: NoDataSlotProps
}

export const NoDataFoundSlot: FC<INoDataFoundSlotProps> = ({
  icon = <SearchIcon />,
  title,
  subtitle,
  buttonText,
  redirectLink,
  slotProps,
}) => {
  const { i18n } = useI18nContext()
  const history = useHistory()
  const hasButton = !!buttonText && !!redirectLink
  return (
    <NoDataFoundSlotWrapper
      hasButton={hasButton}
      {...slotProps?.wrapper}
    >
      <IconWrapper>{icon}</IconWrapper>
      <Title
        hasSubtitle={!!subtitle}
        {...slotProps?.title}
      >
        {i18n.text(title)}
      </Title>
      {subtitle && <Subtitle {...slotProps?.subtitle}>{i18n.text(subtitle)}</Subtitle>}
      {buttonText && redirectLink && (
        <StyledPrimaryButton
          disableRipple
          sx={{ width: 'fit-content' }}
          onClick={() => history.push(redirectLink)}
        >
          {i18n.text(buttonText)}
        </StyledPrimaryButton>
      )}
    </NoDataFoundSlotWrapper>
  )
}

const NoDataFoundSlotWrapper = styled((props: { hasButton: boolean }) => {
  const { hasButton, ...other } = props
  return <Box {...other} />
})(({ hasButton }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '250px',
  backgroundImage: 'url(/assets/backgroundPatternDecorative.svg)',
  backgroundSize: 'auto',
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  paddingBottom: hasButton ? '1.2rem' : '4rem',
}))

const IconWrapper = styled(Box)(({ theme }) => ({
  marginBottom: '1rem',
  width: '3rem',
  height: '3rem',
  borderRadius: '0.625rem',
  border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const Title = styled((props: { hasSubtitle: boolean }) => {
  const { hasSubtitle, ...other } = props
  return <Typography {...other} />
})(({ theme, hasSubtitle }) => ({
  marginBottom: hasSubtitle ? '0.25rem' : '1.5rem',
  color: theme.palette.SFIGreyLight[800],
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: '1.5rem',
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  marginBottom: '1.5rem',
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  textAlign: 'center',
}))
