import { DateTime } from 'luxon'

import {
  AirSensorLocationFragment,
  CompressedAirAssetFragment,
  CompressedAirAssetTreeFragment,
} from '../../Shared/graphql/codegen'
import { FetchParams } from '../utils/utils'
import { ISODateTime } from '../../Shared/types/types'
import { LoaderError } from '../../Shared/hooks/incrementalDataLoader'
import { AlertInterval, Recipient, ThresholdOperatorType } from '../../App/types/alertTypes'

export enum SeriesPoints {
  FLOW = 'flowPoints',
  TEMPERATURE = 'temperaturePoints',
  PRESSURE = 'pressurePoints',
}

export interface IConsumptionPoint {
  time: DateTime
  value: number
  previousTime?: DateTime
}

export interface IFlowPoint extends Omit<IConsumptionPoint, 'value'> {
  value: number | null
  min: number | null
  max: number | null
}

export type SensorLocationSeries = {
  name: string
  flowPoints: IFlowPoint[]
  temperaturePoints: IFlowPoint[]
  pressurePoints: IFlowPoint[]
  consumptionPoints: IConsumptionPoint[]
}

export type CompressedAirDataMap = Map<string, SensorLocationSeries> | null

export type CompressedAirQueryParams = {
  from: number
  to: number
  sid: never[] | (string | null)[]
}

export type GraphsComponentProps = {
  dataMap: CompressedAirDataMap
  groups: readonly CompressedAirAssetTreeFragment[]
  updateParams: (value: FetchParams) => void
  fetching: boolean
  seriesError: LoaderError | null
  sensorLocationIds: string[]
  queryParams: CompressedAirQueryParams
}

export enum SwitchUnit {
  cfm = 'cfm',
  cf = 'cf',
  m3 = 'm³',
  m3m = 'm³/min',
  bar = 'bar',
  celsius = '°C',
  kw = 'kW',
  kwhm3 = 'kWh/m³',
}

export enum PreviousState {
  IS_PREVIOUS = 'is-previous',
  HAS_PREVIOUS = 'has-previous',
  NO_PREVIOUS = 'not-previous',
}

export enum SelectedAssetType {
  SYSTEM = 'system',
  LOCATION = 'location',
}

export enum ImagesCollectionName {
  SYSTEM = 'compressed-air-system-images',
  LOCATION = 'compressed-air-sensor-location-images',
}

export enum GraphId {
  FlOW = 'air-flow-chart',
  TEMPERATURE = 'air-temperature-chart',
  PRESSURE = 'air-pressure-chart',
  CONSUMPTION = 'air-consumption-chart',
  BALANCE = 'air-balance-chart',
  BALANCE_PREVIOUS = 'air-balance-previous-chart',
  EFFICIENCY_FLOW = 'air-efficiency-flow-chart',
  EFFICIENCY_POWER = 'air-efficiency-power-chart',
  EFFICIENCY_MAIN = 'air-efficiency-main-chart',
}

export interface BalanceSeries {
  ids: string[]
  series: number[]
  items: {
    [key: string]: number
  }
  labels: string[]
  colors: string[]
}

export interface Series {
  consumption: ApexAxisChartSeries
  flow: ApexAxisChartSeries
  temperature: ApexAxisChartSeries
  pressure: ApexAxisChartSeries
  balance: BalanceSeries
}

export interface ITimeSeriesData {
  id: string
  name: string
  data: {
    value: number | null
    time: string
    previousTime?: string
  }[]
}

export interface ISeriesData extends ITimeSeriesData {
  name: string
  color: string
  data: {
    time: string
    value: number | null
    min: number | null
    max: number | null
  }[]
}

export interface ISeriesCoords extends Omit<ISeriesData, 'data'> {
  data: {
    x: ISODateTime
    y: number | null
  }[]
}

export interface ICompressedAirSystemData {
  image?: string
  id: string
  pressure?: number | null
  compressors?: string[]
  pipes?: string[]
}

export interface ICASensorLocationData {
  id: string
  name: string
  inner_diameter?: number | null
  outer_diameter?: number | null
  image?: string
}

export interface IExtendedAirSensorLocation extends AirSensorLocationFragment {
  images: string[]
}

export interface IExtendedCompressedAirAsset extends CompressedAirAssetFragment {
  images: string[]
  sensorLocations: IExtendedAirSensorLocation[]
}

export type SeriesDataValues = {
  x: string
  y: number | number[] | null
}

export type AllSeriesData = {
  flow: ISeriesData[]
  temperature: ISeriesData[]
  pressure: ISeriesData[]
  consumption: ITimeSeriesData[]
}

export type CompressedAirFlowSystem = {
  id: string
  name: string
}

export type CompressedAirAlertFormVars = {
  assetId: string
  message: string
  coolDownMinutes?: number
  alertAfterMinutes?: number
  category?: string
  isAlwaysOn?: boolean
  recipients?: Recipient[]
  locationId: string
  thresholdOperatorType?: ThresholdOperatorType
  lowerThresholdValue?: number
  upperThresholdValue?: number
  alertIntervals?: AlertInterval[]
}

export type CompressedAirAlertAssetOption = {
  id: string
  name: string
  sensorLocations: Omit<CompressedAirAlertAssetOption, 'sensorLocations'>[]
}
