import { FC } from 'react'

import { ProductionStatisticsRow } from '../../types/ProductionStatistics.types'
import { StyledTableAccentCell, StyledTableCell, StyledTableRow } from '../StyledTable'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type LineStatisticsTableRowProps = {
  row: ProductionStatisticsRow
}

const ProductionStatisticsTableRow: FC<LineStatisticsTableRowProps> = ({ row }) => {
  const { i18n } = useI18nContext()

  return (
    <StyledTableRow>
      <StyledTableAccentCell>{row.name}</StyledTableAccentCell>
      <StyledTableCell align="center">{row.startTime}</StyledTableCell>
      <StyledTableCell align="center">{row.endTime}</StyledTableCell>
      <StyledTableCell align="center">{row.totalActiveTime}</StyledTableCell>
      <StyledTableCell align="center">{row.totalDownTime}</StyledTableCell>
      <StyledTableCell align="center">{row.availabilityPercentage}</StyledTableCell>
      <StyledTableCell align="center">{i18n.number(row.totalCycles, { maximumFractionDigits: 2 })}</StyledTableCell>
      <StyledTableCell align="center">{i18n.number(row.averageSpeedVsTotalTime)}</StyledTableCell>
      <StyledTableCell align="center">{i18n.number(row.averageSpeedVsRuntime)}</StyledTableCell>
      <StyledTableCell align="center">{i18n.number(row.maxSpeed)}</StyledTableCell>
    </StyledTableRow>
  )
}

export default ProductionStatisticsTableRow
