import React, { FC } from 'react'
import { Box, Typography, styled } from '@mui/material'

import { CustomDateTimePicker } from './CustomDateTimePicker'
import { StartEndDatesValues } from './types'
import { useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

interface ICompareDateTimeFormProps {
  fromToValues: StartEndDatesValues
  comparedFromToValues: StartEndDatesValues
}

interface IDatePickersRowProps {
  isCompare?: boolean
  values: StartEndDatesValues
  setValues?: (fromToValues: StartEndDatesValues) => void
}
const DatePickersWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  gridTemplateColumns: 'minmax(0, 1fr) auto minmax(0, 1fr)',
  gap: theme.spacing(0.5),
}))

const CompareTypography = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '1.25rem',
  margin: theme.spacing(1.5, 0),
}))

const DatePickersRow: FC<IDatePickersRowProps> = ({ values, isCompare, setValues }) => {
  const { i18n } = useI18nContext()
  const { compareStatusesRef } = useDateTimeRangePickerContext()

  return (
    <DatePickersWrapper>
      <CustomDateTimePicker
        values={values}
        setValues={setValues}
        labelText={i18n.text('datetimepicker.start-date')}
        type="startDate"
        onFocus={() => {
          compareStatusesRef.current = {
            from: isCompare ? false : true,
            to: isCompare ? false : true,
            compareFrom: true,
            compareTo: true
          }
        }}
        error={(values.startDate && values.endDate && values.startDate > values.endDate) || undefined}
      />
      {' - '}
      <CustomDateTimePicker
        values={values}
        setValues={setValues}
        labelText={i18n.text('datetimepicker.end-date')}
        type="endDate"
        onFocus={() => {
          compareStatusesRef.current = {
            from: false,
            to: isCompare ? false : true,
            compareFrom: isCompare ? false : true,
            compareTo: true
          }
        }}
        error={(values.startDate && values.endDate && values.startDate > values.endDate) || undefined}
      />
    </DatePickersWrapper>
  )
}

export const CompareDateTimeForm: FC<ICompareDateTimeFormProps> = ({ fromToValues, comparedFromToValues }) => {
  const { i18n } = useI18nContext()
  const { handleStartEndDates, handleCompareStartEndDates } = useDateTimeRangePickerContext()
  return (
    <Box>
      <DatePickersRow
        values={fromToValues}
        setValues={handleStartEndDates}
      />
      <CompareTypography>{i18n.text('contract-benchmark.step-1.compare')}</CompareTypography>
      <DatePickersRow
        values={comparedFromToValues}
        setValues={handleCompareStartEndDates}
        isCompare
      />
    </Box>
  )
}
