import { Box, List, ListItem, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import MUILoader from '../../../Shared/components/MUIComponents/Loader'
import { DEFAULT_TIMEZONE } from '../../../Shared/constants/timezone'
import {
  VibrationThresholdAlertEvent,
  useGetMessagesQuery,
  useGetThresholdAlertMessagesQuery,
} from '../../../Shared/graphql/codegen'
import { VibrationThresholdAlertsList } from './VibrationThresholdAlertsList'
import {
  groupMessagesPerDay,
  groupMessagesUnderRelativeDayLabel,
  groupThresholdEventsUnderRelativeDayLabel,
} from '../../containers/Messages/utils'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

export const AlertsListMini = () => {
  const { loading, error, data } = useGetMessagesQuery({ variables: { last: 10 } })
  const { selectedCustomer } = useCurrentUser()
  const { data: thresholdAlertData } = useGetThresholdAlertMessagesQuery({ variables: { last: 10 } })

  const thresholdAlertEvents = thresholdAlertData?.vibrationThresholdAlertEvents as VibrationThresholdAlertEvent[]

  const { userLocale, i18n } = useI18nContext()
  const theme = useTheme()

  const timezone = selectedCustomer?.timeZone ?? DEFAULT_TIMEZONE

  const messages = useMemo(() => {
    return (
      data?.myOrg?.alertNotificationEvents.map(event => ({
        id: event.id,
        time: event.time,
        message: event.message,
        subjects: event.subjects,
      })) ?? []
    )
  }, [data])

  const messagesGroupedByDay = groupMessagesPerDay(timezone, messages)
  const dailyMessages = groupMessagesUnderRelativeDayLabel(timezone, userLocale, messagesGroupedByDay)
  const thresholdEventsGroupedByDay = groupThresholdEventsUnderRelativeDayLabel(timezone, thresholdAlertEvents)

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <MUILoader
          height={4}
          margin="0 0 2rem 0"
        />
      </Box>
    )
  }

  if (error || !data) {
    return (
      <Typography
        variant="h6"
        fontWeight={400}
        textAlign="center"
      >
        {i18n.text('notifications.messages.error')}
        <br /> {i18n.text('notifications.messages.error.more')}
      </Typography>
    )
  }

  return (
    <Box>
      <List>
        {
          // only show headers if we're also showing pdm alerts
          thresholdAlertEvents?.length !== 0 && (
            <Typography
              variant="h6"
              color={theme.palette.SFIBrand[900]}
              mt={1}
              mb={1}
              fontWeight={500}
            >
              {i18n.text('notifications.messages.energy.header')}
            </Typography>
          )
        }
        {!messages.length && !thresholdAlertEvents?.length ? (
          <Typography
            variant="body1"
            fontWeight="400"
            color={theme.palette.SFIGreyLight[800]}
          >
            {i18n.text('messages.error.no-selected-assets.body')}
          </Typography>
        ) : (
          Object.keys(dailyMessages).map(day => (
            <Box key={day}>
              <Typography
                variant="subtitle2"
                color={theme.palette.SFIGreyLight[50]}
                sx={{
                  backgroundColor: theme.palette.SFIGreyLight[950],
                  width: 'fit-content',
                  fontWeight: 200,
                  padding: '0.05rem 0.35rem',
                }}
              >
                {day.substring(0, 1).toUpperCase() + day.substring(1)}
              </Typography>
              <Box>
                <List>
                  {dailyMessages[day].map(message => (
                    <ListItem
                      key={message.id}
                      sx={{ paddingLeft: 0.35, paddingTop: 0.5, paddingBottom: 0 }}
                    >
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.SFIGreyLight[800]}
                        minWidth="40px"
                      >
                        {DateTime.fromISO(message.time).toFormat('HH:mm')}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        ml={1}
                      >
                        {message.message}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          ))
        )}
        {thresholdAlertEvents?.length !== 0 && (
          <Typography
            variant="h6"
            color={theme.palette.SFIBrand[900]}
            mt={1}
            mb={1}
            fontWeight={500}
          >
            {i18n.text('notifications.messages.thresholds.header')}
          </Typography>
        )}
        <VibrationThresholdAlertsList
          thresholdEventsGroupedByDay={thresholdEventsGroupedByDay}
          maxDays={3}
        />
      </List>
    </Box>
  )
}
