import { useMemo } from 'react'

import { useOEEContext } from '../context/OEEContext'

const useLocationId = () => {
  const { selectedMachine, assets } = useOEEContext()

  const selectedGroup = useMemo(
    () => assets?.find(asset => asset.machines.find(machine => machine.id === selectedMachine)),
    [assets, selectedMachine]
  )

  const selectedMachineObject = useMemo(
    () => selectedGroup?.machines.find(machine => machine.id === selectedMachine),
    [selectedGroup, selectedMachine]
  )

  const locationId = selectedMachineObject?.enoceanPulseCounterSensorLocations?.[0]?.id

  return { selectedMachineObject, locationId }
}

export default useLocationId
