import { Box, styled } from '@mui/material'
import { FC } from 'react'

import { SemiboldBrandLightLgText, SemiboldBrandLightXlDisplayText, SemiboldDarkLgText } from '../../Common/v1'

const InsightBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const NumberUnitBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.125rem',
}))

type InputProps = {
  metric: number
  unitText: string
  titleText: string
}

export const Insight: FC<InputProps> = ({ metric, unitText, titleText }) => {
  return (
    <InsightBox>
      <NumberUnitBox>
        <SemiboldBrandLightXlDisplayText>{metric}</SemiboldBrandLightXlDisplayText>
        <SemiboldBrandLightLgText>{unitText}</SemiboldBrandLightLgText>
      </NumberUnitBox>
      <SemiboldDarkLgText sx={{ textAlign: 'center', padding: '0 1rem' }}>{titleText}</SemiboldDarkLgText>
    </InsightBox>
  )
}
