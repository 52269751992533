import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react'
import { Add, Remove } from '@mui/icons-material'
import { Button, MenuItem, Select, styled } from '@mui/material'

import PhoneInput from '../../../Shared/components/PhoneInput'
import { Recipient as RecipientType, SupportedTransport } from './types'
import { StyledTooltip } from '../../../Shared/components/MUIComponents/Tooltip'

const RecipientWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  marginBottom: '1em',
})

const StyledLabel = styled('label')({
  fontSize: '1rem',
  display: 'block',
})

const baseInputStyle = {
  display: 'block',
  border: 'none',
  outline: 'none',
  borderRadius: '4px',
  padding: '1em',
  fontSize: '13px',
  textAlign: 'left',
}

const Group = styled('div')(({ theme }) => ({
  margin: '0em 1em 0 0',
  '& > label': {
    fontSize: '13px',
    marginBottom: '0.3em',
  },
  '& > div button': {
    ...baseInputStyle,
    backgroundColor: theme.palette.SFIBase.white,
  },
}))

// The nucleus input can't update its state so we're using this as a quick fix.
const NativeInput: FC<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = props => (
  <input {...props} />
)

const StyledInput = styled(NativeInput)({
  border: 'none',
  borderRadius: '0.125em',
  padding: '1em',
  outline: 'none',
  backgroundColor: 'white',
  fontSize: '0.875em',
  '&[type="number"]': {
    maxWidth: '7.8em',
    display: 'inline-block',
  },
  '&[type="text"]': {
    width: '38.3em',
  },
})

const StyledDropdownSmall = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBase.white,
  width: '7.8em',
  height: '44px',
  '&::before': {
    borderBottom: 'none',
  },
}))

const ActionsBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 'auto',
  paddingTop: '1em',
})

const ActionButton = styled(Button)({
  margin: 0,
  minWidth: '2rem',
})

interface RecipientProps {
  i18n: $I18FixMe
  onChange: (recipient: RecipientType) => void
  onDelete: () => void
  onAdd: () => void
  recipient: RecipientType
  removeDisabled: boolean
  transportOptions: SupportedTransport[]
}

const Recipient = ({
  i18n,
  onChange,
  onDelete,
  onAdd,
  recipient,
  removeDisabled = false,
  transportOptions,
}: RecipientProps): JSX.Element => {
  function _onChange(change: Partial<RecipientType>) {
    const updatedRecipient = { ...recipient, ...change } as RecipientType
    onChange(updatedRecipient)
  }

  function _onTransportChange(transport: SupportedTransport) {
    if (transport === recipient.transport) return _onChange({ transport })

    if (transport === 'email') return _onChange({ transport, destination: '' })
    if (transport === 'webhook') return _onChange({ transport, destination: 'https://' })
    if (transport === 'whatsapp') return _onChange({ transport, destination: { dialCode: '+31', phoneNumber: '' } })

    return _onChange({ transport: 'email', destination: '' })
  }

  const allTransportOptions: { key: SupportedTransport; option: string }[] = [
    // { key: 'sms', label: i18n.text('rules.recipients.sms') },
    { key: 'email', option: i18n.text('rules.recipients.email') },
    { key: 'whatsapp', option: i18n.text('rules.recipients.whatsapp') },
    { key: 'webhook', option: i18n.text('rules.recipients.webhook') },
  ]
  const transportSelectOptions = allTransportOptions.filter(option => transportOptions.includes(option.key))

  return (
    <RecipientWrapper>
      <Group>
        <StyledLabel>{i18n.text('rules.headers.recipients.type')}</StyledLabel>
        <StyledDropdownSmall
          id="message-transport-select"
          variant="standard"
          value={recipient.transport}
          onChange={({ target: { value } }) => _onTransportChange(value as SupportedTransport)}
          disabled={transportSelectOptions.length === 1}
        >
          {transportSelectOptions.map(option => (
            <MenuItem
              key={option.key}
              value={option.key}
            >
              {option.option}
            </MenuItem>
          ))}
        </StyledDropdownSmall>
      </Group>
      <Group>
        <StyledLabel>{i18n.text('rules.recipients.name')}</StyledLabel>
        <StyledInput
          id="recipient-name-input"
          type="text"
          style={{ width: '20em' }}
          value={recipient.name}
          placeholder={i18n.text('rules.placeholders.recipients.name')}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => _onChange({ name: value })}
        />
      </Group>
      <Group>
        <StyledLabel>{i18n.text('rules.recipients.destination')}</StyledLabel>
        {recipient.transport === 'email' || recipient.transport === 'webhook' ? (
          <StyledInput
            id="recipient-email-input"
            type="email"
            style={{ width: '20em' }}
            value={recipient.destination}
            placeholder={
              recipient.transport === 'email'
                ? i18n.text('rules.placeholders.recipients.email')
                : i18n.text('rules.placeholders.recipients.webhook')
            }
            onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => _onChange({ destination: value })}
          />
        ) : (
          <PhoneInput
            id="recipient-phone-input"
            key={`recipient-phone-input-${recipient.destination.dialCode}`}
            style={{ width: '20em' }}
            dialCodeValue={recipient.destination.dialCode}
            phoneNumberValue={recipient.destination.phoneNumber}
            placeholder={i18n.text('rules.placeholders.recipients.sms')}
            onDialCodeSelect={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
              _onChange({ destination: { ...recipient.destination, dialCode: value } })}
            onNumberChange={(value: string) =>
              _onChange({ destination: { ...recipient.destination, phoneNumber: value } })}
          />
        )}
      </Group>
      <ActionsBar>
        <StyledTooltip title={i18n.text('rules.button.duplicate')}>
          <ActionButton
            id="add-recipient"
            onClick={onAdd}
          >
            <Add
              sx={{
                width: '1.25rem',
                height: '1.25rem',
              }}
            />
          </ActionButton>
        </StyledTooltip>
        <StyledTooltip title={i18n.text('rules.button.remove')}>
          <div>
            <ActionButton
              id="remove-recipient"
              disabled={removeDisabled}
              onClick={onDelete}
            >
              <Remove
                sx={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              />
            </ActionButton>
          </div>
        </StyledTooltip>
      </ActionsBar>
    </RecipientWrapper>
  )
}

export default Recipient
