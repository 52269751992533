import SortableCollapsableTable from '@sensorfactdev/nucleus/SortableCollapsableTable'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { useContext } from 'react'

import ThresholdAlertMessages from './ThresholdAlertMessages/index'
import usePropositions, { CurrentPropositions } from '../../../Shared/hooks/usePropositions/usePropositions'
import { AlertMessage, AlertMessageAsset } from './types'
import { DEFAULT_TIMEZONE } from '../../../Shared/constants/timezone'
import { I18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { getTableCols, groupMessagesPerDay } from './utils'
import { themeColors } from '../../../Shared/utils'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useGetMessagesQuery } from '../../../Shared/graphql/codegen'

const MESSAGE_COUNT = 50

const LocalWrapper = styled.div`
  text-align: left;
`
const WrapperInner = styled.div``
const TableWrapper = styled.div`
  margin-bottom: 1em;
`
const StyledSubheading = styled.small`
  && {
    color: ${themeColors.grey};
  }
`

function formatMessagesForTable(messages: AlertMessage[], assets: AlertMessageAsset[], timezone: string) {
  return messages.map(({ time, subjects, message }) => {
    const messageAssets = subjects.map((subj: string) => {
      const elements = subj.split('.')
      const assetId = elements[elements.length - 1]
      return assets.find(asset => asset.id === assetId)
    })
    return {
      date: DateTime.fromISO(time).setZone(timezone).toFormat('HH:mm'),
      assets: (
        <ul key={time}>
          {messageAssets.map(asset => (
            <li key={asset?.name}>{asset?.name ?? '-'}</li>
          ))}
        </ul>
      ),
      message,
      id: time,
      empty: '',
    }
  })
}

const Messages = () => {
  const { i18n } = useContext(I18nContext)
  const { selectedCustomer } = useCurrentUser()
  const { hasProposition } = usePropositions()
  const hasPdm = hasProposition(CurrentPropositions['PREDICTIVE-MAINTENANCE'])
  const timezone = selectedCustomer?.timeZone ?? DEFAULT_TIMEZONE
  const { data, loading } = useGetMessagesQuery({ variables: { last: MESSAGE_COUNT } })

  if (loading) {
    return <div>{i18n.text('app.loading')}</div>
  }
  const messages =
    data?.myOrg?.alertNotificationEvents.map(event => ({
      id: event.id,
      time: event.time,
      message: event.message,
      subjects: event.subjects,
    })) ?? []
  const assets = data?.myOrg?.assets.map(asset => ({ id: asset.id, name: asset.name })) ?? []
  const messagesGroupedByDay = groupMessagesPerDay(timezone, messages)

  return (
    <LocalWrapper>
      <h1>
        {i18n.text('notifications.messages.title')}
        <StyledSubheading>{i18n.text('notifications.messages.description')}</StyledSubheading>
      </h1>
      <WrapperInner>
        {messagesGroupedByDay.map(day => (
          <TableWrapper key={day[0].time}>
            <SortableCollapsableTable
              i18n={i18n}
              cols={getTableCols(timezone, day, i18n)}
              data={formatMessagesForTable(day, assets, timezone)}
              highlight={['message']}
            />
          </TableWrapper>
        ))}
      </WrapperInner>
      {hasPdm && <ThresholdAlertMessages />}
    </LocalWrapper>
  )
}

export default Messages
