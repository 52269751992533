import { Box } from '@mui/material'
import { FC } from 'react'
import { useLocation } from 'react-router'

import AssetTree from '../../components/AssetTree/AssetTree'

const AssetTreeLayout: FC = ({ children }) => {
  const location = useLocation()
  const isOverviewPage = location.pathname.includes('overview')

  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        padding: isOverviewPage ? '1.75rem' : '1rem',
        gridTemplateColumns: isOverviewPage ? '1fr' : 'auto 1fr',
        gap: '1rem',
        overflowY: 'auto',
      }}
    >
      {!isOverviewPage && <AssetTree />}
      {children}
    </Box>
  )
}

export default AssetTreeLayout
