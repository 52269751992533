import { Box, Switch, styled } from '@mui/material'
import { Typography } from 'antd'

export const SwitchWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.75),
  gridColumn: 1,
  gridRow: 2,
  borderRight: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  overflow: 'hidden',
}))

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 24,
  borderRadius: '12px',
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 20,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: theme.palette.SFIBase.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.SFIBrand?.[600],
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: `0px 1px 2px 0px ${theme.palette.SFIGreyLight[900]}0F, 0px 1px 3px 0px ${theme.palette.SFIGreyLight[900]}1A`,
    width: 20,
    height: 20,
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 100,
    }),
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    borderRadius: '12px',
    backgroundColor: theme.palette.SFIGreyLight[200],
  },
}))

export const SmallCustomSwitch = styled(CustomSwitch)(({ theme }) => ({
  width: 36,
  height: 20,
  borderRadius: '9px',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 16,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(6px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      transform: 'translateX(16px)',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    borderRadius: '50%',
  },
  '& .MuiSwitch-track': {
    borderRadius: '9px',
  },
}))

export const SwitchLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[700],
  fontSize: '14px',
  lineHeight: '1.25rem',
  fontWeight: 400,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}))
