import {
  AnalyticsEventTypes,
  AnalyticsIntent,
  AnalyticsPropositionType,
  CheckAlertsPopup,
  CheckAllAlerts,
  CreateAlertEMOEE,
  VisitShop,
} from '../../Shared/hooks/useAnalytics/analyticsTypes'

export const trackCheckAllAlertsEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: CheckAllAlerts) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.CHECK_ALL_ALERTS,
    target: 'all_alerts_link_popup',
  })
}

export const trackCheckAlertsPopupEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: CheckAlertsPopup) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.CHECK_ALERTS_POPUP,
    target: 'nav_alerts_button',
  })
}

export const trackShopNavigationEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: VisitShop) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.VISIT_SHOP,
    target: 'nav_shop_button',
  })
}

export const sendCreateAlertEvent = (
  target: 'create_standby_alert_rule_form' | 'create_rule_based_alert_form',
  proposition:
    | AnalyticsPropositionType.ELECTRICITY
    | AnalyticsPropositionType.WATER
    | AnalyticsPropositionType.GAS
    | AnalyticsPropositionType.OEE,
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: CreateAlertEMOEE) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition,
    intent: AnalyticsIntent.CREATE_ALERT,
    target,
  })
}

export function getAnalyticsPropositionByCommodityType(
  commodityType: 'electric-current' | 'natural-gas' | 'water' | 'production-cycles'
) {
  switch (commodityType) {
    case 'electric-current':
      return AnalyticsPropositionType.ELECTRICITY
    case 'natural-gas':
      return AnalyticsPropositionType.GAS
    case 'water':
      return AnalyticsPropositionType.WATER
    case 'production-cycles':
      return AnalyticsPropositionType.OEE
    default:
      return AnalyticsPropositionType.ELECTRICITY
  }
}
