import { createSvgIcon } from '@mui/material'

export const PlaceholderImageIcon = createSvgIcon(
  <svg
    viewBox="0 0 40 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd_7035_19677)">
      <path
        d="M4.75 6C4.75 4.20508 6.20508 2.75 8 2.75H24C24.1212 2.75 24.2375 2.79816 24.3232 2.88388L35.1161 13.6768C35.2018 13.7625 35.25 13.8788 35.25 14V38C35.25 39.7949 33.7949 41.25 32 41.25H8C6.20507 41.25 4.75 39.7949 4.75 38V6Z"
        stroke="#D0D5DD"
        strokeWidth="1.5"
      />
      <path
        d="M24 2.5V10C24 12.2091 25.7909 14 28 14H35.5"
        stroke="#D0D5DD"
        strokeWidth="1.5"
      />
      <path
        d="M25.2499 32.75H26.0076C26.736 32.75 27.1002 32.75 27.301 32.5981C27.4758 32.4658 27.5841 32.2636 27.5971 32.0447C27.6121 31.7934 27.4101 31.4904 27.0061 30.8844L24.7483 27.4977C24.4145 26.9969 24.2476 26.7465 24.0372 26.6593C23.8532 26.583 23.6465 26.583 23.4626 26.6593C23.2522 26.7465 23.0852 26.9969 22.7514 27.4977L22.1933 28.3349M25.2499 32.75L19.4865 24.4251C19.155 23.9464 18.9893 23.707 18.7823 23.6228C18.6012 23.5492 18.3985 23.5492 18.2174 23.6228C18.0104 23.707 17.8447 23.9464 17.5132 24.4251L13.0535 30.8669C12.6312 31.4769 12.4201 31.7819 12.4321 32.0355C12.4426 32.2564 12.55 32.4614 12.7257 32.5957C12.9274 32.75 13.2983 32.75 14.0401 32.75H25.2499ZM26.7499 21.5C26.7499 22.7426 25.7425 23.75 24.4999 23.75C23.2572 23.75 22.2499 22.7426 22.2499 21.5C22.2499 20.2574 23.2572 19.25 24.4999 19.25C25.7425 19.25 26.7499 20.2574 26.7499 21.5Z"
        stroke="#4793FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_7035_19677"
        x="-3"
        y="0"
        width="46"
        height="46"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7035_19677"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_7035_19677"
          result="effect2_dropShadow_7035_19677"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_7035_19677"
          result="shape"
        />
      </filter>
    </defs>
  </svg>,
  'placeholder-image-icon'
)
