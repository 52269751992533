import SwipeableViews from 'react-swipeable-views'
import React, { useState } from 'react'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Box, BoxTypeMap, Button, styled } from '@mui/material'

const CarouselButton = styled(Button)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[100],
  borderRadius: '0',
  position: 'absolute',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    color: theme.palette.SFIBrand[300],
  },
  '&.Mui-disabled': {
    pointerEvents: 'auto',
  },
}))

export type CarouselProps = {
  images: string[]
  containerProps?: BoxTypeMap['props']
  imageProps?: BoxTypeMap['props']
  onClick?: (image: string, index: number) => void
}

const Carousel: React.FC<CarouselProps> = ({ images, containerProps, imageProps, onClick }) => {
  const [index, setIndex] = useState(0)
  const max = images.length

  const _onClick = onClick || (() => undefined)

  const handleNext = () => {
    setIndex(prevIndex => prevIndex + 1)
  }

  const handleBack = () => {
    setIndex(prevIndex => prevIndex - 1)
  }

  return (
    <Box
      data-testid="carousel-container"
      {...containerProps}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        ...containerProps?.sx,
      }}
      borderRadius={'0.25rem'}
    >
      <SwipeableViews
        index={index}
        axis="x"
      >
        {images.map((image, i) => (
          <Box
            key={image}
            component={'img'}
            {...imageProps}
            src={image}
            onClick={() => _onClick(image, i)}
            data-testid="carousel-image"
          />
        ))}
      </SwipeableViews>
      {images.length > 1 && (
        <>
          <CarouselButton
            onClick={handleBack}
            disabled={index === 0}
            sx={{ top: 0, left: 0 }}
            data-testid="carousel-button-prev"
          >
            <ArrowBack fontSize="small" />
          </CarouselButton>
          <CarouselButton
            onClick={handleNext}
            disabled={index === max - 1}
            sx={{ top: 0, right: 0 }}
            data-testid="carousel-button-next"
          >
            <ArrowForward fontSize="small" />
          </CarouselButton>
        </>
      )}
    </Box>
  )
}

export default Carousel
