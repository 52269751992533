import { ApolloClient } from '@apollo/client'
import { DateTime } from 'luxon'

import { ASSETS_FETCHED, BRIDGES_FETCHED, FETCH_ASSETS, FETCH_BRIDGES } from './constants'
import {
  SensorsInstallationAssetsDocument,
  SensorsInstallationAssetsQuery,
  SensorsInstallationAssetsQueryVariables,
  SensorsInstallationBridgesDocument,
  SensorsInstallationBridgesQuery,
  SensorsInstallationBridgesQueryVariables,
} from '../../../../Shared/graphql/codegen'
import { handleGenericError } from '../../../../Shared/utils'
import { toLocalDateTime } from '../../../../Shared/utils'

function fetchAssets(client: ApolloClient<unknown>, from: Date, to: Date) {
  return async (dispatch: $TSFixMeDispatch) => {
    dispatch({ type: FETCH_ASSETS })

    try {
      const variables = {
        from: toLocalDateTime(DateTime.fromJSDate(from)),
        to: toLocalDateTime(DateTime.fromJSDate(to)),
      }
      const { data } = await client.query<SensorsInstallationAssetsQuery, SensorsInstallationAssetsQueryVariables>({
        query: SensorsInstallationAssetsDocument,
        variables,
      })

      const assets = {
        assetTree: data?.myOrg?.assetTree ?? [],
        flatAssets: data?.myOrg?.flatAssets ?? [],
      }

      dispatch({ type: ASSETS_FETCHED, payload: { assets } })
    } catch (err) {
      handleGenericError(ASSETS_FETCHED, err, dispatch)
    }
  }
}

function fetchBridges(client: ApolloClient<unknown>) {
  return async (dispatch: $TSFixMeDispatch) => {
    dispatch({ type: FETCH_BRIDGES })

    try {
      const { data } = await client.query<SensorsInstallationBridgesQuery, SensorsInstallationBridgesQueryVariables>({
        query: SensorsInstallationBridgesDocument,
        fetchPolicy: 'no-cache',
      })
      const bridges = data?.myOrg?.bridges ?? []

      dispatch({ type: BRIDGES_FETCHED, payload: { bridges } })
    } catch (err) {
      handleGenericError(BRIDGES_FETCHED, err, dispatch)
    }
  }
}

const actionCreators = {
  fetchAssets,
  fetchBridges,
}

export default actionCreators
