import React, { useState } from 'react'

import CSVExportModal from '../../../Shared/components/MUIComponents/update/CSVExportModal/CSVExportModal'
import { DownloadIcon } from '../../../Shared/components/icons'
import { StyledMenu } from '../../../Shared/components/MUIComponents/update/StyledMenu'
import { StyledMenuItem } from '../../../Shared/components/MUIComponents/update/StyledMenu/StyledMenuItem'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type DataExporterProps<T> = {
  data: T[]
  customFileName?: string
}

function DataExporter<T>({ customFileName, data }: DataExporterProps<T>) {
  const [isCSVModalOpen, setIsCSVModalOpen] = useState<boolean>(false)

  const { i18n } = useI18nContext()

  const handleCSVExportOpen = () => {
    setIsCSVModalOpen(true)
  }

  const handleCSVExportClose = () => {
    setIsCSVModalOpen(false)
  }

  return (
    <>
      <StyledMenu
        menuButtonContent={
          <>
            <DownloadIcon
              sx={{
                width: '15px',
                height: '15px',
              }}
            />
            {i18n.text('graph.button.tools.export')}
          </>
        }
      >
        <StyledMenuItem onClick={handleCSVExportOpen}>Export to .csv</StyledMenuItem>
      </StyledMenu>
      {isCSVModalOpen && (
        <CSVExportModal
          customFileName={customFileName}
          isOpen={isCSVModalOpen}
          onClose={handleCSVExportClose}
          data={data}
        />
      )}
    </>
  )
}

export default DataExporter
