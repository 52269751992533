import { Box, styled, useTheme } from '@mui/material'
import { FC } from 'react'

import { SemiboldDarkMdDisplayText } from '../../../components/Common/v1/index'
import { TextBlock } from '../../../components/Methodology/v1/TextBlock'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.SFIGreyNeutral[50],
  width: '100%',
}))

const ContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.SFIGreyNeutral[50],
  width: '100%',
  flexDirection: 'column',
  backgroundPosition: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '3rem 0',
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  maxWidth: theme.breakpoints.values.xl,
  marginLeft: 'auto',
  marginRight: 'auto',
}))

type InputProps = {
  CO2_value: number
  source: string
}
export const Methodology: FC<InputProps> = ({ CO2_value, source }) => {
  const TEXT_WIDTH_RATIO = 0.4
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const textBlocks = [
    {
      title: i18n.text(`scan.intro.methodology.precision-title`),
      subTitle: i18n.text(`scan.intro.methodology.precision-subtitle`),
    },
    {
      title: i18n.text(`scan.intro.methodology.numbers-title`),
      subTitle: i18n.text(`scan.intro.methodology.numbers-subtitle`),
    },
    {
      title: i18n.text(`scan.intro.methodology.CO2-title`),
      subTitle: i18n.text(`scan.intro.methodology.CO2-subtitle`),
    },
  ]

  return (
    <BackgroundBox>
      <ContentBox>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            loading="lazy"
            alt="Coding image"
            src={
              'https://res.cloudinary.com/dj4ypr91s/image/upload/v1723790583/online_energy_scan_static/codingImage.png'
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: theme.breakpoints.values.xl * TEXT_WIDTH_RATIO,
          }}
        >
          <SemiboldDarkMdDisplayText sx={{ paddingBottom: '2.75rem' }}>
            {i18n.text(`scan.intro.methodology.title`)}
          </SemiboldDarkMdDisplayText>
          {textBlocks.map((textBlock, index) => (
            <TextBlock
              key={index}
              title={textBlock.title}
              subTitle={textBlock.subTitle}
            />
          ))}
        </Box>
      </ContentBox>
    </BackgroundBox>
  )
}
