import { Box, RadioGroup } from '@mui/material'
import { FC } from 'react'

import { FrequencySelectProps } from '../types'
import { StyledFormControlLabel } from '../../../../../Shared/components/MUIComponents/update/Radio/StyledFormControlLabel'
import { StyledInputFieldHeader } from '../Common'
import { StyledRadio } from '../../../../../Shared/components/MUIComponents/update/Radio/StyledRadio'
import { useGetAvailableFrequenciesQuery } from '../../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const FrequencySelect: FC<FrequencySelectProps> = ({ frequency, handlefrequencychange }) => {
  const { i18n } = useI18nContext()
  const { data: availableFrequenciesData } = useGetAvailableFrequenciesQuery()
  const availableFrequencies = availableFrequenciesData?.trackingReportFrequecies.map(a => a.value)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.375rem',
      }}
    >
      <StyledInputFieldHeader>{i18n.text(`reports.tracking.configuration.general.frequency`)}</StyledInputFieldHeader>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignContent: 'left',
        }}
      >
        <RadioGroup
          row
          value={frequency}
          onChange={handlefrequencychange}
        >
          {availableFrequencies &&
            availableFrequencies.map((frequency, index) => (
              <StyledFormControlLabel
                key={index}
                value={frequency}
                control={<StyledRadio />}
                label={i18n.text(`reports.tracking.configuration.general.${frequency}`)}
              />
            ))}
        </RadioGroup>
      </Box>
    </Box>
  )
}
