import React, { FC } from 'react'
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  ToggleButtonProps,
  styled,
  useTheme,
} from '@mui/material'

const StyledToggleButtonGroupWrapper = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  borderRadius: theme.spacing(0.75),
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  width: 'fit-content',
  overflow: 'hidden',
  maxHeight: theme.spacing(5),
  background: theme.palette.SFIBase.white,
}))

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: '0.5rem 1rem',
  border: 'none',
  borderRight: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  minWidth: '42px',
  fontSize: '14px',
  color: theme.palette.SFIGreyLight[700],
  fontWeight: 600,
  textTransform: 'initial',
  borderRadius: 0,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  '&:last-of-type': {
    borderRight: 'none!important',
  },
  '&.Mui-disabled': {
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    borderWidth: '0 1px 0 0',
  },
}))

export type StyledToggleButtonGroupItem = {
  value: string
  title?: string
}

interface IStyledToggleButtonGroupProps {
  items: StyledToggleButtonGroupItem[]
  selectedItem: StyledToggleButtonGroupItem['value']
  slotProps?: {
    wrapper?: ToggleButtonGroupProps
    button?: ToggleButtonProps['sx']
  }
  onChange: (newValue: string) => void
}

export const StyledToggleButtonGroup: FC<IStyledToggleButtonGroupProps> = ({
  items,
  selectedItem,
  slotProps,
  onChange,
}) => {
  const theme = useTheme()

  function handleChange(event: React.MouseEvent<HTMLElement>, newValue: StyledToggleButtonGroupItem['value']) {
    onChange(newValue)
  }

  return (
    <StyledToggleButtonGroupWrapper
      color="primary"
      value={selectedItem}
      exclusive
      onChange={handleChange}
      aria-label="Toggle buttons"
      {...slotProps?.wrapper}
    >
      {items.map(item => {
        const isDisabled = item.value === selectedItem
        return (
          <StyledToggleButton
            key={item.value}
            value={item.value}
            disabled={isDisabled}
            sx={{ background: isDisabled ? theme.palette.SFIGreyLight[100] : 'transparent', ...slotProps?.button }}
          >
            {item.title || item.value}
          </StyledToggleButton>
        )
      })}
    </StyledToggleButtonGroupWrapper>
  )
}
