import * as z from 'zod'
import {
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'

import MUILoader from '../../../../Shared/components/MUIComponents/Loader'
import { ID } from '../../../../Shared/types/types'
import {
  MeasurementTypeInput,
  ThresholdAlertConditionInput,
  ThresholdConstraintInput,
  useCreateProbeLocationThresholdsMutation,
  useDeleteProbeLocationThresholdsMutation,
  useUpdateProbeLocationThresholdsMutation,
} from '../../../../Shared/graphql/codegen'
import { ParsedAlertRule } from '../utils'
import { StyledAlert } from '../../../components/AlertBox/StyledAlert'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const DEFAULT_THRESHOLD_LEVELS = {
  WARNING: 4.5,
  DANGER: 7.1,
}

type FormInputs = {
  warningLevel: number
  dangerLevel: number
}

interface ConfigureThresholdAlertDialogProps {
  open: boolean
  assetId: string
  rule: ParsedAlertRule | null
  onClose: (thresholdsUpdates: boolean) => void
}
export function ConfigureThresholdAlertDialog(props: ConfigureThresholdAlertDialogProps) {
  const { onClose, rule, open, assetId } = props
  const { i18n } = useI18nContext()

  const [createRuleMutation, { loading: creatingRule, error: createRuleError }] =
    useCreateProbeLocationThresholdsMutation()
  const [updateRuleMutation, { loading: updatingRule, error: updateRuleError }] =
    useUpdateProbeLocationThresholdsMutation()
  const [deleteRuleMutation, { loading: deletingRule, error: deleteRuleError }] =
    useDeleteProbeLocationThresholdsMutation()

  const formSchema = z
    .object({
      warningLevel: z.coerce.number().min(0.000001, i18n.text('pdm.invalid-threshold-min-value')),
      dangerLevel: z.coerce.number().min(0.000001, i18n.text('pdm.invalid-threshold-min-value')),
    })
    .refine(obj => obj.dangerLevel > obj.warningLevel, {
      path: ['dangerLevel'],
      message: i18n.text('pdm.invalid-threshold-order'),
    })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    reset({
      warningLevel: rule?.thresholds[0] ?? DEFAULT_THRESHOLD_LEVELS.WARNING,
      dangerLevel: rule?.thresholds[1] ?? DEFAULT_THRESHOLD_LEVELS.DANGER,
    })
  }, [reset, rule])

  useEffect(() => {
    reset({
      warningLevel: rule?.thresholds[0],
      dangerLevel: rule?.thresholds[1],
    })
  }, [reset, rule])

  if (!open) return <></>

  const title = rule ? i18n.text('pdm.edit-threshold-alert-rule') : i18n.text('pdm.create-threshold-alert-rule')

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    const condition: ThresholdAlertConditionInput[] = [
      {
        assetId: (rule?.assetId as ID) || (assetId as ID),
        thresholds: [data.warningLevel, data.dangerLevel],
        constraint: ThresholdConstraintInput.Above,
        measurementType: MeasurementTypeInput.VelocityRms,
        evaluationMode: rule?.evaluationMode || 'CONTINUOUS_INTERVAL',
        duration: rule?.duration || 0,
        measurementCount: rule?.measurementCount,
      },
    ]
    if (rule) {
      await updateRuleMutation({
        variables: {
          ruleId: rule.id as ID,
          condition: condition,
        },
      })
    } else {
      await createRuleMutation({
        variables: {
          name: `${assetId}_thresholds`,
          condition: condition,
        },
      })
    }

    onClose(true)
  }

  const onDeleteClick = async (ruleId: string) => {
    await deleteRuleMutation({
      variables: {
        ruleId: ruleId as ID,
      },
    })

    onClose(true)
  }

  const handleClose = () => {
    onClose(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText marginBottom="2em">{i18n.text('pdm.configure-vibration-thresholds')}</DialogContentText>
        {(creatingRule || updatingRule || deletingRule) && (
          <Box>
            <MUILoader />
          </Box>
        )}
        {!creatingRule && !updatingRule && !deletingRule && (
          <>
            <Typography
              variant="h6"
              fontWeight={400}
            >
              {i18n.text('pdm.graphs.velocity-rms')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}>
                <Controller
                  name="warningLevel"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                    />
                  )}
                />
                <Box sx={{ marginLeft: '1em' }}>
                  <StyledAlert severity="warning">
                    <AlertTitle>{i18n.text('pdm.warning-level')}</AlertTitle>
                    {i18n.text('pdm.set-warning-level')}
                  </StyledAlert>
                </Box>
              </Box>
              {errors.warningLevel && (
                <Typography
                  variant="h6"
                  color="red"
                  fontWeight={400}
                >
                  {errors.warningLevel.message}
                </Typography>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}>
                <Controller
                  name="dangerLevel"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                    />
                  )}
                />
                <Box sx={{ marginLeft: '1em' }}>
                  <StyledAlert severity="error">
                    <AlertTitle>{i18n.text('pdm.critical-level')}</AlertTitle>
                    {i18n.text('pdm.set-critical-level')}
                  </StyledAlert>
                </Box>
              </Box>
              {errors.dangerLevel && (
                <Typography
                  variant="h6"
                  color="red"
                  fontWeight={400}
                >
                  {errors.dangerLevel.message}
                </Typography>
              )}
              {(createRuleError || updateRuleError || deleteRuleError) && (
                <Typography
                  variant="h6"
                  color="red"
                  fontWeight={400}
                >
                  {i18n.text('pdm.configure-vibration-thresholds-error')}
                </Typography>
              )}
              <Box sx={{ marginTop: '2em' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={theme => ({
                    textTransform: 'none',
                    color: theme.palette.common.white,
                    fontWeight: 400,
                    fontSize: '0.9rem',
                    marginRight: '1em',
                  })}
                >
                  {i18n.text('generic.save')}
                </Button>
                {!!rule && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => onDeleteClick(rule.id)}
                    sx={theme => ({
                      textTransform: 'none',
                      color: theme.palette.common.white,
                      fontWeight: 400,
                      fontSize: '0.9rem',
                      marginRight: '1em',
                    })}
                  >
                    {i18n.text('pdm.delete-threshold-alert-rule')}
                  </Button>
                )}
              </Box>
            </form>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(false)}
          sx={() => ({
            textTransform: 'none',
            fontWeight: 400,
            fontSize: '0.9rem',
            marginRight: '1em',
          })}
        >
          {i18n.text('generic.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
