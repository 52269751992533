import { Box } from '@mui/material'
import { Interval } from 'luxon'
import { WarningAmber } from '@mui/icons-material'
import { lighten } from '@mui/material'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useFeature } from 'flagged'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import { ChartToolsItem } from '../../../Shared/components/MUIComponents/update/ChartToolsDropdown'
import { DEFAULT_TIMEZONE } from '../../../Shared/constants/timezone'
import { DataPoint, SummedEnergyBalanceSeries, YAxisUnits } from '../../types'
import { EnergyBalanceBarChart } from '../../components/EnergyBalanceChart/EnergyBalanceBarChart'
import { EnergyBalanceCsvExporter } from '../../components/EnergyBalanceCsvExporter'
import { EnergyBalancePieChart } from '../../components/EnergyBalanceChart/EnergyBalancePieChart'
import { EnergyBalanceStandbyBarChart } from '../../components/EnergyBalanceChart/EnergyBalanceStandbyBarChart'
import { EnergyBalanceTable } from '../../components/EnergyBalanceTable'
import { GRAPH_TOOLBAR_DATE_CHIP_FORMAT } from '../../constants'
import { GraphToolbar } from '../../components/GraphToolbar'
import { NonNegativeFloat } from '../../../Shared/types/types'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { adjustMeasurementToMagnitude, getPowerOrderOfMagnitude, getScaledEnergyPriceMap } from '../../../Shared/utils'
import { filterUpToNow } from '../../utils/filterSeriesToNow'
import { getCurrencySymbol } from '../../../Shared/utils/formatCurrency'
import { mapMeasurementsToXY, mapStandbyToXYEnergy } from '../../utils/mapLegacyMeasurements'
import { sendToolbarButtonClickElectricity } from '../../utils/analyticsEvents'
import { useChartToolsContext } from '../../context/ChartToolsContext'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useElectricityContext } from '../../context/ElectricityContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const sumSeries = (series: DataPoint[]): NonNegativeFloat => {
  const total = series.reduce((acc, cur) => {
    if (cur.y === null) return acc
    return (acc + +cur.y) as NonNegativeFloat
  }, 0)
  return total as NonNegativeFloat
}

const EnergyBalanceContainer = () => {
  const {
    showWaste,
    setShowWaste,
    yAxisUnit,
    setYAxisUnit,
    showTable,
    setShowTable,
    energyTableContainerHeight,
    setEnergyTableContainerHeight,
    showOop,
    setShowOop,
  } = useChartToolsContext()
  const [hoveredSeries, setHoveredSeries] = useState<string>('')
  const [chartWidth, setChartWidth] = useState('100%')

  const { i18n, userLocale } = useI18nContext()
  const theme = useTheme()
  const { sendEvent } = useAnalytics()
  const { selectedCustomer, hasElectricityStandbyEnabled, customerCurrency, commodityPrices } = useCurrentUser()
  const {
    measurements,
    selectedDateRange,
    fetchMeasurementsLoading,
    fetchStandbyLoading,
    selectedAssets,
    getAssetStandbyValidation,
    previousAssetMeasurements,
    showCompare,
    setShowCompare,
    csvExportRequest,
  } = useElectricityContext()

  const loading = fetchMeasurementsLoading || fetchStandbyLoading
  const interval = Interval.fromDateTimes(selectedDateRange.startDate, selectedDateRange.endDate)
  const timeZone = selectedCustomer.timeZone || DEFAULT_TIMEZONE

  const compareDataSeries: SummedEnergyBalanceSeries = useMemo(() => {
    const result: SummedEnergyBalanceSeries = []
    for (const asset of previousAssetMeasurements) {
      const data = filterUpToNow(mapMeasurementsToXY(asset.measurements), timeZone)
      const total = sumSeries(data)
      const standby: Array<never> = []
      const standbyTotal = 0 as NonNegativeFloat
      result.push({
        assetId: asset.id,
        group: 'previousPeriod',
        name: asset.name,
        type: 'bar',
        data,
        total,
        standby,
        standbyTotal,
        color: lighten(asset.color, 0.7),
        isPreviousPeriod: true,
        oop: [],
        oopTotal: 0 as NonNegativeFloat,
      })
    }
    return result
  }, [previousAssetMeasurements, timeZone, showCompare])

  const initialDataSeries: SummedEnergyBalanceSeries = useMemo(() => {
    const result: SummedEnergyBalanceSeries = []
    for (const asset of measurements) {
      const data = filterUpToNow(mapMeasurementsToXY(asset.measurements), timeZone)
      const total = sumSeries(data)
      const standby = filterUpToNow(mapStandbyToXYEnergy(asset.standby), timeZone)
      const standbyTotal = sumSeries(standby)
      const oop = filterUpToNow(mapStandbyToXYEnergy(asset.oop), timeZone)
      const oopTotal = sumSeries(oop)
      result.push({
        assetId: asset.id,
        name: asset.name,
        group: 'regular',
        type: showWaste ? 'bar' : 'donut',
        data,
        total,
        standby,
        standbyTotal,
        oop,
        oopTotal,
        color: asset.color,
      })
    }
    if (showCompare) result.push(...compareDataSeries)
    return result
  }, [measurements, showWaste, showCompare, compareDataSeries, timeZone, showOop])

  const seriesWithWasteSubtracted: SummedEnergyBalanceSeries = useMemo(() => {
    let series = [...initialDataSeries]
    if (!showWaste && !showOop) return series
    // subtract standby/waste from measurements so the bar chart has correct height
    if (showWaste) {
      series = series.map(s => {
        let totalWithoutStandby = s.total - s.standbyTotal
        totalWithoutStandby = totalWithoutStandby < 0 ? 0 : totalWithoutStandby
        return {
          ...s,
          data: s.data.map((d, i) => {
            // we limit the data point to 0 to avoid processing bugs where standby > total energy
            let pointWithStandbySubtracted = d.y ? d.y - (s.standby[i]?.y || 0) : 0
            pointWithStandbySubtracted = pointWithStandbySubtracted < 0 ? 0 : pointWithStandbySubtracted

            return {
              ...d,
              y: pointWithStandbySubtracted as NonNegativeFloat,
            }
          }),
          total: totalWithoutStandby as NonNegativeFloat,
        }
      })
    }
    if (showOop) {
      series = series.map(s => {
        let totalWithoutOop = s.total - s.oopTotal
        totalWithoutOop = totalWithoutOop < 0 ? 0 : totalWithoutOop
        return {
          ...s,
          data: s.data.map((d, i) => {
            // we limit the data point to 0 to avoid processing bugs where oop > total energy
            let pointWithOopSubtracted = d.y ? d.y - (s.oop[i]?.y || 0) : 0
            pointWithOopSubtracted = pointWithOopSubtracted < 0 ? 0 : pointWithOopSubtracted

            return {
              ...d,
              y: pointWithOopSubtracted as NonNegativeFloat,
            }
          }),
          total: totalWithoutOop as NonNegativeFloat,
        }
      })
    }
    return series
  }, [showWaste, showOop, initialDataSeries])

  // For energy balance we only need the biggest totaled measurement, not the biggest in series
  const largestMeasurement = useMemo(
    () =>
      seriesWithWasteSubtracted.reduce((largest, currentSeries) => {
        if (largest > currentSeries.total) return largest
        largest = currentSeries.total
        return largest
      }, 0),
    [seriesWithWasteSubtracted]
  )
  const valuesModifier = useMemo(() => getPowerOrderOfMagnitude(largestMeasurement, true), [largestMeasurement])

  const energyPrice = useMemo(
    () => getScaledEnergyPriceMap(commodityPrices, valuesModifier.unit).get('electric-energy'),
    [commodityPrices, valuesModifier.unit]
  )

  const scaleAdjustedDataSeries: SummedEnergyBalanceSeries = useMemo(() => {
    // adjust data to be in correct order of magnitude
    return seriesWithWasteSubtracted.map(series => {
      const total = adjustMeasurementToMagnitude(series.total, valuesModifier.decimal) as number
      const cost = total && energyPrice && total * energyPrice

      const standby = series.standby.map(d => ({ ...d, y: adjustMeasurementToMagnitude(d.y, valuesModifier.decimal) }))
      const standbyTotal = adjustMeasurementToMagnitude(series.standbyTotal, valuesModifier.decimal) as number
      const standbyCost = standbyTotal && energyPrice && standbyTotal * energyPrice

      const oop = series.oop.map(d => ({ ...d, y: adjustMeasurementToMagnitude(d.y, valuesModifier.decimal) }))
      const oopTotal = adjustMeasurementToMagnitude(series.oopTotal, valuesModifier.decimal) as number
      const oopCost = oopTotal && energyPrice && oopTotal * energyPrice

      return {
        ...series,
        data: series.data.map(d => ({
          ...d,
          y: adjustMeasurementToMagnitude(d.y, valuesModifier.decimal),
        })),
        total,
        cost,
        standby,
        standbyTotal,
        standbyCost,
        oop,
        oopTotal,
        oopCost,
      }
    })
  }, [seriesWithWasteSubtracted, valuesModifier.decimal, energyPrice])

  const scaleAdjustedInitialSeries = useMemo(() => {
    // adjust data to be in correct order of magnitude
    return initialDataSeries.map(series => {
      const total = adjustMeasurementToMagnitude(series.total, valuesModifier.decimal) as number
      const cost: number = total && energyPrice && ((total * energyPrice) as number)

      const standby = series.standby.map(d => ({ ...d, y: adjustMeasurementToMagnitude(d.y, valuesModifier.decimal) }))
      const standbyTotal = adjustMeasurementToMagnitude(series.standbyTotal, valuesModifier.decimal) as number
      const standbyCost: number = standbyTotal && energyPrice && standbyTotal * energyPrice

      const oop = series.oop.map(d => ({ ...d, y: adjustMeasurementToMagnitude(d.y, valuesModifier.decimal) }))
      const oopTotal = adjustMeasurementToMagnitude(series.oopTotal, valuesModifier.decimal) as number
      const oopCost: number = oopTotal && energyPrice && oopTotal * energyPrice

      return {
        ...series,
        data: series.data.map(d => ({
          ...d,
          y: adjustMeasurementToMagnitude(d.y, valuesModifier.decimal),
        })),
        total,
        standby,
        standbyTotal,
        standbyCost,
        oop,
        oopTotal,
        oopCost,
        cost,
      }
    })
  }, [initialDataSeries, valuesModifier.decimal, energyPrice])

  const onDataPointMouseEnter = useCallback(
    (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => {
      setHoveredSeries(scaleAdjustedDataSeries[dataPointIndex].assetId)
    },
    [scaleAdjustedDataSeries]
  )

  const onDataPointMouseLeave = useCallback(() => setHoveredSeries(''), [])

  const onOopToogle = () => {
    setShowOop(showOop => !showOop)
    setShowCompare(false)
  }

  const onWasteToggle = () => {
    setShowWaste(!showWaste)
    setShowCompare(false)
    sendToolbarButtonClickElectricity('toolbar_button_standby_waste', sendEvent)
  }
  const onTableToggle = () => {
    setChartWidth(!showTable ? '99%' : '100%')
    setShowTable(!showTable)
  }
  const onYAxisUnitChange = (unit: YAxisUnits) => setYAxisUnit(unit)

  // Disable waste and oop when compare is enabled
  useEffect(() => {
    if (showCompare) {
      setShowWaste(false)
      setShowOop(false)
    }
  }, [showCompare])

  const isBelowLg = useMediaQuery(theme.breakpoints.down('lg'))

  const hasOop = useFeature('ELECTRICITY_OOP')
  // * SHOW WASTE TOOLTIP / WARNING *
  // We show a DC warning if the validation is DC
  // We show a tooltip with "Show waste" if the button is enabled and there's no DC warning
  const shouldShowDCValidationWarning = Object.keys(selectedAssets).some(a => getAssetStandbyValidation(a) === 'DC')
  const dcValidationWarning = shouldShowDCValidationWarning
    ? i18n.text('graph.button.tools.show-waste-dc-validation-warning')
    : ''
  const wasteButtonTooltip = shouldShowDCValidationWarning
    ? dcValidationWarning
    : i18n.text('graph.button.tools.show-waste')

  // * PNG EXPORTER OPTIONS *
  // On large screens when the table is side by side we need
  // to manually pass height, and allow overflow-y to be visible for png export
  const tableRef = useRef<HTMLElement>(null)
  useLayoutEffect(
    () => setEnergyTableContainerHeight(tableRef.current?.scrollHeight || 0),
    [tableRef.current, showTable, scaleAdjustedInitialSeries]
  )

  const title =
    `${i18n.text('router.sub-routes.energy-balance')} ` +
    (yAxisUnit === 'energy'
      ? ` (${valuesModifier.unit})`
      : `(${getCurrencySymbol(userLocale, customerCurrency.isocode)})`)

  const chipDates =
    selectedDateRange.startDate.toFormat(GRAPH_TOOLBAR_DATE_CHIP_FORMAT) +
    ' - ' +
    selectedDateRange.endDate.toFormat(GRAPH_TOOLBAR_DATE_CHIP_FORMAT)

  const switchUnitValues = [
    {
      value: 'energy' as YAxisUnits,
      title: valuesModifier.unit,
    },
    {
      value: 'cost' as YAxisUnits,
      title: getCurrencySymbol(userLocale, customerCurrency.isocode),
    },
  ]

  const { decimal, setDecimal } = useChartToolsContext()

  const toolbarItems: ChartToolsItem[] = useMemo(() => {
    const result: ChartToolsItem[] = [
      {
        label: i18n.text('graph.button.tools.show-table'),
        onToggle: onTableToggle,
        isChecked: showTable,
      },
      {
        label: `${i18n.text('chart.tools.decimal-label')} : ${decimal}`,
        values: [0, 1, 2, 3, 4],
        onValueToggle: (value: number) => setDecimal(value),
        selectedValue: decimal,
      },
    ]

    if (hasElectricityStandbyEnabled)
      result.unshift({
        label: i18n.text('graph.button.tools.show-waste'),
        onToggle: onWasteToggle,
        isChecked: showWaste,
        isDisabled: false,
        tooltip: {
          text: wasteButtonTooltip,
        },
        icon: shouldShowDCValidationWarning ? <WarningAmber fontSize="small" /> : undefined,
      })

    if (hasOop) {
      result.unshift({
        label: i18n.text('graph.button.tools.show-oop'),
        onToggle: onOopToogle,
        isChecked: showOop,
      })
    }
    return result
  }, [
    showTable,
    onTableToggle,
    i18n,
    showWaste,
    onWasteToggle,
    hasElectricityStandbyEnabled,
    wasteButtonTooltip,
    shouldShowDCValidationWarning,
    decimal,
    setDecimal,
  ])

  return (
    <SectionBoxWrapper
      sx={{ height: isBelowLg ? 'auto' : '100%', overflowY: isBelowLg ? 'auto' : '' }}
      id="energy-balance-container"
    >
      <GraphToolbar
        titleText={title}
        chipContent={chipDates}
        onUnitChange={onYAxisUnitChange}
        switchUnit={yAxisUnit}
        switchUnitValues={switchUnitValues}
        toolbarItems={toolbarItems}
      />
      <Box
        sx={{
          height: isBelowLg ? '100%' : '92%',
          position: 'relative',
          backgroundColor: theme.palette.background.paper,
          overflowY: 'auto',
        }}
      >
        <Box sx={{ display: 'none' }}>
          <EnergyBalanceCsvExporter
            data={initialDataSeries}
            interval={interval}
            showDialog={csvExportRequest === 'energy-balance'}
          />
        </Box>

        <Box
          id="analysis-graph"
          sx={{ height: '100%' }}
        >
          <Box
            id="png-container"
            sx={{
              display: 'flex',
              flexDirection: isBelowLg ? 'column' : 'row',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
              paddingLeft: showWaste || showOop ? 0 : '1rem',
              paddingRight: '1rem',
              maxWidth: '100%',
              height: '100%',
              overflowY: 'hidden',
            }}
          >
            {showWaste || showOop ? (
              <Box sx={{ paddingTop: '2.5rem', flex: 1, minWidth: '50%' }}>
                <EnergyBalanceStandbyBarChart
                  loading={loading}
                  chartWidth={chartWidth}
                  data={scaleAdjustedDataSeries}
                  valuesModifier={valuesModifier}
                  showingCosts={yAxisUnit === 'cost'}
                  onDataPointMouseEnter={onDataPointMouseEnter}
                  onDataPointMouseLeave={onDataPointMouseLeave}
                />
              </Box>
            ) : (
              <>
                {showCompare ? (
                  <Box sx={{ paddingTop: '2.5rem', flex: 1, minWidth: '50%' }}>
                    <EnergyBalanceBarChart
                      loading={loading}
                      showCompare={showCompare}
                      chartWidth={chartWidth}
                      data={scaleAdjustedDataSeries}
                      valuesModifier={valuesModifier}
                      showingCosts={yAxisUnit === 'cost'}
                      onDataPointMouseEnter={onDataPointMouseEnter}
                      onDataPointMouseLeave={onDataPointMouseLeave}
                    />
                  </Box>
                ) : (
                  <Box sx={{ paddingTop: '5rem', flex: 1, minWidth: '50%' }}>
                    <EnergyBalancePieChart
                      loading={loading}
                      chartWidth={chartWidth}
                      data={scaleAdjustedDataSeries}
                      valuesModifier={valuesModifier}
                      showingCosts={yAxisUnit === 'cost'}
                      onDataPointMouseEnter={onDataPointMouseEnter}
                      onDataPointMouseLeave={onDataPointMouseLeave}
                    />
                  </Box>
                )}
              </>
            )}
            {showTable && (
              <Box
                id="analysis-table"
                ref={tableRef}
                sx={{
                  minWidth: '50%',
                  paddingTop: '2rem',
                  height: isBelowLg ? 'initial' : '92%',
                  overflowY: isBelowLg ? '' : 'auto',
                  paddingBottom: '2rem',
                }}
              >
                <EnergyBalanceTable
                  data={scaleAdjustedInitialSeries}
                  highlightedAsset={hoveredSeries}
                  valuesModifier={valuesModifier}
                  showWaste={showWaste}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </SectionBoxWrapper>
  )
}

export { EnergyBalanceContainer }
