import { createSvgIcon } from '@mui/material'

export const GraduationHatIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M17.5 14.5V11.4944C17.5 11.315 17.5 11.2252 17.4727 11.146C17.4485 11.0759 17.4091 11.0121 17.3572 10.9592C17.2986 10.8993 17.2183 10.8592 17.0578 10.7789L12.5 8.5M4.5 9.5V16.3066C4.5 16.6785 4.5 16.8645 4.55802 17.0273C4.60931 17.1712 4.6929 17.3015 4.80238 17.4081C4.92622 17.5287 5.09527 17.6062 5.43335 17.7611L11.8334 20.6945C12.0786 20.8069 12.2012 20.8631 12.3289 20.8852C12.4421 20.9049 12.5579 20.9049 12.6711 20.8852C12.7988 20.8631 12.9214 20.8069 13.1666 20.6945L19.5666 17.7611C19.9047 17.6062 20.0738 17.5287 20.1976 17.4081C20.3071 17.3015 20.3907 17.1712 20.442 17.0273C20.5 16.8645 20.5 16.6785 20.5 16.3066V9.5M2.5 8.5L12.1422 3.67889C12.2734 3.6133 12.339 3.5805 12.4078 3.5676C12.4687 3.55616 12.5313 3.55616 12.5922 3.5676C12.661 3.5805 12.7266 3.6133 12.8578 3.67889L22.5 8.5L12.8578 13.3211C12.7266 13.3867 12.661 13.4195 12.5922 13.4324C12.5313 13.4438 12.4687 13.4438 12.4078 13.4324C12.339 13.4195 12.2734 13.3867 12.1422 13.3211L2.5 8.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'graduation-hat-icon'
)
