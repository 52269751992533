import { createSvgIcon } from '@mui/material'

export const ZapFastIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M11 20.4167H4.58333M8.08333 14H2.83333M11 7.58333H5.16666M20.3333 3.5L12.6375 14.2742C12.2969 14.7511 12.1266 14.9895 12.1339 15.1883C12.1403 15.3614 12.2233 15.5226 12.3604 15.6285C12.5179 15.75 12.8109 15.75 13.397 15.75H19.1667L18 24.5L25.6958 13.7258C26.0365 13.2489 26.2068 13.0105 26.1994 12.8117C26.193 12.6386 26.11 12.4774 25.9729 12.3715C25.8154 12.25 25.5224 12.25 24.9364 12.25H19.1667L20.3333 3.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'zap-fast-icon'
)
