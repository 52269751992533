import { FC, useCallback, useState } from 'react'

import { CA_TOOLBAR_DECIMAL_KEY } from '../utils/utils'
import { browserStorage, createCtx } from '../../Shared/utils'

type CAChartToolsContextType = {
  showMinMaxRange: boolean
  onShowMinMaxRangeToggle: (boolValue: boolean) => void
  showMean: boolean
  onShowMeanToggle: (boolValue: boolean) => void
  decimal: number
  onDecimalToggle: (newValue: number) => void
  showOutsideOperatingHoursInsights: boolean
  onShowOutsideOperatingHoursInsightsToggle: (val: boolean) => void
}
const [useCAChartToolsContext, CAChartToolsContextReactProvider] = createCtx<CAChartToolsContextType>()

/**
 * Each container is responsible for its own tools and
 * validating state against its own rules, but state itself is shared
 *
 */
const CAChartToolsContextProvider: FC = ({ children }) => {
  const savedDecimal = browserStorage.get<string>(CA_TOOLBAR_DECIMAL_KEY).value
  const initialDecimal = savedDecimal !== null ? parseInt(savedDecimal) : 1

  const [showMinMaxRange, setShowMinMaxRange] = useState(false)
  const [showMean, setShowMean] = useState(false)
  const [decimal, setDecimal] = useState(initialDecimal)

  const onShowMinMaxRangeToggle = (boolValue: boolean) => {
    setShowMinMaxRange(boolValue)
  }

  const onShowMeanToggle = (boolValue: boolean) => {
    setShowMean(boolValue)
  }

  const onDecimalToggle = (newValue: number) => {
    browserStorage.set(CA_TOOLBAR_DECIMAL_KEY, newValue)
    setDecimal(newValue)
  }
  const [showOutsideOperatingHoursInsights, setShowOutsideOperatingHoursInsights] = useState(false)

  const onShowOutsideOperatingHoursInsightsToggle = useCallback(
    (val: boolean) => setShowOutsideOperatingHoursInsights(val),
    []
  )

  return (
    <CAChartToolsContextReactProvider
      value={{
        showMinMaxRange,
        onShowMinMaxRangeToggle,
        showMean,
        onShowMeanToggle,
        decimal,
        onDecimalToggle,
        showOutsideOperatingHoursInsights,
        onShowOutsideOperatingHoursInsightsToggle,
      }}
    >
      {children}
    </CAChartToolsContextReactProvider>
  )
}

export { useCAChartToolsContext, CAChartToolsContextProvider }
