import { DateTime, Interval } from 'luxon'
import { Mutable } from 'utility-types'

import {
  DateRange,
  ElectricityAssetMeasurement,
  FetchedAssetWithMeasurements,
  FetchedAssetWithOop,
  FetchedAssetWithStandby,
  FlatAsset,
  OOPMoment,
} from '../types'
import { Measurement, StandbyMoment } from '../../Shared/graphql/codegen'

const MONTH_IN_MILLIS = 1000 * 60 * 60 * 24 * 31

export const mapFetchedMeasurements = (
  flatAssets: FlatAsset[],
  fetchedMeasurements: FetchedAssetWithMeasurements[] = [],
  fetchedStandby?: FetchedAssetWithStandby[],
  fetchedOop?: FetchedAssetWithOop[]
): ElectricityAssetMeasurement[] => {
  const fullAssetsMap = {} as Record<string, FlatAsset>
  flatAssets.forEach(a => {
    fullAssetsMap[a.id] = a
  })

  const allAssetsStandby = fetchedStandby ?? []
  const allAssetsStandbyMap = {} as Record<string, FetchedAssetWithStandby>

  allAssetsStandby.forEach(s => {
    allAssetsStandbyMap[s.id] = s
  })

  const allAssetsOop = fetchedOop ?? []
  const allAssetsOopMap = {} as Record<string, FetchedAssetWithOop>

  allAssetsOop.forEach(s => {
    allAssetsOopMap[s.id] = s
  })

  const result = fetchedMeasurements.map(a => {
    const fullAsset = fullAssetsMap[a.id]
    const standby = allAssetsStandbyMap[a.id]?.standby as Mutable<StandbyMoment>[]
    return {
      id: a.id,
      name: a.name,
      measurements: a.measurements as Mutable<Measurement>[],
      color: fullAsset?.color || '#99FF22',
      standby,
      oop: allAssetsOopMap[a.id]?.outsideProduction as unknown as Mutable<OOPMoment>[],
    }
  })
  return result
}

export const mapFetchedCompareMeasurements = (
  flatAssets: FlatAsset[],
  fetchedCompareMeasurements: FetchedAssetWithMeasurements[] = [],
  currentDateRange: DateRange,
  compareDateRange: DateRange,
  customerTimezone: string
) => {
  let dateRange1: DateRange, dateRange2: DateRange, direction: number

  if (+currentDateRange.startDate >= +compareDateRange.startDate) {
    dateRange1 = currentDateRange
    dateRange2 = compareDateRange
    direction = -1
  } else {
    dateRange1 = compareDateRange
    dateRange2 = currentDateRange
    direction = 1
  }

  const { startDate, endDate } = dateRange1
  const { startDate: compareStart } = dateRange2

  const isWeeklyAggregation = endDate.diff(startDate, 'milliseconds').milliseconds > MONTH_IN_MILLIS

  let expectedDuration = Interval.fromDateTimes(
    compareStart.setZone(customerTimezone),
    startDate.setZone(customerTimezone)
  )
    .toDuration()
    .as('weeks')

  if (isWeeklyAggregation) {
    // If we're aggregating on a weekly level, we want to compare the same day of the week,
    // so we're ignoring the exact time difference between the two dates, instead opting for this approximation,
    // which works with Luxon's calculation of weeks and DST changes.
    expectedDuration = +expectedDuration.toFixed(0)
  }

  const result = fetchedCompareMeasurements.map(asset => {
    const fullAsset = flatAssets.find(fa => fa.id === asset.id)
    const mappedAsset = {
      id: asset.id,
      name: asset.name,
      measurements: [] as Mutable<Measurement>[],
      color: fullAsset?.color || '#FF50FF',
      standby: [],
    }

    if (!asset.prevMeasurements) {
      return mappedAsset
    }

    const measurementsMillisMap = {} as Record<string, Measurement>

    asset.measurements.forEach(m => {
      const millis = DateTime.fromISO(m.time, { zone: customerTimezone }).toMillis()
      measurementsMillisMap[millis] = m
    })

    mappedAsset.measurements = asset.prevMeasurements
      .map(prevMeasurement => {
        // Take the current measurement
        // Find the previous measurement in that series
        // that is the exact distance in time as the startDate and compareDate delta - expected duration
        // Align the time of the found prevMeasurement to current measurement
        // Discard any previous measurements that are not at the correct distance in time

        const comparedDataPointTime = DateTime.fromISO(prevMeasurement.time, {
          zone: customerTimezone,
        })
        const expectedComparedPoint =
          direction < 0
            ? comparedDataPointTime.plus({ weeks: expectedDuration })
            : comparedDataPointTime.minus({ weeks: expectedDuration })

        const measurementToAlignTo = measurementsMillisMap[expectedComparedPoint.toMillis()]

        if (!measurementToAlignTo) {
          // When we pass through DST there may be a
          // value missing or extra between the regular
          // measurements and the compared period
          return null
        }
        return {
          ...prevMeasurement,
          time: measurementToAlignTo.time,
          prevTime: prevMeasurement.time,
        }
      })
      .filter(m => !!m) as Mutable<Measurement>[]

    return mappedAsset
  })
  return result
}
