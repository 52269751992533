import React from 'react'
import html2canvas from 'html2canvas'
import { Attachment } from '@mui/icons-material'
import { Typography, useTheme } from '@mui/material'

import StyledButton from '../AnalysisTools/StyledButton'
import useAnalytics from '../../hooks/useAnalytics/useAnalytics'
import { AnalyticsEventTypes, DownloadPNG } from '../../hooks/useAnalytics/analyticsTypes'
import { useCurrentUser } from '../../contexts/CurrentUserContext'
import { useToastContext } from '../../contexts/ToastContext'

type PngExportProps = {
  element?: $TSFixMe | null
  fileName?: string
  i18n: $I18FixMe
  options?: $TSFixMe
}

/** @deprecated do not use */
const PngExport: React.FC<PngExportProps> = ({ element, fileName, i18n, options }) => {
  const { isInternalUser, userId, customerId } = useCurrentUser()
  const { showToast } = useToastContext()
  const { sendEvent } = useAnalytics()
  const theme = useTheme()

  function onClickExportButton() {
    if (element == null) {
      return showToast(
        i18n.text('analysis.png-export.error.message.body'),
        'warning',
        i18n.text('analysis.png-export.error.message.title')
      )
    }

    sendEvent<DownloadPNG>(AnalyticsEventTypes.TRACKED_DOWNLOAD, { target: 'png' })

    return html2canvas(element, options).then(canvas => {
      const root = document.body
      canvas.style.display = 'none'
      const image = canvas.toDataURL('png')
      const workElement = document.createElement('a')
      workElement.setAttribute('download', `${fileName}.png`)
      workElement.setAttribute('href', image)
      root.appendChild(workElement)
      workElement.click()
      root.removeChild(workElement)
    })
  }

  return (
    <>
      <StyledButton
        appearance="secondary"
        onClick={onClickExportButton}
        tooltip={(i18n as $TSFixMe).text('tooltip.png-export-button')}
      >
        <Attachment
          fontSize="small"
          sx={{ marginRight: theme.spacing(1) }}
        />
        <Typography sx={{ textTransform: 'none', fontSize: theme.spacing(1.5) }}>
          {i18n.text('tooltip.png-export-button')}
        </Typography>
      </StyledButton>
    </>
  )
}

export default PngExport
