import { Button } from '@mui/material'

const StyledButton = (props: any) => (
  <Button
    {...props}
    style={{
      backgroundColor: '#0F1628',
      borderRadius: '8px 8px',
      fontSize: '12px',
      padding: '8px 12px',
      marginRight: '8px',
      marginBottom: '7px',
      color: '#FFFFFF',
    }}
  />
)

export default StyledButton
