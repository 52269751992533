import React, { FC } from 'react'
import { Box, useTheme } from '@mui/material'

import OEEMetricItem from './OEEMetricItem'
import { SPEED_UNIT_STRING_SHORT } from '../../utils/constants'
import { TransformedOeeMetrics } from '../../types/OeeMetrics.type'
import { convertFromCycleTime, round } from '../../utils/unitConversions'
import { formatDuration } from '../../utils/helpers'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type OEEMetricsWarnings = {
  availability?: boolean
  performance?: boolean
  oee1?: boolean
}

type AdditionalMetric = {
  label: string
  value: string
}

type OEEMetricsProps = {
  oeeMetrics: TransformedOeeMetrics
  warnings?: OEEMetricsWarnings
}

const OEEMetrics: FC<OEEMetricsProps> = ({ oeeMetrics, warnings }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { units } = useOEEContext()

  const availability = Math.round(oeeMetrics.availabilityPct ?? 0)
  const performance = Math.round(oeeMetrics.performancePct ?? 0)
  const oee1 = Math.round(oeeMetrics.oee1 ?? 0)

  const totalTime = formatDuration(Math.round(oeeMetrics.totalTimeSeconds))
  const runtime = formatDuration(Math.round(oeeMetrics.runtimeSeconds))

  const averageTargetSpeed = convertFromCycleTime(oeeMetrics.averageTargetCycleTimeSeconds, units.speed)
  const averageSpeed = convertFromCycleTime(oeeMetrics.averageCycleTimeSeconds, units.speed)

  const getFormattedSpeed = (speed: number) => `${round(speed, 1)} ${i18n.text(SPEED_UNIT_STRING_SHORT[units.speed])}`

  const additionalAvailabilityMetrics: AdditionalMetric[] = [
    {
      label: i18n.text('oee.oee-metrics.additional-metric.planned-time'),
      value: totalTime,
    },
    {
      label: i18n.text('oee.oee-metrics.additional-metric.runtime'),
      value: runtime,
    },
  ]

  const additionalPerformanceMetrics: AdditionalMetric[] = [
    {
      label: i18n.text('oee.oee-metrics.additional-metric.average-targetspeed'),
      value: getFormattedSpeed(averageTargetSpeed),
    },
    {
      label: i18n.text('oee.oee-metrics.additional-metric.average-speed'),
      value: getFormattedSpeed(averageSpeed),
    },
  ]

  const additionalOee1Metrics: AdditionalMetric[] = [
    {
      label: i18n.text('oee.oee-metrics.additional-metric.target-volume'),
      value: `${i18n.number(oeeMetrics.targetProductionVolume, { maximumFractionDigits: 0 })} ${i18n.text('units')}`,
    },
    {
      label: i18n.text('oee.oee-metrics.additional-metric.total-volume'),
      value: `${i18n.number(oeeMetrics.totalProductionVolume, { maximumFractionDigits: 0 })} ${i18n.text('units')}`,
    },
  ]

  const warningPerformanceMessage = warnings?.performance
    ? `${i18n.text('oee.oee-metrics.warning.performance.undefined-target-speed')} \n \n ${i18n.text(
        'oee.oee-metrics.warning.performance.undefined-target-speed.explanation'
      )}`
    : undefined

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '1rem',
        gridTemplateColumns: 'repeat(auto-fit, 290px)',
        justifyContent: 'center',
      }}
    >
      <OEEMetricItem
        title={i18n.text('oee.machine-view.oee-metrics.availability')}
        value={availability}
        color={theme.palette.SFIWarning?.[400]}
        additionalMetrics={additionalAvailabilityMetrics}
      />
      <OEEMetricItem
        title={i18n.text('oee.machine-view.oee-metrics.performance')}
        value={performance}
        color={theme.palette.SFIWarning?.[600]}
        additionalMetrics={additionalPerformanceMetrics}
        warningMessage={warningPerformanceMessage}
      />
      <OEEMetricItem
        title={i18n.text('oee.machine-view.oee-metrics.oee-1')}
        value={oee1}
        color={theme.palette.SFIBrand?.[900]}
        additionalMetrics={additionalOee1Metrics}
      />
    </Box>
  )
}

export default OEEMetrics
