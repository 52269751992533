export enum DownloadType {
  'report' = 'report',
}

export enum DownloadMimeTypes {
  'pdf' = 'application/pdf',
}

export type DownloadPageParams = {
  file: string // url to file, base64 encoded
  mimeType: DownloadMimeTypes
  gaEvent?: DownloadType
  fileName?: string
  userId?: string
  internalUser?: boolean
  origin: 'webapp' | 'email'
  customerId?: string
  reportType?: 'tracking' | 'insights' | 'DcSummary' | 'RaSummary' | 'EnergyReport'
  reportId?: string
}
