import { DateTime, Duration } from 'luxon'

/**
 * Calculate a new comparison interval based on the delta between
 * the old start date and the old compare start date
 * **/
export const calculateNewCompareInterval = (
  oldStartDate: DateTime,
  oldCompareStartDate: DateTime,
  newStartDate: DateTime,
  newEndDate: DateTime
) => {
  // if interval 2, the comparisson, is the first chronologically
  if (+oldStartDate >= +oldCompareStartDate) {
    const diffInMilliseconds = oldStartDate.diff(oldCompareStartDate).milliseconds || 0
    const diff = Duration.fromObject({ milliseconds: Math.floor(diffInMilliseconds) }) // JS and floating point numbers are weird
    const newCompareStartDate = newStartDate.minus(diff)
    const newCompareEndDate = newEndDate.minus(diff)
    return { newCompareStartDate, newCompareEndDate }
  } else {
    // interval 1, the current interval, is the first chronologically, the comparisson is in the future
    const diffInMilliseconds = oldCompareStartDate.diff(oldStartDate).milliseconds || 0
    const diff = Duration.fromObject({ milliseconds: Math.floor(diffInMilliseconds) }) // JS and floating point numbers are weird
    const newCompareStartDate = newStartDate.plus(diff)
    const newCompareEndDate = newEndDate.plus(diff)
    return { newCompareStartDate, newCompareEndDate }
  }
}
