import { Alert, AlertColor, AlertTitle, Box, Slide, Snackbar, useTheme } from '@mui/material'
import { FC, useState } from 'react'

import { AlertIcon } from '../components/icons/AlertIcon'
import { CheckToastIcon } from '../components/icons/CheckToastIcon'
import { LoadingIcon } from '../components/icons/LoadingIcon'
import { WarningIcon } from '../components/icons'
import { createCtx } from '../utils/createCtx'
import { useI18nContext } from './i18nContext/I18nContext'

type ToastContext = {
  showToast: (message: string, type?: AlertColor, title?: string, timeout?: number) => void
  handleGenericError: (message?: string) => void
}

const [useToastContext, ToastContextReactProvider] = createCtx<ToastContext>()

const ToastContextProvider: FC = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [title, setTitle] = useState('')
  const [type, setType] = useState<AlertColor>('info')
  const [timeout, setTimeout] = useState(4000)
  const [rand, setRand] = useState(Math.random())

  const theme = useTheme()
  const { i18n } = useI18nContext()

  const handleClose = () => {
    setOpen(false)
  }

  const showToast = (message: string, type: AlertColor = 'info', title = '', timeout = 4000) => {
    setOpen(true)
    setType(type)
    setMessage(message)
    setTitle(title)
    setTimeout(timeout)
    setRand(Math.random())
  }

  const showToastCustomStyles = (type: AlertColor) => {
    switch (type) {
      case 'info':
        return {
          color: theme.palette.SFIGreyLight[300],
          fontSizeColor: theme.palette.SFIGreyLight[700],
          backroundColor: theme.palette.SFIBase.white,
          icon: <LoadingIcon size="small" />,
        }
      case 'success':
        return {
          color: theme.palette.SFIGreen[600],
          backroundColor: theme.palette.SFIGreen[50],
          icon: <CheckToastIcon size="small" />,
        }
      case 'error':
        return {
          color: theme.palette.SFIOrange[600],
          fontSizeColor: theme.palette.error.dark,
          backroundColor: theme.palette.SFIOrange[50],
          icon: <AlertIcon size="small" />,
        }
      case 'warning':
        return {
          color: theme.palette.SFIWarning[700],
          fontSizeColor: theme.palette.SFIWarning[700],
          backroundColor: theme.palette.SFIWarning[50],
          icon: <WarningIcon sx={{ color: theme.palette.SFIWarning[600], width: '1.125rem', height: '1.125rem' }} />,
        }
      default:
        return {
          backroundColor: theme.palette.info.main,
          icon: <LoadingIcon size="small" />,
        }
    }
  }

  const handleGenericError = (message?: string) =>
    showToast(message || i18n.text('errors.generic.title'), 'error', 'Error')

  return (
    <ToastContextReactProvider value={{ showToast, handleGenericError }}>
      {children}
      <Snackbar
        key={rand}
        open={open}
        autoHideDuration={timeout}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={props => (
          <Slide
            {...props}
            direction="left"
            unmountOnExit
          />
        )}
        transitionDuration={500}
      >
        <Box
          sx={{
            margin: theme.spacing(1, 1),
            transform: 'translateY(-50%)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Alert
              onClose={handleClose}
              data-testid="toast-alert"
              variant="outlined"
              sx={{
                display: 'flex',
                background: showToastCustomStyles(type).backroundColor,
                flexDirection: 'column',
                padding: theme.spacing(1.1),
                border: `1px solid ${showToastCustomStyles(type).color}`,
                alignItems: 'flex-start',
                width: '20.25rem',
                gap: theme.spacing(4),
                '.MuiAlert-icon': {
                  display: 'none',
                },
                '.MuiAlert-action': {
                  position: 'absolute',
                  right: '2rem',
                },
              }}
            >
              {showToastCustomStyles(type).icon}
              {title && (
                <AlertTitle
                  sx={{
                    marginTop: theme.spacing(2),
                    color: showToastCustomStyles(type).fontSizeColor,
                  }}
                >
                  {title}
                </AlertTitle>
              )}
              <Box>{message}</Box>
            </Alert>
          </Box>
        </Box>
      </Snackbar>
    </ToastContextReactProvider>
  )
}

export { useToastContext, ToastContextProvider }
