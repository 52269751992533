import React, { FC, useMemo, useState } from 'react'
import { Divider, Typography } from '@mui/material'

import useAnalytics from '../../../../Shared/hooks/useAnalytics/useAnalytics'
import { AutocompleteOption, ID, IImage } from '../../../../Shared/types/types'
import { Block, EfficiencyText, EfficiencyTextWrapper, LearnMoreButton } from '../CASettingsStyledComponents'
import { CompressorsAutocomplete } from './CompressorsAutocomplete'
import { IExtendedCompressedAirAsset } from '../../../../CompressedAir/types/compressed-air_types'
import { ImagePlaceholder } from './ImagePlaceholder'
import {
  ImageTypeInput,
  useCreateCompressedAirImageMutation,
  useDeleteCompressedAirFlowSystemImageMutation,
  useDeleteLinkedAssetFromCaSystemMutation,
  useHandleMainPipeMutation,
  useLinkAssetToCaSystemMutation,
  useUpdateCompressedAirFlowSystemMutation,
  useUpdateLinkedAssetOfCaSystemMutation,
  useUpdateMyAssetMutation,
} from '../../../../Shared/graphql/codegen'
import { MainPipesAutocomplete } from './MainPipesAutocomplete'
import { StyledTextField } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { getBase64 } from '../../../../Shared/utils/getBase64FromFile'
import { mapCompressors, mapMainPipes } from '../../../utils/compressedAirSettings'
import { sendCADeleteImageEvent, sendCAUploadImageEvent } from '../../../../CompressedAir/utils/analyticsEvents'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../Shared/contexts/ToastContext'

interface ICAAssetAccordionDetailsProps {
  asset: IExtendedCompressedAirAsset
  images: IImage[]
  compressorsOptions: AutocompleteOption[]
  refetchSystems: (resetFreeCompressors?: boolean) => void
  openLearnMoreModal: () => void
}

export const CAAssetAccordionDetails: FC<ICAAssetAccordionDetailsProps> = ({
  asset,
  images,
  compressorsOptions,
  refetchSystems,
  openLearnMoreModal,
}) => {
  const { i18n } = useI18nContext()
  const { sendEvent } = useAnalytics()
  const { customerId } = useCurrentUser()
  const { showToast } = useToastContext()

  const [selectedFiles, setSelectedFiles] = useState<File[]>()
  const [isViewImagesModalOpen, setIsViewImagesModalOpen] = useState(false)
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false)
  const [mainPipe, setMainPipe] = useState<AutocompleteOption | null | undefined>(null)
  const [name, setName] = useState(asset.name)
  const [pressure, setPressure] = useState<string | number | undefined | null>(asset.compressedAirFlowSystem?.pressure)
  const [pressureError, setPressureError] = useState<string>('')
  const [uploadedFileIndex, setUploadedFileIndex] = useState<number>(0)
  const [isNewCompressor, setIsNewCompressor] = useState(false)

  const mainPipes = useMemo(() => mapMainPipes(asset), [asset])
  const compressors = useMemo(() => mapCompressors({ asset, isNewCompressor }), [asset, isNewCompressor])

  const mainPipesOptions = useMemo(
    () =>
      asset.sensorLocations.reduce<AutocompleteOption[]>((acc, location) => {
        if (!location.isSelectedForEnergyEfficiency) {
          acc.push({
            label: location.name,
            id: location.id,
          })
        }
        return acc
      }, []),
    [asset.sensorLocations]
  )

  const selectedImages = useMemo(() => {
    return (
      images?.map(img => ({
        id: img.id,
        url: img.url,
      })) || []
    )
  }, [images])

  const onError = () => showToast(i18n.text('file-modal.image-upload.error'), 'error', 'Error')
  const onSuccess = (refetchFreeCompressors?: boolean) => {
    showToast(i18n.text('general.success.changes-saved'), 'success', 'Success!')
    refetchSystems(!!refetchFreeCompressors)
  }

  const [handleMainPipe] = useHandleMainPipeMutation({
    onCompleted: () => onSuccess(),
    onError,
  })

  const [assignCompressorToSystem] = useLinkAssetToCaSystemMutation({
    onCompleted: () => onSuccess(true),
    onError,
  })

  const [unassignCompressor] = useDeleteLinkedAssetFromCaSystemMutation({
    onCompleted: () => onSuccess(true),
    onError,
  })

  const [updateCompressedAirSystem] = useUpdateCompressedAirFlowSystemMutation({
    onCompleted: () => onSuccess(),
    onError,
  })

  const [updateSystemName] = useUpdateMyAssetMutation({
    onCompleted: () => onSuccess(),
    onError,
  })

  const [updateCompressorLinkage] = useUpdateLinkedAssetOfCaSystemMutation({
    onCompleted: () => onSuccess(true),
    onError,
  })

  const onCompleteCreateImage = () => {
    setUploadedFileIndex(uploadedFileIndex - 1)
    if (uploadedFileIndex - 1 === 0) {
      showToast(i18n.text('file-modal.image-upload.success'), 'success', 'Success!')
      refetchSystems()
      setIsUploadImageModalOpen(false)
    }
  }

  const onCompleteDeleteImage = () => {
    showToast(i18n.text('file-modal.image-delete.success'), 'success', 'Success!')
    refetchSystems()
  }

  const [createCompressedAirImage, { loading: createImageLoading }] = useCreateCompressedAirImageMutation({
    onCompleted: onCompleteCreateImage,
    onError,
  })

  const [deleteCompressedAirImage, { loading: deleteImageLoading }] = useDeleteCompressedAirFlowSystemImageMutation({
    onCompleted: onCompleteDeleteImage,
    onError,
  })

  function uploadImage() {
    if (!selectedFiles || selectedFiles.length === 0 || !asset.compressedAirFlowSystem?.id) {
      return
    }
    selectedFiles.forEach(imageFile => {
      getBase64(imageFile, async (result: string) => {
        const base64string = result.split(';base64,')[1]
        const sendVariables = {
          customerId,
          image: {
            base64String: base64string,
            type: imageFile.type.replace('image/', '') as ImageTypeInput,
          },
        }
        await createCompressedAirImage({
          variables: {
            ...sendVariables,
            systemId: asset.compressedAirFlowSystem?.id as string,
          },
        })
        sendCAUploadImageEvent('asset', sendEvent)
      })
    })
  }

  function deleteImage(imageId: string) {
    deleteCompressedAirImage({ variables: { id: imageId as ID } })
    sendCADeleteImageEvent('asset', sendEvent)
    refetchSystems()
  }

  function deleteMainPipe(id: string) {
    handleMainPipe({
      variables: {
        locationId: id as ID,
        isSelected: false,
      },
    })
  }

  function selectMainPipe(id: ID) {
    handleMainPipe({
      variables: {
        locationId: id,
        isSelected: true,
      },
    })
    setMainPipe(null)
  }

  function linkCompressor(value: AutocompleteOption | null, compressorId: string) {
    if (value && asset.compressedAirFlowSystem?.id && !compressorId) {
      assignCompressorToSystem({
        variables: {
          systemId: asset.compressedAirFlowSystem?.id as ID,
          linkedAssetId: value.id as ID,
        },
      })
      setIsNewCompressor(false)
    } else if (value && compressorId && asset.compressedAirFlowSystem?.id) {
      const linkedCompressor = asset.compressedAirFlowSystem.compressedAirSystemLinkedAssets.find(
        asset => asset.id === compressorId
      )
      if (linkedCompressor) {
        updateCompressorLinkage({
          variables: {
            id: linkedCompressor.id as ID,
            linkedAssetId: value.id as ID,
            systemId: asset.compressedAirFlowSystem?.id as ID,
          },
        })
      }
    }
  }

  function unlinkCompressor(compressorId: string) {
    if (compressorId) {
      unassignCompressor({
        variables: {
          id: compressorId as ID,
        },
      })
    } else {
      setIsNewCompressor(false)
    }
  }

  function handleUpdatePressure() {
    if (
      !pressure ||
      !asset.compressedAirFlowSystem?.id ||
      (asset.compressedAirFlowSystem.pressure && +asset.compressedAirFlowSystem.pressure === +pressure)
    ) {
      setPressure(asset.compressedAirFlowSystem?.pressure)
      return
    }

    if (pressure && +pressure < 0) {
      setPressureError(i18n.text('compressed-air.settings.pressure-error'))
      return
    }

    updateCompressedAirSystem({
      variables: {
        id: asset.compressedAirFlowSystem.id,
        pressure: +pressure,
      },
    })
  }

  function handleUploadImagesModalOpenState(isOpen: boolean) {
    setIsUploadImageModalOpen(isOpen)
  }

  function handleViewImagesModalOpenState(isOpen: boolean) {
    setIsViewImagesModalOpen(isOpen)
  }

  function handleSelectedFiles(files: File[]) {
    setSelectedFiles(files)
    setUploadedFileIndex(files.length)
  }

  function saveSystemName() {
    if (!name || !asset.id || asset.name === name) {
      setName(asset.name)
      return
    }

    updateSystemName({
      variables: {
        id: asset.id as ID,
        name,
      },
    })
  }

  function handleIsNewCompressor(val: boolean) {
    setIsNewCompressor(val)
  }

  return (
    <>
      <EfficiencyTextWrapper>
        <EfficiencyText>{i18n.text('compressed-air.settings.efficiency').replace(/..$/, '...')}</EfficiencyText>
        <LearnMoreButton
          disableRipple
          onClick={openLearnMoreModal}
        >
          {i18n.text('settings.learn-more')}
        </LearnMoreButton>
      </EfficiencyTextWrapper>
      <Divider sx={{ marginBottom: '1.5rem', borderColor: theme => theme.palette.SFIGreyLight[200] }} />
      <Block>
        <Typography>{i18n.text('compressed-air.settings.system-name')}</Typography>
        <StyledTextField
          value={name}
          size="small"
          variant="outlined"
          onChange={e => setName(e.target.value)}
          onBlur={saveSystemName}
        />
      </Block>
      <ImagePlaceholder
        imageUrl={images[0]?.url}
        viewImagesModalProps={{
          title: asset.name ?? '',
          subtitle: i18n.text('compressed-air.asset-information.upload-system-image'),
          isOpen: isViewImagesModalOpen,
          images: selectedImages,
          isLoading: deleteImageLoading,
          uploadModal: {
            subtitle: i18n.text('compressed-air.asset-information.upload-system-image'),
            isOpen: isUploadImageModalOpen,
            isLoading: createImageLoading,
            onToggleModal: handleUploadImagesModalOpenState,
          },
          onToggleModal: handleViewImagesModalOpenState,
          onSelectFiles: handleSelectedFiles,
          onUpload: uploadImage,
          onDelete: deleteImage,
        }}
        uploadImageModalProps={{
          title: asset.name,
          isLoading: createImageLoading,
          isOpen: isUploadImageModalOpen && !isViewImagesModalOpen,
          subtitle: i18n.text('compressed-air.asset-information.upload-system-image'),
          onToggleModal: handleUploadImagesModalOpenState,
          onSelectFiles: handleSelectedFiles,
          onUpload: uploadImage,
        }}
      />
      <Block>
        <Typography>ID</Typography>
        <StyledTextField
          value={asset.id}
          size="small"
          variant="outlined"
          disabled
        />
      </Block>
      <Block>
        <Typography>{i18n.text('air.graphs.air-pressure-header')}</Typography>
        <StyledTextField
          error={!!pressureError}
          helperText={pressureError}
          value={pressure}
          type="number"
          size="small"
          variant="outlined"
          onChange={e => {
            if (pressureError) {
              setPressureError('')
            }
            setPressure(e.target.value)
          }}
          sx={{
            maxWidth: '20rem',
          }}
          onBlur={handleUpdatePressure}
        />
      </Block>
      <Divider sx={{ marginBottom: '1.25rem', borderColor: theme => theme.palette.SFIGreyLight[200] }} />
      <MainPipesAutocomplete
        mainPipes={mainPipes}
        mainPipe={mainPipe}
        options={mainPipesOptions}
        onSelectMainPipe={id => selectMainPipe(id)}
        onDeleteMainPipe={deleteMainPipe}
      />
      <Divider sx={{ marginBottom: '1.25rem', borderColor: theme => theme.palette.SFIGreyLight[200] }} />
      <CompressorsAutocomplete
        compressors={compressors}
        options={compressorsOptions}
        onAddCompressor={handleIsNewCompressor}
        onLinkCompressor={linkCompressor}
        onUnlinkCompressor={unlinkCompressor}
      />
    </>
  )
}
