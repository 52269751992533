import React, { FC } from 'react'
import { Box, Pagination } from '@mui/material'

import { LongArrowLeftIcon } from '../../../../icons'
import { StyledOutlinedButton } from '../../styledComponents/StyledButtons'
import { useI18nContext } from '../../../../../contexts/i18nContext/I18nContext'
import { TablePaginationProps } from '../../../../../types/customTableTypes'

/**
 * @description - CustomTablePagination is a component that is responsible for a table's pagination
 * @param {number} totalRows - A total number of rows, required for the calculation of how many pages there are in total
 * @param {number} [rowsPerPage = 20] - A number of rows per page.
 * @param {number} activePage - An active page that is used to control the pagination from outside
 * @param {function(page: number): void} onChange - A function is called when a user clicks on another page
 */

export const CustomTablePagination: FC<TablePaginationProps> = ({
  activePage,
  rowsPerPage = 20,
  totalRows,
  slotProps,
  onChange,
}) => {
  const { i18n } = useI18nContext()

  const totalPages = Math.ceil(totalRows / rowsPerPage)

  const handlePageChange = (_: unknown, newPage: number) => {
    onChange(newPage)
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        gap: '1rem',
        padding: '0.75rem 1.5rem',
      }}
    >
      <StyledOutlinedButton
        sx={{
          height: '2.25rem',
          padding: '0.5rem 0.75rem',
        }}
        disabled={activePage === 1}
        onClick={(e) => handlePageChange(e, activePage - 1)}
      >
        <LongArrowLeftIcon
          sx={{
            width: '1.25rem',
            height: '1.25rem',
          }}
        />
        {i18n.text('onboarding.previous')}
      </StyledOutlinedButton>
      <Pagination
        count={totalPages}
        shape="rounded"
        page={activePage}
        siblingCount={1}
        boundaryCount={2}
        hideNextButton
        hidePrevButton
        onChange={handlePageChange}
        {...slotProps?.pagination}
        sx={{
          margin: '0 auto',
        }}
      />
      <StyledOutlinedButton
        sx={{
          height: '2.25rem',
          padding: '0.5rem 0.75rem',
        }}
        disabled={activePage === totalPages}
        onClick={(e) => handlePageChange(e, activePage + 1)}
      >
        {i18n.text('onboarding.next')}
        <LongArrowLeftIcon
          sx={{
            width: '1.25rem',
            height: '1.25rem',
            transform: 'rotate(180deg)',
          }}
        />
      </StyledOutlinedButton>
    </Box>
  )
}
