import { Box, SxProps, styled } from '@mui/material'
import { FC } from 'react'

import { SemiboldDarkXlText, SquareIconBox } from '../../Common/v1'

const IconTextBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1.25rem',
}))

type InputProps = {
  icon: React.ReactNode
  title: string
  sx?: SxProps
  sxtitle?: SxProps
}

export const ProcessStepBlock: FC<InputProps> = ({ icon, title, sx, sxtitle }) => {
  return (
    <IconTextBox>
      <SquareIconBox sx={sx}>{icon}</SquareIconBox>
      <SemiboldDarkXlText sx={sxtitle}>{title}</SemiboldDarkXlText>
    </IconTextBox>
  )
}
