import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

import { AssetsSelectAutocomplete } from './AssetsSelectAutocomplete'
import { AssetsSelectProps } from '../types'
import { StyledInputFieldHeader } from '../Common'
import { getFilteredAssets, getFilteredAssetsHelperText } from '../utils'
import { useCurrentUser } from '../../../../../Shared/contexts/CurrentUserContext'
import {
  useCustomerAssetProductionSchedulesQuery,
  useCustomerDailyShiftSchedulesQuery,
  useCustomerElectricityAssetStandbyQuery,
  useCustomerProtocolAssetsQuery,
  useTrSupportedAssetsQuery,
} from '../../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const AssetsSelect: FC<AssetsSelectProps> = ({
  analysisType,
  selectedAssets,
  handleAssetsChange,
  selectAll,
  handleSelectAllAssets,
}) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { customerId } = useCurrentUser()
  const { data: assetsData } = useTrSupportedAssetsQuery()
  const assets = assetsData?.myOrg?.assets
  const { data: shiftSchedulesData } = useCustomerDailyShiftSchedulesQuery({ variables: { customerId: customerId } })

  const assetIds = assets
    ? assets.map(asset => {
        return asset.value
      })
    : []
  const { data: assetsProtocolsData } = useCustomerProtocolAssetsQuery()
  const { data: assetsProductionSchedulesData } = useCustomerAssetProductionSchedulesQuery({
    variables: {
      assetIds: assetIds,
    },
  })
  const { data: assetsWithStandbyData } = useCustomerElectricityAssetStandbyQuery({ variables: { assetIds: assetIds } })
  const sortedAssetsInput = getFilteredAssets(
    analysisType,
    shiftSchedulesData,
    assetsData,
    assetsProductionSchedulesData,
    assetsProtocolsData,
    assetsWithStandbyData
  )
  const filteredAssetsHelperText = getFilteredAssetsHelperText(analysisType, shiftSchedulesData)
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '30% 70%',
        }}
      >
        <StyledInputFieldHeader sx={{ alignContent: 'top', mt: '0.75rem' }}>
          {i18n.text(`reports.tracking.configuration.module.machineSelect`)}
        </StyledInputFieldHeader>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignContent: 'flex-start',
            textAlign: 'left',
          }}
        >
          {sortedAssetsInput && (
            <AssetsSelectAutocomplete
              selectedAssets={selectedAssets}
              assetsFiltered={sortedAssetsInput}
              handleAssetsChange={handleAssetsChange}
              handleSelectAllAssets={handleSelectAllAssets}
              selectAll={selectAll}
            ></AssetsSelectAutocomplete>
          )}
          <Typography
            fontWeight={200}
            marginRight="1rem"
            sx={{ fontSize: '0.875rem', fontWeight: 400, color: theme.palette.SFIGreyLight[600] }}
          >
            {i18n.text(filteredAssetsHelperText)}
          </Typography>
        </Box>
      </Box>
    </>
  )
}
