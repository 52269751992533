import React, { FC, SyntheticEvent, useState } from 'react'
import { Autocomplete, Box, Typography, outlinedInputClasses } from '@mui/material'

import {
  Block,
  BlockInfo,
  CompressorsAutocompleteWrapper,
  CompressorsText,
  CompressorsWrapper,
  TrashIconButton,
} from '../../CASettingsStyledComponents'
import { ArrowDownIcon } from '../../../../../Shared/components/icons'
import { AutocompleteOption } from '../../../../../Shared/types/types'
import { PlusIcon } from '../../../../../Shared/components/icons/PlusIcon'
import { StyledAutocompleteTextField } from '../../../../../Shared/components/MUIComponents/update/styledComponents/StyledAutocomplete'
import { StyledTooltip } from '../../../../../Shared/components/MUIComponents/Tooltip'
import { TrashIcon } from '../../../../../Shared/components/icons/TrashIcon'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'
import { AddButton, AddButtonText } from '../../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'

interface ICompressorsAutocompleteProps {
  compressors: AutocompleteOption[]
  options: AutocompleteOption[]
  onLinkCompressor: (val: AutocompleteOption | null, id: string) => void
  onUnlinkCompressor: (compressorId: string) => void
  onAddCompressor: (val: boolean) => void
}

type OpenStates = {
  [key: number]: boolean
}

export const CompressorsAutocomplete: FC<ICompressorsAutocompleteProps> = ({
  compressors,
  options,
  onLinkCompressor,
  onUnlinkCompressor,
  onAddCompressor,
}) => {
  const { i18n } = useI18nContext()
  const [openStates, setOpenStates] = useState<OpenStates>({ 0: false })

  const handleAddCompressorClick = (val: boolean) => (e: React.MouseEvent) => {
    if (compressors) {
      onAddCompressor(val)
    }
  }

  const handleLinkCompressor =
    (compressor: AutocompleteOption) => (e: SyntheticEvent<Element, Event>, value: AutocompleteOption | null) =>
      onLinkCompressor(value, compressor.id)

  const handleUnlinkCompressor = (compressorId: string) => (e: React.MouseEvent) => {
    onUnlinkCompressor(compressorId)
  }

  const handleOpenStates = (index: number, boolValue: boolean) => {
    return () => setOpenStates({ ...openStates, [index]: boolValue })
  }

  return (
    <Block>
      <Box>
        <Typography>{i18n.text('compressed-air.settings.linked-compressors')}</Typography>
        <BlockInfo>{i18n.text('compressed-air.settings.linked-compressors.extend')}</BlockInfo>
      </Box>
      <CompressorsWrapper>
        <CompressorsText>{i18n.text('compressed-air.asset-information.compressors')}</CompressorsText>
        {compressors?.map((compressor, index) => (
          <CompressorsAutocompleteWrapper key={compressor.id + index}>
            <Autocomplete
              id="compressors"
              open={openStates[index]}
              value={compressor}
              options={options}
              clearIcon={''}
              popupIcon={
                <ArrowDownIcon
                  sx={{
                    width: '1rem',
                    height: '1rem',
                  }}
                />
              }
              onOpen={handleOpenStates(index, true)}
              onClose={handleOpenStates(index, false)}
              onChange={handleLinkCompressor(compressor)}
              renderInput={params => {
                return (
                  <StyledAutocompleteTextField
                    {...params}
                    size="medium"
                    placeholder={i18n.text('compressed-air.settings.compressors.select')}
                    disabled
                    sx={{
                      [`.${outlinedInputClasses.root}`]: {
                        boxShadow: openStates[index] ? '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #1d7afd3d' : 'none',
                      },
                    }}
                  />
                )
              }}
            />
            {((!!compressor.id && compressors.length === 1) || compressors.length > 1) && (
              <StyledTooltip title={i18n.text('compressed-air.settings.compressors.delete')}>
                <TrashIconButton
                  disableRipple
                  onClick={handleUnlinkCompressor(compressor.id)}
                >
                  <TrashIcon />
                </TrashIconButton>
              </StyledTooltip>
            )}
          </CompressorsAutocompleteWrapper>
        ))}
        {compressors.length > 0 && !!compressors[0].id && (
          <AddButton
            disableRipple
            onClick={handleAddCompressorClick(true)}
          >
            <Box sx={{ display: 'flex' }}>
              <PlusIcon
                sx={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              />
            </Box>
            <AddButtonText>{i18n.text('compressed-air.settings.compressors.add')}</AddButtonText>
          </AddButton>
        )}
      </CompressorsWrapper>
    </Block>
  )
}
