import {
  DISMISS_MOTD,
  DISMISS_SETTINGS,
  DISMISS_TOAST,
  LOGOUT,
  PREFERENCE_CHANGE,
  SHOW_SETTINGS,
  SHOW_TOAST,
  UPDATE_NETWORK_STATUS,
} from '../constants/appConstants'
import { browserStorage } from '../utils'

export function logout(logoutHandler: $TSFixMeAsyncFunction) {
  return (dispatch: $TSFixMeDispatch) => {
    logoutHandler()
    dispatch({ type: LOGOUT })
  }
}

export function showToast(title: $TSFixMe, body: $TSFixMe, level: $TSFixMe) {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({
      type: SHOW_TOAST,
      payload: {
        toast: {
          title,
          body,
          level,
          timeStamp: Date.now(),
        },
      },
    })
  }
}

export function dismissToast(index: $TSFixMe) {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({
      type: DISMISS_TOAST,
      payload: { index },
    })
  }
}

export function dismissMotd() {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({
      type: DISMISS_MOTD,
    })
  }
}

export function hideSettings() {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({ type: DISMISS_SETTINGS })
    browserStorage.set('sf-hasSeenTrackingConsent', true)
  }
}

export function showSettings(pane = 'account') {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({ type: SHOW_SETTINGS, payload: { pane } })
  }
}

export function preferenceChange(preference: $TSFixMe, value: $TSFixMe) {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({ type: PREFERENCE_CHANGE, payload: { preference, value } })
    browserStorage.set(`sf-pref-${preference}`, value)
    if (preference === 'tracking-new') {
      global.window.location.reload()
    }
  }
}

export function updateNetworkStatus() {
  return (dispatch: $TSFixMeDispatch) =>
    dispatch({
      type: UPDATE_NETWORK_STATUS,
      payload: {
        online: global.window.navigator.onLine,
      },
    })
}

const actionCreators = {
  dismissMotd,
  dismissToast,
  hideSettings,
  logout,
  preferenceChange,
  showSettings,
  showToast,
  updateNetworkStatus,
}
export default actionCreators
