import { Box, styled } from '@mui/material'
import { FC } from 'react'

import { RegularDarkMdText, SemiboldDarkXlText } from '../../Common/v1'

const TextBox = styled(Box)(({ theme }) => ({
  padding: '1rem 0 1rem 1.5rem',
  borderLeft: `4px solid ${theme.palette.SFIGreyLight[100]}`,
  '&:hover': { borderLeft: `4px solid ${theme.palette.SFIBrand[900]}` },
}))

type TextBlockProps = {
  title: string
  subTitle: string
}

export const TextBlock: FC<TextBlockProps> = ({ title, subTitle }) => {
  return (
    <TextBox>
      <SemiboldDarkXlText>{title}</SemiboldDarkXlText>
      <RegularDarkMdText>{subTitle}</RegularDarkMdText>
    </TextBox>
  )
}
