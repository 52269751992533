import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import React from 'react'
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import { DateTime } from 'luxon'
import { KeyboardArrowRight, PrecisionManufacturingOutlined, Sensors } from '@mui/icons-material'
import { useState } from 'react'

import { DEFAULT_TIMEZONE } from '../../../../Shared/constants/timezone'
import { VibrationThresholdAlertEvent, useGetThresholdAlertMessagesQuery } from '../../../../Shared/graphql/codegen'
import { YEAR_MONTH_ABBR_DAY_FORMAT } from '../constants'
import { groupThresholdEventsPerAsset, groupThresholdEventsUnderRelativeDayLabel } from '../utils'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

interface ThresholdEventInnerTableRowProps {
  thresholdEvent: VibrationThresholdAlertEvent
  assetIndex: number
  thresholdEventIndex: number | null
}

interface ThresholdEventTableRowProps {
  assetIndex: number
  thresholdEventIndex: number | null
  thresholdEvent: VibrationThresholdAlertEvent
  handleThresholdEventOpen: (assetIndex: number) => void
}

const StyledTable = styled(Table)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: 'none',
  border: 'none',
}))

export default function ThresholdAlertMessages() {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { data, loading: isLoadingAlertEvents, error: thresholdAlertError } = useGetThresholdAlertMessagesQuery()
  const vibrationThresholdAlertEvents = data
    ? ([...data?.vibrationThresholdAlertEvents] as VibrationThresholdAlertEvent[])
    : []

  const [thresholdEventIndex, setThresholdEventIndex] = useState<number | null>(null)

  const { selectedCustomer } = useCurrentUser()
  const timezone = selectedCustomer?.timeZone ?? DEFAULT_TIMEZONE

  const thresholdEventAssets = groupThresholdEventsPerAsset(vibrationThresholdAlertEvents)
  const allThresholdEventsDays = Object.keys(
    groupThresholdEventsUnderRelativeDayLabel(timezone, vibrationThresholdAlertEvents)
  )

  if (isLoadingAlertEvents) {
    return <div>{i18n.text('app.loading')}</div>
  }

  if (thresholdAlertError || !data) {
    return (
      <Typography
        variant="h6"
        fontWeight={400}
        textAlign="center"
      >
        {i18n.text('notifications.messages.error')}
        <br /> {i18n.text('notifications.messages.error.more')}
      </Typography>
    )
  }
  if (!vibrationThresholdAlertEvents.length) {
    return null
  }

  const handleThresholdEventOpen = (index: number) => {
    setThresholdEventIndex(thresholdEventIndex === index ? null : index)
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ padding: '1rem', boxShadow: 'none' }}
    >
      <Typography
        variant="h5"
        sx={{
          color: theme.palette.SFIBrand[900],
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          fontWeight: '500',
        }}
      >
        {i18n.text('notifications.messages.thresholds.header')}
      </Typography>
      <StyledTable
        sx={{ minWidth: 650, border: 'none' }}
        aria-label="threshold violation alerts table"
      >
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              sx={{ fontSize: '1rem' }}
            >
              {' '}
              {i18n.text('notifications.messages.thresholds.table.date')}{' '}
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontSize: '1rem' }}
            >
              {i18n.text('notifications.messages.thresholds.table.asset')}
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontSize: '1rem' }}
            >
              {' '}
              {i18n.text('notifications.messages.thresholds.table.sensor.location')}
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontSize: '1rem' }}
            >
              {' '}
              {i18n.text('notifications.messages.thresholds.table.sensor.message')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(thresholdEventAssets).map((asset, assetIndex) => {
            const thresholdEventsAsset = thresholdEventAssets[asset]
            const thresholdEventsGroupedByDay = groupThresholdEventsUnderRelativeDayLabel(
              timezone,
              thresholdEventsAsset
            )
            const thresholdEventDays = Object.keys(thresholdEventsGroupedByDay)
            return (
              <React.Fragment key={assetIndex}>
                {thresholdEventDays.map((day, dayIndex) => {
                  const thresholdEvents = thresholdEventsGroupedByDay[day]
                  // This index calculation allows to continue iteartiong the day index even when the asset index changes, so that the thresholdEventIndex is unique and does not start from 0 for each asset.
                  const thresholdEventGlobalIndex = dayIndex + assetIndex * allThresholdEventsDays.length
                  return (
                    <React.Fragment key={dayIndex}>
                      <ThresholdEventTableRow
                        key={`${assetIndex}-${dayIndex}`}
                        assetIndex={thresholdEventGlobalIndex}
                        handleThresholdEventOpen={handleThresholdEventOpen}
                        thresholdEvent={thresholdEvents[0]}
                        thresholdEventIndex={thresholdEventIndex}
                      />
                      {thresholdEvents.map((event, index) => (
                        <ThresholdEventInnerTableRow
                          key={`${assetIndex}-${dayIndex}-${index}`}
                          thresholdEvent={event}
                          assetIndex={thresholdEventGlobalIndex}
                          thresholdEventIndex={thresholdEventIndex}
                        />
                      ))}
                    </React.Fragment>
                  )
                })}
              </React.Fragment>
            )
          })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

function ThresholdEventTableRow({
  assetIndex,
  thresholdEventIndex,
  thresholdEvent,
  handleThresholdEventOpen,
}: ThresholdEventTableRowProps) {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const createdAt = DateTime.fromISO(thresholdEvent.createdAt).toFormat(YEAR_MONTH_ABBR_DAY_FORMAT)
  const eventConditions = thresholdEvent.config.conditions
  const violationLevel =
    thresholdEvent.violationLevel === 0
      ? i18n.text('notifications.messages.asset.text.vibrating.warning')
      : i18n.text('notifications.messages.asset.text.vibrating.alarm')

  return (
    <TableRow
      sx={{ margin: 1, borderBottom: `2px solid ${theme.palette.SFIGreyLight[300]}` }}
      onClick={() => handleThresholdEventOpen(assetIndex)}
    >
      <TableCell
        component="th"
        scope="row"
        sx={{
          display: 'flex',
          border: 'none',
          alignItems: 'center',
        }}
      >
        <IconButton
          aria-label="expand row"
          sx={{ padding: '0px' }}
        >
          {thresholdEventIndex === assetIndex ? (
            <KeyboardArrowDownIcon fontSize="large" />
          ) : (
            <KeyboardArrowRight fontSize="large" />
          )}
        </IconButton>
        {createdAt}
      </TableCell>
      {eventConditions.map((condition, index) => (
        <React.Fragment key={index}>
          <TableCell
            component="th"
            scope="row"
          >
            <Typography
              component={'span'}
              sx={{ display: 'flex', justifyItems: 'center', fontSize: '1.1rem' }}
            >
              <PrecisionManufacturingOutlined sx={{ paddingRight: '0.3rem' }} />
              {condition.sensorLocation.asset.name}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              component={'span'}
              sx={{ display: 'flex', justifyItems: 'center', fontSize: '1.1rem' }}
            >
              <Sensors sx={{ paddingRight: '0.3rem' }} />
              {condition.sensorLocation.name}
            </Typography>
          </TableCell>
        </React.Fragment>
      ))}
      <TableCell align="left">
        <Typography
          component={'span'}
          display={'flex'}
        >
          {i18n.text('notifications.messages.asset.text.vibrating')}
          <Typography
            component={'span'}
            sx={{ fontWeight: '600', marginLeft: 0.5, marginRight: 0.5 }}
          >
            {' '}
            {violationLevel}{' '}
          </Typography>{' '}
          {i18n.text('notifications.messages.asset.text.vibrating.level')}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

function ThresholdEventInnerTableRow({
  thresholdEvent,
  assetIndex,
  thresholdEventIndex,
}: ThresholdEventInnerTableRowProps) {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const createdAt = DateTime.fromISO(thresholdEvent.createdAt).toFormat('HH:mm')
  const assetName = thresholdEvent.config.conditions[0].sensorLocation.asset.name
  const sensotLocationName = thresholdEvent.config.conditions[0].sensorLocation.name
  const violationLevel =
    thresholdEvent.violationLevel === 0
      ? i18n.text('notifications.messages.asset.text.vibrating.warning')
      : i18n.text('notifications.messages.asset.text.vibrating.alarm')

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 'none' } }}>
      <TableCell
        style={{ paddingTop: 0, paddingBottom: 0, paddingRight: 0, border: 'none' }}
        colSpan={6}
      >
        <Collapse
          in={thresholdEventIndex === assetIndex}
          timeout={900}
          unmountOnExit
        >
          <Box sx={{ margin: 1, borderBottom: `2px solid ${theme.palette.SFIGreyLight[300]}` }}>
            <Table>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 'none' } }}>
                  <TableCell
                    sx={{
                      display: 'flex',
                      paddingBottom: theme.spacing(2.4),
                      justifyItems: 'center',
                      justifyContent: 'center',
                      fontSize: '0.9rem',
                      fontWeight: '500',
                      paddingRight: '5rem',
                    }}
                  >
                    {createdAt}
                  </TableCell>
                  <TableCell>
                    <Typography
                      component={'span'}
                      sx={{ display: 'flex', justifyItems: 'center', fontSize: '1.1rem' }}
                    >
                      <PrecisionManufacturingOutlined sx={{ paddingRight: '0.3rem' }} />
                      {assetName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      component={'span'}
                      sx={{ display: 'flex', justifyItems: 'center', fontSize: '1.1rem' }}
                    >
                      <Sensors sx={{ paddingRight: '0.3rem' }} />
                      {sensotLocationName}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{}}
                  >
                    <Typography
                      component={'span'}
                      display={'flex'}
                    >
                      {i18n.text('notifications.messages.asset.text.vibrating')}
                      <Typography
                        component={'span'}
                        sx={{ fontWeight: '600', marginLeft: 0.5, marginRight: 0.5 }}
                      >
                        {' '}
                        {violationLevel}{' '}
                      </Typography>
                      {i18n.text('notifications.messages.asset.text.vibrating.level')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
