import React, { FC } from 'react'
import { ArrowRightIcon } from '@mui/x-date-pickers'
import { Box, Menu, useTheme } from '@mui/material'

import { CheckIcon } from '../../../icons'
import { StyledMenuItem } from './StyledMenuItem'
import { ToolbarMultiSelectItem } from '../../../../types/types'

export const MultiSelectMenuItem: FC<{ item: ToolbarMultiSelectItem }> = ({ item }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const theme = useTheme()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickListItem = (value: any) => {
    item.onValueToggle(value)
    handleClose()
  }
  return (
    <Box sx={{ borderTop: `solid 1px`, borderTopColor: theme.palette.SFIGreyLight[100] }}>
      <StyledMenuItem onClick={handleClick}>
        <Box sx={{ display: 'flex' }}>
          {item.label}
          <ArrowRightIcon fontSize="small" />
        </Box>
      </StyledMenuItem>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        MenuListProps={{
          role: 'listbox',
          sx: {
            padding: theme.spacing(0.5),
          },
        }}
      >
        {item.values.map((value, index) => {
          const isSelected = item.selectedValue === value
          return (
            <StyledMenuItem
              sx={{
                minWidth: '50px',
                backgroundColor: isSelected ? theme.palette.SFIGreyLight[50] : 'initial',
                borderRadius: '6px',
              }}
              key={index}
              onClick={() => {
                if (!item.isDisabled) {
                  handleClickListItem(value)
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'space-between',
                }}
              >
                {value} {isSelected && <CheckIcon />}
              </Box>
            </StyledMenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}
