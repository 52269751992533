import moment from 'moment'
import { isNullish, isNumber, isString } from 'type-guards'

import type { SignalQuality } from './constants'

export const getSensorStatus = (time?: string | null) => {
  if (isNullish(time) || (!isString(time) && !moment.isMoment(time) && !moment.isDate(time))) {
    return 'offline'
  }
  const now = moment()
  const then = moment(time)

  const dur = moment.duration(now.diff(then))

  return dur.asHours() > 48 ? 'offline' : 'online'
}

export const sensorSignalToString = (signal?: number | null): SignalQuality => {
  if (!isNumber(signal)) return 'unknown'
  if (signal >= -60) return 'excellent'
  if (signal >= -70) return 'good'
  if (signal >= -80) return 'fair'
  return 'poor'
}
