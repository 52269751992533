export const getLineChartColors = () => {
  const colors = [
    '#377eb8',
    '#ff7f00',
    '#a6cee3',
    '#E4C004',
    '#4daf4a',
    '#e41a1c',
    '#984ea3',
    '#a65628',
    '#f781bf',
    '#999999',
    '#000000',
  ]
  return colors
}
