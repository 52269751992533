import apexDe from 'apexcharts/dist/locales/de.json'
import apexEn from 'apexcharts/dist/locales/en.json'
import apexEs from 'apexcharts/dist/locales/es.json'
import apexFr from 'apexcharts/dist/locales/fr.json'
import apexIt from 'apexcharts/dist/locales/it.json'
import apexNl from 'apexcharts/dist/locales/nl.json'

import { zoomInIconHTML, zoomOutIconHTML } from '../components/ApexWrapper'

const apexLocales = [apexEn, apexDe, apexEs, apexIt, apexFr, apexNl]

export const defaultOptions = {
  chart: {
    group: 'trends',
    locales: apexLocales,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true,
    },
    animations: {
      enabled: false,
    },
    toolbar: {
      show: true,
      offsetY: -58,
      offsetX: 3,
      autoSelected: 'zoom',
      tools: {
        reset: false,
        zoomin: zoomInIconHTML,
        zoomout: zoomOutIconHTML,
        download: false,
        pan: false,
        selection: false,
        zoom: '<div width=0 height=0></div>',
      },
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeUTC: false,
    },
  },
  yaxis: {
    forceNiceScale: true,
    decimalsInFloat: 1,
    tickAmount: 6,
    labels: {
      minWidth: 20,
    },
  },
  tooltip: {
    fixed: {
      enabled: true,
      position: 'topRight',
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    position: 'bottom',
    horizontalAlign: 'center',
    fontSize: '14',
    markers: {
      width: 8,
      height: 8,
      radius: 8,
    },
  },
  stroke: {
    width: 2,
    dashArray: 0,
  },
  fill: {
    opacity: 1,
  },
}
