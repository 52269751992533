import calculateVibrationStatus from './calculateVibrationStatus'
import { AssetCardData } from '../containers/OverviewPageContainer/types'
import { PdmOverviewFragment } from '../../Shared/graphql/codegen'

// Reminder: util needs to be refactored and simplified.
export const transformGroupToCards: (
  group: PdmOverviewFragment['groups'][number],
  referenceValue: number
) => AssetCardData[] = (group, referenceValue) => {
  const cards = group.machines.flatMap(machine => {
    const cards = []
    for (const probe of machine.probes) {
      if (probe.sensors.length === 0) {
        continue
      }
      const sensorInsights = probe.sensors[0].insights
      const card: AssetCardData = {
        groupName: group.name,
        machineId: machine.id,
        probeId: probe.id,
        name: `${machine.name} | ${probe.name} `,
        machineName: machine.name,
        probeName: probe.name,
        description: machine.description ?? undefined,
        lastMeasurement: sensorInsights?.lastMeasurement?.time,
        lastMessage: sensorInsights?.lastMessage?.time,
        lookbackDuration: 0,
        vibrationStatus: calculateVibrationStatus(
          probe.insights?.velViolation,
          sensorInsights?.lastMeasurement,
          sensorInsights?.lastMessage,
          referenceValue
        ),
      }
      cards.push(card)
    }
    return cards
  })

  return cards
}
