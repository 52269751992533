import moment from 'moment'
import { groupBy, pipe, sort, take, uniqBy } from 'ramda'

import { ReportFragment as Report } from '../../../Shared/graphql/codegen'
import { ReportDocument, ReportGroup, TitleInfo } from './types'
import { SupportedLocale } from '../../../Shared/utils/supportedLocales'

const INSIGHTS_CONFIG_ID = '__insights__'
const EXAMPLE_REPORTS_ID = '__example_tracking_reports__'

const addReportDisplayInfo = (i18n: $TSFixMe, documents: Report[]) =>
  documents.map(doc => {
    const weekNumber =
      doc.configId === INSIGHTS_CONFIG_ID
        ? moment(doc.from).format('WW')
        : moment(doc.from).subtract(1, 'weeks').format('WW')
    const date =
      doc.from === doc.to
        ? `${moment(doc.to).format('DD MMM YYYY')}`
        : `${moment(doc.from).format('DD MMM YYYY')}/${moment(doc.to).format('DD MMM YYYY')}`

    const displayInfo = {
      name: i18n.text('reports.filename.insights', { weekNumber }),
      date,
    }

    return {
      ...doc,
      displayInfo,
    }
  })

const filterDocumentsByLocale = (locale: SupportedLocale, documents: $TSFixMe[]) => {
  return documents.filter(doc => {
    return doc.configId != null || doc.locale === locale
  })
}

interface ReportGroupInfo {
  heading: string
  description: string
}

const getReportGroupInfo = (docs: ReportDocument[], i18n: $TSFixMe): ReportGroupInfo => {
  const info: ReportGroupInfo = {
    heading: '',
    description: '',
  }

  if (docs[0].configId === INSIGHTS_CONFIG_ID) {
    info.heading = i18n.text('reports.insights.heading')
    info.description = i18n.text('reports.update-reports-description')
  } else {
    info.heading = docs[0].title || i18n.text('reports.tracking.configuration.general.title.suggestion')
    info.description = i18n.text('reports.default-description')
  }

  return info
}

const unique = uniqBy(document => (document as $TSFixMe).s3Url)
// @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
const sortByDate = sort((a, b) => moment((b as $TSFixMe).to) - moment((a as $TSFixMe).to))
const groupByConfig = groupBy(document => (document as $TSFixMe).configId)

const sanatizeConfigs = (documents: $TSFixMe) =>
  documents.map((d: $TSFixMe) => {
    if (d.configId != null) return d

    return {
      ...d,
      configId: INSIGHTS_CONFIG_ID,
    }
  })

export function getReportGroups(documents: Readonly<Report[]>, i18n: $TSFixMe, limit = 20): ReportGroup[] {
  const { locale } = i18n

  const filterByLocale = filterDocumentsByLocale.bind(null, locale)
  const addDisplayInfo = addReportDisplayInfo.bind(null, i18n)

  const formatReportGroups = pipe(unique, filterByLocale, sortByDate, sanatizeConfigs, addDisplayInfo, groupByConfig)
  const groups = formatReportGroups(documents)

  const result: ReportGroup[] = []

  for (const [key, docs] of Object.entries(groups)) {
    const { heading, description } = getReportGroupInfo(docs as ReportDocument[], i18n)
    result.push({
      id: key,
      heading,
      description,
      documents: take(limit, docs) as ReportDocument[],
    })
  }

  if (!checkHasTrackingReports(result)) {
    result.push({
      id: EXAMPLE_REPORTS_ID,
      heading: i18n.text('reports.tracking.request.preview-group-title'),
      description: i18n.text('reports.tracking.request.preview-group-description'),
      documents: [],
      preview: true,
    })
  }

  return result
}

const checkHasTrackingReports = (reportGroups: ReportGroup[]) => {
  return reportGroups.some(g => g.id !== INSIGHTS_CONFIG_ID)
}

export const getReportsIdsTitles = (reports: Readonly<Report[]>, titles: Readonly<TitleInfo[]>): Report[] => {
  const reportsIdsTitles: Report[] = Array.from(reports, report => report).map(report => {
    if (report.configId != null) {
      return {
        ...report,
        title: titles.find(obj => {
          return obj.id === report.configId
        })?.title,
        config: { id: report.configId },
      }
    } else {
      return {
        ...report,
        title: 'insights',
        config: { id: '__insights__' },
      }
    }
  })

  return reportsIdsTitles
}
