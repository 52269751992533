import { ApexOptions } from 'apexcharts'
import { Interval } from 'luxon'
import { useMemo } from 'react'
import { useTheme } from '@mui/material'

import { APEX_TOOLTIP_DATE_FORMAT } from '../../constants'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { zoomInIconHTML, zoomOutIconHTML } from '../ApexWrapper'

interface EnergyChartOptionsArgs {
  loading: boolean
  earliestDate: number
  interval: Interval
  tooltipFormatter: (chart: any, options?: any) => string
  handleZoomChange: (chart: unknown, changed: { xaxis: { min: number; max: number } }) => void
  beforeZoom: (chart: unknown, changed: { xaxis: { min: number; max: number } }) => void
  valuesModifier: { unit: string }
  showingCosts: boolean
  totalsFormatter: (v: string | undefined) => string
  currencyFormatter: (num: number, fractionDigits?: number) => string
  showCompare: boolean
  columnWidth: string
  decimal: number
}

export const useEnergyChartOptions = ({
  loading,
  earliestDate,
  interval,
  tooltipFormatter,
  handleZoomChange,
  beforeZoom,
  valuesModifier,
  showingCosts,
  totalsFormatter,
  showCompare,
  columnWidth,
  currencyFormatter,
  decimal,
}: EnergyChartOptionsArgs) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const energyChartOptions: ApexOptions = useMemo(
    () => ({
      noData: {
        text: loading ? i18n.text('app.loading') : i18n.text('analysis.energy-balance.no-data'),
      },
      chart: {
        id: 'energyChart' + Math.random(),
        type: 'bar',
        background: 'white',
        stacked: true,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
          offsetY: 21,
          autoSelected: 'zoom',
          tools: {
            reset: false,
            zoomin: zoomInIconHTML,
            zoomout: zoomOutIconHTML,
            download: false,
            pan: false,
            selection: false,
            zoom: '<div width=0 height=0></div>',
          },
        },
        events: {
          beforeZoom,
          zoomed: handleZoomChange,
        },
      },
      tooltip: {
        x: {
          show: true,
          format: APEX_TOOLTIP_DATE_FORMAT,
        },
        y: {
          formatter: tooltipFormatter,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth,
          dataLabels: {
            total: {
              enabled: !showCompare,
              formatter: totalsFormatter,
              offsetX: 0,
              style: {
                fontSize: '14px',
                fontWeight: 400,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'datetime',
        min: earliestDate,
        max: interval.end.toMillis(),
        labels: {
          datetimeUTC: false,

          style: {
            fontSize: '14px',
          },
        },
        tickPlacement: 'on',
      },
      yaxis: {
        // legacy scale is 1.5 max y value and 5 ticks
        // max is generated based on largest value in data
        min: 0,
        max: max => max * 1.25,
        forceNiceScale: true,
        tickAmount: 5,
        labels: {
          minWidth: 80,
          maxWidth: 80,
          offsetY: -10,
          style: {
            fontSize: '14px',
          },
          formatter: v =>
            showingCosts
              ? currencyFormatter(v, 0)
              : `${i18n.number(v, { maximumFractionDigits: decimal })} ${valuesModifier.unit}`,
        },
        decimalsInFloat: 0,
      },

      grid: {
        show: true,
        borderColor: 'lightGrey',
        strokeDashArray: 6,
      },
      legend: {
        fontFamily: theme.typography.fontFamily,
        showForSingleSeries: true,
        horizontalAlign: 'left',
        fontSize: '14px',
        offsetY: 10,
        itemMargin: {
          vertical: 10,
        },
      },
      markers: {
        size: 0,
        // Apex uses JSON.stringify to diff options and does not see updated
        // functions such as an updated formatter, so we update this stroke
        // opacity which is not being used to force re-render
        strokeOpacity: Math.random(),
      },
      stroke: {
        width: 3,
        colors: ['transparent'],
      },
    }),
    [
      earliestDate,
      interval.end,
      tooltipFormatter,
      handleZoomChange,
      beforeZoom,
      valuesModifier.unit,
      showingCosts,
      totalsFormatter,
      showCompare,
      columnWidth,
      currencyFormatter,
      decimal,
    ]
  )
  return energyChartOptions
}
