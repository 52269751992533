import { createSvgIcon } from '@mui/material'

export const FileIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10 2.26953V6.40007C10 6.96012 10 7.24015 9.89101 7.45406C9.79513 7.64222 9.64215 7.7952 9.45399 7.89108C9.24008 8.00007 8.96005 8.00007 8.4 8.00007H4.26946M16 15V18M8 13V18M12 10.5V18M4 9.98822V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H11.9882C11.2545 2 10.8876 2 10.5423 2.08289C10.2362 2.15638 9.94356 2.27759 9.67515 2.44208C9.3724 2.6276 9.11297 2.88703 8.59411 3.40589L5.40589 6.59411C4.88703 7.11297 4.6276 7.3724 4.44208 7.67515C4.27759 7.94356 4.15638 8.2362 4.08289 8.5423C4 8.88757 4 9.25445 4 9.98822Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'file-icon'
)
