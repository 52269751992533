import React, { FC, useMemo } from 'react'
import { ApexOptions } from 'apexcharts'
import { Typography } from '@mui/material'

import GenericChart from '../GraphsShared/GenericChart'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { StopReasonSummary } from '../../types/Assets.types'
import { getAssetColor } from '../../../Shared/utils'
import { getFormattedDuration } from '../../utils/helpers'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type StopTimeByCategoryProps = {
  stops: Record<string, StopReasonSummary>
}

type Series = {
  name: string
  data: number[]
  color: string
}

const StopTimeByCategory: FC<StopTimeByCategoryProps> = ({ stops }) => {
  const { i18n } = useI18nContext()
  const { selectedStopReasons } = useOEEContext()

  const series = useMemo(
    () =>
      Object.entries(stops).reduce<Series[]>((acc, [key, value], index) => {
        if (selectedStopReasons.includes(key)) {
          acc.push({
            name: key,
            data: [Math.round(value.totalDurationInMinutes)],
            color: getAssetColor(index),
          })
        }
        return acc
      }, []),
    [selectedStopReasons, stops]
  )

  const customOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      stacked: true,
    },
    xaxis: {
      type: 'category',
      categories: [''],
    },
    plotOptions: {
      bar: {
        columnWidth: '110px',
        borderRadius: 4,
      },
    },
    yaxis: {
      labels: {
        formatter: getFormattedDuration,
      },
    },
    legend: {
      showForSingleSeries: true,
    },
    dataLabels: {
      enabled: true,
      formatter: getFormattedDuration,
    },
  } as ApexOptions

  return (
    <SectionBoxWrapper>
      <Typography variant="h6">{i18n.text('oee.stop-analysis.categories-chart.title')}</Typography>
      <GenericChart
        id="stop-time-by-category"
        customOptions={customOptions}
        series={series}
        chartType="bar"
        height={300}
      />
    </SectionBoxWrapper>
  )
}

export default StopTimeByCategory
