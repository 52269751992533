import { add } from 'date-fns'

import { HourOfDay, MinuteOfHour } from '../../../../Shared/types/types'

export function transformToDate(hour: HourOfDay = 0 as HourOfDay, minute: MinuteOfHour = 0 as MinuteOfHour): Date {
  const REFERENCE_TIME = new Date('2022-01-10T00:00:00.000')
  return add(REFERENCE_TIME, { hours: hour as number, minutes: minute as number })
}

export function transformFromDate(date = new Date('2022-01-10T00:00:00.000')): {
  hour: HourOfDay
  minute: MinuteOfHour
} {
  return {
    hour: date.getHours() as HourOfDay,
    minute: date.getMinutes() as MinuteOfHour,
  }
}
