import Icon from '@sensorfactdev/nucleus/Icon'
import Loader from '@sensorfactdev/nucleus/Loader'
import NestedList from '@sensorfactdev/nucleus/NestedList'
import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useStore } from 'react-redux'

import ListWrapper from '../ListWrapper'
import { ASSET_UPDATED } from '../../constants/analysis_constants'
import { GROUP, MACHINE } from '../../constants/tabTypes'
import { LocalAsset } from '../../types/analysis_types'
import { formatAssetList } from '../../utils/analysis_utils'
import { handleGenericError, themeColors } from '../../utils'
import { useI18nContext } from '../../contexts/i18nContext/I18nContext'
import { useUpdateMyAssetMutation } from '../../graphql/codegen'

const TabsWrapper = styled.div``

const Tab = styled.button<{ active: boolean }>`
  border: none;
  outline: none;
  cursor: pointer;
  width: 50%;
  padding: 1.83em;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Rubik', Helvetica, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: ${({ active }) => (active ? themeColors.secondary : themeColors.white)};
  background-color: ${({ active }) => (active ? 'transparent' : themeColors.grey)};
  opacity: ${({ active }) => (active ? '1' : '0.3')};

  &:hover {
    color: ${themeColors.success};
    opacity: 1;
  }
`

const NestedListWrapper = styled.div`
  padding-bottom: 6em;
`

const UnOrderedList = styled.ul`
  padding: 0.5em 0;
  padding-bottom: 6em;
`

const ListItem = styled.li`
  width: 100%;
  box-sizing: border-box;
  list-style: none;
  position: relative;

  &:before,
  &:after {
    content: ' ';
  }

  &:after {
    display: table;
    clear: both;
  }
`

const ListItemInner = styled.button<{ selected: boolean }>`
  outline: none;
  border: none;
  display: block;
  float: right;
  width: 100%;
  padding: 1em 1em 1em 1.5em;
  background-color: transparent;
  color: ${({ selected }) => (selected ? themeColors.secondary : themeColors.whiteDark)};
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-family: 'Rubik', Helvetica, sans-serif;
  text-overflow: ellipsis;
  text-align: left;
  font-weight: 400;
  font-size: 15px;

  &:hover {
    color: ${themeColors.success};

    .compareButton {
      right: 0;
      transition: all 0.2s ease-out;
    }
  }
`

const AssetIcon = styled(Icon)`
  height: 1.5em;
  margin-top: 0.4em;
  margin-right: 0.5em;
`

const GroupItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const GroupName = styled.span``

const CustomerName = styled.div`
  position: fixed;
  bottom: 0;
  padding: 1em 0.5em;
  font-size: 14px;
  color: ${themeColors.white};
  background-color: ${themeColors.primaryDark};
  max-width: 18.1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const CustomerLabel = styled.span`
  margin-left: 5px;
`

type Props = {
  activeTab?: $TSFixMe
  assets: LocalAsset[]
  hasInfo?: boolean
  hasMultipleCustomers?: boolean
  hasTotalConsumption: boolean
  isLoading?: boolean
  isDashboard?: boolean
  onAssetSelect: $TSFixMeFunction
  onTabNavigate: $TSFixMeFunction
  onToggleFamilySelect: $TSFixMeFunction
  onTotalConsumptionSelect: $TSFixMeFunction
  selectedAssets: string[][]
  selectedCustomerName?: string
  totalConsumptionSelected: boolean
}

/** @deprecated do not use */
const AssetList: React.FC<Props> = ({
  activeTab = MACHINE,
  assets,
  hasInfo = true,
  hasMultipleCustomers = false,
  hasTotalConsumption,
  isLoading = false,
  isDashboard = false,
  onAssetSelect,
  onTabNavigate,
  onToggleFamilySelect,
  onTotalConsumptionSelect,
  selectedCustomerName = 'Unknown',
  selectedAssets,
  totalConsumptionSelected,
}) => {
  const { i18n } = useI18nContext()
  const store = useStore()
  const history = useHistory()

  const [updateMyAssetMutation, { loading: updating }] = useUpdateMyAssetMutation({
    onCompleted: response => {
      store.dispatch({ type: ASSET_UPDATED, payload: response.updateMyAsset })
    },
    onError: error => {
      handleGenericError(ASSET_UPDATED, error, store.dispatch, i18n.text('error.asset.update'))
    },
  })

  if (isLoading || updating) {
    return (
      <ListWrapper
        className="AssetList-Wrapper"
        isDashboard={isDashboard}
      >
        <Loader fill={themeColors.white} />
      </ListWrapper>
    )
  }

  const onSaveDetailChanges = (data: $TSFixMe) => {
    const { id, name } = data

    updateMyAssetMutation({
      variables: {
        id,
        name,
      },
    })
  }

  const { assetList, selectedAssetsList } = formatAssetList(assets, selectedAssets)

  return (
    <ListWrapper isDashboard={isDashboard}>
      <TabsWrapper>
        <Tab
          type="button"
          active={activeTab === MACHINE}
          onClick={() => onTabNavigate(MACHINE)}
        >
          {i18n.text('analyis.asset-list.tab.machine')}
        </Tab>
        <Tab
          type="button"
          active={activeTab === GROUP}
          onClick={() => onTabNavigate(GROUP)}
        >
          {i18n.text('analyis.asset-list.tab.group')}
        </Tab>
      </TabsWrapper>
      {activeTab === MACHINE && (
        <NestedListWrapper className="AssetList-Wrapper">
          <NestedList
            items={assetList}
            selectedItems={selectedAssetsList}
            onItemSelect={onAssetSelect}
            onToggleFamilySelect={onToggleFamilySelect}
            onSaveDetailChanges={onSaveDetailChanges}
            i18n={i18n}
            hasInfo={hasInfo}
            appearance="secondary"
          />
        </NestedListWrapper>
      )}
      {activeTab === GROUP && (
        <UnOrderedList>
          {hasTotalConsumption && (
            <ListItem key="total-consumption">
              <ListItemInner
                type="button"
                onClick={onTotalConsumptionSelect}
                selected={totalConsumptionSelected}
              >
                <GroupName>{i18n.text('analysis.total-consumption')}</GroupName>
              </ListItemInner>
            </ListItem>
          )}
          {assetList.map(({ name, id, icon }) => (
            <ListItem key={id}>
              <ListItemInner
                type="button"
                onClick={() => onAssetSelect({ id })}
                selected={selectedAssets[0].includes(id)}
              >
                <GroupItem>
                  <AssetIcon
                    icon={icon}
                    appearance="secondary"
                    size="small"
                  />
                  <GroupName>{name}</GroupName>
                </GroupItem>
              </ListItemInner>
            </ListItem>
          ))}
        </UnOrderedList>
      )}
    </ListWrapper>
  )
}

export default AssetList
