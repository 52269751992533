import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'

import ProductionScheduleForm, { emptyProductionTime } from './ProductionScheduleForm'
import { FormSubmitData, ParsedSchedule } from './types'
import { ID } from '../../../Shared/types/types'
import { ProductionSchedulesList } from './ProductionSchedulesList'
import { formatProductionTimes } from './utils/formatProductionTimes'
import { parseSchedule } from './utils/parseProductionSchedule'
import {
  useCreateProductionScheduleMutation,
  useDeleteProductionScheduleMutation,
  useGetProductionSchedulesQuery,
  useUpdateProductionScheduleMutation,
} from '../../../Shared/graphql/codegen'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'

const NEW_SCHEDULE_ID = 'NEW_SCHEDULE_ID' as ID

const newProductionSchedule: ParsedSchedule = {
  id: NEW_SCHEDULE_ID,
  name: 'New schedule',
  productionTimes: [{ ...emptyProductionTime }],
}

const ProductionSchedulesPage = () => {
  const [selectedSchedule, setSelected] = useState<ParsedSchedule | null>(null)

  const { getHasuraRoleHeader, customerId } = useCurrentUser()
  const context = {
    headers: {
      'x-hasura-role': getHasuraRoleHeader(),
    },
  }

  const {
    loading: getScheduleLoading,
    data: getSchedulesResult,
    error: getSchedulesError,
    refetch: refetchSchedules,
  } = useGetProductionSchedulesQuery()

  const [updateProductionScheduleMutation] =
    useUpdateProductionScheduleMutation({ context })

  const [createProductionScheduleMutation] =
    useCreateProductionScheduleMutation({ context })

  const [deleteProductionScheduleMutation] =
    useDeleteProductionScheduleMutation({ context })

  const schedules = getSchedulesResult?.myOrg?.schedules || []
  const parsedSchedules = schedules.map(parseSchedule)

  const handleSubmit = async (data: FormSubmitData) => {
    if (!selectedSchedule) return
    const { name, productionTimes } = data
    const formattedProductionTimes = formatProductionTimes(productionTimes)

    if (selectedSchedule.id === NEW_SCHEDULE_ID) {
      await createProductionScheduleMutation({
        variables: {
          customerId,
          name,
          configuration: {
            productionTimes: formattedProductionTimes,
          },
        },
      })
      handleNew()
    } else {
      await updateProductionScheduleMutation({
        variables: {
          name,
          scheduleId: selectedSchedule?.id as ID,
          configuration: {
            productionTimes: formattedProductionTimes,
          },
        },
      })
    }
    await refetchSchedules()
  }

  const handleDelete = async () => {
    if (!selectedSchedule || selectedSchedule.id === NEW_SCHEDULE_ID) return

    await deleteProductionScheduleMutation({ variables: { scheduleId: selectedSchedule.id as ID } })
    await refetchSchedules()
    setSelected(null)
  }

  const handleNew = () => {
    setSelected({ ...newProductionSchedule })
  }

  // TODO handle these better
  if (getScheduleLoading) return <p>Loading...</p>
  if (getSchedulesError)
    return (
      <p>
        Error: <pre>{JSON.stringify(getSchedulesError)}</pre>
      </p>
    )

  return (
    <Grid
      container
      p="1.8rem"
      height="80vh"
    >
      {/* Sidebar */}
      <Grid
        item
        xs={3}
        sx={{
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <ProductionSchedulesList
          schedules={parsedSchedules}
          selectedSchedule={selectedSchedule}
          onSelect={setSelected}
          onNew={handleNew}
        />
      </Grid>

      {/* Main */}
      <Grid
        item
        xs={9}
        md={8}
        sx={{
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Box>
          <ProductionScheduleForm
            productionSchedule={selectedSchedule as ParsedSchedule}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default ProductionSchedulesPage
