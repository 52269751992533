import { Oee_Production_Speed_Unit_Enum } from '../../Shared/graphql/codegen'
import { SPEED_UNIT_STRING } from './constants'
import { TableHeadCell } from '../types/Assets.types'

export const getStopsOverviewTableHead = (): TableHeadCell[] => {
  return [
    {
      id: 'machine',
      label: 'oee.stop-analysis.stops-overview.table-head.machine',
      align: 'left',
    },
    {
      id: 'startTime',
      label: 'oee.stop-analysis.stops-overview.table-head.start-time',
      align: 'center',
    },
    {
      id: 'endTime',
      label: 'oee.stop-analysis.stops-overview.table-head.end-time',
      align: 'center',
    },
    {
      id: 'duration',
      label: 'oee.stop-analysis.stops-overview.table-head.duration',
      align: 'center',
    },
    {
      id: 'stopReason',
      label: 'oee.stop-analysis.stops-overview.table-head.stop-reason',
      align: 'left',
    },
    {
      id: 'operatorFeedback',
      label: 'oee.stop-analysis.stops-overview.table-head.operator-input',
      align: 'left',
    },
  ]
}

export const getCategoryDataSummaryTableHead = (): TableHeadCell[] => {
  return [
    { id: 'category', label: 'oee.stop-analysis.categories-table.category', align: 'left' },
    { id: 'totalDuration', label: 'oee.stop-analysis.categories-table.total-duration', align: 'center' },
    { id: 'totalStops', label: 'oee.stop-analysis.categories-table.number-of-stops', align: 'center' },
    { id: 'averageDuration', label: 'oee.stop-analysis.categories-table.average-duration', align: 'center' },
  ]
}

export const getProductionStatisticsTableHead = (unit: Oee_Production_Speed_Unit_Enum): TableHeadCell[] => {
  const unitString = SPEED_UNIT_STRING[unit]

  return [
    {
      id: 'machine',
      label: 'oee.production-statistics.table-heading.machine',
      align: 'left',
    },
    {
      id: 'startTime',
      label: 'oee.production-statistics.start-time',
      align: 'center',
    },
    {
      id: 'endTime',
      label: 'oee.production-statistics.end-time',
      align: 'center',
    },
    {
      id: 'totalRuntime',
      label: 'oee.production-statistics.table-heading.total-runtime',
      align: 'center',
    },
    {
      id: 'totalStopTime',
      label: 'oee.production-statistics.table-heading.total-stoptime',
      align: 'center',
    },
    {
      id: 'availability',
      label: 'oee.production-statistics.availability',
      align: 'center',
    },
    {
      id: 'totalCycles',
      label: 'oee.production-statistics.table-heading.total-cycles',
      align: 'center',
    },
    {
      id: 'averageSpeedVsTotalTime',
      label: 'oee.production-statistics.average-speed-vs-total-time',
      unit: unitString,
      align: 'center',
    },
    {
      id: 'averageSpeedVsRuntime',
      label: 'oee.production-statistics.average-speed-vs-runtime',
      unit: unitString,
      align: 'center',
    },
    {
      id: 'maxSpeed',
      label: 'oee.production-statistics.table-heading.max-speed',
      unit: unitString,
      align: 'center',
    },
  ]
}

export const getShiftComparisonHeadCells = (): TableHeadCell[] => {
  return [
    {
      id: 'machine',
      label: 'oee.shift-comparison.table.header.machine',
      align: 'left',
    },
    {
      id: 'shift',
      label: 'oee.shift-comparison.table.header.shift',
      align: 'left',
    },
    {
      id: 'oee',
      label: 'oee.shift-comparison.table.header.oee',
      align: 'center',
      unit: '%',
    },
    {
      id: 'availability',
      label: 'oee.shift-comparison.table.header.availability',
      align: 'center',
      unit: '%',
    },
    {
      id: 'performance',
      label: 'oee.shift-comparison.table.header.performance',
      align: 'center',
      unit: '%',
    },
    {
      id: 'totalPieces',
      label: 'oee.shift-comparison.table.header.total-pieces',
      align: 'center',
    },
    {
      id: 'seeDetails',
      label: '',
      align: 'center',
    },
  ]
}
