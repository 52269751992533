import List from '@mui/material/List'
import React, { FC } from 'react'
import { Box, FormControlLabel, ListItem, Typography } from '@mui/material'

import { StyledCheckbox as Checkbox } from '../../../Shared/components/MUIComponents/update/Checkbox/StyledCheckBox'
import { FilterIcon } from '../../../Shared/components/icons'
import { StyledMenu } from '../../../Shared/components/MUIComponents/update/StyledMenu'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

const StopReasonsFilter: FC = () => {
  const { stopReasons, selectedStopReasons, selectStopReason, deselectStopReason } = useOEEContext()
  const { i18n } = useI18nContext()

  const handleStopReasonChange = (event: React.ChangeEvent<HTMLInputElement>, reason: string) => {
    if (event.target.checked) selectStopReason(reason)
    else deselectStopReason(reason)
  }

  return (
    <StyledMenu
      menuButtonContent={
        <>
          <FilterIcon sx={{ width: '1rem', height: '1rem' }} />
          {i18n.text('oee.stop-analysis.filter.label')}
        </>
      }
    >
      <Box sx={{ padding: '1rem' }}>
        {(!stopReasons || stopReasons.length === 0) && (
          <Typography>{i18n.text('oee.stop-analysis.filter.no-data')}</Typography>
        )}
        {stopReasons && stopReasons.length > 0 && (
          <List>
            {stopReasons.map(reason => (
              <ListItem
                key={reason}
                sx={{ display: 'flex', gap: '1rem' }}
              >
                <FormControlLabel
                  label={reason}
                  control={
                    <Checkbox
                      sx={{ padding: 0, marginRight: '0.5rem' }}
                      id={reason}
                      checked={selectedStopReasons.includes(reason)}
                      color="secondary"
                      size="small"
                      onChange={event => handleStopReasonChange(event, reason)}
                    />
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </StyledMenu>
  )
}

export default StopReasonsFilter
