import * as Sentry from '@sentry/react'
import createSentryMiddleware from 'redux-sentry-middleware'
import thunk from 'redux-thunk'
import { Iterable } from 'immutable'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { createLogger } from 'redux-logger'

import app from '../containers/AppContainer/reducers'
import gas from '../../Gas/containers/GasContainer/reducers'
import installation from '../containers/Installation/Sensors/reducers'
import login from '../containers/LoginPage/reducers'
import water from '../../Water/containers/WaterContainer/reducers'
import { InferStoreState } from '../../Shared/types/types'

const rootReducer = combineReducers({
  app,
  gas,
  water,
  login,
  installation,
})

export type RootState = InferStoreState<typeof rootReducer>

/**
 * Redeclare DefaultRootState in module react-redux, so useStore() is bound to it
 */
declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultRootState extends RootState {}
}

const actionTransformer = <T extends $TSFixMe>(action: T): T & { type: string } => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type 'T'.
  return Object.assign({ type: String(action.type ?? 'unknown') }, action)
}

const stateTransformer = (state: $TSFixMe) =>
  Object.keys(state).reduce((newState, key) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    newState[key] = Iterable.isIterable(state[key]) ? state[key].toJS() : state[key]
    return newState
  }, {})

const logger = createLogger({
  collapsed: true,
  actionTransformer,
  stateTransformer,
})

const browserExtDevtoolsOptions = {
  serialize: {
    options: true,
  },
}

const browserExtDevtools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__(browserExtDevtoolsOptions)
  : (f: $TSFixMe) => f

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof import("/Users/davydkov/S... Remove this comment to see the full error message
const sentryMiddleware = createSentryMiddleware<RootState>(Sentry, {
  actionTransformer,
  stateTransformer: state =>
    Object.keys(state).reduce((newState, key) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newState[key] = Iterable.isIterable((state as $TSFixMe)[key])
        ? (state as $TSFixMe)[key].toJS()
        : (state as $TSFixMe)[key]
      // Filter out big private payload to prevent 413 status code
      if (key === 'electricity' || key === 'gas' || key === 'water') {
        ;['altMeasurements', 'measurements', 'altConsumption', 'assets'].forEach(ignore => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          delete newState[key][ignore]
        })
      }
      return newState
    }, {}),
  breadcrumbDataFromAction: action => ({ type: String(action.type) }),
})
const enhancer =
  process.env.NODE_ENV === 'production'
    ? compose(applyMiddleware(thunk, sentryMiddleware))
    : compose(applyMiddleware(thunk, logger, sentryMiddleware), browserExtDevtools)

export default function configureStore(initialState: $TSFixMe) {
  // Note: only Redux >= 3.1.0 supports passing enhancer as third argument.
  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  const store = createStore(rootReducer, initialState, enhancer)
  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if ((module as $TSFixMe).hot) {
    ;(module as $TSFixMe).hot.accept(
      [
        '../../Water/containers/WaterContainer/reducers',
        '../../Gas/containers/GasContainer/reducers',
        '../containers/AppContainer/reducers',
        '../containers/Installation/Sensors/reducers',
        '../containers/LoginPage/reducers',
      ],
      () => store.replaceReducer(rootReducer)
    )
  }
  return store
}
