import { MenuItem, styled } from '@mui/material'

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '1.25rem',
  color: theme.palette.SFIGreyLight[700],
}))
