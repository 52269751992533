import { FC } from 'react'

import { StyledTableCell, StyledTableRow } from '../StyledTable'
import { minutesToHumanReadable } from '../../utils/helpers'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type StopReasonsStatisticsRowProps = {
  row: {
    id: string
    category: string
    totalDuration: number
    totalStops: number
    averageDuration: number
  }
}

const StopReasonsStatisticsRow: FC<StopReasonsStatisticsRowProps> = ({ row }) => {
  const { i18n } = useI18nContext()

  const totalDuration = minutesToHumanReadable(row.totalDuration)
  const averageDuration = minutesToHumanReadable(row.averageDuration)

  return (
    <StyledTableRow>
      <StyledTableCell>{row.category}</StyledTableCell>
      <StyledTableCell align="center">{totalDuration}</StyledTableCell>
      <StyledTableCell align="center">{i18n.number(row.totalStops)}</StyledTableCell>
      <StyledTableCell align="center">{averageDuration}</StyledTableCell>
    </StyledTableRow>
  )
}

export default StopReasonsStatisticsRow
