import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import AppLayout from './Layouts/AppLayout'
import OEEContextProvider from '../context/OEEContext'

const OverallEquipmentEffectiveness = () => {
  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <OEEContextProvider>
        <AppLayout />
      </OEEContextProvider>
    </QueryParamProvider>
  )
}

export default OverallEquipmentEffectiveness
