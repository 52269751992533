import { FC } from 'react'

import { CustomTable } from '../../../../Shared/components/MUIComponents/update/CustomTable'
import { ICustomTableColumn } from '../../../../Shared/types/types'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

type PeakShavingTableInputProps = {
  machineStartTimeShifts: {
    id: number
    dispatched_assets: string
    dispatches: string
  }[]
}

type PeakShavingTableProps = {
  dispatched_assets: string
  dispatches: string
  id: string
}
export const PeakShavingTable: FC<PeakShavingTableInputProps> = ({ machineStartTimeShifts }) => {
  const { i18n } = useI18nContext()
  const tableColumns: ICustomTableColumn<PeakShavingTableProps>[] = [
    {
      id: 'dispatched_assets',
      label: i18n.text('scan.savings.peak_shaving.v1.table.dispatched-assets'),
      align: 'left',
    },
    { id: 'dispatches', label: i18n.text('scan.savings.peak_shaving.v1.table.dispatches'), align: 'left' },
  ]
  const tableRows: PeakShavingTableProps[] = machineStartTimeShifts.map(row => ({
    ...row,
    dispatched_assets: row.dispatched_assets,
    dispatches: row.dispatches,
    id: row.id.toString(),
  }))

  return (
    <CustomTable
      columns={tableColumns}
      rows={tableRows}
    />
  )
}
