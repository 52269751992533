import { Box, Button, IconButton, SxProps, Theme, Typography, styled, useTheme } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'
import { FC, ReactNode } from 'react'

import { CrossIcon, FeaturedInfoCircleIcon, FeaturedSuccessCircleIcon } from '../../../icons'
import { OutlinedStyledButton } from '../OutlinedStyledButton'
import { SectionBoxWrapper } from '../styledComponents/SectionBoxWrapper'

type AnnouncementBannerVariant = 'info' | 'success' | 'warning' | 'error'

interface AnnouncementBannerProps {
  variant?: AnnouncementBannerVariant
  primaryText?: string
  secondaryText?: string
  showCtaButton?: boolean
  ctaText?: string
  ctaType?: ButtonProps['variant']
  ctaColor?: ButtonProps['color']
  customTextSlot?: ReactNode
  onCtaButtonClick?: () => void
  onDismiss?: () => void
  sx?: SxProps<Theme>
}

const TextAndIconContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.875rem',
})

const TextContainer = styled(Box)({
  display: 'flex',
  gap: '0.38rem',
})

const ButtonsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
})

const PrimaryText = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: '600',
  lineHeight: '1.25rem',
})

const SecondaryText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '400',
  lineHeight: '1.25rem',
  color: theme.palette.SFIGreyLight[600],
}))

const CtaButton = styled(Button)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '600',
  lineHeight: '1.25rem',
  textTransform: 'none',
  padding: theme.spacing(1.25, 1.75),
}))

/**
 * AnnouncementBanner component displays a banner with an optional call-to-action button and a dismiss option.
 * This banner can be used to inform users about important information or actions they need to take.
 * It supports four variants for styling the icon: "info", "success", "warning", and "error". The wrapper should be styled in the parent component.
 *
 *  @param {'info' | 'success' | 'warning' | 'error'} [variant='info'] - The variant of the banner icon.
 *  @param {string} [primaryText] - The primary text of the banner.
 *  @param {string} [secondaryText] - The secondary text of the banner.
 *  @param {boolean} [showCtaButton='false'] - Whether to show the call-to-action button.
 *  @param {string} [ctaText] - The text of the call-to-action button.
 *  @param {'contained' | 'outlined' | 'text'} [ctaType='contained'] - The type of the call-to-action button.
 *  @param {'primary' | 'secondary' | 'default'} [ctaColor='secondary'] - The color of the call-to-action button.
 *  @param {ReactNode} [customTextSlot] - A custom slot for additional text or components.
 *  @param {function} [onCtaButtonClick] - The function to call when the call-to-action button is clicked.
 *  @param {function} [onDismiss] - The function to call when the close icon is clicked.
 *  @param {SxProps<Theme>} [sx] - The custom styles to apply to the banner wrapper.
 * **/
export const AnnouncementBanner: FC<AnnouncementBannerProps> = ({
  variant = 'info',
  primaryText,
  secondaryText,
  showCtaButton,
  ctaText,
  ctaType,
  ctaColor,
  customTextSlot,
  onCtaButtonClick,
  onDismiss,
  sx,
}) => {
  const theme = useTheme()

  const renderIcon = () => {
    switch (variant) {
      case 'success':
        return <FeaturedSuccessCircleIcon sx={{ width: '38px', height: '38px', color: theme.palette.success.main }} />
      case 'warning':
        return <FeaturedInfoCircleIcon sx={{ width: '38px', height: '38px', color: theme.palette.warning.main }} />
      case 'error':
        return <FeaturedInfoCircleIcon sx={{ width: '38px', height: '38px', color: theme.palette.error.main }} />
      case 'info':
      default:
        return <FeaturedInfoCircleIcon sx={{ width: '38px', height: '38px', color: theme.palette.SFIBrand[950] }} />
    }
  }

  return (
    <SectionBoxWrapper
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <TextAndIconContainer>
        {renderIcon()}
        <TextContainer>
          {primaryText && <PrimaryText>{primaryText}</PrimaryText>}
          {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
        </TextContainer>
        {customTextSlot}
      </TextAndIconContainer>
      <ButtonsContainer>
        {showCtaButton && ctaType !== 'outlined' && (
          <CtaButton
            variant={ctaType ?? 'contained'}
            color={ctaColor ?? 'secondary'}
            onClick={onCtaButtonClick}
          >
            {ctaText}
          </CtaButton>
        )}
        {showCtaButton && onCtaButtonClick && ctaType === 'outlined' && (
          <OutlinedStyledButton
            variant={ctaType ?? 'contained'}
            color={ctaColor ?? 'secondary'}
            onClick={onCtaButtonClick}
          >
            {ctaText}
          </OutlinedStyledButton>
        )}
        {onDismiss && (
          <IconButton
            size="small"
            aria-label="dismiss"
            onClick={onDismiss}
          >
            <CrossIcon fontSize="inherit" />
          </IconButton>
        )}
      </ButtonsContainer>
    </SectionBoxWrapper>
  )
}
