import { useMemo } from 'react'

import { UserLocale_Enum, useCustomerPropositionsQuery } from '../../graphql/codegen'
import { useCurrentUser } from '../../contexts/CurrentUserContext'
import { useI18nContext } from '../../contexts/i18nContext/I18nContext'

export enum CurrentPropositions {
  'ELECTRICITY' = 'electricity',
  'GAS' = 'gas',
  'WATER' = 'water',
  'COMPRESSED-AIR' = 'compressed-air',
  'PREDICTIVE-MAINTENANCE' = 'predictive-maintenance',
  'OVERALL-EQUIPMENT-EFFECTIVENESS' = 'oee',
  'TEMPERATURE' = 'temperature',
}

/**
 * @param sellingPoints need to be kept in sync across
 * locale and the sf.eu/products-services main website
 * @param externalLink needs to be kept in sync, per locale
 * with locale-specific sf domains
 */
export type PropositionInfo = {
  key: CurrentPropositions
  mainPath: string
  title: string
  name: string
  image: string
  sellingPoints: string[]
  externalLink: string
}

const getExternalLink = (proposition: CurrentPropositions, locale: UserLocale_Enum) => {
  const links: {
    [key in CurrentPropositions]: {
      [key in UserLocale_Enum]: string
    }
  } = {
    electricity: {
      [UserLocale_Enum.NlNl]: 'https://www.sensorfact.nl/product/elektriciteitsmanagement/#vraag-demo',
      [UserLocale_Enum.EnGb]: 'https://www.sensorfact.eu/product/electricity-management/#request-demo',
      [UserLocale_Enum.DeDe]: 'https://www.sensorfact.de/product/strommanagement/#demo-anfordern',
      [UserLocale_Enum.ItIt]: 'https://www.sensorfact.it/product/gestione-dellenergia/#richiedi-una-demo',
      [UserLocale_Enum.FrFr]: 'https://www.sensorfact.fr/product/gestion-de-lelectricite/"#demander-une-demo',
      [UserLocale_Enum.EsEs]: 'https://www.sensorfact.es/product/gestion-de-la-energia-electrica/#solicitar-demo',
    },
    gas: {
      [UserLocale_Enum.NlNl]: 'https://www.sensorfact.nl/product/gasmanagement/#vraag-demo',
      [UserLocale_Enum.EnGb]: 'https://www.sensorfact.eu/product/gas-management/#request-demo',
      [UserLocale_Enum.DeDe]: 'https://www.sensorfact.de/product/gasmanagement/#demo-anfordern',
      [UserLocale_Enum.ItIt]: 'https://www.sensorfact.it/product/gestione-del-consumo-di-gas/#richiedi-una-demo',
      [UserLocale_Enum.FrFr]: 'https://www.sensorfact.fr/product/gestion-du-gaz/"#demander-une-demo',
      [UserLocale_Enum.EsEs]: 'https://www.sensorfact.es/product/gestion-del-consumo-de-gas/#solicitar-demo',
    },
    water: {
      [UserLocale_Enum.NlNl]: 'https://www.sensorfact.nl/product/watermanagement/#vraag-demo',
      [UserLocale_Enum.EnGb]: 'https://www.sensorfact.eu/product/water-management/#request-demo',
      [UserLocale_Enum.DeDe]: 'https://www.sensorfact.de/product/wassermanagement/#demo-anfordern',
      [UserLocale_Enum.ItIt]: 'https://www.sensorfact.it/product/gestione-dellacqua/#richiedi-una-demo',
      [UserLocale_Enum.FrFr]: 'https://www.sensorfact.fr/product/gestion-de-leau/"#demander-une-demo',
      [UserLocale_Enum.EsEs]: 'https://www.sensorfact.es/product/gestion-del-agua/#solicitar-demo',
    },
    'compressed-air': {
      [UserLocale_Enum.NlNl]: 'https://www.sensorfact.nl/product/persluchtmanagement/#vraag-demo',
      [UserLocale_Enum.EnGb]: 'https://www.sensorfact.eu/product/compressed-air-management-beta/#request-demo',
      [UserLocale_Enum.DeDe]: 'https://www.sensorfact.de/product/druckluftmanagement/#demo-anfordern',
      [UserLocale_Enum.ItIt]: 'https://www.sensorfact.it/product/gestione-dellaria-compressa/#richiedi-una-demo',
      [UserLocale_Enum.FrFr]: 'https://www.sensorfact.fr/product/gestion-de-lair-comprime/"#demander-une-demo',
      [UserLocale_Enum.EsEs]: 'https://www.sensorfact.es/product/gestion-del-aire-comprimido/#solicitar-demo',
    },
    'predictive-maintenance': {
      [UserLocale_Enum.NlNl]: 'https://www.sensorfact.nl/product/voorspellend-onderhoud/#vraag-demo',
      [UserLocale_Enum.EnGb]: 'https://www.sensorfact.eu/product/predictive-maintenance/#request-demo',
      [UserLocale_Enum.DeDe]: 'https://www.sensorfact.de/product/vorausschauende-wartung/#demo-anfordern',
      [UserLocale_Enum.ItIt]: 'https://www.sensorfact.it/product/manutenzione-preventiva/#richiedi-una-demo',
      [UserLocale_Enum.FrFr]: 'https://www.sensorfact.fr/product/maintenance-predictive/"#demander-une-demo',
      [UserLocale_Enum.EsEs]: 'https://www.sensorfact.es/product/mantenimiento-predictivo/#solicitar-demo',
    },
    oee: {
      [UserLocale_Enum.NlNl]: 'https://www.sensorfact.eu/product/oee',
      [UserLocale_Enum.EnGb]: 'https://www.sensorfact.eu/product/oee',
      [UserLocale_Enum.DeDe]: 'https://www.sensorfact.eu/product/oee',
      [UserLocale_Enum.ItIt]: 'https://www.sensorfact.eu/product/oee',
      [UserLocale_Enum.FrFr]: 'https://www.sensorfact.eu/product/oee',
      [UserLocale_Enum.EsEs]: 'https://www.sensorfact.eu/product/oee',
    },
    // @todo update with real temperature links when it is ready
    temperature: {
      [UserLocale_Enum.NlNl]: 'https://www.sensorfact.eu/product/temperature',
      [UserLocale_Enum.EnGb]: 'https://www.sensorfact.eu/product/temperature',
      [UserLocale_Enum.DeDe]: 'https://www.sensorfact.eu/product/temperature',
      [UserLocale_Enum.ItIt]: 'https://www.sensorfact.eu/product/temperature',
      [UserLocale_Enum.FrFr]: 'https://www.sensorfact.eu/product/temperature',
      [UserLocale_Enum.EsEs]: 'https://www.sensorfact.eu/product/temperature',
    },
  }
  return links[proposition][locale]
}

const usePropositions = () => {
  const { i18n, userLocale } = useI18nContext()
  const { customerId } = useCurrentUser()
  const customerPropositions: string[] = []

  const { data, loading } = useCustomerPropositionsQuery({
    variables: { customerId },
  })
  const computedPropositions = data?.customerById?.propositions

  if (computedPropositions?.length) {
    computedPropositions.forEach(p => customerPropositions.push(p.name as CurrentPropositions))
  }

  const propositionsLandingInfo: {
    [key in CurrentPropositions]: PropositionInfo
  } = useMemo(
    () => ({
      [CurrentPropositions.ELECTRICITY]: {
        title: i18n.text('multi.prop.title.electricity'), // 'Electricity Management',
        key: CurrentPropositions.ELECTRICITY,
        name: i18n.text('router.multi.electricity'),
        mainPath: `/${CurrentPropositions.ELECTRICITY}/analysis/power`,
        image:
          'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687425106/production/proposition_hero_images/ye7xlipqwn0h6bxvkodu.png',
        sellingPoints: [
          i18n.text('multi.prop.electricity.sellingPoint1'),
          i18n.text('multi.prop.electricity.sellingPoint2'),
          i18n.text('multi.prop.electricity.sellingPoint3'),
        ],
        externalLink: getExternalLink(CurrentPropositions.ELECTRICITY, userLocale),
      },
      [CurrentPropositions.GAS]: {
        title: i18n.text('multi.prop.title.gas'), // 'Gas Management',
        key: CurrentPropositions.GAS,
        name: i18n.text('router.multi.gas'),
        mainPath: `/${CurrentPropositions.GAS}/analysis/volume`,
        image:
          'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687425107/production/proposition_hero_images/vubegbjsdufy00zxhyxm.png',
        sellingPoints: [
          i18n.text('multi.prop.gas.sellingPoint1'),
          i18n.text('multi.prop.gas.sellingPoint2'),
          i18n.text('multi.prop.gas.sellingPoint3'),
        ],
        externalLink: getExternalLink(CurrentPropositions.GAS, userLocale),
      },
      [CurrentPropositions.WATER]: {
        title: i18n.text('multi.prop.title.water'), // 'Water Management',
        key: CurrentPropositions.WATER,
        name: i18n.text('router.multi.water'),
        mainPath: `/${CurrentPropositions.WATER}/analysis/volume`,
        image:
          'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687425107/production/proposition_hero_images/vubegbjsdufy00zxhyxm.png',
        sellingPoints: [
          i18n.text('multi.prop.water.sellingPoint1'),
          i18n.text('multi.prop.water.sellingPoint2'),
          i18n.text('multi.prop.water.sellingPoint3'),
        ],
        externalLink: getExternalLink(CurrentPropositions.WATER, userLocale),
      },
      [CurrentPropositions['COMPRESSED-AIR']]: {
        title: i18n.text('multi.prop.title.air'), // 'Compressed Air Management',
        key: CurrentPropositions['COMPRESSED-AIR'],
        name: i18n.text('router.multi.air'),
        mainPath: `/${CurrentPropositions['COMPRESSED-AIR']}/flow`,
        image:
          'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687425106/production/proposition_hero_images/isjcptdtnilv7wrggtgx.png',
        sellingPoints: [
          i18n.text('multi.prop.compressed-air.sellingPoint1'),
          i18n.text('multi.prop.compressed-air.sellingPoint2'),
          i18n.text('multi.prop.compressed-air.sellingPoint3'),
        ],
        externalLink: getExternalLink(CurrentPropositions['COMPRESSED-AIR'], userLocale),
      },
      [CurrentPropositions['PREDICTIVE-MAINTENANCE']]: {
        title: i18n.text('multi.prop.title.maintenance'), // 'Predictive Maintenance',
        key: CurrentPropositions['PREDICTIVE-MAINTENANCE'],
        name: i18n.text('router.multi.maintenance'),
        mainPath: `/${CurrentPropositions['PREDICTIVE-MAINTENANCE']}/current-vibration-status`,
        image:
          'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687425107/production/proposition_hero_images/efd1zxwazr0obivefzdf.png',
        sellingPoints: [
          i18n.text('multi.prop.predictive-maintenance.sellingPoint1'),
          i18n.text('multi.prop.predictive-maintenance.sellingPoint2'),
          i18n.text('multi.prop.predictive-maintenance.sellingPoint3'),
        ],
        externalLink: getExternalLink(CurrentPropositions['PREDICTIVE-MAINTENANCE'], userLocale),
      },
      [CurrentPropositions['OVERALL-EQUIPMENT-EFFECTIVENESS']]: {
        title: i18n.text('multi.prop.title.oee'), // 'OEE',
        key: CurrentPropositions['OVERALL-EQUIPMENT-EFFECTIVENESS'],
        name: i18n.text('router.multi.oee'),
        mainPath: `/${CurrentPropositions['OVERALL-EQUIPMENT-EFFECTIVENESS']}/speed-analysis`,
        image:
          'https://res.cloudinary.com/dj4ypr91s/image/upload/v1707901129/production/proposition_hero_images/gjhbozxmtxdt4xmuqrrg.png',
        sellingPoints: [
          i18n.text('multi.prop.oee.sellingPoint1'),
          i18n.text('multi.prop.oee.sellingPoint2'),
          i18n.text('multi.prop.oee.sellingPoint3'),
        ],
        externalLink: getExternalLink(CurrentPropositions['OVERALL-EQUIPMENT-EFFECTIVENESS'], userLocale),
      },
      [CurrentPropositions['TEMPERATURE']]: {
        title: i18n.text('multi.prop.title.temperature'), // 'Temperature Management',
        key: CurrentPropositions.TEMPERATURE,
        name: i18n.text('router.multi.temperature'),
        mainPath: `/${CurrentPropositions['TEMPERATURE']}/overview`,
        image:
          'https://res.cloudinary.com/dj4ypr91s/image/upload/v1707475679/production/proposition_hero_images/xtcrexnsw3e2bwxpjetv.png',
        sellingPoints: [
          i18n.text('multi.prop.temperature.sellingPoint1'),
          i18n.text('multi.prop.temperature.sellingPoint2'),
          i18n.text('multi.prop.temperature.sellingPoint3'),
        ], // @todo update with temperature selling points
        externalLink: getExternalLink(CurrentPropositions['TEMPERATURE'], userLocale),
      },
    }),
    [i18n, userLocale]
  )

  const hasProposition = (prop: CurrentPropositions) => customerPropositions.includes(prop)

  /** Returns the first proposition the customer has access to  */
  const getFirstProposition: () => CurrentPropositions | null = () =>
    (customerPropositions[0] as CurrentPropositions) ?? null

  return {
    propositions: propositionsLandingInfo,
    hasProposition,
    getFirstProposition,
    loading,
  }
}

export default usePropositions
