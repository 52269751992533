import { OutlinedInput, outlinedInputClasses, styled } from '@mui/material'

export const StyledSearchTextField = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '0.5rem',
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  padding: theme.spacing(1.25, 1.75),
  gap: '0.5rem',
  color: theme.palette.SFIGreyLight[500],
  width: '100%',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  '&:focus-visible': {
    outline: 'none',
  },
  [`.${outlinedInputClasses.notchedOutline}`]: {
    border: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: 'max-content',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
  '& input': {
    padding: 0,
    '&::placeholder': {
      color: theme.palette.SFIGreyLight[500],
      fontSide: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
      opacity: 1,
    },
  },
  '& svg': {
    width: '1.25rem',
    height: '1.25rem',
  },
}))
