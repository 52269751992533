import { Box } from '@mui/material'
import { FC } from 'react'

import { AnalysisTypeSelectProps } from '../types'
import { MenuItemContentCheckmark } from '../../../../../Shared/components/MUIComponents/update/Selectv1/MenuItemContentCheckmark'
import { StyledInputFieldHeader, StyledTooltipWithIcon } from '../Common'
import { StyledMenuItem } from '../../../../../Shared/components/MUIComponents/update/Selectv1/StyledMenuItem'
import { StyledSelect } from '../../../../../Shared/components/MUIComponents/update/Selectv1/StyledSelect'
import { useGetAvailableAnalysisTypesQuery } from '../../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const AnalysisTypeSelect: FC<AnalysisTypeSelectProps> = ({ analysisType, handleAnalysisTypeChange }) => {
  const { i18n } = useI18nContext()
  const { data: availableAnalysisTypesData } = useGetAvailableAnalysisTypesQuery()
  const availableAnalysisTypes = availableAnalysisTypesData?.trackingReportAnalysisTypes
    .map(analysisType => analysisType.value)
    .filter(analysisType => analysisType !== 'ruleBasedThresholds')

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '30% 70%',
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.125rem', paddingRight: '1rem' }}
        >
          <StyledInputFieldHeader sx={{ alignContent: 'center' }}>
            {i18n.text(`reports.tracking.configuration.module.analysisType`)}
          </StyledInputFieldHeader>
          <StyledTooltipWithIcon
            title={i18n.text(`reports.tracking.configuration.tooltip.analysisModule.${analysisType}`)}
          ></StyledTooltipWithIcon>
        </Box>
        <StyledSelect
          value={analysisType}
          size="small"
          onChange={handleAnalysisTypeChange}
          renderValue={selected => i18n.text(`reports.tracking.configuration.analysisType.${selected}`)}
        >
          {availableAnalysisTypes &&
            availableAnalysisTypes.map((analysisTypeOption, index) => (
              <StyledMenuItem
                key={index}
                value={analysisTypeOption}
              >
                {analysisTypeOption === analysisType ? (
                  <MenuItemContentCheckmark
                    i18n={`reports.tracking.configuration.analysisType.${analysisTypeOption}`}
                  />
                ) : (
                  i18n.text(`reports.tracking.configuration.analysisType.${analysisTypeOption}`)
                )}
              </StyledMenuItem>
            ))}
        </StyledSelect>
      </Box>
    </>
  )
}
