import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { FC, useCallback, useMemo } from 'react'
import { useTheme } from '@mui/material'

import { SummedEnergyBalanceSeries, ValuesModifier } from '../../types'
import { getCurrencyFormatter } from '../../../Shared/utils/formatCurrency'
import { useChartToolsContext } from '../../context/ChartToolsContext'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useEnergyBalanceBarChartOptions } from './EnergyBalanceBarChartOptions'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type EnergyBalanceBarChartProps = {
  chartWidth: string
  interval?: Interval
  data: SummedEnergyBalanceSeries
  valuesModifier: ValuesModifier
  showingCosts: boolean
  loading: boolean
  showCompare: boolean
  onDataPointMouseEnter?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
  onDataPointMouseLeave?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
}

const EnergyBalanceBarChart: FC<EnergyBalanceBarChartProps> = ({
  data,
  valuesModifier,
  chartWidth,
  showingCosts,
  loading,
  showCompare,
  onDataPointMouseEnter,
  onDataPointMouseLeave,
}) => {
  const { i18n, userLocale } = useI18nContext()
  const { decimal } = useChartToolsContext()
  const { customerCurrency } = useCurrentUser()
  const theme = useTheme()
  const currencyFormatter = useMemo(
    () => getCurrencyFormatter(userLocale, customerCurrency.isocode),
    [userLocale, customerCurrency.isocode]
  )

  const sortedData = useMemo(() => data.sort((a, b) => b.total - a.total), [data])
  const categories = useMemo(
    () => sortedData.map(s => (s.isPreviousPeriod ? null : s.name)).filter(s => !!s),
    [sortedData]
  )

  const dataSeries: ApexAxisChartSeries = useMemo(() => {
    let result: ApexAxisChartSeries
    if (showingCosts) {
      result = [
        {
          name: 'Period 1', // TODO i18n
          group: 'Current',
          data: [],
          color: theme.palette.SFIBrand ? theme.palette.SFIBrand[600] : '',
        },
        {
          name: 'Period 2 ', //TODO i18n
          group: 'Previous consumption',
          data: [],
          color: theme.palette.SFIGreen ? theme.palette.SFIGreen[500] : '',
        },
      ]
      sortedData.forEach((series, i) => {
        const currentSeries = result[0].data
        const previousSeries = result[1].data
        //@ts-expect-error  type inference is messed up here
        series.isPreviousPeriod ? previousSeries.push(series.cost) : currentSeries.push(series.cost)
      })
    } else {
      result = [
        {
          name: 'Period 1' + ` (${valuesModifier.unit})`, // TODO i18n
          group: 'Current',
          data: [],
          color: theme.palette.SFIBrand ? theme.palette.SFIBrand[600] : '',
        },
        {
          name: 'Period 2 ' + ` (${valuesModifier.unit})`, //TODO i18n
          group: 'Previous consumption',
          data: [],
          color: theme.palette.SFIGreen ? theme.palette.SFIGreen[500] : '',
        },
      ]
      sortedData.forEach((series, i) => {
        const currentSeries = result[0].data
        const previousSeries = result[1].data
        //@ts-expect-error  type inference is messed up here
        series.isPreviousPeriod ? previousSeries.push(series.total) : currentSeries.push(series.total)
      })
    }
    return result
  }, [sortedData, i18n, valuesModifier.unit, showingCosts, showCompare])

  const tooltipFormatter: (value: number | string) => string = useCallback(
    value => {
      return showingCosts
        ? currencyFormatter(+value)
        : `${i18n.number(value, { maximumFractionDigits: decimal })} ${valuesModifier.unit}`
    },
    [valuesModifier.unit, showingCosts, i18n]
  )

  const enhancedOptions: ApexOptions = useEnergyBalanceBarChartOptions({
    tooltipFormatter,
    categories,
    onDataPointMouseEnter,
    onDataPointMouseLeave,
    showingCosts,
    valuesModifier,
    decimal,
    loading,
  })

  return (
    <ReactApexChart
      options={enhancedOptions}
      series={dataSeries}
      type="bar"
      height="590"
      width={chartWidth}
    />
  )
}

export { EnergyBalanceBarChart }
