import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FC, useMemo, useState } from 'react'
import { TableSortLabel, styled } from '@mui/material'

import { SummedEnergyBalanceSeries, ValuesModifier } from '../types'
import { getCurrencyFormatter } from '../../Shared/utils/formatCurrency'
import { useChartToolsContext } from '../context/ChartToolsContext'
import { useCurrentUser } from '../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  boxShadow: 'none',
  '& .MuiTableCell-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiTableCell-head': {
    backgroundColor: theme.palette.background.paper,
  },
})) as typeof Paper

type AssetEnergyBalance = {
  assetId: string
  name: string
  energyConsumption: number
  energyCosts: number
  standbyTotal?: number
  standbyPercentage?: number
  standbyCost?: number
}

type EnergyBalanceTableProps = {
  data: SummedEnergyBalanceSeries
  highlightedAsset?: string
  valuesModifier: ValuesModifier
  showWaste: boolean
}

const EnergyBalanceTable: FC<EnergyBalanceTableProps> = ({ data, highlightedAsset, valuesModifier, showWaste }) => {
  const { i18n, userLocale } = useI18nContext()
  const { decimal } = useChartToolsContext()
  const { customerCurrency } = useCurrentUser()
  const numberFormatOptions = { maximumFractionDigits: decimal, minimumFractionDigits: decimal }
  const [sortBy, setSortBy] = useState('analysis.energy-balance.total-consumption')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
  const headers: string[] = showWaste
    ? [
        'Asset',
        'analysis.energy-balance.total-consumption',
        'graph.energy-balance.legend.waste',
        'analysis.energy-balance.percent-waste',
        'analysis.energy-balance.waste-costs',
        'analysis.energy-balance.energy-costs',
      ]
    : ['Asset', 'analysis.energy-balance.total-consumption', 'analysis.energy-balance.energy-costs']

  const currencyFormatter = useMemo(() => getCurrencyFormatter(userLocale, customerCurrency.isocode), [userLocale])
  const totalEnergyConsumption = useMemo(() => data.reduce((acc, cur) => (acc += cur.total), 0), [data])
  const totalStandbyConsumption = useMemo(() => data.reduce((acc, cur) => (acc += cur.standbyTotal || 0), 0), [data])
  const totalStandbyCosts = useMemo(() => data.reduce((acc, cur) => (acc += cur.standbyCost || 0), 0), [data])
  const totalEnergyCosts = useMemo(() => data.reduce((acc, cur) => (acc += cur.cost || 0), 0), [data])

  const rows: AssetEnergyBalance[] = useMemo(() => {
    const partialRows = data.map(series => {
      return {
        assetId: series.assetId,
        name: series.name,
        energyConsumption: series.total || 0,
        energyCosts: series.cost || 0,
        standbyTotal: series.standbyTotal || 0,
        standbyPercentage: series.total ? (series.standbyTotal * 100) / series.total : 0,
        standbyCost: series.standbyCost || 0,
      }
    })
    partialRows.sort((a, b) => {
      if (sortBy === 'Asset') {
        return sortDirection === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
      } else if (sortBy === 'analysis.energy-balance.total-consumption') {
        return sortDirection === 'asc'
          ? a.energyConsumption - b.energyConsumption
          : b.energyConsumption - a.energyConsumption
      } else if (sortBy === 'graph.energy-balance.legend.waste') {
        return sortDirection === 'asc' ? a.standbyTotal - b.standbyTotal : b.standbyTotal - a.standbyTotal
      } else if (sortBy === 'analysis.energy-balance.percent-waste') {
        return sortDirection === 'asc'
          ? a.standbyPercentage - b.standbyPercentage
          : b.standbyPercentage - a.standbyPercentage
      } else if (sortBy === 'analysis.energy-balance.waste-costs') {
        return sortDirection === 'asc' ? a.standbyCost - b.standbyCost : b.standbyCost - a.standbyCost
      } else if (sortBy === 'analysis.energy-balance.energy-costs') {
        return sortDirection === 'asc' ? a.energyCosts - b.energyCosts : b.energyCosts - a.energyCosts
      } else {
        return 0
      }
    })

    partialRows.unshift({
      assetId: 'table.total',
      name: i18n.text('table.total')[0].toUpperCase() + i18n.text('table.total').substring(1),
      energyConsumption: totalEnergyConsumption,
      energyCosts: totalEnergyCosts,
      standbyTotal: totalStandbyConsumption,
      standbyPercentage: totalEnergyConsumption ? (totalStandbyConsumption * 100) / totalEnergyConsumption : 0,
      standbyCost: totalStandbyCosts,
    })
    return partialRows
  }, [
    data,
    totalEnergyConsumption,
    totalEnergyCosts,
    i18n,
    totalStandbyConsumption,
    totalStandbyCosts,
    sortBy,
    sortDirection,
  ])

  const onSortRequest = (header: string) => {
    if (sortBy === header) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(header)
      setSortDirection('desc')
    }
  }

  return (
    <TableContainer
      component={StyledPaper}
      sx={{ borderRadius: 'none', boxShadow: 'none' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableCell key={header}>
                <TableSortLabel
                  active={sortBy === header}
                  direction={sortDirection}
                  onClick={() => onSortRequest(header)}
                >
                  {i18n.text(header)}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow
              key={row.assetId}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor:
                  row.assetId === 'table.total'
                    ? 'rgba(0, 0, 0, 0.05)'
                    : highlightedAsset === row.assetId
                    ? 'rgba(0, 0, 0, 0.15)'
                    : '',
              }}
            >
              <TableCell
                component="th"
                scope="row"
              >
                {row.name}
              </TableCell>
              <TableCell align="left">{`${i18n.number(row.energyConsumption, numberFormatOptions)} ${
                valuesModifier.unit
              }`}</TableCell>
              {showWaste && (
                <>
                  <TableCell align="left">{`${i18n.number(row.standbyTotal, numberFormatOptions)} ${
                    valuesModifier.unit
                  }`}</TableCell>
                  <TableCell align="left">{`${
                    row.standbyPercentage ? Math.round(row.standbyPercentage) : 0
                  } %`}</TableCell>
                  <TableCell align="left">{currencyFormatter(Math.round(row.standbyCost ?? 0), 0)}</TableCell>
                </>
              )}
              <TableCell align="left">{currencyFormatter(Math.round(row.energyCosts), 0)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export { EnergyBalanceTable }
