import { Box, Button, Divider, styled } from '@mui/material'

export const BackGroundBox = styled(Box)(({ theme }) => ({
  borderRadius: '1.5rem',
  backgroundColor: theme.palette.SFIGreyLight[100],
  display: 'flex',
  padding: '3rem ',
  alignItems: 'flex-start',
  flexShrink: 0,
  gap: '2rem',
  flexDirection: 'column',
}))

export const GraphMainInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '2.875rem',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
}))

export const GraphHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const BackGroundGraphBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '2.75rem 0 0 0',
}))

export const GraphBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  background: theme.palette.SFIBase.white,
  borderRadius: '0.625rem',
  padding: '0.625rem',
  boxShadow: '0px 0.603px 1.81px 0px rgba(16, 24, 40, 0.10)',
}))

export const GraphNoteBox = styled(Box)(({ theme }) => ({
  background: theme.palette.SFIGreyLight[100],
  borderRadius: '0.375rem',
  padding: '0.375rem',
  gap: '0.625rem',
  display: 'inline-flex',
}))

export const GraphMenuButton = styled(Button)(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: 600,
  textTransform: 'none',
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '1px',
  alignSelf: 'stretch',
  fill: theme.palette.SFIGreyLight[200],
}))

export const SquareIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '3rem',
  height: '3rem',
  padding: '1.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0.675rem',
  border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  boxShadow: `0px 1px 2px 0px ${theme.palette.SFIGreyLight[900]}0d`,
}))

export const RoundBlueIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.SFIBrand[100],
  width: '3.5rem',
  height: '3.5rem',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const SquareIconCenterTextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1.25rem',
}))
