import IconButton from '@mui/material/IconButton'
import React, { FC, FormEvent, useEffect, useState } from 'react'
import { Box, Button, FormLabel, useTheme } from '@mui/material'

import { CustomSwitch } from '../../../../../Shared/components/MUIComponents/update/NewDesignStyledSwitch'
import { ID } from '../../../../../Shared/types/types'
import {
  OeeCustomerProductionStopReason,
  useDeleteOeeCustomerProductionStopReasonMutation,
  useUpdateCustomerReasonBatchMutation,
} from '../../../../../Shared/graphql/codegen'
import { OutlinedStyledButton } from '../../../../../Shared/components/MUIComponents/update/OutlinedStyledButton'
import { StyledDialogModal } from '../../../../../Shared/components/MUIComponents/StyledDialogModal'
import { StyledTextField } from '../../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { TrashIcon } from '../../../../../Shared/components/icons'
import { useCurrentUser } from '../../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../../Shared/contexts/ToastContext'

type ManageStopReasonsProps = {
  stopReasons: OeeCustomerProductionStopReason[]
  onUpdate: () => void
}

type StopReasonField = {
  id: string
  text: string
  isEnabled: boolean
}

const ManageStopReasons: FC<ManageStopReasonsProps> = ({ stopReasons, onUpdate }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [updatedStopReasons, setUpdatedStopReasons] = useState<StopReasonField[]>([])

  const { selectedCustomer } = useCurrentUser()
  const { i18n } = useI18nContext()
  const { showToast } = useToastContext()
  const theme = useTheme()

  const labels = [
    { id: 'stop-reason', text: 'settings.oee-settings.stop-reasons.manage.stop-reason' },
    { id: 'activate', text: 'settings.oee-settings.stop-reasons.manage.activate' },
    { id: 'delete', text: 'settings.oee-settings.stop-reasons.manage.delete' },
  ]

  const sortedStopReasons = [...stopReasons].sort((a, b) => (a.isEnabled === b.isEnabled ? 0 : a.isEnabled ? -1 : 1))

  const onError = (error: string) => showToast(error, 'error', 'Error')

  const onSuccess = () => {
    showToast(i18n.text('general.success.changes-saved'), 'success', 'Success!')
    onUpdate()
  }

  const [updateStopReasons, { loading: isUpdating }] = useUpdateCustomerReasonBatchMutation({
    awaitRefetchQueries: true,
    onCompleted: async () => onSuccess(),
    onError: async () => onError(i18n.text('general.error.something-went-wrong')),
  })

  const [deleteStopReason, { loading: isDeleting }] = useDeleteOeeCustomerProductionStopReasonMutation({
    awaitRefetchQueries: true,
    onCompleted: async () => onSuccess(),
    onError: async () => onError(i18n.text('general.error.something-went-wrong')),
  })

  useEffect(() => {
    const updatedReasons = stopReasons.map(reason => ({
      ...reason,
      text: reason.text,
      isEnabled: reason.isEnabled,
    }))
    setUpdatedStopReasons(updatedReasons)
  }, [stopReasons])

  const handleModalOpen = () => {
    setIsOpen(true)
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }

  const handleStopReasonTextChange = (id: string, newText: string) => {
    setUpdatedStopReasons(prevReasons =>
      prevReasons.map(reason => (reason.id === id ? { ...reason, text: newText } : reason))
    )
  }

  const handleStopReasonEnabledStatusChange = (id: string, isEnabled: boolean) => {
    setUpdatedStopReasons(prevReasons =>
      prevReasons.map(reason => (reason.id === id ? { ...reason, isEnabled } : reason))
    )
  }

  const handleDeleteStopReason = async (id: string) => {
    await deleteStopReason({
      variables: {
        customerId: selectedCustomer.id as ID,
        customerProductionStopReasonId: id as ID,
      },
    })

    onUpdate()
  }

  const handleSubmitUpdatedStopReasons = async (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    await updateStopReasons({
      variables: {
        customerId: selectedCustomer.id as ID,
        objects: updatedStopReasons.map(reason => ({
          id: reason.id as ID,
          text: reason.text,
          isEnabled: reason.isEnabled,
        })),
      },
    })

    onUpdate()
    handleModalClose()
  }

  return (
    <>
      <OutlinedStyledButton
        sx={{
          display: 'flex',
          textTransform: 'none',
        }}
        onClick={handleModalOpen}
      >
        {i18n.text('settings.oee-settings.stop-reasons.manage')}
      </OutlinedStyledButton>
      <StyledDialogModal
        title={i18n.text('settings.oee-settings.stop-reasons.manage')}
        subtitle={i18n.text('settings.oee-settings.stop-reasons.manage.description')}
        isOpen={isOpen}
        isLoading={false}
        onClose={handleModalClose}
        height="responsive"
      >
        <Box
          component="form"
          onSubmit={handleSubmitUpdatedStopReasons}
        >
          <Box sx={{ display: 'grid', gridTemplateColumns: '4fr 1fr 1fr' }}>
            {labels.map(label => (
              <FormLabel
                key={label.id}
                sx={{ fontSize: '0.875rem', fontWeight: 500, color: theme.palette.text.primary }}
              >
                {i18n.text(label.text)}
              </FormLabel>
            ))}
          </Box>
          <Box sx={{ display: 'grid', rowGap: '0.75rem', padding: '1rem 0 2.5rem', alignItems: 'center' }}>
            {sortedStopReasons.map((stopReason, index) => (
              <Box
                sx={{ display: 'grid', gridTemplateColumns: '4fr 1fr 1fr' }}
                key={stopReason.id}
              >
                <StyledTextField
                  id={stopReason.id}
                  name={stopReason.id}
                  type="text"
                  size="small"
                  disabled={!stopReason.isEnabled || isUpdating || isDeleting}
                  variant="outlined"
                  defaultValue={stopReason.text}
                  onChange={event => handleStopReasonTextChange(stopReason.id, event.target.value)}
                />
                <Box sx={{ display: 'grid', alignItems: 'center' }}>
                  <CustomSwitch
                    size="small"
                    color="secondary"
                    defaultChecked={stopReason.isEnabled}
                    onChange={event => handleStopReasonEnabledStatusChange(stopReason.id, event.target.checked)}
                  />
                </Box>
                <Box>
                  <IconButton
                    disabled={isDeleting}
                    onClick={() => handleDeleteStopReason(stopReason.id)}
                  >
                    <TrashIcon sx={{ color: theme.palette.error.dark, width: '1.5rem', height: '1.5rem' }} />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: theme.spacing(1) }}>
            <OutlinedStyledButton
              type="reset"
              onClick={handleModalClose}
            >
              {i18n.text('generic.cancel')}
            </OutlinedStyledButton>
            <Button
              sx={{ textTransform: 'none', fontWeight: 600 }}
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isUpdating || isDeleting}
            >
              {i18n.text('generic.save')}
            </Button>
          </Box>
        </Box>
      </StyledDialogModal>
    </>
  )
}

export default ManageStopReasons
