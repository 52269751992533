import React from 'react'
import { Box, List, ListItem, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { PrecisionManufacturingOutlined, Sensors } from '@mui/icons-material'

import { VibrationThresholdAlertEvent } from '../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface VibrationThresholdAlertsListProps {
  thresholdEventsGroupedByDay: Record<string, VibrationThresholdAlertEvent[]>
  maxDays?: number
}

export function VibrationThresholdAlertsList({
  thresholdEventsGroupedByDay,
  maxDays,
}: VibrationThresholdAlertsListProps) {
  const theme = useTheme()
  const days = Object.keys(thresholdEventsGroupedByDay).slice(0, maxDays)

  return (
    <>
      {days.map((day, index) => (
        <Box key={day}>
          <Typography
            variant="subtitle2"
            color={theme.palette.SFIGreyLight[50]}
            sx={{
              backgroundColor: theme.palette.SFIGreyLight[950],
              width: 'fit-content',
              fontWeight: 200,
              padding: '0.05rem 0.35rem',
            }}
          >
            {day.substring(0, 1).toUpperCase() + day.substring(1)}
          </Typography>
          <Box>
            <List key={index}>
              {thresholdEventsGroupedByDay[day].map((event, index) => (
                <VibrationThresholdAlertListItem
                  key={index}
                  event={event}
                />
              ))}
            </List>
          </Box>
        </Box>
      ))}
    </>
  )
}

interface VibrationThresholdAlertListItemProps {
  event: VibrationThresholdAlertEvent
}

function VibrationThresholdAlertListItem({ event }: VibrationThresholdAlertListItemProps) {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const dayConversion = DateTime.fromISO(event.createdAt).toFormat('HH:mm')
  const violationLevel =
    event.violationLevel === 0
      ? i18n.text('notifications.messages.asset.text.vibrating.warning')
      : i18n.text('notifications.messages.asset.text.vibrating.alarm')

  return (
    <ListItem
      data-testid="pdmAlerts"
      key={event.id}
      sx={{ display: 'flex', paddingLeft: 0.35, paddingTop: 0.5, paddingBottom: 0 }}
    >
      <Typography
        variant="subtitle2"
        color={theme.palette.SFIGreyLight[800]}
      >
        {dayConversion}
      </Typography>

      {event.config.conditions.map((condition, index) => {
        const assetName = condition.sensorLocation.asset.name
        const sensorLocation = condition.sensorLocation.name
        return (
          <React.Fragment key={index}>
            <Typography
              variant="subtitle2"
              ml={1}
              sx={{
                display: 'flex',
                justifyItems: 'center',
              }}
            >
              <PrecisionManufacturingOutlined fontSize="small" />
              {assetName}
            </Typography>
            <Typography
              variant="subtitle2"
              ml={1}
              sx={{
                display: 'flex',
                justifyItems: 'center',
              }}
            >
              <Sensors fontSize="small" />
              {sensorLocation}
            </Typography>
          </React.Fragment>
        )
      })}
      <Typography
        component={'div'}
        fontSize="small"
        ml={1}
        display={'flex'}
      >
        {i18n.text('notifications.messages.asset.text.vibrating')}{' '}
        <Typography
          variant="subtitle2"
          ml={1}
          mr={1}
          fontStyle={'italic'}
        >
          {' '}
          {violationLevel}
        </Typography>{' '}
        {i18n.text('notifications.messages.asset.text.vibrating.level')}
      </Typography>
    </ListItem>
  )
}
