import { Box, Typography, styled } from '@mui/material'
import { FC } from 'react'

import { AssetTreeMachine } from '../../types'
import { InfoIcon } from '../../../Shared/components/icons'
import { StyledTooltip } from '../../../Shared/components/MUIComponents/Tooltip'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const MiniHeader = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 500,
  color: theme.palette.SFIGreyLight[700],
  marginBottom: theme.spacing(0.5),
}))

export const ElectricityAssetTreeInfoTooltip: FC<{
  machine: AssetTreeMachine
}> = ({ machine }) => {
  const { i18n } = useI18nContext()
  const missingValuePlaceholder = '--'
  const popoutContent = (
    <Box>
      <Box>
        <p>{i18n.text('generic.name')} :</p>
        <MiniHeader>{machine.name}</MiniHeader>
        <p>{i18n.text('machine-details.commodity-type')} :</p>
        <MiniHeader>{machine.commodityType || missingValuePlaceholder}</MiniHeader>
        <p>{i18n.text('machine-details.voltage')} :</p>
        <MiniHeader>{machine.voltage || missingValuePlaceholder}</MiniHeader>
        <p>{i18n.text('machine-details.power-factor')} :</p>
        <MiniHeader>{machine.powerFactor || missingValuePlaceholder}</MiniHeader>
        <p>{i18n.text('machine-details.scale')} :</p>
        <MiniHeader>{machine.scale || missingValuePlaceholder}</MiniHeader>
      </Box>
    </Box>
  )
  return (
    <StyledTooltip
      title={popoutContent}
      placement="right"
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 30],
            },
          },
        ],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          fontSize: '1rem',
          cursor: 'pointer',
        }}
      >
        <InfoIcon />
      </Box>
    </StyledTooltip>
  )
}
