import { FC } from 'react'

import { AssetTreeChildNode } from '../../../Shared/components/MUIComponents/update/AssetTree/StyledAssetTreeChildNode'
import { AssetTreeComponent } from '../../types'
import { useElectricityContext } from '../../context/ElectricityContext'

interface ElectricityAssetTreeComponentProps {
  component: AssetTreeComponent
  onSelect: (assetId: string) => void
  iconSlot?: React.ReactElement
}

const ElectricityAssetTreeComponent: FC<ElectricityAssetTreeComponentProps> = ({ component, onSelect, iconSlot }) => {
  const { selectedAssets } = useElectricityContext()
  const isSelected = !!selectedAssets[component.id]
  return (
    <AssetTreeChildNode
      isSelected={isSelected}
      item={{ id: component.id, name: component.name }}
      handleClick={() => onSelect(component.id)}
      hasCheckbox={true}
      wrapperSxProps={theme => ({ paddingLeft: theme.spacing(5) })}
      iconSlot={iconSlot}
    />
  )
}

export { ElectricityAssetTreeComponent }
