import { SeriesNames } from '../types'

interface ISeriesManipulationsArgs {
  seriesName: string
  areSeriesHiddenRef: React.MutableRefObject<{
    temperature: boolean
    humidity: boolean
  }>
  apexSeries: ApexAxisChartSeries
  setOpacity: (seriesName: SeriesNames | null) => void
}

export function toggleSeries(args: ISeriesManipulationsArgs) {
  const { seriesName, areSeriesHiddenRef, apexSeries, setOpacity } = args
  if (
    (seriesName === SeriesNames.temperature && areSeriesHiddenRef.current.temperature) ||
    (seriesName === SeriesNames.humidity && areSeriesHiddenRef.current.humidity)
  ) {
    showSeries({ seriesName, areSeriesHiddenRef, apexSeries, setOpacity })
  } else {
    hideSeries({
      seriesName,
      areSeriesHiddenRef,
      apexSeries,
      setOpacity,
    })
  }
}

function hideSeries(args: ISeriesManipulationsArgs) {
  const { seriesName, areSeriesHiddenRef, apexSeries, setOpacity } = args
  const chart = ApexCharts?.getChartByID('TemperatureGraphId')
  if (
    (seriesName === SeriesNames.temperature && !areSeriesHiddenRef.current.humidity) ||
    (seriesName === SeriesNames.humidity && !areSeriesHiddenRef.current.temperature)
  ) {
    if (apexSeries.findIndex(item => item.name === seriesName + ' Range') > -1) {
      chart?.hideSeries(seriesName + ' Range')
    }
    if (apexSeries.findIndex(item => item.name === seriesName) > -1) {
      chart?.hideSeries(seriesName)
    }
    const indexName = seriesName.toLowerCase() as keyof typeof areSeriesHiddenRef.current
    areSeriesHiddenRef.current = { ...areSeriesHiddenRef.current, [indexName]: true }
    setOpacity(seriesName)
  }
}

function showSeries(args: ISeriesManipulationsArgs) {
  const { seriesName, areSeriesHiddenRef, apexSeries, setOpacity } = args
  const chart = ApexCharts?.getChartByID('TemperatureGraphId')
  if (apexSeries.findIndex(item => item.name === seriesName + ' Range') > -1) {
    chart?.showSeries(seriesName + ' Range')
  }
  if (apexSeries.findIndex(item => item.name === seriesName) > -1) {
    chart?.showSeries(seriesName)
  }
  const indexName = seriesName.toLowerCase() as keyof typeof areSeriesHiddenRef.current
  areSeriesHiddenRef.current = { ...areSeriesHiddenRef.current, [indexName]: false }
  setOpacity(null)
}
