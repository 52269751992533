import React, { useMemo } from 'react'
import { Box, useTheme } from '@mui/material'

import AssetTreeLayout from '../Layouts/AssetTreeLayout'
import Error from '../../../Shared/components/MUIComponents/Error'
import Loader from '../../../Shared/components/MUIComponents/Loader'
import ProductionVolume from '../../components/ProductionVolume/ProductionVolume'
import QuickIntervalButtons from '../../components/QuickIntervalButtons/QuickIntervalButtons'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import useAssetTreeMode from '../../hooks/useAssetTreeMode'
import useLiveRefetching from '../../../Shared/hooks/useLiveRefetching'
import { AssetTreeMode } from '../../types/Assets.types'
import { DataType, GetOeeProductionVolumeDocument } from '../../../Shared/graphql/codegen'
import { getDefaultQuickIntervals } from '../../utils/quickIntervals'
import { getFormattedInterval } from '../../utils/helpers'
import { transformProductionVolumeData } from '../../utils/dataTransformations'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

const ProductionAnalysis = () => {
  const { interval, selectedMachines, assetColors } = useOEEContext()
  const theme = useTheme()
  const { i18n } = useI18nContext()

  useAssetTreeMode(AssetTreeMode.MULTI_SELECT)

  const { data, loading, error, internalInterval } = useLiveRefetching({
    query: GetOeeProductionVolumeDocument,
    options: {
      variables: {
        assetsIds: selectedMachines,
        from: interval.start,
        to: interval.end,
        type: DataType.ProductionCycles,
      },
    },
    interval,
    incrementStart: false,
  })

  const productionVolumeData = useMemo(
    () => transformProductionVolumeData(data?.myOrg?.assets ?? [], assetColors),
    [assetColors, data?.myOrg?.assets]
  )

  const formattedInterval = getFormattedInterval(
    internalInterval?.start ?? interval.start,
    internalInterval?.end ?? interval.end,
    i18n.locale.replace('_', '-')
  )

  if (loading) return <Loader />

  if (error) return <Error />

  return (
    <AssetTreeLayout>
      <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr', gridRowGap: theme.spacing(2) }}>
        <SectionHeader
          title={`${i18n.text('oee.secondary-nav.production-analysis')}`}
          chipContent={`${formattedInterval.start} - ${formattedInterval.end}`}
        >
          <QuickIntervalButtons quickIntervals={getDefaultQuickIntervals()} />
        </SectionHeader>
        <Box
          sx={{
            height: '60vh',
          }}
          id="chart-wrapper"
        >
          <ProductionVolume productionVolumeData={productionVolumeData} />
        </Box>
      </Box>
    </AssetTreeLayout>
  )
}

export default ProductionAnalysis
