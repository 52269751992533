import React, { FC, useRef, useState } from 'react'
import { Box, Button } from '@mui/material'

import { Float8 } from '../../../Shared/types/types'
import { Oee_Production_Speed_Unit_Enum, useInsertOeeTargetCycleTimeMutation } from '../../../Shared/graphql/codegen'
import { OutlinedStyledButton } from '../../../Shared/components/MUIComponents/update/OutlinedStyledButton'
import { SPEED_UNIT_STRING_SHORT } from '../../utils/constants'
import { StyledDialogModal } from '../../../Shared/components/MUIComponents/StyledDialogModal'
import {
  StyledFormControl,
  StyledInputLabel,
} from '../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { StyledUnitTextField } from '../../../Shared/components/MUIComponents/update/styledComponents/StyledUnitTextField'
import { convertToCycleTime } from '../../utils/unitConversions'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'
import { useToastContext } from '../../../Shared/contexts/ToastContext'

type SetTargetSpeedModalProps = {
  isOpen: boolean
  onClose: () => void
  machineName: string
  locationId: string
  currentCycleTime: number
}

const SetTargetSpeedModal: FC<SetTargetSpeedModalProps> = ({
  isOpen,
  onClose,
  machineName,
  locationId,
  currentCycleTime,
}) => {
  const [targetSpeedValidationError, setTargetSpeedValidationError] = useState<string>('')
  const [targetSpeedValue, setTargetSpeedValue] = useState<number | null>(null)
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false)

  const targetSpeedInput = useRef<HTMLInputElement>(null)

  const { units } = useOEEContext()
  const { i18n } = useI18nContext()
  const { showToast } = useToastContext()

  const isCycleTime = units.speed === Oee_Production_Speed_Unit_Enum.CycleTimeSeconds

  const successToastMessage = i18n.text(
    isCycleTime ? 'oee.machine-view.set-target-cycle-time.success' : 'oee.machine-view.set-target-speed.success'
  )

  const dialogTitle = i18n.text(
    isCycleTime ? 'oee.machine-view.set-target-cycle-time' : 'oee.machine-view.set-target-speed'
  )

  const dialogSubtitle = i18n.text(
    isCycleTime ? 'oee.machine-view.set-target-cycle-time.subtitle' : 'oee.machine-view.set-target-speed.subtitle'
  )

  const clearButtonText = i18n.text(
    isCycleTime ? 'oee.machine-view.set-target-cycle-time.clear' : 'oee.machine-view.set-target-speed.clear'
  )

  const [insertTargetCycleTime] = useInsertOeeTargetCycleTimeMutation({
    awaitRefetchQueries: true,
    onCompleted: () => {
      setIsFormDisabled(false)
      handleCloseModal()
      showToast(successToastMessage, 'success', 'Success!')
    },
    onError: error => {
      setIsFormDisabled(false)
      showToast(error.message, 'error', 'Error')
    },
  })

  const handleTargetSpeedValueChange = () => {
    if (!targetSpeedInput.current) return

    const value = targetSpeedInput.current.value
    setTargetSpeedValidationError('')

    if (!value || value.trim().length === 0) {
      setTargetSpeedValue(null)
      return
    }

    const valueToNumber = Number(value)

    if (isNaN(valueToNumber)) {
      setTargetSpeedValidationError(i18n.text('oee.machine-view.set-target-speed.validation.nan'))
      return
    }

    if (valueToNumber < 0) {
      setTargetSpeedValidationError(i18n.text('oee.machine-view.set-target-speed.validation.negative'))
      return
    }

    if (units.speed === Oee_Production_Speed_Unit_Enum.CycleTimeSeconds && valueToNumber === 0) {
      setTargetSpeedValidationError(i18n.text('oee.machine-view.set-target-cycle-time.not-zero'))
      return
    }

    setTargetSpeedValue(valueToNumber)
  }

  const handleClearTargetSpeedClick = async () => {
    setTargetSpeedValue(null)
    setTargetSpeedValidationError('')

    if (targetSpeedInput.current) {
      targetSpeedInput.current.value = ''
    }

    setIsFormDisabled(true)

    await insertTargetCycleTime({
      variables: {
        locationId: locationId,
        valueInSeconds: null,
      },
    })
  }

  const handleSave = async () => {
    if (targetSpeedValidationError) return

    setIsFormDisabled(true)

    await insertTargetCycleTime({
      variables: {
        locationId: locationId,
        valueInSeconds: (targetSpeedValue ? convertToCycleTime(targetSpeedValue, units.speed) : null) as Float8,
      },
    })
  }

  const handleCloseModal = () => {
    setTargetSpeedValidationError('')
    onClose()
  }

  return (
    <StyledDialogModal
      title={dialogTitle}
      subtitle={dialogSubtitle}
      isOpen={isOpen}
      onClose={handleCloseModal}
      height="responsive"
      maxWidth="xs"
    >
      <Box sx={{ display: 'grid', rowGap: '1.25rem', marginBottom: '1.25rem' }}>
        <StyledFormControl>
          <StyledInputLabel htmlFor="target-speed">{`${machineName}:`}</StyledInputLabel>
          <StyledUnitTextField
            unitName={`${i18n.text(SPEED_UNIT_STRING_SHORT[units.speed])}`}
            id="target-speed"
            name="target-speed"
            type="number"
            defaultValue={currentCycleTime}
            inputRef={targetSpeedInput}
            inputProps={{
              min: 0,
            }}
            size="small"
            variant="outlined"
            disabled={isFormDisabled}
            onChange={handleTargetSpeedValueChange}
            error={!!targetSpeedValidationError}
            helperText={targetSpeedValidationError}
          />
        </StyledFormControl>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="reset"
            sx={{ width: 'auto', textTransform: 'none' }}
            color="secondary"
            onClick={handleClearTargetSpeedClick}
          >
            {clearButtonText}
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '1rem' }}>
        <OutlinedStyledButton
          type="reset"
          onClick={handleCloseModal}
        >
          {i18n.text('generic.cancel')}
        </OutlinedStyledButton>
        <Button
          sx={{
            boxShadow: 'none',
            textTransform: 'none',
          }}
          variant="contained"
          color="secondary"
          disabled={!!targetSpeedValidationError || isFormDisabled}
          onClick={handleSave}
        >
          {i18n.text('generic.save')}
        </Button>
      </Box>
    </StyledDialogModal>
  )
}

export default SetTargetSpeedModal
