import type { ApolloClient, ApolloQueryResult } from '@apollo/client'
import { DateTime, Interval } from 'luxon'
import { List, Map } from 'immutable'
import { flatten } from 'ramda'

import {
  ASSETS_FETCHED,
  ASSET_TAB_CHANGE,
  DESELECT_ALL_ASSETS,
  FETCH_ASSETS,
  FETCH_MEASUREMENTS,
  MEASUREMENTS_FETCHED,
  RESET_SELECTED_ASSETS,
  SELECT_ASSET,
  SELECT_COMPONENT,
  SELECT_DATE_RANGE,
  SELECT_TOTAL_CONSUMPTION,
  SET_ANALYSIS_TYPE,
  TOGGLE_PREVIOUS_PERIOD,
  Y_AXIS_CHANGE,
} from './constants'
import {
  AnalysisAssetMeasurementsDocument,
  AnalysisAssetMeasurementsQuery,
  AnalysisAssetMeasurementsQueryVariables,
  AnalysisAssetsQuery,
} from '../../../Shared/graphql/codegen'
import { AnalysisType, DateRange } from '../../../Shared/types/analysis_types'
import { GROUP } from '../../../Shared/constants/tabTypes'
import { ID } from '../../../Shared/types/types'
import { LocalStoreState } from '../../types'
import {
  filterAssetsByAnalysisType,
  flattenAssetsIds,
  formatConsumption,
  getAssetGroupIndex,
  getFirstRelevantAssetId,
  getMeasurementTypesByAnalysis,
} from '../../../Shared/utils/analysis_utils'
import { formatMeasurements } from '../../../Shared/utils'
import { handleGenericError } from '../../../Shared/utils'
import { processWaterAssetTree } from './waterUtils'
import { toLocalDateTime } from '../../../Shared/utils'

function selectDateRange({ startDate, endDate }: DateRange) {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({
      type: SELECT_DATE_RANGE,
      payload: { startDate, endDate },
    })
  }
}

function setAnalysisType(analysisType: AnalysisType) {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({
      type: SET_ANALYSIS_TYPE,
      payload: {
        type: analysisType,
      },
    })
  }
}

function fetchMeasurements(
  client: ApolloClient<unknown>,
  { startDate, endDate }: DateRange,
  i18n: $I18FixMe,
  refetch = false
) {
  return (dispatch: $TSFixMeDispatch, getState: $TSFixMe) => {
    const { water: analysis } = getState()
    const allAssets = analysis.get('assets', List()).toJS()
    const analysisType = analysis.get('analysisType')
    const selectedAssets = analysis.get('selectedAssets', List()).toJS()
    const selectedAssetIds = flatten(selectedAssets)
    const totalConsumptionSelected = analysis.get('totalConsumptionSelected')
    const previousPeriodEnabled = analysis.get('previousPeriodEnabled')
    const relevantAssetsIds = flattenAssetsIds(filterAssetsByAnalysisType(allAssets, analysisType))
    const assetIds = selectedAssetIds.filter(id => relevantAssetsIds.includes(id))
    const measurementTypes = getMeasurementTypesByAnalysis(analysisType)
    if (allAssets.length === 0) {
      return
    }
    if (refetch) {
      selectDateRange({ startDate, endDate })(dispatch)
    } else {
      dispatch({ type: FETCH_MEASUREMENTS })
    }
    const activeAssetTab = analysis.get('activeAssetTab')
    const groupMeasurements = activeAssetTab === GROUP
    measurementTypes.forEach(type => {
      executeFetchMeasurements(
        client,
        dispatch,
        type,
        startDate,
        endDate,
        previousPeriodEnabled,
        assetIds,
        totalConsumptionSelected,
        groupMeasurements,
        i18n
      )
    })
  }
}

function executeFetchMeasurements(
  client: ApolloClient<unknown>,
  dispatch: $TSFixMeDispatch,
  measurementsType: $TSFixMe,
  startDate: DateTime,
  endDate: DateTime,
  previousPeriodEnabled: $TSFixMe,
  assetIds: string[],
  totalConsumptionSelected: $TSFixMe,
  groupMeasurements: boolean,
  i18n: $I18FixMe
) {
  const timeRange = Interval.fromDateTimes(startDate, endDate).toDuration()

  const variables = {
    from: toLocalDateTime(startDate),
    to: toLocalDateTime(endDate),
    altFrom: toLocalDateTime(startDate.minus(timeRange)),
    altTo: toLocalDateTime(endDate.minus(timeRange)),
    machineIds: assetIds,
    type: measurementsType.toUpperCase(),

    altPeriod: previousPeriodEnabled,
    totalConsumptionSelected,
    groupMeasurements,

    totalConsumptionSelectedAndAltPeriod: totalConsumptionSelected && previousPeriodEnabled,
    groupMeasurementsAndAltPeriod: groupMeasurements && previousPeriodEnabled,
  }

  client
    .query<AnalysisAssetMeasurementsQuery, AnalysisAssetMeasurementsQueryVariables>({
    query: AnalysisAssetMeasurementsDocument,
    variables,
    fetchPolicy: endDate > DateTime.now() ? 'network-only' : 'cache-first',
  })
    .then(({ data }) => {
      const { assets = [], consumption = [], altConsumption = [] } = data?.myOrg ?? {}
      const formattedAssets = assets.map(asset => ({ ...asset }))
      const formattedMeasurements = formatMeasurements(formattedAssets, {
        skipFirstLevel: !groupMeasurements,
      })
      const formattedAltMeasurements = previousPeriodEnabled
        ? formatMeasurements(formattedAssets, {
          measurementKey: 'altMeasurements',
          skipFirstLevel: !groupMeasurements,
        })
        : {}
      //@ts-expect-error bla
      const formattedConsumption = formatConsumption(i18n, consumption)
      //@ts-expect-error bla
      const formattedAltConsumption = formatConsumption(i18n, altConsumption)
      dispatch({
        type: MEASUREMENTS_FETCHED,
        payload: {
          measurements: formattedMeasurements,
          altMeasurements: formattedAltMeasurements,
          consumption: formattedConsumption,
          altConsumption: formattedAltConsumption,
          type: measurementsType,
        },
      })
    })
    .catch((err: Error) => handleGenericError(MEASUREMENTS_FETCHED, err, dispatch))
}

function resetSelectedAssets() {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({ type: RESET_SELECTED_ASSETS })
  }
}

function selectTotalConsumption(
  client: ApolloClient<unknown>,
  dateRange: $TSFixMe,
  totalConsumptionSelected: $TSFixMe,
  i18n: $I18FixMe
) {
  return (dispatch: $TSFixMeDispatch, getState: $TSFixMe) => {
    dispatch({
      type: SELECT_TOTAL_CONSUMPTION,
      payload: { totalConsumptionSelected },
    })
    fetchMeasurements(client, dateRange, i18n)(dispatch, getState)
  }
}

function togglePreviousPeriod(client: ApolloClient<unknown>, i18n: $I18FixMe) {
  return (dispatch: $TSFixMeDispatch, getState: $TSFixMe) => {
    const { water: analysis } = getState()
    const selectedDateRange = analysis.get('selectedDateRange', Map()).toJS()
    const previousPeriodEnabled = analysis.get('previousPeriodEnabled')
    dispatch({
      type: TOGGLE_PREVIOUS_PERIOD,
      payload: { previousPeriodEnabled },
    })
    fetchMeasurements(client, selectedDateRange, i18n)(dispatch, getState)
  }
}

function selectComponent(client: ApolloClient<unknown>, id: $TSFixMe, selectedDateRange: $TSFixMe, i18n: $I18FixMe) {
  return (dispatch: $TSFixMeDispatch, getState: $TSFixMe) => {
    const { water: analysis } = getState()
    const analysisType = analysis.get('analysisType')
    const measurementType = getMeasurementTypesByAnalysis(analysisType)[0]
    const selectedComponents = analysis.get('selectedComponents', List()).toJS()
    const measurements = analysis.get('data', measurementType, 'measurements', Map()).toJS()
    dispatch({
      type: SELECT_COMPONENT,
      payload: { id },
    })
    if (
      // fetch measurements if the selected component is not already in the list of selected components
      // (this is the case on component deselection)
      !selectedComponents.find((componentId: $TSFixMe) => componentId === id) &&
      // and if it doesn't already have measurements
      !Object.keys(measurements).find(key => key === id)
    ) {
      fetchMeasurements(client, selectedDateRange, i18n)(dispatch, getState)
    }
  }
}

function selectMultipleAssets(
  client: ApolloClient<unknown>,
  ids: $TSFixMe,
  selectedDateRange: $TSFixMe,
  i18n: $I18FixMe
) {
  return (dispatch: $TSFixMeDispatch, getState: $TSFixMe) => {
    ids.forEach((id: $TSFixMe) => {
      selectAsset(client, id, selectedDateRange, i18n, false)(dispatch, getState)
    })
    fetchMeasurements(client, selectedDateRange, i18n)(dispatch, getState)
  }
}

function selectAsset(
  client: ApolloClient<unknown>,
  id: $TSFixMe,
  selectedDateRange: $TSFixMe,
  i18n: $I18FixMe,
  callFetchMeasurements = true
) {
  return (dispatch: $TSFixMeDispatch, getState: $TSFixMe) => {
    const { water: analysis } = getState()
    // Get things needed to check if  this asset has selectedComponents
    const assets = analysis.get('assets', List()).toJS()
    const selectedAssets = analysis.get('selectedAssets', List()).toJS()
    const selectedComponents = analysis.get('selectedComponents', List()).toJS()
    const groupIndex = getAssetGroupIndex(assets, id)
    const family = selectedAssets[groupIndex] || []
    const currentIndex = family.findIndex((a: $TSFixMe) => a === id)

    // If there is an asset we need to deselect its components
    if (currentIndex !== -1) {
      const assetId = family[currentIndex]
      // Get list of all component ID's
      const components = analysis
        .get('assets', List())
        .find(
          (group: $TSFixMe) => group.get('assets', List()).find((a: $TSFixMe) => a.get('id') === assetId),
          null,
          List()
        )
        .get('assets', List())
        .map((component: $TSFixMe) => component.get('id'))
      selectedComponents.forEach((selectedComponentId: $TSFixMe) => {
        if (components.includes(selectedComponentId)) {
          // Dispatch the actions for deselecting selected components.
          selectComponent(client, selectedComponentId, selectedDateRange, i18n)(dispatch, getState)
        }
      })
    }

    dispatch({
      type: SELECT_ASSET,
      payload: { id, index: groupIndex },
    })

    if (callFetchMeasurements) {
      fetchMeasurements(client, selectedDateRange, i18n)(dispatch, getState)
    }
  }
}

function setTotalSelected(totalConsumptionSelected: $TSFixMe) {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({
      type: SELECT_TOTAL_CONSUMPTION,
      payload: { totalConsumptionSelected },
    })
  }
}

function fetchAssets(
  queryAnalysisAssets: Promise<ApolloQueryResult<AnalysisAssetsQuery>>,
  client: ApolloClient<unknown>,
  i18n: $I18FixMe,
  autoSelectAssets?: ID[]
) {
  return (dispatch: $TSFixMeDispatch, getState: () => LocalStoreState) => {
    const fetching = getState().water.get('fetchAssetsPending')

    if (fetching) {
      return
    }

    dispatch({ type: FETCH_ASSETS })
    queryAnalysisAssets
      .then(({ data }) => {
        const unSortedAssets = data.myOrg?.assets ?? []
        const selectedDateRange = getState().water.get('selectedDateRange', Map()).toJS()
        const analysisType = getState().water.get('analysisType')
        const assets = processWaterAssetTree(unSortedAssets)
        const firstAssetId = getFirstRelevantAssetId(assets, analysisType)
        dispatch({ type: ASSETS_FETCHED, payload: { assets } })

        let assetIds = firstAssetId ? [firstAssetId] : []

        if (autoSelectAssets && autoSelectAssets.length > 0) {
          const allAssetIds = flattenAssetsIds(unSortedAssets)
          const assetSelection = autoSelectAssets.filter(id => allAssetIds.includes(id))

          if (assetSelection.length > 0) {
            assetIds = autoSelectAssets
          }
        }

        selectMultipleAssets(client, assetIds, selectedDateRange, i18n)(dispatch, getState)
      })
      .catch((err: $TSFixMe) => handleGenericError(ASSETS_FETCHED, err, dispatch))
  }
}

function yAxisChange() {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({
      type: Y_AXIS_CHANGE,
    })
  }
}

function assetTabChange(activeTab: $TSFixMe) {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({
      type: ASSET_TAB_CHANGE,
      payload: { activeTab },
    })
  }
}

function deselectAllAssets() {
  return (dispatch: $TSFixMeDispatch) => {
    dispatch({ type: DESELECT_ALL_ASSETS })
  }
}

const actionCreators = {
  assetTabChange,
  deselectAllAssets,
  fetchAssets,
  setAnalysisType,
  fetchMeasurements,
  resetSelectedAssets,
  selectAsset,
  selectMultipleAssets,
  selectComponent,
  selectDateRange,
  selectTotalConsumption,
  setTotalSelected,
  togglePreviousPeriod,
  yAxisChange,
}
export default actionCreators
