import { AlarmIcon } from '../../../Shared/components/icons/AlarmIcon'
import { AssetCardData, VibrationStatus } from '../../containers/OverviewPageContainer/types'
import { AssetStatus } from '../../../Shared/components/AssetCard/utils/types'
import { DisabledIcon } from '../../../Shared/components/icons/DisabledIcon'
import { NoDataIcon } from '../../../Shared/components/icons/NoDataIcon'
import { OfflineDotIcon } from '../../../Shared/components/icons/OfflineDotIcon'
import { SuccessIcon } from '../../../Shared/components/icons/SuccessIcon'
import { WarningIcon } from '../../../Shared/components/icons/WarningIcon'

export const getVibrationStatusValue = (vibrationStatus: number): AssetStatus => {
  switch (vibrationStatus) {
    case VibrationStatus.Alarm:
      return AssetStatus.Error
    case VibrationStatus.Warning:
      return AssetStatus.Warning
    case VibrationStatus.Good:
      return AssetStatus.Success
    case VibrationStatus.Offline:
      return AssetStatus.Off
    case VibrationStatus.NotConfigured:
      return AssetStatus.Disabled
    case VibrationStatus.NoData:
      return AssetStatus.NoData
    default:
      throw AssetStatus.NoData
  }
}

export const getVibrationStatusLabel = (vibStatus: AssetStatus, asset: AssetCardData, i18n: $TSFixMe) => {
  switch (vibStatus) {
    case AssetStatus.Success:
      return {
        icon: SuccessIcon,
        text: i18n.text('pdm.asset.vibration.status.good'),
      }
    case AssetStatus.Warning:
      return {
        icon: WarningIcon,
        text: i18n.text('pdm.asset.vibration.status.warning'),
      }
    case AssetStatus.Error:
      return {
        icon: AlarmIcon,
        text: i18n.text('pdm.asset.vibration.status.alarm'),
      }
    case AssetStatus.Off:
      return {
        icon: OfflineDotIcon,
        text: i18n.text('pdm.asset.vibration.status.offline'),
      }
    case AssetStatus.NoData:
      return {
        icon: NoDataIcon,
        text: i18n.text('pdm.asset.vibration.status.no.data'),
      }
    case AssetStatus.Disabled:
      return {
        icon: DisabledIcon,
        text: i18n.text('pdm.asset.vibration.status.not.configured'),
      }
    default:
      return {
        icon: DisabledIcon,
        text: i18n.text('pdm.asset.vibration.status.unknown'),
      }
  }
}
