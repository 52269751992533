import Chart from 'react-apexcharts'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Alert, Box, Typography, useTheme } from '@mui/material'
import { ApexOptions } from 'apexcharts'
import { FC } from 'react'
import { useLocation } from 'react-router'

import {
  AnalyticsEventTypes,
  AnalyticsIntent,
  AnalyticsPropositionType,
} from '../../../Shared/hooks/useAnalytics/analyticsTypes'
import { ChartToolsItem } from '../../../Shared/components/MUIComponents/update/ChartToolsDropdown'
import { GraphLoader } from '../GraphLoader'
import { GraphToolbar } from '../GraphToolbar'
import { HoverOnTitleInfoButtonCA } from '../../utils/analyticsTypes'
import { SwitchUnit } from '../../types/compressed-air_types'
import { useCompressedAirContext } from '../../context/CompressedAirContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useTrackHoveredElement } from '../../../Shared/hooks/useTrackHoveredElement'

interface IGraphWrapperProps {
  id: string
  isGrid: boolean
  children: React.ReactNode
  columnsNumber: number
  wrapperWidth?: number | string
  wrapperMinHeight?: number | string
}

type AirTemplateGraphProps = {
  wrapperWidth?: string
  isLoading?: boolean
  chartData: {
    series?: ApexAxisChartSeries | ApexNonAxisChartSeries
    options: ApexOptions
    type: 'line' | 'bar' | 'donut' | 'rangeArea'
    id: string
    height?: number | string
    isIconHidden?: boolean
    wrapperMinHeight?: string | number
    isFlex?: boolean
  }
  texts: {
    errorKey: string
    header?: string
    tooltip?: string
  }
  unitSwitchData?: {
    units: SwitchUnit[]
  }
  toolbarItems?: ChartToolsItem[]
  hasToolbar?: boolean
  noDataSlot?: React.ReactNode
  isToolbarMargined?: boolean
}

export const AirTemplateGraph: FC<AirTemplateGraphProps> = ({
  isLoading,
  wrapperWidth,
  chartData,
  texts,
  unitSwitchData,
  toolbarItems,
  hasToolbar,
  noDataSlot,
  isToolbarMargined = true,
}) => {
  const {
    dataMap,
    seriesError: error,
    switchUnit,
    queryParams,
    previousPeriodSeries,
    updatedOptionsFlag,
    setSwitchUnit,
  } = useCompressedAirContext()
  const location = useLocation()
  const { i18n } = useI18nContext()
  const { sid } = queryParams
  const initialGraphHeight = window.innerHeight * 0.55
  const [responsiveHeight, setResponsiveHeight] = useState(initialGraphHeight)
  const elementRef = useRef<HTMLElement>()

  useTrackHoveredElement<HoverOnTitleInfoButtonCA>({
    eventName: AnalyticsEventTypes.TRACKED_BUTTON_HOVER,
    eventParams: {
      proposition: AnalyticsPropositionType.COMPRESSED_AIR,
      intent: AnalyticsIntent.READ_INFO,
      target: 'hover_info_button',
    },
    elementRef,
    isLoading,
    duration: 10000,
  })

  useEffect(() => {
    if (unitSwitchData) {
      setSwitchUnit(unitSwitchData.units[0])
    }
  }, [])

  useLayoutEffect(() => {
    const handleResize = () => {
      const graphHeight = window.innerHeight * 0.55
      setResponsiveHeight(graphHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setResponsiveHeight])

  const currentSwitchUnit = unitSwitchData ? `(${switchUnit})` : ''

  return (
    <GraphWrapper
      id={chartData.id}
      wrapperWidth={wrapperWidth}
      wrapperMinHeight={chartData.wrapperMinHeight}
      columnsNumber={sid.length}
      isGrid={
        !!(previousPeriodSeries?.flow || previousPeriodSeries?.consumption) && sid.length > 1 && !chartData.isFlex
      }
    >
      {error && <GraphError>{i18n.text(texts.errorKey)}</GraphError>}
      {isLoading && <GraphLoader />}
      {!isLoading && (
        <Box>
          {(texts.header || unitSwitchData || toolbarItems) && (
            <GraphToolbar
              hasHeader={!!texts.header}
              elementRef={elementRef}
              toolbarItems={toolbarItems}
              unitSwitchData={unitSwitchData}
              i18nTitle={texts.header}
              currentSwitchUnit={currentSwitchUnit}
              switchUnit={switchUnit}
              setSwitchUnit={setSwitchUnit}
              isToolbarMargined={isToolbarMargined}
              tooltipText={texts.tooltip}
            />
          )}
          <Box
            sx={{
              position: 'relative',
            }}
          >
            {((!updatedOptionsFlag && location.pathname.includes('flow')) || !location.pathname.includes('flow')) &&
              !noDataSlot && (
                <Chart
                  data-testid={chartData.id}
                  options={chartData.options}
                  series={chartData.series}
                  type={chartData.type}
                  height={chartData.height || responsiveHeight}
                />
              )}
            {!!noDataSlot && noDataSlot}
          </Box>
        </Box>
      )}
    </GraphWrapper>
  )
}

const GraphWrapper: FC<IGraphWrapperProps> = ({
  children,
  isGrid,
  columnsNumber,
  wrapperWidth,
  wrapperMinHeight,
  id,
}) => {
  const theme = useTheme()
  return (
    <Box
      id={id}
      sx={{
        overflowY: 'visible',
        width: wrapperWidth || '100%',
        minHeight: wrapperMinHeight || 0,
        '.apexcharts-legend': {
          display: isGrid ? 'grid' : 'flex',
          gridTemplateColumns: `repeat(${columnsNumber}, auto)`,
          width: 'fit-content',
          marginTop: '20px',
        },
        [theme.breakpoints.down('lg')]: {
          width: '100%',
          overflowY: 'hidden',
          height: 'fit-content',
        },
      }}
    >
      {children}
    </Box>
  )
}

const GraphError: FC = ({ children }) => {
  return (
    <Box>
      <Alert severity="error">
        <Typography>{children}</Typography>
      </Alert>
    </Box>
  )
}

const NoAssetsSelectedTextMessage: FC = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="h5"
        fontWeight={400}
        textAlign="center"
      >
        {children}
      </Typography>
    </Box>
  )
}
