import { HourOfDay, MinuteOfHour } from '../../../../Shared/types/types'
import { ParsedSchedule } from '../types'
import { ProductionScheduleFragment } from '../../../../Shared/graphql/codegen'
import { transformToDate } from './productionSchedulesDateTransform'

export function parseSchedule(schedule: ProductionScheduleFragment) {
  const result: ParsedSchedule = {
    id: schedule.id,
    name: schedule.name,
    productionTimes:
      schedule.activeConfig?.config?.productionTimes.map(pt => ({
        id: pt.id,
        fromDay: pt.fromDay,
        toDay: pt.toDay,
        fromMinuteHourDate: transformToDate(pt.fromHour as HourOfDay, pt.fromMinute as MinuteOfHour),
        toMinuteHourDate: transformToDate(pt.toHour as HourOfDay, pt.toMinute as MinuteOfHour),
      })) ?? [],
  }
  return result
}
