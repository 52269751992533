import { FC, useMemo } from 'react'

import { AssetTreeChildWithChildren } from '../../../Shared/components/MUIComponents/update/AssetTree/StylesAssetTreeChildWithChildren'
import { AssetTreeComponent, AssetTreeMachine } from '../../types'
import { ElectricityAssetTreeComponent } from './ElectricityAssetTreeComponent'
import { ElectricityAssetTreeInfoTooltip } from './ElectricityAssetTreeInfoTooltip'
import { useElectricityContext } from '../../context/ElectricityContext'

interface ElectricityMachineWithComponentsProps {
  machine: AssetTreeMachine
  components: AssetTreeComponent[]
  onSelect: (assetId: string) => void
  searchTerm: string
  iconSlot?: React.ReactElement
}

const ElectricityMachineWithComponents: FC<ElectricityMachineWithComponentsProps> = ({
  machine,
  components,
  searchTerm,
  onSelect,
  iconSlot,
}) => {
  // Specific selection logic:
  // if the machine is selected we display normal checkbox
  // if the components are selected we display indeterminate
  const { selectedAssets } = useElectricityContext()
  const isSelected = !!selectedAssets[machine.id]

  const visibleComponents = useMemo(() => {
    if (!searchTerm) return components
    return components?.filter(component => component.name.toLowerCase().includes(searchTerm))
  }, [components, searchTerm])

  return (
    <AssetTreeChildWithChildren
      isSelected={isSelected}
      isIndeterminate={!isSelected && components.some(component => selectedAssets[component.id])}
      parent={{ id: machine.id, name: machine.name }}
      onCheckboxToggle={() => onSelect(machine.id)}
      hasCheckbox={true}
      iconSlot={iconSlot}
    >
      {visibleComponents.map((component: AssetTreeComponent) => (
        <ElectricityAssetTreeComponent
          key={component.id}
          component={component}
          onSelect={() => onSelect(component.id)}
          iconSlot={<ElectricityAssetTreeInfoTooltip machine={component} />}
        />
      ))}
    </AssetTreeChildWithChildren>
  )
}

export { ElectricityMachineWithComponents }
