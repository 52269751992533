import React, { FC } from 'react'
import { Box, Typography, paperClasses, styled } from '@mui/material'

import { StyledDialogModal } from '../../../../Shared/components/MUIComponents/StyledDialogModal'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

interface ILearnMoreAboutEfficiencyModalProps {
  isOpen: boolean
  handleModalClose: () => void
}

export const LearnMoreAboutEfficiencyModal: FC<ILearnMoreAboutEfficiencyModalProps> = ({
  isOpen,
  handleModalClose,
}) => {
  const { i18n } = useI18nContext()
  return (
    <StyledDialogModal
      title={i18n.text('router.multi.air.efficiency')}
      isOpen={isOpen}
      isLoading={false}
      onClose={handleModalClose}
      height="responsive"
      maxWidth="lg"
      sx={{
        [`.${paperClasses.root}`]: {
          maxWidth: '61.625rem',
        },
      }}
    >
      <Box>
        <TextWrapper>{i18n.text('settings.efficiency.learn-more.text1')}</TextWrapper>
        <ImagesWrapper>
          <ImageWrapper>
            <img
              alt="Compressed air system"
              src="https://res.cloudinary.com/dj4ypr91s/image/upload/v1720002633/data_collection_apps_static/urm4lreeln0buiy2ykhf.png"
            />
          </ImageWrapper>
          <ImageWrapper>
            <img
              alt="Compressed air system"
              src="https://res.cloudinary.com/dj4ypr91s/image/upload/v1720002634/data_collection_apps_static/iph7kwrxapux2olu4qgw.png"
            />
          </ImageWrapper>
        </ImagesWrapper>
        <TextWrapper>{i18n.text('settings.efficiency.learn-more.text2')}</TextWrapper>
        <ImagesWrapper>
          <ImageWrapper>
            <img
              alt="Compressed air system"
              src="https://res.cloudinary.com/dj4ypr91s/image/upload/v1720002634/data_collection_apps_static/fwfhe5kfqxx5oesivqyl.png"
            />
          </ImageWrapper>
          <ImageWrapper>
            <img
              alt="Compressed air system"
              src="https://res.cloudinary.com/dj4ypr91s/image/upload/v1720002635/data_collection_apps_static/h3hwo1gnr4oiqclp1df2.png"
            />
          </ImageWrapper>
        </ImagesWrapper>
      </Box>
    </StyledDialogModal>
  )
}

const ImagesWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  '&:first-of-type': {
    marginBottom: '1.5rem',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const TextWrapper = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  marginBottom: '1rem',
}))

const ImageWrapper = styled(Box)(({ theme }) => ({
  '> img': {
    width: '100%',
  },
}))
