import Button from '@mui/material/Button'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { ImageList, useTheme } from '@mui/material'
import { Typography } from 'antd'

import SelectableImageCard from './SelectableImageCard'
import { AssetInfoQuery } from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

type SelectableImageDialogProps = {
  data: AssetInfoQuery | undefined
  selectedImages: string[]
  imageDialogOpen: boolean
  setImageDialog: React.Dispatch<React.SetStateAction<boolean>>
  deleteAssetImages: () => Promise<void>
  toggleSelectableImages: (image: string) => void
}

const DeleteAssetImagesDialog = ({
  data,
  selectedImages,
  imageDialogOpen,
  setImageDialog,
  deleteAssetImages,
  toggleSelectableImages,
}: SelectableImageDialogProps) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()

  const isImageSelected = (image: { url?: string; name?: string }, selectedImages: string[]) => {
    return image.url ? selectedImages.includes(image.url) : false
  }

  const handleDialogOpen = () => {
    setImageDialog(true)
  }

  const handleDialogClose = () => {
    setImageDialog(false)
  }

  const locationImages = data?.probe?.images ?? []

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        disabled={locationImages.length === 0}
        sx={{
          padding: theme.spacing(1.5, 8),
        }}
        onClick={handleDialogOpen}
      >
        <DeleteOutlineIcon fontSize="small" />
        <Typography>{i18n.text('pdm.asset.details.images.delete')}</Typography>
      </Button>
      <Dialog
        open={imageDialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>{i18n.text('pdm.asset.details.images.select')}</DialogTitle>
        <DialogContent sx={{ width: 500, height: 450 }}>
          <ImageList
            cols={3}
            rowHeight={164}
          >
            {locationImages.map((img: { url?: string; name?: string }, index: number) => (
              <SelectableImageCard
                key={index}
                isSelected={isImageSelected(img, selectedImages)}
                theme={theme}
                image={img}
                toggleImageSelection={toggleSelectableImages}
              />
            ))}
          </ImageList>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={handleDialogClose}
            variant="outlined"
          >
            {i18n.text('pdm.asset.details.images.cancel')}
          </Button>
          <Button
            onClick={deleteAssetImages}
            variant="outlined"
            color="error"
          >
            {i18n.text('pdm.asset.details.images.delete.button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteAssetImagesDialog
