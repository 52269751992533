import { Box, styled, useTheme } from '@mui/material'
import { FC } from 'react'

import { RegularDarkMdText, SemiboldDarkXlText, SquareIconBox } from '../../Common/v1'

const IconTextBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1.25rem',
}))

type InputProps = {
  icon: React.ReactNode
  title: string
  subtitle: string
}

export const GoalBlock: FC<InputProps> = ({ icon, title, subtitle }) => {
  const theme = useTheme()
  return (
    <IconTextBox>
      <SquareIconBox sx={{ backgroundColor: theme.palette.SFIBase.white }}>{icon}</SquareIconBox>
      <SemiboldDarkXlText sx={{ textAlign: 'center' }}>{title}</SemiboldDarkXlText>
      <RegularDarkMdText sx={{ textAlign: 'center' }}>{subtitle}</RegularDarkMdText>
    </IconTextBox>
  )
}
