import React from 'react'
import { AutocompleteChangeReason, SelectChangeEvent } from '@mui/material'
import { OnDragEndResponder } from 'react-beautiful-dnd'

import { TrackingReportReportConfig_Insert_Input, UserLocale_Enum } from '../../../../Shared/graphql/codegen'

export type ModuleProps = {
  analysisType: string
  title: string | null
  enabled: boolean
  id?: string
  position?: number
  reportConfigId?: string
  assetIds?: string[]
  descriptionInclude?: boolean
  referenceValueInclude?: boolean
  assetGroupingLevel?: string
  timeGroupingLevel?: string

  daysOfWeekInclude?: DaysOfWeekIncludeProps
}
export type AssetsProps =
  | {
      assetId: string
      assetName: string
      groupId: string
      groupName: string
      type: string
    }[]
  | undefined

export type ModuleUpsertProps = {
  modules: {
    analysisType: string
    title: string | null
    enabled: boolean
    id?: string
    position?: number
    reportConfigId?: string
    assetIds?: string[]
  }[]
}

export type DraggableListProps = {
  items: ModuleProps[]
  onDragEnd: OnDragEndResponder
  reportId: string
  handleChange: (reportId: string) => void
}

export type DraggableListItemProps = {
  item: ModuleProps
  index: number
  reportId: string
  moduleId: string
  enabled: boolean
  handleChange: (reportId: string) => void
  numModules: number
}

export type RecipientRowProps = {
  currentName: string
  currentEmailAddress: string
  currentEnabled: boolean
  currentId?: string
  handlerecipientchange: (recipient: RecipientDataProps) => void
  onDelete: (id: string | undefined, index: number) => void
  index: number
}

export type RecipientDataProps = {
  name: string
  destination: string
  enabled: boolean
  id?: string
  __typename?: string
}

export type RecipientUpsertDataProps = {
  recipients: {
    name: string
    destination: string
    enabled: boolean
    id?: string | undefined
    __typename?: string | undefined
    reportConfigId: string
  }[]
}

export type RecipientListProps = {
  recipients: RecipientDataProps[]
  onChange: (recipients: RecipientDataProps[]) => void
  onDelete: (id: string | undefined, index: number) => void
  onAdd: () => void
}

export type VisualisationUpsertDataProps = {
  visualisations: {
    visualisationType: string
    unit: string
    position: number
    id?: string | undefined
    analysisModuleId: string
    __typename?: string | undefined
  }[]
}

export type VisualisationDataProps = {
  position: number
  visualisationType: string
  unit: string
  id?: string | undefined
  analysisType?: string
  __typename?: string
}

export type VisualisationListProps = {
  visualisations: VisualisationDataProps[]
  onChange: (visualisations: VisualisationDataProps[]) => void
  onDelete: (id: string | undefined, index: number) => void
  onAdd: () => void
  analysisType: string
  assetGroupingLevel: string
  timeGroupingLevel: string
  selectedAssets: AssetsProps
  maxNumBars: number
  daysOfWeekInclude: DaysOfWeekIncludeProps
  referenceInclude: boolean
}

export type VisualisationRowProps = {
  currentPosition: number
  currentId?: string | undefined
  currentVisualisationType: string
  currentUnit: string
  analysisType: string
  numAssets: number
  handleVisualisationChange: (visualisation: VisualisationDataProps) => void
  assetGroupingLevel: string
  timeGroupingLevel: string
  selectedAssets: AssetsProps
  maxNumBars: number
  daysOfWeekInclude: DaysOfWeekIncludeProps
  referenceInclude: boolean
  onDelete: (id: string | undefined, index: number) => void
  index: number
}

export type ReportRowProps = {
  reportId: string
  label: string
  enabled: boolean
  timeframe: string
  modules: number
  recipients: number
  language: string
  index: number
  numReports: number
}

export type MatchParams = {
  reportId: string
  moduleId: string | undefined
  tab: string
}

export type ReportParams = {
  reportId: string
}

export type ModuleParams = {
  reportId: string
  moduleId: string
}

export type FrequencySelectProps = {
  frequency: string
  handlefrequencychange: (event: SelectChangeEvent<string>) => void
}

export type LanguageSelectProps = {
  locale: UserLocale_Enum
  handlelocalechange: (event: SelectChangeEvent<UserLocale_Enum>) => void
}

export type TitleInputProps = {
  title: string
  handletitlechange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export type AnalysisTypeSelectProps = {
  analysisType: string
  handleAnalysisTypeChange: (event: SelectChangeEvent<string>) => void
}

export type AssetGroupingSelectProps = {
  numCommoditiesSelected: number
  assetGroupingLevel: string
  handleAssetGroupingChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export type AssetsSelectProps = {
  analysisType: string
  selectedAssets: AssetsProps
  handleAssetsChange: (
    _event: React.SyntheticEvent<Element, Event>,
    value: AssetsProps,
    reason: AutocompleteChangeReason
  ) => void
  selectAll: boolean
  handleSelectAllAssets: () => void
}

export type DaysOfWeekIncludeProps = {
  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
  sunday: boolean
}

export type DaysOfWeekSelectProps = {
  daysOfWeekInclude: DaysOfWeekIncludeProps
  handleToggleAllDays: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleToggleDay: (dayName: string, event: React.ChangeEvent<HTMLInputElement>) => void
}

export type DescriptionInputProps = {
  description: boolean
  handleDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export type ModuleTitleInputProps = {
  title: string
  handleTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export type ReferenceInputProps = {
  reference: boolean
  handleReferenceChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export type SaveCancelButtonsProps = {
  handleOpenCancelDialog: () => void
  handleCloseCancelDialog: () => void
  openCancelDialog: boolean
  handleSave: () => void
  isSaveDisabled: boolean
}

export type TimeGroupingSelectProps = {
  timeGroupingLevel: string
  handleTimeGroupingChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  frequency: string
}

export type Report = {
  id?: string
  customerId: string
  enabled: boolean
  frequency: string
  locale: UserLocale_Enum
  title: string
  recipients: Recipients[]
  analysisModules: AnalysisModules[]
}

export type AnalysisModules = {
  id?: string
  enabled: boolean
  position?: number
  analysisType: string
  title?: string
  descriptionInclude: boolean
  assetIds: JSON
  assetGroupingLevel: string
  timeGroupingLevel: string
  nTimeUnits?: number
  referenceValueInclude?: boolean
  daysOfWeekInclude?: JSON
  analysisParameters?: JSON
  visualisations: Visualisations[]
}

export type Visualisations = {
  id?: string
  position?: number
  visualisationType: string
  unit: string
}

export type Recipients = {
  name: string
  destination: string
  enabled: boolean
  id?: string
}

export type ReportInputProps = {
  input: TrackingReportReportConfig_Insert_Input
}

export enum AnalysisTypeEnum {
  energyUsage = 'energyUsage',
  shiftEnergyUsage = 'shiftEnergyUsage',
  productionTimes = 'productionTimes',
  standby = 'standby',
  protocolDeviation = 'protocolDeviation',
  peakPower = 'peakPower',
  timeSpentOn = 'timeSpentOn',
  timeSpentPerState = 'timeSpentPerState',
}

export type AssetsAutocompleteProps = {
  selectedAssets: AssetsProps
  assetsFiltered: {
    assetId: string
    assetName: string
    groupId: string
    groupName: string
    type: string
  }[]
  handleAssetsChange: (
    _event: React.SyntheticEvent<Element, Event>,
    value: AssetsProps,
    reason: AutocompleteChangeReason
  ) => void
  handleSelectAllAssets: () => void
  selectAll: boolean
}
