import { Theme } from '@mui/material'

import { AssetStatus, AssetStatusLabel } from './types'

export const getAssetStatusColors = (
  assetStatusColor: AssetStatus,
  assetStatusLabel: AssetStatusLabel,
  theme: Theme
) => {
  switch (assetStatusColor) {
    case AssetStatus.Success:
      return {
        backgroundColor: theme.palette.success.light,
        statusColor: theme.palette.success.main,
        textColor: theme.palette.success.dark,
        icon: assetStatusLabel.icon,
        text: assetStatusLabel.text,
        stroke: theme.palette.SFIGreen[400],
      }
    case AssetStatus.Warning:
      return {
        backgroundColor: theme.palette.warning.light,
        statusColor: theme.palette.warning.main,
        textColor: theme.palette.warning.dark,
        icon: assetStatusLabel.icon,
        text: assetStatusLabel.text,
        stroke: theme.palette.SFIWarning[500],
      }
    case AssetStatus.Error:
      return {
        backgroundColor: '#FEF3F2', // current theme.palette.error.light color is too dark, so we use a custom color
        statusColor: theme.palette.error.main,
        textColor: theme.palette.error.dark,
        icon: assetStatusLabel.icon,
        text: assetStatusLabel.text,
        stroke: theme.palette.SFIOrange[300],
      }
    case AssetStatus.NoData:
    case AssetStatus.Disabled:
      return {
        backgroundColor: theme.palette.SFIGreyLight[100],
        statusColor: theme.palette.SFIGreyLight[400],
        textColor: theme.palette.SFIGreyLight[700],
        icon: assetStatusLabel.icon,
        text: assetStatusLabel.text,
        stroke: theme.palette.SFIGreyLight[400],
      }
    case AssetStatus.Off:
    default:
      return {
        backgroundColor: theme.palette.SFIGreyLight[300],
        statusColor: theme.palette.SFIGreyLight[700],
        textColor: theme.palette.SFIGreyLight[700],
        icon: assetStatusLabel.icon,
        text: assetStatusLabel.text,
        stroke: theme.palette.SFIGreyLight[500],
      }
  }
}
