import React, { ChangeEvent, FC, useMemo } from 'react'
import { Box, FormControlLabel } from '@mui/material'

import AddStopReasonsDropdown from '../AddStopReasonsDropdown/AddStopReasonsDropdown'
import {
  Asset,
  OeeCustomerProductionStopReason,
  useDeleteOeeSensorLocationProductionStopReasonMutation,
} from '../../../../../Shared/graphql/codegen'
import { AssetWithAssignedStopReasons } from '../../../../types/Assets.types'
import { StyledCheckbox as Checkbox } from '../../../../../Shared/components/MUIComponents/update/Checkbox/StyledCheckBox'
import { ID } from '../../../../../Shared/types/types'
import { MuiStyledChip } from '../../../../../Shared/components/MUIComponents/update/StyledChips'
import {
  StyledTableAccentCell,
  StyledTableCell,
  StyledTableCellContentWrapper,
  StyledTableRow,
} from '../../../StyledTable'
import { useCurrentUser } from '../../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../../Shared/contexts/ToastContext'

type AssetTableRowProps = {
  asset: Asset
  stopReasons: OeeCustomerProductionStopReason[]
  isChecked: boolean
  onAssetSelectionChange: (assetId: string, isSelected: boolean) => void
  onStopReasonsUpdate: (updatedReasons: AssetWithAssignedStopReasons[]) => void
  onDelete: () => void
  isLoading: boolean
}

const AssetTableRow: FC<AssetTableRowProps> = ({
  asset,
  stopReasons,
  isChecked,
  onAssetSelectionChange,
  onStopReasonsUpdate,
  onDelete,
  isLoading,
}) => {
  const { selectedCustomer } = useCurrentUser()
  const { i18n } = useI18nContext()
  const { showToast } = useToastContext()

  const assetId = asset.enoceanPulseCounterSensorLocations?.[0].id

  const [deleteStopReasonFromAsset] = useDeleteOeeSensorLocationProductionStopReasonMutation({
    awaitRefetchQueries: true,
    onCompleted: async () => {
      showToast(i18n.text('settings.oee-settings.stop-reasons.success.deleted'), 'success', 'Success!')
      onDelete()
    },
    onError: async () => {
      showToast(i18n.text('general.error.something-went-wrong'), 'error', 'Error')
    },
  })

  const productionStopReasons = useMemo(
    () => asset.enoceanPulseCounterSensorLocations.map(sensorLocation => sensorLocation.productionStopReasons),
    [asset.enoceanPulseCounterSensorLocations]
  )

  const customerProductionStopReasons = useMemo(
    () =>
      productionStopReasons.flatMap(productionStopReason => {
        return productionStopReason.map(stopReason => {
          return stopReason.customerProductionStopReason
        })
      }),
    [productionStopReasons]
  )

  const selectedAsset = useMemo(() => {
    return {
      locationId: assetId,
      customerProductionStopReasonIds: customerProductionStopReasons.map(reason => reason.id as ID),
    }
  }, [assetId, customerProductionStopReasons])

  const handleReasonDelete = (itemId: string) => {
    const stopReason = customerProductionStopReasons.find(
      customerProductionStopReason => customerProductionStopReason.text === itemId
    )

    if (!stopReason) return

    deleteStopReasonFromAsset({
      variables: {
        customerId: selectedCustomer.id as ID,
        customerProductionStopReasonId: stopReason.id as ID,
        locationId: assetId as ID,
      },
    })
  }

  const handleAssetSelectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    onAssetSelectionChange(asset.enoceanPulseCounterSensorLocations?.[0].id, event.target.checked)
  }

  return (
    <StyledTableRow sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
      <StyledTableAccentCell>
        <StyledTableCellContentWrapper>
          <FormControlLabel
            sx={{
              padding: '1rem',
              '.MuiFormControlLabel-label': {
                fontSize: '0.875rem',
              },
            }}
            label={asset.name}
            control={
              <Checkbox
                sx={{ padding: 0, marginRight: '0.5rem', fontSize: '0.875rem' }}
                id={asset.id}
                checked={isChecked}
                color="secondary"
                size="small"
                onChange={handleAssetSelectionChange}
              />
            }
          />
        </StyledTableCellContentWrapper>
      </StyledTableAccentCell>
      <StyledTableCell>
        <StyledTableCellContentWrapper>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', alignContent: 'center', alignItems: 'center' }}>
            {customerProductionStopReasons.map(stopReason => {
              return (
                <MuiStyledChip
                  key={stopReason.id}
                  handleDelete={handleReasonDelete}
                  item={stopReason.text}
                />
              )
            })}
            <AddStopReasonsDropdown
              stopReasons={stopReasons}
              selectedAssets={[selectedAsset]}
              onStopReasonsUpdate={onStopReasonsUpdate}
              anchor={{ variant: 'text', sx: { padding: '0.25rem 0.5rem', textTransform: 'none' } }}
              isLoading={isLoading}
            />
          </Box>
        </StyledTableCellContentWrapper>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default AssetTableRow
