import React, { FC, useState } from 'react'

import { createCtx } from '../../Shared/utils'

type ChartToolsContextType = {
  showMean: boolean
  setShowMean: React.Dispatch<React.SetStateAction<boolean>>
  showThresholds: boolean
  setShowThresholds: React.Dispatch<React.SetStateAction<boolean>>
  showMinMaxRange: boolean
  setShowMinMaxRange: React.Dispatch<React.SetStateAction<boolean>>
}
const [useChartToolsContext, ChartToolsContextReactProvider] = createCtx<ChartToolsContextType>()

const ChartToolsContextProvider: FC = ({ children }) => {
  const [showMean, setShowMean] = useState(false)
  const [showThresholds, setShowThresholds] = useState(false)
  const [showMinMaxRange, setShowMinMaxRange] = useState(false)

  return (
    <ChartToolsContextReactProvider
      value={{
        showMean,
        setShowMean,
        showMinMaxRange,
        setShowMinMaxRange,
        showThresholds,
        setShowThresholds,
      }}
    >
      {children}
    </ChartToolsContextReactProvider>
  )
}

export { useChartToolsContext, ChartToolsContextProvider }
