import { FC } from 'react'

import { AssetTreeGroup } from '../../types'
import { ElectricityAssetTreeGroup } from './ElectricityAssetTreeGroup'

interface ElectricityAssetTreeGroupsTabProps {
  visibleGroups: AssetTreeGroup[]
  searchTerm: string
  onSelect: (assetId: string) => void
  onGroupSelect: (groupId: string) => void
}

export const ElectricityAssetTreeGroupsTab: FC<ElectricityAssetTreeGroupsTabProps> = ({
  visibleGroups,
  searchTerm,
  onSelect,
  onGroupSelect,
}) => {
  return (
    <>
      {visibleGroups.map((group: AssetTreeGroup, index: number) => (
        <ElectricityAssetTreeGroup
          showIfEmpty={true}
          isExpanded={false}
          isIndeterminate={false}
          disableExpand={true}
          isSelectable={true}
          key={group.id}
          group={group}
          machines={[]}
          onSelect={onSelect}
          onGroupSelect={onGroupSelect}
          searchTerm={searchTerm}
        />
      ))}
    </>
  )
}
