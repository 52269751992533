import React, { FC } from 'react'
import { Tooltip } from '@mui/material'

import { HUMIDITY_COLOR, TEMPERATURE_COLOR } from '../../constants'
import { Legend, LegendStamp, LegendTitle, LegendsWrapper } from '../Graphs/StyledLegend'
import { SeriesNames } from '../../types'
import { toggleSeries } from '../../utils/seriesManipulations'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useTemperatureContext } from '../../context/TemperatureContext'

interface ICustomLegendProps {
  hasHumidity: boolean
}

export const CustomLegend: FC<ICustomLegendProps> = ({ hasHumidity }) => {
  const { i18n } = useI18nContext()
  const { setOpacity, areSeriesHiddenRef, apexSeries, legendOpacity } = useTemperatureContext()

  const setTooltipText = (isHidden: boolean) =>
    isHidden ? i18n.text('temperature.graph.show-series') : i18n.text('temperature.graph.hide-series')
  return (
    <LegendsWrapper>
      <Tooltip title={setTooltipText(legendOpacity === SeriesNames.temperature)}>
        <Legend
          sx={{ opacity: legendOpacity === SeriesNames.temperature ? 0.45 : 1 }}
          onClick={() =>
            toggleSeries({
              seriesName: SeriesNames.temperature,
              setOpacity,
              apexSeries,
              areSeriesHiddenRef,
            })}
        >
          <LegendStamp sx={{ background: TEMPERATURE_COLOR }} />
          <LegendTitle>{i18n.text('temperature.main.title')}</LegendTitle>
        </Legend>
      </Tooltip>
      {hasHumidity && (
        <Tooltip title={setTooltipText(legendOpacity === SeriesNames.humidity)}>
          <Legend
            sx={{ opacity: legendOpacity === SeriesNames.humidity ? 0.45 : 1 }}
            onClick={() =>
              toggleSeries({
                seriesName: SeriesNames.humidity,
                setOpacity,
                apexSeries,
                areSeriesHiddenRef,
              })}
          >
            <LegendStamp sx={{ background: HUMIDITY_COLOR }} />
            <LegendTitle>{i18n.text('temperature.name.humidity')}</LegendTitle>
          </Legend>
        </Tooltip>
      )}
    </LegendsWrapper>
  )
}
