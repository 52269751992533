import { Box, Typography, useTheme } from '@mui/material'
import { Redirect, Route, Switch } from 'react-router'

import ReportDocuments from '../ReportDocuments'
import MuiSecondaryNav, {
  LinkInfo,
} from '../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'
import { ConfigureTabs } from './ReportSettings/ConfigurationTabs'
import { ReportsOverview } from './ReportSettings/ReportsOverview'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

export function EnergyReportsContainer() {
  const theme = useTheme()

  const { i18n } = useI18nContext()

  const secondaryLinks: LinkInfo[] = [
    {
      name: 'Reports',
      i18n: 'reports.navigation.reports',
      path: '/reports/documents',
    },
    {
      name: 'Configuration',
      i18n: 'reports.navigation.configuration',
      path: '/reports/configuration/tracking',
    },
  ]

  return (
    <Box
      sx={{
        overflowY: 'auto',
        padding: '1.5rem 7rem',
        height: '100%',
        [theme.breakpoints.down('md')]: {
          padding: '1.5rem 2rem',
        },
      }}
    >
      <Typography
        sx={{
          color: theme => theme.palette.SFIGreyLight[800],
          fontSize: '1.875rem',
          fontWeight: 600,
          lineHeight: '2.375rem',
          marginBottom: '0.875rem',
        }}
      >
        {i18n.text('reports.documents.reports')}
      </Typography>
      <MuiSecondaryNav links={secondaryLinks}></MuiSecondaryNav>

      <Box>
        <Switch>
          <Route
            exact
            path="/reports"
            render={() => <Redirect to="/reports/documents" />}
          />
          <Route
            exact
            path="/reports/documents"
            render={() => <ReportDocuments />}
          />
          <Route
            exact
            path="/reports/configuration/tracking"
            render={() => <ReportsOverview />}
          />
          <Route
            exact
            path="/reports/configuration/tracking/:reportId/:tab"
            render={() => <ConfigureTabs />}
          />
          <Route
            exact
            path="/reports/configuration/tracking/:reportId/:tab/:moduleId"
            render={() => <ConfigureTabs />}
          />
          <Redirect to="/reports/documents" />
        </Switch>
      </Box>
    </Box>
  )
}

export default EnergyReportsContainer
