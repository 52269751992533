import { ApexOptions } from 'apexcharts'
import { useMemo, useState } from 'react'
import { useTheme } from '@mui/material'

import { ApexXAxisAnnotationData } from '../containers/GraphsPage/types'
import { customPanIcon, exportIconHTML, zoomIconHTML, zoomInIconHTML, zoomOutIconHTML } from '../components/ApexWrapper'

export interface IVibrationChartOptions {
  GRAPH_NAME: string
  thresholds?: number[] | null
  annotations: ApexXAxisAnnotationData[] | undefined
  seriesYMin?: number
  seriesYMax?: number
  zoom?: {
    min: number
    max: number
  }
  i18n: $I18FixMe
  group?: string
  showTemperature: boolean
  temperatureUnit?: string // default: '°C' will be configurable in the future - still in discussion with PM
  handleZoomChange?: (chart: unknown, changed: { xaxis: { min: number; max: number } }) => void
}

// TODO: Colour missalignment in the chart.
export const useVibrationChartOptions = (args: IVibrationChartOptions): ApexOptions => {
  const {
    GRAPH_NAME,
    thresholds,
    annotations,
    seriesYMax,
    i18n,
    handleZoomChange,
    zoom,
    group,
    showTemperature,
    temperatureUnit = '°C',
  } = args
  const theme = useTheme()
  const [togglePanning, setTogglePanning] = useState(false)

  // Panning functionality is not stable !
  /**
   * //TODO: The main issue with the is that it does not call the togglePanning() on the correct chart instance.
   * Specifying the chart instance and calling the method on it still not working and atm not possible.
   * Handles the toggle functionality for panning in a chart.
   * @param chart - The chart object.
   * @param options - The chart options.
   * @param e - The event object.
   */
  // const handleTogglePanning = async (chart: any, options: any, e: any) => {
  //   if (togglePanning) {
  //     console.log('Panning is enabled')
  //     chart.w.globals.panEnabled = true
  //     console.log(chart)
  //     chart.ctx.toolbar.togglePanning()
  //     chart.ctx.toolbar.elCustomIcons[0].classList.add('apexcharts-selected')
  //   } else {
  //     console.log('Panning is disabled')
  //     chart.w.globals.panEnabled = false
  //     chart.ctx.toolbar.toggleOtherControls()
  //     chart.ctx.toolbar.elCustomIcons[0].classList.remove('apexcharts-selected')
  //   }
  //   setTogglePanning(!togglePanning)
  // }

  const graphOptions: ApexOptions = useMemo(() => {
    const options: ApexOptions = {
      annotations: {
        xaxis: [...(annotations || [])],
      },
      colors: [
        theme.palette.SFIBrand[900], // x
        theme.palette.success.dark, // y
        theme.palette.SFIGreyLight[600], // z
        theme.palette.SFIOrange[600], // temperature
      ],
      chart: {
        id: GRAPH_NAME,
        group,
        offsetX: showTemperature ? 10 : 0,
        type: 'line',
        animations: {
          enabled: false,
        },
        toolbar: {
          offsetY: -40,
          offsetX: 0,
          autoSelected: 'zoom',
          tools: {
            reset: false,
            zoomin: zoomInIconHTML,
            zoomout: zoomOutIconHTML,
            download: exportIconHTML,
            pan: customPanIcon(i18n),
            // TODO: Panning requires creating a custom icon and handling custom event, so that we can access the ApexChart object.
            // customIcons: [
            //   {
            //     icon: panIconHTML,
            //     index: 2,
            //     click: handleTogglePanning,
            //     title: 'Move Timeline',
            //   },
            // ],
            selection: true,
            zoom: zoomIconHTML,
          },
        },
        zoom: {
          enabled: true,
          autoScaleYaxis: true,
          type: 'x',
        },
        events: {
          zoomed: handleZoomChange,
        },
      },
      xaxis: {
        type: 'datetime',
        min: zoom?.min,
        max: zoom?.max,
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: [
        {
          seriesName: 'x',
          min: 0,
          forceNiceScale: true,
          decimalsInFloat: 1,
          tickAmount: 6,
          floating: false,
          show: true,
        },
        {
          seriesName: 'x',
          min: 0,
          decimalsInFloat: 3,
          tickAmount: 6,
          floating: false,
          forceNiceScale: true,
          show: false,
        },
        {
          seriesName: 'x',
          min: 0,
          decimalsInFloat: 3,
          tickAmount: 6,
          floating: false,
          forceNiceScale: true,
          show: false,
        },
        showTemperature
          ? {
              seriesName: 'temperature',
              opposite: true,
              min: 0,
              decimalsInFloat: 3,
              forceNiceScale: true,
              tickAmount: 6,
              max: Math.floor(seriesYMax ?? 0),
              floating: false,
              labels: {
                formatter: value => `${value % 1 ? value.toFixed(2) : value.toString()}${temperatureUnit}`,
              },
              tooltip: {
                enabled: false,
              },
            }
          : {
              show: false,
            },
      ],
      legend: {
        position: 'bottom',
        horizontalAlign: 'left',
        offsetY: 5,
        offsetX: 10,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14',
        markers: {
          width: 8,
          height: 8,
          offsetX: -3,
          offsetY: 0,
          radius: 12,
        },
      },
      tooltip: {
        x: {
          show: true,
          format: 'MMM ddd dd HH:mm',
        },
        y: {
          formatter: (
            value: number | string,
            options: { series: number[][]; seriesIndex: number; dataPointIndex: number }
          ) => {
            return `${value ? (+value).toFixed(3) : 0} ${options.seriesIndex === 3 ? temperatureUnit : 'mm/s'}`
          },
        },
      },
      stroke: {
        width: 3,
      },
    }

    if (thresholds) {
      options.annotations = {
        ...options.annotations,
        yaxis: [
          {
            y: 0,
            y2: thresholds[0],
            fillColor: theme.palette.success.main,
            opacity: 0.1,
          },
          {
            y: thresholds[0],
            y2: thresholds[1],
            fillColor: theme.palette.warning.main,
            opacity: 0.1,
          },
          {
            y: thresholds[1],
            y2: thresholds[1] * 1000,
            fillColor: theme.palette.error.main,
            opacity: 0.1,
          },
        ],
      }
    }
    return options
  }, [handleZoomChange, theme, group, annotations, thresholds, GRAPH_NAME, showTemperature, togglePanning])

  return graphOptions
}
