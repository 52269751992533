// A menu that imitates the secondary navigation menu of the website but does not expect paths, and is not responsible for active states.

import { Button, ButtonProps, Toolbar, ToolbarProps, styled } from '@mui/material'
import { FC } from 'react'

import { NavLikeToolbarItem } from '../../../../../Electricity/types'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

const StyledToolbar = styled(props => {
  return <Toolbar {...props} />
})(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'stretch',
  flex: 1,
  padding: '0 !important',
  minHeight: '0 !important',
  gap: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  [theme.breakpoints.down('md')]: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
}))

interface ToolbarButtonProps extends ButtonProps {
  isActive: boolean
}

const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'isActive',
})<ToolbarButtonProps>(({ theme, isActive }) => ({
  borderRadius: 0,
  width: '100%',
  padding: '0.5rem 0.75rem',
  fontSize: '0.875rem',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  color: theme.palette.SFIGreyLight[500],
  borderBottom: `2px solid transparent`,
  borderColor: isActive ? theme.palette.SFIBrand[700] : 'transparent',
  marginBottom: '-1px',
  '&:hover': {
    background: 'none',
    color: isActive ? theme.palette.SFIBrand[700] : 'inherit',
  },
}))

interface NavLikeMenuProps {
  onItemClick: (value: string) => void
  items: NavLikeToolbarItem[]
  wrapperProps?: ToolbarProps
}

export const NavLikeToolbar: FC<NavLikeMenuProps> = ({ items, onItemClick, wrapperProps }) => {
  const { i18n } = useI18nContext()

  return (
    <StyledToolbar {...wrapperProps}>
      {items.map(item => (
        <StyledButton
          isActive={item.isActive}
          onClick={() => onItemClick(item.value)}
          key={item.value}
          variant="text"
        >
          {i18n.text(item.title)}
        </StyledButton>
      ))}
    </StyledToolbar>
  )
}
