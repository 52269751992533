import List from '@mui/material/List'
import React, { FC, useEffect, useState } from 'react'
import { Box, Button, FormControlLabel, ListItem, Typography, useTheme } from '@mui/material'

import { StyledCheckbox as Checkbox } from '../../../../../Shared/components/MUIComponents/update/Checkbox/StyledCheckBox'
import { OeeCustomerProductionStopReason } from '../../../../../Shared/graphql/codegen'
import { OutlinedStyledButton } from '../../../../../Shared/components/MUIComponents/update/OutlinedStyledButton'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

const MAX_AMOUNT_OF_REASONS_PER_ASSET = 20

type AddStopReasonsDropdownFormProps = {
  stopReasons: OeeCustomerProductionStopReason[]
  defaultCheckedReasons: string[]
  defaultIndeterminateReasons: string[]
  isLoading: boolean
  onSubmit: (checkedReasons: string[], indeterminateReasons: string[]) => void
  onClose: () => void
}

const AddStopReasonsDropdownForm: FC<AddStopReasonsDropdownFormProps> = ({
  stopReasons,
  defaultCheckedReasons,
  defaultIndeterminateReasons,
  isLoading,
  onSubmit,
  onClose,
}) => {
  const [checkedReasons, setCheckedReasons] = useState<string[]>(defaultCheckedReasons)
  const [indeterminateReasons, setIndeterminateReasons] = useState<string[]>(defaultIndeterminateReasons)
  const [isValidAmount, setIsValidAmount] = useState<boolean>(true)

  const theme = useTheme()
  const { i18n } = useI18nContext()

  useEffect(() => {
    setIsValidAmount(checkedReasons.length + indeterminateReasons.length <= MAX_AMOUNT_OF_REASONS_PER_ASSET)
  }, [checkedReasons.length, indeterminateReasons.length])

  const handleReasonsChange = (checked: boolean, reasonId: string) => {
    if (indeterminateReasons.includes(reasonId)) {
      setIndeterminateReasons(prevIndeterminateReasons =>
        prevIndeterminateReasons.filter(indeterminateReason => indeterminateReason !== reasonId)
      )
    }

    if (checked) {
      setCheckedReasons(prevCheckedReasons => [...prevCheckedReasons, reasonId])
    } else {
      setCheckedReasons(prevCheckedReasons => prevCheckedReasons.filter(checkedReason => checkedReason !== reasonId))
    }
  }

  const handleFormReset = () => {
    setCheckedReasons(defaultCheckedReasons)
    setIndeterminateReasons(defaultIndeterminateReasons)
    onClose()
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!isValidAmount) return

    onSubmit(checkedReasons, indeterminateReasons)
  }

  return (
    <Box
      component="form"
      sx={{ paddingBottom: '0.5rem' }}
      onSubmit={handleFormSubmit}
    >
      <List>
        <Box sx={{ padding: '1rem' }}>
          {stopReasons.map(stopReason => (
            <ListItem
              key={stopReason.id}
              sx={{ display: 'flex', gap: '1rem' }}
            >
              <FormControlLabel
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontSize: '0.875rem',
                  },
                }}
                label={stopReason.text}
                control={
                  <Checkbox
                    sx={{ padding: 0, marginRight: '0.5rem' }}
                    id={stopReason.id}
                    color="secondary"
                    size="small"
                    indeterminate={indeterminateReasons.includes(stopReason.id)}
                    checked={checkedReasons.includes(stopReason.id)}
                    onChange={event => handleReasonsChange(event.target.checked, stopReason.id)}
                  />
                }
              />
            </ListItem>
          ))}
        </Box>
      </List>
      {!isValidAmount && (
        <Box sx={{ maxWidth: '100%', padding: '1rem', borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}` }}>
          <Typography
            sx={{
              fontSize: '0.875rem',
              color: theme.palette.error.main,
            }}
          >
            {i18n.text('settings.oee-settings.stop-reasons.error.max-amount-assigned', {
              maxAmountOfReasons: MAX_AMOUNT_OF_REASONS_PER_ASSET,
            })}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: theme.spacing(1),
          padding: '1rem 1rem 0',
          borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
        }}
      >
        <OutlinedStyledButton
          type="reset"
          onClick={handleFormReset}
        >
          {i18n.text('generic.cancel')}
        </OutlinedStyledButton>
        <Button
          sx={{ textTransform: 'none', fontWeight: 600 }}
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!isValidAmount || isLoading}
        >
          {i18n.text('generic.save')}
        </Button>
      </Box>
    </Box>
  )
}

export default AddStopReasonsDropdownForm
