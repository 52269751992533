import React, { FC, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  SvgIconProps,
  Typography,
  accordionClasses,
  accordionSummaryClasses,
  styled,
} from '@mui/material'

import { ArrowDownIcon } from '../../../Shared/components/icons'

interface ICAAssetAccordionProps {
  title: string
  children: React.ReactNode
  isDefaultExpanded?: boolean
}

export const CAAssetAccordion: FC<ICAAssetAccordionProps> = ({ title, children, isDefaultExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(!!isDefaultExpanded)

  const handleIsExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <StyledAccordion
      defaultExpanded={isDefaultExpanded}
      expanded={isExpanded}
      onChange={handleIsExpanded}
    >
      <StyledAccordionSummary
        aria-controls="ca-asset-content"
        id="ca-asset-header"
        isExpanded={isExpanded}
      >
        <Title>{title}</Title>
        <StyledArrowIcon isExpanded={isExpanded} />
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`&.${accordionClasses.root}`]: {
    border: 'none',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.SFIGreyLight[200]}`,
    paddingBottom: '1.5rem',
    borderRadius: 0,
    '&::before': {
      display: 'none',
    },
  },
}))

interface IStyledAccordionSummaryProps extends AccordionSummaryProps {
  isExpanded: boolean
}

interface IStyledArrowIconProps extends SvgIconProps {
  isExpanded: boolean
}

const StyledAccordionSummary = styled((props: IStyledAccordionSummaryProps) => {
  const { isExpanded, ...other } = props
  return <AccordionSummary {...other} />
})(({ theme, isExpanded }) => ({
  padding: 0,
  [`.${accordionSummaryClasses.content}`]: {
    color: theme.palette.SFIGreyLight[400],
    alignItems: 'center',
    gap: '0.75rem',
    margin: isExpanded ? '1.5rem 0 0.25rem' : '1.5rem 0 0',
  },
}))

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[800],
  fontSize: '1.125rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
}))

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0,
})

const StyledArrowIcon = styled((props: IStyledArrowIconProps) => {
  const { isExpanded, ...other } = props
  return <ArrowDownIcon {...other} />
})(({ isExpanded }) => ({
  transform: isExpanded ? 'rotate(180deg)' : 'none',
  width: '0.875rem',
  height: '0.875rem',
}))
