import { ApexOptions } from 'apexcharts'
import { useMemo } from 'react'
import { useTheme } from '@mui/material'

import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface EnergyBalanceBarChartOptionsArgs {
  tooltipFormatter: (chart: any, options?: any) => string
  categories: Array<string | null>
  onDataPointMouseEnter?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
  onDataPointMouseLeave?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
  showingCosts: boolean
  valuesModifier: { unit: string }
  decimal: number
  loading: boolean
}
export const useEnergyBalanceBarChartOptions = ({
  tooltipFormatter,
  categories,
  onDataPointMouseEnter,
  onDataPointMouseLeave,
  showingCosts,
  valuesModifier,
  decimal,
  loading,
}: EnergyBalanceBarChartOptionsArgs) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const energyBalanceBarChartOptions: ApexOptions = useMemo(
    () => ({
      noData: {
        text: loading ? i18n.text('app.loading') : i18n.text('analysis.energy-balance.no-data'),
      },
      fill: {
        opacity: 1,
      },
      chart: {
        id: 'energyBalanceBarChart',
        type: 'bar',
        background: 'white',
        stacked: false,
        toolbar: {
          show: false,
        },
        events: {
          dataPointMouseEnter: onDataPointMouseEnter,
          dataPointMouseLeave: onDataPointMouseLeave,
        },
      },
      dataLabels: {
        formatter: v => `${i18n.number(v, { maximumFractionDigits: decimal })}`,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '80%',
          borderRadius: 4,
          borderRadiusApplication: 'end',
        },
      },
      xaxis: {
        categories,
        axisBorder: {
          show: true,
          color: 'lightGrey',
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          style: {
            fontSize: '14px',
          },
        },
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          style: {
            fontSize: '14px',
          },
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: tooltipFormatter,
        },
      },
      legend: {
        fontFamily: theme.typography.fontFamily,
        showForSingleSeries: true,
        horizontalAlign: 'left',
        fontSize: '14px',
        offsetY: 10,
        itemMargin: {
          vertical: 10,
        },
      },
      stroke: {
        width: 3,
        colors: ['transparent'],
      },
      markers: {
        size: 0,
        // Apex uses JSON.stringify to diff options and does not see updated
        // functions such as an updated formatter, so we update this stroke
        // opacity which is not being used to force re-render
        strokeOpacity: Math.random(),
      },
    }),
    [
      tooltipFormatter,
      categories,
      onDataPointMouseEnter,
      onDataPointMouseLeave,
      showingCosts,
      valuesModifier.unit,
      decimal,
      loading,
    ]
  )

  return energyBalanceBarChartOptions
}
