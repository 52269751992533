import React, { FC } from 'react'
import { Box, Typography, styled, useTheme } from '@mui/material'
import { DateTime } from 'luxon'

import { DataIntervalMode } from '../../types/Time.types'
import { GetOeeProductionMachineViewQuery, ProductionStatus } from '../../../Shared/graphql/codegen'
import { OffIcon, SuccessIcon, WarningIcon } from '../../../Shared/components/icons'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type ProductionShift = GetOeeProductionMachineViewQuery['productionMachineView']['shift']

type MachineStatisticsProps = {
  status: ProductionStatus
  shift?: ProductionShift | null
  totalCycles: number
  mode: DataIntervalMode
}

const StyledValue = styled('span')({
  fontWeight: 500,
})

const MachineStatistics: FC<MachineStatisticsProps> = ({ status, totalCycles, shift, mode }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()

  const iconStyles = {
    display: 'block',
    width: '1.25rem',
    height: '1.25rem',
  }

  const machineStatusCardProperties = {
    [ProductionStatus.Stopped]: {
      backgroundColor: theme.palette.SFIOrange[50],
      borderColor: theme.palette.SFIOrange[500],
      iconColor: theme.palette.SFIOrange[700],
      iconBackgroundColor: theme.palette.SFIOrange[100],
      textColor: theme.palette.SFIOrange[700],
      icon: <WarningIcon sx={iconStyles} />,
    },
    [ProductionStatus.Running]: {
      backgroundColor: theme.palette.SFIGreen[50],
      borderColor: theme.palette.SFIGreen[500],
      iconColor: theme.palette.SFIGreen[700],
      iconBackgroundColor: theme.palette.SFIGreen[100],
      textColor: theme.palette.SFIGreen[700],
      icon: <SuccessIcon sx={iconStyles} />,
    },
    [ProductionStatus.Off]: {
      backgroundColor: theme.palette.SFIGreyLight[300],
      borderColor: theme.palette.SFIGreyLight[500],
      iconColor: theme.palette.SFIGreyLight[700],
      iconBackgroundColor: theme.palette.SFIGreyLight[400],
      textColor: theme.palette.SFIGreyLight[700],
      icon: <OffIcon sx={iconStyles} />,
    },
    [ProductionStatus.Offline]: {
      backgroundColor: theme.palette.SFIGreyLight[200],
      borderColor: theme.palette.SFIGreyLight[400],
      iconColor: theme.palette.SFIGreyLight[700],
      iconBackgroundColor: theme.palette.SFIGreyLight[300],
      textColor: theme.palette.SFIGreyLight[700],
      icon: <OffIcon sx={iconStyles} />,
    },
  }

  const machineStatusTitle = {
    [ProductionStatus.Stopped]: 'oee.machine-view.machine-statistics.status.stopped',
    [ProductionStatus.Running]: 'oee.machine-view.machine-statistics.status.running',
    [ProductionStatus.Off]: 'oee.machine-view.machine-statistics.status.off',
    [ProductionStatus.Offline]: 'oee.machine-view.machine-statistics.status.offline',
  }

  const shiftDateFormat = 'cccc HH:mm'

  const getShiftName = () => {
    if (!shift) return i18n.text('oee.machine-view.machine-statistics.no-shift')

    if (shift.name) return shift.name

    return `${DateTime.fromISO(shift.start).toFormat(shiftDateFormat)} - ${DateTime.fromISO(shift.end).toFormat(
      shiftDateFormat
    )}`
  }

  const totalCyclesPeriodName =
    mode === DataIntervalMode.DAY
      ? 'oee.machine-view.machine-statistics.total-cycles.day-oee'
      : 'oee.machine-view.machine-statistics.total-cycles.shift-oee'

  return (
    <SectionBoxWrapper sx={{ span: 4 }}>
      <Typography
        variant="h6"
        sx={{ marginBottom: '1.25rem' }}
      >
        {i18n.text('oee.machine-view.machine-statistics.status.title')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1.12rem',
          width: '100%',
          padding: '0.5rem 1rem',
          borderRadius: '0.5rem',
          color: machineStatusCardProperties[status].textColor,
          border: `1px solid ${machineStatusCardProperties[status].borderColor}`,
          backgroundColor: machineStatusCardProperties[status].backgroundColor,
          marginBottom: '1.62rem',
        }}
      >
        <Box
          sx={{
            backgroundColor: machineStatusCardProperties[status].iconBackgroundColor,
            padding: '0.62rem',
            borderRadius: '50%',
          }}
        >
          {machineStatusCardProperties[status].icon}
        </Box>
        {i18n.text(machineStatusTitle[status])}
      </Box>
      <Typography sx={{ marginBottom: '0.75rem' }}>
        {`${i18n.text('oee.machine-view.machine-statistics.active-shift')}: `}
        <StyledValue>{getShiftName()}</StyledValue>
      </Typography>
      <Typography>
        {`${i18n.text('oee.machine-view.machine-statistics.total-cycles')} (${i18n.text(totalCyclesPeriodName)}): `}
        <StyledValue>{i18n.number(totalCycles ?? 0)}</StyledValue>
      </Typography>
    </SectionBoxWrapper>
  )
}

export default MachineStatistics
