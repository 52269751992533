import React, { BaseSyntheticEvent, FC } from 'react'
import { Alert, Box, Button, FormControl, FormLabel, RadioGroup, TextField, styled, useTheme } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import Loader from '../../../Shared/components/MUIComponents/Loader'
import StopReasonOption from './StopReasonOption'
import { ID } from '../../../Shared/types/types'
import { OutlinedStyledButton } from '../../../Shared/components/MUIComponents/update/OutlinedStyledButton'
import { StyledDialogModal } from '../../../Shared/components/MUIComponents/StyledDialogModal'
import {
  useCreateOeeProductionStopLogMutation,
  useGetOeeMachineStopReasonsQuery,
  useUpdateOeeProductionStopLogMutation,
} from '../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type Stop = {
  stopId: string
  stopLogId: string
  assetId: string
  stopReason: {
    id: string
    text: string
  }
  operatorFeedback: string
  duration: string | number
  startTime: string
}

type ExplainStopModalProps = {
  isOpen: boolean
  onClose: () => void
  onListUpdate: () => void
  stop: Stop
}

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
  color: theme.palette.text.primary,
}))

const StyledPseudoInput = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  borderRadius: theme.shape.borderRadius,
  padding: '0.5rem 0.2rem',
  textAlign: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  boxShadow: '0 1px 2px 0px #1018280D',
}))

const ExplainStopModal: FC<ExplainStopModalProps> = ({ isOpen, onClose, onListUpdate, stop }) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const {
    data: machineStopReasons,
    loading: machineStopReasonsLoading,
    error: machineStopReasonsError,
  } = useGetOeeMachineStopReasonsQuery({
    variables: {
      assetId: stop.assetId as ID,
    },
  })

  const hasReasons =
    !machineStopReasonsError &&
    machineStopReasons?.assets?.[0]?.enoceanPulseCounterSensorLocations?.[0].productionStopReasons &&
    machineStopReasons?.assets?.[0]?.enoceanPulseCounterSensorLocations?.[0].productionStopReasons.length > 0

  const [createProductionStopLog, { loading: isCreating, error: createProductionStopLogError }] =
    useCreateOeeProductionStopLogMutation({
      awaitRefetchQueries: true,
      onCompleted: async response => {
        onListUpdate()
        onClose()
      },
    })

  const [updateProductionStopLog, { loading: isUpdating, error: updateProductionStopLogError }] =
    useUpdateOeeProductionStopLogMutation({
      awaitRefetchQueries: true,
      onCompleted: async response => {
        onListUpdate()
        handleModalClose()
      },
    })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      stopReason: stop.stopReason.id ?? '',
      operatorFeedback: stop.operatorFeedback ?? '',
    },
    mode: 'onChange',
  })

  const stopReasons =
    machineStopReasons?.assets?.[0]?.enoceanPulseCounterSensorLocations?.[0].productionStopReasons ?? []

  const formattedStopDuration =
    typeof stop.duration === 'number'
      ? `${i18n.number(stop.duration)} ${i18n.text('oee.stop-logging.drawer.item.minutes')}`
      : i18n.text('oee.stop-logging.drawer.item.modal.duration.ongoing')

  const handleModalClose = () => {
    reset({
      stopReason: stop.stopReason.id ?? '',
      operatorFeedback: stop.operatorFeedback ?? '',
    })
    onClose()
  }

  const onSubmit = (data: { stopReason: string; operatorFeedback: string }, event?: BaseSyntheticEvent) => {
    event?.preventDefault()

    if (!data.stopReason || data.stopReason.length === 0) return

    if (!stop.stopLogId) {
      createProductionStopLog({
        variables: {
          productionStopId: stop.stopId as ID,
          productionStopReasonId: data.stopReason as ID,
          operatorFeedback: data.operatorFeedback,
        },
      })
    } else {
      updateProductionStopLog({
        variables: {
          productionStopReasonLogId: stop.stopLogId as ID,
          productionStopReasonId: data.stopReason as ID,
          operatorFeedback: data.operatorFeedback,
        },
      })
    }
  }

  return (
    <StyledDialogModal
      sx={{ zIndex: 2500 }}
      title={i18n.text('oee.stop-logging.drawer.item.action-button.explain')}
      isOpen={isOpen}
      isLoading={false}
      onClose={handleModalClose}
      height="responsive"
    >
      {(createProductionStopLogError || updateProductionStopLogError) && (
        <Alert severity="error">{i18n.text('oee.stop-logging.drawer.item.modal.error.fetch-error')}</Alert>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ display: 'grid', rowGap: theme.spacing(2) }}>
          {machineStopReasonsLoading && <Loader height={100} />}
          {!machineStopReasonsLoading && !hasReasons && (
            <Alert severity="error">{i18n.text('oee.stop-logging.drawer.item.modal.error.no-reasons')}</Alert>
          )}
          {hasReasons && (
            <FormControl
              component="fieldset"
              sx={{ display: 'grid', rowGap: theme.spacing(1) }}
            >
              <StyledFormLabel>{`${i18n.text('oee.stop-logging.drawer.item.stop-reason')} (${i18n.text(
                'required'
              )})`}</StyledFormLabel>
              <Controller
                name="stopReason"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    defaultValue={stop.stopReason.id}
                    sx={{ gap: theme.spacing(2) }}
                  >
                    {stopReasons.map(stopReason => (
                      <StopReasonOption
                        key={stopReason.customerProductionStopReason.id}
                        id={stopReason.customerProductionStopReason.id}
                        text={stopReason.customerProductionStopReason.text}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </FormControl>
          )}
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: theme.spacing(1) }}>
            <Box sx={{ display: 'grid', gridRowGap: theme.spacing(1) }}>
              <StyledFormLabel>{i18n.text('oee.stop-logging.drawer.item.start-time')}</StyledFormLabel>
              <StyledPseudoInput>{stop.startTime}</StyledPseudoInput>
            </Box>
            <Box sx={{ display: 'grid', gridRowGap: theme.spacing(1) }}>
              <StyledFormLabel>{i18n.text('oee.stop-logging.drawer.item.duration')}</StyledFormLabel>
              <StyledPseudoInput>{formattedStopDuration}</StyledPseudoInput>
            </Box>
          </Box>
          <FormControl sx={{ display: 'grid', gridRowGap: theme.spacing(1) }}>
            <StyledFormLabel>{i18n.text('oee.stop-logging.drawer.item.modal.feedback-label')}</StyledFormLabel>
            <Controller
              name="operatorFeedback"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="textarea"
                  placeholder={i18n.text('oee.stop-logging.drawer.item.modal.feedback-placeholder')}
                  multiline
                  rows={4}
                  error={!!error}
                  helperText={error ? error.message : null}
                  size="small"
                  disabled={!hasReasons || isCreating || isUpdating}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '6px',
                      boxShadow: '0 1px 2px 0px #1018280D',
                      border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
                    },
                  }}
                />
              )}
            />
          </FormControl>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: theme.spacing(1) }}>
            <OutlinedStyledButton
              type="reset"
              onClick={handleModalClose}
            >
              {i18n.text('generic.cancel')}
            </OutlinedStyledButton>
            <Button
              sx={{ textTransform: 'none', fontWeight: 600 }}
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isCreating || isUpdating || !isValid || !hasReasons}
            >
              {!isCreating && !isUpdating ? i18n.text('generic.save') : i18n.text('generic.saving')}
            </Button>
          </Box>
        </Box>
      </Box>
    </StyledDialogModal>
  )
}

export default ExplainStopModal
