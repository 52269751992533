export const validationCases = [
    {
      key: 'password-length-valid',
      name: 'forms.submit.password-validation-8-chars',
      onValidate: (val: string) => val.length >= 8,
    },
    {
      key: 'has-lower-case',
      name: 'forms.submit.password-validation-lower-case',
      onValidate: (val: string) => val.split('').some(ch => ch >= 'a' && ch <= 'z'),
    },
    {
      key: 'has-upper-case',
      name: 'forms.submit.password-validation-upper-case',
      onValidate: (val: string) => val.split('').some(ch => ch >= 'A' && ch <= 'Z'),
    },
    {
      key: 'has-number',
      name: 'forms.submit.password-validation-number',
      onValidate: (val: string) => /\d/.test(val),
    },
    {
      key: 'has-special-character',
      name: 'forms.submit.password-validation-special-char',
      onValidate: (val: string) => /[!@#$%^&*(),.?":{}|<>]/.test(val),
    },
  ]