
import Navigation from '../components/Navigation'
import { OnboardingPath } from '../models'
import { StyledContent } from '../components/Wrapper'
import { useProgressContext } from '../context/ProgressContext'

export default function CompanyDetails() {
  const { navigateStage } = useProgressContext();

  const handleNextAction = () => {
    // check if changes are saved first
    
    navigateStage(OnboardingPath.INVITE_MEMBERS)
  }

  return (
    <>
      <StyledContent>Company Details</StyledContent>
      <Navigation nextAction={handleNextAction} />
    </>
  )
}
