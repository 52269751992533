import { ApolloError } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'

import { ID } from '../../Shared/types/types'
import {
  PdmAssetHealthReportFragment,
  PdmAssetHealthReportQuery,
  Pdm_Asset_Health_Report_Status_Enum,
  usePdmAssetHealthReportLazyQuery,
} from '../../Shared/graphql/codegen'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../Shared/contexts/ToastContext'

const useHealthReportData = (): {
  selectReportId: (id: ID | undefined) => void
  report: PdmAssetHealthReportFragment | null
  status?: Pdm_Asset_Health_Report_Status_Enum
  data?: PdmAssetHealthReportQuery
  loading: boolean
  error: ApolloError | undefined
  selectedId: ID | undefined
} => {
  const [selectedId, setSelectedId] = useState<ID | undefined>(undefined)
  const [completedReport, setCompletedReport] = useState<PdmAssetHealthReportFragment | null>(null)
  const [status, setStatus] = useState<Pdm_Asset_Health_Report_Status_Enum>()
  const { i18n } = useI18nContext()
  const { showToast } = useToastContext()

  const [getReport, { data, loading, error, startPolling, stopPolling }] = usePdmAssetHealthReportLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const selectReportId = useCallback(
    (id: ID | undefined) => {
      if (id === selectedId) {
        return
      }

      setSelectedId(id)

      if (id) {
        getReport({
          variables: {
            id,
          },
        })
      } else if (stopPolling) {
        stopPolling()
      }
    },
    [selectedId, getReport, stopPolling]
  )

  useEffect(() => {
    switch (data?.report?.status) {
      case Pdm_Asset_Health_Report_Status_Enum.Pending:
        if (startPolling) {
          setStatus(Pdm_Asset_Health_Report_Status_Enum.Pending)
          startPolling(5000)
          showToast('', 'info', i18n.text('pdm.health.report.toast.info.text'), 999999)
        }
        break
      case Pdm_Asset_Health_Report_Status_Enum.Completed:
        if (stopPolling) {
          stopPolling()
          setCompletedReport(data.report)
          setStatus(Pdm_Asset_Health_Report_Status_Enum.Completed)
        }
        break
      case Pdm_Asset_Health_Report_Status_Enum.Failed:
        if (stopPolling) {
          stopPolling()
          showToast('', 'error', i18n.text('pdm.asset.health.report.tooltip.error.text'), 3000) //i18n
          setStatus(Pdm_Asset_Health_Report_Status_Enum.Failed)
        }
        break
    }
  }, [data, startPolling, stopPolling])

  return {
    selectReportId,
    data,
    report: completedReport ?? null,
    error,
    selectedId,
    loading,
    status: status,
  }
}

export default useHealthReportData
