// Some of these functions are doubled in the Energy Prop Containers
import { DateTime } from 'luxon'
import { parse } from 'query-string'
import { pipe, propOr } from 'ramda'

import type { AnalysisType, Asset, AssetLevel0, DateRange, LocalAsset } from '../types/analysis_types'
import { DEFAULT_TIMEZONE } from '../constants/timezone'
import { getAssetColor, sortByName, sortMeasurements, themeColors } from '.'

/**
 * toggleDashboardStyles - append/remove a style tag overriding some styles for the Dashboard
 *
 * @param  {object} prop  previous date range containing start and end date as moment object
 */
export function toggleDashboardStyles(isDashboard: $TSFixMe) {
  const hasStyles = !!document.getElementById('override')
  if (isDashboard && !hasStyles) {
    const overrideElement = document.createElement('style')
    const styles = `
      #portal-wrapper { min-height: 100vh; }
      #top-nav { display: none !important }
      #graph {display: none}`
    overrideElement.id = 'override'
    overrideElement.type = 'text/css'
    overrideElement.appendChild(document.createTextNode(styles))
    document.getElementsByTagName('head')[0].appendChild(overrideElement)
  } else if (!isDashboard && hasStyles) {
    const styleElement = document.getElementById('override')
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    styleElement.parentNode.removeChild(styleElement)
  }
}

/**
 * flattenAssetsIds - takes a list of assets and returns a flat list of ID's
 * of those assets as well as their child assets recursively.
 *
 * @param  {Array} assets  An array of assets
 */
export const flattenAssetsIds = (assets: $TSFixMe) => {
  if (!Array.isArray(assets)) return undefined
  const flatAssets = assets.reduce((acc, curr) => {
    if (curr.assets && curr.assets.length > 0) {
      acc.push(curr.id, ...flattenAssetsIds(curr.assets))
    } else {
      acc.push(curr.id)
    }
    return acc
  }, [])
  return flatAssets
}

export function sortAssetList(assets: LocalAsset[]) {
  return sortByName(
    assets.map(a => ({
      id: a.id,
      name: a.name,
      items: sortByName(a.assets),
      payload: a.payload ?? {},
    }))
  )
}

export function formatAssetList(assetGroups: LocalAsset[], selectedAssets: string[][]) {
  const sortedAssetGroups = sortAssetList(assetGroups)
  const formattedGroupList = sortedAssetGroups.map(group => {
    group.items = group.items.map(machine => ({
      ...machine,
      icon: getMachineIcon(machine),
    }))

    return {
      ...group,
      icon: propOr('default', 'typeIcon', group.payload),
    }
  })

  const formattedSelected: string[][] = [...formattedGroupList.map(() => [])]

  selectedAssets.flat().forEach(selectedAssetId => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < formattedGroupList.length; i++) {
      const group = formattedGroupList[i]

      if (group.items.some(a => a.id === selectedAssetId)) {
        formattedSelected[i].push(selectedAssetId)
        break
      }
    }
  })

  return {
    assetList: formattedGroupList,
    selectedAssetsList: formattedSelected,
  }
}

export function getSubRoutes(i18n: $I18FixMe, assetCount: $TSFixMe) {
  const routes = [
    { label: i18n.text('machine-details.power'), to: '/analysis/power' },
    { label: i18n.text('graph.measurements.energy'), to: '/analysis/energy' },
    { label: i18n.text('router.sub-routes.energy-balance'), to: '/analysis/energy-balance' },
    { label: i18n.text('router.sub-routes.energy-balance'), to: '/analysis/water-balance' },
  ]
  if (assetCount.analysisType.volume > 0) {
    routes.splice(1, 0, { label: i18n.text('router.sub-routes.volume'), to: '/analysis/volume' })
  }
  return routes
}

export function getMachineCountByAnalysis(groups: $TSFixMe, analysis: AnalysisType) {
  const filteredGroups = filterAssetsByAnalysisType(groups, analysis)
  return filteredGroups.reduce((acc: $TSFixMe, current: $TSFixMe) => acc + current.assets.length, 0)
}

export function filterAssetsByAnalysisType<A>(assets: A[], analysisType: AnalysisType): A[] {
  // if commodityType is undefined, the default is 'electric-current'
  let commodityTypes
  switch (analysisType) {
    case 'power':
      commodityTypes = ['electric-current', undefined, 'electric-energy', 'natural-gas']
      break
    case 'volume':
      commodityTypes = ['natural-gas', 'water', 'steam']
      break
    case 'energy':
      commodityTypes = ['electric-current', undefined, 'natural-gas', 'electric-energy']
      break
    default:
      break
  }
  if (!commodityTypes) return assets

  return filterAssetListByCommodityTypes(assets, commodityTypes)
}

export function getMarkerShapeByCommodityType(commodityType: $TSFixMe): 'circle' | 'diamond' | 'rect' {
  switch (commodityType) {
    case 'electric-current':
    case 'electric-energy':
      return 'circle'
    case 'natural-gas':
      return 'diamond'
    case 'steam':
    case 'water':
      return 'rect'
    default:
      return 'circle'
  }
}

export function getMeasurementTypesByAnalysis(analysisType: AnalysisType) {
  switch (analysisType) {
    case 'power':
      return ['power']
    case 'volume':
      return ['volume']
    case 'energy':
      return ['energy']
    case 'energy-balance':
      return ['energy', 'volume']
    default:
      console.error(`Unknown AnalysisType: ${analysisType}`)
      return ['power']
  }
}

export function getAssetGroupIndex(assetGroups: $TSFixMe, assetId: $TSFixMe) {
  let index = assetGroups.findIndex((group: $TSFixMe) => group.assets.find((a: $TSFixMe) => a.id === assetId))
  if (index === -1) {
    // if we couldn't find the asset inside a group, the asset is a group, use index [0]
    index = 0
  }
  return index
}

export function getInitialDateRange(search: string, timeZone: string | undefined | null = DEFAULT_TIMEZONE): DateRange {
  const params = parse(search)

  if (!params.startDate || !params.endDate) {
    return getDefaultDateRange()
  }

  const _timeZone = timeZone ?? DEFAULT_TIMEZONE

  const startDate = DateTime.fromISO(params.startDate as string).setZone(_timeZone)
  const endDate = DateTime.fromISO(params.endDate as string).setZone(_timeZone)

  if (startDate.isValid && endDate.isValid) {
    return {
      startDate,
      endDate,
    }
  }

  return getDefaultDateRange()
}

export function formatConsumption(i18n: $I18FixMe, consumption: $TSFixMe[] | null | undefined) {
  return consumption
    ? sortMeasurements([...consumption]).map(measurement => ({
        ...measurement,
        color: themeColors.primary,
        name: i18n.text('analysis.total-consumption'),
      }))
    : []
}

/**
 * Tries to snap the range to nice a interval (month/week/day/hour) if
 * the start and end dates are close enough. Returns a DateRange object
 * if the snap succeeded, else returns null.
 * @param {DateTime} startDate
 * @param {DateTime} endDate
 * @param {string} direction
 * @returns {object | null}
 */
export function trySnapToInterval(
  startDate: DateTime,
  endDate: DateTime,
  direction: 'forwards' | 'backwards'
): DateRange | null {
  const delta = direction === 'forwards' ? 1 : -1

  if (checkIsMonthPeriod(startDate, endDate)) {
    const newStartDate = startDate.plus({ months: delta })
    const newEndDate = newStartDate.endOf('month')
    return {
      startDate: newStartDate,
      endDate: newEndDate,
    }
  }
  if (checkIsWeekPeriod(startDate, endDate)) {
    const newStartDate = startDate.plus({ weeks: delta })
    const newEndDate = newStartDate.endOf('week')
    return {
      startDate: newStartDate,
      endDate: newEndDate,
    }
  }
  if (checkIsDayPeriod(startDate, endDate)) {
    const newStartDate = startDate.plus({ days: delta })
    const newEndDate = newStartDate.endOf('day')
    return {
      startDate: newStartDate,
      endDate: newEndDate,
    }
  }
  if (checkIsHourPeriod(startDate, endDate)) {
    const newStartDate = startDate.plus({ hours: delta })
    const newEndDate = newStartDate.endOf('hour')
    return {
      startDate: newStartDate,
      endDate: newEndDate,
    }
  }
  return null
}

export function filterAssetListByCommodityTypes(assetGroups: $TSFixMe, commodityTypes: $TSFixMe) {
  if (!Array.isArray(commodityTypes)) return undefined
  const result: $TSFixMe = []

  assetGroups.forEach((group: $TSFixMe) => {
    const matchedAssets = group.assets.filter(
      (asset: $TSFixMe) => asset.payload && commodityTypes.includes(asset.payload.commodityType)
    )
    if (matchedAssets.length > 0) {
      result.push({
        ...group,
        assets: matchedAssets,
      })
    }
  })
  return result
}

function checkIsMonthPeriod(startDate: DateTime, endDate: DateTime) {
  const monthStart = startDate.startOf('month')
  const deltaStart = startDate.diff(monthStart, 'minutes')
  const monthEnd = endDate.endOf('month')
  const deltaEnd = endDate.diff(monthEnd, 'minutes')
  return Math.abs(deltaStart.toMillis()) + Math.abs(deltaEnd.toMillis()) < 2 && startDate.month === endDate.month
}

function checkIsWeekPeriod(startDate: DateTime, endDate: DateTime) {
  const weekStart = startDate.startOf('week')
  const deltaStart = startDate.diff(weekStart, 'hours')
  const weekEnd = endDate.endOf('week')
  const deltaEnd = endDate.diff(weekEnd, 'hours')
  // NOTE: we consider a delta of less than 2 hours to be a week to account for DST
  return (
    Math.abs(deltaStart.toMillis()) + Math.abs(deltaEnd.toMillis()) < 2 && startDate.weekNumber === endDate.weekNumber
  )
}

function checkIsDayPeriod(startDate: DateTime, endDate: DateTime) {
  const dayStart = startDate.startOf('day')
  const deltaStart = startDate.diff(dayStart, 'minutes')
  const dayEnd = endDate.endOf('day')
  const deltaEnd = endDate.diff(dayEnd, 'minutes')
  // NOTE: we consider a delta of less than 70 minutes to be a day to account for DST
  return Math.abs(deltaStart.toMillis()) + Math.abs(deltaEnd.toMillis()) < 70 && startDate.day === endDate.day
}

function checkIsHourPeriod(startDate: DateTime, endDate: DateTime) {
  const hourStart = startDate.startOf('hour')
  const deltaStart = startDate.diff(hourStart, 'minutes')
  const hourEnd = hourStart.plus({ hours: 1 })
  const endDelta = endDate.diff(hourEnd, 'minutes')
  return Math.abs(deltaStart.toMillis()) <= 5 && Math.abs(endDelta.toMillis()) <= 5
}

export function getDefaultDateRange() {
  const _startDate = DateTime.now().startOf('week')

  return {
    startDate: _startDate,
    endDate: _startDate.endOf('week').endOf('day'),
  }
}

const getMachineIcon = (machine: LocalAsset) => {
  const commodityType = machine.payload?.commodityType
  switch (commodityType) {
    case 'natural-gas':
      return 'info-diamond'
    case 'water':
    case 'steam':
      return 'info-square'
    default:
      return 'info-outline'
  }
}

const assetSortFn = <T extends { id: string }>({ id: a }: T, { id: b }: T) => {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}
const sortAssets = (all: AssetLevel0[]): AssetLevel0[] =>
  all.sort(assetSortFn).map(asset => ({
    ...asset,
    assets: [...asset.assets].sort(assetSortFn),
  }))

const assignColorToAssets = (assets: Asset[]): LocalAsset[] => {
  let counter = 0

  const recursive = (assets: ReadonlyArray<Asset>): LocalAsset[] => {
    return assets.map(one => {
      const color = getAssetColor(counter++)
      let subassets = [] as LocalAsset[]
      if ('assets' in one) {
        subassets = recursive(one.assets)
      }
      return {
        ...one,
        color,
        assets: subassets,
      }
    })
  }

  return recursive(assets)
}

const assignMarkerShapeToAssets = (assets: LocalAsset[]): LocalAsset[] =>
  assets.map(asset => {
    if (asset.assets.length) {
      asset.assets = assignMarkerShapeToAssets(asset.assets)
    }
    if (asset.payload == null) {
      return asset
    }
    asset.markerShape = getMarkerShapeByCommodityType(asset.payload.commodityType)
    return asset
  })

export const prepareAssets = pipe(sortAssets, assignColorToAssets, assignMarkerShapeToAssets)

export const processAssetTree = (rawGroups: readonly AssetLevel0[]): LocalAsset[] => {
  const groups: AssetLevel0[] = []

  rawGroups.forEach(g => {
    const machines = g.assets.filter(machine => {
      const sensorCount = [...machine.sensors, ...machine.assets.flatMap(subAsset => subAsset.sensors)].length

      return sensorCount > 0
    })

    const group: AssetLevel0 = {
      ...g,
      assets: machines,
    }

    if (group.assets.length > 0) {
      groups.push(group)
    }
  })

  return prepareAssets(groups)
}

export function getFirstRelevantAssetId(assets: LocalAsset[], analysisType: AnalysisType, isGroup = false) {
  const relevantAssets = filterAssetsByAnalysisType(assets, analysisType)
  const sortedAssets = sortAssetList(relevantAssets)
  const firstRelevantAssetId = isGroup ? sortedAssets[0]?.id : sortedAssets[0]?.items[0]?.id
  return firstRelevantAssetId
}
