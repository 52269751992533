import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import React, { useEffect, useMemo, useState } from 'react'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Slide,
  Snackbar,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import { DateTime, Interval } from 'luxon'
import { useParams } from 'react-router'

import LoadingBar from '../../../Shared/components/MUIComponents/LoaderBar'
import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import { AccelerationGraph } from '../../components/Charts/AccelerationGraph'
import {
  AnnotationDataFragment,
  AnnotationTagType_Enum,
  useAnnotationsLazyQuery,
  useTagsQuery,
} from '../../../Shared/graphql/codegen'
import { ApexWrapper } from '../../components/ApexWrapper'
import { StyledCheckbox as Checkbox } from '../../../Shared/components/MUIComponents/update/Checkbox/StyledCheckBox'
import { ConfigureThresholdAlertDialog } from './ConfigureThresholdAlertDialog/ConfigureThresholdAlertDialog'
import { DateInterval, VibrationResolution } from './types'
import { EditAnnotationForm } from './EditAnnotationForm/EditAnnotationForm'
import { Error } from '../../../Shared/components/MUIComponents/Error'
import { ISODateTime, UrlParams } from '../../../Shared/types/types'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { VelocityGraph } from '../../components/Charts/VelocityGraph'
import { filterAnnotations, formatAPIDate, parseAnnotations } from './utils'
import { sendShowAnnotationsEventPDM } from '../../utils/analyticsEvents'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { usePredictiveMaintenanceContext } from '../../context/PredictiveMaintenanceContext'

const VelocityGraphMemo = React.memo(VelocityGraph)
const AccelerationGraphMemo = React.memo(AccelerationGraph)
const ConfigureThresholdAlertDialogMemo = React.memo(ConfigureThresholdAlertDialog)

const Container = styled(Box)(({ theme }) => ({
  marginBottom: '100px',
  padding: '0 1rem',
}))

const ToolBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  height: '3rem',
  maxHeight: '3rem',
  justifyContent: 'space-between',
  marginBottom: '1rem',
  padding: '0.5rem',
  '& *:last-child': {
    marginRight: 0,
  },
}))

const InternalUserTools = styled(ToolBox)(({ theme }) => ({
  justifyContent: 'flex-start',
  background: theme.palette.SFIGreyLight[100],
}))

let isFirstRender = true

const GraphsPage = () => {
  const {
    setQuery,
    getGraphData,
    data,
    dataLoading,
    dataError,
    from,
    to,
    zoomMin,
    zoomMax,
    intervalError,
    thresholdsConfig,
    thresholds,
    thresholdsUpdatedAt,
    setThresholdsUpdatedAt,
  } = usePredictiveMaintenanceContext()
  const { isInternalUser, getHasuraRoleHeader } = useCurrentUser()
  const { sendEvent } = useAnalytics()
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { machineId, probeId } = useParams<UrlParams>()
  const [thresholdsDialogOpen, setThresholdsDialogOpen] = useState<boolean>(false)
  const [showingAnnotations, toggleAnnotations] = useState(true)
  const [annotationFormOpen, setAnnotationFormOpen] = useState(false)
  const [selectedAnnotationId, setSelectedAnnotationId] = useState<string | null>(null)
  const [annotationsFilters, setAnnotationsFilters] = useState<string[]>([
    AnnotationTagType_Enum.Fault,
    AnnotationTagType_Enum.Internal,
    AnnotationTagType_Enum.Maintenance,
    AnnotationTagType_Enum.ProcessChange,
  ])

  const windowChangeDays = Interval.fromDateTimes(DateTime.fromMillis(zoomMin), DateTime.fromMillis(zoomMax)).length(
    'days'
  )
  const resolution: VibrationResolution =
    windowChangeDays > DateInterval.ThreeMonths ? '3h' : windowChangeDays > DateInterval.TwoMonths ? '1h' : '0h'

  // To avoid zooming window to be refetched there needs to be a second useEffect for this component to refetch the graph data on every resolution change.
  useEffect(() => {
    getGraphData({
      variables: {
        probeId,
        from: formatAPIDate(from),
        to: formatAPIDate(to),
        resolution: resolution,
      },
    })
  }, [resolution])

  const handleFiltersChange = (e: React.MouseEvent<HTMLElement>, filters: string[]) => {
    setAnnotationsFilters(filters)
  }

  const onThresholdsDialogClosed = (thresholdsUpdated: boolean) => {
    setThresholdsDialogOpen(false)

    if (thresholdsUpdated) {
      setThresholdsUpdatedAt(DateTime.now())
    }
  }

  const handleZoomChange = useMemo(
    () => (chart: unknown, changed: { xaxis: { min: number; max: number } }) => {
      const {
        xaxis: { min, max },
      } = changed
      // Ensure we're not zooming beyond the overall data range
      const newMin = Math.max(min, from)
      const newMax = Math.min(max, to)
      if (newMin !== zoomMin || newMax !== zoomMax) {
        setQuery({ zoomXMin: newMin, zoomXMax: newMax })
      }
    },
    [setQuery, zoomMin, zoomMax, from, to]
  )
  const [getAnnotations, { data: annotationsData, loading: annotationsLoading, error: annotationsError }] =
    useAnnotationsLazyQuery({
      fetchPolicy: 'network-only',
      context: {
        headers: {
          'x-hasura-role': getHasuraRoleHeader(),
        },
      },
    })

  const handleAnnotationMutationCompleted = () => {
    setSelectedAnnotationId(null)
    getAnnotations({
      variables: {
        start: DateTime.fromMillis(from).toISO() as ISODateTime,
        end: DateTime.fromMillis(to).toISO() as ISODateTime,
        assetId: machineId,
      },
    })
  }

  const {
    data: tagsData,
    loading: tagsLoading,
    error: tagsError,
  } = useTagsQuery({
    context: {
      headers: {
        'x-hasura-role': getHasuraRoleHeader(),
      },
    },
  })

  const loading = dataLoading || tagsLoading || annotationsLoading
  const error = dataError || tagsError || annotationsError

  const tags = useMemo(() => (tagsData?.tags ? tagsData.tags.map((t: { value: string }) => t.value) : []), [tagsData])

  useEffect(() => {
    const bufferPeriod = { weeks: 1 }
    const bufferedZoomMin = DateTime.fromMillis(zoomMin).minus(bufferPeriod)
    const bufferedZoomMax = DateTime.fromMillis(zoomMax).plus(bufferPeriod)

    const windowChangeDays = Interval.fromDateTimes(DateTime.fromMillis(zoomMin), DateTime.fromMillis(zoomMax)).length(
      'days'
    )
    const newResolution = windowChangeDays > 90 ? '3h' : windowChangeDays > 62 ? '1h' : '0h'
    getGraphData({
      variables: {
        probeId,
        from: formatAPIDate(bufferedZoomMin.toMillis()),
        to: formatAPIDate(bufferedZoomMax.toMillis()),
        resolution: newResolution,
      },
    })
    getAnnotations({
      variables: {
        start: bufferedZoomMin.toISO() as ISODateTime,
        end: bufferedZoomMax.toISO() as ISODateTime,
        assetId: machineId,
      },
    })
  }, [zoomMin, zoomMax, probeId, machineId, getGraphData, getAnnotations])

  useEffect(() => {
    const resetZoom = (min: number, max: number) => setQuery({ zoomXMin: min, zoomXMax: max })

    if (isFirstRender) {
      isFirstRender = false
    } else {
      resetZoom(from, to)
    }
  }, [from, to, setQuery])

  const handleFormClose = () => {
    setSelectedAnnotationId(null)
    setAnnotationFormOpen(false)
  }

  const annotations = useMemo(() => annotationsData?.annotations || [], [annotationsData])

  const filteredAnnotations = useMemo(
    () => filterAnnotations(annotations as AnnotationDataFragment[], annotationsFilters as AnnotationTagType_Enum[]),
    [annotations, annotationsFilters]
  )

  const chartXAnnotations = useMemo(() => {
    const handleChartAnnotationClick = (id: string) => {
      setSelectedAnnotationId(id)
    }

    return parseAnnotations({
      annotations: filteredAnnotations,
      onLabelClick: handleChartAnnotationClick,
      selectedAnnotationId,
    })
  }, [filteredAnnotations, selectedAnnotationId])

  const visibleAnnotations = useMemo(
    () => (showingAnnotations ? chartXAnnotations : []),
    [showingAnnotations, chartXAnnotations]
  )

  const { velocityRms, accelerationRms } = data?.probe?.insights?.timeWindow || {
    velocityRms: [],
    accelerationRms: [],
  }

  const segmentVelocity = useMemo(
    () =>
      velocityRms.filter(v => {
        const timestamp = DateTime.fromISO(v.time)
        const min = DateTime.fromMillis(zoomMin).minus({ weeks: 1 })
        const max = DateTime.fromMillis(zoomMax).plus({ weeks: 1 })
        return timestamp > min && timestamp < max
      }),
    [zoomMin, zoomMax, velocityRms]
  )

  const segmentAcceleration = useMemo(
    () =>
      accelerationRms.filter(a => {
        const timestamp = DateTime.fromISO(a.time)
        const min = DateTime.fromMillis(zoomMin).minus({ weeks: 1 })
        const max = DateTime.fromMillis(zoomMax).plus({ weeks: 1 })
        return timestamp > min && timestamp < max
      }),
    [zoomMin, zoomMax, accelerationRms]
  )

  const velocitySeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: 'x',
        data: segmentVelocity.map(t => ({ x: t.time, y: t.x.toFixed(3) })),
      },
      {
        name: 'y',
        data: segmentVelocity.map(t => ({ x: t.time, y: t.y.toFixed(3) })),
      },
      {
        name: 'z',
        data: segmentVelocity.map(t => ({ x: t.time, y: t.z.toFixed(3) })),
      },
      {
        name: 'temperature',
        data: segmentVelocity.map(t => ({ x: t.time, y: t.temperature ? t.temperature.toFixed(3) : 0 })),
      },
    ],
    [segmentVelocity]
  )
  const accelerationSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: 'x',
        data: segmentAcceleration.map(t => ({ x: t.time, y: t.x.toFixed(3) })),
      },
      {
        name: 'y',
        data: segmentAcceleration.map(t => ({ x: t.time, y: t.y.toFixed(3) })),
      },
      {
        name: 'z',
        data: segmentAcceleration.map(t => ({ x: t.time, y: t.z.toFixed(3) })),
      },
      {
        name: 'temperature',
        data: segmentVelocity.map(t => ({ x: t.time, y: t.temperature ? t.temperature.toFixed(3) : 0 })),
      },
    ],
    [segmentAcceleration]
  )

  const getSelectedAnnotation = () => {
    if (!selectedAnnotationId) return null
    const [a] = annotations.filter(existing => existing.id === selectedAnnotationId)
    return a
  }

  const getDescription = (id?: string | null) => {
    if (!id) return
    const [a] = filteredAnnotations.filter(ant => ant.id === id)
    if (!a) return
    return a.description || i18n.text('pdm.annotations.labels.no-description')
  }

  const zoom = useMemo(() => ({ min: zoomMin, max: zoomMax }), [zoomMin, zoomMax])

  return (
    <Container minHeight="80vh">
      {isInternalUser() && (
        <InternalUserTools>
          <Typography
            variant="subtitle1"
            sx={theme => ({
              color: theme.palette.SFIGreyLight[700],
              fontWeight: 400,
              marginRight: '1rem',
            })}
          >
            Internal tools
          </Typography>
          {!error && thresholdsConfig?.state !== 'invalid' && (
            <Button
              variant="outlined"
              onClick={() => setThresholdsDialogOpen(true)}
              sx={() => ({
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '0.9rem',
                marginRight: '1em',
              })}
            >
              {thresholdsConfig?.state === 'valid'
                ? i18n.text('pdm.edit-threshold-alert-rule')
                : i18n.text('pdm.create-threshold-alert-rule')}
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={() => setAnnotationFormOpen(true)}
            sx={() => ({
              textTransform: 'none',
              fontWeight: 400,
              fontSize: '0.9rem',
              marginRight: '1em',
            })}
          >
            {`${selectedAnnotationId ? 'Edit' : 'Create'} annotation`}
          </Button>
        </InternalUserTools>
      )}
      <ToolBox>
        {loading && (
          <Box
            data-testid="testingbox"
            sx={{ position: 'absolute', width: '98%', bottom: theme.spacing(0) }}
          >
            <LoadingBar loading={true} />
          </Box>
        )}
        {!loading && (
          <>
            <FormControl sx={{ flexDirection: 'row', flexWrap: 'wrap', maxWidth: '100%' }}>
              <FormControlLabel
                componentsProps={{
                  typography: {
                    variant: 'subtitle1',
                  },
                }}
                label={i18n.text('pdm.graphs.show-annotations')}
                control={
                  <Checkbox
                    checked={showingAnnotations}
                    onChange={e => {
                      toggleAnnotations(e.target.checked)
                      sendShowAnnotationsEventPDM(sendEvent)
                    }}
                  />
                }
              />
              {showingAnnotations && (
                <ToggleButtonGroup
                  color="secondary"
                  size="small"
                  sx={{ flexWrap: 'wrap' }}
                  value={annotationsFilters}
                  onChange={handleFiltersChange}
                >
                  {tags.map((tag: string) => (
                    <ToggleButton
                      key={tag}
                      value={tag}
                      sx={{
                        textTransform: 'none',
                        fontWeight: 400,
                        fontSize: '0.9rem',
                        padding: '5px 15px',
                      }}
                    >
                      {i18n.text(`pdm.annotations.tags.value.${tag.toLowerCase()}`)}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              )}
            </FormControl>
          </>
        )}
      </ToolBox>

      {error ? (
        <Error />
      ) : (
        <>
          <SectionBoxWrapper>
            <ApexWrapper>
              <VelocityGraphMemo
                series={velocitySeries}
                thresholds={thresholds}
                loading={loading}
                group="charts"
                handleZoomChange={handleZoomChange}
                zoom={zoom}
                annotations={visibleAnnotations}
              />
            </ApexWrapper>
          </SectionBoxWrapper>
          <Collapse in={!!selectedAnnotationId}>
            <Alert severity="info">{getDescription(selectedAnnotationId)}</Alert>
          </Collapse>
          <Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
          <Collapse in={annotationFormOpen}>
            <EditAnnotationForm
              assetId={machineId}
              onClose={handleFormClose}
              initialValue={selectedAnnotationId ? getSelectedAnnotation() : null}
              handleMutationCompleted={handleAnnotationMutationCompleted}
            />
            <Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
          </Collapse>
          <SectionBoxWrapper>
            <ApexWrapper>
              <AccelerationGraphMemo
                series={accelerationSeries}
                group="charts"
                handleZoomChange={handleZoomChange}
                zoom={zoom}
                loading={loading}
                annotations={visibleAnnotations}
              />
            </ApexWrapper>
          </SectionBoxWrapper>
          {resolution !== '0h' && (
            <Typography padding={'1rem'}>{i18n.text('pdm.graphs.resolution-informal-message')}</Typography>
          )}
        </>
      )}

      <ConfigureThresholdAlertDialogMemo
        assetId={probeId}
        rule={thresholdsConfig?.state == 'valid' ? thresholdsConfig?.alertRule : null}
        open={thresholdsDialogOpen}
        onClose={onThresholdsDialogClosed}
      />
      <Snackbar
        open={intervalError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={props => (
          <Slide
            {...props}
            direction="left"
          />
        )}
        transitionDuration={500}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {i18n.text('pdm.timepicker.max_interval')}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default GraphsPage
