import { FC } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { AssetTreeChildNode } from '../../../Shared/components/MUIComponents/update/AssetTree/StyledAssetTreeChildNode'

type AssetTreeLinkProps = {
  label: string
  id: string
  onClick: () => void
}

const AssetTreeLink: FC<AssetTreeLinkProps> = ({ label, id, onClick }) => {
  const location = useLocation()
  const history = useHistory()

  const route = `/oee/machine/${id}`

  const isSelected = location.pathname === route

  const handleMachineClick = () => {
    history.push(route)
    onClick()
  }

  return (
    <AssetTreeChildNode
      isSelected={isSelected}
      item={{ id, name: label }}
      handleClick={handleMachineClick}
    />
  )
}

export default AssetTreeLink
