import Dropdown from '@sensorfactdev/nucleus/Dropdown'
import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Add, ExpandLess, ExpandMore, Remove } from '@mui/icons-material'
import { Button, Tooltip, styled as muiStyled } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import { DropdownOption } from '../../types/types'
import { Interval as IntervalType } from './types'
import { getDayName, hourOptions, mergeTime, minuteOptions, parseDay, parseTime } from './utils'

const ActionButton = muiStyled(Button)(() => ({
  margin: 0,
  padding: '0.5em',
  minWidth: 'auto',
}))

const OrderingBox = muiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const StyledDropdown = styled(Dropdown)`
  background-color: white;
  margin: 0 10px 0 10px;
`

const FormSectionInner = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1em;
`

const Group = styled.div``

const GroupPrefix = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  margin: 0 1em;
  min-width: 2em;
`

const TimeSeparator = styled.span`
  display: inline-flex;
  align-items: baseline;
`

const Spacer = styled.div`
  flex-grow: 1;
`

const ActionsBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`

const dayFromDropdown = (option: string): Day => {
  const d = +option
  return d as Day
}

interface IntervalProps {
  availableDayOptions?: number[]
  changeOrderDisabled?: boolean
  disabled?: boolean
  i18n?: $TSFixMe
  index: number
  interval: IntervalType
  onChange?: (index: number, selected: IntervalType | undefined) => void
  onChangeOrder?: (index: number, direction: -1 | 1) => void
  onClone?: (index: number) => void
  removeDisabled?: boolean
}

export type { IntervalType }

type FormData = {
  fromDay: Day
  fromHour: string
  fromMinute: string
  toDay: Day
  toHour: string
  toMinute: string
}

/** @deprecated do not use */
const Interval = ({
  availableDayOptions = [1, 2, 3, 4, 5, 6, 0],
  changeOrderDisabled = false,
  disabled = false,
  i18n = { text: (t: string) => t },
  index,
  interval,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChangeOrder = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClone = () => {},
  removeDisabled = false,
}: IntervalProps): JSX.Element => {
  moment.locale(i18n.locale.replace('_', '-'))
  const dayOptions: DropdownOption[] = availableDayOptions.map(o => ({
    option: moment.weekdays()[o],
    value: o,
    key: o,
  }))

  const { from, to } = interval
  const [fromHour, fromMinute] = parseTime(from.time)
  const fromDay = parseDay(from.day)
  const [toHour, toMinute] = parseTime(to.time)
  const toDay = parseDay(to.day)

  const { getValues, setValue, control } = useForm<FormData>()

  const _onChange = () => {
    const values = getValues()
    const newInterval = {
      from: {
        day: getDayName(values.fromDay),
        time: mergeTime(values.fromHour, values.fromMinute),
      },
      to: {
        day: getDayName(values.toDay),
        time: mergeTime(values.toHour, values.toMinute),
      },
    }

    onChange(index, newInterval)
  }

  return (
    <FormSectionInner onChange={_onChange}>
      <OrderingBox>
        <Tooltip title={i18n.text('rules.button.move-up')}>
          <span>
            <ActionButton
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                onChangeOrder(index, -1)
              }}
              disabled={disabled || changeOrderDisabled}
            >
              <ExpandLess fontSize="small" />
            </ActionButton>
          </span>
        </Tooltip>
        <Tooltip title={i18n.text('rules.button.move-down')}>
          <span>
            <ActionButton
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                onChangeOrder(index, 1)
              }}
              disabled={disabled || changeOrderDisabled}
            >
              <ExpandMore fontSize="small" />
            </ActionButton>
          </span>
        </Tooltip>
      </OrderingBox>
      <GroupPrefix>{i18n.text('rules.time.start')}</GroupPrefix>
      <Group>
        <Controller
          control={control}
          name="fromDay"
          defaultValue={fromDay as Day}
          render={() => (
            <StyledDropdown
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('fromDay', dayFromDropdown(e.target.value))}
              name="fromDay"
              options={dayOptions}
              selected={fromDay}
              disabled={disabled}
            />
          )}
        />
      </Group>
      <Group>
        <Controller
          control={control}
          name="fromHour"
          defaultValue={fromHour}
          render={() => (
            <StyledDropdown
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue('fromHour', e.target.value)}
              name="fromHour"
              options={hourOptions.map((o): DropdownOption => ({ option: o, value: o, key: o }))}
              selected={fromHour}
              disabled={disabled}
            />
          )}
        />
      </Group>
      <TimeSeparator>:</TimeSeparator>
      <Group>
        <Controller
          control={control}
          name="fromMinute"
          defaultValue={fromMinute}
          render={() => (
            <StyledDropdown
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue('fromMinute', e.target.value)}
              name="fromMinute"
              options={minuteOptions.map((o): DropdownOption => ({ option: o, value: o, key: o }))}
              selected={fromMinute}
              disabled={disabled}
            />
          )}
        />
      </Group>
      <GroupPrefix>{i18n.text('rules.time.stop')}</GroupPrefix>
      <Group>
        <Controller
          control={control}
          name="toDay"
          defaultValue={toDay as Day}
          render={() => (
            <StyledDropdown
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue('toDay', dayFromDropdown(e.target.value))}
              name="toDay"
              options={dayOptions}
              selected={toDay}
              disabled={disabled}
            />
          )}
        />
      </Group>
      <Group>
        <Controller
          control={control}
          name="toHour"
          defaultValue={toHour}
          render={() => (
            <StyledDropdown
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue('toHour', e.target.value)}
              name="toHour"
              options={hourOptions.map((o): DropdownOption => ({ option: o, value: o, key: o }))}
              selected={toHour}
              disabled={disabled}
            />
          )}
        />
      </Group>
      <TimeSeparator>:</TimeSeparator>
      <Group>
        <Controller
          control={control}
          name="toMinute"
          defaultValue={toMinute}
          render={() => (
            <StyledDropdown
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue('toMinute', e.target.value)}
              name="toMinute"
              options={minuteOptions.map((o): DropdownOption => ({ option: o, value: o, key: o }))}
              selected={toMinute}
              disabled={disabled}
            />
          )}
        />
      </Group>
      <Spacer />
      <ActionsBar>
        <Tooltip title={i18n.text('rules.button.duplicate')}>
          <span>
            <ActionButton
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                onClone(index)
              }}
              disabled={disabled}
            >
              <Add fontSize="small" />
            </ActionButton>
          </span>
        </Tooltip>
        <Tooltip title={i18n.text('rules.button.remove')}>
          <span>
            <ActionButton
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                onChange(index, undefined)
              }}
              disabled={disabled || removeDisabled}
            >
              <Remove fontSize="small" />
            </ActionButton>
          </span>
        </Tooltip>
      </ActionsBar>
    </FormSectionInner>
  )
}

export default Interval
