import { CompressedAirDataMap, ISeriesData, PreviousState, SeriesPoints } from '../types/compressed-air_types'
import { NEGATIVE_MIN_VALUE, getPreviousName, sortByAlphabeticalOrder, transformToCFMIfCondition } from './utils'
import { SwitchUnit } from '../types/compressed-air_types'

export const mapSeriesData = (
  dataMap: CompressedAirDataMap,
  sensorLocationColors: {
    [key: string]: string
  },
  switchUnit: SwitchUnit,
  i18n: $I18FixMe,
  previousState: PreviousState,
  pointsType?: SeriesPoints
) => {
  if (!dataMap) {
    return []
  }
  const type = pointsType ?? SeriesPoints.FLOW
  const transformToCFM = transformToCFMIfCondition(switchUnit.includes('cf'))
  const getTransformedValue = (val: number | null) =>
    (val && val < 0) || !val ? transformToCFM(0) : transformToCFM(val)
  const resultArray = [...dataMap.entries()].map(([id, obj]) => {
    return {
      id: id,
      name: getPreviousName(obj.name, previousState, i18n),
      color: sensorLocationColors[id],
      data: obj[type].reduce<ISeriesData['data']>((acc, point) => {
        const getFormattedValue = (val: number | null) =>
          val !== null ? (val > NEGATIVE_MIN_VALUE && val <= 0 ? 0 : val < NEGATIVE_MIN_VALUE ? null : val) : null
        acc.push({
          time: point.previousTime
            ? point.previousTime.toISO({ includeOffset: false })
            : point.time.toISO({ includeOffset: false }),
          value: getFormattedValue(point.value) !== null ? getTransformedValue(point.value) : null,
          min: getFormattedValue(point.min) !== null ? getTransformedValue(point.min) : null,
          max: getFormattedValue(point.max) !== null ? getTransformedValue(point.max) : null,
        })
        return acc
      }, []),
    }
  })
  return sortByAlphabeticalOrder(resultArray)
}

export const mapConsumptionTimeSeriesData = (
  dataMap: CompressedAirDataMap,
  previousState: PreviousState,
  i18n: $I18FixMe
) => {
  if (!dataMap) {
    return []
  }
  const resultArray = [...dataMap.entries()].map(([id, obj]) => {
    return {
      id: id,
      name: getPreviousName(obj.name, previousState, i18n),
      data: obj.consumptionPoints.map(point => {
        return {
          value: point.value,
          time: point.previousTime
            ? point.previousTime.toISO({ includeOffset: false })
            : point.time.toISO({ includeOffset: false }),
        }
      }),
    }
  })

  return resultArray
}
