import { AutocompleteChangeReason } from '@mui/material/Autocomplete'
import { Box, Divider } from '@mui/material'
import { DateTime } from 'luxon'
import { SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import useAnalytics from '../../../../Shared/hooks/useAnalytics/useAnalytics'
import { AnalysisTypeSelect } from './ModuleSettingsInputs/AnalysisTypeSelect'
import { AnalyticsIntent } from '../../../../Shared/hooks/useAnalytics/analyticsTypes'
import { AssetGroupingSelect } from './ModuleSettingsInputs/AssetGroupingSelect'
import { AssetsProps, ModuleParams, ModuleProps, VisualisationDataProps, VisualisationUpsertDataProps } from './types'
import { AssetsSelect } from './ModuleSettingsInputs/AssetsSelect'
import { DaysOfWeekSelect } from './ModuleSettingsInputs/DaysOfWeekSelect'
import { DescriptionSwitch } from './ModuleSettingsInputs/DescriptionSwitch'
import { ISODateTime } from '../../../../Shared/types/types'
import { ModuleTitleInput } from './ModuleSettingsInputs/ModuleTitleInput'
import { ReferenceSwitch } from './ModuleSettingsInputs/ReferenceSwitch'
import { SaveCancelButtons } from './ModuleSettingsInputs/SaveCancelButtons'
import { StyledInputFieldHeader, StyledTooltipWithIcon } from './Common'
import { TimeGroupingSelect } from './ModuleSettingsInputs/TimeGroupingSelect'
import { VisualisationList } from './VisualisationList'
import { getAllIndexes, getNumBars } from './utils'
import { getAssetsInput, getFilteredAssets } from './utils'
import { trackCancelEditModuleEvent, trackSaveModuleEvent } from './trackingEvents'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import {
  useCustomerAssetProductionSchedulesQuery,
  useCustomerDailyShiftSchedulesQuery,
  useCustomerElectricityAssetStandbyQuery,
  useCustomerProtocolAssetsQuery,
  useDeleteTrackingReportVisualisationMutation,
  useGetAnalysisModuleByIdQuery,
  useGetReportByIdQuery,
  useInsertAnalysisModuleMutation,
  useInsertTrackingReportVisualisationsMutation,
  useTrSupportedAssetsQuery,
  useUpdateModuleSettingsMutation,
} from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../Shared/contexts/ToastContext'

const emptyVisualisation: VisualisationDataProps = {
  visualisationType: '',
  unit: '',
  position: 0,
}

export const ModuleConfiguration = () => {
  const MAX_NUM_BARS = 35
  const { i18n } = useI18nContext()
  const { showToast } = useToastContext()
  const history = useHistory()
  const { sendEvent } = useAnalytics()
  const { reportId, moduleId } = useParams<ModuleParams>()
  const { customerId } = useCurrentUser()

  const { data: assetsData } = useTrSupportedAssetsQuery()
  const assets = assetsData?.myOrg?.assets
  const { data: shiftSchedulesData } = useCustomerDailyShiftSchedulesQuery({ variables: { customerId: customerId } })

  const { data: assetsProtocolsData } = useCustomerProtocolAssetsQuery()

  const { data: moduleData, refetch: refetchModule } = useGetAnalysisModuleByIdQuery({
    variables: { reportId: reportId, moduleId: moduleId },
  })
  const { data: reportData, refetch: refetchModules } = useGetReportByIdQuery({ variables: { id: reportId } })

  const assetIds = assets
    ? assets?.map(asset => {
        return asset.value
      })
    : []

  const { data: assetsWithStandbyData } = useCustomerElectricityAssetStandbyQuery({ variables: { assetIds: assetIds } })
  const { data: assetsProductionSchedulesData } = useCustomerAssetProductionSchedulesQuery({
    variables: {
      assetIds: assetIds,
    },
  })

  const numModules = reportData && reportData.TrackingReportReportConfigById?.analysisModules.length
  const frequency = reportData?.TrackingReportReportConfigById?.frequency

  const [updateModuleSettingsMutation] = useUpdateModuleSettingsMutation({
    onCompleted: async response => {
      showToast(
        i18n.text(`reports.tracking.configuration.module.updatedSuccess`),
        'success',
        i18n.text(`reports.tracking.configuration.module.success`)
      )
    },
    onError: async response =>
      showToast(
        i18n.text(`reports.tracking.configuration.module.savedFail`),
        'error',
        i18n.text(`reports.tracking.configuration.module.error`)
      ),
  })

  const [insertTrackingReportVisualisationsMutation] = useInsertTrackingReportVisualisationsMutation({
    onCompleted: async response => {
      showToast(
        i18n.text(`reports.tracking.configuration.module.updatedSuccess`),
        'success',
        i18n.text(`reports.tracking.configuration.module.success`)
      )
    },
    onError: async response =>
      showToast(
        i18n.text(`reports.tracking.configuration.module.savedFail`),
        'error',
        i18n.text(`reports.tracking.configuration.module.error`)
      ),
  })

  const [deleteVisualisationMutation] = useDeleteTrackingReportVisualisationMutation({
    onCompleted: async response => {
      showToast(
        i18n.text(`reports.tracking.configuration.module.deletedSuccess`),
        'success',
        i18n.text(`reports.tracking.configuration.module.success`)
      )
    },
    onError: async response =>
      showToast(
        i18n.text(`reports.tracking.configuration.module.deletedFail`),
        'error',
        i18n.text(`reports.tracking.configuration.module.error`)
      ),
  })

  const [InsertAnalysisModuleMutation] = useInsertAnalysisModuleMutation({
    onCompleted: async response => {
      showToast(
        i18n.text(`reports.tracking.configuration.module.savedSuccess`),
        'success',
        i18n.text(`reports.tracking.configuration.module.success`)
      )
    },
    onError: async response =>
      showToast(
        i18n.text(`reports.tracking.configuration.module.savedFail`),
        'error',
        i18n.text(`reports.tracking.configuration.module.error`)
      ),
  })

  const [selectedAssets, setSelectedAssets] = useState<AssetsProps>([])
  const [analysisType, setAnalysisType] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState<boolean>(true)
  const [reference, setReference] = useState<boolean>(true)
  const [assetGroupingLevel, setAssetGroupingLevel] = useState('')
  const [timeGroupingLevel, setTimeGroupingLevel] = useState('')
  const [daysOfWeekInclude, setDaysOfWeekInclude] = useState({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  })
  const [visualisations, setVisualisations] = useState<VisualisationDataProps[]>([])

  const analysisModule = moduleData?.TrackingReportReportConfigs[0]?.analysisModules[0]
  const daysOfWeekNumbers = analysisModule?.daysOfWeekInclude || [0, 1, 2, 3, 4, 5, 6]
  const monday = daysOfWeekNumbers === null ? true : daysOfWeekNumbers && daysOfWeekNumbers.includes(0)
  const tuesday = daysOfWeekNumbers === null ? true : daysOfWeekNumbers && daysOfWeekNumbers.includes(1)
  const wednesday = daysOfWeekNumbers === null ? true : daysOfWeekNumbers && daysOfWeekNumbers.includes(2)
  const thursday = daysOfWeekNumbers === null ? true : daysOfWeekNumbers && daysOfWeekNumbers.includes(3)
  const friday = daysOfWeekNumbers === null ? true : daysOfWeekNumbers && daysOfWeekNumbers.includes(4)
  const saturday = daysOfWeekNumbers === null ? true : daysOfWeekNumbers && daysOfWeekNumbers.includes(5)
  const sunday = daysOfWeekNumbers === null ? true : daysOfWeekNumbers && daysOfWeekNumbers.includes(6)

  const analysisModuleData = {
    analysisType: analysisModule?.analysisType,
    title: analysisModule?.title,
    descriptionInclude: analysisModule?.descriptionInclude,
    referenceValueInclude: analysisModule?.referenceValueInclude,
    assetIds: analysisModule?.assetIds,
    timeGroupingLevel: analysisModule?.timeGroupingLevel,
    daysOfWeekInclude: {
      monday: monday,
      tuesday: tuesday,
      wednesday: wednesday,
      thursday: thursday,
      friday: friday,
      saturday: saturday,
      sunday: sunday,
    },
    assetGroupingLevel: analysisModule?.assetGroupingLevel,
    enabled: analysisModule?.enabled,
  } as ModuleProps

  const sortedAssetsInput = getFilteredAssets(
    analysisType,
    shiftSchedulesData,
    assetsData,
    assetsProductionSchedulesData,
    assetsProtocolsData,
    assetsWithStandbyData
  )
  const assetsInput = getAssetsInput(assetsData)
  const currentVisualisationData = analysisModule && analysisModule.visualisations

  const currentVisualisations = currentVisualisationData
    ? Array.from(currentVisualisationData, item => item as VisualisationDataProps).map(visualisation => ({
        ...visualisation,
        analysisType: analysisType,
      }))
    : [emptyVisualisation]

  const handleDeleteVisualisation = async (id: string | undefined, index: number) => {
    if (id) {
      const deleteParameters = { visualisationId: id, deletedAt: DateTime.now().toISO() as ISODateTime }

      await deleteVisualisationMutation({
        variables: deleteParameters,
      })
    }
    const newVisualisationsArray = visualisations?.filter((elem, ind) => ind !== index)
    setVisualisations(newVisualisationsArray)
  }

  const handleAddVisualisation = () => {
    visualisations
      ? setVisualisations([...visualisations, emptyVisualisation])
      : setVisualisations([emptyVisualisation])
  }

  const handleVisualisationChange = (visualisationsChanged: VisualisationDataProps[]) => {
    visualisationsChanged && setVisualisations(visualisationsChanged)
  }
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true)
    if (moduleId === 'new') {
      trackCancelEditModuleEvent('modal_cancel_button', AnalyticsIntent.TR_CANCEL_EDIT_NEW_MODULE, sendEvent)
    } else {
      trackCancelEditModuleEvent('modal_cancel_button', AnalyticsIntent.TR_CANCEL_EDIT_EXISTING_MODULE, sendEvent)
    }
  }
  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false)
  }

  useEffect(() => {
    setAnalysisType(analysisModuleData?.analysisType || 'standby')
    setTitle(analysisModuleData?.title || '')
    setDescription(moduleId !== 'new' ? analysisModuleData?.descriptionInclude || false : true)
    setReference(moduleId !== 'new' ? analysisModuleData?.referenceValueInclude || false : true)
    setAssetGroupingLevel(analysisModuleData?.assetGroupingLevel || '')
    setTimeGroupingLevel(analysisModuleData?.timeGroupingLevel || '')
    setDaysOfWeekInclude(
      moduleId !== 'new'
        ? analysisModuleData?.daysOfWeekInclude || {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true,
          }
        : {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true,
          }
    )
    setVisualisations(currentVisualisations)

    setSelectedAssets(
      (assetsInput && assetsInput.filter(item => analysisModuleData?.assetIds?.includes(item.assetId))) || []
    )
  }, [moduleData, history, assets])

  const handleAnalysisTypeChange = (event: SelectChangeEvent) => {
    setAnalysisType(event.target.value as string)
  }

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value as string)
  }

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.checked)
  }
  const [selectAllAssets, setSelectAllAssets] = useState<boolean>(false)

  const handleSelectAllAssets = () => {
    setSelectAllAssets(prev => {
      if (!prev && sortedAssetsInput) setSelectedAssets([...sortedAssetsInput])
      else setSelectedAssets([])
      return !prev
    })
  }

  const handleAssetsChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: AssetsProps,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'clear' || reason === 'removeOption') setSelectAllAssets(false)
    if (reason === 'selectOption' && value?.length === sortedAssetsInput?.length) setSelectAllAssets(true)
    setSelectedAssets(value)
  }

  const handleAssetGroupingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAssetGroupingLevel(event.target.value)
  }

  const handleTimeGroupingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeGroupingLevel(event.target.value)
  }

  const handleReferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReference(event.target.checked)
  }
  const handleToggleAllDays = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDaysOfWeekInclude = {
      monday: event.target.checked,
      tuesday: event.target.checked,
      wednesday: event.target.checked,
      thursday: event.target.checked,
      friday: event.target.checked,
      saturday: event.target.checked,
      sunday: event.target.checked,
    }
    setDaysOfWeekInclude(newDaysOfWeekInclude)
  }
  const handleToggleDay = (dayName: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const newDaysOfWeekInclude = {
      ...daysOfWeekInclude,
      [dayName]: event.target.checked,
    }
    setDaysOfWeekInclude(newDaysOfWeekInclude)
  }

  const handleSave = async () => {
    const daysOfWeekIncludeIndexes: number[] = getAllIndexes(Object.values(daysOfWeekInclude), true)

    if (moduleId === 'new') {
      const moduleInput = {
        input: {
          analysisType: analysisType,
          title: title,
          descriptionInclude: description,
          referenceValueInclude: reference,
          assetIds: selectedAssets?.map(item => item.assetId),
          assetGroupingLevel: assetGroupingLevel,
          timeGroupingLevel: timeGroupingLevel,
          daysOfWeekInclude: daysOfWeekIncludeIndexes,
          reportConfigId: reportId,
          enabled: true,
          position: numModules,
        },
      }

      await InsertAnalysisModuleMutation({
        variables: {
          ...moduleInput,
        },
      }).then(response => {
        const newModuleId = response.data?.insertTrackingReportAnalysisModules?.returning[0].id

        if (visualisations) {
          const visualisationsSettingsParametersNew = {
            visualisations: visualisations
              .map((visualisation, index) => ({ ...visualisation, analysisModuleId: newModuleId, position: index }))
              .map(function (visualisation) {
                delete visualisation.analysisType
                return visualisation
              }),
          } as VisualisationUpsertDataProps

          insertTrackingReportVisualisationsMutation({
            variables: {
              ...visualisationsSettingsParametersNew,
            },
          })
          refetchModules()
        }
      })
      trackSaveModuleEvent('save_new_module_button', sendEvent)
    } else {
      const moduleSettingsParameters = {
        moduleId: moduleId,
        analysisType: analysisType,
        title: title,
        descriptionInclude: description,
        referenceValueInclude: reference,
        assetIds: selectedAssets?.map(item => item.assetId),
        assetGroupingLevel: assetGroupingLevel,
        timeGroupingLevel: timeGroupingLevel,
        daysOfWeekInclude: daysOfWeekIncludeIndexes,
        nTimeUnits: null,
      }

      await updateModuleSettingsMutation({
        variables: {
          ...moduleSettingsParameters,
        },
      })
      trackSaveModuleEvent('save_existing_module_button', sendEvent)
    }
    if (visualisations && moduleId !== 'new') {
      const visualisationsSettingsParameters = {
        visualisations:
          visualisations &&
          visualisations
            .map((visualisation, index) => ({ ...visualisation, analysisModuleId: moduleId, position: index }))
            .map(function (visualisation) {
              delete visualisation.analysisType
              delete visualisation.__typename
              return visualisation
            }),
      } as VisualisationUpsertDataProps
      await insertTrackingReportVisualisationsMutation({
        variables: {
          ...visualisationsSettingsParameters,
        },
      })
    }
    await refetchModule()
    await refetchModules()
    history.push(`/reports/configuration/tracking/${reportId}/modules`)
  }

  const numCommoditiesSelected = selectedAssets ? new Set(selectedAssets.map(asset => asset.type)).size : 0

  const numBars = getNumBars(selectedAssets, assetGroupingLevel, timeGroupingLevel, daysOfWeekInclude, reference) || 0

  const areVisualisationsValid = visualisations?.map(visualisation => ({
    isTypeValid: visualisation.visualisationType,
    isUnitValid: visualisation.unit,
    isBarChartValid: visualisation.visualisationType != 'barChart' ? true : numBars <= MAX_NUM_BARS,
    get isVisualisationValid() {
      return this.isTypeValid && this.isUnitValid && this.isBarChartValid
    },
  }))

  const visualisationTypesUnitsArray = visualisations.map(visualisation =>
    visualisation.visualisationType.concat(visualisation.unit)
  )

  const isSaveDisabled = {
    areVisualisationsValid: areVisualisationsValid?.every(({ isVisualisationValid }) => isVisualisationValid),
    isAssetsValid: selectedAssets && selectedAssets.length > 0,
    isAssetGroupingValid:
      !(assetGroupingLevel === '') && (assetGroupingLevel != 'total' || numCommoditiesSelected <= 1),
    isTimeGroupingValid: !(timeGroupingLevel === ''),
    isDaysOfWeekValid: Object.values(daysOfWeekInclude).includes(true),
    isVisualisationsNumValid: visualisations.length > 0,
    areVisualisationsNotDuplicate: new Set(visualisationTypesUnitsArray).size === visualisationTypesUnitsArray.length,
    get isSaveDisabled() {
      return (
        !this.areVisualisationsValid ||
        !this.isAssetsValid ||
        !this.isAssetGroupingValid ||
        !this.isTimeGroupingValid ||
        !this.isDaysOfWeekValid ||
        !this.isVisualisationsNumValid ||
        !this.areVisualisationsNotDuplicate
      )
    },
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            alignItems: 'left',
            justifyContent: 'flex-start',
            marginBottom: '-1.25rem',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
            <Divider sx={{ marginLeft: '-1.5rem', marginRight: '-1.5rem' }} />
            <AnalysisTypeSelect
              handleAnalysisTypeChange={handleAnalysisTypeChange}
              analysisType={analysisType}
            />
            <ModuleTitleInput
              handleTitleChange={handleTitleChange}
              title={title}
            />
            <DescriptionSwitch
              handleDescriptionChange={handleDescriptionChange}
              description={description}
            />
            <Divider />
            <AssetsSelect
              selectedAssets={selectedAssets}
              handleAssetsChange={handleAssetsChange}
              selectAll={selectAllAssets}
              handleSelectAllAssets={handleSelectAllAssets}
              analysisType={analysisType}
            />
            <AssetGroupingSelect
              assetGroupingLevel={assetGroupingLevel}
              handleAssetGroupingChange={handleAssetGroupingChange}
              numCommoditiesSelected={numCommoditiesSelected || 0}
            />
            <Divider />
            <TimeGroupingSelect
              timeGroupingLevel={timeGroupingLevel}
              handleTimeGroupingChange={handleTimeGroupingChange}
              frequency={frequency || 'weekly'}
            />
            <Divider />
            <DaysOfWeekSelect
              daysOfWeekInclude={daysOfWeekInclude}
              handleToggleAllDays={handleToggleAllDays}
              handleToggleDay={handleToggleDay}
            />
            <Divider />
            <ReferenceSwitch
              reference={reference}
              handleReferenceChange={handleReferenceChange}
            />
            <Divider />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '0.125rem',
                paddingRight: '1rem',
              }}
            >
              <StyledInputFieldHeader>
                {i18n.text(`reports.tracking.configuration.module.visualisations`)}
              </StyledInputFieldHeader>
              <StyledTooltipWithIcon
                title={i18n.text('reports.tracking.configuration.tooltip.visualisations')}
              ></StyledTooltipWithIcon>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              {visualisations && (
                <VisualisationList
                  visualisations={visualisations}
                  onChange={handleVisualisationChange}
                  onDelete={handleDeleteVisualisation}
                  onAdd={handleAddVisualisation}
                  analysisType={analysisType}
                  assetGroupingLevel={assetGroupingLevel}
                  timeGroupingLevel={timeGroupingLevel}
                  selectedAssets={selectedAssets}
                  daysOfWeekInclude={daysOfWeekInclude}
                  maxNumBars={MAX_NUM_BARS}
                  referenceInclude={reference}
                />
              )}
            </Box>
            <Divider sx={{ marginLeft: '-1.5rem', marginRight: '-1.5rem', marginBottom: '1rem' }} />
          </Box>

          <SaveCancelButtons
            handleCloseCancelDialog={handleCloseCancelDialog}
            handleOpenCancelDialog={handleOpenCancelDialog}
            handleSave={handleSave}
            openCancelDialog={openCancelDialog}
            isSaveDisabled={isSaveDisabled.isSaveDisabled}
          />
        </Box>
      </Box>
    </>
  )
}
