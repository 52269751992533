import { Box, Typography, styled } from '@mui/material'
import { FC } from 'react'

import { EnergyByUnitElectricityUnit, EnergyByUnitOutputMetricUnits } from '../../types'
import { StyledToggleButtonGroup } from '../../../Shared/components/MUIComponents/update/StyledToggleButtonGroup'
import { getCurrencySymbol } from '../../../Shared/utils/formatCurrency'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  paddingRight: theme.spacing(1.5),
}))

const GraphTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[800],
  fontSize: '1.125rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
  padding: theme.spacing(2, 0, 2, 2),
}))

interface GraphTitleToolbarProps {
  valuesModifierUnit: string
  selectedUnit: EnergyByUnitElectricityUnit
  outputMetric: EnergyByUnitOutputMetricUnits
  onUnitChange: (unit: EnergyByUnitElectricityUnit) => void
}

export const GraphTitleToolbar: FC<GraphTitleToolbarProps> = ({
  valuesModifierUnit,
  selectedUnit,
  outputMetric,
  onUnitChange,
}) => {
  const { i18n, userLocale } = useI18nContext()
  const { customerCurrency } = useCurrentUser()

  const switchUnitValues = [
    {
      value: 'energy',
      title: valuesModifierUnit,
    },
    {
      value: 'cost',
      title: getCurrencySymbol(userLocale, customerCurrency.isocode),
    },
  ]

  return (
    <Wrapper>
      <GraphTitle>
        {i18n.text('ebu.chart.header')} ({i18n.text(`ebu.set-unit.units.${outputMetric}`)}){' '}
      </GraphTitle>
      <StyledToggleButtonGroup
        items={switchUnitValues}
        selectedItem={selectedUnit}
        onChange={value => onUnitChange(value as EnergyByUnitElectricityUnit)}
      />
    </Wrapper>
  )
}
