import React, { FC } from 'react'
import { Box, Typography, dialogClasses, styled } from '@mui/material'

import { AlertIcon } from '../../../../../../Shared/components/icons'
import { StyledDialogModal } from '../../../../../../Shared/components/MUIComponents/StyledDialogModal'
import { StyledPrimaryButton } from '../../../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { useI18nContext } from '../../../../../../Shared/contexts/i18nContext/I18nContext'

interface ICurrencyUpdatedModalProps {
  isOpen: boolean
  onClose: () => void
}

export const CurrencyUpdatedModal: FC<ICurrencyUpdatedModalProps> = ({ isOpen, onClose }) => {
  const { i18n } = useI18nContext()

  const handleModalClose = () => {
    onClose()
  }

  const renderAlertIcon = () => (
    <Box
      sx={{
        background: theme => theme.palette.SFIWarning[100],
        width: '3rem',
        height: '3rem',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AlertIcon
        size="small"
        sx={{ color: theme => theme.palette.SFIWarning[600] }}
      />
    </Box>
  )

  return (
    <StyledDialogModal
      title=""
      isOpen={isOpen}
      isLoading={false}
      onClose={handleModalClose}
      height="responsive"
      maxWidth="xs"
      icon={renderAlertIcon()}
      sx={{
        [`.${dialogClasses.paper}`]: {
          maxWidth: '25rem',
        },
        '.modal-box': {
          gap: 0,
        },
      }}
    >
      <Title>{i18n.text('settings.currency.alert-dialog.title')}</Title>
      <Subtitle>{i18n.text('settings.currency.alert-dialog.text2')}</Subtitle>
      <StyledPrimaryButton onClick={handleModalClose}>
        {i18n.text('general-settings.currency-updated-modal.button-text')}
      </StyledPrimaryButton>
    </StyledDialogModal>
  )
}

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: 600,
  color: theme.palette.SFIGreyLight[800],
  lineHeight: '1.75rem',
  margin: '1rem 0 0.25rem',
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  marginBottom: '2rem',
}))
