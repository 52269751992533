import { Box, Typography, styled } from '@mui/material'
import { FC } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import { AlertIcon } from '../../../../../Shared/components/icons'
import { ModalFooter, StyledDialogModal } from '../../../../../Shared/components/MUIComponents/StyledDialogModal'
import { ModuleParams } from '../types'
import {
  StyledOutlinedButton,
  StyledPrimaryButton,
} from '../../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

interface CancelEditModuleDialogProps {
  handleCloseCancelDialog: () => void
  openCancelDialog: boolean
}

export const CancelEditModuleDialog: FC<CancelEditModuleDialogProps> = ({
  handleCloseCancelDialog,
  openCancelDialog,
}) => {
  const { i18n } = useI18nContext()
  const { reportId } = useParams<ModuleParams>()
  const history = useHistory()

  return (
    <StyledDialogModal
      title=""
      isOpen={openCancelDialog}
      isLoading={false}
      onClose={handleCloseCancelDialog}
      height="responsive"
      maxWidth="sm"
      icon={
        <AlertIconWrapper>
          <AlertIcon
            size="small"
            sx={{
              width: '1.5rem',
              height: '1.5rem',
            }}
          />
        </AlertIconWrapper>
      }
    >
      <ContentWrapper>
        <Title>{i18n.text(`reports.tracking.configuration.module.cancelSure`)}</Title>
        {<Subtitle>{i18n.text(`reports.tracking.configuration.module.loseUnsaved`)}</Subtitle>}
      </ContentWrapper>
      <ModalFooter
        sx={{
          gap: '0.75rem',
          marginTop: '1rem',
          overflow: 'visible',
        }}
      >
        <StyledOutlinedButton
          disableRipple
          onClick={() => history.push(`/reports/configuration/tracking/${reportId}/modules`)}
        >
          {i18n.text(`reports.tracking.configuration.module.cancelDiscard`)}
        </StyledOutlinedButton>
        <StyledPrimaryButton
          disableRipple
          onClick={handleCloseCancelDialog}
        >
          {i18n.text(`reports.tracking.configuration.module.keepEditing`)}
        </StyledPrimaryButton>
      </ModalFooter>
    </StyledDialogModal>
  )
}

const AlertIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '3rem',
  height: '3rem',
  padding: '0.75rem',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  background: theme.palette.SFIWarning[100],
  color: theme.palette.SFIWarning[600],
}))

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
})

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: 600,
  color: theme.palette.SFIGreyLight[800],
  lineHeight: '1.75rem',
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
}))
