import { ElectricityAnalysisType } from '../types'

export const getApexElectricityChartType = (analysisType: ElectricityAnalysisType) => {
  switch (analysisType) {
    case 'power':
      return 'line'
    case 'energy':
      return 'bar'
    case 'energy-balance':
      return 'bar'
  }
}
