import { Box } from '@mui/material'
import { FC } from 'react'

import { DaysOfWeekSelectProps } from '../types'
import { StyledCheckbox } from '../../../../../Shared/components/MUIComponents/update/Checkbox/StyledCheckBox'
import { StyledFormControlLabel } from '../../../../../Shared/components/MUIComponents/update/Checkbox/StyledFormControlLabel'
import { StyledInputFieldHeader, StyledTooltipWithIcon } from '../Common'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const DaysOfWeekSelect: FC<DaysOfWeekSelectProps> = ({
  daysOfWeekInclude,
  handleToggleAllDays,
  handleToggleDay,
}) => {
  const { i18n } = useI18nContext()

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '30% 70%',
        }}
      >
        {' '}
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.125rem', paddingRight: '1rem' }}>
          <StyledInputFieldHeader sx={{ alignContent: 'top', mt: '0.375rem' }}>
            {i18n.text(`reports.tracking.configuration.module.includeDays`)}
          </StyledInputFieldHeader>
          <Box sx={{ alignContent: 'top', mt: '0.375rem' }}>
            <StyledTooltipWithIcon
              title={i18n.text('reports.tracking.configuration.tooltip.daysOfWeek')}
            ></StyledTooltipWithIcon>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', mb: '0.375rem' }}>
            <StyledFormControlLabel
              value="Parent"
              label={i18n.text(`reports.tracking.configuration.module.selectAll`)}
              control={
                <StyledCheckbox
                  checked={!Object.values(daysOfWeekInclude).includes(false)}
                  indeterminate={
                    Object.values(daysOfWeekInclude).includes(false) && Object.values(daysOfWeekInclude).includes(true)
                  }
                  onChange={handleToggleAllDays}
                />
              }
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', ml: '0.438rem', flexWrap: 'wrap', rowGap: '0.375rem' }}>
            {Object.entries(daysOfWeekInclude).map(([day, checked]) => (
              <StyledFormControlLabel
                key={day}
                value={day}
                control={
                  <StyledCheckbox
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={checked}
                    onChange={event => handleToggleDay(day, event)}
                  />
                }
                label={i18n.text(`reports.tracking.configuration.module.${day}`)}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}
