import { Box, Typography } from '@mui/material'
import { FC } from 'react'

type GraphHeaderProps = {
  title: string
  shouldShowQuickIntervals?: boolean
}

const GraphHeader: FC<GraphHeaderProps> = ({ title, shouldShowQuickIntervals = true }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h6">{title}</Typography>
      {/* @todo graph toolbar */}
    </Box>
  )
}

export default GraphHeader
