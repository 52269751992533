import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { FC } from 'react'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useHistory } from 'react-router'
import { useTheme } from '@mui/material/styles'

import logo from '../../../Shared/assets/images/logo.svg'
import { LinkInfo } from '../../../Shared/components/MUIComponents/SecondaryNav/types'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface SidebarProps {
  isOpen: boolean
  handleClose: () => void
  links: LinkInfo[]
}

const DRAWER_WIDTH = 240

export const Sidebar: FC<SidebarProps> = ({ isOpen, handleClose, links }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const history = useHistory()
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={handleClose}
      sx={theme => ({
        width: DRAWER_WIDTH,
        display: { xs: 'block', lg: 'none' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          marginTop: '64px',
          paddingLeft: 2,
          width: DRAWER_WIDTH,
          backgroundColor: theme.palette.SFIGreyLight[800],
          '*': { color: theme.palette.primary.contrastText },
          [theme.breakpoints.down('sm')]: {
            marginTop: '56px',
          },
        },
      })}
    >
      <List>
        {isBelowSm && (
          <ListItemButton onClick={() => history.push('/')}>
            <ListItemIcon>
              <img
                src={logo}
                alt="Sensorfact Logo"
                height="40"
              />
            </ListItemIcon>
          </ListItemButton>
        )}
        {links.map(link => (
          <ListItemButton
            key={link.path}
            onClick={() => history.push(link.path)}
            sx={{
              '&.activeLink, &:hover': {
                backgroundColor: theme.palette.SFIGreyCool[500],
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={i18n.text(link.i18n)} />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  )
}
