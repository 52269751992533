import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import ProgressContextProvider from '../context/ProgressContext'
import Layout from './Layout'

export default function OnlineOnboarding() {
  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <ProgressContextProvider>
        <Layout />
      </ProgressContextProvider>
    </QueryParamProvider>
  )
}
