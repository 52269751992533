import { TextField, autocompleteClasses, inputBaseClasses, outlinedInputClasses, styled } from '@mui/material'

export const StyledAutocompleteTextField = styled(TextField)(({ theme }) => ({
  [`.${inputBaseClasses.root}`]: {
    borderRadius: '0.375rem',
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    boxShadow: '0px 1px 2px 0px #1018280d',
    maxWidth: '20rem',
    padding: 0,
    height: '2.625rem',
    overflow: 'hidden',
    '&:focus-visible': {
      outline: 'none',
      boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #1d7afd3d',
    },
    '&.Mui-disabled': {
      background: theme.palette.SFIGreyLight[50],
    },
    input: {
      WebkitTextFillColor: theme.palette.SFIGreyLight[500],
      '&::placeholder': {
        opacity: 1,
      },
    },
  },
  [`.${outlinedInputClasses.notchedOutline}`]: {
    border: 'none',
  },
  [`.${autocompleteClasses.endAdornment}`]: {
    top: 'calc(50% - 12px)',
  },
}))
