import React from 'react'
import { Box, styled } from '@mui/material'
import { Control, Controller, FieldErrorsImpl, FieldValues, Path } from 'react-hook-form'

import { StyledLabel } from '../AlertConfigurationModal.styled'
import { StyledTextField } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const GridBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '10rem 1fr',
  gap: '2rem',
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  paddingTop: '1rem',
}))

interface IMessageFormSectionProps<T extends FieldValues> {
  control: Control<T>
  name: Path<T>
  errors: FieldErrorsImpl<T>
}

export const MessageFormSection = <T extends FieldValues>({ name, control, errors }: IMessageFormSectionProps<T>) => {
  const { i18n } = useI18nContext()

  return (
    <GridBox>
      <StyledLabel>{i18n.text('rules.headers.message')}</StyledLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <StyledTextField
            value={value}
            placeholder={i18n.text('rules.placeholders.message')}
            size="small"
            variant="outlined"
            error={!!errors.message}
            helperText={errors.message?.message}
            onChange={onChange}
            sx={{
              maxWidth: '100%',
            }}
          />
        )}
      ></Controller>
    </GridBox>
  )
}
