import { useLocation } from 'react-router'
import { useMemo } from 'react'

function useQueryParams() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default useQueryParams
