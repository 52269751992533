import React, { useMemo, useState } from 'react'
import { Alert, Box, useTheme } from '@mui/material'

import AssetTreeLayout from '../Layouts/AssetTreeLayout'
import CriticalStopTimeModal from '../../components/CriticalStopTimeModal/CriticalStopTimeModal'
import Error from '../../../Shared/components/MUIComponents/Error'
import Loader from '../../../Shared/components/MUIComponents/Loader'
import ProductionSpeed from '../../components/ProductionSpeed/ProductionSpeed'
import ProductionStatistics from '../../components/ProductionStatistics/ProductionStatistics'
import QuickIntervalButtons from '../../components/QuickIntervalButtons/QuickIntervalButtons'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import useAssetTreeMode from '../../hooks/useAssetTreeMode'
import useLiveRefetching from '../../../Shared/hooks/useLiveRefetching'
import { AssetProductionSpeed, AssetTreeMode } from '../../types/Assets.types'
import {
  DataType,
  GetOeeProductionSpeedDocument,
  GetOeeProductionStatisticsDocument,
} from '../../../Shared/graphql/codegen'
import { OutlinedStyledButton } from '../../../Shared/components/MUIComponents/update/OutlinedStyledButton'
import { SettingsIcon } from '../../../Shared/components/icons'
import { getDefaultQuickIntervals } from '../../utils/quickIntervals'
import { getFormattedInterval } from '../../utils/helpers'
import { transformAssetProductionSpeedData, transformProductionStatisticsData } from '../../utils/dataTransformations'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

const SpeedAnalysis = () => {
  const { interval, assetColors, selectedMachines, units } = useOEEContext()
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const {
    data: productionSpeedData,
    loading: productionSpeedLoading,
    error: productionSpeedError,
    internalInterval,
  } = useLiveRefetching({
    query: GetOeeProductionSpeedDocument,
    options: {
      variables: {
        assetsIds: selectedMachines,
        from: interval.start,
        to: interval.end,
        type: DataType.ProductionCycles,
      },
    },
    interval,
    incrementStart: false,
  })

  const {
    data: productionStatisticsData,
    loading: productionStatisticsLoading,
    error: productionStatisticsError,
  } = useLiveRefetching({
    query: GetOeeProductionStatisticsDocument,
    options: {
      variables: {
        assetIds: selectedMachines,
        from: interval.start,
        to: interval.end,
      },
    },
    interval,
    incrementStart: false,
  })

  const formattedInterval = getFormattedInterval(
    internalInterval?.start ?? interval.start,
    internalInterval?.end ?? interval.end,
    i18n.locale.replace('_', '-')
  )

  const series = useMemo<AssetProductionSpeed[]>(() => {
    const assets = productionSpeedData?.myOrg.assets ?? []
    return transformAssetProductionSpeedData(assets, assetColors, units)
  }, [assetColors, productionSpeedData?.myOrg.assets, units])

  const productionStatistics = useMemo(
    () => transformProductionStatisticsData(productionStatisticsData, units),
    [productionStatisticsData, units]
  )

  useAssetTreeMode(AssetTreeMode.MULTI_SELECT)

  return (
    <AssetTreeLayout>
      <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto 1fr', gridRowGap: theme.spacing(2) }}>
        <SectionHeader
          title={i18n.text('oee.secondary-nav.speed-analysis')}
          chipContent={`${formattedInterval.start} - ${formattedInterval.end}`}
        >
          <OutlinedStyledButton
            sx={{ flexDirection: 'row', gap: theme.spacing(1) }}
            onClick={handleModalOpen}
          >
            <SettingsIcon />
            {i18n.text('oee.critical-stop-time.modal.anchor.label')}
          </OutlinedStyledButton>
          <QuickIntervalButtons quickIntervals={getDefaultQuickIntervals()} />
        </SectionHeader>
        <Box id="chart-wrapper">
          {!series && productionSpeedLoading && <Loader />}
          {productionSpeedError && <Error />}
          {!productionSpeedLoading && !productionSpeedError && (
            <ProductionSpeed
              productionSpeedData={series}
              chartHeight={350}
            />
          )}
        </Box>
        {!productionStatistics && productionStatisticsLoading && <Loader />}
        {productionStatisticsError && (
          <Alert severity="error">{i18n.text('oee.production-statistics.table.error.no-data')}</Alert>
        )}
        {!productionStatisticsLoading && !productionStatisticsError && (
          <ProductionStatistics productionStatisticsData={productionStatistics} />
        )}
        <CriticalStopTimeModal
          isOpen={modalOpen}
          onClose={handleModalClose}
        />
      </Box>
    </AssetTreeLayout>
  )
}

export default SpeedAnalysis
