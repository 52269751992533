import { createSvgIcon } from '@mui/material'

export const ChevronDoubleRight = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 14.1663L9.16667 9.99967L5 5.83301M10.8333 14.1663L15 9.99967L10.8333 5.83301"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'chevron-double-right-icon'
)
