import React, { FC, useState } from 'react'
import {
  AppBar,
  Box,
  Button,
  ButtonProps,
  Container,
  IconButton,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'

import AlertsMenu from './AlertsMenu'
import CustomerMenu from './CustomerMenu'
import logo from '../../../Shared/assets/images/logo.svg'
import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import { CustomerSwitcherButton } from './CustomerSwitcher'
import { LinkInfo } from '../../../Shared/components/MUIComponents/SecondaryNav/types'
import { NewSettingsMenu } from './NewSettingsMenu'
import { Sidebar } from './Sidebar'
import { UserIcon } from '../../../Shared/components/icons'
import { primaryLinks, secondaryLinks } from './menuRoutes'
import { trackCheckAlertsPopupEvent, trackShopNavigationEvent } from '../../utils/trackingEvents'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface CollapsibleButtonProps extends ButtonProps {
  collapsed: boolean
}

type ButtonNavLink = ButtonProps<NavLink, { component: typeof NavLink }>

interface CollapsibleNavLinkProps extends ButtonNavLink {
  collapsed: boolean
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '2.5rem',
  height: '2.5rem',
  background: theme.palette.SFIBase.white,
  marginLeft: '0.88rem',
  color: theme.palette.SFIGreyLight[700],
  '&:hover': {
    background: theme.palette.SFIGreyLight[50],
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
}))

const StyledButton: FC<ButtonProps> = props => {
  const theme = useTheme()
  return (
    <Button
      {...props}
      sx={{
        paddingTop: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        fontWeight: 400,
        marginRight: 1,
        textTransform: 'none',
        '*': {
          marginLeft: 0.25,
          marginRight: 0.25,
        },
        [theme.breakpoints.down('sm')]: {
          '*': {
            margin: 0, // Center the icon when displaying just icons
          },
        },
        '&.activeLink, &:hover': {
          backgroundColor: theme.palette.SFIGreyCool[500],
          color: theme.palette.primary.contrastText,
        },
        ...props.sx,
      }}
    />
  )
}

/** MUI button with icon and text that shows only icon when collapsed == true*/
const StyledCollapsibleButton: FC<CollapsibleButtonProps> = ({ collapsed, children, sx, ...props }) => {
  let styles = { ...sx }
  if (collapsed)
    styles = {
      '*': {
        margin: 0, // Center the icon when displaying just icons
      },
      ...sx,
    }
  return (
    <StyledButton
      {...props}
      sx={styles}
    >
      {!collapsed && children}
    </StyledButton>
  )
}

const StyledNavLink: FC<ButtonNavLink> = props => {
  const theme = useTheme()
  return (
    <Button
      {...props}
      variant="text"
      color="inherit"
      activeClassName="activeLink"
      sx={{
        paddingTop: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        fontWeight: 400,
        marginRight: 1,
        textTransform: 'none',
        '&.activeLink, &:hover': {
          backgroundColor: theme.palette.SFIGreyCool[500],
          color: theme.palette.primary.contrastText,
        },
        '*': {
          marginLeft: 0.25,
          marginRight: 0.25,
        },
        ...props.sx,
        [theme.breakpoints.up('lg')]: {
          padding: theme.spacing(1, 0.5, 0.75),
        },
        [theme.breakpoints.up('xl')]: {
          padding: theme.spacing(1, 1.5, 0.75),
        },
      }}
    />
  )
}

const StyledCollapsibleNavLink: FC<CollapsibleNavLinkProps> = ({ collapsed, children, sx, ...rest }) => {
  let styles = { ...sx }
  if (collapsed)
    styles = {
      '*': {
        margin: 0, // Center the icon when displaying just icons
      },
      ...sx,
    }

  return (
    <StyledNavLink
      {...rest}
      sx={styles}
    >
      {!collapsed && children}
    </StyledNavLink>
  )
}

const ShopButton: FC<CollapsibleButtonProps> = props => {
  const theme = useTheme()
  return (
    <StyledCollapsibleButton
      {...props}
      variant="contained"
      color="inherit"
      sx={{
        backgroundColor: theme.palette.success.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.success.dark,
        },
      }}
    />
  )
}

const PrimaryLinks: FC<{ links: LinkInfo[] }> = ({ links }) => {
  const { i18n } = useI18nContext()

  return (
    <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
      {primaryLinks.map(link => (
        <StyledNavLink
          key={link.path}
          color="inherit"
          startIcon={link.icon}
          component={NavLink}
          to={link.path}
          activeClassName="activeLink"
        >
          {i18n.text(link.i18n)}
        </StyledNavLink>
      ))}
    </Box>
  )
}

const NavBar: FC = () => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const isBelowLg = useMediaQuery(theme.breakpoints.down('lg'))
  const isBelow1370 = useMediaQuery(theme.breakpoints.down(1370))
  const needsSeparator = !isBelowLg && isBelow1370
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { user, selectedCustomer } = useCurrentUser()
  const hasMultipleCustomers = user.customers.length > 1
  const { sendEvent } = useAnalytics()

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const handleDrawerToggle = () => {
    setSidebarOpen(!sidebarOpen)
  }

  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleSettingsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsMenuAnchorEl(event.currentTarget)
  }

  const handleSettingsMenuClose = () => {
    setSettingsMenuAnchorEl(null)
  }

  const [customerMenuAnchorEl, setCustomerMenuAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleCustomerMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setCustomerMenuAnchorEl(event.currentTarget)
  }

  const handleCustomerMenuClose = () => {
    setCustomerMenuAnchorEl(null)
  }

  const [alertsrMenuAnchorEl, setAlertsMenuAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleAlertsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAlertsMenuAnchorEl(event.currentTarget)
    trackCheckAlertsPopupEvent(sendEvent)
  }

  const handleShopButtonClick = (link: LinkInfo) => {
    trackShopNavigationEvent(sendEvent)
    window.open(i18n.text(link.path), '_blank', 'noopener,noreferrer') // using the i18n system to set shop link from backoffice
  }

  const handleAlertsMenuClose = () => {
    setAlertsMenuAnchorEl(null)
  }

  const renderSecondaryLinks = () => {
    // Secondary links collapse to icons on small screensizes
    return (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', borderLeft: needsSeparator ? 'solid 1px white' : 'none' }}>
        {secondaryLinks.map(link => {
          if (link.name === 'Shop') {
            // Shop button that's always highligted
            return (
              <ShopButton
                key={link.path}
                startIcon={link.icon}
                collapsed={isBelowSm}
                onClick={() => handleShopButtonClick(link)}
              >
                {i18n.text(link.i18n)}
              </ShopButton>
            )
          } else if (link.name === 'Alerts') {
            return (
              <React.Fragment key="abc">
                <StyledCollapsibleButton
                  key={link.path}
                  variant="text"
                  color="inherit"
                  collapsed={isBelowSm}
                  startIcon={link.icon}
                  onClick={handleAlertsMenuOpen}
                >
                  {i18n.text(link.i18n)}
                </StyledCollapsibleButton>
                <AlertsMenu
                  key="alerts-menu"
                  handleMenuClose={handleAlertsMenuClose}
                  menuAnchorEl={alertsrMenuAnchorEl}
                />
              </React.Fragment>
            )
          } else {
            return (
              <StyledCollapsibleNavLink
                key={link.path}
                startIcon={link.icon}
                collapsed={isBelowSm}
                component={NavLink}
                to={link.path}
              >
                {i18n.text(link.i18n)}
              </StyledCollapsibleNavLink>
            )
          }
        })}
        {/* Customer switching button */}
        {hasMultipleCustomers && !isBelowLg && (
          <>
            <CustomerSwitcherButton
              key="customer_switch_button"
              mini={needsSeparator}
              customerName={selectedCustomer.name}
              handleClick={handleCustomerMenuOpen}
            />
            <CustomerMenu
              key="customer_switch_menu"
              handleMenuClose={handleCustomerMenuClose}
              menuAnchorEl={customerMenuAnchorEl}
            />
          </>
        )}
        {/* Settings button that's always only an icon */}
        <StyledIconButton
          key="settings-icon-button"
          edge="end"
          color="inherit"
          aria-label="settings"
          disableRipple
          onClick={handleSettingsMenuOpen}
        >
          <UserIcon />
        </StyledIconButton>
        <NewSettingsMenu
          key="settings_menu"
          handleMenuClose={handleSettingsMenuClose}
          menuAnchorEl={settingsMenuAnchorEl}
        />
      </Box>
    )
  }

  return (
    <Box>
      <AppBar
        position="sticky"
        id="top-nav"
        sx={{ zIndex: theme.zIndex.drawer + 1, boxShadow: 'none', backgroundColor: theme.palette.SFIGreyLight[950] }}
      >
        <Container>
          <Toolbar
            sx={{
              [theme.breakpoints.down('xl')]: {
                padding: 0,
              },
            }}
          >
            {isBelowLg && (
              // Show hamburger button
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            )}
            {!isBelowSm && (
              // Show logo in navbar
              <IconButton
                edge="start"
                color="inherit"
                aria-label="logo"
                component={NavLink}
                to="/"
                sx={{ mr: 0 }}
              >
                <img
                  src={logo}
                  alt="Sensorfact Logo"
                  height="40"
                />
              </IconButton>
            )}

            {isBelowLg ? (
              <Sidebar
                isOpen={sidebarOpen}
                handleClose={handleDrawerToggle}
                links={primaryLinks}
              />
            ) : (
              <PrimaryLinks links={primaryLinks} />
            )}
            <Box sx={{ flexGrow: 1 }} />
            {renderSecondaryLinks()}
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}

export default NavBar
