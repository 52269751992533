import React, { FC, useMemo } from 'react'
import { Box, styled } from '@mui/material'
import { DateTime, Interval } from 'luxon'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router'

import AssetTreeLayout from './AssetTreeLayout.tsx'
import MuiDateTimeRangePicker from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePickerControlled'
import PngExporter from '../../../Shared/components/MUIComponents/ChartTools/PngExporter'
import PngFlowExporter from '../PngFlowExporter'
import SecondaryNavContainer from '../../containers/SecondaryNavContainer/SecondaryNavContainer'
import { AirBalanceGraph } from '../Graphs/BalanceGraph'
import { AirConsumptionGraph } from '../Graphs/ConsumptionGraph'
import { AirFlowGraph } from '../Graphs/FlowGraph'
import { CABalanceCsvExporter } from '../CsvExporter/CABalanceCsvExporter'
import { CAConsumptionCsvExporter } from '../CsvExporter/CAConsumptionCsvExporter'
import { CAEfficiencyCsvExporter } from '../CsvExporter/CAEfficiencyCsvEXporter'
import { CAFlowCsvExporter } from '../CsvExporter/CAFlowCsvEXporter'
import { DownloadIcon } from '../../../Shared/components/icons/DownloadIcon'
import { EfficiencyPage } from '../../pages/EfficiencyPage.tsx'
import { GraphId } from '../../types/compressed-air_types'
import { StartEndDatesValues } from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePickerControlled/types'
import { StyledMenu } from '../../../Shared/components/MUIComponents/update/StyledMenu'
import { StyledMenuItem } from '../../../Shared/components/MUIComponents/update/StyledMenu/StyledMenuItem'
import { SystemInformation } from '../SystemInformation'
import { useCAChartToolsContext } from '../../context/CAChartToolsContext'
import { useCompressedAirContext } from '../../context/CompressedAirContext'
import { useEfficiencyContext } from '../../context/EfficiencyContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const AirFlowGraphMemo = React.memo(AirFlowGraph)
const AirConsumptionGraphMemo = React.memo(AirConsumptionGraph)
const AirBalanceGraphMemo = React.memo(AirBalanceGraph)

interface ILayoutProps {
  sid: (string | null)[] | never[]
  onSelectionUpdate: (ids: string[]) => void
}

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgb(245, 245, 245)',
  width: '100%',
  height: 'calc(-64px + 100vh)',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  overflow: 'hidden',
}))

const Layout: FC<ILayoutProps> = ({ sid, onSelectionUpdate }) => {
  const {
    fromToValues,
    previousPeriodFromToValues,
    series,
    switchUnit,
    seriesData,
    previousPeriodSeries,
    isCompareDatesOn,
    setFromToValues,
    setPreviousPeriodFromToValues,
    setIsCompareDatesOn,
    handleDatesChange,
    handleCompareDatesChange,
  } = useCompressedAirContext()
  const { efficiencyPowerSeries, mainPipesFlowSeries, efficiencySeries } = useEfficiencyContext()
  const { decimal } = useCAChartToolsContext()
  const { path } = useRouteMatch()
  const location = useLocation()
  const { i18n } = useI18nContext()
  const isSystemInformationPage = location.pathname.includes('asset-information')

  const interval: Interval =
    fromToValues.startDate && fromToValues.endDate
      ? Interval.fromDateTimes(fromToValues.startDate, fromToValues.endDate)
      : Interval.fromDateTimes(DateTime.now().startOf('day'), DateTime.now().endOf('day'))
  const previousInterval: Interval =
    previousPeriodFromToValues.startDate && previousPeriodFromToValues.endDate
      ? Interval.fromDateTimes(previousPeriodFromToValues.startDate, previousPeriodFromToValues.endDate)
      : Interval.fromDateTimes(DateTime.now().startOf('day'), DateTime.now().endOf('day'))

  const navRoutes = (): {
    key: string
    path: string
    component: React.ReactNode
  }[] => [
    {
      key: 'flow',
      path: `${path}/flow`,
      component: <AirFlowGraphMemo />,
    },
    {
      key: 'consumption',
      path: `${path}/consumption`,
      component: <AirConsumptionGraphMemo />,
    },
    {
      key: 'balance',
      path: `${path}/balance`,
      component: <AirBalanceGraphMemo />,
    },
    {
      key: 'efficiency',
      path: `${path}/efficiency`,
      component: <EfficiencyPage />,
    },
    {
      key: 'asset-information',
      path: `${path}/asset-information`,
      component: <SystemInformation />,
    },
  ]

  const secondaryLinks = [
    {
      name: 'Air Flow',
      i18n: 'router.multi.air.flow',
      path: `${path}/flow${location.search}`,
    },
    {
      name: 'Air Consumption',
      i18n: 'router.multi.air.consumption',
      path: `${path}/consumption${location.search}`,
    },
    {
      name: 'Air Balance',
      i18n: 'router.multi.air.balance',
      path: `${path}/balance${location.search}`,
    },
    {
      name: 'Efficiency',
      i18n: 'router.multi.air.efficiency',
      path: `${path}/efficiency${location.search}`,
    },
    {
      name: 'Asset information',
      i18n: 'router.multi.air.asset-information',
      path: `${path}/asset-information${location.search}`,
    },
  ]

  const isPrevious = useMemo(
    () =>
      !!(
        previousPeriodSeries?.balance.series &&
        previousPeriodFromToValues.startDate &&
        previousPeriodFromToValues.endDate
      ),
    [previousPeriodSeries, previousPeriodFromToValues]
  )

  const showAssetTree = useMemo(() => location.pathname.includes('efficiency'), [location.pathname])

  const currentGraphId = useMemo(() => {
    return location.pathname.includes('flow')
      ? GraphId.FlOW
      : location.pathname.includes('efficiency')
      ? GraphId.EFFICIENCY_MAIN
      : location.pathname.includes('consumption')
      ? GraphId.CONSUMPTION
      : !isPrevious
      ? GraphId.BALANCE
      : GraphId.BALANCE_PREVIOUS
  }, [location, isPrevious])

  const pngFlowElements = [
    document.getElementById(currentGraphId),
    document.getElementById(GraphId.TEMPERATURE),
    document.getElementById(GraphId.PRESSURE),
  ]
  const pngFlowFileNames = [
    `Sensorfact Compressed Air Flow Analysis - ${new Date().toLocaleDateString()}`,
    `Sensorfact Compressed Air Temperature Analysis - ${new Date().toLocaleDateString()}`,
    `Sensorfact Compressed Air Pressure Analysis - ${new Date().toLocaleDateString()}`,
  ]

  const pngEfficiencyElements = [
    document.getElementById(currentGraphId),
    document.getElementById(GraphId.EFFICIENCY_POWER),
    document.getElementById(GraphId.EFFICIENCY_FLOW),
  ]
  const pngEfficiencyFileNames = [
    `Sensorfact Compressed Air Efficiency Analysis - ${new Date().toLocaleDateString()}`,
    `Sensorfact Compressed Air Efficiency Power Analysis - ${new Date().toLocaleDateString()}`,
    `Sensorfact Compressed Air Efficiency Flow Analysis - ${new Date().toLocaleDateString()}`,
  ]

  const downloadedBalanceSeries = useMemo(() => {
    const actualSeries = series.balance.labels.map((label, index) => ({
      name: isPrevious ? `${label} (${i18n.text('app.graphs.period')} 1)` : label,
      isPrevious: false,
      data: [series.balance.series[index]],
    }))

    const previousSeries =
      isPrevious && previousPeriodSeries
        ? previousPeriodSeries.balance.labels.map((label, index) => ({
            name: `${label} (${i18n.text('app.graphs.period')} 2)`,
            isPrevious: true,
            data: [previousPeriodSeries.balance.series[index]],
          }))
        : []
    return actualSeries.concat(previousSeries)
  }, [series, isPrevious])

  function onCompareDatesToggle(isSwitchedOn?: boolean) {
    setIsCompareDatesOn(!!isSwitchedOn)
    if (!isSwitchedOn) {
      setPreviousPeriodFromToValues({
        startDate: null,
        endDate: null,
      })
    }
  }

  function handleStartEndDates(startEndDates: StartEndDatesValues) {
    const { startDate, endDate } = startEndDates
    const validStartDate = startDate && startDate.isValid ? startDate : null
    const validEndDate = endDate && endDate.isValid ? endDate : null
    setFromToValues({
      startDate: validStartDate,
      endDate: validEndDate,
    })
  }

  function handleCompareStartEndDates(startEndDates: StartEndDatesValues) {
    setPreviousPeriodFromToValues(startEndDates)
  }

  return (
    <StyledBox>
      <SecondaryNavContainer links={secondaryLinks}>
        {!isSystemInformationPage && (
          <>
            <MuiDateTimeRangePicker
              fromToValues={fromToValues}
              compareFromToValues={previousPeriodFromToValues}
              hasPrevNextPeriodButtons
              onDatesChange={handleDatesChange}
              onCompareDatesChange={handleCompareDatesChange}
              onCompareDatesToggle={onCompareDatesToggle}
              isCompareDatesOn={isCompareDatesOn}
              handleStartEndDates={handleStartEndDates}
              handleCompareStartEndDates={handleCompareStartEndDates}
            />
            <StyledMenu
              menuButtonContent={
                <>
                  <DownloadIcon
                    sx={{
                      width: '15px',
                      height: '15px',
                    }}
                  />
                  {i18n.text('graph.button.tools.export')}
                </>
              }
            >
              <StyledMenuItem>
                {currentGraphId === GraphId.FlOW && (
                  <PngFlowExporter
                    elements={pngFlowElements}
                    fileNames={pngFlowFileNames}
                  />
                )}
                {currentGraphId === GraphId.EFFICIENCY_MAIN && (
                  <PngFlowExporter
                    elements={pngEfficiencyElements}
                    fileNames={pngEfficiencyFileNames}
                  />
                )}
                {currentGraphId !== GraphId.FlOW && currentGraphId !== GraphId.EFFICIENCY_MAIN && (
                  <PngExporter
                    element={document.getElementById(currentGraphId)}
                    fileName={`Sensorfact Compressed Air Analysis - ${new Date().toLocaleDateString()}`}
                    slotProps={{
                      button: {
                        sx: {
                          background: 'none',
                          padding: 0,
                          '&:hover': {
                            background: 'none',
                          },
                        },
                      },
                    }}
                  />
                )}
              </StyledMenuItem>
              <StyledMenuItem>
                {currentGraphId === GraphId.FlOW && (
                  <CAFlowCsvExporter
                    interval={interval}
                    unit={switchUnit}
                    seriesData={seriesData}
                    decimal={decimal}
                  />
                )}
                {currentGraphId === GraphId.EFFICIENCY_MAIN && (
                  <CAEfficiencyCsvExporter
                    interval={interval}
                    mainSeries={efficiencySeries}
                    powerSeries={efficiencyPowerSeries}
                    flowSeries={mainPipesFlowSeries}
                    decimal={decimal}
                  />
                )}
                {currentGraphId === GraphId.CONSUMPTION && (
                  <CAConsumptionCsvExporter
                    interval={interval}
                    unit={switchUnit}
                    seriesData={seriesData.consumption}
                    decimal={decimal}
                  />
                )}
                {(currentGraphId === GraphId.BALANCE || currentGraphId === GraphId.BALANCE_PREVIOUS) && (
                  <CABalanceCsvExporter
                    data={downloadedBalanceSeries}
                    interval={interval}
                    unit={switchUnit}
                    previousInterval={previousInterval}
                    decimal={decimal}
                  />
                )}
              </StyledMenuItem>
            </StyledMenu>
          </>
        )}
      </SecondaryNavContainer>
      <AssetTreeLayout
        sid={sid}
        onSelectionUpdate={onSelectionUpdate}
        showAssetTree={showAssetTree}
      >
        <Switch>
          {navRoutes().map(route => (
            <Route
              key={route.key}
              exact
              path={route.path}
              render={() => route.component}
            />
          ))}
        </Switch>
      </AssetTreeLayout>
    </StyledBox>
  )
}

export default Layout
