import { Select, outlinedInputClasses, styled } from '@mui/material'

export const StyledSelect = styled(Select)(({ theme }) => ({
  '&:focus-visible': {
    outline: 'none',
  },
  '& .MuiInputBase-input': {
    borderRadius: '0.375rem',
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    boxShadow: '0px 1px 2px 0px #1018280d',
    color: theme.palette.SFIGreyLight[800],
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    '&:focus': {
      boxShadow: '0px 0px 0px 4px rgba(29, 122, 253, 0.24)',
    },
    '&.Mui-disabled': {
      background: theme.palette.SFIGreyLight[50],
      WebkitTextFillColor: theme.palette.SFIGreyLight[500],
    },
  },
  [`.${outlinedInputClasses.notchedOutline}`]: {
    border: 'none',
  },
  [`.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
    border: `1px solid ${theme.palette.SFIOrange[500]}`,
  },
  'p.Mui-error': {
    color: theme.palette.SFIOrange[600],
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
    marginLeft: 0,
  },
  'div.Mui-error': {
    '& .MuiInputBase-input': {
      '&:focus-visible': {
        boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #f044383d',
      },
      '&:focus': {
        boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #f044383d',
      },
    },
  },
})) as unknown as typeof Select
