import { TemperatureAssetFragment, TemperatureSensorLocationFragment } from '../../Shared/graphql/codegen'

export const sortByAlphabeticalOrder = (arr: Array<TemperatureAssetFragment | TemperatureSensorLocationFragment>) => {
  return arr.sort(function (a, b) {
    if (a.name && b.name) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
    }
    return 0
  })
}
