import Button from '@sensorfactdev/nucleus/Button'
import ButtonLabel from '@sensorfactdev/nucleus/ButtonLabel'
import Dropdown from '@sensorfactdev/nucleus/Dropdown'
import GenericJsonEditor from '@sensorfactdev/nucleus/GenericJsonEditor'
import Input from '@sensorfactdev/nucleus/Input'
import RadioGroup from '@sensorfactdev/nucleus/RadioGroup'
import styled from 'styled-components'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { GraphQLError } from 'graphql'
import { RouteComponentProps, withRouter } from 'react-router'
import { clone, isEmpty } from 'ramda'

import IntervalEditor from '../../components/IntervalEditor'
import RecipientEditor from '../../components/RecipientEditor'
import AssetTrigger, { TriggerData } from './AssetTrigger'
import DurationPicker, { DurationSelection } from '../../components/DurationPicker'
import {
  AlertRulePayloadType,
  useCreateRuleMutation,
  useGetRuleQuery,
  useRuleEditorAllAssetsQuery,
  useUpdateRuleMutation,
} from '../../../Shared/graphql/codegen'
import {
  Commodity,
  CommodityOption,
  DurationUnit,
  GqlAssetGroup,
  InputValues,
  RuleEditorMatchParams,
  ThresholdRuleOptions,
} from './types'
import { DropdownOption, ID } from '../../../Shared/types/types'
import { I18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { IntervalType } from '../../../Shared/components/Interval'
import { Recipient, SupportedTransport } from '../../components/Recipient/types'
import { RuleType } from '../Rules/types'
import {
  createAssetGroups,
  defaultRule,
  filterAssetsByCommodity,
  getAvailableCommodityTypes,
  getMessageType,
  getMs,
  getRandomId,
  getSelectedCommodityTypeByAssetId,
  isVolumeThresholdRule,
  normalizeRule,
  ruleFromState,
  scrollToTop,
  stateFromRule,
} from './utils'
import { createNotificationPreferenceInput, getDuration } from '../Rules/utils'
import { navRoutes } from '../Alerts'
import { themeColors } from '../../../Shared/utils'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useToastContext } from '../../../Shared/contexts/ToastContext'
import { getAnalyticsPropositionByCommodityType, sendCreateAlertEvent } from '../../utils/trackingEvents'
import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'

const LocalWrapper = styled.div`
  text-align: left;
`
const InnerWrapper = styled.div``
const StyledSubheading = styled.small`
  && {
    color: ${themeColors.grey};
  }
`
const StyledLabel = styled.label`
  font-size: 15px;
  display: block;
`
const Description = styled.p`
  font-size: 13px;
  display: block;
`
const FormSection = styled.div``
const FormSectionInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${themeColors.greyLight};
  padding: 1em;
  border-radius: 4px;
  margin-bottom: 1em;
`

const FormSectionInnerError = styled(FormSectionInner)`
  background-color: transparent;
  border: 1px solid ${themeColors.danger};
  color: ${themeColors.danger};
`

const ErrorContainer = styled.ul`
  list-style-type: circle;
  list-style-position: inside;
`

const ErrorMessage = styled.li`
  font-size: 15px;
`

const baseInputStyle = `
  display: block;
  background-color: ${themeColors.white};
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 1em;
  font-size: 13px;
  text-align: left;
`
const Group = styled.div`
  margin: 0em 1em 0 0;

  & > ${StyledLabel} {
    font-size: 13px;
    margin-bottom: 0.3em;
  }

  & > div button {
    ${baseInputStyle}
  }
`
const StyledInput = styled(Input)`
  background-color: white;

  &[type='number'] {
    max-width: 7.8em;
    display: inline-block;
  }

  &[type='text'] {
    width: 38.3em;
  }
`
const StyledDropdownSmall = styled(Dropdown)`
  background-color: white;
  width: 7.8em;
`
const BreakGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  margin: 1.3em 0 0 0;
  margin-left: auto;
  float: right;

  button {
    display: inline-block;

    &:first-of-type {
      margin-right: 1em;
    }
  }
`

const TriggerSeparator = styled.p`
  margin: 0.5em 0em 1.2em 0em;
`

const extractValueFromEvent = (fn: $TSFixMe) => (event: $TSFixMe) => {
  event.persist()
  fn(event)
}

const EMPTY: GqlAssetGroup[] = []
const defaultInputValues: InputValues = {
  duration: 0,
  durationUnit: 'minutes' as InputValues['durationUnit'],
  intervals: [],
  message: '',
  messageType: 'text',
  recipients: [],
  triggers: [],
  default: true,
}

const RuleEditor = ({ history, match }: RouteComponentProps<RuleEditorMatchParams>): JSX.Element => {
  const { ruleId } = match.params
  const { i18n } = useContext(I18nContext)
  const { sendEvent } = useAnalytics()
  const { showToast } = useToastContext()
  const {
    selectedCustomer: { id: customerId },
  } = useCurrentUser()
  const [createRuleMutation, { loading: createLoading }] = useCreateRuleMutation()
  const [updateRuleMutation, { loading: updateLoading }] = useUpdateRuleMutation()

  const { data: getAssetsData } = useRuleEditorAllAssetsQuery()

  const { data: getRuleData, loading: ruleLoading } = useGetRuleQuery({
    variables: { id: ruleId },
    skip: ruleId === 'new',
  })

  const durationUnitOptions: DropdownOption[] = [
    { key: 'minutes', option: i18n.text('rules.time.unit.minutes') },
    { key: 'hours', option: i18n.text('rules.time.unit.hours') },
    { key: 'days', option: i18n.text('rules.time.unit.days') },
    // { key: 'weeks', option: i18n.text('rules.time.unit.weeks') }, disabled as of PLA-1183
  ]
  const conditionOptions: DropdownOption[] = [
    { key: 'above', option: i18n.text('rules.problem.conditions.above') },
    { key: 'below', option: i18n.text('rules.problem.conditions.below') },
    { key: 'between', option: i18n.text('rules.problem.conditions.between') },
  ]
  const thresholdOptions: ThresholdRuleOptions = {
    conditions: conditionOptions,
    durationUnits: durationUnitOptions,
  }

  const [availableCommodityOptions, setAvailableCommodityOptions] = useState<CommodityOption[]>([])
  const [commodityType, setCommodityType] = useState<Commodity>('electric-current')
  const [ruleType, setRuleType] = useState<RuleType>('powerThreshold')

  const transportOptions: SupportedTransport[] =
    commodityType === 'production-cycles' ? ['email', 'whatsapp'] : ['email', 'webhook', 'whatsapp']

  const [inputValues, setInputValues] = useState<InputValues>(defaultInputValues)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [volumetricAggregation, setVolumetricAggregation] = useState<number>(0)

  const rule = useMemo(() => {
    const gqlRule = getRuleData?.rule ?? null
    return gqlRule ? normalizeRule(gqlRule) : defaultRule
  }, [getRuleData?.rule])

  const allAssetGroups = useMemo(() => {
    const allAssets = getAssetsData?.myOrg?.assets ?? EMPTY
    return createAssetGroups([...allAssets])
  }, [getAssetsData?.myOrg?.assets])

  const availableAssetGroups = useMemo(
    () => filterAssetsByCommodity(commodityType, allAssetGroups),
    [allAssetGroups, commodityType]
  )

  const getAssetTriggerType = () => {
    if (ruleType === 'powerThreshold') return AlertRulePayloadType.Power
    if (ruleType === 'productionThreshold') return AlertRulePayloadType.ProductionSpeed
    return AlertRulePayloadType.Volume
  }

  useEffect(() => {
    if (isEmpty(allAssetGroups) || isEmpty(rule)) return

    const inputValues = stateFromRule(allAssetGroups, rule)
    setInputValues(inputValues)

    if (isVolumeThresholdRule(rule)) setVolumetricAggregation(rule.payload.aggregation)

    const commodityOptions: CommodityOption[] = [
      { value: 'electric-current', label: i18n.text('rules.commodity-types.electric-current') },
      { value: 'natural-gas', label: i18n.text('rules.commodity-types.natural-gas') },
      { value: 'water', label: i18n.text('rules.commodity-types.water') },
      { value: 'production-cycles', label: i18n.text('rules.commodity-types.oee') },
    ]
    const availableCommodityTypes = getAvailableCommodityTypes(allAssetGroups)
    setAvailableCommodityOptions(commodityOptions.filter(({ value }) => availableCommodityTypes.has(value)))
    setCommodityType(getSelectedCommodityTypeByAssetId(allAssetGroups, rule.type, rule.payload.type, rule.assets[0]))
  }, [allAssetGroups, i18n, rule])

  useEffect(() => {
    if (
      commodityType === getSelectedCommodityTypeByAssetId(allAssetGroups, rule.type, rule.payload.type, rule.assets[0])
    ) {
      const { triggers } = stateFromRule(allAssetGroups, rule)
      setInputValues(currentValue => ({ ...currentValue, triggers }))
    } else {
      const { triggers } = stateFromRule(allAssetGroups, defaultRule)
      setInputValues(currentValue => ({ ...currentValue, triggers }))
    }

    switch (commodityType) {
      case 'electric-current':
        setRuleType('powerThreshold')
        break
      case 'natural-gas':
        setRuleType('volumeThreshold')
        break
      case 'water':
        setRuleType('volumeThreshold')
        break
      case 'production-cycles':
        setRuleType('productionThreshold')
        break
    }
  }, [allAssetGroups, commodityType, rule])

  const { duration, durationUnit, intervals, message, messageType, triggers, recipients } = inputValues

  function _onAssetCountInputChange(count: number) {
    if (count > triggers.length) {
      const newTrigger = clone(triggers[0])
      newTrigger.id = getRandomId()
      _onTriggersChange([...triggers, newTrigger])
    } else if (count < triggers.length) {
      _onTriggersChange(triggers.slice(0, -1))
    }
  }

  function _onInputChange(field: string) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      setInputValues({
        ...inputValues,
        [field]: value,
      })
    }
  }

  function _onAssetTriggerChange(data: TriggerData) {
    const updatedAssetTriggers = [...triggers]
    const index = updatedAssetTriggers.findIndex(t => t.id === data.id)

    updatedAssetTriggers[index] = data
    _onTriggersChange(updatedAssetTriggers)
  }

  function _onDurationChange({ duration, durationUnit }: DurationSelection) {
    setInputValues(values => ({
      ...values,
      duration,
      durationUnit: durationUnit as InputValues['durationUnit'],
    }))
  }

  function _onIntervalsChange(data: IntervalType[]) {
    setInputValues(values => ({ ...values, intervals: data }))
  }

  function _onRecipientsChange(data: Recipient[]) {
    setInputValues(values => ({ ...values, recipients: data }))
  }

  function _onTriggersChange(data: TriggerData[]) {
    setInputValues(values => ({ ...values, triggers: data }))
  }

  function _onVolumetricAggregationChange({ duration, durationUnit }: DurationSelection) {
    setVolumetricAggregation(getMs(duration, durationUnit as DurationUnit))
  }

  async function _onSaveRule() {
    // (re)construct the rule from the form state
    const muted = rule.notificationPreferences.length > 0 ? rule.notificationPreferences[0].muted : false

    const parseResult = ruleFromState(rule.id, ruleType, inputValues, volumetricAggregation, muted)

    if (parseResult.status === 'failed') {
      setFormErrors(parseResult.errors)
      scrollToTop()
      return
    }

    setFormErrors([])

    const { id, name, type, payload, notificationPreferences } = parseResult.rule

    const variables = {
      customer: customerId as ID,
      name,
      payload,
      notificationPreferences: notificationPreferences.map(createNotificationPreferenceInput),
      type,
    }

    let hasError: GraphQLError | undefined = undefined
    if (ruleId === 'new') {
      try {
        await createRuleMutation({ variables })
        const analyticsProposition = getAnalyticsPropositionByCommodityType(commodityType)
        sendCreateAlertEvent(
          'create_rule_based_alert_form', 
          analyticsProposition, 
          sendEvent
          )
      } catch (e) {
        hasError = e as GraphQLError
      }
    } else {
      try {
        await updateRuleMutation({
          variables: {
            ...variables,
            id,
          },
        })
      } catch (e) {
        hasError = e as GraphQLError
      }
    }

    if (hasError) {
      showToast(hasError.message, 'error', i18n.text('notifications.rules.save-error.title'))
    } else {
      showToast(
        i18n.text('notifications.rules.save-success'),
        'success',
        i18n.text('notifications.rules.save-success.title')
      )
      history.push(navRoutes.rules)
    }
  }

  function _onMessageTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValues({
      ...inputValues,
      messageType: e.target.value === 'json' ? 'json' : 'text',
    })
  }

  if (ruleId !== 'new' && (inputValues.default || ruleLoading)) {
    return (
      <LocalWrapper>
        <InnerWrapper>
          <p>{i18n.text('app.loading')}</p>
        </InnerWrapper>
      </LocalWrapper>
    )
  }

  if (isEmpty(allAssetGroups)) {
    return (
      <LocalWrapper>
        <InnerWrapper>
          <p>{i18n.text('notifications.rules.no-assets')}</p>
        </InnerWrapper>
      </LocalWrapper>
    )
  }

  return (
    <LocalWrapper>
      <h1>
        {ruleId === 'new' ? i18n.text('notifications.rules-create.title') : i18n.text('notifications.rules-edit.title')}
        <StyledSubheading>{i18n.text('notifications.rules.description')}</StyledSubheading>
      </h1>
      <InnerWrapper>
        {!isEmpty(formErrors) && (
          <FormSectionInnerError>
            <ErrorContainer>
              {formErrors.map(err => (
                <ErrorMessage key={err}>{i18n.text(err)}</ErrorMessage>
              ))}
            </ErrorContainer>
          </FormSectionInnerError>
        )}
        {availableCommodityOptions.length > 1 && (
          <FormSection>
            <StyledLabel>{i18n.text('rules.headers.commodity-type')}</StyledLabel>
            <FormSectionInner>
              <RadioGroup
                options={availableCommodityOptions}
                onChange={setCommodityType}
                value={commodityType}
              />
            </FormSectionInner>
          </FormSection>
        )}
        {commodityType === 'electric-current' && (
          <FormSection>
            <StyledLabel>{i18n.text('rules.headers.asset-count')}</StyledLabel>
            <FormSectionInner>
              <RadioGroup
                options={[
                  { value: 1, label: i18n.text('generic.one') },
                  { value: 2, label: i18n.text('generic.two') },
                ]}
                onChange={(value: number) => _onAssetCountInputChange(value)}
                value={triggers.length}
              />
            </FormSectionInner>
          </FormSection>
        )}
        <FormSection>
          <StyledLabel>{i18n.text('rules.headers.trigger-conditions')}</StyledLabel>
          <FormSectionInner>
            <div>
              {triggers.map(({ assetSelection: asset, trigger, id }, index) => {
                return (
                  <div key={id}>
                    {index - 1 >= 0 && <TriggerSeparator>{i18n.text('rules.condition.infix.and')}</TriggerSeparator>}
                    <AssetTrigger
                      id={id}
                      i18n={i18n}
                      assets={availableAssetGroups}
                      selectedAsset={asset}
                      triggerOptions={thresholdOptions}
                      trigger={trigger}
                      onChange={_onAssetTriggerChange}
                      type={getAssetTriggerType()}
                    />
                  </div>
                )
              })}
              {ruleType === 'volumeThreshold' && (
                <>
                  <TriggerSeparator>{i18n.text('rules.condition.infix.per-volume-aggregation')}</TriggerSeparator>
                  <DurationPicker
                    i18n={i18n}
                    selected={{
                      duration: getDuration(volumetricAggregation).time,
                      durationUnit: getDuration(volumetricAggregation).unit,
                    }}
                    onChange={_onVolumetricAggregationChange}
                    durationUnitOptions={durationUnitOptions}
                  />
                </>
              )}
              <TriggerSeparator>{i18n.text('rules.condition.infix.for')}</TriggerSeparator>
              <DurationPicker
                i18n={i18n}
                selected={{ duration, durationUnit }}
                onChange={_onDurationChange}
                durationUnitOptions={durationUnitOptions}
              />
            </div>
          </FormSectionInner>
        </FormSection>
        <FormSection>
          <StyledLabel>{i18n.text('rules.headers.message')}</StyledLabel>
          <FormSectionInner>
            <BreakGroup>
              <Group>
                <StyledLabel>{i18n.text('rules.headers.messageType')}</StyledLabel>
                <StyledDropdownSmall
                  id="message-type-select"
                  selected={messageType || getMessageType(message)}
                  options={[
                    { key: 'text', option: i18n.text('rules.messages.type.text') },
                    { key: 'json', option: i18n.text('rules.messages.type.json') },
                  ]}
                  onChange={_onMessageTypeChange}
                />
              </Group>
              <Group style={messageType !== 'json' ? {} : { verticalAlign: 'top' }}>
                <StyledLabel>{i18n.text('rules.headers.message')}</StyledLabel>
                {messageType !== 'json' ? (
                  <StyledInput
                    id="message-input"
                    key={`message-input-${rule.id}`}
                    type="text"
                    value={message}
                    placeholder={i18n.text('rules.placeholders.message')}
                    onChange={extractValueFromEvent(_onInputChange('message'))}
                  />
                ) : (
                  <GenericJsonEditor
                    id="json-message-input"
                    json={message}
                    height="200px"
                    width="500px"
                    onChange={({ json }: { json: string }) => {
                      // Path the event so we can reuse existing functions
                      const event = { target: { value: json }, persist: (f: boolean) => f }
                      extractValueFromEvent(_onInputChange('message'))(event)
                    }}
                  />
                )}
              </Group>
            </BreakGroup>
          </FormSectionInner>
        </FormSection>
        <FormSection>
          <StyledLabel>{i18n.text('rules.headers.time')}</StyledLabel>
          <Description>{i18n.text('rules.description.time')}</Description>
          <FormSectionInner>
            <IntervalEditor
              key={`interval-editor-${rule.id}`}
              i18n={i18n}
              selected={intervals}
              onChange={_onIntervalsChange}
            />
          </FormSectionInner>
        </FormSection>
        <FormSection>
          <StyledLabel>{i18n.text('rules.headers.recipients')}</StyledLabel>
          <FormSectionInner>
            <RecipientEditor
              key={`recipient-editor-${rule.id}`}
              i18n={i18n}
              selected={recipients}
              onChange={_onRecipientsChange}
              transportOptions={transportOptions}
            />
          </FormSectionInner>
        </FormSection>
        <ButtonWrapper>
          <div>
            <Button
              id="rule-cancel-button"
              onClick={() => history.go(-1)}
              size="medium"
            >
              <ButtonLabel>{i18n.text('rules.form.cancel')}</ButtonLabel>
            </Button>
          </div>
          <div>
            <Button
              appearance="primary"
              id="rule-save-button"
              onClick={_onSaveRule}
              size="medium"
            >
              <ButtonLabel>
                {createLoading || updateLoading ? i18n.text('generic.saving') : i18n.text('rules.form.save')}
              </ButtonLabel>
            </Button>
          </div>
        </ButtonWrapper>
      </InnerWrapper>
    </LocalWrapper>
  )
}

export default withRouter(RuleEditor)
