import { Alert, styled } from '@mui/material'

export const StyledAlert = styled(Alert)(({ theme }) => ({
  width: '27rem',
  '& .MuiAlert-icon': {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.SFIWarning[600],
  },
  '&.MuiAlert-standardError': {
    backgroundColor: theme.palette.SFIOrange[50],
    color: theme.palette.SFIOrange[600],
  },
  '&.MuiAlert-standardWarning': {
    backgroundColor: theme.palette.SFIWarning[50],
    color: theme.palette.SFIWarning[600],
  },
}))
