import Navigation from '../components/Navigation'
import { OnboardingPath } from '../models'
import { StyledContent } from '../components/Wrapper'
import { useProgressContext } from '../context/ProgressContext'

export default function InviteMembers() {
  const { navigateStage } = useProgressContext();

  const handleBackAction = () => {
    navigateStage(OnboardingPath.COMPANY_DETAILS)
  };
  const handleNextAction = () => {
    navigateStage(OnboardingPath.TECHNICAL_INFO)
  }

  return (
    <>
      <StyledContent>Invite Members</StyledContent>
      <Navigation
        backAction={handleBackAction}
        nextAction={handleNextAction}
      />
    </>
  )
}
