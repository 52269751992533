import { MutableRefObject, useLayoutEffect } from 'react'

import useAnalytics from './useAnalytics/useAnalytics'
import { AnalyticsEventTypes, DefaultEventParams } from './useAnalytics/analyticsTypes'

const MILLISECONDS_IN_MINUTE = 2000

// This hook helps us send a GA tracking event only
// if users hover on an element for more a defined minimum

export function useTrackHoveredElement<T extends DefaultEventParams>({
  eventName,
  eventParams,
  elementRef,
  isLoading,
  duration = MILLISECONDS_IN_MINUTE,
}: {
  eventName: AnalyticsEventTypes
  eventParams: T
  elementRef: MutableRefObject<HTMLElement | undefined>
  isLoading?: boolean
  duration?: number
}) {
  const { sendEvent } = useAnalytics()

  let start = 0 // milliseconds when we start tracking time

  useLayoutEffect(() => {
    if (!isLoading) {
      elementRef.current?.addEventListener('mouseenter', startTrackingTime)
      elementRef.current?.addEventListener('mouseleave', stopTrackingTime)
    }
    return () => {
      elementRef.current?.removeEventListener('mouseenter', startTrackingTime)
      elementRef.current?.removeEventListener('mouseleave', stopTrackingTime)
    }
  }, [isLoading])

  function startTrackingTime() {
    start = Date.now()
  }

  function stopTrackingTime() {
    if (!start) return
    const passedMillis = Date.now() - start // variable to handle milliseconds passed after tracking time started
    if (passedMillis >= duration) {
      sendEvent<T>(eventName, eventParams)
      start = 0
    }
  }
}
