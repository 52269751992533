import moment from 'moment'

import { ProductionTime } from './types'

export const sortPeriods = (periods: { start: moment.Moment; end: moment.Moment; index: number }[]) => {
  return periods.sort((a, b) => (a.start.isBefore(b.start) ? -1 : 1))
}

export const productionTimesToPeriods = (productionTimes: ProductionTime[]) => {
  // Monday 10th January 2022
  const REFERENCE_WEEK_START = moment.utc('2022-01-10T00:00:00.000', moment.ISO_8601)

  const periods = productionTimes.map((item, index) => ({
    start: REFERENCE_WEEK_START.clone()
      .add(item.fromDay - 1, 'days')
      .add(item.fromHour, 'hours')
      .add(item.fromMinute, 'minutes'),
    end: REFERENCE_WEEK_START.clone()
      .add(item.toDay - 1, 'days')
      .add(item.toHour, 'hours')
      .add(item.toMinute, 'minutes'),
    index: index,
  }))

  return periods
}

const validateProductionTimesInput = (
  productionTimes: ProductionTime[]
): { success: boolean; errorAtIndex?: number[]; type?: string; message?: string } => {
  if (!productionTimes || productionTimes.length === 0) {
    return { success: false, type: 'required', message: 'Intervals cannot be empty.' }
  }

  const periods = productionTimesToPeriods(productionTimes)

  for (let i = 0; i < periods.length; i++) {
    const period = periods[i]

    if (period.end.isBefore(period.start)) {
      return {
        success: false,
        errorAtIndex: [period.index],
        type: 'invalid',
        message: 'Beginning cannot come after the end date.',
      }
    }
  }

  if (periods.length < 2) {
    return { success: true }
  }

  const sorted = sortPeriods(periods)

  for (let i = 1; i < sorted.length; i++) {
    const current = sorted[i]
    const previous = sorted[i - 1]

    if (current.start.isSameOrBefore(previous.end)) {
      return {
        success: false,
        errorAtIndex: [previous.index, current.index],
        type: 'overlap',
        message: 'Intervals must not overlap.',
      }
    }
  }

  return { success: true }
}

export default validateProductionTimesInput
