import React, { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'

import {
  BaseAnchorButtonProps,
  CompareDateRange,
  ComparingDatesStatuses,
  DateFormat,
  DateRangePickerShortCut,
  FromToValues,
  IDateTimeRangePickerProps,
} from '../types'
import { DateRange } from '../../../../../types/analysis_types'
import { createCtx } from '../../../../../utils'
import { defaultShortcutsItems } from '../../../../../constants/defaultDateRangePickerShortcuts'
import { useI18nContext } from '../../../../../contexts/i18nContext/I18nContext'

const defaultDateFormat = {
  day: '2-digit',
  month: 'short',
  year: '2-digit',
}

interface IDateTimeRangePickerContextProps<T extends BaseAnchorButtonProps> extends IDateTimeRangePickerProps<T> {
  children: React.ReactNode
}

interface IDateTimeRangePickerContextValues {
  initialFromTo: FromToValues
  initialCompareFromTo?: FromToValues
  fromDate: DateTime | null
  toDate: DateTime | null
  comparedFromDate: DateTime | null
  comparedToDate: DateTime | null
  currentDate: DateTime | null
  isTimePicker: boolean
  isCompareDatesOn: boolean
  activeShortcutIndex: number | null
  settingFromDate: boolean
  dateFormat: DateFormat
  isAutomaticCompareDates: boolean
  hasPrevNextPeriodButtons: boolean
  showCompareDatesButton: boolean
  shortcutsItems: DateRangePickerShortCut[]
  error: string
  compareStatusesRef: React.MutableRefObject<ComparingDatesStatuses>
  isZoomed?: boolean
  onDatesChange: (selection: DateRange, zooming?: boolean) => void
  onCompareDatesChange?: (selection: CompareDateRange, zooming?: boolean) => void
  onCompareDatesToggle?: (isSwitchedOn?: boolean) => void
  handleFromToValues: (fromToValues: FromToValues) => void
  handleComparedFromToValues: (fromToValues: FromToValues) => void
  setActiveShortcutIndex: React.Dispatch<React.SetStateAction<number | null>>
  setToDate: React.Dispatch<React.SetStateAction<DateTime | null>>
  setFromDate: React.Dispatch<React.SetStateAction<DateTime | null>>
  setComparedToDate: React.Dispatch<React.SetStateAction<DateTime | null>>
  setComparedFromDate: React.Dispatch<React.SetStateAction<DateTime | null>>
  setCurrentDate: React.Dispatch<React.SetStateAction<DateTime | null>>
  setFromDateStatus: React.Dispatch<React.SetStateAction<boolean>>
  setIsCompareDatesOn: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<React.SetStateAction<string>>
  setIsZoomed?: React.Dispatch<React.SetStateAction<boolean>>
}

const [useDateTimeRangePickerContext, DateTimeRangePickerContextReactProvider] =
  createCtx<IDateTimeRangePickerContextValues>()

export function DateTimeRangePickerContextProvider<T extends BaseAnchorButtonProps>({
  children,
  startDate,
  endDate,
  compareFromToValues,
  dateFormat,
  isTimePicker,
  isCompareDatesOn: initialCompareDatesOnState,
  isAutomaticCompareDates = true,
  hasPrevNextPeriodButtons = true,
  showCompareDatesButton = true,
  shortcutsItems,
  isZoomed,
  isResetComparedIntervalOnZooming,
  zoomedDates,
  onDatesChange,
  onCompareDatesChange,
  onCompareDatesToggle,
  setIsZoomed,
  setZoomedDates,
}: IDateTimeRangePickerContextProps<T>) {
  const today = DateTime.now()
  const aWeekAgo = today.minus({ days: 7 })
  const { i18n } = useI18nContext()
  const [error, setError] = useState('')
  const [isCompareDatesOn, setIsCompareDatesOn] = useState(!!initialCompareDatesOnState)

  const initialValues = startDate && endDate ? { from: startDate, to: endDate } : { from: aWeekAgo, to: today }
  const initialCompareValues = compareFromToValues
  const [fromDate, setFromDate] = useState<DateTime | null>(initialValues.from)
  const [toDate, setToDate] = useState<DateTime | null>(initialValues.to)
  const [comparedFromDate, setComparedFromDate] = useState<DateTime | null>(null)
  const [comparedToDate, setComparedToDate] = useState<DateTime | null>(null)
  const [currentDate, setCurrentDate] = useState<DateTime | null>(toDate ? toDate : fromDate)
  const [activeShortcutIndex, setActiveShortcutIndex] = useState<number | null>(null)
  const [settingFromDate, setFromDateStatus] = useState(true)

  const compareStatusesRef = useRef<ComparingDatesStatuses>({
    from: true,
    to: true,
    compareFrom: true,
    compareTo: true
  })

  useEffect(() => {
    setError('')
    setFromDateStatus(true)
    compareStatusesRef.current = {
      from: true,
      to: true,
      compareFrom: true,
      compareTo: true
    }
    setComparedFromDate(null)
    setComparedToDate(null)
    if (onCompareDatesChange) {
      onCompareDatesChange({
        startDate: null,
        endDate: null,
      })
    }
  }, [isCompareDatesOn])

  const handleFromToValues = (newValues: FromToValues) => {
    setFromDate(newValues.from)
    setToDate(newValues.to)
  }

  const handleComparedFromToValues = (newValues: FromToValues) => {
    setComparedFromDate(newValues.from)
    setComparedToDate(newValues.to)
  }

  useEffect(() => {
    if (zoomedDates?.from && zoomedDates?.to) {
      setIsCompareDatesOn(false)
      if (isResetComparedIntervalOnZooming) {
        handleComparedFromToValues({ from: null, to: null })
      }
      handleFromToValues(zoomedDates)
      if (setZoomedDates)
        setZoomedDates({
          from: null,
          to: null,
        })
    }
  }, [zoomedDates])

  return (
    <DateTimeRangePickerContextReactProvider
      value={{
        initialFromTo: initialValues,
        initialCompareFromTo: initialCompareValues,
        fromDate,
        toDate,
        comparedFromDate,
        comparedToDate,
        currentDate,
        isTimePicker: !!isTimePicker,
        isCompareDatesOn: !!isCompareDatesOn,
        activeShortcutIndex,
        settingFromDate,
        isAutomaticCompareDates,
        dateFormat: dateFormat || defaultDateFormat,
        hasPrevNextPeriodButtons: !!hasPrevNextPeriodButtons,
        showCompareDatesButton: !!showCompareDatesButton,
        shortcutsItems: shortcutsItems || defaultShortcutsItems(i18n),
        error,
        setError,
        onDatesChange,
        onCompareDatesChange,
        handleFromToValues,
        onCompareDatesToggle,
        setToDate,
        setFromDate,
        setComparedFromDate,
        setComparedToDate,
        setActiveShortcutIndex,
        setCurrentDate,
        setFromDateStatus,
        setIsCompareDatesOn,
        handleComparedFromToValues,
        compareStatusesRef,
        isZoomed,
        setIsZoomed,
      }}
    >
      {children}
    </DateTimeRangePickerContextReactProvider>
  )
}

export { useDateTimeRangePickerContext }
