import { Interval } from 'luxon'
import { useCallback } from 'react'

import { calculateZoom } from '../../Shared/utils/calculateZoom'

/**
 * Calculates zoom level; more stable than Apex implementation
 * @returns xaxis min, max value that will be used for zooming when using buttons
 *  or Apex calculated levels when using mouse drag zoom
 */
const useApexBeforeZoom = (interval: Interval) => {
  return useCallback(
    (chartContext: any, { xaxis }: { xaxis: { min: number; max: number } }) => {
      const { zoomPanSelection } = chartContext.ctx
      const { dragX } = zoomPanSelection
      const isMouseZoom = dragX !== 0
      if (isMouseZoom) {
        return xaxis
      }
      const isZoomIn = xaxis.max - xaxis.min < interval.end.toMillis() - interval.start.toMillis()
      return calculateZoom(interval, isZoomIn)
    },
    [interval.start, interval.end]
  )
}

export { useApexBeforeZoom }
