import { Box, SvgIconProps, SxProps, styled } from '@mui/material'
import { ComponentType } from 'react'

import { SFIndigoMuiTheme as theme } from '../../theme/MuiThemeProvider'

type IconWrapperProps = { active: boolean; sx?: SxProps, color?: string }
type IconWrapperPropsWithIcon = IconWrapperProps & { Icon: ComponentType<SvgIconProps> }

const Wrapper = styled(Box, { shouldForwardProp: prop => prop !== 'active' })<IconWrapperProps>(
  ({ theme, active }) => ({
    padding: '0.625rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0.0625rem solid',
    borderRadius: '0.5rem',
    borderColor: active ? theme.palette.SFIBrand[300] : theme.palette.SFIGreyLight[200],
    boxShadow: active
      ? '0 0.0625rem 0.0125rem 0 rgba(16, 24, 40, 0.05), 0 0 0 0.25rem rgba(29, 122, 253, 0.24)'
      : '0 0.0625rem 0.0125rem 0 rgba(16, 24, 40, 0.05)',
    width: 'fit-content',
  })
)

export default function IconFrame({ active, Icon, sx, color }: IconWrapperPropsWithIcon) {
  return (
    <Wrapper
      active={active}
      sx={sx}
    >
      <Icon
        fontSize="small"
        htmlColor={color || theme.palette.background.default}
        stroke={active ? theme.palette.secondary.main : theme.palette.SFIGreyLight[700]}
      />
    </Wrapper>
  )
}
