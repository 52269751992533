import { createSvgIcon } from '@mui/material'

export const EyeIcon = createSvgIcon(
  <svg
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.92012 13.6932C2.78394 13.4775 2.71584 13.3697 2.67772 13.2034C2.64909 13.0785 2.64909 12.8815 2.67772 12.7566C2.71584 12.5903 2.78394 12.4824 2.92012 12.2668C4.04553 10.4848 7.3954 5.97998 12.5004 5.97998C17.6054 5.97998 20.9553 10.4848 22.0807 12.2668C22.2169 12.4824 22.285 12.5903 22.3231 12.7566C22.3517 12.8815 22.3517 13.0785 22.3231 13.2034C22.285 13.3697 22.2169 13.4775 22.0807 13.6932C20.9553 15.4751 17.6054 19.98 12.5004 19.98C7.3954 19.98 4.04553 15.4751 2.92012 13.6932Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5004 15.98C14.1573 15.98 15.5004 14.6368 15.5004 12.98C15.5004 11.3231 14.1573 9.97998 12.5004 9.97998C10.8435 9.97998 9.5004 11.3231 9.5004 12.98C9.5004 14.6368 10.8435 15.98 12.5004 15.98Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'eye-icon'
)
