import ReactApexChart from 'react-apexcharts'
import { DateTime, Interval } from 'luxon'
import { FC, useLayoutEffect, useMemo, useState } from 'react'

import { ApexWrapper } from '../ApexWrapper'
import { ElectricityDataSeries, ValuesModifier } from '../../types'
import { useApexBeforeZoom } from '../../hooks/useApexBeforeZoom'
import { useChartToolsContext } from '../../context/ChartToolsContext'
import { usePowerChartOptions } from './powerChartOptions'

export interface PowerChartProps {
  interval: Interval
  data: ElectricityDataSeries
  valuesModifier: ValuesModifier
  showMean: boolean
  showWaste: boolean
  showOop: boolean
  showPreviousPeriod: boolean
  handleZoomChange: (chart: unknown, changed: { xaxis: { min: number; max: number } }) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tooltipFormatter: any // Apex has incorrect types for this
  loading: boolean
}

const PowerChart: FC<PowerChartProps> = ({
  data,
  interval,
  valuesModifier,
  handleZoomChange,
  tooltipFormatter,
  showMean,
  loading,
}) => {
  const [responsiveHeight, setResponsiveHeight] = useState(0)
  const [responsiveGraphWidth, setReponsiveGraphWidth] = useState(0)
  const { decimal } = useChartToolsContext()

  const earliestDate = useMemo(() => {
    let earliest = interval.start.toMillis()
    if (data) {
      const firstSeries = data[0]
      if (firstSeries) {
        const firstPoint = firstSeries.data[0]
        if (firstPoint) {
          earliest = DateTime.fromISO(firstPoint.x).toMillis()
        }
      }
    }
    return earliest
  }, [data, interval.start])

  const meanAnnotations = useMemo(() => {
    if (!showMean) return []

    const annotations = data
      .map(series => {
        if (!series.mean) return null
        return {
          y: series.mean.toFixed(decimal),
          strokeDashArray: 0,
          borderColor: series?.color || 'black',
          label: {
            borderColor: series?.color || 'black',
            style: {
              color: '#fff',
              background: series?.color || '#775DD0',
            },
            text: `Mean: ${series.mean.toFixed(decimal)} ${valuesModifier.unit}`,
          },
        }
      })
      .filter(s => !!s)
    return annotations as YAxisAnnotations[]
  }, [data, showMean, valuesModifier.unit, decimal])

  const beforeZoom = useApexBeforeZoom(interval)

  const dashArray = useMemo(
    () =>
      data.map(s => {
        if (s.isPreviousPeriod) {
          return 3
        }
        return 0
      }),
    [data]
  )

  const options = usePowerChartOptions({
    earliestDate,
    interval,
    tooltipFormatter,
    handleZoomChange,
    beforeZoom,
    meanAnnotations,
    valuesModifier,
    decimal,
    loading,
    dashArray,
  })

  useLayoutEffect(() => {
    const handleResize = () => {
      const el = document.getElementById('chart-wrapper')
      const graphHeight = el?.getBoundingClientRect().height || 0
      setResponsiveHeight(graphHeight)
    }
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setResponsiveHeight])

  useLayoutEffect(() => {
    const handleChange: ResizeObserverCallback = entries => {
      for (const entry of entries) {
        if (entry.contentRect) {
          const graphWidth = entry.contentRect.width
          setReponsiveGraphWidth(graphWidth)
        }
      }
    }
    const el = document.getElementById('chart-wrapper')
    const observer = new ResizeObserver(handleChange)
    if (el) {
      observer.observe(el)
    }
    return () => {
      observer.disconnect()
    }
  })

  return (
    <ApexWrapper>
      <ReactApexChart
        type="line"
        options={options}
        series={data}
        height={responsiveHeight}
        width={responsiveGraphWidth}
      />
    </ApexWrapper>
  )
}

export { PowerChart }
