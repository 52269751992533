/* eslint-disable max-len */
const translations = [
  {
    id: '59f85c68737b0b001d50096f',
    key: 'analysis.energy-balance.energy-consumption',
    en_GB: 'Energy Consumption [kWh]',
    nl_NL: 'Energieverbruik [kWh]',
    de_DE: 'Energy Consumption [kWh]',
    it_IT: 'Energy Consumption [kWh]',
    es_ES: 'Consumo energético [kWh]',
    fr_FR: "Consommation d'énergie [kWh]",
  },
  {
    id: '59f85caa737b0b001d500970',
    key: 'analysis.energy-balance.energy-costs',
    en_GB: 'Energy Costs [EUR]',
    nl_NL: 'Energiekosten [EUR]',
    de_DE: 'Energy Costs [EUR]',
    it_IT: 'Energy Costs [EUR]',
    es_ES: 'Costes energéticos [EUR]',
    fr_FR: 'Coût énergétique [EUR]',
  },
  {
    id: '5a0029fb45eb37001824b97a',
    key: 'analysis.energy-balance.no-data',
    en_GB: 'No data',
    nl_NL: 'Geen data',
    de_DE: 'No data',
    it_IT: 'No data',
    es_ES: 'Sin datos',
    fr_FR: 'Pas de données',
  },
  {
    id: '59f20c177f649900170b33be',
    key: 'branding.app',
    en_GB: '{app}',
    nl_NL: '{app}',
    de_DE: '{app}',
    it_IT: '{app}',
    es_ES: '{app}',
    fr_FR: '{app}',
  },
  {
    id: '59f20c177f649900170b33b0',
    key: 'errors.generic.title',
    en_GB: 'Uh-oh!',
    nl_NL: 'Oeps!',
    de_DE: 'Uh-oh!',
    it_IT: 'Oups!',
    es_ES: 'Ups!',
    fr_FR: 'Uh-oh!',
  },
  {
    id: '59f20c177f649900170b33fb',
    key: 'feedback.close',
    en_GB: 'Close panel',
    nl_NL: 'Sluit scherm',
    de_DE: 'Panel Schließen',
    it_IT: 'Fermer le panneau',
    es_ES: 'Cerrar la ventana',
    fr_FR: 'Panneau fermé',
  },
  {
    id: '59f20c177f649900170b33ac',
    key: 'feedback.input.email',
    en_GB: 'Your email',
    nl_NL: 'Your email',
    de_DE: 'Your email',
    it_IT: 'Your email',
    es_ES: 'Tu email',
    fr_FR: 'Votre e-mail',
  },
  {
    id: '59f20c177f649900170b33f3',
    key: 'feedback.received',
    en_GB: 'We received your feedback.',
    nl_NL: 'Wij hebben uw feedback ontvangen.',
    de_DE: 'Wir haben Ihr Feedback erhalten.',
    it_IT: 'Nous avons bien reçu vos commentaires.',
    es_ES: 'Hemos recibido sus comentarios.',
    fr_FR: 'Nous avons reçu vos commentaires.',
  },
  {
    id: '59f20c177f649900170b33b9',
    key: 'forms.error',
    en_GB: 'Error',
    nl_NL: 'Fout',
    de_DE: 'Fehler',
    it_IT: 'Erreur',
    es_ES: 'Error',
    fr_FR: 'Erreur',
  },
  {
    id: '59f20c177f649900170b33c0',
    key: 'forms.login.password',
    en_GB: 'Password',
    nl_NL: 'Wachtwoord',
    de_DE: 'Passwort',
    it_IT: 'Mot de passe',
    es_ES: 'Contraseña',
    fr_FR: 'Mot de passe',
  },
  {
    id: '59f20c177f649900170b33b3',
    key: 'forms.login.remember',
    en_GB: 'Remember me',
    nl_NL: 'Onthoud mijn account',
    de_DE: 'Angemeldet bleiben',
    it_IT: 'Rester connecté',
    es_ES: 'Seguir conectado',
    fr_FR: 'Rester connecté',
  },
  {
    id: '59f20c177f649900170b33ce',
    key: 'forms.login.submit',
    en_GB: 'Sign in',
    nl_NL: 'Sign in',
    de_DE: 'Sign in',
    it_IT: 'Sign in',
    es_ES: 'Acceso',
    fr_FR: 'Se connecter',
  },
  {
    id: '59f20c177f649900170b33c4',
    key: 'forms.login.username',
    en_GB: 'email',
    nl_NL: 'email',
    de_DE: 'E-Mail',
    it_IT: 'email',
    es_ES: 'email',
    fr_FR: 'e-mail',
  },
  {
    id: '59f20c177f649900170b340d',
    key: 'forms.profile.change-password',
    en_GB: 'Change password',
    nl_NL: 'Wijzig wachtwoord',
    de_DE: 'Passwort ändern',
    it_IT: 'Changer le mot de passe',
    es_ES: 'Canviar la contraseña',
    fr_FR: 'Changer le mot de passe',
  },
  {
    id: '59f20c177f649900170b33e2',
    key: 'forms.profile.confirm-password',
    en_GB: 'Confirm password',
    nl_NL: 'Bevestig wachtwoord',
    de_DE: 'Passwort bestätigen',
    it_IT: 'Confirmez le mot de passe',
    es_ES: 'Confirme la contraseña',
    fr_FR: 'Confirmez le mot de passe',
  },
  {
    id: '59f20c177f649900170b33b1',
    key: 'forms.profile.current-password',
    en_GB: 'Current password',
    nl_NL: 'Huidig wachtwoord',
    de_DE: 'Aktuelles Passwort',
    it_IT: 'Mot de passe actuel',
    es_ES: 'Contraseña actual',
    fr_FR: 'Mot de passe actuel',
  },
  {
    id: '59f20c177f649900170b33bb',
    key: 'forms.profile.email',
    en_GB: 'Email',
    nl_NL: 'Email',
    de_DE: 'E-Mail',
    it_IT: 'Email',
    es_ES: 'Email',
    fr_FR: 'E-mail',
  },
  {
    id: '59f20c177f649900170b33c5',
    key: 'forms.profile.firstName',
    en_GB: 'First name',
    nl_NL: 'Voornaam',
    de_DE: 'Vorname',
    it_IT: 'Prénom',
    es_ES: 'Nombre',
    fr_FR: 'Prénom',
  },
  {
    id: '59f20c177f649900170b33b4',
    key: 'forms.profile.lastName',
    en_GB: 'Last name',
    nl_NL: 'Achternaam',
    de_DE: 'Name / Familienname',
    it_IT: 'Nom',
    es_ES: 'Apellido',
    fr_FR: 'Nom de famille',
  },
  {
    id: '59f20c177f649900170b33d9',
    key: 'forms.profile.new-password',
    en_GB: 'New password',
    nl_NL: 'Nieuw wachtwoord',
    de_DE: 'Neues Passwort',
    it_IT: 'Nouveau mot de passe',
    es_ES: 'Nueva contraseña',
    fr_FR: 'Nouveau mot de passe',
  },
  {
    id: '59f20c177f649900170b33b8',
    key: 'forms.profile.password.updated',
    en_GB: 'Password has been updated',
    nl_NL: 'Wachtwoord is bijgewerkt',
    de_DE: 'Kennwort wurde aktualisiert',
    it_IT: 'Mot de passe mis à jour',
    es_ES: 'La contraseña ha sido actualizada',
    fr_FR: 'Le mot de passe a été mis à jour',
  },
  {
    id: '59f20c177f649900170b33ba',
    key: 'forms.profile.submit',
    en_GB: 'Update',
    nl_NL: 'Bijwerken',
    de_DE: 'Update',
    it_IT: 'Metre á jour',
    es_ES: 'Actualizado',
    fr_FR: 'Mise à jour',
  },
  {
    id: '59f20c177f649900170b33cd',
    key: 'forms.profile.updated',
    en_GB: 'Profile updated',
    nl_NL: 'Profiel bijgewerkt',
    de_DE: 'Profil auf dem neuesten Stand',
    it_IT: 'Profil mis à jour',
    es_ES: 'Perfil actualizado',
    fr_FR: 'Profil mis à jour',
  },
  {
    id: '59f20c177f649900170b33c1',
    key: 'forms.reset-password.email',
    en_GB: 'Email',
    nl_NL: 'Email',
    de_DE: 'E-mail',
    it_IT: 'Email',
    es_ES: 'Email',
    fr_FR: 'E-mail',
  },
  {
    id: '59f20c177f649900170b33dd',
    key: 'forms.reset-password.email-sent',
    en_GB:
      'We have sent a password reset link to the email address of the account that you specified. Please check your email for this message, then click on the link.',
    nl_NL:
      'Wij hebben een wachtwoord herstel link gestuurd naar het door u opgegeven mailadres. Bekijk uw email en klik op de link in de mail.',
    de_DE:
      'Wir haben Ihnen einen Link zum Zurücksetzen des Passwortes an die E-Mail-Adresse ihres angegebenen Kontos geschickt. Bitte überprüfen Sie ihr E-Mail-Konto af diese Nachricht, und klicken Sie den Link.',
    it_IT:
      'Nous avons envoyé un lien de réinitialisation de mot de passe à votre adresse e-mail.Veuillez vérifier votre boîte mail pour ce message, puis cliquez sur le lien.',
    es_ES:
      'Hemos enviado un enlace para restablecer la contraseña al email de la cuenta que han especificado. Por favor, compruebe su correo y haga click en el enlace.',
    fr_FR:
      "Nous avons envoyé un lien de réinitialisation de mot de passe vers l'adresse e-mail du compte que vous avez spécifié. Veuillez vérifier votre e-mail pour ce message, puis cliquez sur le lien.",
  },
  {
    id: '59f20c177f649900170b33ef',
    key: 'forms.reset-password.not-match-error',
    en_GB: "Provided passwords don't match",
    nl_NL: 'Opgegeven wachtwoord komt niet overeen',
    de_DE: 'Geleistete Passwörter stimmen nicht überein',
    it_IT: 'Les mots de passe fournis ne sont pas identiques',
    es_ES: 'Las contraseñas proporcionadas no son identicas',
    fr_FR: 'Les mots de passe fournis ne correspondent pas',
  },
  {
    id: '59f20c177f649900170b33bd',
    key: 'forms.reset-password.submit',
    en_GB: 'Request password reset',
    nl_NL: 'Vraag een wachtwoord herstel aan',
    de_DE: 'Neues Passwort anfordern',
    it_IT: 'Demander nouveau mots de passe',
    es_ES: 'Petición para recuperar contraseña',
    fr_FR: 'Demander la réinitialisation du mot de passe',
  },
  {
    id: '59f20c177f649900170b33e4',
    key: 'generic.name',
    en_GB: 'Name',
    nl_NL: 'Naam',
    de_DE: 'Name',
    it_IT: 'Nom',
    es_ES: 'Nom',
    fr_FR: 'Nom',
  },
  {
    id: '59f20c177f649900170b33d4',
    key: 'graph.analysis.auto-refresh',
    en_GB: 'Auto-refresh every min.',
    nl_NL: 'Iedere minuut autom. verversen',
    de_DE: 'Automatisches Auffrischen jede Minute',
    it_IT: 'Auto-refresh toutes les minutes.',
    es_ES: 'Autorefrescar cada minuto.',
    fr_FR: 'Rafraîchissement automatique toutes les minutes',
  },
  {
    id: '59f20c177f649900170b33e1',
    key: 'graph.measurements.components',
    en_GB: 'Components',
    nl_NL: 'Onderdelen',
    de_DE: 'Komponenten',
    it_IT: 'Composants',
    es_ES: 'Componentes',
    fr_FR: 'Composants',
  },
  {
    id: '59f20c177f649900170b33b5',
    key: 'graph.measurements.components.select',
    en_GB: 'Select one or more component(s)',
    nl_NL: 'Selecteer één of meer onderdelen',
    de_DE: 'Wählen Sie eine oder mehrere Komponente (n) aus',
    it_IT: 'Sélectionnez un ou plusieurs composant (s)',
    es_ES: 'Selecciona uno o más componentes',
    fr_FR: 'Sélectionnez un ou plusieurs composants (s)',
  },
  {
    id: '59f20c177f649900170b3408',
    key: 'graph.measurements.components.select.not-allowed',
    en_GB: 'You are not allowed to select component while comparing machines',
    nl_NL: 'Het is niet toegestaan componenten te selecteren wanneer machines worden vergeleken',
    de_DE: 'Sie dürfen keine Komponenten auswählen, während Sie Maschinen vergleichen',
    it_IT: "Vous n'êtes pas autorisé à sélectionner un composant lors de la comparaison de plusieurs machines",
    es_ES: 'No está autorizado a seleccionar un componente mientras está comparando máquinas',
    fr_FR: "Vous n'êtes pas autorisé à sélectionner les composants tout en comparant les machines",
  },
  {
    id: '59f20c177f649900170b33e7',
    key: 'graph.measurements.energy',
    en_GB: 'Energy',
    nl_NL: 'Energie',
    de_DE: 'Energie',
    it_IT: 'Energy',
    es_ES: 'Energia',
    fr_FR: 'Énergie',
  },
  {
    id: '59f20c177f649900170b3399',
    key: 'graph.quick-filter.day',
    en_GB: '24 h',
    nl_NL: '24 uur',
    de_DE: '24 Stunden',
    it_IT: '24 h',
    es_ES: '24 h',
    fr_FR: '24 h',
  },
  {
    id: '59f20c177f649900170b339f',
    key: 'graph.quick-filter.month',
    en_GB: '1 m',
    nl_NL: '1 m',
    de_DE: '1 m',
    it_IT: '1 m',
    es_ES: '1 m',
    fr_FR: '1 m',
  },
  {
    id: '59f20c177f649900170b339b',
    key: 'graph.quick-filter.week',
    en_GB: '1 w',
    nl_NL: '1 w',
    de_DE: '1 w',
    it_IT: '1 s',
    es_ES: '1 s',
    fr_FR: '1 W',
  },
  {
    id: '59f20c177f649900170b3396',
    key: 'graph.quick-filter.year',
    en_GB: '1 Y',
    nl_NL: '1 j',
    de_DE: '1 j',
    it_IT: '1 an',
    es_ES: '1 año',
    fr_FR: '1 an',
  },
  {
    id: '59f20c177f649900170b33f8',
    key: 'graph.range.view.days',
    en_GB: 'Days',
    nl_NL: 'Dagen',
    de_DE: 'Tagen',
    it_IT: 'Jours',
    es_ES: 'Dias',
    fr_FR: 'Jours',
  },
  {
    id: '59f20c177f649900170b33f9',
    key: 'graph.range.view.hours',
    en_GB: 'Hours',
    nl_NL: 'Uren',
    de_DE: 'Stunden',
    it_IT: 'Heures',
    es_ES: 'Horas',
    fr_FR: 'Heures',
  },
  {
    id: '59f20c177f649900170b3397',
    key: 'graph.range.view.minutes',
    en_GB: 'Minutes',
    nl_NL: 'Minuten',
    de_DE: 'Minuten',
    it_IT: 'Minutes',
    es_ES: 'Minutos',
    fr_FR: 'Minutes',
  },
  {
    id: '59f20c177f649900170b33ad',
    key: 'graph.range.view.months',
    en_GB: 'Months',
    nl_NL: 'Maanden',
    de_DE: 'Monate',
    it_IT: 'Mois',
    es_ES: 'Mes',
    fr_FR: 'Mois',
  },
  {
    id: '59f20c177f649900170b33a3',
    key: 'info.component-level.description',
    en_GB:
      'When only one machine is selected and we measure its components, you are able to select the components. Simply select one machine and select the desired component by clicking on the buttons below the graph.',
    nl_NL:
      'Wanneer u een enkele machine heeft geselecteerd en wij ook de componenten hiervan bemeteren, is het mogelijk deze ook in te zien. Selecteer eenvoudig de gewenste machine en selecteer vervolgens de beschikbare componenten door op de knoppen onder aan de grafiek te klikken.',
    de_DE:
      'Wenn Sie eine einzelne Maschine auswählen und wir seine Komponenten Abmessen ist es auch möglich, die Komponenten zu sehen. Wählen Sie einfach die gewünschte Maschine und wählen Sie dann die Komponenten zur Verfügung, indem Sie den unteren Rand der Grafik Schaltflächen klicken.',
    it_IT:
      'Lorsque vous sélectionnez une seule machine et nous que mesurons ses composants, il est possible de les voir. Il suffit de sélectionner la machine désirée puis sélectionnez les composants disponibles en cliquant sur les boutons en bas du graphique.',
    es_ES:
      'Cuando una sola máquina es seleccionada y medimos los componentes, puedes seleccionarlos. Simplemente seleccione una sola máquina y seleccione el componente deseado haciendo click en los botones debajo del gráfico.',
    fr_FR:
      "Lorsqu'une seule machine est sélectionnée et que nous mesurons ses composants, vous pouvez sélectionner les composants. Sélectionnez simplement une machine et sélectionnez le composant souhaité en cliquant sur les boutons sous le graphique.",
  },
  {
    id: '59f20c177f649900170b33a9',
    key: 'info.component-level.heading',
    en_GB: 'Component level',
    nl_NL: 'Component niveau',
    de_DE: 'Komponente niveau',
    it_IT: 'Niveau composants',
    es_ES: 'Nivel componente',
    fr_FR: 'Niveau de composant',
  },
  {
    id: '59f20c177f649900170b339d',
    key: 'info.data-export.description',
    en_GB:
      'To enable further analysis or to view the raw measurements it is possible to export data to .csv. Select the .csv export button next to the « calendar » date selector. Select the week of which you want a data export. Select export to .csv. Your data is now being downloaded.',
    nl_NL:
      'Voor verdere analyses of om de ruwe data in te zien kunt u uw data exporteren naar .csv. Selecteerd de export knop naast de kalender zoekfunctie. Selecteer de week waarvan u data wilt exporteren. Uw .csv bestand word nu gedownload.',
    de_DE:
      'Zur weiteren Analyse oder die Rohdaten zu sehen, können Sie Ihre Daten in CSV exportieren. Wählen Sie graduierten Export-Knopf neben dem Kalender suchen. Wählen Sie die Woche, die Sie Daten exportieren möchten. Ihre CSV-Datei wird nun heruntergeladen.',
    it_IT:
      "Pour une analyse plus détaillées ou pour voir les données brutes, vous pouvez exporter vos données en format .csv. Sélectionnez le bouton d'exportation à côté du bouton de « recherche calendrier ». Sélectionnez la semaine que vous souhaitez exporter. Votre fichier .csv vas être maintenant téléchargé.",
    es_ES:
      'Para un analisis más detallado o ver los datos sin procesar, pueden exportar los datos en formato .csv. Seleccione el botón de exportación al lado del botón « calendario » y seleccione la semana que quieren exportar. Su fichero .csv será descargado.',
    fr_FR:
      "Pour permettre une analyse plus approfondie ou de visualiser les mesures brutes, il est possible d'exporter des données vers .csv. Cliquez  sur le bouton d'exportation .csv à côté du sélecteur de date. Sélectionnez la semaine pour laquelle vous souhaitez une exportation de données. Sélectionnez Exporter vers .csv. Vos données sont maintenant en cours de téléchargement.",
  },
  {
    id: '59f20c177f649900170b33a0',
    key: 'info.data-export.heading',
    en_GB: 'Export data',
    nl_NL: 'Data exporteren',
    de_DE: 'Daten exporteren',
    it_IT: 'Exportation des données',
    es_ES: 'Exportación de datos',
    fr_FR: 'Exporter des données',
  },
  {
    id: '59f20c177f649900170b33a4',
    key: 'info.machine-level.description',
    en_GB:
      'The graph is displayed on a machine level by default. Select multiple machines in the menu on the left to compare machines with eachother. Your machines are being categorised based on their type to facilitate benchmarking.',
    nl_NL:
      'De grafiek word standaard op machine niveau weergegeven. Selecteer meerdere machines in het linker menu om machines met elkaar te vergelijken. Standaard worden uw machines gesorteerd op type om vergelijken makkelijker te maken.',
    de_DE:
      'Standard erhalten Sie die Grafik auf der Maschinenebene dargestellt. Wählen Sie mehrere Maschinen im linken Menü vergleichen Maschinen miteinander. Standardsortierung Ihre Maschinen nach Typ Vergleich zu erleichtern.',
    it_IT:
      'Le graphique est par défaut au niveau de vos machine. Sélectionnez plusieurs machines dans le menu de gauche pour comparer vos machines. Par défaut vos machine seront triés par type.',
    es_ES:
      'El gráfico muestra por defecto a nivel máquina. Seleccione más maquinas en el menú de la izquierda para comparar. Por defecto sus maquinas serán clasificadas por tipos.',
    fr_FR:
      "Le graphique n'affiche qu'une machine par défaut. Sélectionnez plusieurs machines dans le menu de gauche pour comparer les machines les unes avec les autres. Vos machines sont classées en fonction de leur type pour faciliter l'analyse comparative.",
  },
  {
    id: '59f20c177f649900170b33aa',
    key: 'info.machine-level.heading',
    en_GB: 'Machine level',
    nl_NL: 'Machine niveau',
    de_DE: 'Maschinenniveau',
    it_IT: 'Niveau machine',
    es_ES: 'Nivel máquina',
    fr_FR: 'Niveau de la machine',
  },
  {
    id: '59f20c177f649900170b33fc',
    key: 'info.sensor-data.description',
    en_GB:
      'This page gives you insights in your sensor data. The data is displayed in kW (Power). We calculate the Power by multiplying the raw sensor data in Ampere (A) with an assumed Voltage and average Power Factor (P = I * V * PF)',
    nl_NL:
      'Deze pagina geeft u inzicht in de data van de sensoren. De data word weergegeven in kW (vermogen). Wij berekenen het vermogen door ruwe sensor data in Ampère te combineren met Voltage en een gemiddelde Power Factor (P = I * V * PF)',
    de_DE:
      'Diese Seite gibt Ihnen einen Einblick in die Daten von den Sensoren. Das Datenwort in kW (Leistung) gezeigt. Wir berechnen die Leistung, die von Sensorrohdaten in Ampere und eine mittlere Spannung und Leistungsfaktors Kombinieren (P = I * V * PF)',
    it_IT:
      'Cette page vous donne un aperçu des données des capteurs installés chez vous. Les données sont affichées en kW (Puissance). Nous calculons la Puissance en combinant des données de capteur brutes en ampères et une tension moyenne et de facteur de puissance (P = I * V * PF)',
    es_ES:
      'Esta página le muestra información sobre su sensor. Los datos se muestran en kW (potencia). Calculamos la potencia multiplicando los datos del sensor sin procesar en Amperios (A) con un voltaje asumido y un factor de potencia promedio (P = I * V * PF)',
    fr_FR:
      'Cette page vous donne un aperçu des données de vos capteurs. Les données sont affichées dans KW (puissance). Nous calculons la puissance en multipliant les données brutes du capteur en Ampère (a) avec une tension (v) supposée et un facteur de puissance moyen (P = i * v * pf)',
  },
  {
    id: '59f20c177f649900170b3400',
    key: 'info.sensor-data.heading',
    en_GB: 'Sensor Data',
    nl_NL: 'Data van sensoren',
    de_DE: 'Daten von Sensoren',
    it_IT: 'Donnée des capteurs',
    es_ES: 'Datos del sensor',
    fr_FR: 'Données du capteur',
  },
  {
    id: '59f20c177f649900170b3404',
    key: 'lists.items.deselect-all',
    en_GB: 'Deselect all',
    nl_NL: 'Deselecteer alles',
    de_DE: 'Alles abwählen',
    it_IT: 'Tout déselectionner',
    es_ES: 'Deseleccionar todo',
    fr_FR: 'Tout déselectionner',
  },
  {
    id: '59f20c177f649900170b3395',
    key: 'machine-details.power',
    en_GB: 'Power',
    nl_NL: 'Vermogen',
    de_DE: 'Leistung',
    it_IT: 'Puissance',
    es_ES: 'Potencia',
    fr_FR: 'Puissance',
  },
  {
    id: '59f20c177f649900170b33fa',
    key: 'messages.error.no-selected-assets.body',
    en_GB: 'Try selecting one or more assets to see the graph',
    nl_NL: 'Selecteer een of meerdere asset om de grafiek te zien',
    de_DE: 'Versuchen Sie, ein oder mehrere Assets auszuwählen, um die Grafik zu sehen',
    it_IT: 'Sélectionné une ou plusieurs machine pour voir le graphique',
    es_ES: 'Seleccione una o más máquinas para ver el gráfico',
    fr_FR: 'Essayez de sélectionner une ou plusieurs machines pour voir le graphique',
  },
  {
    id: '59f20c177f649900170b33ae',
    key: 'messages.error.no-selected-assets.header',
    en_GB: 'No selected assets',
    nl_NL: 'Geen assets geselecteerd',
    de_DE: 'Keine ausgewählten Assets',
    it_IT: 'Aucune machine sélectionné',
    es_ES: 'Ninguna máquina seleccionada',
    fr_FR: 'Aucune machine sélectionnée',
  },
  {
    id: '5a6f1090391e90001d27132b',
    key: 'policies.tracking.enable',
    en_GB: 'Enable Tracking',
    nl_NL: 'Tracking inschakelen',
    de_DE: 'Tracking aktivieren',
    it_IT: 'Activer le suivi',
    es_ES: 'Habilitar el seguimiento',
    fr_FR: 'Activer le suivi',
  },
  {
    id: '5a6f0fa8391e90001d27132a',
    key: 'policies.tracking.why',
    en_GB: 'We use cookies like Google Analytics to improve your experience of our product.',
    nl_NL: 'Wij gebruiken cookies zoals Google Analytics om uw ervaring van ons product te verbeteren.',
    de_DE: 'Wir verwenden Cookies wie Google Analytics, um Ihre Erfahrung mit unserem Produkt zu verbessern.',
    it_IT: "Nous utilisons des cookies comme Google Analytics afin d'améliorer l'expérience de notre produit.",
    es_ES: 'Utilizamos cookies como Google Analytics para mejorar la experiencia de nuestro producto.',
    fr_FR: 'Nous utilisons des cookies comme Google Analytics pour améliorer votre expérience de notre produit.',
  },
  {
    id: '59f20c177f649900170b3409',
    key: 'router.routes.analysis',
    en_GB: 'Analysis',
    nl_NL: 'Analyse',
    de_DE: 'Analyse',
    it_IT: 'Analyse',
    es_ES: 'Analisis',
    fr_FR: 'Analyse',
  },
  {
    id: '5d651a973eaaca0037b1a699',
    key: 'router.routes.installation',
    en_GB: 'Installation',
    nl_NL: 'Installatie',
    de_DE: 'Installation',
    it_IT: 'Installazione',
    es_ES: 'Instalación',
    fr_FR: 'Installation',
  },
  {
    id: '5addc7212afe12001ffb06d7',
    key: 'router.routes.notifications',
    en_GB: 'Alerts',
    nl_NL: 'Alerts',
    de_DE: 'Alerts',
    it_IT: 'Alerts',
    es_ES: 'Alertas',
    fr_FR: 'Alertes',
  },
  {
    id: '5ab0d10c2afe12001ffb06cb',
    key: 'router.routes.reports',
    en_GB: 'Reports',
    nl_NL: 'Rapporten',
    de_DE: 'Berichte',
    it_IT: 'Report',
    es_ES: 'Informe',
    fr_FR: 'Rapports',
  },
  {
    id: '59f84cb1737b0b001d50096e',
    key: 'router.sub-routes.energy-balance',
    en_GB: 'Energy Balance',
    nl_NL: 'Energiebalans',
    de_DE: 'Energy Balance',
    it_IT: 'Energy Balance',
    es_ES: 'Balance energético',
    fr_FR: 'Bilan énergétique',
  },
  {
    id: '4',
    key: 'setpassword.code',
    en_GB: 'security code',
    nl_NL: 'securitycode',
    de_DE: 'Sicherheitscode',
    it_IT: 'codice di sicurezza',
    es_ES: 'código de seguridad',
    fr_FR: 'code de sécurité',
  },
  {
    id: '42',
    key: 'setpassword2.code',
    en_GB: 'Security code',
    nl_NL: 'Securitycode',
    de_DE: 'Sicherheitscode',
    it_IT: 'Codice di sicurezza',
    es_ES: 'Código de seguridad',
    fr_FR: 'Code de sécurité',
  },
  {
    id: '3',
    key: 'setpassword.enter-code-header',
    en_GB: 'Enter the verification code you received by email and set your new password.',
    nl_NL: 'Enter the verification code you received by email and set your new password.',
    de_DE: 'Enter the verification code you received by email and set your new password.',
    it_IT: 'Enter the verification code you received by email and set your new password.',
    es_ES: 'Enter the verification code you received by email and set your new password.',
    fr_FR: 'Entrez le code de vérification que vous avez reçu par e-mail et définissez votre nouveau mot de passe.',
  },
  {
    id: '2',
    key: 'setpassword.enter-email',
    en_GB: 'Enter your email address below to receive a password reset code.',
    nl_NL: 'Enter your email address below to receive a password reset code.',
    de_DE: 'Enter your email address below to receive a password reset code.',
    it_IT: 'Enter your email address below to receive a password reset code.',
    es_ES: 'Enter your email address below to receive a password reset code.',
    fr_FR: 'Entrez votre adresse e-mail ci-dessous pour recevoir un code de réinitialisation de mot de passe.',
  },
  {
    id: '5',
    key: 'setpassword.success',
    en_GB: 'Done! you can now sign in to your account.',
    nl_NL: 'Klaar! U kunt zich nu aanmelden bij uw account.',
    de_DE: 'Erledigt! Sie können sich jetzt in Ihrem Konto anmelden.',
    it_IT: 'Fatto! Ora puoi accedere al tuo account.',
    es_ES: '¡Hecho! Ahora puede iniciar sesión en su cuenta.',
    fr_FR: "C'est fait ! Vous pouvez maintenant vous connecter à votre compte.",
  },
  {
    id: '1',
    key: 'setpassword.welcome-user',
    en_GB: 'Welcome to the Sensorfact app! Click next to set a password for your account.',
    nl_NL: 'Welcome to the Sensorfact app! Click next to set a password for your account.',
    de_DE: 'Welcome to the Sensorfact app! Click next to set a password for your account.',
    it_IT: 'Welcome to the Sensorfact app! Click next to set a password for your account.',
    es_ES: 'Welcome to the Sensorfact app! Click next to set a password for your account.',
    fr_FR:
      "Bienvenue dans l'application SensorFact ! Cliquez sur Suivant pour définir un mot de passe pour votre compte.",
  },
  {
    id: '59f20c177f649900170b33ea',
    key: 'settings.account',
    en_GB: 'Account',
    nl_NL: 'Account',
    de_DE: 'Konto',
    it_IT: 'Compte',
    es_ES: 'Cuenta',
    fr_FR: 'Compte',
  },
  {
    id: '59f20c177f649900170b33e8',
    key: 'settings.language',
    en_GB: 'Language settings',
    nl_NL: 'Taal instellingen',
    de_DE: 'Spracheinstellungen',
    it_IT: 'Paramètres de langue',
    es_ES: 'Opciones de idioma',
    fr_FR: 'Paramètres de langue',
  },
  {
    id: '5a705dac2afe12001ffb0536',
    key: 'tour.steps.next',
    en_GB: 'Next',
    nl_NL: 'Volgende',
    de_DE: 'Nächste',
    it_IT: 'Prossimo',
    es_ES: 'Próximo',
    fr_FR: 'Suivant',
  },
  {
    id: '59f20c177f649900170b33c6',
    key: 'user.login',
    en_GB: 'Sign in',
    nl_NL: 'Inloggen',
    de_DE: 'Anmelden',
    it_IT: 'Se connecter',
    es_ES: 'Conectar',
    fr_FR: 'Se connecter',
  },
  {
    id: '59f20c177f649900170b33c8',
    key: 'user.logout',
    en_GB: 'Logout',
    nl_NL: 'Afmelden',
    de_DE: 'Ausloggen',
    it_IT: 'Se déconnecter',
    es_ES: 'Desconectar',
    fr_FR: 'Se déconnecter',
  },
  {
    id: '59f20c177f649900170b33c9',
    key: 'user.password.reset',
    en_GB: 'Reset password',
    nl_NL: 'Wachtwoord resetten',
    de_DE: 'Passwort zurücksetzen',
    it_IT: 'Réinitialiser le mot de passe',
    es_ES: 'Restablecer la contraseña',
    fr_FR: 'Réinitialiser le mot de passe',
  },
  {
    id: '59f20c177f649900170b33b7',
    key: 'user.profile.edit',
    en_GB: 'Edit profile',
    nl_NL: 'Profiel bewerken',
    de_DE: 'Profil bearbeiten',
    it_IT: 'Editer le profil',
    es_ES: 'Editar el perfil',
    fr_FR: 'Editer le profil',
  },
  {
    id: '6',
    key: 'download.page.done',
    en_GB: 'Done',
    nl_NL: 'Klaar',
    de_DE: 'Fertig',
    it_IT: 'Fatto',
    es_ES: 'Terminado',
    fr_FR: 'Terminé',
  },
  {
    id: '7',
    key: 'download.page.downloading',
    en_GB: 'Downloading...',
    nl_NL: 'Downloaden...',
    de_De: 'Wird heruntergeladen...',
    it_IT: 'Downloading...',
    es_ES: 'Descargando...',
    fr_FR: 'Téléchargement...',
  },
  {
    id: '8',
    key: 'download.report.manually',
    en_GB: 'If your download does not start click here to download manually.',
    nl_NL: 'Als uw download niet start, klik dan hier om handmatig te downloaden.',
    de_De: 'Wenn Ihr Download nicht startet, klicken Sie hier, um ihn manuell zu starten.',
    it_IT: 'Se il download non si avvia, faccia clic qui per scaricarlo manualmente.',
    es_ES: 'Si no se inicia la descarga, haga clic aquí para iniciar la desca manualmente.',
    fr_FR: 'Si votre téléchargement ne démarre pas, cliquez ici pour le télécharger manuellement.',
  },
  {
    id: '9',
    key: 'error.downloading.report',
    en_GB:
      'Something went wrong fetching the file. You can try refreshing this page or using the manual download link below.	',
    nl_NL:
      'Er is iets misgegaan bij het ophalen van het bestand. U kunt proberen deze pagina te vernieuwen of op onderstaande link klikken om handmatig te downloaden.	',
    de_De:
      'Beim Abrufen der Datei ist ein Fehler aufgetreten. Sie können versuchen, diese Seite zu aktualisieren oder den manuellen Download-Link unten zu verwenden.	',
    it_IT:
      "Qualcosa non ha funzionato nell'acquisizione del file. Può provare ad aggiornare questa pagina o utilizzare il link per il download manuale qui sotto.	",
    es_ES:
      'Se ha producido un error al descargar el archivo. Puede intentar actualizar la página o utilizar el enlace de descarga manual que aparece a continuación.	',
    fr_FR:
      "Un problème s'est produit lors de la récupération du fichier. Vous pouvez essayer d'actualiser cette page ou d'utiliser le lien de téléchargement du manuel ci-dessous.",
  },
  // TODO (Sabina): add translations
  {
    key: 'generic.log-in-title',
    en_GB: 'Log in to your account',
    nl_NL: 'Log in to your account',
    de_De: 'Log in to your account',
    it_IT: 'Log in to your account',
    es_ES: 'Log in to your account',
    fr_FR: 'Log in to your account',
  },
  {
    key: 'forms.profile.save-new-password',
    en_GB: 'Save new password',
    nl_NL: 'Save new password',
    de_De: 'Save new password',
    it_IT: 'Save new password',
    es_ES: 'Save new password',
    fr_FR: 'Save new password',
  },
  {
    key: 'forms.profile.required-error',
    en_GB: 'Required',
    nl_NL: 'Required',
    de_De: 'Required',
    it_IT: 'Required',
    es_ES: 'Required',
    fr_FR: 'Required',
  },
  {
    key: 'forms.submit.fetching-error',
    en_GB: 'Error occured, please try again later.',
    nl_NL: 'Error occured, please try again later.',
    de_De: 'Error occured, please try again later.',
    it_IT: 'Error occured, please try again later.',
    es_ES: 'Error occured, please try again later.',
    fr_FR: 'Error occured, please try again later.',
  },
  {
    key: 'forms.submit.no-criteria-password-match-error',
    en_GB: 'This password doesn’t meet all criteria below.',
    nl_NL: 'This password doesn’t meet all criteria below.',
    de_De: 'This password doesn’t meet all criteria below.',
    it_IT: 'This password doesn’t meet all criteria below.',
    es_ES: 'This password doesn’t meet all criteria below.',
    fr_FR: 'This password doesn’t meet all criteria below.',
  },
  {
    key: 'forms.submit.security-code-invalid-error',
    en_GB: 'This security code may be invalid or has expired. You can request a new code by clicking the back button below and submitting the form again.',
    nl_NL: 'This security code may be invalid or has expired. You can request a new code by clicking the back button below and submitting the form again.',
    de_De: 'This security code may be invalid or has expired. You can request a new code by clicking the back button below and submitting the form again.',
    it_IT: 'This security code may be invalid or has expired. You can request a new code by clicking the back button below and submitting the form again.',
    es_ES: 'This security code may be invalid or has expired. You can request a new code by clicking the back button below and submitting the form again.',
    fr_FR: 'This security code may be invalid or has expired. You can request a new code by clicking the back button below and submitting the form again.',
  },
  {
    key: 'forms.submit.password-rules-title',
    en_GB: 'Your password must have',
    nl_NL: 'Your password must have',
    de_De: 'Your password must have',
    it_IT: 'Your password must have',
    es_ES: 'Your password must have',
    fr_FR: 'Your password must have',
  },
  {
    key: 'forms.submit.password-validation-8-chars',
    en_GB: 'at least 8 characters',
    nl_NL: 'at least 8 characters',
    de_De: 'at least 8 characters',
    it_IT: 'at least 8 characters',
    es_ES: 'at least 8 characters',
    fr_FR: 'at least 8 characters',
  },
  {
    key: 'forms.submit.password-validation-lower-case',
    en_GB: '1 lower case',
    nl_NL: '1 lower case',
    de_De: '1 lower case',
    it_IT: '1 lower case',
    es_ES: '1 lower case',
    fr_FR: '1 lower case',
  },
  {
    key: 'forms.submit.password-validation-upper-case',
    en_GB: '1 upper case',
    nl_NL: '1 upper case',
    de_De: '1 upper case',
    it_IT: '1 upper case',
    es_ES: '1 upper case',
    fr_FR: '1 upper case',
  },
  {
    key: 'forms.submit.password-validation-number',
    en_GB: '1 number',
    nl_NL: '1 number',
    de_De: '1 number',
    it_IT: '1 number',
    es_ES: '1 number',
    fr_FR: '1 number',
  },
  {
    key: 'forms.submit.password-validation-special-char',
    en_GB: '1 special character',
    nl_NL: '1 special character',
    de_De: '1 special character',
    it_IT: '1 special character',
    es_ES: '1 special character',
    fr_FR: '1 special character',
  }
]

export default translations
