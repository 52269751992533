import React, { FC } from 'react'
import { Box } from '@mui/material'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'

import MainComponentsPage from './ComponentsMainPage'
import MuiSecondaryNav from '../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'
import { IconsPage } from './IconsPage'
import { StickyColumnTablePage } from './StickyColumnTablePage'

// This is a temporary page, created for testing date picker
const ComponentsPage: FC = () => {
  const { path } = useRouteMatch()

  const navRoutes = (): {
    key: string
    path: string
    component: React.ReactNode
  }[] => [
    {
      key: 'components',
      path: `${path}/main`,
      component: <MainComponentsPage />,
    },
    {
      key: 'icons',
      path: `${path}/icons`,
      component: <IconsPage />,
    },
    {
      key: 'table',
      path: `${path}/sticky-column-table`,
      component: <StickyColumnTablePage />,
    },
  ]

  const links = [
    {
      name: 'components',
      i18n: 'Components',
      path: `${path}/main`,
    },
    {
      name: 'icons',
      i18n: 'Icons',
      path: `${path}/icons`,
    },
    {
      name: 'Sticky Column Table',
      i18n: 'Sticky Column Table',
      path: `${path}/sticky-column-table`,
    },
  ]

  return (
    <Box
      sx={{
        padding: '3rem 10rem',
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <Box>
        <MuiSecondaryNav links={links} />
      </Box>
      <Switch>
        {navRoutes().map(route => (
          <Route
            key={route.key}
            exact
            path={route.path}
            render={() => route.component}
          />
        ))}
        <Redirect to={`${path}/main`} />
      </Switch>
    </Box>
  )
}

export default ComponentsPage
