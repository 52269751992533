import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import ConfigureVibrationSensorLocation from './ConfigureVibrationSensorLocation'
import MUILoader from '../../../../Shared/components/MUIComponents/Loader'
import {
  AssetType,
  MachineInfoFragment,
  useUpdateAssetTypeMutation,
} from '../../../../Shared/graphql/codegen'
import { ID } from '../../../../Shared/types/types'
import { Line } from '../AssetInfo'
import { VibrationSensorLocationInfo } from '../types'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'

const formSchema = z.object({
  assetType: z.string(),
})
type FormInputs = z.infer<typeof formSchema>

interface EditAssetInformationProps {
  sensorLocation: VibrationSensorLocationInfo
  machine: MachineInfoFragment
  assetTypes: AssetType[]
  onClose: (wasUpdated: boolean) => void
}

const ConfigureAssetInformation = ({
  machine,
  sensorLocation,
  assetTypes,
  onClose,
}: EditAssetInformationProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const theme = useTheme()
  const { getHasuraRoleHeader } = useCurrentUser()
  const { customerId } = useCurrentUser()

  const [updateAssetTypeMutation, { loading: isLoading, error: isError }] = useUpdateAssetTypeMutation({
    context: {
      headers: {
        'x-hasura-role': getHasuraRoleHeader(),
      },
    },
  })

  const assetTypeOptions = assetTypes.map(assetType => {
    return {
      option: assetType.name,
      value: assetType.name,
      key: assetType.id,
    }
  })
  const defaultAssetTypeValue = assetTypeOptions.find(option => option.key === machine.assetType?.id)?.key

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      assetType: defaultAssetTypeValue ? defaultAssetTypeValue : 'none',
    },
  })

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    await updateAssetTypeMutation({
      variables: {
        id: machine.id as ID,
        customerId: customerId as ID,
        type: data.assetType === 'none' ? null : data.assetType,
      },
    })

    onClose(true)
  }

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        variant="outlined"
        sx={() => ({
          textTransform: 'none',
          fontWeight: 400,
          fontSize: '0.9rem',
          marginRight: '1em',
        })}
        onClick={handleDialogOpen}
      >
        <Typography>Update Asset Information</Typography>
      </Button>
      <Dialog
        fullWidth
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle
          sx={{
            paddingLeft: theme.spacing(1),
          }}
        >
          <Typography
            variant="h4"
            component="span"
            display="inline"
            color="primary"
          >
            Configure Asset Information{' '}
          </Typography>
        </DialogTitle>
        <Box margin={1}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              {isError && (
                <Alert
                  sx={{ margin: theme.spacing(1) }}
                  severity="error"
                >
                  {' '}
                  {isError?.message || 'Server error please try again !'}{' '}
                </Alert>
              )}
              {isLoading ? (
                <MUILoader
                  margin="10vh 0 10vh 0"
                  height={15}
                />
              ) : (
                <>
                  <Box>
                    <Typography
                      fontWeight={400}
                      variant="h5"
                      component="span"
                      display="inline"
                      color="secondary"
                    >
                      Asset Configurations
                    </Typography>
                    <Line
                      icon={PrecisionManufacturingIcon}
                      label={'Asset Types'}
                      component={
                        <Controller
                          name="assetType"
                          control={control}
                          render={({ field: { onChange, value, ...rest } }) => {
                            return (
                              <Select
                                {...rest}
                                style={{ width: '100%' }}
                                value={value}
                                placeholder="Select an AssetType"
                                onChange={onChange}
                              >
                                <MenuItem value="none">None</MenuItem>
                                {assetTypeOptions.map(option => {
                                  return (
                                    <MenuItem
                                      key={option.key}
                                      value={option.key}
                                    >
                                      {option.option}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            )
                          }}
                        />
                      }
                    />
                  </Box>
                </>
              )}
            </DialogContent>

            <DialogActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                sx={{ margin: '1rem' }}
                type="submit"
                variant="outlined"
              >
                Edit Asset Configuration
              </Button>
            </DialogActions>
          </form>
          <ConfigureVibrationSensorLocation
            sensorLocation={sensorLocation}
            onClose={onClose}
          />
        </Box>
      </Dialog>
    </Box>
  )
}

export default ConfigureAssetInformation
