import { createSvgIcon } from '@mui/material'

export const WarningIcon = createSvgIcon(
  <svg
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99982 5.75017V8.75017M8.99982 11.7502H9.00732M7.9613 1.91897L1.79264 12.5739C1.45049 13.1649 1.27941 13.4604 1.30469 13.7029C1.32675 13.9145 1.43758 14.1067 1.60959 14.2318C1.80681 14.3752 2.14826 14.3752 2.83115 14.3752H15.1685C15.8514 14.3752 16.1928 14.3752 16.39 14.2318C16.5621 14.1067 16.6729 13.9145 16.6949 13.7029C16.7202 13.4604 16.5491 13.1649 16.207 12.5739L10.0383 1.91896C9.6974 1.33009 9.52694 1.03566 9.30454 0.936765C9.11055 0.850505 8.88908 0.850505 8.69509 0.936765C8.47269 1.03566 8.30223 1.33009 7.9613 1.91897Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'warning-icon'
)
