import { Box } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from '@mui/material'
import { useLocation } from 'react-router'

import Error from '../../../Shared/components/MUIComponents/Error'
import MUILoader from '../../../Shared/components/MUIComponents/Loader'
import { ActivePage } from '../../components/Layout/Layout'
import { AssetCardData, GroupedCards } from './types'
import { AssetHealthReportWrapper } from './AssetHealthReport/AssetHealthReport'
import { CurrentVibrationStatusCards } from './CurrentVibrationStatus/CurrentVibrationStatusCards'
import {
  ProbeLocationThresholdAlertRuleFragment,
  useGetThresholdAlertRulesQuery,
} from '../../../Shared/graphql/codegen'
import { SearchAndSortAssetsComponent } from './SearchAndSortAssetsComponent'
import { compareByName } from '../../utils/compareByName'
import { compareByStatus } from '../../utils/compareByStatus'
import { transformGroupToCards } from '../../utils/transformGroupToCards'
import { usePredictiveMaintenanceContext } from '../../context/PredictiveMaintenanceContext'

interface OverviewContainerProps {
  activePage: ActivePage.CURRENT_VIBRATION_STATUS | ActivePage.ASSET_HEALTH_REPORT
}

const OverviewContainer = ({ activePage = ActivePage.CURRENT_VIBRATION_STATUS }: OverviewContainerProps) => {
  const {
    groups,
    pastReferenceValue,
    setPastReferenceValue,
    refetchAssetOverview,
    errorAssetOverveiew,
    loadingAssetOverview,
  } = usePredictiveMaintenanceContext()
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState('status')
  const [assetCards, setAssetCards] = useState<AssetCardData[]>([])
  const [sortAsc, setSortAsc] = useState(false)
  const { data } = useGetThresholdAlertRulesQuery()
  const { pathname } = useLocation()
  const isAssetHealthReport = pathname?.includes('asset-health-report')
  const thresholdAlertRules: ProbeLocationThresholdAlertRuleFragment[] = [...(data?.myOrg?.thresholdAlertRules ?? [])]
  const referenceValueInSeconds = pastReferenceValue * 60
  const handleSearch = debounce(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSearchTerm(e.target.value),
    200
  )

  const cards: AssetCardData[] = groups.flatMap(group => transformGroupToCards(group, pastReferenceValue))

  const refetchIfEmpty = useCallback(async () => {
    if (groups.length === 0 && !loadingAssetOverview) {
      await refetchAssetOverview({
        variables: {
          input: {
            lookbackSeconds: pastReferenceValue,
          },
        },
      })
    }
  }, [groups, loadingAssetOverview, refetchAssetOverview, pastReferenceValue])

  useEffect(() => {
    refetchIfEmpty()
  }, [refetchIfEmpty])

  useEffect(() => {
    if (cards && cards.length > 0) {
      setAssetCards(cards)
    }
  }, [groups])

  useEffect(() => {
    refetchAssetOverview({
      variables: {
        input: {
          lookback: referenceValueInSeconds,
        },
      },
    })
  }, [pastReferenceValue])

  const displayedCards = useMemo(() => {
    const regExTerm = new RegExp(searchTerm, 'i')

    const sortedCard = assetCards
      .filter(asset => regExTerm.test(asset.name) || regExTerm.test(asset.groupName))
      .sort((prev, cur) => {
        let result = 0
        switch (sortBy) {
          case 'status':
            result = compareByStatus(prev, cur, sortAsc)
            break
          case 'name':
            result = compareByName(prev, cur, sortAsc)
            break
          default:
            result = compareByName(prev, cur, sortAsc)
        }
        return result
      })
    if (sortBy !== 'group') {
      return sortedCard
    } else {
      return sortedCard
        .sort((prev, cur) => {
          return sortAsc ? prev.vibrationStatus - cur.vibrationStatus : cur.vibrationStatus - prev.vibrationStatus
        })
        .reduce<GroupedCards>((acc, card) => {
          if (!acc[card.groupName]) {
            acc[card.groupName] = []
          }
          acc[card.groupName].push(card)
          return acc
        }, {})
    }
  }, [assetCards, searchTerm, sortBy, sortAsc])

  const renderContent = () => {
    switch (activePage) {
      case 'current-vibration-status':
        return (
          <>
            {loadingAssetOverview ? (
              <MUILoader />
            ) : errorAssetOverveiew ? (
              <Error />
            ) : (
              <CurrentVibrationStatusCards
                cards={displayedCards}
                thresholdAlertRules={thresholdAlertRules}
              />
            )}
          </>
        )
      case 'asset-health-report':
        return <AssetHealthReportWrapper />
      default:
        return null
    }
  }

  return (
    <Box paddingLeft="1rem">
      {!isAssetHealthReport && (
        <>
          <SearchAndSortAssetsComponent
            checkUpdateValue={pastReferenceValue}
            setCheckUpdateValue={setPastReferenceValue}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortAsc={sortAsc}
            setSortAsc={setSortAsc}
            handleSearch={handleSearch}
          />
        </>
      )}
      {renderContent()}
    </Box>
  )
}
export default OverviewContainer
