import React, { FC } from 'react'
import { Box, styled } from '@mui/material'

import { EyeIcon } from '../../../icons/EyeIcon'

interface IImageReviewItemProps {
  item: { value: string; id?: string }
  openViewImagesModal: (id?: string) => void
}

export const ImageReviewItem: FC<IImageReviewItemProps> = ({ item, openViewImagesModal }) => {
  return (
    <ImageWrapper
      onClick={() => {
        openViewImagesModal(item?.id)
      }}
    >
      <img src={item.value} />
      <HoverStateBox className="hover-state-box">
        <EyeIcon />
      </HoverStateBox>
    </ImageWrapper>
  )
}

const ImageWrapper = styled(Box)(({ theme }) => ({
  width: '6.125rem',
  height: '6.0025rem',
  overflow: 'hidden',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  '> img': {
    width: 'auto',
    height: '100%',
  },
  '.hover-state-box': {
    opacity: 0,
  },
  '&:hover .hover-state-box': {
    opacity: 1,
  },
}))

const HoverStateBox = styled(Box)(({ theme }) => ({
  background: theme.palette.SFIGreyLight[700],
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.SFIBase.white,
  transition: 'all 150ms linear',
}))
