import React from 'react'
import { FC } from 'react'
import { Interval } from 'luxon'

import { CsvDataMapper, CsvHeaderMapper, CsvHeaders, ElectricityCsvExporter } from './ElectricityCsvExporter'
import { ElectricityAssetMeasurement } from '../types'

interface PowerCsvExporterProps {
  measurements: ElectricityAssetMeasurement[]
  interval: Interval
  filename?: string
  unit?: string
  showDialog: boolean
}

const PowerCsvExporter: FC<PowerCsvExporterProps> = ({
  measurements,
  interval,
  unit: unitProp,
  filename,
  showDialog = false,
}) => {
  const unit = unitProp || 'W'
  const headers: CsvHeaders = ['date', 'assetName', 'value', 'unit']

  const mapHeaders: CsvHeaderMapper = (headers, { valueSeparator }) => {
    return headers.join(valueSeparator)
  }

  const mapDataToHeaders: CsvDataMapper<ElectricityAssetMeasurement[]> = (
    data,
    { valueSeparator, decimalSeparator }
  ) => {
    const result = []
    for (const asset of data) {
      for (const measurement of asset.measurements) {
        const { time, value } = measurement
        const formattedValue = value.toString().replace('.', decimalSeparator)
        const row = `${time}${valueSeparator}${asset.name}${valueSeparator}${formattedValue}${valueSeparator}${unit}`
        result.push(row)
      }
    }
    return result.join('\n')
  }

  return (
    <ElectricityCsvExporter
      headers={headers}
      mapHeaders={mapHeaders}
      data={measurements}
      mapDataToHeaders={mapDataToHeaders}
      interval={interval}
      filename={filename}
      forceShowDialog={showDialog}
    />
  )
}

export { PowerCsvExporter }
