import { ApexOptions } from 'apexcharts'
import { FC } from 'react'

import GenericChart from '../GraphsShared/GenericChart'
import { AssetProductionSpeed } from '../../types/Assets.types'
import { Oee_Production_Speed_Unit_Enum } from '../../../Shared/graphql/codegen'
import { PRODUCTION_STATISTICS_DATE_FORMAT, SPEED_UNIT_STRING } from '../../utils/constants'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type ProductionSpeedProps = {
  productionSpeedData: AssetProductionSpeed[]
  chartHeight?: number
  customOptions?: ApexOptions
}

const ProductionSpeedGraph: FC<ProductionSpeedProps> = ({ productionSpeedData, chartHeight, customOptions }) => {
  const id = `production-speed-${Math.random()}`

  const { i18n } = useI18nContext()
  const { units } = useOEEContext()

  const isCycleTime = units.speed === Oee_Production_Speed_Unit_Enum.CycleTimeSeconds

  const formatYAxisLabel = (value: number) => `${i18n.number(Math.round(value))}`

  const chartOptions: ApexOptions = {
    stroke: {
      curve: !isCycleTime ? 'straight' : 'stepline',
    },
    tooltip: {
      x: {
        show: true,
        format: PRODUCTION_STATISTICS_DATE_FORMAT,
      },
      y: {
        formatter: value => `${i18n.number(value)} ${i18n.text(SPEED_UNIT_STRING[units.speed])}`,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: formatYAxisLabel,
      },
    },
    ...(customOptions ?? {}),
  }

  return (
    <GenericChart
      id={id}
      customOptions={chartOptions}
      series={productionSpeedData}
      chartType="line"
      height={chartHeight}
    />
  )
}

export default ProductionSpeedGraph
