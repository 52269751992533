import {
  AirSensorLocationFragment,
  AirSensorLocationImageFragment,
  CompressedAirAssetFragment,
  CompressedAirFlowSystemImageFragment,
} from '../../Shared/graphql/codegen'

export function getCompressedAirFlowSystemImages(
  asset: CompressedAirAssetFragment
): CompressedAirFlowSystemImageFragment[] {
  return asset.compressedAirFlowSystem ? [...asset.compressedAirFlowSystem.compressedAirSystemImages] : []
}

export function getSensorLocationImages(sensorLocation: AirSensorLocationFragment): AirSensorLocationImageFragment[] {
  return [...sensorLocation.sensorLocationImages]
}
