import { UseFormWatch } from 'react-hook-form'

import { CompressedAirAlertFormVars } from '../types/compressed-air_types'
import { ThresholdOperatorType } from '../../App/types/alertTypes'

interface IValidationFunctionArgs {
  value?: number
  watch: UseFormWatch<CompressedAirAlertFormVars>
}

interface IValidateThresholdValueRequiredCaseArgs extends IValidationFunctionArgs {
  thresholdType: 'GREATER' | 'LESS'
}

interface IValidateThresholdValueBetweenCaseArgs extends IValidationFunctionArgs {
  pathName: 'lowerThresholdValue' | 'upperThresholdValue'
  isLess: boolean
}

export const validateThresholdValueRequiredCase = ({
  value,
  thresholdType,
  watch,
}: IValidateThresholdValueRequiredCaseArgs) => {
  const thresholdOperatorType = watch('thresholdOperatorType')
  if (
    thresholdOperatorType === ThresholdOperatorType[thresholdType] ||
    thresholdOperatorType === ThresholdOperatorType.BETWEEN
  ) {
    return typeof value === 'number' && value >= 0
  }
  return true
}

export const validateThresholdValueBetweenCase = ({
  value,
  pathName,
  isLess,
  watch,
}: IValidateThresholdValueBetweenCaseArgs) => {
  const thresholdOperatorType = watch('thresholdOperatorType')
  const thresholdValue = watch(pathName)
  if (thresholdOperatorType === ThresholdOperatorType.BETWEEN) {
    const isValid = value && thresholdValue ? (isLess ? value < thresholdValue : value > thresholdValue) : false
    return thresholdValue !== undefined && value !== undefined && isValid
  }
  return true
}
