import * as SentryBrowser from '@sentry/react'
import React from 'react'
import { Dedupe as DedupeIntegration, ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
import { Route } from 'react-router-dom'

import MassiveFooBar from '../components/MassiveFooBar'
import { apiOrigins } from '../utils'
import { history } from './history'

export const SentryRoute = SentryBrowser.withSentryRouting(Route)
export const SENTRY_URL = process.env.REACT_APP_SENTRY_DSN

const SENTRY_ENV = window.location.origin === 'https://app.sensorfact.nl' ? 'PRODUCTION' : 'DEVELOPMENT'

SentryBrowser.init({
  enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: SENTRY_ENV,
  release: process.env.REACT_APP_GIT_SHA || 'Unknown',
  maxBreadcrumbs: 30,
  autoSessionTracking: true,
  attachStacktrace: true,
  normalizeDepth: 5,
  integrations: defaults =>
    defaults.concat([
      new Integrations.BrowserTracing({
        tracingOrigins: [...apiOrigins],
        routingInstrumentation: SentryBrowser.reactRouterV5Instrumentation(history),
      }),
      new ExtraErrorDataIntegration({ depth: 5 }),
      new DedupeIntegration(),
    ]),
  sampleRate: 1.0, // Send 100% errors
  tracesSampleRate: 0.5, // Send 50%  transactions
})

SentryBrowser.setContext('app', {
  version: process.env.REACT_APP_VERSION,
})

export const ErrorBoundary = ({ children }: React.PropsWithChildren<unknown>) => (
  <SentryBrowser.ErrorBoundary
    fallback={({ error, componentStack }) => (
      <MassiveFooBar
        error={error}
        componentStack={componentStack}
      />
    )}
  >
    {children}
  </SentryBrowser.ErrorBoundary>
)
