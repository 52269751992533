import { DataPointTemperature, TemperatureSensorLocationFragment } from '../../Shared/graphql/codegen'

export enum SeriesNames {
  temperature = 'Temperature',
  humidity = 'Humidity',
}

export interface ISeriesData {
  id: string
  name: string
  color: string
  data: {
    time: string
    value: number
    min: number
    max: number
  }[]
}

export interface ITemperatureSensorLocation extends TemperatureSensorLocationFragment {
  insights: {
    timeWindow: {
      humidityMeasurements: {
        value: number
      }[]
      temperatureMeasurements: {
        value: number
      }[]
    }
  }
}

export type LegendNames = 'temperature' | 'humidity'

export interface IChartData {
  id: string
  seriesData: ISeriesData[]
}

export interface SensorLocation {
  name: string
  id: string
  temperature: number
  humidity: number
  isAlertConfigured: boolean
}

export interface Asset {
  name: string
  id: string
  sensorLocations: SensorLocation[]
}

export type TemperatureUrlParams = {
  sensorLocationId: string
}

export enum UnitName {
  celsius = '°C',
  percentage = '%',
}

export interface ILocationSeries {
  name: string
  id: string
  color: string
  measurements: DataPointTemperature[]
}

export interface ITemperatureHumiditySeries {
  temperature: ILocationSeries
  humidity: ILocationSeries
}

export type Insights = {
  timeWindow: {
    temperatureMeasurements: Array<DataPointTemperature>
    humidityMeasurements: Array<DataPointTemperature>
  }
} | null
