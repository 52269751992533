import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'

import { Block, BlockInfo, ImageWrapper, UploadButton } from '../../CASettingsStyledComponents'
import { IImage } from '../../../../../Shared/types/types'
import { UploadIcon } from '../../../../../Shared/components/icons/UploadIcon'
import { UploadImageModal } from '../../../../../Shared/components/MUIComponents/update/UploadImageModal'
import { ViewImagesModal } from '../../../../../Shared/components/MUIComponents/update/ViewImageModal'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'
import { ImageReviewItem } from '../../../../../Shared/components/MUIComponents/update/ImageReviewItem'

interface IUploadImageModalProps {
  title: string
  subtitle: string
  isLoading: boolean
  isOpen: boolean
  onToggleModal: (isOpen: boolean) => void
  onSelectFiles: (files: File[]) => void
  onUpload: () => void
}

interface IViewImagesModalProps extends IUploadImageModalProps {
  images: IImage[]
  uploadModal: {
    subtitle: string
    isOpen: boolean
    isLoading: boolean
    onToggleModal: (isOpen: boolean) => void
  }
  onDelete: (imageId: string) => void
}

interface IImagePlaceholderProps {
  imageUrl?: string
  viewImagesModalProps: IViewImagesModalProps
  uploadImageModalProps: IUploadImageModalProps
}

export const ImagePlaceholder: FC<IImagePlaceholderProps> = ({
  imageUrl,
  viewImagesModalProps,
  uploadImageModalProps,
}) => {
  const { i18n } = useI18nContext()
  return (
    <Block>
      <Box>
        <Typography>{i18n.text('compressed-air.image-upload.pid-or-technical-drawing')}</Typography>
        <BlockInfo>{i18n.text('compressed-air.settings.pid-information')}</BlockInfo>
      </Box>
      {imageUrl ? (
        <ImageWrapper>
          <ImageReviewItem
            item={{ value: imageUrl }}
            openViewImagesModal={() => viewImagesModalProps.onToggleModal(true)}
          />
        </ImageWrapper>
      ) : (
        <Box sx={{ width: 'fit-content' }}>
          <UploadButton onClick={() => uploadImageModalProps.onToggleModal(true)}>
            <UploadIcon
              sx={{
                height: '1.25rem',
                width: '1.25rem',
              }}
            />
            {i18n.text('compressed-air.settings.upload-photo')}
          </UploadButton>
        </Box>
      )}
      <ViewImagesModal
        title={viewImagesModalProps.title}
        subtitle={i18n.text('compressed-air.asset-information.upload-system-image')}
        isOpen={viewImagesModalProps.isOpen}
        images={viewImagesModalProps.images}
        isLoading={viewImagesModalProps.isLoading}
        uploadModal={{
          subtitle: i18n.text('compressed-air.asset-information.upload-system-image'),
          isOpen: viewImagesModalProps.uploadModal.isOpen,
          isLoading: viewImagesModalProps.uploadModal.isLoading,
          onToggleModal: viewImagesModalProps.uploadModal.onToggleModal,
        }}
        onToggleModal={viewImagesModalProps.onToggleModal}
        onSelectFiles={viewImagesModalProps.onSelectFiles}
        onUpload={viewImagesModalProps.onUpload}
        onDelete={viewImagesModalProps.onDelete}
      />
      <UploadImageModal
        title={uploadImageModalProps.title}
        isLoading={uploadImageModalProps.isLoading}
        isOpen={uploadImageModalProps.isOpen}
        subtitle={uploadImageModalProps.subtitle}
        onToggleModal={uploadImageModalProps.onToggleModal}
        onSelectFiles={uploadImageModalProps.onSelectFiles}
        onUpload={uploadImageModalProps.onUpload}
      />
    </Block>
  )
}
