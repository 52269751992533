import { Box, Typography, TypographyProps, styled } from '@mui/material'

interface IStyledValidationCaseTextProps extends TypographyProps {
  isValid: boolean
}

export const StyledValidationCaseText = styled((props: IStyledValidationCaseTextProps) => {
  const { isValid, children, ...other } = props
  return <Typography {...other}>{children}</Typography>
})(({ theme, isValid }) => ({
  color: isValid ? theme.palette.SFISuccess[600] : theme.palette.SFIGreyLight[600],
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1.125rem',
}))

export const StyledFlexBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const StyledGridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '0.38rem 2.69rem',
})

export const StyledValidationCase = styled(Box)({
  display: 'flex',
  gap: '0.25rem',
  alignItems: 'center',
})

export const StyledFormWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25rem',
})

export const StyledValidationCasesTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[800],
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1.125rem',
  marginBottom: '0.5rem',
}))
