import { FC } from 'react'

import AssetTreeGroup from './AssetTreeGroup'
import CollapsibleAssetTreeWrapper from '../../../Shared/components/MUIComponents/update/AssetTree/CollapsibleAssetTreeWrapper'
import { useTemperatureContext } from '../../context/TemperatureContext'

const AssetTree: FC = () => {
  const { assets } = useTemperatureContext()

  return (
    <CollapsibleAssetTreeWrapper>
      {assets.map(asset => {
        return (
          <AssetTreeGroup
            key={asset.id}
            asset={asset}
          />
        )
      })}
    </CollapsibleAssetTreeWrapper>
  )
}

export default AssetTree
