import { Box, ListItemIcon, useTheme } from '@mui/material'

import { CheckIcon } from '../../../icons'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

interface MenuItemContentProps {
  i18n: string
}

export const MenuItemContentCheckmark = (props: MenuItemContentProps) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      {i18n.text(props.i18n)}
      <ListItemIcon>
        <CheckIcon sx={{ color: theme.palette.SFIBrand[950] }} />
      </ListItemIcon>
    </Box>
  )
}
