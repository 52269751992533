import { BrowserStorageKeys } from '../constants/browserStorageKeys'
import { browserStorage } from './browserStorage'

export function isTrackingEnabled() {
  return (
    !!browserStorage.get(BrowserStorageKeys.AnalyticsTracking).value &&
    process.env.REACT_APP_GA_4_MEASUREMENT_ID != null
  )
}

export function setInitialTracking() {
  if (
    process.env.REACT_APP_GA_4_MEASUREMENT_ID != null &&
    browserStorage.get(BrowserStorageKeys.AnalyticsTracking).value === null
  ) {
    browserStorage.set(BrowserStorageKeys.AnalyticsTracking, true)
  }
}

export function setTracking(status: boolean) {
  browserStorage.set(BrowserStorageKeys.AnalyticsTracking, status)
}
