//Creates the overview of which Tracking Reports the customer already has

import { Box, Typography, styled, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import MUILoader from '../../../../Shared/components/MUIComponents/Loader'
import useAnalytics from '../../../../Shared/hooks/useAnalytics/useAnalytics'
import { NoDataFoundSlot } from '../../../../Shared/components/MUIComponents/update/NoDataFoundSlot'
import { ReportRow } from './ReportRow'
import { StyledPrimaryButton } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { SupportSection } from './SupportSection'
import { trackStartNewReportEvent } from './trackingEvents'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { useGetReportsByCustomerIdQuery } from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const StyledHeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '1.125rem',
  color: theme.palette.SFIGreyLight[600],
}))

export const ReportsOverview = () => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { customerId } = useCurrentUser()
  const history = useHistory()
  const { sendEvent } = useAnalytics()
  const { data, loading } = useGetReportsByCustomerIdQuery({ variables: { id: customerId } })
  const reportsData = (data && data.TrackingReportReportConfigs) || []
  const [reports, setReports] = useState(reportsData)
  useEffect(() => {
    setReports(reportsData)
  }, [data])

  const handleNewReportClick = () => {
    trackStartNewReportEvent('new_report_button', sendEvent)
    history.push(`/reports/configuration/tracking/new/general`)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '3.25rem 0',
      }}
    >
      <Box
        sx={{
          border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
          borderRadius: '12px',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        }}
      >
        {loading && (
          <MUILoader
            height={12}
            margin="2rem 0 2rem 0"
          />
        )}
        {!loading && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1.25rem 1.5rem 1.25rem 1.5rem',
              borderBottom: reports.length === 0 ? `1px solid ${theme.palette.SFIGreyLight[200]}` : 'none',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
              <Typography
                fontWeight={600}
                fontSize="1.125rem"
                lineHeight="1.75rem"
                color={theme.palette.SFIGreyLight[800]}
              >
                {i18n.text('reports.tracking.configuration.reports.overview')}
              </Typography>
              <Typography
                fontWeight={400}
                fontSize="0.875rem"
                lineHeight="1.25rem"
                color={theme.palette.SFIGreyLight[600]}
              >
                {i18n.text('reports.tracking.configuration.reports.manage')}
              </Typography>
            </Box>
            <StyledPrimaryButton
              sx={{ textTransform: 'none', width: 'fit-content', height: 'fit-content', fontSize: '0.875rem' }}
              onClick={handleNewReportClick}
            >
              {i18n.text(`reports.tracking.configuration.reports.newReport`)}
            </StyledPrimaryButton>
          </Box>
        )}
        {!loading && reports.length !== 0 && (
          <Box
            sx={{
              display: 'inline-grid',
              gridTemplateColumns: '25% 9% 15% 9% 15% 12% 15%',
              width: '100%',
              alignItems: 'center',
              background: theme.palette.SFIBase.white,
              padding: '0.75rem 1.5rem 0.75rem 1.5rem',
              borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
            }}
          >
            <StyledHeaderTypography>{i18n.text('reports.tracking.configuration.reports.title')}</StyledHeaderTypography>
            <StyledHeaderTypography>
              {i18n.text('reports.tracking.configuration.reports.language')}
            </StyledHeaderTypography>
            <StyledHeaderTypography sx={{ textAlign: 'center' }}>
              {i18n.text('reports.tracking.configuration.reports.modules')}
            </StyledHeaderTypography>
            <StyledHeaderTypography>
              {i18n.text('reports.tracking.configuration.reports.frequency')}
            </StyledHeaderTypography>
            <StyledHeaderTypography sx={{ textAlign: 'center' }}>
              {i18n.text('reports.tracking.configuration.reports.recipients')}
            </StyledHeaderTypography>
            <StyledHeaderTypography>
              {i18n.text('reports.tracking.configuration.reports.enabled')}
            </StyledHeaderTypography>
          </Box>
        )}
        {!loading &&
          reports.length !== 0 &&
          reports.map((report, index) => (
            <Box key={index}>
              <ReportRow
                index={index}
                reportId={report.id}
                label={report.title}
                enabled={report.enabled}
                timeframe={report.frequency}
                modules={report.analysisModules.length}
                recipients={report.recipients.length}
                language={report.locale}
                numReports={reports.length}
              ></ReportRow>
            </Box>
          ))}
        {!loading && reports && reports.length === 0 && (
          <Box>
            <NoDataFoundSlot
              title={i18n.text('reports.tracking.configuration.reports.noReports')}
              subtitle={i18n.text('reports.tracking.configuration.reports.noReportsSubTitle')}
            />
          </Box>
        )}
      </Box>
      <SupportSection />
    </Box>
  )
}
