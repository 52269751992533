import styled from 'styled-components'

import { themeColors } from '../../utils'

/** @deprecated do not use */
export default styled.div<{ isDashboard?: boolean }>`
  position: initial;
  top: ${({ isDashboard }) => (isDashboard ? '0' : '3.5em')};
  bottom: 0;
  left: 0;
  width: 15.8rem;
  height: 100%;
  background-color: #0f1628;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${themeColors.primaryLight};
  }

  @media (max-width: 800px) {
    top: 4em;
  }
`
