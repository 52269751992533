import { Box, Typography, styled } from '@mui/material'

export const StyledFlexBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.37rem',
})

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[700],
  whiteSpace: 'nowrap',
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '1.25rem',
  textAlign: 'left',
}))
