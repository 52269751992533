/* eslint-disable max-len */
import React from 'react'

type Props = {
  color: string
  signal: string
  size: number
}

const WifiSignalStrength = ({ signal = 'poor', color = '000', size = 24 }: Props) => (
  <svg
    x={0}
    y={0}
    width={size}
    height={size}
    viewBox="0 0 512 512"
    enableBackground="new 0 0 512 512"
  >
    <path
      fill={signal === 'excellent' ? color : 'rgba(0,0,0,0.2)'}
      d="M233.503,70.369c93.726-6.75,189.622,28.317,256.515,94.363c8.765,9.023,7.622,24.807-1.625,33.076  c-8.493,10.012-25.501,10.189-34.266,0.436c-57.224-55.091-138.018-84.881-217.35-78.956  c-65.905,4.24-129.749,32.628-177.572,78.096c-4.253,4.347-9.684,7.916-15.938,8.222c-15.666,1.837-30.154-14.536-26.773-29.86  c0.954-5.501,4.487-10.083,8.527-13.747C81.009,108.392,156.172,75.469,233.503,70.369z"
    />
    <path
      fill={signal === 'excellent' || signal === 'good' ? color : 'rgba(0,0,0,0.2)'}
      d="M231.183,162.282c71.806-7.81,146.369,18.658,197.183,69.968c7.939,9.07,6.95,23.901-1.872,32.052  c-8.046,9.847-24.265,10.953-33.383,2.024c-32.534-32.074-76.518-52.44-122.126-55.774c-55.387-4.594-111.974,16.42-151.411,55.445  c-9.094,9.612-26.033,8.518-34.218-1.708c-8.823-8.327-9.553-23.559-1.084-32.381C123.356,192.802,176.209,167.924,231.183,162.282z  "
    />
    <path
      fill={signal === 'excellent' || signal === 'good' || signal === 'fair' ? color : 'rgba(0,0,0,0.2)'}
      d="M241.796,252.97c45.314-4.499,92.161,12.852,123.693,45.669c8.634,8.987,7.634,24.454-1.461,32.77  c-8.257,9.966-24.807,10.638-33.782,1.296c-17.774-18.245-42.394-29.778-67.884-31.168c-28.929-2.062-58.201,9.365-78.744,29.695  c-5.053,5.679-12.557,9.165-20.237,8.259c-17.833-1.25-30.213-25.056-17.928-39.344C170.26,273.243,205.492,256.575,241.796,252.97z  "
    />
    <path
      fill={
        signal === 'excellent' || signal === 'good' || signal === 'fair' || signal === 'poor'
          ? color
          : 'rgba(0,0,0,0.2)'
      }
      d="M248.781,337.651c29.201-4.9,58.837,19.213,59.78,48.824c2.709,29.036-22.923,56.589-52.063,55.951  c-26.197,0.908-50.475-20.825-52.772-46.88C199.991,368.194,221.382,340.808,248.781,337.651z"
    />
  </svg>
)

export default WifiSignalStrength
