import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Interval } from 'luxon'

import useAnalytics from '../../../hooks/useAnalytics/useAnalytics'
import { AnalyticsEventTypes, DownloadCSV } from '../../../hooks/useAnalytics/analyticsTypes'
import { StyledChartToolsButton } from './StyledChartToolsButton'
import { useI18nContext } from '../../../contexts/i18nContext/I18nContext'

export type CsvHeaders = string[]
export type CsvSeparators = {
  valueSeparator: string
  decimalSeparator: string
}

export type CsvHeaderMapper = (headers: CsvHeaders, separators: CsvSeparators) => string
export type CsvDataMapper<T = any> = (data: T, separators: CsvSeparators) => string

interface SimpleCsvExporterProps {
  data: any
  interval: Interval
  filename?: string
  unit?: string
  headers: CsvHeaders
  slotProps?: {
    button?: ButtonProps
  }
  mapHeaders: CsvHeaderMapper
  mapDataToHeaders: CsvDataMapper
}

const SimpleCsvExporter: React.FC<SimpleCsvExporterProps> = ({
  interval,
  filename: filenameProp,
  headers: headersProp,
  mapHeaders,
  mapDataToHeaders,
  data,
  slotProps,
}) => {
  const { sendEvent } = useAnalytics()
  const { i18n } = useI18nContext()
  const [decimalSeparator, setDecimalSeparator] = useState('.')
  const formattedStartDate = interval.start.toFormat('yyyy-MM-dd')
  const formattedEndDate = interval.end.toFormat('yyyy-MM-dd')
  const [filename, setFilename] = useState<string>(filenameProp || `${formattedStartDate}-${formattedEndDate}`)
  const [filenameError, setFilenameError] = useState<string | null>(null)
  const [decimalSeparatorError, setDecimalSeparatorError] = useState<string | null>(null)
  const [valueSeparator, setValueSeparator] = useState(',')
  const [valueSeparatorError, setValueSeparatorError] = useState<string | null>(null)
  const [showDialog, setShowDialog] = useState(false)

  const handleClick = () => {
    setShowDialog(true)
  }

  const onDownload = () => {
    // 1. Map data to headers using separators
    const headers = mapHeaders(headersProp, { valueSeparator, decimalSeparator })
    const rows = mapDataToHeaders(data, { valueSeparator, decimalSeparator })
    const result = [headers, rows].join('\n')

    // 2. Download file
    const blob = new Blob([result], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', `${filename}.csv`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()

    sendEvent<DownloadCSV>(AnalyticsEventTypes.TRACKED_DOWNLOAD, { target: 'csv' })
    setShowDialog(false)
  }

  const onDecimalSeparatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDecimalSeparator(event.target.value)
  }
  const onValueSeparatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueSeparator(event.target.value)
  }
  const onFilenameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilename(event.target.value)
  }

  useEffect(() => {
    if (decimalSeparator.length === 1) {
      setDecimalSeparatorError(null)
    } else {
      setDecimalSeparatorError(i18n.text('export.error-decimal-separator'))
    }
  }, [decimalSeparator, i18n, setDecimalSeparatorError])

  useEffect(() => {
    if (valueSeparator.length === 1) {
      setValueSeparatorError(null)
    } else {
      setValueSeparatorError(i18n.text('export.error-value-separator'))
    }
  }, [valueSeparator, i18n, setValueSeparatorError])

  useEffect(() => {
    if (filename.trim().length > 0) {
      setFilenameError(null)
    } else {
      setFilenameError(i18n.text('export.error-filename'))
    }
  }, [filename, i18n, setFilenameError])

  const exampleHeading = `time${valueSeparator}name${valueSeparator}value`
  const exampleLine = `2021-08-03T12:00:44.000${valueSeparator}AssetName${valueSeparator}3${decimalSeparator}243`

  return (
    <>
      <StyledChartToolsButton
        onClick={handleClick}
        {...slotProps?.button}
      >
        {i18n.text('tooltip.export-button')}
      </StyledChartToolsButton>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <DialogContent>
          <Box>
            <Typography variant="h5">{i18n.text('export.dialog-title')}</Typography>
            <Typography my={1}>{i18n.text('export.instructions')}</Typography>
          </Box>
          <Box my={2}>
            <Stack direction="column">
              <TextField
                label={i18n.text('export.filename')}
                defaultValue={filename}
                size="small"
                variant="standard"
                helperText={filenameError}
                error={Boolean(filenameError)}
                onChange={onFilenameChange}
              />
              <TextField
                label={i18n.text('export.decimal-separator')}
                value={decimalSeparator}
                size="small"
                variant="standard"
                helperText={decimalSeparatorError}
                error={Boolean(decimalSeparatorError)}
                onChange={onDecimalSeparatorChange}
              />
              <TextField
                label={i18n.text('export.value-separator')}
                value={valueSeparator}
                size="small"
                variant="standard"
                helperText={valueSeparatorError}
                error={Boolean(valueSeparatorError)}
                onChange={onValueSeparatorChange}
              />
            </Stack>
          </Box>
          <Box my={1}>
            <Typography mb={1}>{i18n.text('export.example-output')}:</Typography>
            <Typography>{exampleHeading}</Typography>
            <Typography>{exampleLine}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={Boolean(decimalSeparatorError) || Boolean(valueSeparatorError)}
            onClick={onDownload}
          >
            {i18n.text('export.download-button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SimpleCsvExporter
