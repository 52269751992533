import { SlideshowImageInfo } from './IntroSlideshow'
import { UserLocale_Enum } from '../../../Shared/graphql/codegen'

const imagesByLocale: { [key in UserLocale_Enum]: SlideshowImageInfo[] } = {
  [UserLocale_Enum.EnGb]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687425137/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20EN/xikkg1miibcpjws1o05u.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334064/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20EN/rkbthn0aoblf7er9rkyp.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334064/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20EN/k9yxgkv08c5b3x02tfc6.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334064/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20EN/bf1qdkcgkzqiawtvlekb.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334064/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20EN/elsuur4kuqhwca9cxjjy.png',
    },
  ],
  [UserLocale_Enum.NlNl]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687425208/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20NL/mrmhhbyq285zg8vowpre.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334065/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20NL/dbbcqisyippayltdowgf.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334065/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20NL/whndnik8rbcm1jn9xbbs.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334065/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20NL/cxwvlktcocjqs7ikngyz.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334065/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20NL/ua6fan9rxzzzg3uip5ny.png',
    },
  ],
  [UserLocale_Enum.DeDe]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687425147/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20DE/kesyf4pqx9rhf6fmojez.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334035/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20DE/djxlgdzf3ur80ihb8t7j.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334035/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20DE/hmmbxyotz17sgl7fn0jn.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334035/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20DE/lsvxlebdoqeqtfuoyzvv.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334035/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20DE/xzvzgejtkntabewrujnm.png',
    },
  ],
  [UserLocale_Enum.EsEs]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687425187/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20ES/kgezoefo2xfbvk5tlfpv.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334064/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20ES/fu51sy7swqw3hq32vdmw.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334064/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20ES/ac1ngepsnhmnmg5ih35q.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334064/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20ES/gfxtghochmoscu8saaig.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334065/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20ES/en5fy7j7tscmb3hwmddd.png',
    },
  ],
  [UserLocale_Enum.FrFr]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687425193/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20FR/tjofoicuudlw7g0jjn4w.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334064/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20FR/d6fq60rjxtyo5nwrszvx.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334064/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20FR/hq37rwx7dcdxhxcc6vni.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334064/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20FR/xv63cgb114pjeuwbb7fy.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334065/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20FR/z6zo9ggi3x9u3tfhohu5.png',
    },
  ],
  [UserLocale_Enum.ItIt]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687425200/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20IT/slpbs509qgkyryxytrmb.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334065/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20IT/ye7stzz70ltfrh6zmzie.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334064/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20IT/kqxjveoc5wkjtrlses1x.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334065/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20IT/wcekaodmlpcegxdsmopi.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1687334065/production/intro_slideshow_202306/Multi%20Value%20Proposition%20-%20IT/zr0mcgjkh81f9eieww5f.png',
    },
  ],
}

export default imagesByLocale
