import React, { FC, useEffect, useMemo } from 'react'
import { Box, SelectProps } from '@mui/material'
import { Mutable } from 'utility-types'
import { SubmitHandler } from 'react-hook-form'
import { useApolloClient } from '@apollo/client'

import { AlertTriggerConditions } from './AlertTriggerConditions'
import { CompressedAirAlertFormVars } from '../../types/compressed-air_types'
import {
  CreateGeneralAlertRuleInput,
  GeneralAlertRuleCategoriesEnum,
  GeneralAlertRuleRecipientChannelsEnum,
  GetCaGeneralAlertRuleByIdDocument,
  useGetAssetsWithSensorLocationsQuery,
  useGetCaGeneralAlertRuleByIdLazyQuery,
  useInsertGeneralAlertForCaMutation,
  useUpdateGeneralAlertForCaMutation,
} from '../../../Shared/graphql/codegen'
import { DEFAULT_ALERT_RULE_WEEKDAY } from '../../../App/utils/alertTimeManipulation'
import { DEFAULT_MIDDAY_TIME } from '../../../Shared/constants/timezone'
import { MessageFormSection } from '../../../App/components/AlertConfigurationModal/MessageFormSection'
import { Recipient, ThresholdOperatorType } from '../../../App/types/alertTypes'
import { RecipientsFormSection } from '../../../App/components/AlertConfigurationModal/RecipientsFormSection'
import { SelectedAlertRule } from '../../../App/pages/AlertsPage/ConfigurationPage'
import {
  StyledOutlinedButton,
  StyledPrimaryButton,
} from '../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { TimeFormSection } from '../../../App/components/AlertConfigurationModal/TimeFormSection'
import { getObjectsOnlyInFirstArray, getObjectsWithoutId, getUpdatedObjects } from '../../../App/utils/objectsFiltering'
import { showToast } from '../../../Shared/actions/appActions'
import { useCompressedAirAlertForm } from '../../hooks/useCompressedAirAlertForm'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface MutableCreateGeneralAlertRuleInput extends Mutable<CreateGeneralAlertRuleInput> {
  compressedAirThresholdCondition: Mutable<CreateGeneralAlertRuleInput['compressedAirThresholdCondition']>
}

interface ICompressedAirAlertFormProps {
  selectProps: SelectProps<string>
  selectedAlert?: SelectedAlertRule
  onClose: () => void
  onLoading: (newValue: boolean) => void
  refetchAlertRules: () => void
}

export const CompressedAirAlertForm: FC<ICompressedAirAlertFormProps> = ({
  selectProps,
  selectedAlert,
  onClose,
  onLoading,
  refetchAlertRules,
}) => {
  const { i18n } = useI18nContext()
  const client = useApolloClient()
  const { customerId } = useCurrentUser()
  const { control, errors, watch, handleSubmit, clearErrors, setValue, reset } = useCompressedAirAlertForm()
  const { data: assetsData } = useGetAssetsWithSensorLocationsQuery()

  const isCreateMode = !selectedAlert?.id

  const onCompleted = () => {
    clearErrors()
    refetchAlertRules()
    onClose()
  }

  const onError = () => {
    showToast(i18n.text('errors.generic.title'), 'error', 'Error')
  }

  const [createCompressedAirAlertRule, { loading: createGeneralAlertRuleLoading }] = useInsertGeneralAlertForCaMutation(
    {
      onCompleted,
      onError,
    }
  )

  const [updateCompressedAirAlertRule, { loading: updateGeneralAlertRuleLoading }] = useUpdateGeneralAlertForCaMutation(
    {
      onCompleted,
      onError,
      refetchQueries: [
        {
          query: GetCaGeneralAlertRuleByIdDocument,
          variables: { id: selectedAlert?.id },
        },
      ],
    }
  )

  const [runGeneralAlertRuleInformation, { data: alertRuleData, loading: getGeneralAlertRuleInformationLoading }] =
    useGetCaGeneralAlertRuleByIdLazyQuery()

  const isLoading =
    createGeneralAlertRuleLoading || getGeneralAlertRuleInformationLoading || updateGeneralAlertRuleLoading

  const assets = useMemo(() => {
    const assets = assetsData?.myOrg?.groups.flatMap(group => group.assets.flatMap(asset => asset.asset))
    return (
      assets?.map(asset => ({
        id: asset.id,
        name: asset.name,
        sensorLocations: asset.compressedAirFlowSensorLocations.map(location => ({
          id: location.id,
          name: location.name,
        })),
      })) || []
    )
  }, [assetsData])

  useEffect(() => {
    onLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (alertRuleData) {
      const alertRule = alertRuleData.generalAlertRuleById
      if (alertRule) {
        reset({
          assetId: alertRule.condition?.sensorLocation.assetId || '',
          message: alertRule.message || '',
          coolDownMinutes: alertRule.coolDownMinutes || 2,
          alertAfterMinutes: alertRule.alertAfterMinutes,
          category: alertRule.category,
          isAlwaysOn: alertRule.isAlwaysOn,
          recipients: alertRule.recipients.map(recipient => ({
            id: recipient.id,
            channel: recipient.channel as unknown as GeneralAlertRuleRecipientChannelsEnum,
            destination: recipient.destination,
            name: recipient.name,
          })),
          locationId: alertRule.condition?.sensorLocation.id,
          thresholdOperatorType:
            (alertRule.condition?.thresholdOperatorType as ThresholdOperatorType) || ThresholdOperatorType.GREATER,
          lowerThresholdValue: alertRule.condition?.lowerThreshold || 0,
          upperThresholdValue: alertRule.condition?.upperThreshold || 0,
          alertIntervals: alertRule.alertIntervals.map(interval => ({
            id: interval.id,
            fromDay: interval.fromDay || DEFAULT_ALERT_RULE_WEEKDAY,
            fromTime: interval.fromTime || DEFAULT_MIDDAY_TIME,
            toDay: interval.toDay || DEFAULT_ALERT_RULE_WEEKDAY,
            toTime: interval.toTime || DEFAULT_MIDDAY_TIME,
          })),
        })
      }
    }
  }, [alertRuleData])

  useEffect(() => {
    if (selectedAlert?.id) {
      runGeneralAlertRuleInformation({
        variables: {
          id: selectedAlert.id,
        },
      })
    }
  }, [selectedAlert?.id])

  const handleFormSubmit: SubmitHandler<CompressedAirAlertFormVars> = data => {
    if (isCreateMode) {
      handleNewAlertRuleCreate(data)
    } else {
      handleAlertRuleUpdate(data)
    }
  }

  function handleNewAlertRuleCreate(data: CompressedAirAlertFormVars) {
    const sendForm: MutableCreateGeneralAlertRuleInput = {
      customerId,
      message: data.message,
      category: GeneralAlertRuleCategoriesEnum.CaFlow,
      isAlwaysOn: !!data.isAlwaysOn,
      recipients: data.recipients?.map(recipient => ({
        channel: recipient.channel,
        destination: recipient.destination,
        name: recipient.name,
      })) as Recipient[],
      alertIntervals: data.alertIntervals,
      alertAfterMinutes: 2,
      compressedAirThresholdCondition: {
        locationId: data.locationId,
        thresholdOperatorType: data.thresholdOperatorType || ThresholdOperatorType.GREATER,
      },
    }

    if (sendForm.compressedAirThresholdCondition) {
      switch (data.thresholdOperatorType) {
        case ThresholdOperatorType.GREATER:
          sendForm.compressedAirThresholdCondition.lowerThreshold = data.lowerThresholdValue || 0
          sendForm.compressedAirThresholdCondition.upperThreshold = null
          break
        case ThresholdOperatorType.BETWEEN:
          sendForm.compressedAirThresholdCondition.lowerThreshold = data.lowerThresholdValue || 0
          sendForm.compressedAirThresholdCondition.upperThreshold = data.upperThresholdValue || 0
          break
        case ThresholdOperatorType.LESS:
          sendForm.compressedAirThresholdCondition.upperThreshold = data.upperThresholdValue || 0
          sendForm.compressedAirThresholdCondition.lowerThreshold = null
          break
        default:
          break
      }
    }

    if (data.isAlwaysOn) {
      sendForm.alertIntervals = []
    }

    createCompressedAirAlertRule({
      variables: { vars: { ...sendForm } },
    })
  }

  function handleAlertRuleUpdate(data: CompressedAirAlertFormVars) {
    if (alertRuleData?.generalAlertRuleById) {
      const alertRule = alertRuleData.generalAlertRuleById

      const initialRecipients = alertRule.recipients.map(recipient => ({
        id: recipient.id,
        name: recipient.name,
        channel: recipient.channel,
        destination: recipient.destination,
      }))

      const initialAlertIntervals = alertRule.alertIntervals.map(interval => ({
        id: interval.id,
        fromDay: interval.fromDay || '',
        fromTime: interval.fromTime || '',
        toDay: interval.toDay || '',
        toTime: interval.toTime || '',
      }))

      const recipientsToBeCreated = getObjectsWithoutId(data.recipients as unknown as Record<string, string>[]).map(
        recipient => ({
          vars: {
            alertRuleId: alertRule.id,
            name: recipient.name,
            destination: recipient.destination,
            channel: recipient.channel as GeneralAlertRuleRecipientChannelsEnum,
          },
        })
      )

      const recipientsToBeUpdated = getUpdatedObjects(
        initialRecipients,
        data.recipients as unknown as Record<string, string>[]
      ).map(recipient => ({
        id: recipient.id,
        vars: {
          name: recipient.name,
          destination: recipient.destination,
          channel: recipient.channel,
        },
      }))

      const recipientsToBeDeleted = getObjectsOnlyInFirstArray(
        initialRecipients,
        data.recipients as unknown as Record<string, string>[]
      )

      const alertIntervalsToBeCreated = getObjectsWithoutId(
        data.alertIntervals as unknown as Record<string, string>[]
      ).map(interval => ({
        vars: {
          fromDay: interval.fromDay,
          fromTime: interval.fromTime,
          toDay: interval.toDay,
          toTime: interval.toTime,
          alertRuleId: alertRule.id,
        },
      }))
      const alertIntervalsToBeUpdated = getUpdatedObjects(
        initialAlertIntervals,
        data.alertIntervals as unknown as Record<string, string>[]
      ).map(interval => ({
        id: interval.id,
        vars: {
          fromDay: interval.fromDay,
          fromTime: interval.fromTime,
          toDay: interval.toDay,
          toTime: interval.toTime,
        },
      }))

      const alertIntervalsToBeDeleted = data.isAlwaysOn
        ? initialAlertIntervals.map(alertInterval => alertInterval.id)
        : getObjectsOnlyInFirstArray(initialAlertIntervals, data.alertIntervals as unknown as Record<string, string>[])

      const vars = {
        message: data.message,
        coolDownMinutes: data.coolDownMinutes,
        alertAfterMinutes: data.alertAfterMinutes,
        category: data.category as GeneralAlertRuleCategoriesEnum,
        isAlwaysOn: data.isAlwaysOn,
      }

      const compressedAirThresholdCondition = {
        vars: {
          locationId: alertRule.condition?.sensorLocation.id || data.locationId,
          thresholdOperatorType:
            data.thresholdOperatorType || alertRule.condition?.thresholdOperatorType || ThresholdOperatorType.GREATER,
          upperThreshold:
            data.thresholdOperatorType === ThresholdOperatorType.GREATER ? null : data.upperThresholdValue,
          lowerThreshold: data.thresholdOperatorType === ThresholdOperatorType.LESS ? null : data.lowerThresholdValue,
        },
      }

      updateCompressedAirAlertRule({
        variables: {
          id: alertRule.id,
          vars,
          conditionVars: compressedAirThresholdCondition,
          createRecipientsVars: recipientsToBeCreated,
          updateRecipientsVars: recipientsToBeUpdated,
          deleteRecipientsIds: recipientsToBeDeleted,
          createAlertIntervalsVars: alertIntervalsToBeCreated,
          updateAlertIntervalsVars: alertIntervalsToBeUpdated,
          deleteAlertIntervalsIds: alertIntervalsToBeDeleted,
        },
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <AlertTriggerConditions
          control={control}
          selectProps={selectProps}
          assets={assets}
          watch={watch}
          isCreate={isCreateMode}
          errors={errors}
        />
        <MessageFormSection
          name="message"
          control={control}
          errors={errors}
        />
        <TimeFormSection
          names={{
            isAlwaysOn: 'isAlwaysOn',
            alertIntervals: 'alertIntervals',
          }}
          selectProps={selectProps}
          control={control}
          watch={watch}
          setValue={setValue}
        />
        <RecipientsFormSection
          name="recipients"
          selectProps={selectProps}
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
        <Box
          sx={{
            display: 'flex',
            gap: '0.75rem',
            borderTop: theme => `1px solid ${theme.palette.SFIGreyLight[200]}`,
            paddingTop: '1.5rem',
          }}
        >
          <StyledOutlinedButton
            disabled={isLoading}
            onClick={onClose}
          >
            {i18n.text('generic.cancel')}
          </StyledOutlinedButton>
          <StyledPrimaryButton
            disabled={isLoading}
            type="submit"
          >
            {i18n.text('generic.save')}
          </StyledPrimaryButton>
        </Box>
      </Box>
    </form>
  )
}
