import { FC } from 'react'
import { Menu } from '@mui/material'

import { CustomerSelector } from '../../../Shared/components/MUIComponents/CustomerSelector/CustomerSelector'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'

interface CustomerMenuProps {
  handleMenuClose: () => void
  menuAnchorEl: null | HTMLElement
}

const CustomerMenu: FC<CustomerMenuProps> = ({ menuAnchorEl, handleMenuClose }) => {
  const { user, selectedCustomer, switchCustomer } = useCurrentUser()
  return (
    <Menu
      anchorEl={menuAnchorEl}
      open={!!menuAnchorEl}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 3,
        sx: {
          p: 1,
          overflow: 'visible',
          mt: 3,
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <CustomerSelector
        customers={user.customers}
        selectedCustomer={selectedCustomer}
        switchCustomer={switchCustomer}
      />
    </Menu>
  )
}

export default CustomerMenu
