import { useTheme } from '@mui/material'

import { Widget, WidgetLoader } from '../../../Shared/components/UploadWidget/UploadWidget'
import { WidgetResult } from '../../../Shared/components/UploadWidget/types'
import { getCustomCognitoHeaders, getMediaUploadSignatureEndpoint } from './utils'

export interface WidgetWrapperProps {
  onCloudinaryImageUploaded: (response: WidgetResult) => void
  onCloudinaryWidgetClosed: () => Promise<void>
}

export const WidgetWrapper = ({ onCloudinaryImageUploaded, onCloudinaryWidgetClosed }: WidgetWrapperProps) => {
  const theme = useTheme()

  return (
    <>
      <WidgetLoader />
      <Widget
        sources={['local']}
        resourceType={'image'}
        cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
        uploadPreset={'signed_upload'}
        buttonText={'+ UPLOAD IMAGE'}
        style={{
          color: theme.palette.common.white,
          border: 'none',
          backgroundColor: theme.palette.SFIBrand[900],
          borderRadius: '5px',
          height: '50px',
          fontSize: '15px',
          fontWeight: 500,
          cursor: 'pointer',
          margin: theme.spacing(2),
          padding: theme.spacing(1.5, 8),
        }}
        folder={`${process.env.REACT_APP_API_ENV}/machine_images`}
        cropping={false}
        multiple={true}
        autoClose={false}
        onSuccess={onCloudinaryImageUploaded}
        onClose={onCloudinaryWidgetClosed}
        logging={false}
        use_filename={false}
        destroy={true}
        generateSignatureUrl={getMediaUploadSignatureEndpoint()}
        getCustomHeaders={getCustomCognitoHeaders}
        accepts={'application/json'}
        contentType={'application/json'}
        unique_filename={true}
      />
    </>
  )
}
