import { createSvgIcon } from '@mui/material'

export const ChevronDoubleLeft = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 14.1668L10.8333 10.0002L15 5.8335M9.16667 14.1668L5 10.0002L9.16667 5.8335"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'chevron-double-left-icon'
)
