import { Box, Typography, Select, MenuItem } from '@mui/material';
import MUIPopover from '../../../../../../Shared/components/MUIComponents/Popover/Popover';
import { Control, Controller, UseFormClearErrors } from 'react-hook-form';
import { FormInputs } from '../EditAlertDialog';
import { useI18nContext } from '../../../../../../Shared/contexts/i18nContext/I18nContext';

type AlertConfigurationProps = {
  control: Control<FormInputs>;
  clearErrors: UseFormClearErrors<FormInputs>
};

export default function SelectAlertEvaluationMode({ control, clearErrors }: AlertConfigurationProps) {

  const {i18n} = useI18nContext();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignSelf: 'center',
        alignItems: 'center',
        width: '90%',
        flexDirection: 'row',
        padding: '1rem'
      }}
    >
      <Typography noWrap width={200}>{i18n.text('pdm.configure.alert.dialog.type.alert')}</Typography>
      <Controller
        name="evaluationMode"
        control={control}
        render={({ field: {onChange, ...rest} }) => {
          return (
            <Select
            {...rest}
            defaultValue='CONTINUOUS_INTERVAL'
            fullWidth
            onChange={(e)=>{
              clearErrors();
              onChange(e.target.value);
            }}
            >
              <MenuItem value="CONTINUOUS_INTERVAL">{i18n.text('pdm.configure.alert.dialog.continuous.interval')}</MenuItem>
              <MenuItem value="MEASUREMENTS_IN_INTERVAL">{i18n.text('pdm.configure.alert.dialog.measurement.in.interval')}</MenuItem>
            </Select>
          )
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          marginLeft: '1rem',
          marginRight: '1rem',
        }}
      >
        <MUIPopover>
          <Box sx={{ p: 2 }} width={'50rem'}>
            <Typography variant='h6'> {i18n.text('pdm.configure.alert.dialog.popover.title')} </Typography>
            <Typography variant='body1'>
              {i18n.text('pdm.configure.alert.dialog.popover.body')}
            </Typography>
          </Box>
        </MUIPopover>
      </Box>
    </Box>
  );
}
