import { FC } from 'react'
import { Typography, useTheme } from '@mui/material'

import ProductionStatisticsTable from './ProductionStatisticsTable'
import { ProductionStatisticsRow } from '../../types/ProductionStatistics.types'
import { TableBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type ProductionStatisticsProps = {
  productionStatisticsData: ProductionStatisticsRow[]
}

const ProductionStatistics: FC<ProductionStatisticsProps> = ({ productionStatisticsData }) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()

  return (
    <TableBoxWrapper>
      <Typography
        variant="h6"
        sx={{ padding: theme.spacing(2) }}
      >
        {i18n.text('oee.production-statistics.title')}
      </Typography>
      <ProductionStatisticsTable tableData={productionStatisticsData} />
    </TableBoxWrapper>
  )
}

export default ProductionStatistics
