import { FC } from 'react'

import { AssetTreeGroup } from '../../types'
import { ElectricityAssetTreeGroup } from './ElectricityAssetTreeGroup'

interface ElectricityAssetTreeMachineTabProps {
  visibleGroups: AssetTreeGroup[]
  searchTerm: string
  onSelect: (assetId: string) => void
  onGroupSelect: (groupId: string) => void
}

export const ElectricityAssetTreeMachineTab: FC<ElectricityAssetTreeMachineTabProps> = ({
  visibleGroups,
  searchTerm,
  onSelect,
  onGroupSelect,
}) => {
  return (
    <>
      {visibleGroups.map((group: AssetTreeGroup, index: number) => (
        <ElectricityAssetTreeGroup
          isExpanded={index === 0}
          key={group.id}
          group={group}
          machines={group.machines}
          onSelect={onSelect}
          onGroupSelect={onGroupSelect}
          searchTerm={searchTerm}
        />
      ))}
    </>
  )
}
