import { createSvgIcon } from '@mui/material'

export const CopyIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_11286_21696)">
      <path
        d="M4.16669 12.4993C3.39012 12.4993 3.00183 12.4993 2.69555 12.3725C2.28717 12.2033 1.96271 11.8789 1.79355 11.4705C1.66669 11.1642 1.66669 10.7759 1.66669 9.99935V4.33268C1.66669 3.39926 1.66669 2.93255 1.84834 2.57603C2.00813 2.26243 2.2631 2.00746 2.5767 1.84767C2.93322 1.66602 3.39993 1.66602 4.33335 1.66602H10C10.7766 1.66602 11.1649 1.66602 11.4712 1.79288C11.8795 1.96204 12.204 2.2865 12.3732 2.69488C12.5 3.00116 12.5 3.38945 12.5 4.16602M10.1667 18.3327H15.6667C16.6001 18.3327 17.0668 18.3327 17.4233 18.151C17.7369 17.9912 17.9919 17.7363 18.1517 17.4227C18.3334 17.0661 18.3334 16.5994 18.3334 15.666V10.166C18.3334 9.23259 18.3334 8.76588 18.1517 8.40937C17.9919 8.09576 17.7369 7.84079 17.4233 7.68101C17.0668 7.49935 16.6001 7.49935 15.6667 7.49935H10.1667C9.23327 7.49935 8.76656 7.49935 8.41004 7.68101C8.09643 7.84079 7.84147 8.09576 7.68168 8.40937C7.50002 8.76588 7.50002 9.2326 7.50002 10.166V15.666C7.50002 16.5994 7.50002 17.0661 7.68168 17.4227C7.84147 17.7363 8.09643 17.9912 8.41004 18.151C8.76656 18.3327 9.23327 18.3327 10.1667 18.3327Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11286_21696">
        <rect
          width="20"
          height="20"
          fill="none"
        />
      </clipPath>
    </defs>
  </svg>,
  'copy-icon'
)
