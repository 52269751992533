import React, { FC } from 'react'
import { Box, Typography, styled, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import {
  DateView,
  PickersLayoutContentWrapper,
  PickersLayoutProps,
  PickersLayoutRoot,
  dateCalendarClasses,
  pickersLayoutClasses,
  usePickerLayout,
} from '@mui/x-date-pickers'

import { BrandContainedButton } from '../../BrandContainedButton'
import { CustomSwitch, SwitchLabel, SwitchWrapper } from '../NewDesignStyledSwitch'
import { DateTimeRangePickerShortcuts } from './DateTimeRangePickerShortcuts'
import { OutlinedStyledButton } from '../OutlinedStyledButton'
import { useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

interface ICustomLayoutProps extends PickersLayoutProps<DateTime | null, DateTime, DateView> {
  saveDates: (from?: DateTime | null, to?: DateTime | null) => void
  closeCalendar: () => void
}

interface IActionBarProps {
  saveDates: (from?: DateTime | null, to?: DateTime | null) => void
  closeCalendar: () => void
}

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '14px',
  padding: theme.spacing(0, 3),
  position: 'absolute',
  bottom: theme.spacing(0.25),
}))

const ActionBarButtons = styled(Box)(({ theme }) => ({
  gridColumn: 2,
  gridRow: 2,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1.5),
  padding: theme.spacing(2),
}))

const ActionBar: FC<IActionBarProps> = ({ saveDates, closeCalendar }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  return (
    <ActionBarButtons>
      <OutlinedStyledButton
        sx={{
          borderRadius: '6px',
          width: '110px',
          textTransform: 'capitalize',
          color: theme.palette.SFIGreyLight[700],
        }}
        onClick={closeCalendar}
      >
        {i18n.text('generic.cancel')}
      </OutlinedStyledButton>
      <BrandContainedButton onClick={saveDates}>{i18n.text('datetimepicker.button.apply')}</BrandContainedButton>
    </ActionBarButtons>
  )
}

export const CustomLayout: FC<ICustomLayoutProps> = ({ saveDates, closeCalendar, ...props }) => {
  const { content } = usePickerLayout(props)
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { error, isTimePicker, isCompareDatesOn, showCompareDatesButton, onCompareDatesToggle } =
    useDateTimeRangePickerContext()

  function handleCompareDatesToggle() {
    if (onCompareDatesToggle) {
      onCompareDatesToggle(!isCompareDatesOn)
    }
  }

  return (
    <PickersLayoutRoot
      ownerState={props}
      sx={{
        gridTemplateColumns: `${theme.spacing(24)} 1fr`,
        gridTemplateRows: showCompareDatesButton || isTimePicker ? `1fr ${theme.spacing(9)}` : '1fr 0',
        padding: 0,
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        [`.${pickersLayoutClasses.contentWrapper}`]: {
          gridColumn: 2,
          gridRow: 1,
          border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
          borderWidth: '0 0 1px 1px',
        },
        [`.MuiDayCalendar-slideTransition`]: {
          minHeight: theme.spacing(35),
        },
      }}
    >
      <DateTimeRangePickerShortcuts saveDates={saveDates} />
      {showCompareDatesButton && (
        <SwitchWrapper>
          <CustomSwitch
            checked={isCompareDatesOn}
            onChange={handleCompareDatesToggle}
            color="secondary"
          />
          <SwitchLabel>{i18n.text('date-picker.compare-dates')}</SwitchLabel>
        </SwitchWrapper>
      )}
      <PickersLayoutContentWrapper
        className={pickersLayoutClasses.contentWrapper}
        sx={{
          position: 'relative',
          [`.MuiDayCalendar-weekDayLabel`]: {
            fontSize: '14px',
            color: theme.palette.SFIGreyLight[700],
            lineHeight: '1.25rem',
            fontWeight: 500,
          },
          [`.${dateCalendarClasses.root}`]: {
            maxHeight: theme.spacing(68),
            height: 'fit-content',
            width: isTimePicker ? '100%' : 'initial',
          },
        }}
      >
        {content}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </PickersLayoutContentWrapper>
      {(isCompareDatesOn || isTimePicker) && (
        <ActionBar
          saveDates={saveDates}
          closeCalendar={closeCalendar}
        />
      )}
    </PickersLayoutRoot>
  )
}
