import { styled } from '@mui/material'

const StyledSVG = styled('svg')(({ theme }) => ({
  width: theme.breakpoints.values.xl,
  [theme.breakpoints.up('xl')]: {
    width: '100vw',
  },
}))

export function SSFWave() {
  return (
    <StyledSVG
      viewBox="0 0 1440 237"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1440 42L1415.98 69.0833C1391.97 96.1667 1343.93 150.333 1295.9 161.167C1247.87 172 1199.83 139.5 1151.8 134.083C1103.77 128.667 1055.73 150.333 1007.7 128.667C959.667 107 911.633 2 863.6 2C815.567 2 767.533 107 719.5 123.25C671.467 139.5 623.433 107 575.4 85.3333C527.367 63.6667 479.333 52.8333 431.3 79.9167C383.267 107 335.233 172 287.2 204.5C239.167 237 191.133 237 143.1 220.75C95.0667 204.5 47.0334 172 23.0167 155.75L-1 139.5V237H23.0167C47.0334 237 95.0667 237 143.1 237C191.133 237 239.167 237 287.2 237C335.233 237 383.267 237 431.3 237C479.333 237 527.367 237 575.4 237C623.433 237 671.467 237 719.5 237C767.533 237 815.567 237 863.6 237C911.633 237 959.667 237 1007.7 237C1055.73 237 1103.77 237 1151.8 237C1199.83 237 1247.87 237 1295.9 237C1343.93 237 1391.97 237 1415.98 237H1440V42Z"
        fill="url(#paint0_linear_1630_27814)"
        fillOpacity="0.25"
      />
      <path
        opacity="0.2"
        d="M1440 42L1415.98 69.1657C1391.97 96.3314 1343.93 150.663 1295.9 161.529C1247.87 172.395 1199.83 139.797 1151.8 134.363C1103.77 128.93 1055.73 150.663 1007.7 128.93C959.667 107.198 911.633 2 863.6 2C815.567 2 767.533 107.198 719.5 123.497C671.467 139.797 623.433 107.198 575.4 85.4651C527.367 63.7326 479.333 52.8663 431.3 80.032C383.267 107.198 335.233 172.395 287.2 204.994C239.167 237.593 191.133 237.593 143.1 221.294C95.0666 204.994 47.0334 172.395 23.0167 156.096L-1 139.797"
        stroke="#006AFF"
        strokeWidth="3"
      />
      <g filter="url(#filter0_d_1630_27814)">
        <circle
          cx="490.5"
          cy="64.5"
          r="6.5"
          fill="#006AFF"
        />
        <circle
          cx="490.5"
          cy="64.5"
          r="8.5"
          stroke="white"
          strokeWidth="4"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1630_27814"
          x="460"
          y="44"
          width="61"
          height="61"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.207843 0 0 0 0 0.521569 0 0 0 0 0.545098 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1630_27814"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1630_27814"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1630_27814"
          x1="719.5"
          y1="42"
          x2="719.5"
          y2="237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AFF" />
          <stop
            offset="1"
            stopColor="#1F7DFF"
            stopOpacity="0"
          />
        </linearGradient>
      </defs>
    </StyledSVG>
  )
}
