import * as Sentry from '@sentry/react'
import ky from 'ky'
import { isOfShape, isString } from 'type-guards'

import { AuthApiReq, MigrateResponse, MigrateResult, RequestResetResult } from '../types/types'
import { safeJsonParse } from '../utils'

const isResponseWithError = isOfShape({
  error: isString,
})

async function authApiReq<Result extends {}>(
  body: AuthApiReq,
  options?: {
    headers?: Record<string, string>
  }
): Promise<Result> {
  try {
    const form = new URLSearchParams(body)
    const provisionalHeaders = options?.headers ?? {}
    const response = await ky.post('/api/auth/token', {
      body: form,
      retry: 2,
      timeout: 30000,
      headers: {
        ...(body.grant_type === 'switch_customer' ? provisionalHeaders : {}),
      },
      throwHttpErrors: false,
    })

    const rawResponse = await response.text()
    const result = safeJsonParse<Result | { error: string }>(rawResponse)

    const reportToSentryAndFail = (message: string) => {
      Sentry.captureEvent({
        level: Sentry.Severity.Error,
        message,
        contexts: {
          'API Response': {
            status: response.status,
            body: rawResponse,
          },
        },
      })
      return Promise.reject(new Error(message))
    }

    if (isResponseWithError(result)) {
      return reportToSentryAndFail(result.error)
    }

    if (!result || !response.ok) {
      return reportToSentryAndFail('unexpected server response')
    }

    return result
  } catch (e) {
    Sentry.captureException(e)
    return Promise.reject(new Error('unable to authenticate'))
  }
}

export const AuthApi = {
  async migrate(username: string, password: string, headers: Record<string, string>): Promise<MigrateResult> {
    Sentry.addBreadcrumb({
      message: 'Attempt Cognito migration',
      data: { username },
    })
    try {
      await authApiReq<MigrateResponse>(
        {
          grant_type: 'migrate',
          username,
          password,
          origin: window.origin,
        },
        {
          headers,
        }
      )
      return { code: 'RETRY_LOGIN' }
    } catch (error) {
      const e = error as Error
      return { code: 'NO_RETRY', message: e.message }
    }
  },

  async forgotPassword(username: string): Promise<RequestResetResult> {
    Sentry.addBreadcrumb({
      message: 'Attempt Cognito password reset request',
      data: { username },
    })

    try {
      await authApiReq<MigrateResponse>({
        grant_type: 'password_reset_request',
        username,
        origin: window.origin,
      })
      return { code: 'RESET' }
    } catch (error) {
      const e = error as Error
      return { code: 'ERROR', message: e.message }
    }
  },
}
