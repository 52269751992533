import { useMemo } from 'react'

import usePropositions from '../../Shared/hooks/usePropositions/usePropositions'
import { AlertTypes } from '../types/alertTypes'
import { AlertType as AlertTypeEnum } from '../../Shared/types/types'

type AlertType = typeof AlertTypes
type AlertTypeKey = keyof AlertType

export const useAlertTypeCategoriesByProposition = (selectedProposition: string) => {
  const { propositions } = usePropositions()

  const alertTypeCategories = useMemo(() => {
    switch (selectedProposition) {
      case propositions.electricity.name:
        return [AlertTypeEnum.POWER_ALERT, AlertTypeEnum.STANDBY_ALERT]
      case propositions['compressed-air'].name:
        return [AlertTypeEnum.CA_FLOW]
      case propositions.gas.name:
        return [AlertTypeEnum.GAS_ALERT]
      case propositions.water.name:
        return [AlertTypeEnum.WATER_ALERT]
      case propositions['predictive-maintenance'].name:
        return [AlertTypeEnum.THRESHOLD_ALERT]
      case propositions.oee.name:
        return [AlertTypeEnum.DOWNTIME_ALERT]
      case propositions.temperature.name:
      default:
        return []
    }
  }, [selectedProposition])

  return alertTypeCategories.map(alertType => ({
    value: alertType,
    title: AlertTypes[alertType as AlertTypeKey],
  }))
}
