import { InputBase, inputBaseClasses, Select, SelectProps, styled } from '@mui/material'

// To be used with StyledSelectMenuItem as items
const StyledSelectBaseInput = styled(InputBase)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '1.5rem',
  color: theme.palette.SFIGreyLight[800],
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  borderRadius: theme.shape.borderRadius,
  '& .MuiInputBase-input': {
    padding: '0.625rem 0.875rem',
  },
  [`&.${inputBaseClasses.root}.Mui-error`]: {
    borderColor: theme.palette.SFIOrange[600]
  },
}))

const SelectElement = styled(Select)(({ theme }) => ({
  width: '100%',
})) as unknown as typeof Select

export const StyledSelect: <T>(props: SelectProps<T>) => JSX.Element = props => {
  return (
    <SelectElement
      {...props}
      input={<StyledSelectBaseInput />}
    />
  )
}
