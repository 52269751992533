import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import { Box, Switch, Tooltip, Typography } from '@mui/material'

import MUILoader from '../../../Shared/components/MUIComponents/Loader'
import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import { ID } from '../../../Shared/types/types'
import { ParsedAlertRule } from '../../containers/GraphsPage/utils'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useUpdateMuteNotificationsAlertRuleMutation } from '../../../Shared/graphql/codegen'
import { sendMuteAlertsEventPDM } from '../../utils/analyticsEvents'

type MuteNotificationsProps = {
  rule: ParsedAlertRule
}

export const MuteNotificationsToggle = ({ rule }: MuteNotificationsProps) => {
  const { i18n } = useI18nContext()
  const { sendEvent } = useAnalytics()
  const [
    updateMuteNotificationMutation,
    { data: muteNotificationData, loading: muteNotificationLoading, error: muteNotificationError },
  ] = useUpdateMuteNotificationsAlertRuleMutation()

  const muteNotificationUpdatedState = muteNotificationData?.updateThresholdAlertRule?.rule?.config.muteNotifications
  const muteNotificationsState = muteNotificationData ? muteNotificationUpdatedState : rule.muteNotifications

  const handleMuteNotification = async () => {
    const muteNotificationsParameters = {
      ruleId: rule.id as ID,
      muteNotifications: !muteNotificationsState,
    }

    await updateMuteNotificationMutation({
      variables: {
        ...muteNotificationsParameters,
      },
    })
    sendMuteAlertsEventPDM(sendEvent)
  }

  return (
    <>
      {muteNotificationLoading && (
        <Box>
          <MUILoader
            margin="0"
            height={2.5}
          />
        </Box>
      )}
      {muteNotificationError && (
        <Typography
          variant="h6"
          color="red"
          sx={{ margin: '1rem' }}
          fontWeight={400}
        >
          {i18n.text('pdm.configure.alert.dialog.mute-notification-error')}
        </Typography>
      )}
      {!muteNotificationLoading && !muteNotificationError && (
        <>
          {!muteNotificationsState && rule.duration !== 0 ? (
            <NotificationsRoundedIcon
              fontSize="large"
              color="secondary"
            />
          ) : (
            <Tooltip
              title={i18n.text('pdm.configure.alert.dialog.display.alert.tooltip')}
              placement="top"
              arrow
            >
              <NotificationsOffIcon
                fontSize="large"
                color="disabled"
              />
            </Tooltip>
          )}
          <Switch
            size="medium"
            color="secondary"
            disabled={rule.duration !== 0 ? false : true}
            checked={!muteNotificationsState && rule.duration !== 0}
            onClick={handleMuteNotification}
          />
        </>
      )}
    </>
  )
}
