import type { AssetLevel0, AssetTree } from '../../types'
import { ElectricityAssetFragment } from '../../../Shared/graphql/codegen'

const isElectricityAsset = (asset: ElectricityAssetFragment) =>
  asset.payload &&
  (asset.payload.commodityType === 'electric-current' ||
    asset.payload.commodityType === 'electric-energy' ||
    !asset.payload.commodityType)

/**
 *
 * @param rawGroups Asset groups trees as returned by query
 * @returns Groups that have machines with the electricity commodity types and a sensor attached
 *  OR with at least 1 component with electricity commodity type and a sensor attached
 *  If Component is unnamed we do not show component.
 */
export const filterElectricityAssets = (rawGroups: readonly AssetLevel0[]) => {
  const groups: AssetLevel0[] = []

  rawGroups.forEach(g => {
    const machines = g.assets.filter(machine => {
      // return machines with the electricity commodity type and that have 1 sensor attached
      // or machines with at least 1 component with the electricity commodity type with a sensor attached
      const sensorCount = [
        ...machine.sensors,
        ...machine.assets.filter(component => isElectricityAsset(component)).flatMap(component => component.sensors),
      ]
      return isElectricityAsset(machine) && sensorCount.length > 0
    })

    const machinesWithComponents = machines.map(machine => {
      // filter a machine's components to only those with the electricity commodity type and that have a name and a sensor attached
      const components = machine.assets.filter(
        component => isElectricityAsset(component) && !!component.name && component.sensors.length > 0
      )
      return {
        ...machine,
        assets: components,
      }
    })

    const group: AssetLevel0 = {
      ...g,
      assets: machinesWithComponents,
    }

    if (group.assets.length > 0) {
      groups.push(group)
    }
  })
  return groups
}

const localeCompareName: (a: { name: string }, b: { name: string }) => number = (a, b) => a.name.localeCompare(b.name)

export const processElectricityNewAssetTree = (rawGroups: readonly AssetLevel0[]): AssetTree => {
  const filteredGroups = filterElectricityAssets(rawGroups)
  const sortedGroups = filteredGroups.sort(localeCompareName)

  return {
    groups: sortedGroups.map(group => ({
      id: group.id,
      name: group.name,
      //@ts-expect-error our version is so old it doesn't know modern JS toSorted
      machines: group.assets.toSorted(localeCompareName).map(machine => ({
        id: machine.id,
        name: machine.name,
        commodityType: machine.payload?.commodityType || '',
        voltage: machine.payload?.voltage || '',
        powerFactor: machine.payload?.powerFactor || '',
        scale: machine.payload?.scale || '',
        //@ts-expect-error our version is so old it doesn't know modern JS toSorted
        components: machine.assets.toSorted(localeCompareName).map(component => ({
          id: component.id,
          name: component.name,
          commodityType: machine.payload?.commodityType || '',
          voltage: machine.payload?.voltage || '',
          powerFactor: machine.payload?.powerFactor || '',
          scale: machine.payload?.scale || '',
        })),
      })),
    })),
  }
}
