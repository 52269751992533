import React from 'react'
import { Redirect, RouteProps } from 'react-router-dom'
import type { Required } from 'utility-types'

import { SentryRoute as Route } from '../../services/sentry'
import { useCurrentUser } from '../../contexts/CurrentUserContext'

type Props = Required<RouteProps, 'component'>

export const InternalRoute = ({ component: RouteComponent, ...rest }: Props) => {
  const { user, isInternalUser } = useCurrentUser()
  return (
    <Route
      {...rest}
      render={routeProps => (user && isInternalUser() ? <RouteComponent {...routeProps} /> : <Redirect to="/" />)}
    />
  )
}
