import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { useState } from 'react'

import { IntegrationDialogComponent } from './IntegrationDialogComponent'
import { IntegrationsCard } from './IntegrationCard'
import {
  useCreateUserApiIntegrationMutation,
  useDeleteUserApiIntegrationMutation,
  useUpdateUserApiIntegrationMutation,
  useUserApiIntegrationsQuery,
} from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { API_DOCS_URL } from './constants'

export const Integrations = () => {
  const { i18n } = useI18nContext()
  const [integrationName, setIntegrationName] = useState<string>('')

  const [createUserApiIntegration, { data, loading, error }] = useCreateUserApiIntegrationMutation({
    onError: error => {
      console.log(error)
    },
  })

  const [updateUserApiIntegration] = useUpdateUserApiIntegrationMutation()

  const [deleteUserApiIntegration, { loading: deleteIntegrationsLoading }] = useDeleteUserApiIntegrationMutation()

  const { refetch, data: userApiIntegrationsData, loading: userApiIntegrationsLoading } = useUserApiIntegrationsQuery()

  const onCreateUserApiIntegration = async () => {
    await createUserApiIntegration({
      variables: {
        name: integrationName,
      },
    })

    await refetch()
  }

  const onDeleteUserApiIntegration = async (id: string) => {
    await deleteUserApiIntegration({
      variables: {
        id: id,
      },
    })
    await refetch()
  }

  const onSaveIntegrationName = async (id: string, name: string) => {
    await updateUserApiIntegration({
      variables: {
        id: id,
        name: name,
      },
    })
    await refetch()
  }
  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <Typography
        variant="h5"
        fontWeight={400}
        mb={2}
      >
        {i18n.text('integrations.title')}
      </Typography>
      <Box sx={{ margin: '1rem', position: 'flex', justifyContent: 'start', alignItems: 'center' }}>
        {i18n.text('integrations.key.more.info')}
        <a
          target="_blank"
          href={API_DOCS_URL} rel="noreferrer"
        >
          {' '}
          {i18n.text('integrations.key.link')}
        </a>
        <br />
        {i18n.text('integrations.key.max.keys')}
      </Box>
      <IntegrationDialogComponent
        error={error}
        data={data}
        createIntegrationLoading={loading}
        integrationName={integrationName}
        setIntegrationName={setIntegrationName}
        onCreateUserApiIntegration={onCreateUserApiIntegration}
      />
      <Box
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'wrap'}
      >
        {userApiIntegrationsData?.userApiIntegrations
          ?.slice()
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          .map((integration, index) => {
            return (
              <IntegrationsCard
                key={index}
                integration={integration}
                loading={userApiIntegrationsLoading || deleteIntegrationsLoading}
                onSaveIntegrationName={onSaveIntegrationName}
                onDeleteUserApiIntegration={onDeleteUserApiIntegration}
              />
            )
          })}
      </Box>
    </Box>
  )
}
