import { FeatureFlag } from '@sensorfactdev/draconarius'
import { mergeLeft } from 'ramda'

import { CurrentUserFragment } from '../graphql/codegen'
import { SupportedLocale, defaultLocale as DEFAULT_LOCALE } from './supportedLocales'

const normalizeUser = (gqlUser: CurrentUserFragment) => {
  const customers = (gqlUser.customers ?? []).map(({ customer }) => {
    const featureFlags: FeatureFlag[] = customer.featureFlagGroups ?? []

    const payload = customer.payload ?? {}
    return {
      ...customer,
      featureFlags,
      payload,
    }
  })

  const reportSubscription: { email: boolean; sms?: boolean } = mergeLeft(gqlUser.reportSubscription ?? {}, {
    email: false,
  })

  return {
    ...gqlUser,
    customers,
    reportSubscription,
    locale: (gqlUser.locale ?? DEFAULT_LOCALE) as SupportedLocale,
  }
}

export { normalizeUser }
