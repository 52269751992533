import { Box } from '@mui/material'
import { FC } from 'react'

import AssetTree from '../../components/AssetTree/AssetTree'

const AssetTreeLayout: FC = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        padding: '1rem',
        gridTemplateColumns: 'auto 1fr',
        gap: '1rem',
        overflowY: 'auto',
        alignItems: 'start',
      }}
    >
      <AssetTree />
      {children}
    </Box>
  )
}

export default AssetTreeLayout
