import { DoneAll, PriorityHigh } from '@mui/icons-material'
import MUILoader from '../../../Shared/components/MUIComponents/Loader'
import { styled, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import useQueryParams from '../../../Shared/hooks/UseQueryParamsHook'
import { AnalyticsEventTypes, DownloadReport } from '../../../Shared/hooks/useAnalytics/analyticsTypes'
import { DownloadType } from './types'
import { useAuthContext } from '../../../Shared/contexts/AuthContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const Wrapper = styled('div')({
  margin: 'auto',
  marginTop: '20vh',
  textAlign: 'center',
  '& > *': {
    marginTop: '2rem'
  }
})

const HiddenLink = styled('a')({
  display: 'hidden'
})

const TextWithIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': {
    marginLeft: '0.5rem'
  }
})

const DownloadLink = styled('p')({
    fontSize: '0.875em',
    fontWeight: 300,
    letterSpacing: '0.016em',
    color: '#111111',
    margin: 0,
    padding: 0
})

const Heading = styled(Typography)({
    fontSize: '1.5em',
    fontWeight: 300,
    letterSpacing: 0,
    color: '#111111',
    margin: 0,
    padding: 0,
    marginTop: '1.5rem'
})

const DownloadPage = () => {
  const { sendEvent } = useAnalytics()

  function sendDownloadEvent(event: DownloadReport | null) {
    if (event) {
      sendEvent<DownloadReport>(AnalyticsEventTypes.TRACKED_DOWNLOAD, { ...event })
    }
  }

  function decodeFileUrl(url?: string | null): string | null {
    if (!url) return null
    // Check if encoded as base64
    const base64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/
    if (base64.test(url)) {
      return atob(url)
    }
    return url
  }

  const { i18n } = useI18nContext()
  const params = useQueryParams()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const downloadLink = useRef<HTMLAnchorElement>(null)
  const { user, isSensorfactEmployee } = useAuthContext()

  const fileUrl = decodeFileUrl(params.get('file'))
  const fileName = params.get('fileName')
  const mimeType = params.get('mimeType') || ''
  const gaEvent = params.get('gaEvent')

  /**
   * Attempts to fill in the report if userId and / or internalUser keys are missing
   */
  function fillInReport(report: DownloadReport) {
    report.internalUserId = report.internalUserId ?? user?.id ?? null
    // If the user is being redirected from the e-mail there's a chance
    // they're not logged in. So we test the e-mail as opposed to the jwt
    report.isInternal = report.isInternal || isSensorfactEmployee()
    return report
  }

  function createEventTrackingReport(params: URLSearchParams): DownloadReport | null {
    const report: DownloadReport = {
      target: 'report',
      origin: params.get('origin') as DownloadReport['origin'],
      internalUserId: params.get('userId') || user?.id || null,
      customerId: params.get('customerId'),
      isInternal: params.get('internalUser') === 'true',
      reportType: params.get('reportType') as DownloadReport['reportType'],
      reportId: params.get('reportId') || 'missing_report_id',
    }

    if (user) {
      fillInReport(report)
    }
    return report
  }

  let event: DownloadReport | null = null
  if (gaEvent === DownloadType.report) {
    event = createEventTrackingReport(params)
  }

  useEffect(() => {
    if (!fileUrl) {
      return history.push('/')
    }
    fetch(fileUrl, { headers: { 'Content-Type': mimeType } })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        downloadLink.current?.setAttribute('href', url)
        sendDownloadEvent(event)
        downloadLink.current?.click()
        setLoading(false)
      })
      .catch(err => {
        const errorMessage = i18n.text('error.downloading.report')
        setError(errorMessage)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrl])

  return (
    <Wrapper>
      {loading && <MUILoader />}
      <Heading style={{ marginTop: '1.5rem' }}>
        {error ? (
          'Error'
        ) : loading ? (
          i18n.text('download.page.downloading')
        ) : (
          <TextWithIcon>
            {i18n.text('download.page.done')}{' '}
            <DoneAll sx={{
              fill: theme => theme.palette.success.main,
              width: '2.25rem',
              height: '2.25rem'
            }} />{' '}
          </TextWithIcon>
        )}
      </Heading>
      {error && (
        <TextWithIcon>
          <PriorityHigh sx={{
            fill: theme => theme.palette.error.dark
          }} />
          {error}
        </TextWithIcon>
      )}
      {fileUrl && (
        <a
          style={{ display: 'inline-block' }}
          download={fileName}
          href={fileUrl}
          onClick={() => sendDownloadEvent(event)}
        >
          {' '}
          <DownloadLink>{i18n.text('download.report.manually')}</DownloadLink>{' '}
        </a>
      )}
      <HiddenLink
        ref={downloadLink}
        download={fileName}
      />
    </Wrapper>
  )
}

export default DownloadPage
