//This is a temporary container for development purposes to easily figure which typography to pick

import { Box } from '@mui/material'

import {
  RegularDarkMdText,
  RegularDarkSmText,
  RegularDarkXlText,
  RegularLightMdText,
  RegularLightSmText,
  RegularLightXLText,
  SemiBoldDarkLgDisplayText,
  SemiboldBrandDarkMdText,
  SemiboldBrandExtraDarkXlText,
  SemiboldBrandExtraDarkXsDisplayText,
  SemiboldBrandLightLgText,
  SemiboldBrandLightMdText,
  SemiboldBrandLightXlDisplayText,
  SemiboldBrandMdDisplayText,
  SemiboldDarkLgText,
  SemiboldDarkMdDisplayText,
  SemiboldDarkMdText,
  SemiboldDarkXlText,
  SemiboldLightLgText,
  SemiboldLightMdText,
  SemiboldLightXlText,
} from '../../components/Common/v1/index'

export const TypographyExamples = () => {
  return (
    <Box>
      <SemiboldBrandLightXlDisplayText>
        SemiboldBrandLightXlDisplayText is 60 px 3.75 rem, fontweight 600, color brand600 #4793FF
      </SemiboldBrandLightXlDisplayText>
      <SemiBoldDarkLgDisplayText>
        SemiBoldDarkLgDisplayText is 48 px 3 rem, fontweight 600, grey900 #1D2939
      </SemiBoldDarkLgDisplayText>
      <SemiboldDarkMdDisplayText>
        SemiboldDarkMdDisplayText is 36px 2.25 rem, fontweight 600, color grey900 #1D2939
      </SemiboldDarkMdDisplayText>
      <SemiboldBrandMdDisplayText>
        SemiboldBrandMdDisplayText is 36px 2.25 rem, fontweight 600, color brand600 #4793FF
      </SemiboldBrandMdDisplayText>
      <SemiboldBrandExtraDarkXsDisplayText>
        SemiboldBrandExtraDarkXsDisplayText is 24px 1.5 rem, fontweight 600, color brand900 #006AFF
      </SemiboldBrandExtraDarkXsDisplayText>
      <SemiboldDarkXlText>
        SemiboldDarkXlText is 20px 1.25 rem, fontweight 600, color grey900 #1D2939
      </SemiboldDarkXlText>
      <SemiboldLightXlText>
        SemiboldLightXlText is 20px 1.25 rem, fontweight 600, color grey600 #475467
      </SemiboldLightXlText>
      <SemiboldBrandExtraDarkXlText>
        SemiboldBrandExtraDarkXlText is 20px 1.25 rem, fontweight 600, color brand900 #006AFF
      </SemiboldBrandExtraDarkXlText>
      <RegularDarkXlText>RegularDarkXlText is 20px 1.25 rem, fontweight 400, color grey900 #1D2939</RegularDarkXlText>
      <RegularLightXLText>
        RegularLightXLText is 20px 1.25 rem, fontweight 400, color grey600 #475467
      </RegularLightXLText>
      <SemiboldBrandLightLgText>
        SemiboldBrandLightLgText is 18 px 1.125 rem, fontweight 600, color brand600 #4793FF
      </SemiboldBrandLightLgText>
      <SemiboldDarkLgText>
        SemiboldDarkLgText is 18px 1.125 rem, fontweight 600, color grey900 #1D2939
      </SemiboldDarkLgText>
      <SemiboldLightLgText>
        SemiboldLightLgText is 18px 1.125 rem, fontweight 600, color grey600 #475467
      </SemiboldLightLgText>
      <SemiboldBrandDarkMdText>
        SemiboldBrandDarkMdText is 16px 1 rem, fontweight 600, color brand700 #3187FF
      </SemiboldBrandDarkMdText>
      <SemiboldBrandLightMdText>
        SemiboldBrandLightMdText is 16px 1 rem, fontweight 600, color brand600 #4793FF
      </SemiboldBrandLightMdText>
      <SemiboldDarkMdText>SemiboldDarkMdText is 16px 1 rem, fontweight 600, color grey900 #1D2939</SemiboldDarkMdText>
      <SemiboldLightMdText>
        SemiboldLightMdText is 16px 1 rem, fontweight 600, color grey600 #475467
      </SemiboldLightMdText>
      <RegularDarkMdText>RegularDarkMdText is 16px 1 rem, fontweight 400, color grey900 #1D2939</RegularDarkMdText>
      <RegularLightMdText>RegularLightMdText is 16px 1 rem, fontweight 400, color grey600 #475467</RegularLightMdText>
      <RegularDarkSmText>RegularDarkSmText is 14px 0.875 rem, fontweight 400, color grey900 #1D2939</RegularDarkSmText>
      <RegularLightSmText>
        RegularLightSmText is 14px 0.875 rem, fontweight 400, color grey600 #475467
      </RegularLightSmText>
    </Box>
  )
}
