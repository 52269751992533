import React from 'react'
import ReactDOMServer from 'react-dom/server'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { Box, darken, styled } from '@mui/material'
import { FC } from 'react'

import MuiThemeProvider from '../../Shared/theme/MuiThemeProvider'

const StyledApexWrapper = styled(Box)(({ theme }) => ({
  height: '70vh',
  [theme.breakpoints.down('xl')]: {
    height: '70vh',
  },
  overflow: 'hidden',
  '.apexcharts-toolbar': {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  '.apexcharts-zoom-icon': {
    display: 'none',
  },
  '.apexcharts-zoomin-icon': {
    width: 'fit-content',
    height: 'auto',
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.SFIBlueDark[50],
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.SFIBlueDark[50] && darken(theme.palette.SFIBlueDark[50], 0.1),
      color: theme.palette.SFIGreyLight[950],
    },
    svg: {
      fill: theme.palette.SFIGreyLight[950],
    },
  },
  '.apexcharts-zoomout-icon': {
    width: 'fit-content',
    height: 'auto',
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.SFIBlueDark[50],
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.SFIBlueDark[50] && darken(theme.palette.SFIBlueDark[50], 0.1),
      color: theme.palette.SFIGreyLight[950],
    },
    svg: {
      fill: theme.palette.SFIGreyLight[950],
    },
  },
}))

const ApexWrapper: FC = ({ children }) => {
  return <StyledApexWrapper id="chart-wrapper">{children}</StyledApexWrapper>
}

const CustomZoomInIcon = () => <ZoomInIcon sx={{ fontSize: '2rem' }} />

const CustomZoomOutIcon = () => <ZoomOutIcon sx={{ fontSize: '2rem' }} />

const zoomInIconHTML = ReactDOMServer.renderToString(
  <MuiThemeProvider>
    <CustomZoomInIcon />
  </MuiThemeProvider>
)

const zoomOutIconHTML = ReactDOMServer.renderToString(
  <MuiThemeProvider>
    <CustomZoomOutIcon />
  </MuiThemeProvider>
)

export { ApexWrapper, zoomInIconHTML, zoomOutIconHTML }
