import { Box, SxProps, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material'

interface StyledTooltipProps extends TooltipProps {
  tooltipSx?: SxProps
}

export const StyledTooltip = styled(({ className, ...props }: StyledTooltipProps) => (
  <Tooltip
    placement="top"
    arrow
    classes={{ popper: className }}
    {...props}
  />
))(({ theme, tooltipSx }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.SFIBase.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: `0px 12px 16px -4px #10182832, 0px 4px 6px -2px #10182826`,
    backgroundColor: theme.palette.SFIBase.white,
    color: theme.palette.SFIGreyLight[500],
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    ...tooltipSx,
  },
}))

export const IconWrapper = styled(Box)({
  display: 'flex',
  fontSize: '1rem',
  cursor: 'pointer',
})
