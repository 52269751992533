import { createSvgIcon } from '@mui/material'

export const CoinsHandIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M14.0295 8.35186C13.4571 8.75995 12.7566 9 12 9C10.067 9 8.5 7.433 8.5 5.5C8.5 3.567 10.067 2 12 2C13.253 2 14.3522 2.65842 14.9705 3.64814M6.5 20.0872H9.11029C9.45063 20.0872 9.78888 20.1277 10.1188 20.2086L12.8769 20.8789C13.4753 21.0247 14.0988 21.0388 14.7035 20.9214L17.753 20.3281C18.5585 20.1712 19.2996 19.7854 19.8803 19.2205L22.0379 17.1217C22.654 16.5234 22.654 15.5524 22.0379 14.9531C21.4832 14.4134 20.6047 14.3527 19.9771 14.8103L17.4626 16.6449C17.1025 16.9081 16.6643 17.0498 16.2137 17.0498H13.7855L15.3311 17.0498C16.2022 17.0498 16.9079 16.3633 16.9079 15.5159V15.2091C16.9079 14.5055 16.4156 13.892 15.7141 13.7219L13.3286 13.1417C12.9404 13.0476 12.5428 13 12.1431 13C11.1783 13 9.43189 13.7988 9.43189 13.7988L6.5 15.0249M20.5 6.5C20.5 8.433 18.933 10 17 10C15.067 10 13.5 8.433 13.5 6.5C13.5 4.567 15.067 3 17 3C18.933 3 20.5 4.567 20.5 6.5ZM2.5 14.6L2.5 20.4C2.5 20.9601 2.5 21.2401 2.60899 21.454C2.70487 21.6422 2.85785 21.7951 3.04601 21.891C3.25992 22 3.53995 22 4.1 22H4.9C5.46005 22 5.74008 22 5.95399 21.891C6.14215 21.7951 6.29513 21.6422 6.39101 21.454C6.5 21.2401 6.5 20.9601 6.5 20.4V14.6C6.5 14.0399 6.5 13.7599 6.39101 13.546C6.29513 13.3578 6.14215 13.2049 5.95399 13.109C5.74008 13 5.46005 13 4.9 13L4.1 13C3.53995 13 3.25992 13 3.04601 13.109C2.85785 13.2049 2.70487 13.3578 2.60899 13.546C2.5 13.7599 2.5 14.0399 2.5 14.6Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'coins-hand-icon'
)
