import { Theme } from '@mui/material'

import { SwitchUnit } from '../types/compressed-air_types'

interface IGetMeanAnnotationsArgs {
  showMean: boolean
  series: ApexAxisChartSeries
  unitName: SwitchUnit
  i18n: $I18FixMe
  theme: Theme
  decimal: number
}

function calculateMean(arr: number[]) {
  return arr.reduce((acc, curr) => acc + curr / arr.length, 0)
}

export function getMeanAnnotations(args: IGetMeanAnnotationsArgs): YAxisAnnotations[] {
  const { showMean, series, unitName, i18n, theme, decimal } = args
  if (!showMean) {
    return []
  }
  return series.reduce<YAxisAnnotations[]>((acc, item) => {
    if (item.type !== 'rangeArea') {
      const onlyYNumbers = item.data.map(coords => (coords ? (coords as any).y : 0))
      const mean = calculateMean(onlyYNumbers)
      acc.push({
        y: mean,
        strokeDashArray: 0,
        borderColor: item.color,
        label: {
          borderColor: item.color,
          style: {
            color: theme.palette.SFIBase.white,
            background: item.color,
          },
          text: `${i18n.text('analysis.mean')}: ${mean.toFixed(decimal)} ${unitName}`,
        },
      })
    }
    return acc
  }, [])
}
