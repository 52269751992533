import './Shared/theme/styles.css'

import React from 'react'
import svg4everybody from 'svg4everybody'
import theme from '@sensorfactdev/nucleus/themes/sensorfact.theme'
import { Amplify } from 'aws-amplify'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { render } from 'react-dom'

import MuiThemeProvider from './Shared/theme/MuiThemeProvider'
import Routes from './App/routes'
import awsConfig from './Shared/services/amplify.config'
import store from './App/store'
import { ApolloProvider } from './Shared/graphql/client'
import { AuthContextProvider } from './Shared/contexts/AuthContext'
import { ErrorBoundary } from './Shared/services/sentry'
import { I18nProvider } from './Shared/contexts/i18nContext/I18nContext'
import { ToastContextProvider } from './Shared/contexts/ToastContext'
import { edgeSliceFix } from './Shared/utils'
import { history } from './Shared/services/history'

Amplify.configure(awsConfig)

svg4everybody()
edgeSliceFix()

render(
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <MuiThemeProvider>
        <ApolloProvider>
          <Router history={history}>
            <Provider store={store}>
              <AuthContextProvider store={store}>
                <I18nProvider>
                  <ToastContextProvider>
                    <Routes />
                  </ToastContextProvider>
                </I18nProvider>
              </AuthContextProvider>
            </Provider>
          </Router>
        </ApolloProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  </ErrorBoundary>,
  global.document.getElementById('portal-wrapper')
)
