import React, { FC } from 'react'
import { Box, useTheme } from '@mui/material'

import CriticalStopTimeAssetGroup from './CriticalStopTimeAssetGroup'
import { ModalFooter, StyledDialogModal } from '../../../Shared/components/MUIComponents/StyledDialogModal'
import { OutlinedStyledButton } from '../../../Shared/components/MUIComponents/update/OutlinedStyledButton'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type CriticalStopTimeModalProps = {
  isOpen: boolean
  onClose: () => void
}

const CriticalStopTimeModal: FC<CriticalStopTimeModalProps> = ({ isOpen, onClose }) => {
  const { assets } = useOEEContext()
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const handleModalClose = () => {
    onClose()
  }

  return (
    <StyledDialogModal
      title={i18n.text('oee.critical-stop-time.modal.title')}
      isOpen={isOpen}
      isLoading={false}
      onClose={handleModalClose}
      height="responsive"
    >
      <Box sx={{ display: 'grid', gridRowGap: theme.spacing(2) }}>
        {assets.map(asset => {
          return (
            <CriticalStopTimeAssetGroup
              key={asset.id}
              group={asset}
            />
          )
        })}
      </Box>
      <ModalFooter>
        <OutlinedStyledButton onClick={handleModalClose}>{i18n.text('generic.cancel')}</OutlinedStyledButton>
      </ModalFooter>
    </StyledDialogModal>
  )
}

export default CriticalStopTimeModal
