import { createSvgIcon } from '@mui/material'

export const PlusCircleIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M9.99984 7.16602V13.8327M6.6665 10.4993H13.3332M18.3332 10.4993C18.3332 15.1017 14.6022 18.8327 9.99984 18.8327C5.39746 18.8327 1.6665 15.1017 1.6665 10.4993C1.6665 5.89698 5.39746 2.16602 9.99984 2.16602C14.6022 2.16602 18.3332 5.89698 18.3332 10.4993Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'plus-circle-icon'
)
