import { ElectricityDataSeries } from '../types'
import { getMaxInSeries } from './getMaxInSeries'

export function getLargestSeriesMeasurement(data: ElectricityDataSeries) {
  return data.reduce((acc, cur) => {
    if (cur?.data?.length) {
      const localMax = getMaxInSeries(cur.data, acc)
      if (localMax > acc) {
        acc = localMax
      }
    }
    return acc
  }, 0)
}
