import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ApolloError } from '@apollo/client'
import { DateTime } from 'luxon'
import { useLocation } from 'react-router'

import {
  DataPointTemperature,
  TemperatureAssetFragment,
  TemperatureSensorLocationFragment,
  useTemperatureAssetTreeQuery,
  useTemperatureLocationTimeSeriesBatchLazyQuery,
} from '../../Shared/graphql/codegen'
import { DateRange } from '../../Shared/types/analysis_types'
import { HUMIDITY_COLOR, TEMPERATURE_COLOR } from '../constants'
import { ISeriesData, ITemperatureHumiditySeries, SeriesNames } from '../types'
import { MILLIS_IN_ONE_DAY } from '../../Shared/constants/timeIntervals'
import { createCtx, toLocalDateTime } from '../../Shared/utils'
import { mapDataToApexSeries, mapSeriesData } from '../utils/apexSeries'
import { sortByAlphabeticalOrder } from '../utils/utils'
import { useChartToolsContext } from './ChartToolsContext'

interface ITemperatureContextValues {
  selectedDateRange: DateRange
  setDateRange: (dateRange: DateRange) => void
  onDateRangeChange: (dateRange: DateRange, zooming?: boolean) => void
  assets: TemperatureAssetFragment[]
  areSeriesLoading: boolean
  temperatureHumidityLocationSeries: ITemperatureHumiditySeries
  selectedLocationId: string
  setSelectedLocationId: (id: string) => void
  areSeriesHiddenRef: React.MutableRefObject<{
    temperature: boolean
    humidity: boolean
  }>
  apexSeries: ApexAxisChartSeries
  seriesData: ISeriesData[]
  legendOpacity: SeriesNames | null
  setOpacity: React.Dispatch<React.SetStateAction<SeriesNames | null>>
  dataLoading: boolean
  loadAssetTreeError: ApolloError | undefined
}

interface ITemperatureContextProps {
  children: React.ReactNode
}

const [useTemperatureContext, TemperatureContextReactProvider] = createCtx<ITemperatureContextValues>()

export function TemperatureContextProvider({ children }: ITemperatureContextProps) {
  const { showMinMaxRange } = useChartToolsContext()
  const location = useLocation()

  const areSeriesHiddenRef = useRef({
    temperature: false,
    humidity: false,
  })

  const { data, loading: dataLoading, error: loadAssetTreeError } = useTemperatureAssetTreeQuery()

  const assets: TemperatureAssetFragment[] = useMemo(() => {
    return data?.myOrg
      ? (sortByAlphabeticalOrder(
          [...data.myOrg.assets].map(asset => ({
            ...asset,
            temperatureSensorLocations: sortByAlphabeticalOrder([
              ...asset.temperatureSensorLocations,
            ]) as TemperatureSensorLocationFragment[],
          }))
        ) as TemperatureAssetFragment[])
      : []
  }, [data])

  const [legendOpacity, setOpacity] = useState<SeriesNames | null>(null)
  const [selectedLocationId, setSelectedLocationId] = useState<string>('')
  const [selectedDateRange, setDateRange] = useState<DateRange>({
    startDate: DateTime.now().startOf('week'),
    endDate: DateTime.now().endOf('week'),
  })

  function onDateRangeChange({ startDate, endDate }: DateRange, zooming?: boolean) {
    const startOfStartDate = startDate ? startDate.startOf('day') : selectedDateRange.startDate?.startOf('day')
    const endOfEndDay = endDate ? endDate.endOf('day') : selectedDateRange.endDate?.endOf('day')
    const dateRange = zooming
      ? {
          startDate: startDate || selectedDateRange.startDate,
          endDate: endDate || selectedDateRange.endDate,
        }
      : {
          startDate: startOfStartDate,
          endDate: endOfEndDay,
        }
    if (dateRange.startDate && dateRange.endDate) {
      setDateRange({
        startDate: dateRange.startDate as DateTime,
        endDate: dateRange.endDate as DateTime,
      })
    }
  }

  const [
    runFetchTemperatureLocationTimeSeries,
    { data: temperatureLocationSeries, loading: fetchTemperatureSeriesLoading },
  ] = useTemperatureLocationTimeSeriesBatchLazyQuery()

  const fetchTemperatureLocationSeries = useCallback(
    async ({ id, dateRange: { startDate, endDate } }: { id: string; dateRange: DateRange }) => {
      const isHourly = endDate.diff(startDate).milliseconds > MILLIS_IN_ONE_DAY
      const variables = {
        id,
        from: toLocalDateTime(startDate),
        to: toLocalDateTime(endDate),
        resolution: isHourly ? '1h' : '0m',
      }
      runFetchTemperatureLocationTimeSeries({ variables: { ...variables } })
    },
    [runFetchTemperatureLocationTimeSeries]
  )

  const temperatureHumidityLocationSeries = useMemo(() => {
    return {
      temperature: {
        measurements:
          (temperatureLocationSeries?.sensorLocations?.[0]?.insights?.timeWindow
            ?.temperatureMeasurements as DataPointTemperature[]) ?? [],
        name: SeriesNames.temperature,
        id: 'TemperatureId',
        color: TEMPERATURE_COLOR,
      },
      humidity: {
        measurements:
          (temperatureLocationSeries?.sensorLocations?.[0]?.insights?.timeWindow
            ?.humidityMeasurements as DataPointTemperature[]) ?? [],
        name: SeriesNames.humidity,
        id: 'HumidityId',
        color: HUMIDITY_COLOR,
      },
    }
  }, [temperatureLocationSeries])

  const apexSeries = useMemo<ApexAxisChartSeries>(
    () => [...mapDataToApexSeries(temperatureHumidityLocationSeries, showMinMaxRange)],
    [temperatureHumidityLocationSeries, showMinMaxRange]
  )

  const seriesData = useMemo(
    () => [...mapSeriesData(temperatureHumidityLocationSeries)],
    [temperatureHumidityLocationSeries]
  )

  useEffect(() => {
    if (selectedLocationId) {
      fetchTemperatureLocationSeries({
        id: selectedLocationId,
        dateRange: selectedDateRange,
      })
    }
  }, [selectedLocationId, selectedDateRange])

  useEffect(() => {
    areSeriesHiddenRef.current = {
      temperature: false,
      humidity: false,
    }
    setOpacity(null)
  }, [temperatureHumidityLocationSeries, showMinMaxRange])

  return (
    <TemperatureContextReactProvider
      value={{
        selectedDateRange,
        setDateRange,
        onDateRangeChange,
        assets,
        temperatureHumidityLocationSeries,
        areSeriesLoading: fetchTemperatureSeriesLoading,
        selectedLocationId,
        setSelectedLocationId,
        seriesData,
        apexSeries,
        areSeriesHiddenRef,
        legendOpacity,
        setOpacity,
        dataLoading,
        loadAssetTreeError,
      }}
    >
      {children}
    </TemperatureContextReactProvider>
  )
}

export { useTemperatureContext }
