import { Box, Collapse, Typography, useTheme } from '@mui/material'
import { FC, ReactNode, useState } from 'react'

import { ChevronDoubleLeft, ChevronDoubleRight } from '../../../icons'
import { OutlinedStyledIconButton } from '../OutlinedStyledButton'
import { StyledTooltip } from '../../Tooltip'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

type CollapsibleAssetTreeWrapperProps = {
  children: ReactNode
  isCollapsed?: boolean
  isOpen?: boolean
  handleIsOpen?: (val: boolean) => void
}

/** Wrapper for the collapsible asset tree.
 *  @param {ReactNode} children - The children to be rendered
 *  @param {boolean} isCollapsed - (optional) The initial collapsed state of the wrapper (default: false)
 * */
const CollapsibleAssetTreeWrapper: FC<CollapsibleAssetTreeWrapperProps> = ({
  children,
  isCollapsed = false,
  isOpen,
  handleIsOpen,
}) => {
  const [_isOpen, setIsOpen] = useState<boolean>(!isCollapsed)
  const theme = useTheme()
  const { i18n } = useI18nContext()

  const handleCollapse = () => {
    setIsOpen(!_isOpen)
    if (handleIsOpen) {
      handleIsOpen(!isOpen)
    }
  }

  const openStatus = handleIsOpen ? isOpen : _isOpen

  return (
    <Box
      sx={{
        maxHeight: !openStatus ? '100px' : 'max-content',
      }}
    >
      {!openStatus && (
        <Box
          sx={{
            padding: '0.62rem 1rem 1rem',
          }}
        >
          <StyledTooltip
            title={i18n.text('generic.asset-tree-wrapper.open')}
            placement="top-end"
            arrow
          >
            <OutlinedStyledIconButton
              size="small"
              onClick={handleCollapse}
              aria-label={i18n.text('generic.asset-tree-wrapper.open')}
            >
              <ChevronDoubleRight sx={{ display: 'flex', width: '1.25rem', height: '1.25rem' }} />
            </OutlinedStyledIconButton>
          </StyledTooltip>
        </Box>
      )}
      <Collapse
        in={openStatus}
        orientation="horizontal"
        timeout={{
          enter: 500,
          exit: 0,
        }} // we want to have a small timeout for the enter animation, because container of apex chart is not reacting to the width change immediately
      >
        <Box
          sx={{
            border: `solid 1px ${theme.palette.SFIGreyLight[200]}`,
            padding: '0.62rem 1rem',
            width: '20rem',
            height: 'calc(100vh - 172px)', // 172px = header + secondary navigation + gaps
            overflowY: 'auto',
            background: theme.palette.SFIBase.white,
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
            gap: theme.spacing(1.5),
            borderRadius: '0.625rem',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: theme.spacing(1) }}>
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontWeight: 500,
                lineHeight: '1.25rem',
                color: theme.palette.SFIGreyLight[500],
              }}
            >
              {i18n.text('generic.asset-tree-wrapper.close')}
            </Typography>
            <OutlinedStyledIconButton
              size="small"
              onClick={handleCollapse}
              aria-label={i18n.text('generic.asset-tree-wrapper.close')}
            >
              <ChevronDoubleLeft sx={{ display: 'flex', width: '1.25rem', height: '1.25rem' }} />
            </OutlinedStyledIconButton>
          </Box>
          <Box
            sx={{
              width: '100%',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {children}
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

export default CollapsibleAssetTreeWrapper
