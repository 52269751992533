import { Box, Typography, useTheme } from '@mui/material'

import { NegativeTrendIcon } from '../../../../../Shared/components/icons/NegativeTrendIcon'
import { NeutralTrendIcon } from '../../../../../Shared/components/icons/NeutralTrendIcon'
import { PositiveTrendIcon } from '../../../../../Shared/components/icons/PositiveTrendIcon'
import { VibrationSensorLocation } from '../../../../../Shared/types/maintenanceAssetHealthReportTypes'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

interface VibrationTrendProps {
  vibrationTrend: NonNullable<VibrationSensorLocation['vibrationTrend']>
}

const VibrationTrendIndicator = ({ vibrationTrend }: VibrationTrendProps) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const getVibrationTrendStyle = (indicator: 'neutral' | 'down' | 'up') => {
    switch (indicator) {
      case 'neutral':
        return {
          borderColour: theme.palette.SFIGreyLight[200],
          backgroundColor: theme.palette.SFIGreyLight[50],
          color: theme.palette.SFIGreyLight[700],
          icon: (
            <NeutralTrendIcon
              fontSize="small"
              sx={{ width: theme.typography.pxToRem(13), height: theme.typography.pxToRem(13) }}
            />
          ),
        }
      case 'down':
        return {
          borderColour: theme.palette.SFIGreen[200],
          backgroundColor: theme.palette.SFIGreen[50],
          color: theme.palette.SFIGreen[700],
          icon: (
            <NegativeTrendIcon
              fontSize="small"
              sx={{
                width: theme.typography.pxToRem(13),
                height: theme.typography.pxToRem(13),
                color: theme.palette.SFIGreen[600],
              }}
            />
          ),
        }
      case 'up':
        return {
          borderColour: theme.palette.SFIWarning[200],
          backgroundColor: theme.palette.SFIWarning[50],
          color: theme.palette.SFIWarning[700],
          icon: (
            <PositiveTrendIcon
              fontSize="small"
              sx={{
                width: theme.typography.pxToRem(13),
                height: theme.typography.pxToRem(13),
                color: theme.palette.SFIWarning[600],
              }}
            />
          ),
        }
      default:
        return {
          borderColour: theme.palette.SFIGreyLight[200],
          backgroundColor: theme.palette.SFIGreyLight[50],
          color: theme.palette.SFIGreyLight[700],
          icon: (
            <NeutralTrendIcon
              fontSize="small"
              sx={{ width: theme.typography.pxToRem(13), height: theme.typography.pxToRem(13) }}
            />
          ),
        }
    }
  }

  const { color, borderColour, backgroundColor, icon } =
    vibrationTrend?.indicator && getVibrationTrendStyle(vibrationTrend.indicator)
  const vibrationTrendPercentage = vibrationTrend && Math.round(vibrationTrend.value * 100) + '%'

  return (
    <Box
      data-testid="asset-health-report-vibration-trend"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '0.375rem',
        alignItems: 'center',
        backgroundColor: theme.palette.SFIGreyLight[50],
        borderRadius: '0.5rem',
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
      }}
    >
      <Typography
        sx={{
          color: theme.palette.SFIGreyLight[800],
          fontSize: theme.typography.pxToRem(12),
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
        }}
      >
        {i18n.text('pdm.assetHealthReport.vibration.trend')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)} ${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
          alignItems: 'center',
          gap: '0.4rem',
          borderRadius: '0.4rem',
          border: `1px solid ${borderColour}`,
          background: backgroundColor,
        }}
      >
        <Typography
          component={'span'}
          data-testid="asset-health-report-vibration-trend-icon"
        >
          <Box position={'relative'}>{icon}</Box>
        </Typography>
        <Typography
          component={'span'}
          sx={{
            color: color,
            textAlign: 'center',
            fontSize: theme.typography.pxToRem(12),
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '18px',
          }}
          data-testid="asset-health-report-vibration-trend-value"
        >
          {vibrationTrendPercentage}
        </Typography>
      </Box>
    </Box>
  )
}

export default VibrationTrendIndicator
