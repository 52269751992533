import React from 'react'

import { ICustomTableColumn } from '../../../../types/types'
import { StyledChipsLayout } from '../StyledChips'
import { StyledTableCell } from './StyledTableCell'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

interface ICustomTableItemProps<T> {
  item: T[keyof T]
  column: ICustomTableColumn<T>
}

export function CustomTableItem<T extends { id: string }>({ item, column }: ICustomTableItemProps<T>) {
  const { i18n } = useI18nContext()

  const itemsArray = Array.isArray(item) && item.length > 0 ? item : null
  const notArrayItem = !Array.isArray(item) && item !== null ? item : null

  const getAlignment = () => {
    switch(column.align) {
      case 'left': {
        return 'flex-start'
      }
      case 'right': {
        return 'flex-end'
      }
      case 'center': {
        return 'center'
      }
      default: {
        return column.align
      }
    }
  }

  return (
    <StyledTableCell
      key={column.id as string}
      align={column.align ?? 'center'}
      isBoldText={column.isBoldText}
    >
      {itemsArray ? (
        <StyledChipsLayout
          items={itemsArray}
          slotProps={{
            chips: {
              sx: {
                justifyContent: getAlignment(),
              },
            },
          }}
        />
      ) : (
        notArrayItem || i18n.text('pdm.asset.details.not-set')
      )}
    </StyledTableCell>
  )
}
