export const FETCH_ASSETS = Symbol('FETCH_ASSETS')
export const ASSETS_FETCHED = Symbol('ASSETS_FETCHED')
export const SET_ANALYSIS_TYPE = Symbol('SET_ANALYSIS_TYPE')
export const FETCH_MEASUREMENTS = Symbol('FETCH_MEASUREMENTS')
export const MEASUREMENTS_FETCHED = Symbol('MEASUREMENTS_FETCHED')
export const SELECT_ASSET = Symbol('SELECT_ASSET')
export const DESELECT_ALL_ASSETS = Symbol('DESELECT_ALL_ASSETS')
export const SELECT_DATE_RANGE = Symbol('SELECT_DATE_RANGE')
export const SELECT_COMPONENT = Symbol('SELECT_COMPONENT')
export const RESET_SELECTED_ASSETS = Symbol('RESET_SELECTED_ASSETS')
export const Y_AXIS_CHANGE = Symbol('Y_AXIS_CHANGE')
export const ASSET_TAB_CHANGE = Symbol('ASSET_TAB_CHANGE')
export const SELECT_TOTAL_CONSUMPTION = Symbol('SELECT_TOTAL_CONSUMPTION')
export const TOGGLE_PREVIOUS_PERIOD = Symbol('TOGGLE_PREVIOUS_PERIOD')
export const ASSET_UPDATED = Symbol('ASSET_UPDATED')

export const numberFormatOptions = { maximumFractionDigits: 0, minimumFractionDigits: 0 }
export const STANDBY_STRING = 'Standby Consumption'
