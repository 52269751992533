import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Dialog from '@mui/material/Dialog'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { Alert, Button, DialogContent, DialogTitle, IconButton, Snackbar, TextField, Typography } from '@mui/material'
import { ApolloError } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'

import MUILoader from '../../../../Shared/components/MUIComponents/Loader'
import { CreateUserApiIntegrationMutation } from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

interface IntegrationDialogProps {
  data: CreateUserApiIntegrationMutation | null | undefined
  createIntegrationLoading: boolean
  integrationName: string
  error: ApolloError | undefined
  setIntegrationName: (name: string) => void
  onCreateUserApiIntegration: () => void
}

export const IntegrationDialogComponent = ({
  data,
  error,
  createIntegrationLoading,
  integrationName,
  setIntegrationName,
  onCreateUserApiIntegration,
}: IntegrationDialogProps) => {
  const theme = useTheme()
  const [dialog, setDialogOpen] = useState<boolean>(false)
  const [integrationNameError, setIntegrationNameError] = useState(false)
  const { i18n } = useI18nContext()
  const [integrationsData, setIntegrationsData] = useState<CreateUserApiIntegrationMutation | null | undefined>(data)
  const apiKey = integrationsData?.createUserApiIntegration?.key

  useEffect(() => {
    setIntegrationsData(data)
  }, [data])

  const handleSetIntegrationName = async (value: string) => {
    if (value.length > 0) setIntegrationNameError(false)
    setIntegrationName(value)
  }

  const handleCreateUserApiIntegration = () => {
    if (integrationName.trim() === '') {
      setIntegrationNameError(true)
      return
    }
    onCreateUserApiIntegration()
  }

  const handleCloseDialog = async () => {
    setDialogOpen(false)
    setTimeout(() => {
      setIntegrationName('')
      setIntegrationNameError(false)
      setIntegrationsData(null)
    }, 200)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setDialogOpen(true)
        }}
      >
        {i18n.text('generate.key')}
      </Button>
      <Dialog
        open={dialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleCloseDialog()
          }
        }}
      >
        <DialogTitle
          sx={{
            paddingBottom: theme.spacing(0),
          }}
        >
          {i18n.text('api.key')}
        </DialogTitle>
        <IconButton
          onClick={() => handleCloseDialog()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {error && (
            <Alert
              sx={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(0) }}
              severity="error"
            >
              {error?.message}
            </Alert>
          )}
          <Alert
            severity="warning"
            sx={{
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(2),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {i18n.text('integrations.dialog.disclaimer')}
          </Alert>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              gap: 2,
            }}
          >
            <TextField
              size="small"
              label={i18n.text('integrations.key.name')}
              error={integrationNameError}
              helperText={integrationNameError && i18n.text('integrations.key.empty.state')}
              onChange={e => handleSetIntegrationName(e.target.value)}
            />
            {!createIntegrationLoading ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleCreateUserApiIntegration()}
              >
                {i18n.text('create.key')}
              </Button>
            ) : (
              <Box width={'3rem'}>
                <MUILoader
                  height={3}
                  margin="0 0 0 0"
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        {apiKey && (
          <>
            <Alert
              sx={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              severity="success"
            >
              {i18n.text('integrations.key.created.successfully')}
              <CopyToClipboardButton textCopy={apiKey} />
            </Alert>
            <Box
              sx={{
                padding: theme.spacing(2),
                backgroundColor: theme.palette.SFIGreyLight[200],
                overflow: 'break-word',
                wordWrap: 'break-word',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                style={{
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                }}
              >
                {apiKey}
              </Typography>
            </Box>
          </>
        )}
      </Dialog>
    </>
  )
}

interface ICopyToClipboardButton {
  textCopy: string
}

const CopyToClipboardButton = ({ textCopy }: ICopyToClipboardButton) => {
  const { i18n } = useI18nContext()
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(textCopy)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        disableRipple={true}
        color="primary"
      >
        {open ? <FileCopyIcon /> : <ContentCopyIcon />}
      </IconButton>
      <Snackbar
        disableWindowBlurListener={true}
        message={i18n.text('integrations.key.copy.to.clipboard')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  )
}
