import Chart from 'react-apexcharts'
import React, { useLayoutEffect, useState } from 'react'
import { ApexOptions } from 'apexcharts'
import { Box, styled } from '@mui/material'
import { FC } from 'react'

import { ChartToolsDropdown } from '../MUIComponents/update/ChartToolsDropdown'
import { ToolBarItem } from '../../types/types'

const GraphWrapper = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    overflowY: 'hidden',
    height: 'fit-content',
  },
}))

type ResponsiveApexGraphProps = {
  wrapperWidth?: string
  toolbarItems?: ToolBarItem[]
  hasToolbar?: boolean
  chartData: {
    series?: ApexAxisChartSeries | ApexNonAxisChartSeries
    options: ApexOptions
    type: 'rangeArea'
    id: string
  }
}

export const ResponsiveApexGraph: FC<ResponsiveApexGraphProps> = ({
  wrapperWidth,
  chartData,
  toolbarItems,
  hasToolbar,
}) => {
  const initialGraphHeight = window.innerHeight * 0.55
  const [responsiveHeight, setResponsiveHeight] = useState(initialGraphHeight)

  useLayoutEffect(() => {
    const handleResize = () => {
      const graphHeight = window.innerHeight * 0.55
      setResponsiveHeight(graphHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setResponsiveHeight])

  return (
    <GraphWrapper sx={{ width: wrapperWidth || '100%' }}>
      {toolbarItems && (
        <Box
          sx={{
            marginRight: theme => (hasToolbar ? theme.spacing(13.25) : 0),
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ChartToolsDropdown items={toolbarItems} />
        </Box>
      )}
      <Box id={chartData.id}>
        <Chart
          data-testid={chartData.id}
          options={chartData.options}
          series={chartData.series}
          type={chartData.type}
          height={responsiveHeight}
          width="100%"
        />
      </Box>
    </GraphWrapper>
  )
}
