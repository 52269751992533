import React, { FC } from 'react'
import { Box, Typography, styled } from '@mui/material'

import * as All from '../../../../Shared/components/icons'

export const IconsPage: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',
        marginTop: '2rem',
        flexWrap: 'wrap',
      }}
    >
      {Object.keys(All).map((key: string) => {
        type AllType = typeof All
        type AllKeysType = keyof AllType
        const Component = All[key as AllKeysType]
        return (
          <Box
            key={key}
            sx={{
              border: theme => `1px solid ${theme.palette.SFIGreyLight[600]}`,
              padding: '0.5rem',
              display: 'flex',
              gap: '0.875rem',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <IconLabel>{key}</IconLabel>
            <Component size="small" />
          </Box>
        )
      })}
    </Box>
  )
}

const IconLabel = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: '1.25rem',
  color: theme.palette.SFIGreyLight[600],
  fontWeight: 500,
}))
