/* eslint-disable import/prefer-default-export */
/**
 * Swaps two elements in the array based on their index.
 * @param {Array} arr - The array containing the elements to be swapped.
 * @param {number} index1 - The index of element 1 in the array.
 * @param {number} index2 - The index of element 2 in the array.
 * @returns {Array} Returns a new array with the swapped elements.
 */
export function swapArrayElements<T>(arr: T[], index1: number, index2: number): T[] {
  return arr.map((val, idx) => {
    if (idx === index1) return arr[index2]
    if (idx === index2) return arr[index1]
    return val
  })
}
