// react-date-range skeleton and theme styles first.
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

import styled from 'styled-components'
import React, { useCallback, useRef, useState } from 'react'
import { Button } from 'antd'
import { DateRangePicker } from 'react-date-range'
import { DateTime } from 'luxon'
import { differenceInDays, isAfter } from 'date-fns'
import { useClickAway } from 'ahooks'
import { useRouteMatch } from 'react-router'

import { DateRange } from '../../types/analysis_types'
import { getIntervalRangePdm, getStaticRanges, selectLocale } from './utils'
import { themeColors } from '../../utils'
import { trySnapToInterval } from '../../utils/analysis_utils'

const dateFormat = 'dd LLL ’yy'

const Wrapper = styled.div`
  position: relative;
  font-family: 'Rubik', sans-serif;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.75rem;

  .rdrDateDisplayWrapper {
    display: none;
  }

  .timeNavButton {
    background-color: ${themeColors.white};
    border-radius: 8px;
    border: none;
    padding: 0 1em;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    &:hover {
      background-color: ${themeColors.greyLight};
    }
    &:disabled {
      background-color: ${themeColors.greyLight};
      cursor: not-allowed;
    }
  }

  .rdrNextPrevButton {
    background-color: transparent;
    border-radius: 4px;
    width: 3em;
    height: 3em;

    &:hover {
      background-color: ${themeColors.greyLight};
    }
  }

  .rdrMonthPicker select,
  .rdrYearPicker select {
    &:hover {
      background-color: ${themeColors.greyLight};
    }
  }

  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    color: ${themeColors.secondary} !important;
  }

  .rdrStartEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge,
  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfMonth,
  .rdrDayStartOfWeek .rdrDayInPreview,
  .rdrDayStartOfWeek .rdrDayEndPreview,
  .rdrDayStartOfMonth .rdrDayInPreview,
  .rdrDayStartOfMonth .rdrDayEndPreview,
  .rdrDayStartPreview {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .rdrEndEdge,
  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge,
  .rdrDayEndOfMonth,
  .rdrDayEndOfWeek .rdrDayStartPreview,
  .rdrDayEndOfWeek .rdrDayInPreview,
  .rdrDayEndOfMonth .rdrDayInPreview,
  .rdrDayEndOfMonth .rdrDayEndPreview,
  .rdrDayEndPreview {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
`

const Inner = styled.div`
  position: absolute;
  top: 3em;
  right: 0;
  z-index: 500;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid ${themeColors.greyLight};
`

const DateWrapper = styled.div`
  padding: 8px 1em;
  font-size: 12px;
  background-color: ${themeColors.white};
  color: ${themeColors.black};
  font-family: 'Rubik', sans-serif;
  display: inline-flex;
  border-radius: 8px;
  font-weight: 400;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
`

const DateEl = styled.span`
  padding: 0 1em;
`

const Separator = styled.span`
  margin: 0 1em;
`

type Props = {
  onDatesChange: (selection: DateRange, zooming?: boolean) => void
  startDate: DateTime
  endDate: DateTime
  i18n: $I18FixMe
  disabled?: boolean
}

function isDateRange(range: { startDate: unknown; endDate: unknown }): range is DateRange {
  return DateTime.isDateTime(range.startDate) && DateTime.isDateTime(range.endDate)
}

export default function DatePicker({ onDatesChange, disabled = false, startDate, endDate, i18n }: Props) {
  const [focused, setFocus] = useState(false)
  const ref = useRef()
  const { path } = useRouteMatch()
  const isPredictiveMaintenance = path.includes('predictive-maintenance')
  const intervalRange = isPredictiveMaintenance ? getIntervalRangePdm(i18n) : getStaticRanges(i18n)

  useClickAway(() => {
    setFocus(false)
  }, ref)

  const _onDatesChange = useCallback(
    (selection: DateRange | { startDate: Date; endDate: Date }) => {
      if (selection.startDate.valueOf() !== selection.endDate.valueOf()) {
        setFocus(false)
      }
      isDateRange(selection)
        ? onDatesChange(selection, true) // it's the arrow navigation, zoom == true
        : onDatesChange({
            // it's the calendar navigation, zoom == false
            startDate: DateTime.fromJSDate(selection.startDate),
            endDate: DateTime.fromJSDate(selection.endDate),
          })
    },
    [onDatesChange]
  )

  function isInvalidDate(date: Date) {
    const today = new Date()
    const _startDate = startDate.toJSDate()
    const _endDate = endDate.toJSDate()

    if (differenceInDays(_endDate, _startDate) === 0) {
      return false
    }

    if (isAfter(_endDate, today)) {
      return isAfter(date, _endDate)
    }

    return isAfter(date, today)
  }

  function checkDisabled() {
    const today = new Date()
    const _endDate = endDate.toJSDate()
    return isAfter(_endDate, today)
  }

  const handleBackwardsNav = useCallback(() => {
    const start = startDate
    const end = endDate
    let nextDateRange = trySnapToInterval(startDate, endDate, 'backwards')
    if (!nextDateRange) {
      const intervalLength = end.diff(start)
      const newStartDate = start.minus(intervalLength).startOf('minute')
      const newEndDate = newStartDate.plus(intervalLength).endOf('minute')
      nextDateRange = { startDate: newStartDate, endDate: newEndDate }
    }
    _onDatesChange(nextDateRange)
  }, [startDate, endDate, _onDatesChange])

  const handleForwardsNav = useCallback(() => {
    const start = startDate
    const end = endDate
    let nextDateRange = trySnapToInterval(startDate, endDate, 'forwards')
    if (!nextDateRange) {
      const intervalLength = end.diff(start)
      const newStartDate = start.plus(intervalLength).plus({ minutes: 1 }).startOf('minute')
      const newEndDate = newStartDate.plus(intervalLength).endOf('minute')
      nextDateRange = { startDate: newStartDate, endDate: newEndDate }
    }
    _onDatesChange(nextDateRange)
  }, [startDate, endDate, _onDatesChange])

  return (
    <div
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
      ref={ref}
      style={{ padding: '5px 10px', flexGrow: 2, marginLeft: '8px' }}
    >
      <Wrapper className="DatePickerWrapper">
        <Button
          className="timeNavButton"
          onClick={handleBackwardsNav}
        >
          {' '}
          {'<'}{' '}
        </Button>

        <DateWrapper
          onClick={() => !disabled && setFocus(!focused)}
          className="DatePickerTrigger"
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <DateEl style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            {startDate.toFormat(dateFormat)}
          </DateEl>
          <Separator>/</Separator>
          <DateEl style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            {endDate.toFormat(dateFormat)}
          </DateEl>
        </DateWrapper>
        <Button
          className="timeNavButton"
          disabled={checkDisabled()}
          onClick={handleForwardsNav}
        >
          {' '}
          {'>'}{' '}
        </Button>
        {focused && (
          <Inner>
            <DateRangePicker
              color={themeColors.secondary}
              locale={selectLocale(i18n)}
              ranges={[
                {
                  startDate: startDate.toJSDate(),
                  endDate: endDate.toJSDate(),
                  key: 'selection',
                },
              ]}
              disabledDay={isInvalidDate}
              months={2}
              direction="vertical"
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'selection' does not exist on type 'OnCha... Remove this comment to see the full error message
              onChange={({ selection }) => _onDatesChange(selection)}
              staticRanges={intervalRange}
              inputRanges={[]}
            />
          </Inner>
        )}
      </Wrapper>
    </div>
  )
}
