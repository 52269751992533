import { Box } from '@mui/material'
import { FC } from 'react'

import { StyledInputFieldHeader } from '../Common'
import { StyledTextField } from '../../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { TitleInputProps } from '../types'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const TitleInput: FC<TitleInputProps> = ({ title, handletitlechange }) => {
  const { i18n } = useI18nContext()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.375rem',
      }}
    >
      <StyledInputFieldHeader>{i18n.text(`reports.tracking.configuration.general.title`)}</StyledInputFieldHeader>
      <StyledTextField
        size="small"
        inputProps={{ maxLength: 30 }}
        value={title}
        onChange={handletitlechange}
        sx={{ maxWidth: '30rem' }}
      >
        {' '}
      </StyledTextField>
    </Box>
  )
}
