import { Box, Typography, styled } from '@mui/material'

import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  alignItems: 'flex-start',
})

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 600,
  color: theme.palette.SFIGreyLight[800],
  lineHeight: '1.25rem',
  textAlign: 'left',
}))

const MainText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  textAlign: 'left',
}))

export const ContactUs = () => {
  const { i18n } = useI18nContext()
  return (
    <ContentWrapper>
      <Title>{i18n.text(`reports.tracking.support.dialog.contactUs`)}</Title>
      <MainText>{i18n.text(`reports.tracking.support.dialog.furtherQuestions`)}</MainText>
    </ContentWrapper>
  )
}
