import { createSvgIcon } from '@mui/material'

export const PlusIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99996 4.16699V15.8337M4.16663 10.0003H15.8333"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'plus-icon'
)
