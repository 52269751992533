import {
  AnalyticsEventTypes,
  AnalyticsIntent,
  AnalyticsPropositionType,
} from '../../Shared/hooks/useAnalytics/analyticsTypes'
import {
  DeleteAssetLocationImageCA,
  DownloadGraphCA,
  ExpandAssetLocationDetailsCA,
  SelectSensorLocationCA,
  UploadAssetLocationImageCA,
  ZoomGraphCA,
} from './analyticsTypes'

export function sendCATrackedDownloadEvent(
  e: React.MouseEvent<HTMLElement>,
  graphType: 'flow' | 'consumption',
  sendEvent: (name: string, params?: DownloadGraphCA) => void
) {
  const { target } = e
  const targetClassName =
    target && target instanceof HTMLElement && target.className && typeof target.className === 'string'
      ? target.className
      : ''
  const downloadType = targetClassName
    ? targetClassName.includes('exportSVG')
      ? AnalyticsIntent.DOWNLOAD_SVG
      : targetClassName.includes('exportPNG')
      ? AnalyticsIntent.DOWNLOAD_PNG
      : targetClassName.includes('exportCSV')
      ? AnalyticsIntent.DOWNLOAD_CSV
      : null
    : null
  if (downloadType) {
    sendEvent(AnalyticsEventTypes.TRACKED_DOWNLOAD, {
      proposition: AnalyticsPropositionType.COMPRESSED_AIR,
      intent: downloadType,
      target: `${graphType}_graph_toolbar_menu_item`,
    })
  }
}

export function sendCAZoomEvent(sendEvent: (name: string, params?: ZoomGraphCA) => void) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.COMPRESSED_AIR,
    intent: AnalyticsIntent.ZOOM_GRAPH,
    target: 'zoom_icon_button',
  })
}

export function sendCAExpandAccordionEvent(
  type: 'asset' | 'location',
  sendEvent: (name: string, params?: ExpandAssetLocationDetailsCA) => void
) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.COMPRESSED_AIR,
    intent: type === 'asset' ? AnalyticsIntent.EXPAND_ASSET_DETAILS : AnalyticsIntent.EXPAND_LOCATION_DETAILS,
    target: type === 'asset' ? 'asset_details_accordion' : 'location_details_accordion',
  })
}

export function sendCAUploadImageEvent(
  type: 'asset' | 'location',
  sendEvent: (name: string, params?: UploadAssetLocationImageCA) => void
) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.COMPRESSED_AIR,
    intent: type === 'asset' ? AnalyticsIntent.UPLOAD_ASSET_IMAGE : AnalyticsIntent.UPLOAD_LOCATION_IMAGE,
    target: type === 'asset' ? 'save_asset_image_button' : 'save_location_image_button',
  })
}

export function sendCADeleteImageEvent(
  type: 'asset' | 'location',
  sendEvent: (name: string, params?: DeleteAssetLocationImageCA) => void
) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.COMPRESSED_AIR,
    intent: type === 'asset' ? AnalyticsIntent.DELETE_ASSET_IMAGE : AnalyticsIntent.DELETE_LOCATION_IMAGE,
    target: type === 'asset' ? 'delete_asset_image_button' : 'delete_location_image_button',
  })
}

export function sendCASensorLocationSelectEvent(sendEvent: (name: string, params?: SelectSensorLocationCA) => void) {
  sendEvent(AnalyticsEventTypes.TRACKED_ASSET_SELECT, {
    proposition: AnalyticsPropositionType.COMPRESSED_AIR,
    intent: AnalyticsIntent.SELECT_SENSOR_LOCATION,
    target: 'asset_tree_item_link',
  })
}
