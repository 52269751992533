import { createSvgIcon } from '@mui/material'

export const SpeedIcon = createSvgIcon(
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="speedometer-03"
      clipPath="url(#clip0_6413_57524)"
    >
      <path
        d="M3.33337 7.99967C3.33337 5.42235 5.42271 3.33301 8.00004 3.33301M11 4.99967L7.99998 7.99967M14.6667 7.99967C14.6667 11.6816 11.6819 14.6663 8.00004 14.6663C4.31814 14.6663 1.33337 11.6816 1.33337 7.99967C1.33337 4.31778 4.31814 1.33301 8.00004 1.33301C11.6819 1.33301 14.6667 4.31778 14.6667 7.99967ZM8.66671 7.99967C8.66671 8.36786 8.36823 8.66634 8.00004 8.66634C7.63185 8.66634 7.33337 8.36786 7.33337 7.99967C7.33337 7.63148 7.63185 7.33301 8.00004 7.33301C8.36823 7.33301 8.66671 7.63148 8.66671 7.99967Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6413_57524">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>,
  'speed-icon'
)
