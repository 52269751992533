import { Box, Typography, styled } from '@mui/material'
import { FC } from 'react'

import { ModalFooter, StyledDialogModal } from '../../../../../Shared/components/MUIComponents/StyledDialogModal'
import {
  StyledDangerButton,
  StyledOutlinedButton,
} from '../../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { TrashIcon } from '../../../../../Shared/components/icons'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

interface DeleteModuleDialogProps {
  openDeleteDialog: boolean
  handleDeleteReport: (id: string) => void
  handleCancelDelete: () => void
  reportId: string
}

export const DeleteReportDialog: FC<DeleteModuleDialogProps> = ({
  openDeleteDialog,
  handleDeleteReport,
  handleCancelDelete,
  reportId,
}) => {
  const { i18n } = useI18nContext()

  return (
    <StyledDialogModal
      title=""
      isOpen={openDeleteDialog}
      isLoading={false}
      onClose={handleCancelDelete}
      height="responsive"
      maxWidth="xs"
      icon={
        <TrashIconWrapper>
          <TrashIcon
            sx={{
              width: '1.5rem',
              height: '1.5rem',
            }}
          />
        </TrashIconWrapper>
      }
    >
      <ContentWrapper>
        <Title>{i18n.text(`reports.tracking.configuration.reports.deleteSure`)}</Title>
        {<Subtitle>{i18n.text(`reports.tracking.configuration.reports.deleteUndone`)}</Subtitle>}
      </ContentWrapper>
      <ModalFooter
        sx={{
          gap: '0.75rem',
          marginTop: '1rem',
          overflow: 'visible',
        }}
      >
        <StyledOutlinedButton
          disableRipple
          onClick={handleCancelDelete}
        >
          {i18n.text(`reports.tracking.configuration.module.cancel`)}
        </StyledOutlinedButton>
        <StyledDangerButton
          disableRipple
          onClick={() => handleDeleteReport(reportId)}
        >
          {i18n.text(`reports.tracking.configuration.reports.delete`)}
        </StyledDangerButton>
      </ModalFooter>
    </StyledDialogModal>
  )
}

const TrashIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '3rem',
  height: '3rem',
  padding: '0.75rem',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  background: theme.palette.SFIOrange[100],
  color: theme.palette.error.dark,
}))

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
})

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: 600,
  color: theme.palette.SFIGreyLight[800],
  lineHeight: '1.75rem',
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
}))
