import { createSvgIcon } from '@mui/material'

export const PanIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5.74999 9.53687V11.8517M5.74999 9.53687V3.98132C5.74999 3.21426 6.38425 2.59243 7.16666 2.59243C7.94906 2.59243 8.58332 3.21426 8.58332 3.98132M5.74999 9.53687C5.74999 8.76981 5.11573 8.14799 4.33332 8.14799C3.55092 8.14799 2.91666 8.76981 2.91666 9.53687V11.3887C2.91666 15.224 6.08797 18.3332 9.99999 18.3332C13.912 18.3332 17.0833 15.224 17.0833 11.3887V6.7591C17.0833 5.99204 16.4491 5.37021 15.6667 5.37021C14.8843 5.37021 14.25 5.99203 14.25 6.7591M8.58332 3.98132V9.07391M8.58332 3.98132V3.05539C8.58332 2.28833 9.21759 1.6665 9.99999 1.6665C10.7824 1.6665 11.4167 2.28833 11.4167 3.05539V3.98132M11.4167 3.98132V9.07391M11.4167 3.98132C11.4167 3.21426 12.0509 2.59243 12.8333 2.59243C13.6157 2.59243 14.25 3.21426 14.25 3.98132V6.7591M14.25 6.7591V9.07391"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'pan-icon'
)
