import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ArrowLeft = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      sx={{
        display: 'flex',
        width: '20px',
        height: '20px',
      }}
    >
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 15L7.5 10L12.5 5"
          stroke="#344054"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
