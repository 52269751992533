import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { ApolloClient } from '@apollo/client'
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { Cancel, Edit, Save } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useFeature } from 'flagged'

import GenericError from '../../../../Shared/components/GenericError'
import MUILoader from '../../../../Shared/components/MUIComponents/Loader'
import WifiSignalStrength from '../../../components/PressacBridge/WifiSignalStrength'
import actionCreators from './actions'
import { AuthContextQueryDocument } from '../../../../Shared/graphql/codegen'
import { DEFAULT_TIMEZONE } from '../../../../Shared/constants/timezone'
import { ID } from '../../../../Shared/types/types'
import {
  InstallationAssetTreeFragment,
  InstallationFlatAssetsFragment,
  SensorInstallationBridgeFragment,
  useUpdateMyAssetMutation,
} from '../../../../Shared/graphql/codegen'
import { SENSOR_UPDATED } from './constants'
import { formatSensorTableGroups } from './utils'
import { handleGenericError } from '../../../../Shared/utils'
import { isValidMachineName } from './utils'
import { qualities } from '../../../components/PressacBridge/constants'
import { themeColors } from '../../../../Shared/utils'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../Shared/contexts/ToastContext'

const MACHINE_NAME_DEFAULT_VALUE = ''

const LocalWrapper = styled.div`
  display: flex;
`
const MainContainer = styled.div`
  padding: 1em;
  width: 100%;
  text-align: left;
  background-color: ${themeColors.white};
  min-height: 100vh;

  table {
    margin-bottom: 1.5em;
  }
`

const SignalWrapper = styled.div`
  display: flex;
  align-items: center;

  *:not(:last-child) {
    margin-right: 1em;
  }
`

type SensorsProps = {
  app: $TSFixMe
  installation: $TSFixMe
  apolloClient: ApolloClient<unknown>
  actions: ActionsPropsMap<typeof actionCreators>
}

const Sensors = ({ installation, apolloClient, actions: { fetchAssets, fetchBridges } }: SensorsProps) => {
  const theme = useTheme()
  const { showToast } = useToastContext()
  const dispatch = useDispatch()
  const [errorValidation, setErrorValidation] = useState<boolean>(false)

  const assets = (installation as $TSFixMe).get('assets').toJS()
  const assetNames: Array<string> = assets.flatAssets.flatMap((object: { [x: string]: any }) =>
    ['name'].map(element => object[element])
  )
  const _bridges = (installation as $TSFixMe).get('bridges').toJS()
  const error = (installation as $TSFixMe).get('error', false)
  const assetsPending = (installation as $TSFixMe).get('assetsPending', false)
  const bridgesPending = (installation as $TSFixMe).get('bridgesPending', false)

  const { selectedCustomer } = useCurrentUser()
  const { i18n } = useI18nContext()
  const pdmEnabled = !!useFeature('pdm-beta')
  const airEnabled = !!useFeature('compressed-air')

  const _fetchBridges = fetchBridges.bind(null, apolloClient)
  const _fetchAssets = fetchAssets.bind(null, apolloClient)
  const [editRow, setEditRow] = useState<{ assetId: string; sensorExternalId: string } | null>(null)
  const [selectedMachineName, setSelectedMachineName] = useState(MACHINE_NAME_DEFAULT_VALUE)

  const handleMachineNameChange = (e: $TSFixMe) => {
    setSelectedMachineName(e.target.value)
  }

  const [updateMyAssetMutation, { loading: updating, data }] = useUpdateMyAssetMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: AuthContextQueryDocument }],

    onCompleted: async response => {
      showToast(i18n.text('success.machineName.update'), 'success', 'Success!')
    },
    onError: error => {
      handleGenericError(SENSOR_UPDATED, error, dispatch, i18n.text('error.asset.update'))
    },
  })

  const onUpdateRowClick = (assetId: string) => {
    if (isValidMachineName(selectedMachineName, assetNames)) {
      onUpdateAsset(assetId, selectedMachineName.trim())
      setSelectedMachineName(MACHINE_NAME_DEFAULT_VALUE)
      setEditRow(null)
      setErrorValidation(false)
    } else {
      setErrorValidation(true)
    }
  }

  useEffect(() => {
    async function getData() {
      await _fetchBridges()

      const timeZone = selectedCustomer?.timeZone ?? DEFAULT_TIMEZONE
      const now = DateTime.now().setZone(timeZone)
      await _fetchAssets(now.minus({ days: 1 }).toJSDate(), now.toJSDate())
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (error) {
    return <GenericError message={error.toString()} />
  }

  if (assetsPending || bridgesPending || updating) {
    return <MUILoader />
  }

  const bridges = _bridges as SensorInstallationBridgeFragment[]
  const assetTree = assets.assetTree as InstallationAssetTreeFragment[]
  const flatAssets = assets.flatAssets as InstallationFlatAssetsFragment[]

  const sensorTableGroups = formatSensorTableGroups(flatAssets, assetTree, bridges, airEnabled)

  const onUpdateAsset = (id: string, name: string) => {
    updateMyAssetMutation({
      variables: {
        id: id as ID,
        name,
      },
    })
  }

  return (
    <LocalWrapper>
      <MainContainer>
        <h2>{i18n.text('installation.sensors.sensors')}</h2>
        <br />
        {Array.from(sensorTableGroups.entries()).map(([groupId, group]) => (
          <div key={groupId}>
            <h1>{group.name}</h1>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: theme => theme.palette.SFIGreyLight[50] }}>
                    <TableCell>{i18n.text('installation.sensors.machine')} </TableCell>
                    <TableCell>{i18n.text('installation.sensors.sensorId')}</TableCell>
                    <TableCell>{i18n.text('installation.sensors.recent-measurement')}</TableCell>
                    <TableCell>{i18n.text('installation.sensors.strongest-signal')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {group.sensors.map(sensor => (
                    <TableRow>
                      <TableCell width="30%">
                        {(!editRow || editRow.sensorExternalId !== sensor.externalId) && (
                          <Box
                            justifyContent="space-between"
                            sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
                          >
                            <Typography
                              variant="body1"
                              style={{ color: theme.palette.SFIGreyLight[950] }}
                            >
                              {sensor.machine.name}
                            </Typography>
                            <IconButton
                              onClick={() =>
                                setEditRow({ assetId: sensor.machine.assetId, sensorExternalId: sensor.externalId })}
                            >
                              <Edit
                                style={{
                                  color: theme.palette.SFIGreyLight[950],
                                  fontSize: '0.85em',
                                  marginTop: '-0.15em',
                                }}
                              />
                            </IconButton>
                          </Box>
                        )}
                        {editRow && editRow.sensorExternalId === sensor.externalId && (
                          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <TextField
                              sx={{
                                width: '85%',
                              }}
                              defaultValue={sensor.machine.name}
                              onChange={handleMachineNameChange}
                              style={{ color: theme.palette.SFIGreyLight[950] }}
                              type="string"
                              error={errorValidation}
                              helperText={
                                errorValidation ? i18n.text('installation.sensor.machineName.inputError') : ''
                              }
                            />
                            <IconButton
                              disabled={selectedMachineName === MACHINE_NAME_DEFAULT_VALUE}
                              onClick={() => onUpdateRowClick(sensor.machine.assetId)}
                            >
                              <Save style={{ color: theme.palette.SFIGreyLight[950] }} />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setSelectedMachineName(MACHINE_NAME_DEFAULT_VALUE)
                                setEditRow(null)
                              }}
                            >
                              <Cancel style={{ color: theme.palette.SFIGreyLight[950] }} />
                            </IconButton>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell width="30%">{sensor.externalId}</TableCell>
                      <TableCell>{sensor.statusOk ? '✅' : '❌'}</TableCell>
                      <TableCell>
                        {sensor.connection?.quality && sensor.connection.quality !== 'unknown' ? (
                          <SignalWrapper>
                            <WifiSignalStrength
                              signal={sensor.connection.quality}
                              color={qualities[sensor.connection.quality].color}
                              size={22}
                            />
                            <p>{sensor.connection.name ?? '-'}</p>
                          </SignalWrapper>
                        ) : (
                          <div>-</div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
      </MainContainer>
    </LocalWrapper>
  )
}

const mapStateToProps = ({ app, installation }: $TSFixMe) => ({ app, installation })
const mapDispatchToProps = (dispatch: $TSFixMeDispatch, { actions }: $TSFixMe) => ({
  actions: { ...actions, ...bindActionCreators(actionCreators, dispatch) },
})
export default connect(mapStateToProps, mapDispatchToProps)(Sensors)
