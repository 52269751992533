import { getLatestCommodityPrices } from './getLatestCommodityPrices'
import { getPriceOfEnergy } from './getPriceOfEnergy'
import { getPriceOfVolume } from './getPriceOfVolume'

/**
 * Returns a map where each key is an Asset commodityType and
 * the value is the scaled energy price for that type.
 * @param {Object} commodityPrices - An object containting the price history of commodities.
 * @param {string} unit - The unit of energy to scale the prices to.
 */
export function getScaledEnergyPriceMap(commodityPrices: $TSFixMe, unit: $TSFixMe) {
  const { electricity, naturalGas, water } = getLatestCommodityPrices(commodityPrices)

  const scaledElectricityPrice = getPriceOfEnergy(unit, electricity.price)
  const scaledNaturalGasPrice = getPriceOfEnergy(unit, naturalGas.price)
  const scaledWaterPrice = getPriceOfVolume(unit, water.price)

  const priceMap = new Map()

  priceMap.set(undefined, scaledElectricityPrice)
  priceMap.set('electric-current', scaledElectricityPrice)
  priceMap.set('electric-energy', scaledElectricityPrice)
  priceMap.set('natural-gas', scaledNaturalGasPrice)
  priceMap.set('water', scaledWaterPrice)

  return priceMap
}
