import { Radio, RadioProps, styled } from '@mui/material'

export const RadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  backgroundColor: theme.palette.SFIBase.white,
  border: `1px solid var(--Colors-Border-border-primary, ${theme.palette.SFIGreyLight[300]})`,
  'input:hover ~ &': {
    boxShadow: '0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.SFIGreyLight[50],
  },
}))

export const RadioCheckedIcon = styled(RadioIcon)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBase.white,
  border: `6px solid var(--Colors-Border-border-primary, ${theme.palette.SFIBrand[600]})`,
  'input:hover ~ &': {
    boxShadow: '0px 0px 0px 4px rgba(29, 122, 253, 0.24)',
  },
}))

export function StyledRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<RadioCheckedIcon />}
      icon={<RadioIcon />}
      {...props}
    />
  )
}
