import * as z from 'zod'

import { AlertRuleCondition, AlertRulePayloadType, AlertRuleType } from '../../../Shared/graphql/codegen'
import { EmailRecipientSchema, Recipient, WebhookRecipientSchema } from '../../components/Recipient/types'
import { ID } from '../../../Shared/types/types'
import { IntervalType } from '../../../Shared/components/Interval'

export type RuleType = 'powerThreshold' | 'volumeThreshold' | 'productionThreshold'

export const AlertRuleWhatsappRecipientSchema = z.strictObject({
  name: z.string(),
  destination: z.string(),
  transport: z.literal('whatsapp'),
})

export const AlertRuleRecipientsSchema = z.array(
  z.union([EmailRecipientSchema, WebhookRecipientSchema, AlertRuleWhatsappRecipientSchema])
)

export type SupportedRuleType = AlertRuleType
export type ThresholdRuleCondition = AlertRuleCondition

export interface ThresholdV1 {
  value: number
}

export interface ThresholdInfo {
  asset: ID
  condition: ThresholdRuleCondition
  values: number[]
}

export interface BasePayload<DataType> {
  type: DataType
  duration: number
  intervals: IntervalType[]
}

export interface PowerThresholdRulePayload extends BasePayload<AlertRulePayloadType.Power> {
  thresholdsV2: ThresholdInfo[]
}

export interface ProductionSpeedThresholdRulePayload extends BasePayload<AlertRulePayloadType.ProductionSpeed> {
  thresholdsV2: ThresholdInfo[]
}

export interface VolumeThresholdRulePayload extends BasePayload<AlertRulePayloadType.Volume> {
  thresholdsV2: ThresholdInfo[]
  aggregation: number
}

export interface NotificationPreference {
  condition: ThresholdRuleCondition
  message: string
  muted: boolean
  recipients: Recipient[]
}

export interface BaseRule {
  id: ID
  name: string
  type: SupportedRuleType
  assets: {
    id: ID
    name: string
  }[]
  notificationPreferences: NotificationPreference[]
}

export interface PowerThresholdRule extends BaseRule {
  payload: PowerThresholdRulePayload
}

export interface VolumeThresholdRule extends BaseRule {
  payload: VolumeThresholdRulePayload
}

export interface ProductionSpeedThresholdRule extends BaseRule {
  payload: ProductionSpeedThresholdRulePayload
}

export type Rule = PowerThresholdRule | VolumeThresholdRule | ProductionSpeedThresholdRule
