import { Card, CardActions, CardContent, Typography } from '@mui/material'

import { IntegrationDeleteAlert } from './IntegrationDeleteAlert'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

interface IntegrationCardProps {
  integration: {
    id: string
    name: string
    createdAt: string
  }
  loading: boolean
  onSaveIntegrationName: (id: string, name: string) => void
  onDeleteUserApiIntegration: (id: string) => void
}

export const IntegrationsCard = ({ integration, onDeleteUserApiIntegration, loading }: IntegrationCardProps) => {
  const { i18n } = useI18nContext()

  const createdAt = new Date(integration.createdAt).toLocaleDateString()

  const handleDeleteUserApiIntegration = async (id: string) => {
    await onDeleteUserApiIntegration(id)
  }

  return (
    <Card sx={{ width: 400, padding: 2, margin: 2 }}>
      <CardContent>
        <Typography
          variant="h5"
          component="div"
        >
          {integration.name}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Typography>
          {i18n.text('integrations.created.at')} {createdAt}
        </Typography>
        <IntegrationDeleteAlert
          loading={loading}
          handleDeleteUserApiIntegration={handleDeleteUserApiIntegration}
          id={integration.id}
        />
      </CardActions>
    </Card>
  )
}
