import { DateTime } from 'luxon'
import { FC, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { AssetCard } from '../../../Shared/components/AssetCard'
import { AssetCardData } from '../../containers/OverviewPageContainer/types'
import { DEFAULT_TIMEZONE } from '../../../Shared/constants/timezone'
import { ProbeLocationThresholdAlertRuleFragment } from '../../../Shared/graphql/codegen'
import { getVibrationStatusLabel, getVibrationStatusValue } from './utils'
import { isoLocales } from '../../../Shared/utils'
import { parseThresholdsAlert } from '../../containers/GraphsPage/utils'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

export type AssetCardProps = {
  thresholdAlertRules: ProbeLocationThresholdAlertRuleFragment[] | undefined
  asset: AssetCardData
}

const VibrationCard: FC<AssetCardProps> = ({ asset, thresholdAlertRules }) => {
  const { i18n, userLocale } = useI18nContext()
  const { selectedCustomer } = useCurrentUser()
  const history = useHistory()
  const { path } = useRouteMatch()
  const thresholdAlerRule = thresholdAlertRules?.filter(rule => rule.config.conditions[0].assetId === asset.probeId)

  const thresholdsConfig = useMemo(
    () => parseThresholdsAlert(asset.probeId, thresholdAlerRule),
    [asset.probeId, thresholdAlertRules]
  )

  let muteNotifications = false
  if (thresholdsConfig.state === 'valid') {
    muteNotifications = !thresholdsConfig.alertRule.muteNotifications && thresholdsConfig.alertRule.duration !== 0
  }

  const lastMeasurementLabel = asset.lastMeasurement
    ? `Last measurement: ${DateTime.fromISO(asset.lastMeasurement) //TODO: i18n
        .setZone(selectedCustomer?.timeZone ?? DEFAULT_TIMEZONE)
        .setLocale(isoLocales[userLocale])
        .toFormat('dd MMM, HH:mm')}`
    : i18n.text('pdm.no.update') //TODO: Update in dev.

  const handleClick = () =>
    history.push(`/predictive-maintenance/machines/${asset.machineId}/probes/${asset.probeId}/graphs`)

  const vibrationStatusValue = getVibrationStatusValue(asset.vibrationStatus)
  const { icon: StatusIcon, text: vibrationStatusText } = getVibrationStatusLabel(vibrationStatusValue, asset, i18n)

  return (
    <AssetCard
      onClick={handleClick}
      hasAlerts={true}
      alerts={muteNotifications}
      lastMeasurement={lastMeasurementLabel}
      primaryName={asset.machineName}
      secondaryName={asset.probeName}
      assetNameOverflow={true}
      assetStatusLabel={{
        icon: <StatusIcon />,
        text: vibrationStatusText,
      }}
      assetStatusColor={vibrationStatusValue}
    />
  )
}

export default VibrationCard
