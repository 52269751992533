import { Input, styled } from '@mui/material'

export const StyledInput = styled(Input)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '1rem',
  color: theme.palette.SFIGreyLight[800],
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  borderRadius: theme.shape.borderRadius,
  padding: '0.625rem 0.875rem',
  fontWeight: 500,
  lineHeight: '24px',
  '& input': {
    padding: 0,
    paddingBottom: '1px',
  },
}))
