import { Box, styled, useTheme } from '@mui/material'

import backGroundLine from './Images/BackgroundLine.svg'
import {
  CoinsHandIcon,
  FileSettingsIcon,
  GraduationHatIcon,
  PresentationChartIcon,
} from '../../../../Shared/components/icons'
import { GoalBlock } from '../../../components/MeetingGoals/v1/GoalBlock'
import { SemiboldDarkMdDisplayText } from '../../../components/Common/v1/index'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const ContentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4rem',
  padding: '6rem 0',
  maxWidth: theme.breakpoints.values.xl,
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const LineBox = styled(Box)(() => ({
  backgroundImage: `url(${backGroundLine})`,
  display: 'grid',
  gridTemplateColumns: '22% 22% 22% 22%',
  alignItems: 'top',
  columnGap: '4%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 1.563rem',
  backgroundSize: '80%',
  width: '80%',
}))

export const MeetingGoals = () => {
  const theme = useTheme()
  const { i18n } = useI18nContext()

  const features = [
    {
      icon: <PresentationChartIcon sx={{ color: theme.palette.SFIGreyLight[700] }} />,
      title: i18n.text(`scan.meeting-goals.insights.title`),
      subtitle: i18n.text(`scan.meeting-goals.insights.subtitle`),
    },
    {
      icon: <CoinsHandIcon sx={{ color: theme.palette.SFIGreyLight[700] }} />,
      title: i18n.text(`scan.meeting-goals.efficiency.title`),
      subtitle: i18n.text(`scan.meeting-goals.efficiency.subtitle`),
    },
    {
      icon: <FileSettingsIcon sx={{ color: theme.palette.SFIGreyLight[700] }} />,
      title: i18n.text(`scan.meeting-goals.action.title`),
      subtitle: i18n.text(`scan.meeting-goals.action.subtitle`),
    },
    {
      icon: <GraduationHatIcon sx={{ color: theme.palette.SFIGreyLight[700] }} />,
      title: i18n.text(`scan.meeting-goals.solutions.title`),
      subtitle: i18n.text(`scan.meeting-goals.solutions.subtitle`),
    },
  ]

  return (
    <ContentBox>
      <SemiboldDarkMdDisplayText>{i18n.text(`scan.meeting-goals.title`)}</SemiboldDarkMdDisplayText>
      <LineBox>
        {features.map((feature, index) => (
          <GoalBlock
            key={index}
            icon={feature.icon}
            title={feature.title}
            subtitle={feature.subtitle}
          />
        ))}
      </LineBox>
    </ContentBox>
  )
}
