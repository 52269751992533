import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { Sensors } from '@mui/icons-material'

import { Machine } from './PdMAssetTree'

type Probe = Machine['probes'][number]

type ProbeWrapperProps = {
  probe: Probe
  selected: string
}

const ProbeComponent: FC<ProbeWrapperProps> = ({ probe, selected }: ProbeWrapperProps) => {
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '1rem',
        paddingBottom: '0.5rem',
        padding: '1rem 0 0.75rem 1rem',
        backgroundColor: selected === probe.id ? 'hsl(200, 50%, 93%)' : 'initial',
        '&:hover': {
          backgroundColor: theme.palette.SFIBrand[200],
          color: theme.palette.SFIBrand[900],
        },
      })}
      data-testid="tree-probe-container"
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Sensors
          color="primary"
          sx={theme => ({
            marginRight: 1,
            color: selected === probe.id ? theme.palette.SFIBrand[900] : 'initial',
          })}
        />
        <Typography
          color="primary"
          sx={theme => ({
            fontSize: '0.95em',
            underline: 'none',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '150px',
            color: selected === probe.id ? theme.palette.SFIBrand[900] : 'initial',
          })}
        >
          {probe.name}
        </Typography>
      </Box>
    </Box>
  )
}

export default ProbeComponent
