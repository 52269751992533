import { Box } from '@mui/material'

import {
  CustomTableCellInput,
  CustomTableCellInputProps,
} from '../../../Shared/components/MUIComponents/update/StickyColumnTable/CustomTableCellInput'
import {
  ItemInGridInTableCell,
  StickyColumn,
  StickyColumnTable,
} from '../../../Shared/components/MUIComponents/update/StickyColumnTable'

const TableTitleComponent = () => {
  return <div>Energy Consumption across sources</div>
}
export const StickyColumnTablePage = () => {
  const stickyColumns: StickyColumn[] = [
    {
      columnTitleSlot: () => {
        return <ItemInGridInTableCell>Energy Source</ItemInGridInTableCell>
      },
      columnValuesSlots: [
        props => {
          return (
            <ItemInGridInTableCell
              sx={{ justifyContent: 'flex-start' }}
              {...props}
            >
              Coal
            </ItemInGridInTableCell>
          )
        },
        props => {
          return (
            <ItemInGridInTableCell
              sx={{ justifyContent: 'flex-start' }}
              {...props}
            >
              Natural Gas
            </ItemInGridInTableCell>
          )
        },
        props => {
          return (
            <ItemInGridInTableCell
              sx={{ justifyContent: 'flex-start' }}
              {...props}
            >
              Hydro
            </ItemInGridInTableCell>
          )
        },
        props => {
          return (
            <ItemInGridInTableCell
              sx={{ justifyContent: 'flex-start' }}
              {...props}
            >
              Banana
            </ItemInGridInTableCell>
          )
        },
      ],
    },
    {
      columnTitleSlot: () => {
        return <ItemInGridInTableCell>CO2 eq/kWh</ItemInGridInTableCell>
      },
      columnValuesSlots: [
        props => {
          return <ItemInGridInTableCell {...props}>100</ItemInGridInTableCell>
        },
        props => {
          return <ItemInGridInTableCell {...props}>200</ItemInGridInTableCell>
        },
        props => {
          return <ItemInGridInTableCell {...props}>300</ItemInGridInTableCell>
        },
        props => {
          return <ItemInGridInTableCell {...props}>300</ItemInGridInTableCell>
        },
      ],
    },
  ]

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const status = [
    'Actual',
    'Actual',
    'Actual',
    'Actual',
    'Forecast',
    'Forecast',
    'Forecast',
    'Forecast',
    'Forecast',
    'Forecast',
    'Forecast',
    'Forecast',
  ]
  const tableValues = stickyColumns[0].columnValuesSlots.map(source => {
    return Array.from({ length: 12 }, () => Math.floor(Math.random() * 100).toString())
  })

  const tableValuesInputs = tableValues.map((row, index) =>
    row.map((value, index) => (props: CustomTableCellInputProps) => (
      <CustomTableCellInput
        cellProps={props.cellProps}
        inputProps={{ value, onClick: e => console.log((e.target as any).value), endAdornment: '%' }}
      />
    ))
  )
  return (
    <Box mt={4}>
      <StickyColumnTable
        tableTitleSlot={TableTitleComponent}
        stickyColumns={stickyColumns}
        tableHeaders={[months, status]}
        tableValues={tableValuesInputs}
      />
    </Box>
  )
}
