import moment from 'moment'

import { ID } from '../../../Shared/types/types'
import { ReportDocument } from './types'
import { useGetCustomerEsDocumentsQuery } from '../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

export function useEnergyScanDocumentGroup(id: ID) {
  const { i18n } = useI18nContext()
  const { data } = useGetCustomerEsDocumentsQuery({ variables: { customerId: id } })
  const eSDocuments = data?.customerEnergyScanDocuments
  const description = i18n.text('reports.scan-documents.available')
  const heading = i18n.text('reports.scan-documents.scan-documents')
  const docId = '__CustomerEnergyScanDocument__'
  const documents =
    eSDocuments?.map(doc => {
      const document: ReportDocument = {
        config: {
          id: doc.id,
          title: doc.documentType,
        },
        from: new Date(doc.documentDate),
        locale: 'en_GB',
        s3Url: doc.s3Url,
        to: new Date(doc.documentDate),
        displayInfo: {
          date: `${moment(new Date(doc.documentDate)).format('DD MMM YYYY')}`,
          name: i18n.text(`reports.scan-documents.${doc.documentType}`),
        },
      }
      return document
    }) ?? []

  const eSDocumentGroup = {
    description: description,
    heading: heading,
    id: docId,
    documents: documents,
  }
  return eSDocumentGroup
}
