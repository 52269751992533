import { AlertConfigurationActions } from '../components/AlertConfigurationActions'
import { IAlertRuleData } from '../types/alertTypes'
import { ICustomTableColumn } from '../../Shared/types/types'

interface IGetAlertRulesColumnsArgs {
  onDeleteButtonClick: (id?: string) => void
  onEditButtonClick: (id?: string, row?: IAlertRuleData) => void
}

export const getAlertRulesColumns = ({ onDeleteButtonClick, onEditButtonClick }: IGetAlertRulesColumnsArgs) => {
  const columns: ICustomTableColumn<IAlertRuleData>[] = [
    {
      id: 'asset',
      label: 'alerts.messages.column.subject',
      width: '10%',
      align: 'left',
      isBoldText: true,
    },
    {
      id: 'message',
      label: 'messages.headers.message',
      width: '25%',
      align: 'left',
    },
    {
      id: 'category',
      label: 'alerts.messages.column.category',
      width: '15%',
      align: 'left',
    },
    {
      id: 'product',
      label: 'alerts.messages.column.product',
      width: '10%',
      align: 'left',
    },
    {
      id: 'recipient',
      label: 'rules.headers.recipients',
      width: '15%',
      align: 'left',
      isBoldText: true,
    },
    {
      id: 'action',
      label: '',
      width: '10%',
      children: (item, id, row) => (
        <AlertConfigurationActions
          id={id}
          row={row}
          onDeleteButtonClick={onDeleteButtonClick}
          onEditButtonClick={onEditButtonClick}
        />
      ),
    },
  ]
  return columns
}

export function createAlertsRowData({
  id,
  asset,
  recipient,
  category,
  message,
  product,
  action,
}: IAlertRuleData): IAlertRuleData {
  return { id, asset, recipient, category, message, product, action }
}
