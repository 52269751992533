import { useMemo } from 'react'

import usePropositions, { CurrentPropositions } from '../../Shared/hooks/usePropositions/usePropositions'
import { StyledToggleButtonGroupItem } from '../../Shared/components/MUIComponents/update/StyledToggleButtonGroup'

export const useFilteredPropositions = () => {
  const { propositions, hasProposition } = usePropositions()

  // TODO (Sabina): Once ready, return temperature proposition
  const customizedPropositions = useMemo(
    () =>
      Object.values(propositions).reduce<StyledToggleButtonGroupItem[]>((acc, proposition) => {
        if (hasProposition(proposition.key) && proposition.key !== CurrentPropositions.TEMPERATURE) {
          acc.push({
            value: proposition.name,
          })
        }
        return acc
      }, []),
    [propositions]
  )

  return customizedPropositions
}
