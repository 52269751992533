import { useEffect, useState } from 'react'

import usePropositions, { CurrentPropositions } from '../../Shared/hooks/usePropositions/usePropositions'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'

export const useSelectedProposition = () => {
  const { i18n } = useI18nContext()
  const { propositions, hasProposition } = usePropositions()
  const [selectedProposition, setSelectedProposition] = useState(propositions.electricity.name)

  useEffect(() => {
    if (!hasProposition(propositions.electricity.key)) {
      for (const key in propositions) {
        if (hasProposition(key as CurrentPropositions)) {
          setSelectedProposition(propositions[key as CurrentPropositions].name)
          break
        }
      }
    } else {
      setSelectedProposition(propositions.electricity.name)
    }
  }, [propositions, i18n])

  return {
    selectedProposition,
    setSelectedProposition,
  }
}
