import IconButton from '@mui/material/IconButton'
import React, { FC } from 'react'
import { Box, Chip, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'

import { CrossIcon } from '../../../Shared/components/icons'
import { Interval } from '../../../Shared/hooks/useLiveRefetching'
import { SECTION_HEADER_DATE_FORMAT } from '../../utils/constants'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type ProductionStopDrawerHeaderProps = {
  name: string
  interval: Interval | null
  unexplainedStopsCount: number
  onClose: () => void
}

const ProductionStopsDrawerHeader: FC<ProductionStopDrawerHeaderProps> = ({
  name,
  interval,
  unexplainedStopsCount,
  onClose,
}) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()

  const getFormattedDate = (date: string) => DateTime.fromISO(date).toFormat(SECTION_HEADER_DATE_FORMAT)

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        rowGap: theme.spacing(1),
        alignItems: 'start',
        padding: '1.2rem',
        borderBottom: `1px solid ${theme.palette.SFIGreyLight[300]}`,
      }}
    >
      <Box>
        <Typography
          sx={{ fontWeight: 600, fontSize: '1.2rem' }}
          variant="h6"
        >
          {i18n.text('oee.stop-logging.drawer.header-title')} - {name}
        </Typography>
        {interval && (
          <Typography
            sx={{ fontWeight: 400, fontSize: '0.875rem', color: theme.palette.SFIGreyLight[500], mb: '0.2rem' }}
            variant="subtitle2"
          >
            {getFormattedDate(interval.start)} - {getFormattedDate(interval.end)}
          </Typography>
        )}
        {unexplainedStopsCount > 0 && (
          <Chip
            sx={{
              backgroundColor: theme.palette.SFIWarning[50],
              border: `1px solid ${theme.palette.SFIWarning[200]}`,
              borderRadius: '1rem',
              width: 'fit-content',
              height: 'auto',
              padding: '0.1rem 0.5rem',
              '& .MuiChip-label': {
                color: theme.palette.SFIWarning[700],
                fontSize: '0.75rem',
                padding: 0,
              },
            }}
            label={`${unexplainedStopsCount} ${i18n.text('oee.stop-logging.drawer.header-chip-counter')}`}
          />
        )}
      </Box>
      <IconButton
        aria-label="Close drawer"
        sx={{ display: 'flex', cursor: 'pointer', padding: '0.5rem' }}
        onClick={onClose}
      >
        <CrossIcon sx={{ width: '0.8rem', height: '0.8rem', color: theme.palette.SFIGreyLight[400] }} />
      </IconButton>
    </Box>
  )
}

export default ProductionStopsDrawerHeader
