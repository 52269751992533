import { DateTime, Interval } from 'luxon'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Mutable } from 'utility-types'
import { useLocation, useParams } from 'react-router'

import useAnalytics from '../../Shared/hooks/useAnalytics/useAnalytics'
import { AnalysisType } from '../../Shared/types/analysis_types'
import { AssetTree, AssetTreeTab } from '../types'
import {
  Asset_Contexts_Enum,
  DataType,
  useElectricityAssetMeasurementsLazyQuery,
  useElectricityAssetOutsideProductionLazyQuery,
  useElectricityAssetStandbyLazyQuery,
  useElectricityAssetStandbyValidationTypesQuery,
  useElectricityAssetsQuery,
  useStandbyAlertsRulesQuery,
} from '../../Shared/graphql/codegen'
import { CompareDateRange } from '../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker/types'
import { DEFAULT_TIMEZONE } from '../../Shared/constants/timezone'
import {
  DateRange,
  ElectricityAnalysisType,
  ElectricityAssetMeasurement,
  FetchedAssetWithMeasurements,
  FetchedAssetWithOop,
  FetchedAssetWithStandby,
  FlatAsset,
  FullGroupAsset,
  StandbyValidationType,
} from '../types'
import { LocalDateTime } from '../../Shared/types/types'
import { StartEndDatesValues } from '../../Shared/components/MUIComponents/update/MuiDateTimeRangePickerControlled/types'
import { createCtx, logInDev, toLocalDateTime } from '../../Shared/utils'
import {
  filterElectricityAssets,
  processElectricityNewAssetTree,
} from '../containers/ElectricityContainer/electricityUtils'
import { flattenAssets } from '../utils/flattenAssets'
import { getInitialDateRange } from '../../Shared/utils/analysis_utils'
import { mapFetchedCompareMeasurements, mapFetchedMeasurements } from '../utils/mapFetchedMeasurements'
import { processFlatAssets } from '../utils/processFlatAssets'
import { sendAssetSelectionEventElectricity, sendSwitchAssetGroupTabEventElectricity } from '../utils/analyticsEvents'
import { useCurrentUser } from '../../Shared/contexts/CurrentUserContext'

type ElectricityContext = {
  assetTree: AssetTree
  selectedAssets: Record<string, boolean>
  machines: FullGroupAsset[]
  isSingleAssetSelected: boolean
  areAssetsSelected: boolean
  areAllAssetsSelected: boolean
  fetchAssetsLoading: boolean
  fetchMeasurementsLoading: boolean
  fetchStandbyLoading: boolean
  fetchOopLoading: boolean
  fetchError: boolean
  selectedDateRange: DateRange
  compareDateRange: CompareDateRange
  analysisType: ElectricityAnalysisType
  assetTreeTab: AssetTreeTab
  measurements: ElectricityAssetMeasurement[]
  previousAssetMeasurements: ElectricityAssetMeasurement[]
  toggleAssetSelect: (assetId: string) => void
  toggleSelectAll: () => void
  areMachinesOrTheirComponentsSelected: (groupOrMachineOrComponentId: string) => boolean
  onDateRangeChange: (dateRange: DateRange, zooming?: boolean) => void
  onCompareRangeChange: (dateRange: CompareDateRange, zooming?: boolean) => void
  onCompareDatesToggle: () => void
  onTabNavigate: (tab: AssetTreeTab) => void
  toggleGroupSelect: (groupId: string) => void
  doesAssetHaveStandbyEnabled: (id: string) => boolean
  getAssetStandbyValidation: (assetId: string) => StandbyValidationType
  fromToValues: StartEndDatesValues
  setFromToValues: React.Dispatch<React.SetStateAction<StartEndDatesValues>>
  previousPeriodFromToValues: StartEndDatesValues
  setPreviousPeriodFromToValues: React.Dispatch<React.SetStateAction<StartEndDatesValues>>
  showCompare: boolean
  setShowCompare: React.Dispatch<React.SetStateAction<boolean>>
  csvExportRequest: AnalysisType | null
  setCsvExportRequest: React.Dispatch<React.SetStateAction<AnalysisType | null>>
}

const [useElectricityContext, ElectricityContextReactProvider] = createCtx<ElectricityContext>()

const ElectricityContextProvider: FC = ({ children }) => {
  const [fetchError, setFetchError] = useState(false)
  const [assetTree, setAssetTree] = useState<AssetTree>({ groups: [] })
  const [processedFlatAssets, setProcessedFlatAssets] = useState<Array<FlatAsset>>([])
  const [groups, setGroups] = useState<FullGroupAsset[]>([])
  const [machines, setMachines] = useState<FullGroupAsset[]>([])
  const [measurements, setMeasurements] = useState<ElectricityAssetMeasurement[]>([])
  const [previousAssetMeasurements, setPreviousAssetMeasurements] = useState<ElectricityAssetMeasurement[]>([])
  const [assetTreeTab, setAssetTreeTab] = useState<AssetTreeTab>(AssetTreeTab.MACHINE)
  const [csvExportRequest, setCsvExportRequest] = useState<AnalysisType | null>(null)

  const [showCompare, setShowCompare] = useState<boolean>(false)
  const [selectedDateRange, setDateRange] = useState<DateRange>({
    startDate: DateTime.now().startOf('week'),
    endDate: DateTime.now().endOf('week'),
  })
  const [compareDateRange, setCompareDateRange] = useState<CompareDateRange>({
    startDate: null,
    endDate: null,
  })

  // TODO rename this and refactor Timepicker component
  // to not need separate state for display vs the current time range
  // and to not set state by itself
  // These are the from and to values used for display purposes by the timepicker
  const [fromToValues, setFromToValues] = useState<StartEndDatesValues>({
    startDate: DateTime.now().startOf('week'),
    endDate: DateTime.now().endOf('week'),
  })

  // Same as above but for Compare time range
  const [previousPeriodFromToValues, setPreviousPeriodFromToValues] = useState<StartEndDatesValues>({
    startDate: null,
    endDate: null,
  })

  const { sendEvent } = useAnalytics()

  const [fromQueryAssetIds, setFromQueryAssetIds] = useState<string[] | null>(null)
  const [selectedAssets, setSelectedAssets] = useState<Record<string, boolean>>({})
  const isSingleAssetSelected = Object.keys(selectedAssets).length === 1
  const areAssetsSelected = Object.keys(selectedAssets).length > 0
  const areAllAssetsSelected = Object.keys(selectedAssets).length === machines.length

  const analysisType = useParams<{ type: ElectricityAnalysisType }>().type || 'power'
  const location = useLocation()
  const { selectedCustomer } = useCurrentUser()
  const customerTimezone = selectedCustomer.timeZone || DEFAULT_TIMEZONE // @TODO: guarantee timezone defaults in context not here

  const toggleAssetSelect = useCallback(
    (assetId: string) => {
      setSelectedAssets(prevState => {
        const newState = { ...prevState }

        if (prevState[assetId]) {
          delete newState[assetId]
          return newState
        }
        // When selecting a machine we deselect its components
        const isMachine = machines.some(a => a.id === assetId)
        if (isMachine) {
          const machineComponents = processedFlatAssets.find(a => a.id === assetId)?.assets
          machineComponents && machineComponents.forEach(c => delete newState[c.id])
        } else {
          // When selecting a component we deselect its parent machine
          const component = processedFlatAssets.find(a => a.id === assetId)
          if (component?.parent) {
            delete newState[component.parent.id]
          }
        }

        return { ...newState, [assetId]: true }
      })
      sendAssetSelectionEventElectricity(sendEvent)
    },
    [machines, processedFlatAssets]
  )

  const toggleGroupSelect = useCallback(
    (groupId: string) => {
      const group = processedFlatAssets.find(g => g.id === groupId)
      if (!group) return
      const groupMachinesIds = group.assets?.map(m => m.id) || []

      setSelectedAssets(prevState => {
        const newState = { ...prevState }
        if (prevState[groupId] || groupMachinesIds.some(id => prevState[id])) {
          delete newState[groupId]
          groupMachinesIds.forEach(id => delete newState[id])
          return newState
        }
        groupMachinesIds.forEach(id => (newState[id] = true))
        return newState
      })
      sendAssetSelectionEventElectricity(sendEvent)
    },
    [processedFlatAssets]
  )

  const toggleSelectAll = useCallback(() => {
    if (assetTreeTab === AssetTreeTab.GROUP) {
      const groupIds = groups.map(g => g.id)
      setSelectedAssets(prevState => {
        const ifAnySelected = Object.keys(prevState).length > 0
        if (!ifAnySelected) {
          const newState: typeof prevState = {}
          groupIds.forEach(id => (newState[id] = true))
          return newState
        } else {
          return {}
        }
      })
    } else {
      const machineIds = machines.map(m => m.id)
      setSelectedAssets(prevState => {
        const ifAnySelected = Object.keys(prevState).length > 0
        if (!ifAnySelected) {
          const newState: typeof prevState = {}
          machineIds.forEach(id => (newState[id] = true))
          return newState
        } else {
          return {}
        }
      })
    }
    sendAssetSelectionEventElectricity(sendEvent)
  }, [assetTreeTab, groups, machines])

  const onDateRangeChange = useCallback(
    ({ startDate, endDate }: DateRange, zooming?: boolean) => {
      const startOfStartDate = startDate ? startDate.startOf('day') : selectedDateRange.startDate.startOf('day')
      const endOfEndDay = endDate ? endDate.endOf('day') : selectedDateRange.endDate.endOf('day')
      const dateRange = zooming
        ? {
            startDate: startDate.startOf('second') || selectedDateRange.startDate,
            endDate: endDate.startOf('second') || selectedDateRange.endDate,
          }
        : {
            startDate: startOfStartDate,
            endDate: endOfEndDay,
          }
      setDateRange(dateRange)
      setFromToValues({ startDate: dateRange.startDate, endDate: dateRange.endDate })
    },
    [selectedDateRange.endDate, selectedDateRange.startDate]
  )

  const onCompareRangeChange = useCallback(
    ({ startDate, endDate }: CompareDateRange, zooming?: boolean) => {
      const startOfStartDate = startDate
        ? startDate.startOf('day')
        : compareDateRange.startDate && compareDateRange.startDate.startOf('day')
      const endOfEndDay = endDate
        ? endDate.endOf('day')
        : compareDateRange.endDate && compareDateRange.endDate.endOf('day')
      const dateRange = zooming
        ? {
            startDate: startDate?.startOf('second') || selectedDateRange.startDate,
            endDate: endDate?.startOf('second') || selectedDateRange.endDate,
          }
        : {
            startDate: startOfStartDate,
            endDate: endOfEndDay,
          }
      setCompareDateRange(dateRange)
      setPreviousPeriodFromToValues(dateRange)
    },
    [compareDateRange.endDate, compareDateRange.startDate]
  )

  const onCompareDatesToggle = useCallback(() => {
    onCompareRangeChange({ startDate: null, endDate: null })
    setPreviousAssetMeasurements([])
  }, [])

  const onAssetTreeTabNavigate = useCallback(
    (tab: AssetTreeTab) => {
      if (tab !== assetTreeTab) {
        setAssetTreeTab(tab)
        setSelectedAssets({})
        sendSwitchAssetGroupTabEventElectricity(sendEvent)
      }
    },
    [assetTreeTab]
  )

  // fetchAssetsQuery
  const { error: fetchAssetsError, loading: fetchAssetsLoading, data: fetchedAssets } = useElectricityAssetsQuery()

  // fetchStandbyAlertsQuery
  const { data: fetchedStandbyAlertsRules } = useStandbyAlertsRulesQuery()

  // fetchMeasurementsQuery
  const [
    runFetchAssetMeasurementsQuery,
    { error: fetchMeasurementsError, loading: fetchMeasurementsLoading, data: fetchedMeasurements },
  ] = useElectricityAssetMeasurementsLazyQuery()

  const [runFetchAssetStandbyQuery, { error: fetchStandbyError, loading: fetchStandbyLoading, data: fetchedStandby }] =
    useElectricityAssetStandbyLazyQuery()

  const [runFetchOOPLazyQuery, { data: fetchedOOP, loading: fetchOopLoading, error: fetchOOPError }] =
    useElectricityAssetOutsideProductionLazyQuery()

  // fetchStandbyValidationTypeQuery
  const { data: fetchedStandbyValidationTypes } = useElectricityAssetStandbyValidationTypesQuery({
    variables: {
      assetIds: processedFlatAssets.map(a => a.id),
    },
  })

  const assetsWithStandby = useMemo(() => {
    const assetsWithStandby = new Map<string, boolean>()
    processedFlatAssets.forEach(a => assetsWithStandby.set(a.id, a.standbyEnabled))
    return assetsWithStandby
  }, [processedFlatAssets])

  const doesAssetHaveStandbyEnabled = (id: string) => assetsWithStandby.get(id) || false

  const getAssetStandbyValidation = (assetId: string): StandbyValidationType => {
    const asset = processedFlatAssets.find(a => a.id === assetId)
    return asset?.standbyValidationType || null
  }

  const rawAssets = useMemo(() => fetchedAssets?.myOrg?.assets ?? [], [fetchedAssets])

  const standbyAlertRules = useMemo(
    () => fetchedStandbyAlertsRules?.myOrg?.assets.flatMap(asset => asset.standbyAlertRules) ?? [],
    [fetchedStandbyAlertsRules]
  )

  const rawFlatAssets = useMemo(() => {
    const nonEmptyElectricityAssets = filterElectricityAssets(rawAssets)
    return flattenAssets(nonEmptyElectricityAssets)
  }, [rawAssets])

  const areMachinesOrTheirComponentsSelected = useCallback(
    (groupOrMachineOrComponentId: string) => {
      const asset = processedFlatAssets.find(a => a.id === groupOrMachineOrComponentId)
      const isGroup = asset?.context === Asset_Contexts_Enum.Group
      const isMachine = asset?.context === Asset_Contexts_Enum.Machine

      if (isGroup) {
        const machines = asset?.assets || []
        return machines.some(m => {
          const isMachineSelected = !!selectedAssets[m.id]
          const areItsComponentsSelected = m.assets?.some(c => selectedAssets[c.id])
          return isMachineSelected || areItsComponentsSelected
        })
      }
      if (isMachine) {
        const isMachineSelected = !!selectedAssets[groupOrMachineOrComponentId]
        const components = asset?.assets || []
        const areItsComponentsSelected = components.some(c => selectedAssets[c.id])
        return isMachineSelected || areItsComponentsSelected
      } else {
        const parentMachineId = asset?.parent?.id
        const isComponentSelected = !!selectedAssets[groupOrMachineOrComponentId]
        const isParentMachineSelected = !!(parentMachineId && selectedAssets[parentMachineId])
        return isComponentSelected || isParentMachineSelected
      }
    },
    [processedFlatAssets, selectedAssets]
  )
  // intercept query params on first load and save asset to select
  useEffect(() => {
    const queryAssetIds = new URLSearchParams(location.search).getAll('assets')
    if (queryAssetIds.length) setFromQueryAssetIds([...queryAssetIds])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // get initial dates from query params
  useEffect(() => {
    const queryDateRange = getInitialDateRange(location.search, customerTimezone)
    onDateRangeChange(queryDateRange, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // create asset tree by filtering assets and commodity types
  useEffect(() => {
    const assetTree = processElectricityNewAssetTree(rawAssets)
    setAssetTree(assetTree)
  }, [rawAssets])

  // extract groups and machines for convenience
  useEffect(() => {
    const groups = rawFlatAssets.filter(g => g.context === 'GROUP').sort((a, b) => a.name.localeCompare(b.name))
    const machines = rawFlatAssets.filter(m => m.context === 'MACHINE').sort((a, b) => a.name.localeCompare(b.name))

    setGroups(groups)
    setMachines(machines)
  }, [rawFlatAssets])

  // process assets with colors, standby rules and validation types
  useEffect(() => {
    const flatAssetsWithStandbyValidationTypes = processFlatAssets(
      rawFlatAssets,
      standbyAlertRules,
      fetchedStandbyValidationTypes
    )
    setProcessedFlatAssets(flatAssetsWithStandbyValidationTypes)
  }, [rawFlatAssets, fetchedStandbyAlertsRules, fetchedStandbyValidationTypes])

  // select initial asset from query params or asset list
  useEffect(() => {
    if (!rawFlatAssets.length || !machines.length || !groups.length) return
    const typeNeeded = assetTreeTab === AssetTreeTab.MACHINE ? Asset_Contexts_Enum.Machine : Asset_Contexts_Enum.Group
    const assets = rawFlatAssets.filter(a => a.context === typeNeeded)

    if (fromQueryAssetIds) {
      const assetsToSelect = assets.filter(a => fromQueryAssetIds.includes(a.id)).map(a => a.id)
      setFromQueryAssetIds(null) // reset query param after it was used once
      if (assetsToSelect.length) {
        const selectedAssets = assetsToSelect.reduce((acc, id) => ({ ...acc, [id]: true }), {})
        return setSelectedAssets(selectedAssets)
      }
    }

    const [firstGroup] = groups
    const firstMachineInGroup = machines.find(m => m.parent?.id === firstGroup.id) ?? machines[0]
    typeNeeded === Asset_Contexts_Enum.Machine
      ? setSelectedAssets({ [firstMachineInGroup.id]: true })
      : setSelectedAssets({ [firstGroup.id]: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTreeTab, rawFlatAssets, machines, groups])

  // map measurements, standby and compare measurements to assets after fetch
  useEffect(() => {
    const safeFetchedMeasurements = (fetchedMeasurements?.myOrg?.assets ??
      []) as Mutable<FetchedAssetWithMeasurements>[]
    const safeFetchedStandby = (fetchedStandby?.myOrg?.assets ?? []) as Mutable<FetchedAssetWithStandby>[]
    const safeFetchedOop = (fetchedOOP?.myOrg?.assets ?? []) as Mutable<FetchedAssetWithOop>[]

    const assetMeasurements: ElectricityAssetMeasurement[] = mapFetchedMeasurements(
      processedFlatAssets,
      safeFetchedMeasurements,
      safeFetchedStandby,
      safeFetchedOop
    )

    setMeasurements(assetMeasurements)
    // COMPARE MEASUREMENTS

    if (!compareDateRange.startDate || !compareDateRange.endDate) return
    const prevAssetMeasurements: ElectricityAssetMeasurement[] = mapFetchedCompareMeasurements(
      processedFlatAssets,
      safeFetchedMeasurements,
      selectedDateRange,
      compareDateRange as DateRange,
      customerTimezone
    )
    setPreviousAssetMeasurements(prevAssetMeasurements)
  }, [fetchedMeasurements, fetchedStandby, selectedAssets])

  // set error state
  useEffect(() => {
    const error = fetchAssetsError || fetchMeasurementsError
    if (error) {
      logInDev(error.message)
      setFetchError(true)
    }
  }, [fetchAssetsError, fetchMeasurementsError])

  // fetch measurements, standby, oop when measurementsType, selected assets or dates change
  useEffect(() => {
    if (!areAssetsSelected) return

    // fetch measurements
    const { startDate, endDate } = selectedDateRange
    const timeRange = Interval.fromDateTimes(startDate, endDate).toDuration()
    const { startDate: prevFrom, endDate: prevTo } = compareDateRange
    const measurementsType = analysisType === 'power' ? DataType.Power : DataType.Energy

    // We've stripped out the last miliseconds of the date to make it easier to allign compare data,
    // but api-measurement expects the date to end in 999 ms. So we set everything in the query back to the end of the second.
    const measurementVariables = {
      assetIds: Object.keys(selectedAssets),
      from: toLocalDateTime(startDate.endOf('second')),
      to: toLocalDateTime(endDate.endOf('second')),
      type: measurementsType,
      prevFrom: prevFrom ? toLocalDateTime(prevFrom.endOf('second')) : ('' as LocalDateTime), // TODO break this query into its own getPrevMeasurements query
      prevTo: prevTo ? toLocalDateTime(prevTo.endOf('second')) : ('' as LocalDateTime),
      fetchPrev: !!prevFrom && !!prevTo,
    }
    runFetchAssetMeasurementsQuery({ variables: measurementVariables })

    // fetch oop
    runFetchOOPLazyQuery({ variables: measurementVariables })

    // fetch standby
    const assetsWithStandbyIds = Object.keys(selectedAssets).filter(doesAssetHaveStandbyEnabled)
    const standbyVariables = {
      assetIds: assetsWithStandbyIds,
      from: toLocalDateTime(startDate.endOf('second')),
      to: toLocalDateTime(endDate.endOf('second')),
      type: measurementsType,
    }

    assetsWithStandbyIds.length > 0 && runFetchAssetStandbyQuery({ variables: standbyVariables })
  }, [selectedAssets, selectedDateRange, analysisType])

  // clear measurements and compareMeasurements when no assets are selected
  useEffect(() => {
    if (!areAssetsSelected) {
      setMeasurements([])
      setPreviousAssetMeasurements([])
    }
  }, [selectedAssets])

  return (
    <ElectricityContextReactProvider
      value={{
        fetchError,
        assetTree,
        selectedAssets,
        machines,
        isSingleAssetSelected,
        areAssetsSelected,
        areAllAssetsSelected,
        fetchAssetsLoading,
        fetchMeasurementsLoading,
        fetchStandbyLoading,
        fetchOopLoading,
        selectedDateRange,
        compareDateRange,
        analysisType,
        assetTreeTab,
        measurements,
        previousAssetMeasurements,
        toggleAssetSelect,
        toggleSelectAll,
        onDateRangeChange,
        onCompareRangeChange,
        onCompareDatesToggle,
        onTabNavigate: onAssetTreeTabNavigate,
        toggleGroupSelect,
        doesAssetHaveStandbyEnabled,
        getAssetStandbyValidation,
        areMachinesOrTheirComponentsSelected,
        fromToValues,
        setFromToValues,
        previousPeriodFromToValues,
        setPreviousPeriodFromToValues,
        showCompare,
        setShowCompare,
        csvExportRequest,
        setCsvExportRequest,
      }}
    >
      {children}
    </ElectricityContextReactProvider>
  )
}

export { useElectricityContext, ElectricityContextProvider }
