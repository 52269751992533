import React from 'react'
import { createSvgIcon } from '@mui/material'

export const CodeIcon = createSvgIcon(
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6666 12L14.6666 8L10.6666 4M5.33331 4L1.33331 8L5.33331 12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,

  'code-icon'
)
