import { Box, Link, Typography, styled, useTheme } from '@mui/material'
import { FC } from 'react'

import { CertificateIcon, LinkExternalIcon } from '../../../../../Shared/components/icons'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  alignItems: 'flex-start',
})

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 600,
  color: theme.palette.SFIGreyLight[800],
  lineHeight: '1.25rem',
  textAlign: 'left',
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '1.25rem',
  textAlign: 'left',
}))

const MainText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  textAlign: 'left',
}))

type KnowledgeBaseLinkProps = {
  knowledgeBaseUrl: string
  handleKnowledgeBaseClick: () => void
}

export const KnowledgeBaseLink: FC<KnowledgeBaseLinkProps> = ({ knowledgeBaseUrl, handleKnowledgeBaseClick }) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  return (
    <ContentWrapper>
      <Title sx={{ paddingTop: '1rem' }}>{i18n.text(`reports.tracking.support.dialog.articles`)}</Title>
      <Link
        underline="none"
        href={knowledgeBaseUrl}
        target="_blank"
        onClick={() => handleKnowledgeBaseClick}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.75rem' }}>
            <Box
              sx={{
                display: 'flex',
                padding: '0.5rem',
                background: theme.palette.SFIGreyLight[100],
                height: 'fit-content',
              }}
            >
              <CertificateIcon sx={{ color: theme.palette.SFIBrand[800] }} />
            </Box>
            <Box>
              <Subtitle>{i18n.text(`reports.tracking.support.dialog.reports`)}</Subtitle>
              <MainText>{i18n.text(`reports.tracking.support.dialog.whatReports`)}</MainText>
            </Box>
          </Box>
          <LinkExternalIcon
            sx={{
              color: theme.palette.SFIGreyLight[500],
              fontSize: '1rem',
            }}
          />
        </Box>
      </Link>
    </ContentWrapper>
  )
}
