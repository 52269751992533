import React, { FC, useState } from 'react'
import { Autocomplete, Box, outlinedInputClasses } from '@mui/material'

import { ArrowDownIcon } from '../../../../../../Shared/components/icons'
import { AutocompleteOption } from '../../../../../../Shared/types/types'
import { ExplanationText, GridBoxRow, Label } from '../..'
import { StyledAutocompleteTextField } from '../../../../../../Shared/components/MUIComponents/update/styledComponents/StyledAutocomplete'
import { SupportedLocale, supportedLocalesStrings } from '../../../../../../Shared/utils'
import { useCurrentUser } from '../../../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../../../Shared/contexts/i18nContext/I18nContext'

export const LanguageAutocomplete: FC = () => {
  const { i18n, userLocale } = useI18nContext()
  const { updateUserLocale } = useCurrentUser()
  const languages = supportedLocalesStrings
  const [isLanguageAutocompleteOpen, setIsLanguageAutocompleteOpen] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState<AutocompleteOption>({
    id: userLocale,
    label: languages[userLocale],
  })

  const languagesOptions: AutocompleteOption[] = Object.keys(languages).map(locale => ({
    id: locale,
    label: languages[locale as SupportedLocale],
  }))

  const handleLanguageChange = (option: AutocompleteOption) => {
    updateUserLocale(option.id as SupportedLocale)
    setSelectedLanguage(option)
  }

  return (
    <GridBoxRow>
      <Box>
        <Label>{i18n.text('settings.language')}</Label>
        <ExplanationText>{i18n.text('general-settings.language.text')}</ExplanationText>
      </Box>
      <Autocomplete
        id="language-autocomplete"
        open={isLanguageAutocompleteOpen}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={languagesOptions}
        clearIcon={''}
        value={selectedLanguage}
        onOpen={() => setIsLanguageAutocompleteOpen(true)}
        onClose={() => setIsLanguageAutocompleteOpen(false)}
        onChange={(_, value) => {
          if (value) handleLanguageChange(value)
        }}
        popupIcon={
          <ArrowDownIcon
            sx={{
              width: '1rem',
              height: '1rem',
            }}
          />
        }
        renderInput={params => {
          return (
            <StyledAutocompleteTextField
              {...params}
              value={selectedLanguage}
              size="medium"
              disabled
              sx={{
                [`.${outlinedInputClasses.root}`]: {
                  boxShadow: isLanguageAutocompleteOpen
                    ? '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #1d7afd3d'
                    : 'none',
                  maxWidth: '32rem',
                  width: '100%',
                },
              }}
            />
          )
        }}
      />
    </GridBoxRow>
  )
}
