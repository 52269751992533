import React, { FC, forwardRef } from 'react'
import { Button, ButtonProps, styled } from '@mui/material'

interface IOutlinedStyledButtonProps extends ButtonProps {
  testId?: string
  children: React.ReactNode
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isActive?: boolean
}

const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  textTransform: 'none',
  color: theme.palette.SFIGreyLight[800],
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  background: !isActive ? theme.palette.SFIBase.white : theme.palette.SFIGreyLight[100],
  fontWeight: 600,
  fontSize: '14px',
  borderRadius: '8px',
  padding: theme.spacing(1.25, 1.5),
  boxShadow: `0px 1px 2px 0px ${theme.palette.SFIGreyLight[900]}0D`,
  lineHeight: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: theme.spacing(5),
  '&:hover': {
    background: theme.palette.SFIGreyLight[200],
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: `0px 0px 0px 4px ${theme.palette.SFIGreyLight[50]}`,
    background: theme.palette.SFIBase.white,
  },
}))

export const OutlinedStyledIconButton = styled(StyledButton)(({ theme }) => ({
  minWidth: theme.spacing(4.5),
  width: theme.spacing(4.5),
  maxHeight: theme.spacing(4.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const OutlinedStyledButton: FC<IOutlinedStyledButtonProps> = forwardRef(
  ({ children, testId, onClick, ...props }, ref) => {
    return (
      <StyledButton
        variant="contained"
        color="secondary"
        data-testid={testId}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {children}
      </StyledButton>
    )
  }
)
