import React from 'react'
import { Box, SelectProps, Typography, styled } from '@mui/material'
import { Control, Controller, FieldValues, Path, PathValue, UseFormSetValue, UseFormWatch } from 'react-hook-form'

import {
  AddButton,
  AddButtonText,
} from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { AlertInterval } from '../../../types/alertTypes'
import {
  CustomSwitch,
  SwitchLabel,
  SwitchWrapper,
} from '../../../../Shared/components/MUIComponents/update/NewDesignStyledSwitch'
import { DEFAULT_ALERT_RULE_WEEKDAY } from '../../../utils/alertTimeManipulation'
import { DEFAULT_MIDDAY_TIME } from '../../../../Shared/constants/timezone'
import { PlusIcon } from '../../../../Shared/components/icons'
import { StyledLabel } from '../AlertConfigurationModal.styled'
import { TimePeriodFormSection } from './TimePeriodFormSection'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

interface ITimeFormSectionProps<T extends FieldValues> {
  selectProps: SelectProps<string>
  control: Control<T>
  names: {
    isAlwaysOn: Path<T>
    alertIntervals: Path<T>
  }
  watch: UseFormWatch<T>
  setValue: UseFormSetValue<T>
}

export const TimeFormSection = <T extends FieldValues>({
  selectProps,
  names,
  control,
  watch,
  setValue,
}: ITimeFormSectionProps<T>) => {
  const { i18n } = useI18nContext()
  const isAlwaysOn = watch(names.isAlwaysOn)

  const existingTimeIntervals: AlertInterval[] = watch(names.alertIntervals)
  const defaultTimeInterval: AlertInterval = {
    fromDay: DEFAULT_ALERT_RULE_WEEKDAY,
    fromTime: DEFAULT_MIDDAY_TIME,
    toDay: DEFAULT_ALERT_RULE_WEEKDAY,
    toTime: DEFAULT_MIDDAY_TIME,
  }

  const addNewTimeInterval = () => {
    if (existingTimeIntervals) {
      setValue(names.alertIntervals, [...existingTimeIntervals, defaultTimeInterval] as PathValue<T, Path<T>>)
    }
  }

  return (
    <>
      <GridAreaBox>
        <StyledLabel>{i18n.text('rules.headers.time')}</StyledLabel>
        <SubText>{i18n.text('rules.time.explanation')}</SubText>
        <SwitchWrapper
          sx={{
            gridArea: 'b',
          }}
        >
          <Controller
            name={names.isAlwaysOn}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <CustomSwitch
                checked={value}
                color="secondary"
                onChange={onChange}
              />
            )}
          ></Controller>
          <SwitchLabel>{i18n.text('rules.time.always-on')}</SwitchLabel>
        </SwitchWrapper>
      </GridAreaBox>
      {!isAlwaysOn && (
        <>
          {existingTimeIntervals?.map((alertInterval, index) => (
            <TimePeriodFormSection
              key={`time-interval-${index}`}
              name={names.alertIntervals}
              index={index}
              timeInterval={alertInterval}
              selectProps={selectProps}
              control={control}
              watch={watch}
              setValue={setValue}
              isDisabled={index === 0 && existingTimeIntervals.length === 1}
            />
          ))}
          <AddButton
            disableRipple
            onClick={addNewTimeInterval}
          >
            <Box sx={{ display: 'flex' }}>
              <PlusIcon
                sx={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              />
            </Box>
            <AddButtonText>{i18n.text('rules.time.add-another-period')}</AddButtonText>
          </AddButton>
        </>
      )}
    </>
  )
}

const GridAreaBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas: `'a b'
                      'c .'`,
  justifyContent: 'flex-start',
  columnGap: '2rem',
  borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  paddingTop: '1rem',
}))

const SubText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  gridArea: 'c',
}))
