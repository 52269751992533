import { isEmpty, isNil, toLower } from 'ramda'

import {
  Commodity,
  DurationUnit,
  GqlAssetComponent,
  GqlAssetGroup,
  GqlAssetMachine,
  GqlAssetSensor,
  InputValues,
} from './types'
import { ID } from '../../../Shared/types/types'
import { StandbyAlert, StandbyAlertWithAsset } from '../Rules/standby/types'
import { Recipient } from '../../components/Recipient/types'
import { AssetComponent, AssetGroup, AssetMachine, Sensor } from "../../components/AssetSelector/types";
import { isSensorType } from "../../components/AssetSelector/utils";
import { validateRecipients } from '../Rules/utils'
import { AssetSelection } from '../../components/AssetSelector'

interface StandbyValidationSuccess {
  status: 'success'
  standbyAlert: StandbyAlert
}
interface ValidationError {
  status: 'failed'
  errors: string[]
}
type StandbyValidationResult = StandbyValidationSuccess | ValidationError

// Dropdowns require a unique key for every option, use this key when a 'none' option is required
export const NONE_KEY = '--NONE--' as ID
const MINUTE = 1
const HOUR = MINUTE * 60
const DAY = HOUR * 24

export const defaultStandbyAlert: StandbyAlertWithAsset = {
  id: NONE_KEY,
  enabled: true,
  resolvedEnabled: false,
  recipients: [],
  assetId: NONE_KEY,
  alertAfterMinutes: 30,
  silenceHours: 3,
  message: '',
  asset: {
    id: NONE_KEY,
    name: '',
  }
}

function serializeStandbyRecipients(recipients: Recipient[]): Recipient[] {
  return recipients
    .filter(rec => rec.destination)
    .map(rec => {
      if (rec.transport === 'email') {
        return {
          ...rec,
          destination: toLower(rec.destination),
        }
      }
      return rec
    })
}

export function getMinutes(duration: number, unit: DurationUnit) {
  const units = {
    minutes: MINUTE,
    hours: HOUR,
    days: DAY,
  }

  return duration * units[unit]
}

function getSelectedAssetId(asset: AssetSelection): ID | null {
  const { groupId, machineId, componentId } = asset
  if (!isNil(componentId) && componentId !== NONE_KEY) return componentId
  if (!isNil(machineId) && machineId !== NONE_KEY) return machineId
  if (groupId === NONE_KEY) return null
  return groupId
}

function findInAssetTree(groups: AssetGroup[], assetId: ID) {
  let group = NONE_KEY
  let machine = NONE_KEY
  let component = NONE_KEY

  groups.some(({ id: groupId, machines }) => {
    if (groupId === assetId) {
      group = groupId
      return true
    }
    return machines.some(({ id: machineId, components }) => {
      if (machineId === assetId) {
        group = groupId
        machine = machineId
        return true
      }
      return components.some(({ id: componentId }) => {
        if (componentId === assetId) {
          group = groupId
          machine = machineId
          component = componentId
          return true
        }
        return false
      })
    })
  })

  return {
    groupId: group,
    machineId: machine,
    componentId: component,
  }
}

export function scrollToTop() {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

export function standbyAlertFromState(
  id: string,
  formState: InputValues,
  allAssetGroups: AssetGroup[]
): StandbyValidationResult {
  const assetId = getSelectedAssetId(formState.asset)
  // only type checks, rest of the validation is in validateStandbyAlert
  if (isNil(assetId)) {
    return {
      status: 'failed',
      errors: ['alerts.standby.errors.no-machine'],
    }
  }

  const recipients = serializeStandbyRecipients(formState.recipients)
  const duration = getMinutes(formState.duration, formState.durationUnit)

  const standbyAlert: StandbyAlert = {
    id,
    enabled: true,
    resolvedEnabled: false,
    recipients,
    assetId,
    alertAfterMinutes: duration,
    silenceHours: formState.silenceHours,
    message: formState.message,
  }

  const validationErrors = validateStandbyAlert(standbyAlert, allAssetGroups, formState.asset)

  if (validationErrors.length > 0) {
    return {
      status: 'failed',
      errors: validationErrors,
    }
  }

  return {
    status: 'success',
    standbyAlert,
  }
}

export function stateFromStandbyAlert(assets: AssetGroup[], standbyAlert: StandbyAlert): InputValues {
  const alertAfterMinutes = standbyAlert.alertAfterMinutes
  const { time: duration, unit: durationUnit } = getDuration(alertAfterMinutes)

  const assetId = standbyAlert.assetId as ID

  // parse group/machine/component
  const asset = assetId
    ? findInAssetTree(assets, assetId)
    : {
      groupId: NONE_KEY,
      machineId: NONE_KEY,
      componentId: NONE_KEY,
    }

  return {
    duration,
    durationUnit,
    message: standbyAlert.message,
    asset,
    recipients: standbyAlert.recipients,
    default: standbyAlert.id === NONE_KEY,
    silenceHours: standbyAlert.silenceHours,
  }
}

function toLocalSensor(sensor: GqlAssetSensor): Sensor {
  return {
    id: sensor.id as ID,
    types: sensor.types.filter(isSensorType),
  }
}

export function validateStandbyAlert(standbyAlert: StandbyAlert, allAssetGroups: AssetGroup[], asset: AssetSelection): string[] {
  const errors = []
  const { groupId, machineId, componentId } = asset
  if (machineId === '--NONE--') {
    errors.push('alerts.standby.errors.no-machine')
  } else {
    const machineHasAvoidableStandby =
      allAssetGroups
        .find(group => group.id === groupId)?.machines
        .find(machine => machine.id === machineId)?.hasAvoidableStandby
    if (!machineHasAvoidableStandby && (componentId === '--NONE--')) {
      // TODO I18n alerts.standby.errors.no-component
      errors.push('alerts.standby.errors.no-component')
    }
  }

  if (standbyAlert.alertAfterMinutes < 5) {
    errors.push('alerts.standby.errors.minimum-duration')
  }

  if (standbyAlert.silenceHours < 0) {
    errors.push('alerts.standby.errors.silence-time')
  }

  const recipients = standbyAlert.recipients;
  if (isEmpty(recipients)) {
    errors.push('rules.errors.recipients')
  }
  const recipientErrors = validateRecipients(recipients)
  if (recipientErrors.length > 0) errors.push(...recipientErrors)

  if (standbyAlert.alertAfterMinutes > 10080) {
    errors.push('rules.errors.duration')
  }

  return errors
}

/**
 * Create list of components with avoidable standby mappings
 * @param gqlComponents List of assets that represent components from the API
 */
function createAssetComponents(gqlComponents: Readonly<GqlAssetComponent[]>): AssetComponent[] {
  const componentsWithAvoidableStandbyMapping = gqlComponents.filter(component => component.avoidableStandbyMappings.length > 0)
  return componentsWithAvoidableStandbyMapping.map(component => ({
    id: component.id as ID,
    name: component.name as string,
    payload: component.payload?.commodityType ? component.payload : { commodityType: 'electric-current' as Commodity },
    sensors: component.sensors.map(toLocalSensor)
  }))
}

/**
 * Create list of machines with avoidable standby mappings or with components that have avoidable standby
 * @param gqlMachines List of assets that represent machines from the API
 */
function createAssetMachines(gqlMachines: Readonly<GqlAssetMachine[]>): AssetMachine[] {
  return gqlMachines.flatMap(machine => {
    const components = createAssetComponents(machine.components)
    const hasAvoidableStandby = machine.avoidableStandbyMappings.length > 0
    if (!hasAvoidableStandby && components.length === 0) return []
    return ({
      id: machine.id as ID,
      name: machine.name as string,
      payload: machine.payload?.commodityType ? machine.payload : { commodityType: 'electric-current' as Commodity },
      sensors: machine.sensors.map(toLocalSensor),
      components,
      hasAvoidableStandby
    })
  })
}

/**
 * Create list of groups that contain machines with avoidable standby mappings or with components that have avoidable standby
 * @param gqlMachines List of assets that represent machines from the API
 */
export function createAssetGroups(gqlGroups: GqlAssetGroup[]): AssetGroup[] {
  return gqlGroups.flatMap(group => {
    const machines = createAssetMachines(group.machines)
    if (machines.length === 0) return []
    return({
      id: group.id as ID,
      name: group.name as string,
      payload: group.payload,
      machines: machines,
    })
  })
}

function getDuration(durationMinutes: number): { unit: InputValues['durationUnit']; time: number } {
  if (durationMinutes % DAY === 0) {
    return {
      unit: 'days',
      time: durationMinutes / DAY,
    }
  }

  if (durationMinutes % HOUR === 0) {
    return {
      unit: 'hours',
      time: durationMinutes / HOUR,
    }
  }

  return {
    unit: 'minutes',
    time: durationMinutes,
  }
}
