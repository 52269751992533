import React from 'react'
import styled from 'styled-components'

import { useI18nContext } from '../../contexts/i18nContext/I18nContext'

const Wrapper = styled.div``

const Heading = styled.h1``

const WhatHappened = styled.p``

type Props = {
  title?: React.ReactNode
  message: React.ReactNode
}

/** @deprecated do not use */
export default function GenericError({ title, message }: Props) {
  const { i18n } = useI18nContext()

  return (
    <Wrapper>
      <Heading>{title ?? i18n.text('errors.generic.title')}</Heading>
      <WhatHappened>{message}</WhatHappened>
    </Wrapper>
  )
}
