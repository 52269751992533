import { FC } from 'react'

import { ModuleConfiguration } from '../ModuleConfiguration'
import { StyledDialogModal } from '../../../../../Shared/components/MUIComponents/StyledDialogModal'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

interface EditModuleSettingsDialogProps {
  handleCloseEditModuleSettingsDialog: () => void
}

export const EditModuleSettingsDialog: FC<EditModuleSettingsDialogProps> = ({
  handleCloseEditModuleSettingsDialog,
}) => {
  const { i18n } = useI18nContext()
  return (
    <StyledDialogModal
      title={i18n.text(`reports.tracking.configuration.module.configuration`)}
      subtitle={i18n.text(`reports.tracking.configuration.module.configuration.setUp`)}
      isOpen={true}
      isLoading={false}
      onClose={handleCloseEditModuleSettingsDialog}
      height="responsive"
      maxWidth={false}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '750px',
          },
        },
      }}
    >
      <ModuleConfiguration></ModuleConfiguration>
    </StyledDialogModal>
  )
}
