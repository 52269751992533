import { Box } from '@mui/material'
import { FC } from 'react'

import { ModuleTitleInputProps } from '../types'
import { StyledInputFieldHeader, StyledTooltipWithIcon } from '../Common'
import { StyledTextField } from '../../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const ModuleTitleInput: FC<ModuleTitleInputProps> = ({ title, handleTitleChange }) => {
  const { i18n } = useI18nContext()
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '30% 70%',
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.125rem', paddingRight: '1rem' }}
        >
          <StyledInputFieldHeader sx={{ alignContent: 'center' }}>
            {i18n.text(`reports.tracking.configuration.module.title`)}
          </StyledInputFieldHeader>
          <StyledTooltipWithIcon
            title={i18n.text('reports.tracking.configuration.tooltip.subTitle')}
          ></StyledTooltipWithIcon>
        </Box>
        <StyledTextField
          size="small"
          inputProps={{ maxLength: 30 }}
          value={title}
          onChange={handleTitleChange}
          sx={{ maxWidth: '100%' }}
          placeholder={i18n.text('reports.tracking.configuration.placeholder.moduleSubtitle')}
        >
          {' '}
        </StyledTextField>
      </Box>
    </>
  )
}
