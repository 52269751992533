import * as p from 'prop-types'
import React, { Component } from 'react'
import { Input, MenuItem, Select, styled } from '@mui/material'

import countries from '../../constants/countries'

const Wrapper = styled('div')({
  display: 'flex',
  '& > div:first-of-type': {
    width: 'auto',
    margin: '0em 1em 0 0',
  },
  div: {
    button: {
      textAlign: 'left',
      width: 'auto',
      padding: '1em',
      '& > div': {
        paddingRight: '3em',
      },
      '& > div > span': {
        display: 'none',
      },
    },
    input: {
      padding: '1em',
    },
  },
  ul: {
    textAlign: 'left',
    width: '10em',
  },
})

const StyledDropdownSmall = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBase.white,
  display: 'flex',
  height: '44px',
  minWidth: '20rem',
  '&::before': {
    borderBottom: 'none',
  },
}))

const StyledInput = styled(Input)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBase.white,
  fontSize: '0.875em',
  height: '44px',
  '> input': {
    padding: 0,
  },
  '&::before': {
    borderColor: 'rgb(220, 220, 220)',
  },
}))

const countryCodeOptions = countries.map(({ name, dialCode }) => ({
  key: dialCode,
  option: `${dialCode} ${name}`,
}))

export default class PhoneInput extends Component {
  constructor(props) {
    super(props)

    const { dialCodeValue, phoneNumberValue } = this.props

    this.state = {
      dialCode: dialCodeValue,
      phoneNumber: phoneNumberValue,
    }

    this.onDialCodeSelect = this.onDialCodeSelect.bind(this)
    this.onNumberChange = this.onNumberChange.bind(this)
  }

  onDialCodeSelect(dialCode) {
    const { onDialCodeSelect } = this.props
    this.setState(() => ({ dialCode }))

    return onDialCodeSelect(dialCode)
  }

  onNumberChange(event) {
    const phoneNumber = event.target.value
    const { onNumberChange } = this.props

    this.setState(() => ({ phoneNumber }))

    return onNumberChange(phoneNumber)
  }

  render() {
    const { disabled, placeholder } = this.props

    const { dialCode, phoneNumber } = this.state

    return (
      <Wrapper>
        <StyledDropdownSmall
          variant="standard"
          value={dialCode ?? countryCodeOptions[0].key}
          options={countryCodeOptions}
          disabled={disabled}
          onChange={this.onDialCodeSelect}
        >
          {countryCodeOptions.map((country, index) => (
            <MenuItem
              key={country.key + index}
              value={country.key}
            >
              {country.option}
            </MenuItem>
          ))}
        </StyledDropdownSmall>
        <StyledInput
          id="phone-number-input"
          type="number"
          placeholder={placeholder}
          value={phoneNumber || ''}
          disabled={disabled}
          onChange={this.onNumberChange}
        />
      </Wrapper>
    )
  }
}

PhoneInput.propTypes = {
  onDialCodeSelect: p.func.isRequired,
  onNumberChange: p.func.isRequired,
  dialCodeValue: p.string,
  phoneNumberValue: p.string,
  disabled: p.bool,
  placeholder: p.string,
}

PhoneInput.defaultProps = {
  dialCodeValue: '+31',
  phoneNumberValue: '',
  disabled: false,
  placeholder: 'Phone number',
}
