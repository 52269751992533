import { SvgIconProps } from "@mui/material"

export enum OnboardingPath {
  COMPANY_DETAILS = 'company-details',
  INVITE_MEMBERS = 'invite-members',
  TECHNICAL_INFO = 'technical-info',
  INSTALLATION = 'installation',
}

export interface IStage {
  key: OnboardingPath
  icon: (props: SvgIconProps) => JSX.Element
  title: string
  subtitle: string
}