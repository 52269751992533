import React, { FC } from 'react'
import { Box, Typography, styled } from '@mui/material'

import { DEFAULT_TIMEZONE } from '../../../../../Shared/constants/timezone'
import { ExplanationText, GridBoxRow, Label } from '..'
import { LanguageAutocomplete } from './LanguageAutocomplete'
import { useCurrentUser } from '../../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const LanguageAndRegion: FC = () => {
  const { i18n } = useI18nContext()
  const { selectedCustomer } = useCurrentUser()

  return (
    <Box>
      <LanguageAutocomplete />
      <GridBoxRow>
        <Box>
          <Label>{i18n.text('general-settings.time-zone.title')}</Label>
          <ExplanationText>{i18n.text('general-settings.time-zone.text')}</ExplanationText>
        </Box>
        <TimeZoneText>{selectedCustomer?.timeZone || DEFAULT_TIMEZONE}</TimeZoneText>
      </GridBoxRow>
    </Box>
  )
}

const TimeZoneText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[700],
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '1.5rem',
}))
