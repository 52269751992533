import { FC, useEffect, useState } from 'react'

import { AssetTreeChildNode } from '../../../Shared/components/MUIComponents/update/AssetTree/StyledAssetTreeChildNode'
import { useOEEContext } from '../../context/OEEContext'

type AssetTreeSelectionProps = {
  label: string
  id: string
  onItemSelect: (id: string, isChecked: boolean) => void
}

const AssetTreeItem: FC<AssetTreeSelectionProps> = ({ label, id, onItemSelect }) => {
  const { selectedMachines } = useOEEContext()
  const [isMachineChecked, setIsMachineChecked] = useState(false)

  useEffect(() => {
    setIsMachineChecked(selectedMachines.includes(id))
  }, [id, selectedMachines])

  const handleItemClick = () => {
    onItemSelect(id, !isMachineChecked)
  }

  return (
    <AssetTreeChildNode
      item={{ id, name: label }}
      handleClick={handleItemClick}
      hasCheckbox
      isSelected={isMachineChecked}
    />
  )
}

export default AssetTreeItem
