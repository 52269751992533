import React, { FC, useEffect, useMemo, useState } from 'react'
import { ApexOptions } from 'apexcharts'
import { Box, styled, useTheme } from '@mui/material'
import { DateTime, Interval } from 'luxon'

import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import { AirTemplateGraph } from './TemplateGraph'
import { ApexWrapper } from '../ApexWrapper'
import { ChartToolsItem } from '../../../Shared/components/MUIComponents/update/ChartToolsDropdown'
import { FitHeightBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { GraphId, SwitchUnit } from '../../types/compressed-air_types'
import { checkIsTeltonika } from '../../utils/utils'
import { getFlowOptions } from '../../utils/getFlowOptions'
import { getMeanAnnotations } from '../../utils/annotations'
import { setToolbarItems } from '../../utils/setToolbarItems'
import { useApexBeforeZoom } from '../../hooks/useApexBeforeZoom'
import { useCAChartToolsContext } from '../../context/CAChartToolsContext'
import { useCompressedAirContext } from '../../context/CompressedAirContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOnZoomChange } from '../../hooks/useOnZoomChange'
import { useFeature } from 'flagged'

enum GraphType {
  temperature = 'temperature',
  pressure = 'pressure',
  flow = 'flow',
}

const GraphsGridWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  gap: theme.spacing(1.5),
  gridTemplateRows: 'max-content',
}))

const FullLengthSectionBoxWrapper = styled(FitHeightBoxWrapper)({
  gridColumn: '1 / span 2',
  height: 'fit-content',
})

export const AirFlowGraph: FC = () => {
  const {
    assets,
    seriesData,
    queryParams,
    series,
    switchUnit,
    previousPeriodSeries,
    previousPeriodFromToValues,
    fromToValues,
    outsideOperatingHoursFlowSeries,
    fetching,
    handleCompareDatesChange,
    handleDatesChange,
    updatedOptionsFlag,
    isMoreThanMonth,
    setUpdatedOptionsFlag,
  } = useCompressedAirContext()
  const { i18n } = useI18nContext()
  const { from, to, sid } = queryParams
  const theme = useTheme()
  const { sendEvent } = useAnalytics()

  // TODO (Sabina): Remove when not needed
  const isOutsideOperatingHoursVisible = !!useFeature('HAS_OUTSIDE_OPERATING_HOURS')
  
  const interval: Interval =
    fromToValues.startDate && fromToValues.endDate
      ? Interval.fromDateTimes(fromToValues.startDate, fromToValues.endDate)
      : Interval.fromDateTimes(DateTime.now().startOf('day'), DateTime.now().endOf('day'))

  const beforeZoom = useApexBeforeZoom(interval)

  const {
    showMinMaxRange,
    onShowMinMaxRangeToggle,
    showMean,
    onShowMeanToggle,
    decimal,
    onDecimalToggle,
    showOutsideOperatingHoursInsights,
    onShowOutsideOperatingHoursInsightsToggle,
  } = useCAChartToolsContext()

  const isPreviousPeriodEnabled = !!previousPeriodSeries?.flow && previousPeriodSeries.flow.length > 0
  const shouldUseRangeArea = sid.length === 1
  const shouldUseOutsideOperatingHours = sid.length === 1 && !isPreviousPeriodEnabled && !isMoreThanMonth
  const hasShowMinMaxRangeEnabled = shouldUseRangeArea && showMinMaxRange
  const hasShowOutsideOperatingHoursInsightsEnabled = shouldUseRangeArea && showOutsideOperatingHoursInsights
  const onZoomChange = useOnZoomChange({
    fromToValues,
    previousPeriodFromToValues,
    handleDatesChange,
    handleCompareDatesChange,
    sendEvent,
  })

  const getFlowOptionsArgs = () => ({
    i18n,
    theme,
    shouldUseRangeArea: showMinMaxRange && shouldUseRangeArea,
    hasShowMinMaxRangeEnabled,
    hasShowOutsideOperatingHoursInsightsEnabled,
    from,
    to,
    seriesData,
    previousPeriodFromToValues,
    decimal,
    sendEvent,
    beforeZoom,
    onZoomChange,
  })

  const sensorLocationsWithTemperatureAndPressure = useMemo(() => {
    const sensorLocations = assets.flatMap(asset => asset.sensorLocations)
    return sensorLocations.filter(checkIsTeltonika)
  }, [assets])

  const someSensorLocationWithTemperatureAndPressure = sensorLocationsWithTemperatureAndPressure.length > 0

  const previousPeriodTrendsSeries = useMemo(
    () =>
      previousPeriodSeries
        ? {
            flow: previousPeriodSeries.flow,
            temperature: previousPeriodSeries.temperature,
            pressure: previousPeriodSeries.pressure,
          }
        : {
            flow: [],
            temperature: [],
            pressure: [],
          },
    [previousPeriodSeries]
  )

  const allSeries = useMemo(
    () => ({
      flow:
        hasShowOutsideOperatingHoursInsightsEnabled && !fetching
          ? [...series.flow, ...previousPeriodTrendsSeries.flow, ...outsideOperatingHoursFlowSeries]
          : [...series.flow, ...previousPeriodTrendsSeries.flow],
      pressure: [...series.pressure, ...previousPeriodTrendsSeries.pressure],
      temperature: [...series.temperature, ...previousPeriodTrendsSeries.temperature],
    }),
    [series, previousPeriodTrendsSeries, hasShowOutsideOperatingHoursInsightsEnabled, fetching]
  )

  const meanFlowAnnotations = useMemo(() => {
    return getMeanAnnotations({
      showMean,
      series: allSeries.flow,
      i18n,
      theme,
      unitName: switchUnit,
      decimal,
    })
  }, [showMean, allSeries.flow, switchUnit, i18n, theme, decimal])

  const meanTemperatureAnnotations = useMemo(() => {
    return getMeanAnnotations({
      showMean: showMean && someSensorLocationWithTemperatureAndPressure,
      series: allSeries.temperature,
      i18n,
      theme,
      unitName: SwitchUnit.celsius,
      decimal,
    })
  }, [showMean, someSensorLocationWithTemperatureAndPressure, allSeries.temperature, i18n, theme, decimal])

  const meanPressureAnnotations = useMemo(() => {
    return getMeanAnnotations({
      showMean: showMean && someSensorLocationWithTemperatureAndPressure,
      series: allSeries.pressure,
      i18n,
      theme,
      unitName: SwitchUnit.bar,
      decimal,
    })
  }, [showMean, someSensorLocationWithTemperatureAndPressure, allSeries.pressure, i18n, theme, decimal])

  const annotations: {
    flow: YAxisAnnotations[]
    temperature: YAxisAnnotations[]
    pressure: YAxisAnnotations[]
  } = useMemo(() => {
    return {
      flow: [...meanFlowAnnotations],
      temperature: [...meanTemperatureAnnotations],
      pressure: [...meanPressureAnnotations],
    }
  }, [meanFlowAnnotations])

  const [flowOptions, setFlowOptions] = useState<ApexOptions>(
    getFlowOptions({
      graphId: GraphId.FlOW,
      isFlow: true,
      unitName: SwitchUnit.m3m,
      type: GraphType.flow,
      annotations: annotations.flow,
      ...getFlowOptionsArgs(),
    })
  )
  const [pressureOptions, setPressureOptions] = useState<ApexOptions>(
    getFlowOptions({
      graphId: GraphId.PRESSURE,
      isFlow: false,
      unitName: SwitchUnit.bar,
      type: GraphType.pressure,
      annotations: annotations.pressure,
      ...getFlowOptionsArgs(),
    })
  )
  const [temperatureOptions, setTemperatureOptions] = useState<ApexOptions>(
    getFlowOptions({
      graphId: GraphId.TEMPERATURE,
      isFlow: false,
      unitName: SwitchUnit.celsius,
      type: GraphType.temperature,
      annotations: annotations.temperature,
      ...getFlowOptionsArgs(),
    })
  )

  useEffect(() => {
    setUpdatedOptionsFlag(true)
  }, [someSensorLocationWithTemperatureAndPressure, showOutsideOperatingHoursInsights])

  useEffect(() => {
    if (updatedOptionsFlag) {
      setFlowOptions(
        getFlowOptions({
          graphId: GraphId.FlOW,
          isFlow: true,
          type: GraphType.flow,
          unitName: switchUnit,
          annotations: annotations.flow,
          ...getFlowOptionsArgs(),
        })
      )
      setPressureOptions(
        getFlowOptions({
          graphId: GraphId.PRESSURE,
          isFlow: false,
          type: GraphType.pressure,
          unitName: SwitchUnit.bar,
          annotations: annotations.pressure,
          ...getFlowOptionsArgs(),
        })
      )
      setTemperatureOptions(
        getFlowOptions({
          graphId: GraphId.TEMPERATURE,
          isFlow: false,
          type: GraphType.temperature,
          unitName: SwitchUnit.celsius,
          annotations: annotations.temperature,
          ...getFlowOptionsArgs(),
        })
      )
      setUpdatedOptionsFlag(false)
    }
  }, [updatedOptionsFlag])

  const toolbarItems: ChartToolsItem[] = useMemo(
    () =>
      setToolbarItems({
        minMaxRange: {
          isChecked: hasShowMinMaxRangeEnabled,
          isDisabled: !shouldUseRangeArea,
          onToggle: () => {
            onShowMinMaxRangeToggle(!showMinMaxRange)
            setUpdatedOptionsFlag(true)
          },
        },
        isOutsideOperatingHoursVisible,
        outsideOperatingHours: {
          isChecked: hasShowOutsideOperatingHoursInsightsEnabled,
          isDisabled: !shouldUseOutsideOperatingHours,
          isCompareDatesOn: isPreviousPeriodEnabled,
          isMoreThanMonth,
          onToggle: () => {
            onShowOutsideOperatingHoursInsightsToggle(!showOutsideOperatingHoursInsights)
          },
        },
        decimalChangeArgs: {
          selected: decimal,
          label: `${i18n.text('chart.tools.decimal-label')} : ${decimal}`,
          onToggle: (value: number) => {
            onDecimalToggle(value)
            setUpdatedOptionsFlag(true)
          },
        },
        showMean: {
          isChecked: showMean,
          onToggle: () => {
            onShowMeanToggle(!showMean)
            setUpdatedOptionsFlag(true)
          },
        },
      }),
    [
      isPreviousPeriodEnabled,
      shouldUseRangeArea,
      shouldUseOutsideOperatingHours,
      showOutsideOperatingHoursInsights,
      hasShowMinMaxRangeEnabled,
      hasShowOutsideOperatingHoursInsightsEnabled,
      decimal,
      showMean,
    ]
  )

  return (
    <GraphsGridWrapper>
      <FullLengthSectionBoxWrapper>
        <ApexWrapper>
          <AirTemplateGraph
            chartData={{
              series: allSeries.flow,
              options: flowOptions,
              type: 'rangeArea',
              id: GraphId.FlOW,
              height: someSensorLocationWithTemperatureAndPressure ? 250 : undefined,
            }}
            texts={{
              errorKey: 'error.sensor.data-fetch',
              header: 'air.graphs.air-flow-header',
              tooltip: 'compressed-air.nm3-info',
            }}
            isLoading={fetching}
            unitSwitchData={{
              units: [SwitchUnit.m3m, SwitchUnit.cfm],
            }}
            toolbarItems={toolbarItems}
          />
        </ApexWrapper>
      </FullLengthSectionBoxWrapper>
      {someSensorLocationWithTemperatureAndPressure && (
        <>
          <FitHeightBoxWrapper>
            <AirTemplateGraph
              chartData={{
                series: allSeries.pressure,
                options: pressureOptions,
                type: 'rangeArea',
                id: GraphId.PRESSURE,
                height: 200,
                isIconHidden: true,
              }}
              texts={{
                errorKey: 'error.sensor.data-fetch',
                header: 'air.graphs.air-pressure-header',
              }}
              isLoading={fetching}
              hasToolbar={false}
            />
          </FitHeightBoxWrapper>
          <FitHeightBoxWrapper>
            <AirTemplateGraph
              chartData={{
                series: allSeries.temperature,
                options: temperatureOptions,
                type: 'rangeArea',
                id: GraphId.TEMPERATURE,
                height: 200,
                isIconHidden: true,
              }}
              isLoading={fetching}
              texts={{
                errorKey: 'error.sensor.data-fetch',
                header: 'air.graphs.air-temperature-header',
              }}
              hasToolbar={false}
            />
          </FitHeightBoxWrapper>
        </>
      )}
    </GraphsGridWrapper>
  )
}
