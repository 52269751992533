import React from 'react'
import {
  Box,
  BoxProps,
  Paper,
  PaperProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableProps,
  TableRow,
  styled,
} from '@mui/material'

import CustomTableBody from './CustomTableBody'
import CustomTableHeader from './CustomTableHeader'
import MUILoader from '../../Loader'
import { CustomTablePagination } from './CustomTablePagination'
import { ICustomTableColumn, ItemsAlignment } from '../../../../types/types'
import { NoDataFoundSlot } from '../NoDataFoundSlot'
import {
  NoDataFoundSlotProps,
  TableContentBodyProps,
  TableHeaderProps,
  TablePaginationProps,
} from '../../../../types/customTableTypes'

/**
 * @description - CustomTable is a table to list some data in a proper way
 * @param {ICustomTableColumn<T>} columns - A list of columns
 * @param {'id' | keyof T} ICustomTableColumn[id] - A key of each column. It is required so that it can be associated with specific value from each row
 * @param {string} ICustomTableColumn[label] - A name of each column
 * @param {string} ICustomTableColumn[tooltipText] - If set, there will be an info icon next to a column's label with a tooltip with a provided text
 * @param {number | string} ICustomTableColumn[minWidth] - A minimum width of a column
 * @param {number | string} ICustomTableColumn[width] - A fixed width of a column
 * @param {'right' | 'left' | 'center'} ICustomTableColumn[align] - It allows to align column label to either side
 * @param {boolean} ICustomTableColumn[isBoldText] - If true, a label of a column is darker and bolder
 * @param {function(item: T[keyof T], id?: string): React.ReactNode} ICustomTableColumn[children] - If set, instead of a simple text in a row a developer can add customized component
 * @param {function(value: number): string} ICustomTableColumn[format] - A function to format a number if applicable
 *
 * @param {T[]} rows - An array of data to be presented
 * @param {number | string} maxHeight - Max height of the table container. Its default value is 440px
 * @param {boolean} isHeaderSticky - If true, table's header always locates on top
 * @param {boolean} isLoading - If true, the loader will be rendered instead of the CustomTableBody component
 *
 * @param {Object} header - A bunch of properties required for the CustomTableHeader component (see {@link CustomTableHeader})
 * @param {Object} body - A bunch of properties required for the CustomTableBody component (see {@link CustomTableBody})
 * @param {Object} pagination - A bunch of properties required for the CustomTablePagination component (see {@link CustomTablePagination})
 * @param {Object} noDataSlot - A bunch of properties required for the CustomTablePagination component (see {@link NoDataFoundSlot})
 * @param {Object} slotProps - Additional props in case a developer wants to add something extra to the TableContainer
 */

interface ICustomTableProps<T> {
  columns: ICustomTableColumn<T>[]
  rows: T[]
  maxHeight?: number | string
  isHeaderSticky?: boolean
  header?: TableHeaderProps
  body?: TableContentBodyProps
  pagination?: TablePaginationProps
  noDataSlot?: NoDataFoundSlotProps
  isLoading?: boolean
  slotProps?: {
    tableWrapper?: PaperProps
    table?: TableProps
  }
}

export function CustomTable<T extends { id: string }>({
  columns,
  rows,
  maxHeight = '440px',
  slotProps,
  isHeaderSticky = true,
  isLoading = false,
  pagination,
  header,
  body,
  noDataSlot,
}: ICustomTableProps<T>) {
  const tableBody = () => {
    if (isLoading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length}>
              <LoaderWrapper>
                <MUILoader margin="0" />
              </LoaderWrapper>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
    if (rows.length === 0 && noDataSlot) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length}>
              <NoDataFoundSlot {...noDataSlot} />
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
    return (
      <CustomTableBody
        rows={rows}
        columns={columns}
        {...body}
      />
    )
  }

  return (
    <TableWrapper {...slotProps?.tableWrapper}>
      <TableContainer sx={{ maxHeight }}>
        <Table
          stickyHeader={isHeaderSticky}
          {...slotProps?.table}
        >
          <CustomTableHeader
            columns={columns}
            {...header}
          />
          {tableBody()}
        </Table>
        {!!pagination && rows.length > 0 && <CustomTablePagination {...pagination} />}
      </TableContainer>
    </TableWrapper>
  )
}

interface IInfoLabelWrapperProps extends BoxProps {
  alignment: ItemsAlignment
}

export const InfoLabelWrapper = styled((props: IInfoLabelWrapperProps) => {
  const { alignment, ...other } = props
  return <Box {...other} />
})(({ alignment }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  justifyContent: alignment,
}))

const TableWrapper = styled(Paper)({
  width: '100%',
  overflow: 'hidden',
  marginBottom: '0.75rem',
  borderRadius: '12px',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
})

const LoaderWrapper = styled(Box)({
  height: '16rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
