import { descend, prop } from 'ramda'

import { AssetGql, BridgeGql, isBridgeSensor } from './types'

const mapBridgeToSensorData = (bridgeData: BridgeGql, assets: ReadonlyArray<AssetGql>) => {
  const { seenSensors } = bridgeData.bridgify

  if (Array.isArray(seenSensors)) {
    return seenSensors
      .filter(isBridgeSensor)
      .flatMap(sensor => {
        const matchingAsset = assets.find(a =>
          a.sensors.some(s => s.externalId.toUpperCase() === sensor.id.toUpperCase())
        )
        return matchingAsset
          ? {
            ...sensor,
            asset: matchingAsset.name,
          }
          : []
      })
      .sort(descend(prop('signal')))
  }
  return []
}

export default mapBridgeToSensorData
