import { AutocompleteOption } from '../../Shared/types/types'
import { CompressedAirAssetTreeFragment } from '../../Shared/graphql/codegen'
import { IExtendedCompressedAirAsset } from '../../CompressedAir/types/compressed-air_types'
import { getCompressedAirFlowSystemImages } from '../../CompressedAir/utils/getImages'

type MapCompressorsArgs = {
  asset: IExtendedCompressedAirAsset
  isNewCompressor: boolean
}

export function mapCompressedAirSystems(groups: CompressedAirAssetTreeFragment[]) {
  return groups.flatMap(group =>
    group.assets.reduce<IExtendedCompressedAirAsset[]>((result, asset) => {
      if (asset.sensorLocations.length > 0 && asset.compressedAirFlowSystem) {
        result.push({
          ...asset,
          images: getCompressedAirFlowSystemImages(asset).map(img => img.id),
        } as IExtendedCompressedAirAsset)
      }
      return result
    }, [])
  )
}

export function mapCompressors({ asset, isNewCompressor }: MapCompressorsArgs) {
  const defaultCompressor = {
    label: '',
    id: '',
  }

  if (
    !asset.compressedAirFlowSystem?.compressedAirSystemLinkedAssets ||
    asset.compressedAirFlowSystem?.compressedAirSystemLinkedAssets?.length === 0
  ) {
    return [defaultCompressor]
  }

  const result = asset.compressedAirFlowSystem?.compressedAirSystemLinkedAssets.map(compressor => ({
    id: compressor.id,
    label: compressor.asset.name,
  }))

  if (isNewCompressor) {
    result.push(defaultCompressor)
  }
  return result
}

export function mapMainPipes(asset: IExtendedCompressedAirAsset) {
  return asset.sensorLocations.reduce<AutocompleteOption[]>((acc, location) => {
    if (location.isSelectedForEnergyEfficiency) {
      acc.push({
        label: location.name,
        id: location.id,
      })
    }
    return acc
  }, [])
}
