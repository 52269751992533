import { Box, Typography, Select, MenuItem, TextField, Theme } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import { FormInputs } from '../EditAlertDialog';
import { useI18nContext } from '../../../../../../Shared/contexts/i18nContext/I18nContext';

type MeasurementCountProps = {
  theme: Theme;
  control: Control<FormInputs, any>;
  errors: any;
};

export default function MeasurementCount({ control, theme, errors }: MeasurementCountProps) {
  const { i18n } = useI18nContext();
  return (
    <Box>
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <Controller
        name="measurementCount"
        control={control}
        render={({ field: { onChange, value, ...rest } }) => {
          return (
            <TextField
              {...rest}
              value={value}
              label={i18n.text('pdm.configure.alert.dialog.measurementCount.label')}
              inputProps={{ min: 0 }}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                return (
                  onChange(value)
                )
              }}
              type="number"
              placeholder={i18n.text('pdm.configure.alert.dialog.measurement.count.placeholder')}
              error={!!errors.measurementCount}
              fullWidth
            />
          )
        }} />
        <Typography margin={theme.spacing(2)}>{i18n.text('pdm.configure.alert.dialog.measurementCount.per')}</Typography>
        <Controller
        name="measurementCountInterval"
        control={control}
        defaultValue='hour'
        render={({ field }) => {
          return(
            <Select
            {...field}
            labelId="selecte measurements count interval"
            defaultValue={'hour'}
            sx={{
              width: '7rem',
            }}
            variant='outlined'
            >
              <MenuItem value="hour">{i18n.text('pdm.configure.alert.dialog.measurementCount.hour')}</MenuItem>
              <MenuItem value="day">{i18n.text('pdm.configure.alert.dialog.measurementCount.day')}</MenuItem>
              <MenuItem value="week">{i18n.text('pdm.configure.alert.dialog.measurementCount.week')}</MenuItem>
            </Select>
          )
        }}
        />
      </Box>
      <Typography
        variant='caption'
        color='error'
        sx={{
          marginLeft: theme.spacing(2),
        }}
      >{errors.measurementCount && errors.measurementCount?.message}</Typography>
    </Box>
  );
}
