import ReactApexChart from 'react-apexcharts'
import { DateTime, Interval } from 'luxon'
import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react'

import { ApexWrapper } from '../ApexWrapper'
import { ElectricityDataSeries, ValuesModifier } from '../../types'
import { getCurrencyFormatter } from '../../../Shared/utils/formatCurrency'
import { useApexBeforeZoom } from '../../hooks/useApexBeforeZoom'
import { useChartToolsContext } from '../../context/ChartToolsContext'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useEnergyChartOptions } from './energyChartOptions'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

export type EnergyChartProps = {
  interval: Interval
  data: ElectricityDataSeries
  largestMeasurement: number
  valuesModifier: ValuesModifier
  showingCosts: boolean
  loading: boolean
  showCompare: boolean
  showWaste: boolean
  handleZoomChange: (chart: unknown, changed: { xaxis: { min: number; max: number } }) => void
  tooltipFormatter: (value: number, options: unknown) => string
}

const EnergyChart: FC<EnergyChartProps> = ({
  data,
  valuesModifier,
  interval,
  showingCosts,
  handleZoomChange,
  tooltipFormatter,
  loading,
  showCompare,
  showWaste,
}) => {
  const [responsiveHeight, setResponsiveHeight] = useState(0)
  const [responsiveGraphWidth, setReponsiveGraphWidth] = useState(0)
  const { i18n, userLocale } = useI18nContext()
  const { decimal } = useChartToolsContext()
  const { customerCurrency } = useCurrentUser()
  const currencyFormatter = useMemo(() => getCurrencyFormatter(userLocale, customerCurrency.isocode), [userLocale])

  const earliestDate = useMemo(() => {
    let earliest = interval.start.toMillis()
    if (data) {
      const firstSeries = data[0]
      if (firstSeries) {
        const firstPoint = firstSeries.data[0]
        if (firstPoint) {
          earliest = DateTime.fromISO(firstPoint.x).toMillis()
        }
      }
    }
    return earliest
  }, [data, interval.start])

  const totalsFormatter = useCallback(
    v =>
      showingCosts ? currencyFormatter(Math.round(v), 0) : v ? i18n.number(v, { maximumFractionDigits: decimal }) : '0',
    [showingCosts, i18n, decimal, showCompare]
  )

  const beforeZoom = useApexBeforeZoom(interval)

  const nrOfCategories = data[0]?.data?.length || 1
  const columnWidth = `${Math.min(10 * +nrOfCategories, 75)}%` // Fixes SMA-848

  const options = useEnergyChartOptions({
    loading,
    earliestDate,
    interval,
    tooltipFormatter,
    handleZoomChange,
    beforeZoom,
    valuesModifier,
    showingCosts,
    totalsFormatter,
    showCompare,
    columnWidth,
    currencyFormatter,
    decimal,
  })

  useLayoutEffect(() => {
    const handleResize = () => {
      const el = document.getElementById('chart-wrapper')
      const graphHeight = el?.getBoundingClientRect().height || 0
      setResponsiveHeight(graphHeight)
    }
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setResponsiveHeight])

  useLayoutEffect(() => {
    const handleChange: ResizeObserverCallback = entries => {
      for (const entry of entries) {
        if (entry.contentRect) {
          const graphWidth = entry.contentRect.width
          setReponsiveGraphWidth(graphWidth)
        }
      }
    }
    const el = document.getElementById('chart-wrapper')
    const observer = new ResizeObserver(handleChange)
    if (el) {
      observer.observe(el)
    }
    return () => {
      observer.disconnect()
    }
  })

  // This is a workaround to force the chart to rerender when the data and options change
  // Otherwise stacking and totals break when toggling compare
  const getChart = () => (
    <ReactApexChart
      key={Math.random()}
      type="bar"
      options={options}
      series={data as ApexAxisChartSeries}
      height={responsiveHeight}
      width={responsiveGraphWidth}
    />
  )

  return <ApexWrapper>{getChart()}</ApexWrapper>
}

export { EnergyChart }
