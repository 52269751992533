import React from 'react'
import { TableBody, TableRow } from '@mui/material'

import { CustomTableItem } from '../CustomTableItem'
import { ICustomTableColumn } from '../../../../../types/types'
import { StyledTableCell } from '../StyledTableCell'
import { TableContentBodyProps } from '../../../../../types/customTableTypes'

/**
 * @description - CustomTableBody is a table body where all content is present
 * @param {ICustomTableColumn<T>} columns - A list of columns
 * @param {T[]} rows - An array of data to be presented
 * @param {'even' | 'odd'} rowColored - Determines what rows have to be colored
 * @param {Object} slotProps - Additional props in case a developer wants to add something extra to the table body
 */

interface ICustomTableBodyProps<T> extends TableContentBodyProps {
  rows: T[]
  columns: ICustomTableColumn<T>[]
}

function CustomTableBody<T extends { id: string }>({
  columns,
  rows,
  rowColored = 'even',
  slotProps,
}: ICustomTableBodyProps<T>) {
  return (
    <TableBody {...slotProps?.tableBody}>
      {rows.map((row, index) => {
        return (
          <TableRow
            key={index}
            sx={{
              [`&:nth-of-type(${rowColored})`]: {
                background: theme => theme.palette.SFIGreyLight[50],
              },
              '&:hover': {
                background: theme => theme.palette.SFIGreyLight[50],
              },
            }}
          >
            {columns.map(column => {
              const value = row[column.id]
              return column.children ? (
                <StyledTableCell key={column.id as string}>{column.children(value, row.id)}</StyledTableCell>
              ) : (
                <CustomTableItem
                  key={column.id as string}
                  item={value}
                  column={column}
                />
              )
            })}
          </TableRow>
        )
      })}
    </TableBody>
  )
}

export default CustomTableBody
