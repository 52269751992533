import createReducer from 'create-reducer-map'
import { Map, fromJS } from 'immutable'

import { ACCESS_TOKEN_RECEIVED, GET_ACCESS_TOKEN } from '../LoginPage/constants'
import {
  DISMISS_MOTD,
  DISMISS_SETTINGS,
  DISMISS_TOAST,
  LOGOUT,
  PREFERENCE_CHANGE,
  SHOW_SETTINGS,
  SHOW_TOAST,
  UPDATE_NETWORK_STATUS,
} from '../../../Shared/constants/appConstants'
import { browserStorage, setInitialTracking } from '../../../Shared/utils'

setInitialTracking()

const state = {
  activeSettingsPane: 'account',
  showMotd: browserStorage.get('sf-motd').value === null,
  pending: true,
  pendingTranslations: false,
  userMenuExpanded: false,
  toasts: [],
  showSettings: false,
  userPreferences: {
    'tracking-new': browserStorage.get<boolean>('sf-pref-tracking-new').value ?? false,
  },
  isOnline: true,
  passwordResetRequested: false,
  passwordResetRequestedError: false,
  customer: {
    id: null,
    name: '',
    commodityPrices: [],
  },
}
export type AppState = Map<keyof typeof state, $TSFixMe>

export const initialState = fromJS(state)

export default createReducer<AppState>(initialState, {
  [GET_ACCESS_TOKEN]: state => state.set('pending', true),
  [ACCESS_TOKEN_RECEIVED]: state => state.set('pending', false),
  [LOGOUT]: () => initialState,
  [SHOW_TOAST]: (state, { toast }: $TSFixMe) => state.set('toasts', state.get('toasts').concat(fromJS([toast]))),
  [DISMISS_TOAST]: (state, { index }: $TSFixMe) => state.set('toasts', state.get('toasts').delete(index)),
  [DISMISS_MOTD]: state => state.set('showMotd', false),
  [SHOW_SETTINGS]: (state, { pane = 'account' }) => state.set('showSettings', true).set('activeSettingsPane', pane),
  [DISMISS_SETTINGS]: state =>
    state.set('showSettings', false).set('passwordResetRequested', false).set('passwordResetRequestedError', false),
  [PREFERENCE_CHANGE]: (state, { value, preference }: $TSFixMe) => state.setIn(['userPreferences', preference], value),
  [UPDATE_NETWORK_STATUS]: (state, { online }: $TSFixMe) => state.set('isOnline', online),
})
