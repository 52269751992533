import Heading from '@sensorfactdev/nucleus/Heading'
import Icon from '@sensorfactdev/nucleus/Icon'
import Loader from '@sensorfactdev/nucleus/Loader'
import Text from '@sensorfactdev/nucleus/Text'
import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'

import { ReportParams } from './types'
import {
  Tracking_Reports_Tracking_Report_Request_Status_Enum,
  useGetTrackingReportRequestsQuery,
} from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const Wrapper = styled.div`
  margin: auto;
  margin-top: 20vh;
  text-align: center;
  & > * {
    margin-top: 2rem;
  }
`
const HiddenLink = styled.a`
  display: hidden;
`
const TextWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin-left: 0.5rem;
  }
`
export const PreviewDownloadPage = () => {
  const { i18n } = useI18nContext()
  const { reportId } = useParams<ReportParams>()
  const downloadLink = useRef<HTMLAnchorElement>(null)
  const { data, error, loading, startPolling, stopPolling } = useGetTrackingReportRequestsQuery({
    variables: { reportId: reportId },
  })
  const url = data?.trackingReportRequests?.[0].url
  const status = data?.trackingReportRequests?.[0].status

  const [pending, setPending] = useState(true)
  const [cancelled, setCancelled] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [timedOut, setTimedOut] = useState(false)

  setTimeout(() => {
    setTimedOut(true)
  }, 120000)

  useEffect(() => {
    setPending(status === Tracking_Reports_Tracking_Report_Request_Status_Enum.Pending)
    setCancelled(status === Tracking_Reports_Tracking_Report_Request_Status_Enum.Cancelled)
    setProcessing(status === Tracking_Reports_Tracking_Report_Request_Status_Enum.Processing)
    setCompleted(status === Tracking_Reports_Tracking_Report_Request_Status_Enum.Completed)
    startPolling(3000)

    if (data?.trackingReportRequests && url) {
      stopPolling()
      url && downloadLink.current?.setAttribute('href', url)
      url && downloadLink.current?.click()
    }

    if (timedOut || cancelled) {
      stopPolling()
    }

    return () => stopPolling()
  }, [data?.trackingReportRequests, startPolling, stopPolling])

  return (
    <Wrapper>
      {(loading || pending || processing) && !(timedOut && pending) && <Loader />}
      <Heading
        style={{ marginTop: '1.5rem' }}
        size={4}
      >
        {(error || cancelled || (timedOut && pending)) && (
          <TextWithIcon>
            {i18n.text('reports.tracking.configuration.module.error')}{' '}
            <Icon
              icon="error"
              intent="danger"
            />{' '}
          </TextWithIcon>
        )}
        {completed && (
          <TextWithIcon>
            {i18n.text('download.page.done')}{' '}
            <Icon
              icon="done-all"
              intent="success"
            />{' '}
          </TextWithIcon>
        )}
      </Heading>
      {(error || cancelled || (timedOut && pending)) && i18n.text('reports.tracking.preview.error')}
      {(loading || pending || processing) && !(timedOut && pending) && i18n.text('report.tracking.preview.pending')}
      {completed && url && (
        <a
          style={{ display: 'inline-block' }}
          href={url}
        >
          {' '}
          <Text>{i18n.text('download.report.manually')}</Text>{' '}
        </a>
      )}
      <HiddenLink
        ref={downloadLink}
        download={url}
      />
    </Wrapper>
  )
}
