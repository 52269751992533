import { Box, Typography, useTheme } from '@mui/material'
import { FC, useMemo } from 'react'

import CriticalStopTimeAssetGroupRow from './CriticalStopTimeAssetGroupRow'
import Error from '../../../Shared/components/MUIComponents/Error'
import Loader from '../../../Shared/components/MUIComponents/Loader'
import { Group, MachineWithCriticalStopTime } from '../../types/Assets.types'
import { transformCriticalStopTimeData } from '../../utils/dataTransformations'
import { useGetOeeMachineCriticalStopTimeQuery } from '../../../Shared/graphql/codegen'

type CriticalStopTimeAssetGroupProps = {
  group: Group
}

const CriticalStopTimeAssetGroup: FC<CriticalStopTimeAssetGroupProps> = ({ group }) => {
  const theme = useTheme()
  const machineIds = group.machines.map(machine => machine.id)

  const { data, loading, error, refetch } = useGetOeeMachineCriticalStopTimeQuery({
    variables: { assetIds: machineIds },
  })

  const machines: MachineWithCriticalStopTime[] = useMemo(() => transformCriticalStopTimeData(data), [data])

  if (error || !machines) return <Error />
  if (loading) return <Loader />

  return (
    <Box sx={{ display: 'grid', gridRowGap: theme.spacing(1) }}>
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 500 }}
      >
        {group.name}
      </Typography>
      <Box sx={{ display: 'grid', gridRowGap: theme.spacing(1), paddingLeft: '2rem' }}>
        {machines.map(machine => {
          return (
            <CriticalStopTimeAssetGroupRow
              key={machine.id}
              machine={machine}
              onSave={refetch}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default CriticalStopTimeAssetGroup
