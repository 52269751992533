import { ICASensorLocationData, ICompressedAirSystemData } from '../types/compressed-air_types'
import { ICustomTableColumn } from '../../Shared/types/types'
import { ImagePlaceholder } from '../components/SystemInformation/ImagePlaceholder'

export function getSystemInformationColumns(
  openViewImagesModal: (id?: string) => void,
  openUploadImageModal: (id: string) => void
): ICustomTableColumn<ICompressedAirSystemData>[] {
  return [
    {
      id: 'image',
      label: 'compressed-air.image-upload.pid-or-technical-drawing',
      minWidth: '15%',
      width: '15%',
      children: (item, id) => (
        <ImagePlaceholder
          item={{ value: item as string, id }}
          openUploadImageModal={openUploadImageModal}
          openViewImagesModal={openViewImagesModal}
        />
      ),
    },
    { id: 'id', label: 'ID', minWidth: '25%' },
    {
      id: 'pressure',
      label: 'air.graphs.air-pressure-header',
      minWidth: '10%',
    },
    {
      id: 'compressors',
      label: 'compressed-air.asset-information.compressors',
      minWidth: '25%',
    },
    {
      id: 'pipes',
      label: 'compressed-air.asset-information.main-pipes',
      minWidth: '25%',
      width: '25%',
    },
  ]
}

export function getCASensorLocationColumns(
  i18n: $I18FixMe,
  openViewImagesModal: (id?: string) => void,
  openUploadImageModal: (id: string) => void
): ICustomTableColumn<ICASensorLocationData>[] {
  return [
    {
      id: 'image',
      label: 'general.image',
      minWidth: '15%',
      width: '15%',
      children: (item, id) => (
        <ImagePlaceholder
          item={{ value: item as string, id }}
          openUploadImageModal={openUploadImageModal}
          openViewImagesModal={openViewImagesModal}
        />
      ),
    },
    {
      id: 'name',
      label: 'generic.name',
      minWidth: '25%',
      isBoldText: true,
      align: 'left',
    },
    { id: 'id', label: 'ID', minWidth: '20%' },
    {
      id: 'inner_diameter',
      label: `${i18n.text('compressed-air.asset-information.inner-diameter')} (mm)`,
      minWidth: '20%',
    },
    {
      id: 'outer_diameter',
      label: `${i18n.text('compressed-air.asset-information.outer-diameter')} (mm)`,
      minWidth: '20%',
    },
  ]
}
