import { FlagsProvider } from 'flagged'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import type { Required } from 'utility-types'

import { CurrentUserContextProvider } from '../../contexts/CurrentUserContext'
import { useAuthContext } from '../../contexts/AuthContext'

type Props = Required<RouteProps, 'component'>

export const PrivateRoute = ({ component: RouteComponent, ...rest }: Props) => {
  const { user, currentCustomer } = useAuthContext()
  return (
    <Route
      {...rest}
      render={routeProps =>
        user && currentCustomer ? (
          <CurrentUserContextProvider
            user={user}
            currentCustomer={currentCustomer}
          >
            <FlagsProvider features={[...currentCustomer.featureFlagGroups, ...user.featureFlags]}>
              <RouteComponent {...routeProps} />
            </FlagsProvider>
          </CurrentUserContextProvider>
        ) : (
          <Redirect to="/login" />
        )}
    />
  )
}
