import { createSvgIcon } from '@mui/material'

export const PresentationChartIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M12.5 16V21M18.5 21L14.5486 17.7072C13.8198 17.0998 13.4554 16.7962 13.0487 16.6801C12.69 16.5778 12.31 16.5778 11.9513 16.6801C11.5446 16.7962 11.1802 17.0998 10.4514 17.7072L6.5 21M8.5 11V12M12.5 9V12M16.5 7V12M22.5 3H2.5M3.5 3H21.5V11.2C21.5 12.8802 21.5 13.7202 21.173 14.362C20.8854 14.9265 20.4265 15.3854 19.862 15.673C19.2202 16 18.3802 16 16.7 16H8.3C6.61984 16 5.77976 16 5.13803 15.673C4.57354 15.3854 4.1146 14.9265 3.82698 14.362C3.5 13.7202 3.5 12.8802 3.5 11.2V3Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'presentation-chart-icon'
)
