import { is, isEmpty, isNil, not, uniq } from 'ramda'

import {
  AlertNotificationPreferenceInput,
  AlertRuleCondition,
  AlertRulePayloadType,
  GetRulesRuleFragment,
} from '../../../Shared/graphql/codegen'
import {
  AlertRuleRecipientsSchema,
  NotificationPreference,
  PowerThresholdRule,
  Rule,
  ThresholdInfo,
  ThresholdRuleCondition,
  VolumeThresholdRule,
} from './types'
import { ID } from '../../../Shared/types/types'
import { InputValues } from '../RuleEditor/types'
import { PhoneNumber, Recipient } from '../../components/Recipient/types'

const HOUR = 60 * 60 * 1000
const DAY = 24 * HOUR
const WEEK = 7 * DAY

export const isPhoneNumber = (destination: Recipient['destination']): destination is PhoneNumber => {
  return not(is(String, destination))
}

export function createNotificationPreferenceInput(pref: NotificationPreference): AlertNotificationPreferenceInput {
  return {
    condition: pref.condition as AlertRuleCondition,
    message: pref.message,
    muted: pref.muted,
    recipients: pref.recipients.map(recipient => ({
      destination: isPhoneNumber(recipient.destination)
        ? `${recipient.destination.dialCode} ${recipient.destination.phoneNumber}`
        : recipient.destination,
      name: recipient.name,
      transport: recipient.transport,
    })),
  }
}

export function getDuration(durationMs: number): { unit: InputValues['durationUnit']; time: number } {
  const _duration = durationMs || 1

  if (_duration % WEEK === 0) {
    return {
      unit: 'weeks',
      time: _duration / WEEK,
    }
  }

  if (_duration % DAY === 0) {
    return {
      unit: 'days',
      time: _duration / DAY,
    }
  }

  if (_duration % HOUR === 0) {
    return {
      unit: 'hours',
      time: _duration / HOUR,
    }
  }

  return {
    unit: 'minutes',
    time: Math.round(_duration / 60 / 1000),
  }
}

const isVolumeThresholdRule = (rule: Rule): rule is VolumeThresholdRule => {
  return rule.payload.type === 'volume'
}

export function getSentencesFromRule(rule: Rule, i18n: $I18FixMe): string[] {
  let aggregation = 0
  let type: AlertRulePayloadType = AlertRulePayloadType.Power
  if (isVolumeThresholdRule(rule)) {
    aggregation = rule.payload.aggregation
    type = AlertRulePayloadType.Volume
  }

  if (rule.payload.type === AlertRulePayloadType.ProductionSpeed) {
    type = rule.payload.type
  }
  const payload = rule.payload

  const durationInMs = payload.duration || 1
  const duration = getDuration(durationInMs)
  const sentences = rule.payload.thresholdsV2.map(t => getThresholdSentence(t, type, duration, aggregation, i18n))

  return sentences
}

const getThresholdSentence = (
  thresholdInfo: ThresholdInfo,
  type: AlertRulePayloadType,
  duration: { time: number; unit: string },
  aggregation: number,
  i18n: $I18FixMe
): string => {
  const { condition, values } = thresholdInfo
  const { unit: durationUnit, time: durationTime } = duration
  const { unit: aggregationUnit, time: aggregationTime } = getDuration(aggregation)

  const threshold0 = Math.round(values[0]).toString()
  const threshold1 = condition === 'between' ? Math.round(values[1]).toString() : '0'

  const unitMap = {
    power: 'W',
    productionSpeed: `cycles per minute`,
    volume: 'm³',
  }

  const vars = {
    aggregation:
      type === 'power'
        ? ''
        : i18n.text(`rules.problem.aggregationUnits.${aggregationUnit}`, { durationTime: aggregationTime }),
    duration: i18n.text(`rules.problem.durationUnits.${durationUnit}`, { durationTime }),
    condition: i18n.text(`rules.problem.conditions.${condition}`),
    thresholdUnit: unitMap[type],
    threshold0,
    threshold1,
  }

  return condition === 'between'
    ? i18n.text('rules.problem.description-between', vars)
    : i18n.text('rules.problem.description', vars)
}

function parsePhoneNumber(dest: string): PhoneNumber {
  // NB: Assumes that the phone numbers are serialized using spaces
  const [dialCode, ...numbers] = dest.split(' ')
  // Remove white spaces from number
  const phoneNumber = numbers.join('')
  return { dialCode, phoneNumber }
}

export function parseRecipients(recs: unknown): Recipient[] {
  const res = AlertRuleRecipientsSchema.safeParse(recs)
  if (!res.success) return []
  return res.data.map(rec => {
    if (rec.transport !== 'whatsapp') return rec as Recipient
    const destination = parsePhoneNumber(rec.destination)
    return { ...rec, destination } as Recipient
  })
}

export function normalizeRule(rule: GetRulesRuleFragment): Rule {
  const prefs: NotificationPreference[] = rule.notificationPreferences.map(pref => ({
    condition: pref.condition as ThresholdRuleCondition,
    message: pref.message,
    muted: pref.muted,
    recipients: parseRecipients(pref.recipients),
  }))

  const baseRule = {
    id: rule.id as ID,
    name: rule.name,
    type: rule.type as Rule['type'],
    assets: rule?.assets?.map(({ asset: { id, name } }) => ({ id: id as ID, name })) ?? [],
    notificationPreferences: prefs,
  }

  const basePayload = {
    duration: rule.payload.duration,
    intervals: rule.payload.intervals,
    type: rule.payload.type,
    thresholdsV2: rule.payload.thresholdsV2,
  }

  if (rule.payload.type === 'power') {
    return {
      ...baseRule,
      payload: basePayload,
    } as PowerThresholdRule
  }

  return {
    ...baseRule,
    payload: { ...basePayload, aggregation: rule.payload.aggregation },
  } as VolumeThresholdRule
}

// prettier-ignore
/* eslint-disable */
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

function isValidEmail(email: string): boolean {
  return EMAIL_REGEX.test(email)
}

export function validateRecipients(recipients: Recipient[]): string[] {
  const errors: string[] = []
  recipients.forEach(({ name, destination, transport }) => {
    if ([name, destination, transport].some(field => isNil(field) || isEmpty(field)))
      errors.push('rules.errors.recipients')

    if (transport === 'email' && !isValidEmail(destination as string))
      errors.push('rules.errors.recipients.invalid-email')
  })
  return uniq(errors)
}
