import React, { FC, useCallback, useMemo } from 'react'
import { Box, styled, useTheme } from '@mui/material'
import { DateTime, Info } from 'luxon'

import { CompareDateTimeForm } from './CompareDateTimeForm'
import { CompareDatesForm } from './CompareDatesForm'
import { CustomizedMenu } from './CustomizedMenu'
import { DateTimePickersForm } from './DateTimePickersForm'
import { Option } from './types'
import { useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

const MonthAndYearDropdowns = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1.5, 0, 2.75),
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2.75),
}))

const FormWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 3, 3),
}))

export const CalendarHeader: FC = () => {
  const { isCompareDatesOn, isTimePicker, fromDate, toDate, compareFromToValues, currentDate, setCurrentDate } =
    useDateTimeRangePickerContext()
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const fromToValues = {
    startDate: fromDate,
    endDate: toDate,
  }

  const months = Info.months('numeric').map((month, index) => ({
    option: Info.months('long', { locale: i18n.locale.replace('_', '-') })[index],
    key: +month,
  }))

  // Get years from 1980 to 2080
  const years = Array.from({ length: 101 }, (_, i) => ({
    option: 1980 + i,
    key: 1980 + i,
  }))

  const selectedMonth = useMemo(
    () => ({
      option: currentDate?.month
        ? Info.months('short', { locale: i18n.locale.replace('_', '-') })[currentDate?.month - 1]
        : '',
      key: currentDate?.month || 0,
    }),
    [currentDate, i18n.locale]
  )

  const selectedYear = useMemo(
    () => ({
      option: currentDate?.year || '',
      key: currentDate?.year || 0,
    }),
    [currentDate]
  )

  const handleMonthChange = useCallback(
    (option: Option, action: 'add' | 'sub' | null) => {
      let newDate = currentDate ? currentDate.set({ month: option.key }) : DateTime.now().set({ month: option.key })
      if (action === 'add') {
        newDate = newDate.set({ year: newDate.year + 1 })
      }
      if (action === 'sub') {
        newDate = newDate.set({ year: newDate.year - 1 })
      }
      setCurrentDate(newDate)
    },
    [toDate, currentDate, setCurrentDate]
  )

  const handleYearChange = (option: Option) => {
    setCurrentDate(currentDate ? currentDate.set({ year: option.key }) : DateTime.now().set({ year: option.key }))
  }

  return (
    <Box>
      <MonthAndYearDropdowns>
        <CustomizedMenu
          options={months}
          selectedOption={selectedMonth}
          setSelectedOption={handleMonthChange}
        />
        <CustomizedMenu
          options={years}
          selectedOption={selectedYear}
          setSelectedOption={handleYearChange}
          isOnTheRight
        />
      </MonthAndYearDropdowns>
      {(isCompareDatesOn || isTimePicker) && (
        <FormWrapper
          sx={{
            padding: isCompareDatesOn && isTimePicker ? theme.spacing(1.5, 1.5, 3) : theme.spacing(1.5, 3, 3),
          }}
        >
          {isCompareDatesOn && !isTimePicker && (
            <CompareDatesForm
              fromToValues={fromToValues}
              comparedFromToValues={compareFromToValues}
            />
          )}
          {isCompareDatesOn && isTimePicker && (
            <CompareDateTimeForm
              fromToValues={fromToValues}
              comparedFromToValues={compareFromToValues}
            />
          )}
          {!isCompareDatesOn && isTimePicker && <DateTimePickersForm values={fromToValues} />}
        </FormWrapper>
      )}
    </Box>
  )
}
