import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box, useTheme } from '@mui/material'

import usePropositions from '../../../../Shared/hooks/usePropositions/usePropositions'
import { AlertsMessagesTableTools } from '../../../components/AlertsMessagesTableTools'
import { CustomTable } from '../../../../Shared/components/MUIComponents/update/CustomTable'
import {
  Order_By,
  useGetAlertMessagesLazyQuery,
  useGetAssetsForAlertMessagesQuery,
  useGetSortedMessagesLazyQuery,
  useGetVibrationThresholdAlertMessagesLazyQuery,
} from '../../../../Shared/graphql/codegen'
import { getAlertSearchType } from '../../../utils/getAlertSearchType'
import { getMessagesColumns } from '../../../utils/alertMessages'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { useFilteredPropositions } from '../../../hooks/useFilteredPropositions'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useMessagesRows } from '../../../hooks/useMessagesRows'
import { useSelectedProposition } from '../../../hooks/useSelectedProposition'

const MAXIMUM_ROWS_PER_PAGE = 20

export interface IMessagesData {
  id: string
  date: string
  asset: string
  proposition: string
  category: string
  message: string
}

export const MessagesPage: FC = () => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { customerId } = useCurrentUser()
  const { propositions } = usePropositions()

  const [page, setPage] = useState(1)
  const [isDateInDescOrder, setIsDateInDescOrder] = useState(true)
  const [searchText, setSearchText] = useState('')
  const { selectedProposition, setSelectedProposition } = useSelectedProposition()
  const filteredPropositions = useFilteredPropositions()

  const headerProps = {
    title: i18n.text('notifications.messages.title'),
    subtitle: i18n.text('notifications.messages.description'),
    toolsSlot: renderToolsSlot(),
    slotProps: {
      tableTitle: {
        sx: {
          borderBottom: `1px solid ${theme.palette.SFIGreyLight[200]}`,
        },
      },
      toolsSlot: {
        sx: {
          border: 'none',
        },
      },
      tableColumn: {
        sx: {
          borderTop: 'none',
          background: theme.palette.SFIBase.white,
        },
      },
    },
  }

  const tableSlotProps = {
    tableWrapper: {
      sx: {
        border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
      },
    },
  }

  const { data: assetsData } = useGetAssetsForAlertMessagesQuery()

  const offsetValue = (page - 1) * MAXIMUM_ROWS_PER_PAGE
  const noDataSlotData = {
    title: 'alerts.messages.no-messages-text',
    subtitle: 'alerts.messages.no-messages-explanation-text',
    slotProps: {
      subtitle: {
        sx: {
          width: '22rem',
        },
      },
      wrapper: {
        sx: {
          height: '20rem',
          paddingBottom: 0,
          paddingTop: '1.5rem',
        },
      },
    },
  }
  const searchType = getAlertSearchType(selectedProposition, propositions)
  const orderByValue = isDateInDescOrder ? Order_By.Desc : Order_By.Asc

  const [runFetchRuleBasedMessages, { loading: fetchRuleBasedMessagesLoading, data: ruleBasedMessagesData }] =
    useGetSortedMessagesLazyQuery()
  const [runFetchCAMessages, { loading: fetchCAMessagesLoading, data: caMessagesData }] = useGetAlertMessagesLazyQuery()
  const [runFetchThresholdMessages, { loading: fetchThresholdMessagesLoading, data: thresholdMessagesData }] =
    useGetVibrationThresholdAlertMessagesLazyQuery()

  const messagesRows = useMessagesRows({
    selectedProposition,
    propositions,
    ruleBasedMessagesData,
    caMessagesData,
    thresholdMessagesData,
    assetsData,
  })

  const isLoading = fetchCAMessagesLoading || fetchRuleBasedMessagesLoading || fetchThresholdMessagesLoading

  const columns = getMessagesColumns({
    isDateInDescOrder,
    theme,
    propositions,
    handleDateSorting,
  })

  const searchAssets = useMemo(() => {
    const assets = assetsData?.myOrg?.assets || []

    const commodityTypeMap = {
      [propositions.gas.name]: 'natural-gas',
      [propositions.water.name]: 'water',
    }

    const commodityType = commodityTypeMap[selectedProposition]

    return assets
      .filter(asset => !commodityType || asset.payload?.commodityType === commodityType)
      .reduce<string[]>((acc, asset) => {
        if (asset.name.toLowerCase().includes(searchText.toLowerCase())) {
          acc.push(`create.assetmeasurement.${searchType}.${asset.id}`)
        }
        return acc
      }, [])
  }, [assetsData?.myOrg?.assets, searchText, selectedProposition])

  const totalRows = useMemo(() => {
    const isRuleBasedAlert =
      selectedProposition !== propositions['compressed-air'].name &&
      selectedProposition !== propositions['predictive-maintenance'].name

    const isCaAlert = selectedProposition === propositions['compressed-air'].name

    const ruleBasedCount = ruleBasedMessagesData?.myOrg?.alertNotificationEvents_aggregate.aggregate?.count || 0
    const caAlertCount = caMessagesData?.generalAlertRuleAlertEventAggregate.aggregate?.count || 0

    if (isRuleBasedAlert) return ruleBasedCount
    if (isCaAlert) return caAlertCount

    return MAXIMUM_ROWS_PER_PAGE
  }, [
    selectedProposition,
    ruleBasedMessagesData?.myOrg?.alertNotificationEvents_aggregate.aggregate?.count,
    caMessagesData?.generalAlertRuleAlertEventAggregate.aggregate?.count,
  ])

  useEffect(() => {
    setPage(1)
    setIsDateInDescOrder(true)
    setSearchText('')
  }, [selectedProposition])

  useEffect(() => {
    const isRuleBasedAlert =
      selectedProposition !== propositions['compressed-air'].name &&
      selectedProposition !== propositions['predictive-maintenance'].name
    const isCaAlert = selectedProposition === propositions['compressed-air'].name

    if (isRuleBasedAlert) {
      runFetchRuleBasedMessages({
        variables: {
          limit: MAXIMUM_ROWS_PER_PAGE,
          offset: offsetValue,
          orderBy: orderByValue,
          searchType,
          searchTexts: searchAssets,
        },
      })
    } else if (isCaAlert) {
      runFetchCAMessages({
        variables: { limit: MAXIMUM_ROWS_PER_PAGE, offset: offsetValue, orderBy: orderByValue, searchText, customerId },
      })
    } else {
      runFetchThresholdMessages({
        variables: { limit: MAXIMUM_ROWS_PER_PAGE, offset: offsetValue, orderBy: orderByValue },
      })
    }
  }, [page, selectedProposition, isDateInDescOrder, searchText, searchAssets])

  function renderToolsSlot() {
    return (
      <AlertsMessagesTableTools
        searchText={searchText}
        selectedItem={selectedProposition}
        onItemChange={onPropositionChange}
        items={filteredPropositions}
        onSearchTextChange={e => {
          setSearchText(e.target.value)
        }}
      />
    )
  }

  function handleDateSorting() {
    setIsDateInDescOrder(!isDateInDescOrder)
  }

  function handleChangePage(newPage: number) {
    setPage(newPage)
  }

  function onPropositionChange(newValue: string) {
    setSelectedProposition(newValue)
  }

  return (
    <Box
      sx={{
        marginTop: '2.25rem',
        overflowY: 'auto',
      }}
    >
      <CustomTable
        columns={columns}
        rows={messagesRows}
        header={headerProps}
        body={{
          rowColored: 'odd',
        }}
        isHeaderSticky={false}
        maxHeight="max-content"
        pagination={{
          totalRows,
          activePage: page,
          rowsPerPage: MAXIMUM_ROWS_PER_PAGE,
          onChange: handleChangePage,
        }}
        slotProps={tableSlotProps}
        isLoading={isLoading}
        noDataSlot={noDataSlotData}
      />
    </Box>
  )
}
