import { createSvgIcon } from '@mui/material'

export const MessageAlertCirleIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M11.5006 11.5V8M11.5006 15H11.4906M11.5006 20.5C6.80614 20.5 3.00056 16.6944 3.00056 12C3.00056 7.30558 6.80614 3.5 11.5006 3.5C16.195 3.5 20.0006 7.30558 20.0006 12C20.0006 12.95 19.8447 13.8636 19.5572 14.7166C19.449 15.0376 19.3949 15.1981 19.3851 15.3214C19.3755 15.4432 19.3828 15.5286 19.4129 15.6469C19.4434 15.7668 19.5108 15.8915 19.6455 16.1408L21.2811 19.1684C21.5144 19.6002 21.6311 19.8161 21.605 19.9828C21.5822 20.1279 21.4968 20.2557 21.3714 20.3322C21.2274 20.4201 20.9833 20.3948 20.495 20.3444L15.374 19.815C15.2189 19.799 15.1414 19.791 15.0707 19.7937C15.0012 19.7963 14.9521 19.8029 14.8843 19.8185C14.8154 19.8344 14.7287 19.8678 14.5554 19.9345C13.6074 20.2998 12.5773 20.5 11.5006 20.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'message-alert-circle-icon'
)
