import * as z from 'zod'
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent'
import { Alert, Box, Button, Divider, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { SettingsBackupRestore } from '@mui/icons-material'
import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'

import MUILoader from '../../../../Shared/components/MUIComponents/Loader/index'
import { ComponentType, VibrationSensorLocationInfo } from '../types'
import { Line } from '../AssetInfo'
import {
  UpdateVibrationSensorLocationVariables,
  VibrationComponentTypeInput,
  VibrationFoundationTypeInput,
  VibrationSpeedControlInput,
  useUpdateVibrationSensorLocationMutation,
} from '../../../../Shared/graphql/codegen'
import { getStoredUserLocale, useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { toValidIntlLocale } from '../../../../Shared/utils/formatCurrency'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { vibrationLocationComponentTypeNames } from '../constants'

const NULL_OPTION = '__null__'
const EMPTY_OPTION = ''

const parseNullableNumberInput = (value: string): number | null => {
  const result = value === EMPTY_OPTION ? null : parseFloat(value)
  return result
}

interface ConfigureComponentTypeProps {
  sensorLocation: VibrationSensorLocationInfo
  onClose: (assetInfoUpdate: boolean) => void
}

const rpmSchema = z.coerce.number().int().min(1).max(10000)
const machinePowerSchema = z.coerce
  .number()
  .min(0)
  .max(1500)
  .refine(
    (value: number) => {
      const locale = getStoredUserLocale()
      const formatLocale = new Intl.NumberFormat(toValidIntlLocale(locale))
      const fraction = formatLocale.formatToParts(value).find(part => part.type === 'fraction')?.value || ''
      return fraction.length <= 2
    },
    {
      message: 'Machine power should have at most two decimal places.',
    }
  )

const rpmFormInputSchema = z.string().refine(value => {
  if (value === EMPTY_OPTION) return true
  const parsed = rpmSchema.safeParse(value)
  return parsed.success
})

const machinePowerFormInputSchema = z.string().refine(value => {
  if (value === EMPTY_OPTION) return true
  const parsed = machinePowerSchema.safeParse(value)
  return parsed.success
})

const formSchema = z.object({
  componentType: z.enum([...ComponentType, NULL_OPTION]).transform(v => v as string),
  speedControl: z.nativeEnum({ ...VibrationSpeedControlInput, None: NULL_OPTION }).transform(v => v as string),
  foundation: z.nativeEnum({ ...VibrationFoundationTypeInput, None: NULL_OPTION }).transform(v => v as string),
  setSpeed: rpmFormInputSchema,
  rpm: rpmFormInputSchema,
  machinePower: machinePowerFormInputSchema,
})

type FormInputs = z.infer<typeof formSchema>

const vibrationComponentTypeInputSchema = z.nativeEnum(VibrationComponentTypeInput)
const vibrationComponentSpeedControlInputSchema = z.nativeEnum(VibrationSpeedControlInput)
const vibrationComponentFoundationInputSchema = z.nativeEnum(VibrationFoundationTypeInput)

const parseLocationUpdateVars = (data: FormInputs): UpdateVibrationSensorLocationVariables => {
  const { componentType, setSpeed, speedControl, rpm, foundation, machinePower } = data

  const vars: UpdateVibrationSensorLocationVariables = {
    componentType: componentType === NULL_OPTION ? null : vibrationComponentTypeInputSchema.parse(componentType),
    speedControl: speedControl === NULL_OPTION ? null : vibrationComponentSpeedControlInputSchema.parse(speedControl),
    foundation: foundation === NULL_OPTION ? null : vibrationComponentFoundationInputSchema.parse(foundation),
    setSpeed: parseNullableNumberInput(setSpeed),
    rpm: parseNullableNumberInput(rpm),
    machinePower: parseNullableNumberInput(machinePower),
  }

  return vars
}

const ConfigureVibrationSensorLocation = ({ sensorLocation, onClose }: ConfigureComponentTypeProps) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { getHasuraRoleHeader } = useCurrentUser()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [updateVibrationSensorLocation, { loading: isLoading, error: isError }] =
    useUpdateVibrationSensorLocationMutation({
      context: {
        headers: {
          'x-hasura-role': getHasuraRoleHeader(),
        },
      },
    })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      componentType: sensorLocation.componentType ? sensorLocation.componentType : NULL_OPTION,
      speedControl: sensorLocation.speedControl ? sensorLocation.speedControl : NULL_OPTION,
      foundation: sensorLocation.foundation ? sensorLocation.foundation : NULL_OPTION,
      setSpeed: sensorLocation.setSpeed ? String(sensorLocation.setSpeed) : EMPTY_OPTION,
      rpm: sensorLocation.rpm ? String(sensorLocation.rpm) : EMPTY_OPTION,
      machinePower: sensorLocation.machinePower ? String(sensorLocation.machinePower) : EMPTY_OPTION,
    },
  })

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    setErrorMessage(null)

    try {
      const vars = parseLocationUpdateVars(data)

      await updateVibrationSensorLocation({
        variables: {
          id: sensorLocation.id,
          vars,
        },
      })
      onClose(true)
    } catch (error) {
      setErrorMessage((error as Error).message)
    }
  }

  return (
    <Box margin={2.5}>
      <Typography
        fontWeight={400}
        variant="h5"
        component="span"
        display="inline"
        color="secondary"
      >
        Sensor Location Configuration
      </Typography>
      {isError && (
        <Alert
          sx={{ margin: theme.spacing(1) }}
          severity="error"
        >
          {' '}
          {isError?.message || 'Server error please try again !'}{' '}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          {isLoading ? (
            <MUILoader
              margin="1vh 0 1vh 0"
              height={5.3}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Controller
                name={'componentType'}
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <Line
                    icon={SettingsInputComponentIcon}
                    label={'Component Type'}
                    component={
                      <Select
                        {...rest}
                        style={{ width: '100%' }}
                        placeholder="Select a Component Type"
                        onChange={e => {
                          e.preventDefault()
                          onChange(e.target.value)
                        }}
                      >
                        <MenuItem value={NULL_OPTION}>None</MenuItem>
                        {Object.entries(vibrationLocationComponentTypeNames).map(([key, value]) => (
                          <MenuItem
                            key={key}
                            value={key}
                          >
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                )}
              />
              <Controller
                name={'speedControl'}
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <Line
                    icon={SettingsInputComponentIcon}
                    label={'Speed Control'}
                    component={
                      <Select
                        {...rest}
                        style={{ width: '100%' }}
                        placeholder="Select Speed Control Type"
                        onChange={e => {
                          e.preventDefault()
                          onChange(e.target.value)
                        }}
                      >
                        <MenuItem value={NULL_OPTION}>Unknown</MenuItem>
                        {Object.entries(VibrationSpeedControlInput).map(([key, value]) => (
                          <MenuItem
                            key={key}
                            value={value}
                          >
                            {key}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                )}
              />
              <Line
                icon={SettingsBackupRestore}
                label={i18n.text('pdm.asset.details.machine.set-speed')}
                component={
                  <Controller
                    name="setSpeed"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Set Speed"
                        placeholder="Enter a Set Speed"
                        type="number"
                        fullWidth
                        margin="normal"
                        error={!!errors.setSpeed}
                        helperText={errors.setSpeed?.message}
                        onChange={(event: { target: { value: string } }) => {
                          field.onChange(event.target.value)
                        }}
                      />
                    )}
                  />
                }
              />
              <Line
                icon={SettingsBackupRestore}
                label={i18n.text('pdm.asset.details.machine.rpm')}
                component={
                  <Controller
                    name="rpm"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="RPM"
                        type="number"
                        fullWidth
                        margin="normal"
                        error={!!errors.rpm}
                        helperText={errors.rpm?.message}
                        onChange={(event: { target: { value: string } }) => {
                          field.onChange(event.target.value)
                        }}
                      />
                    )}
                  />
                }
              />
              <Line
                icon={SettingsBackupRestore}
                label={i18n.text('pdm.asset.details.machine.machine-power') + ' (kW)'}
                component={
                  <Controller
                    name="machinePower"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Machine Power"
                        type="number"
                        fullWidth
                        margin="normal"
                        error={!!errors.machinePower}
                        helperText={errors.machinePower?.message}
                        onChange={(event: { target: { value: string } }) => {
                          field.onChange(event.target.value)
                        }}
                      />
                    )}
                  />
                }
              />
              <Controller
                name={'foundation'}
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <Line
                    icon={SettingsInputComponentIcon}
                    label={'Foundation'}
                    component={
                      <Select
                        {...rest}
                        style={{ width: '100%' }}
                        placeholder="Select Foundation Type"
                        onChange={e => {
                          e.preventDefault()
                          onChange(e.target.value)
                        }}
                      >
                        <MenuItem value={NULL_OPTION}>Unknown</MenuItem>
                        {Object.entries(VibrationFoundationTypeInput).map(([key, value]) => (
                          <MenuItem
                            key={key}
                            value={value}
                          >
                            {key}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                )}
              />
              <Divider sx={{ width: '100%' }} />
              {errorMessage && (
                <Alert
                  sx={{
                    margin: theme.spacing(1),
                    width: '100%',
                  }}
                  severity="error"
                >
                  {errorMessage}
                </Alert>
              )}
              <Button
                sx={{ marginTop: '3rem' }}
                variant="outlined"
                type="submit"
              >
                Edit Sensor Configuration
              </Button>
            </Box>
          )}
        </Box>
      </form>
    </Box>
  )
}

export default ConfigureVibrationSensorLocation
