import Navigation from '../components/Navigation'
import { OnboardingPath } from '../models'
import { StyledContent } from '../components/Wrapper'
import { useProgressContext } from '../context/ProgressContext';

export default function TechnicalInfo() {
  const { navigateStage } = useProgressContext();

  const handleBackAction = () => {
    navigateStage(OnboardingPath.INVITE_MEMBERS)
  }
  const handleNextAction = () => {
    navigateStage(OnboardingPath.INSTALLATION)
  }

  return (
    <>
      <StyledContent>Technical Info</StyledContent>
      <Navigation
        backAction={handleBackAction}
        nextAction={handleNextAction}
      />
    </>
  )
}
