import { compose, filter, flatten, isEmpty, last, mean, pluck, propEq } from 'ramda'

import { getAllAssets, getAssetById } from '../../../../Shared/utils'

interface PartialAsset<A> {
  id: string
  color: string
  name: string
  markerShape: string
  models?: {
    clusters?: $TSFixMe
  }[]
  assets?: PartialAsset<A>[]
}

type Data<A> = { assets: PartialAsset<A>[]; selectedAssets: string[] | string[][]; selectedComponents: $TSFixMe[] }

/**
 * Prepares data to be used and processed by the LineChart
 */
export function prepareGraphData<A>(
  { assets, selectedAssets, selectedComponents }: Data<A>,
  measurements: $TSFixMe,
  key: $TSFixMe
) {
  const multipleMachinesSelected = flatten(selectedAssets).length > 1
  const selectedAssetIds = flatten(selectedAssets)

  // First flatten the selectedAssets so we have a list of ID's
  const selectedMeasurements = selectedAssetIds
    // Iterate over the ID's to get the currently selected measurements
    .map(selectedAssetId => {
      const currentAsset = measurements[selectedAssetId]

      // Grab color from listed assets so colors won't ever change
      const listedAsset = getAssetById(assets, selectedAssetId)

      return currentAsset && currentAsset[key] && listedAsset
        ? currentAsset[key].map((measurement: $TSFixMe) => ({
          ...measurement,
          id: selectedAssetId,
          color: listedAsset.color,
          name: listedAsset.name,
          markerShape: listedAsset.markerShape,
        }))
        : []
    })
    // Filter out empty collections so LineChart doesn't break
    .filter(measurement => !isEmpty(measurement))

  const selectedComponentMeasurements =
    selectedAssetIds.length === 1
      ? selectedComponents.map((id: $TSFixMe) => {
        const currentComponent = measurements[id]

        // Grab color from listed assets so colors won't ever change
        const listedAsset = getAssetById(assets, id)

        return currentComponent && currentComponent[key]
          ? currentComponent[key].map((measurement: $TSFixMe) => ({
            ...measurement,
            color: listedAsset.color,
            name: listedAsset.name,
            id,
            isComponent: true,
          }))
          : []
      })
      : null

  return !multipleMachinesSelected
    ? [].concat(
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      selectedMeasurements,
      selectedComponentMeasurements || []
    )
    : selectedMeasurements
}

const convertStateToKW = (value: number) => Math.round(value / 1000)

const getStateMean = (clusters: $TSFixMe, state: $TSFixMe) =>
  compose(
    mean,
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    pluck('center'),
    filter(propEq('category', state))
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
  )(clusters)

/**
 * Prepares state data to be used and process by the MachineStateGraph. Returns
 * arrays of models, containing clusters, asset id, unit, production + standby means.
 */
export function prepareStateData<A>({ assets, selectedAssets, selectedComponents }: Data<A>) {
  const flatAssetIds = flatten([selectedAssets, selectedComponents])
  const flatSelectedAssets = getAllAssets(assets).filter(({ id }) => flatAssetIds.includes(id))

  return flatSelectedAssets.flatMap(({ id, models }) => {
    if (!models || isEmpty(models)) return []

    // get last model, assuming models are sorted chronologically
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'clusters' does not exist on type '{ clus... Remove this comment to see the full error message
    const { clusters } = last(models)
    if (!clusters || isEmpty(clusters)) return []

    // Converting centers to kW in place
    clusters.forEach((cluster: $TSFixMe) => {
      cluster.center = convertStateToKW(cluster.center)
    })

    const model = {
      id,
      clusters,
      standbyMean: getStateMean(clusters, 'standby'),
      productionMean: getStateMean(clusters, 'production'),
      unit: 'kW',
    }

    return [model]
  })
}
