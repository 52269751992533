import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, useTheme } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import DurationInput from './EditAlertDialogInputs/DurationInput'
import MUILoader from '../../../../../Shared/components/MUIComponents/Loader'
import MeasurementCount from './EditAlertDialogInputs/MeasurementsCountInput'
import NotificationLevels from './EditAlertDialogInputs/NotificationLevel'
import RecipientsInput from './EditAlertDialogInputs/ReciepientsInput'
import SelectAlertFrequency from './EditAlertDialogInputs/SelectAlertFrequency'
import SelectAlertType from './EditAlertDialogInputs/SelectAlertEvaluationMode'
import useAnalytics from '../../../../../Shared/hooks/useAnalytics/useAnalytics'
import { ID } from '../../../../../Shared/types/types'
import {
  MeasurementTypeInput,
  ThresholdAlertConditionInput,
  ThresholdAlertRecipientInput,
  ThresholdConstraintInput,
  useCustomerUsersQuery,
  useUpdateConfigurableThresholdAlertsMutation,
} from '../../../../../Shared/graphql/codegen'
import {
  ParsedAlertRule,
  SelectDurationIntervalType,
  SelectEventCooldownIntervalType,
  SelectMeasurementCountIntervalType,
  getCalculatedDuration,
  getCooldownIntervalFromSeconds,
  getDurationSeconds,
  getEventCooldownSeconds,
  getMeasurementCountSeconds,
} from '../../utils'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'
import { sendEditThresholdAlertEventPDM } from '../../../../utils/analyticsEvents'

const evaluationModeSchema = z.enum(['MEASUREMENTS_IN_INTERVAL', 'CONTINUOUS_INTERVAL'])
type EvaluationMode = z.infer<typeof evaluationModeSchema>

export type FormInputs = {
  eventCooldownSeconds: number
  duration: number
  durationInterval?: SelectDurationIntervalType
  eventCooldownInterval: SelectEventCooldownIntervalType
  measurementCount?: number | null
  minimumNotifyLevel: number
  measurementCountInterval?: SelectMeasurementCountIntervalType
  reciepients: ThresholdAlertRecipientInput[]
  evaluationMode: EvaluationMode
}

interface EditThresholdAlertDialogProps {
  onSubmitConfigurationAlertDialog: (condigureThresholdsUpdated: boolean) => void
  rule: ParsedAlertRule
}
export function EditThresholdAlertDialog({ onSubmitConfigurationAlertDialog, rule }: EditThresholdAlertDialogProps) {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { sendEvent } = useAnalytics()
  const [updateUserAlertMutation, { loading: updatingUserAlertRule }] = useUpdateConfigurableThresholdAlertsMutation()

  const [open, setOpen] = useState(false)

  const { data: reciepientsData } = useCustomerUsersQuery()

  const initialDurationInterval: SelectDurationIntervalType = 'minutes'
  const initialEvalMode = rule.evaluationMode as EvaluationMode

  const formSchema: z.Schema<FormInputs> = z.object({
    reciepients: z.array(
      z.object(
        {
          name: z.string().min(1),
          deliveryType: z.literal('email'),
          destination: z.string().min(1),
        },
        { description: i18n.text('pdm.configure.alert.dialog.reciepients.error') }
      )
    ),
    eventCooldownSeconds: z.number().int(),
    duration: z.number().refine(
      value => {
        const evaluationMode = watch('evaluationMode')
        if (evaluationMode === 'MEASUREMENTS_IN_INTERVAL') {
          return true
        } else {
          const durationInterval = watch('durationInterval')
          if (durationInterval === 'minutes') return value >= 10
          if (durationInterval === 'hours') return value >= 1
        }
      },
      { message: i18n.text('pdm.configure.alert.dialog.duration.error') }
    ),
    eventCooldownInterval: z.enum(['hour', 'day', 'week', 'no-limit']),
    measurementCountInterval: z.enum(['hour', 'day', 'week']).optional(),
    measurementCount: z
      .number()
      .positive()
      .refine(
        value => {
          const evaluationMode = watch('evaluationMode')
          if (evaluationMode === 'CONTINUOUS_INTERVAL') {
            return true
          } else {
            const measurementCountInterval = watch('measurementCountInterval')
            if (measurementCountInterval === 'hour') return !(measurementCountInterval === 'hour' && value > 6)
            if (measurementCountInterval === 'day') return !(measurementCountInterval === 'day' && value > 144)
            if (measurementCountInterval === 'week') return !(measurementCountInterval === 'week' && value > 1008)
          }
        },
        { message: i18n.text('pdm.configure.alert.dialog.measurementCount.error') }
      )
      .optional(),
    durationInterval: z.enum(['minutes', 'hours']).optional(),
    minimumNotifyLevel: z.number().int().min(0).max(1),
    evaluationMode: evaluationModeSchema,
  })

  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      evaluationMode: initialEvalMode,
      duration: getCalculatedDuration(initialDurationInterval, rule.duration),
      measurementCount: rule.measurementCount,
      minimumNotifyLevel: rule.minimumNotifyLevel,
      eventCooldownSeconds: rule.eventCooldownSeconds,
      reciepients: rule.recipients,
      durationInterval: initialDurationInterval,
    },
  })

  const evaluationMode = watch('evaluationMode')

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    const condition: ThresholdAlertConditionInput[] = [
      {
        assetId: rule.assetId as ID,
        thresholds: rule.thresholds,
        constraint: ThresholdConstraintInput.Above,
        measurementType: MeasurementTypeInput.VelocityRms,
        evaluationMode: data.evaluationMode,
        duration: getDurationSeconds(data.durationInterval, data.duration),
        measurementCount: data.measurementCount,
      },
    ]

    if (evaluationMode === 'CONTINUOUS_INTERVAL') {
      await updateUserAlertMutation({
        variables: {
          ruleId: rule.id as ID,
          recipients: data.reciepients,
          eventCooldownSeconds: getEventCooldownSeconds(data.eventCooldownInterval),
          minimumNotifyLevel: data.minimumNotifyLevel,
          muteNotifications: false,
          condition: [{ ...condition[0], measurementCount: undefined }],
        },
      })
    } else {
      await updateUserAlertMutation({
        variables: {
          ruleId: rule.id as ID,
          recipients: data.reciepients,
          // eventCooldownSeconds: getEventCooldownSeconds(data.eventCooldownInterval),
          eventCooldownSeconds: getEventCooldownSeconds(data.eventCooldownInterval),
          minimumNotifyLevel: data.minimumNotifyLevel,
          condition: [{ ...condition[0], duration: getMeasurementCountSeconds(data.measurementCountInterval) }],
          muteNotifications: false,
        },
      })
    }
    onSubmitConfigurationAlertDialog(true)
    setOpen(false)
  }

  const handleOpen = () => {
    sendEditThresholdAlertEventPDM(sendEvent)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleOpen}
        >
          {i18n.text('pdm.configure.alert.button')}
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        {updatingUserAlertRule && (
          <MUILoader
            margin="20vh 0 20vh 0"
            height={15}
          />
        )}
        {!updatingUserAlertRule && (
          <>
            <DialogTitle
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {i18n.text('pdm.configure.alert.dialog.text')}
            </DialogTitle>
            <SelectAlertType
              clearErrors={clearErrors}
              control={control}
            />

            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <DialogContentText margin={theme.spacing(1)}>
                  {i18n.text('pdm.configure.alert.dialog.content.text')}
                </DialogContentText>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    {evaluationMode === 'CONTINUOUS_INTERVAL' && (
                      <DurationInput
                        theme={theme}
                        control={control}
                        errors={errors}
                      />
                    )}
                    {evaluationMode === 'MEASUREMENTS_IN_INTERVAL' && (
                      <MeasurementCount
                        theme={theme}
                        control={control}
                        errors={errors}
                      />
                    )}
                  </Box>
                </Box>

                <NotificationLevels control={control} />

                <Divider />

                <SelectAlertFrequency
                  defaultValue={getCooldownIntervalFromSeconds(rule.eventCooldownSeconds)}
                  control={control}
                  theme={theme}
                />

                <Divider />
                {reciepientsData && (
                  <RecipientsInput
                    theme={theme}
                    control={control}
                    data={reciepientsData}
                    errors={errors}
                  />
                )}
                <Divider />

                <Box
                  display={'flex'}
                  alignContent={'space-between'}
                  justifyContent={'space-between'}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ margin: theme.spacing(2) }}
                  >
                    {i18n.text('pdm.configure.alert.dialog.button')}
                  </Button>
                </Box>
              </DialogContent>
            </form>
          </>
        )}
      </Dialog>
    </>
  )
}
