import React, { FC } from 'react'
import { Box, BoxProps, Chip, ChipProps, chipClasses, styled } from '@mui/material'

import { CrossIcon } from '../../../icons'
import { ItemsAlignment } from '../../../../types/types'

/**
 * @description - StyledChips is a component to display a list of chips
 * @param {string[] | ChipType[]} items - Chips data
 * @param {string} ChipType[label] - A name of a chip
 * @param {string} ChipType[id] - An id of a chip
 * @param {ItemsAlignment} align - Align a list of chips
 * @param {function(item: string): void} handleDelete - If set, there is a cross icon on the right. This function handles removing chips from a chips list
 * @param {{
 *  chip: ChipProps
 *  chips: BoxProps
 * }} slotProps - Additional props, in case a developer wants to customize whether chips wrapper or a chip itself
 */

type ChipType = {
  label: string
  id: string
}

type IMuiStyledChipProps = {
  item: string | ChipType
  slotProps?: ChipProps
  handleDelete?: (item: string) => void
}

interface IStyledChipsLayoutProps {
  items: string[] | ChipType[]
  align?: ItemsAlignment
  handleDelete?: (item: string) => void
  slotProps?: {
    chip?: ChipProps
    chips?: BoxProps
  }
}

const ChipsWrapper = styled(Box)({
  display: 'flex',
  gap: '0.25rem',
  flexWrap: 'wrap',
})

const StyledChip = styled(Chip)(({ theme }) => ({
  display: 'flex',
  padding: '0.125rem 0.375rem',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  gap: theme.spacing(0.75),
  [`.${chipClasses.deleteIcon}`]: {
    marginRight: 0,
  },
}))

/** A single chip component */
export const MuiStyledChip: FC<IMuiStyledChipProps> = ({ item, slotProps, handleDelete }) => {
  return (
    <StyledChip
      key={typeof item === 'string' ? item : item.id}
      label={typeof item === 'string' ? item : item.label}
      variant="outlined"
      onDelete={handleDelete ? () => handleDelete(typeof item === 'string' ? item : item.id) : undefined}
      sx={{
        '> span': {
          padding: handleDelete ? '0 6px 0 0' : '0',
        },
      }}
      deleteIcon={
        handleDelete ? (
          <CrossIcon
            sx={{
              width: '0.5rem',
              height: '0.5rem',
              marginRight: 0,
            }}
          />
        ) : undefined
      }
      {...slotProps}
    />
  )
}

/** A list of chips */
export const StyledChipsLayout = ({
  items,
  align = ItemsAlignment.CENTER,
  slotProps,
  handleDelete,
}: IStyledChipsLayoutProps) => {
  return (
    <ChipsWrapper
      sx={{ justifyContent: align }}
      {...slotProps?.chips}
    >
      {items.map(item => (
        <MuiStyledChip
          key={typeof item === 'string' ? item : item.id}
          item={item}
          slotProps={slotProps?.chip}
          handleDelete={handleDelete}
        />
      ))}
    </ChipsWrapper>
  )
}
