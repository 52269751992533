import { Button, Typography, styled } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  padding: '0.625rem 1rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.375rem',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: '1.5rem',
  textTransform: 'capitalize',
  '&.Mui-disabled': {
    background: theme.palette.SFIGreyLight[100],
    borderColor: theme.palette.SFIGreyLight[200],
    color: theme.palette.SFIGreyLight[400],
  },
}))

export const StyledOutlinedButton = styled(StyledButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  background: theme.palette.SFIBase.white,
  color: theme.palette.SFIGreyLight[700],
  '&:hover': {
    background: theme.palette.SFIGreyLight[50],
  },
  '&:focus-visible': {
    boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #98a2b324',
  },
  '&:focus': {
    boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #98a2b324',
  },
}))

export const StyledPrimaryButton = styled(StyledButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.SFIBrand?.[900]}`,
  background: theme.palette.SFIBrand?.[900],
  color: theme.palette.SFIBase.white,
  '&:hover': {
    background: theme.palette.SFIBrand?.[950],
    borderColor: theme.palette.SFIBrand?.[950],
  },
  '&:focus-visible': {
    boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #1d7afd3d',
  },
  '&:focus': {
    boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #1d7afd3d',
  },
}))

export const StyledErrorButton = styled(StyledButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.SFIOrange[300]}`,
  color: theme.palette.error.dark,
  '&:hover': {
    background: theme.palette.SFIOrange?.[50],
  },
  '&:focus-visible': {
    boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #f044383d',
  },
  '&:focus': {
    boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #f044383d',
  },
}))

export const StyledDangerButton = styled(StyledButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.error.contrastText}`,
  background: theme.palette.error.contrastText,
  color: theme.palette.SFIBase.white,
  '&:hover': {
    background: theme.palette.error.dark,
  },
  '&:focus-visible': {
    boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #f044383d',
  },
  '&:focus': {
    boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #f044383d',
  },
}))

export const StyledTextButton = styled(StyledButton)(({ theme }) => ({
  textTransform: 'none',
  marginLeft: '-0.5rem',
  color: theme.palette.SFIBrand[800],
  border: 'none',
  boxShadow: 'none',
  padding: '0.5rem 0.75rem',
  '&:hover': {
    background: theme.palette.SFIBrand[50],
    border: `none`,
    borderRadius: '0.5rem',
    padding: '0.5rem 0.75rem',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const AddButton = styled(Button)(({ theme }) => ({
  color: theme.palette.SFIBrand[700],
  display: 'flex',
  alignItems: 'center',
  gap: '0.38rem',
  cursor: 'pointer',
  textTransform: 'none',
  width: 'fit-content',
  p: {
    color: theme.palette.SFIBrand[700],
  },
  '&:hover': {
    background: theme.palette.SFIGreyLight[50],
  },
  '&:focus': {
    boxShadow: '0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
  },
}))

export const AddButtonText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1.25rem',
}))
