import React, { FC, useState } from 'react'
import { Box, Button, FormLabel, useTheme } from '@mui/material'

import { ID } from '../../../../../Shared/types/types'
import { OutlinedStyledButton } from '../../../../../Shared/components/MUIComponents/update/OutlinedStyledButton'
import { PlusIcon } from '../../../../../Shared/components/icons/PlusIcon'
import { StyledDialogModal } from '../../../../../Shared/components/MUIComponents/StyledDialogModal'
import { StyledTextField } from '../../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { useCreateOeeCustomerProductionStopReasonsBatchMutation } from '../../../../../Shared/graphql/codegen'
import { useCurrentUser } from '../../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../../Shared/contexts/ToastContext'

const DEFAULT_AMOUNT_OF_INPUTS = 6
const AMOUNT_OF_NEW_INPUTS = 2

type CreateStopReasonsProps = {
  onUpdate: () => void
}

const CreateStopReasons: FC<CreateStopReasonsProps> = ({ onUpdate }) => {
  const [amountOfInputs, setAmountOfInputs] = useState<number>(DEFAULT_AMOUNT_OF_INPUTS)
  const [inputValues, setInputValues] = useState<string[]>(Array(DEFAULT_AMOUNT_OF_INPUTS).fill(''))
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { selectedCustomer } = useCurrentUser()
  const { showToast } = useToastContext()
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const onError = (error: string) => showToast(error, 'error', 'Error')

  const onSuccess = () => {
    showToast(i18n.text('general.success.changes-saved'), 'success', 'Success!')
    onUpdate()
  }

  const [createStopReasons, { loading: isCreating }] = useCreateOeeCustomerProductionStopReasonsBatchMutation({
    awaitRefetchQueries: true,
    onCompleted: async () => onSuccess(),
    onError: async () => onError(i18n.text('general.error.something-went-wrong')),
  })

  const handleModalOpen = () => {
    setIsOpen(true)
  }

  const handleModalClose = () => {
    setAmountOfInputs(DEFAULT_AMOUNT_OF_INPUTS)
    setInputValues(Array(DEFAULT_AMOUNT_OF_INPUTS).fill(''))
    setIsOpen(false)
  }

  const handleAddMore = () => {
    setAmountOfInputs(amountOfInputs + AMOUNT_OF_NEW_INPUTS)
  }

  const handleInputChange = (index: number, value: string) => {
    const newInputValues = [...inputValues]
    newInputValues[index] = value
    setInputValues(newInputValues)
  }

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const createdStopReasons = inputValues.filter(inputValue => inputValue.trim() !== '')

    if (createdStopReasons.length === 0) return

    await createStopReasons({
      variables: {
        customerId: selectedCustomer.id as ID,
        texts: createdStopReasons,
      },
    })

    handleModalClose()
  }

  return (
    <>
      <Button
        sx={{
          display: 'flex',
          textTransform: 'none',
        }}
        onClick={handleModalOpen}
        color="secondary"
        variant="contained"
      >
        Create stop reasons
      </Button>
      <StyledDialogModal
        title={i18n.text('settings.oee-settings.stop-reasons.create')}
        subtitle={i18n.text('settings.oee-settings.stop-reasons.create.description')}
        isOpen={isOpen}
        isLoading={false}
        onClose={handleModalClose}
        height="responsive"
      >
        <Box
          component="form"
          onSubmit={handleFormSubmit}
        >
          <Box sx={{ display: 'grid', rowGap: '0.75rem', marginBottom: '2rem', paddingTop: '0.25rem' }}>
            <Box sx={{ display: 'grid', rowGap: '0.4rem' }}>
              <FormLabel sx={{ fontSize: '0.875rem', fontWeight: 500, color: theme.palette.text.primary }}>
                Stop reasons
              </FormLabel>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  columnGap: '1rem',
                  rowGap: '0.75rem',
                }}
              >
                {Array.from({ length: amountOfInputs }).map((_, index) => (
                  <StyledTextField
                    key={`stop-reason-${index}`}
                    type="text"
                    placeholder={i18n.text('settings.oee-settings.stop-reasons.create.input-placeholder')}
                    size="small"
                    variant="outlined"
                    value={inputValues[index] ?? ''}
                    onChange={e => handleInputChange(index, e.target.value)}
                  />
                ))}
              </Box>
            </Box>
            <Box>
              <Button
                sx={{
                  display: 'flex',
                  gap: '0.4rem',
                  alignItems: 'center',
                  textTransform: 'none',
                  fontWeight: 600,
                  lineHeight: '1.25rem',
                }}
                type="button"
                color="secondary"
                onClick={handleAddMore}
              >
                <PlusIcon
                  sx={{
                    width: '1.25rem',
                    height: '1.25rem',
                  }}
                />
                {i18n.text('settings.oee-settings.stop-reasons.create.add-more')}
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: theme.spacing(1) }}>
            <OutlinedStyledButton
              type="reset"
              onClick={handleModalClose}
            >
              {i18n.text('generic.cancel')}
            </OutlinedStyledButton>
            <Button
              sx={{ textTransform: 'none', fontWeight: 600 }}
              type="submit"
              disabled={isCreating}
              variant="contained"
              color="secondary"
            >
              {i18n.text('generic.save')}
            </Button>
          </Box>
        </Box>
      </StyledDialogModal>
    </>
  )
}

export default CreateStopReasons
