import { createSvgIcon } from '@mui/material'

export const Modem = createSvgIcon(
  <svg viewBox="0 0 20 20">
    <path
      d="M5.17064 8.7982C4.77153 7.30798 5.07262 5.66926 6.07392 4.40309M14.8294 8.79816C15.2285 7.30794 14.9274 5.66922 13.9261 4.40305M1.95156 9.66633C1.25327 7.06238 1.8379 4.18602 3.70546 2.03821M18.0456 9.66637C18.7439 7.06242 18.1592 4.18606 16.2917 2.03824M10 12.5V7.49997M4.58336 15.4166H7.91669M4.33336 18.3333H15.6667C16.6001 18.3333 17.0668 18.3333 17.4233 18.1517C17.7369 17.9919 17.9919 17.7369 18.1517 17.4233C18.3334 17.0668 18.3334 16.6001 18.3334 15.6666V15.1666C18.3334 14.2332 18.3334 13.7665 18.1517 13.41C17.9919 13.0964 17.7369 12.8414 17.4233 12.6816C17.0668 12.5 16.6001 12.5 15.6667 12.5H4.33335C3.39993 12.5 2.93322 12.5 2.5767 12.6816C2.2631 12.8414 2.00813 13.0964 1.84834 13.41C1.66669 13.7665 1.66669 14.2332 1.66669 15.1666V15.6666C1.66669 16.6001 1.66669 17.0668 1.84834 17.4233C2.00813 17.7369 2.2631 17.9919 2.5767 18.1517C2.93322 18.3333 3.39993 18.3333 4.33336 18.3333Z"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'modem'
)
