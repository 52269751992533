import { useCallback, useMemo } from 'react'

import {
  CycleTime,
  GetOeeProductionMachineViewQuery,
  Measurement,
  OeeProductionStop,
  Oee_Production_Speed_Unit_Enum,
} from '../../Shared/graphql/codegen'
import { DataIntervalMode } from '../types/Time.types'
import { Machine } from '../types/Assets.types'
import { OeeMetricsResponse } from '../types/OeeMetrics.type'
import { convertFromCycleTimeSeconds, round } from '../utils/unitConversions'
import { getHasPerformanceWarnings } from '../utils/helpers'
import { transformMachineViewProductionSpeedData, transformOeeMetrics } from '../utils/dataTransformations'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../context/OEEContext'

type MachineViewData = {
  machineViewData: GetOeeProductionMachineViewQuery | undefined
  dataIntervalMode: DataIntervalMode
  selectedMachineObject: Machine | undefined
}

export const useMachineViewData = ({ machineViewData, dataIntervalMode, selectedMachineObject }: MachineViewData) => {
  const { units } = useOEEContext()
  const { i18n } = useI18nContext()

  const isCycleTime = units.speed === Oee_Production_Speed_Unit_Enum.CycleTimeSeconds

  const dailyData = machineViewData?.productionMachineView?.daily
  const shiftData = machineViewData?.productionMachineView?.shift

  const targetGraphLineName = i18n.text(
    isCycleTime ? 'oee.speed-graph.tooltip.target-cycle-time' : 'oee.speed-graph.tooltip.target-speed'
  )

  const oeeMetrics = useMemo(
    () => ({
      [DataIntervalMode.DAY]: transformOeeMetrics(dailyData?.oeeMetrics as OeeMetricsResponse),
      [DataIntervalMode.SHIFT]: transformOeeMetrics(shiftData?.oeeMetrics as OeeMetricsResponse),
    }),
    [dailyData?.oeeMetrics, shiftData?.oeeMetrics]
  )

  const productionSpeedData = useMemo(
    () =>
      ({
        [DataIntervalMode.DAY]: dailyData?.productionSpeeds ?? [],
        [DataIntervalMode.SHIFT]: shiftData?.productionSpeeds ?? [],
      } as Record<DataIntervalMode, Partial<Measurement>[]>),
    [dailyData?.productionSpeeds, shiftData?.productionSpeeds]
  )

  const targetCycleTimes = useMemo(
    () =>
      ({
        [DataIntervalMode.DAY]: dailyData?.targetCycleTimes ?? [],
        [DataIntervalMode.SHIFT]: shiftData?.targetCycleTimes ?? [],
      } as Record<DataIntervalMode, Partial<CycleTime>[]>),
    [dailyData?.targetCycleTimes, shiftData?.targetCycleTimes]
  )

  const hasPerformanceWarning = getHasPerformanceWarnings(targetCycleTimes[dataIntervalMode])

  const productionStops = useMemo<Record<DataIntervalMode, OeeProductionStop[]>>(() => {
    return {
      [DataIntervalMode.DAY]: dailyData?.stops.map(stop => stop?.stop as OeeProductionStop) ?? [],
      [DataIntervalMode.SHIFT]: shiftData?.stops.map(stop => stop?.stop as OeeProductionStop) ?? [],
    }
  }, [dailyData?.stops, shiftData?.stops])

  const getUnexplainedStopsCount = useCallback(() => {
    return productionStops[dataIntervalMode].filter(stop => stop.productionStopReasonLogs.length === 0).length
  }, [dataIntervalMode, productionStops])

  const productionSpeedSeries = useMemo(
    () => transformMachineViewProductionSpeedData(selectedMachineObject, productionSpeedData, units, dataIntervalMode),
    [dataIntervalMode, productionSpeedData, selectedMachineObject, units]
  )

  const cycleTimes = targetCycleTimes[dataIntervalMode]

  const currentCycleTime = convertFromCycleTimeSeconds(
    Number(cycleTimes[cycleTimes.length - 1]?.valueInSeconds),
    units.speed
  )

  const targetCycleTimeSeries = useMemo(() => {
    return {
      name: targetGraphLineName,
      data: cycleTimes.map(targetCycleTime => {
        return {
          x: targetCycleTime?.time,
          y: round(convertFromCycleTimeSeconds(targetCycleTime?.valueInSeconds ?? 0, units.speed), 1),
        }
      }),
    }
  }, [cycleTimes, targetGraphLineName, units.speed])

  const series = [productionSpeedSeries, targetCycleTimeSeries]

  return {
    oeeMetrics,
    series,
    productionStops,
    getUnexplainedStopsCount,
    hasPerformanceWarning,
    currentCycleTime: round(currentCycleTime, 1),
  }
}
