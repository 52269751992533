import React, { FC } from 'react'
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  dividerClasses,
  listClasses,
  listItemIconClasses,
  paperClasses,
  styled,
  typographyClasses,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { CodeIcon } from '../../../Shared/components/icons/CodeIcon'
import { HelpIcon, LinkExternalIcon, LogOutIcon, SettingsIcon, SuccessIcon } from '../../../Shared/components/icons'
import { useAuthContext } from '../../../Shared/contexts/AuthContext'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface SettingsMenuProps {
  handleMenuClose: () => void
  menuAnchorEl: HTMLElement | null
}

export const NewSettingsMenu: FC<SettingsMenuProps> = ({ handleMenuClose, menuAnchorEl }) => {
  const { i18n } = useI18nContext()
  const { logout } = useAuthContext()
  const { user } = useCurrentUser()
  const supportPathName = 'paths.knowledge-base'

  const handleSupportButtonClick = (path: string) => {
    window.open(i18n.text(path), '_blank', 'noopener,noreferrer') // using the i18n system to set knowledge base link from backoffice
  }

  const menuItems = [
    {
      name: 'user-bar.settings',
      path: '/settings/main',
      icon: <SettingsIcon />,
    },
    {
      name: 'router.multi.settings.status',
      path: '/installation/sensors',
      icon: <SuccessIcon />,
    },
    {
      name: 'api.keys',
      path: '/settings/integrations',
      icon: <CodeIcon />,
    },
    {
      name: 'settings.menu.support',
      icon: <HelpIcon />,
      extraIcon: <LinkExternalIcon />,
      onClick: () => handleSupportButtonClick(supportPathName),
    },
    {
      name: 'user.logout',
      icon: <LogOutIcon />,
      onClick: logout,
    },
  ]

  return (
    <StyledMenu
      anchorEl={menuAnchorEl}
      open={!!menuAnchorEl}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 3,
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <StyledTopMenuItem disableRipple>
        <StyledBox>
          <NameTypography>{user.name}</NameTypography>
          <EmailTypography>{user.email}</EmailTypography>
        </StyledBox>
      </StyledTopMenuItem>
      {menuItems.map(item => {
        if (item.path) {
          return (
            <StyledMenuItem
              key={item.name}
              to={item.path}
              component={RouterLink}
              onClick={handleMenuClose}
              disableRipple
            >
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              <StyledListItemText primary={i18n.text(item.name)} />
            </StyledMenuItem>
          )
        }
        return [
          <Divider />,
          <StyledMenuItem
            key={item.name}
            onClick={item.onClick}
            disableRipple
          >
            <StyledListItemIcon>{item.icon}</StyledListItemIcon>
            <StyledListItemText primary={i18n.text(item.name)} />
            {item.extraIcon && <StyledListItemIcon>{item.extraIcon}</StyledListItemIcon>}
          </StyledMenuItem>,
        ]
      })}
    </StyledMenu>
  )
}

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  [`.${typographyClasses.root}`]: {
    color: theme.palette.SFIGreyLight[700],
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.25rem',
    padding: 0,
  },
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[500],
  svg: {
    fontSize: '1rem',
  },
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: '0.06rem 0.38rem',
  padding: '0.63rem 0.5rem',
  borderRadius: '0.375rem',
  '&:hover': {
    background: theme.palette.SFIGreyLight[100],
  },
  [`&+.${dividerClasses.root}`]: {
    margin: '0.25rem 0',
    borderColor: theme.palette.SFIGreyLight[200],
  },
  [`.${listItemIconClasses.root}`]: {
    minWidth: '1.5rem',
    '&:last-of-type': {
      minWidth: 'auto',
    },
  },
})) as typeof MenuItem

const NameTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[700],
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1.25rem',
}))

const EmailTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
}))

const StyledTopMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: '0 0 0.75rem',
  margin: '0 0 0.25rem',
  cursor: 'auto',
  borderBottom: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  '&:hover': {
    background: 'none',
  },
}))

const StyledBox = styled(Box)({
  margin: '0 1rem',
})

const StyledMenu = styled(Menu)(({ theme }) => ({
  [`.${paperClasses.root}`]: {
    padding: '0.75rem 0 0.25rem',
    marginTop: '0.5rem',
    overflow: 'visible',
    borderRadius: '0.5rem',
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    [`.${listClasses.root}`]: {
      padding: 0,
    },
  },
}))
