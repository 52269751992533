import React, { FC, useState } from 'react'
import { Box, Button, Typography, styled, useTheme } from '@mui/material'
import { DateTime } from 'luxon'

import ExplainStopModal from '../ExplainStopModal/ExplainStopModal'
import StatusCard from '../../../Shared/components/MUIComponents/update/StatusCard/StatusCard'
import { DEFAULT_TIMEZONE } from '../../../Shared/constants/timezone'
import { OeeProductionStop } from '../../../Shared/graphql/codegen'
import { PRODUCTION_STATISTICS_DATE_FORMAT } from '../../utils/constants'
import { WarningIcon } from '../../../Shared/components/icons'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type ProductionStopItemProps = {
  stop: OeeProductionStop
  assetId: string
  onListUpdate: () => void
}

const StyledWarningBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0.25rem 0.5rem',
  gap: theme.spacing(1),
  backgroundColor: theme.palette.SFIWarning[100],
  fontSize: '1rem',
  borderRadius: '6px',
  color: theme.palette.SFIWarning[700],
}))

const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  '& span': {
    fontWeight: '500',
  },
}))

const StyledStopReason = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  padding: '0.1rem 0.4rem',
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  borderRadius: '6px',
}))

const ProductionStopsDrawerItem: FC<ProductionStopItemProps> = ({ stop, assetId, onListUpdate }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const [isExplainModalOpen, setIsExplainModalOpen] = useState<boolean>(false)

  const { selectedCustomer } = useCurrentUser()
  const timezone = selectedCustomer?.timeZone ?? DEFAULT_TIMEZONE

  const isExplained = stop.productionStopReasonLogs.length > 0

  const startTime = DateTime.fromISO(stop.startTime).setZone(timezone)
  const endTime = stop.endTime ? DateTime.fromISO(stop.endTime).setZone(timezone) : null

  const formattedStartTime = startTime.toFormat(PRODUCTION_STATISTICS_DATE_FORMAT)
  const formattedEndTime = endTime ? endTime.toFormat(PRODUCTION_STATISTICS_DATE_FORMAT) : '-'

  const duration = endTime
    ? Math.round(endTime.diff(startTime).as('minutes'))
    : i18n.text('oee.stop-logging.drawer.item.modal.duration.ongoing')

  const formattedDuration =
    typeof duration === 'number'
      ? `${i18n.number(duration)} ${
          duration === 1
            ? i18n.text('oee.stop-logging.drawer.item.minute')
            : i18n.text('oee.stop-logging.drawer.item.minutes')
        }`
      : duration

  const stopReason = stop.productionStopReasonLogs?.[0]?.customerProductionStopReason?.text

  const stopData = {
    stopId: stop.id,
    assetId: assetId,
    stopLogId: stop.productionStopReasonLogs?.[0]?.id,
    stopReason: {
      id: stop.productionStopReasonLogs?.[0]?.customerProductionStopReason.id,
      text: stop.productionStopReasonLogs?.[0]?.customerProductionStopReason.text,
    },
    operatorFeedback: stop.productionStopReasonLogs?.[0]?.operatorFeedback ?? '',
    duration: duration,
    startTime: formattedStartTime,
  }

  const handleStopReasonClick = () => {
    setIsExplainModalOpen(true)
  }

  const handleExplainStopModalClose = () => {
    setIsExplainModalOpen(false)
  }

  return (
    <>
      <StatusCard status={!isExplained ? 'warning' : 'grey'}>
        {!isExplained && (
          <StyledWarningBox>
            <WarningIcon sx={{ color: theme.palette.warning.main, width: '1.125rem', height: '1.125rem' }} />
            {i18n.text('oee.stop-logging.drawer.item.warning-text')}
          </StyledWarningBox>
        )}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'minmax(auto, 300px) auto',
            alignItems: 'end',
            columnGap: theme.spacing(1),
          }}
        >
          <Box sx={{ display: 'grid', rowGap: theme.spacing(0.5) }}>
            <StyledDescription>
              {i18n.text('oee.stop-logging.drawer.item.start-time')}: <span>{formattedStartTime}</span>
            </StyledDescription>
            <StyledDescription>
              {i18n.text('oee.stop-logging.drawer.item.end-time')}: <span>{formattedEndTime}</span>
            </StyledDescription>
            <StyledDescription>
              {i18n.text('oee.stop-logging.drawer.item.duration')}: <span>{formattedDuration}</span>
            </StyledDescription>
            {isExplained && (
              <StyledDescription>
                {i18n.text('oee.stop-logging.drawer.item.stop-reason')}:{' '}
                <StyledStopReason>{stopReason}</StyledStopReason>
              </StyledDescription>
            )}
          </Box>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={handleStopReasonClick}
            color="secondary"
            variant={!isExplained ? 'contained' : 'outlined'}
          >
            {!isExplained
              ? i18n.text('oee.stop-logging.drawer.item.action-button.explain')
              : i18n.text('oee.stop-logging.drawer.item.action-button.edit')}
          </Button>
        </Box>
      </StatusCard>
      {isExplainModalOpen && (
        <ExplainStopModal
          isOpen={isExplainModalOpen}
          onClose={handleExplainStopModalClose}
          onListUpdate={onListUpdate}
          stop={stopData}
        />
      )}
    </>
  )
}

export default ProductionStopsDrawerItem
