import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  outlinedInputClasses,
  styled,
} from '@mui/material'
import type { History } from 'history'
import { Link } from 'react-router-dom'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'

import actionCreators from './actions'
import type { AuthContext } from '../../../Shared/contexts/AuthContext'
import type { CurrentUser } from '../../../Shared/types/types'
import { RootState } from '../../store/configureStore'
import { SFIndigoMuiTheme } from '../../../Shared/theme/MuiThemeProvider'
import { StyledTextField } from '../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { UnauthorizedPageLayout } from '../UnauthorizedPageLayout/UnauthorizedPageLayout'
import { browserStorage } from '../../../Shared/utils'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type LoginPageProps = {
  actions: typeof actionCreators
  error: string | null
  history: History
  pending: boolean
  login: AuthContext['login']
  user: CurrentUser | null
}

type LoginState = {
  rememberMe: boolean
  username: string
}

const SFLoginUsername = 'sf-login-username'
const SFLoginRemember = 'sf-login-remember'

const Remembered = {
  read: (): LoginState => {
    const username = browserStorage.get<string>(SFLoginUsername).value
    return {
      rememberMe: browserStorage.get<boolean>(SFLoginRemember).value || false,
      username: username ? atob(username) : '',
    }
  },
  write: ({ rememberMe, username }: LoginState) => {
    browserStorage.set(SFLoginUsername, btoa(username))
    browserStorage.set(SFLoginRemember, String(rememberMe))
  },
} as const

const Label = styled('label')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem',
  fontWeight: 500,
}))

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.SFIBrand[700],
  textDecoration: 'none',
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem',
  fontWeight: 600,
}))

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBrand[900],
  color: theme.palette.SFIBase.white,
  fontFamily: theme.typography.fontFamily,
  fontSize: '1rem',
  fontWeight: 600,
  padding: '0.75rem 1rem',
  width: '100%',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.SFIBrand[950],
  },
  '&:disabled': {
    backgroundColor: theme.palette.SFIGreyLight[100],
    color: theme.palette.SFIGreyLight[400],
  },
}))

const CustomStyledTextField = styled(StyledTextField)(({ theme }) => ({
  maxWidth: '100%',
  [`.${outlinedInputClasses.input}`]: {
    color: theme.palette.SFIBase.black,
    padding: '0.625rem 0.875rem',
  },
}))

const StyledError = styled('span')(({ theme }) => ({
  color: theme.palette.SFIError[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  fontFamily: theme.typography.fontFamily,
  marginTop: '0.125rem',
}))

function LoginPage({ history, user, error, pending, login, actions }: LoginPageProps) {
  const { i18n } = useI18nContext()

  const [username, setUsername] = useState(Remembered.read().username)
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [rememberMe, setRememberMe] = useState(Remembered.read().rememberMe)

  const handleRememberMe = () => setRememberMe(!rememberMe)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()

  useEffect(() => {
    if (user) {
      history.replace('/')
    }
  }, [])

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (pending) {
      return
    }

    if (rememberMe) {
      Remembered.write({ rememberMe, username })
    } else {
      Remembered.write({ rememberMe: false, username: '' })
    }
    actions.getAccessToken(username.toLowerCase(), password, login, history)
  }

  return (
    <UnauthorizedPageLayout title="generic.log-in-title">
      <form {...{ onSubmit }}>
        <FormGroup sx={{ marginBottom: '1.25rem' }}>
          <Label htmlFor="username">{i18n.text('forms.profile.email')}</Label>
          <CustomStyledTextField
            id="username"
            type="email"
            name="username"
            autoComplete="username"
            placeholder={i18n.text('forms.profile.email')}
            value={username}
            onChange={event => setUsername(event.target.value)}
            sx={{ maxWidth: '100%' }}
            error={Boolean(error)}
          />
        </FormGroup>
        <FormGroup sx={{ marginBottom: '1.25rem' }}>
          <Label htmlFor="password">{i18n.text('forms.login.password')}</Label>
          <CustomStyledTextField
            id="password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            autoComplete="current-password"
            placeholder={i18n.text('forms.login.password')}
            value={password}
            onChange={event => setPassword(event.target.value)}
            sx={{ maxWidth: '100%' }}
            error={Boolean(error)}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ margin: 0 }}
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && <StyledError>{error}</StyledError>}
        </FormGroup>
        <FormGroup
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '1.5rem',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: SFIndigoMuiTheme.palette.SFIGreyLight[300],
                  '&.Mui-checked': {
                    color: SFIndigoMuiTheme.palette.SFIBrand[600],
                  },
                }}
              />
            }
            label={i18n.text('forms.login.remember')}
            checked={rememberMe}
            onChange={handleRememberMe}
          />
          <StyledLink to="/setpassword">{i18n.text('user.password.reset')}</StyledLink>
        </FormGroup>
        <StyledButton
          disabled={pending}
          type="submit"
        >
          {i18n.text('forms.login.submit')}
        </StyledButton>
      </form>
    </UnauthorizedPageLayout>
  )
}

const mapStateToProps = ({ app, login }: RootState) => ({
  app,
  pending: login.get('pending'),
  error: login.get('error'),
})
const mapDispatchToProps = (dispatch: $TSFixMeDispatch) => ({
  actions: bindActionCreators({ ...actionCreators }, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
