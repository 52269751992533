import React, { FC, forwardRef } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { DateTime, Interval } from 'luxon'

import { ArrowLeft } from '../../../icons/ArrowLeftIcon'
import { DateFormat } from './types'
import { OutlinedStyledButton, OutlinedStyledIconButton } from '../OutlinedStyledButton'
import { useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

const MILIS_IN_ONE_DAY = 86399999

const ArrowRight = styled(ArrowLeft)({
  transform: 'rotate(180deg)',
})

interface IDateTimeRangePickerButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  applyDates: (from?: DateTime | null, to?: DateTime | null) => void
  customDateRangeButtonLabel?: string
  fromDate: DateTime | null
  toDate: DateTime | null
  compareFromDate: DateTime | null
  compareToDate: DateTime | null
  dateFormat: DateFormat
  ref?: React.Ref<HTMLButtonElement>
}

const CompareText = styled(Typography)(({ theme }) => ({
  fontSize: '10px',
  lineHeight: '16px',
  fontWeight: 400,
  color: theme.palette.SFIGreyLight[600],
}))

export const DateTimeRangePickerAnchorButton: FC<IDateTimeRangePickerButtonProps> = forwardRef(
  ({ onClick, fromDate, toDate, compareFromDate, compareToDate, dateFormat, customDateRangeButtonLabel }, ref) => {
    const { i18n } = useI18nContext()
    const {
      isCompareDatesOn,
      hasPrevNextPeriodButtons,
      handleStartEndDates,
      handleCompareStartEndDates,
      setCurrentDate,
      onDatesChange,
      onCompareDatesChange,
    } = useDateTimeRangePickerContext()

    const navigateToPeriod = (action: 'plus' | 'minus') => {
      if (fromDate && toDate) {
        const { startDate, endDate } = getNewStartEndDates(fromDate, toDate, action)

        handleStartEndDates({
          startDate,
          endDate,
        })
        setCurrentDate(endDate)
        onDatesChange({
          startDate,
          endDate,
        })
      }
      if (compareFromDate && compareToDate) {
        const { startDate, endDate } = getNewStartEndDates(compareFromDate, compareToDate, action)
        if (handleCompareStartEndDates) {
          handleCompareStartEndDates({
            startDate,
            endDate,
          })
          if (onCompareDatesChange) {
            onCompareDatesChange({
              startDate,
              endDate,
            })
          }
        }
      }
    }

    function getNewStartEndDates(fromDate: DateTime, toDate: DateTime, action: 'plus' | 'minus') {
      const dailyDuration = toDate.startOf('day').diff(fromDate.startOf('day'), ['days']).days
      const durationBetweenFromAndTo = Interval.fromDateTimes(fromDate.startOf('minute'), toDate.endOf('minute'))
      const isDurationDaily = fromDate.hour === 0 && fromDate.minute === 0 && toDate.hour === 23 && toDate.minute === 59
      let newStartDate = null
      if (isDurationDaily) {
        newStartDate = fromDate[action]({ days: dailyDuration + 1 })
      } else {
        newStartDate =
          action === 'minus'
            ? fromDate[action](durationBetweenFromAndTo.toDuration()).plus({ minutes: 1 }).startOf('minute')
            : fromDate[action](durationBetweenFromAndTo.toDuration()).startOf('minute')
      }

      let newEndDate = null

      if (isDurationDaily) {
        newEndDate = toDate[action]({ days: dailyDuration + 1 })
      } else {
        newEndDate = newStartDate.plus(durationBetweenFromAndTo.toDuration()).endOf('minute')
      }

      return { startDate: newStartDate, endDate: newEndDate }
    }

    const getFormattedDateText = (date: DateTime | null, type: 'start' | 'end') => {
      const dateJS = date?.toJSDate()
      return date && date.isValid ? i18n.date(dateJS, dateFormat) : i18n.text(`datetimepicker.${type}-date`)
    }

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        {hasPrevNextPeriodButtons && (
          <OutlinedStyledIconButton
            size="small"
            disabled={!fromDate || !toDate}
            onClick={() => navigateToPeriod('minus')}
          >
            <ArrowLeft />
          </OutlinedStyledIconButton>
        )}
        <OutlinedStyledButton
          testId="date-picker-button"
          onClick={onClick}
          ref={ref}
          sx={{
            width: '185px',
          }}
        >
          {customDateRangeButtonLabel ? (
            customDateRangeButtonLabel
          ) : (
            <>
              {`${getFormattedDateText(fromDate, 'start')} / ${getFormattedDateText(toDate, 'end')}`}
              {isCompareDatesOn && (
                <CompareText variant="body1">
                  {`${i18n.text('contract-benchmark.step-1.compare')}: 
                    ${getFormattedDateText(compareFromDate, 'start')} / 
                    ${getFormattedDateText(compareToDate, 'end')}`}
                </CompareText>
              )}
            </>
          )}
        </OutlinedStyledButton>
        {hasPrevNextPeriodButtons && (
          <OutlinedStyledIconButton
            size="small"
            disabled={!fromDate || !toDate}
            onClick={() => navigateToPeriod('plus')}
          >
            <ArrowRight />
          </OutlinedStyledIconButton>
        )}
      </Box>
    )
  }
)
