import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { useFeature } from 'flagged'

import AssetTreeLayout from '../Layouts/AssetTreeLayout'
import DataIntervalModeSwitch from '../../components/DataIntervalModeSwitch/DataIntervalModeSwitch'
import Error from '../../../Shared/components/MUIComponents/Error'
import Loader from '../../../Shared/components/MUIComponents/Loader'
import MachineStatistics from '../../components/MachineStatistics/MachineStatistics'
import OEEMetrics from '../../components/OEEMetrics/OEEMetrics'
import ProductionSpeed from '../../components/ProductionSpeed/ProductionSpeed'
import ProductionStopsBanner from '../../components/ProductionStopsBanner/ProductionStopsBanner'
import ProductionStopsDrawer from '../../components/ProductionStopsDrawer/ProductionStopsDrawer'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import SetTargetSpeedModal from '../../components/SetTargetSpeedModal/SetTargetSpeedModal'
import useAssetTreeMode from '../../hooks/useAssetTreeMode'
import useLocationId from '../../hooks/useLocationId'
import { ArrowRight, TargetIcon } from '../../../Shared/components/icons'
import { AssetProductionSpeed, AssetTreeMode } from '../../types/Assets.types'
import { DataIntervalMode, Time } from '../../types/Time.types'
import { ID } from '../../../Shared/types/types'
import { OEE_STOP_LOGGING_DISABLED } from '../../utils/constants'
import { Oee_Production_Speed_Unit_Enum, useGetOeeProductionMachineViewQuery } from '../../../Shared/graphql/codegen'
import { OutlinedStyledButton } from '../../../Shared/components/MUIComponents/update/OutlinedStyledButton'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { getFormattedInterval } from '../../utils/helpers'
import { toLocalDateTime } from '../../../Shared/utils'
import { useAuthContext } from '../../../Shared/contexts/AuthContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useMachineViewData } from '../../hooks/useMachineViewData'
import { useMachineViewId } from '../../hooks/useMachineViewId'
import { useOEEContext } from '../../context/OEEContext'

const MachineView = () => {
  const [dataIntervalMode, setDataIntervalMode] = useState<DataIntervalMode>(DataIntervalMode.DAY)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isSetTargetSpeedOpen, setIsSetTargetSpeedOpen] = useState(false)
  const [internalInterval, setInternalInterval] = useState({
    start: toLocalDateTime(DateTime.now().startOf('day')),
    end: toLocalDateTime(DateTime.now()),
  })

  const { currentCustomer } = useAuthContext()
  const { units } = useOEEContext()

  const theme = useTheme()
  const { i18n } = useI18nContext()

  const stopLoggingFeatureDisabled = !!useFeature(OEE_STOP_LOGGING_DISABLED)
  const POLLING_INTERVAL_IN_MS = Time.MillisecondsInMinute * 2 // 2 minutes

  const isCycleTime = units.speed === Oee_Production_Speed_Unit_Enum.CycleTimeSeconds

  const targetSpeedButtonName = i18n.text(
    isCycleTime ? 'oee.machine-view.set-target-cycle-time' : 'oee.machine-view.set-target-speed'
  )

  useMachineViewId()
  useAssetTreeMode(AssetTreeMode.SINGLE_SELECT)

  const { selectedMachineObject, locationId } = useLocationId()

  const {
    data: machineViewData,
    loading: machineViewLoading,
    error: machineViewError,
    startPolling,
    stopPolling,
    refetch,
  } = useGetOeeProductionMachineViewQuery({
    variables: {
      customerId: currentCustomer?.id as ID,
      locationId: locationId as ID,
    },
  })

  useEffect(() => {
    if (!machineViewData?.productionMachineView?.shift) {
      setDataIntervalMode(DataIntervalMode.DAY)
    }
  }, [machineViewData?.productionMachineView?.shift])

  useEffect(() => {
    if (!machineViewData) return

    const syncInterval = () => {
      if (dataIntervalMode === DataIntervalMode.DAY) {
        setInternalInterval({
          start: toLocalDateTime(DateTime.now().startOf('day')),
          end: toLocalDateTime(DateTime.now()),
        })
      }

      if (machineViewData.productionMachineView?.shift && dataIntervalMode === DataIntervalMode.SHIFT) {
        setInternalInterval({
          start: machineViewData.productionMachineView.shift.start,
          end: machineViewData.productionMachineView.shift.end,
        })
      }
    }

    syncInterval()
    startPolling(POLLING_INTERVAL_IN_MS)
    const intervalId = setInterval(syncInterval, POLLING_INTERVAL_IN_MS)

    return () => {
      clearInterval(intervalId)
      stopPolling()
    }
  }, [dataIntervalMode, machineViewData, stopPolling, setInternalInterval, POLLING_INTERVAL_IN_MS, startPolling])

  const { oeeMetrics, series, productionStops, getUnexplainedStopsCount, currentCycleTime, hasPerformanceWarning } =
    useMachineViewData({
      machineViewData,
      dataIntervalMode,
      selectedMachineObject,
    })

  const formattedInterval = getFormattedInterval(
    internalInterval.start,
    internalInterval.end,
    i18n.locale.replace('_', '-')
  )

  const renderFallback = () => {
    if (machineViewLoading) return <Loader />
    if (!selectedMachineObject || machineViewError) return <Error />
    return null
  }

  const handleDataIntervalModeChange = (mode: DataIntervalMode) => {
    setDataIntervalMode(mode)
  }

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  const handleSetTargetSpeedOpen = () => {
    setIsSetTargetSpeedOpen(true)
  }

  const handleSetTargetSpeedClose = () => {
    refetch()
    setIsSetTargetSpeedOpen(false)
  }

  const handleStopsDataUpdate = () => {
    refetch()
  }

  const customOptions = {
    chart: {
      type: 'line' as ApexChart['type'],
    },
    stroke: {
      curve: ['straight', 'stepline'] as ApexStroke['curve'],
      width: [3, 2],
      dashArray: [0, 5],
    },
    tooltip: {
      shared: true,
    },
    colors: [theme.palette.secondary.main, theme.palette.SFIOrangeDark[600]],
  }

  return (
    <AssetTreeLayout>
      {machineViewData ? (
        <>
          <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr', gridRowGap: theme.spacing(2) }}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                columnGap: theme.spacing(1),
                alignItems: 'center',
              }}
            >
              <SectionHeader
                title={selectedMachineObject?.name ?? ''}
                chipContent={`${formattedInterval.start} - ${formattedInterval.end}`}
                shouldShowDatePicker={false}
              >
                <DataIntervalModeSwitch
                  hasShiftData={!!machineViewData?.productionMachineView?.shift}
                  onIntervalChange={handleDataIntervalModeChange}
                  dataIntervalMode={dataIntervalMode}
                />
                <OutlinedStyledButton
                  sx={{ flexDirection: 'row', gap: theme.spacing(1) }}
                  onClick={handleSetTargetSpeedOpen}
                >
                  <TargetIcon sx={{ color: theme.palette.grey[700], width: '1.25rem', height: '1.25rem' }} />
                  {targetSpeedButtonName}
                </OutlinedStyledButton>
                {!stopLoggingFeatureDisabled && (
                  <Button
                    sx={{
                      display: 'flex',
                      gap: '0.5rem',
                      textTransform: 'none',
                    }}
                    onClick={handleDrawerOpen}
                    color="secondary"
                    variant="contained"
                  >
                    <ArrowRight sx={{ color: theme.palette.SFIBase.white, width: '0.8rem' }} />
                    {i18n.text('oee.stop-logging.anchor-label')}
                  </Button>
                )}
              </SectionHeader>
            </Box>
            {!stopLoggingFeatureDisabled && getUnexplainedStopsCount() > 0 && (
              <ProductionStopsBanner
                unexplainedStopsCount={getUnexplainedStopsCount()}
                onDrawerOpen={handleDrawerOpen}
              />
            )}
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: '1rem',

                [theme.breakpoints.up('lg')]: {
                  gridTemplateColumns: 'minmax(max-content, 960px) minmax(max-content, 1fr)',
                },
              }}
            >
              <SectionBoxWrapper>
                <Box sx={{ display: 'grid', rowGap: '1rem' }}>
                  <Typography variant="h6">{i18n.text('oee.machine-view.oee-metrics.title')}</Typography>
                  <OEEMetrics
                    oeeMetrics={oeeMetrics[dataIntervalMode]}
                    warnings={{ performance: hasPerformanceWarning }}
                  />
                </Box>
              </SectionBoxWrapper>
              <MachineStatistics
                mode={dataIntervalMode}
                status={machineViewData?.productionMachineView?.status}
                shift={machineViewData?.productionMachineView?.shift}
                totalCycles={oeeMetrics[dataIntervalMode].totalProductionCycles}
              />
            </Box>
            <Box id="chart-wrapper">
              <ProductionSpeed
                productionSpeedData={series as AssetProductionSpeed[]}
                shouldShowQuickIntervals={false}
                chartHeight={350}
                isToolbarDisabled
                customOptions={customOptions}
              />
            </Box>
          </Box>
          {!stopLoggingFeatureDisabled && selectedMachineObject && (
            <ProductionStopsDrawer
              stops={productionStops[dataIntervalMode]}
              machine={selectedMachineObject}
              isOpen={isDrawerOpen}
              interval={internalInterval}
              onDataChange={handleStopsDataUpdate}
              onDrawerClose={handleDrawerClose}
            />
          )}
          {isSetTargetSpeedOpen && locationId && (
            <SetTargetSpeedModal
              isOpen={isSetTargetSpeedOpen}
              onClose={handleSetTargetSpeedClose}
              machineName={selectedMachineObject?.name ?? ''}
              currentCycleTime={currentCycleTime}
              locationId={locationId}
            />
          )}
        </>
      ) : (
        renderFallback()
      )}
    </AssetTreeLayout>
  )
}

export default MachineView
