import * as Sentry from '@sentry/react'
import jwt_decode from 'jwt-decode'
import { Box } from '@mui/material'
import { Auth as Cognito } from 'aws-amplify'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { isString } from 'type-guards'
import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'

import MUILoader from '../../components/MUIComponents/Loader'
import { AuthApi } from '../../services/AuthApi'
import { AuthContextQuery, CurrentUserFragment, useAuthContextQueryLazyQuery } from '../../graphql/codegen'
import type { CurrentCustomer, CurrentUser, HasuraClaims, JwtPayload } from '../../types/types'
import { Props } from '../../../App/store/types'
import { createCtx, isSensorfactEmail, normalizeUser } from '../../utils'
import { hasuraIdKey } from '../../constants/cognito-hasuraId'

const customGraphQLPath = process.env.REACT_APP_GRAPHQL_URL

export type AuthContext = {
  user: CurrentUser | null
  allowedRoles: string[]
  currentCustomer: CurrentCustomer | null
  login: (username: string, password: string) => Promise<unknown>
  logout: () => Promise<unknown>
  refreshSession: () => Promise<CognitoUserSession>
  isSensorfactEmployee: () => boolean
}

type AuthUser = {
  user: CognitoUser
  allowedRoles: string[]
}

const [useAuthContext, AuthContextReactProvider] = createCtx<AuthContext>()

export const AuthContextProvider = ({ store, children }: Props) => {
  const apolloClient = useApolloClient()

  const [authUser, _setAuthUser] = useState<AuthUser | null | undefined>(undefined)
  const [hasuraUser, setHasuraUser] = useState<AuthContextQuery | null | undefined>(undefined)

  const [getMe] = useAuthContextQueryLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setHasuraUser(data)
    },
    onError: error => {
      setHasuraUser(null)
    },
  })

  const getHasuraUser = () => {
    setHasuraUser(undefined)
    getMe()
  }

  const gqlUser = hasuraUser?.me ? (hasuraUser.me as CurrentUserFragment) : null

  const currentCustomer = hasuraUser?.myOrg ? (hasuraUser.myOrg as CurrentCustomer) : null

  const user = useMemo(() => (gqlUser ? normalizeUser(gqlUser) : null), [gqlUser])

  // get initial cognitoUser state
  useEffect(() => {
    initCognitoUser()
  }, [])

  useEffect(() => {
    if (authUser) {
      getHasuraUser()
    } else if (authUser === null) {
      setHasuraUser(null)
    }
  }, [authUser])

  // Dispatch when "user" changes
  useEffect(() => {
    if (!user) {
      Sentry.setUser(null)
      return
    }
    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.name,
      locale: user.locale,
      customers: user.customers.map(c => c.id),
    })
  }, [user, store])

  const initCognitoUser = async () => {
    try {
      const user = (await Cognito.currentAuthenticatedUser()) as CognitoUser
      const session = await Cognito.currentSession()

      const allowedRoles = getAllowedRoles(session.getIdToken().getJwtToken())
      _setAuthUser({
        user,
        allowedRoles,
      })
    } catch (error) {
      _setAuthUser(null)
    }
  }

  const login = async (username: string, password: string): Promise<CognitoUser> => {
    if (!password || password.length === 0) {
      return Promise.reject(new Error('password field can not be empty.'))
    }

    _setAuthUser(null)
    let retry = false

    try {
      Sentry.addBreadcrumb({
        message: 'Cognito login',
        data: { username },
      })

      await Cognito.signIn(username, password)
      const session = await Cognito.currentSession()
      const user = (await Cognito.currentAuthenticatedUser()) as CognitoUser

      const idToken = session.getIdToken()
      const id = idToken.payload[hasuraIdKey]

      const allowedRoles = getAllowedRoles(idToken.getJwtToken())

      if (!isString(id)) {
        throw new Error('Unexpected user id')
      }

      _setAuthUser({
        user,
        allowedRoles,
      })
      return user
    } catch (error) {
      const e = error as Error

      if (e.name === 'NotAuthorizedException' && e.message === 'Incorrect username or password.') {
        const migrateResult = await AuthApi.migrate(username, password, getHeaders(null))

        if (migrateResult.code === 'RETRY_LOGIN') {
          retry = true
        } else {
          return Promise.reject(new Error(migrateResult.message))
        }

        if (retry) {
          return login(username, password)
        }
      }

      return Promise.reject(error)
    }
  }

  const refreshSession = async (): Promise<CognitoUserSession> => {
    const cognitoUser = (await Cognito.currentAuthenticatedUser()) as CognitoUser
    const currentSession = await Cognito.currentSession()
    const newSession = await _cognitoRefreshSession(cognitoUser, currentSession)

    const idToken = newSession.getIdToken()

    _setAuthUser({
      user: cognitoUser,
      allowedRoles: getAllowedRoles(idToken.getJwtToken()),
    })

    return newSession
  }

  const logout = useCallback(async () => {
    await apolloClient.resetStore()
    await Cognito.signOut()
    _setAuthUser(null)
  }, [apolloClient])

  const _cognitoRefreshSession = (
    user: CognitoUser,
    currentSession: CognitoUserSession
  ): Promise<CognitoUserSession> => {
    return new Promise<CognitoUserSession>((resolve, reject) => {
      user.refreshSession(currentSession.getRefreshToken(), (err, session) => {
        if (err) {
          reject((err as Error).message)
        }

        resolve(session)
      })
    })
  }

  const getAllowedRoles = (jwt: string | null): string[] => {
    if (!jwt) {
      return []
    }

    const decoded = jwt_decode<JwtPayload>(jwt)
    const claims: HasuraClaims = JSON.parse(decoded['https://hasura.io/jwt/claims'])
    const roles = claims['x-hasura-allowed-roles']

    return roles
  }

  const getHeaders = (jwt: string | null): Record<string, string> => {
    const headers: Record<string, string> = {}

    if (!jwt) {
      return headers
    }

    headers['Authorization'] = `Bearer ${jwt}`
    headers['x-hasura-role'] = 'webclient'

    if (customGraphQLPath) {
      const decoded = jwt_decode<JwtPayload>(jwt)
      const claims: HasuraClaims = JSON.parse(decoded['https://hasura.io/jwt/claims'])

      headers['x-hasura-user-id'] = claims['x-hasura-user-id']
      headers['x-hasura-customer-id'] = claims['x-hasura-customer-id']
    }

    return headers
  }

  const allowedRoles = useMemo(() => authUser?.allowedRoles ?? [], [authUser])

  /** Checks whether the user has an admin role jwt or an @sensorfact e-mail */
  const isSensorfactEmployee = useCallback(() => {
    return allowedRoles.includes('employee-admin') || isSensorfactEmail(user?.email)
  }, [allowedRoles, user])

  // if auth/hasura user is undefined, we are still determining the auth state
  if (authUser === undefined || hasuraUser === undefined) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '50vh' }}>
        <MUILoader />
      </Box>
    )
  }

  return (
    <AuthContextReactProvider
      value={{
        user,
        allowedRoles,
        currentCustomer,
        login,
        logout,
        refreshSession,
        isSensorfactEmployee,
      }}
    >
      {children}
    </AuthContextReactProvider>
  )
}

export { useAuthContext }
