import { ApexOptions } from 'apexcharts'
import { Theme } from '@mui/material'

export const useEnergyByUnitChartOptions: (totalsFormatter: (val: string) => string, theme: Theme) => ApexOptions = (
  totalsFormatter,
  theme
) => {
  const options: ApexOptions = {
    chart: {
      id: 'energy-by-unit' + Math.random(),
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
        tools: {
          reset: false,
          zoomin: false,
          zoomout: false,
          download: false,
          pan: false,
          selection: false,
          zoom: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: 'end',
        columnWidth: '25%',
        dataLabels: {
          position: 'center',
          total: {
            enabled: true,
            formatter: totalsFormatter,
            style: {
              fontFamily: theme.typography.fontFamily,
              fontSize: '14px',
              fontWeight: 400,
            },
          },
        },
      },
    },
    dataLabels: {
      formatter: totalsFormatter,
    },
    grid: {
      show: false,
    },
    yaxis: {
      max: value => value * 1.1 || 1,
      labels: {
        show: false,
        style: {
          fontFamily: theme.typography.fontFamily,
          fontSize: '14px',
          fontWeight: 400,
        },
      },
    },
    xaxis: {
      categories: [''],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      showForSingleSeries: true,
      horizontalAlign: 'left',
      fontFamily: theme.typography.fontFamily,
      fontSize: '14px',
      fontWeight: '400px',
      markers: {
        width: 8,
        height: 8,
        radius: 8,
      },
    },
    tooltip: {
      y: { formatter: val => totalsFormatter(val ? val.toString() : '0') },
      style: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
      },
    },
  }
  return options
}
