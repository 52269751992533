import { FC, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import LandingPage from '../MUIComponents/LandingPage/LandingPage'
import usePropositions, { CurrentPropositions } from '../../hooks/usePropositions/usePropositions'
import { SentryRoute } from '../../services/sentry'

type PropositionRouteProps = {
  component: JSX.Element
  prop: CurrentPropositions
  path: string
}

/**
 * Creates a /proposition path with the correct <Landing Page />
 * and a entry path into the proposition based on prop.mainPath;
 * Checks if customer has access to proposition
 * and redirects to the correct path of the two
 */
export const PropositionRoute: FC<PropositionRouteProps> = ({ prop, component: RouteComponent, ...rest }) => {
  const { propositions, hasProposition } = usePropositions()

  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    // when trying landing page redirect to main path if user has proposition
    if (pathname === `/${prop}` && hasProposition(prop)) {
      history.push(propositions[prop].mainPath) // a
    }
    // when trying deeper prop path redirect to landing page if users does not have proposition
    if (pathname.startsWith(`/${prop}`) && pathname !== `/${prop}` && !hasProposition(prop)) {
      history.push(`/${prop}`) // d
    }
  }, [pathname, prop, hasProposition, history, propositions])

  return (
    <SentryRoute
      {...rest}
      render={() => {
        if (hasProposition(prop)) return RouteComponent
        return <LandingPage proposition={propositions[prop]} />
      }}
    />
  )
}
