import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import React, { useEffect, useMemo, useRef, useState } from 'react'
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  alpha,
  styled,
} from '@mui/material'
import { CalendarMonth, Check, Clear, Edit } from '@mui/icons-material'
import { Controller, Resolver, ResolverError, SubmitHandler, useForm } from 'react-hook-form'

import { StyledCheckbox as Checkbox } from '../../../Shared/components/MUIComponents/update/Checkbox/StyledCheckBox'
import {
  GroupFragment,
  useGetAssetsQuery,
  useGetProductionSchedulesQuery,
  useUpdateMyAssetsMutation,
} from '../../../Shared/graphql/codegen'
import { ID } from '../../../Shared/types/types'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../Shared/contexts/ToastContext'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: 'solid 1px lightGrey',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: 'lightGrey',
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
const CancelIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
  },
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '0.9rem',
}))

const inputStyles = { paddingTop: '0.7rem', paddingBottom: '0.7rem', fontSize: '0.9rem' }

interface AssetInfo {
  id: string
  name: string
  productionScheduleId: string | null
}

type Groups = readonly GroupFragment[]
type Asset = GroupFragment['assets'][number]

type SubmittedData = {
  [key: number]: AssetInfo
}

const resolver: Resolver<SubmittedData> = values => {
  const errors: ResolverError<SubmittedData>['errors'] = {}
  const nameErrorsIndices = Object.values(values)
    .map((val, index) => {
      if (!val.name || val.name?.trim().length === 0) {
        return index
      }
      return undefined
    })
    .filter(i => i !== undefined)
    .map(i => i as number)

  if (nameErrorsIndices.length) {
    nameErrorsIndices.forEach(errorIndex => {
      errors[errorIndex] = { name: { type: 'required', message: 'Asset name cannot be empty' } }
    })
  }
  return {
    values,
    errors,
  }
}

// TODO: refactoring with SubmittedData being an array instead of {} fixes types but breaks the validation onChange/ blur;
// only works on submit same as on ProductionScheduleForm

// TODO: Searching needs debouncing;
const Assets = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const { i18n } = useI18nContext()
  const { showToast } = useToastContext()
  const [selectedSchedule, setSelectedSchedule] = useState('select')

  const [selectedAssets, _updateSelectedAssets] = useState<string[]>([])
  const [selectedGroups, _updateSelectedGroups] = useState<string[]>([])
  const [isAllSelected, _setAllSelected] = useState(false)

  const { data: assetsData, error: assetsError, loading: assetsLoading, refetch: refetchAssets } = useGetAssetsQuery()
  const { data: psData, error: schedulesError, loading: schedulesLoading } = useGetProductionSchedulesQuery()

  const [updateMyAssetsMutation, { loading: updateLoading }] = useUpdateMyAssetsMutation({
    onCompleted: () => showToast('Assets updated succesfuly.', 'success', 'Success!'),
    onError: () => showToast('Something went wrong while saving changes.', 'error', 'Error'),
  })

  const productionSchedules = useMemo(() => psData?.myOrg?.schedules, [psData?.myOrg])
  const groups = useMemo(() => assetsData?.myOrg?.groups, [assetsData?.myOrg])

  const NO_SCHEDULE = 'NO_SCHEDULE'
  const NO_SCHEDULE_PLACEHOLDER = 'No schedule assigned'

  const isSelectedAsset = useMemo(() => (id: string) => selectedAssets.includes(id), [selectedAssets])
  const isSelectedGroup = useMemo(() => (id: string) => selectedGroups.includes(id), [selectedGroups])

  /**
   * Checks group will be fully selected if assetId is added to selectedAssets
   * @returns Boolean
   */
  const checkGroupWillBeFull = (assetId: string, group: GroupFragment) => {
    const ids = group.assets.map(a => a.id)
    const newSelection = [...selectedAssets, assetId]
    return ids.every(id => newSelection.includes(id))
  }

  /**
   * Checks group will no longer be fully selected if assetId is removed from selectedAssets
   * @returns Boolean
   */
  const checkGroupWillBePartial = (assetId: string, group: GroupFragment) => {
    const ids = group.assets.map(a => a.id)
    return ids.every(id => selectedAssets.includes(id))
  }

  const toggleSelectAsset = (assetId: string, group: GroupFragment) => {
    if (isSelectedAsset(assetId)) {
      const groupNotFull = checkGroupWillBePartial(assetId, group)
      if (groupNotFull) _updateSelectedGroups(current => current.filter(g => g !== group.id))
      return _updateSelectedAssets(current => current.filter(c => c !== assetId))
    }
    const groupIsFull = checkGroupWillBeFull(assetId, group)
    if (groupIsFull) _updateSelectedGroups(current => [...current, group.id])
    return _updateSelectedAssets(current => [assetId, ...current])
  }

  const toggleSelectGroup = (group: GroupFragment) => {
    if (!group.assets || !group.assets.length) return

    if (isSelectedGroup(group.id)) {
      const ids = group.assets.map(a => a.id)
      const updatedAssets = selectedAssets.filter(sa => !ids.includes(sa))
      _updateSelectedAssets(updatedAssets)
      _updateSelectedGroups(current => current.filter(c => c !== group.id))
      return
    }

    const ids = group.assets.map(a => a.id)
    const unique = [...new Set([...selectedAssets, ...ids])] // in case some assets in the group were already selected
    _updateSelectedAssets(unique)
    _updateSelectedGroups(current => [...current, group.id])
  }

  const toggleAllSelected = () => {
    if (isAllSelected) {
      _updateSelectedAssets([])
      _updateSelectedGroups([])
      _setAllSelected(false)
    } else {
      if (!filteredGroupsAndAssets || !filteredGroupsAndAssets.length) return
      const groupIds = filteredGroupsAndAssets.map(g => g.id)
      const assetIds = filteredGroupsAndAssets.flatMap((group: GroupFragment) => {
        return group.assets.map(a => a.id)
      })
      _updateSelectedAssets(assetIds)
      _updateSelectedGroups(groupIds)
      _setAllSelected(true)
    }
  }

  const assetContainsSearchTerm = (asset: Asset) => {
    return (
      asset.name.toLowerCase().includes(searchTerm) ||
      asset.activeProductionSchedule?.productionSchedule?.name.toLowerCase().includes(searchTerm)
    )
  }

  const groupContainsSearchTerm = (group: GroupFragment) => group.name.toLowerCase().includes(searchTerm)

  const fitsNoScheduleSearch = (asset: Asset) =>
    !asset.activeProductionSchedule?.productionSchedule && NO_SCHEDULE_PLACEHOLDER.toLowerCase().includes(searchTerm)

  const filterGroupsAndAssets = (groups: Groups) => {
    // if groups or one of its assets matches search term keep it
    const filtered = groups?.filter(
      group =>
        groupContainsSearchTerm(group) ||
        group.assets.some(assetContainsSearchTerm) ||
        group.assets.some(fitsNoScheduleSearch)
    )

    // Modify the groups to only display the assets that fit search
    // if searching for group name show all of its assets
    // if searching for asset name or schedule only display assets that match
    const displayed = filtered.map(group => ({
      ...group,
      assets: groupContainsSearchTerm(group)
        ? group.assets
        : group.assets.filter(asset => assetContainsSearchTerm(asset) || fitsNoScheduleSearch(asset)),
    }))

    return displayed
  }

  const filteredGroupsAndAssets = useMemo(() => {
    if (!groups) return []
    return filterGroupsAndAssets(groups)
  }, [groups, searchTerm])

  const defaultValues = useMemo(
    () => ({
      ...(groups?.flatMap(group =>
        group.assets.map(asset => ({
          id: asset.id,
          name: asset.name,
          productionScheduleId: asset.activeProductionSchedule?.productionSchedule?.id || NO_SCHEDULE,
        }))
      ) || []),
    }),
    [groups]
  )

  const parseSubmitted = (values: SubmittedData) => {
    return Object.values(values).map(value => {
      if (value.productionScheduleId === NO_SCHEDULE) {
        value.productionScheduleId = null
      }
      return { id: value.id as ID, productionScheduleId: value.productionScheduleId as ID, name: value.name }
    })
  }

  const { register, handleSubmit, reset, formState, setValue, control, getValues } = useForm<SubmittedData>({
    defaultValues: { ...defaultValues },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver,
  })
  const { errors, dirtyFields, isValid, isDirty, isSubmitSuccessful } = formState
  const onReset = () => {
    reset()
  }

  const onSubmit: SubmitHandler<SubmittedData> = async data => {
    // For each asset submit only the actual keys that have changed together with ID.
    const changedAssets: AssetInfo[] = []

    for (const key in dirtyFields) {
      // key is a number ; it is the key of each asset in the defaultValues object
      const submittedAsset = data[key]
      type fields = ['productionScheduleId' | 'name']
      const changedFields = Object.keys(dirtyFields[key]) as fields
      const changedAsset = { id: submittedAsset.id } as AssetInfo
      changedFields.forEach(field => {
        ;(changedAsset[field] as string | null) = submittedAsset[field]
      })
      changedAssets.push(changedAsset)
    }

    const parsed = parseSubmitted(changedAssets)
    await updateMyAssetsMutation({ variables: { assets: parsed } })
    await refetchAssets()
    _updateSelectedAssets([])
    _updateSelectedGroups([])
    setSelectedSchedule('select')
  }

  const handleAssignScheduleAll = (e: SelectChangeEvent<string>) => {
    const scheduleId = e.target.value
    const formValues = getValues()
    setSelectedSchedule(scheduleId)
    Object.keys(formValues).forEach(key => {
      const asset = formValues[+key]
      if (!isSelectedAsset(asset.id)) return
      // @ts-ignore
      setValue(`${key}.productionScheduleId`, scheduleId, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      })
    })
  }

  useEffect(() => {
    reset({ ...defaultValues })
  }, [isSubmitSuccessful, reset, defaultValues])

  const getKey = (asset: Asset) => {
    // key is a number ; it is the key of each asset in the defaultValues object
    return Object.values(defaultValues).findIndex(value => (value as AssetInfo).id === asset.id)
  }

  const formRef = useRef(null)

  // TODO handle these better
  if (assetsLoading || schedulesLoading) return <p>Loading...</p>
  if (assetsError || schedulesError) return <p>Error: {assetsError?.message || schedulesError?.message}</p>

  return (
    <Box
      paddingLeft={1}
      paddingRight={1}
    >
      <Grid
        container
        alignItems="baseline"
      >
        <Grid
          item
          xs={8}
        >
          <Typography
            color="secondary"
            variant="h4"
          >
            {i18n.text('configuration.assets.your-assets')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              value={searchTerm}
              onChange={e => setSearchTerm(e.currentTarget.value.trim().toLocaleLowerCase())}
              placeholder="Search by asset, group or schedule"
              inputProps={{ 'aria-label': 'search', 'data-testid': 'search-input' }}
            />
            <CancelIconWrapper>
              <Clear
                sx={theme => ({
                  color: theme.palette.SFIGreyLight[400],
                  '&: hover': { color: theme.palette.common.black },
                })}
                onClick={() => setSearchTerm('')}
              />
            </CancelIconWrapper>
          </Search>
        </Grid>
      </Grid>
      <Box>
        <Box
          sx={theme => ({
            alignItems: 'center',
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            backgroundColor: 'hsl(270, 10%, 90%)',
            padding: '0.7rem 1rem',
            // paddingRight: "2rem",
            marginTop: '1.5rem',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
          })}
        >
          {/* FORM CONTROLS */}
          <Box>
            <Checkbox
              checked={isAllSelected}
              onChange={toggleAllSelected}
              sx={{ fontSize: '1rem', marginLeft: '-11px' }}
            />
            <Button
              variant="outlined"
              onClick={toggleAllSelected}
              sx={{ fontWeight: 400, fontSize: '0.9rem', textTransform: 'none' }}
            >
              {isAllSelected ? 'Deselect' : 'Select all'}
            </Button>
            <Typography sx={{ display: 'inline-block', fontSize: '0.9rem', marginLeft: '0.5rem' }}>
              Selected items: {selectedAssets.length}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CalendarMonth sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '1.2rem' }} />
            <Typography sx={{ fontSize: '0.9rem', marginLeft: '0.5rem' }}>Assign Schedule</Typography>
            <Select
              onChange={handleAssignScheduleAll}
              value={selectedSchedule}
              sx={{
                justifySelf: 'flex-end',
                fontSize: '0.9rem',
                marginLeft: '1rem',
              }}
              inputProps={{
                'data-testid': 'schedule-select-input-all',
                sx: { paddingTop: '0.3rem', paddingBottom: '0.3rem', fontSize: '0.9rem' },
              }}
              SelectDisplayProps={
                {
                  'data-testid': 'schedule-select-button-all',
                } as {}
              }
              defaultValue="select"
            >
              <StyledMenuItem
                disabled
                value="select"
              >
                Select a schedule
              </StyledMenuItem>
              <StyledMenuItem
                value={NO_SCHEDULE}
                data-testid="schedule-select-option"
              >
                {NO_SCHEDULE_PLACEHOLDER}
              </StyledMenuItem>

              {productionSchedules?.map(sched => (
                <StyledMenuItem
                  key={sched.id}
                  value={sched.id}
                  data-testid="schedule-select-option"
                >
                  {sched.name}
                </StyledMenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{ '& > *': { marginLeft: '1rem' }, '& > *:first-of-type': { marginLeft: '-11px' }, textAlign: 'right' }}
          >
            <Button
              variant="outlined"
              color="error"
              sx={{
                color: 'white',
                fontWeight: 400,
                fontSize: '0.9rem',
                textTransform: 'none',
              }}
              disabled={!isDirty || updateLoading}
              onClick={onReset}
              data-testid="reset"
            >
              Reset
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || !isValid || updateLoading}
              variant="outlined"
              color="primary"
              sx={{
                color: 'white',
                fontWeight: 400,
                fontSize: '0.9rem',
                textTransform: 'none',
                marginLeft: '1rem',
              }}
              data-testid="submit"
            >
              <Check sx={{ fontSize: '0.9rem', marginRight: '0.5rem' }} />
              Save
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            maxWidth: '1450px',
            margin: 'auto',
            padding: '1rem',
            paddingTop: '0.5rem',
            paddingBottom: '2rem',
            overflowY: 'auto',
            maxHeight: '75vh',
          }}
        >
          {/* FORM */}
          <form
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            {filteredGroupsAndAssets?.map(group => {
              if (!group.assets || !group.assets.length) {
                return null
              }
              return (
                <Box key={group.id}>
                  <Box
                    display="flex"
                    alignItems="center"
                  >
                    <FormControlLabel
                      label={group.name}
                      componentsProps={{
                        typography: {
                          variant: 'h6',
                          color: 'secondary',
                        },
                      }}
                      control={
                        <Checkbox
                          checked={isSelectedGroup(group.id)}
                          onChange={_ => toggleSelectGroup(group)}
                          sx={theme => ({ color: theme.palette.SFIGreyLight[400] })}
                        />
                      }
                    />
                  </Box>
                  <List
                    sx={{
                      '& >*: nth-of-type(odd)': {
                        backgroundColor: 'none',
                      },
                      '& >*: nth-of-type(even)': {
                        backgroundColor: 'hsl(270, 10%, 90%)',
                      },
                    }}
                  >
                    <hr />
                    {group.assets.map(asset => (
                      <ListItem
                        key={asset.id}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          <Checkbox
                            sx={theme => ({ color: theme.palette.SFIGreyLight[400] })}
                            checked={isSelectedAsset(asset.id)}
                            onChange={_ => toggleSelectAsset(asset.id, group)}
                          />
                          <TextField
                            sx={{
                              justifySelf: 'flex-start',
                              '.MuiSvgIcon-root': { visibility: 'hidden' },
                              '&:hover .MuiSvgIcon-root': { visibility: 'initial' },
                            }}
                            InputProps={{
                              endAdornment: <Edit sx={{ fontSize: '1.2rem', marginRight: '0.5rem' }} />,
                            }}
                            inputProps={{
                              sx: { ...inputStyles },
                            }}
                            // @ts-ignore rhf typings compile to <never> instead of number.string but this works
                            {...register(`${getKey(asset)}.name`)}
                            // @ts-ignore rhf typings compile to <never> instead of number.string but this works
                            onBlur={e => setValue(`${getKey(asset)}.name`, e.target.value.trim())}
                            // @ts-ignore rhf typings compile to <never> instead of number.string but this works
                            error={!!errors[`${getKey(asset)}`]?.name}
                            // @ts-ignore rhf typings compile to <never> instead of number.string but this works
                            helperText={errors[`${getKey(asset)}`]?.name?.message}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {/* Same transparency as ListItemIcon */}
                          <CalendarMonth
                            sx={{ color: 'rgba(0, 0, 0, 0.54)', marginRight: '1rem', fontSize: '1.2rem' }}
                          />
                          <Controller
                            // @ts-ignore rhf typings compile to <never> instead of number.string but this works
                            name={`${getKey(asset)}.productionScheduleId`}
                            control={control}
                            render={({ field }) => {
                              field.value = field.value || NO_SCHEDULE
                              return (
                                <Select
                                  {...field}
                                  sx={{
                                    justifySelf: 'flex-end',
                                    fontSize: '0.9rem',
                                  }}
                                  inputProps={{
                                    'data-testid': 'schedule-select-input',
                                    sx: { ...inputStyles },
                                  }}
                                  SelectDisplayProps={
                                    {
                                      'data-testid': 'schedule-select-button',
                                    } as {}
                                  }
                                >
                                  {productionSchedules?.map(sched => (
                                    <StyledMenuItem
                                      key={sched.id}
                                      value={sched.id}
                                      data-testid="schedule-select-option"
                                    >
                                      {sched.name}
                                    </StyledMenuItem>
                                  ))}
                                  <StyledMenuItem
                                    value={NO_SCHEDULE}
                                    data-testid="schedule-select-option"
                                  >
                                    No schedule assigned
                                  </StyledMenuItem>
                                </Select>
                              )
                            }}
                          />
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )
            })}
          </form>
        </Box>
      </Box>
    </Box>
  )
}

export default Assets
