import React, { FC } from 'react'

import { ImageReviewItem } from '../../../../Shared/components/MUIComponents/update/ImageReviewItem'
import { UploadButton } from './UploadButton'

interface IImagePlaceholderProps {
  item: { value: string; id?: string }
  openViewImagesModal: (id?: string) => void
  openUploadImageModal: (id: string) => void
}

export const ImagePlaceholder: FC<IImagePlaceholderProps> = ({ item, openViewImagesModal, openUploadImageModal }) => {
  return item.value ? (
    <ImageReviewItem
      item={{ value: item.value, id: item.id }}
      openViewImagesModal={openViewImagesModal}
    />
  ) : (
    <UploadButton
      itemId={item.id}
      openUploadImageModal={openUploadImageModal}
    />
  )
}
