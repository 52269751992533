import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'

import {
  AuthContextQueryDocument,
  CommodityPrice,
  CurrencyIsoCode,
  GetCustomerCommodityPricesDocument,
  useGetAllCurrenciesQuery,
  useGetCustomerCommodityPricesQuery,
  useUpdateCustomerCommodityPricesMutation,
  useUpdateCustomerCurrencyMutation,
} from '../../../../../Shared/graphql/codegen'
import { AutocompleteOption, CustomerCurrency } from '../../../../../Shared/types/types'
import { CurrencyAutocomplete } from './CurrencyAutocomplete'
import { CurrencyUpdatedModal } from './CurrencyUpdatedModal'
import { PriceTextFieldBox } from './PriceTextFieldBox'
import { getCurrencyName, getCurrencySymbol } from '../../../../../Shared/utils/formatCurrency'
import { getLatestCommodityPrices, handleGenericError } from '../../../../../Shared/utils'
import { useCurrentUser } from '../../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../../Shared/contexts/ToastContext'

enum PriceUnit {
  kwh = 'KWh',
  'm³' = 'm³',
}

interface CurrencyAutocompleteOption extends AutocompleteOption {
  key: string
}

export const CurrencyAndPrice: FC = () => {
  const { i18n, userLocale } = useI18nContext()
  const { showToast } = useToastContext()
  const dispatch = useDispatch()
  const { customerCurrency } = useCurrentUser()
  const { data: fetchedCurrencies } = useGetAllCurrenciesQuery()
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyAutocompleteOption>({
    label: getCurrencyText(customerCurrency),
    id: customerCurrency.isocode,
    key: customerCurrency.symbol,
  })
  const [selectedElectricityPrice, setSelectedElectricityPrice] = useState('')
  const [selectedGasPrice, setSelectedGasPrice] = useState('')
  const [selectedWaterPrice, setSelectedWaterPrice] = useState('')
  const [isCurrencyUpdatedModalOpen, setIsCurrencyUpdatedModalOpen] = useState(false)

  const { data: fetchedCommodityPrices, loading: getCustomerCommodityPricesLoading } =
    useGetCustomerCommodityPricesQuery()

  const allCurrencies: CustomerCurrency[] = useMemo(
    () => (fetchedCurrencies?.Currency?.length ? (fetchedCurrencies?.Currency as unknown as CustomerCurrency[]) : []),
    [fetchedCurrencies]
  )

  const allCommodityPrices: CommodityPrice[] = useMemo(
    () => (fetchedCommodityPrices ? (fetchedCommodityPrices.commodityPrices as CommodityPrice[]) : []),
    [fetchedCommodityPrices]
  )

  const {
    electricity: electricityPrice,
    naturalGas: gasPrice,
    water: waterPrice,
  } = useMemo(() => getLatestCommodityPrices(allCommodityPrices), [allCommodityPrices])

  const currencyOptions = useMemo<CurrencyAutocompleteOption[]>(() => {
    if (!allCurrencies) {
      return []
    }
    return allCurrencies.map(currency => ({
      label: getCurrencyText(currency),
      id: currency.isocode,
      key: currency.symbol,
    }))
  }, [allCurrencies])

  useEffect(() => {
    if (allCurrencies && !selectedCurrency.id) {
      const firstCurrency = allCurrencies[0]
      setSelectedCurrency({
        label: getCurrencyText(firstCurrency),
        id: firstCurrency.isocode,
        key: firstCurrency.symbol,
      })
    }
  }, [allCurrencies])

  const [updateCustomerCurrency] = useUpdateCustomerCurrencyMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: AuthContextQueryDocument }],
    onCompleted: () => setIsCurrencyUpdatedModalOpen(true),
    onError: () => showToast(i18n.text('error.currency.update'), 'error', 'Error!'),
  })

  const [updateCommodityPrice] = useUpdateCustomerCommodityPricesMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetCustomerCommodityPricesDocument }],
    onCompleted: () => showToast(i18n.text('success.commodityPrice.update'), 'success', 'Success!'),
    onError: error =>
      handleGenericError('COMMODITY_PRICE_UPDATED', error, dispatch, i18n.text('error.commodityPrice.update')),
  })

  async function onSelectCurrency(option: CurrencyAutocompleteOption) {
    setSelectedCurrency(option)
    await updateCustomerCurrency({
      variables: {
        currencyISOCode: option.id as CurrencyIsoCode,
      },
    })
  }

  async function saveElectricityPrice() {
    if (selectedElectricityPrice) {
      await updateCommodityPrice({
        variables: {
          prices: {
            electricity: {
              value: +selectedElectricityPrice,
            },
          },
        },
      })
    }
  }

  async function saveGasPrice() {
    if (selectedGasPrice) {
      await updateCommodityPrice({
        variables: {
          prices: {
            naturalGas: {
              value: +selectedGasPrice,
            },
          },
        },
      })
    }
  }

  async function saveWaterPrice() {
    if (selectedWaterPrice) {
      await updateCommodityPrice({
        variables: {
          prices: {
            water: {
              value: +selectedWaterPrice,
            },
          },
        },
      })
    }
  }

  function getCurrencyText(currency: CustomerCurrency) {
    return `${getCurrencyName(userLocale, currency?.isocode)} - ${getCurrencySymbol(userLocale, currency?.isocode)}`
  }

  const priceTextFields = useMemo(
    () => [
      {
        label: 'settings.customer.energy-prices',
        explanationText: 'settings.customer.energy-prices-description',
        unitName: `${selectedCurrency.key} / ${PriceUnit.kwh}`,
        value: selectedElectricityPrice || electricityPrice.price.toFixed(2),
        isDisabled: getCustomerCommodityPricesLoading,
        onChange: (newValue: string) => setSelectedElectricityPrice(newValue),
        onSave: saveElectricityPrice,
      },
      {
        label: 'settings.customer.gas-prices',
        explanationText: 'settings.customer.gas-prices-description',
        unitName: `${selectedCurrency.key} / ${PriceUnit.kwh}`,
        value: selectedGasPrice || gasPrice.price.toFixed(2),
        isDisabled: getCustomerCommodityPricesLoading,
        onChange: (newValue: string) => setSelectedGasPrice(newValue),
        onSave: saveGasPrice,
      },
      {
        label: 'settings.customer.water-prices',
        explanationText: 'settings.customer.water-prices-description',
        unitName: `${selectedCurrency.key} / ${PriceUnit['m³']}`,
        value: selectedWaterPrice || waterPrice.price.toFixed(2),
        isDisabled: getCustomerCommodityPricesLoading,
        onChange: (newValue: string) => setSelectedWaterPrice(newValue),
        onSave: saveWaterPrice,
      },
    ],
    [
      selectedCurrency,
      selectedElectricityPrice,
      electricityPrice.price,
      getCustomerCommodityPricesLoading,
      selectedGasPrice,
      gasPrice.price,
      selectedWaterPrice,
      waterPrice.price,
    ]
  )

  return (
    <Box>
      <CurrencyAutocomplete
        selectedCurrency={selectedCurrency}
        currencyOptions={currencyOptions}
        onSelectCurrency={onSelectCurrency}
      />
      {priceTextFields.map((textFieldData, index) => (
        <PriceTextFieldBox
          key={index}
          {...textFieldData}
        />
      ))}
      <CurrencyUpdatedModal
        isOpen={isCurrencyUpdatedModalOpen}
        onClose={() => {
          setIsCurrencyUpdatedModalOpen(false)
        }}
      />
    </Box>
  )
}
