import { Box, IconButton, ListItem, ListItemText, Typography, styled, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { Draggable } from 'react-beautiful-dnd'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import useAnalytics from '../../../../Shared/hooks/useAnalytics/useAnalytics'
import { AnalyticsIntent } from '../../../../Shared/hooks/useAnalytics/analyticsTypes'
import { CopyIcon, DotsGridIcon, EditIcon } from '../../../../Shared/components/icons'
import { DeleteModuleDialog } from './Dialogs/DeleteModuleDialog'
import { DraggableListItemProps } from './types'
import { ISODateTime } from '../../../../Shared/types/types'
import { SmallCustomSwitch } from '../../../../Shared/components/MUIComponents/update/NewDesignStyledSwitch'
import { StyledTooltip } from '../../../../Shared/components/MUIComponents/Tooltip'
import { TrashIcon } from '../../../../Shared/components/icons/TrashIcon'
import { VisualisationDataProps, VisualisationUpsertDataProps } from './types'
import {
  trackCancelDeleteModuleEvent,
  trackConfirmDeleteModuleEvent,
  trackDeleteModuleEvent,
  trackDuplicateModuleEvent,
  trackStartEditModuleEvent,
  trackToggleModuleEvent,
} from './trackingEvents'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import {
  useDeleteTrackingReportModuleMutation,
  useGetAnalysisModuleByIdQuery,
  useGetReportByIdQuery,
  useInsertAnalysisModuleMutation,
  useInsertTrackingReportVisualisationsMutation,
  useUpdateModuleEnableMutation,
} from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../Shared/contexts/ToastContext'

const StyledModuleRowBox = styled(Box)(({ theme }) => ({
  display: 'inline-grid',
  gridTemplateColumns: '5% 40% 30% 10% 5% 5% 5%',
  width: '100%',
  alignItems: 'center',
  background: theme.palette.SFIBase.white,
  padding: '1rem 1.5rem 1rem 0',
  borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
}))

export const DraggableListItem: FC<DraggableListItemProps> = ({
  item,
  index,
  reportId,
  moduleId,
  enabled,
  handleChange,
  numModules,
}) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { showToast } = useToastContext()
  const { sendEvent } = useAnalytics()
  const history = useHistory()
  const { isInternalUser } = useCurrentUser()
  const { data: reportData } = useGetReportByIdQuery({ variables: { id: reportId } })
  const modules = reportData && reportData.TrackingReportReportConfigById?.analysisModules
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [updateModuleEnableMutation] = useUpdateModuleEnableMutation()

  const [deleteModuleMutation] = useDeleteTrackingReportModuleMutation({
    onCompleted: async response => {
      showToast(
        i18n.text(`reports.tracking.configuration.modules.deletedSuccess`),
        'success',
        i18n.text(`reports.tracking.configuration.module.success`)
      )
    },
    onError: async response =>
      showToast(
        i18n.text(`reports.tracking.configuration.modules.deletedFail`),
        'error',
        i18n.text(`reports.tracking.configuration.module.error`)
      ),
  })

  const [InsertAnalysisModuleMutation] = useInsertAnalysisModuleMutation({
    onCompleted: async response => {
      showToast(
        i18n.text(`reports.tracking.configuration.modules.duplicatedSuccess`),
        'success',
        i18n.text(`reports.tracking.configuration.module.success`)
      )
    },
    onError: async response =>
      showToast(
        i18n.text(`reports.tracking.configuration.modules.duplicatedFail`),
        'error',
        i18n.text(`reports.tracking.configuration.module.error`)
      ),
  })

  const [insertTrackingReportVisualisationsMutation] = useInsertTrackingReportVisualisationsMutation({
    onCompleted: async response => {
      showToast(
        i18n.text(`reports.tracking.configuration.module.updatedSuccess`),
        'success',
        i18n.text(`reports.tracking.configuration.module.success`)
      )
    },
    onError: async response =>
      showToast(
        i18n.text(`reports.tracking.configuration.module.savedFail`),
        'error',
        i18n.text(`reports.tracking.configuration.module.error`)
      ),
  })

  const { data, refetch: refetchGetModule } = useGetAnalysisModuleByIdQuery({
    variables: { reportId: reportId, moduleId: moduleId },
  })
  const analysisModule = data && data?.TrackingReportReportConfigs[0].analysisModules[0]
  const currentVisualisationData = analysisModule && analysisModule.visualisations
  const currentVisualisations =
    currentVisualisationData &&
    Array.from(currentVisualisationData, item => item as VisualisationDataProps).map(visualisation => ({
      ...visualisation,
    }))

  const [enabledChecked, setEnabledChecked] = useState(false)

  useEffect(() => {
    setEnabledChecked(enabled || false)
  }, [enabled, enabledChecked])

  const handleEnableModule = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnabledChecked(event.target.checked)
    const enableModuleParameters = {
      moduleId: moduleId,
      enableModule: !enabled,
    }

    await updateModuleEnableMutation({
      variables: {
        ...enableModuleParameters,
      },
    })
    if (event.target.checked) {
      trackToggleModuleEvent(AnalyticsIntent.TR_ENABLE_MODULE, sendEvent)
    } else {
      trackToggleModuleEvent(AnalyticsIntent.TR_DISABLE_MODULE, sendEvent)
    }

    refetchGetModule()
  }

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
    trackDeleteModuleEvent(sendEvent)
  }
  const handleCancelDelete = () => {
    setOpenDeleteDialog(false)
    trackCancelDeleteModuleEvent(sendEvent)
  }
  const handleDeleteModule = async (id: string | undefined) => {
    if (id) {
      const deleteParameters = { moduleId: id, deletedAt: DateTime.now().toISO() as ISODateTime }

      await deleteModuleMutation({
        variables: deleteParameters,
      })
    }

    setOpenDeleteDialog(false)
    trackConfirmDeleteModuleEvent(sendEvent)
    handleChange(reportId)
  }

  const handleCloneModule = async (id: string | undefined) => {
    if (id) {
      const clonedModuleInput = {
        input: {
          analysisType: analysisModule?.analysisType,
          title: analysisModule?.title,
          descriptionInclude: analysisModule?.descriptionInclude,
          referenceValueInclude: analysisModule?.referenceValueInclude,
          assetIds: analysisModule?.assetIds,
          assetGroupingLevel: analysisModule?.assetGroupingLevel,
          timeGroupingLevel: analysisModule?.timeGroupingLevel,
          daysOfWeekInclude: analysisModule?.daysOfWeekInclude,
          reportConfigId: reportId,
          enabled: true,
          position: modules?.length,
        },
      }
      await InsertAnalysisModuleMutation({
        variables: {
          ...clonedModuleInput,
        },
      }).then(response => {
        const newModuleId = response.data?.insertTrackingReportAnalysisModules?.returning[0].id

        if (currentVisualisations) {
          const visualisationsSettingsParametersNew = {
            visualisations: currentVisualisations
              .map((visualisation, index) => ({ ...visualisation, analysisModuleId: newModuleId, position: index }))
              .map(function (visualisation) {
                delete visualisation.analysisType
                delete visualisation.id
                delete visualisation.__typename
                return visualisation
              }),
          } as VisualisationUpsertDataProps

          insertTrackingReportVisualisationsMutation({
            variables: {
              ...visualisationsSettingsParametersNew,
            },
          })
        }
      })
      trackDuplicateModuleEvent(sendEvent)
      handleChange(reportId)
    }
  }

  const handleEditModuleClick = () => {
    trackStartEditModuleEvent(sendEvent)
    history.push(`/reports/configuration/tracking/${reportId}/modules/${moduleId}`)
  }

  const visualisationDescriptionText =
    analysisModule?.visualisations.length && analysisModule?.visualisations.length > 1
      ? i18n.text('reports.tracking.configuration.modules.description.visualisations')
      : i18n.text('reports.tracking.configuration.modules.description.visualisation')

  const moduleSummaryText =
    i18n.text(`reports.tracking.configuration.modules.description.${analysisModule?.timeGroupingLevel}`) +
    ' ' +
    i18n.text('reports.tracking.configuration.modules.description.aggregation') +
    ' ' +
    i18n.text(`reports.tracking.configuration.modules.description.${analysisModule?.assetGroupingLevel}`) +
    ', ' +
    analysisModule?.visualisations.length +
    ' ' +
    visualisationDescriptionText

  const [displayDragIndicator, setDisplayDragIndicator] = useState(false)

  return (
    <>
      <Draggable
        draggableId={item.id || 'new'}
        index={index}
      >
        {provided => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            disablePadding
            onMouseEnter={() => setDisplayDragIndicator(true)}
            onMouseLeave={() => setDisplayDragIndicator(false)}
            sx={{
              '&:nth-of-type(even) > div': {
                background: theme.palette.SFIGreyNeutral[50],
              },
              '&:last-of-type > div': { borderBottom: `1px solid ${theme.palette.SFIGreyLight[200]}` },
            }}
          >
            <StyledModuleRowBox>
              {displayDragIndicator && <DotsGridIcon sx={{ color: theme.palette.SFIGreyLight[400] }} />}
              {!displayDragIndicator && <Box sx={{ width: '1.5rem' }} />}
              <StyledTooltip
                title={i18n.text('reports.tracking.configuration.modules.dragTooltip')}
                placement="bottom"
                arrow={false}
              >
                <ListItemText
                  primary={i18n.text(`reports.tracking.configuration.analysisType.${item.analysisType}`)}
                  primaryTypographyProps={{
                    fontFamily: 'Inter',
                    fontSize: '0.875rem',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '1.25rem',
                    color: theme.palette.SFIGreyLight[800],
                  }}
                  secondary={moduleSummaryText}
                  secondaryTypographyProps={{
                    fontFamily: 'Inter',
                    fontSize: '0.875rem',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '1.25rem',
                    color: theme.palette.SFIGreyLight[600],
                  }}
                />
              </StyledTooltip>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '1.25rem',
                  color: theme.palette.SFIGreyLight[800],
                }}
              >
                {item.title}
              </Typography>

              <SmallCustomSwitch
                checked={enabled}
                onChange={handleEnableModule}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <IconButton
                disableRipple
                onClick={() => handleCloneModule(moduleId)}
              >
                <CopyIcon
                  sx={{
                    color: theme.palette.SFIGreyLight[600],
                  }}
                />
              </IconButton>
              {isInternalUser() && (
                <IconButton
                  disableRipple
                  onClick={handleOpenDeleteDialog}
                >
                  <TrashIcon
                    sx={{
                      color: theme.palette.SFIGreyLight[600],
                    }}
                  />
                </IconButton>
              )}
              <IconButton
                disableRipple
                onClick={handleEditModuleClick}
              >
                <EditIcon
                  sx={{
                    color: theme.palette.SFIGreyLight[600],
                  }}
                />
              </IconButton>
            </StyledModuleRowBox>
          </ListItem>
        )}
      </Draggable>
      <DeleteModuleDialog
        openDeleteDialog={openDeleteDialog}
        moduleId={moduleId}
        handleDeleteModule={handleDeleteModule}
        handleCancelDelete={handleCancelDelete}
      />
    </>
  )
}
