import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box, MenuItem, SelectChangeEvent, linearProgressClasses, paperClasses, styled, useTheme } from '@mui/material'

import LoadingBar from '../../../Shared/components/MUIComponents/LoaderBar'
import usePropositions from '../../../Shared/hooks/usePropositions/usePropositions'
import { AlertType, AutocompleteOption } from '../../../Shared/types/types'
import { ArrowDownIcon } from '../../../Shared/components/icons'
import { CompressedAirAlertForm } from '../../../CompressedAir/components/CompressedAirAlertForm'
import { SelectedAlertRule } from '../../pages/AlertsPage/ConfigurationPage'
import { StyledDialogModal } from '../../../Shared/components/MUIComponents/StyledDialogModal'
import { StyledLabel } from './AlertConfigurationModal.styled'
import { StyledSelect } from '../../../Shared/components/MUIComponents/update/Selectv1/StyledSelect'
import { useAlertTypeCategoriesByProposition } from '../../hooks/useAlertTypeCategoriesByProposition'
import { useFilteredPropositions } from '../../hooks/useFilteredPropositions'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface IAlertConfigurationModalProps {
  isOpen: boolean
  selectedProduct?: AutocompleteOption
  title?: string
  selectedAlert?: SelectedAlertRule
  onClose: () => void
  refetchAlertRules: () => void
}

export const AlertConfigurationModal: FC<IAlertConfigurationModalProps> = ({
  selectedAlert,
  selectedProduct,
  isOpen,
  title,
  onClose,
  refetchAlertRules,
}) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { propositions } = usePropositions()
  const filteredPropositions = useFilteredPropositions()

  const isCreate = !selectedAlert?.id
  const defaultProposition = filteredPropositions[0]

  const [isLoading, setIsLoading] = useState(false)
  const [selectedProposition, setProposition] = useState<string>(selectedProduct?.id || defaultProposition.value)
  const [alertType, setAlertType] = useState(AlertType.POWER_ALERT)

  const alertTypeCategories = useAlertTypeCategoriesByProposition(selectedProposition)
  const modalTitle = title
    ? title
    : isCreate
    ? i18n.text('alerts.configuration.create-new')
    : i18n.text('rules.tooltip.edit-rule')

  const selectProps = {
    IconComponent: () => (
      <ArrowDownIcon
        sx={{
          display: 'block',
          color: theme.palette.SFIGreyLight[500],
          width: '1rem',
          height: '1rem',
          position: 'absolute',
          right: '0.88rem',
        }}
      />
    ),
    MenuProps: {
      sx: {
        '.MuiMenu-paper': {
          border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
          boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
        },
      },
    },
  }

  // TODO (Sabina): Update when new propositions modal are developed
  const selectedForm = useMemo(() => {
    if (selectedProposition === propositions['compressed-air'].name) {
      return (
        <CompressedAirAlertForm
          selectedAlert={selectedAlert}
          selectProps={selectProps}
          onClose={onClose}
          refetchAlertRules={refetchAlertRules}
          onLoading={handleIsLoading}
        />
      )
    }
    return null
  }, [selectedProposition])

  useEffect(() => {
    setAlertType(alertTypeCategories[0].value)
  }, [selectedProposition, alertTypeCategories])

  // Sets proposition and alert type based on what tab is open
  useEffect(() => {
    if (!selectedAlert || !selectedAlert.proposition) return
    setProposition(selectedAlert.proposition)
    if (selectedAlert.category) {
      setAlertType(selectedAlert.category as AlertType)
    }
  }, [selectedAlert])

  function handleAlertTypeChange(event: SelectChangeEvent) {
    setAlertType(event.target.value as AlertType)
  }

  function onSelectProposition(event: SelectChangeEvent) {
    setProposition(event.target.value)
    setAlertType(event.target.value as AlertType)
  }

  function handleIsLoading(newValue: boolean) {
    setIsLoading(newValue)
  }

  return (
    <StyledDialogModal
      title={modalTitle}
      isOpen={isOpen}
      isLoading={false}
      height="responsive"
      onClose={onClose}
      sx={{
        [`.${paperClasses.root}`]: {
          maxWidth: '45rem',
        },
      }}
    >
      <LoadingBarWrapper>
        <LoadingBar
          loading={isLoading}
          progressProps={{
            sx: {
              background: theme.palette.SFIGreyLight[200],
              height: '2px',
              [`${linearProgressClasses.bar}`]: {
                background: theme.palette.SFIBrand[800],
              },
            },
          }}
        />
      </LoadingBarWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        <StyledGridBox>
          <StyledLabel>{i18n.text('alerts.configuration.select-product')}</StyledLabel>
          <StyledSelect
            {...selectProps}
            size="small"
            labelId="proposition-select-label"
            id="proposition-select"
            value={selectedProposition}
            disabled={!isCreate}
            onChange={onSelectProposition}
          >
            {filteredPropositions.map(item => (
              <MenuItem
                key={item.value}
                value={item.value}
              >
                {item.value}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledGridBox>
        <StyledGridBox>
          <StyledLabel>{i18n.text('alerts.configuration.select-alert-type')}</StyledLabel>
          <StyledSelect
            {...selectProps}
            size="small"
            labelId="alert-type-select-label"
            id="alert-type-select"
            value={alertType}
            disabled={!isCreate}
            onChange={handleAlertTypeChange}
          >
            {alertTypeCategories.map(type => (
              <MenuItem
                key={type.value}
                value={type.value}
              >
                {i18n.text(type.title)}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledGridBox>
        {selectedForm}
      </Box>
    </StyledDialogModal>
  )
}

const StyledGridBox = styled(Box)({
  display: 'grid',
  gap: '2rem',
  gridTemplateColumns: '10rem 1fr',
  alignItems: 'center',
  width: '100%',
})

const LoadingBarWrapper = styled(Box)({
  marginLeft: '-1.5rem',
  width: 'calc(100% + 3rem)',
})
