import moment from 'moment'
import { DateTime } from 'luxon'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { de, enGB, es, fr, it, nl, pt } from 'react-date-range/dist/locale'

const locales = new Map([
  ['de', de],
  ['en', enGB],
  ['es', es],
  ['fr', fr],
  ['it', it],
  ['nl', nl],
  ['pt', pt],
])

export const selectLocale = ({ locale }: $TSFixMe) => {
  const _locale = locale.substr(0, 2)

  if (!locales.has(_locale)) {
    return locales.get('en')
  }

  return locales.get(_locale)
}

const isSameDay = (a: $TSFixMe, b: $TSFixMe) => moment(a).isSame(b, 'day')

const getRangeShape = ({ label, startDate, endDate }: $TSFixMe) => {
  const range = { startDate, endDate }

  return {
    label,
    range: () => range,
    isSelected(r: $TSFixMe) {
      return isSameDay(r.startDate, range.startDate) && isSameDay(r.endDate, range.endDate)
    },
  }
}

export const getStaticRanges = (i18n: $I18FixMe) => {
  const ranges = [
    {
      label: i18n.text('graph.quick-filter.yesterday'),
      startDate: DateTime.now().minus({ days: 1 }).startOf('day'),
      endDate: DateTime.now().minus({ days: 1 }).endOf('day'),
    },
    {
      label: i18n.text('graph.quick-filter.day'),
      startDate: DateTime.now().startOf('day'),
      endDate: DateTime.now().endOf('day'),
    },
    {
      label: i18n.text('graph.quick-filter.last-week'),
      startDate: DateTime.now().minus({ weeks: 1 }).startOf('week'),
      endDate: DateTime.now().minus({ weeks: 1 }).endOf('week'),
    },
    {
      label: i18n.text('graph.quick-filter.week'),
      startDate: DateTime.now().startOf('week'),
      endDate: DateTime.now().endOf('week'),
    },
    {
      label: i18n.text('graph.quick-filter.last-month'),
      startDate: DateTime.now().minus({ months: 1 }).startOf('month'),
      endDate: DateTime.now().minus({ months: 1 }).endOf('month'),
    },
    {
      label: i18n.text('graph.quick-filter.month'),
      startDate: DateTime.now().startOf('month'),
      endDate: DateTime.now().endOf('month'),
    },
    {
      label: i18n.text('graph.quick-filter.last-year'),
      startDate: DateTime.now().minus({ years: 1 }).startOf('year'),
      endDate: DateTime.now().minus({ years: 1 }).endOf('year'),
    },
    {
      label: i18n.text('graph.quick-filter.year'),
      startDate: DateTime.now().startOf('year'),
      endDate: DateTime.now().endOf('year'),
    },
  ]

  return ranges.map(getRangeShape)
}

export const getIntervalRangePdm = (i18n: $I18FixMe) => {
  return [
    {
      label: i18n.text('graph.quick-filter.day'),
      startDate: DateTime.now().startOf('day'),
      endDate: DateTime.now().endOf('day'),
    },
    {
      label: i18n.text('pdm.timepicker.intervals.last_7_days'),
      startDate: DateTime.now().minus({ weeks: 1 }).startOf('week'),
      endDate: DateTime.now().endOf('day'),
    },
    {
      label: i18n.text('pdm.timepicker.intervals.last_30_days'),
      startDate: DateTime.now().minus({ months: 1 }).startOf('month'),
      endDate: DateTime.now().endOf('day'),
    },
    {
      label: i18n.text('pdm.timepicker.intervals.last_90_days'),
      startDate: DateTime.now().minus({ months: 3 }).startOf('month'),
      endDate: DateTime.now().endOf('day'),
    },
    {
      label: i18n.text('pdm.timepicker.intervals.last_6_months'),
      startDate: DateTime.now().minus({ months: 6 }).startOf('month'),
      endDate: DateTime.now().endOf('day'),
    },
    {
      label: i18n.text('pdm.timepicker.intervals.last_year'),
      startDate: DateTime.now().minus({ years: 1 }).startOf('day'),
      endDate: DateTime.now().endOf('day'),
    },
  ].map(getRangeShape)
}
