import {
  AnalyticsEventTypes,
  AnalyticsIntent,
  AnalyticsPropositionType,
} from '../../Shared/hooks/useAnalytics/analyticsTypes'
import {
  DeleteLocationImagePDM,
  EditThresholdAlertPDM,
  MuteAlertsPDM,
  SearchBarAssetsPDM,
  ShowAnnotationsPDM,
  UploadLocationImagePDM,
} from './analyticsTypes'

export function sendMuteAlertsEventPDM(sendEvent: (name: string, params: MuteAlertsPDM) => void) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.PDM,
    intent: AnalyticsIntent.MUTE_ALERTS,
    target: 'mute_alerts_toggle_button',
  })
}

export function sendUploadSensorLocationImageEventPDM(
  sendEvent: (name: string, params: UploadLocationImagePDM) => void
) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.PDM,
    intent: AnalyticsIntent.UPLOAD_LOCATION_IMAGE,
    target: 'upload_asset_image_cloudinary_button',
  })
}

export function sendDeleteSensorLocationImageEventPDM(
  sendEvent: (name: string, params: DeleteLocationImagePDM) => void
) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.PDM,
    intent: AnalyticsIntent.DELETE_LOCATION_IMAGE,
    target: 'delete_asset_image_button',
  })
}

export function sendEditThresholdAlertEventPDM(sendEvent: (name: string, params: EditThresholdAlertPDM) => void) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.PDM,
    intent: AnalyticsIntent.EDIT_ALERT,
    target: 'edit_threshold_alert_button',
  })
}

export function sendSearchBarAssetsEventPDM(sendEvent: (name: string, params: SearchBarAssetsPDM) => void) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.PDM,
    intent: AnalyticsIntent.SEARCH,
    target: 'search_bar_card_assets_button',
  })
}

export function sendShowAnnotationsEventPDM(sendEvent: (name: string, params: ShowAnnotationsPDM) => void) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.PDM,
    intent: AnalyticsIntent.SHOW_ANNOTATIONS,
    target: 'show_annotations_checkbox',
  })
}
