import { FC } from 'react'

import GraphContainer from '../GraphsShared/GraphContainer'
import GraphHeader from '../GraphsShared/GraphHeader'
import ProductionVolumeGraph from './ProductionVolumeGraph'
import { AssetProductionSpeed } from '../../types/Assets.types'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type ProductionVolumeProps = {
  productionVolumeData: AssetProductionSpeed[]
}

const ProductionVolume: FC<ProductionVolumeProps> = ({ productionVolumeData }) => {
  const { i18n } = useI18nContext()

  return (
    <SectionBoxWrapper>
      <GraphContainer>
        <GraphHeader title={i18n.text('oee.production-volume.title')} />
        <ProductionVolumeGraph productionVolumeData={productionVolumeData} />
      </GraphContainer>
    </SectionBoxWrapper>
  )
}

export default ProductionVolume
