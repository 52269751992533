import { Box, Card, Chip, ChipProps, IconButton, IconButtonProps, styled } from '@mui/material'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

interface AssetStatusChipProps extends ChipProps {
  backgroundColor?: string
  statusColor?: string
  textColor?: string
  stroke?: string
}

export const StyledAssetCard = styled(Card)(({ theme }) => ({
  display: 'grid',
  padding: theme.spacing(1.2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none',
  gap: theme.spacing(1.3),
  border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  '&:hover': {
    cursor: 'pointer',
    boxShadow: '-1px 5px 13px rgba(0, 0, 0, 0.1)',
  },
}))

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const CardLastMeasurement = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const AssetStatusChip = styled((props: AssetStatusChipProps) => {
  const { backgroundColor, statusColor, textColor, stroke, ...rest } = props
  return <Chip {...rest} />
})(({ backgroundColor, statusColor, textColor, stroke }) => ({
  backgroundColor: backgroundColor,
  color: textColor,
  borderRadius: '1rem',
  height: 'auto',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  border: stroke ? `1px solid ${stroke}` : 'none',
  gridColumnGap: '0.3rem',
  justifyContent: 'center',
  position: 'relative',
  width: 'fit-content',
  alignItems: 'center',
  padding: '0 0.5rem 0.1rem 0.3rem',
  '& .MuiChip-icon': {
    display: 'flex',
    position: 'relative',
    left: 1,
    width: '0.8rem',
    height: '0.8rem',
    margin: 1,
    color: statusColor,
  },
  '& .MuiChip-label': {
    lineHeight: '1.4rem',
    fontSize: '0.75rem',
    position: 'relative',
    top: 0.6,
    padding: 0,
  },
}))
