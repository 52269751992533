import React from 'react'
import { Input, MenuItem, Select, styled } from '@mui/material'
import { isNil } from 'ramda'

import { AlertRulePayloadType } from '../../../../Shared/graphql/codegen'
import { ThresholdRuleCondition } from '../../Rules/types'
import { ThresholdRuleOptions, ThresholdRuleSelection } from '../types'

const baseInputStyle = {
  display: 'block',
  border: 'none',
  outline: 'none',
  borderRadius: '4px',
  padding: '1em',
  fontSize: '13px',
  textAlign: 'left',
}

const StyledInput = styled(Input)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBase.white,
  fontSize: '0.875em',
  padding: '1em',
  height: '48px',
  '> input': {
    padding: 0,
  },
  '&::before': {
    borderColor: 'rgb(220, 220, 220)',
  },
}))

const InfixWrapper = styled('span')({
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '14px',
  marginRight: '1em',
  paddingTop: '1em',
  minWidth: '2em',
})

const BreakGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledLabel = styled('label')({
  fontSize: '14px',
  display: 'block',
})

const Group = styled('div')(({ theme }) => ({
  margin: '0em 1em 0 0',
  '& > label': {
    fontSize: '14px',
  },
  '& > div button': {
    ...baseInputStyle,
    backgroundColor: theme.palette.SFIBase.white,
  },
}))

const StyledDropdown = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBase.white,
  width: '7.8em',
  height: '44px',
  '&::before': {
    borderBottom: 'none',
  },
}))

interface ThresholdRuleEditorProps {
  i18n: $I18FixMe
  onChange: (selection: ThresholdRuleSelection) => void
  options: ThresholdRuleOptions
  selected: ThresholdRuleSelection
  type: AlertRulePayloadType
}

const ThresholdRuleEditor = ({
  i18n,
  onChange,
  options,
  selected,
  type = AlertRulePayloadType.Power,
}: ThresholdRuleEditorProps): JSX.Element => {
  const { condition, values } = selected
  const { conditions: conditionOptions } = options

  const unitMap = {
    power: '(W)',
    productionSpeed: `(cycles per minute)`,
    volume: '(m³)',
  }

  const unit = unitMap[type] ?? '(W)'

  function _onChange(changes: Partial<ThresholdRuleSelection>) {
    const update = { ...selected, ...changes }

    if (update.condition === 'between' && update.values.length === 1) {
      update.values.push(0)
    } else if (update.condition !== 'between' && update.values.length === 2) {
      update.values.pop()
    }
    onChange(update)
  }

  function changeThreshold(index: number, threshold: number) {
    const updatedValues = [...values]
    updatedValues.splice(index, 1, threshold)
    _onChange({ values: updatedValues })
  }

  return (
    <>
      <BreakGroup>
        <Group>
          <StyledLabel>{i18n.text('rules.headers.condition')}</StyledLabel>
          <StyledDropdown
            variant="standard"
            value={condition}
            onChange={({ target: { value } }) => _onChange({ condition: value as ThresholdRuleCondition })}
          >
            {conditionOptions.map(option => (
              <MenuItem
                key={option.key}
                value={option.key}
              >
                {option.option}
              </MenuItem>
            ))}
          </StyledDropdown>
        </Group>
      </BreakGroup>
      <BreakGroup>
        <Group>
          <StyledLabel>
            {i18n.text('rules.headers.value')}
            &nbsp;
            {unit}
          </StyledLabel>
          <StyledInput
            key="threshold-rule-threshold-0"
            type="number"
            value={values[0] || 0}
            onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => changeThreshold(0, +value)}
          />
        </Group>
      </BreakGroup>
      {(condition === 'between' || isNil(condition)) && (
        <BreakGroup>
          <InfixWrapper>{i18n.text('rules.condition.infix.and')}</InfixWrapper>
          <Group>
            <StyledLabel>
              {i18n.text('rules.headers.value')}
              &nbsp;
              {unit}
            </StyledLabel>
            <StyledInput
              key="threshold-rule-threshold-1"
              type="number"
              value={values[1] || 0}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => changeThreshold(1, +value)}
            />
          </Group>
        </BreakGroup>
      )}
    </>
  )
}

export default ThresholdRuleEditor
