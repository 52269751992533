import React, { FC } from 'react'
import { Box, styled } from '@mui/material'

import { CustomDateTimePicker } from './CustomDateTimePicker'
import { FromToValues } from './types'
import { useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

interface IDateTimePickersFormProps {
  values: FromToValues
}

const DateTimePickersWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacing(3)}`,
}))

export const DateTimePickersForm: FC<IDateTimePickersFormProps> = ({ values }) => {
  const { i18n } = useI18nContext()
  const { handleFromToValues, setFromDateStatus } = useDateTimeRangePickerContext()

  return (
    <DateTimePickersWrapper>
      <CustomDateTimePicker
        values={values}
        setValues={handleFromToValues}
        labelText={i18n.text('datetimepicker.start-date')}
        type="from"
        onFocus={() => {
          setFromDateStatus(true)
        }}
        error={(values.from && values.to && values.from > values.to) || undefined}
      />
      <CustomDateTimePicker
        values={values}
        setValues={handleFromToValues}
        labelText={i18n.text('datetimepicker.end-date')}
        type="to"
        onFocus={() => {
          setFromDateStatus(false)
        }}
        error={(values.from && values.to && values.from > values.to) || undefined}
      />
    </DateTimePickersWrapper>
  )
}
