export interface ApexXAxisAnnotationData {
  id: string
  x: number
  x2?: number | null
  [key: string]: any
  label: {
    text?: string
    click?: (a: ApexXAxisAnnotationData) => void
    [key: string]: any
  }
}

// Valid units: "M" | "w" | "d" | "h" | "m" | "s" | "ms"
export type VibrationResolution =
  | `${number}M`
  | `${number}w`
  | `${number}d`
  | `${number}h`
  | `${number}m`
  | `${number}s`
  | `${number}ms`
export enum DateInterval {
  ThreeMonths = 90,
  TwoMonths = 60,
}
