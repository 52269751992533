import { Box, styled } from '@mui/material'
import { FC } from 'react'

import backgroundPattern from './Images/backGroundPattern.svg'
import {
  RegularLightXLText,
  SemiBoldDarkLgDisplayText,
  SemiboldBrandExtraDarkXsDisplayText,
} from '../../../components/Common/v1/index'
import { StyledPrimaryButton } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${backgroundPattern})`,
  paddingTop: '3.625rem',
  paddingBottom: '16.875rem',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'row',
  backgroundPosition: 'center',
  justifyContent: 'center',
  maxWidth: theme.breakpoints.values.xl,
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const ContentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  paddingTop: '3rem',
  marginLeft: '2rem',
  width: '50%',
}))

type IntroductionInputProps = {
  customer_name: string
}
export const Introduction: FC<IntroductionInputProps> = ({ customer_name }) => {
  const { i18n } = useI18nContext()
  return (
    <BackgroundBox>
      <ContentBox>
        <SemiboldBrandExtraDarkXsDisplayText>{customer_name}</SemiboldBrandExtraDarkXsDisplayText>
        <SemiBoldDarkLgDisplayText>{i18n.text(`scan.intro.start-page.title`)}</SemiBoldDarkLgDisplayText>
        <RegularLightXLText>{i18n.text(`scan.intro.start-page.subtitle`)}</RegularLightXLText>
        <StyledPrimaryButton sx={{ width: 'fit-content', height: 'fit-content', marginTop: '0.75rem' }}>
          {i18n.text(`scan.intro.start-page.savings-overview-button`)}
        </StyledPrimaryButton>
      </ContentBox>
      <img
        loading="lazy"
        alt="Discussion image"
        src={'https://res.cloudinary.com/dj4ypr91s/image/upload/v1723790582/online_energy_scan_static/meetingImage.png'}
      />
    </BackgroundBox>
  )
}
