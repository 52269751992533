import { Box, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import { FormInputs } from '../EditAlertDialog';
import { useI18nContext } from '../../../../../../Shared/contexts/i18nContext/I18nContext';

type NotificationLevelsProps = {
  control: Control<FormInputs, any>;
};

export default function NotificationLevels({ control }: NotificationLevelsProps) {
  const {i18n} = useI18nContext();
  return (
    <Box margin={"1rem"}>
      <FormLabel id="form-notification-level">{i18n.text('pdm.configure.alert.dialog.notification.levels.label')}</FormLabel>
      <Controller
        name="minimumNotifyLevel"
        control={control}
        render={({ field:{onChange, ...rest} }) => {
          return (
            <RadioGroup
              {...rest}
            >
              <FormControlLabel
                sx={{pointerEvents: 'none'}}
                value={0}
                label={i18n.text('pdm.configure.alert.dialog.notification.warning.label')}
                control={
                  <Radio color="primary" size='medium' sx={{pointerEvents: "auto"}} onChange={(e)=>{
                    onChange(0);
                  }} />
                }/>

              <FormControlLabel
                sx={{pointerEvents: 'none'}}
                value={1}
                label={i18n.text('pdm.configure.alert.dialog.notification.alarm.label')}
                control={
                  <Radio color="primary" size='medium' sx={{pointerEvents: "auto"}} onChange={(e)=>{
                    onChange(1);
                  }} />
                }/>
            </RadioGroup>
          )
        }} />
    </Box>
  );
}
