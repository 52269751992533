import React, { FC } from 'react'
import { Interval } from 'luxon'

import SimpleCsvExporter, {
  CsvDataMapper,
  CsvHeaderMapper,
  CsvHeaders,
} from '../../../Shared/components/MUIComponents/ChartTools/CsvExporter'
import { ITimeSeriesData, SwitchUnit } from '../../types/compressed-air_types'

interface CAConsumptionCsvExporterProps {
  seriesData: ITimeSeriesData[]
  interval: Interval
  filename?: string
  unit?: SwitchUnit
  decimal: number
}

const CAConsumptionCsvExporter: FC<CAConsumptionCsvExporterProps> = ({
  interval,
  unit: unitProp,
  filename,
  seriesData,
  decimal,
}) => {
  const headers: CsvHeaders = ['date', 'type', 'value', 'unit']

  const mapHeaders: CsvHeaderMapper = (headers, { valueSeparator }) => {
    return headers.join(valueSeparator)
  }

  const mapDataToHeaders: CsvDataMapper<ITimeSeriesData[]> = (data, { valueSeparator, decimalSeparator }) => {
    const result: string[] = []
    seriesData.forEach(asset => {
      asset.data.forEach(measurement => {
        const { value, time } = measurement as any
        const formattedValue = `${value.toFixed(decimal)}`.replace('.', decimalSeparator)
        const unit = unitProp
        const row = `${time}${valueSeparator}${asset.name}${valueSeparator}${formattedValue}${valueSeparator}${unit}`
        result.push(row)
      })
    })
    return result.join('\n')
  }

  return (
    <SimpleCsvExporter
      headers={headers}
      mapHeaders={mapHeaders}
      data={seriesData}
      mapDataToHeaders={mapDataToHeaders}
      interval={interval}
      filename={filename}
      slotProps={{
        button: {
          sx: {
            background: 'none',
            padding: 0,
            '&:hover': {
              background: 'none',
            },
          },
        },
      }}
    />
  )
}

export { CAConsumptionCsvExporter }
