import { flatten, propOr } from 'ramda'

import { LocalAsset } from '../../../../Shared/types/analysis_types'
import {
  getAssetById,
  getLargestMeasurement,
  getPowerOrderOfMagnitude,
  getScaledEnergyPriceMap,
  getVolumeOrderOfMagnitude,
  sortByName,
} from '../../../../Shared/utils'

const numberFormatOptions = { maximumFractionDigits: 2, minimumFractionDigits: 2 }

export function sumOfMeasurements(...measurements: $TSFixMe[]) {
  return measurements.reduce((acc, { value }) => acc + value, 0)
}

export function prepareData(
  allAssets: LocalAsset[],
  assetCount: $TSFixMe,
  selectedAssets: string[][],
  energyMeasurements: $TSFixMe,
  volumeMeasurements: $TSFixMe,
  commodityPrices: $TSFixMe,
  selectedColumn: $TSFixMe,
  i18n: $I18FixMe
) {
  const flatSelection = flatten(selectedAssets)
  const _selectedAssets = sortByName(flatSelection.map(id => getAssetById(allAssets, id)).filter(a => a))

  const selectedEnergyMeasurements = prepareMeasurements(flatSelection, energyMeasurements, getPowerOrderOfMagnitude)
  const selectedVolumeMeasurements = prepareMeasurements(flatSelection, volumeMeasurements, getVolumeOrderOfMagnitude)

  const scaledEnergyPrices = getScaledEnergyPriceMap(commodityPrices, selectedVolumeMeasurements.scale.unit)
  const showVolume = assetCount.volume > 0

  const tableCols = [
    { key: 'assetName', label: 'Asset' },
    {
      key: 'volumeConsumption',
      label: i18n.text('analysis.energy-balance.volumetric-consumption'),
    },
    {
      key: 'energyCost',
      label: i18n.text('analysis.energy-balance.water-costs'),
    }
  ]

  const tableContent = {
    showVolume,
    cols: tableCols,
    data: getTableData(
      _selectedAssets,
      volumeMeasurements,
      selectedVolumeMeasurements.scale,
      energyMeasurements,
      selectedEnergyMeasurements.scale,
      scaledEnergyPrices,
      i18n
    ),
  }

  const totalVolumeValue = tableContent.data.reduce(
    (acc: $TSFixMe, { values }: $TSFixMe) => acc + values.volumeConsumption,
    0
  )
  const totalEnergyValue = tableContent.data.reduce(
    (acc: $TSFixMe, { values }: $TSFixMe) => acc + values.energyConsumption,
    0
  )
  const totalEnergyCost = tableContent.data.reduce((acc: $TSFixMe, { values }: $TSFixMe) => acc + values.energyCost, 0)

  const { pieData, pieTotal } = getPieData(tableContent.data, selectedColumn)

  return {
    tableContent,
    tableTotals: {
      volume: `${i18n.number(totalVolumeValue, numberFormatOptions)} ${selectedVolumeMeasurements.scale.unit}`,
      energy: `${i18n.number(totalEnergyValue, numberFormatOptions)} ${selectedEnergyMeasurements.scale.unit}h`,
      energyCost: i18n.currency(totalEnergyCost, 'EUR'),
    },
    pieChart: {
      data: pieData,
      total: pieTotal,
    },
  }
}

function getTableData(
  assets: $TSFixMe,
  volumeMeasurements: $TSFixMe,
  volumeScale: $TSFixMe,
  energyMeasurements: $TSFixMe,
  powerScale: $TSFixMe,
  scaledEnergyPrices: $TSFixMe,
  i18n: $I18FixMe
) {
  return assets
    .map(({ name, id, color, markerShape, payload }: $TSFixMe) => {
      const { commodityType } = payload || {}
      const price = scaledEnergyPrices.get(commodityType) || 0

      const assetEnergyMeasurements = energyMeasurements[id]
      // We need to aggregate all the measurements because
      // GraphQL does not return consistent numbers of measurements.
      const energyValue =
        ((assetEnergyMeasurements &&
          assetEnergyMeasurements.measurements &&
          sumOfMeasurements(...assetEnergyMeasurements.measurements)) ||
          0) / powerScale.decimal

      const assetVolumeMeasurements = volumeMeasurements[id]
      const volumeValue =
        ((assetVolumeMeasurements &&
          assetVolumeMeasurements.measurements &&
          sumOfMeasurements(...assetVolumeMeasurements.measurements)) ||
          0) / volumeScale.decimal

      return {
        id,
        assetName: name,
        assetColor: color,
        assetMarkerShape: markerShape,
        values: {
          energyConsumption: energyValue,
          energyCost: volumeValue * price,
          volumeConsumption: volumeValue,
        },
        labels: {
          energyConsumption: energyValue
            ? `${i18n.number(energyValue, numberFormatOptions)} ${powerScale.unit}h`
            : i18n.text('analysis.energy-balance.no-data'),
          energyCosts: i18n.currency(volumeValue * price, 'EUR'),
          volumeConsumption: volumeValue
            ? `${i18n.number(volumeValue, numberFormatOptions)} ${volumeScale.unit}`
            : i18n.text('analysis.energy-balance.no-data'),
        },
      }
    })
    .sort(assetSort)
}

function getPieData(rowItems: $TSFixMe, selectedColumn: $TSFixMe) {
  const totalValue = rowItems.reduce((acc: $TSFixMe, { values }: $TSFixMe) => acc + (values[selectedColumn] || 0), 0)

  const data = rowItems
    .map(({ assetName, id, assetColor, assetMarkerShape, values }: $TSFixMe) => {
      const value = values[selectedColumn] || 0

      return {
        id,
        name: assetName,
        value,
        label: `${((value / totalValue) * 100).toFixed()}%`,
        color: assetColor,
        markerShape: assetMarkerShape,
      }
    })
    .filter(({ label }: $TSFixMe) => label !== '0%')
    .sort(assetSort)

  return {
    pieData: data,
    pieTotal: totalValue,
  }
}

function prepareMeasurements(flatAssets: $TSFixMe, measurementData: $TSFixMe, getOrderOfMagnitude: $TSFixMe) {
  const assetData = flatAssets
    .map((assetId: $TSFixMe) => measurementData[assetId])
    .filter((x: $TSFixMe) => x && x.measurements && x.measurements.length !== 0)

  const largestMeasurement = propOr(
    0,
    'value',
    getLargestMeasurement(assetData.flatMap((a: $TSFixMe) => a.measurements))
  )
  const scale = getOrderOfMagnitude(largestMeasurement)

  return {
    measurements: assetData,
    scale,
  }
}

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'a' implicitly has an 'any' type.
const assetSort = ({ value: a }, { value: b }) => {
  if (a > b) return -1
  if (a < b) return 1
  return 0
}
