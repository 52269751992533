import styled from 'styled-components'

import { themeColors } from '../../utils'

/** @deprecated do not use */
export default styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 400px;
  width: 100%;
  padding: 2em;
  color: ${themeColors.blackLight};
  margin: 4em auto 0 auto;

  p {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
`
