import { Box } from '@mui/material'
import { FC, useMemo } from 'react'

import AssetsTable from './AssetTable/AssetsTable'
import CreateStopReasons from './Modals/CreateStopReasons'
import ManageStopReasons from './Modals/ManageStopReasons'
import SettingsPageTitle from '../SettingsPageTitle'
import {
  Asset,
  OeeCustomerProductionStopReason,
  useGetOeeCustomerProductionStopReasonsQuery,
} from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

type StopReasonsProps = {
  assets: Asset[]
  refetchAssets: () => void
}

const StopReasons: FC<StopReasonsProps> = ({ assets, refetchAssets }) => {
  const { data, refetch: refetchStopReasons } = useGetOeeCustomerProductionStopReasonsQuery()

  const { i18n } = useI18nContext()

  const stopReasons = useMemo(
    () => (data?.myOrg?.customerProductionStopReasons ?? []) as OeeCustomerProductionStopReason[],
    [data]
  )

  const activeStopReasons = useMemo(() => stopReasons.filter(stopReason => stopReason.isEnabled), [stopReasons])
  const hasStopReasons = stopReasons.length > 0

  const handleStopReasonsUpdate = async () => {
    await refetchStopReasons()
    refetchAssets()
  }

  return (
    <Box sx={{ display: 'grid', padding: '0 2rem', rowGap: '2.5rem' }}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto', columnGap: '1rem', alignItems: 'start' }}>
        <SettingsPageTitle
          title={i18n.text('settings.oee-settings.stop-reasons')}
          description={i18n.text('settings.oee-settings.stop-reasons.description')}
        />
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          {hasStopReasons && (
            <ManageStopReasons
              onUpdate={handleStopReasonsUpdate}
              stopReasons={stopReasons}
            />
          )}
          <CreateStopReasons onUpdate={handleStopReasonsUpdate} />
        </Box>
      </Box>
      <AssetsTable
        assets={assets}
        stopReasons={activeStopReasons}
        onUpdate={handleStopReasonsUpdate}
      />
    </Box>
  )
}

export default StopReasons
