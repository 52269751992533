import { Card, CardActionArea, CardMedia, ImageListItem, Theme } from '@mui/material'

import { StyledCheckbox as Checkbox } from '../../../../Shared/components/MUIComponents/update/Checkbox/StyledCheckBox'

type ImageCheckboxProps = {
  theme: Theme
  image: {
    url?: string
    name?: string
  }
  isSelected: boolean
  toggleImageSelection: (url: string) => void
}

const SelectableImageCard = ({ theme, image, isSelected, toggleImageSelection }: ImageCheckboxProps) => {
  return (
    <ImageListItem>
      <Card
        raised={isSelected}
        sx={{
          maxWidth: '200px',
          cursor: 'pointer',
        }}
      />
      <CardActionArea onClick={() => image.url && toggleImageSelection(image.url)}>
        <CardMedia
          component="img"
          height="150"
          sx={{
            border: isSelected ? `5px solid ${theme.palette.SFIBrand[900]}` : undefined,
          }}
          image={image.url}
          alt={`Image ${image.url}`}
        />
        <Checkbox
          checked={isSelected}
          color="info"
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
          }}
        />
      </CardActionArea>
    </ImageListItem>
  )
}

export default SelectableImageCard
