import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { AlertTypes, Propositions } from '../types/alertTypes'
import {
  GetAlertMessagesQuery,
  GetAssetsForAlertMessagesQuery,
  GetSortedMessagesQuery,
  GetVibrationThresholdAlertMessagesQuery,
} from '../../Shared/graphql/codegen'
import { createMessagesRowData, getAssetName } from '../utils/alertMessages'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'

const DateFormat = 'dd MMM yyyy, HH:mm'

interface IUseMessagesRowsArgs {
  selectedProposition: string
  propositions: Propositions
  ruleBasedMessagesData?: GetSortedMessagesQuery
  caMessagesData?: GetAlertMessagesQuery
  thresholdMessagesData?: GetVibrationThresholdAlertMessagesQuery
  assetsData?: GetAssetsForAlertMessagesQuery
}

export const useMessagesRows = ({
  selectedProposition,
  propositions,
  ruleBasedMessagesData,
  caMessagesData,
  thresholdMessagesData,
  assetsData,
}: IUseMessagesRowsArgs) => {
  const { i18n } = useI18nContext()
  const rows = useMemo(() => {
    switch (selectedProposition) {
      case propositions.electricity.name:
      case propositions.oee.name: {
        const rows = ruleBasedMessagesData?.myOrg?.alertNotificationEvents || []
        const category =
          selectedProposition === propositions.electricity.name
            ? AlertTypes['POWER_ALERT']
            : AlertTypes['DOWNTIME_ALERT']
        return rows.map(row =>
          createMessagesRowData({
            id: row.id,
            date: DateTime.fromISO(row.time).toFormat(DateFormat),
            asset: getAssetName(row.subjects[0], assetsData),
            proposition: selectedProposition,
            message: row.message,
            category,
          })
        )
      }
      case propositions['water'].name:
      case propositions['gas'].name: {
        const rows = ruleBasedMessagesData?.myOrg?.alertNotificationEvents || []
        const category =
          selectedProposition === propositions.gas.name ? AlertTypes['GAS_ALERT'] : AlertTypes['WATER_ALERT']
        // cons
        return rows.map(row =>
          createMessagesRowData({
            id: row.id,
            date: DateTime.fromISO(row.time).toFormat(DateFormat),
            asset: getAssetName(row.subjects[0], assetsData),
            proposition: selectedProposition,
            message: row.message,
            category,
          })
        )
      }
      case propositions['compressed-air'].name: {
        const caRows = caMessagesData?.generalAlertRuleAlertEvents || []
        return caRows.map(row =>
          createMessagesRowData({
            id: row.id,
            date: DateTime.fromISO(row.triggeredAt).toFormat(DateFormat),
            asset: row.subject,
            proposition: propositions['compressed-air'].name,
            message: row.message || '',
            category: AlertTypes[row.category],
          })
        )
      }
      case propositions['predictive-maintenance'].name: {
        const pdmRows = thresholdMessagesData?.vibrationThresholdAlertEvents || []
        const getViolationLevelMessage = (violationLevel: number) => {
          const violationLevelText =
            violationLevel === 0
              ? i18n.text('notifications.messages.asset.text.vibrating.warning')
              : i18n.text('notifications.messages.asset.text.vibrating.alarm')
          return `${i18n.text('notifications.messages.asset.text.vibrating')} ${violationLevelText} ${i18n.text(
            'notifications.messages.asset.text.vibrating.level'
          )}`
        }
        return pdmRows.map(row =>
          createMessagesRowData({
            id: row.id,
            date: DateTime.fromISO(row.createdAt).toFormat(DateFormat),
            asset: row.config.conditions[0].sensorLocation.name,
            proposition: selectedProposition,
            message: getViolationLevelMessage(row.violationLevel),
            category: AlertTypes['THRESHOLD_ALERT'],
          })
        )
      }
      default:
        return []
    }
  }, [selectedProposition, ruleBasedMessagesData, caMessagesData, thresholdMessagesData, assetsData])
  return rows
}
