import { ComponentType } from './types'

type ComponentTypeNames = {
  [K in ComponentType]: string
}

export const vibrationLocationComponentTypeNames: ComponentTypeNames = {
  AC_MOTOR: 'AC Motor',
  DC_MOTOR: 'DC Motor',
  PUMP: 'Pump',
  BEARING: 'Bearing',
  COMPRESSOR: 'Compressor',
  GEARBOX: 'Gearbox',
  BLOWER_OR_FAN: 'Blower or Fan',
  OTHER: 'Other',
}
