import { Box } from '@mui/material'
import { FC } from 'react'

import { StyledOutlinedButton } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

type OpenTableButtonProps = {
  tableOpen: boolean
  handleOnClick: () => void
}

export const OpenTableButton: FC<OpenTableButtonProps> = ({ tableOpen, handleOnClick }) => {
  const { i18n } = useI18nContext()
  return (
    <Box sx={{ justifyContent: 'flex-start' }}>
      <StyledOutlinedButton
        disableRipple
        onClick={handleOnClick}
      >
        {tableOpen ? i18n.text('scan.general.hide-table') : i18n.text('scan.general.show-table')}
      </StyledOutlinedButton>
    </Box>
  )
}
