import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import Layout from '../components/Layout/Layout'
import MUILoader from '../../Shared/components/MUIComponents/Loader'
import { DEFAULT_LOOKBACK_DURATION_MINUTES } from '../utils/constants'
import { Error } from '../../Shared/components/MUIComponents/Error'
import { PredictiveMaintenanceContextProvider } from '../context/PredictiveMaintenanceContext'
import { usePdmOverviewQuery } from '../../Shared/graphql/codegen'

const PredictiveMaintenanceWrapper = () => {
  const { data, error, loading } = usePdmOverviewQuery({
    variables: {
      lookbackSeconds: DEFAULT_LOOKBACK_DURATION_MINUTES * 60,
    },
  })
  if (loading) {
    return <MUILoader />
  }
  if (error || !data) {
    return <Error />
  }

  const groups = (data.myOrg?.groups || []).filter(group => group.machines && group.machines.length)

  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <PredictiveMaintenanceContextProvider>
        <Layout groups={groups} />
      </PredictiveMaintenanceContextProvider>
    </QueryParamProvider>
  )
}
export default PredictiveMaintenanceWrapper
