import { Box, SelectChangeEvent } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import { DateTime } from 'luxon'

import { EBUBasicEnergyAsset, EnergyAssetRowState } from '../../types'
import { EnergyAssetFormRow } from './EnergyAssetFormRow'

interface EnergyFormProps {
  machines: EBUBasicEnergyAsset[]
  energyAssetRows: EnergyAssetRowState[]
  onAssetChange: (key: string, event: SelectChangeEvent<string>) => void
  onDateChange: (key: string, startDate: DateTime, endDate: DateTime) => void
  onContributionChange: (key: string, event: ChangeEvent<HTMLInputElement>) => void
  onAssetRemove: (key: string) => void
}

export const EnergyForm: FC<EnergyFormProps> = ({
  machines,
  energyAssetRows,
  onAssetChange,
  onContributionChange,
  onDateChange,
  onAssetRemove,
}) => {
  return (
    <Box>
      {energyAssetRows.map(ear => (
        <EnergyAssetFormRow
          machines={machines}
          selectedAssetId={ear.selectedAssetId}
          key={ear.key}
          uniqueKey={ear.key}
          startDate={ear.startDate}
          endDate={ear.endDate}
          contribution={ear.contribution}
          onAssetChange={onAssetChange}
          onDateChange={onDateChange}
          onContributionChange={onContributionChange}
          onAssetRemove={onAssetRemove}
        />
      ))}
    </Box>
  )
}
