import React, { useEffect } from 'react'
import { Box, useTheme } from '@mui/material'
import { DateTime } from 'luxon'

import AssetTreeLayout from '../Layouts/AssetTreeLayout'
import Error from '../../../Shared/components/MUIComponents/Error'
import Loader from '../../../Shared/components/MUIComponents/Loader'
import OeeAssetCard from '../../components/OeeAssetCard/OeeAssetCard'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import useAssetTreeMode from '../../hooks/useAssetTreeMode'
import { AssetTreeMode } from '../../types/Assets.types'
import { DEFAULT_TIMEZONE } from '../../../Shared/constants/timezone'
import { ID, TimeZone } from '../../../Shared/types/types'
import {
  OffAsset,
  OfflineAsset,
  ProductionAssetStatus,
  RunningAsset,
  StoppedAsset,
  useGetOeeAssetStatusQuery,
} from '../../../Shared/graphql/codegen'
import { PRODUCTION_STATISTICS_DATE_FORMAT } from '../../utils/constants'
import { Time } from '../../types/Time.types'
import { transformAssetOverviewData } from '../../utils/dataTransformations'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

const AssetOverview = () => {
  const { selectedCustomer } = useCurrentUser()
  const { selectedMachines } = useOEEContext()
  const theme = useTheme()
  const { i18n } = useI18nContext()

  useAssetTreeMode(AssetTreeMode.MULTI_SELECT)

  const pollInterval = Time.MillisecondsInMinute * 2
  const timeZone = (selectedCustomer?.timeZone || DEFAULT_TIMEZONE) as TimeZone

  const { data, loading, error, refetch } = useGetOeeAssetStatusQuery({
    variables: { assetIds: selectedMachines as ID[], timeZone: timeZone },
    pollInterval,
  })

  useEffect(() => {
    refetch({ assetIds: selectedMachines as ID[], timeZone: timeZone })
  }, [refetch, selectedMachines, timeZone])

  const productionAssetStatus = data?.productionAssetStatus as ProductionAssetStatus
  const lastUpdate = data?.productionAssetStatus.timestamp ?? DateTime.now().toISO()

  if (loading) return <Loader />
  if (error || !productionAssetStatus) return <Error />

  const assets = transformAssetOverviewData({
    running: (productionAssetStatus.running ?? []) as RunningAsset[],
    offline: (productionAssetStatus.offline ?? []) as OfflineAsset[],
    stopped: (productionAssetStatus.stopped ?? []) as StoppedAsset[],
    off: (productionAssetStatus.off ?? []) as OffAsset[],
  })

  return (
    <AssetTreeLayout>
      <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr', gridRowGap: theme.spacing(2) }}>
        <SectionHeader
          title={i18n.text('oee.secondary-nav.asset-overview')}
          chipContent={`${i18n.text('oee.asset-overview.last-update')}: ${DateTime.fromISO(lastUpdate).toFormat(
            PRODUCTION_STATISTICS_DATE_FORMAT
          )}`}
          shouldShowDatePicker={false}
        />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, 16.125rem)',
            gap: theme.spacing(1.5),
            paddingY: theme.spacing(1),
            alignItems: 'stretch',
            alignContent: 'start',
          }}
        >
          {assets.map(asset => {
            return (
              <OeeAssetCard
                key={asset.asset?.id}
                asset={asset}
                status={asset.status}
              />
            )
          })}
        </Box>
      </Box>
    </AssetTreeLayout>
  )
}

export default AssetOverview
