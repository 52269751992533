import { Box, lighten } from '@mui/material'
import { DateTime, Interval } from 'luxon'
import { WarningAmber } from '@mui/icons-material'
import { useCallback, useEffect, useMemo } from 'react'
import { useFeature } from 'flagged'

import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import {
  APEX_TOOLTIP_DATE_FORMAT_INTL,
  COMPARE_INTERVAL_NAME,
  GRAPH_TOOLBAR_DATE_CHIP_FORMAT,
  INITIAL_INTERVAL_NAME,
} from '../../constants'
import { ChartToolsItem } from '../../../Shared/components/MUIComponents/update/ChartToolsDropdown'
import { DEFAULT_TIMEZONE } from '../../../Shared/constants/timezone'
import { DataPointWithPrevTime, ElectricityDataSeries, SingleElectricityDataSeries } from '../../types'
import { GraphToolbar } from '../../components/GraphToolbar'
import { LocalDateTime, NonNegativeFloat } from '../../../Shared/types/types'
import { OOP_COLOR, STANDBY_COLOR } from '../../../Shared/theme/MuiThemeProvider'
import { PowerChart } from '../../components/PowerChart/PowerChart'
import { PowerCsvExporter } from '../../components/PowerCsvExporter'
import { STANDBY_STRING } from '../ElectricityContainer/constants'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { adjustMeasurementToMagnitude, getPowerOrderOfMagnitude } from '../../../Shared/utils'
import { calculateNewCompareInterval } from '../../utils/calculateNewCompareInterval'
import { filterUpToNow } from '../../utils/filterSeriesToNow'
import { getApexElectricityChartType } from '../../utils/getApexChartType'
import { getLargestSeriesMeasurement } from '../../utils/getLargestSeriesMeasurement'
import { mapMeasurementsToXY, mapStandbyToXYFakePower } from '../../utils/mapLegacyMeasurements'
import { sendToolbarButtonClickElectricity, sendZoomEventElectricity } from '../../utils/analyticsEvents'
import { useChartToolsContext } from '../../context/ChartToolsContext'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useElectricityContext } from '../../context/ElectricityContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const TOTAL_SERIES_ID = 'total'
const STANDBY_DAYS = 7 // this is based on the aggregation for power - daily aggregation does not make sense for standby, we keep it to hourly

const PowerChartContainer = () => {
  const { i18n } = useI18nContext()
  const {
    isSingleAssetSelected,
    areAssetsSelected,
    onDateRangeChange,
    selectedDateRange,
    compareDateRange,
    measurements,
    analysisType,
    selectedAssets,
    previousAssetMeasurements,
    doesAssetHaveStandbyEnabled,
    getAssetStandbyValidation,
    fetchMeasurementsLoading,
    fetchStandbyLoading,
    onCompareRangeChange,
    showCompare,
    setShowCompare,
    csvExportRequest,
  } = useElectricityContext()
  const {
    showTotal,
    setShowTotal,
    showMean,
    setShowMean,
    showWaste,
    setShowWaste,
    decimal,
    setDecimal,
    showOop,
    setShowOop,
  } = useChartToolsContext()
  const { hasElectricityStandbyEnabled, selectedCustomer } = useCurrentUser()
  const customerTimeZone = selectedCustomer.timeZone
  const { sendEvent } = useAnalytics()
  const finishedLoading = !fetchMeasurementsLoading && !fetchStandbyLoading

  const interval = useMemo(
    () => Interval.fromDateTimes(selectedDateRange.startDate, selectedDateRange.endDate),
    [selectedDateRange]
  )
  const timeZone = selectedCustomer.timeZone || DEFAULT_TIMEZONE

  const initialDataSeries: ElectricityDataSeries = useMemo(() => {
    const result: ElectricityDataSeries = []

    for (const asset of measurements) {
      const measurementsData = filterUpToNow(mapMeasurementsToXY(asset.measurements), timeZone)
      result.push({
        assetId: asset.id,
        name: showCompare ? `${asset.name} ${INITIAL_INTERVAL_NAME}` : asset.name,
        type: getApexElectricityChartType(analysisType),
        data: measurementsData,
        mean: (measurementsData.reduce((acc, curr) => acc + (curr.y || 0), 0) /
          measurementsData.length) as NonNegativeFloat,
        color: asset.color,
      })
    }
    return result
  }, [measurements, analysisType, showCompare])

  const totalSeries: SingleElectricityDataSeries = useMemo(() => {
    const totalSeries: SingleElectricityDataSeries = {
      assetId: TOTAL_SERIES_ID,
      name: i18n.text('analysis.total-consumption'),
      type: getApexElectricityChartType(analysisType),
      data: [],
      color: 'hsl(160, 100%, 40%)',
    }
    const timestampMap: { [key: string]: number } = {}

    initialDataSeries.forEach(assetSeries => {
      assetSeries.data.forEach(dataPoint => {
        const timestamp = dataPoint.x
        const value = dataPoint.y
        if (timestampMap[timestamp]) {
          timestampMap[timestamp] += value ? +value : 0
        } else {
          timestampMap[timestamp] = value ? +value : 0
        }
      })
    })

    Object.keys(timestampMap).forEach(x => {
      totalSeries.data.push({
        x: x as LocalDateTime,
        y: timestampMap[x] as NonNegativeFloat,
      })
    })
    return totalSeries
  }, [initialDataSeries, analysisType, i18n])

  const prevMeasurementSeries: ElectricityDataSeries = useMemo(() => {
    const result: ElectricityDataSeries = []

    for (const asset of previousAssetMeasurements) {
      const measurementsData = filterUpToNow(mapMeasurementsToXY(asset.measurements), timeZone)
      result.push({
        assetId: `${asset.id}-${COMPARE_INTERVAL_NAME}`,
        name: `${asset.name} ${i18n.text('graph.tooltip.previous-period')}`,
        isPreviousPeriod: true,
        type: getApexElectricityChartType(analysisType),
        data: measurementsData,
        mean: (measurementsData.reduce((acc, curr) => acc + (curr.y || 0), 0) /
          measurementsData.length) as NonNegativeFloat,
        color: lighten(asset.color, 0.7),
      })
    }
    return result
  }, [previousAssetMeasurements, i18n, showCompare])

  const wasteSeries: SingleElectricityDataSeries | null = useMemo(() => {
    if (!areAssetsSelected) return null
    const [selectedAssetId] = Object.keys(selectedAssets)
    const [standbyAsset] = measurements.filter(s => s.id === selectedAssetId)
    if (!standbyAsset || !standbyAsset.standby) return null

    const wasteSeries: SingleElectricityDataSeries = {
      assetId: standbyAsset.id,
      name: `${standbyAsset.name} ${STANDBY_STRING}`,
      isWaste: true,
      type: 'area',
      data: filterUpToNow(mapStandbyToXYFakePower(standbyAsset.standby, standbyAsset.measurements), timeZone),
      color: STANDBY_COLOR,
    }
    return wasteSeries
  }, [selectedAssets, measurements])

  const oopSeries: SingleElectricityDataSeries | null = useMemo(() => {
    if (!areAssetsSelected) {
      return null
    }
    const [selectedAssetId] = Object.keys(selectedAssets)
    const [standbyAsset] = measurements.filter(s => s.id === selectedAssetId)
    if (!standbyAsset || !standbyAsset.oop) return null

    const oopSeries: SingleElectricityDataSeries = {
      assetId: standbyAsset.id,
      name: `${standbyAsset.name} ${i18n.text('chart.tooltip.oop-name')}`,
      isOop: true,
      type: 'area',
      data: filterUpToNow(mapStandbyToXYFakePower(standbyAsset.oop, standbyAsset.measurements), timeZone),
      color: OOP_COLOR,
    }
    return oopSeries
  }, [selectedAssets, measurements])

  const fullDataSeries: ElectricityDataSeries = useMemo(() => {
    // Order is important here, it's what Apex uses to determine how to stack the colors, the last one is on top
    const series = [...initialDataSeries]

    if (showTotal) series.unshift(totalSeries)

    if (showCompare) series.push(...prevMeasurementSeries)

    if (showOop && oopSeries) series.push(oopSeries)

    if (showWaste && wasteSeries) series.push(wasteSeries)

    return series
  }, [
    showCompare,
    showWaste,
    showTotal,
    initialDataSeries,
    prevMeasurementSeries,
    totalSeries,
    wasteSeries,
    oopSeries,
    showOop,
  ])

  const largestMeasurement = useMemo(() => getLargestSeriesMeasurement(fullDataSeries), [fullDataSeries])
  const valuesModifier = useMemo(() => getPowerOrderOfMagnitude(largestMeasurement, false), [largestMeasurement])

  // adjust each series to be in correct order of magnitude
  const scaleAdjustedDataSeries = useMemo(() => {
    return fullDataSeries.map(series => ({
      ...series,
      ...(series.mean && {
        mean: adjustMeasurementToMagnitude(series.mean, valuesModifier.decimal) as NonNegativeFloat,
      }),
      data: series.data.map(d => ({
        ...d,
        y: adjustMeasurementToMagnitude(d.y, valuesModifier.decimal),
      })),
    }))
  }, [fullDataSeries, valuesModifier.decimal])

  const onTotalToggle = () => {
    setShowTotal(showTotal => !showTotal)
    sendToolbarButtonClickElectricity('toolbar_button_total', sendEvent)
  }

  const onMeanToggle = () => {
    setShowMean(showMean => !showMean)
    sendToolbarButtonClickElectricity('toolbar_button_mean', sendEvent)
  }
  const onStandbyWasteToggle = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setShowWaste(showWaste => !showWaste)
    setShowCompare(false)
    sendToolbarButtonClickElectricity('toolbar_button_standby_waste', sendEvent)
  }
  const onOopToggle = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setShowOop(showOop => !showOop)
  }

  const onZoomChange = useCallback(
    (chart: unknown, changed: { xaxis: { min: number; max: number } }) => {
      const {
        xaxis: { min, max },
      } = changed

      const startDate = DateTime.fromMillis(min)
      const endDate = DateTime.fromMillis(max)
      onDateRangeChange({ startDate, endDate }, true)
      if (compareDateRange.startDate && compareDateRange.endDate && showCompare) {
        const { newCompareStartDate, newCompareEndDate } = calculateNewCompareInterval(
          selectedDateRange.startDate,
          compareDateRange.startDate,
          startDate,
          endDate
        )
        onCompareRangeChange({ startDate: newCompareStartDate, endDate: newCompareEndDate }, true)
      }
      sendZoomEventElectricity(sendEvent)
    },
    [onDateRangeChange, onCompareRangeChange, compareDateRange, selectedDateRange, showCompare]
  )

  // TODO This is being refactored in a subsequent MR
  const tooltipFormatter: (
    value: number,
    options: { series: number[][]; seriesIndex: number; dataPointIndex: number }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any = useCallback(
    (value, { series: chartSeries, seriesIndex: chartIndex, dataPointIndex }) => {
      if (showWaste) {
        const wasteSeries = scaleAdjustedDataSeries.find(s => s.isWaste)
        const wasteIndex = scaleAdjustedDataSeries.findIndex(s => s.isWaste)
        const wasteData = chartSeries[wasteIndex]
        if (!wasteSeries || !wasteData) return null
        if (wasteData[dataPointIndex] !== null) {
          // DISABLED until we have a solution implemented to properly calculate standby energy for the interval
          // if there is a waste value, we're hovering over an area with waste and we want to show the total wasted energy
          // const assetStandbyData = measurements.find(s => s.id === wasteSeries.assetId)
          // const totalWasteInInterval =
          //   assetStandbyData?.standby[dataPointIndex].interval_energy || (0 as NonNegativeFloat)

          if (chartIndex !== wasteIndex) {
            // this is the power or the previous series or the oop series -- TODO fix situation where standby and oop are shown
            return value !== undefined && value !== null ? `${value.toFixed(decimal)} ${valuesModifier.unit}` : null
          } else {
            // this is the waste series
            return null
          }
        } else {
          // if there is no waste value, we're hovering over an area without waste and we want to show the power value at that specific point
          if (chartIndex !== wasteIndex) {
            return `${value ? value.toFixed(decimal) : 0} ${valuesModifier.unit}`
          } else {
            // this is the waste series, normally we would show the energy usage for the interval here
            // but this is DISABLED until we have a solution implemented to properly calculate standby energy for the interval
            // we also don't want to show the fake waste power value, so we return null
            return null
          }
        }
      } else if (showOop) {
        // same implementation as for standby - if hovering on the oop area, don't show anything, if hovering on a different series show the value
        const oopSeries = scaleAdjustedDataSeries.find(s => s.isOop)
        const oopIndex = scaleAdjustedDataSeries.findIndex(s => s.isOop)
        const oopData = chartSeries[oopIndex]
        if (!oopSeries || !oopData) return null
        if (oopData[dataPointIndex] !== null) {
          if (chartIndex !== oopIndex) {
            // this is the Power series or the previous series or the standby series -- TODO fix situation where standby and oop are shown
            return value || value === 0 ? `${value.toFixed(decimal)} ${valuesModifier.unit}` : null
          } else {
            // this is the oop series
            return null
          }
        } else {
          if (chartIndex !== oopIndex) {
            return `${value ? value.toFixed(decimal) : 0} ${valuesModifier.unit}`
          } else {
            return null
          }
        }
      } else {
        const previousPeriodSeriesIndexes = scaleAdjustedDataSeries
          .map((s, i) => (s.isPreviousPeriod ? i : null))
          .filter(i => i !== null)
        const isPreviousPeriodSeries = previousPeriodSeriesIndexes.includes(chartIndex)
        const previousPeriodSeries = scaleAdjustedDataSeries[chartIndex]
        const compareDataPoint = (previousPeriodSeries?.data[dataPointIndex] as DataPointWithPrevTime) || null
        const previousDateStampFormatted = compareDataPoint
          ? DateTime.fromISO(compareDataPoint.prevTime!, {
              zone: customerTimeZone as string,
            }).toFormat(APEX_TOOLTIP_DATE_FORMAT_INTL)
          : ''
        if (isPreviousPeriodSeries)
          return value || value === 0
            ? ` ${value.toFixed(decimal)} ${valuesModifier.unit} ${previousDateStampFormatted}`
            : ''
        if (value || value === 0) return `${value.toFixed(decimal)} ${valuesModifier.unit}`
      }
    },
    [scaleAdjustedDataSeries, valuesModifier.unit, showWaste, decimal]
  )

  const isStandbyForAssetEnabled = useMemo(() => {
    if (!isSingleAssetSelected || !areAssetsSelected) {
      return false
    }
    const [selectedAssetId] = Object.keys(selectedAssets)
    const result = doesAssetHaveStandbyEnabled(selectedAssetId)
    return result
  }, [isSingleAssetSelected, selectedAssets, doesAssetHaveStandbyEnabled])

  const selectedAssetValidationType = useMemo(() => {
    if (!isSingleAssetSelected) return null
    const [selectedAssetId] = Object.keys(selectedAssets)
    return getAssetStandbyValidation(selectedAssetId)
  }, [isSingleAssetSelected, selectedAssets])

  const isLargerThanStandbyInterval = useMemo(() => {
    const { startDate, endDate } = selectedDateRange
    const interval = Interval.fromDateTimes(startDate, endDate)

    return interval.toDuration('days').days > STANDBY_DAYS
  }, [selectedDateRange])

  const isStandbyButtonEnabled = useMemo(() => {
    return isSingleAssetSelected && isStandbyForAssetEnabled && !isLargerThanStandbyInterval
  }, [isSingleAssetSelected, isStandbyForAssetEnabled, isLargerThanStandbyInterval])

  const whyNoStandby = () => {
    if (!isSingleAssetSelected) {
      return i18n.text('graph.button.tools.show-waste-disabled:not-single-asset')
    }
    if (!isStandbyForAssetEnabled) {
      return i18n.text('graph.button.tools.show-waste-disabled:no-standby')
    }
    if (isLargerThanStandbyInterval) {
      return i18n.text('graph.button.tools.show-waste-disabled:larger-than-interval')
    }
    return i18n.text('graph.button.tools.show-waste')
  }

  // toggle chart tools buttons based on selected assets
  useEffect(() => {
    if (finishedLoading && isSingleAssetSelected) {
      setShowTotal(false)
    } else if (finishedLoading) {
      setShowWaste(false)
      setShowOop(false)
    }
  }, [isSingleAssetSelected, setShowTotal, setShowWaste, finishedLoading, setShowOop])

  // toggle waste based on selected asset
  useEffect(() => {
    if (finishedLoading && !isStandbyForAssetEnabled) {
      setShowWaste(false)
    }
  }, [isStandbyForAssetEnabled, setShowWaste, finishedLoading, setShowOop])

  // toggle waste based on interval
  useEffect(() => {
    if (isLargerThanStandbyInterval) {
      setShowWaste(false)
    }
  }, [isLargerThanStandbyInterval, setShowWaste])

  // togle standby waste based on compare period
  useEffect(() => {
    if (finishedLoading && showCompare) setShowWaste(false)
  }, [showWaste, finishedLoading, showCompare])

  const hasOop = useFeature('ELECTRICITY_OOP')
  const isDCValidation = useMemo(() => selectedAssetValidationType === 'DC', [selectedAssetValidationType])

  // We show a DC warning only if the standby button is enabled and DC is the validation
  // We don't show a DC warning if the button is disabled
  const shouldShowDCValidationWarning = isStandbyButtonEnabled && isDCValidation
  const dcValidationWarning = shouldShowDCValidationWarning
    ? i18n.text('graph.button.tools.show-waste-dc-validation-warning')
    : ''

  // We show a tooltip with "Show waste" if the button is enabled and there's no DC warning
  // We show a tooltip with the reason for the button being disabled if the button is disabled
  const shouldShowWasteButtonTooltip = !shouldShowDCValidationWarning

  const wasteButtonTooltip = shouldShowWasteButtonTooltip
    ? isStandbyButtonEnabled
      ? ''
      : whyNoStandby()
    : dcValidationWarning

  const title = `${i18n.text('machine-details.power')} (${valuesModifier.unit})`
  const chipDates =
    selectedDateRange.startDate.toFormat(GRAPH_TOOLBAR_DATE_CHIP_FORMAT) +
    ' - ' +
    selectedDateRange.endDate.toFormat(GRAPH_TOOLBAR_DATE_CHIP_FORMAT)

  const toolbarItems: ChartToolsItem[] = useMemo(() => {
    const result: ChartToolsItem[] = [
      {
        label: i18n.text('graph.button.tools.show-total'),
        onToggle: onTotalToggle,
        isChecked: showTotal,
        isDisabled: isSingleAssetSelected,
        tooltip: {
          text: isSingleAssetSelected ? i18n.text('graph.button.tools.show-total-disabled') : '',
        },
      },
      {
        label: i18n.text('graph.button.tools.show-mean'),
        onToggle: onMeanToggle,
        isChecked: showMean,
      },
      {
        label: `${i18n.text('chart.tools.decimal-label')} : ${decimal}`,
        values: [0, 1, 2, 3, 4],
        onValueToggle: (value: number) => setDecimal(value),
        selectedValue: decimal,
      },
    ]
    if (hasElectricityStandbyEnabled)
      result.unshift({
        label: i18n.text('graph.button.tools.show-waste'),
        onToggle: onStandbyWasteToggle as any,
        isChecked: showWaste,
        isDisabled: !isStandbyButtonEnabled,
        tooltip: {
          text: wasteButtonTooltip,
        },
        icon: shouldShowDCValidationWarning ? <WarningAmber fontSize="small" /> : undefined,
      })
    if (hasOop) {
      result.unshift({
        label: i18n.text('graph.button.tools.show-oop'),
        onToggle: onOopToggle as any,
        isChecked: showOop,
        isDisabled: !isSingleAssetSelected,
        tooltip: {
          text: !isSingleAssetSelected ? i18n.text('graph.button.tools.show-oop-disabled:not-single-asset') : '',
        },
      })
    }
    return result
  }, [
    decimal,
    hasElectricityStandbyEnabled,
    i18n,
    isSingleAssetSelected,
    onMeanToggle,
    onTotalToggle,
    onStandbyWasteToggle,
    setDecimal,
    showMean,
    showTotal,
    showWaste,
    shouldShowDCValidationWarning,
    isStandbyButtonEnabled,
    whyNoStandby,
  ])

  return (
    <SectionBoxWrapper sx={{ height: '100%' }}>
      <GraphToolbar
        titleText={title}
        chipContent={chipDates}
        toolbarItems={toolbarItems}
        showZoomButtons={true}
      />
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ display: 'none' }}>
          <PowerCsvExporter
            measurements={measurements}
            interval={interval}
            showDialog={csvExportRequest === 'power'}
          />
        </Box>

        <Box id="analysis-graph">
          <PowerChart
            tooltipFormatter={tooltipFormatter}
            valuesModifier={valuesModifier}
            data={scaleAdjustedDataSeries}
            interval={interval}
            handleZoomChange={onZoomChange}
            showMean={showMean}
            showWaste={showWaste}
            showOop={showOop}
            showPreviousPeriod={showCompare}
            loading={!finishedLoading}
          />
        </Box>
      </Box>
    </SectionBoxWrapper>
  )
}

export { PowerChartContainer }
