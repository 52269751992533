const storage = global.window.localStorage

export function isSupported(localStorage: $TSFixMe) {
  try {
    localStorage.setItem('localStorage', 1)
    localStorage.removeItem('localStorage')
    return true
  } catch (e) {
    return false
  }
}

export function makeString(value: $TSFixMe) {
  switch (typeof value) {
    case 'object':
      return JSON.stringify(value)
    case 'number':
      return `${value}`
    default:
      return value
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromString<T>(value: any): T | null {
  if (value == null) {
    return null
  }
  try {
    return JSON.parse(value)
  } catch (e) {
    return value as T
  }
}

export const browserStorage = {
  isSupported: isSupported(storage),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  internalStorage: {} as Record<string, any>,
  set(key: string, value: $TSFixMe) {
    const strValue = makeString(value)
    if (this.isSupported) storage.setItem(key, strValue)
    this.internalStorage[key] = strValue
    return { key, value }
  },
  get<T>(key: string): { key: string; value: T | null } {
    const value = this.isSupported
      ? fromString<T>(storage.getItem(key))
      : (this.internalStorage as Record<string, T>)[key]
    return { key, value }
  },
  remove(key: string) {
    if (this.isSupported) {
      storage.removeItem(key)
    }
    delete this.internalStorage[key]
  },
  clear() {
    if (this.isSupported) {
      storage.clear()
    }
    this.internalStorage = {}
  },
}
