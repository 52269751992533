import { Box, SelectChangeEvent, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import useAnalytics from '../../../../Shared/hooks/useAnalytics/useAnalytics'
import { AnalyticsIntent } from '../../../../Shared/hooks/useAnalytics/analyticsTypes'
import { FrequencySelect } from './GeneralSettingsInputs/FrequencySelect'
import { ISODateTime } from '../../../../Shared/types/types'
import { LanguageSelect } from './GeneralSettingsInputs/LanguageSelect'
import { MatchParams, RecipientDataProps, RecipientUpsertDataProps } from './types'
import {
  RecipientsFragment,
  UserLocale_Enum,
  useDeleteTrackingReportRecipientMutation,
  useGetReportByIdQuery,
  useGetReportsByCustomerIdQuery,
  useInsertReportMutation,
  useInsertTrackingReportRecipientsMutation,
  useUpdateReportSettingsMutation,
} from '../../../../Shared/graphql/codegen'
import { RecipientsList } from './RecipientsList'
import {
  StyledOutlinedButton,
  StyledPrimaryButton,
} from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { StyledTooltip } from '../../../../Shared/components/MUIComponents/Tooltip'
import { TitleInput } from './GeneralSettingsInputs/TitleInput'
import { trackBackToOverviewEvent, trackSaveReportEvent } from './trackingEvents'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../Shared/contexts/ToastContext'
import { validateEmail } from './utils'

const emptyRecipient = {
  name: '',
  destination: '',
  enabled: true,
}

export const ConfigureMainReportSettings: FC<MatchParams> = ({ reportId }) => {
  const { selectedCustomer } = useCurrentUser()
  const customerId = selectedCustomer?.id
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { sendEvent } = useAnalytics()
  const { showToast } = useToastContext()
  const { data, refetch: refetchReport } = useGetReportByIdQuery({ variables: { id: reportId } })
  const history = useHistory()
  const { refetch: refetchReports } = useGetReportsByCustomerIdQuery({
    variables: { id: customerId },
  })

  const [updateReportSettingsMutation] = useUpdateReportSettingsMutation({
    onCompleted: async response => {
      showToast('Report configuration updated successfully', 'success', 'Success!')
    },
    onError: async response => showToast('Something went wrong while saving changes.', 'error', 'Error'),
  })
  const [insertTrackingReportRecipientsMutation] = useInsertTrackingReportRecipientsMutation({
    onCompleted: async response => {
      showToast('Report configuration updated successfully', 'success', 'Success!')
    },
    onError: async response => showToast('Something went wrong while saving changes.', 'error', 'Error'),
  })
  const [deleteRecipientMutation] = useDeleteTrackingReportRecipientMutation({
    onCompleted: async response => {
      showToast('Successfully deleted recipient', 'success', 'Success!')
    },
    onError: async response => showToast('Something went wrong while deleting recipient.', 'error', 'Error'),
  })

  const [insertReportMutation] = useInsertReportMutation({
    onCompleted: async response => {
      showToast('New report saved successfully', 'success', 'Success!')
    },
    onError: async response => showToast('Something went wrong while saving changes.', 'error', 'Error'),
  })

  const [recipients, setRecipients] = useState<RecipientDataProps[] | undefined>([])
  const [title, setTitle] = useState<string>('Tracking Report')
  const [locale, setLocale] = useState<UserLocale_Enum>(UserLocale_Enum.EnGb)
  const [frequency, setFrequency] = useState<string>('weekly')

  const recipientsData: RecipientsFragment[] = [...(data?.TrackingReportReportConfigById?.recipients ?? [])]
  const currentRecipients =
    recipientsData.length > 0 ? Array.from(recipientsData, item => item as RecipientDataProps) : [emptyRecipient]

  const currentReportSettings = data && {
    title: data.TrackingReportReportConfigById?.title as string,
    locale: data.TrackingReportReportConfigById?.locale as UserLocale_Enum,
    frequency: data.TrackingReportReportConfigById?.frequency as string,
  }

  const handleDeleteRecipient = async (id: string | undefined, index: number) => {
    if (id) {
      const deleteParameters = { recipientId: id, deletedAt: DateTime.now().toISO() as ISODateTime }

      await deleteRecipientMutation({
        variables: deleteParameters,
      })
    }
    const newRecipientsArray = recipients?.filter((elem, ind) => ind !== index)
    setRecipients(newRecipientsArray)
  }

  const handleAddRecipient = () => {
    recipients ? setRecipients([...recipients, emptyRecipient]) : setRecipients([emptyRecipient])
  }

  const handleRecipientsChange = (recipients: RecipientDataProps[]) => {
    recipients && setRecipients(recipients)
  }

  useEffect(() => {
    setRecipients(currentRecipients)
    setTitle(currentReportSettings?.title || i18n.text(`reports.tracking.configuration.general.title.suggestion`))
    setLocale(currentReportSettings?.locale || UserLocale_Enum.EnGb)
    setFrequency(currentReportSettings?.frequency || 'weekly')
  }, [data, history])

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }

  const handleLocaleChange = (event: SelectChangeEvent) => {
    setLocale(event.target.value as UserLocale_Enum)
  }

  const handleFrequencyChange = (event: SelectChangeEvent) => {
    setFrequency(event.target.value)
  }

  const handleSave = async () => {
    if (reportId === 'new') {
      const reportInput = {
        input: {
          title: title,
          locale: locale,
          frequency: frequency,
          customerId: customerId,
          enabled: true,
        },
      }

      await insertReportMutation({
        variables: {
          ...reportInput,
        },
      }).then(async response => {
        const newReportId = response.data?.insertTrackingReportReportConfigs?.returning[0].id
        if (recipients) {
          const recipientsSettingsParametersNew = {
            recipients: recipients && recipients.map(recipient => ({ ...recipient, reportConfigId: newReportId })),
          } as RecipientUpsertDataProps

          await insertTrackingReportRecipientsMutation({
            variables: {
              ...recipientsSettingsParametersNew,
            },
          })
          await refetchReports()
        }
        trackSaveReportEvent(AnalyticsIntent.TR_SAVE_NEW_REPORT_GENERAL, sendEvent)
        history.push(`/reports/configuration/tracking/${newReportId}/modules`)
      })
    } else {
      const reportSettingsParameters = {
        reportId: reportId,
        title: title,
        locale: locale,
        frequency: frequency,
      }

      await updateReportSettingsMutation({
        variables: {
          ...reportSettingsParameters,
        },
      })

      if (recipients) {
        const recipientsSettingsParameters = {
          recipients:
            recipients &&
            recipients
              .map(recipient => ({ ...recipient, reportConfigId: reportId }))
              .map(function (recipient) {
                delete recipient.__typename
                return recipient
              }),
        } as RecipientUpsertDataProps

        await insertTrackingReportRecipientsMutation({
          variables: {
            ...recipientsSettingsParameters,
          },
        })
      }
      await refetchReport()
      await refetchReports()
      trackSaveReportEvent(AnalyticsIntent.TR_SAVE_EXISTING_REPORT_GENERAL, sendEvent)
      history.push(`/reports/configuration/tracking/${reportId}/modules`)
    }
  }

  const handleBackOverviewClick = () => {
    trackBackToOverviewEvent('general_back_to_overview_button', sendEvent)
    history.push(`/reports/configuration/tracking`)
  }

  const areRecipientsValid = recipients?.map(recipient => ({
    isNameValid: recipient.name.length > 0,
    isDestinationValid: !(validateEmail(recipient.destination) === null),
    get isRecipientValid() {
      return this.isNameValid && this.isDestinationValid
    },
  }))

  const isInputValid = {
    isTitleValid: title.length > 0,
    areRecipientsValid: areRecipientsValid?.every(({ isRecipientValid }) => isRecipientValid),
    get isSaveDisabled() {
      return !this.isTitleValid || !this.areRecipientsValid
    },
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '70rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginBottom: '2.625rem',
          }}
        >
          <Typography
            fontSize="1.125rem"
            color={theme.palette.SFIGreyLight[800]}
            fontWeight={600}
          >
            {i18n.text(`reports.tracking.configuration.general.reportSettings`)}
          </Typography>
          <Typography
            fontSize="0.875rem"
            color={theme.palette.SFIGreyLight[800]}
            fontWeight={400}
          >
            {i18n.text(`reports.tracking.configuration.general.editHere`)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <StyledOutlinedButton
            onClick={handleBackOverviewClick}
            sx={{ width: 'fit-content', height: 'fit-content' }}
          >
            {i18n.text(`reports.tracking.configuration.tabs.backOverview`)}
          </StyledOutlinedButton>
          <StyledTooltip
            title={isInputValid.isSaveDisabled ? i18n.text('reports.tracking.configuration.tooltip.saveDisabled') : ''}
          >
            <span>
              <StyledPrimaryButton
                onClick={handleSave}
                disabled={isInputValid.isSaveDisabled}
                sx={{ width: 'fit-content', height: 'fit-content' }}
              >
                {i18n.text(`reports.tracking.configuration.general.save`)}
              </StyledPrimaryButton>
            </span>
          </StyledTooltip>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          alignItems: 'left',
          justifyContent: 'flex-start',
        }}
      >
        <TitleInput
          handletitlechange={handleTitleChange}
          title={title}
        />
        <LanguageSelect
          handlelocalechange={handleLocaleChange}
          locale={locale}
        />
        <FrequencySelect
          handlefrequencychange={handleFrequencyChange}
          frequency={frequency}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'flex-start',
            textAlign: 'left',
          }}
        >
          <Typography
            fontWeight={600}
            fontSize="0.875rem"
            lineHeight="1.25rem"
            color={theme.palette.SFIGreyLight[700]}
          >
            {i18n.text(`reports.tracking.configuration.general.recipients`)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {recipients && (
            <RecipientsList
              recipients={recipients}
              onChange={handleRecipientsChange}
              onDelete={handleDeleteRecipient}
              onAdd={handleAddRecipient}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        ></Box>
      </Box>
    </Box>
  )
}
