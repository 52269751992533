import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { Box, Typography, useTheme } from '@mui/material'

interface StatusOverviewChartProps {
  vibrationSensorLocationLabels: string[]
  vibrationSensorLocationSeries: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined
}

const StatusOverviewChart = ({
  vibrationSensorLocationLabels,
  vibrationSensorLocationSeries,
}: StatusOverviewChartProps) => {
  const theme = useTheme()

  const statusOverviewLabels = vibrationSensorLocationLabels.map(
    label => label.charAt(0).toUpperCase() + label.substring(1)
  )

  const assetHealthReportChart: ApexOptions = {
    chart: {
      type: 'donut',
      offsetX: -10,
      offsetY: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '45%',
        },
      },
    },
    colors: [
      theme.palette.success.main,
      theme.palette.SFIWarning[300],
      theme.palette.SFIOrange[400],
      theme.palette.SFIGreyLight[300],
    ],
    labels: statusOverviewLabels,
    dataLabels: {
      enabled: false,
    },

    legend: {
      position: 'right',
      show: true,
      markers: {
        radius: 12,
        width: 9,
        height: 9,
        offsetY: 0,
        offsetX: 0,
      },
      offsetY: -25,
      offsetX: 5,
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          // Convert from decimal to percentage
          const percentage = val * 100
          return percentage.toFixed(0) + '%'
        },
      },
    },
    responsive: [
      {
        breakpoint: theme.breakpoints.values.sm,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }

  return (
    <Box
      data-testid="asset-health-report-pie-chart"
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        fontWeight: 600,
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: theme.palette.SFIGreyNeutral[50],
        gap: theme.spacing(1),
        borderRadius: '0.5rem',
      }}
    >
      {/* {i18n.text('pdm.asset-health-report.status-overview')} // TODO: Internationalization */}
      <Typography
        sx={{
          color: theme.palette.SFIGreyLight[800],
          fontSize: theme.typography.pxToRem(12),
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
        }}
      >
        Status overview
      </Typography>
      <ReactApexChart
        series={vibrationSensorLocationSeries}
        options={assetHealthReportChart}
        type="donut"
        height={350}
      />
    </Box>
  )
}

export default StatusOverviewChart
