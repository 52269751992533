import { createSvgIcon } from '@mui/material'

export const OfflineDotIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clipPath="url(#clip0_870_644)">
      <path
        d="M0.5 0.5L11.5 11.5M8.36 5.53C8.76955 5.72988 9.15252 5.98015 9.5 6.275M2.5 6.275C3.24104 5.65541 4.13294 5.24311 5.085 5.08M5.355 2.525C6.42621 2.43868 7.50382 2.56908 8.52352 2.90841C9.54323 3.24774 10.4841 3.78904 11.29 4.5M0.71 4.5C1.40358 3.88693 2.19885 3.39961 3.06 3.06M4.265 8.055C4.7726 7.69437 5.37984 7.50063 6.0025 7.50063C6.62516 7.50063 7.2324 7.69437 7.74 8.055M6 10H6.005"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_870_644">
        <rect
          width="12"
          height="12"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>,
  'offline-dot-icon'
)
