import { ICASensorLocationData, ICompressedAirSystemData } from '../types/compressed-air_types'

export function createSystemTableRowData({
  id,
  image,
  pressure,
  compressors,
  pipes,
}: ICompressedAirSystemData): ICompressedAirSystemData {
  return { image, id, pressure, compressors, pipes }
}

export function createCASensorLocationTableRowData({
  id,
  name,
  inner_diameter,
  outer_diameter,
  image,
}: ICASensorLocationData): ICASensorLocationData {
  return { image, id, name, inner_diameter, outer_diameter }
}
