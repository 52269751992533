import { Box } from '@mui/material'
import { FC } from 'react'

import { CompressedAirTree } from '../../AirAssetTree/AirAssetTree'

interface IAssetTreeLayoutProps {
  sid: (string | null)[] | never[]
  showAssetTree: boolean
  onSelectionUpdate: (ids: string[]) => void
}

const AssetTreeLayout: FC<IAssetTreeLayoutProps> = ({ children, sid, showAssetTree, onSelectionUpdate }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        padding: '1rem',
        gridTemplateColumns: showAssetTree ? '1fr' : 'auto 1fr',
        gap: '1rem',
        overflowY: 'auto',
      }}
    >
      {!showAssetTree && (
        <CompressedAirTree
          sid={sid}
          onSelectionUpdate={onSelectionUpdate}
        />
      )}
      {children}
    </Box>
  )
}

export default AssetTreeLayout
