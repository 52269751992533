import React, { FC } from 'react'
import { UseFormWatch } from 'react-hook-form'

import { CheckIcon } from '../../../../../Shared/components/icons'
import {
  StyledFlexBox,
  StyledGridBox,
  StyledValidationCase,
  StyledValidationCaseText,
  StyledValidationCasesTitle,
} from '../NewPasswordValidationView.styled'
import { MinusIcon } from '../../../../../Shared/components/icons/MinusIcon'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'
import { validationCases } from '../../../../utils/passwordValidationCases'
import { SetPasswordFormData } from '../../../../utils/setPasswordFormSchema'

type ValidationCasesBoxProps = {
  watch: UseFormWatch<SetPasswordFormData>
}

export const ValidationCasesBox: FC<ValidationCasesBoxProps> = ({ watch }) => {
  const { i18n } = useI18nContext()
  const password = watch('password')

  return (
    <StyledFlexBox>
      <StyledValidationCasesTitle>{i18n.text('forms.submit.password-rules-title')}</StyledValidationCasesTitle>
      <StyledGridBox>
        {validationCases.map(validationCase => (
          <StyledValidationCase key={validationCase.key}>
            {validationCase.onValidate(password) ? (
              <CheckIcon sx={{ width: '1rem', height: '1rem', color: theme => theme.palette.SFISuccess[700] }} />
            ) : (
              <MinusIcon sx={{ width: '1rem', height: '1rem', color: theme => theme.palette.SFIGreyLight[400] }} />
            )}
            <StyledValidationCaseText isValid={validationCase.onValidate(password)}>
              {i18n.text(validationCase.name)}
            </StyledValidationCaseText>
          </StyledValidationCase>
        ))}
      </StyledGridBox>
    </StyledFlexBox>
  )
}
