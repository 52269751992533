import SearchIcon from '@mui/icons-material/Search'
import { Card, CardProps, InputBase, alpha, styled, useTheme } from '@mui/material'
import { InputBaseProps } from '@mui/material'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.SFIGreyLight[600]}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: theme.palette.SFIGreyLight[600],
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.SFIGreyLight[300]} `,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    // backgroundColor: `${theme.palette.SFIGreyLight[600]}`,
    boxShadow: theme.shadows[5],
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}))

type SearchBoxProps = {
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  inputProps?: InputBaseProps
  cardProps?: CardProps
}

export const SearchBox: React.FC<SearchBoxProps> = ({ onChange, inputProps }) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        onChange={onChange}
        inputProps={{ 'aria-label': 'search', 'data-testid': 'search-input' }}
        {...inputProps}
      />
    </Search>
  )
}

export const SearchCard: React.FC<SearchBoxProps> = ({ onChange, onFocus, inputProps, cardProps }) => {
  const theme = useTheme()
  return (
    <StyledCard {...cardProps}>
      <SearchIconWrapper>
        <SearchIcon htmlColor={theme.palette.SFIGreyLight[700]} />
      </SearchIconWrapper>
      <StyledInputBase
        onChange={onChange}
        onFocus={onFocus}
        inputProps={{ 'aria-label': 'search', 'data-testid': 'search-input' }}
        {...inputProps}
      />
    </StyledCard>
  )
}
