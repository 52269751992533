import { Box, styled } from '@mui/material'
import { useMemo } from 'react'

import DocumentGroup from './DocumentGroup'
import { getReportGroups, getReportsIdsTitles } from './utils'
import { supportedLocales } from '../../../Shared/utils'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useEnergyScanDocumentGroup } from './energyScanDocuments'
import { useGetReportsTitlesByCustomerIdQuery, useReportDocumentsQuery } from '../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const LocalWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  height: '100%',
  overflowY: 'auto',
}))

const GroupsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}))

const ReportGroup = styled(Box)(({ theme }) => ({
  margin: '3.25rem 3rem 3.25rem 0',
  minWidth: '420px',
}))

const NoReportMessage = styled(Box)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[500],
  p: {
    display: 'inline-block',
    textAlign: 'center',
    width: '100%',
  },
}))

const ReportDocuments = (): JSX.Element => {
  const { i18n } = useI18nContext()
  const { customerId } = useCurrentUser()

  const { data } = useReportDocumentsQuery({
    variables: { locales: supportedLocales },
  })

  const { data: titlesData } = useGetReportsTitlesByCustomerIdQuery({ variables: { id: customerId } })
  const titles = titlesData?.TrackingReportReportConfigs ?? []

  const reports = data?.myOrg?.reports ?? []
  const reportsIdsTitles = useMemo(() => getReportsIdsTitles(reports, titles), [reports, titles])

  const reportGroups = useMemo(() => getReportGroups(reportsIdsTitles, i18n), [reportsIdsTitles, i18n])
  const eSDocumentGroup = useEnergyScanDocumentGroup(customerId)
  const allReportGroups = eSDocumentGroup.documents.length > 0 ? reportGroups.concat(eSDocumentGroup) : reportGroups
  return (
    <LocalWrapper>
      <GroupsContainer>
        {reportGroups.length === 0 && (
          <NoReportMessage>
            <p>{i18n.text('reports.update-reports.no-report')}</p>
          </NoReportMessage>
        )}
        {allReportGroups.map(group => (
          <ReportGroup key={group.id}>
            <DocumentGroup
              i18n={i18n}
              heading={group.heading}
              description={group.description}
              documents={group.documents}
              preview={group.preview}
            />
          </ReportGroup>
        ))}
      </GroupsContainer>
    </LocalWrapper>
  )
}

export default ReportDocuments
