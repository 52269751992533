import { ApexOptions } from 'apexcharts'
import { Theme } from '@mui/material'

import { DownloadGraphCA } from './analyticsTypes'
import { GraphId, SwitchUnit } from '../types/compressed-air_types'
import { dayTimeFormat, monthWeekDayFormat } from './utils'
import { defaultOptions } from './defaultOptions'
import { numberFormatOptions } from '../../Shared/constants/numberFormatOptions'
import { sendCATrackedDownloadEvent } from './analyticsEvents'
import { shortLocalesNames } from '../../Shared/utils'

interface IGetEfficiencyOptionsArgs {
  from: number,
  to: number,
  i18n: $I18FixMe
  graphId: GraphId
  unitName: SwitchUnit
  hasToolbar?: boolean
  theme: Theme
  isYaxisReversed?: boolean
  beforeZoom: (chart: any, options?: any) => void
  onZoomChange: (chart: any, options?: any) => void
  sendEvent: (name: string, params?: DownloadGraphCA) => void
}

export function getEfficiencyOptions({
  from,
  to,
  i18n,
  graphId,
  unitName,
  hasToolbar,
  beforeZoom,
  onZoomChange,
  theme,
  isYaxisReversed = false,
  sendEvent,
}: IGetEfficiencyOptionsArgs): ApexOptions {
  const apexOptions = defaultOptions as ApexOptions
  return {
    ...apexOptions,
    chart: {
      ...apexOptions.chart,
      group: 'trends',
      id: graphId,
      type: 'line',
      defaultLocale: shortLocalesNames[i18n.locale],
      events: {
        click: e => sendCATrackedDownloadEvent(e, 'flow', sendEvent),
        beforeZoom: beforeZoom ?? undefined,
        zoomed: onZoomChange,
      },
      toolbar: {
        ...apexOptions.chart?.toolbar,
        show: !!hasToolbar,
      },
    },
    tooltip: {
      ...apexOptions.tooltip,
      fixed: {
        enabled: false,
      },
      x: {
        show: true,
        format: 'MMM ddd dd HH:mm',
        formatter: (value: number) => {
          return `${i18n.date(new Date(value), monthWeekDayFormat)} ${i18n.date(new Date(value), dayTimeFormat)}`
        },
      },
      y: {
        formatter: (value: number) => `${value ? i18n.number(value, numberFormatOptions) : 0} ${unitName}`,
      },
    },
    legend: {
      ...apexOptions.legend,
      fontFamily: theme.typography.fontFamily,
      show: true,
      showForNullSeries: true,
      showForSingleSeries: true,
      showForZeroSeries: true,
    },
    xaxis: {
      ...apexOptions.xaxis,
      min: from,
      max: to
    },
    yaxis: {
      ...apexOptions.yaxis,
      reversed: isYaxisReversed,
      min: isYaxisReversed ? 0 : undefined,
      labels: {
        formatter: value => {
          return value ? `${i18n.number(value, numberFormatOptions)}` : '0'
        },
      },
    },
    stroke: {
      width: 2,
    },
    fill: {
      opacity: 1,
    },
  }
}
