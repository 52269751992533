export function edgeSliceFix() {
  const _window = global.window
  if (
    _window &&
    _window.navigator &&
    _window.navigator.userAgent &&
    /Edge\/1[0-4]\./.test(_window.navigator.userAgent)
  ) {
    // Fix for bug in Microsoft Edge: https://github.com/Microsoft/ChakraCore/issues/1415#issuecomment-246424339
    // eslint-disable-next-line no-console
    if (console && console.log) console.log('Applying function.call fix for Microsoft Edge <= 14')
    /* eslint-disable */
    Function.prototype.call = function (t) {
      return this.apply(t, Array.prototype.slice.apply(arguments, [1]))
    }
    /* eslint-enable */
  }
}
