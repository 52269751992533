import { TableCell, styled } from '@mui/material'

/**
 * CustomTableCell component for text content
 */
export const CustomTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '1rem',
  padding: '1rem 1.5rem',
  minWidth: 'max-content',
  textAlign: 'center',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderBottom: 'none',
  cursor: 'text',
}))
