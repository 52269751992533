import { Box, Checkbox, Typography } from '@mui/material'
import { FC } from 'react'

import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface SelectAllCheckboxProps {
  onClick: () => void
  isSelected: boolean
  isIndeterminate: boolean
}
export const SelectAllCheckbox: FC<SelectAllCheckboxProps> = ({ onClick, isSelected, isIndeterminate }) => {
  const { i18n } = useI18nContext()
  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme => theme.spacing(1.5),
        padding: theme => theme.spacing(1, 1, 1, 0),
        cursor: 'pointer',
        borderRadius: '6px',
        color: theme => theme.palette.SFIGreyLight[700],
        '&:hover': {
          backgroundColor: theme => theme.palette.SFIBrand?.[100],
          color: theme => theme.palette.SFIBrand?.[800],
        },
      }}
      onClick={onClick}
    >
      <Checkbox
        checked={isSelected}
        indeterminate={isIndeterminate}
        color="secondary"
        size="small"
        sx={{
          padding: 0,
        }}
      />
      <Typography
        sx={{
          fontSize: '0.875rem',
          fontWeight: 500,
          lineHeight: '1.25rem',
          underline: 'none',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: 'inherit',
        }}
      >
        {isSelected || isIndeterminate ? i18n.text('lists.items.deselect-all') : i18n.text('lists.items.select-all')}
      </Typography>
    </Box>
  )
}
