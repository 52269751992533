import Toast from '@sensorfactdev/nucleus/Toast'
import ToastWrapper from '@sensorfactdev/nucleus/ToastWrapper'
import { Box, CssBaseline, ThemeProvider, useTheme } from '@mui/material'
import { Redirect, RouteComponentProps, Switch, useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useApolloClient } from '@apollo/client'
import { useEffect, useMemo } from 'react'

import Alerts from '../Alerts'
import ComponentsPage from '../ComponentsPage'
import CompressedAirContainer from '../../../CompressedAir/containers/CompressedAirContainer'
import ElectricityContainer from '../../../Electricity/containers/ElectricityContainer/ElectricityContainer'
import EnergyReportsContainer from '../EnergyReports'
import GasContainer from '../../../Gas/containers/GasContainer/GasContainer'
import IntroSlideshow from '../../components/IntroSlideshow/IntroSlideshow'
import NavBar from '../../components/Navigation/NavBar'
import OfflineMessage from '../../../Shared/components/OfflineMessage'
import OverallEquipmentEffectiveness from '../../../OEE/containers'
import PredictiveMaintenance from '../../../PredictiveMaintenance/containers'
import SettingsContainer from '../SettingsContainer/SettingsContainer'
import Temperature from '../../../Temperature/containers'
import WaterContainer from '../../../Water/containers/WaterContainer/WaterContainer'
import actionCreators from '../../../Shared/actions/appActions'
import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import usePropositions, { CurrentPropositions } from '../../../Shared/hooks/usePropositions/usePropositions'
import type { AppState } from './reducers'
import { ElectricityContextProvider } from '../../../Electricity/context/ElectricityContext'
import { Installation } from '../Installation'
import { PropositionRoute } from '../../../Shared/components/RouteComponents/PropositionRoute'
import type { RootState } from '../../store/configureStore'
import { SentryRoute as Route } from '../../../Shared/services/sentry'
import { SFIndigoMuiTheme } from '../../../Shared/theme/MuiThemeProvider'
import { browserStorage } from '../../../Shared/utils'
import { isAnalysisType } from '../../../Shared/types/analysis_types'
import { useAuthContext } from '../../../Shared/contexts/AuthContext'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import OnlineOnboarding from '../../../Onboarding/containers'
import { AlertsPage } from '../../pages/AlertsPage'
import { useFeature } from 'flagged'

type AppContainerProps = RouteComponentProps & {
  actions: ActionsPropsMap<typeof actionCreators>
  app: AppState
}

const AppContainer = (props: AppContainerProps) => {
  const apolloClient = useApolloClient()
  const { app } = props
  const {
    actions: { dismissToast, showSettings, updateNetworkStatus },
  } = props

  const { i18n } = useI18nContext()
  const { user, selectedCustomer } = useCurrentUser()
  const { isSensorfactEmployee } = useAuthContext()

  const { getFirstProposition, propositions, loading } = usePropositions()
  const {
    match: { path },
  } = props

  const isOnline = app.get('isOnline', false)

  const hasMultipleCustomers = user.customers.length > 1
  // TODO (Sabina): DELETE WHEN READY
  const isNewAlertsDesignEnabled = useFeature('ALERTS_NEW_DESIGN')

  const { sendPageview } = useAnalytics()

  const location = useLocation()

  // Send pageviews to GA
  useEffect(() => {
    sendPageview()
  }, [location.pathname, sendPageview])

  useEffect(() => {
    const shouldShowTrackingConsent = !browserStorage.get('sf-hasSeenTrackingConsent').value

    if (shouldShowTrackingConsent) {
      showSettings('preferences')
    }

    global.window.addEventListener('online', updateNetworkStatus)
    global.window.addEventListener('offline', updateNetworkStatus)
    updateNetworkStatus()

    return () => {
      global.window.removeEventListener('online', updateNetworkStatus)
      global.window.removeEventListener('offline', updateNetworkStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const theme = useTheme()
  const firstAvailableProposition = getFirstProposition() ?? CurrentPropositions.ELECTRICITY
  const toasts = app.get('toasts').toJS()

  const ElectricityRoutes = useMemo(
    () => (
      <Switch>
        <Route
          exact
          path="/electricity/analysis/:type"
          render={routeProps => {
            const type = routeProps.match.params.type
            if (!isAnalysisType(type)) {
              return <Redirect to="/electricity/analysis/power" />
            }
            return (
              <ElectricityContextProvider>
                <ElectricityContainer />
              </ElectricityContextProvider>
            )
          }}
        />
        <Redirect to={propositions[CurrentPropositions.ELECTRICITY].mainPath} />
      </Switch>
    ),
    [propositions]
  )
  const GasRoutes = useMemo(
    () => (
      <Switch>
        <Route
          exact
          path="/gas/analysis/:type"
          render={routeProps => {
            const type = routeProps.match.params.type
            if (!isAnalysisType(type)) {
              return <Redirect to="/gas/analysis/volume" />
            }
            return (
              <GasContainer
                {...props}
                apolloClient={apolloClient}
                hasMultipleCustomers={hasMultipleCustomers}
                i18n={i18n}
                route={{ path }}
                location={location}
                customer={selectedCustomer}
                type={type}
              />
            )
          }}
        />
        <Redirect to={propositions[CurrentPropositions.GAS].mainPath} />
      </Switch>
    ),
    [propositions, apolloClient, hasMultipleCustomers, i18n, location, path, props, selectedCustomer]
  )
  const WaterRoutes = useMemo(
    () => (
      <Switch>
        <Route
          exact
          path="/water/analysis/:type"
          render={routeProps => {
            const type =
              routeProps.match.params.type === 'water-balance' ? 'energy-balance' : routeProps.match.params.type
            if (!isAnalysisType(type)) {
              return <Redirect to="/water/analysis/volume" />
            }
            return (
              <WaterContainer
                {...props}
                apolloClient={apolloClient}
                hasMultipleCustomers={hasMultipleCustomers}
                i18n={i18n}
                route={{ path }}
                location={location}
                customer={selectedCustomer}
                type={type}
              />
            )
          }}
        />
        <Redirect to={propositions[CurrentPropositions.WATER].mainPath} />
      </Switch>
    ),
    [propositions, apolloClient, hasMultipleCustomers, i18n, location, path, props, selectedCustomer]
  )

  return (
    <ThemeProvider theme={SFIndigoMuiTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: theme.palette.SFIBase.white,
          height: '100vh',
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
        }}
      >
        <NavBar />

        <Box
          sx={{
            // account for navbar height
            height: 'calc(100% - 64px)',
            [theme.breakpoints.down('sm')]: {
              height: 'calc(100% - 56px)',
            },
          }}
        >
          {!loading && (
            <Switch>
              <PropositionRoute
                path={`/${CurrentPropositions.ELECTRICITY}`}
                prop={CurrentPropositions.ELECTRICITY}
                component={ElectricityRoutes}
              />
              <PropositionRoute
                path={`/${CurrentPropositions.GAS}`}
                prop={CurrentPropositions.GAS}
                component={GasRoutes}
              />
              <PropositionRoute
                path={`/${CurrentPropositions.WATER}`}
                prop={CurrentPropositions.WATER}
                component={WaterRoutes}
              />
              <PropositionRoute
                path={`/${CurrentPropositions['COMPRESSED-AIR']}`}
                prop={CurrentPropositions['COMPRESSED-AIR']}
                component={<CompressedAirContainer />}
              />
              <PropositionRoute
                path={`/${CurrentPropositions['PREDICTIVE-MAINTENANCE']}`}
                prop={CurrentPropositions['PREDICTIVE-MAINTENANCE']}
                component={<PredictiveMaintenance />}
              />
              <PropositionRoute
                path={`/${CurrentPropositions['OVERALL-EQUIPMENT-EFFECTIVENESS']}`}
                prop={CurrentPropositions['OVERALL-EQUIPMENT-EFFECTIVENESS']}
                component={<OverallEquipmentEffectiveness />}
              />
              <PropositionRoute
                path={`/${CurrentPropositions['TEMPERATURE']}`}
                prop={CurrentPropositions['TEMPERATURE']}
                component={<Temperature />}
              />
              <Route
                path="/settings"
                render={() => <SettingsContainer />}
              />
              <Route
                path="/reports"
                render={() => <EnergyReportsContainer />}
              />
              <Route
                path="/installation"
                render={() => (
                  <Installation
                    {...props}
                    apolloClient={apolloClient}
                  />
                )}
              />
              <Route
                path="/alerts"
                render={() => (
                  <Alerts
                    history={props.history}
                    location={props.location}
                    match={props.match}
                  />
                )}
              />
              <Route
                path="/onboarding"
                render={() => <OnlineOnboarding />}
              />
              {/* keep electricity legacy links working with this redirect */}
              {/* TODO (Sabina): Delete once all alerts migrated */}
              {isNewAlertsDesignEnabled && 
                <Route
                  path="/alerts2"
                  render={() => (
                    <AlertsPage />
                  )}
                />}
              <Route
                path="/analysis/power"
                render={({ location }) => <Redirect to={`/electricity/analysis/power${location.search}`} />}
              />
              {isSensorfactEmployee() && (
                <Route
                  path="/components"
                  render={() => <ComponentsPage />}
                />
              )}
              <Redirect to={`/${firstAvailableProposition}`} />
            </Switch>
          )}
          {!isOnline && <OfflineMessage />}
          <IntroSlideshow />
          <ToastWrapper style={{ zIndex: 10001 }}>
            {toasts.map(({ title, body, level, timeStamp }: $TSFixMe, index: $TSFixMe) => (
              <Toast
                key={timeStamp}
                title={title}
                body={body}
                level={level}
                onDismiss={() => dismissToast(index)}
              />
            ))}
          </ToastWrapper>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const mapStateToProps = ({ app }: RootState) => ({
  app,
})

const mapDispatchToProps = (dispatch: $TSFixMeDispatch) => ({
  actions: bindActionCreators({ ...actionCreators }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
