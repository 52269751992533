import ReactApexChart from 'react-apexcharts'
import { FC, useCallback, useMemo } from 'react'

import { OOP_COLOR, STANDBY_COLOR } from '../../../Shared/theme/MuiThemeProvider'
import { SummedEnergyBalanceSeries, ValuesModifier } from '../../types'
import { getCurrencyFormatter } from '../../../Shared/utils/formatCurrency'
import { useChartToolsContext } from '../../context/ChartToolsContext'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useEnergyBalanceStandbyBarChartOptions } from './energyBalanceStandbyBarChartOptions'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type EnergyBalanceBarChartProps = {
  chartWidth: string
  interval?: Interval
  data: SummedEnergyBalanceSeries
  valuesModifier: ValuesModifier
  showingCosts: boolean
  loading: boolean
  onDataPointMouseEnter?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
  onDataPointMouseLeave?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
}

const EnergyBalanceStandbyBarChart: FC<EnergyBalanceBarChartProps> = ({
  data,
  valuesModifier,
  chartWidth,
  showingCosts,
  loading,
  onDataPointMouseEnter,
  onDataPointMouseLeave,
}) => {
  const { i18n, userLocale } = useI18nContext()
  const { decimal, showOop, showWaste } = useChartToolsContext()

  const { customerCurrency } = useCurrentUser()
  const currencyFormatter = useMemo(
    () => getCurrencyFormatter(userLocale, customerCurrency.isocode),
    [userLocale, customerCurrency.isocode]
  )

  const sortedData = useMemo(() => data.sort((a, b) => b.total - a.total), [data])
  const categories = useMemo(() => sortedData.map(s => (s.isWaste ? null : s.name)).filter(s => !!s), [sortedData])

  const totalsFormatter = useCallback(
    (v: string | undefined) => {
      if (typeof v === 'undefined') return ''
      return showingCosts
        ? currencyFormatter(Math.round(+v), 0)
        : `${i18n.number(v, { maximumFractionDigits: decimal })} ${valuesModifier.unit}`
    },
    [currencyFormatter, decimal, i18n, showingCosts, valuesModifier.unit]
  )

  const dataSeries: ApexAxisChartSeries = useMemo(() => {
    const result: {
      regular?: ApexAxisChartSeries[number]
      standby?: ApexAxisChartSeries[number]
      oop?: ApexAxisChartSeries[number]
    } = {}
    if (showingCosts) {
      result.regular = {
        name: i18n.text('graph.energy-balance.legend.regular-consumption'),
        data: [],
        color: '#4CAF50',
      }
      if (showWaste)
        result.standby = {
          name: i18n.text('graph.energy-balance.legend.waste'),
          data: [],
          color: STANDBY_COLOR,
        }
      if (showOop)
        result.oop = {
          name: i18n.text('chart.tooltip.oop-name'),
          data: [],
          color: OOP_COLOR,
        }

      sortedData.forEach((series, i) => {
        result.regular?.data.push(series.cost as any)
        showWaste && result.standby?.data.push(series.standbyCost as any)
        showOop && result.oop?.data.push(series.oopCost as any)
      })
    } else {
      result.regular = {
        name: i18n.text('graph.energy-balance.legend.regular-consumption') + ` (${valuesModifier.unit})`,
        data: [],
        color: '#4CAF50',
      }

      if (showWaste)
        result.standby = {
          name: i18n.text('graph.energy-balance.legend.waste') + ` (${valuesModifier.unit})`,
          data: [],
          color: STANDBY_COLOR,
        }

      if (showOop)
        result.oop = {
          name: i18n.text('chart.tooltip.oop-name') + ` (${valuesModifier.unit})`,
          data: [],
          color: OOP_COLOR,
        }

      sortedData.forEach((series, i) => {
        result.regular?.data.push(series.total as any)
        showWaste && result.standby?.data.push(series.standbyTotal as any)
        if (showOop) {
          result.oop?.data.push(series.oopTotal as any)
        }
      })
    }
    return [result.standby, result.oop, result.regular].filter(s => s) as ApexAxisChartSeries // Apex breaks with undefined series
  }, [sortedData, i18n, valuesModifier.unit, showingCosts, showWaste, showOop])

  const tooltipFormatter: (value: number | string) => string = useCallback(
    value => {
      return showingCosts
        ? currencyFormatter(+value)
        : `${i18n.number(value, { maximumFractionDigits: decimal })} ${valuesModifier.unit}`
    },
    [valuesModifier.unit, showingCosts, i18n]
  )

  const options = useEnergyBalanceStandbyBarChartOptions({
    tooltipFormatter,
    totalsFormatter,
    categories,
    onDataPointMouseEnter,
    onDataPointMouseLeave,
    showingCosts,
    valuesModifier,
    decimal,
    loading,
  })
  return (
    <ReactApexChart
      options={options}
      series={dataSeries}
      type="bar"
      height="600"
      width={chartWidth}
    />
  )
}

export { EnergyBalanceStandbyBarChart }
