import { ButtonGroup } from '@mui/material'
import { DateTime } from 'luxon'
import { FC } from 'react'

import { OutlinedStyledButton } from '../../../Shared/components/MUIComponents/update/OutlinedStyledButton'
import { toLocalDateTime } from '../../../Shared/utils'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type QuickInterval = {
  start: DateTime
  end: DateTime
  label: string
}

type QuickIntervalButtonsProps = {
  quickIntervals: QuickInterval[]
}

const QuickIntervalButtons: FC<QuickIntervalButtonsProps> = ({ quickIntervals }) => {
  const { updateInterval } = useOEEContext()
  const { i18n } = useI18nContext()

  const selectInterval = (start: DateTime, end: DateTime) => {
    updateInterval({ start: toLocalDateTime(start), end: toLocalDateTime(end) })
  }

  return (
    <ButtonGroup>
      {quickIntervals.map((interval, index) => {
        return (
          <OutlinedStyledButton
            key={`quick-interval-${index}`}
            onClick={() => {
              selectInterval(interval.start, interval.end)
            }}
          >
            {i18n.text(interval.label)}
          </OutlinedStyledButton>
        )
      })}
    </ButtonGroup>
  )
}

export default QuickIntervalButtons
