//Creates overview of the buttons the report has. Module order can be changed here by d-n-d
//Module settings can be accessed by pressing pencil button

import { Box, Typography, styled, useTheme } from '@mui/material'
import { DropResult } from 'react-beautiful-dnd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import DraggableModuleList from './ModuleList'
import MUILoader from '../../../../Shared/components/MUIComponents/Loader'
import useAnalytics from '../../../../Shared/hooks/useAnalytics/useAnalytics'
import {
  AnalysisModulesFragment,
  TrackingReportRequestTypeInput,
  Tracking_Reports_Tracking_Report_Request_Type_Enum,
  useFlowTriggerMutation,
  useGetReportByIdQuery,
  useUpdateAnalysisModulesPositionsMutation,
} from '../../../../Shared/graphql/codegen'
import { EyeIcon } from '../../../../Shared/components/icons/EyeIcon'
import { FileDownloadIcon, PlusCircleIcon } from '../../../../Shared/components/icons'
import { HelpIcon } from '../../../../Shared/components/icons'
import { ID } from '../../../../Shared/types/types'
import { IconWrapper, StyledTooltip } from '../../../../Shared/components/MUIComponents/Tooltip'
import { MatchParams, ModuleProps, ModuleUpsertProps } from './types'
import { NoDataFoundSlot } from '../../../../Shared/components/MUIComponents/update/NoDataFoundSlot'
import {
  StyledOutlinedButton,
  StyledPrimaryButton,
} from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { reorder } from './utils'
import {
  trackBackToOverviewEvent,
  trackModuleOrderChangeEvent,
  trackPreviewReportEvent,
  trackStartNewModuleEvent,
} from './trackingEvents'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../Shared/contexts/ToastContext'

const StyledHeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '1.125rem',
  color: theme.palette.SFIGreyLight[600],
}))

const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '1.125rem',
  lineHeight: '1.75rem',
  color: theme.palette.SFIGreyLight[800],
}))

export const ModuleOverview = () => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { showToast } = useToastContext()
  const { isInternalUser } = useCurrentUser()
  const { sendEvent } = useAnalytics()
  const history = useHistory()
  const { reportId } = useParams<MatchParams>()
  const { data, refetch: refetchGetReportByIdQuery, loading } = useGetReportByIdQuery({ variables: { id: reportId } })
  const analysisModules: AnalysisModulesFragment[] = [...(data?.TrackingReportReportConfigById?.analysisModules ?? [])]

  const [updateAnalysisModulesPositionsMutation] = useUpdateAnalysisModulesPositionsMutation({
    onCompleted: async response => {
      showToast(
        i18n.text(`reports.tracking.configuration.modules.positionSuccess`),
        'success',
        i18n.text(`reports.tracking.configuration.module.success`)
      )
    },
    onError: async response =>
      showToast(
        i18n.text(`reports.tracking.configuration.module.savedFail`),
        'error',
        i18n.text(`reports.tracking.configuration.module.error`)
      ),
  })

  const [flowTriggerMutation] = useFlowTriggerMutation({
    onCompleted: async response => {
      if (
        response.requestTrackingReport?.reportRequest?.requestType ===
        Tracking_Reports_Tracking_Report_Request_Type_Enum.DryRun
      ) {
        showToast(
          i18n.text(`reports.tracking.configuration.modules.flowSuccess`),
          'success',
          i18n.text(`reports.tracking.configuration.module.success`)
        )
      }

      if (
        response.requestTrackingReport?.reportRequest?.requestType ===
        Tracking_Reports_Tracking_Report_Request_Type_Enum.Preview
      ) {
        window.open(`/downloadpreview/${reportId}`, '_blank')
      }
    },
    onError: async response => {
      showToast(
        i18n.text(`reports.tracking.configuration.modules.flowFail`),
        'error',
        i18n.text(`reports.tracking.configuration.module.error`)
      )
    },
  })

  const modulesList = analysisModules?.map(module => {
    const properties = {
      analysisType: module.analysisType,
      title: module.title,
      enabled: module.enabled,
      id: module.id,
    }
    return properties
  })

  const [items, setItems] = useState<ModuleProps[]>([])

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    if (!items) return
    const reorderedItems = reorder(items, source.index, destination.index)
    const newItems: ModuleProps[] = reorderedItems.map((item, index) => ({
      ...item,
      position: index,
      reportConfigId: reportId,
      assetIds: ['000'],
      descriptionInclude: true,
      referenceValueInclude: true,
      assetGroupingLevel: '',
      timeGroupingLevel: '',
      daysOfWeekInclude: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      },
    }))
    setItems(newItems)
    const modulePositionsParameters = {
      modules: newItems && newItems,
    } as ModuleUpsertProps

    updateAnalysisModulesPositionsMutation({
      variables: {
        ...modulePositionsParameters,
      },
    })
    trackModuleOrderChangeEvent(sendEvent)
  }

  useEffect(() => {
    setItems(modulesList)
  }, [data])

  const handleChange = (reportId: string) => {
    refetchGetReportByIdQuery({ id: reportId })
  }

  const handleAddModuleClick = () => {
    trackStartNewModuleEvent(sendEvent)
    history.push(`/reports/configuration/tracking/${reportId}/modules/new`)
  }

  const handlePreviewFlow = async (reportId: string) => {
    const variables = {
      input: {
        reportConfigId: reportId as ID,
        requestType: TrackingReportRequestTypeInput.Preview,
      },
    }

    await flowTriggerMutation({
      variables: {
        ...variables,
      },
    })
    trackPreviewReportEvent(sendEvent)
  }

  const handleDryRunFlow = async () => {
    const variables = {
      input: {
        reportConfigId: reportId as ID,
        requestType: TrackingReportRequestTypeInput.DryRun,
      },
    }

    await flowTriggerMutation({
      variables: {
        ...variables,
      },
    })
  }

  const handleBackOverviewClick = () => {
    trackBackToOverviewEvent('modules_back_to_overview_button', sendEvent)
    history.push(`/reports/configuration/tracking`)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '100%',
          gap: '2.625rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <StyledTitleTypography>{data?.TrackingReportReportConfigById?.title}</StyledTitleTypography>
            <Typography
              fontWeight={400}
              fontSize="0.875rem"
              lineHeight="1.25rem"
              color={theme.palette.SFIGreyLight[800]}
            >
              {i18n.text('reports.tracking.configuration.modules.manageModules')}
            </Typography>
          </Box>
          <StyledOutlinedButton
            onClick={handleBackOverviewClick}
            sx={{ width: 'fit-content', height: 'fit-content' }}
          >
            {i18n.text(`reports.tracking.configuration.tabs.backOverview`)}
          </StyledOutlinedButton>
        </Box>
        <Box
          sx={{
            alignItems: 'left',
            justifyContent: 'flex-start',
            border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
            borderRadius: '0.75rem',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1.25rem 1.5rem 1.25rem 1.5rem',
              borderBottom: items.length === 0 ? `1px solid ${theme.palette.SFIGreyLight[200]}` : 'none',
            }}
          >
            <StyledTitleTypography>{i18n.text(`reports.tracking.configuration.modules`)}</StyledTitleTypography>
          </Box>
          {loading && (
            <MUILoader
              height={12}
              margin="0 0 2rem 0"
            />
          )}
          {items.length > 0 && (
            <Box
              sx={{
                display: 'inline-grid',
                gridTemplateColumns: '5% 40% 30% 10%',
                width: '100%',
                alignItems: 'center',
                background: theme.palette.SFIBase.white,
                padding: '0.75rem 1.5rem 0.75rem 0',
                borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
              }}
            >
              <Box sx={{ width: '1.5rem' }} />
              <StyledHeaderTypography>
                {i18n.text('reports.tracking.configuration.modules.moduleType')}
              </StyledHeaderTypography>
              <StyledHeaderTypography>
                {i18n.text('reports.tracking.configuration.modules.title')}
              </StyledHeaderTypography>
              <StyledHeaderTypography>
                {i18n.text('reports.tracking.configuration.modules.enabled')}
              </StyledHeaderTypography>
            </Box>
          )}
          {items && (
            <DraggableModuleList
              items={items}
              handleChange={handleChange}
              onDragEnd={onDragEnd}
              reportId={reportId}
            />
          )}
          {items.length === 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ width: '30rem' }}>
                <NoDataFoundSlot
                  title={i18n.text('reports.tracking.configuration.modules.noModules')}
                  subtitle={i18n.text('reports.tracking.configuration.modules.noModulesSubTitle')}
                />
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '1.25rem 1rem 1.25rem 1rem',
              gap: '0.625rem',
              borderTop: items.length === 0 ? `1px solid ${theme.palette.SFIGreyLight[200]}` : 'none',
            }}
          >
            {isInternalUser() && items.length > 0 && (
              <StyledOutlinedButton
                sx={{ width: 'fit-content', height: 'fit-content' }}
                onClick={() => handleDryRunFlow()}
                startIcon={<FileDownloadIcon />}
              >
                {i18n.text(`reports.tracking.configuration.modules.generate`)}
              </StyledOutlinedButton>
            )}
            <StyledOutlinedButton
              sx={{
                width: 'fit-content',
                height: 'fit-content',
                color: theme.palette.SFIBrand[700],
                border: `1px solid ${theme.palette.SFIBrand[300]}`,
              }}
              startIcon={<PlusCircleIcon />}
              onClick={handleAddModuleClick}
            >
              {i18n.text(`reports.tracking.configuration.modules.add`)}
            </StyledOutlinedButton>
            {items.length > 0 && (
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'top', gap: '0.125rem' }}>
                <StyledPrimaryButton
                  sx={{ width: 'fit-content', height: 'fit-content' }}
                  onClick={() => handlePreviewFlow(reportId)}
                  startIcon={<EyeIcon />}
                >
                  {i18n.text(`reports.tracking.configuration.modules.preview`)}
                </StyledPrimaryButton>
                <StyledTooltip title={i18n.text('reports.tracking.configuration.tooltip.previewReport')}>
                  <IconWrapper>
                    <HelpIcon sx={{ color: theme.palette.SFIGreyLight[400] }} />
                  </IconWrapper>
                </StyledTooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
