import { Box, Button, styled } from '@mui/material'

import { ArrowLeft } from '../../Shared/components/icons/ArrowLeftIcon'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'

interface NavigationProps {
  disableBack?: boolean
  disableNext?: boolean
  backAction?: () => void
  nextAction?: () => void
}

const StyledNavigation = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  padding: '1rem 1.5rem',
}))

export default function Navigation({ disableBack, disableNext, backAction, nextAction }: NavigationProps) {
  const { i18n } = useI18nContext();

  return (
    <StyledNavigation>
      {backAction && (
        <Button
          disabled={disableBack}
          startIcon={<ArrowLeft />}
          onClick={backAction}
        >
          {i18n.text('generic.button-text.back')}
        </Button>
      )}
      {nextAction && (
        <Button
          disabled={disableNext}
          variant="contained"
          color="secondary"
          sx={{ marginLeft: 'auto' }}
          onClick={nextAction}
        >
          {i18n.text('onboarding.next')}
        </Button>
      )}
    </StyledNavigation>
  )
}
