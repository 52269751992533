/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react'

import {
  Cloudinary,
  CreateUploadDialogParams,
  GenerateSignatureCallback,
  GenerateSignatureParams,
  GenerateSignatureResponse,
  UploadWidgetProps,
  WidgetButtonProps,
  WidgetSignatureOptions,
} from './types'
import { getterFunction } from './getterFunction'

export const WidgetLoader = () => <>{useScript('https://widget.cloudinary.com/v2.0/global/all.js')}</>

export const Widget = (props: UploadWidgetProps) => <UploadWidget {...props} />

const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.type = 'text/javascript'
    script.src = url
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

const UploadWidget: React.FC<UploadWidgetProps> = ({
  sources = [],
  sourceKeys = null,
  resourceType = 'auto',
  cloudName = null,
  uploadPreset = null,
  buttonText = null,
  style = null,
  widgetStyles = null,
  folder = null,
  cropping = true,
  generateSignatureUrl,
  getCustomHeaders = null,
  onSuccess = null,
  onClose = null,
  onFailure = null,
  logging = true,
  customPublicId = null,
  eager = null,
  use_filename = false,
  unique_filename = true,
  googleDriveClientId = null,
  multiple = false,
  buttonType = 'button',
  destroy = false,
  autoClose = true,
}) => {
  const openUploadDialogFunction = () =>
    createUploadDialog({
      sources,
      sourceKeys,
      resourceType,
      cloudName,
      uploadPreset,
      folder,
      cropping,
      generateSignatureUrl,
      getCustomHeaders,
      onSuccess,
      onClose,
      onFailure,
      logging,
      customPublicId,
      eager,
      use_filename,
      unique_filename,
      googleDriveClientId,
      multiple,
      widgetStyles,
      destroy,
      autoClose,
    })

  return (
    <WidgetButton
      openUploadDialogFunction={openUploadDialogFunction}
      buttonText={buttonText}
      buttonType={buttonType}
      style={style}
    />
  )
}

const createUploadDialog = ({
  sources,
  sourceKeys,
  resourceType,
  cloudName,
  uploadPreset,
  folder,
  cropping,
  generateSignatureUrl,
  getCustomHeaders,
  onSuccess,
  onClose,
  onFailure,
  logging,
  customPublicId,
  eager,
  use_filename,
  unique_filename,
  googleDriveClientId,
  multiple,
  widgetStyles,
  destroy,
  autoClose,
}: CreateUploadDialogParams) => {
  const widget: Cloudinary =
    !!window.cloudinary &&
    window.cloudinary.createUploadWidget(
      {
        showCompletedButton: true,
        multiple: multiple,
        singleUploadAutoClose: autoClose,
        showAdvancedOptions: true,
        showPoweredBy: false,
        styles: widgetStyles,
        googleDriveClientId: googleDriveClientId,
        sources: sources,
        ...(sourceKeys && sourceKeys),
        cloudName: cloudName,
        uploadPreset: uploadPreset,
        folder: folder,
        cropping: cropping,
        resourceType: resourceType,
        ...(generateSignatureUrl && { use_filename: use_filename }),
        ...(generateSignatureUrl && { eager: eager }),
        ...(generateSignatureUrl && { unique_filename: unique_filename }),
        ...(generateSignatureUrl && {
          prepareUploadParams: async (
            callback: GenerateSignatureCallback,
            params: GenerateSignatureParams | GenerateSignatureParams[]
          ) =>
            await generateSignature(
              callback,
              params,
              {
                generateSignatureUrl,
                customPublicId,
                eager,
                resourceType,
                unique_filename,
                use_filename,
                getCustomHeaders,
                folder,
              },
              logging
            ),
        }),
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          logging && console.log(result)
          !!onSuccess && onSuccess(result)
          // destroy && widget.destroy()
        } else if (error) {
          onFailure ? onFailure(error, result) : logging && console.log({ error: error, result: result })
          // destroy && widget.destroy()
        } else if (!!resourceType && result.info === 'shown') {
          logging && console.log('setting resourceType')
        } else if (result.event === 'close') {
          if (destroy) widget.destroy()
          !!onClose && onClose()
        } else {
          logging && console.log(result)
        }
      }
    )
  widget.open()
}

const WidgetButton = ({ openUploadDialogFunction, style, buttonText, buttonType }: WidgetButtonProps) => (
  <>
    <button
      type={buttonType}
      id="cloudinary_upload_button"
      style={
        style || {
          color: 'white',
          border: 'none',
          width: '120px',
          backgroundColor: 'green',
          borderRadius: '4px',
          height: '25px',
        }
      }
      onClick={() => openUploadDialogFunction()}
    >
      {buttonText || 'Upload files'}
    </button>
  </>
)
WidgetButton.defaultProps = {
  buttonType: 'button',
}

const generateSignature = (
  callback: GenerateSignatureCallback,
  params: GenerateSignatureParams | GenerateSignatureParams[] | any,
  {
    generateSignatureUrl,
    customPublicId,
    eager,
    resourceType,
    unique_filename,
    use_filename,
    getCustomHeaders,
    folder,
  }: WidgetSignatureOptions,
  logging: boolean
) => {
  params = [].concat(params) // params can be a single object or an array of objects
  logging && console.log(params, 'Params')
  Promise.all(
    params.map((req: any) => {
      const uploadParams = req
      logging && console.log(req)
      return getterFunction({
        url: generateSignatureUrl,
        data: {
          params_to_sign: {
            ...(req.custom_coordinates && {
              custom_coordinates: req.custom_coordinates,
            }),
            ...(eager && { eager: eager }),
            ...(req.filename_override && {
              filename_override: req.filename_override,
            }),
            ...(req.headers && { headers: req.headers }),
            ...(customPublicId && { public_id: customPublicId }),
            ...(req.source && { source: req.source }),
            timestamp: req.timestamp,
            unique_filename: unique_filename,
            ...(req.upload_preset && {
              upload_preset: req.upload_preset,
            }),
            use_filename: use_filename,
            ...(folder && { folder: folder }),
          },
        },
        getCustomHeaders,
      }).then(response => {
        logging && console.log(response, 'Signature Response')

        return Object.assign(
          {
            ...(eager && { eager: eager }),
            ...(customPublicId && { public_id: customPublicId }),
            signature: response.signature,
            api_key: response.apiKey,
            resource_type: resourceType,
          },
          uploadParams
        )
      })
    })
    // @ts-ignore typings compile to [unknown, ...], but this works.
  ).then((results: GenerateSignatureResponse[]) => {
    callback(results.length === 1 ? results[0] : results)
  })
}
