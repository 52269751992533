import React, { FC } from 'react'
import { ApexOptions } from 'apexcharts'

import GenericChart from '../GraphsShared/GenericChart'
import { AssetProductionSpeed } from '../../types/Assets.types'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type ProductionVolumeProps = {
  productionVolumeData: AssetProductionSpeed[]
  chartHeight?: number
}

const ProductionVolumeGraph: FC<ProductionVolumeProps> = ({ productionVolumeData, chartHeight }) => {
  const { i18n } = useI18nContext()
  const id = `production-volume-${Math.random()}`

  const numberOfColumns = productionVolumeData[0]?.data.length ?? 1
  const columnWidth = `${Math.min(10 * numberOfColumns, 75)}%`

  const chartOptions: ApexOptions = {
    chart: {
      stacked: true,
    },
    tooltip: {
      x: {
        show: true,
        format: 'dd MMM yyyy, HH:mm',
      },
      y: {
        formatter: value => `${i18n.number(Math.round(value))}`,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: columnWidth,
        dataLabels: {
          total: {
            enabled: true,
            formatter: value => i18n.number(value),
            offsetX: 0,
            style: {
              fontSize: '12px',
              fontWeight: 400,
            },
          },
        },
      },
    },
    yaxis: {
      labels: {
        formatter: value => i18n.number(Math.round(value)),
      },
    },
  }

  return (
    <GenericChart
      id={id}
      customOptions={chartOptions}
      series={productionVolumeData}
      chartType="bar"
      height={chartHeight}
    />
  )
}

export default ProductionVolumeGraph
