import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, useTheme } from '@mui/material'
import { FC } from 'react'

import { CustomTableCell } from './CustomTableCell'
import { CustomTableTitleCell } from './CustomTableTitleCell'
import { CustomeHeaderTableCell } from './CustomHeaderTableCell'
import { TableBoxWrapper } from '../styledComponents/SectionBoxWrapper'

export interface StickyColumn {
  columnTitleSlot: React.FC<any>
  columnValuesSlots: React.FC<any>[]
}

interface StickyColumnTableProps {
  tableTitleSlot?: React.FC<any>
  stickyColumns?: StickyColumn[]
  tableHeaders?: string[][]
  tableValues: string[][] | React.FC<any>[][]
}

const TableStyledBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  alignItems: 'stretch',
}))

const TableCellForGrid = styled(TableCell)(({ theme }) => ({
  height: '100%',
  padding: 0,
  margin: '0 !important',
  border: 'none',
  position: 'sticky',
  left: 0,
  zIndex: 1,
  backgroundColor: theme.palette.grey[50],
  boxShadow: '2px 0 4px 0px rgba(0, 0, 0, 0.1)',
}))

const GridInTableCell = styled(Box, { shouldForwardProp: prop => prop !== 'columns' })<{ columns?: number }>(
  ({ theme, columns = 1 }) => ({
    height: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, ${Math.floor(100 / columns)}%)`,
    padding: 0,
    margin: '0 !important',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    minWidth: 'max-content',
  })
)

const FullHeightRow = styled(TableRow)(() => ({
  height: '100%',
}))

export const ItemInGridInTableCell = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  border: `1px solid ${theme.palette.grey[200]}`,
  padding: '1rem 1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  font: 'inherit',
}))

export const StickyColumnTable: FC<StickyColumnTableProps> = ({
  tableTitleSlot,
  stickyColumns,
  tableHeaders,
  tableValues,
}) => {
  const theme = useTheme()
  const TableTitleComponent = tableTitleSlot || null

  return (
    <TableBoxWrapper sx={{ display: 'flex', overflow: 'auto' }}>
      <TableStyledBox
        id="table-container"
        sx={{ width: '100%' }}
      >
        <TableContainer sx={{ display: 'flex' }}>
          <Table sx={{ height: '100%' }}>
            <TableHead sx={{ height: '100%' }}>
              <FullHeightRow>
                {/* Sticky columns headers */}
                <TableCellForGrid>
                  <GridInTableCell columns={stickyColumns?.length}>
                    {stickyColumns &&
                      stickyColumns.map((column, stickyColumnIndex) => {
                        const ColumnTitleComponent = column.columnTitleSlot || ''
                        return <ColumnTitleComponent key={stickyColumnIndex + 'stickyColumnHead'} />
                      })}
                  </GridInTableCell>
                </TableCellForGrid>
                {/* Table title header */}
                <CustomTableTitleCell
                  //@ts-expect-error incorrect type from MUI on colSpan
                  colSpan={'100%'}
                  align="center"
                  sx={{
                    borderBottom: `1px solid ${theme.palette.grey[200]}`,
                  }}
                >
                  {TableTitleComponent && <TableTitleComponent />}
                </CustomTableTitleCell>
              </FullHeightRow>
              {tableHeaders &&
                tableHeaders.map((headerRow, headerRowIndex) => (
                  <FullHeightRow key={headerRowIndex + 'headerRow'}>
                    {/* Empty cells */}
                    <TableCellForGrid>
                      <GridInTableCell columns={stickyColumns?.length}>
                        {stickyColumns &&
                          stickyColumns.map((column, columnIndex) => {
                            return (
                              <ItemInGridInTableCell
                                key={columnIndex + 'stickyColumnHeaderRow'}
                                sx={{
                                  height: '20px',
                                }}
                              />
                            )
                          })}
                      </GridInTableCell>
                    </TableCellForGrid>
                    <>
                      {headerRow.map((header, headerIndex) => (
                        <CustomeHeaderTableCell key={headerIndex + 'header'}>{header}</CustomeHeaderTableCell>
                      ))}
                    </>
                  </FullHeightRow>
                ))}
            </TableHead>
            <TableBody>
              {tableValues.map((row, rowIndex) => {
                return (
                  <FullHeightRow key={rowIndex}>
                    <TableCellForGrid>
                      <GridInTableCell columns={stickyColumns?.length}>
                        {stickyColumns &&
                          stickyColumns.map((column, stickyColumnsIndex) => {
                            const ColumnValuesComponent = column.columnValuesSlots[rowIndex] || ''
                            return (
                              <ColumnValuesComponent
                                key={rowIndex + stickyColumnsIndex.toString() + 'stickyColumnValue'}
                              />
                            )
                          })}
                      </GridInTableCell>
                    </TableCellForGrid>
                    {row.map((cell, cellIndex) => {
                      if (typeof cell === 'string') {
                        return <CustomTableCell key={cellIndex + 'value'}>{cell}</CustomTableCell>
                      }
                      const CellComponent = cell || ''
                      return <CellComponent key={cellIndex + 'value'} />
                    })}
                  </FullHeightRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TableStyledBox>
    </TableBoxWrapper>
  )
}
