import React, { FC, useState } from 'react'
import { Autocomplete, Box, Typography, outlinedInputClasses } from '@mui/material'

import { ArrowDownIcon } from '../../../../../Shared/components/icons'
import { AutocompleteOption, ID, ItemsAlignment } from '../../../../../Shared/types/types'
import { Block, BlockInfo, MainPipesWrapper } from '../../CASettingsStyledComponents'
import { StyledAutocompleteTextField } from '../../../../../Shared/components/MUIComponents/update/styledComponents/StyledAutocomplete'
import { StyledChipsLayout } from '../../../../../Shared/components/MUIComponents/update/StyledChips'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

interface IMainPipesAutocompleteProps {
  mainPipe?: AutocompleteOption | null
  mainPipes: AutocompleteOption[]
  options: AutocompleteOption[]
  onSelectMainPipe: (id: ID) => void
  onDeleteMainPipe: (id: string) => void
}

export const MainPipesAutocomplete: FC<IMainPipesAutocompleteProps> = ({
  options,
  mainPipe,
  mainPipes,
  onSelectMainPipe,
  onDeleteMainPipe,
}) => {
  const { i18n } = useI18nContext()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Block>
      <Box>
        <Typography>{i18n.text('compressed-air.settings.main-pipes')}</Typography>
        <BlockInfo>{i18n.text('compressed-air.settings.main-pipes.extend')}</BlockInfo>
      </Box>
      <MainPipesWrapper>
        <Autocomplete
          id="main-pipes"
          open={isOpen}
          options={options}
          clearIcon={''}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          onChange={(_, value) => onSelectMainPipe(value?.id as ID)}
          popupIcon={
            <ArrowDownIcon
              sx={{
                width: '1rem',
                height: '1rem',
              }}
            />
          }
          renderInput={params => {
            return (
              <StyledAutocompleteTextField
                {...params}
                value={mainPipe?.label}
                size="medium"
                placeholder={i18n.text('compressed-air.settings.select-main-pipes')}
                disabled
                sx={{
                  [`.${outlinedInputClasses.root}`]: {
                    boxShadow: isOpen ? '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #1d7afd3d' : 'none',
                  },
                }}
              />
            )
          }}
        />
        {mainPipes && mainPipes.length > 0 && (
          <Box>
            <StyledChipsLayout
              items={mainPipes}
              handleDelete={onDeleteMainPipe}
              align={ItemsAlignment.START}
            />
          </Box>
        )}
      </MainPipesWrapper>
    </Block>
  )
}
