import { DateTime } from 'luxon'

import { AssetHealthReport } from '../types'
import { ISODateTime } from '../../../../Shared/types/types'
import { PdmAssetHealthReport, Pdm_Asset_Health_Report_Trigger_Types_Enum } from '../../../../Shared/graphql/codegen'

export const transformHealthReportListData = (
  reportList: PdmAssetHealthReport[],
  i18n: $TSFixMe
): AssetHealthReport[] => {
  const getDateOnly = (dateString: string) => dateString.split('T')[0]

  const filteredHealthReportList = reportList
    .sort((a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis())
    // Remove duplicate reports that are generated on the same date.
    .filter(
      (report, index, self) =>
        index ===
        self.findIndex(
          t =>
            getDateOnly(t.fromDate) === getDateOnly(report.fromDate) &&
            getDateOnly(t.toDate) === getDateOnly(report.toDate)
        )
    )
    // Sort by last end date.
    .sort((a, b) => {
      const lastEndDateA = a.toDate ? DateTime.fromISO(a.toDate) : null
      const lastEndDateB = b.toDate ? DateTime.fromISO(b.toDate) : null
      if (lastEndDateA && lastEndDateB) {
        return lastEndDateB.diff(lastEndDateA).as('milliseconds')
      } else if (lastEndDateA) {
        return -1
      } else if (lastEndDateB) {
        return 1
      } else {
        return 0
      }
    })
    .map(report => ({
      ...report,
      custom: report.triggerType === Pdm_Asset_Health_Report_Trigger_Types_Enum.User ? i18n.text('Custom') : undefined,
    }))
  return filteredHealthReportList
}

export const getLocaleString = (fromDate: ISODateTime, toDate: ISODateTime) => {
  return {
    fromDate: DateTime.fromISO(fromDate).toLocaleString({ day: 'numeric', month: 'long' }),
    toDate: DateTime.fromISO(toDate).toLocaleString({ day: 'numeric', month: 'long' }),
  }
}

export const getHealthReporShortcutIntervals = (i18n: $TSFixMe) => [
  {
    label: i18n.text('pdm.datetimepicker.predefined-ranges.yesterday'),
    from: DateTime.local().minus({ hours: 24 }).startOf('day'),
    to: DateTime.local(),
  },
  {
    label: i18n.text('pdm.datetimepicker.predefined-ranges.last-7-days'),
    from: DateTime.now().minus({ days: 7 }).startOf('day'),
    to: DateTime.local(),
  },
  {
    label: i18n.text('pdm.datetimepicker.predefined-ranges.last.month'),
    from: DateTime.local().minus({ months: 1 }).startOf('month'),
    to: DateTime.local(),
  },
  {
    label: i18n.text('pdm.datetimepicker.predefined-ranges.last-3-month'),
    from: DateTime.local().minus({ months: 3 }).startOf('month'),
    to: DateTime.local(),
  },
  {
    label: i18n.text('pdm.datetimepicker.predefined-ranges.last-6-month'),
    from: DateTime.local().minus({ months: 6 }).startOf('month'),
    to: DateTime.local(),
  },
]
