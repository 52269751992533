import getColor from '@sensorfactdev/nucleus/utils/getColor'
import theme from '@sensorfactdev/nucleus/themes/sensorfact.theme'

export const themeColors = {
  black: getColor('black', 'default', theme),
  blackLight: getColor('black', 'light', theme),
  danger: getColor('danger', 'default', theme),
  dangerDark: getColor('danger', 'dark', theme),
  grey: '#424a5e',
  greyLight: getColor('white', 'default', theme),
  primary: getColor('primary', 'default', theme),
  primaryDark: getColor('primary', 'dark', theme),
  primaryLight: getColor('primary', 'light', theme),
  primaryMediumDark: getColor('primary', 'mediumDark', theme),
  secondary: getColor('secondary', 'default', theme),
  success: getColor('success', 'default', theme),
  successDark: getColor('success', 'dark', theme),
  warning: getColor('warning', 'default', theme),
  white: getColor('white', 'light', theme),
  whiteDark: getColor('white', 'dark', theme),
}
