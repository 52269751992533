import * as ReactDomServer from 'react-dom/server'
import { DateTime } from 'luxon'
import { FC } from 'react'

import MuiThemeProvider from '../../../Shared/theme/MuiThemeProvider'
import { BoldSpan, TooltipBody, TooltipContent, TooltipHeader, TooltipMarker, TooltipTitle } from './StyledComponents'
import { ISeriesData } from '../../types'
import { UnitName } from '../../types'
import { numberFormatOptions } from '../../../Shared/constants/numberFormatOptions'

interface ICustomTooltipProps {
  seriesData: ISeriesData[]
  dataPointIndex: number
  i18n: $I18FixMe
}

interface IRenderCustomTooltipArgs extends Omit<ICustomTooltipProps, 'seriesData'> {
  seriesIndex: number
  seriesData: ISeriesData[]
}

export function renderCustomTooltip({ seriesData, dataPointIndex, i18n }: IRenderCustomTooltipArgs) {
  return ReactDomServer.renderToString(
    <CustomTooltip
      seriesData={seriesData}
      dataPointIndex={dataPointIndex}
      i18n={i18n}
    />
  )
}

const CustomTooltip: FC<ICustomTooltipProps> = ({ seriesData, dataPointIndex, i18n }) => {
  if (seriesData.length === 0) {
    return <></>
  }

  const hasHumidityData = seriesData.length > 1

  const xaxisDateValue = seriesData[0]?.data?.[dataPointIndex]?.time
    ? DateTime.fromISO(seriesData[0]?.data?.[dataPointIndex]?.time).toJSDate()
    : DateTime.fromISO(seriesData[1]?.data?.[dataPointIndex]?.time).toJSDate()

  const monthWeekDayFormat = {
    month: 'short',
    weekday: 'short',
  }
  const dayTimeFormat = {
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }

  return (
    <MuiThemeProvider>
      <div>
        <TooltipHeader>
          {`${i18n.date(xaxisDateValue, monthWeekDayFormat)} ${i18n.date(xaxisDateValue, dayTimeFormat)}`}
        </TooltipHeader>
        {seriesData.map((series, index) => {
          const { value, min, max } = series?.data?.[dataPointIndex]
          const unit = index === 0 ? UnitName.celsius : UnitName.percentage
          return (
            <TooltipBody
              key={index}
              sx={{
                borderBottom: hasHumidityData && index === 0 ? '1px solid' : 'none',
              }}
            >
              <TooltipTitle>
                <TooltipMarker style={{ backgroundColor: series?.color }} />
                {series?.name}
              </TooltipTitle>
              <TooltipContent>
                {!!max && (
                  <p>
                    max:{' '}
                    <BoldSpan>
                      {i18n.number(max, numberFormatOptions(1))} {unit}
                    </BoldSpan>
                  </p>
                )}
                <p>
                  avg:{' '}
                  <BoldSpan>
                    {value ? i18n.number(value, numberFormatOptions(1)) : 0} {unit}
                  </BoldSpan>
                </p>
                {!!min && (
                  <p>
                    min:{' '}
                    <BoldSpan>
                      {i18n.number(min, numberFormatOptions(1))} {unit}
                    </BoldSpan>
                  </p>
                )}
              </TooltipContent>
            </TooltipBody>
          )
        })}
      </div>
    </MuiThemeProvider>
  )
}

export default CustomTooltip
