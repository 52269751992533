import { createSvgIcon } from '@mui/material'

export const NeutralTrendIcon = createSvgIcon(
  <svg
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4H9M9 4L6 1M9 4L6 7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'neutral-trend'
)
