import Chart from 'react-apexcharts'
import { Box, Typography, useTheme } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'

import { ApexXAxisAnnotationData } from '../../containers/GraphsPage/types'
import { ChartToolsDropdown } from '../../../Shared/components/MUIComponents/update/ChartToolsDropdown'
import { getTemperatureDataSeries } from '../../containers/GraphsPage/utils'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useVibrationChartOptions } from '../../hooks/useVibrationOptions'

export type VelocityGraphProps = {
  series: ApexAxisChartSeries
  thresholds: number[] | null
  group?: string
  zoom?: {
    min: number
    max: number
  }
  handleZoomChange?: (chart: unknown, changed: { xaxis: { min: number; max: number } }) => void
  loading: boolean
  annotations?: ApexXAxisAnnotationData[]
}

export const VelocityGraph: FC<VelocityGraphProps> = ({
  series,
  thresholds,
  handleZoomChange,
  group,
  zoom,
  loading,
  annotations,
}) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const GRAPH_NAME = useMemo(() => 'velocity' + Math.random(), [])
  const [showTemperature, setShowTemperature] = useState(false)
  const velocityTimeSeriesData = showTemperature ? series : series.filter(s => s.name !== 'temperature')

  const tempSeries = getTemperatureDataSeries(series)
  const tempSeriesValues = tempSeries[0]?.data.map(value => parseFloat((value as { y: any }).y))

  const tempMaxValue = tempSeriesValues ? Math.max(...tempSeriesValues) : 0
  const tempMinValue = tempSeriesValues ? Math.min(...tempSeriesValues) : 0

  const displayChartItems = [
    {
      label: i18n.text('pdm.graphs.tools.temperature'),
      isChecked: showTemperature,
      onToggle: () => setShowTemperature(!showTemperature),
    },
  ]

  const velocityOptions = useVibrationChartOptions({
    GRAPH_NAME,
    thresholds,
    annotations,
    seriesYMin: tempMinValue,
    seriesYMax: tempMaxValue,
    i18n,
    handleZoomChange,
    zoom,
    group,
    showTemperature,
  })

  useEffect(() => {
    if (zoom) {
      const { min, max } = zoom
      if (ApexCharts.getChartByID(GRAPH_NAME)) {
        ApexCharts.exec(GRAPH_NAME, 'zoomX', min, max)
      }
    }
  }, [zoom?.max, zoom?.min, zoom, GRAPH_NAME, showTemperature])

  return (
    <>
      <Box
        sx={{
          marginRight: theme.spacing(32),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          fontSize={18}
          fontWeight={600}
        >
          {i18n.text('pdm.graphs.velocity-rms')}
        </Typography>
        {!loading ? <ChartToolsDropdown items={displayChartItems} /> : null}
      </Box>
      <Chart
        key={Math.random()}
        options={velocityOptions}
        series={velocityTimeSeriesData}
        type="line"
        height="350"
      />
    </>
  )
}
