import { createSvgIcon } from '@mui/material'

export const FileCheckIcon = createSvgIcon(
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="file-check-02">
      <path
        id="Icon"
        d="M13.3335 8.83301V5.03301C13.3335 3.9129 13.3335 3.35285 13.1155 2.92503C12.9238 2.5487 12.6178 2.24274 12.2415 2.05099C11.8137 1.83301 11.2536 1.83301 10.1335 1.83301H5.86685C4.74675 1.83301 4.1867 1.83301 3.75887 2.05099C3.38255 2.24274 3.07659 2.5487 2.88484 2.92503C2.66685 3.35285 2.66685 3.9129 2.66685 5.03301V11.9663C2.66685 13.0864 2.66685 13.6465 2.88484 14.0743C3.07659 14.4506 3.38255 14.7566 3.75887 14.9484C4.1867 15.1663 4.74675 15.1663 5.86685 15.1663H8.00019M9.33352 7.83301H5.33352M6.66685 10.4997H5.33352M10.6669 5.16634H5.33352M9.66685 13.1663L11.0002 14.4997L14.0002 11.4997"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>,
  'file-check-icon'
)
