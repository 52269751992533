import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'

import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

export const GraphLoader: FC = () => {
  const { i18n } = useI18nContext()
  return (
    <Box
      sx={{
        width: '100%',
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="h5"
        fontWeight={400}
        textAlign="center"
      >
        {i18n.text('app.loading')}
      </Typography>
    </Box>
  )
}
