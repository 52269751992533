import { useTheme } from '@mui/material'

import { getLineChartColors } from '../../../../Shared/theme/lineChartColors'

export const useAnnotationLabelOptions = (
  text: string,
  offsetX: number,
  offsetY: number,
  position: string,
  textAnchor: string
): AnnotationLabel => {
  const theme = useTheme()
  return {
    text: text,
    textAnchor: textAnchor,
    borderColor: theme.palette.SFIGreyLight[300],
    borderWidth: 1,
    borderRadius: 8,
    offsetX: offsetX,
    offsetY: offsetY,
    position: position,
    style: {
      fontSize: '0.75rem',
      fontWeight: 700,
      color: theme.palette.SFIGreyLight[800],
      background: theme.palette.SFIBase.white,
      padding: {
        left: 12,
        right: 12,
        top: 8,
        bottom: 8,
      },
    },
  }
}

export const useAxisTitleStyleOptions = (): ApexTitleSubtitle['style'] => {
  const theme = useTheme()
  return {
    fontSize: '0.875rem',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.SFIGreyLight[500],
    fontWeight: 400,
  }
}

export const useXAxisLabelStyleOptions = (): ApexXAxis['labels'] => {
  const theme = useTheme()
  return {
    style: { fontSize: '0.875rem', colors: theme.palette.SFIGreyLight[400] },
  }
}

export const useYAxisLabelStyleOptions = (): ApexYAxis['labels'] => {
  const theme = useTheme()
  return {
    style: { fontSize: '0.875rem', colors: theme.palette.SFIGreyLight[400] },
  }
}

export const getGridOptions = (): ApexGrid => {
  return {
    show: true,
    borderColor: 'lightGrey',
    strokeDashArray: 3.6,
  }
}

export const useDataLabelsOptions = (): ApexDataLabels => {
  const theme = useTheme()
  return {
    offsetY: -25,
    style: {
      colors: [theme.palette.SFIBase.black],
      fontSize: '0.875rem',
      fontWeight: 400,
    },
  }
}

export const getPlotOptionsBarChart = (): ApexPlotOptions => {
  return {
    bar: {
      distributed: true,
      horizontal: false,
      columnWidth: '55%',
      borderRadius: 4,
      borderRadiusApplication: 'end',
      dataLabels: {
        position: 'top',
      },
    },
  }
}

export const useYaxisAnnotationsAverageLineOptions = (
  averageLineValue: string | undefined,
  annotationLabelOptions: AnnotationLabel
) => {
  const theme = useTheme()
  return [
    {
      y: averageLineValue,
      strokeDashArray: 0,
      borderColor: theme.palette.SFIBrand[900],
      borderWidth: 1.5,
      label: annotationLabelOptions,
    },
  ]
}

export const useLineChartLegendOptions = (): ApexLegend => {
  const theme = useTheme()
  return {
    show: true,
    position: 'bottom',
    horizontalAlign: 'left',
    fontSize: '14px',
    labels: {
      colors: theme.palette.SFIGreyLight[500],
      useSeriesColors: false,
    },
    markers: {
      fillColors: getLineChartColors(),
    },
  }
}

export const getLineChartStrokeOptions = (): ApexStroke => {
  return {
    width: 2,
    colors: getLineChartColors(),
  }
}
