import React, { FC, useEffect, useState } from 'react'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Box, Popover, useTheme } from '@mui/material'
import { DateTime, Info } from 'luxon'
import { LocalizationProvider, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers'

import { BaseAnchorButtonProps, IDateTimeRangePickerProps } from './types'
import { CalendarHeader } from './CalendarHeader'
import { CustomLayout } from './CustomLayout'
import { DateTimeRangePickerAnchorButton } from './DateTimeRangePickerAnchorButton'
import { DateTimeRangePickerContextProvider, useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'
import { renderStyledDay } from './CustomDay'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

/**
 * @description - DateTimeRangePicker with customizable shortcuts and compare dates functionality.
 * @param {DateTime | null} startDate - Initial start date
 * @param {DateTime | null} endDate - Initial end date
 * @param {FromToValues} compareFromToValues - Initial compared start and end dates.
 * @param {DateFormat} dateFormat - Preferred date format visible on the DateTimeRangePickerAnchorButton.
 * @param {boolean} isCompareDatesOn - Enables working with compared start and end dates if true.
 * @param {boolean} isTimePicker - Allows users to select time along with dates if true.
 *
 * @param {boolean} isAutomaticCompareDates - If true, compared end date is selected automatically
 * based on the main start and end dates interval
 * @param {boolean} hasPrevNextPeriodButtons - Renders left and right arrow buttons next to the anchor button if true.
 * @param {boolean} showCompareDatesButton - Renders the "Compare dates switch" if true.
 * @param {DateRangePickerShortCut[]} shortcutsItems - List of customized shorcuts
 * @param {function(selection: DateRange, zooming?: boolean): void} onDatesChange - Called when start and end dates are applied.
 * @param {function(selection: DateRange, zooming?: boolean): void} onCompareDatesChange - Called when compared start and end dates
 * are applied.
 * @param {function(isSwitchedOn: boolean): void} onCompareDatesToggle - Called when the user switches the 'Compare dates'
 * switch on or off.
 * @param {React.FC<T>} AnchorButton - Custom anchor button component.
 * @param {T} anchorButtonProps - Props for the custom anchor button component.
 */

const MuiDateTimeRangePicker = <T extends BaseAnchorButtonProps>({
  AnchorButton,
  anchorButtonProps,
  disableClickingAfterCompareSelect = true,
  ...props
}: IDateTimeRangePickerProps<T>) => {
  return (
    <DateTimeRangePickerContextProvider {...props}>
      <DateTimePickerContent
        AnchorButton={AnchorButton}
        anchorButtonProps={anchorButtonProps}
        disableClickingAfterCompareSelect={disableClickingAfterCompareSelect}
      />
    </DateTimeRangePickerContextProvider>
  )
}

interface DateTimePickerContentProps<T extends BaseAnchorButtonProps> {
  AnchorButton?: FC<T>
  anchorButtonProps?: T
  disableClickingAfterCompareSelect: boolean
}

const DateTimePickerContent = <T extends BaseAnchorButtonProps>({
  AnchorButton,
  anchorButtonProps,
  disableClickingAfterCompareSelect
}: DateTimePickerContentProps<T>) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null)
  const isOpen = Boolean(anchorElement)

  const {
    dateFormat,
    isCompareDatesOn,
    isTimePicker,
    isAutomaticCompareDates,
    fromDate,
    toDate,
    comparedFromDate,
    comparedToDate,
    settingFromDate,
    initialFromTo,
    initialCompareFromTo,
    currentDate,
    compareStatusesRef,
    isZoomed,
    setIsZoomed,
    setError,
    setComparedFromDate,
    setToDate,
    setFromDate,
    setComparedToDate,
    onDatesChange,
    onCompareDatesChange,
    setFromDateStatus,
    setActiveShortcutIndex,
  } = useDateTimeRangePickerContext()

  useEffect(() => {
    if (!isOpen) {
      setActiveShortcutIndex(null)
      resetCompareStatuses(true)
    }
  }, [isOpen])

  useEffect(() => {
    if ((!!fromDate && !!toDate && !isCompareDatesOn && !isTimePicker) || (!!fromDate && !!toDate && !isOpen)) {
      applyDates()
    }
  }, [fromDate, toDate])

  // This function is used to style date range
  const renderDay = ({ day, ...other }: PickersDayProps<DateTime>) => {
    return renderStyledDay({
      day,
      fromDate,
      toDate,
      comparedFromDate,
      comparedToDate,
      settingFromDate,
      settingCompareDates: compareStatusesRef.current,
      isCompareDatesOn,
      handleChange: isCompareDatesOn ? handleCompareChange : handleChange,
      ...other,
    })
  }

  const openDatePickerCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  const handleChange = (date: DateTime | null) => {
    if (!date || !date.isValid) return null
    setError('')
    setActiveShortcutIndex(null)
    if (settingFromDate || (fromDate && date.startOf('day') < fromDate.startOf('day'))) {
      setFromDateStatus(false)
      setToDate(null)
      setFromDate(date.startOf('day'))
    } else {
      setFromDateStatus(true)
      setToDate(date.endOf('day'))
    }
  }

  const handleCompareChange = (date: DateTime | null) => {
    if (!date || !date.isValid) return null
    setError('')
    setActiveShortcutIndex(null)
    if (
      compareStatusesRef.current.from ||
      (compareStatusesRef.current.to && fromDate && date.startOf('day') < fromDate.startOf('day'))
    ) {
      compareStatusesRef.current.from = false
      setToDate(null)
      setFromDate(date.startOf('day'))
    } else if (compareStatusesRef.current.to) {
      compareStatusesRef.current.to = false
      setToDate(date.endOf('day'))
    } else if (
      compareStatusesRef.current.compareFrom ||
      (!isAutomaticCompareDates && comparedFromDate && date.startOf('day') < comparedFromDate.startOf('day'))
    ) {
      compareStatusesRef.current.compareFrom = false
      setComparedToDate(null)
      setComparedFromDate(date.startOf('day'))

      // Set Compare 'to' date automatically
      if (isAutomaticCompareDates && !!fromDate && !!toDate) {
        compareStatusesRef.current.compareTo = false
        const durationBetweenFromAndTo = toDate.diff(fromDate, ['days']).days
        const newCompareToDate = date.endOf('day').plus({ days: durationBetweenFromAndTo - 1 })
        resetCompareStatuses()
        setComparedToDate(newCompareToDate)
      }
    } else if (!isAutomaticCompareDates && compareStatusesRef.current.compareTo) {
      compareStatusesRef.current.compareTo = false
      setComparedToDate(date.endOf('day'))
      resetCompareStatuses()
    } else if (compareStatusesRef.current.compareTo) {
      compareStatusesRef.current.compareTo = false
      setComparedToDate(date.endOf('day'))
      resetCompareStatuses()

      // Set Compare 'from' date automatically
      if (!!fromDate && !!toDate) {
        const durationBetweenFromAndTo = toDate.diff(fromDate, ['days']).days
        const newCompareFromDate = date.endOf('day').minus({ days: durationBetweenFromAndTo })
        setComparedFromDate(newCompareFromDate)
      }
    }
  }

  const closeCalendar = () => {
    setFromDate(initialFromTo.from)
    setToDate(initialFromTo.to)
    setActiveShortcutIndex(null)
    setAnchorElement(null)
  }

  const applyDates = () => {
    if (!fromDate) {
      setError(i18n.text('datetimepicker.validation.error1'))
    } else if (!toDate && isCompareDatesOn) {
      setError(i18n.text('datetimepicker.validation.error2'))
    } else if (toDate && toDate < fromDate) {
      setError(i18n.text('datetimepicker.validation.error3'))
    } else if (isCompareDatesOn && comparedFromDate && !comparedToDate) {
      setError(i18n.text('datetimepicker.validation.error4'))
    } else if (isCompareDatesOn && comparedFromDate && comparedToDate && comparedToDate < comparedFromDate) {
      setError(i18n.text('datetimepicker.validation.error5'))
    } else if (
      (fromDate && toDate && (!fromDate.isValid || !toDate?.isValid)) ||
      (isCompareDatesOn && (!comparedFromDate?.isValid || !comparedToDate?.isValid))
    ) {
      setError(i18n.text('datetimepicker.validation.error6'))
    } else if (
      fromDate &&
      toDate &&
      isAutomaticCompareDates &&
      comparedToDate &&
      comparedFromDate &&
      toDate.startOf('day').diff(fromDate.startOf('day'), ['days']).days !==
        comparedToDate.startOf('day').diff(comparedFromDate.startOf('day'), ['days']).days
    ) {
      setError(i18n.text('datetimepicker.validation.error7'))
    } else if ((fromDate && toDate) || (fromDate && !toDate && !isCompareDatesOn)) {
      const newToDate = toDate || fromDate?.endOf('day') || null
      setToDate(newToDate)
      setFromDateStatus(true)
      onDatesChange(
        {
          startDate: fromDate,
          endDate: newToDate,
        },
        isZoomed
      )
      if (comparedFromDate && comparedToDate && onCompareDatesChange) {
        onCompareDatesChange({
          startDate: comparedFromDate,
          endDate: comparedToDate,
        })
      }
      setError('')
      setAnchorElement(null)
      if (setIsZoomed) setIsZoomed(false)
    }
  }

  const defaultAnchorButton = DateTimeRangePickerAnchorButton as unknown as FC<T>
  const AnchorButtonToRender: any = AnchorButton || defaultAnchorButton

  function resetCompareStatuses(isReset = false) {
    if (!disableClickingAfterCompareSelect || isReset) {
      compareStatusesRef.current = {
        from: true,
        to: true,
        compareFrom: true,
        compareTo: true
      }
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box>
        <AnchorButtonToRender
          fromDate={initialFromTo.from}
          toDate={initialFromTo.to}
          compareFromDate={initialCompareFromTo?.from || null}
          compareToDate={initialCompareFromTo?.to || null}
          dateFormat={dateFormat}
          {...anchorButtonProps}
          onClick={openDatePickerCalendar}
        />
        <Popover
          anchorEl={anchorElement}
          onClose={handleClose}
          open={isOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={3}
        >
          <Box
            sx={{
              maxWidth: isTimePicker ? theme.spacing(72.5) : theme.spacing(65),
              width: isTimePicker ? theme.spacing(72.5) : theme.spacing(65),
              display: 'grid',
            }}
          >
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              dayOfWeekFormatter={(_day: string, date: DateTime) => {
                const luxonDay = (date.weekday + 6) % 7
                return Info.weekdays('short')[luxonDay]
              }}
              autoFocus={false}
              openTo="day"
              value={currentDate}
              disableFuture={false}
              disableHighlightToday={false}
              views={['day']}
              slots={{
                day: renderDay,
                calendarHeader: () => <CalendarHeader />,
                layout: props => (
                  <CustomLayout
                    {...props}
                    saveDates={applyDates}
                    closeCalendar={closeCalendar}
                  />
                ),
              }}
            />
          </Box>
        </Popover>
      </Box>
    </LocalizationProvider>
  )
}

export default MuiDateTimeRangePicker
