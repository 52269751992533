import React, { FC, useEffect } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { DateTime } from 'luxon'

import { ITemperatureSensorLocation, Insights } from '../../types'
import { OverviewCard } from './OverviewCard'
import { toLocalDateTime } from '../../../Shared/utils'
import { useAllTemperatureLocationTimeSeriesQuery } from '../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useTemperatureContext } from '../../context/TemperatureContext'

const updatedAtDateFormat = {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

export const TemperatureOverview: FC = () => {
  const { i18n } = useI18nContext()
  const { assets, setSelectedLocationId } = useTemperatureContext()

  const getLastUpdatedText = () => {
    return `${i18n.date(DateTime.now().toJSDate(), updatedAtDateFormat)}`
  }

  const { data: assetsInsightsData, loading: assetsInsightsLoading } = useAllTemperatureLocationTimeSeriesQuery({
    variables: {
      from: toLocalDateTime(DateTime.now().startOf('hour')),
      to: toLocalDateTime(DateTime.now().endOf('hour')),
    },
  })

  const getInsights = (locationId: string, assetId: string) => {
    const asset = assetsInsightsData?.myOrg?.assets?.find(asset => asset.id === assetId)
    if (asset && asset.sensorLocations) {
      const selectedLocation = asset.sensorLocations.find(location => location.id === locationId)
      return selectedLocation ? selectedLocation.insights : null
    }
    return null
  }

  useEffect(() => {
    setSelectedLocationId('')
  }, [])

  return (
    <Box>
      {!assetsInsightsLoading && (
        <>
          <TitleWrapper>
            <TitleText>
              {`${i18n.text('router.multi.temperature')} ${i18n.text('rules.condition.infix.and')} ${i18n.text(
                'temperature.name.humidity'
              )}`}
            </TitleText>
            <LastUpdatedTextWrapper>
              {`${i18n.text('pdm.last-updated')}: ${getLastUpdatedText()}`}
            </LastUpdatedTextWrapper>
          </TitleWrapper>
          <OverviewPageGridLayout>
            {assets.map(asset =>
              asset.temperatureSensorLocations.map(sensorLocation => (
                <OverviewCard
                  key={sensorLocation.id}
                  assetName={asset.name}
                  sensorLocation={sensorLocation as ITemperatureSensorLocation}
                  insights={getInsights(sensorLocation.id, asset.id) as Insights}
                />
              ))
            )}
          </OverviewPageGridLayout>
        </>
      )}
      {assetsInsightsLoading && (
        <LoadingTextWrapper>
          <Typography
            variant="h5"
            fontWeight={400}
            textAlign="center"
          >
            {i18n.text('app.loading')}
          </Typography>
        </LoadingTextWrapper>
      )}
    </Box>
  )
}

const OverviewPageGridLayout = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1rem 0.75rem',
  flexWrap: 'wrap',
}))

const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  marginBottom: theme.spacing(3.75),
}))

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[800],
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: '2rem',
}))

const LastUpdatedTextWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  background: theme.palette.SFIBase.white,
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  padding: theme.spacing(0.5, 1.25),
  color: theme.palette.SFIGreyLight[700],
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '1.25rem',
}))

const LoadingTextWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '50vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
