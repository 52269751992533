import { Interval } from 'luxon'

export const calculateZoom = (interval: Interval, isZoomIn: boolean) => {
  if (isZoomIn) {
    // Zoom in
    const delta = interval.end.diff(interval.start).toMillis() / 4
    return {
      xaxis: {
        min: interval.start.toMillis() + delta,
        max: interval.end.toMillis() - delta,
      },
    }
  } else {
    // Zoom out
    const delta = interval.end.diff(interval.start).toMillis() / 2
    return {
      xaxis: {
        min: interval.start.toMillis() - delta,
        max: interval.end.toMillis() + delta,
      },
    }
  }
}
