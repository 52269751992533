import { PropsWithChildren, useState } from 'react'
import { useHistory } from 'react-router'

import { ONBOARDING_STAGE_STORAGE } from '../utils/constants'
import { browserStorage, createCtx } from '../../Shared/utils'
import { OnboardingPath } from '../models'

interface ProgressContextType {
  currentStage: OnboardingPath
  navigateStage: (stage: OnboardingPath) => void
}

const [useProgressContext, ProgressContextReactProvider] = createCtx<ProgressContextType>()

export default function ProgressContextProvider({ children }: PropsWithChildren<{}>) {
  const history = useHistory()

  const [currentStage, setCurrentStage] = useState(OnboardingPath.COMPANY_DETAILS)

  const navigateStage = (stage: OnboardingPath) => {
    setCurrentStage(stage)
    browserStorage.set(ONBOARDING_STAGE_STORAGE, stage)
    history.push(`/onboarding/${stage}`)
  }

  return <ProgressContextReactProvider value={{ currentStage, navigateStage }}>{children}</ProgressContextReactProvider>
}

export { useProgressContext }
