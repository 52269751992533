import apexDe from 'apexcharts/dist/locales/de.json'
import apexEn from 'apexcharts/dist/locales/en.json'
import apexEs from 'apexcharts/dist/locales/es.json'
import apexFr from 'apexcharts/dist/locales/fr.json'
import apexIt from 'apexcharts/dist/locales/it.json'
import apexNl from 'apexcharts/dist/locales/nl.json'
import { ApexOptions } from 'apexcharts'
import { Interval } from 'luxon'
import { useMemo } from 'react'
import { useTheme } from '@mui/material'

import useAnalytics from '../../Shared/hooks/useAnalytics/useAnalytics'
import { ITimeSeriesData, SwitchUnit } from '../types/compressed-air_types'
import { changeToWeeklyAggregatedData, dayTimeFormat, getIsWeeklyAggregation, monthWeekDayFormat } from '../utils/utils'
import { numberFormatOptions, zeroAfterDecimalFormatOptions } from '../../Shared/constants/numberFormatOptions'
import { sendCATrackedDownloadEvent } from '../utils/analyticsEvents'
import { shortLocalesNames } from '../../Shared/utils'
import { useApexBeforeZoom } from './useApexBeforeZoom'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'
import { zoomInIconHTML, zoomOutIconHTML } from '../components/ApexWrapper'

const apexLocales = [apexEn, apexDe, apexEs, apexIt, apexFr, apexNl]

export const useConsumptionOptions = (
  from: number,
  to: number,
  seriesLength: number,
  switchUnit: SwitchUnit,
  yAxisMaxValue: number,
  consumptionTimeSeriesData: ITimeSeriesData[],
  onZoomChange: (chart: unknown, changed: { xaxis: { min: number; max: number } }) => void,
  interval: Interval,
  decimal: number
): ApexOptions => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { sendEvent } = useAnalytics()
  const beforeZoom = useApexBeforeZoom(interval)
  const options: ApexOptions = useMemo(() => {
    const isWeekly = getIsWeeklyAggregation(from, to)
    const defaultXaxis: ApexXAxis = {
      type: 'datetime',
      min: from,
      labels: {
        datetimeUTC: false,
      },
    }
    const weeklyXaxis: ApexXAxis = {
      type: 'category',
      labels: {
        formatter: (val: string) => changeToWeeklyAggregatedData(from, to, val, i18n),
      },
    }

    return {
      chart: {
        id: switchUnit.includes('cf') ? 'AirConsumptionCFMGraphId' : 'AirConsumptionM3GraphId',
        type: 'bar',
        locales: apexLocales,
        defaultLocale: shortLocalesNames[i18n.locale],
        height: 350,
        animations: {
          enabled: false,
        },
        stacked: true,
        toolbar: {
          show: !isWeekly,
          offsetY: -58,
          offsetX: 3,
          autoSelected: 'zoom',
          tools: {
            reset: false,
            zoomin: zoomInIconHTML,
            zoomout: zoomOutIconHTML,
            download: false,
            pan: false,
            selection: false,
            zoom: '<div width=0 height=0></div>',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        events: {
          click: e => sendCATrackedDownloadEvent(e, 'consumption', sendEvent),
          beforeZoom: beforeZoom ?? undefined,
          zoomed: onZoomChange,
        },
      },
      tooltip: {
        x: {
          show: true,
          format: 'MMM ddd dd HH:mm',
          formatter: (value: number, { seriesIndex, dataPointIndex }) => {
            const seriesData = consumptionTimeSeriesData[seriesIndex]?.data?.[dataPointIndex]
            const timeValue = seriesData
              ? `${i18n.date(new Date(seriesData.time), monthWeekDayFormat)} ${i18n.date(
                  new Date(seriesData.time),
                  dayTimeFormat
                )}`
              : ''
            return timeValue
          },
        },
        y: {
          formatter: (value: number) => `${value ? i18n.number(value, numberFormatOptions(decimal)) : 0} ${switchUnit}`,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: isWeekly ? weeklyXaxis : defaultXaxis,
      yaxis: {
        min: 0,
        max: yAxisMaxValue,
        forceNiceScale: true,
        decimalsInFloat: decimal,
        tickAmount: 6,
        labels: {
          formatter: value => `${value ? i18n.number(value, zeroAfterDecimalFormatOptions) : 0}`,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: Math.min(10 * +seriesLength, 75) + '%',
          borderRadius: 0,
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        fontFamily: theme.typography.fontFamily,
        fontSize: '14',
        showForSingleSeries: true,
        markers: {
          width: 8,
          height: 8,
          radius: 8,
        },
      },
      stroke: {
        width: 3,
        colors: ['transparent'],
      },
    }
  }, [theme, from, to, seriesLength, i18n, switchUnit, yAxisMaxValue, consumptionTimeSeriesData, decimal])

  return options
}
