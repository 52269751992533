import { FormControl, InputLabel, Theme, TextField, outlinedInputClasses, styled } from '@mui/material'

export const textFieldStyle = (theme: Theme) => ({
  maxWidth: '20rem',
  '&:focus-visible': {
    outline: 'none',
  },

  input: {
    borderRadius: '0.375rem',
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    boxShadow: '0px 1px 2px 0px #1018280d',
    color: theme.palette.SFIGreyLight[800],
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    '&:focus-visible': {
      boxShadow: '0 1px 2px 0 #1018280d, 0 0 0 4px #1d7afd3d',
    },
    '&:invalid': {
      boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(240, 68, 56, 0.24)',
    },
    '&.Mui-disabled': {
      background: theme.palette.SFIGreyLight[50],
      WebkitTextFillColor: theme.palette.SFIGreyLight[500],
    },
    '&::placeholder': {
      color: theme.palette.SFIGreyLight[500],
      fontSize: '1rem',
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  '.MuiInputBase-adornedEnd': {
    paddingRight: 0,
    input: {
      paddingRight: '2.375rem',
    }
  },
  '& .MuiInputAdornment-positionEnd': {
    position: 'absolute',
    right: 0,
    width: '2.5rem'
  },
  [`.${outlinedInputClasses.notchedOutline}`]: {
    border: 'none',
  },
  [`.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
    border: `1px solid ${theme.palette.SFIOrange[500]}`,
  },
  'p.Mui-error': {
    color: theme.palette.SFIOrange[600],
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
    marginLeft: 0,
  },
  '& div.Mui-error': {
    input: {
      '&:focus-visible': {
        boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #f044383d',
      },
      '&:focus': {
        boxShadow: '0px 1px 2px 0px #1018280d, 0px 0px 0px 4px #f044383d',
      },
    },
  },
})

// a styled label for the input
export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    display: 'inline-grid',
    position: 'static',
    fontSize: '0.875rem',
    color: theme.palette.SFIGreyLight[700],
    fontWeight: 500,
    lineHeight: '1.25rem',
    transform: 'none',
  },
}))

// a wrapper for styled input and label
export const StyledFormControl = styled(FormControl)(({ theme, fullWidth }) => ({
  '&.MuiFormControl-root': {
    display: 'grid',
    rowGap: '0.38rem',

    '& .MuiTextField-root': {
      maxWidth: fullWidth ? '100%' : 'auto',
    },

    '& .MuiInputBase-input': {
      padding: '0.5rem 0.75rem',
    },
  },
}))

export const StyledTextField = styled(TextField)(({theme}) => ({
  ...textFieldStyle(theme)
}))