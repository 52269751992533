import Button from '@mui/material/Button'
import Toolbar, { ToolbarProps } from '@mui/material/Toolbar'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { styled, useTheme } from '@mui/material'

import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

export type LinkInfo = {
  name: string // canonical english name
  i18n: string // i18n key
  path: string
  icon?: JSX.Element
}

export enum SecondaryNavLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

interface MuiSecondaryNavProps {
  links: LinkInfo[]
  hideBorderBottom?: boolean
  layout?: SecondaryNavLayout
  slotProps?: {
    toolbar?: ToolbarProps
  }
}

const MuiSecondaryNav: FC<MuiSecondaryNavProps> = ({
  links,
  hideBorderBottom = false,
  layout = SecondaryNavLayout.Horizontal,
  slotProps,
}) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const isHorizontalLayout = layout === SecondaryNavLayout.Horizontal

  return (
    <StyledToolbar
      hideBorderBottom={hideBorderBottom}
      isHorizontalLayout={isHorizontalLayout}
      {...slotProps?.toolbar}
    >
      {links.map(link => (
        <Button
          sx={{
            borderRadius: 0,
            padding: '0.5rem 0.75rem',
            fontSize: '0.875rem',
            textTransform: 'none',
            whiteSpace: 'nowrap',
            color: theme.palette.SFIGreyLight[500],
            borderBottom: isHorizontalLayout ? `2px solid transparent` : 'none',
            borderLeft: isHorizontalLayout ? 'none' : `2px solid transparent`,
            marginBottom: '-1px',
            width: isHorizontalLayout ? 'auto' : '100%',
            justifyContent: isHorizontalLayout ? 'center' : 'flex-start',
            '&.activeLink, &:hover': {
              background: 'none',
              color: theme.palette.SFIBrand?.[700],
            },
            '&.activeLink': {
              borderColor: theme.palette.SFIBrand?.[700],
            },
          }}
          key={link.path}
          variant="text"
          startIcon={link.icon}
          component={NavLink}
          to={link.path}
          activeClassName="activeLink"
        >
          {i18n.text(link.i18n)}
        </Button>
      ))}
    </StyledToolbar>
  )
}

interface IStyledToolbarProps extends ToolbarProps {
  hideBorderBottom: boolean
  isHorizontalLayout: boolean
}

const StyledToolbar = styled((props: IStyledToolbarProps) => {
  const { hideBorderBottom, isHorizontalLayout, ...other } = props
  return <Toolbar {...other} />
})(({ theme, isHorizontalLayout, hideBorderBottom }) => ({
  flexDirection: isHorizontalLayout ? 'row' : 'column',
  flex: 1,
  padding: '0 !important',
  minHeight: '0 !important',
  gap: isHorizontalLayout ? theme.spacing(1) : '0px',
  borderBottom: isHorizontalLayout && !hideBorderBottom ? `1px solid ${theme.palette.SFIGreyLight[300]}` : 'none',
  alignItems: isHorizontalLayout ? 'center' : 'flex-start',
  [theme.breakpoints.down('md')]: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
}))

export default MuiSecondaryNav
