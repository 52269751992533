import { Box } from '@mui/material'
import { FC } from 'react'

import { DescriptionInputProps } from '../types'
import { SmallCustomSwitch } from '../../../../../Shared/components/MUIComponents/update/NewDesignStyledSwitch'
import { StyledInputFieldHeader, StyledTooltipWithIcon } from '../Common'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const DescriptionSwitch: FC<DescriptionInputProps> = ({ description, handleDescriptionChange }) => {
  const { i18n } = useI18nContext()

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '30% 70%',
        }}
      >
        {' '}
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.125rem', paddingRight: '1rem' }}
        >
          <StyledInputFieldHeader sx={{ alignContent: 'center' }}>
            {i18n.text(`reports.tracking.configuration.module.descriptionInclude`)}
          </StyledInputFieldHeader>
          <StyledTooltipWithIcon
            title={i18n.text('reports.tracking.configuration.tooltip.description')}
          ></StyledTooltipWithIcon>
        </Box>
        <SmallCustomSwitch
          checked={description}
          onChange={handleDescriptionChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Box>
    </>
  )
}
