import { Box, Button, styled } from '@mui/material'
import { DateTime, DateTimeFormatOptions } from 'luxon'
import { FC } from 'react'

import { BaseAnchorButtonProps } from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker/types'
import { CalendarIcon } from '../../../Shared/components/icons'
import { SupportedLocale, isoLocales } from '../../../Shared/utils'

interface EBUTimepickerButtonProps extends BaseAnchorButtonProps {
  startDate: DateTime
  endDate: DateTime
  locale: SupportedLocale
}

const dateFormat: DateTimeFormatOptions = { day: '2-digit', month: 'short' }
const yearFormat: DateTimeFormatOptions = { year: '2-digit' }
const timeFormat: DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false }

const StyledAnchorButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '1rem',
  color: theme.palette.SFIGreyLight[800],
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  padding: '0.625rem 0.875rem',
  fontWeight: 500,
  lineHeight: '24px',
  minWidth: '330px',
}))

export const EBUTimepickerButton: FC<EBUTimepickerButtonProps> = ({ startDate, endDate, locale, onClick }) => {
  const isoLocale = isoLocales[locale]
  return (
    <StyledAnchorButton
      onClick={onClick}
      endIcon={<CalendarIcon />}
    >
      <Box sx={{ display: 'flex', gap: 0.5 }}>
        <Box>
          {startDate.toLocaleString(dateFormat, { locale: isoLocale })} '
          {startDate.toLocaleString(yearFormat, { locale: isoLocale })}{' '}
        </Box>
        /
        <Box>
          {endDate.toLocaleString(dateFormat, { locale: isoLocale })} '
          {endDate.toLocaleString(yearFormat, { locale: isoLocale })}
        </Box>
      </Box>
      <Box style={{ marginLeft: '1rem' }}>
        {startDate.toLocaleString(timeFormat, { locale: isoLocale })} -{' '}
        {endDate.toLocaleString(timeFormat, { locale: isoLocale })}
      </Box>
    </StyledAnchorButton>
  )
}
