//creates tabs for general report configuration and module configuration
import { Box } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import useAnalytics from '../../../../Shared/hooks/useAnalytics/useAnalytics'
import MuiSecondaryNav, {
  LinkInfo,
  SecondaryNavLayout,
} from '../../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'
import { AnalyticsIntent } from '../../../../Shared/hooks/useAnalytics/analyticsTypes'
import { ConfigureMainReportSettings } from './GeneralReportConfiguration'
import { EditModuleSettingsDialog } from './Dialogs/EditModuleSettingsDialog'
import { MatchParams } from './types'
import { ModuleOverview } from './ModulesOverview'
import { trackCancelEditModuleEvent } from './trackingEvents'

export const ConfigureTabs: FC = () => {
  const { sendEvent } = useAnalytics()
  const { reportId, moduleId: moduleIdParam, tab: tabParam } = useParams<MatchParams>()

  const [moduleId, setModuleId] = useState<string | undefined>()
  const history = useHistory()

  const handleCloseEditModuleSettingsDialog = () => {
    if (moduleId === 'new') {
      trackCancelEditModuleEvent('modal_cross_button', AnalyticsIntent.TR_CANCEL_EDIT_NEW_MODULE, sendEvent)
    } else {
      trackCancelEditModuleEvent('modal_cross_button', AnalyticsIntent.TR_CANCEL_EDIT_EXISTING_MODULE, sendEvent)
    }
    history.push(`/reports/configuration/tracking/${reportId}/modules`)
  }

  const secondaryLinks: LinkInfo[] = [
    {
      name: 'General',
      i18n: 'reports.tracking.configuration.tabs.generalSettings',
      path: `/reports/configuration/tracking/${reportId}/general`,
    },
    {
      name: 'Module',
      i18n: 'reports.tracking.configuration.tabs.moduleConfiguration',
      path: `/reports/configuration/tracking/${reportId}/modules`,
    },
  ]

  useEffect(() => {
    setModuleId(moduleIdParam)
  }, [moduleIdParam, tabParam])

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0.6rem',
        paddingTop: '3.25rem',
      }}
    >
      <Box sx={{ display: 'flex', width: 'fit-content' }}>
        <MuiSecondaryNav
          links={secondaryLinks}
          layout={SecondaryNavLayout.Vertical}
        />
      </Box>

      {tabParam === 'general' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
          {reportId && (
            <ConfigureMainReportSettings
              reportId={reportId}
              moduleId={moduleId}
              tab={tabParam}
            />
          )}
        </Box>
      )}
      {tabParam === 'modules' && moduleIdParam === undefined && <ModuleOverview />}
      {tabParam === 'modules' && moduleIdParam && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '1rem',
            width: '100%',
          }}
        >
          <ModuleOverview />
          <EditModuleSettingsDialog handleCloseEditModuleSettingsDialog={handleCloseEditModuleSettingsDialog} />
        </Box>
      )}
    </Box>
  )
}
