import React, { FC, useState } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { DateTime, Interval } from 'luxon'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router'

import AssetTreeLayout from './AssetTreeLayout'
import MuiDateTimeRangePicker from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker'
import PngExporter from '../../../Shared/components/MUIComponents/ChartTools/PngExporter'
import SecondaryNavContainer from '../../containers/SecondaryNavContainer'
import { DownloadIcon } from '../../../Shared/components/icons/DownloadIcon'
import { FromToValues } from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker/types'
import { OptionalIconLink } from '../../../Shared/components/MUIComponents/SecondaryNav/SecondaryNav'
import { StyledMenu } from '../../../Shared/components/MUIComponents/update/StyledMenu'
import { StyledMenuItem } from '../../../Shared/components/MUIComponents/update/StyledMenu/StyledMenuItem'
import { TemperatureCsvExporter } from '../TemperatureCsvExporter'
import { TemperatureOverview } from '../Overview'
import { TrendsContainer } from '../Graphs/TrendsContainer'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useTemperatureContext } from '../../context/TemperatureContext'

const StyledBox = styled(Box)({
  backgroundColor: 'rgb(245, 245, 245)',
  width: '100%',
  height: 'calc(-64px + 100vh)',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  overflow: 'hidden',
})

export const TemperatureLayout: FC = () => {
  const { path } = useRouteMatch()
  const { i18n } = useI18nContext()
  const { selectedDateRange, onDateRangeChange, assets, apexSeries } = useTemperatureContext()
  const history = useHistory()
  const isOverviewPage = history.location.pathname.includes('overview')
  const firstSensorLocation = assets[0]?.temperatureSensorLocations[0]
  const [isZoomed, setIsZoomed] = useState(false)
  const [zoomedDates, setZoomedDates] = useState<FromToValues>({
    from: null,
    to: null,
  })
  const interval: Interval =
    selectedDateRange.startDate && selectedDateRange.endDate
      ? Interval.fromDateTimes(selectedDateRange.startDate, selectedDateRange.endDate)
      : Interval.fromDateTimes(DateTime.now().startOf('day'), DateTime.now().endOf('day'))

  const temperatureLinks: OptionalIconLink[] = [
    {
      name: 'Overview',
      i18n: i18n.text('temperature.tabs.overview'),
      path: `${path}/overview`,
    },
    {
      name: 'Temperature',
      i18n: i18n.text('temperature.tabs.trends'),
      path: `${path}/flow/`,
    },
  ]

  const getNavRoutes = (): {
    key: string
    path: string
    component: React.ReactNode
  }[] => [
    {
      key: 'overview',
      path: `${path}/overview`,
      component: <TemperatureOverview />,
    },
    {
      key: 'flow',
      path: `${path}/flow/:sensorLocationId`,
      component: (
        <TrendsContainer
          handleIsZoomed={(val: boolean) => setIsZoomed(val)}
          handleZoomedDates={(dates: FromToValues) => setZoomedDates(dates)}
        />
      ),
    },
  ]

  return (
    <StyledBox>
      <SecondaryNavContainer links={temperatureLinks}>
        {!isOverviewPage && (
          <>
            <MuiDateTimeRangePicker
              isZoomed={isZoomed}
              startDate={selectedDateRange.startDate}
              endDate={selectedDateRange.endDate}
              hasPrevNextPeriodButtons
              onDatesChange={onDateRangeChange}
              setIsZoomed={setIsZoomed}
              zoomedDates={zoomedDates}
              setZoomedDates={setZoomedDates}
              showCompareDatesButton={false}
            />
            <StyledMenu
              menuButtonContent={
                <>
                  <DownloadIcon
                    sx={{
                      width: '1rem',
                      height: '1rem',
                    }}
                  />
                  {i18n.text('graph.button.tools.export')}
                </>
              }
            >
              <StyledMenuItem>
                <PngExporter
                  element={document.getElementById(`temperature-flow-chart`)}
                  fileName={`Sensorfact Temperature Analysis - ${new Date().toLocaleDateString()}`}
                  slotProps={{
                    button: {
                      sx: {
                        background: 'none',
                        padding: 0,
                        '&:hover': {
                          background: 'none',
                        },
                      },
                    },
                  }}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <TemperatureCsvExporter
                  data={apexSeries.filter(item => !item.name?.includes('Range'))}
                  interval={interval}
                />
              </StyledMenuItem>
            </StyledMenu>
          </>
        )}
      </SecondaryNavContainer>
      <AssetTreeLayout>
        {assets.length > 0 && assets[0].temperatureSensorLocations.length > 0 ? (
          <Switch>
            {getNavRoutes().map(route => (
              <Route
                key={route.key}
                exact
                path={route.path}
                render={() => route.component}
              />
            ))}
            <Redirect
              from={`${path}/flow`}
              to={`${path}/flow/${firstSensorLocation.id}`}
              exact
            />
          </Switch>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '80vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h5"
              fontWeight={400}
              textAlign="center"
            >
              {i18n.text('messages.error.no-selected-assets.header')}
            </Typography>
          </Box>
        )}
      </AssetTreeLayout>
    </StyledBox>
  )
}
