import { Typography, styled } from '@mui/material'

export const IntroTextWrapper = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  textAlign: 'center',
  fontSize: '1.125rem',
  fontWeight: 500,
  lineHeight: '1.75rem',
  marginBottom: '2rem',
}))

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[700],
  whiteSpace: 'nowrap',
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '1.25rem',
  textAlign: 'left',
  marginBottom: '0.38rem',
}))
