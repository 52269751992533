import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { Redirect, Route, Switch } from 'react-router'

import Account from './Account'
import Configuration from '../Configuration/Configuration'
import MuiSecondaryNav from '../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'
import OEESettings from '../../../OEE/containers/OEESettings/OEESettings'
import usePropositions, { CurrentPropositions } from '../../../Shared/hooks/usePropositions/usePropositions'
import { CompressedAirSettings } from './CompressedAir'
import { GeneralSettings } from './GeneralSettings'
import { Integrations } from './Integrations/Integrations'
import { InternalRoute } from '../../../Shared/components/RouteComponents/InternalRoute'
import { OptionalIconLink } from '../../../Shared/components/MUIComponents/SecondaryNav/SecondaryNav'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const SettingsContainer = () => {
  const theme = useTheme()
  const { user, isInternalUser } = useCurrentUser()
  const { i18n } = useI18nContext()
  const { hasProposition } = usePropositions()

  // Should be partially kept in sync with menuRoutes/settingsMenuLinks
  // TODO (Sabina): Remove Facility and Preferences
  const secondaryLinks: OptionalIconLink[] = [
    {
      name: 'Account',
      i18n: 'router.multi.settings.account',
      path: '/settings/account',
    },
    {
      name: 'General settings',
      i18n: 'router.multi.settings.general-settings',
      path: '/settings/main',
    },
  ]

  if (user.featureFlags?.includes('USER_API_INTEGRATIONS')) {
    secondaryLinks.push({
      name: 'Api Keys',
      i18n: 'api.keys',
      path: '/settings/integrations',
    })
  }

  if (isInternalUser())
    secondaryLinks.push({
      name: 'Configuration',
      i18n: 'router.multi.settings.configuration',
      path: '/settings/configuration',
    })

  if (hasProposition(CurrentPropositions['COMPRESSED-AIR'])) {
    secondaryLinks.push({
      name: 'Compressed Air Settings',
      i18n: 'compressed-air.settings',
      path: '/settings/compressed-air',
    })
  }

  // OEE Settings are only available for internal users (CS team) and only if the OEE proposition
  // is enabled for a selected customer
  if (isInternalUser() && hasProposition(CurrentPropositions['OVERALL-EQUIPMENT-EFFECTIVENESS'])) {
    secondaryLinks.push({
      name: 'OEE Settings',
      i18n: 'router.multi.settings.oee-settings',
      path: '/settings/oee',
    })
  }

  return (
    <Box
      sx={{
        overflowY: 'auto',
        padding: '1.5rem 7rem',
        height: '100%',
        [theme.breakpoints.down('md')]: {
          padding: '1.5rem 2rem',
        },
      }}
    >
      <Typography
        sx={{
          color: theme => theme.palette.SFIGreyLight[800],
          fontSize: '1.875rem',
          fontWeight: 600,
          lineHeight: '2.375rem',
          marginBottom: '0.875rem',
        }}
      >
        {i18n.text('user-bar.settings')}
      </Typography>
      <MuiSecondaryNav
        links={secondaryLinks}
        slotProps={{
          toolbar: {
            sx: {
              padding: 0,
              flexWrap: 'wrap',
              [theme.breakpoints.up(0)]: {
                paddingLeft: 0,
                paddingRight: 0,
              },
            },
          },
        }}
      />
      <Box sx={{ backgroundColor: theme.palette.background.paper, minHeight: '50vh', padding: 0, marginTop: '2rem' }}>
        <Switch>
          <Route
            exact
            path="/settings/account"
            component={Account}
          />
          <Route
            path="/settings/main"
            component={GeneralSettings}
          />
          {user.featureFlags?.includes('USER_API_INTEGRATIONS') && (
            <Route
              exact
              path="/settings/integrations"
              component={Integrations}
            />
          )}
          <InternalRoute
            path="/settings/configuration"
            component={Configuration}
          />
          {hasProposition(CurrentPropositions['COMPRESSED-AIR']) && (
            <Route
              exact
              path="/settings/compressed-air"
              component={CompressedAirSettings}
            />
          )}
          {hasProposition(CurrentPropositions['OVERALL-EQUIPMENT-EFFECTIVENESS']) && (
            <Route
              path="/settings/oee"
              component={OEESettings}
            />
          )}
          <Redirect to="/settings/account" />
        </Switch>
      </Box>
    </Box>
  )
}

export default SettingsContainer
