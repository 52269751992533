import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Box, styled } from '@mui/material'
import { FC } from 'react'

import MuiThemeProvider from '../../../Shared/theme/MuiThemeProvider'
import { ZoomInIcon } from '../../../Shared/components/icons/ZoomInIcon'
import { ZoomOutIcon } from '../../../Shared/components/icons/ZoomOutIcon'

const StyledApexWrapper = styled(Box)(({ theme }) => ({
  '.apexcharts-toolbar': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'none',
    height: '36px',
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    borderRadius: theme.shape.borderRadius,
    padding: 0,
  },
  '.apexcharts-zoom-icon': {
    display: 'none',
  },
  '.apexcharts-zoomin-icon': {
    height: '100%',
    width: '44px',
    transform: 'none',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.SFIGreyLight[700],
    cursor: 'pointer',
    '&:hover': {
      svg: {
        fill: `${theme.palette.SFIBase.white}!important`,
      },
    },
    svg: {
      fill: theme.palette.SFIBase.white,
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
  },
  '.apexcharts-zoomout-icon': {
    height: '100%',
    width: '44px',
    transform: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    color: theme.palette.SFIGreyLight[700],
    cursor: 'pointer',
    '&:hover': {
      svg: {
        fill: `${theme.palette.SFIBase.white}!important`,
      },
    },
    svg: {
      fill: theme.palette.SFIBase.white,
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
  },
}))

const ApexWrapper: FC = ({ children }) => {
  return <StyledApexWrapper id="chart-wrapper">{children}</StyledApexWrapper>
}

const CustomZoomInIcon = () => <ZoomInIcon sx={{ fontSize: '2rem' }} />

const CustomZoomOutIcon = () => <ZoomOutIcon sx={{ fontSize: '2rem' }} />

const zoomInIconHTML = ReactDOMServer.renderToString(
  <MuiThemeProvider>
    <CustomZoomInIcon />
  </MuiThemeProvider>
)

const zoomOutIconHTML = ReactDOMServer.renderToString(
  <MuiThemeProvider>
    <CustomZoomOutIcon />
  </MuiThemeProvider>
)

export { ApexWrapper, zoomInIconHTML, zoomOutIconHTML }
