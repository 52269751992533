import moment from 'moment'
import styled from 'styled-components'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { isNil } from 'ramda'

import EthernetConnector from './EthernetConnector'
import MobileSignalStrength from './MobileSignalStrength'
import WifiSignalStrength from './WifiSignalStrength'
import { BridgifyNetworkFragment } from '../../../Shared/graphql/codegen'
import { SignalQuality, qualities } from './constants'
import { getSensorStatus, sensorSignalToString } from './utils'
import { themeColors } from '../../../Shared/utils'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const getStatusColor = (status: $TSFixMe) => {
  switch (status) {
    case 'online':
      return themeColors.successDark
    case 'offline':
      return themeColors.dangerDark
    default:
      return themeColors.white
  }
}

const getSignalQualityColor = (network: BridgifyNetworkFragment) => {
  const quality = (network.info?.signal ?? 'unknown') as SignalQuality

  return qualities[quality].color
}

const BridgeWrapper = styled.li<{ status: string }>`
  display: inline-block;
  border: 1px solid ${themeColors.grey};
  border-radius: 4px;
  padding: 1em;
  margin: 0 1em 1em 0;
  border-top: 5px solid ${({ status }) => getStatusColor(status)};

  max-width: 30em;
  width: 100%;

  h2 {
    margin: 0;
  }
`
const OverflowWrapper = styled.div`
  max-height: 20em;
  overflow-y: scroll;
`
const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const BridgeId = styled.h2`
  margin: 0;
  display: flex;
  align-items: center;
`
const BridgeType = styled.h3`
  color: ${themeColors.grey};
`

const SensorTime = styled.span<{ status: string }>`
  color: ${({ status }) => getStatusColor(status)};
`
const Pane = styled.div`
  :not(:last-child) {
    border-bottom: 1px dotted ${themeColors.grey};
  }

  :not(:first-child) {
    margin-top: 0.5em;
  }
`
const PaneHeader = styled.div`
  margin-bottom: 1em;
  display: flex;
  align-items: center;
`
const PaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Indicator = styled.span`
  margin-left: auto;
`
const SensorCount = styled.p`
  padding: 0.2em 0.8em;
  font-size: 16px;
  text-align: center;
  border-radius: 1em;
  background-color: ${themeColors.greyLight};
`
const Streamer = styled.p`
  display: block;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-style: italic;
`
type BridgeProps = {
  bridgeId: string
  network: BridgifyNetworkFragment | null
  sensors: {
    id: string
    asset: string
    time?: string | null
    signal?: number | null
  }[]
}

const PressacBridge = ({ bridgeId, network, sensors }: BridgeProps) => {
  const { i18n } = useI18nContext()
  const bridgeStatus = !isNil(network) ? 'online' : 'offline'

  return (
    <BridgeWrapper status={bridgeStatus}>
      <BridgeId>{bridgeId}</BridgeId>
      <BridgeType>Pressac</BridgeType>
      {bridgeStatus === 'offline' && <Streamer>{i18n.text('installation.bridges.bridge-unreachable')}</Streamer>}
      {bridgeStatus === 'online' && network && (
        <PaneWrapper>
          <Pane>
            <PaneHeader>
              <VerticalWrapper>
                <h3>{`${i18n.text('installation.bridges.network')}: ${network.type}`}</h3>
                {network.info && <p>{network.info.essid}</p>}
              </VerticalWrapper>
              <Indicator>
                {network.type === 'unknown' && '❌'}
                {network.type === 'ethernet' && <EthernetConnector />}
                {network.type === 'wifi' && (
                  <WifiSignalStrength
                    signal={network.info?.signal ?? 'poor'}
                    color={getSignalQualityColor(network)}
                    size={48}
                  />
                )}
                {network.type === 'mobile' && (
                  <MobileSignalStrength
                    signal={network.info?.signal ?? 'poor'}
                    color={getSignalQualityColor(network)}
                    act={network.info?.act ?? '?'}
                  />
                )}
              </Indicator>
            </PaneHeader>
          </Pane>
          <Pane>
            <PaneHeader>
              <h3>{i18n.text('installation.bridges.sensors')}</h3>
              <Indicator>
                <SensorCount>{sensors.length}</SensorCount>
              </Indicator>
            </PaneHeader>
            {sensors.length > 0 && (
              <OverflowWrapper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: theme => theme.palette.SFIGreyLight[50] }}>
                        <TableCell>ID </TableCell>
                        <TableCell>{i18n.text('installation.bridges.asset-header')}</TableCell>
                        <TableCell>{i18n.text('installation.bridges.last-seen-header')}</TableCell>
                        <TableCell>{i18n.text('installation.bridges.signal-header')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sensors.map(({ id, asset, time, signal }) => (
                        <TableRow>
                          <TableCell>{id}</TableCell>
                          <TableCell>{asset}</TableCell>
                          <TableCell>
                            <SensorTime status={getSensorStatus(time)}>
                              {!isNil(time) ? moment(time).fromNow() : i18n.text('installation.bridges.never')}
                            </SensorTime>
                          </TableCell>
                          <TableCell>
                            <WifiSignalStrength
                              signal={sensorSignalToString(signal)}
                              color={qualities[sensorSignalToString(signal)].color}
                              size={24}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </OverflowWrapper>
            )}
          </Pane>
        </PaneWrapper>
      )}
    </BridgeWrapper>
  )
}

export default PressacBridge
