import { Box, Button, Chip, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useRef, useState } from 'react'

import AssetHealthReportCard from './AssetHealthReportCard/AssetHealthReportCard'
import useHealthReportData from '../../../hooks/healthReportDataHook'
import { AnnouncementBanner } from '../../../../Shared/components/MUIComponents/update/AnnouncementBanner/AnnouncementBanner'
import { AssetGrid } from '../styled/StyledOverviewPage'
import { ID, ISODateTime } from '../../../../Shared/types/types'
import { InfoTooltipIconWithTitle } from '../../../../Shared/components/MUIComponents/update/InfoTooltipIconWithTitle'
import {
  Pdm_Asset_Health_Report_Status_Enum,
  usePdmAssetHealthReportsListQuery,
  useRequestPdmAssetHealthReportMutation,
} from '../../../../Shared/graphql/codegen'
import { SelectHealthReportInterval } from './SelectReportInterval/SelectHealthReportInterval'
import { isoLocales } from '../../../../Shared/utils/supportedLocales'
import { maintenanceAssetHealthReportSchema } from '../../../../Shared/types/maintenanceAssetHealthReportTypes'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../Shared/contexts/ToastContext'

export const AssetHealthReportWrapper = () => {
  const { i18n, userLocale } = useI18nContext()
  const { showToast } = useToastContext()
  const { customerId } = useCurrentUser()
  const [dataFetchingBanner, setDataFetchingBanner] = useState(true)
  const theme = useTheme()
  const [expandAll, setExpandAll] = useState(false)

  const {
    report,
    data: healthReportData,
    selectReportId,
    loading: loadingReport,
    selectedId,
    error: reportServerError,
  } = useHealthReportData()

  const [requestPdmAssetHealthReport] = useRequestPdmAssetHealthReportMutation({
    onError: () => {
      showToast('', 'error', i18n.text('pdm.asset.health.report.tooltip.error.text'), 5000)
    },
  })

  const {
    data: healthReportsList,
    loading: loadingHealthReportsList,
    error: errorHealthReports,
  } = usePdmAssetHealthReportsListQuery({
    variables: {
      status: Pdm_Asset_Health_Report_Status_Enum.Completed,
    },
  })

  const [fromDate, setFromDate] = useState<ISODateTime>()
  const [toDate, setToDate] = useState<ISODateTime>()
  const isInitialMount = useRef(true)
  const handleExpandAll = () => {
    setExpandAll(!expandAll)
  }

  const handleDateChange = async ({ from, to }: { from: DateTime; to: DateTime }) => {
    setFromDate(from.toISO() as ISODateTime)
    setToDate(to.toISO() as ISODateTime)
  }

  if (!selectedId && healthReportsList && healthReportsList?.pdmAssetHealthReports.length > 0) {
    const lastScheduledReport = healthReportsList.pdmAssetHealthReports[0]
    selectReportId(lastScheduledReport?.id as ID)
  }
  const healthReportLoader = loadingReport || loadingHealthReportsList
  const healthReportError = reportServerError || errorHealthReports

  const fetchRequestedReportData = useCallback(async () => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (fromDate && toDate && customerId && fromDate !== report?.fromDate && toDate !== report?.toDate) {
        const { data: reportData } = await requestPdmAssetHealthReport({
          variables: {
            vars: {
              customerId: customerId,
              fromDate: fromDate,
              toDate: toDate,
            },
          },
        })
        if (reportData?.requestPdmAssetHealthReport?.id) {
          selectReportId(reportData.requestPdmAssetHealthReport.id)
        } else {
          throw new Error('Failed to fetch report data')
        }
      }
    }
  }, [fromDate, toDate])

  useEffect(() => {
    fetchRequestedReportData()
  }, [fetchRequestedReportData])

  if (healthReportError) {
    return <Typography>{i18n.text('pdm.asset.health.report.error.message')}</Typography>
  }

  if (!report?.data) {
    return (
      <AnnouncementBanner
        primaryText={i18n.text('pdm.asset.health.report.primary.text.fetching.data')}
        secondaryText={i18n.text('pdm.asset.health.report.secondary.text.fetching.data')}
        showCtaButton={false}
        onDismiss={() => {
          setDataFetchingBanner(false)
        }}
      />
    )
  }

  const reportData = report?.data
  const { data } = maintenanceAssetHealthReportSchema.parse(reportData)

  const assetGroups = data.content.assetGroups
  const healthReportTitle = `${DateTime.fromISO(data.fromDate)
    .setLocale(isoLocales[userLocale])
    .toFormat('dd LLL yyyy')} / ${DateTime.fromISO(data.toDate)
    .setLocale(isoLocales[userLocale])
    .toFormat('dd LLL yyyy')}`

  return (
    <Box
      sx={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        justifyItems: 'center',
      }}
    >
      <Box
        sx={{
          margin: theme.spacing(1, 0),
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography
          data-testid="asset-health-report-title"
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            gridColumnGap: '0.5rem',
            padding: '0.875rem 0 0',
            fontSize: theme.typography.pxToRem(24),
            alignItems: 'center',
            fontWeight: 600,
            whiteSpace: 'pre-line',
          }}
        >
          <InfoTooltipIconWithTitle
            title={i18n.text('pdm.health.report.title')}
            tooltipText={i18n.text('pdm.health.report.tooltip')}
            isHealpIcon={true}
            slotProps={{
              title: {
                variant: 'h5',
                fontWeight: 600,
              },
            }}
          />
          <Chip
            size="small"
            sx={theme => ({
              backgroundColor: theme.palette.SFIBase.white,
              border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
              borderRadius: '6px',
              width: 'fit-content',
              marginLeft: '0.5rem',
              boxShadow: `0px 1px 2px 0px ${theme.palette.SFIGreyLight[300]}`,
              padding: '0 0.5rem',
              '& .MuiChip-label': {
                padding: 0,
              },
            })}
            label={healthReportTitle}
          />
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: theme.spacing(4),
          }}
        >
          <SelectHealthReportInterval
            sheduledReportsList={healthReportsList!}
            handleDateChange={(from, to) => handleDateChange({ from: from, to: to })}
            loadingHealthReport={healthReportLoader}
            selectReportId={selectReportId}
            fromDate={fromDate || ('' as ISODateTime)}
            toDate={toDate || ('' as ISODateTime)}
            report={healthReportData}
          />
        </Box>
      </Box>
      {healthReportData?.report?.status === Pdm_Asset_Health_Report_Status_Enum.Pending && dataFetchingBanner && (
        <Box>
          <AnnouncementBanner
            primaryText={i18n.text('pdm.asset.health.report.primary.text.fetching.data')}
            secondaryText={i18n.text('pdm.asset.health.report.secondary.text.fetching.data')}
            sx={{
              margin: theme.spacing(2, 0),
            }}
            showCtaButton={false}
            onDismiss={() => {
              setDataFetchingBanner(false)
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginLeft: theme.spacing(2),
        }}
      >
        <Button
          variant="text"
          sx={{
            color: theme.palette.SFIBrand[700],
            marginBottom: theme.spacing(1),
            textAlign: 'right',
            fontFamily: 'Inter',
            fontSize: theme.typography.pxToRem(14),
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: theme.palette.SFIBrand[50],
            },
          }}
          onClick={handleExpandAll}
        >
          {i18n.text('pdm.health.report.expand.all')}
        </Button>
      </Box>
      <AssetGrid data-test-id="asset-grid">
        {assetGroups?.flatMap(group => {
          return group.assets.flatMap(asset => {
            return asset.vibrationSensorLocations.map((vibrationSensorLocation, index) => {
              const key = `${asset.id}-${index}`
              return (
                <AssetHealthReportCard
                  key={key}
                  expandAll={expandAll}
                  assetId={asset.id}
                  name={asset.name}
                  vibrationSensorLocations={vibrationSensorLocation}
                />
              )
            })
          })
        })}
      </AssetGrid>
    </Box>
  )
}
