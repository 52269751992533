import { Operation } from '@apollo/client'

const currentEnv = process.env.REACT_APP_API_ENV ?? 'development'
const customGraphQLPath = process.env.REACT_APP_GRAPHQL_URL

function getPrefix(env: string) {
  switch (env) {
    case 'production':
      return 'prod'
    case 'development':
      return 'dev'
    default:
      return env
  }
}

const apiBackend = `https://api-hasura.${getPrefix(currentEnv)}-external.sensorfact.nl`

export const getGraphQLPath = (operation: Operation) => {
  return customGraphQLPath ?? `${apiBackend}/v1/graphql?opname=${operation.operationName}`
}

export function getAPIPath(env = currentEnv) {
  return `https://${getPrefix(env)}.sensorfact.nl/api`
}

export const apiPath = (paths: TemplateStringsArray, ...parts: (string | number)[]) => {
  return paths.reduce((out, current, index) => {
    return out + current + ((parts && parts[index]) ?? '')
  }, getAPIPath())
}

const cloudApi = `https://gateway.${getPrefix(currentEnv)}.sensorfact.cloud`
export const apiCloud = (paths: TemplateStringsArray, ...parts: (string | number)[]) => {
  return paths.reduce((out, current, index) => {
    return out + current + ((parts && parts[index]) ?? '')
  }, cloudApi)
}

//TODO refactor duplication
export const apiOrigins = [
  `${getPrefix(currentEnv)}.sensorfact.nl`,
  `gateway.${getPrefix(currentEnv)}.sensorfact.cloud`,
]
