import { createSvgIcon } from '@mui/material'

export const FileSettings = createSvgIcon(
  <svg viewBox="0 0 20 20">
    <path
      d="M14.4168 7.91667V5.25C14.4168 3.84987 14.4168 3.1498 14.1443 2.61502C13.9047 2.14462 13.5222 1.76217 13.0518 1.52248C12.517 1.25 11.817 1.25 10.4168 1.25H5.0835C3.68336 1.25 2.9833 1.25 2.44852 1.52248C1.97811 1.76217 1.59566 2.14462 1.35598 2.61502C1.0835 3.1498 1.0835 3.84987 1.0835 5.25V13.9167C1.0835 15.3168 1.0835 16.0169 1.35598 16.5516C1.59566 17.022 1.97811 17.4045 2.44852 17.6442C2.9833 17.9167 3.68336 17.9167 5.0835 17.9167H7.75016M8.16683 8.75H4.41683M5.25016 12.0833H4.41683M11.0835 5.41667H4.41683"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5376 17.5327L11.8094 18.1413C11.8903 18.3224 12.0222 18.4763 12.1891 18.5843C12.3561 18.6924 12.551 18.7498 12.7502 18.7498C12.9493 18.7498 13.1442 18.6924 13.3112 18.5843C13.4782 18.4763 13.6101 18.3224 13.6909 18.1413L13.9628 17.5327C14.0596 17.3168 14.2224 17.1368 14.428 17.0183C14.6349 16.8995 14.8742 16.8489 15.1118 16.8738L15.777 16.9442C15.9751 16.9651 16.1749 16.9283 16.3523 16.8384C16.5298 16.7484 16.6772 16.6092 16.7767 16.4376C16.8763 16.266 16.9238 16.0694 16.9133 15.8715C16.9029 15.6736 16.8349 15.483 16.7178 15.3228L16.3239 14.7843C16.1837 14.5911 16.1087 14.3585 16.1099 14.1202C16.1099 13.8825 16.1855 13.6509 16.326 13.4586L16.7198 12.9201C16.837 12.7599 16.9049 12.5693 16.9154 12.3714C16.9259 12.1735 16.8784 11.9769 16.7788 11.8054C16.6793 11.6337 16.5318 11.4945 16.3544 11.4045C16.177 11.3146 15.9771 11.2778 15.7791 11.2987L15.1139 11.3691C14.8763 11.394 14.637 11.3434 14.43 11.2246C14.224 11.1055 14.0612 10.9245 13.9648 10.7076L13.6909 10.0991C13.6101 9.91793 13.4782 9.76402 13.3112 9.656C13.1442 9.54799 12.9493 9.49051 12.7502 9.49054C12.551 9.49051 12.3561 9.54799 12.1891 9.656C12.0222 9.76402 11.8903 9.91793 11.8094 10.0991L11.5376 10.7076C11.4412 10.9245 11.2784 11.1055 11.0724 11.2246C10.8654 11.3434 10.6261 11.394 10.3885 11.3691L9.72122 11.2987C9.5232 11.2778 9.32335 11.3146 9.14591 11.4045C8.96848 11.4945 8.82107 11.6337 8.72156 11.8054C8.62192 11.9769 8.57446 12.1735 8.58492 12.3714C8.59538 12.5693 8.66331 12.7599 8.78049 12.9201L9.17435 13.4586C9.31481 13.6509 9.39045 13.8825 9.39041 14.1202C9.39045 14.3579 9.31481 14.5895 9.17435 14.7817L8.78049 15.3203C8.66331 15.4805 8.59538 15.6711 8.58492 15.8689C8.57446 16.0668 8.62192 16.2634 8.72156 16.435C8.82117 16.6065 8.96859 16.7457 9.146 16.8356C9.32342 16.9256 9.52321 16.9624 9.72122 16.9417L10.3864 16.8712C10.624 16.8464 10.8634 16.897 11.0703 17.0157C11.2771 17.1345 11.4407 17.3155 11.5376 17.5327Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7493 15.5091C13.5201 15.5091 14.1449 14.8872 14.1449 14.1202C14.1449 13.3531 13.5201 12.7313 12.7493 12.7313C11.9786 12.7313 11.3537 13.3531 11.3537 14.1202C11.3537 14.8872 11.9786 15.5091 12.7493 15.5091Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'technical-info'
)
