import { Box, RadioGroup, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

import { AssetGroupingSelectProps } from '../types'
import { StyledFormControlLabel } from '../../../../../Shared/components/MUIComponents/update/Radio/StyledFormControlLabel'
import { StyledInputFieldHeader, StyledTooltipWithIcon } from '../Common'
import { StyledRadio } from '../../../../../Shared/components/MUIComponents/update/Radio/StyledRadio'
import { useGetAvailableAssetGroupingLevelsQuery } from '../../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const AssetGroupingSelect: FC<AssetGroupingSelectProps> = ({
  assetGroupingLevel,
  handleAssetGroupingChange,
  numCommoditiesSelected,
}) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { data: availableAssetGroupingData } = useGetAvailableAssetGroupingLevelsQuery()
  const availableAssetGroupingLevels = availableAssetGroupingData?.trackingReportAssetGroupingLevels.map(
    assetGroupingLevel => assetGroupingLevel.value
  )

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '30% 70%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.125rem', marginRight: '1rem' }}>
          <StyledInputFieldHeader sx={{ alignContent: 'center', width: 'fit-content' }}>
            {i18n.text(`reports.tracking.configuration.module.showData`)}
          </StyledInputFieldHeader>
          <StyledTooltipWithIcon
            title={i18n.text('reports.tracking.configuration.tooltip.assetGrouping')}
          ></StyledTooltipWithIcon>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignContent: 'left',
          }}
        >
          <RadioGroup
            row
            value={assetGroupingLevel}
            onChange={handleAssetGroupingChange}
          >
            {' '}
            {availableAssetGroupingLevels &&
              availableAssetGroupingLevels.map((assetGroupingLevel, index) => (
                <StyledFormControlLabel
                  key={index}
                  value={assetGroupingLevel}
                  control={<StyledRadio />}
                  label={i18n.text(`reports.tracking.configuration.module.${assetGroupingLevel}`)}
                  disabled={assetGroupingLevel === 'total' && numCommoditiesSelected > 1}
                />
              ))}
          </RadioGroup>
          {numCommoditiesSelected > 1 && (
            <Typography
              fontWeight={200}
              marginRight="1rem"
              sx={{ fontSize: '0.875rem', fontWeight: 400, color: theme.palette.SFIGreyLight[600] }}
            >
              {i18n.text('reports.tracking.configuration.helperText.asTotalDisabled')}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  )
}
