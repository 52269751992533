import timeIntervals from '../constants/timeIntervals'

export type TickSize = {
  format: string
  size: number
  unit: string
  viewKey: string
  calculateInterval: () => number
}

function millisecondsToDays(milliseconds: number): number {
  return milliseconds / 1000 / 60 / 60 / 24
}

// correction number for when delta is on same day
const magicNumber = 1.15740740325165359e-8
/**
 * getTickSize - Returns the interval size for fetching data
 *
 * @param  {Number} days time diff in days
 * @return {Object}     Interval object
 */
export function getTickSize(milliseconds: number): TickSize {
  if (Number.isNaN(milliseconds) || typeof milliseconds !== 'number') throw new TypeError('`days` should be a number')

  const days = millisecondsToDays(milliseconds)
  if (days + magicNumber < 1) {
    return {
      format: '%H:%M',
      size: 1,
      unit: 'minute',
      viewKey: 'graph.range.view.minutes',
      calculateInterval: () => 12,
    }
  }

  const daysCeil = Math.ceil(days)
  const timeInterval = timeIntervals.find(interval => interval.days.includes(daysCeil))
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  return timeInterval.ticks
}
