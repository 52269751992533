import EnergyBalance from './Gas_EnergyBalance'
import Power from './Gas_Power'
import StackedBarGraph from './Gas_StackedBarGraph'
import type { AnalysisType, AssetLevel0, LocalAsset } from '../../../Shared/types/analysis_types'
import { prepareAssets } from '../../../Shared/utils/analysis_utils'
import { AnalysisAssetFragment } from '../../../Shared/graphql/codegen'

export function getGraph(type: AnalysisType) {
  switch (type) {
    case 'energy':
      return StackedBarGraph
    case 'energy-balance':
      return EnergyBalance
    case 'power':
      return Power
    case 'volume':
      return StackedBarGraph
    default:
      console.error(`Unknown AnalysisType: ${type}`)
      return null
  }
}

const isGasAsset = (asset: AnalysisAssetFragment) =>
  asset.payload && asset.payload.commodityType === 'natural-gas'

export const processGasAssetTree = (rawGroups: readonly AssetLevel0[]): LocalAsset[] => {
  const groups: AssetLevel0[] = []

  rawGroups.forEach(g => {
    const machines = g.assets.filter(machine => {
      const sensorCount = [...machine.sensors, ...machine.assets
        .filter(subAsset => isGasAsset(subAsset))
        .flatMap(subAsset => subAsset.sensors)].length

      return isGasAsset(machine) && sensorCount > 0
    })

    const group: AssetLevel0 = {
      ...g,
      assets: machines,
    }

    if (group.assets.length > 0) {
      groups.push(group)
    }
  })

  return prepareAssets(groups)
}
