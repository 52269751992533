import { DateTime, DurationUnits } from 'luxon'
import { DocumentNode } from 'graphql'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { LocalDateTime } from '../types/types'
import { toLocalDateTime } from '../utils'

const DEFAULT_FREQUENCY_IN_MS = 1000 * 60 * 2 // 2 minutes

const getTimeDifferenceFromNow = (time: string, units: DurationUnits) => {
  const now = DateTime.now()
  const parsedTime = DateTime.fromISO(time)
  return now.diff(parsedTime, units)
}

export type Interval = {
  start: LocalDateTime
  end: LocalDateTime
}

interface UseLiveRefetchingProps {
  query: DocumentNode
  options: QueryHookOptions
  interval: Interval
  frequency?: number
  incrementStart?: boolean
  onInternalIntervalChange?: (newInterval: Interval) => void
  allowedDifferenceFromNow?: number
}

/** This hook is used to fetch the data from the server with the provided frequency.
    It checks the current interval end and decides how to handle the re-fetching:
    - If the interval end is in the future, it starts polling with the provided frequency.
    - If the interval end is close to now, it does re-fetching with the provided frequency and shifts the interval by the frequency value.
    - If the interval end is in the past, it doesn't poll/re-fetch data.

    @param query - The query to be executed.
    @param options - The options for the query.
    @param interval - The interval for the query.
    @param frequency - The frequency of the re-fetching in milliseconds (default is 2 minutes).
    @param incrementStart - If true, the start of the interval will be incremented by the frequency value (default is true).
    @param onInternalIntervalChange - A callback function that is called when the internal interval is changed.
    @param allowedDifferenceFromNow - The allowed difference from now in minutes to start polling (default is 10).
 **/
const useLiveRefetching = ({
  query,
  options,
  interval,
  frequency = DEFAULT_FREQUENCY_IN_MS,
  incrementStart = true,
  onInternalIntervalChange,
  allowedDifferenceFromNow = 10,
}: UseLiveRefetchingProps) => {
  const { data, loading, error, startPolling, stopPolling, refetch } = useQuery(query, options)
  const [internalInterval, setInternalInterval] = useState<Interval>(interval)
  const [isManualPollingEnabled, setIsManualPollingEnabled] = useState(false)

  const timeDifferenceFromNowInMinutes = getTimeDifferenceFromNow(internalInterval.end, 'minutes').minutes

  const isIntervalEndInFuture = timeDifferenceFromNowInMinutes < 0
  const isIntervalEndCloseToNow =
    timeDifferenceFromNowInMinutes <= allowedDifferenceFromNow && timeDifferenceFromNowInMinutes >= 0

  const startManualPolling = (newFrequency?: number) => {
    setIsManualPollingEnabled(true)
    startPolling(newFrequency ?? frequency)
  }

  const stopManualPolling = () => {
    setIsManualPollingEnabled(false)
    stopPolling()
  }

  useEffect(() => {
    setInternalInterval(interval)
  }, [interval])

  useEffect(() => {
    if (isIntervalEndInFuture && !isManualPollingEnabled) {
      startPolling(frequency)
    } else if (!isIntervalEndInFuture && !isManualPollingEnabled) {
      stopPolling()
    }

    return () => {
      stopPolling()
    }
  }, [isIntervalEndInFuture, startPolling, stopPolling, frequency, isManualPollingEnabled])

  useEffect(() => {
    const shiftIntervalAndRefetch = () => {
      if (!isIntervalEndCloseToNow || isManualPollingEnabled) return

      const nextStart = incrementStart
        ? DateTime.fromISO(internalInterval.start).plus({ milliseconds: frequency })
        : DateTime.fromISO(internalInterval.start)
      const nextEnd = DateTime.fromISO(internalInterval.end).plus({ milliseconds: frequency })
      const newInterval = { start: toLocalDateTime(nextStart), end: toLocalDateTime(nextEnd) }

      setInternalInterval(newInterval)

      if (onInternalIntervalChange) onInternalIntervalChange(newInterval)

      /** @note we should pass to refetch() function only variables that have changed,
                because Apollo will use previous values for all omitted variables automatically **/
      refetch({ from: newInterval.start, to: newInterval.end })
    }

    const fetchTimeoutId = setTimeout(shiftIntervalAndRefetch, frequency)

    return () => {
      clearTimeout(fetchTimeoutId)
    }
  }, [
    frequency,
    isIntervalEndCloseToNow,
    refetch,
    options,
    onInternalIntervalChange,
    isManualPollingEnabled,
    incrementStart,
    internalInterval.start,
    internalInterval.end,
  ])

  return {
    data,
    loading,
    error,
    internalInterval,
    startPolling: startManualPolling,
    stopPolling: stopManualPolling,
    refetch,
  }
}

export default useLiveRefetching
