// Until we update i18n / etc, using this as a quick fix

import { CurrencyIsoCode } from '../graphql/codegen'
import { SupportedLocale } from './supportedLocales'

export function toValidIntlLocale(locale: SupportedLocale) {
  return locale.replace('_', '-')
}

export const getCurrencyFormatter = (locale: SupportedLocale, currencyCode: CurrencyIsoCode) => {
  return (num: number, fractionDigits = 2) => {
    const formatter = new Intl.NumberFormat(toValidIntlLocale(locale), {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: fractionDigits,
      currencyDisplay: 'narrowSymbol',
    })
    return formatter.format(num)
  }
}

export const getCurrencySymbol = (locale: SupportedLocale, currencyCode: CurrencyIsoCode) => {
  const formatter = new Intl.NumberFormat(toValidIntlLocale(locale), {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0,
  })
  return formatter.format(0).replace(/\d/g, '').trim()
}

export const getCurrencyName = (locale: SupportedLocale, currencyCode: CurrencyIsoCode) => {
  const formatter = new Intl.NumberFormat(toValidIntlLocale(locale), {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'name',
    maximumFractionDigits: 0,
  })
  const name = formatter.format(0).replace(/\d/g, '').trim()
  const firstLetter = name[0].toLocaleUpperCase(toValidIntlLocale(locale))
  return firstLetter + name.slice(1)
}
