import Drawer from '@mui/material/Drawer'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Alert, Box, useTheme } from '@mui/material'
import { DateTime } from 'luxon'

import Loader from '../../../Shared/components/MUIComponents/Loader'
import ProductionStopsDrawerFilter from './ProductionStopsDrawerFilter'
import ProductionStopsDrawerHeader from './ProductionStopsDrawerHeader'
import ProductionStopsDrawerItem from './ProductionStopsDrawerItem'
import { DEFAULT_TIMEZONE } from '../../../Shared/constants/timezone'
import { GraphInterval } from '../../types/Graph.types'
import { Machine } from '../../types/Assets.types'
import { OeeProductionStop, useGetOeeProductionStopsLazyQuery } from '../../../Shared/graphql/codegen'
import { setTimeZone } from '../../utils/helpers'
import { toLocalDateTime } from '../../../Shared/utils'
import { transformProductionStops } from '../../utils/dataTransformations'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type ProductionStopsDrawerProps = {
  stops: OeeProductionStop[]
  machine: Machine
  isOpen: boolean
  interval: GraphInterval
  onDataChange: () => void
  onDrawerClose: () => void
}

const ProductionStopsDrawer: FC<ProductionStopsDrawerProps> = ({
  stops,
  machine,
  isOpen,
  interval,
  onDataChange,
  onDrawerClose,
}) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { selectedCustomer } = useCurrentUser()

  const [stopsInterval, setStopsInterval] = useState<GraphInterval | null>(null)
  const [isLiveData, setIsLiveData] = useState(true)
  const [liveStops, setLiveStops] = useState<OeeProductionStop[]>([])
  const [unexplainedStopsCount, setUnexplainedStopsCount] = useState(0)
  const [shownStops, setShownStops] = useState<OeeProductionStop[]>([])

  const timeZone = selectedCustomer?.timeZone ?? DEFAULT_TIMEZONE

  const [fetchProductionStops, { data: historicalStops, loading, error }] = useGetOeeProductionStopsLazyQuery()

  const updateShownStops = useCallback((newStops: OeeProductionStop[], isLiveData?: boolean) => {
    const { unexplainedStops, explainedStops } = transformProductionStops(newStops ?? [])

    setUnexplainedStopsCount(unexplainedStops.length)
    setShownStops([...unexplainedStops, ...explainedStops])

    if (isLiveData) setLiveStops([...unexplainedStops, ...explainedStops])
  }, [])

  useEffect(() => {
    if (isLiveData) {
      updateShownStops(stops, isLiveData)
    } else {
      updateShownStops(
        historicalStops?.myOrg?.assets?.[0].enoceanPulseCounterSensorLocations?.[0]
          .productionStops as OeeProductionStop[]
      )
    }
  }, [historicalStops?.myOrg?.assets, isLiveData, stops, updateShownStops])

  const handleDrawerClose = () => {
    setIsLiveData(true)
    setStopsInterval(null)
    setShownStops(liveStops)
    onDrawerClose()
  }

  const handleIntervalChange = (start: DateTime, end: DateTime) => {
    const newStart = toLocalDateTime(start)
    const newEnd = toLocalDateTime(end)

    setStopsInterval({ start: newStart, end: newEnd })
    setIsLiveData(false)

    fetchProductionStops({
      variables: {
        assetIds: [machine.id],
        from: setTimeZone(newStart, timeZone),
        to: setTimeZone(newEnd, timeZone),
      },
    })
  }

  return (
    <Drawer
      sx={{
        zIndex: 2000,
      }}
      open={isOpen}
      anchor="right"
      onClose={handleDrawerClose}
    >
      <Box
        sx={{
          width: '50vw',
        }}
      >
        <ProductionStopsDrawerHeader
          name={machine.name}
          interval={isLiveData ? interval : stopsInterval}
          unexplainedStopsCount={unexplainedStopsCount}
          onClose={handleDrawerClose}
        />
        <Box
          sx={{
            padding: '1.2rem',
            display: 'grid',
            rowGap: theme.spacing(2),
          }}
        >
          <ProductionStopsDrawerFilter onIntervalChange={handleIntervalChange} />
          {loading && <Loader />}
          {error && <Alert severity="error">{i18n.text('oee.stop-logging.fetching-error')}</Alert>}
          {!loading && !error && shownStops.length === 0 && (
            <Alert severity="info">{i18n.text('oee.stop-logging.no-data')}</Alert>
          )}
          {!loading && !error && shownStops.length > 0 && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateRow: '1fr',
                gridTemplateColumns: 'repeat(auto-fill, minmax(350px, auto))',
                gap: theme.spacing(1.5),
              }}
            >
              {shownStops.map(shownStop => (
                <ProductionStopsDrawerItem
                  key={shownStop.id}
                  stop={shownStop}
                  assetId={machine.id}
                  onListUpdate={onDataChange}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  )
}

export default ProductionStopsDrawer
