import React, { useMemo } from 'react'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Box, IconButton, MenuItem, SelectProps, outlinedInputClasses, styled, useTheme } from '@mui/material'
import { Control, Controller, FieldValues, Path, PathValue, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { DateTime, Info } from 'luxon'
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers'

import { AlertInterval } from '../../../../types/alertTypes'
import { StyledFlexBox, StyledLabel } from '../../AlertConfigurationModal.styled'
import { StyledSelect } from '../../../../../Shared/components/MUIComponents/update/Selectv1/StyledSelect'
import { TrashIcon } from '../../../../../Shared/components/icons'
import { textFieldStyle } from '../../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { toTwoDigits } from '../../../../utils/alertTimeManipulation'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

const timeFormat = 'HH:mm'

interface ITimePeriodFormSectionProps<T extends FieldValues> {
  name: Path<T>
  timeInterval: AlertInterval
  index: number
  selectProps: SelectProps<string>
  control: Control<T>
  isDisabled: boolean
  watch: UseFormWatch<T>
  setValue: UseFormSetValue<T>
}

export const TimePeriodFormSection = <T extends FieldValues>({
  name,
  timeInterval,
  index,
  selectProps,
  control,
  isDisabled,
  watch,
  setValue,
}: ITimePeriodFormSectionProps<T>) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const existingTimeIntervals: AlertInterval[] = watch(name)
  const availableDayOptions = [0, 1, 2, 3, 4, 5, 6]

  const dayOptions = availableDayOptions.map(day => ({
    option: Info.weekdays()[day],
    value: Info.weekdays()[day].toUpperCase(),
    key: day,
  }))

  const fromTime = useMemo(() => getFormattedTime('fromTime'), [timeInterval.fromTime])

  const toTime = useMemo(() => getFormattedTime('toTime'), [timeInterval.toTime])

  function getFormattedTime(timeType: 'fromTime' | 'toTime') {
    const timeValues = timeInterval[timeType].split(':')
    const hours = timeValues[0] ? +timeValues[0] : 12
    const minutes = timeValues[1] ? +timeValues[1] : 0
    return DateTime.now().set({ hour: hours, minute: minutes })
  }

  function removeTimePeriod() {
    const newTimeIntervals: AlertInterval[] = existingTimeIntervals?.filter(
      (interval: AlertInterval, idx: number) => idx !== index
    )
    setValue(name, newTimeIntervals as PathValue<T, Path<T>>)
  }

  function handleTimeChange(newValue: DateTime | null, type: 'fromTime' | 'toTime') {
    const timeValue = `${toTwoDigits(newValue?.hour)}:${toTwoDigits(newValue?.minute)}`
    setValue(`${name}.${index}.${type}` as Path<T>, timeValue as PathValue<T, Path<T>>)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <SimpleGridBox>
        <StyledFlexBox>
          {index === 0 && <StyledLabel>{i18n.text('rules.time.start')}</StyledLabel>}
          <Controller
            name={`${name}.${index}.fromDay` as Path<T>}
            control={control}
            render={({ field: { onChange, value } }) => (
              <StyledSelect
                {...selectProps}
                size="small"
                labelId="start-select-label"
                id="start-select"
                value={value}
                onChange={onChange}
              >
                {dayOptions.map(day => (
                  <MenuItem
                    key={day.key}
                    value={day.value}
                  >
                    {day.option}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
          ></Controller>
        </StyledFlexBox>
        <StyledFlexBox
          sx={{
            marginRight: '1.44rem',
          }}
        >
          {index === 0 && <StyledLabel>{i18n.text('rules.headers.time')}</StyledLabel>}
          <Controller
            name={`${name}.${index}.fromTime` as Path<T>}
            control={control}
            render={() => (
              <TimeField
                value={fromTime}
                format={timeFormat}
                minutesStep={15}
                size="small"
                onChange={newValue => handleTimeChange(newValue, 'fromTime')}
                slotProps={{
                  textField: {
                    sx: {
                      ...textFieldStyle(theme),
                      [`.${outlinedInputClasses.root}`]: {
                        paddingRight: 0,
                      },
                    },
                  },
                }}
              />
            )}
          ></Controller>
        </StyledFlexBox>
        <StyledFlexBox>
          {index === 0 && <StyledLabel>{i18n.text('rules.time.stop')}</StyledLabel>}
          <Controller
            name={`${name}.${index}.toDay` as Path<T>}
            control={control}
            render={({ field: { onChange, value } }) => (
              <StyledSelect
                {...selectProps}
                size="small"
                labelId="end-select-label"
                id="emd-select"
                value={value}
                onChange={onChange}
              >
                {dayOptions.map(day => (
                  <MenuItem
                    key={day.key}
                    value={day.value}
                  >
                    {day.option}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
          ></Controller>
        </StyledFlexBox>
        <StyledFlexBox>
          {index === 0 && <StyledLabel>{i18n.text('rules.headers.time')}</StyledLabel>}
          <Controller
            name={`${name}.${index}.toTime` as Path<T>}
            control={control}
            render={() => (
              <TimeField
                value={toTime}
                format={timeFormat}
                minutesStep={15}
                size="small"
                onChange={newValue => handleTimeChange(newValue, 'toTime')}
                slotProps={{
                  textField: {
                    sx: {
                      ...textFieldStyle(theme),
                      [`.${outlinedInputClasses.root}`]: {
                        paddingRight: 0,
                      },
                    },
                  },
                }}
              />
            )}
          ></Controller>
        </StyledFlexBox>
        <StyledFlexBox sx={{ alignItems: 'center' }}>
          {index === 0 && <StyledLabel>{i18n.text('integrations.delete')}</StyledLabel>}
          <IconButton
            disabled={isDisabled}
            onClick={removeTimePeriod}
          >
            <TrashIcon sx={{ color: theme => theme.palette.SFIOrange[700] }} />
          </IconButton>
        </StyledFlexBox>
      </SimpleGridBox>
    </LocalizationProvider>
  )
}

const SimpleGridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'minmax(0, 1fr) 0.75fr minmax(0, 1fr) 0.75fr auto',
  gap: '1rem',
})
