import { Box, Button, Card, CardContent, CardMedia, Dialog, DialogContent, Typography } from '@mui/material'
import { ChevronLeft, ChevronRight, Close } from '@mui/icons-material'
import { FC, useEffect, useMemo, useState } from 'react'

import slideshowImagesByLocale from '../../components/IntroSlideshow/currentImages'
import wasteInWebclientImages from '../../components/IntroSlideshow/wasteInWebclient'
import { browserStorage } from '../../../Shared/utils'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

export type SlideshowImageInfo = {
  src: string
  i18n?: string
}

export type SlideshowProps = {
  images?: SlideshowImageInfo[]
  storageKey?: string
}

const IntroSlideshow: FC<SlideshowProps> = () => {
  const { hasElectricityStandbyEnabled, INTRO_SLIDESHOW_KEY } = useCurrentUser()
  const { userLocale } = useI18nContext()

  const storageKey = INTRO_SLIDESHOW_KEY || ''

  const images = useMemo(
    () =>
      hasElectricityStandbyEnabled ? wasteInWebclientImages[userLocale] : slideshowImagesByLocale[userLocale] || [],
    [hasElectricityStandbyEnabled, userLocale]
  )
  const hasSeenSlideshow = browserStorage.get(storageKey).value
  const [open, setOpen] = useState(false)
  const [currentImageIndex, setImageIndex] = useState(0)
  const isLastImage = currentImageIndex === images.length - 1
  const { i18n } = useI18nContext()

  useEffect(() => setOpen(!hasSeenSlideshow), [hasSeenSlideshow])

  const handleClose = () => setOpen(false)

  const handleCloseAndSkip = () => {
    setOpen(false)
    browserStorage.set(storageKey, true)
  }
  const handlePreviousImage = () => {
    if (currentImageIndex > 0) setImageIndex(i => i - 1)
  }
  const handleNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setImageIndex(i => i + 1)
    } else {
      handleClose()
      browserStorage.set(storageKey, true)
    }
  }

  const currentImage = images[currentImageIndex]
  if (!storageKey) return null
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
      >
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={handlePreviousImage}
              startIcon={<ChevronLeft />}
            >
              Back
            </Button>
            <Button
              onClick={handleNextImage}
              endIcon={<ChevronRight />}
            >
              {isLastImage ? 'Close' : 'Next'}
            </Button>
          </Box>
          <Card>
            <CardMedia
              component="img"
              image={currentImage.src}
              sx={{
                height: '475px',
                objectFit: 'contain',
                width: '100%',
              }}
            />
            {currentImage.i18n && (
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >
                  {i18n.text(currentImage.i18n)}
                </Typography>
              </CardContent>
            )}
          </Card>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
            <Button
              endIcon={<Close />}
              onClick={handleCloseAndSkip}
            >
              Close and do not show again
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default IntroSlideshow
