import { createSvgIcon } from '@mui/material'

export const Building = createSvgIcon(
  <svg viewBox="0 0 20 20">
    <path
      d="M6.25033 5.83333H8.54199M6.25033 9.16667H8.54199M6.25033 12.5H8.54199M11.4587 5.83333H13.7503M11.4587 9.16667H13.7503M11.4587 12.5H13.7503M16.667 17.5V5.16667C16.667 4.23325 16.667 3.76654 16.4853 3.41002C16.3255 3.09641 16.0706 2.84144 15.757 2.68166C15.4005 2.5 14.9337 2.5 14.0003 2.5H6.00033C5.0669 2.5 4.60019 2.5 4.24367 2.68166C3.93007 2.84144 3.6751 3.09641 3.51531 3.41002C3.33366 3.76654 3.33366 4.23325 3.33366 5.16667V17.5M18.3337 17.5H1.66699"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'building'
)
