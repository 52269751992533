import { SvgIcon, SxProps, Theme } from '@mui/material'
interface IAlertIcon {
  size: 'small' | 'medium' | 'large'
  sx?: SxProps<Theme> | undefined
}

export const AlertIcon = ({ size, sx }: IAlertIcon) => (
  <SvgIcon
    viewBox="0 0 20 20"
    fontSize={size}
    sx={sx}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 6.66675V10.0001M9.99984 13.3334H10.0082M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
