import { Box } from '@mui/material'
import { FC } from 'react'

import { LanguageSelectProps } from '../types'
import { StyledInputFieldHeader } from '../Common'
import { StyledMenuItem } from '../../../../../Shared/components/MUIComponents/update/Selectv1/StyledMenuItem'
import { StyledSelect } from '../../../../../Shared/components/MUIComponents/update/Selectv1/StyledSelect'
import { supportedLocalesStrings } from '../../../../../Shared/utils'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const LanguageSelect: FC<LanguageSelectProps> = ({ locale, handlelocalechange }) => {
  const { i18n } = useI18nContext()
  const languages = supportedLocalesStrings
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.375rem',
      }}
    >
      <StyledInputFieldHeader>{i18n.text(`reports.tracking.configuration.general.language`)}</StyledInputFieldHeader>
      <StyledSelect
        size="small"
        value={locale}
        onChange={handlelocalechange || ''}
        sx={{ maxWidth: '30rem' }}
      >
        {Object.keys(languages).map((language, index) => (
          <StyledMenuItem
            disableRipple
            key={index}
            value={language}
          >
            {i18n.text(`reports.tracking.configuration.language.${language}`)}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </Box>
  )
}
