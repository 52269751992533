import React, { FC } from 'react'
import { Box } from '@mui/material'

import { ExplanationText, GridBoxRow, Label } from '../..'
import { StyledUnitTextField } from '../../../../../../Shared/components/MUIComponents/update/styledComponents/StyledUnitTextField'
import { useI18nContext } from '../../../../../../Shared/contexts/i18nContext/I18nContext'

interface IPriceTextFieldBoxProps {
  label: string
  explanationText: string
  unitName: string
  value: string
  isDisabled: boolean
  onChange: (newValue: string) => void
  onSave: () => void
}

export const PriceTextFieldBox: FC<IPriceTextFieldBoxProps> = ({
  label,
  explanationText,
  unitName,
  value,
  isDisabled,
  onChange,
  onSave,
}) => {
  const { i18n } = useI18nContext()

  return (
    <GridBoxRow>
      <Box>
        <Label>{i18n.text(label)}</Label>
        <ExplanationText>{i18n.text(explanationText)}</ExplanationText>
      </Box>
      <StyledUnitTextField
        unitName={unitName}
        value={value}
        size="small"
        variant="outlined"
        onChange={e => onChange(e.target.value)}
        onBlur={onSave}
        maxWidth="32rem"
        disabled={isDisabled}
      />
    </GridBoxRow>
  )
}
