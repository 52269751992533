import { ApexOptions } from 'apexcharts'
import { FC } from 'react'

import GraphContainer from '../GraphsShared/GraphContainer'
import GraphHeader from '../GraphsShared/GraphHeader'
import ProductionSpeedGraph from './ProductionSpeedGraph'
import { AssetProductionSpeed } from '../../types/Assets.types'
import { Oee_Production_Speed_Unit_Enum } from '../../../Shared/graphql/codegen'
import { SPEED_UNIT_STRING } from '../../utils/constants'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type ProductionSpeedProps = {
  productionSpeedData: AssetProductionSpeed[]
  chartHeight?: number
  shouldShowQuickIntervals?: boolean
  customOptions?: ApexOptions
  isToolbarDisabled?: boolean
}

const ProductionSpeed: FC<ProductionSpeedProps> = ({
  chartHeight,
  productionSpeedData,
  shouldShowQuickIntervals = true,
  customOptions,
  isToolbarDisabled,
}) => {
  const { i18n } = useI18nContext()
  const { units } = useOEEContext()

  const isCycleTime = units.speed === Oee_Production_Speed_Unit_Enum.CycleTimeSeconds
  const graphTitle = `${i18n.text(
    isCycleTime ? 'oee.machine-view.cycle-time' : 'oee.machine-view.production-speed'
  )} (${i18n.text(SPEED_UNIT_STRING[units.speed])})`

  const toolbarOptions = {
    chart: {
      toolbar: {
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
        },
      },
    },
  }

  return (
    <SectionBoxWrapper>
      <GraphContainer>
        <GraphHeader
          title={graphTitle}
          shouldShowQuickIntervals={shouldShowQuickIntervals}
        />
        <ProductionSpeedGraph
          chartHeight={chartHeight}
          productionSpeedData={productionSpeedData}
          customOptions={isToolbarDisabled ? { ...customOptions, ...toolbarOptions } : customOptions}
        />
      </GraphContainer>
    </SectionBoxWrapper>
  )
}
export default ProductionSpeed
