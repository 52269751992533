import Chart from 'react-apexcharts'
import apexDe from 'apexcharts/dist/locales/de.json'
import apexEn from 'apexcharts/dist/locales/en.json'
import apexEs from 'apexcharts/dist/locales/es.json'
import apexFr from 'apexcharts/dist/locales/fr.json'
import apexIt from 'apexcharts/dist/locales/it.json'
import apexNl from 'apexcharts/dist/locales/nl.json'
import React, { FC, useCallback, useLayoutEffect, useState } from 'react'
import { ApexOptions } from 'apexcharts'
import { ApolloError } from '@apollo/client'
import { DateTime, Duration } from 'luxon'
import { deepmerge } from '@mui/utils'

import Error from '../../../Shared/components/MUIComponents/Error'
import Loader from '../../../Shared/components/MUIComponents/Loader'
import { toLocalDateTime } from '../../../Shared/utils'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type GenericChartProps = {
  id: string
  series: ApexOptions['series']
  chartType: ApexChart['type']
  loading?: boolean
  error?: ApolloError | undefined
  customOptions?: ApexOptions
  height?: number
}

const apexLocales = [apexEn, apexDe, apexEs, apexIt, apexFr, apexNl]

const GenericChart: FC<GenericChartProps> = ({ id, series, chartType, height, customOptions = {}, loading, error }) => {
  const { i18n } = useI18nContext()
  const { updateInterval } = useOEEContext()

  const initialGraphHeight = window.innerHeight * 0.55
  const [responsiveHeight, setResponsiveHeight] = useState(initialGraphHeight)

  useLayoutEffect(() => {
    const handleResize = () => {
      const el = document.getElementById('chart-wrapper')
      const graphHeight = el?.getBoundingClientRect().height ?? 0

      setResponsiveHeight(graphHeight)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setResponsiveHeight])

  const handleZoomChange = useCallback(
    (chart: unknown, changed: { xaxis: { min: number; max: number } }) => {
      const {
        xaxis: { min, max },
      } = changed

      const startDate = DateTime.fromMillis(min)
      const endDate = DateTime.fromMillis(max)

      const duration = endDate.diff(startDate)

      if (duration <= Duration.fromObject({ minutes: 1 })) return

      updateInterval({ start: toLocalDateTime(startDate), end: toLocalDateTime(endDate) })
    },
    [updateInterval]
  )

  const dateFormatter = (timestamp: string) => {
    const dateTimeFromTimestamp = DateTime.fromISO(timestamp)
    return dateTimeFromTimestamp.toFormat('dd-MM-yyyy HH:mm')
  }

  if (loading) return <Loader />

  if (error) return <Error />

  const defaultOptions: ApexOptions = {
    chart: {
      id,
      locales: apexLocales,
      animations: {
        enabled: false,
      },
      events: {
        zoomed: handleZoomChange,
      },
      toolbar: {
        tools: {
          reset: false,
          pan: false,
        },
        export: {
          csv: {
            headerCategory: 'Date',
            headerValue: 'Value',
            columnDelimiter: ';',
            // according to the documentation, this should be a function that takes a numeric timestamp, but it actually
            // takes ISO string, so we have to do this 'transformation' here
            dateFormatter: (timestamp: number) => dateFormatter(timestamp.toString()),
          },
        },
      },
    },
    series,
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      fontSize: '14px',
      markers: {
        width: 8,
        height: 8,
        radius: 4,
        offsetY: -1,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      min: 0,
      max: max => (max === 0 ? 10 : max + max * 0.1), // we want to show line even if the max data is 0
      decimalsInFloat: 1,
      labels: {
        formatter: value => `${i18n.number(value)}`,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 3,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      strokeDashArray: 4,
    },
  }

  const enhancedOptions = deepmerge(defaultOptions, customOptions)

  return (
    <Chart
      options={enhancedOptions}
      series={series}
      type={chartType}
      height={height ?? responsiveHeight}
    />
  )
}

export default GenericChart
