import React, { FC } from 'react'
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material'

import { HelpIcon } from '../../../icons/HelpIcon'
import { InfoIcon } from '../../../icons/InfoIcon'
import { StyledTooltip } from '../../Tooltip'

interface ITitleWithInfoIcon {
  title: string
  tooltipText: string
  isIconHidden?: boolean
  isHealpIcon?: boolean
  slotProps?: {
    title?: TypographyProps
    wrapper?: BoxProps
    iconWrapper?: BoxProps
  }
  icon?: React.ReactNode
}

export const InfoTooltipIconWithTitle: FC<ITitleWithInfoIcon> = ({
  title,
  tooltipText,
  slotProps,
  isIconHidden = false,
  isHealpIcon,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
      }}
      {...slotProps?.wrapper}
    >
      <Typography
        variant="h6"
        fontWeight={400}
        {...slotProps?.title}
      >
        {title}
      </Typography>
      {!isIconHidden && (
        <StyledTooltip title={tooltipText}>
          <Box
            sx={{
              display: 'flex',
              fontSize: '16px',
              cursor: 'pointer',
            }}
            {...slotProps?.iconWrapper}
          >
            {isHealpIcon ? <HelpIcon /> : <InfoIcon />}
          </Box>
        </StyledTooltip>
      )}
    </Box>
  )
}
