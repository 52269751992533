import React, { useMemo } from 'react'
import { Input, MenuItem, Select, styled } from '@mui/material'

import { DropdownOption } from '../../../Shared/types/types'
import { themeColors } from '../../../Shared/utils'

const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
})

const baseInputStyle = {
  display: 'block',
  backgroundColor: `${themeColors.white}`,
  border: 'none',
  outline: 'none',
  borderRadius: '4px',
  padding: '1em',
  fontSize: '13px',
  textAlign: 'left',
}

const StyledLabel = styled('label')({
  fontSize: '14px',
  display: 'block',
})

const Group = styled('div')({
  margin: '0em 1em 0 0',
  '& > label': {
    fontSize: '14px',
  },
  '& > div button': {
    ...baseInputStyle,
  },
})

const StyledInput = styled(Input)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBase.white,
  fontSize: '0.875em',
  padding: '1em',
  height: '48px',
  '> input': {
    padding: 0,
  },
  '&::before': {
    borderColor: 'rgb(220, 220, 220)',
  },
}))

const StyledDropdownSmall = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBase.white,
  width: '7.8em',
  padding: '1em',
  height: '48px',
  '&::before': {
    borderBottom: 'none',
  },
}))

export interface DurationSelection {
  duration: number
  durationUnit: string
}

interface DurationPickerProps {
  i18n: $I18FixMe
  selected: DurationSelection
  durationUnitOptions: DropdownOption[]
  onChange: (selection: DurationSelection) => void
}

const DurationPicker = ({ i18n, selected, durationUnitOptions, onChange }: DurationPickerProps): JSX.Element => {
  const { duration, durationUnit } = selected

  function _onChange(updatedSelection: Partial<DurationSelection>) {
    onChange({ ...selected, ...updatedSelection })
  }

  const max = useMemo(() => {
    // limit everything to 7 days in total
    switch (durationUnit) {
      case 'minutes':
        return 10080
      case 'hours':
        return 168
      case 'days':
        return 7
      default:
        return 99
    }
  }, [durationUnit])

  return (
    <Container>
      <Group>
        <StyledLabel>{i18n.text('rules.headers.value')}</StyledLabel>
        <StyledInput
          id="duration-input"
          key="threshold-rule-duration"
          type="number"
          value={duration}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => _onChange({ duration: +value })}
          sx={{
            maxWidth: '5.8em',
            display: 'inline-block',
          }}
        />
      </Group>
      <Group>
        <StyledLabel>{i18n.text('rules.headers.unit')}</StyledLabel>
        <StyledDropdownSmall
          id="duration-select"
          variant="standard"
          value={durationUnit}
          onChange={({ target: { value } }) => _onChange({ durationUnit: value as string })}
        >
          {durationUnitOptions.map(unit => (
            <MenuItem
              key={unit.key}
              value={unit.key}
            >
              {unit.option}
            </MenuItem>
          ))}
        </StyledDropdownSmall>
      </Group>
    </Container>
  )
}

export default DurationPicker
