import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router'
import { useEffect } from 'react'

import CompanyDetails from './CompanyDetails'
import Installation from './Installation'
import InviteMembers from './InviteMembers'
import ProgressBar from '../components/ProgressBar'
import TechnicalInfo from './TechnicalInfo'
import { ONBOARDING_STAGE_STORAGE, OnboardingWorkflow } from '../utils/constants'
import { StyledWrapper } from '../components/Wrapper'
import { browserStorage } from '../../Shared/utils'
import { useProgressContext } from '../context/ProgressContext'
import { OnboardingPath } from '../models'

export default function Layout() {
  const { path } = useRouteMatch()
  const { navigateStage } = useProgressContext()
  const { pathname } = useLocation()

  function handleStage() {
    const stageByURL = pathname.split('/')[2] as OnboardingPath
    if (OnboardingWorkflow.includes(stageByURL)) {
      navigateStage(stageByURL)
      return
    }

    const storedStage = browserStorage.get<OnboardingPath>(ONBOARDING_STAGE_STORAGE).value
    if (storedStage && OnboardingWorkflow.includes(storedStage)) {
      navigateStage(storedStage)
    }
  }

  useEffect(handleStage, [])

  return (
    <StyledWrapper>
      <ProgressBar />
      <Switch>
        <Route
          path={`${path}/${OnboardingPath.COMPANY_DETAILS}`}
          exact
          component={CompanyDetails}
        />
        <Route
          path={`${path}/${OnboardingPath.INVITE_MEMBERS}`}
          exact
          component={InviteMembers}
        />
        <Route
          path={`${path}/${OnboardingPath.TECHNICAL_INFO}`}
          exact
          component={TechnicalInfo}
        />
        <Route
          path={`${path}/${OnboardingPath.INSTALLATION}`}
          exact
          component={Installation}
        />
        <Redirect
          to={`${path}/${OnboardingPath.COMPANY_DETAILS}`}
        />
      </Switch>
    </StyledWrapper>
  )
}
