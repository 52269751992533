import {
  BalanceSeries,
  CompressedAirDataMap,
  IConsumptionPoint,
  PreviousState,
  SensorLocationSeries,
  SeriesDataValues,
  SeriesPoints,
  SwitchUnit,
} from '../types/compressed-air_types'
import {
  NEGATIVE_MIN_VALUE,
  getPreviousName,
  sortAccordingToCompareData,
  sortByAlphabeticalOrder,
  sortByTotalConsumption,
  transformToCFMIfCondition,
} from './utils'

function mapRangeAreaSeries(
  dataMap: CompressedAirDataMap,
  sensorLocationColors: { [key: string]: string },
  i18n: $I18FixMe,
  transformToCFM: (num: number | null) => number,
  propertyName: SeriesPoints
): ApexAxisChartSeries {
  if (!dataMap) {
    return []
  }

  return [...dataMap.entries()].map(([id, obj]: [string, SensorLocationSeries]) => {
    const getTransformedValue = (val: number | null) =>
      (val && val < 0) || !val ? transformToCFM(0) : transformToCFM(val)
    return {
      id: `${id}Range`,
      name: `${obj.name} (${i18n.text('air.graphs.min-max-range')})`,
      type: 'rangeArea',
      color: sensorLocationColors[id],
      data: obj[propertyName].reduce<SeriesDataValues[]>((acc, point) => {
        acc.push({
          x: point.time.toISO({ includeOffset: false }),
          y:
            point.min === null && point.max === 0
              ? null
              : [getTransformedValue(point.min), getTransformedValue(point.max)],
        })
        return acc
      }, []),
    }
  })
}

function getFlowData(arr: SensorLocationSeries['flowPoints'], transformToCFM: (num: number | null) => number) {
  return arr.reduce<SeriesDataValues[]>((acc, point) => {
    let formattedValue = null
    if (point.value !== null) {
      if (point.value > NEGATIVE_MIN_VALUE && point.value <= 0) {
        formattedValue = 0
      } else if (point.value >= NEGATIVE_MIN_VALUE) {
        formattedValue = point.value
      }
    }

    acc.push({
      x: point.time.toISO({ includeOffset: false }),
      y: formattedValue !== null ? transformToCFM(formattedValue) : null,
    })
    return acc
  }, [])
}

function getConsumptionData(
  arr: SensorLocationSeries['consumptionPoints'],
  transformToCFM: (num: number | null) => number
) {
  return arr.map((point: IConsumptionPoint) => ({
    x: point.time.toISO({ includeOffset: false }),
    y: transformToCFM(point.value),
  }))
}

function mapDataToApexSeries(
  dataMap: CompressedAirDataMap,
  sensorLocationColors: { [key: string]: string },
  propertyName: 'consumptionPoints' | SeriesPoints,
  switchUnit: SwitchUnit,
  i18n: $I18FixMe,
  previousState: PreviousState,
  needsRangeArea?: boolean
) {
  if (!dataMap) {
    return []
  }
  const transformToCFM = transformToCFMIfCondition(switchUnit.includes('cf'))
  const series: ApexAxisChartSeries = [...dataMap.entries()].map(([id, obj]: [string, SensorLocationSeries]) => {
    return {
      id: previousState === PreviousState.IS_PREVIOUS ? `${id}Previous` : id,
      name: getPreviousName(obj.name, previousState, i18n),
      group: previousState === PreviousState.IS_PREVIOUS ? 'previous' : 'actual',
      type: propertyName === 'consumptionPoints' ? 'column' : 'line',
      color: sensorLocationColors[id],
      data:
        propertyName !== 'consumptionPoints'
          ? getFlowData(obj[propertyName], transformToCFM)
          : getConsumptionData(obj[propertyName], transformToCFM),
    }
  })

  let mixedFlowSeries: ApexAxisChartSeries = []
  const shouldUseRangeArea = dataMap.size === 1 && needsRangeArea

  if (
    shouldUseRangeArea &&
    (propertyName === SeriesPoints.FLOW ||
      propertyName === SeriesPoints.TEMPERATURE ||
      propertyName === SeriesPoints.PRESSURE)
  ) {
    const rangeAreaSeries: ApexAxisChartSeries = mapRangeAreaSeries(
      dataMap,
      sensorLocationColors,
      i18n,
      transformToCFM,
      propertyName
    )
    mixedFlowSeries = series.concat(rangeAreaSeries)
  }

  return mixedFlowSeries.length > 0 ? mixedFlowSeries : sortByAlphabeticalOrder(series)
}

export const mapBalanceApexSeries = (
  dataMap: CompressedAirDataMap,
  sensorLocationColors: { [key: string]: string },
  switchUnit: SwitchUnit,
  ids?: string[]
) => {
  const transformToCFM = transformToCFMIfCondition(switchUnit.includes('cf'))
  const result: BalanceSeries = {
    ids: [],
    series: [],
    items: {},
    labels: [],
    colors: [],
  }

  if (!dataMap) {
    return result
  }

  const sortedDataMap = !ids
    ? sortByTotalConsumption(dataMap, transformToCFM)
    : sortAccordingToCompareData(dataMap, ids)

  sortedDataMap.forEach(([id, { name, consumptionPoints }]) => {
    result.ids.push(id)
    result.labels.push(name)
    result.colors.push(sensorLocationColors[id])
    const totalValue = consumptionPoints.reduce((acc, curr) => acc + transformToCFM(curr.value), 0)
    result.items[id] = totalValue
    result.series.push(totalValue)
  })

  if (result.series.every(value => value === 0)) {
    result.series = []
  }

  return result
}

export const mapApexSeries = (
  dataMap: CompressedAirDataMap,
  sensorLocationColors: { [key: string]: string },
  switchUnit: SwitchUnit,
  i18n: $I18FixMe,
  previousState: PreviousState,
  needsRangeArea?: boolean,
  ids?: string[]
): {
  consumption: ApexAxisChartSeries
  flow: ApexAxisChartSeries
  temperature: ApexAxisChartSeries
  pressure: ApexAxisChartSeries
  balance: BalanceSeries
} => {
  const flowSeries = mapDataToApexSeries(
    dataMap,
    sensorLocationColors,
    SeriesPoints.FLOW,
    switchUnit,
    i18n,
    previousState,
    needsRangeArea
  )
  const consumptionSeries = mapDataToApexSeries(
    dataMap,
    sensorLocationColors,
    'consumptionPoints',
    switchUnit,
    i18n,
    previousState,
    needsRangeArea
  )
  const balanceSeries: BalanceSeries = mapBalanceApexSeries(dataMap, sensorLocationColors, switchUnit, ids)
  const temperatureSeries = mapDataToApexSeries(
    dataMap,
    sensorLocationColors,
    SeriesPoints.TEMPERATURE,
    SwitchUnit.celsius,
    i18n,
    previousState,
    needsRangeArea
  )
  const pressureSeries = mapDataToApexSeries(
    dataMap,
    sensorLocationColors,
    SeriesPoints.PRESSURE,
    SwitchUnit.bar,
    i18n,
    previousState,
    needsRangeArea
  )
  return {
    consumption: consumptionSeries as ApexAxisChartSeries,
    flow: flowSeries as ApexAxisChartSeries,
    balance: balanceSeries,
    temperature: temperatureSeries as ApexAxisChartSeries,
    pressure: pressureSeries as ApexAxisChartSeries,
  }
}
