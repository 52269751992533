/**
 * @field internalUserId Refers to the hasura userId of the currently logged in user but userId is a reserved keyword in GA4; Not to be confused with isInternal
 * @field isInternal Refers to whether the currently logged in user is a Sensorfact employee; Not to be confused with internalUserId
 */
export interface DefaultEventParams {
  timestamp?: number
  pathname?: string
  internalUserId?: string | null
  customerId?: string | null
  isInternal?: boolean
}
export enum AnalyticsEventTypes {
  TRACKED_DOWNLOAD = 'tracked_download',
  TRACKED_PAGE_VIEW = 'tracked_page_view',
  TRACKED_BUTTON_CLICK = 'tracked_button_click',
  TRACKED_INPUT_CHANGED = 'tracked_input_changed',
  TRACKED_ASSET_SELECT = 'tracked_asset_select',
  TRACKED_BUTTON_HOVER = 'tracked_button_hover',
}

export enum AnalyticsPropositionType {
  APP = 'webapp',
  PDM = 'pdm',
  COMPRESSED_AIR = 'compressed-air',
  GAS = 'gas',
  WATER = 'water',
  ELECTRICITY = 'electricity',
  OEE = 'oee',
}

export enum AnalyticsIntent {
  VISIT_SHOP = 'visit_shop',
  CREATE_ALERT = 'create_alert',
  MUTE_ALERTS = 'mute_alerts',
  CHECK_ALERTS_POPUP = 'check_alerts_popup',
  CHECK_ALL_ALERTS = 'check_all_alerts',
  EDIT_ALERT = 'edit_alert',
  UPLOAD_ASSET_IMAGE = 'upload_asset_image',
  UPLOAD_LOCATION_IMAGE = 'upload_location_image',
  DELETE_ASSET_IMAGE = 'delete_asset_image',
  DELETE_LOCATION_IMAGE = 'delete_location_image',
  SHOW_ANNOTATIONS = 'show_annotations',
  DOWNLOAD_CSV = 'download_csv',
  DOWNLOAD_PNG = 'download_png',
  DOWNLOAD_REPORT = 'download_report',
  DOWNLOAD_SVG = 'download_svg',
  ZOOM_GRAPH = 'zoom_graph',
  CHANGE_GRAPH_UNIT = 'change_graph_unit',
  READ_INFO = 'read_info',
  SELECT_ASSET = 'select_asset',
  SELECT_SENSOR_LOCATION = 'select_sensor_location',
  EXPAND_ASSET_DETAILS = 'expand_asset_details',
  EXPAND_LOCATION_DETAILS = 'expand_location_details',
  SEARCH = 'search',
  SORT = 'sort',
  TOOGLE_TOOLBAR = 'toogle_toolbar',
  SWITCH_EM_GROUP_ASSET_TAB = 'switch_em_group_asset_tab',
  TOGGLE_STANDBY_WASTE = 'toggle_standby_waste',
  TOGGLE_TOTAL = 'toggle_total',
  TOGGLE_MEAN = 'toggle_mean',
  TR_START_NEW_REPORT = 'tr_start_new_report',
  TR_EDIT_REPORT = 'tr_edit_report',
  TR_DELETE_REPORT = 'tr_delete_report',
  TR_CONFIRM_DELETE_REPORT = 'tr_confirm_delete_report',
  TR_CANCEL_DELETE_REPORT = 'tr_cancel_delete_report',
  TR_DUPLICATE_REPORT = 'tr_duplicate_report',
  TR_ENABLE_REPORT = 'tr_enable_report',
  TR_DISABLE_REPORT = 'tr_disable_report',
  TR_SAVE_NEW_REPORT_GENERAL = 'tr_save_new_report_general',
  TR_SAVE_EXISTING_REPORT_GENERAL = 'tr_save_existing_report_general',
  TR_START_NEW_MODULE = 'tr_start_new_module',
  TR_PREVIEW_REPORT = 'tr_preview_report',
  TR_DUPLICATE_MODULE = 'tr_duplicate_module',
  TR_DELETE_MODULE = 'tr_delete_module',
  TR_START_EDIT_MODULE = 'tr_start_edit_module',
  TR_CONFIRM_DELETE_MODULE = 'tr_confirm_delete_module',
  TR_CANCEL_DELETE_MODULE = 'tr_cancel_delete_module',
  TR_CANCEL_EDIT_EXISTING_MODULE = 'tr_cancel_edit_existing_module',
  TR_CANCEL_EDIT_NEW_MODULE = 'tr_cancel_edit_new_module',
  TR_DISABLE_MODULE = 'tr_disable_module',
  TR_ENABLE_MODULE = 'tr_enable_module',
  TR_SAVE_MODULE = 'tr_save_module',
  TR_BACK_TO_OVERVIEW = 'tr_back_to_overview',
  TR_CHANGE_MODULE_ORDER = 'tr_change_module_order',
  TR_EXPAND_SUPPORT = 'tr_expand_support',
  TR_OPEN_KNOWLEDGE_BASE = 'tr_open_knowledge_base',
}

export const downloadTargets = ['csv', 'png', 'report'] as const

export interface DownloadEventParams extends DefaultEventParams {
  target: (typeof downloadTargets)[number]
}

export interface DownloadCSV extends DownloadEventParams {
  target: 'csv'
}
export interface DownloadPNG extends DownloadEventParams {
  target: 'png'
}

export interface MuteAlertsEvent extends DefaultEventParams {
  intent: AnalyticsIntent.MUTE_ALERTS
}

export interface ReadInfoEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.READ_INFO
}

export interface SortEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.SORT
}

export interface SearchEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.SEARCH
}

export interface SelectSensorLocationEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.SELECT_SENSOR_LOCATION
}

export interface SelectAssetEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.SELECT_ASSET
}

export interface UploadImageEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.UPLOAD_ASSET_IMAGE | AnalyticsIntent.UPLOAD_LOCATION_IMAGE
}

export interface DeleteImageEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.DELETE_ASSET_IMAGE | AnalyticsIntent.DELETE_LOCATION_IMAGE
}

export interface ShowAnnotationsEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.SHOW_ANNOTATIONS
}

export interface EditAlert extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.EDIT_ALERT
}

export interface ChangeGraphUnitEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.CHANGE_GRAPH_UNIT
}

export interface DownloadReport extends DownloadEventParams {
  target: 'report'
  origin: 'webapp' | 'email'
  reportType: 'tracking' | 'insights'
  reportId: string
}

export interface CheckAllAlerts extends DefaultEventParams {
  proposition: AnalyticsPropositionType.APP
  intent: AnalyticsIntent.CHECK_ALL_ALERTS
  target: 'all_alerts_link_popup'
}

export interface CreateAlertEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.CREATE_ALERT
}

export interface ShowAssetLocationDetailsEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.EXPAND_ASSET_DETAILS | AnalyticsIntent.EXPAND_LOCATION_DETAILS
}

export interface VisitShop extends DefaultEventParams {
  proposition: AnalyticsPropositionType.APP
  intent: AnalyticsIntent.VISIT_SHOP
  target: 'nav_shop_button'
}

export interface CheckAlertsPopup extends DefaultEventParams {
  proposition: AnalyticsPropositionType.APP
  intent: AnalyticsIntent.CHECK_ALERTS_POPUP
  target: 'nav_alerts_button'
}

export interface DownloadGraphEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.DOWNLOAD_CSV | AnalyticsIntent.DOWNLOAD_PNG | AnalyticsIntent.DOWNLOAD_SVG
}

export interface ZoomGraphEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.ZOOM_GRAPH
}

// **** CUSToM ******

export interface CreateAlertEMOEE extends CreateAlertEvent {
  proposition: AnalyticsPropositionType
  intent: AnalyticsIntent.CREATE_ALERT
  target: 'create_standby_alert_rule_form' | 'create_rule_based_alert_form'
}
