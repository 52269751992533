import { Box, Button, List, ListItem, styled, useTheme } from '@mui/material'
import { FC } from 'react'

import { BellIcon, FileCheckIcon, FileIcon, LightbulbIcon, LineChartUpIcon } from '../../../../Shared/components/icons'
import { JSON } from '../../../../Shared/types/types'
import {
  RegularLightMdText,
  SemiboldBrandLightMdText,
  SemiboldBrandMdDisplayText,
  SemiboldLightMdText,
  SemiboldLightXlText,
} from '../../Common/v1/index'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

export const MainInfoBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  width: '45%',
  alignItems: 'flex-start',
  gap: '1.25rem',
}))

export const TotalSavingsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0.75rem',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
  borderRadius: '0.625rem',
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  background: theme.palette.SFIBase.white,
  width: 'fit-content',
}))

export const BulletPointBox = styled(Box)(({ theme }) => ({
  marginLeft: '2rem',
}))

export const BulletPointText = styled(ListItem)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
  display: 'list-item',
  padding: '0rem ',
  fontFamily: 'Inter',
}))

export const BulletPointList = styled(List)(({ theme }) => ({
  listStyleType: 'disc',
  pl: 2,
  listStylePosition: 'outside',
}))

export const SavingsNumbersBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '0.5rem',
  flexWrap: 'wrap',
}))

export const SavingsNumberUnitBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '0.188rem',
}))

export const SupportingTextIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.75rem',
}))

export const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '2rem',
  height: '2rem',
  padding: '0.25rem',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0.375rem',
  border: `1px solid  ${theme.palette.SFIGreyLight[300]}`,
  background: theme.palette.SFIBase.white,
  boxShadow: '0 0.063rem 0.125rem 0 rgba(16, 24, 40, 0.05)',
}))

export const TipBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  padding: '0.625rem',
  alignItems: 'flex-start',
  gap: '0.625rem',
  borderRadius: '0.625rem',
  border: `1px solid  ${theme.palette.SFIBrand[600]}`,
  background: theme.palette.SFIBrand[50],
}))

export const TipIconHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const MoreInfoButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.25rem',
  textDecorationLine: 'underline',
  textTransform: 'none',
  padding: '0',
  marginLeft: '-2px',
}))

export const MoreInfoBox = styled(Box)(({ theme }) => ({
  gap: '0.5rem',
}))

interface SavingsMetricProps {
  metric: number
  userLocaleFormat: string
  unit: string
}

export const SavingsMetric: FC<SavingsMetricProps> = ({ metric, userLocaleFormat, unit }) => {
  return (
    <SavingsNumberUnitBox>
      <SemiboldBrandMdDisplayText>
        {metric.toLocaleString(userLocaleFormat, { maximumFractionDigits: 0 })}
      </SemiboldBrandMdDisplayText>
      <SemiboldBrandLightMdText>{unit}</SemiboldBrandLightMdText>
    </SavingsNumberUnitBox>
  )
}

interface BulletIconBoxProps {
  templateVersion: string
  textArray: number[]
  savingName: string
  version: string
  vars: JSON
  type: string
}

export const BulletIconBox: FC<BulletIconBoxProps> = ({
  templateVersion,
  textArray,
  savingName,
  version,
  vars,
  type,
}) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  return (
    <Box>
      <SupportingTextIconBox>
        <IconBox>
          {type === 'insights' && <LightbulbIcon sx={{ fontSize: 'medium', color: theme.palette.SFIGreyLight[700] }} />}
          {type === 'key-actions' && (
            <FileCheckIcon sx={{ fontSize: 'medium', color: theme.palette.SFIGreyLight[700] }} />
          )}
        </IconBox>
        <SemiboldLightXlText>{i18n.text(`scan.savings.${templateVersion}.${type}`)}</SemiboldLightXlText>
      </SupportingTextIconBox>
      <BulletPointBox>
        <BulletPointList>
          {textArray.map((text, index) => (
            <BulletPointText key={index}>
              {i18n.text(`scan.savings.${savingName}.${version}.${type}.${text}`, vars)}
            </BulletPointText>
          ))}
        </BulletPointList>
      </BulletPointBox>
    </Box>
  )
}

interface TipProps {
  webappTip: boolean
  alertTip: boolean
  trTip: boolean
  templateVersion: string
  savingName: string
  version: string
}

export const Tip: FC<TipProps> = ({ webappTip, alertTip, trTip, templateVersion, savingName, version }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  return (
    <TipBox>
      <TipIconHeaderBox>
        {webappTip && <LineChartUpIcon sx={{ color: theme.palette.SFIBrand[800] }} />}
        {alertTip && <BellIcon sx={{ color: theme.palette.SFIBrand[800] }} />}
        {trTip && <FileIcon sx={{ color: theme.palette.SFIBrand[800] }} />}
        <SemiboldLightMdText>{i18n.text(`scan.savings.${templateVersion}.tip`)}</SemiboldLightMdText>
      </TipIconHeaderBox>
      <RegularLightMdText>{i18n.text(`scan.savings.${savingName}.${version}.tip`)}</RegularLightMdText>
    </TipBox>
  )
}
