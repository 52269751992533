import Grid3x3Icon from '@mui/icons-material/Grid3x3'
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent'
import React, { FC, useRef, useState } from 'react'
import { Bolt, BorderClear, SettingsBackupRestore, Speed, SvgIconComponent } from '@mui/icons-material'
import { Box, Divider, Typography, styled } from '@mui/material'
import { useParams } from 'react-router'

import Carousel from '../../../Shared/components/MUIComponents/Carousel/Carousel'
import ConfigureAssetInformation from './ConfigureAssetInformation/ConfigureAssetInformation'
import Lightbox from '../../../Shared/components/MUIComponents/Lightbox/Lightbox'
import MUILoader from '../../../Shared/components/MUIComponents/Loader'
import SelectableImageDialog from './SelectableImageDialog'
import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import {
  AssetType,
  ImageInput,
  ProbeInfoFragment,
  UserLocale_Enum,
  useAssetInfoQuery,
  useUpdateMyAssetsImagesMutation,
} from '../../../Shared/graphql/codegen'
import { Error } from '../../../Shared/components/MUIComponents/Error'
import { ID } from '../../../Shared/types/types'
import { WidgetResult } from '../../../Shared/components/UploadWidget/types'
import { WidgetWrapper } from './WidgetWrapper'
import {
  sendDeleteSensorLocationImageEventPDM,
  sendUploadSensorLocationImageEventPDM,
} from '../../utils/analyticsEvents'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { vibrationLocationComponentTypeNames } from './constants'
import { vibrationSensorLocationInfoSchema } from './types'

type Probe = ProbeInfoFragment
type ImageJSON = {
  url: string
}

type LineProps = {
  icon: SvgIconComponent
  label: string
  value?: number | string | null
  component?: React.ReactNode
}
export const Line: FC<LineProps> = ({ icon: Icon, label, value, component }) => {
  const { i18n } = useI18nContext()
  return (
    <>
      <Box
        display="flex"
        sx={
          component
            ? {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }
            : undefined
        }
        padding={3}
        paddingLeft={0}
      >
        <Icon sx={theme => ({ color: theme.palette.SFIGreyLight[600], fontSize: '1.75rem', marginRight: '50px' })} />
        <Typography
          fontWeight={500}
          width="200px"
          mr="50px"
        >
          {label}
        </Typography>
        {component ? component : <Typography>{value || i18n.text('pdm.asset.details.not-set')}</Typography>}
      </Box>
      <Divider />
    </>
  )
}

const getProbeLocationImages = (probe: Probe) => {
  let res: string[] = []
  if (probe?.images && probe?.images.length) {
    res = [...res, ...probe.images.map((img: ImageJSON) => img.url)]
  }
  return res
}

const ToolBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1rem',
  padding: '0.5rem',
  '&  >*': {
    marginRight: '1rem',
  },
  '& *:last-child': {
    marginRight: 0,
  },
}))

const InternalUserTools = styled(ToolBox)(({ theme }) => ({
  justifyContent: 'flex-start',
  background: theme.palette.SFIGreyLight[100],
}))

const AssetInfo = () => {
  const { i18n, userLocale: locale } = useI18nContext()
  const [modalOpen, setModal] = useState('')
  const { sendEvent } = useAnalytics()
  const { machineId, probeId } = useParams<{ machineId: string; probeId: string }>()
  const unsavedImagesDataRef = useRef<string[]>([])
  const [selectedImages, setSelectedImages] = useState<string[]>([])
  const [imageDialogOpen, setImageDialogOpen] = useState(false)

  const { isInternalUser } = useCurrentUser()

  // Temporary fix for locale until we have all the languages supported in the BE.
  const localeTempValue = (locale === 'fr_FR' || locale === 'es_ES' ? UserLocale_Enum.EnGb : locale).substring(0, 2)

  const {
    data,
    loading,
    error,
    refetch: refetchAssets,
  } = useAssetInfoQuery({
    variables: { machineId, probeId, locale: localeTempValue },
  })

  const filteredImageUrls = data?.probe?.images?.filter(
    (img: { url?: string; name?: string }) => !selectedImages.includes(img.url as string)
  )

  const [updateMyAssetsImagesMutation, { loading: saveImagesLoader }] = useUpdateMyAssetsImagesMutation({
    onCompleted: async () => {
      await refetchAssets()
    },
    onError: error => console.log('Error', 'Something went wrong while saving changes.', error),
  })

  // Toggle selectable images for deletion from the asset.
  const toggleSelectableImages = (image: string) => {
    if (selectedImages.includes(image)) {
      setSelectedImages(selectedImages.filter(selectedImage => selectedImage !== image))
    } else {
      setSelectedImages([...selectedImages, image])
    }
  }

  // Function for deleting all the selected asset images.
  const deleteAssetImages = async () => {
    await updateMyAssetsImagesMutation({
      variables: {
        assets: {
          id: probeId as ID,
          images: filteredImageUrls,
        },
      },
    })
    unsavedImagesDataRef.current = []
    await refetchAssets()
    sendDeleteSensorLocationImageEventPDM(sendEvent)
    setImageDialogOpen(false)
  }

  // Callback function that is called every time an image is uploaded to cloudinary.
  const onCloudinaryImageUploaded = (response: WidgetResult) => {
    const cloudinaryImageUrl: string = response.info.url
    if (cloudinaryImageUrl) {
      unsavedImagesDataRef.current.push(cloudinaryImageUrl)
    } else {
      console.log('Error getting cloudinary image', response)
    }
    sendUploadSensorLocationImageEventPDM(sendEvent)
  }

  // Callback function that is called when the cloudinary widget is closed.
  const onCloudinaryWidgetClosed = async () => {
    if (unsavedImagesDataRef.current.length > 0) {
      const images: ImageInput[] = [...allImages, ...unsavedImagesDataRef.current].map((img: string) => {
        return {
          url: img,
        }
      })
      await updateMyAssetsImagesMutation({
        variables: {
          assets: {
            id: probeId as ID,
            images: images,
          },
        },
      })
      unsavedImagesDataRef.current = []
    }
  }

  const handleAssetInfoUpdate = async (assetInforUpdate: boolean) => {
    if (assetInforUpdate) {
      await refetchAssets()
    }
  }

  if (loading) {
    return <MUILoader />
  }
  if (error || !data) {
    return <Error />
  }

  const { machine, probe, assetTypes } = data

  const sensorLocationParseResult = vibrationSensorLocationInfoSchema.safeParse(data.sensorLocation)
  const sensorLocation = sensorLocationParseResult.success ? sensorLocationParseResult.data : null

  if (!machine || !probe || !sensorLocation) {
    return <Error />
  }

  const allImages = getProbeLocationImages(probe)

  const assetImages = (): string[] => {
    if (allImages.length === 0) {
      return ['/assets/img-placeholder.png']
    } else {
      return allImages
    }
  }

  const handleClose = () => setModal('')
  const handleOpen = (imageUrl: string) => setModal(imageUrl)

  return (
    <Box padding={4}>
      {isInternalUser() && (
        <InternalUserTools>
          <Typography
            variant="subtitle1"
            sx={theme => ({
              color: theme.palette.SFIGreyLight[700],
              fontWeight: 400,
              marginRight: '1rem',
            })}
          >
            Internal tools
          </Typography>
          <ConfigureAssetInformation
            machine={machine}
            sensorLocation={sensorLocation}
            assetTypes={assetTypes as AssetType[]}
            onClose={handleAssetInfoUpdate}
          />
        </InternalUserTools>
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(275px, 1fr))',
          gridTemplateRows: 'repeat(auto-fit, 275px)',
          rowGap: '2rem',
          marginBottom: 2,
          '@media screen and (max-width: 993px)': {
            marginBottom: 0,
          },
        }}
      >
        <Box>
          {saveImagesLoader ? (
            <MUILoader margin="3rem 0 0 -3rem" />
          ) : (
            <Carousel
              imageProps={{
                sx: { width: '100%', height: '275px', objectFit: 'cover', cursor: 'pointer' },
              }}
              images={assetImages()}
              onClick={handleOpen}
            />
          )}
        </Box>
        <Lightbox
          open={modalOpen}
          handleClose={handleClose}
        />
        <Box
          sx={{
            position: 'relative',
            marginLeft: 4,
            '@media screen and (max-width: 993px)': {
              padding: '50px',
            },
          }}
        >
          <Typography fontWeight={500}>{i18n.text('pdm.asset.details.machine.name')}</Typography>
          <Typography>
            {machine.name} | {probe.name}
          </Typography>
          <Typography
            mt={4}
            fontWeight={500}
          >
            {i18n.text('pdm.asset.details.asset.type')}
          </Typography>
          <Typography>{machine?.assetType?.name[localeTempValue] || i18n.text('pdm.asset.details.not-set')}</Typography>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
            }}
          >
            <WidgetWrapper
              onCloudinaryWidgetClosed={onCloudinaryWidgetClosed}
              onCloudinaryImageUploaded={onCloudinaryImageUploaded}
            />
            <SelectableImageDialog
              data={data}
              selectedImages={selectedImages}
              imageDialogOpen={imageDialogOpen}
              setImageDialog={setImageDialogOpen}
              deleteAssetImages={deleteAssetImages}
              toggleSelectableImages={toggleSelectableImages}
            />
          </Box>
        </Box>
      </Box>
      <Line
        icon={Grid3x3Icon}
        label={i18n.text('pdm.asset.details.sensor.id')}
        value={probe.sensors.flatMap(id => id.externalId).toString() || i18n.text('pdm.asset.details.not-set')}
      />
      <Line
        icon={SettingsInputComponentIcon}
        label={i18n.text('pdm.asset.details.component.type')}
        value={
          (sensorLocation.componentType && vibrationLocationComponentTypeNames[sensorLocation.componentType]) ||
          i18n.text('pdm.asset.details.not-set')
        }
      />
      <Line
        icon={Speed}
        label={i18n.text('pdm.asset.details.machine.speed-control')}
        value={sensorLocation.speedControl || i18n.text('pdm.asset.details.not-set')}
      />
      <Line
        icon={Speed}
        label={i18n.text('pdm.asset.details.machine.set-speed')}
        value={sensorLocation.setSpeed || i18n.text('pdm.asset.details.not-set')}
      />
      <Line
        icon={SettingsBackupRestore}
        label={i18n.text('pdm.asset.details.machine.rpm')}
        value={sensorLocation.rpm || i18n.text('pdm.asset.details.not-set')}
      />
      <Line
        icon={BorderClear}
        label={i18n.text('pdm.asset.details.machine.foundation-type')}
        value={sensorLocation.foundation || i18n.text('pdm.asset.details.not-set')}
      />
      <Line
        icon={Bolt}
        label={i18n.text('pdm.asset.details.machine.machine-power') + ' (kW)'}
        value={sensorLocation.machinePower || i18n.text('pdm.asset.details.not-set')}
      />
    </Box>
  )
}

export default AssetInfo
