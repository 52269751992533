import { Box, Input, List, ListItemButton, Typography, styled } from '@mui/material'
import { FC, useMemo, useState } from 'react'

import { CurrentCustomerFragment } from '../../../graphql/codegen'
import { CurrentUser } from '../../../types/types'
import { useI18nContext } from '../../../contexts/i18nContext/I18nContext'

const CustomerButton = styled(ListItemButton)(({ theme }) => ({
  '&.active': {
    backgroundColor: theme.palette.SFIGreyLight[950],
    color: theme.palette.SFIGreyLight[100],
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
})) as typeof ListItemButton

type CustomerSelectorProps = {
  customers: CurrentUser['customers']
  selectedCustomer: CurrentCustomerFragment
  switchCustomer: (id: string) => void
}

export const CustomerSelector: FC<CustomerSelectorProps> = ({ customers, selectedCustomer, switchCustomer }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setLoading] = useState(false)
  const selectedCustomerId = selectedCustomer.id
  const filteredCustomers = useMemo(
    () =>
      customers
        .filter(c => c.name.toLowerCase().includes(searchTerm))
        .sort(({ name: aName, id: aId }, { name: bName, id: bId }) => {
          if (aId === selectedCustomerId) return -1
          if (bId === selectedCustomerId) return 1
          if (aName.toLowerCase() < bName.toLowerCase()) return -1
          return 0
        }),
    [customers, searchTerm, selectedCustomerId]
  )
  const handleCustomerClick = (id: string) => {
    if (id === selectedCustomerId) return
    setLoading(true)
    switchCustomer(id)
  }
  const { i18n } = useI18nContext()
  return (
    <>
      <Box>
        {isLoading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pl: 2, pr: 2, pb: 0 }}>
            <Typography>Switching site...</Typography>
          </Box>
        ) : (
          <>
            <Input
              autoFocus={true}
              placeholder={i18n.text('customer-select.select-customer')}
              id="customer-search"
              value={searchTerm}
              autoComplete="off"
              onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
              sx={{ width: '100%' }}
            />

            <List sx={{ maxHeight: '400px', overflow: 'auto', minWidth: '200px' }}>
              {filteredCustomers.map(customer => (
                <CustomerButton
                  className={customer.id === selectedCustomerId ? 'active' : ''}
                  key={customer.id}
                  onClick={() => handleCustomerClick(customer.id)}
                >
                  {customer.name}
                </CustomerButton>
              ))}
            </List>
          </>
        )}
      </Box>
    </>
  )
}
