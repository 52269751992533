import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { ExpandMore } from '@mui/icons-material'
import { FC, useEffect, useState } from 'react'
import { Menu, MenuItem, useTheme } from '@mui/material'

import { BackGroundGraphBox, GraphBox, GraphHeaderBox, GraphMenuButton, StyledDivider } from '../../Common/v1/index'
import { PressureReductionGraphProps } from '../../../types'
import { StyledToggleButtonGroup } from '../../../../Shared/components/MUIComponents/update/StyledToggleButtonGroup'
import {
  getGridOptions,
  getPlotOptionsBarChart,
  useAnnotationLabelOptions,
  useAxisTitleStyleOptions,
  useDataLabelsOptions,
  useXAxisLabelStyleOptions,
  useYAxisLabelStyleOptions,
  useYaxisAnnotationsAverageLineOptions,
} from '../../Common/v1/ApexOptions'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

enum Quantity {
  ENERGY_SAVED = 'energy_saved',
  COSTS_SAVED = 'costs_saved',
  EMISSIONS_SAVED = 'emissions_saved',
}

enum Unit {
  ENERGY = 'kWh',
  COSTS = '€',
  EMISSIONS = 'kg CO₂e',
}

export const PressureReductionGraph: FC<PressureReductionGraphProps> = ({ input, chartWidth }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const [graphQuantity, setGraphQuantity] = useState(Quantity.ENERGY_SAVED)
  const [graphUnit, setGraphUnit] = useState(Unit.ENERGY)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const assetsArray: string[] = [...new Set(input.savings_outputs.map(item => item.unit))]
  const eventsArray: string[] = [...new Set(input.savings_outputs.map(item => item.event_id))]
  const [asset, setAsset] = useState(assetsArray[0])
  const [event, setEvent] = useState(eventsArray[0])
  const assetSelectOpen = Boolean(anchorEl)
  const handleClickAssetSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseAssetSelect = () => {
    setAnchorEl(null)
  }
  const handleSelectAsset = (event: string) => {
    setEvent(event)
    setAsset(input.savings_outputs?.find(item => item.event_id === event)?.unit || '1')
    setAnchorEl(null)
  }
  const handleGraphQuantitySwitch = setGraphQuantity as React.Dispatch<React.SetStateAction<string>>

  useEffect(() => {
    if (graphQuantity === Quantity.ENERGY_SAVED) {
      setGraphUnit(Unit.ENERGY)
    } else if (graphQuantity === Quantity.COSTS_SAVED) {
      setGraphUnit(Unit.COSTS)
    } else {
      setGraphUnit(Unit.EMISSIONS)
    }
  }, [graphQuantity])

  const rawDataPerAsset = input.savings_outputs.filter(item => item.unit === asset)

  const energySavedArray = rawDataPerAsset.map(item => {
    return item.energy_saved
  })

  const costSavedArray = rawDataPerAsset.map(item => {
    return item.costs_saved
  })
  const emissionsSavedArray = rawDataPerAsset.map(item => {
    return item.emissions_saved
  })

  const categoriesArray = rawDataPerAsset.map(item => {
    return item.scenario
  })

  const averageLineValue = () => {
    if (graphQuantity === Quantity.ENERGY_SAVED) {
      return input.savings_outputs_averages.find(item => item.event_id === event)?.energy_saved.toFixed(0)
    } else if (graphQuantity === Quantity.COSTS_SAVED) {
      return input.savings_outputs_averages.find(item => item.event_id === event)?.costs_saved.toFixed(0)
    } else {
      return input.savings_outputs_averages.find(item => item.event_id === event)?.emissions_saved.toFixed(0)
    }
  }
  const dataSeries = () => {
    if (graphQuantity === Quantity.ENERGY_SAVED) {
      return [
        {
          name: 'Saving (kWh)',
          data: energySavedArray,
        },
      ]
    } else if (graphQuantity === Quantity.EMISSIONS_SAVED) {
      return [
        {
          name: 'Saving (kg CO₂e)',
          data: emissionsSavedArray,
        },
      ]
    } else {
      return [
        {
          name: 'Saving (€)',
          data: costSavedArray,
        },
      ]
    }
  }

  const annotationLabelOptions = useAnnotationLabelOptions(
    i18n.text('scan.savings.pressure_reduction.v1.graph.average') + ` ${averageLineValue()} ${graphUnit}`,
    -8,
    25,
    'right',
    'end'
  )
  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },

    plotOptions: getPlotOptionsBarChart(),
    dataLabels: useDataLabelsOptions(),
    annotations: {
      yaxis: useYaxisAnnotationsAverageLineOptions(averageLineValue(), annotationLabelOptions),
    },
    xaxis: {
      title: {
        text: i18n.text('scan.savings.pressure_reduction.v1.graph.xaxis-label'),
        style: useAxisTitleStyleOptions(),
      },
      categories: categoriesArray,
      labels: useXAxisLabelStyleOptions(),

      axisBorder: { show: false },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: i18n.text('scan.savings.pressure_reduction.v1.graph.yaxis-label') + ` (${graphUnit})`,
        style: useAxisTitleStyleOptions(),
      },
      labels: useYAxisLabelStyleOptions(),
    },
    fill: {
      opacity: 1,
      colors: ['#4793FF', '#FDB022', '#17B26A'], //todo need more colors in case there are
    },
    legend: {
      show: false,
    },
    grid: getGridOptions(),
  }
  return (
    <BackGroundGraphBox>
      <GraphBox id="abc">
        <GraphHeaderBox>
          <GraphMenuButton
            disableRipple
            onClick={handleClickAssetSelect}
          >
            {asset} <ExpandMore />
          </GraphMenuButton>
          <Menu
            open={assetSelectOpen}
            onClose={handleCloseAssetSelect}
            anchorEl={anchorEl}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {eventsArray.map((event, index) => (
              <MenuItem
                onClick={() => handleSelectAsset(event)}
                key={index}
              >
                {input.savings_outputs.find(item => item.event_id === event)?.unit}
              </MenuItem>
            ))}
          </Menu>
          <StyledToggleButtonGroup
            items={[
              { value: Quantity.EMISSIONS_SAVED, title: 'CO₂e' },
              { value: Quantity.ENERGY_SAVED, title: 'kwh' },
              { value: Quantity.COSTS_SAVED, title: '€' },
            ]}
            selectedItem={graphQuantity}
            onChange={handleGraphQuantitySwitch}
          />
        </GraphHeaderBox>
        <StyledDivider sx={{ margin: '0.625rem -0.625rem 0 -0.625rem' }} />
        <ReactApexChart
          options={options}
          series={dataSeries()}
          type="bar"
          width={chartWidth}
        />
      </GraphBox>
    </BackGroundGraphBox>
  )
}
