import createReducer from 'create-reducer-map'
import { Map, fromJS } from 'immutable'

import { ACCESS_TOKEN_RECEIVED, GET_ACCESS_TOKEN } from './constants'

const state = {
  test: 0,
  error: null,
  pending: false,
}

export type LoginPageState = Map<keyof typeof state, $TSFixMe>

export const initialState = fromJS(state)

export default createReducer<LoginPageState>(initialState, {
  [GET_ACCESS_TOKEN]: state =>
    state.merge({
      error: null,
      pending: true,
    }),
  [ACCESS_TOKEN_RECEIVED]: {
    next: state =>
      state.merge({
        error: null,
        pending: false,
      }),
    throw: (state, { error }: $TSFixMe) =>
      state.merge({
        error: error?.message,
        pending: false,
      }),
  },
})
