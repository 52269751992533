const KILO = 1000
const MEGA = KILO * 1000
const GIGA = MEGA * 1000
const TERA = GIGA * 1000

const magnitudes = [
  { unit: 'W', decimal: 1 },
  { unit: 'kW', decimal: KILO },
  { unit: 'MW', decimal: MEGA },
  { unit: 'GW', decimal: GIGA },
  { unit: 'TW', decimal: TERA },
]

/**
 * getPowerOrderOfMagnitude - Returns the order of magnitude info to convert measurement to that unit
 *
 * @param {number} n - Value you need to order of
 * @returns {object} - The order of magitude containing `unit` and `decimal` to devide with.
 */
export function getPowerOrderOfMagnitude(n: number, isEnergy?: boolean) {
  let order = Math.floor(Math.log10(n) / 3)

  // We still want to show kW up to 9999 kW, and switch to MW at 10 MW
  if (n >= KILO && n < 10 * MEGA) {
    order = 1
  }

  const orderIndex = order >= 0 ? order : 0

  const provisional = { ...magnitudes[orderIndex] }
  if (isEnergy) {
    provisional.unit += 'h'
  }
  return provisional
}
