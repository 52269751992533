// Some of these types are doubled in the Energy Prop Containers
import { DateTime } from 'luxon'
import type { Mutable } from 'utility-types'

import { AnalysisAssetFragment, AnalysisAssetsQuery } from '../graphql/codegen'
import type { getMarkerShapeByCommodityType } from '../utils/analysis_utils'

export const AnalysisTypes = ['power', 'volume', 'energy', 'energy-balance', 'energy-by-unit'] as const
export type AnalysisType = (typeof AnalysisTypes)[number]

export function isAnalysisType(value?: string): value is AnalysisType {
  return AnalysisTypes.includes(value as AnalysisType)
}

export type AssetLevel0 = NonNullable<AnalysisAssetsQuery['myOrg']>['assets'][number]
export type FullGroupAsset = Mutable<AssetLevel0>
export type AssetLevel1 = AssetLevel0['assets'][number]
export type AssetLevel2 = AssetLevel1['assets'][number]
export type Asset = AssetLevel0 | AssetLevel1 | AssetLevel2

export type LocalAsset = Mutable<AnalysisAssetFragment> & {
  color: string
  markerShape?: ReturnType<typeof getMarkerShapeByCommodityType>
  assets: LocalAsset[]
}

export type DateRange = {
  startDate: DateTime
  endDate: DateTime
}

export type YAxisUnits = 'energy' | 'cost'
