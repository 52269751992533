import { DateTime, Interval } from 'luxon'
import { useCallback } from 'react'

import { DateRange } from '../../Shared/types/analysis_types'
import { StartEndDatesValues } from '../../Shared/components/MUIComponents/update/MuiDateTimeRangePickerControlled/types'
import { ZoomGraphCA } from '../utils/analyticsTypes'
import { sendCAZoomEvent } from '../utils/analyticsEvents'

interface IOnZoomChangeArgs {
  fromToValues: StartEndDatesValues
  previousPeriodFromToValues?: StartEndDatesValues
  sendEvent: (name: string, params?: ZoomGraphCA) => void
  handleDatesChange: (dateRange: DateRange, zooming?: boolean) => void
  handleCompareDatesChange?: (dateRange: StartEndDatesValues, zooming?: boolean) => void
}

export const useOnZoomChange = ({
  fromToValues,
  previousPeriodFromToValues,
  handleDatesChange,
  handleCompareDatesChange,
  sendEvent,
}: IOnZoomChangeArgs) => {
  return useCallback(
    (chart: unknown, changed: { xaxis: { min: number; max: number } }) => {
      const { xaxis } = changed
      const startDate = DateTime.fromMillis(xaxis?.min) || fromToValues.startDate
      const endDate = DateTime.fromMillis(xaxis?.max) || fromToValues.endDate
      const isZoomedIn =
        fromToValues.startDate &&
        fromToValues.startDate < startDate &&
        fromToValues.endDate &&
        fromToValues.endDate > endDate
      let intervalBetweenStartDates: Interval | null = null
      let intervalBetweenEndDates: Interval | null = null

      if (fromToValues.startDate) {
        if (!isZoomedIn) {
          intervalBetweenStartDates = Interval.fromDateTimes(startDate, fromToValues.startDate)
        } else {
          intervalBetweenStartDates = Interval.fromDateTimes(fromToValues.startDate, startDate)
        }
      }
      if (fromToValues.endDate) {
        if (isZoomedIn) {
          intervalBetweenEndDates = Interval.fromDateTimes(endDate, fromToValues.endDate)
        } else {
          intervalBetweenEndDates = Interval.fromDateTimes(fromToValues.endDate, endDate)
        }
      }
      handleDatesChange(
        {
          startDate,
          endDate,
        },
        true
      )

      if (previousPeriodFromToValues?.startDate && previousPeriodFromToValues.endDate && handleCompareDatesChange) {
        const newPreviousPeriodStartDate =
          intervalBetweenStartDates && intervalBetweenStartDates.toDuration()
            ? isZoomedIn
              ? previousPeriodFromToValues.startDate?.plus(intervalBetweenStartDates.toDuration())
              : previousPeriodFromToValues.startDate?.minus(intervalBetweenStartDates.toDuration())
            : previousPeriodFromToValues.startDate

        const newPreviousPeriodEndDate =
          intervalBetweenEndDates && intervalBetweenEndDates.toDuration()
            ? isZoomedIn
              ? previousPeriodFromToValues.endDate?.minus(intervalBetweenEndDates.toDuration())
              : previousPeriodFromToValues.endDate?.plus(intervalBetweenEndDates.toDuration())
            : previousPeriodFromToValues.endDate

        if (newPreviousPeriodStartDate && newPreviousPeriodEndDate) {
          handleCompareDatesChange(
            {
              startDate: newPreviousPeriodStartDate,
              endDate: newPreviousPeriodEndDate,
            },
            true
          )
        }
      }
      sendCAZoomEvent(sendEvent)
    },
    [handleDatesChange, handleCompareDatesChange]
  )
}
