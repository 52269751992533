import EnergyBalance from './Water_EnergyBalance'
import Power from './Water_Power'
import StackedBarGraph from './Water_StackedBarGraph'
import type { AnalysisType, AssetLevel0, LocalAsset } from '../../../Shared/types/analysis_types'
import { prepareAssets } from '../../../Shared/utils/analysis_utils'
import { AnalysisAssetFragment } from '../../../Shared/graphql/codegen'

/**
 * A mountable React component
 * @typedef {object} MountableComponent
 */
/**
 * getGraph - Get a component to mount as a graph widget
 *
 * @param  {(graphs)} type The type of graph you want you grab
 * @return {MountableComponent}       Mountable component
 */
export function getGraph(type: AnalysisType) {
  switch (type) {
    case 'energy':
      return StackedBarGraph
    case 'energy-balance':
      return EnergyBalance
    case 'power':
      return Power
    case 'volume':
      return StackedBarGraph
    default:
      console.error(`Unknown AnalysisType: ${type}`)
      return null
  }
}

const isWaterAsset = (asset: AnalysisAssetFragment) =>
asset.payload && (asset.payload.commodityType === 'water' || asset.payload.commodityType === 'steam')

export const processWaterAssetTree = (rawGroups: readonly AssetLevel0[]): LocalAsset[] => {
  const groups: AssetLevel0[] = []

  rawGroups.forEach(g => {
    const machines = g.assets.filter(machine => {
      const sensorCount = [...machine.sensors, ...machine.assets
        .filter(subAsset => isWaterAsset(subAsset))
        .flatMap(subAsset => subAsset.sensors)].length

      return isWaterAsset(machine) && sensorCount > 0
    })

    const group: AssetLevel0 = {
      ...g,
      assets: machines,
    }

    if (group.assets.length > 0) {
      groups.push(group)
    }
  })

  return prepareAssets(groups)
}
