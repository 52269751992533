import BarChart from '@sensorfactdev/nucleus/StackedBarChart'
import Loader from '@sensorfactdev/nucleus/Loader'
import styled from 'styled-components'
import React, { Component, ComponentClass } from 'react'
import { DurationUnit } from 'luxon'
import { connect } from 'react-redux'
import { path, prop, propOr } from 'ramda'

import CenterMessage from '../../../../Shared/components/CenterMessage'
import GenericError from '../../../../Shared/components/GenericError'
import { Asset } from './types'
import { CurrentCustomer, ID } from '../../../../Shared/types/types'
import { DateRange } from '../../../../Shared/types/analysis_types'
import { LocalStoreState } from '../../../types'
import { getAssetById } from '../../../../Shared/utils'
import { getLargestMeasurement } from '../../../../Shared/utils'
import { getPowerOrderOfMagnitude } from '../../../../Shared/utils'
import { getScaledEnergyPriceMap } from '../../../../Shared/utils'
import { getTickSize } from '../../../../Shared/utils'
import { getVolumeOrderOfMagnitude } from '../../../../Shared/utils'
import { isDateOutsideOfRange } from '../../../../Shared/utils'
import { themeColors } from '../../../../Shared/utils'

const defaultMagnitude = { unit: '?', decimal: 1 }

const getYValueUnits = (measurementType: OwnStackedBarChartProps['measurementType'], largestMeasurement: number) => {
  switch (measurementType) {
    case 'energy': {
      const { unit, decimal } = getPowerOrderOfMagnitude(largestMeasurement)
      return { unit: `${unit}h`, decimal }
    }
    case 'volume': {
      const { unit, decimal } = getVolumeOrderOfMagnitude(largestMeasurement) ?? defaultMagnitude
      return { unit: `${unit}`, decimal }
    }
    default:
      return defaultMagnitude
  }
}
const LocalWrapper = styled.div`
  display: flex;
`
const MainContainer = styled.div`
  width: 100%;
  text-align: left;
  background-color: ${themeColors.white};
`
const ChartContainer = styled.div`
  position: relative;
  display: inline-block;
`

type StateProps = ReturnType<typeof mapStateToProps>

const defaultProps = {
  isDashboard: false,
  linkToDashboard: '',
  yUnit: 'currency' as 'currency' | 'kWh',
  zoomInEnabled: true,
}
type DefaultProps = Readonly<typeof defaultProps>
type OwnStackedBarChartProps = {
  customer: CurrentCustomer
  params: {
    id?: string
  }
  measurementType: 'energy' | 'volume'
  measurements: $TSFixMe
  i18n: $I18FixMe
  selectedDateRange: DateRange
  onTravelInTime: $TSFixMeFunction
  onComponentSelect: $TSFixMeFunction
  selectedAssets: string[][]
  selectedComponents: string[]
  pending: boolean
  error: boolean
  onYAxisChange: $TSFixMeFunction

  zoomInEnabled: boolean
  zoomIn: $TSFixMeFunction
  zoomOutEnabled: boolean
  zoomOut: $TSFixMeFunction
} & Partial<DefaultProps>
type StackedBarChartState = $TSFixMe
type StackedBarChartProps = StateProps & DefaultProps & OwnStackedBarChartProps

const StackedBarChart = class extends Component<StackedBarChartProps, StackedBarChartState> {
  static defaultProps = defaultProps
  chartContainer: $TSFixMe
  constructor(props: StackedBarChartProps) {
    super(props)
    this.state = {
      containerWidth: window.innerWidth - 370,
      yUnit: 'currency',
    }
    this.onTriggerResize = this.onTriggerResize.bind(this)
    this.onToggleYUnit = this.onToggleYUnit.bind(this)
  }
  componentDidMount() {
    window.addEventListener('resize', this.onTriggerResize)
    if (this.chartContainer) {
      this.onTriggerResize()
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onTriggerResize)
  }
  onTriggerResize() {
    const { isDashboard } = this.props
    let containerWidth: $TSFixMe
    if (this.chartContainer) {
      containerWidth = this.chartContainer.offsetWidth - 10
    } else if (isDashboard) {
      containerWidth = window.innerWidth - 50
    } else {
      containerWidth = window.innerWidth - 360
    }
    this.setState(() => ({
      containerWidth,
    }))
  }
  onToggleYUnit() {
    const { onYAxisChange } = this.props
    onYAxisChange()
  }
  render() {
    const {
      customer,
      analysis,
      i18n,
      selectedDateRange,
      pending,
      error,
      yUnit,
      isDashboard,
      selectedAssets,
      measurementType,
      measurements,
      onTravelInTime,
      zoomInEnabled,
      zoomIn,
      zoomOutEnabled,
      zoomOut,
    } = this.props

    const { containerWidth } = this.state
    const flattenedSelectedAssets = selectedAssets.flat()
    const allAssets = analysis.get('assets').toJS()
    const selectedMeasurements = flattenedSelectedAssets
      .map(assetId => ({ ...measurements[assetId], id: assetId }))
      .filter(x => x && x.measurements && x.measurements.length !== 0)
    const _measurements = selectedMeasurements.map(({ measurements: m }) => m)
    const largestMeasurement: number = propOr(0, 'value', getLargestMeasurement(_measurements))
    const { unit, decimal } = getYValueUnits(measurementType, largestMeasurement)
    let scaledEnergyPrices: $TSFixMe
    let currencyInfo
    if (measurementType === 'energy') {
      scaledEnergyPrices = getScaledEnergyPriceMap(customer.commodityPrices, unit)
      currencyInfo = {
        code: 'EUR',
        symbol: '€',
      }
    }
    const data = selectedMeasurements.map(({ id, name, measurements: m }) => {
      const asset = getAssetById<ID, Asset>(allAssets, id)
      const commodityType = path(['payload', 'commodityType'], asset)
      const scaledCost = scaledEnergyPrices ? scaledEnergyPrices.get(commodityType) || 0 : 0
      return m.map(({ value, time }: $TSFixMe) => ({
        name,
        unit: `${unit}`,
        value: Math.round((value / decimal) * 100) / 100,
        cost: Math.round((value / decimal) * scaledCost * 100) / 100,
        date: new Date(time),
        color: propOr('black', 'color', asset),
        markerShape: prop('markerShape', asset),
      }))
    })
    const deltaDuration =
      selectedDateRange.startDate === selectedDateRange.endDate
        ? selectedDateRange.endDate.endOf('day').diff(selectedDateRange.startDate.startOf('day'))
        : selectedDateRange.endDate.diff(selectedDateRange.startDate)
    const ticks = getTickSize(deltaDuration.toMillis())
    const diffUnit = `${ticks.unit}s` as DurationUnit
    const resolution = selectedDateRange.endDate.diff(selectedDateRange.startDate, diffUnit)[diffUnit]
    const graphHeight = isDashboard ? global.window.innerHeight - 190 : global.window.innerHeight * 0.72
    if (error) {
      return (
        <LocalWrapper>
          <MainContainer style={{ textAlign: 'center', flexBasis: '100%', width: '100%' }}>
            <GenericError message={error.toString()} />
          </MainContainer>
        </LocalWrapper>
      )
    }
    return (
      <LocalWrapper>
        <MainContainer
          ref={el => {
            this.chartContainer = el
          }}
        >
          {data && data.length !== 0 && (
            <ChartContainer>
              <BarChart
                data={data}
                yUnit={yUnit}
                startDate={selectedDateRange.startDate}
                endDate={selectedDateRange.endDate}
                timeTravelDisabled={isDateOutsideOfRange(selectedDateRange.endDate)}
                onTravelInTime={onTravelInTime}
                height={graphHeight}
                width={containerWidth}
                ticks={ticks}
                locale={(i18n as $TSFixMe).locale}
                i18n={i18n}
                timeZone={customer.timeZone}
                resolution={resolution}
                showLegend
                pending={pending}
                currencyMode={!!currencyInfo}
                currencyInfo={currencyInfo}
                zoomInEnabled={zoomInEnabled}
                zoomIn={zoomIn}
                zoomOutEnabled={zoomOutEnabled}
                zoomOut={zoomOut}
              />
            </ChartContainer>
          )}
          {data.length === 0 && (
            <CenterMessage>
              <h2>{i18n.text('analysis.energy-balance.no-data')}</h2>
              <br />
              <p>{i18n.text('analysis.energy-balance.no-data.description')}</p>
            </CenterMessage>
          )}
          {!data && (
            <ChartContainer style={{ textAlign: 'center', width: '100%' }}>
              <Loader />
            </ChartContainer>
          )}
        </MainContainer>
      </LocalWrapper>
    )
  }
} as ComponentClass<StateProps & OwnStackedBarChartProps>

const mapStateToProps = ({ gas }: LocalStoreState) => ({
  analysis: gas,
})

export default connect(mapStateToProps, null)(StackedBarChart)
