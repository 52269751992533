import { Box, BoxProps, LinearProgress, LinearProgressProps } from '@mui/material'

type LoaderingBarProps = {
  loading: boolean,
  boxProps?: BoxProps,
  progressProps?: LinearProgressProps
}

const LoadingBar = ({ loading, boxProps, progressProps }: LoaderingBarProps) => {
  return (
    <Box
      visibility={loading ? 'visible' : 'hidden'}
      sx={{ width: '100%' }}
      {...boxProps}
    >
      <LinearProgress
        color="secondary"
        sx={{ height: 5 }}
        {...progressProps}
      />
    </Box>
  )
}

export default LoadingBar
