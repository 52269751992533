import SwipeableViews from 'react-swipeable-views'
import React, { useState } from 'react'
import { Box, BoxTypeMap, Button, ButtonProps, styled } from '@mui/material'

import { ArrowIcon } from '../../../icons'

/**
 * @description - MuiCarousel is a component that allows you to see images by swiping to the left or right
 * @param {string[]} images - A list of images that are presentable in carousel
 * @param {BoxTypeMap['props']} containerProps - Additional props for mui material component Box that is a container here
 * @param {BoxTypeMap['props']} imageProps - Additional props for mui material component Box that is an image here
 * @param {number} index - An index's state from outside (this is required to make carousel component controlled)
 * @param {function(index: number): void} onIndexChange - A function is called when the image's index is changed
 * @param {function(image: string, index: number): void} onClick - A function that triggers when an image is clicked
 */

export type CarouselProps = {
  images: string[]
  containerProps?: BoxTypeMap['props']
  imageProps?: BoxTypeMap['props']
  index?: number
  onIndexChange?: (index: number) => void
  onClick?: (image: string, index: number) => void
}

const Carousel: React.FC<CarouselProps> = ({ images, containerProps, imageProps, index, onIndexChange, onClick }) => {
  const [_index, setIndex] = useState(0)
  const max = images.length

  const _onClick = onClick || (() => undefined)

  const onNextButtonClick = () => {
    setIndex(prevIndex => prevIndex + 1)
    if (index !== undefined && onIndexChange) {
      onIndexChange(index + 1)
    }
  }

  const onBackButtonClick = () => {
    setIndex(prevIndex => prevIndex - 1)
    if (index !== undefined && onIndexChange) {
      onIndexChange(index - 1)
    }
  }

  const isBackButtonDisabled = (onIndexChange && index === 0) || (!onIndexChange && _index === 0)
  const isNextButtonDisabled = (onIndexChange && index === max - 1) || (!onIndexChange && _index === max - 1)

  return (
    <ImagesWrapper
      data-testid="carousel-container"
      {...containerProps}
    >
      <SwipeableViews
        index={onIndexChange ? index : _index}
        axis="x"
      >
        {images.map((image, i) => (
          <Box
            key={image}
            component={'img'}
            {...imageProps}
            src={image}
            onClick={() => _onClick(image, i)}
            data-testid="carousel-image"
            sx={{
              width: 'fit-content',
              margin: '0 auto',
              display: 'flex',
              height: '100%',
              maxHeight: '31.5rem',
            }}
          />
        ))}
      </SwipeableViews>
      {images.length > 1 && (
        <>
          <CarouselButton
            onClick={onBackButtonClick}
            disabled={isBackButtonDisabled}
            data-testid="carousel-button-prev"
          >
            <StyledArrowIcon />
          </CarouselButton>
          <CarouselButton
            onClick={onNextButtonClick}
            disabled={isNextButtonDisabled}
            data-testid="carousel-button-next"
            isNext
          >
            <StyledArrowIcon />
          </CarouselButton>
        </>
      )}
    </ImagesWrapper>
  )
}

export default Carousel

interface ICarouselButtonProps extends ButtonProps {
  isNext?: boolean
}

const ImagesWrapper = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '0.25rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const CarouselButton = styled((props: ICarouselButtonProps) => {
  const { isNext, ...other } = props
  return <Button {...other} />
})(({ isNext, theme }) => ({
  background: theme.palette.SFIBase.white,
  height: '2.75rem',
  width: '2.75rem',
  minWidth: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0px 1px 2px 0px #1018280D',
  position: 'absolute',
  top: 'calc(50% - 2.75rem)',
  left: !isNext ? '1rem' : 'initial',
  right: isNext ? '1rem' : 'initial',
  transform: isNext ? 'rotate(-90deg)' : 'rotate(90deg)',
  '&:hover': {
    backgroundColor: theme.palette.SFIGreyLight[50],
  },
  '&.Mui-disabled': {
    pointerEvents: 'auto',
  },
}))

const StyledArrowIcon = styled(ArrowIcon)({
  width: '1rem',
  height: '1rem',
})
