import { ChangeEvent, FC } from 'react'
import { InputAdornment, SxProps, TextField, outlinedInputClasses, styled } from '@mui/material'

import { SearchIcon } from '../../../icons/SearchIcon'

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  borderRadius: '8px',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  color: theme.palette.SFIGreyLight[500],
  [`.${outlinedInputClasses.notchedOutline}`]: {
    border: `1px solid ${theme.palette.SFIGreyLight[300]} !important`,
  },
  input: {
    padding: theme.spacing(1.25, 1.75),
    borderRadius: '0.375rem',
    color: theme.palette.SFIGreyLight[500],
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    '&:focus-visible': {
      border: 'none',
    },
    '&.Mui-disabled': {
      background: theme.palette.SFIGreyLight[50],
      WebkitTextFillColor: theme.palette.SFIGreyLight[500],
    },
  },
}))

interface SearchBoxProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: string
  placeholder?: string
  showIcon: boolean
  textFieldStyle?: SxProps
  textFieldProps?: any
}

export const SearchBox: FC<SearchBoxProps> = ({
  onChange,
  placeholder,
  value,
  showIcon,
  textFieldStyle,
  textFieldProps,
}) => {
  return (
    <StyledTextField
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      InputProps={{
        autoComplete: 'off',
        startAdornment: showIcon ? (
          <InputAdornment
            position="start"
            sx={{ marginRight: 0 }}
          >
            <SearchIcon
              sx={{
                width: '20px',
                height: '20px',
              }}
            />
          </InputAdornment>
        ) : null,
      }}
      {...textFieldProps}
      sx={textFieldStyle}
    />
  )
}
