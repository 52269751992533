import { Box, Select, MenuItem, TextField, Theme, Typography } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import { FormInputs } from '../EditAlertDialog';
import { useI18nContext } from '../../../../../../Shared/contexts/i18nContext/I18nContext';

type DurationInputProps = {
  theme: Theme;
  control: Control<FormInputs>;
  errors: any;
};

export default function DurationInput({ control, theme, errors }: DurationInputProps) {
  const {i18n} = useI18nContext();

  return (
    <Box>
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Controller
        name="duration"
        control={control}
        render={({ field: {onChange, ...rest} }) => {
          return (
            <TextField
              {...rest}
              label={i18n.text('pdm.configure.alert.dialog.duration.label')}
              type="number"
              placeholder={i18n.text('pdm.configure.alert.dialog.duration.placeholder')}
              inputProps={{ min: 0 }}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                onChange(value);
              }}
              error={!!errors.duration}
              fullWidth />
          )
        }} />

        <Controller
        name="durationInterval"
        control={control}
        render={({ field }) => {
          return (
            <Select
              {...field}
              labelId="select-duration-interval"
              variant='outlined'
              sx={{
                width: '7rem',
                marginLeft: theme.spacing(2),
              }}
            >
              <MenuItem value="minutes">{i18n.text('pdm.configure.alert.dialog.duration.minutes')}</MenuItem>
              <MenuItem value="hours">{i18n.text('pdm.configure.alert.dialog.duration.hours')}</MenuItem>
            </Select>
          )
        }} />
      </Box>
      <Typography
        variant='caption'
        color='error'
        sx={{
          marginLeft: theme.spacing(2),
        }}
      >{errors.duration && errors.duration?.message}</Typography>
    </Box>
  );
}
