import React, { FC, useEffect, useState } from 'react'
import { Box, Typography, dialogClasses, linearProgressClasses, styled } from '@mui/material'
import { useDropzone } from 'react-dropzone'

import LoadingBar from '../../LoaderBar'
import { ImageTypeInput } from '../../../../graphql/codegen'
import { ModalFooter, StyledDialogModal } from '../../StyledDialogModal'
import { StyledOutlinedButton, StyledPrimaryButton } from '../styledComponents/StyledButtons'
import { UploadIcon } from '../../../icons/UploadIcon'
import { UploadedFileItem } from './UploadedFileItem'
import { detectMimeType } from '../../../../utils/getMimeType'
import { getBase64 } from '../../../../utils/getBase64FromFile'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

/**
 * @description - UploadImageModal
 * @param {string} title - Title of the UploadImageModal
 * @param {string} subtitle - Subtitle of the UploadImageModal
 * @param {boolean} isOpen - Status of the UploadImageModal, it states whether the modal is open or not
 * @param {boolean} isLoading - It activates or deactivates a loading bar on top of the modal
 * @param {number} maximumFileSize - By default it is 3000000 bytes (~ 3MB), but a developer can set another maximum file size in bytes.
 * It is used for the validation. If file's size exceeds this number, there will be an error displayed under the file component
 * @param {string} rulesText - A text in a DragAndDropBox to state what extensions and what size are allowed
 * @param {function(isOpen: boolean): void} onToggleModal - A function to toggle isOpen state
 * @param {function(files: File[]): void} onSelectFiles - A function is called when a user selects files from computer
 * @param {function(): void} onUpload - A function is called when a user click on 'Attach images' button
 */

const MAXIMUM_LIMIT_FILE_SIZE = 3000000 // maximum acceptable file size in bytes
const BYTES_IN_MB = 1000000

interface IUploadImageModalProps {
  title: string
  subtitle?: string
  isOpen: boolean
  isLoading?: boolean
  maximumFileSize?: number
  rulesText?: string
  onToggleModal: (isOpen: boolean) => void
  onSelectFiles: (files: File[]) => void
  onUpload: () => void
}

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}))

const DragAndDropBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '1rem 1.5rem',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.25rem',
  borderRadius: '0.75rem',
  background: theme.palette.SFIBase.white,
  width: '100%',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  transition: 'all 150ms linear',
  maxHeight: '130px',
  '&:hover': {
    border: `2px solid ${theme.palette.SFIBrand[900]}`,
  },
}))

export const UploadImageModal: FC<IUploadImageModalProps> = ({
  title,
  subtitle,
  isOpen,
  isLoading,
  maximumFileSize = MAXIMUM_LIMIT_FILE_SIZE,
  rulesText,
  onToggleModal,
  onSelectFiles,
  onUpload,
}) => {
  const { i18n } = useI18nContext()
  const [selectedFiles, setSelectedFiles] = useState<File[]>()
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  const hasError = Object.keys(errors).length > 0

  useEffect(() => {
    if (!isOpen) {
      setSelectedFiles([])
      setErrors({})
    }
  }, [isOpen])

  const maximumFileSizeInMb = () => maximumFileSize / BYTES_IN_MB

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      const temporaryErrors: { [key: string]: string } = { ...errors }
      acceptedFiles.forEach(acceptedFile => {
        getBase64(acceptedFile, result => {
          if (validateType(acceptedFile, result)) {
            temporaryErrors[acceptedFile.name] = i18n.text('file-modal.wrong-type')
          } else if (validateSize(acceptedFile.size)) {
            temporaryErrors[acceptedFile.name] = `${i18n.text(
              'file-modal.image-size.error'
            )} ${maximumFileSizeInMb()} MB`
          }
        })
      })
      const fileNames = selectedFiles?.flatMap(selectedFile => selectedFile.name)
      const filteredFiles = acceptedFiles.filter(file => !fileNames?.includes(file.name))
      const result = selectedFiles ? [...selectedFiles, ...filteredFiles] : filteredFiles
      onSelectFiles(result)
      setSelectedFiles(result)
      setErrors(temporaryErrors)
    },
  })

  const closeModal = () => onToggleModal(false)

  function removeFile(indexToRemove: number, fileName: string) {
    const result = selectedFiles?.filter((_, index) => index !== indexToRemove)
    if (result) {
      onSelectFiles(result)
      setSelectedFiles(result)
      const { [fileName]: removed, ...other } = errors
      setErrors({ ...other })
    }
  }

  function validateSize(fileSize: number) {
    return fileSize > maximumFileSize
  }

  function validateType(acceptedFile: File, result: string) {
    const allowedInputTypes = Object.values(ImageTypeInput)
    const base64String = result.split(';base64,')[1]
    const fileType = acceptedFile.type.replace('image/', '')
    const mimeType = detectMimeType(base64String)

    return (
      !allowedInputTypes.includes(fileType as ImageTypeInput) || !allowedInputTypes.includes(mimeType as ImageTypeInput)
    )
  }

  return (
    <StyledDialogModal
      isOpen={isOpen}
      title={title}
      subtitle={subtitle}
      isLoading={false}
      onClose={closeModal}
      maxWidth="sm"
      height="responsive"
      sx={{
        [`.${dialogClasses.paper}`]: {
          maxWidth: '480px',
          maxHeight: '800px',
        },
      }}
    >
      <LoadingBarWrapper>
        <LoadingBar
          loading={!!isLoading}
          progressProps={{
            sx: {
              background: theme => theme.palette.SFIGreyLight[200],
              height: '2px',
              [`${linearProgressClasses.bar}`]: {
                background: theme => theme.palette.SFIBrand[800],
              },
            },
          }}
        />
      </LoadingBarWrapper>
      <ContentWrapper>
        <DragAndDropBox {...getRootProps()}>
          <input {...getInputProps()} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <UploadIconWrapper>
              <UploadIcon
                sx={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              />
            </UploadIconWrapper>
            <UploadText>
              <UploadTextSpan>{i18n.text('general.image.click-to-upload')}</UploadTextSpan>{' '}
              {i18n.text('general.image.drag-and-drop')}
            </UploadText>
            <RulesText>{rulesText ?? i18n.text('general.image.types')}</RulesText>
          </Box>
        </DragAndDropBox>
        <FilesWrapper>
          {selectedFiles?.map((file, index) => (
            <UploadedFileItem
              key={file.name + index}
              error={errors[file.name]}
              file={file}
              index={index}
              onRemoveFile={removeFile}
            />
          ))}
        </FilesWrapper>
      </ContentWrapper>
      <ModalFooter
        sx={{
          gap: '0.75rem',
          overflow: 'visible',
        }}
      >
        <StyledOutlinedButton
          disabled={isLoading}
          disableRipple
          onClick={closeModal}
        >
          {i18n.text('generic.cancel')}
        </StyledOutlinedButton>
        <StyledPrimaryButton
          disabled={isLoading || selectedFiles?.length === 0 || hasError}
          disableRipple
          onClick={onUpload}
        >
          <UploadIcon
            sx={{
              width: '1.25rem',
              height: '1.25rem',
            }}
          />
          {i18n.text('general.image.attach-images')}
        </StyledPrimaryButton>
      </ModalFooter>
    </StyledDialogModal>
  )
}

const LoadingBarWrapper = styled(Box)({
  marginLeft: '-1.5rem',
  width: 'calc(100% + 3rem)',
})

const UploadIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '2.5rem',
  height: '2.5rem',
  padding: '0.625rem',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0.5rem',
  border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  color: theme.palette.SFIGreyLight[700],
  marginBottom: '0.75rem',
}))

const UploadText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  marginBottom: '0.25rem',
}))

const UploadTextSpan = styled('span')(({ theme }) => ({
  color: theme.palette.SFIBrand[700],
  fontWeight: 600,
}))

const RulesText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
}))

const FilesWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
  maxHeight: '20rem',
  overflowY: 'auto',
})
