import { Oee_Production_Speed_Unit_Enum, ProductionSpeedUnitInput } from '../../Shared/graphql/codegen'
import { Time } from '../types/Time.types'

export const round = (num: number, fractionDigits: number): number => Number(num.toFixed(fractionDigits))

const cyclesPerHourToCyclesPerSecond = (cyclesPerHour: number): number => cyclesPerHour / Time.SecondsInHour
const cyclesPerHourToCyclesPerMinute = (cyclesPerHour: number): number => cyclesPerHour / Time.MinutesInHour
const cyclesPerMinuteToCyclesPerSecond = (cyclesPerMinute: number): number => cyclesPerMinute / Time.SecondsInMinute

const cyclesPerSecondToCyclesPerHour = (cyclesPerSecond: number): number => cyclesPerSecond * Time.SecondsInHour

const cycleTimeSecondsToCyclesPerSecond = (cycleTimeSeconds: number): number => 1 / cycleTimeSeconds
const cycleTimeSecondsToCyclesPerMinute = (cycleTimeSeconds: number): number =>
  cycleTimeSecondsToCyclesPerSecond(cycleTimeSeconds) * Time.SecondsInMinute
const cycleTimeSecondsToCyclesPerHour = (cycleTimeSeconds: number): number =>
  cycleTimeSecondsToCyclesPerSecond(cycleTimeSeconds) * Time.SecondsInHour

export const convertFromSpeedCyclesPerHour = (
  cyclesPerHour: number,
  unit: Oee_Production_Speed_Unit_Enum | ProductionSpeedUnitInput
): number => {
  if (cyclesPerHour === 0) return 0

  switch (unit) {
    case ProductionSpeedUnitInput.CyclesPerMinute: {
      return cyclesPerHourToCyclesPerMinute(cyclesPerHour)
    }
    case ProductionSpeedUnitInput.CycleTimeSeconds: {
      return 1 / cyclesPerHourToCyclesPerSecond(cyclesPerHour)
    }
    case ProductionSpeedUnitInput.CyclesPerHour:
    default: {
      return cyclesPerHour
    }
  }
}

export const convertToCycleTime = (value: number, unit: Oee_Production_Speed_Unit_Enum): number => {
  if (value === 0) return 0

  switch (unit) {
    case Oee_Production_Speed_Unit_Enum.CyclesPerHour: {
      return cycleTimeSecondsToCyclesPerSecond(cyclesPerHourToCyclesPerSecond(value))
    }
    case Oee_Production_Speed_Unit_Enum.CyclesPerMinute: {
      return 1 / cyclesPerMinuteToCyclesPerSecond(value)
    }
    case Oee_Production_Speed_Unit_Enum.CycleTimeSeconds:
    default: {
      return value
    }
  }
}

export const convertFromCycleTime = (value: number, unit: Oee_Production_Speed_Unit_Enum): number => {
  if (!value || value === 0) return 0

  switch (unit) {
    case Oee_Production_Speed_Unit_Enum.CyclesPerHour: {
      return cycleTimeSecondsToCyclesPerHour(value)
    }
    case Oee_Production_Speed_Unit_Enum.CyclesPerMinute: {
      return cycleTimeSecondsToCyclesPerMinute(value)
    }
    case Oee_Production_Speed_Unit_Enum.CycleTimeSeconds:
    default: {
      return value
    }
  }
}

export const convertFromSpeedCyclesPerSecond = (cyclesPerSecond: number, unit: Oee_Production_Speed_Unit_Enum) => {
  if (!cyclesPerSecond) return 0
  return convertFromSpeedCyclesPerHour(cyclesPerSecondToCyclesPerHour(cyclesPerSecond), unit)
}

export const convertFromCycleTimeSeconds = (cycleTimeSeconds: number, unit: Oee_Production_Speed_Unit_Enum) => {
  if (!cycleTimeSeconds || cycleTimeSeconds === 0) return 0
  return convertFromCycleTime(cycleTimeSeconds, unit)
}
