import { FullGroupAsset } from '../../Shared/types/analysis_types'

export const flattenAssets = (assets: FullGroupAsset[]) => {
  const flatAssets: FullGroupAsset[] = assets.reduce((acc: any, asset: any) => {
    if (asset.assets && asset.assets.length) {
      return [...acc, asset, ...flattenAssets(asset.assets)]
    }
    return [...acc, asset]
  }, [])
  return flatAssets
}
