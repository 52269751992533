type Magnitude = {
  unit: string
  decimal: number
}

const magnitudes: Magnitude[] = [
  { unit: 'm³', decimal: 1 },
  { unit: '\u00D710³ m³', decimal: Math.pow(10, 3) },
  { unit: '\u00D710⁶ m³', decimal: Math.pow(10, 6) },
  { unit: '\u00D710⁹ m³', decimal: Math.pow(10, 9) },
  { unit: '\u00D710¹² m³', decimal: Math.pow(10, 12) },
]

/**
 * getVolumeOrderOfMagnitude - Returns the order of magnitude info to convert measurement to that unit
 *
 * @param {number} n - Value you need to order of
 * @returns {object} - The order of magitude containing `unit` and `decimal` to devide with.
 */
export function getVolumeOrderOfMagnitude(n: number | null | undefined): Magnitude | undefined {
  if (n == null || isNaN(n)) return undefined

  const order = Math.floor(Math.log10(n) / 3)
  const orderIndex = order >= 0 ? order : 0

  return magnitudes[orderIndex]
}
