import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { FC, useState } from 'react'
import { useTheme } from '@mui/material'

import {
  BackGroundGraphBox,
  GraphBox,
  GraphHeaderBox,
  GraphNoteBox,
  RegularLightSmText,
  SemiboldDarkLgText,
  StyledDivider,
} from '../../Common/v1/index'
import { PeakShavingGraphProps } from '../../../types'
import {
  getGridOptions,
  getLineChartStrokeOptions,
  useAnnotationLabelOptions,
  useAxisTitleStyleOptions,
  useLineChartLegendOptions,
  useXAxisLabelStyleOptions,
  useYAxisLabelStyleOptions,
} from '../../Common/v1/ApexOptions'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

export const PeakShavingGraph: FC<PeakShavingGraphProps> = ({ cumulativePowerGraph, chartWidth }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const [labelVisible, setLabelVisible] = useState(false)
  const numLinesChart = cumulativePowerGraph?.length

  const data = cumulativePowerGraph.slice(0, numLinesChart - 2)

  const peakBeforeX = new Date(
    cumulativePowerGraph?.find(item => item.name === 'peak_before')?.data[0].x || 0
  ).getTime()
  const peakBeforeY = cumulativePowerGraph?.find(item => item.name === 'peak_before')?.data[0].y || 0
  const peakAfterX = new Date(cumulativePowerGraph?.find(item => item.name === 'peak_after')?.data[0].x || 0).getTime()
  const peakAfterY = cumulativePowerGraph.find(item => item.name === 'peak_after')?.data[0].y || 0

  const labelBeforeText = labelVisible
    ? i18n.text('scan.savings.peak_shaving.v1.graph.peak-before') + ' ' + peakBeforeY + ' kW'
    : ''
  const labelAfterText = labelVisible
    ? i18n.text('scan.savings.peak_shaving.v1.graph.peak-after') + ' ' + peakAfterY + ' kW'
    : ''

  const labelOptionsBefore = useAnnotationLabelOptions(labelBeforeText, 25, 10, 'bottom', 'start')
  const labelOptionsAfter = useAnnotationLabelOptions(labelAfterText, 25, 10, 'bottom', 'start')
  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: true,
        },
      },
    },
    annotations: {
      points: [
        {
          x: peakBeforeX,
          y: peakBeforeY,
          marker: {
            fillColor: '#D92D20',
            strokeColor: '#fff',
            strokeWidth: 3,
            size: 7,
          },
          mouseEnter: () => setLabelVisible(true),
          mouseLeave: () => setLabelVisible(false),
          label: labelOptionsBefore,
        },
        {
          x: peakAfterX,
          y: peakAfterY,
          marker: {
            fillColor: '#079455',
            strokeColor: '#fff',
            strokeWidth: 3,
            size: 7,
          },
          mouseEnter: () => setLabelVisible(true),
          mouseLeave: () => setLabelVisible(false),
          label: labelOptionsAfter,
        },
      ],
    },
    tooltip: { enabled: false },
    xaxis: {
      type: 'datetime',
      labels: useXAxisLabelStyleOptions(),

      axisBorder: { show: false },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      decimalsInFloat: 0,
      title: {
        text: i18n.text('scan.savings.peak_shaving.v1.graph.yaxis-label'),
        style: useAxisTitleStyleOptions(),
      },
      labels: useYAxisLabelStyleOptions(),
    },
    legend: useLineChartLegendOptions(),
    grid: getGridOptions(),
    stroke: getLineChartStrokeOptions(),
  }
  return (
    <BackGroundGraphBox>
      <GraphBox>
        <GraphHeaderBox>
          <SemiboldDarkLgText>{i18n.text('scan.savings.peak_shaving.v1.graph.header')}</SemiboldDarkLgText>
        </GraphHeaderBox>
        <StyledDivider sx={{ margin: '0.625rem -0.625rem 0 -0.625rem' }} />
        <ReactApexChart
          options={options}
          series={data}
          type="line"
          width={chartWidth}
        />
        <GraphNoteBox>
          <RegularLightSmText>{i18n.text('scan.savings.peak_shaving.v1.graph.note')}</RegularLightSmText>
        </GraphNoteBox>
      </GraphBox>
    </BackGroundGraphBox>
  )
}
