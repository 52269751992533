import { createSvgIcon } from '@mui/material'

export const FeaturedSuccessCircleIcon = createSvgIcon(
  <svg
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <path
        d="M6 19C6 11.8203 11.8203 6 19 6C26.1797 6 32 11.8203 32 19C32 26.1797 26.1797 32 19 32C11.8203 32 6 26.1797 6 19Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </g>
    <g opacity="0.1">
      <path
        d="M1 19C1 9.05888 9.05888 1 19 1C28.9411 1 37 9.05888 37 19C37 28.9411 28.9411 37 19 37C9.05888 37 1 28.9411 1 19Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </g>
    <g clipPath="url(#clip0_4893_412620)">
      <path
        d="M15.2501 19.0001L17.7501 21.5001L22.7501 16.5001M27.3334 19.0001C27.3334 23.6025 23.6025 27.3334 19.0001 27.3334C14.3977 27.3334 10.6667 23.6025 10.6667 19.0001C10.6667 14.3977 14.3977 10.6667 19.0001 10.6667C23.6025 10.6667 27.3334 14.3977 27.3334 19.0001Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4893_412620">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(9 9)"
        />
      </clipPath>
    </defs>
  </svg>,
  'featured-success-circle-icon'
)
