import React, { useEffect, useState } from 'react'
import { ArrayParam, NumberParam, useQueryParams, withDefault } from 'use-query-params'
import { DateTime, Interval } from 'luxon'
import { useApolloClient } from '@apollo/client'
import { useLocation } from 'react-router'
import { useMemo } from 'react'
import { useTheme } from '@mui/material'

import useIncrementalDataLoader from '../../Shared/hooks/incrementalDataLoader'
import {
  AllSeriesData,
  CompressedAirFlowSystem,
  GraphsComponentProps,
  IExtendedCompressedAirAsset,
  ISeriesData,
  PreviousState,
  SensorLocationSeries,
  SeriesPoints,
} from '../types/compressed-air_types'
import {
  CompressedAirAssetFragment,
  CompressedAirAssetTreeQuery,
  useGetOutsideOperatingHoursInsightsLazyQuery,
} from '../../Shared/graphql/codegen'
import { DateRange } from '../../Shared/types/analysis_types'
import { FetchParams, buildTimeSeriesBatchFetcher } from '../utils/utils'
import { ID, ISODateTime } from '../../Shared/types/types'
import { MILLIS_IN_ONE_DAY } from '../../Shared/constants/timeIntervals'
import { Series, SwitchUnit } from '../types/compressed-air_types'
import { StartEndDatesValues } from '../../Shared/components/MUIComponents/update/MuiDateTimeRangePickerControlled/types'
import { createCtx, toLocalDateTime } from '../../Shared/utils'
import { generateColorMap } from '../utils/utils'
import { getAssetsWithSelectedSensors } from '../utils/getAssetsWithSelectedSensors'
import { getCompressedAirFlowSystemImages, getSensorLocationImages } from '../utils/getImages'
import { mapApexSeries } from '../utils/apexSeries'
import { mapConsumptionTimeSeriesData, mapSeriesData } from '../utils/seriesData'
import { useCAChartToolsContext } from './CAChartToolsContext'
import { useCompressedAirTimeLocationTimeSeriesBatchLazyQuery } from '../../Shared/graphql/codegen'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'

interface CompressedAirContextProps extends Omit<GraphsComponentProps, 'groups'> {
  series: Series
  previousPeriodSeries: Series | null
  seriesData: AllSeriesData
  fromToValues: StartEndDatesValues
  previousPeriodFromToValues: StartEndDatesValues
  assets: CompressedAirAssetFragment[]
  allAssets: IExtendedCompressedAirAsset[]
  setUrlParams: any
  switchUnit: SwitchUnit
  sensorLocationColors: {
    [key: string]: string
  }
  isCompareDatesOn: boolean
  updatedOptionsFlag: boolean
  outsideOperatingHoursFlowSeries: ApexAxisChartSeries
  outsideOperatingHoursConsumptionSeries: ApexAxisChartSeries
  currentSystemIndex: number
  currentSystem: CompressedAirFlowSystem | null
  isMoreThanMonth: boolean
  handleCurrentSystemIndex: (index: number) => void
  handleCurrentSystem: (system: CompressedAirFlowSystem | null) => void
  refetchAssetTree: () => void
  updateSelection: (ids: string[]) => void
  setSwitchUnit: React.Dispatch<React.SetStateAction<SwitchUnit>>
  setFromToValues: React.Dispatch<React.SetStateAction<StartEndDatesValues>>
  setIsCompareDatesOn: React.Dispatch<React.SetStateAction<boolean>>
  setPreviousPeriodFromToValues: React.Dispatch<React.SetStateAction<StartEndDatesValues>>
  handleDatesChange: (dateRange: DateRange, zooming?: boolean) => void
  handleCompareDatesChange: (dateRange: StartEndDatesValues, zooming?: boolean) => void
  setUpdatedOptionsFlag: React.Dispatch<React.SetStateAction<boolean>>
}

const [useCompressedAirContext, CompressedAirContextReactProvider] = createCtx<CompressedAirContextProps>()

export function CompressedAirContextProvider({
  children,
  data,
  sensorLocationIds,
  refetchAssetTree,
}: {
  children: React.ReactNode
  data: CompressedAirAssetTreeQuery | undefined
  sensorLocationIds: string[]
  refetchAssetTree: () => void
}) {
  const theme = useTheme()
  const location = useLocation()
  const [updatedOptionsFlag, setUpdatedOptionsFlag] = useState(false)
  const [currentSystemIndex, setCurrentSystemIndex] = useState(0)

  const client = useApolloClient()
  const DEFAULT_FROM = DateTime.local().startOf('week').valueOf()
  const DEFAULT_TO = DateTime.local().endOf('week').valueOf()

  const fromParam = withDefault(NumberParam, DEFAULT_FROM)
  const toParam = withDefault(NumberParam, DEFAULT_TO)
  const sidParam = withDefault(ArrayParam, [])

  const { showMinMaxRange, showOutsideOperatingHoursInsights, onShowOutsideOperatingHoursInsightsToggle } =
    useCAChartToolsContext()
  const { i18n } = useI18nContext()
  const [isCompareDatesOn, setIsCompareDatesOn] = useState(false)

  const [urlParams, setUrlParams] = useQueryParams({
    from: fromParam,
    to: toParam,
    sid: sidParam,
  })

  const [switchUnit, setSwitchUnit] = useState<SwitchUnit>(SwitchUnit.m3)

  const { from, to, sid } = urlParams
  const [fromToValues, setFromToValues] = useState<StartEndDatesValues>({
    startDate: DateTime.fromMillis(from),
    endDate: DateTime.fromMillis(to),
  })

  const interval: Interval =
    fromToValues.startDate && fromToValues.endDate
      ? Interval.fromDateTimes(fromToValues.startDate, fromToValues.endDate)
      : Interval.fromDateTimes(DateTime.now().startOf('day'), DateTime.now().endOf('day'))

  const isMoreThanMonth = interval.toDuration().milliseconds > MILLIS_IN_ONE_DAY * 31

  const [previousPeriodFromToValues, setPreviousPeriodFromToValues] = useState<StartEndDatesValues>({
    startDate: null,
    endDate: null,
  })

  const timeSeriesBatchFetcher = buildTimeSeriesBatchFetcher(client)

  const sensorLocationColors = generateColorMap(sensorLocationIds)
  const previousPeriodColors = generateColorMap(sensorLocationIds, true)

  const previousState =
    previousPeriodFromToValues.startDate && previousPeriodFromToValues.endDate
      ? PreviousState.HAS_PREVIOUS
      : PreviousState.NO_PREVIOUS

  const [runFetchPreviousPeriodQuery, { loading: fetchPreviousPeriodLoading, data: fetchedPreviousPeriod }] =
    useCompressedAirTimeLocationTimeSeriesBatchLazyQuery()

  const [
    runFetchOutsideOperatingHoursInsights,
    { loading: fetchOutsideOperatingHoursInsightsLoading, data: outsideOperatingHoursInsights },
  ] = useGetOutsideOperatingHoursInsightsLazyQuery()

  const {
    dataMap,
    loading: fetching,
    error: seriesError,
    updateParams: updateDataFetcherParams,
    updateSelection,
  } = useIncrementalDataLoader({
    initialState: {
      ids: [],
      params: {
        interval: Interval.fromDateTimes(DateTime.fromMillis(from), DateTime.fromMillis(to)),
      } as FetchParams,
    },
    entityBatchFetcher: timeSeriesBatchFetcher,
  })

  const outsideOperatingHoursFlowSeries: ApexAxisChartSeries = useMemo(() => {
    const dataMapEntries = dataMap ? [...dataMap.entries()] : []
    const item = dataMapEntries[0]

    if (dataMapEntries.length > 1 || !item || !outsideOperatingHoursInsights) {
      return []
    }

    const [id, obj] = item

    const data =
      outsideOperatingHoursInsights.insights?.timeWindow?.flow.reduce<
        { x: ISODateTime | undefined; y: number | null }[]
      >((acc, measurement, index) => {
        if (!measurement || !obj.flowPoints[index]) {
          return acc
        }
        const isSameTime = measurement.time === obj.flowPoints[index]?.time.toISO()
        if (isSameTime) {
          acc.push({
            x: measurement.time,
            y: measurement.isWaste ? (obj.flowPoints[index].value ? obj.flowPoints[index].value : 0) : null,
          })
        } else {
          acc.push({
            x: measurement.time,
            y: null,
          })
        }
        return acc
      }, []) ?? []

    return [
      {
        id,
        name: i18n.text('compressed-air.outside-operating-hours'),
        type: 'area',
        color: theme.palette.SFIYellow[400],
        data,
      },
    ]
  }, [dataMap, outsideOperatingHoursInsights])

  const outsideOperatingHoursConsumptionSeries: ApexAxisChartSeries = useMemo(() => {
    const dataMapEntries = dataMap ? [...dataMap.entries()] : []
    const item = dataMapEntries[0]

    if (dataMapEntries.length > 1 || !item) {
      return []
    }

    const [id] = item

    const consumptionData =
      outsideOperatingHoursInsights?.insights?.timeWindow.consumption.map(measurement => {
        return {
          x: measurement.time,
          y: measurement.value,
        }
      }) ?? []

    return [
      {
        id,
        group: 'actual',
        name: i18n.text('compressed-air.outside-operating-hours'),
        type: 'column',
        color: theme.palette.SFIYellow[400],
        data: consumptionData,
      },
    ]
  }, [dataMap, outsideOperatingHoursInsights])

  const isLoading = fetching || fetchPreviousPeriodLoading || fetchOutsideOperatingHoursInsightsLoading

  const selectedSensorLocationIds: string[] = useMemo(() => (dataMap ? Array.from(dataMap.keys()) : []), [dataMap])

  const allAssetsOfFilteredGroups = useMemo(
    () =>
      data?.myOrg?.groups.reduce<CompressedAirAssetFragment[]>((acc, group) => {
        let result = acc
        if (group.assets.filter(asset => asset.sensorLocations.length > 0).length > 0) {
          result = result.concat(group.assets)
        }
        return result
      }, []),
    [data]
  )

  const allCompressedAirFlowSystems: IExtendedCompressedAirAsset[] = useMemo(
    () =>
      allAssetsOfFilteredGroups
        ? allAssetsOfFilteredGroups.reduce<IExtendedCompressedAirAsset[]>((acc, asset) => {
            if (asset.compressedAirFlowSystem) {
              acc.push({
                ...asset,
                images: getCompressedAirFlowSystemImages(asset).map(img => img.id),
                sensorLocations: asset.sensorLocations.map(location => ({
                  ...location,
                  images: getSensorLocationImages(location).map(img => img.id),
                })),
              })
            }
            return acc
          }, [])
        : [],
    [allAssetsOfFilteredGroups]
  )

  const firstCompressedAirFlowSystem = allCompressedAirFlowSystems[0]

  const [currentSystem, setCurrentSystem] = useState<CompressedAirFlowSystem | null>(
    firstCompressedAirFlowSystem?.compressedAirFlowSystem
      ? {
          id: firstCompressedAirFlowSystem.compressedAirFlowSystem.id,
          name: firstCompressedAirFlowSystem.name,
        }
      : null
  )

  const assets = useMemo(
    () => getAssetsWithSelectedSensors(allAssetsOfFilteredGroups || [], sid as string[]),
    [allAssetsOfFilteredGroups, sid]
  )

  const previousSeriesData = useMemo(() => {
    if (!previousPeriodFromToValues.startDate || !previousPeriodFromToValues.endDate) {
      return null
    }
    const difference =
      fromToValues.startDate && previousPeriodFromToValues.startDate
        ? fromToValues.startDate.diff(previousPeriodFromToValues.startDate)
        : null
    const days = difference?.milliseconds ? difference.milliseconds / MILLIS_IN_ONE_DAY : 0

    const data = new Map<string, SensorLocationSeries>()
    if (fetchedPreviousPeriod) {
      fetchedPreviousPeriod.sensorLocations
        .filter(location => dataMap?.has(location.id))
        .forEach(location => {
          data.set(location.id, {
            name: location.name,
            flowPoints:
              location.insights?.timeWindow.flowSeries.map((series, index) => {
                return {
                  min: series.min || null,
                  max: series.max || null,
                  value: series.value || null,
                  time:
                    dataMap?.get(location.id)?.flowPoints[index]?.time ?? DateTime.fromISO(series?.time).plus({ days }),
                  previousTime: DateTime.fromISO(series?.time),
                }
              }) || [],
            temperaturePoints:
              location.insights?.timeWindow.temperatureSeries.map((series, index) => ({
                min: series.min || null,
                max: series.max || null,
                value: series.value || null,
                time: dataMap?.get(location.id)?.temperaturePoints[index]?.time ?? DateTime.fromISO(series?.time),
                previousTime: DateTime.fromISO(series?.time),
              })) || [],
            pressurePoints:
              location.insights?.timeWindow.pressureSeries.map((series, index) => ({
                min: series.min || null,
                max: series.max || null,
                value: series.value || null,
                time: dataMap?.get(location.id)?.pressurePoints[index]?.time ?? DateTime.fromISO(series?.time),
                previousTime: DateTime.fromISO(series?.time),
              })) || [],
            consumptionPoints:
              location.insights?.timeWindow.consuptionSeries.map((series, index) => ({
                time:
                  dataMap?.get(location.id)?.consumptionPoints[index]?.time ??
                  DateTime.fromISO(series?.time).plus({ days }),
                previousTime: DateTime.fromISO(series?.time),
                value: series.value,
              })) || [],
          })
        })
    }
    return data
  }, [fetchedPreviousPeriod, dataMap, previousPeriodFromToValues])

  const seriesData = useMemo(
    () => ({
      flow: [
        ...mapSeriesData(dataMap, sensorLocationColors, switchUnit, i18n, previousState),
        ...mapSeriesData(previousSeriesData, previousPeriodColors, switchUnit, i18n, PreviousState.IS_PREVIOUS),
      ] as ISeriesData[],
      temperature: [
        ...mapSeriesData(
          dataMap,
          sensorLocationColors,
          SwitchUnit.celsius,
          i18n,
          previousState,
          SeriesPoints.TEMPERATURE
        ),
        ...mapSeriesData(
          previousSeriesData,
          previousPeriodColors,
          SwitchUnit.celsius,
          i18n,
          PreviousState.IS_PREVIOUS,
          SeriesPoints.TEMPERATURE
        ),
      ] as ISeriesData[],
      pressure: [
        ...mapSeriesData(dataMap, sensorLocationColors, SwitchUnit.bar, i18n, previousState, SeriesPoints.PRESSURE),
        ...mapSeriesData(
          previousSeriesData,
          previousPeriodColors,
          SwitchUnit.bar,
          i18n,
          PreviousState.IS_PREVIOUS,
          SeriesPoints.PRESSURE
        ),
      ] as ISeriesData[],
      consumption: [
        ...mapConsumptionTimeSeriesData(dataMap, previousState, i18n),
        ...mapConsumptionTimeSeriesData(previousSeriesData, PreviousState.IS_PREVIOUS, i18n),
      ],
    }),
    [switchUnit, dataMap, sensorLocationColors, previousSeriesData, previousState]
  )

  const series = useMemo(() => {
    return mapApexSeries(dataMap, sensorLocationColors, switchUnit, i18n, previousState, showMinMaxRange)
  }, [dataMap, fromToValues, sensorLocationColors, switchUnit, previousState, showMinMaxRange])

  const {
    balance: { ids },
  } = series

  const previousPeriodSeries = useMemo(() => {
    return mapApexSeries(
      previousSeriesData,
      previousPeriodColors,
      switchUnit,
      i18n,
      PreviousState.IS_PREVIOUS,
      false,
      ids
    )
  }, [previousSeriesData, switchUnit, ids])

  const updateDateRangeWindow = (interval: Interval) => {
    setUrlParams({
      from: interval.start.toMillis(),
      to: interval.end.toMillis(),
      sid: sid.length > 0 ? sid : [sensorLocationIds[0]],
    })
    updateDataFetcherParams({
      interval,
    })
  }

  const handleDateChange = (changed: { from: DateTime; to: DateTime }) => {
    const interval: Interval = Interval.fromDateTimes(changed.from, changed.to)
    updateDateRangeWindow(interval)
    setCurrentSystem(
      firstCompressedAirFlowSystem.compressedAirFlowSystem
        ? {
            id: firstCompressedAirFlowSystem.compressedAirFlowSystem.id,
            name: firstCompressedAirFlowSystem.name,
          }
        : null
    )
    setCurrentSystemIndex(0)
  }

  useEffect(() => {
    if (!fetching && !fetchPreviousPeriodLoading && location.pathname.includes('flow')) {
      setUpdatedOptionsFlag(true)
    }
  }, [fetching, fetchPreviousPeriodLoading])

  useEffect(() => {
    if (selectedSensorLocationIds.length === 1) {
      const selectedSystem = allCompressedAirFlowSystems.find(asset =>
        asset.sensorLocations.some(location => selectedSensorLocationIds.includes(location.id))
      )
      if (selectedSystem && selectedSystem.compressedAirFlowSystem && fromToValues.startDate && fromToValues.endDate) {
        runFetchOutsideOperatingHoursInsights({
          variables: {
            systemId: selectedSystem.compressedAirFlowSystem.id as ID,
            from: toLocalDateTime(fromToValues.startDate),
            to: toLocalDateTime(fromToValues.endDate),
          },
        })
      }
    }
  }, [allCompressedAirFlowSystems, fromToValues, selectedSensorLocationIds])

  useEffect(() => {
    if (previousPeriodFromToValues.startDate && previousPeriodFromToValues.endDate) {
      runFetchPreviousPeriodQuery({
        variables: {
          ids: selectedSensorLocationIds.length > 0 ? selectedSensorLocationIds : [sensorLocationIds[0]],
          from: toLocalDateTime(previousPeriodFromToValues.startDate),
          to: toLocalDateTime(previousPeriodFromToValues.endDate),
        },
      })
    }
  }, [previousPeriodFromToValues, selectedSensorLocationIds, sensorLocationIds])

  useEffect(() => {
    if (
      (previousPeriodFromToValues.startDate && previousPeriodFromToValues.endDate) ||
      (sid.length > 1 && showOutsideOperatingHoursInsights)
    ) {
      onShowOutsideOperatingHoursInsightsToggle(false)
    }
  }, [sid, previousPeriodFromToValues, showOutsideOperatingHoursInsights])

  function handleDatesChange({ startDate, endDate }: DateRange, zooming?: boolean) {
    const startOfStartDate = startDate ? startDate.startOf('day') : fromToValues.startDate?.startOf('day')
    const endOfEndDay = endDate ? endDate.endOf('day') : fromToValues.endDate?.endOf('day')
    const dateRange = zooming
      ? {
          startDate: startDate || fromToValues.startDate,
          endDate: endDate || fromToValues.endDate,
        }
      : {
          startDate: startOfStartDate,
          endDate: endOfEndDay,
        }
    if (dateRange.startDate && dateRange.endDate) {
      const validStartDate = dateRange.startDate && dateRange.startDate.isValid ? dateRange.startDate : null
      const validEndDate = dateRange.endDate && dateRange.endDate.isValid ? dateRange.endDate : null

      if (zooming) {
        setFromToValues({
          startDate: validStartDate,
          endDate: validEndDate,
        })
      }
      handleDateChange({
        from: dateRange.startDate,
        to: dateRange.endDate,
      })
    }
  }

  function handleCompareDatesChange({ startDate, endDate }: StartEndDatesValues, zooming?: boolean) {
    const startOfStartDate = startDate ? startDate : previousPeriodFromToValues.startDate?.startOf('day')
    const endOfEndDay = endDate ? endDate : previousPeriodFromToValues.endDate?.endOf('day')
    const dateRange = zooming
      ? {
          startDate: startDate || previousPeriodFromToValues.startDate,
          endDate: endDate || previousPeriodFromToValues.endDate,
        }
      : {
          startDate: startOfStartDate,
          endDate: endOfEndDay,
        }
    if (dateRange.startDate && dateRange.endDate) {
      if (zooming) {
        setPreviousPeriodFromToValues({
          startDate: dateRange.startDate as DateTime,
          endDate: dateRange.endDate as DateTime,
        })
      }
    }
  }

  function handleCurrentSystem(system: CompressedAirFlowSystem | null) {
    setCurrentSystem(system)
  }

  function handleCurrentSystemIndex(index: number) {
    setCurrentSystemIndex(index)
  }

  return (
    <CompressedAirContextReactProvider
      value={{
        updatedOptionsFlag,
        setUpdatedOptionsFlag,
        outsideOperatingHoursFlowSeries,
        outsideOperatingHoursConsumptionSeries,
        dataMap,
        fetching: isLoading,
        seriesError,
        sensorLocationIds,
        queryParams: urlParams,
        series,
        allAssets: allCompressedAirFlowSystems,
        assets,
        seriesData,
        switchUnit,
        fromToValues,
        previousPeriodFromToValues,
        previousPeriodSeries,
        sensorLocationColors,
        isCompareDatesOn,
        currentSystem,
        currentSystemIndex,
        isMoreThanMonth,
        handleCurrentSystemIndex,
        handleCurrentSystem,
        setIsCompareDatesOn,
        setFromToValues,
        setPreviousPeriodFromToValues,
        updateParams: updateDataFetcherParams,
        setUrlParams,
        updateSelection,
        setSwitchUnit,
        refetchAssetTree,
        handleDatesChange,
        handleCompareDatesChange,
      }}
    >
      {children}
    </CompressedAirContextReactProvider>
  )
}

export { useCompressedAirContext }
