interface LogoProps {
  color?: string
}

export const Logo = ({ color = '#fff' }: LogoProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 548.5 141.2"
    fill={color}
  >
    <path
      className="st1"
      d="M52,96.7c-3.3-1.4-5.9-3.3-7.6-5.6c-1.7-2.3-2.6-4.8-2.7-7.5c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.2-0.5
        h7.1c0.6,0,1,0.1,1.4,0.4c0.4,0.3,0.7,0.6,1,1.1c0.5,1.8,1.7,3.3,3.6,4.5c1.8,1.2,4.4,1.8,7.6,1.8c3.6,0,6.4-0.6,8.2-1.8
        c1.8-1.2,2.8-2.9,2.8-5c0-1.4-0.5-2.6-1.4-3.5c-0.9-0.9-2.4-1.8-4.3-2.5c-1.9-0.7-4.7-1.5-8.5-2.5c-6.2-1.5-10.7-3.4-13.5-5.8
        c-2.9-2.4-4.3-5.8-4.3-10.3c0-3,0.8-5.7,2.5-8.1c1.7-2.4,4.1-4.2,7.2-5.6c3.1-1.4,6.7-2,10.8-2c4.3,0,8.1,0.8,11.2,2.3
        c3.2,1.5,5.6,3.4,7.2,5.7c1.6,2.3,2.5,4.5,2.6,6.8c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.2,0.5h-7.4c-1.1,0-1.9-0.5-2.3-1.4
        c-0.3-1.7-1.3-3.1-3-4.2c-1.8-1.1-4-1.7-6.6-1.7c-2.9,0-5.1,0.5-6.8,1.6c-1.6,1.1-2.5,2.7-2.5,4.8c0,1.4,0.4,2.6,1.3,3.5
        c0.8,0.9,2.1,1.8,3.9,2.5c1.8,0.7,4.4,1.5,7.7,2.4c4.6,1,8.3,2.2,11.1,3.5c2.7,1.3,4.8,2.9,6.1,4.9c1.3,2,2,4.5,2,7.6
        c0,3.4-0.9,6.3-2.8,8.8c-1.8,2.5-4.5,4.4-7.8,5.7c-3.4,1.3-7.3,2-11.7,2C59.4,98.8,55.3,98.1,52,96.7z"
    />
    <path
      className="st1"
      d="M135.5,97.5c-0.4-0.4-0.5-0.8-0.5-1.3V45.2c0-0.6,0.2-1,0.5-1.4c0.3-0.4,0.8-0.5,1.4-0.5h6.1
        c1,0,1.7,0.4,2.3,1.3l22.4,34.7V45.2c0-0.6,0.2-1,0.5-1.4c0.3-0.4,0.8-0.5,1.4-0.5h6.7c0.6,0,1,0.2,1.4,0.5
        c0.4,0.4,0.5,0.8,0.5,1.4V96c0,0.6-0.2,1-0.5,1.4c-0.4,0.4-0.8,0.5-1.3,0.5h-6.2c-1,0-1.8-0.4-2.3-1.3l-22.3-33.9v33.4
        c0,0.5-0.2,1-0.5,1.3c-0.3,0.4-0.8,0.5-1.4,0.5h-6.7C136.4,98,135.9,97.8,135.5,97.5z"
    />
    <path
      className="st1"
      d="M193.5,96.7c-3.3-1.4-5.9-3.3-7.6-5.6c-1.7-2.3-2.6-4.8-2.7-7.5c0-0.4,0.2-0.8,0.5-1.1
        c0.3-0.3,0.7-0.5,1.2-0.5h7.1c0.6,0,1,0.1,1.4,0.4c0.4,0.3,0.7,0.6,1,1.1c0.5,1.8,1.7,3.3,3.6,4.5c1.8,1.2,4.4,1.8,7.6,1.8
        c3.6,0,6.4-0.6,8.2-1.8c1.8-1.2,2.8-2.9,2.8-5c0-1.4-0.5-2.6-1.4-3.5c-0.9-0.9-2.4-1.8-4.3-2.5c-1.9-0.7-4.7-1.5-8.5-2.5
        c-6.2-1.5-10.7-3.4-13.5-5.8c-2.9-2.4-4.3-5.8-4.3-10.3c0-3,0.8-5.7,2.5-8.1c1.7-2.4,4.1-4.2,7.2-5.6c3.1-1.4,6.7-2,10.8-2
        c4.3,0,8.1,0.8,11.2,2.3c3.2,1.5,5.6,3.4,7.2,5.7c1.6,2.3,2.5,4.5,2.6,6.8c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.2,0.5H217
        c-1.1,0-1.9-0.5-2.3-1.4c-0.3-1.7-1.3-3.1-3-4.2c-1.8-1.1-4-1.7-6.6-1.7c-2.9,0-5.1,0.5-6.8,1.6c-1.6,1.1-2.5,2.7-2.5,4.8
        c0,1.4,0.4,2.6,1.3,3.5c0.8,0.9,2.1,1.8,3.9,2.5c1.8,0.7,4.4,1.5,7.7,2.4c4.6,1,8.3,2.2,11.1,3.5c2.7,1.3,4.8,2.9,6.1,4.9
        c1.3,2,2,4.5,2,7.6c0,3.4-0.9,6.3-2.8,8.8c-1.8,2.5-4.5,4.4-7.8,5.7c-3.4,1.3-7.3,2-11.7,2C200.8,98.8,196.8,98.1,193.5,96.7z"
    />
    <path
      className="st1"
      d="M238,93.4c-4-3.6-6-8.9-6.3-16c-0.1-1.5-0.1-3.7-0.1-6.6c0-3,0-5.2,0.1-6.7c0.2-6.9,2.3-12.2,6.4-15.9
        c4.1-3.7,9.6-5.6,16.5-5.6c6.9,0,12.4,1.9,16.5,5.6c4.1,3.7,6.2,9,6.4,15.9c0.1,3,0.2,5.3,0.2,6.7c0,1.4-0.1,3.6-0.2,6.6
        c-0.2,7.1-2.3,12.4-6.3,16s-9.5,5.4-16.6,5.4C247.5,98.8,241.9,97,238,93.4z M262.9,86.6c2-2.1,3.1-5.3,3.3-9.6
        c0.1-3.1,0.2-5.2,0.2-6.3c0-1.2-0.1-3.3-0.2-6.3c-0.2-4.3-1.3-7.5-3.4-9.6c-2.1-2.1-4.8-3.2-8.2-3.2c-3.4,0-6.2,1.1-8.3,3.2
        c-2.1,2.1-3.2,5.3-3.3,9.6c-0.1,1.5-0.1,3.6-0.1,6.3c0,2.7,0,4.8,0.1,6.3c0.1,4.3,1.2,7.5,3.2,9.6c2.1,2.1,4.8,3.2,8.3,3.2
        C258.1,89.7,260.9,88.7,262.9,86.6z"
    />
    <path
      className="st1"
      d="M283.9,97.5c-0.4-0.4-0.5-0.8-0.5-1.3V45.2c0-0.6,0.2-1,0.5-1.4c0.3-0.4,0.8-0.5,1.4-0.5h20.2
        c6.4,0,11.4,1.5,15,4.5c3.6,3,5.4,7.2,5.4,12.7c0,3.8-0.9,7-2.7,9.6c-1.8,2.6-4.4,4.4-7.7,5.6l11.3,19.9c0.2,0.3,0.2,0.6,0.2,0.9
        c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.1,0.5h-7.2c-0.8,0-1.5-0.2-2-0.6c-0.5-0.4-0.9-0.9-1.3-1.5l-10-18.5h-10.7v18.8
        c0,0.5-0.2,1-0.5,1.3c-0.4,0.4-0.8,0.5-1.4,0.5h-7.2C284.7,98,284.2,97.8,283.9,97.5z M305.2,68.5c3.1,0,5.5-0.7,7-2.1
        c1.6-1.4,2.3-3.4,2.3-6.1c0-2.7-0.8-4.7-2.3-6.1c-1.6-1.4-3.9-2.2-7-2.2h-10.9v16.4H305.2z"
    />
    <path
      className="st1"
      d="M332.7,97.5c-0.4-0.4-0.5-0.8-0.5-1.3V45.2c0-0.6,0.2-1,0.5-1.4c0.3-0.4,0.8-0.5,1.4-0.5h34
        c0.6,0,1,0.2,1.4,0.5c0.4,0.4,0.5,0.8,0.5,1.4v5.6c0,0.5-0.2,1-0.5,1.3c-0.4,0.4-0.8,0.5-1.4,0.5h-25v14.7h23.5
        c0.6,0,1,0.2,1.4,0.5c0.4,0.4,0.5,0.8,0.5,1.3v5.6c0,0.5-0.2,1-0.5,1.3c-0.4,0.4-0.8,0.5-1.4,0.5h-23.5v19.3c0,0.5-0.2,1-0.5,1.3
        c-0.4,0.4-0.8,0.5-1.4,0.5h-7C333.5,98,333.1,97.8,332.7,97.5z"
    />
    <path
      className="st1"
      d="M366.4,97.5c-0.3-0.3-0.5-0.7-0.5-1.2l0.2-0.7l18.8-50.4c0.5-1.3,1.5-2,2.8-2h8.6c1.4,0,2.3,0.7,2.8,2
        l18.8,50.4l0.2,0.7c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.1,0.5h-7c-1.1,0-1.9-0.5-2.3-1.5l-3.6-9.5h-23.1l-3.6,9.5
        c-0.4,1-1.2,1.5-2.3,1.5h-7C367.1,98,366.7,97.8,366.4,97.5z M383.1,78h17.7l-8.8-24.1L383.1,78z"
    />
    <path
      className="st1"
      d="M424,93.3c-3.9-3.7-6-8.9-6.3-15.7c-0.1-1.4-0.1-3.7-0.1-6.9c0-3.2,0-5.6,0.1-7c0.3-6.7,2.4-11.9,6.3-15.6
        c4-3.7,9.5-5.6,16.7-5.6c4.6,0,8.7,0.8,12.1,2.4c3.4,1.6,6.1,3.8,8,6.5c1.9,2.8,2.9,5.9,3,9.3v0.2c0,0.4-0.2,0.8-0.5,1.1
        c-0.3,0.3-0.7,0.4-1.1,0.4h-7.6c-0.6,0-1.1-0.1-1.4-0.4c-0.3-0.3-0.6-0.8-0.8-1.5c-0.8-3.2-2.1-5.5-4.1-6.8c-1.9-1.3-4.5-2-7.7-2
        c-7.5,0-11.3,4.2-11.6,12.5c-0.1,1.4-0.1,3.6-0.1,6.5c0,2.9,0,5.1,0.1,6.6c0.3,8.3,4.2,12.5,11.6,12.5c3.1,0,5.7-0.7,7.6-2
        c2-1.3,3.3-3.6,4.1-6.8c0.2-0.7,0.5-1.2,0.8-1.5c0.3-0.3,0.8-0.4,1.4-0.4h7.6c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.4,1.2
        c-0.1,3.4-1.1,6.5-3,9.3c-1.9,2.8-4.6,4.9-8,6.5c-3.4,1.6-7.5,2.4-12.1,2.4C433.5,98.8,427.9,96.9,424,93.3z"
    />
    <path
      className="st1"
      d="M481.9,97.5c-0.4-0.4-0.5-0.8-0.5-1.3V53.1h-14.5c-0.5,0-1-0.2-1.3-0.5c-0.4-0.4-0.5-0.8-0.5-1.4v-5.9
        c0-0.6,0.2-1,0.5-1.4c0.3-0.4,0.8-0.5,1.4-0.5h39.9c0.6,0,1,0.2,1.4,0.5c0.4,0.4,0.5,0.8,0.5,1.4v5.9c0,0.6-0.2,1-0.5,1.4
        c-0.4,0.4-0.8,0.5-1.4,0.5h-14.4v43.1c0,0.5-0.2,1-0.5,1.3c-0.3,0.4-0.8,0.5-1.4,0.5h-7.3C482.7,98,482.2,97.8,481.9,97.5z"
    />
    <path
      className="st1"
      d="M90.2,46.7v7.4c0,1.1,1.1,1.7,2.2,1.5c5.8-1.4,11.1-2,17.2-2s12,0.7,17.2,2c1.1,0.3,2.2-0.3,2.2-1.5v-7.5
        c0-0.7-0.4-1.4-1.1-1.5c-5.7-1.4-11.9-2-18.4-2s-12.7,0.6-18.4,2C90.6,45.3,90.2,46,90.2,46.7z"
    />
    <path
      className="st1"
      d="M90.2,70.5v7.5c0,1.1,1.1,1.9,2.2,1.5c6.2-2,11.1-2.9,17.2-2.9s11.5,1.1,17.2,2.9c1.1,0.3,2.2-0.4,2.2-1.5
        v-7.5c0-0.7-0.4-1.3-1.1-1.5c-5.8-1.9-11.9-2.8-18.4-2.8s-12.8,1-18.4,2.8C90.6,69.2,90.2,69.9,90.2,70.5z"
    />
    <path
      className="st1"
      d="M90.2,95.4v0.9c0,0.9,0.7,1.6,1.6,1.6h35.6c0.9,0,1.6-0.7,1.6-1.6v-0.9c0-0.6-0.3-1.1-0.8-1.4
        c-5.4-3-11.8-4.7-18.6-4.7S96.4,91.1,91,94.1C90.5,94.3,90.2,94.8,90.2,95.4z"
    />
  </svg>
)
