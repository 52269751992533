import React from 'react'
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'

import { PropositionInfo } from '../../../hooks/usePropositions/usePropositions'
import { useI18nContext } from '../../../contexts/i18nContext/I18nContext'

const HeroImage = styled(Box)(({ theme }) => ({
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  flexDirection: 'column',
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(6),
  width: '50%',
  height: '100%',
  position: 'relative',
}))

const BlueBackground = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBrand[300] && alpha(theme.palette.SFIBrand[300], 0.2),
  borderRadius: 32,
}))

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIBlueLight[900],
  fontWeight: 500,
  textAlign: 'left',
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(1),
  fontKerning: 'none',
}))

const CTAButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingTop: theme.spacing(1.75),
  paddingBottom: theme.spacing(1.75),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  borderRadius: 8 * +theme.shape.borderRadius,
  backgroundColor: theme.palette.success.main,
  fontSize: '1.1em',
  textTransform: 'none',
}))

const SellingPoints = ({ sellingPoints }: { sellingPoints: string[] }) => {
  const theme = useTheme()
  return (
    <>
      {sellingPoints.map((item, index) => (
        // we know index is stable
        <ListItem key={index}>
          <ListItemIcon
            sx={{
              '*': {
                color: theme.palette.success.main,
                fontSize: '2.5rem',
              },
            }}
          >
            <CheckCircleOutline />
          </ListItemIcon>
          <Typography
            variant="body1"
            color={theme.palette.SFIBlueLight[900]}
            fontWeight={400}
            fontSize="1.15em"
          >
            {item}
          </Typography>
        </ListItem>
      ))}
    </>
  )
}

type LandingPageProps = {
  proposition: PropositionInfo
}

const LandingPage: React.FC<LandingPageProps> = ({ proposition }) => {
  const { title, image, sellingPoints, externalLink } = proposition
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'))

  const renderHorizontalView = () => {
    return (
      <Box
        sx={{
          maxWidth: '80%',
          margin: 'auto',
          position: 'relative',
          height: '70vh',
        }}
      >
        <HeroImage
          id="the-hero"
          style={{
            backgroundImage: `url(${image})`,
          }}
        ></HeroImage>
        <BlueBackground
          sx={{
            position: 'absolute',
            top: 0,
            left: '25%',
            width: '75%',
            height: '100%',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '55%',
            width: '45%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingTop: 8,
          }}
        >
          <Title variant="h3">{title}</Title>
          <List sx={{ width: '100%', marginTop: 2 }}>
            <SellingPoints sellingPoints={sellingPoints} />
          </List>
          <CTAButton
            variant="contained"
            color="primary"
            onClick={() => window.open(externalLink, '_blank', 'noopener noreferrer')}
          >
            {i18n.text('multi.landing.learn-more')}
          </CTAButton>
        </Box>
      </Box>
    )
  }

  const renderVerticalView = () => {
    return (
      <Box>
        <BlueBackground sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 2 }}>
          <Title
            variant="h3"
            sx={{ textAlign: 'center' }}
          >
            {title}
          </Title>
          <HeroImage
            id="the-hero"
            style={{
              backgroundImage: `url(${image})`,
              height: '30vh',
            }}
          />
          <List>
            <SellingPoints sellingPoints={sellingPoints} />
          </List>
          <CTAButton
            variant="contained"
            color="primary"
            onClick={() => window.open(externalLink, '_blank', 'noopener noreferrer')}
          >
            {i18n.text('multi.landing.learn-more')}
          </CTAButton>
        </BlueBackground>
      </Box>
    )
  }

  return (
    <Container
      id="the-container"
      sx={{
        padding: 4,
        paddingTop: 2,
        [theme.breakpoints.down('md')]: {
          padding: 0,
        },
      }}
    >
      {isBelowMd ? renderVerticalView() : renderHorizontalView()}
    </Container>
  )
}

export default LandingPage
