import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { FC, useMemo } from 'react'

import ProductionStatisticsTableRow from './ProductionStatisticsTableRow'
import { ProductionStatisticsRow } from '../../types/ProductionStatistics.types'
import { StyledTableHeadCell } from '../StyledTable'
import { getProductionStatisticsTableHead } from '../../utils/tableHeads'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type ProductionStatisticsTableProps = {
  tableData: ProductionStatisticsRow[]
}

const ProductionStatisticsTable: FC<ProductionStatisticsTableProps> = ({ tableData }) => {
  const { i18n } = useI18nContext()
  const { units } = useOEEContext()

  const tableHead = useMemo(() => getProductionStatisticsTableHead(units.speed), [units.speed])

  return (
    <TableContainer
      component={Box}
      sx={{ overflowX: 'auto' }}
    >
      <Table
        stickyHeader
        aria-label={i18n.text('oee.secondary-nav.speed-analysis')}
      >
        <TableHead>
          <TableRow>
            {tableHead.map(tableHeadColumn => {
              return (
                <StyledTableHeadCell
                  key={tableHeadColumn.id}
                  align={tableHeadColumn.align}
                >
                  {tableHeadColumn.unit
                    ? `${i18n.text(tableHeadColumn.label)} \n(${i18n.text(tableHeadColumn.unit)})`
                    : i18n.text(tableHeadColumn.label)}
                </StyledTableHeadCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map(data => (
            <ProductionStatisticsTableRow
              key={data.id}
              row={data}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ProductionStatisticsTable
