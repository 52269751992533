import React, { FC, useState } from 'react'

import { CurrentUser } from '../../../Shared/types/types'
import { FlowStates } from '../../types/types'
import { NewPasswordValidationView } from './NewPasswordValidationView/NewPasswordValidationView'
import { SuccessView } from './SuccessView/SuccessView'
import { TypeEmailView } from './TypeEmailView/TypeEmailView'
import { UnauthorizedPageLayout } from '../UnauthorizedPageLayout/UnauthorizedPageLayout'

type SetPasswordPageProps = {
  user: CurrentUser | null
}

export const SetPasswordPage: FC<SetPasswordPageProps> = () => {
  const [flowState, setFlowState] = useState<FlowStates>(FlowStates.EMAIL)
  const [email, setEmail] = useState('')

  const handleFlowStateChange = (flowState: string) => {
    setFlowState(flowState as FlowStates)
  }

  const handleEmailChange = (newEmail: string) => {
    setEmail(newEmail)
  }

  function renderForm() {
    switch (flowState) {
      case FlowStates.EMAIL:
        return (
          <TypeEmailView
            onFlowStateChange={handleFlowStateChange}
            onEmailChange={handleEmailChange}
          />
        )
      case FlowStates.CONFIRM:
        return (
          <NewPasswordValidationView
            email={email}
            onFlowStateChange={handleFlowStateChange}
          />
        )
      case FlowStates.COMPLETED:
        return <SuccessView />
      default:
        return ''
    }
  }

  return <UnauthorizedPageLayout>{renderForm()}</UnauthorizedPageLayout>
}
