import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Optional } from 'utility-types'
import { styled } from '@mui/material'

import { LinkInfo } from './types'
import { useI18nContext } from '../../../contexts/i18nContext/I18nContext'

export type OptionalIconLink = Optional<LinkInfo, 'icon'>

type SecondaryNavProps = {
  links: OptionalIconLink[]
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  // width: '100%',
  flex: 1,
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  borderBottom: `solid 2px white`,
  padding: '0 !important',
  minHeight: '0 !important',
  marginBottom: theme.spacing(2),
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '175px',
  borderRadius: 0,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(3.5),
  paddingRight: theme.spacing(3.5),
  fontWeight: 400,
  textTransform: 'none',
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  '&.activeLink, &:hover': {
    marginBottom: '-2px',
    backgroundColor: theme.palette.SFIGreyCool[500],
    color: theme.palette.primary.contrastText,
    borderBottom: 'solid 2px black',
    position: 'relative',
    zIndex: 2,
  },
})) as typeof Button

const SecondaryNav: FC<SecondaryNavProps> = ({ links }) => {
  const { i18n } = useI18nContext()

  return (
    <StyledToolbar>
      {links.map(link => (
        <StyledButton
          key={link.path}
          variant="text"
          color="inherit"
          startIcon={link.icon}
          component={NavLink}
          to={link.path}
          activeClassName="activeLink"
        >
          {i18n.text(link.i18n)}
        </StyledButton>
      ))}
    </StyledToolbar>
  )
}

export default SecondaryNav
