import { Box, BoxProps, styled } from '@mui/material'

import { Logo } from '../../../Shared/components/icons'

interface ILayoutHeaderProps extends BoxProps {
  hasTitle: boolean
}

export const StyledLayoutHeader = styled((props: ILayoutHeaderProps) => {
  const { hasTitle, ...other } = props
  return <Box {...other} />
})(({ hasTitle }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: hasTitle ? '2rem' : '1.63rem',
}))

export const StyledWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '3.75rem 1.25rem 0',
    maxWidth: '25rem',
  },
  paddingTop: '10vh',
  margin: '0 auto 2rem',
  width: '22.5rem',
}))

export const StyledContainer = styled(Box)({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  alignItems: 'center',
})

export const StyledLogo = styled(Logo)(() => ({
  width: '100%',
  height: '6.25rem',
}))

export const StyledWaveWrapper = styled(Box)({
  position: 'absolute',
  bottom: 0,
  overflow: 'hidden',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: -1,
})

export const StyledTitle = styled(Box)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  textAlign: 'center',
  lineHeight: 1.33,
  fontFamily: theme.typography.fontFamily,
}))
