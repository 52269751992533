import { Box, Typography } from '@mui/material'
import { FC } from 'react'

import { CustomerSelector } from '../../../Shared/components/MUIComponents/CustomerSelector/CustomerSelector'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const CustomHeading: FC = props => (
  <Typography
    variant="body1"
    fontSize="1.2em"
    marginBottom={2}
    {...props}
  />
)

const UserInfo = ({ email, name }: { email: string; name: string }) => {
  const { i18n } = useI18nContext()
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '45%', minWidth: '200px' }}>
        <CustomHeading>{i18n.text('settings.pane.email')}</CustomHeading>
        <Typography variant="body1">{email}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '45%', minWidth: '200px' }}>
        <CustomHeading>{i18n.text('settings.pane.name')}</CustomHeading>
        <Typography variant="body1">{name}</Typography>
      </Box>
    </Box>
  )
}

const Account = () => {
  const { user, selectedCustomer, switchCustomer } = useCurrentUser()
  const hasMultipleCustomers = user.customers.length > 1
  const { i18n } = useI18nContext()
  return (
    <Box>
      <UserInfo
        email={user.email}
        name={user.name}
      />
      {hasMultipleCustomers && (
        <Box
          marginTop={4}
          sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}
        >
          <Box sx={{ width: '45%' }}>
            <CustomHeading>{i18n.text('multi.prop.account.selected-customer')}</CustomHeading>
            <Typography variant="body1">{selectedCustomer.name}</Typography>
          </Box>
          <Box sx={{ width: '45%' }}>
            <CustomerSelector
              customers={user.customers}
              selectedCustomer={selectedCustomer}
              switchCustomer={switchCustomer}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Account
