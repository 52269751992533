import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box, Typography, styled, useTheme } from '@mui/material'

import usePropositions from '../../../../Shared/hooks/usePropositions/usePropositions'
import { AlertConfigurationModal } from '../../../components/AlertConfigurationModal'
import { AlertType, CommodityType } from '../../../../Shared/types/types'
import { AlertsMessagesTableTools } from '../../../components/AlertsMessagesTableTools'
import { CustomTable } from '../../../../Shared/components/MUIComponents/update/CustomTable'
import { DeleteImageModal } from '../../../../Shared/components/MUIComponents/update/DeleteImageModal'
import {
  General_Alert_Rule_Categories_Enum,
  useDeleteGeneralAlertForCaMutation,
  useGetAlertConfigurationRulesLazyQuery,
  useGetAlertRulesLazyQuery,
  useGetOldStandbyAlertsLazyQuery,
} from '../../../../Shared/graphql/codegen'
import { IAlertRuleData } from '../../../types/alertTypes'
import { ID } from '../../../../Shared/types/types'
import { StyledPrimaryButton } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { StyledToggleButtonGroup } from '../../../../Shared/components/MUIComponents/update/StyledToggleButtonGroup'
import { getAlertRulesColumns } from '../../../utils/alertRules'
import { getAlertSearchType } from '../../../utils/getAlertSearchType'
import { useAlertConfigurationRows } from '../../../hooks/useAlertConfigurationRows'
import { useAlertTypeCategoriesByProposition } from '../../../hooks/useAlertTypeCategoriesByProposition'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { useFilteredPropositions } from '../../../hooks/useFilteredPropositions'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useSelectedProposition } from '../../../hooks/useSelectedProposition'

const MAXIMUM_ROWS_PER_PAGE = 20

export type SelectedAlertRule = {
  id: string
  proposition?: string
  category?: string
}
const DEFAULT_PAGE = 1

export const ConfigurationPage: FC = () => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { customerId } = useCurrentUser()
  const { propositions } = usePropositions()

  const { selectedProposition, setSelectedProposition } = useSelectedProposition()
  const filteredPropositions = useFilteredPropositions()
  const alertTypeCategories = useAlertTypeCategoriesByProposition(selectedProposition)

  const [page, setPage] = useState(DEFAULT_PAGE)
  const [searchText, setSearchText] = useState('')
  const [isAlertConfigurationModalOpen, setIsAlertConfigurationModalOpen] = useState(false)
  const [isDeleteAlertRuleModalOpen, setIsDeleteAlertRuleModalOpen] = useState(false)
  const [selectedAlertTypeCategory, setSelectedAlertTypeCategory] = useState(alertTypeCategories[0].value)
  const [selectedAlert, setSelectedAlert] = useState<SelectedAlertRule>({
    id: '',
  })

  const offsetValue = (page - 1) * MAXIMUM_ROWS_PER_PAGE

  const toggleButtonGroupSlotProps = {
    wrapper: {
      sx: {
        borderRadius: '0.5rem',
        marginBottom: '3rem',
        width: '100%',
        background: theme.palette.SFIGreyLight[50],
        borderColor: theme.palette.SFIGreyLight[200],
      },
    },
    button: {
      lineHeight: '1.25rem',
      color: theme.palette.SFIGreyLight[500],
      border: 'none',
      padding: '0.5rem 0.75rem',
      '&.Mui-selected': {
        borderRadius: '8px!important',
        background: theme.palette.SFIBrand[50],
        border: `4px solid ${theme.palette.SFIBrand[800]}3d!important`,
        color: theme.palette.SFIBrand[700],
      },
    },
  }

  const customTableSlotProps = {
    tableWrapper: {
      sx: {
        border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
      },
    },
  }

  const customTableHeaderProps = {
    title: i18n.text('alerts.all'),
    subtitle: `${i18n.text('alerts.create-and-manage-alerts')}.`,
    toolsSlot: renderToolsSlot(),
    headerButtonSlot: (
      <StyledPrimaryButton
        disableRipple
        onClick={handleCreateAlertClick}
        sx={{
          width: 'fit-content',
        }}
      >
        {i18n.text('alerts.configuration.create-new')}
      </StyledPrimaryButton>
    ),
    slotProps: {
      tableTitle: {
        sx: {
          borderBottom: `1px solid ${theme.palette.SFIGreyLight[200]}`,
        },
      },
      toolsSlot: {
        sx: {
          border: 'none',
        },
      },
      tableColumn: {
        sx: {
          borderTop: 'none',
          background: theme.palette.SFIBase.white,
        },
      },
    },
  }

  const searchType = getAlertSearchType(selectedProposition, propositions)

  const getCommodityType = () => {
    switch (selectedProposition) {
      case propositions.electricity.name:
      case propositions.oee.name:
        return CommodityType.ELECTRIC_CURRENT
      case propositions.gas.name:
        return CommodityType.NATURAL_GAS
      case propositions.water.name:
        return CommodityType.WATER
      default:
        return ''
    }
  }

  const noDataSlotData = {
    title: 'alerts.configuration.no-alert-rules-text',
    subtitle: 'alerts.configuration.no-alert-rules-explanation-text',
    slotProps: {
      subtitle: {
        sx: {
          width: '22rem',
        },
      },
      wrapper: {
        sx: {
          height: '20rem',
          paddingBottom: 0,
          paddingTop: '3rem',
        },
      },
    },
  }

  const [deleteGeneralAlertRule] = useDeleteGeneralAlertForCaMutation({
    onCompleted: () => {
      refetchAlertRules()
    },
  })

  const [
    runGetNewGeneralAlertRules,
    { loading: getNewGeneralAlertRulesLoading, data: newAlertRulesData, refetch: refetchNewAlertRules },
  ] = useGetAlertRulesLazyQuery()

  const [
    runGetOldGeneralAlertRules,
    { loading: getOldGeneralAlertRulesLoading, data: oldAlertRulesData, refetch: refetchOldAlertRules },
  ] = useGetAlertConfigurationRulesLazyQuery()

  const [
    runGetStandbyAlertRules,
    { loading: getStandbyAlertRulesLoading, data: standbyAlertsRulesData, refetch: refetchStandbyAlertRules },
  ] = useGetOldStandbyAlertsLazyQuery()

  const isLoading = getNewGeneralAlertRulesLoading || getOldGeneralAlertRulesLoading || getStandbyAlertRulesLoading

  const alertRulesRows = useAlertConfigurationRows({
    selectedProposition,
    propositions,
    selectedAlertTypeCategory,
    newAlertRulesData,
    oldAlertRulesData,
    standbyAlertsRulesData,
  })

  const totalRows = useMemo(() => {
    const isCaAlert = selectedProposition === propositions['compressed-air'].name
    if (isCaAlert) {
      return newAlertRulesData?.generalAlertRuleAggregate.aggregate?.count || 0
    }
    if (selectedAlertTypeCategory === AlertType.STANDBY_ALERT) {
      return standbyAlertsRulesData?.myOrg?.assets_aggregate.aggregate?.count || 0
    }
    return oldAlertRulesData?.myOrg?.alertRules_aggregate.aggregate?.count || 0
  }, [
    selectedAlertTypeCategory,
    selectedProposition,
    newAlertRulesData?.generalAlertRuleAggregate.aggregate?.count,
    oldAlertRulesData?.myOrg?.alertRules_aggregate.aggregate?.count,
    standbyAlertsRulesData?.myOrg?.assets_aggregate.aggregate?.count,
  ])

  const columns = getAlertRulesColumns({
    onDeleteButtonClick: handleDeleteButtonClick,
    onEditButtonClick: handleEditButtonClick,
  })

  useEffect(() => {
    setPage(DEFAULT_PAGE)
    setSelectedAlertTypeCategory(alertTypeCategories[0].value)
    setSearchText('')
  }, [selectedProposition])

  useEffect(() => {
    setPage(DEFAULT_PAGE)
    setSearchText('')
  }, [selectedAlertTypeCategory])

  useEffect(() => {
    setPage(DEFAULT_PAGE)
  }, [searchText])

  useEffect(() => {
    fetchAlertRules()
  }, [page, selectedProposition, selectedAlertTypeCategory, searchText])

  function fetchAlertRules() {
    const isRuleBasedAlert =
      selectedProposition !== propositions['compressed-air'].name &&
      selectedProposition !== propositions['predictive-maintenance'].name
    const isCaAlert = selectedProposition === propositions['compressed-air'].name

    if (isRuleBasedAlert && selectedAlertTypeCategory !== AlertType.STANDBY_ALERT) {
      runGetOldGeneralAlertRules({
        variables: {
          searchType,
          commodityType: getCommodityType(),
          limit: MAXIMUM_ROWS_PER_PAGE,
          offset: offsetValue,
          searchText,
        },
      })
    } else if (selectedAlertTypeCategory === AlertType.STANDBY_ALERT) {
      runGetStandbyAlertRules({
        variables: {
          limit: MAXIMUM_ROWS_PER_PAGE,
          offset: offsetValue,
          searchText,
        },
      })
    } else if (isCaAlert) {
      runGetNewGeneralAlertRules({
        variables: {
          limit: MAXIMUM_ROWS_PER_PAGE,
          offset: offsetValue,
          customerId,
          category: selectedAlertTypeCategory as unknown as General_Alert_Rule_Categories_Enum,
          searchText,
        },
      })
    }
  }

  function refetchAlertRules() {
    const isRuleBasedAlert =
      selectedProposition !== propositions['compressed-air'].name &&
      selectedProposition !== propositions['predictive-maintenance'].name

    const isCaAlert = selectedProposition === propositions['compressed-air'].name

    if (selectedAlertTypeCategory === AlertType.STANDBY_ALERT && refetchStandbyAlertRules) {
      refetchStandbyAlertRules()
    } else if (isRuleBasedAlert && refetchOldAlertRules) {
      refetchOldAlertRules()
    } else if (isCaAlert && refetchNewAlertRules) {
      refetchNewAlertRules()
    }
  }

  function handleEditButtonClick(id?: string, row?: IAlertRuleData) {
    setSelectedAlert({
      id: id || '',
      proposition: selectedProposition,
      category: row?.category,
    })
    setIsAlertConfigurationModalOpen(true)
  }

  function handleDeleteButtonClick(id?: string) {
    if (id) {
      setSelectedAlert({
        id,
      })
      setIsDeleteAlertRuleModalOpen(true)
    }
  }

  function onDeleteConfirm() {
    if (selectedAlert && selectedAlert.id) {
      const isCaAlert = selectedProposition === propositions['compressed-air'].name
      if (isCaAlert) {
        deleteGeneralAlertRule({ variables: { id: selectedAlert.id as ID } })
        setIsDeleteAlertRuleModalOpen(false)
      }
    }
  }

  function handleChangePage(newPage: number) {
    setPage(newPage)
  }

  function handleCreateAlertClick() {
    setSelectedAlert({
      id: '',
      proposition: selectedProposition,
      category: '',
    })
    setIsAlertConfigurationModalOpen(true)
  }

  function onPropositionChange(newValue: string) {
    setSelectedProposition(newValue)
  }

  function onAlertTypeCategoryChange(newValue: string) {
    setSelectedAlertTypeCategory(newValue as AlertType)
  }

  function renderToolsSlot() {
    return (
      <AlertsMessagesTableTools
        searchText={searchText}
        selectedItem={selectedAlertTypeCategory}
        onItemChange={onAlertTypeCategoryChange}
        items={alertTypeCategories}
        onSearchTextChange={e => {
          setSearchText(e.target.value)
        }}
      />
    )
  }

  function handleAlertModalClose() {
    setIsAlertConfigurationModalOpen(false)
    setSelectedAlert({
      id: '',
    })
  }

  return (
    <ConfigurationPageWrapper>
      <Title>{i18n.text('alerts.configuration.title')}</Title>
      <StyledToggleButtonGroup
        items={filteredPropositions}
        selectedItem={selectedProposition}
        onChange={onPropositionChange}
        slotProps={toggleButtonGroupSlotProps}
      />
      <CustomTable
        columns={columns}
        rows={alertRulesRows}
        isLoading={isLoading}
        noDataSlot={noDataSlotData}
        header={customTableHeaderProps}
        body={{
          rowColored: 'odd',
        }}
        isHeaderSticky={false}
        maxHeight="max-content"
        pagination={{
          totalRows,
          activePage: page,
          rowsPerPage: MAXIMUM_ROWS_PER_PAGE,
          onChange: handleChangePage,
        }}
        slotProps={customTableSlotProps}
      />
      {isAlertConfigurationModalOpen && (
        <AlertConfigurationModal
          selectedAlert={selectedAlert}
          isOpen={isAlertConfigurationModalOpen}
          onClose={handleAlertModalClose}
          refetchAlertRules={refetchAlertRules}
        />
      )}
      {isDeleteAlertRuleModalOpen && (
        <DeleteImageModal
          title={i18n.text('alerts.configuration.delete-alert')}
          subtitle={i18n.text('alerts.configuration.delete-alert.alert-text')}
          isOpen={isDeleteAlertRuleModalOpen}
          onClose={() => setIsDeleteAlertRuleModalOpen(false)}
          onDelete={onDeleteConfirm}
        />
      )}
    </ConfigurationPageWrapper>
  )
}

const ConfigurationPageWrapper = styled(Box)({
  marginTop: '2.25rem',
  overflowY: 'auto',
})

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[800],
  fontSize: '1.125rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
  marginBottom: '0.87rem',
}))
