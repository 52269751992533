import { createSvgIcon } from '@mui/material'

export const RocketIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M12.5 15L9.5 12M12.5 15C13.8968 14.4687 15.2369 13.7987 16.5 13M12.5 15V20C12.5 20 15.53 19.45 16.5 18C17.58 16.38 16.5 13 16.5 13M9.5 12C10.0321 10.6194 10.7022 9.29607 11.5 8.05C12.6652 6.18699 14.2876 4.65305 16.213 3.5941C18.1384 2.53514 20.3027 1.98637 22.5 2C22.5 4.72 21.72 9.5 16.5 13M9.5 12H4.5C4.5 12 5.05 8.97 6.5 8C8.12 6.92 11.5 8 11.5 8M5 16.5C3.5 17.76 3 21.5 3 21.5C3 21.5 6.74 21 8 19.5C8.71 18.66 8.7 17.37 7.91 16.59C7.52131 16.219 7.00929 16.0046 6.47223 15.988C5.93516 15.9714 5.41088 16.1537 5 16.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'rocket-icon'
)
