import {
  AnalyticsEventTypes,
  AnalyticsIntent,
  AnalyticsPropositionType,
  DefaultEventParams,
} from '../../../../Shared/hooks/useAnalytics/analyticsTypes'

interface TrackingReportEvent extends DefaultEventParams {
  proposition: AnalyticsPropositionType.APP
  target: string
  intent: AnalyticsIntent
}

export const trackStartNewReportEvent = (
  target: 'new_report_button' | 'no_reports_new_report_button',
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_START_NEW_REPORT,
    target,
  })
}

export const trackEditReportEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_EDIT_REPORT,
    target: 'edit_report_button',
  })
}
export const trackDeleteReportEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_DELETE_REPORT,
    target: 'delete_report_button',
  })
}
export const trackConfirmDeleteReportEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_CONFIRM_DELETE_REPORT,
    target: 'confirm_delete_report_button',
  })
}
export const trackCancelDeleteReportEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_CANCEL_DELETE_REPORT,
    target: 'cancel_delete_report_button',
  })
}
export const trackDuplicateReportEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_DUPLICATE_REPORT,
    target: 'duplicate_report_button',
  })
}
export const trackToggleReportEvent = (
  intent: AnalyticsIntent.TR_DISABLE_REPORT | AnalyticsIntent.TR_ENABLE_REPORT,
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent,
    target: 'report_toggle',
  })
}
export const trackSaveReportEvent = (
  intent: AnalyticsIntent.TR_SAVE_NEW_REPORT_GENERAL | AnalyticsIntent.TR_SAVE_EXISTING_REPORT_GENERAL,
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent,
    target: 'save_general_button',
  })
}
export const trackStartNewModuleEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_START_NEW_MODULE,
    target: 'add_module_button',
  })
}
export const trackPreviewReportEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_PREVIEW_REPORT,
    target: 'preview_report_button',
  })
}
export const trackDuplicateModuleEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_DUPLICATE_MODULE,
    target: 'duplicate_module_button',
  })
}
export const trackDeleteModuleEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_DELETE_MODULE,
    target: 'delete_module_button',
  })
}

export const trackStartEditModuleEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_START_EDIT_MODULE,
    target: 'edit_module_button',
  })
}
export const trackConfirmDeleteModuleEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_CONFIRM_DELETE_MODULE,
    target: 'confirm_delete_module_button',
  })
}
export const trackCancelDeleteModuleEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_CANCEL_DELETE_MODULE,
    target: 'cancel_delete_module_button',
  })
}
export const trackCancelEditModuleEvent = (
  target: 'modal_cancel_button' | 'modal_cross_button',
  intent: AnalyticsIntent.TR_CANCEL_EDIT_EXISTING_MODULE | AnalyticsIntent.TR_CANCEL_EDIT_NEW_MODULE,
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent,
    target,
  })
}
export const trackToggleModuleEvent = (
  intent: AnalyticsIntent.TR_ENABLE_MODULE | AnalyticsIntent.TR_DISABLE_MODULE,
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent,
    target: 'tr_module_toggle',
  })
}

export const trackSaveModuleEvent = (
  target: 'save_new_module_button' | 'save_existing_module_button',
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_SAVE_MODULE,
    target,
  })
}

export const trackBackToOverviewEvent = (
  target: 'modules_back_to_overview_button' | 'general_back_to_overview_button',
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_BACK_TO_OVERVIEW,
    target,
  })
}

export const trackModuleOrderChangeEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_INPUT_CHANGED, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_INPUT_CHANGED, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_CHANGE_MODULE_ORDER,
    target: 'drag_module_order',
  })
}

export const trackSupportEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_EXPAND_SUPPORT,
    target: 'support_button',
  })
}

export const trackOpenKnowledgeBaseEvent = (
  sendEvent: (name: AnalyticsEventTypes.TRACKED_BUTTON_CLICK, params: TrackingReportEvent) => void
) => {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.APP,
    intent: AnalyticsIntent.TR_OPEN_KNOWLEDGE_BASE,
    target: 'knowledge_base_external_link',
  })
}
