import { Box } from '@mui/material'
import { FC } from 'react'

type SupportVideoProps = {
  explanationVideoUrl: string
}

export const SupportVideo: FC<SupportVideoProps> = ({ explanationVideoUrl }) => (
  <>
    <Box
      sx={{
        padding: '56.25% 0 0 0',
        position: 'relative',
      }}
    >
      <iframe
        src={explanationVideoUrl}
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        title="Sensorfact | Tracking Report explanation video"
      ></iframe>
    </Box>
    <script src="https://player.vimeo.com/api/player.js"></script>
  </>
)
