import React, { FC } from 'react'
import { Interval } from 'luxon'

import SimpleCsvExporter, {
  CsvDataMapper,
  CsvHeaderMapper,
  CsvHeaders,
} from '../../../Shared/components/MUIComponents/ChartTools/CsvExporter'
import { AllSeriesData, ISeriesData, SwitchUnit } from '../../types/compressed-air_types'

interface CAFlowCsvExporterProps {
  seriesData: AllSeriesData
  interval: Interval
  decimal: number
  filename?: string
  unit?: SwitchUnit
}

const CAFlowCsvExporter: FC<CAFlowCsvExporterProps> = ({
  interval,
  unit: unitProp,
  filename,
  decimal,
  seriesData: { flow: flowSeriesData, temperature: temperatureSeriesData, pressure: pressureSeriesData },
}) => {
  const headers: CsvHeaders = ['date', 'type', 'min', 'value', 'max', 'unit']

  const mapHeaders: CsvHeaderMapper = (headers, { valueSeparator }) => {
    return headers.join(valueSeparator)
  }

  const mapDataToHeaders: CsvDataMapper<ISeriesData[]> = (data, { valueSeparator, decimalSeparator }) => {
    const result: string[] = []
    mapData(data, { valueSeparator, decimalSeparator }, result, unitProp)
    mapData(pressureSeriesData, { valueSeparator, decimalSeparator }, result, SwitchUnit.bar)
    mapData(temperatureSeriesData, { valueSeparator, decimalSeparator }, result, SwitchUnit.celsius)
    return result.join('\n')
  }

  function mapData(
    data: ISeriesData[],
    { valueSeparator, decimalSeparator }: { valueSeparator: string; decimalSeparator: string },
    result: string[],
    unitName?: SwitchUnit
  ) {
    data.forEach(asset => {
      asset.data.forEach(measurement => {
        const { value, min, max, time } = measurement
        const formattedValue = value !== null ? `${value.toFixed(decimal)}`.replace('.', decimalSeparator) : '-'
        const formattedMin = min ? `${min.toFixed(decimal)}`.replace('.', decimalSeparator) : 'none'
        const formattedMax = max ? `${max.toFixed(decimal)}`.replace('.', decimalSeparator) : 'none'
        const unit = unitName
        const row = `${time}${valueSeparator}${asset.name}${valueSeparator}${formattedMin}${valueSeparator}${formattedValue}${valueSeparator}${formattedMax}${valueSeparator}${unit}`
        result.push(row)
      })
    })
  }

  return (
    <SimpleCsvExporter
      headers={headers}
      mapHeaders={mapHeaders}
      data={flowSeriesData}
      mapDataToHeaders={mapDataToHeaders}
      interval={interval}
      filename={filename}
      slotProps={{
        button: {
          sx: {
            background: 'none',
            padding: 0,
            '&:hover': {
              background: 'none',
            },
          },
        },
      }}
    />
  )
}

export { CAFlowCsvExporter }
