import { Box, styled } from '@mui/material'

export const SectionBoxWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.SFIBase.white,
  padding: theme.spacing(2),
  boxShadow: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A',
  borderRadius: theme.spacing(1.25),
}))

export const TableBoxWrapper = styled(SectionBoxWrapper)(({ theme }) => ({
  padding: 0,
  overflow: 'hidden',
}))

export const FitHeightBoxWrapper = styled(SectionBoxWrapper)(({ theme }) => ({
  height: 'fit-content',
}))
