import { themeColors } from '../../../Shared/utils'
export const qualities = {
  excellent: {
    label: 'Excellent',
    color: themeColors.secondary,
  },
  good: {
    label: 'Good',
    color: themeColors.successDark,
  },
  fair: {
    label: 'Fair',
    color: themeColors.warning,
  },
  poor: {
    label: 'Poor',
    color: themeColors.dangerDark,
  },
  unknown: {
    label: 'unknown',
    color: themeColors.greyLight,
  },
}

export type SignalQuality = keyof typeof qualities
