import { Redirect, Switch } from 'react-router-dom'

import AppContainer from '../containers/AppContainer'
import DownloadPage from '../containers/DownloadPage/DownloadPage'
import LoginPage from '../containers/LoginPage'
import { SetPasswordPage } from '../containers/SetPasswordPage/SetPasswordPage'
import { EnergyScanContainer } from '../../Reports'
import { PreviewDownloadPage } from '../containers/EnergyReports/ReportSettings/PreviewDownloadPage'
import { PrivateRoute } from '../../Shared/components/RouteComponents/PrivateRoute'
import { SentryRoute as Route } from '../../Shared/services/sentry'
import { useAuthContext } from '../../Shared/contexts/AuthContext'

const Routes = () => {
  const { user, login, allowedRoles } = useAuthContext()
  const userHasAdminRole = allowedRoles.includes('employee-admin')

  return (
    <Switch>
      <Route
        path="/login"
        render={props => (
          <LoginPage
            {...props}
            user={user}
            login={login}
          />
        )}
      />
      <Route
        path="/setpassword"
        render={props => <SetPasswordPage user={user} />}
      />
      <Route
        path="/download"
        component={DownloadPage}
      ></Route>
      <Route
        path="/downloadpreview/:reportId"
        component={PreviewDownloadPage}
      ></Route>
      {userHasAdminRole && (
        <Route
          path="/energy-scan"
          component={EnergyScanContainer}
        />
      )}
      <PrivateRoute
        path="/"
        component={AppContainer}
      />
      <Redirect to="/" />
    </Switch>
  )
}

export default Routes
