import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'

import { FileTypeIcon } from '../../../../icons/FileTypeIcon'
import { TrashIcon } from '../../../../icons/TrashIcon'

/**
 * @description - UploadedFileItem is a component that represents a file in an UploadImageModal component
 * @param {string} error - Error text if applicable
 * @param {File} file - A file itself
 * @param {number} index - An index of a file in a files array
 * @param {function(index: number, name: string): void} onRemoveFile - A function to remove file from a files list
 */

interface IUploadedFileItemProps {
  error: string
  file: File
  index: number
  onRemoveFile: (index: number, name: string) => void
}

const textStyle = {
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const UploadedFileItem: FC<IUploadedFileItemProps> = ({ error, file, index, onRemoveFile }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '1rem',
        alignItems: 'flex-start',
        gap: '0.75rem',
        borderRadius: '0.75rem',
        border: theme =>
          error ? `1px solid ${theme.palette.error.contrastText}` : `1px solid ${theme.palette.SFIGreyLight[200]}`,
        background: theme => theme.palette.SFIBase.white,
      }}
    >
      <FileTypeIcon
        sx={{
          width: '2.5rem',
          height: '2.5rem',
        }}
      />
      <Box>
        <Typography
          sx={{
            color: theme => theme.palette.SFIGreyLight[700],
            fontWeight: 500,
            maxWidth: '16rem',
            ...textStyle,
          }}
        >
          {file.name}
        </Typography>
        <Typography
          sx={{
            color: theme => theme.palette.SFIGreyLight[600],
            fontWeight: 400,
            ...textStyle,
          }}
        >
          {Math.ceil(file.size / 1024)} KB
        </Typography>
        {error && (
          <Typography
            sx={{
              color: theme => theme.palette.error.contrastText,
              fontWeight: 400,
              ...textStyle,
            }}
          >
            {`Error: ${error}`}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          marginLeft: 'auto',
          color: theme => theme.palette.SFIGreyLight[600],
          cursor: 'pointer',
        }}
        onClick={() => onRemoveFile(index, file.name)}
      >
        <TrashIcon
          sx={{
            width: '1.25rem',
            height: '1.25rem',
          }}
        />
      </Box>
    </Box>
  )
}
