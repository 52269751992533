import { createSvgIcon } from '@mui/material'

export const BellOnIcon = createSvgIcon(
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.01565 15.75C7.5445 16.2168 8.23918 16.5 9.00001 16.5C9.76085 16.5 10.4555 16.2168 10.9844 15.75M13.5 6C13.5 4.80653 13.0259 3.66193 12.182 2.81802C11.3381 1.97411 10.1935 1.5 9.00001 1.5C7.80654 1.5 6.66195 1.97411 5.81803 2.81802C4.97412 3.66193 4.50001 4.80653 4.50001 6C4.50001 8.31764 3.91537 9.90447 3.26226 10.9541C2.71136 11.8394 2.43591 12.2821 2.44601 12.4056C2.45719 12.5423 2.48616 12.5944 2.59635 12.6762C2.69586 12.75 3.14446 12.75 4.04165 12.75H13.9584C14.8556 12.75 15.3042 12.75 15.4037 12.6762C15.5139 12.5944 15.5428 12.5423 15.554 12.4056C15.5641 12.2821 15.2887 11.8394 14.7378 10.9541C14.0847 9.90447 13.5 8.31764 13.5 6Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'bell-on'
)
