import { Box, SelectChangeEvent } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import { DateTime } from 'luxon'

import { EBUBasicOeeAsset, EnergyByUnitOutputMetricUnits, OeeAssetRowState } from '../../types'
import { OeeAssetFormRow } from './OeeAssetFormRow'

interface OeeFormProps {
  oeeMachines: EBUBasicOeeAsset[]
  oeeAssetRows: OeeAssetRowState[]
  outputMetric: EnergyByUnitOutputMetricUnits
  onAssetChange: (key: string, event: SelectChangeEvent<string>) => void
  onDateChange: (key: string, startDate: DateTime, endDate: DateTime) => void
  onConversionRatioChange: (key: string, event: ChangeEvent<HTMLInputElement>) => void
  onAssetRemove: (key: string) => void
}
export const OeeForm: FC<OeeFormProps> = ({
  oeeMachines,
  oeeAssetRows,
  onAssetChange,
  onConversionRatioChange,
  onDateChange,
  onAssetRemove,
  outputMetric,
}) => {
  return (
    <Box>
      {oeeAssetRows.map(oeeRow => (
        <OeeAssetFormRow
          oeeMachines={oeeMachines}
          selectedAssetId={oeeRow.selectedAssetId}
          key={oeeRow.key}
          uniqueKey={oeeRow.key}
          startDate={oeeRow.startDate}
          endDate={oeeRow.endDate}
          conversionRatio={oeeRow.conversionRatio}
          outputMetric={outputMetric}
          onAssetChange={onAssetChange}
          onDateChange={onDateChange}
          onConversionRatioChange={onConversionRatioChange}
          onAssetRemove={onAssetRemove}
        />
      ))}
    </Box>
  )
}
