import { DateTime, Interval } from 'luxon'

import {
  CompressedAirSensorLocationFragment,
  EnoceanSensorFragment,
  InstallationAssetTreeFragment,
  InstallationFlatAssetsFragment,
  PdmSensorFragment,
  SensorInstallationBridgeFragment,
} from '../../../../Shared/graphql/codegen'
import { SignalQuality } from '../../../components/PressacBridge/constants'
import { sensorSignalToString } from '../../../components/PressacBridge/utils'

export interface GroupSensors {
  name: string
  sensors: SensorSummary[]
}

interface SensorSummary {
  externalId: string
  machine: {
    assetId: string
    name: string
  }
  statusOk: boolean
  connection?: {
    name?: string
    quality?: SignalQuality
  }
}

export const formatSensorTableGroups = (
  flatAssets: InstallationFlatAssetsFragment[],
  assetTree: InstallationAssetTreeFragment[],
  bridges: SensorInstallationBridgeFragment[],
  airEnabled: boolean
): Map<string, GroupSensors> => {
  // Map where each key is an Asset group id, and the value is the name
  // of the group and an array of all sensors belonging to that group
  const groupSensorsMap = new Map<string, GroupSensors>()

  const assetGroups = assetTree.map(group => ({
    id: group.id,
    name: group.name,
    nestedAssetIds: [...group.assets.map(a => a.id), ...group.assets.flatMap(a => a.assets).map(a => a.id)],
  }))

  const assetGroupIds = assetGroups.map(g => g.id)

  flatAssets.forEach(asset => {
    if (
      asset.enoceanSensors.length === 0 &&
      asset.pdmSensors.length === 0 &&
      asset.compressedAirFlowSensorLocations.length === 0
    )
      return
    const assetParentGroup = assetGroups.find(g => g.nestedAssetIds.includes(asset.id))

    if (!assetParentGroup) {
      console.error('parent group not found!')
      return
    }

    const sensorGroup = getSensorGroup(groupSensorsMap, assetParentGroup)

    // Compressed air sensors added to the table groups
    if (airEnabled) {
      asset.compressedAirFlowSensorLocations.forEach(sensorLocation => {
        sensorGroup.sensors.push(formatCompressedAirSensor(sensorLocation))
      })
    }

    asset.enoceanSensors.forEach(sensor =>
      sensorGroup.sensors.push(formatEnoceanSensor(sensor, assetGroupIds, bridges))
    )

    asset.pdmSensors.forEach(sensor => sensorGroup.sensors.push(formatPdmSensor(sensor)))

    // filter empty groups
    for (const entry of Array.from(groupSensorsMap.entries())) {
      const key = entry[0]
      const value = entry[1]

      if (value.sensors.length === 0) {
        groupSensorsMap.delete(key)
      }
    }
  })

  return new Map([...groupSensorsMap].sort(([key1, value1], [key2, value2]) => value1.name.localeCompare(value2.name)))
}

export const formatEnoceanSensor = (
  sensor: EnoceanSensorFragment,
  assetGroupIds: string[],
  bridges: SensorInstallationBridgeFragment[]
): SensorSummary => {
  // we know that the sensor is connected to an asset
  const asset = sensor.asset!

  const statusOk =
    asset.energy.reduce((total, item) => total + item.value, 0) > 0 ||
    asset.volume.reduce((total, item) => total + item.value, 0) > 0

  let best: { name: string; signal: number } | undefined = undefined

  for (let i = 0; i < bridges.length; i++) {
    const bridge = bridges[i]

    // bridgify returns lowercase externalId of sensor
    const sensorMatch = bridge.bridgify.seenSensors?.find(s => s.id.toUpperCase() === sensor.externalId)

    if (!sensorMatch?.signal) {
      continue
    }

    if (!best) {
      best = { name: bridge.bridgeId, signal: sensorMatch.signal }
    } else {
      if (sensorMatch.signal > best.signal) {
        best = { name: bridge.bridgeId, signal: sensorMatch.signal }
      }
    }
  }

  // we query only EnoceanSensorFragments for assets where: { parentId: { _is_null: false } }
  // so we know parent is not null
  const assetIsMachine = assetGroupIds.includes(sensor.asset!.parent!.id)
  const machine = assetIsMachine ? asset : asset.parent!

  return {
    externalId: sensor.externalId,
    machine: {
      assetId: machine.id,
      name: machine.name,
    },
    statusOk,
    connection: {
      name: best?.name,
      quality: sensorSignalToString(best?.signal),
    },
  }
}

export const formatPdmSensor = (sensor: PdmSensorFragment): SensorSummary => {
  const lastMessageAt = sensor.insights?.lastMessage?.time

  // we know that the sensor is on a 3rd level asset
  const probeLocation = sensor.asset!
  const machine = probeLocation.parent!

  const statusOk = lastMessageAt
    ? Interval.fromDateTimes(DateTime.fromISO(lastMessageAt), DateTime.now()).length('hours') <= 24
    : false

  const signalStrength = sensor.insights?.connectionStatus?.signalStrength?.value

  const signalQuality: SignalQuality =
    signalStrength === undefined || signalStrength === null
      ? 'unknown'
      : sensorSignalToString(signalStrength * 100 - 100)

  return {
    externalId: sensor.externalId,
    machine: {
      assetId: machine.id,
      name: machine.name,
    },
    statusOk,
    connection: {
      name: sensor.insights?.connectionStatus?.wifiConnection?.ssid,
      quality: signalQuality,
    },
  }
}

const formatCompressedAirSensor = (sensorLocation: CompressedAirSensorLocationFragment): SensorSummary => {
  return {
    externalId: sensorLocation.sensorConnection?.connection?.compressedAirFlowSensor.neuronSerialNr || '',
    machine: {
      assetId: sensorLocation.asset.id,
      name: sensorLocation.asset.name,
    },
    statusOk: calculateStatusOk(sensorLocation),
  }
}

const calculateStatusOk = (sensor: CompressedAirSensorLocationFragment): boolean => {
  const measurements24h = sensor.insights?.timeWindow.flow.filter(point => {
    return point.time > DateTime.now().minus({ hours: 24 }).toISO()
  })
  if (measurements24h && measurements24h.length > 0) return true
  return false
}

const getSensorGroup = (GroupsMap: Map<string, GroupSensors>, group: { id: string; name: string }): GroupSensors => {
  let sensorGroup = GroupsMap.get(group.id)

  if (!sensorGroup) {
    sensorGroup = {
      name: group.name,
      sensors: [],
    }

    GroupsMap.set(group.id, sensorGroup)
  }

  return sensorGroup
}

export const isValidMachineName = (input: string, assetNames: Array<string>) => {
  return (
    typeof input === 'string' &&
    input.trim().length >= 1 &&
    !input.includes(',') &&
    !input.includes(';') &&
    !assetNames.includes(input)
  )
}
