import React, { FC } from 'react'
import { Box, styled } from '@mui/material'

import { SearchIcon } from '../../../Shared/components/icons/SearchIcon'
import { StyledSearchTextField } from '../../../Shared/components/MUIComponents/update/styledComponents/StyledSearchTextField'
import { StyledToggleButtonGroup, StyledToggleButtonGroupItem } from '../../../Shared/components/MUIComponents/update/StyledToggleButtonGroup'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const ToolsWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  gridTemplateColumns: '1fr 20rem',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

interface IAlertsMessagesTableToolsProps {
  searchPlaceholder?: string
  searchText: string
  items: StyledToggleButtonGroupItem[]
  selectedItem: string
  onSearchTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onItemChange: (val: string) => void
}

export const AlertsMessagesTableTools: FC<IAlertsMessagesTableToolsProps> = ({
  searchPlaceholder,
  selectedItem,
  searchText,
  items,
  onItemChange,
  onSearchTextChange,
}) => {
  const { i18n } = useI18nContext()

  return (
    <ToolsWrapper>
      <StyledToggleButtonGroup
        items={items}
        selectedItem={selectedItem}
        onChange={onItemChange}
        slotProps={{
          wrapper: {
            sx: {
              borderRadius: '0.5rem',
            },
          },
        }}
      />
      <StyledSearchTextField
        value={searchText}
        startAdornment={<SearchIcon />}
        onChange={onSearchTextChange}
        placeholder={searchPlaceholder ?? i18n.text('alerts.messages.search-asset')}
      />
    </ToolsWrapper>
  )
}
