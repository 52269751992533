import React, { useCallback, useEffect, useMemo } from 'react'
import { ApexOptions } from 'apexcharts'
import { Box, Typography, useTheme } from '@mui/material'
import { DateTime, Interval } from 'luxon'
import { FC } from 'react'
import { useHistory, useParams } from 'react-router'

import { ApexWrapper } from '../../../Shared/components/ApexWrapper'
import { CustomLegend } from '../LegendsWrapper'
import { FromToValues } from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker/types'
import { IChartData, SeriesNames, UnitName } from '../../types'
import { ResponsiveApexGraph } from '../../../Shared/components/ResponsiveApexGraph'
import { SectionBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { getHasHumidity, getIsTemperatureSeries } from '../../utils/apexSeries'
import { useChartToolsContext } from '../../context/ChartToolsContext'
import { useFlowOptions } from '../../hooks/useApexOptions'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useTemperatureContext } from '../../context/TemperatureContext'

interface ITrendsContainerProps {
  handleIsZoomed: (val: boolean) => void
  handleZoomedDates: (dates: FromToValues) => void
}

export const TrendsContainer: FC<ITrendsContainerProps> = ({ handleZoomedDates, handleIsZoomed }) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { selectedDateRange, areSeriesLoading, apexSeries, seriesData, assets, setSelectedLocationId } =
    useTemperatureContext()
  const { showMean, setShowMean, showThresholds, setShowThresholds, showMinMaxRange, setShowMinMaxRange } =
    useChartToolsContext()
  const { sensorLocationId } = useParams<{ sensorLocationId: string }>()
  const history = useHistory()
  const interval = Interval.fromDateTimes(selectedDateRange.startDate, selectedDateRange.endDate)

  const chartData: IChartData = {
    id: 'TemperatureGraphId',
    seriesData,
  }

  const meanAnnotations = useMemo(() => {
    if (!showMean) {
      return []
    }
    return apexSeries
      .filter(item => item.name === SeriesNames.temperature || item.name === SeriesNames.humidity)
      .map(item => {
        const isTemperatureSeries = getIsTemperatureSeries(item.name)
        const onlyYNumbers = item.data.map(coords => (coords ? (coords as any).y : 0))
        const mean = calculateMean(onlyYNumbers)
        const unitName = isTemperatureSeries ? UnitName.celsius : UnitName.percentage
        return {
          y: mean,
          yAxisIndex: isTemperatureSeries ? 0 : showMinMaxRange ? 2 : 1,
          strokeDashArray: 0,
          borderColor: item.color,
          label: {
            borderColor: item.color,
            style: {
              color: theme.palette.SFIBase.white,
              background: item.color,
            },
            text: `${i18n.text('analysis.mean')}: ${mean.toFixed(0)} ${unitName}`,
          },
        }
      })
  }, [showMean, apexSeries])

  const annotations: YAxisAnnotations[] = useMemo(() => {
    return [...meanAnnotations]
  }, [meanAnnotations])

  const hasHumidity = useMemo(() => getHasHumidity(apexSeries), [apexSeries])

  const temperatureMaxValue = useMemo(() => {
    const maxValue = seriesData?.[0]?.data?.reduce(
      (acc, curr) => (curr.max ? (acc > curr.max ? acc : curr.max) : acc > curr.value ? acc : curr.value),
      0
    )
    return Math.round(maxValue) + 10
  }, [seriesData])

  const humidityMaxValue = useMemo(() => {
    if (hasHumidity) {
      const maxValue = seriesData?.[1]?.data?.reduce(
        (acc, curr) => (curr.max ? (acc > curr.max ? acc : curr.max) : acc > curr.value ? acc : curr.value),
        0
      )
      return Math.round(maxValue)
    }
    return 100
  }, [hasHumidity, seriesData])

  const onZoomChange = useCallback(
    (chart: unknown, changed: { xaxis: { min: number; max: number } }) => {
      const { xaxis } = changed

      const startDate = typeof xaxis?.min === 'number' ? DateTime.fromMillis(xaxis?.min) : null
      const endDate = typeof xaxis?.max === 'number' ? DateTime.fromMillis(xaxis?.max) : null
      handleZoomedDates({ from: startDate, to: endDate })
      handleIsZoomed(true)
    },
    [handleIsZoomed, handleZoomedDates]
  )

  const temperatureFlowOptions: ApexOptions = useFlowOptions({
    isMinMaxEnabled: showMinMaxRange,
    hasHumidity,
    chartData,
    annotations,
    from: selectedDateRange.startDate.toMillis(),
    to: selectedDateRange.endDate.toMillis(),
    temperatureMaxValue: temperatureMaxValue || 50,
    humidityMaxValue: humidityMaxValue,
    interval,
    handleZoomChange: onZoomChange,
  })

  const toolbarItems = useMemo(
    () => [
      {
        label: i18n.text('graph.button.tools.show-mean'),
        isChecked: showMean,
        onToggle: () => setShowMean(!showMean),
      },
      {
        label: i18n.text('graph.button.tools.show-thresholds'),
        isChecked: showThresholds,
        isDisabled: true,
        onToggle: () => setShowThresholds(!showThresholds),
      },
      {
        label: i18n.text('air.graphs.min-max-range'),
        isChecked: showMinMaxRange,
        onToggle: () => setShowMinMaxRange(!showMinMaxRange),
        tooltipText: i18n.text('graph.button.tools.show-min-max-range.tooltip'),
      },
    ],
    [showMean, showThresholds, showMinMaxRange]
  )

  useEffect(() => {
    if (sensorLocationId) {
      const isValidSensorLocationId = assets.some(asset =>
        asset.temperatureSensorLocations.some(location => location.id === sensorLocationId)
      )
      if (isValidSensorLocationId) {
        setSelectedLocationId(sensorLocationId)
      } else if (assets.length > 0) {
        const firstSensorLocation = assets[0].temperatureSensorLocations[0].id
        history.push(`/temperature/flow/${firstSensorLocation}`)
      } else {
        setSelectedLocationId('')
      }
    }
  }, [sensorLocationId])

  function calculateMean(arr: number[]) {
    return arr.reduce((acc, curr) => acc + curr / arr.length, 0)
  }

  return (
    <SectionBoxWrapper>
      <ApexWrapper>
        {areSeriesLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '80vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h5"
              fontWeight={400}
              textAlign="center"
            >
              {i18n.text('app.loading')}
            </Typography>
          </Box>
        ) : (
          <ResponsiveApexGraph
            chartData={{
              series: apexSeries,
              options: temperatureFlowOptions,
              type: 'rangeArea',
              id: 'temperature-flow-chart',
            }}
            toolbarItems={toolbarItems}
            hasToolbar={apexSeries.length > 0}
          />
        )}
        <CustomLegend hasHumidity={hasHumidity} />
      </ApexWrapper>
    </SectionBoxWrapper>
  )
}
