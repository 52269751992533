import React from 'react'
import html2canvas, { Options } from 'html2canvas'
import { ButtonProps } from '@mui/material'

import useAnalytics from '../../../hooks/useAnalytics/useAnalytics'
import { AnalyticsEventTypes, DownloadPNG } from '../../../hooks/useAnalytics/analyticsTypes'
import { StyledChartToolsButton } from './StyledChartToolsButton'
import { useI18nContext } from '../../../contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../contexts/ToastContext'

type PngExportProps = {
  element?: HTMLElement | null
  fileName?: string
  options?: Partial<Options>
  slotProps?: {
    button?: ButtonProps
  }
}

const PngExporter: React.FC<PngExportProps> = ({ element, fileName, options, slotProps }) => {
  const { showToast } = useToastContext()
  const { sendEvent } = useAnalytics()
  const { i18n } = useI18nContext()

  function onClickExportButton() {
    if (element == null) {
      return showToast(
        i18n.text('analysis.png-export.error.message.body'),
        'warning',
        i18n.text('analysis.png-export.error.message.title')
      )
    }

    sendEvent<DownloadPNG>(AnalyticsEventTypes.TRACKED_DOWNLOAD, { target: 'png' })

    return html2canvas(element, options).then(canvas => {
      const root = document.body
      canvas.style.display = 'none'
      const image = canvas.toDataURL('png')
      const workElement = document.createElement('a')
      workElement.setAttribute('download', `${fileName}.png`)
      workElement.setAttribute('href', image)
      root.appendChild(workElement)
      workElement.click()
      root.removeChild(workElement)
    })
  }

  return (
    <>
      <StyledChartToolsButton
        onClick={onClickExportButton}
        {...slotProps?.button}
      >
        {i18n.text('tooltip.png-export-button')}
      </StyledChartToolsButton>
    </>
  )
}

export default PngExporter
