import { createSvgIcon } from '@mui/material'

export const FileSettingsIcon = createSvgIcon(
  <svg
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9.5V6.3C19 4.61984 19 3.77976 18.673 3.13803C18.3854 2.57354 17.9265 2.1146 17.362 1.82698C16.7202 1.5 15.8802 1.5 14.2 1.5H7.8C6.11984 1.5 5.27976 1.5 4.63803 1.82698C4.07354 2.1146 3.6146 2.57354 3.32698 3.13803C3 3.77976 3 4.61984 3 6.3V16.7C3 18.3802 3 19.2202 3.32698 19.862C3.6146 20.4265 4.07354 20.8854 4.63803 21.173C5.27976 21.5 6.11984 21.5 7.8 21.5H11M11.5 10.5H7M8 14.5H7M15 6.5H7"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5449 21.0393L15.8711 21.7695C15.9681 21.9869 16.1264 22.1716 16.3268 22.3012C16.5271 22.4308 16.761 22.4998 17 22.4998C17.239 22.4998 17.4729 22.4308 17.6732 22.3012C17.8736 22.1716 18.0319 21.9869 18.1289 21.7695L18.4551 21.0393C18.5713 20.7802 18.7666 20.5641 19.0134 20.422C19.2617 20.2795 19.5489 20.2188 19.834 20.2485L20.6323 20.3331C20.8699 20.3581 21.1097 20.314 21.3226 20.2061C21.5355 20.0982 21.7124 19.9311 21.8318 19.7251C21.9514 19.5192 22.0084 19.2833 21.9958 19.0458C21.9833 18.8084 21.9017 18.5797 21.7611 18.3874L21.2885 17.7411C21.1202 17.5093 21.0303 17.2302 21.0317 16.9442C21.0317 16.659 21.1224 16.3811 21.291 16.1504L21.7636 15.5041C21.9042 15.3119 21.9857 15.0832 21.9983 14.8457C22.0108 14.6083 21.9539 14.3723 21.8343 14.1664C21.7149 13.9605 21.538 13.7934 21.3251 13.6855C21.1122 13.5775 20.8724 13.5334 20.6347 13.5584L19.8365 13.643C19.5514 13.6728 19.2641 13.6121 19.0159 13.4695C18.7686 13.3266 18.5732 13.1094 18.4576 12.8492L18.1289 12.1189C18.0319 11.9015 17.8736 11.7168 17.6732 11.5872C17.4729 11.4576 17.239 11.3886 17 11.3887C16.761 11.3886 16.5271 11.4576 16.3268 11.5872C16.1264 11.7168 15.9681 11.9015 15.8711 12.1189L15.5449 12.8492C15.4293 13.1094 15.2339 13.3266 14.9866 13.4695C14.7383 13.6121 14.4511 13.6728 14.166 13.643L13.3653 13.5584C13.1276 13.5334 12.8878 13.5775 12.6749 13.6855C12.462 13.7934 12.2851 13.9605 12.1657 14.1664C12.0461 14.3723 11.9892 14.6083 12.0017 14.8457C12.0143 15.0832 12.0958 15.3119 12.2364 15.5041L12.709 16.1504C12.8776 16.3811 12.9683 16.659 12.9683 16.9442C12.9683 17.2295 12.8776 17.5074 12.709 17.7381L12.2364 18.3844C12.0958 18.5766 12.0143 18.8053 12.0017 19.0427C11.9892 19.2802 12.0461 19.5161 12.1657 19.722C12.2852 19.9279 12.4621 20.0949 12.675 20.2028C12.8879 20.3107 13.1277 20.3549 13.3653 20.33L14.1635 20.2455C14.4486 20.2157 14.7359 20.2764 14.9841 20.4189C15.2323 20.5615 15.4286 20.7787 15.5449 21.0393Z"
      stroke="currentColor"
      strokeWidth="1.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.999 18.6109C17.9239 18.6109 18.6737 17.8647 18.6737 16.9442C18.6737 16.0238 17.9239 15.2776 16.999 15.2776C16.0741 15.2776 15.3243 16.0238 15.3243 16.9442C15.3243 17.8647 16.0741 18.6109 16.999 18.6109Z"
      stroke="currentColor"
      strokeWidth="1.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'file-settings-icon'
)
