import Dropdown from '@sensorfactdev/nucleus/Dropdown'
import Heading from '@sensorfactdev/nucleus/Heading'
import Loader from '@sensorfactdev/nucleus/Loader'
import NucleusSimpleTable from '@sensorfactdev/nucleus/SimpleTable'
import PieChart from '@sensorfactdev/nucleus/PieChart'
import Tools from '@sensorfactdev/nucleus/Chart/Tools'
import styled from 'styled-components'
import React, { Component } from 'react'
import { List } from 'immutable'
import { connect } from 'react-redux'

import CenterMessage from '../../../../Shared/components/CenterMessage'
import GenericError from '../../../../Shared/components/GenericError'
import { DEFAULT_TIMEZONE } from '../../../../Shared/constants/timezone'
import { DateRange } from '../../../../Shared/types/analysis_types'
import { isDateOutsideOfRange } from '../../../../Shared/utils'
import { prepareData } from './utils'
import { themeColors } from '../../../../Shared/utils'

const SimpleTable: $TSFixMe = NucleusSimpleTable

const headingDateOpts = {
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZone: DEFAULT_TIMEZONE,
}
const LocalWrapper = styled.div`
  display: flex;
`
const MainContainer = styled.div`
  padding: 0 10px 0 1em;
  width: 100%;
  text-align: left;
  background-color: ${themeColors.white};
  min-height: ${({
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isDashboard' does not exist on type 'Pic... Remove this comment to see the full error message
    isDashboard,
  }) => (isDashboard ? '100vh' : '75vh')};
`
const HeadingContainer = styled.div`
  position: relative;
`
const TableHeadingContainer = styled.div`
  margin: 25px 0 10px 0;
`
const TableWrapper = styled.div`
  margin-top: 1em;
  max-width: 50%;
  width: 100%;
  float: left;

  & > div {
    width: 100%;
  }
`
const PieChartWrapper = styled.div`
  max-width: 50%;
  width: 100%;
  float: left;
  text-align: center;
  padding-top: 1em;
`
const AssetRow = styled(SimpleTable.TR)`
  background-color: ${({ highlighted }) => (highlighted ? themeColors.primaryLight : themeColors.white)};
`
const TotalRow = styled(SimpleTable.TR)`
  td {
    font-weight: 500;
  }
  background-color: ${themeColors.greyLight};
`
type OwnEnergyBalanceProps = {
  app: $TSFixMe
  analysis: $TSFixMe
  customer: $TSFixMe
  params: {
    id?: string
  }
  measurements: $TSFixMe
  i18n: $I18FixMe
  selectedDateRange: {
    startDate: $TSFixMe
    endDate: $TSFixMe
  }
  onTravelInTime: $TSFixMeFunction
  onComponentSelect: $TSFixMeFunction
  selectedAssets: string[][]
  selectedComponents: string[]
  pending: boolean
  error: boolean
  isDashboard?: boolean
  linkToDashboard?: string
}
type EnergyBalanceState = $TSFixMe
type EnergyBalanceProps = OwnEnergyBalanceProps & typeof EnergyBalance.defaultProps
class EnergyBalance extends Component<EnergyBalanceProps, EnergyBalanceState> {
  static defaultProps = {
    isDashboard: false,
    linkToDashboard: '',
  }
  chartContainer: $TSFixMe
  constructor(props: EnergyBalanceProps) {
    super(props)
    this.state = {
      highlighted: null,
      selectedColumn: 'volumeConsumption',
    }
    this.onColumnSelect = this.onColumnSelect.bind(this)
    this.onTableRowHighlight = this.onTableRowHighlight.bind(this)
    this.onSliceHighlight = this.onSliceHighlight.bind(this)
  }

  onColumnSelect(e: $TSFixMe) {
    const column = e.target.value
    this.setState(() => ({ selectedColumn: column }))
  }
  onTableRowHighlight(index: $TSFixMe, tableData: $TSFixMe) {
    const highlightedAsset = index != null ? tableData[index].id : null
    this.setState(() => ({ highlighted: highlightedAsset }))
  }
  onSliceHighlight(index: $TSFixMe, pieData: $TSFixMe) {
    const highlightedAsset = index != null ? pieData[index].id : null
    this.setState(() => ({ highlighted: highlightedAsset }))
  }
  render() {
    const { i18n, pending, error, selectedAssets, customer, analysis, onTravelInTime } = this.props
    const { highlighted, selectedColumn } = this.state
    const selectedDateRange = (analysis as $TSFixMe).get('selectedDateRange').toJS() as DateRange
    const energyMeasurements = (analysis as $TSFixMe).getIn(['data', 'energy', 'measurements']).toJS()
    const volumeMeasurements = (analysis as $TSFixMe).getIn(['data', 'volume', 'measurements']).toJS()
    const allAssets = (analysis as $TSFixMe).get('assets', List([])).toJS()
    const assetCount = (analysis as $TSFixMe).getIn(['assetCount', 'analysisType']).toJS()
    const { tableContent, tableTotals, pieChart } = prepareData(
      allAssets,
      assetCount,
      selectedAssets,
      energyMeasurements,
      volumeMeasurements,
      customer.commodityPrices,
      selectedColumn,
      i18n
    )
    const dropdownOptions = tableContent.cols.filter(c => c.key !== 'assetName').map(o => ({ ...o, option: o.label }))
    const { showVolume } = tableContent
    const highlightedSlice =
      highlighted != null ? pieChart.data.findIndex((item: $TSFixMe) => item.id === highlighted) : null
    if (error) {
      return (
        <LocalWrapper>
          <MainContainer style={{ textAlign: 'center', flexBasis: '100%', width: '100%' }}>
            <GenericError message={error.toString()} />
          </MainContainer>
        </LocalWrapper>
      )
    }
    if (pending) {
      return (
        <LocalWrapper>
          <MainContainer>
            <Loader />
          </MainContainer>
        </LocalWrapper>
      )
    }
    return (
      <LocalWrapper>
        <MainContainer
          ref={el => {
            this.chartContainer = el
          }}
        >
          <HeadingContainer>
            <Tools
              i18n={i18n}
              onTravelInTime={onTravelInTime}
              timeTravelDisabled={isDateOutsideOfRange(selectedDateRange.endDate)}
            />
          </HeadingContainer>
          {!pending ? (
            <>
              <TableHeadingContainer>
                <Heading size={4}>
                  {`${i18n.date(selectedDateRange.startDate.toJSDate(), headingDateOpts)} -
                    ${i18n.date(selectedDateRange.endDate.toJSDate(), headingDateOpts)}`}
                </Heading>
              </TableHeadingContainer>
              <TableWrapper>
                <SimpleTable>
                  <SimpleTable.THead>
                    <SimpleTable.TR>
                      {tableContent.cols.map(({ key, label }) => (
                        <SimpleTable.TH key={key}>{label}</SimpleTable.TH>
                      ))}
                    </SimpleTable.TR>
                  </SimpleTable.THead>
                  <SimpleTable.TBody>
                    {tableContent.data.map(({ id, assetName, labels }: $TSFixMe, i: $TSFixMe) => (
                      <AssetRow
                        key={assetName}
                        onMouseEnter={() => this.onTableRowHighlight(i, tableContent.data)}
                        /* @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1. */ xx
                        onMouseLeave={() => this.onTableRowHighlight(null)}
                        highlighted={highlighted === id}
                      >
                        <SimpleTable.TD key={`name-${assetName}`}>{assetName}</SimpleTable.TD>
                        {showVolume && (
                          <SimpleTable.TD key={`volumeConsumption-${assetName}`}>
                            {labels.volumeConsumption}
                          </SimpleTable.TD>
                        )}
                        
                        <SimpleTable.TD key={`energyCosts-${assetName}`}>{labels.energyCosts}</SimpleTable.TD>
                      </AssetRow>
                    ))}
                    <TotalRow>
                      <SimpleTable.TD key="totalConsumption">{i18n.text('table.total')}</SimpleTable.TD>
                      {showVolume && <SimpleTable.TD key="volumeConsumptionTotal">{tableTotals.volume}</SimpleTable.TD>}
                      <SimpleTable.TD key="energyCostsTotal">{tableTotals.energyCost}</SimpleTable.TD>
                    </TotalRow>
                  </SimpleTable.TBody>
                </SimpleTable>
              </TableWrapper>
            </>
          ) : (
            <Loader />
          )}
          <PieChartWrapper>
            <div>
              <Dropdown
                selected={selectedColumn}
                options={dropdownOptions}
                onChange={this.onColumnSelect}
              />
            </div>
            {pieChart.total !== 0 ? (
              <PieChart
                data={pieChart.data}
                height={480}
                highlighted={highlightedSlice}
                onSliceHighlight={(index: $TSFixMe) => this.onSliceHighlight(index, pieChart.data)}
                width={480}
              />
            ) : (
              <CenterMessage>
                <h2>{i18n.text('analysis.energy-balance.no-data')}</h2>
                <br />
                <p>{i18n.text('analysis.energy-balance.no-data.description')}</p>
              </CenterMessage>
            )}
          </PieChartWrapper>
        </MainContainer>
      </LocalWrapper>
    )
  }
}
const mapStateToProps = ({ app, water }: $TSFixMe) => ({
  app,
  analysis: water,
})
export default connect(mapStateToProps, null)(EnergyBalance)
