import apexDe from 'apexcharts/dist/locales/de.json'
import apexEn from 'apexcharts/dist/locales/en.json'
import apexEs from 'apexcharts/dist/locales/es.json'
import apexFr from 'apexcharts/dist/locales/fr.json'
import apexIt from 'apexcharts/dist/locales/it.json'
import apexNl from 'apexcharts/dist/locales/nl.json'
import { ApexOptions } from 'apexcharts'
import { useMemo } from 'react'
import { useTheme } from '@mui/material'

import { SwitchUnit } from '../types/compressed-air_types'
import { numberFormatOptions } from '../../Shared/constants/numberFormatOptions'
import { shortLocalesNames } from '../../Shared/utils'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'

const apexLocales = [apexEn, apexDe, apexEs, apexIt, apexFr, apexNl]

interface IUseAirBalanceOptionsArgs {
  labels: string[]
  colors: string[]
  switchUnit: SwitchUnit
  decimal: number
  isPrevious?: boolean
  hideLegend?: boolean
}

export const useAirBalanceOptions = ({
  labels,
  colors,
  switchUnit,
  decimal,
  isPrevious,
  hideLegend,
}: IUseAirBalanceOptionsArgs): ApexOptions => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        id: switchUnit.includes('cf') ? 'AirBalanceCFMGraphId' : 'AirBalanceM3GraphId',
        locales: apexLocales,
        defaultLocale: shortLocalesNames[i18n.locale],
      },
      title: {
        text: isPrevious ? i18n.text('chart.previous') : hideLegend ? i18n.text('chart.current') : '',
        align: 'center',
        style: {
          fontSize: '16px',
        },
      },
      labels,
      colors,
      legend: {
        show: !hideLegend,
        position: 'bottom',
        horizontalAlign: 'center',
        fontFamily: theme.typography.fontFamily,
        fontSize: '14',
        markers: {
          width: 8,
          height: 8,
          radius: 8,
        },
      },
      noData: {
        text: i18n.text('analysis.energy-balance.no-data'),
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: theme.palette.SFIGreyLight[800],
          fontSize: '16px',
        },
      },
      yaxis: {
        decimalsInFloat: decimal,
      },
      tooltip: {
        y: {
          formatter: value =>
            `${value ? i18n.number(value, numberFormatOptions(decimal)) : 0} ${
              switchUnit.includes('cf') ? switchUnit : 'm³'
            }`,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '50%',
          },
        },
      },
    }),
    [theme, labels, colors, i18n, switchUnit, isPrevious, hideLegend, decimal]
  )
  return options
}

interface IUseBalanceBarChartOptionsArgs {
  labels: string[]
  switchUnit: SwitchUnit
  decimal: number
  maxXaxisValue: number
  isStacked?: boolean
}

export const useBalanceBarChartOptions = ({
  labels,
  switchUnit,
  decimal,
  maxXaxisValue,
  isStacked,
}: IUseBalanceBarChartOptionsArgs): ApexOptions => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const maximumBarHeightPercentage = 75
  const maximumHeightOfAllLabels = 15 * labels.length
  const barHeight = Math.min(maximumHeightOfAllLabels, maximumBarHeightPercentage) + '%'

  const options: ApexOptions = useMemo(() => {
    return {
      chart: {
        id: switchUnit.includes('cf') ? 'AirConsumptionCFMGraphId' : 'AirConsumptionM3GraphId',
        type: 'bar',
        stacked: !!isStacked,
        locales: apexLocales,
        defaultLocale: shortLocalesNames[i18n.locale],
        height: isStacked ? 200 : 350,
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      fill: {
        opacity: 1,
        colors: [theme.palette.SFIBrand[600], theme.palette.SFIGreen[500]],
      },
      colors: [theme.palette.SFIBrand[600], theme.palette.SFIGreen[500]],
      tooltip: {
        x: {
          show: true,
          format: 'MMM ddd dd HH:mm',
        },
        y: {
          formatter: (value: number) => `${value ? i18n.number(value, numberFormatOptions(decimal)) : 0} ${switchUnit}`,
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false, //or just here to disable only x axis grids
          },
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: isStacked ? 'end' : 'start',
        offsetX: isStacked ? -4 : 16,
        offsetY: 4,
        style: {
          fontSize: '0.75rem',
          fontWeight: isStacked ? 600 : 400,
          colors: isStacked ? [theme.palette.SFIBase.white] : [theme.palette.SFIGreyLight[700]],
        },
        formatter: val => i18n.number(val, numberFormatOptions(decimal)),
      },
      xaxis: {
        categories: labels,
        max: maxXaxisValue,
      },
      yaxis: {
        decimalsInFloat: decimal,
        labels: {
          maxWidth: 200,
          minWidth: 100,
          offsetY: -24,
          style: {
            colors: theme.palette.SFIGreyLight[500],
            fontSize: '0.75rem',
            fontWeight: 400,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadiusWhenStacked: 'last',
          endingShape: 'rounded',
          barHeight,
          borderRadius: 4,
          borderRadiusApplication: 'end',
          dataLabels: {
            position: 'top',
          },
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        fontFamily: theme.typography.fontFamily,
        fontSize: '14',
        markers: {
          width: 8,
          height: 8,
          radius: 8,
        },
      },
      stroke: {
        width: 3,
        colors: ['transparent'],
      },
    }
  }, [theme, i18n, labels, switchUnit, decimal, isStacked])

  return options
}
