import React, { FC } from 'react'
import { Box } from '@mui/material'

import { ChartToolsDropdown, ChartToolsItem } from '../../../Shared/components/MUIComponents/update/ChartToolsDropdown'
import { InfoTooltipIconWithTitle } from '../../../Shared/components/MUIComponents/update/InfoTooltipIconWithTitle'
import { StyledToggleButtonGroup } from '../../../Shared/components/MUIComponents/update/StyledToggleButtonGroup'
import { SwitchUnit } from '../../types/compressed-air_types'
import { useCompressedAirContext } from '../../context/CompressedAirContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface IGraphToolbarProps {
  hasHeader: boolean
  currentSwitchUnit: string
  unitSwitchData?: {
    units: SwitchUnit[]
  }
  toolbarItems?: ChartToolsItem[]
  elementRef: React.MutableRefObject<HTMLElement | undefined>
  switchUnit: SwitchUnit
  setSwitchUnit: React.Dispatch<React.SetStateAction<SwitchUnit>>
  i18nTitle?: string
  isToolbarMargined?: boolean
  tooltipText?: string
}

export const GraphToolbar: FC<IGraphToolbarProps> = ({
  hasHeader,
  i18nTitle,
  switchUnit,
  currentSwitchUnit,
  unitSwitchData,
  toolbarItems,
  elementRef,
  tooltipText,
  setSwitchUnit,
  isToolbarMargined = true,
}) => {
  const { i18n } = useI18nContext()
  const { setUpdatedOptionsFlag } = useCompressedAirContext()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: hasHeader ? 'space-between' : 'flex-end',
        alignItems: 'center',
        padding: theme => theme.spacing(0, 0, 2.75),
        marginRight: theme => (isToolbarMargined ? theme.spacing(13.25) : 0),
      }}
    >
      {hasHeader && (
        <InfoTooltipIconWithTitle
          title={`${i18n.text(i18nTitle)} ${currentSwitchUnit}`}
          tooltipText={i18n.text(tooltipText) ?? ''}
          isIconHidden={!tooltipText}
          slotProps={{
            iconWrapper: {
              ref: elementRef,
            },
          }}
        />
      )}
      {(toolbarItems || unitSwitchData) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          {toolbarItems && (
            <ChartToolsDropdown
              items={toolbarItems}
              slotProps={{ menuButton: { sx: { maxHeight: '2.25rem' } } }}
            />
          )}
          {unitSwitchData && (
            <StyledToggleButtonGroup
              items={unitSwitchData.units.map(u => ({ value: u }))}
              selectedItem={switchUnit}
              slotProps={{
                wrapper: {
                  sx: {
                    maxHeight: '2.25rem',
                  },
                },
              }}
              onChange={value => {
                setSwitchUnit(value as SwitchUnit)
                setUpdatedOptionsFlag(true)
              }}
            />
          )}
        </Box>
      )}
    </Box>
  )
}
