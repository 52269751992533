import { Box } from '@mui/material'
import { FC } from 'react'

import { BackGroundBox, GraphMainInfoBox, MainSavingsInfoBox } from '../../../components/Common/v1/index'
import { PeakShavingContainerProps } from '../../../types'
import { PeakShavingGraph } from '../../../components/PeakShaving/v1/PeakShavingGraph'
import { PeakShavingTable } from '../../../components/PeakShaving/v1/PeakShavingTable'

export const PeakShaving: FC<PeakShavingContainerProps> = ({ input, chartWidth, versionTemplate }) => {
  const savingName = 'peak_shaving'
  const note = 'Pressure reducing is saving energy. We found that this should be done in your case' //todo from json

  return (
    <BackGroundBox>
      <GraphMainInfoBox>
        <Box
          sx={{
            display: 'grid',
            gap: '2.625rem',
          }}
        >
          <PeakShavingGraph
            cumulativePowerGraph={input.cumulative_power_graph}
            chartWidth={chartWidth}
          />
          <PeakShavingTable machineStartTimeShifts={input.machine_start_time_shifts}></PeakShavingTable>
        </Box>
        <MainSavingsInfoBox
          savingName={savingName}
          costSaving={input.savings_outputs_total.costs_saved}
          energySaving={input.savings_outputs_total.energy_saved}
          emissionsSaving={input.savings_outputs_total.emissions_saved}
          version={'v1'}
          note={note}
          numInsights={1}
          numActions={3}
          webappTip={false}
          trTip={true}
          alertTip={false}
        />
      </GraphMainInfoBox>
    </BackGroundBox>
  )
}
