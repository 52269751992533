/* eslint-disable @typescript-eslint/no-explicit-any */

import { BoxProps, CheckboxProps, TypographyProps } from '@mui/material'
import { DateTime } from 'luxon'
import { Reducer } from 'redux'

import {
  CommodityPrice,
  CurrencyIsoCode,
  CurrentCustomerFragment,
  UpdateMyReportSubscriptionInput,
} from '../graphql/codegen'
import { SupportedLocale, normalizeUser } from '../utils'

export type Brand<T, U> = T & {
  __brand: U
}

export type TypeOf<T extends { _type: any }> = T['_type']

export type ISODateTime = Brand<string, 'DateTime'>

export type LocalDateTime = Brand<string, 'LocalDateTime'>

export type TimeZone = Brand<string, 'TimeZone'>

export type Time = Brand<string, 'Time'>

// Hour of day, minute of hour, day of week based on ISO 8601

export type DayOfWeek = Brand<number, 'DayOfWeek'>

export function isDayOfWeek(value: number): asserts value is DayOfWeek {
  if (!Number.isInteger(value) || value < 1 || value > 7)
    throw new Error('Hour value should be an integer between 1 and 7')
}

export type HourOfDay = Brand<number, 'HourOfDay'>

export function isHourOfDay(value: number): asserts value is HourOfDay {
  if (!Number.isInteger(value) || value < 0 || value > 23)
    throw new Error('Hour value should be an integer between 0 and 23')
}

export type MinuteOfHour = Brand<number, 'MinuteOfHour'>

export function isMinuteOfHour(value: number): asserts value is MinuteOfHour {
  if (!Number.isInteger(value) || value < 0 || value > 59)
    throw new Error('Minute value should be an integer between 0 and 59')
}

export type JSON = any
export type Numeric = number
export type TSVector = any
export type ID = Brand<string, 'ID'>
export type JwtToken = Brand<string, 'JwtToken'>
export type MeasurementResolution = Brand<any, 'MeasurementResolution'>
export type Resolution = Brand<any, 'Resolution'>
export type NonNegativeFloat = Brand<number, 'NonNegativeFloat'>
export type NonNegativeInt = Brand<number, 'NonNegativeInt'>
export type PositiveInt = Brand<number, 'PositiveInt'>
export type Float8 = Brand<number, 'Float8'>
export type NonEmptyString = Brand<string, 'NonEmptyString'>
export type DropdownOption = {
  key: number | string
  option: number | string
  value?: number | string
}

export enum UnitsOfEnergyType {
  'kWh' = 'kWh',
  'm³' = 'm³',
}

export enum SupportedCurrencyCodes {
  'EUR' = 'EUR',
}

export type MigrateResult =
  | {
      code: 'RETRY_LOGIN'
    }
  | {
      code: 'NO_RETRY'
      message: string
    }

export type RequestResetResult =
  | {
      code: 'RESET'
    }
  | {
      code: 'ERROR'
      message: string
    }

export type MigrateResponse = any // TODO Improve this

export type CurrentUser = ReturnType<typeof normalizeUser>
export type CurrentCustomer = CurrentCustomerFragment
export type CustomerCurrency = {
  isocode: CurrencyIsoCode
  symbol: string
}

export type CustomerCommodityPrices = CommodityPrice[]

export type CurrentUserContext = {
  userId: ID
  user: CurrentUser
  isInternalUser: () => boolean
  isSensorfactEmployee: () => boolean
  hasElectricityStandbyEnabled: boolean
  INTRO_SLIDESHOW_KEY: null | string
  customerId: ID
  selectedCustomer: CurrentCustomer
  customerCurrency: CustomerCurrency
  switchCustomer: (customerId: string, route?: string) => Promise<unknown>
  updateMySettings: (patch: {
    locale?: SupportedLocale
    reportSubscription?: UpdateMyReportSubscriptionInput
    selectedCustomerId?: string
  }) => Promise<unknown>
  updateUserLocale: (requestedLocale: SupportedLocale) => Promise<unknown>
  getHasuraRoleHeader: () => 'employee-admin' | 'webclient'
  commodityPrices: CustomerCommodityPrices[]
}

export interface JwtPayload {
  id: string
  wrongPasswords: number
  iat: number
  'https://hasura.io/jwt/claims': string
}

export type HasuraClaims = {
  'x-hasura-allowed-roles': string[]
  'x-hasura-default-role': string
  'x-hasura-user-id': string
  'x-hasura-customer-id': string
}

export type AuthApiReq =
  | { grant_type: 'migrate'; username: string; password: string; origin: string }
  | { grant_type: 'password'; username: string; password: string; origin: string }
  | { grant_type: 'password_reset_request'; username: string; origin: string }
  | { grant_type: 'switch_customer'; customer: string }

export type InferStoreState<R> = R extends Reducer<infer S, any> ? S : never

export type UrlParams = {
  machineId: string
  probeId: string
}

export type PreselectedRange = {
  label: string
  start: DateTime
  end: DateTime
}

export interface IImage {
  id: string
  url: string
}

export type ToolBarItem = {
  label: string
  isChecked: boolean
  onToggle: () => void
  isDisabled?: boolean
  props?: CheckboxProps
  tooltip?: {
    title?: string
    text?: string
    align?: string
  }
  icon?: React.ReactNode
}

export type ToolbarMultiSelectItem = {
  label: string
  onValueToggle: (value: any) => void
  isDisabled?: boolean
  values: Array<any>
  selectedValue: any
  tooltip?: {
    title?: string
    text?: string
    align?: string
  }
}

export interface ICustomTableColumn<T> {
  id: 'id' | keyof T
  label: string
  tooltipText?: string
  minWidth?: number | string
  width?: number | string
  align?: 'right' | 'left' | 'center'
  isBoldText?: boolean
  isSorted?: boolean
  onSortToggle?: () => void
  children?: (item: T[keyof T], id?: string, row?: T) => React.ReactNode
  format?: (value: number) => string
}

export type AutocompleteOption = {
  label: string
  id: string
}

export enum ItemsAlignment {
  START = 'flex-start',
  END = 'flex-end',
  CENTER = 'center',
}

export interface NoDataSlotProps {
  wrapper?: BoxProps
  title?: TypographyProps
  subtitle?: TypographyProps
}

export enum CommodityType {
  ELECTRIC_CURRENT = 'electric-current',
  NATURAL_GAS = 'natural-gas',
  WATER = 'water'
}

export enum AlertType {
  POWER_ALERT = 'POWER_ALERT',
  STANDBY_ALERT = 'STANDBY_ALERT',
  CA_FLOW = 'CA_FLOW',
  CA_CONSUMPTION = 'CA_CONSUMPTION',
  CA_PRESSURE = 'CA_PRESSURE',
  CA_TEMPERATURE = 'CA_TEMPERATURE',
  GAS_ALERT = 'GAS_ALERT',
  WATER_ALERT = 'WATER_ALERT',
  THRESHOLD_ALERT = 'THRESHOLD_ALERT',
  DOWNTIME_ALERT = 'DOWNTIME_ALERT'
}
