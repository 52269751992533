import React from 'react'
import styled from 'styled-components'

const Act = styled.text`
  width: 169px;
  height: 154px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 130px;
  line-height: 154px;

  fill: #000000;
  user-select: none;
`

type Props = {
  act: string
  color: string
  signal: string
}

const MobileSignalStrength = ({ signal = 'poor', color = '000', act }: Props) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 430 309"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M101 242.5V239V309H0.5L101 242.5Z"
      fill={
        signal === 'excellent' || signal === 'good' || signal === 'fair' || signal === 'poor'
          ? color
          : 'rgba(0,0,0,0.2)'
      }
    />
    <path
      d="M211 171V169V309H111V235L211 171Z"
      fill={signal === 'excellent' || signal === 'good' || signal === 'fair' ? color : 'rgba(0,0,0,0.2)'}
    />
    <path
      d="M321 99.5V309H221V164.5L321 99.5Z"
      fill={signal === 'excellent' || signal === 'good' ? color : 'rgba(0,0,0,0.2)'}
    />
    <path
      d="M431 29V309H331V93L431 29Z"
      fill={signal === 'excellent' ? color : 'rgba(0,0,0,0.2)'}
    />
    <Act
      x="0"
      y="60"
    >
      {act}
    </Act>
  </svg>
)

export default MobileSignalStrength
