import React from 'react'
import styled from 'styled-components'
import { isEmpty } from 'ramda'

import RecipientInput from '../Recipient'
import { defaultRecipient } from '../../containers/RuleEditor/utils'
import { Recipient, SupportedTransport } from "../Recipient/types";

const RecipientList = styled.ul`
  width: 100%;
`

interface RecipientEditorProps {
  i18n: $I18FixMe
  onChange: (recipients: Recipient[]) => void
  selected: Recipient[]
  transportOptions: SupportedTransport[]
}

const RecipientEditor = ({ i18n, onChange, selected, transportOptions }: RecipientEditorProps): JSX.Element => {
  const recipients = isEmpty(selected) ? [defaultRecipient] : selected

  function _onChange(index: number, recipient: Recipient) {
    const updatedRecipients = [...recipients]
    updatedRecipients.splice(index, 1, recipient)
    onChange(updatedRecipients)
  }

  function _onDelete(index: number) {
    const updatedRecipients = [...recipients]
    updatedRecipients.splice(index, 1)
    onChange(updatedRecipients)
  }

  function _onClone(index: number) {
    const updatedRecipients = [...recipients]
    updatedRecipients.splice(index + 1, 0, defaultRecipient)
    onChange(updatedRecipients)
  }

  return (
    <RecipientList>
      {recipients.map((recipient, index) => (
        <li
          id={`recipient-${index}`}
          key={`recipient-${index}`}
        >
          <RecipientInput
            i18n={i18n}
            onChange={_onChange.bind(null, index)}
            onDelete={_onDelete.bind(null, index)}
            onAdd={_onClone.bind(null, index)}
            recipient={recipient}
            removeDisabled={recipients.length === 1}
            transportOptions={transportOptions}
          />
        </li>
      ))}
    </RecipientList>
  )
}

export default RecipientEditor
