import moment, { Moment } from 'moment'
import type { ApolloClient } from '@apollo/client'
import { Component } from 'react'
import { DateTime, Duration } from 'luxon'
import { bindActionCreators } from 'redux'
import { bool, object } from 'prop-types'
import { connect } from 'react-redux'
import { dropLast, last } from 'ramda'

import actionCreators from './actions'
import {
  AnalysisAssetsDocument,
  AnalysisAssetsQuery,
  AnalysisAssetsQueryVariables,
} from '../../../Shared/graphql/codegen'
import type { AnalysisState } from './reducers'
import type { AnalysisType, DateRange } from '../../../Shared/types/analysis_types'
import type { CurrentCustomer } from '../../../Shared/types/types'
import { GROUP } from '../../../Shared/constants/tabTypes'
import { ID } from '../../../Shared/types/types'
import { LocalStoreState } from '../../types'
import {
  getFirstRelevantAssetId,
  getInitialDateRange,
  getMeasurementTypesByAnalysis,
  toggleDashboardStyles,
  trySnapToInterval,
} from '../../../Shared/utils/analysis_utils'

type Props = {
  i18n: $I18FixMe
  params: {
    graph: string
  }
  type: AnalysisType
  apolloClient: ApolloClient<unknown>
  actions: ActionsPropsMap<typeof actionCreators>
  analysis: AnalysisState
  route: {
    path: string
  }
  location: {
    search: string
  }
  hasMultipleCustomers: boolean
  customer: CurrentCustomer
}

type State = {
  showInfo: boolean
  showMean: boolean
  autoRefreshActive: boolean
  autoRefreshEnabled: boolean
  showDetails: boolean
  machineDetails: $TSFixMe
  machineName: string
  brushHistory: $TSFixMe[]
  isDashboard: boolean
}

type ZoomRange = {
  minDate?: Moment
  maxDate?: Moment
}
export class GasAnalysis extends Component<Props, State> {
  static contextTypes = {
    authenticated: bool,
    user: object,
  }
  static defaultProps: $TSFixMe
  refreshInterval: $TSFixMe

  constructor(props: $TSFixMe) {
    super(props)
    this.state = {
      showInfo: false,
      showMean: false,
      autoRefreshActive: false,
      autoRefreshEnabled: false,
      showDetails: false,
      machineDetails: {},
      machineName: '',
      brushHistory: [],
      isDashboard: false,
    }
    this.onShowInfo = this.onShowInfo.bind(this)
    this.onHideInfo = this.onHideInfo.bind(this)
    this.onAssetSelect = this.onAssetSelect.bind(this)
    this.onComponentSelect = this.onComponentSelect.bind(this)
    this.onToggleFamilySelect = this.onToggleFamilySelect.bind(this)
    this.onDateRangeChange = this.onDateRangeChange.bind(this)
    this.onTravelInTime = this.onTravelInTime.bind(this)
    this.onToggleAutoRefresh = this.onToggleAutoRefresh.bind(this)
    this.onToggleDashboard = this.onToggleDashboard.bind(this)
    this.onTogglePreviousPeriod = this.onTogglePreviousPeriod.bind(this)
    this.onToggleShowMean = this.onToggleShowMean.bind(this)
    this.onTabChange = this.onTabChange.bind(this)
    this.fetchMeasurements = this.fetchMeasurements.bind(this)
    this.refreshMeasurements = this.refreshMeasurements.bind(this)
    this.setAnalysisType = this.setAnalysisType.bind(this)
    this.onYAxisChange = this.onYAxisChange.bind(this)
    this.zoomIn = this.zoomIn.bind(this)
    this.zoomOut = this.zoomOut.bind(this)
  }

  componentDidMount() {
    const {
      apolloClient,
      customer,
      actions: { fetchAssets, selectDateRange },
      analysis,
      i18n,
      type,
      location,
    } = this.props
    const queryAnalysisAssets = apolloClient.query<AnalysisAssetsQuery, AnalysisAssetsQueryVariables>({
      query: AnalysisAssetsDocument,
    })
    this.setAnalysisType(type)

    const urlParams = new URLSearchParams(location.search)

    const selectedDateRange = getInitialDateRange(location.search, customer.timeZone)
    selectDateRange(selectedDateRange)

    const requestedAssets = urlParams.get('assets')
    const autoSelectAssets = requestedAssets ? requestedAssets.split(',').map(id => id as ID) : undefined

    if (analysis.get('assets').isEmpty()) {
      fetchAssets(queryAnalysisAssets, apolloClient, i18n, autoSelectAssets)
    }
    const measurementType = getMeasurementTypesByAnalysis(type)[0]
    if (analysis.getIn(['data', measurementType, 'measurements']).isEmpty()) {
      this.fetchMeasurements(selectedDateRange)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      params: { graph: prevGraph },
      type: prevType,
    } = prevProps
    const { analysis, params, type } = this.props
    const nextDateRange = analysis.get('selectedDateRange').toJS()
    const graphChanged = params.graph !== prevGraph
    const typeChanged = type !== prevType
    if (graphChanged || typeChanged) {
      this.setAnalysisType(type)
      this.fetchMeasurements(nextDateRange)
    }
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    // set auto-refresh availability
    const { analysis } = nextProps
    const { startDate, endDate } = analysis.get('selectedDateRange').toJS()
    const _startDate = startDate.valueOf()
    const _endDate = endDate.valueOf()
    const now = moment().valueOf()
    const autoRefreshEnabled = _startDate <= now && now <= _endDate
    return {
      autoRefreshEnabled,
      autoRefreshActive: autoRefreshEnabled ? prevState.autoRefreshActive : false,
    }
  }

  // Toggle info
  onShowInfo() {
    this.setState(() => ({ showInfo: true }))
  }

  onHideInfo() {
    this.setState(() => ({ showInfo: false }))
  }

  onDateRangeChange({ startDate, endDate }: DateRange, zooming: boolean) {
    const {
      actions: { selectDateRange },
      analysis,
    } = this.props
    const selectedDateRange = analysis.get('selectedDateRange').toJS()
    const startOfStartDate = startDate ? startDate.startOf('day') : selectedDateRange.startDate.startOf('day')
    const endOfEndDay = endDate ? endDate.endOf('day') : selectedDateRange.endDate.endOf('day')
    const dateRange = zooming
      ? {
        startDate: startDate || selectedDateRange.startDate,
        endDate: endDate || selectedDateRange.endDate,
      }
      : {
        startDate: startOfStartDate,
        endDate: endOfEndDay,
      }
    selectDateRange(dateRange)
    this.fetchMeasurements(dateRange)
  }

  onTravelInTime(direction: 'forwards' | 'backwards') {
    return () => {
      const {
        actions: { selectDateRange },
        analysis,
      } = this.props
      const dateRange: DateRange = analysis.get('selectedDateRange').toJS()
      const { startDate, endDate } = dateRange
      let nextDateRange = trySnapToInterval(startDate, endDate, direction)
      if (!nextDateRange) {
        const timeDelta = endDate.diff(startDate)
        const newStartDate =
          direction === 'backwards'
            ? startDate.minus(timeDelta).startOf('minute')
            : startDate.plus(timeDelta).plus({ minutes: 1 }).startOf('minute')

        const newEndDate = newStartDate.plus(timeDelta).endOf('minute')
        nextDateRange = {
          startDate: newStartDate,
          endDate: newEndDate,
        }
      }
      this.fetchMeasurements(nextDateRange)
      return selectDateRange(nextDateRange)
    }
  }

  onAssetSelect({ id }: $TSFixMe) {
    const {
      actions: { selectAsset },
      analysis,
      apolloClient,
      i18n,
    } = this.props
    const selectedDateRange = analysis.get('selectedDateRange').toJS()
    selectAsset(apolloClient, id, selectedDateRange, i18n)
  }

  onComponentSelect({ id }: $TSFixMe) {
    const {
      actions: { selectComponent },
      apolloClient,
      analysis,
      i18n,
    } = this.props
    return (e: $TSFixMe) => {
      e.preventDefault()
      const selectedDateRange = analysis.get('selectedDateRange').toJS()
      return selectComponent(apolloClient, id, selectedDateRange, i18n)
    }
  }

  onToggleFamilySelect({ id: groupId }: $TSFixMe) {
    const {
      actions: { selectMultipleAssets },
      analysis,
      apolloClient,
      i18n,
    } = this.props
    const selectedAssets = analysis.get('selectedAssets').toJS()
    const assets = analysis.get('assets').toJS()
    const selectedDateRange = analysis.get('selectedDateRange').toJS()
    const assetGroup = assets.find((g: $TSFixMe) => g.id === groupId)
    if (!assetGroup || assetGroup.assets?.length === 0) {
      return
    }
    const flatSelectedAssets = selectedAssets.flat()
    const selectedAssetsInGroup = assetGroup.assets.filter((a: $TSFixMe) => flatSelectedAssets.includes(a.id))
    const assetsToToggle = []
    if (selectedAssetsInGroup.length > 0) {
      // if this family has any assets currently selected, de-select those assets
      assetsToToggle.push(...selectedAssetsInGroup.map((a: $TSFixMe) => a.id))
    } else {
      // else select all assets in this family
      assetsToToggle.push(...assetGroup.assets.map((a: $TSFixMe) => a.id))
    }
    selectMultipleAssets(apolloClient, assetsToToggle, selectedDateRange, i18n)
  }

  onToggleAutoRefresh() {
    const { autoRefreshActive, autoRefreshEnabled } = this.state
    if (autoRefreshEnabled) {
      const oneMinute = 60 * 1000
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval)
        this.refreshInterval = null
      }
      if (!autoRefreshActive) {
        this.refreshMeasurements()
        this.refreshInterval = setInterval(this.refreshMeasurements, oneMinute)
      }
      this.setState(() => ({ autoRefreshActive: !autoRefreshActive }))
    }
  }

  onToggleDashboard() {
    this.setState(state => {
      toggleDashboardStyles(!(state as $TSFixMe).isDashboard)
      return {
        isDashboard: !(state as $TSFixMe).isDashboard,
      }
    })
  }

  onTogglePreviousPeriod() {
    const {
      apolloClient,
      i18n,
      actions: { togglePreviousPeriod },
    } = this.props
    return togglePreviousPeriod(apolloClient, i18n)
  }

  onToggleShowMean() {
    this.setState(({ showMean }) => ({ showMean: !showMean }))
  }

  onYAxisChange() {
    const {
      actions: { yAxisChange },
    } = this.props
    return yAxisChange()
  }

  onTabChange(symbol: $TSFixMe) {
    const {
      analysis,
      actions: { assetTabChange, deselectAllAssets, selectAsset, setTotalSelected },
      apolloClient,
      i18n,
    } = this.props
    const selectedDateRange = analysis.get('selectedDateRange').toJS()
    setTotalSelected(false)
    deselectAllAssets()
    const isGroupTab = symbol === GROUP
    const firstRelevantAssetId = getFirstRelevantAssetId(
      analysis.get('assets').toJS(),
      analysis.get('analysisType'),
      isGroupTab
    )
    assetTabChange(symbol)
    selectAsset(apolloClient, firstRelevantAssetId, selectedDateRange, i18n, true)
  }

  setAnalysisType(type: AnalysisType) {
    const {
      actions: { setAnalysisType },
    } = this.props
    setAnalysisType(type)
  }

  fetchMeasurements(dateRange: DateRange) {
    const {
      actions: { fetchMeasurements },
      apolloClient,
      analysis,
      i18n,
    } = this.props
    const fetchMeasurementsPending = analysis.get('fetchMeasurementsPending', false)
    if (!fetchMeasurementsPending) {
      fetchMeasurements(apolloClient, dateRange, i18n)
    }
  }

  refreshMeasurements() {
    const { actions, analysis, apolloClient, i18n } = this.props
    const { fetchMeasurements } = actions
    const { endDate, startDate } = analysis.get('selectedDateRange').toJS()
    let dateRange = { endDate, startDate }
    const _startDate = startDate.valueOf()
    const _endDate = endDate.valueOf()
    const timeDelta = moment.duration(Date.now() - _endDate)
    const shouldMoveDates = endDate.valueOf() <= Date.now()
    if (shouldMoveDates) {
      dateRange = {
        startDate: moment(_startDate).add(timeDelta),
        endDate: moment(_endDate).add(timeDelta),
      }
    }

    fetchMeasurements(apolloClient, dateRange, i18n, true)
  }

  async zoomIn({ minDate, maxDate }: ZoomRange) {
    const maxZoom = Duration.fromObject({ minutes: 20 })

    if (minDate == null || maxDate == null) {
      // zoom in based on the selected date range
      const { analysis } = this.props
      const { endDate, startDate } = analysis.get('selectedDateRange').toJS() as DateRange

      const duration = endDate.diff(startDate)
      if (duration < maxZoom) return
      const delta = duration.toMillis() / 4
      // add the delta to both start and end (effectively a +50% zoom)
      const dateRange = {
        startDate: startDate.plus(delta),
        endDate: endDate.minus(delta),
      }
      this.onDateRangeChange(dateRange, true)
      return
    }
    const endDate = DateTime.fromJSDate(maxDate.toDate())
    const startDate = DateTime.fromJSDate(minDate.toDate())

    const duration = endDate.diff(startDate)
    if (duration >= maxZoom) {
      const { analysis } = this.props
      const selectedDateRange = analysis.get('selectedDateRange').toJS() as DateRange
      // update brushHistory
      this.setState(({ brushHistory }) => ({
        brushHistory: brushHistory.concat([selectedDateRange]),
      }))
      this.onDateRangeChange({ startDate, endDate }, true)
    }
  }

  zoomOut() {
    const { analysis } = this.props
    const fetchMeasurementsPending = analysis.get('fetchMeasurementsPending', false)
    if (!fetchMeasurementsPending) {
      const { brushHistory } = this.state
      let dateRange = last(brushHistory)
      // navigate back through the brush history
      if (dateRange) {
        // pop last date range off of the brushHistory
        this.setState(() => ({
          brushHistory: dropLast(1, brushHistory),
        }))
      } else {
        // zoom out based on the selected date range
        const { endDate, startDate } = analysis.get('selectedDateRange').toJS() as DateRange
        const delta = endDate.diff(startDate).toMillis() / 2
        // add the delta to both start and end (effectively a -100% zoom)
        dateRange = {
          startDate: startDate.minus(delta),
          endDate: endDate.plus(delta),
        }
      }
      this.onDateRangeChange(dateRange, true)
    }
  }
}

GasAnalysis.defaultProps = {
  params: { graph: 'graphs' },
}

const mapStateToProps = ({ gas }: LocalStoreState) => ({
  analysis: gas,
})

const mapDispatchToProps = (dispatch: $TSFixMeDispatch) => ({
  actions: bindActionCreators({ ...actionCreators }, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(GasAnalysis)
