import React, { FC, useMemo } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  useTheme,
} from '@mui/material'

import { CompressedAirAssetFragment } from '../../../Shared/graphql/codegen'
import { SwitchUnit } from '../../types/compressed-air_types'
import { numberFormatOptions } from '../../../Shared/constants/numberFormatOptions'
import { useCAChartToolsContext } from '../../context/CAChartToolsContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface IBalanceTableProps {
  assets: CompressedAirAssetFragment[]
  items: {
    [key: string]: number
  }
  previousPeriodItems: {
    [key: string]: number
  }
  switchUnit: SwitchUnit
}

const TableWrapper = styled('div')(({ theme }) => ({
  width: '100%',
}))

export const BalanceTable: FC<IBalanceTableProps> = ({ assets, items, previousPeriodItems, switchUnit }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { decimal } = useCAChartToolsContext()

  const getTotalAll: number = useMemo(() => {
    return assets.reduce((acc: number, curr) => acc + getTotal(curr.sensorLocations.map(item => items[item.id])), 0)
  }, [assets, items])

  const getPreviousPeriodTotalAll: number = useMemo(() => {
    return assets.reduce(
      (acc: number, curr) => acc + getTotal(curr.sensorLocations.map(item => previousPeriodItems[item.id])),
      0
    )
  }, [assets, previousPeriodItems])

  const isPrevious = useMemo(() => Object.keys(previousPeriodItems).length > 0, [previousPeriodItems])

  function getTotal(arr: number[]): number {
    return arr.reduce((acc, curr) => acc + curr, 0)
  }

  return (
    <TableWrapper>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 550, borderRadius: '10px', boxShadow: '0px 1px 2px 0px #1018280D' }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: 400 }}
          aria-label="Balance table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: theme.palette.SFIBase.white }}>
                {i18n.text('measurement-location.compressed-air')}
              </TableCell>
              <TableCell
                align="right"
                sx={{ backgroundColor: theme.palette.SFIBase.white }}
              >
                {i18n.text('air-consumption.compressed-air')}
                {` (${switchUnit})`}
              </TableCell>
              {isPrevious && (
                <TableCell
                  align="right"
                  sx={{ backgroundColor: theme.palette.SFIBase.white }}
                >
                  {i18n.text('chart.previous-period')}
                  {` (${switchUnit})`}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          {assets.map(asset => (
            <TableBody key={asset.id}>
              <TableRow
                sx={{
                  background: theme.palette.SFIGreyLight[50],
                }}
              >
                <TableCell
                  colSpan={previousPeriodItems ? 3 : 2}
                  sx={{ fontWeight: 500 }}
                >
                  {asset.name}
                </TableCell>
              </TableRow>
              {asset.sensorLocations.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:nth-of-type(odd)': {
                      background: theme.palette.SFIGreyLight[50],
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      borderBottom:
                        index === asset.sensorLocations.length - 1
                          ? `1px solid ${theme.palette.SFIGreyLight[200]}`
                          : '',
                    }}
                  >
                    {item.name}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      borderBottom:
                        index === asset.sensorLocations.length - 1
                          ? `1px solid ${theme.palette.SFIGreyLight[200]}`
                          : '',
                    }}
                  >
                    {i18n.number(items[item.id], numberFormatOptions(decimal))}
                  </TableCell>
                  {isPrevious && (
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom:
                          index === asset.sensorLocations.length - 1
                            ? `1px solid ${theme.palette.SFIGreyLight[200]}`
                            : '',
                      }}
                    >
                      {i18n.number(previousPeriodItems[item.id], numberFormatOptions(decimal))}
                    </TableCell>
                  )}
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  sx={{
                    background: assets.length === 1 ? theme.palette.SFIGreyLight[50] : theme.palette.SFIGreyLight[100],
                    fontWeight: assets.length === 1 ? 500 : 400,
                    borderBottom: assets.length > 1 ? `1px solid ${theme.palette.SFIGreyLight[200]}` : '',
                  }}
                >
                  {assets.length === 1 ? i18n.text('table.total') : i18n.text('table.subtotal')}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    background: assets.length === 1 ? theme.palette.SFIGreyLight[50] : theme.palette.SFIGreyLight[100],
                    borderBottom: assets.length > 1 ? `1px solid ${theme.palette.SFIGreyLight[200]}` : '',
                  }}
                >
                  {i18n.number(
                    getTotal(asset.sensorLocations.map(item => items[item.id])),
                    numberFormatOptions(decimal)
                  )}
                </TableCell>
                {isPrevious && (
                  <TableCell
                    align="right"
                    sx={{
                      background:
                        assets.length === 1 ? theme.palette.SFIGreyLight[50] : theme.palette.SFIGreyLight[100],
                      borderBottom: assets.length > 1 ? `1px solid ${theme.palette.SFIGreyLight[200]}` : '',
                    }}
                  >
                    {i18n.number(
                      getTotal(asset.sensorLocations.map(item => previousPeriodItems[item.id])),
                      numberFormatOptions(decimal)
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          ))}
          {assets.length > 1 && (
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    background: theme.palette.SFIGreyLight[200],
                    fontWeight: 500,
                  }}
                >
                  {i18n.text('table.total')}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    background: theme.palette.SFIGreyLight[200],
                    fontWeight: 500,
                  }}
                >
                  {i18n.number(getTotalAll, numberFormatOptions(decimal))}
                </TableCell>
                {isPrevious && (
                  <TableCell
                    align="right"
                    sx={{
                      background: theme.palette.SFIGreyLight[200],
                      fontWeight: 500,
                    }}
                  >
                    {i18n.number(getPreviousPeriodTotalAll, numberFormatOptions(decimal))}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </TableWrapper>
  )
}
