import { Box, useTheme } from '@mui/material'
import { FC, ReactNode } from 'react'

import MuiSecondaryNav, {
  LinkInfo,
} from '../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'

type SecondaryNavHeaderProps = {
  links: LinkInfo[]
  children?: ReactNode
}

const SecondaryNavContainer: FC<SecondaryNavHeaderProps> = ({ children, links }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        borderBottom: '1px solid rgb(208, 213, 221)',
        padding: '1.5rem 1.5rem 0',
        background: theme => theme.palette.SFIBase.white,
        '& > div': {
          borderBottom: 'none',
        },
      }}
    >
      <MuiSecondaryNav links={links} />
      {children && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: '0.62rem',
            gap: theme => theme.spacing(1.5),
            height: '100%',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

export default SecondaryNavContainer
