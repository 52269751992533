import React, { FC, useState } from 'react'
import { Box, Button, Typography, styled } from '@mui/material'
import { DateTime } from 'luxon'

import MuiDateTimeRangePicker from '../../../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker'
import {
  BaseAnchorButtonProps,
  CompareDateRange,
  FromToValues,
} from '../../../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker/types'
import { ChartToolsDropdown } from '../../../../Shared/components/MUIComponents/update/ChartToolsDropdown'
import { DateRange } from '../../../../Shared/types/analysis_types'
import { StyledToggleButtonGroup } from '../../../../Shared/components/MUIComponents/update/StyledToggleButtonGroup'
import { SwitchUnit } from '../../../../CompressedAir/types/compressed-air_types'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '100px 0 0',
  flexDirection: 'column',
  gap: '24px',
}))

const defaultDateFormat = {
  day: 'numeric',
  month: 'short',
  year: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

interface CustomAnchorButtonProps extends BaseAnchorButtonProps {
  variant: 'text' | 'outlined' | 'contained'
  color: 'primary' | 'secondary'
  fromDate?: DateTime | null
  toDate?: DateTime | null
}
const CustomAnchorButton: FC<CustomAnchorButtonProps> = ({ onClick, variant, color, fromDate, toDate }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
      }}
    >
      <Button
        variant={variant}
        color={color}
        onClick={onClick}
      >
        {fromDate?.year} | {toDate?.year}
      </Button>
    </Box>
  )
}
// This is a temporary page, created for testing date picker
const MainComponentsPage: FC = () => {
  const { i18n } = useI18nContext()
  const today = DateTime.now()
  const aWeekAgo = today.minus({ days: 7 })

  const [fromToValues, setFromToValues] = useState<FromToValues>({
    from: aWeekAgo,
    to: today,
  })
  const [compareFromToValues, setCompareFromToValues] = useState<FromToValues>({
    from: null,
    to: null,
  })
  const [showMinMaxRange, setShowMinMaxRange] = useState(false)
  const [switchUnit, setSwitchUnit] = useState(SwitchUnit.m3)

  const compareValues =
    !!compareFromToValues.from &&
    !!compareFromToValues.to &&
    `${i18n.date(compareFromToValues.from.toJSDate(), defaultDateFormat)} - ${i18n.date(
      compareFromToValues.to.toJSDate(),
      defaultDateFormat
    )}`

  const displayItems = [
    {
      label: 'Min/Max range',
      isChecked: showMinMaxRange,
      onToggle: () => setShowMinMaxRange(!showMinMaxRange),
    },
  ]

  function onDatesChange({ startDate, endDate }: DateRange, zooming?: boolean) {
    setFromToValues({
      from: startDate,
      to: endDate,
    })
  }

  function onCompareDatesChange({ startDate, endDate }: CompareDateRange, zooming?: boolean) {
    setCompareFromToValues({
      from: startDate,
      to: endDate,
    })
  }

  return (
    <Wrapper>
      {!!fromToValues.from &&
        !!fromToValues.to &&
        `${i18n.date(fromToValues.from.toJSDate(), defaultDateFormat)} - ${i18n.date(
          fromToValues.to.toJSDate(),
          defaultDateFormat
        )}`}
      <MuiDateTimeRangePicker
        startDate={fromToValues.from}
        endDate={fromToValues.to}
        compareFromToValues={compareFromToValues}
        isAutomaticCompareDates={false}
        hasPrevNextPeriodButtons
        onDatesChange={onDatesChange}
        onCompareDatesToggle={() => console.log('here')}
        onCompareDatesChange={onCompareDatesChange}
      />

      <MuiDateTimeRangePicker
        startDate={fromToValues.from}
        endDate={fromToValues.to}
        compareFromToValues={compareFromToValues}
        isTimePicker={true}
        hasPrevNextPeriodButtons
        dateFormat={{
          day: 'numeric',
          month: '2-digit',
          year: 'numeric',
        }}
        shortcutsItems={[
          {
            label: 'Next week',
            from: DateTime.now().plus({ weeks: 1 }).startOf('week'),
            to: DateTime.now().plus({ weeks: 1 }).endOf('week'),
          },
          {
            label: 'Next month',
            from: DateTime.now().plus({ months: 1 }).startOf('month'),
            to: DateTime.now().plus({ months: 1 }).endOf('month'),
          },
        ]}
        onCompareDatesToggle={(isSwitchedOn?: boolean) => console.log('here', isSwitchedOn)}
        onDatesChange={onDatesChange}
        onCompareDatesChange={onCompareDatesChange}
      />

      <MuiDateTimeRangePicker
        startDate={fromToValues.from}
        endDate={fromToValues.to}
        hasPrevNextPeriodButtons={false}
        showCompareDatesButton={false}
        onDatesChange={onDatesChange}
      />

      <Typography>DateTimePicker with a custom anchor button</Typography>
      <MuiDateTimeRangePicker
        startDate={fromToValues.from}
        endDate={fromToValues.to}
        hasPrevNextPeriodButtons
        showCompareDatesButton
        onDatesChange={onDatesChange}
        onCompareDatesChange={onCompareDatesChange}
        anchorButtonProps={{
          variant: 'contained',
          color: 'secondary',
        }}
        AnchorButton={CustomAnchorButton}
      />

      <ChartToolsDropdown items={displayItems} />
      <StyledToggleButtonGroup
        items={[SwitchUnit.m3, SwitchUnit.cf, SwitchUnit.m3m].map(unit => ({ value: unit }))}
        selectedItem={switchUnit}
        onChange={value => setSwitchUnit(value as SwitchUnit)}
      />
    </Wrapper>
  )
}

export default MainComponentsPage
