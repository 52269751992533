import { ApexOptions } from 'apexcharts'
import { Interval } from 'luxon'
import { useMemo } from 'react'
import { useTheme } from '@mui/material'

import { APEX_TOOLTIP_DATE_FORMAT } from '../../constants'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface PowerChartOptionsArgs {
  earliestDate: number
  interval: Interval
  tooltipFormatter: (chart: any, options?: any) => string
  handleZoomChange: (chart: unknown, changed: { xaxis: { min: number; max: number } }) => void
  beforeZoom: (chart: unknown, changed: { xaxis: { min: number; max: number } }) => void
  meanAnnotations: YAxisAnnotations[]
  valuesModifier: { unit: string }
  decimal: number
  loading: boolean
  dashArray: number[]
}

export const usePowerChartOptions = ({
  earliestDate,
  interval,
  tooltipFormatter,
  handleZoomChange,
  beforeZoom,
  meanAnnotations,
  valuesModifier,
  decimal,
  loading,
  dashArray,
}: PowerChartOptionsArgs): ApexOptions => {
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const powerChartOptions: ApexOptions = useMemo(
    () => ({
      noData: {
        text: loading ? i18n.text('app.loading') : i18n.text('analysis.energy-balance.no-data'),
      },
      annotations: {
        yaxis: [...meanAnnotations],
      },
      chart: {
        id: 'powerChart',
        type: 'line',
        background: 'white',
        stacked: false,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        events: {
          beforeZoom: beforeZoom,
          zoomed: handleZoomChange,
        },

        toolbar: {
          show: false,
          offsetY: 21,
          autoSelected: 'zoom',
          tools: {
            reset: false,
            download: false,
            pan: false,
            selection: false,
            zoom: '<div width=0 height=0></div>',
          },
        },
      },
      tooltip: {
        x: {
          show: true,
          format: APEX_TOOLTIP_DATE_FORMAT,
        },
        y: {
          formatter: tooltipFormatter,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          style: {
            fontSize: '14px',
          },
        },
        tickPlacement: 'on',
        min: earliestDate,
        max: interval.end.toMillis(),
      },
      yaxis: {
        // legacy scale is 1.5 max y value and 5 ticks
        // max is generated based on largest value in data
        min: 0,
        max: max => max * 1.25,
        forceNiceScale: true,
        tickAmount: 5,
        labels: {
          minWidth: 80,
          maxWidth: 80,
          offsetY: -10,
          style: {
            fontSize: '14px',
          },
          formatter: v => (v % 1 ? v.toFixed(2) : v.toString()),
        },
      },
      grid: {
        show: true,
        borderColor: 'lightGrey',
        strokeDashArray: 6,
      },
      legend: {
        fontFamily: theme.typography.fontFamily,
        showForSingleSeries: true,
        horizontalAlign: 'left',
        fontSize: '14px',
        offsetY: 10,
        itemMargin: {
          vertical: 10,
        },
      },
      markers: {
        size: 0,
        // Apex uses JSON.stringify to diff options and does not see updated
        // functions such as an updated formatter, so we update this stroke
        // opacity which is not being used to force re-render
        strokeOpacity: Math.random(),
      },
      stroke: {
        width: 2,
        dashArray,
      },
    }),
    [
      earliestDate,
      interval.end,
      tooltipFormatter,
      handleZoomChange,
      beforeZoom,
      meanAnnotations,
      i18n,
      valuesModifier.unit,
      decimal,
      loading,
      dashArray,
    ]
  )
  return powerChartOptions
}
