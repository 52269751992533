import React, { FC } from 'react'
import { ButtonProps } from '@mui/material'

import { StyledCheckbox as Checkbox } from '../Checkbox/StyledCheckBox'
import { InfoTooltipIconWithTitle } from '../InfoTooltipIconWithTitle'
import { LineChartIcon } from '../../../icons/LineChartIcon'
import { MultiSelectMenuItem } from './MultiSelectMenuItem'
import { StyledMenu } from '../StyledMenu'
import { StyledMenuItem } from '../StyledMenu/StyledMenuItem'
import { StyledTooltip } from '../../Tooltip'
import { ToolBarItem, ToolbarMultiSelectItem } from '../../../../types/types'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

export type ChartToolsItem = ToolBarItem | ToolbarMultiSelectItem
interface IChartToolsDropdownProps {
  items?: ChartToolsItem[]
  children?: React.ReactNode
  slotProps?: {
    menuButton?: ButtonProps
  }
}

export const ChartToolsDropdown: FC<IChartToolsDropdownProps> = ({ items, children, slotProps }) => {
  const { i18n } = useI18nContext()
  const isMultiSelect = (item: ToolBarItem | ToolbarMultiSelectItem): item is ToolbarMultiSelectItem => 'values' in item
  return (
    <StyledMenu
      menuButtonContent={
        <>
          <LineChartIcon
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '1.2rem',
              height: '1.2rem',
            }}
          />
          {i18n.text('chart-tools.display')}
        </>
      }
      slotProps={{ menuButton: slotProps?.menuButton }}
    >
      {items &&
        items.map(item => {
          if (!isMultiSelect(item))
            return (
              <StyledMenuItem
                key={item.label}
                onClick={() => {
                  if (!item.isDisabled) {
                    item.onToggle()
                  }
                }}
              >
                <Checkbox
                  checked={item.isChecked}
                  color="secondary"
                  disabled={item.isDisabled}
                  sx={{
                    padding: 0,
                  }}
                  {...item.props}
                />
                {i18n.text(item.label)}
                {item.tooltip && (i18n.text(item.tooltip.title) || i18n.text(item.tooltip.text)) && (
                  <InfoTooltipIconWithTitle
                    title={i18n.text(item.tooltip.title) || ''}
                    tooltipText={i18n.text(item.tooltip.text) || ''}
                    icon={item.icon}
                    slotProps={{
                      wrapper: {
                        sx: {
                          marginLeft: item.tooltip.align === 'right' ? 'auto' : 'initial',
                        },
                      },
                    }}
                  />
                )}
              </StyledMenuItem>
            )
          return (
            <MultiSelectMenuItem
              key={item.label}
              item={item}
            >
              {item.tooltip ? (
                <StyledTooltip title={i18n.text(item.tooltip?.title) || ''}>
                  <p>{i18n.text(item.label)}</p>
                </StyledTooltip>
              ) : (
                <>{i18n.text(item.label) || ''}</>
              )}
            </MultiSelectMenuItem>
          )
        })}
      {children}
    </StyledMenu>
  )
}
