import React, { FC, ReactNode } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'

import AssetStatusLabel from '../AssetStatusLabel/AssetStatusLabel'
import { ASSET_CARD_SHORT_DATE_FORMAT, SPEED_UNIT_STRING_SHORT } from '../../utils/constants'
import { AssetStatus } from '../../../Shared/components/AssetCard/utils/types'
import { ClockSnoozeIcon, MoneyIcon, SpeedIcon } from '../../../Shared/components/icons'
import { OeeAsset } from '../../types/Assets.types'
import { StyledTooltip } from '../../../Shared/components/MUIComponents/Tooltip'
import { convertFromSpeedCyclesPerSecond } from '../../utils/unitConversions'
import { getAssetStatusColors } from '../../utils/helpers'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type OeeAssetCardProps = {
  status: AssetStatus
  asset: OeeAsset
}

type AdditionalDataItem = {
  tooltipText: string
  icon: ReactNode
  value: string
}

const OeeAssetDataItem: FC<AdditionalDataItem> = ({ tooltipText, icon, value }) => {
  return (
    <StyledTooltip title={tooltipText}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {icon}
        <Typography sx={{ fontSize: '0.875rem' }}>{value}</Typography>
      </Box>
    </StyledTooltip>
  )
}

const OeeAssetCard: FC<OeeAssetCardProps> = ({ status, asset }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { units } = useOEEContext()
  const { customerCurrency } = useCurrentUser()
  const assetCardColors = getAssetStatusColors(status, theme)

  const getShortDate = (date: string | undefined) =>
    date ? DateTime.fromISO(date).toFormat(ASSET_CARD_SHORT_DATE_FORMAT) : i18n.text('oee.generic.data.not-available')

  const powerInKw = 'averagePowerWatts' in asset ? asset.averagePowerWatts / 1000 : undefined

  return (
    <Box
      sx={{
        backgroundColor: assetCardColors.background,
        color: assetCardColors.text,
        padding: '0.75rem 0.6rem',
        width: '16rem',
        borderRadius: '0.5rem',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridRowGap: '0.75rem',
          fontSize: '0.8rem',
        }}
      >
        <StyledTooltip title={asset.asset?.name ?? i18n.text('oee.generic.data.not-available')}>
          <Box
            sx={{
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 600,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                lineHeight: '2rem',
                maxHeight: '4rem',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {asset.asset?.name ?? i18n.text('oee.generic.data.not-available')}
            </Typography>
          </Box>
        </StyledTooltip>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: theme.spacing(1),
            alignItems: 'center',
            marginTop: 'auto',
          }}
        >
          <AssetStatusLabel assetStatus={status} />
          {asset.hasElectricitySensor && 'averagePowerCost' in asset && asset.averagePowerCost && (
            <OeeAssetDataItem
              tooltipText={i18n.text('oee.asset-card.not-running.total-energy-costs')}
              icon={<MoneyIcon sx={{ width: '1rem', height: '1rem' }} />}
              value={`${i18n.number(asset.averagePowerCost, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${
                customerCurrency.symbol
              }/hr`}
            />
          )}
          {!asset.hasElectricitySensor && 'lastProductionCycle' in asset && asset.lastProductionCycle && (
            <OeeAssetDataItem
              tooltipText={i18n.text('oee.asset-card.not-running.last-production')}
              icon={<ClockSnoozeIcon sx={{ width: '1rem', height: '1rem' }} />}
              value={getShortDate(asset.lastProductionCycle)}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: theme.spacing(1),
            alignItems: 'center',
            marginTop: 'auto',
            '&:empty': {
              display: 'none',
            },
          }}
        >
          {'averageProductionSpeedCyclesPerSecond' in asset && (
            <OeeAssetDataItem
              tooltipText={i18n.text('oee.asset-card.running.production-speed')}
              icon={<SpeedIcon sx={{ width: '1rem', height: '1rem' }} />}
              value={`${i18n.number(
                convertFromSpeedCyclesPerSecond(asset.averageProductionSpeedCyclesPerSecond, units.speed),
                { maximumFractionDigits: 2 }
              )} ${i18n.text(SPEED_UNIT_STRING_SHORT[units.speed])} `}
            />
          )}
          {asset.hasElectricitySensor && 'lastProductionCycle' in asset && asset.lastProductionCycle && (
            <OeeAssetDataItem
              tooltipText={i18n.text('oee.asset-card.not-running.last-production')}
              icon={<ClockSnoozeIcon sx={{ width: '1rem', height: '1rem' }} />}
              value={getShortDate(asset.lastProductionCycle)}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default OeeAssetCard
