import React, { FC, useEffect, useState } from 'react'
import { Box, dialogClasses, linearProgressClasses, styled } from '@mui/material'

import Carousel from '../MuiCarousel'
import LoadingBar from '../../LoaderBar'
import { DeleteImageModal } from '../DeleteImageModal'
import { IImage } from '../../../../types/types'
import { ModalFooter, StyledDialogModal } from '../../StyledDialogModal'
import { NoImagesData } from '../NoImagesData'
import { StyledErrorButton, StyledPrimaryButton } from '../styledComponents/StyledButtons'
import { TrashIcon } from '../../../icons/TrashIcon'
import { UploadIcon } from '../../../icons/UploadIcon'
import { UploadImageModal } from '../UploadImageModal'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

/**
 * @description - ViewImagesModal
 * @param {string} title - Title of the ViewImagesModal
 * @param {string} subtitle - Subtitle of the ViewImagesModal
 * @param {boolean} isOpen - Status of the ViewImagesModal, it states whether the modal is open or not
 * @param {IImage[]} images - Images that are shown in carousel
 * @param {boolean} isLoading - It activates or deactivates a loading bar on top of the modal
 * @param {string} uploadModal[title] - Title of the UploadImageModal
 * @param {string} uploadModal[subtitle] - Subtitle of the UploadImageModal
 * @param {boolean} uploadModal[isOpen] - Status of the UploadImageModal, it states whether the modal is open or not
 * @param {boolean} uploadModal[isLoading] - It activates or deactivates a loading bar on top of the UploadImageModal modal
 * @param {function(val: boolean): void} uploadModal[onToggleModal] - A function to open or close the UploadImageModal modal
 * @param {function(val: boolean): void} onToggleModal - A function  to open or close the ViewImagesModal modal
 * @param {function(): void} onUpload - A function is called when a user click on 'Attach images' button on the UploadImageModal modal
 * @param {function(imageId: string): void} onDelete - A function is called when a user clicks on the 'Delete' button
 * @param {function(files: File[]): void} onSelectFiles - A function to control a state of selected files from outside
 */

interface IViewImagesModalProps {
  title: string
  subtitle?: string
  isOpen: boolean
  images: IImage[]
  isLoading: boolean
  uploadModal?: {
    title?: string
    subtitle?: string
    isOpen?: boolean
    isLoading?: boolean
    onToggleModal?: (val: boolean) => void
  }
  onToggleModal: (val: boolean) => void
  onUpload: () => void
  onDelete: (imageId: string) => void
  onSelectFiles: (files: File[]) => void
}

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
}))

export const ViewImagesModal: FC<IViewImagesModalProps> = ({
  title,
  subtitle,
  images,
  isLoading,
  isOpen,
  uploadModal,
  onToggleModal,
  onDelete,
  onUpload,
  onSelectFiles,
}) => {
  const { i18n } = useI18nContext()
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)
  const [isDeleteImageModalOpen, setIsDeleteImageModalOpen] = useState(false)
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setSelectedImageIndex(0)
    }
  }, [isOpen])

  function handleUpload() {
    onUpload()
    if (!uploadModal?.onToggleModal) {
      setIsUploadImageModalOpen(false)
    }
  }

  function handleDelete() {
    const selectedImage =
      selectedImageIndex && selectedImageIndex < images.length ? images[selectedImageIndex] : images[0]
    if (selectedImage) {
      onDelete(selectedImage.id)
    } else if (images[0]) {
      onDelete(images[0].id)
    }
    if (selectedImageIndex === images.length - 1 && selectedImageIndex !== 0) {
      setSelectedImageIndex(selectedImageIndex - 1)
    }
    setIsDeleteImageModalOpen(false)
  }

  function toggleUploadModal(value: boolean) {
    if (uploadModal?.onToggleModal) {
      uploadModal.onToggleModal(value)
    } else {
      setIsUploadImageModalOpen(value)
    }
  }

  function closeDeleteModal() {
    setIsDeleteImageModalOpen(false)
  }

  function onImageIndexChange(index: number) {
    setSelectedImageIndex(index)
  }

  function closeModal() {
    onToggleModal(false)
  }

  return (
    <StyledDialogModal
      isOpen={isOpen}
      title={title}
      subtitle={subtitle}
      isLoading={false}
      onClose={closeModal}
      maxWidth="md"
      height="responsive"
      sx={{
        '.modal-box': {
          display: 'flex',
          flexDirection: 'column',
        },
        [`.${dialogClasses.paper}`]: {
          maxHeight: '700px',
          height: '100%',
          maxWidth: '800px',
        },
      }}
    >
      <LoadingBarWrapper>
        <LoadingBar
          loading={!!isLoading}
          progressProps={{
            sx: {
              background: theme => theme.palette.SFIGreyLight[200],
              height: '2px',
              [`${linearProgressClasses.bar}`]: {
                background: theme => theme.palette.SFIBrand[800],
              },
            },
          }}
        />
      </LoadingBarWrapper>
      <ContentWrapper>
        {images.length > 0 && (
          <Carousel
            imageProps={{
              sx: {
                width: '100%',
                height: '500px',
                objectFit: 'cover',
                cursor: 'pointer',
              },
            }}
            index={selectedImageIndex}
            onIndexChange={onImageIndexChange}
            images={images.map(image => image.url)}
          />
        )}
        {images.length === 0 && <NoImagesData />}
      </ContentWrapper>
      <ModalFooter
        sx={{
          gap: '0.75rem',
          marginTop: 'auto',
          overflow: 'visible',
        }}
      >
        <StyledErrorButton
          disabled={images.length === 0}
          disableRipple
          onClick={() => setIsDeleteImageModalOpen(true)}
        >
          <TrashIcon style={{ fontSize: '1.25rem', fill: 'white' }} />
          {i18n.text('integrations.delete')}
        </StyledErrorButton>
        <StyledPrimaryButton
          disableRipple
          onClick={() => toggleUploadModal(true)}
        >
          <UploadIcon
            sx={{
              width: '1.25rem',
              height: '1.25rem',
            }}
          />
          {i18n.text('general.image.add-image')}
        </StyledPrimaryButton>
      </ModalFooter>
      <DeleteImageModal
        isOpen={isDeleteImageModalOpen}
        title={i18n.text('general.image.delete-image')}
        subtitle={i18n.text('general.image.delete-image.confirmation')}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
      />
      <UploadImageModal
        title={uploadModal?.title || i18n.text('general.image.upload-and-attach-image')}
        isOpen={isUploadImageModalOpen || !!uploadModal?.isOpen}
        isLoading={!!uploadModal?.isLoading}
        subtitle={uploadModal?.subtitle || i18n.text('general.image.upload-and-attach-image.extend')}
        onToggleModal={toggleUploadModal}
        onSelectFiles={onSelectFiles}
        onUpload={handleUpload}
      />
    </StyledDialogModal>
  )
}

const LoadingBarWrapper = styled(Box)({
  marginLeft: '-1.5rem',
  width: 'calc(100% + 3rem)',
})
