import { Theme } from '@mui/material'

import { GetAssetsForAlertMessagesQuery } from '../../Shared/graphql/codegen'
import { ICustomTableColumn } from '../../Shared/types/types'
import { IMessagesData } from '../pages/AlertsPage/MessagesPage'
import { Propositions } from '../types/alertTypes'
import { StyledMessageChip } from '../components/StyledMessageChip'

interface IGetMessagesColumnsArgs {
  theme: Theme
  propositions: Propositions
  isDateInDescOrder: boolean
  handleDateSorting: () => void
}

export const getMessagesColumns = ({
  isDateInDescOrder,
  propositions,
  handleDateSorting,
}: IGetMessagesColumnsArgs): ICustomTableColumn<IMessagesData>[] => [
  {
    id: 'date',
    label: 'alerts.messages.column.date',
    width: '15%',
    align: 'left',
    isBoldText: true,
    isSorted: isDateInDescOrder,
    onSortToggle: handleDateSorting,
  },
  {
    id: 'asset',
    label: 'alerts.messages.column.subject',
    width: '15%',
    align: 'left',
  },
  {
    id: 'proposition',
    label: 'alerts.messages.column.product',
    width: '15%',
    align: 'left',
    children: item => (
      <StyledMessageChip
        item={item}
        propositions={propositions}
      />
    ),
  },
  {
    id: 'category',
    label: 'alerts.messages.column.category',
    width: '20%',
    align: 'left',
  },
  {
    id: 'message',
    label: 'messages.headers.message',
    width: '25%',
    align: 'left',
  },
]

export function getAssetName(subject: string, assetsData?: GetAssetsForAlertMessagesQuery) {
  const assetId = subject.split('.').reverse()[0]
  const assets = assetsData?.myOrg?.assets || []
  return assets.find(asset => asset.id === assetId)?.name || ''
}

export function createMessagesRowData({
  id,
  date,
  asset,
  proposition,
  category,
  message,
}: IMessagesData): IMessagesData {
  return { id, date, asset, proposition, category, message }
}
