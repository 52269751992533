import { FC, useState } from 'react'

import {
  BulletIconBox,
  MainInfoBox,
  MoreInfoBox,
  MoreInfoButton,
  SavingsMetric,
  SavingsNumbersBox,
  Tip,
  TotalSavingsBox,
} from './ComponentsMainSavingsInfoBox'
import {
  RegularLightMdText,
  RegularLightSmText,
  SemiboldBrandDarkMdText,
  SemiboldDarkMdDisplayText,
  SemiboldLightXlText,
} from '../../Common/v1/index'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

type MainSavingInfoProps = {
  savingName: string
  costSaving: number
  energySaving: number
  emissionsSaving: number
  version: string
  note: string | undefined
  numInsights: number
  numActions: number
  trTip: boolean
  alertTip: boolean
  webappTip: boolean
}

export const MainSavingsInfoBox: FC<MainSavingInfoProps> = ({
  savingName,
  costSaving,
  energySaving,
  emissionsSaving,
  version,
  note,
  numInsights,
  numActions,
  trTip,
  alertTip,
  webappTip,
}) => {
  const { i18n, userLocale } = useI18nContext()
  const userLocaleFormat = userLocale?.replace('_', '-')
  const templateVersion = 'v1'
  const [moreInfoOpen, setMoreInfoOpen] = useState(false)
  const inSightsArray = [...Array(numInsights).keys()]
  const actionsArray = [...Array(numActions).keys()]
  const vars = {
    costSaving: costSaving.toLocaleString(userLocaleFormat, { maximumFractionDigits: 0 }),
    energySaving: energySaving.toLocaleString(userLocaleFormat, { maximumFractionDigits: 0 }),
    emissionsSaving: emissionsSaving.toLocaleString(userLocaleFormat, { maximumFractionDigits: 0 }),
  }
  return (
    <MainInfoBox>
      <SemiboldBrandDarkMdText>{i18n.text(`scan.savings.${templateVersion}.savings`)}</SemiboldBrandDarkMdText>
      <MoreInfoBox>
        <SemiboldDarkMdDisplayText>
          {i18n.text(`scan.savings.${savingName}.${version}.title`)}
        </SemiboldDarkMdDisplayText>
        {moreInfoOpen && (
          <RegularLightSmText> {i18n.text(`scan.savings.${savingName}.${version}.more-info`)}</RegularLightSmText>
        )}
        <MoreInfoButton onClick={() => setMoreInfoOpen(!moreInfoOpen)}>
          {moreInfoOpen ? 'Less info' : 'More info'}
        </MoreInfoButton>
      </MoreInfoBox>
      <TotalSavingsBox>
        <SemiboldLightXlText>{i18n.text(`scan.savings.${templateVersion}.total-savings`)}</SemiboldLightXlText>
        <SavingsNumbersBox>
          {costSaving > 0 && (
            <SavingsMetric
              metric={costSaving}
              userLocaleFormat={userLocaleFormat}
              unit={'€'}
            />
          )}
          {energySaving > 0 && (
            <SavingsMetric
              metric={energySaving}
              userLocaleFormat={userLocaleFormat}
              unit={'kWh'}
            />
          )}
          {emissionsSaving > 0 && (
            <SavingsMetric
              metric={emissionsSaving}
              userLocaleFormat={userLocaleFormat}
              unit={'kg CO₂e'}
            />
          )}
        </SavingsNumbersBox>
      </TotalSavingsBox>
      <BulletIconBox
        templateVersion={templateVersion}
        textArray={inSightsArray}
        savingName={savingName}
        version={version}
        vars={vars}
        type={'insights'}
      />
      <BulletIconBox
        templateVersion={templateVersion}
        textArray={actionsArray}
        savingName={savingName}
        version={version}
        vars={vars}
        type={'key-actions'}
      />
      {note && (
        <RegularLightMdText>{i18n.text(`scan.savings.${templateVersion}.note`) + ' ' + note}</RegularLightMdText>
      )}
      <Tip
        webappTip={webappTip}
        alertTip={alertTip}
        trTip={trTip}
        templateVersion={templateVersion}
        savingName={savingName}
        version={version}
      />
    </MainInfoBox>
  )
}
