import React, { FC } from 'react'
import TableCell, { TableCellProps } from '@mui/material/TableCell'

interface IStyledTableCellProps extends TableCellProps {
  isBoldText?: boolean
}

export const StyledTableCell: FC<IStyledTableCellProps> = ({ isBoldText, ...props }) => (
  <TableCell
    {...props}
    sx={{
      color: theme => (isBoldText ? theme.palette.SFIGreyLight[800] : theme.palette.SFIGreyLight[600]),
      fontSize: '0.875rem',
      fontWeight: isBoldText ? 500 : 400,
      lineHeight: '1.25rem',
      borderBottom: theme => `1px solid ${theme.palette.SFIGreyLight[200]}`,
    }}
  />
)
