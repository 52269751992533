import { z, ZodIssueCode } from "zod"
import { validationCases } from "./passwordValidationCases"

export const SetPasswordFormSchema =  z
    .object({
      code: z.string().refine(value => !!value, {
        message: 'forms.profile.required-error',
      }),
      password: z
        .string()
        .refine(value => !!value, {
          message: 'forms.profile.required-error',
        })
        .refine(value => validationCases.every(validationCase => validationCase.onValidate(value)), {
          message: 'forms.submit.no-criteria-password-match-error',
        }),
      confirmPassword: z.string().refine(value => !!value, {
        message: 'forms.profile.required-error',
      }),
    })
    .superRefine((data, ctx) => {
      const { password, confirmPassword } = data
      if (password !== confirmPassword) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['confirmPassword'],
          message: 'forms.reset-password.not-match-error',
        })
      }
    })

export type SetPasswordFormData = z.infer<typeof SetPasswordFormSchema>