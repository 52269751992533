import { CustomHeaders } from './types'

export const getterFunction = async ({
  url,
  data,
  getCustomHeaders,
}: {
  url: string
  data?: any
  getCustomHeaders?: (() => Promise<CustomHeaders>) | null
}) => {
  const headers: { [key: string]: string } = { 'Content-Type': 'application/json' }

  if (getCustomHeaders) {
    const customHeaders = await getCustomHeaders()

    for (const [key, value] of Object.entries(customHeaders)) {
      headers[key] = value
    }
  }

  const endpoint = url + '?nocache=' + new Date().getTime()

  const response = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  })

  if (!response.ok) {
    throw new Error('Error fetching media upload signature.')
  }

  return response.json()
}
