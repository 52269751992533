import { ISODateTime } from '../../../Shared/types/types'
import {
  Pdm_Asset_Health_Report_Status_Enum,
  Pdm_Asset_Health_Report_Trigger_Types_Enum,
} from '../../../Shared/graphql/codegen'

export enum VibrationStatus {
  Good = 0,
  Warning = 1,
  Alarm = 2,
  Offline = -1,
  NoData = -2,
  NotConfigured = -3,
}

export type AssetHealthReport = {
  __typename: 'PdmAssetHealthReport'
  custom?: string
  id: string
  createdAt: ISODateTime
  status: Pdm_Asset_Health_Report_Status_Enum
  fromDate: ISODateTime
  toDate: ISODateTime
  triggerType: Pdm_Asset_Health_Report_Trigger_Types_Enum
}

export type AssetCardData = {
  groupName: string
  probeId: string
  machineId: string
  name: string
  machineName?: string
  probeName?: string
  description?: string
  lastMeasurement?: ISODateTime
  lastMessage?: ISODateTime
  lookbackDuration: number
  vibrationStatus: number
}

export enum VibrationIssueType {
  Offline = 'offline',
  HighVibration = 'high-vibration',
  IncreasingVibration = 'increasing-vibration',
  Other = 'other',
}

export type GroupedCards = {
  [key: string]: AssetCardData[]
}
