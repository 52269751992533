import { Card, useTheme } from '@mui/material'
import { FC } from 'react'

type StatusCardProps = {
  status: 'info' | 'warning' | 'error' | 'success' | 'grey' | 'black'
}

/** StatusCard component is used to display a card with a colored left border that indicates the status of the card.
 * The status can be one of the following: 'info', 'warning', 'error', 'success', 'grey', 'black'.
 * @param {string} status - The status of the card.
 * @param {ReactNode} children - The children to be rendered inside the card.
 *  */
const StatusCard: FC<StatusCardProps> = ({ status, children }) => {
  const theme = useTheme()

  const statusColors = {
    info: theme.palette.SFIBrand[900],
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
    grey: theme.palette.SFIGreyLight[400],
    black: theme.palette.SFIGreyLight[700],
  }

  return (
    <Card
      sx={{
        display: 'grid',
        padding: theme.spacing(1.2),
        border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
        borderLeft: `5px solid ${statusColors[status]}`,
        borderRadius: '10px',
        gap: theme.spacing(1),
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        '&:hover': {
          cursor: 'pointer',
          boxShadow: '0 2px 4px -2px rgba(0, 0, 0, 0.06), 0 4px 8px -2px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      {children}
    </Card>
  )
}

export default StatusCard
