import React, { FC } from 'react'
import { Box, IconButton } from '@mui/material'

import { EditIcon, TrashIcon } from '../../../Shared/components/icons'
import { IAlertRuleData } from '../../types/alertTypes'

interface IAlertConfigurationActionsProps {
  id?: string
  row?: IAlertRuleData
  onDeleteButtonClick: (id?: string) => void
  onEditButtonClick: (id?: string, row?: IAlertRuleData) => void
}

export const AlertConfigurationActions: FC<IAlertConfigurationActionsProps> = ({
  id,
  row,
  onDeleteButtonClick,
  onEditButtonClick,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.25rem',
      }}
    >
      <IconButton
        onClick={() => onDeleteButtonClick(id)}
        sx={{
          color: theme => theme.palette.grey[600],
          padding: '0.625rem',
        }}
      >
        <TrashIcon />
      </IconButton>
      <IconButton
        onClick={() => onEditButtonClick(id, row)}
        sx={{
          color: theme => theme.palette.grey[600],
          padding: '0.625rem',
        }}
      >
        <EditIcon />
      </IconButton>
    </Box>
  )
}
