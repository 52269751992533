import React from 'react'
import { ApolloClient } from '@apollo/client'
import { Box, styled } from '@mui/material'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { styled as muiStyled } from '@mui/material/styles'

import Bridges from './Bridges'
import Disclaimer from './Disclaimer'
import Sensors from './Sensors'
import SecondaryNav, { OptionalIconLink } from '../../../Shared/components/MUIComponents/SecondaryNav/SecondaryNav'

const LocalWrapper = muiStyled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  overflowY: 'auto',
}))

const LocalLayout = styled('div')(({ theme }) => ({
  minHeight: '60vh',
  backgroundColor: `${theme.palette.SFIBase.white}`,
  margin: '0.5em',
  textAlign: 'center',
  padding: '2em',
  color: theme.palette.SFIGreyLight[950],
  h1: {
    fontSize: '1.6em',
    fontWeight: 500,
  },
  h2: {
    fontSize: '1.3em',
    fontWeight: 500,
  },
  h3: {
    fontSize: '1em',
    fontWeight: 500,
  },
}))

const DisclaimerLayout = styled('div')(({ theme }) => ({
  backgroundColor: `${theme.palette.SFIBase.white}`,
  margin: '0.5em',
  textAlign: 'center',
  padding: '2em 2em 0 2em',
  color: `${theme.palette.SFIGreyLight[950]}`,
  h1: {
    fontSize: '1.6em',
    fontWeight: 500,
  },
  h2: {
    fontSize: '1.3em',
    fontWeight: 500,
  },
  h3: {
    fontSize: '1em',
    fontWeight: 500,
  },
}))

type Props = {
  apolloClient: ApolloClient<unknown>
  location: {
    pathname?: string
  }
}

export function Installation(props: Props) {
  const { path } = useRouteMatch()
  const paths = {
    sensors: `${path}/sensors`,
    bridges: `${path}/bridges`,
  }

  const secondaryLinks: OptionalIconLink[] = [
    {
      name: 'Sensors',
      i18n: 'installation.subroutes.sensors',
      path: paths.sensors,
    },
    {
      name: 'Bridges',
      i18n: 'installation.subroutes.bridges',
      path: paths.bridges,
    },
  ]

  return (
    <LocalWrapper>
      <SecondaryNav links={secondaryLinks} />
      <DisclaimerLayout>
        {/* @ts-expect-error ts-migrate(2559) FIXME: Type '{ location: { pathname?: string | undefined;... Remove this comment to see the full error message */}
        <Disclaimer {...props} />
      </DisclaimerLayout>
      <LocalLayout>
        <Switch>
          <Route path={paths.sensors}>
            <Sensors {...props} />
          </Route>
          <Route path={paths.bridges}>
            <Bridges {...(props as any)} />
          </Route>
          <Redirect to={paths.sensors} />
        </Switch>
      </LocalLayout>
    </LocalWrapper>
  )
}

export default Installation
