import { ApolloClient } from '@apollo/client'
import { DateTime, Interval } from 'luxon'
import { darken } from '@mui/material'
import { intervalToDuration, isDate } from 'date-fns'

import {
  AirSensorLocationFragment,
  CompressedAirTimeLocationTimeSeriesBatchDocument,
  CompressedAirTimeLocationTimeSeriesBatchQuery,
  CompressedAirTimeLocationTimeSeriesBatchQueryVariables,
} from '../../Shared/graphql/codegen'
import { CFM_IN_ONE_CUBIC_METER } from '../../Shared/constants/unitsTransformations'
import { CompressedAirDataMap, ISeriesData, PreviousState } from '../types/compressed-air_types'
import { colorScheme } from './colorScheme'
import { toLocalDateTime } from '../../Shared/utils'

export const MIN_DAYS_FOR_WEEKLY_AGGREGATION = 14
export const NEGATIVE_MIN_VALUE = -0.25
export const CA_TOOLBAR_DECIMAL_KEY = 'sf_compressed_air_tools_decimal'

const dateFormat = {
  month: 'short',
  day: 'numeric',
}

export const getIsWeeklyAggregation = (from: number, to: number) =>
  (to - from) / (1000 * 3600 * 24) >= MIN_DAYS_FOR_WEEKLY_AGGREGATION

export const generateColorMap = (sensorLocationIds: string[], isDarkColor?: boolean) => {
  let counter = 0
  const colorDictionary: { [key: string]: string } = {}

  sensorLocationIds.forEach(id => {
    colorDictionary[id] = !isDarkColor ? getAssetColor(counter++) : darken(getAssetColor(counter++), 0.3)
  })

  return colorDictionary
}

export function getAssetColor(index: number): string {
  const newIndex = index > colorScheme.length - 1 ? index % colorScheme.length : index

  return colorScheme[newIndex]
}

export type FetchParams = {
  interval: Interval
  consumptionResolution?: string
}

type FetcherReturnType = {
  id: string
  data: {
    name: string
    flowPoints: {
      time: DateTime
      value: number | null
      min: number | null
      max: number | null
    }[]
    temperaturePoints: {
      time: DateTime
      value: number | null
      min: number | null
      max: number | null
    }[]
    pressurePoints: {
      time: DateTime
      value: number | null
      min: number | null
      max: number | null
    }[]
    consumptionPoints: {
      time: DateTime
      value: number
    }[]
  }
}[]

export const buildTimeSeriesBatchFetcher = (client: ApolloClient<unknown>) => {
  return async (ids: string[], args: FetchParams): Promise<FetcherReturnType> => {
    const { interval } = args
    try {
      const {
        data: { sensorLocations },
      } = await client.query<
        CompressedAirTimeLocationTimeSeriesBatchQuery,
        CompressedAirTimeLocationTimeSeriesBatchQueryVariables
      >({
        query: CompressedAirTimeLocationTimeSeriesBatchDocument,
        variables: {
          ids,
          from: toLocalDateTime(interval.start),
          to: toLocalDateTime(interval.end),
        },
      })

      const result: FetcherReturnType = []

      ids.forEach(id => {
        const sensorLocation = sensorLocations.find(s => s.id === id)
        if (!sensorLocation) {
          throw new Error(`Could not find sensor location with id ${id}`)
        }

        const flowSeries = sensorLocation.insights?.timeWindow.flowSeries
        const temperatureSeries = sensorLocation.insights?.timeWindow.temperatureSeries
        const pressureSeries = sensorLocation.insights?.timeWindow.pressureSeries
        const consumptionSeries = sensorLocation.insights?.timeWindow.consuptionSeries

        if (!flowSeries) {
          throw new Error(`Could not fetch flow series for sensor location with id ${id}`)
        }

        if (!temperatureSeries) {
          throw new Error(`Could not fetch temperature series for sensor location with id ${id}`)
        }

        if (!pressureSeries) {
          throw new Error(`Could not fetch pressure series for sensor location with id ${id}`)
        }

        if (!consumptionSeries) {
          throw new Error(`Could not fetch consumption series for sensor location with id ${id}`)
        }

        const flowPoints = flowSeries.map(point => ({
          time: DateTime.fromISO(point.time),
          value: point.value ?? null,
          min: point.min ?? null,
          max: point.max ?? null,
        }))

        const temperaturePoints = temperatureSeries.map(point => ({
          time: DateTime.fromISO(point.time),
          value: point.value ?? null,
          min: point.min ?? null,
          max: point.max ?? null,
        }))

        const pressurePoints = pressureSeries.map(point => ({
          time: DateTime.fromISO(point.time),
          value: point.value ?? null,
          min: point.min ?? null,
          max: point.max ?? null,
        }))

        const consumptionPoints = consumptionSeries.map(point => ({
          time: DateTime.fromISO(point.time),
          value: point.value ?? null,
        }))

        if (flowPoints.length === 0) {
          console.error(`No data points for sensor location with id ${id}`)
        }

        result.push({
          id,
          data: {
            name: sensorLocation.name,
            flowPoints,
            consumptionPoints,
            temperaturePoints,
            pressurePoints,
          },
        })
      })

      return result
    } catch (error) {
      console.warn(error)
      throw error
    }
  }
}

/**
 * Checks if valid interval and < 12.1 months
 */
export const isValidInterval = (from: Date | null, to: Date | null) => {
  if (!from || !to) return false
  if (!isDate(from) && !isDate(to)) return false

  const interval = intervalToDuration({
    start: from,
    end: to,
  })
  // 12.1 months limit in the API.
  if (!interval.years) return true
  if (interval.years === 1) {
    return !interval.months && !interval.weeks && !interval.days
  }
  return false
}

/**
 * Returns a week with start and end dates where val is included
 */
export function changeToWeeklyAggregatedData(from: number, to: number, val: string, i18n: $I18FixMe) {
  if (!DateTime.fromISO(val).isValid) {
    return ''
  }
  const translateDate = (date: DateTime) => i18n.date(date.toJSDate(), dateFormat)

  const sundayAfterValDate = DateTime.fromISO(val).set({ weekday: 7 })
  const fromDate =
    new Date(val).getTime() < from ? translateDate(DateTime.fromMillis(from)) : translateDate(DateTime.fromISO(val))
  const toDate =
    sundayAfterValDate.toJSDate().getTime() > to
      ? translateDate(DateTime.fromMillis(to))
      : translateDate(sundayAfterValDate)
  return `${fromDate} - ${toDate}`
}

export const transformToCFMIfCondition = (isTrue: boolean) => (num: number | null) =>
  (num || 0) * (isTrue ? CFM_IN_ONE_CUBIC_METER : 1)

export const getPreviousName = (name: string, previousState: PreviousState, i18n: $I18FixMe) => {
  const getNewName = (index: number) => `${name} (${i18n.text('app.graphs.period')} ${index})`
  return previousState === PreviousState.IS_PREVIOUS
    ? getNewName(2)
    : previousState === PreviousState.HAS_PREVIOUS
    ? getNewName(1)
    : name
}

export const sortByAlphabeticalOrder = (arr: ISeriesData[] | ApexAxisChartSeries) => {
  return arr.sort(function (a, b) {
    if (a.name && b.name) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
    }
    return 0
  })
}

export function sortByTotalConsumption(dataMap: CompressedAirDataMap, transformToCFM: (num: number | null) => number) {
  if (!dataMap) {
    return []
  }

  return [...dataMap.entries()].sort(function (a, b) {
    const totalValueA = a[1].consumptionPoints.reduce((acc, curr) => acc + transformToCFM(curr.value), 0)
    const totalValueB = b[1].consumptionPoints.reduce((acc, curr) => acc + transformToCFM(curr.value), 0)
    if (totalValueA && totalValueB) {
      if (totalValueB < totalValueA) {
        return -1
      }
      if (totalValueB > totalValueA) {
        return 1
      }
    }
    return 0
  })
}

export function sortAccordingToCompareData(dataMap: CompressedAirDataMap, sortingArray: string[]) {
  if (!dataMap) {
    return []
  }

  return [...dataMap.entries()].sort((a, b) => {
    const indexA = sortingArray.indexOf(a[0])
    const indexB = sortingArray.indexOf(b[0])
    return indexA - indexB
  })
}
export const checkIsTeltonika = (sensorLocation: AirSensorLocationFragment) => {
  const sensor = sensorLocation?.sensorConnection?.connection?.sensor
  return sensor && sensor.model.manufacturer === 'Teltonika'
}

export const monthWeekDayFormat = {
  month: 'short',
  weekday: 'short',
}

export const dayTimeFormat = {
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
}
