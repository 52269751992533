import React, { FC, useState } from 'react'
import { Box, Table, TableBody, TableContainer, TableHead, TableRow, useTheme } from '@mui/material'

import AddStopReasonsDropdown from '../AddStopReasonsDropdown/AddStopReasonsDropdown'
import AssetTableRow from './AssetTableRow'
import SettingsPageTitle from '../../SettingsPageTitle'
import {
  Asset,
  CreateOeeSensorLocationProductionStopReasonsBatchInput,
  OeeCustomerProductionStopReason,
  useClearOeeStopReasonsFromSensorLocationMutation,
  useCreateOeeSensorLocationProductionStopReasonsBatchMutation,
} from '../../../../../Shared/graphql/codegen'
import { AssetWithAssignedStopReasons } from '../../../../types/Assets.types'
import { ID } from '../../../../../Shared/types/types'
import { StyledTableHeadCell } from '../../../StyledTable'
import { getAssignedStopReasons } from '../../../../utils/assetStopReasonsAssignments'
import { useCurrentUser } from '../../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../../Shared/contexts/ToastContext'

type AssetsTableProps = {
  assets: Asset[]
  stopReasons: OeeCustomerProductionStopReason[]
  onUpdate: () => void
}

const AssetsTable: FC<AssetsTableProps> = ({ assets, stopReasons, onUpdate }) => {
  const [selectedAssets, setSelectedAssets] = useState<AssetWithAssignedStopReasons[]>([])

  const { selectedCustomer } = useCurrentUser()
  const { i18n } = useI18nContext()
  const { showToast } = useToastContext()
  const theme = useTheme()

  const sortedStopReasons = [...stopReasons].sort((a, b) => a.text.localeCompare(b.text))

  const onSuccess = () => {
    showToast(i18n.text('general.success.changes-saved'), 'success', 'Success!')
    onUpdate()
  }

  const onError = (error: string) => showToast(error, 'error', 'Error')

  const [clearStopReasonsFromAssets, { loading: isClearing }] = useClearOeeStopReasonsFromSensorLocationMutation({
    awaitRefetchQueries: true,
    onError: async () => onError(i18n.text('general.error.something-went-wrong')),
  })

  const [assignStopReasons, { loading: isUpdating }] = useCreateOeeSensorLocationProductionStopReasonsBatchMutation({
    awaitRefetchQueries: true,
    onCompleted: async () => onSuccess(),
    onError: async () => onError(i18n.text('general.error.something-went-wrong')),
  })

  const noSelectedAssets = selectedAssets.length === 0

  const selectAsset = (assetId: string) => {
    const selectedAsset = assets.find(asset => asset.enoceanPulseCounterSensorLocations?.[0].id === assetId)

    if (!selectedAsset) return

    const assignedStopReasons = getAssignedStopReasons(selectedAsset)

    setSelectedAssets(prevSelected => {
      const restSelected = prevSelected.filter(prevSelectedAsset => prevSelectedAsset.locationId !== assetId)
      return [
        ...restSelected,
        {
          locationId: assetId,
          customerProductionStopReasonIds: assignedStopReasons ?? [],
        },
      ]
    })
  }

  const deselectAsset = (assetId: string) => {
    setSelectedAssets(prevSelected =>
      prevSelected.filter(prevSelectedAsset => prevSelectedAsset.locationId !== assetId)
    )
  }

  const handleAssetSelection = (assetId: string, isSelected: boolean) => {
    if (isSelected) selectAsset(assetId)
    else deselectAsset(assetId)
  }

  const handleAssetsStopReasonsUpdate = async (updatedReasons: AssetWithAssignedStopReasons[]) => {
    await clearStopReasonsFromAssets({
      variables: {
        customerId: selectedCustomer.id as ID,
        locationIds: updatedReasons.map(updatedReason => updatedReason.locationId as ID),
      },
    })
    await assignStopReasons({
      variables: {
        customerId: selectedCustomer.id as ID,
        objects: updatedReasons.filter(
          updatedReason => updatedReason.customerProductionStopReasonIds.length > 0
        ) as unknown as CreateOeeSensorLocationProductionStopReasonsBatchInput[],
      },
    })

    setSelectedAssets([])
  }

  return (
    <Box
      sx={{
        borderRadius: '0.75rem',
        border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
        boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
      }}
    >
      <Box sx={{ padding: '1.25rem 1.5rem', borderBottom: `1px solid ${theme.palette.SFIGreyLight[200]}` }}>
        <SettingsPageTitle
          title={i18n.text('settings.oee-settings.stop-reasons.asset-table.title')}
          description={i18n.text('settings.oee-settings.stop-reasons.asset-table.description')}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem', padding: '0.75rem' }}>
        <AddStopReasonsDropdown
          stopReasons={sortedStopReasons}
          selectedAssets={selectedAssets}
          onStopReasonsUpdate={handleAssetsStopReasonsUpdate}
          disabled={noSelectedAssets}
          isLoading={isUpdating || isClearing}
        />
      </Box>
      <TableContainer component={Box}>
        <Table
          stickyHeader
          aria-label={i18n.text('settings.oee-settings.stop-reasons.asset-table.description')}
        >
          <TableHead>
            <TableRow sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
              <StyledTableHeadCell>
                {i18n.text('settings.oee-settings.stop-reasons.asset-table.assign-to')}
              </StyledTableHeadCell>
              <StyledTableHeadCell>
                {i18n.text('settings.oee-settings.stop-reasons.asset-table.current-reasons')}
              </StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets.map(asset => (
              <AssetTableRow
                key={asset.id}
                asset={asset}
                stopReasons={sortedStopReasons}
                isChecked={selectedAssets.some(
                  selectedAsset => selectedAsset.locationId === asset.enoceanPulseCounterSensorLocations?.[0].id
                )}
                onAssetSelectionChange={handleAssetSelection}
                onStopReasonsUpdate={handleAssetsStopReasonsUpdate}
                onDelete={onUpdate}
                isLoading={isUpdating || isClearing}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default AssetsTable
