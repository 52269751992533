import * as Sentry from '@sentry/react'
import {
  ApolloClient,
  ApolloProvider as ApolloProviderImpl,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client'
import { Auth as Cognito } from 'aws-amplify'
import type { PropsWithChildren } from 'react'
import { onError } from '@apollo/link-error'
import { setContext } from '@apollo/client/link/context'

import fragmentMatcher from './fragment-matcher'
import type { TypedTypePolicies } from './apollo-policies'
import { getGraphQLPath } from '../utils'

const { possibleTypes } = fragmentMatcher

// Disable caching per Type
const typePolicies: TypedTypePolicies = {
  Metadata: {
    keyFields: false,
  },
  Recipient: {
    keyFields: false,
  },
  Translation: {
    keyFields: false,
  },
}

const sentryLogLink = onError(({ operation, response, graphQLErrors, networkError }) => {
  const opName = operation.operationName

  const opContext = {
    'Graphql operation': {
      name: opName,
      response: JSON.stringify(
        {
          data: response?.data,
          errors: response?.errors,
        },
        null,
        4
      ),
    },
  }

  if (networkError) {
    Sentry.captureException(networkError, {
      contexts: {
        ...opContext,
      },
    })
  }

  graphQLErrors?.forEach(err => {
    const contexts = {
      ...opContext,
      'GraphQL Error': {
        name: err.name,
        message: err.message,
        path: err.path,
        extensions: JSON.stringify(err.extensions, null, 4),
      },
    }

    if (err.originalError) {
      Sentry.captureException(err.originalError, {
        contexts,
      })
    } else {
      Sentry.captureMessage(err.message, {
        level: Sentry.Severity.Error,
        contexts,
      })
    }
  })
})

function terminatingLink() {
  const authLink = setContext(async (_, { headers }) => {
    let token = ''
    try {
      const session = await Cognito.currentSession()
      const idToken = session.getIdToken()
      token = idToken.getJwtToken()
    } catch {
      // no jwt present
    }
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-hasura-role': 'webclient',
        ...headers,
      },
    }
  })

  const httpLink = new HttpLink({
    uri: options => getGraphQLPath(options),
  })

  return from([sentryLogLink, authLink, httpLink])
}

const mkApolloClient = () => {
  return new ApolloClient({
    link: terminatingLink(),
    cache: new InMemoryCache({
      typePolicies,
      possibleTypes,
    }),
  })
}

const apolloClient = mkApolloClient()

export const ApolloProvider = ({ children }: PropsWithChildren<unknown>) => (
  <ApolloProviderImpl client={apolloClient}>{children}</ApolloProviderImpl>
)
