import React from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'

import { useI18nContext } from '../../contexts/i18nContext/I18nContext'

type ErrorProps = {
  error?: {
    message: string
  }
  height?: string
}

export const Error = ({ error, height }: ErrorProps) => {
  const { i18n } = useI18nContext()

  return (
    <Box
      sx={{
        width: '100%',
        height: height ? height : '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="h6"
        fontWeight={400}
        textAlign="center"
      >
        {error?.message || <>{i18n.text('error.generic.fetching-data')}</>}
      </Typography>
    </Box>
  )
}

export default Error
