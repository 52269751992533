import React, { useEffect, useState } from 'react'
import { isNull } from 'lodash'
import { useMemo } from 'react'

import { ID, ISODateTime } from '../../Shared/types/types'
import { IExtendedAirSensorLocation, ISeriesCoords } from '../types/compressed-air_types'
import { createCtx, toLocalDateTime } from '../../Shared/utils'
import { generateColorMap, getAssetColor } from '../utils/utils'
import { useCompressedAirContext } from './CompressedAirContext'
import {
  useGetCompressorsPowerMeasurementsLazyQuery,
  useGetEnergyEfficienyInsightsLazyQuery,
  useGetFlowMeasurementsLazyQuery,
} from '../../Shared/graphql/codegen'

interface EfficiencyContextProps {
  efficiencySeries: ISeriesCoords[]
  efficiencyPowerSeries: ISeriesCoords[]
  mainPipesFlowSeries: ISeriesCoords[]
  isEfficiencyLoading: boolean
}

const [useEfficiencyContext, EfficiencyContextReactProvider] = createCtx<EfficiencyContextProps>()

export function EfficiencyContextProvider({ children }: { children: React.ReactNode }) {
  const { allAssets, fromToValues, currentSystem, currentSystemIndex, handleCurrentSystem, handleCurrentSystemIndex } =
    useCompressedAirContext()
  const [efficiencySeries, setEfficiencySeries] = useState<ISeriesCoords[]>([])
  const [isEfficiencyLoading, setIsEfficiencyLoading] = useState(false)

  const systemColors = generateColorMap(
    allAssets.reduce<string[]>((acc, asset) => {
      if (asset.compressedAirFlowSystem?.id) {
        acc.push(asset.compressedAirFlowSystem.id)
      }
      return acc
    }, [])
  )

  const [
    runFetchCompressorsMeasurementsQuery,
    { loading: fetchCompressorsMeasurementsLoading, data: compressorsMeasurements },
  ] = useGetCompressorsPowerMeasurementsLazyQuery({
    onCompleted: () => {
      if (!mainPipes.length) {
        setIsEfficiencyLoading(false)
      }
    },
    onError: () => {
      if (!mainPipes.length) {
        setIsEfficiencyLoading(false)
      }
    },
  })

  const [
    runFetchMainPipesMeasurementsQuery,
    { loading: fetchMainPipesMeasurementsLoading, data: mainPipesMeasurements },
  ] = useGetFlowMeasurementsLazyQuery({
    onCompleted: () => {
      setIsEfficiencyLoading(false)
    },
    onError: () => {
      setIsEfficiencyLoading(false)
    },
  })

  const isLastSystem = currentSystemIndex === allAssets.length - 1

  const mapDataToEfficiencySeries = () => {
    if (efficiencyData?.compressedAirFlowSystemInsights?.timeWindow?.energyEfficiency && currentSystem) {
      const result = currentSystemIndex === 0 ? [] : [...efficiencySeries]
      setEfficiencySeries([
        ...result,
        {
          id: currentSystem.id,
          name: currentSystem.name,
          color: systemColors[currentSystem.id],
          data:
            efficiencyData?.compressedAirFlowSystemInsights?.timeWindow?.energyEfficiency.map(item => ({
              x: item.time,
              y: item.efficiency as number,
            })) || [],
        },
      ])
    }
  }

  const [runFetchEfficiencyQuery, { data: efficiencyData }] = useGetEnergyEfficienyInsightsLazyQuery({
    onCompleted: () => {
      mapDataToEfficiencySeries()
      handleOnResponse()
    },
    onError: () => {
      handleOnResponse()
    },
  })

  const compressors = useMemo(
    () => allAssets.flatMap(asset => asset.compressedAirFlowSystem?.compressedAirSystemLinkedAssets),
    [allAssets]
  )
  const mainPipes = useMemo(
    () =>
      allAssets.reduce<IExtendedAirSensorLocation[]>((acc, asset) => {
        if (asset.compressedAirFlowSystem?.id && asset.compressedAirFlowSystem?.compressedAirSystemLinkedAssets) {
          return acc.concat(asset.sensorLocations.filter(location => location.isSelectedForEnergyEfficiency))
        }
        return acc
      }, []),
    [allAssets]
  )

  const efficiencyPowerSeries = useMemo<ISeriesCoords[]>(() => {
    return (
      compressorsMeasurements?.myOrg?.assets.map((asset, index) => ({
        id: asset.id,
        name: asset.name,
        color: getAssetColor(index),
        type: 'line',
        data: asset.measurements.map(measurement => ({
          x: new Date(measurement.time).toISOString() as ISODateTime,
          y: measurement.value / 1000,
        })),
      })) || []
    )
  }, [compressorsMeasurements])

  const mainPipesFlowSeries = useMemo(() => {
    const result: {
      [key: string]: ISeriesCoords
    } = {}
    mainPipesMeasurements?.sensorLocations.forEach((location, index) => {
      const assetId = location.asset.compressedAirFlowSystem
        ? location.asset.compressedAirFlowSystem.id
        : location.asset.id
      if (result[assetId]) {
        result[assetId] = {
          ...result[assetId],
          data: result[assetId].data.map((item, index) => ({
            x: item.x,
            y:
              isNull(item.y) && isNull(location.insights?.timeWindow.flowSeries[index]?.value)
                ? null
                : (item.y || 0) + (location.insights?.timeWindow.flowSeries[index]?.value || 0),
          })),
        }
      } else {
        const assetName = location.asset.name
        result[assetId] = {
          id: assetId,
          name: assetName,
          color: systemColors[assetId],
          data:
            location.insights?.timeWindow.flowSeries.map(item => ({
              x: item.time,
              y: item.value,
            })) || [],
        }
      }
    })
    return Object.values(result)
  }, [mainPipesMeasurements])

  useEffect(() => {
    const systemId = currentSystem?.id as ID
    const fromValue = fromToValues.startDate ? toLocalDateTime(fromToValues.startDate) : null
    const toValue = fromToValues.endDate ? toLocalDateTime(fromToValues.endDate) : null
    if (systemId && fromValue && toValue) {
      setIsEfficiencyLoading(true)
      runFetchEfficiencyQuery({
        variables: {
          systemId: systemId,
          from: fromValue,
          to: toValue,
        },
      })
    }
  }, [currentSystem, fromToValues, currentSystemIndex, allAssets])

  useEffect(() => {
    if (!isLastSystem || fetchCompressorsMeasurementsLoading || fetchMainPipesMeasurementsLoading) {
      setIsEfficiencyLoading(true)
    }
  }, [currentSystemIndex, fromToValues, fetchCompressorsMeasurementsLoading, fetchMainPipesMeasurementsLoading])

  function handleOnResponse() {
    const nextItem = allAssets[currentSystemIndex + 1]
    if (nextItem) {
      handleCurrentSystem({
        id: nextItem.compressedAirFlowSystem?.id as ID,
        name: nextItem.name,
      })
      handleCurrentSystemIndex(currentSystemIndex + 1)
    } else {
      if (compressors && fromToValues.startDate && fromToValues.endDate) {
        const assetIds = compressors.reduce((acc: string[], compressor) => {
          if (compressor?.linkedAssetId) {
            acc.push(compressor.linkedAssetId)
          }
          return acc
        }, [] as string[])
        runFetchCompressorsMeasurementsQuery({
          variables: {
            assetIds,
            from: toLocalDateTime(fromToValues.startDate),
            to: toLocalDateTime(fromToValues.endDate),
          },
        })
      }
      if (mainPipes && fromToValues.startDate && fromToValues.endDate) {
        if (!mainPipes.length) {
          setIsEfficiencyLoading(false)
        }
        runFetchMainPipesMeasurementsQuery({
          variables: {
            ids: mainPipes.map(pipe => pipe.id),
            from: toLocalDateTime(fromToValues.startDate),
            to: toLocalDateTime(fromToValues.endDate),
          },
        })
      }
    }
  }

  return (
    <EfficiencyContextReactProvider
      value={{
        efficiencySeries,
        efficiencyPowerSeries,
        isEfficiencyLoading,
        mainPipesFlowSeries,
      }}
    >
      {children}
    </EfficiencyContextReactProvider>
  )
}

export { useEfficiencyContext }
