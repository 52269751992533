import React, { FC } from 'react'
import { FormControlLabel, Radio, useRadioGroup, useTheme } from '@mui/material'

type StopReasonOptionProps = {
  id: string
  text: string
}

const StopReasonOption: FC<StopReasonOptionProps> = ({ id, text }) => {
  const theme = useTheme()
  const radioGroup = useRadioGroup()

  const isChecked = radioGroup?.value === id

  return (
    <FormControlLabel
      sx={{
        display: 'flex',
        margin: 0,
        backgroundColor: isChecked ? theme.palette.SFIBrand[900] : theme.palette.SFIBrand[100],
        padding: '0.75rem 1rem 0.75rem 0.5rem',
        borderRadius: '6px',
        boxShadow:
          '0 4px 6px rgba(71, 84, 103, 0.14), 0 0 0 1px #9CC5FF, 0 0 0 3px #F4F6F8, inset 0 9px 14px -5px rgba(255, 255, 255, 0.3)',
        '& .MuiTypography-root': {
          fontSize: '1rem',
          fontWeight: 600,
          color: isChecked ? theme.palette.SFIGreyLight[100] : theme.palette.text.primary,
        },
      }}
      value={id}
      label={text}
      control={
        <Radio
          size="small"
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: '1rem',
              color: isChecked ? theme.palette.SFIGreyLight[100] : theme.palette.SFIBrand[900],
            },
            '& .MuiFormControlLabel-label': { fontSize: '1rem', fontWeight: 600 },
          }}
        />
      }
    />
  )
}

export default StopReasonOption
