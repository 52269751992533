import { DateTime } from 'luxon'

import {
  Asset,
  GetOeeMachineCriticalStopTimeQuery,
  GetOeeProductionStatisticsQuery,
  Measurement,
  OeeAssetFragment,
  OeeProductionStop,
  ProductionShiftComparison,
} from '../../Shared/graphql/codegen'
import { AssetStatus } from '../../Shared/components/AssetCard/utils/types'
import {
  AssetStop,
  AssetStopsCategorization,
  Group,
  Machine,
  MachineWithCriticalStopTime,
  OeeAsset,
  ProductionAssetStatus,
  StopReasonSummary,
} from '../types/Assets.types'
import { DataIntervalMode } from '../types/Time.types'
import { ID, LocalDateTime } from '../../Shared/types/types'
import { OEEUnits } from '../types/Units.types'
import { OeeMetricsResponse, TransformedOeeMetrics } from '../types/OeeMetrics.type'
import { PRODUCTION_STATISTICS_DATE_FORMAT, SHIFT_EXTENDED_INTERVAL_FORMAT } from './constants'
import { ProductionStatisticsRow } from '../types/ProductionStatistics.types'
import { convertFromSpeedCyclesPerSecond, round } from './unitConversions'
import { getAssetColor } from '../../Shared/utils'
import { getProductionSpeedSeriesData, minutesToHumanReadable, secondsToHumanReadable } from './helpers'

export const transformAssetsData = (assets: OeeAssetFragment[]) => {
  if (!assets) return []

  const groups: { [groupId: string]: Group } = {}

  assets.forEach(({ name, id, parent, payload, enoceanPulseCounterSensorLocations }, index) => {
    if (parent) {
      const { id: groupId } = parent

      if (!(groupId in groups)) {
        groups[groupId] = {
          name: parent.name,
          id: groupId,
          machines: [],
        }
      }

      groups[groupId].machines.push({
        name,
        id,
        payload,
        enoceanPulseCounterSensorLocations,
        assetColor: getAssetColor(index),
      })
    }
  })

  return Object.values(groups)
}

export const transformAssetProductionSpeedData = (
  assets: Asset[],
  assetColors: Record<string, string>,
  units: OEEUnits
) => {
  if (!assets) return []

  const speedUnits = units.speed

  return assets.map(asset => {
    return {
      name: asset.name,
      color: assetColors[asset.id],
      data: asset.production.map(production =>
        getProductionSpeedSeriesData(
          production,
          speedUnits,
          asset.enoceanPulseCounterSensorLocations?.[0].criticalStopTimeSeconds
        )
      ),
    }
  })
}

export const transformMachineViewProductionSpeedData = (
  selectedMachineObject: Machine | undefined,
  productionSpeedData: Record<DataIntervalMode, Partial<Measurement>[]>,
  units: OEEUnits,
  dataIntervalMode: DataIntervalMode
) => {
  return {
    name: selectedMachineObject?.name ?? '',
    data: productionSpeedData[dataIntervalMode].map(productionSpeed => {
      return getProductionSpeedSeriesData(
        productionSpeed as Partial<Measurement>,
        units.speed,
        selectedMachineObject?.enoceanPulseCounterSensorLocations?.[0].criticalStopTimeSeconds ?? 0
      )
    }),
  }
}

export const transformProductionStatisticsData = (
  data: GetOeeProductionStatisticsQuery | undefined,
  units: OEEUnits
): ProductionStatisticsRow[] => {
  const productionStatistics = data?.productionStatistics ?? []

  return productionStatistics.map(productionStatisticItem => {
    const averageSpeedVsTotalTime = convertFromSpeedCyclesPerSecond(
      productionStatisticItem.averageSpeedCyclesPerSecond,
      units.speed
    )

    const averageSpeedVsRuntime = convertFromSpeedCyclesPerSecond(
      productionStatisticItem.averageRunningSpeedCyclesPerSecond,
      units.speed
    )

    return {
      id: productionStatisticItem.assetId,
      name: productionStatisticItem?.asset?.name ?? 'Unknown machine',
      startTime: DateTime.fromISO(productionStatisticItem.firstPulseTimestamp).toFormat(
        PRODUCTION_STATISTICS_DATE_FORMAT
      ),
      endTime: DateTime.fromISO(productionStatisticItem.lastPulseTimestamp).toFormat(PRODUCTION_STATISTICS_DATE_FORMAT),
      totalActiveTime: secondsToHumanReadable(productionStatisticItem.totalActiveTimeSeconds),
      totalDownTime: secondsToHumanReadable(productionStatisticItem.totalDowntimeSeconds),
      availabilityPercentage: `${Math.round(productionStatisticItem.availabilityPct)}%`,
      totalCycles: productionStatisticItem.totalProductionCycles,
      averageSpeedVsTotalTime: round(averageSpeedVsTotalTime, 0),
      averageSpeedVsRuntime: round(averageSpeedVsRuntime, 0),
      maxSpeed: convertFromSpeedCyclesPerSecond(productionStatisticItem.maxSpeedCyclesPerSecond, units.speed),
    }
  })
}

export const transformProductionVolumeData = (assets: Asset[], assetColors: Record<string, string>) => {
  return assets.map(asset => {
    return {
      name: asset.name,
      color: assetColors[asset.id],
      data: asset.productionVolume.map((productionVolume: Measurement) => {
        return {
          x: productionVolume?.time,
          y: Math.round(productionVolume?.value ?? 0),
        }
      }),
    }
  })
}

export const transformCriticalStopTimeData = (
  data: GetOeeMachineCriticalStopTimeQuery | undefined
): MachineWithCriticalStopTime[] => {
  const receivedAssets = data?.myOrg?.assets ?? []
  return [...receivedAssets].map(receivedAsset => ({
    id: receivedAsset.id,
    name: receivedAsset.name,
    enoceanPulseCounterSensorLocations: {
      id: receivedAsset.enoceanPulseCounterSensorLocations?.[0].id as ID,
      criticalStopTimeSeconds: receivedAsset.enoceanPulseCounterSensorLocations?.[0].criticalStopTimeSeconds,
    },
  }))
}

export const transformAssetOverviewData = (assets: ProductionAssetStatus): OeeAsset[] => {
  if (!assets) return []

  return [
    ...assets.running.map(asset => ({ ...asset, id: asset.assetId, status: AssetStatus.Success })),
    ...assets.stopped.map(asset => ({ ...asset, id: asset?.assetId, status: AssetStatus.Warning })),
    ...assets.off.map(asset => ({ ...asset, id: asset?.assetId, status: AssetStatus.Off })),
    ...assets.offline.map(asset => ({ ...asset, id: asset?.assetId, status: AssetStatus.Disabled })),
  ]
}

export const transformProductionStops = (stops: OeeProductionStop[]) => {
  return stops.reduce<AssetStopsCategorization>(
    (acc, stop) => {
      if (stop.productionStopReasonLogs.length === 0) acc.unexplainedStops.push(stop)
      else acc.explainedStops.push(stop)

      return acc
    },
    { unexplainedStops: [], explainedStops: [] }
  )
}

export const transformProductionStopsData = (
  stops: OeeProductionStop[],
  assetName: string,
  timezone: string,
  defaultStopReason: string
) => {
  return stops.map(stop => {
    const startTime = DateTime.fromISO(stop.startTime).setZone(timezone)
    const endTime = stop.endTime ? DateTime.fromISO(stop.endTime).setZone(timezone) : null

    const duration = stop.endTime
      ? minutesToHumanReadable(DateTime.fromISO(stop.endTime).diff(startTime).as('minutes'))
      : null

    return {
      id: stop.id,
      assetName: assetName,
      startTime: startTime.toFormat(PRODUCTION_STATISTICS_DATE_FORMAT),
      endTime: endTime ? endTime.toFormat(PRODUCTION_STATISTICS_DATE_FORMAT) : null,
      duration: duration,
      reason: stop.productionStopReasonLogs[0]?.customerProductionStopReason?.text ?? defaultStopReason,
      feedback: stop.productionStopReasonLogs[0]?.operatorFeedback,
    }
  })
}

export const sortProductionStopsByStartTime = (stops: AssetStop[]) => {
  return stops.sort((a, b) => {
    return new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
  })
}

export const transformReasonStatisticsData = (stops: Record<string, StopReasonSummary>) => {
  return Object.entries(stops).map(([key, value]) => ({
    id: key,
    category: value.reasonName,
    totalDuration: Math.round(value.totalDurationInMinutes),
    totalStops: value.count,
    averageDuration: Math.round(value.averageDurationInMinutes),
  }))
}

export const transformOeeMetrics = (oeeMetrics: OeeMetricsResponse): TransformedOeeMetrics => {
  return {
    availabilityPct: oeeMetrics?.availabilityPct ?? 0,
    averageCycleTimeSeconds: oeeMetrics?.averageCycleTimeSeconds ?? 0,
    averageTargetCycleTimeSeconds: oeeMetrics?.averageTargetCycleTimeSeconds ?? 0,
    oee1: oeeMetrics?.oee1 ?? 0,
    performancePct: oeeMetrics?.performancePct ?? 0,
    runtimeSeconds: oeeMetrics?.runtimeSeconds ?? 0,
    targetProductionVolume: oeeMetrics?.targetProductionVolume ?? 0,
    totalProductionCycles: oeeMetrics?.totalProductionCycles ?? 0,
    totalProductionVolume: oeeMetrics?.totalProductionVolume ?? 0,
    totalTimeSeconds: oeeMetrics?.totalTimeSeconds ?? 0,
  }
}

export const transformShiftComparisonData = (productionShiftComparison: ProductionShiftComparison) => {
  const getShiftName = (start: LocalDateTime, end: LocalDateTime) => {
    return `${DateTime.fromISO(start).toFormat(SHIFT_EXTENDED_INTERVAL_FORMAT)} - ${DateTime.fromISO(end).toFormat(
      SHIFT_EXTENDED_INTERVAL_FORMAT
    )}`
  }

  return {
    machineName: productionShiftComparison?.location?.asset?.name ?? '',
    shifts:
      productionShiftComparison?.shifts && productionShiftComparison?.shifts?.length > 0
        ? productionShiftComparison?.shifts.map(shift => {
            const shiftName = shift?.name ?? getShiftName(shift?.start as LocalDateTime, shift?.end as LocalDateTime)
            return {
              locationId: productionShiftComparison?.location?.id ?? '',
              name: shiftName,
              start: shift?.start as LocalDateTime,
              end: shift?.end as LocalDateTime,
              oee: shift?.oeeMetrics?.oee1 ? Number(shift?.oeeMetrics?.oee1) : 0,
              availability: shift?.oeeMetrics?.availabilityPct ? Number(shift?.oeeMetrics?.availabilityPct) : 0,
              performance: shift?.oeeMetrics?.performancePct ? Number(shift?.oeeMetrics?.performancePct) : 0,
              totalPieces: shift?.oeeMetrics?.totalProductionVolume
                ? Number(shift?.oeeMetrics?.totalProductionVolume)
                : 0,
            }
          })
        : [],
  }
}
