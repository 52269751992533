import { Box, Typography, styled } from '@mui/material'

const LegendsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.25),
  alignItems: 'center',
  justifyContent: 'center',
}))

const Legend = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  alignItems: 'center',
  cursor: 'pointer',
}))

const LegendTitle = styled(Typography)({
  fontSize: '16px',
})

const LegendStamp = styled(Box)({
  borderRadius: '50%',
  width: '16px',
  height: '16px',
})

export { Legend, LegendStamp, LegendTitle, LegendsWrapper }
