import { Box, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { FC } from 'react'
import { useParams } from 'react-router'

import AssetInfo from '../AssetInfoPage/AssetInfo'
import GraphsPage from '../GraphsPage/GraphsPage'
import { ActivePage } from '../../components/Layout/Layout'
import { EditThresholdAlertDialog } from '../GraphsPage/ConfigureThresholdAlertDialog/EditAlertDialog/EditAlertDialog'
import { MuteNotificationsToggle } from '../../components/MuteAlertNotifications/MuteNotificationsToggle'
import { PdmOverviewFragment } from '../../../Shared/graphql/codegen'
import { UrlParams } from '../../../Shared/types/types'
import { selectGroupMachineAndProbe } from '../GraphsPage/utils'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { usePredictiveMaintenanceContext } from '../../context/PredictiveMaintenanceContext'

type DetailsPageProps = {
  groups: PdmOverviewFragment['groups']
  activePage?: ActivePage.GRAPHS | ActivePage.DETAILS
}

const DetailsContainer: FC<DetailsPageProps> = ({ groups, activePage = ActivePage.GRAPHS }) => {
  const { i18n } = useI18nContext()
  const { thresholdsConfig, setThresholdsUpdatedAt, isAssetInfoPage } = usePredictiveMaintenanceContext()

  const { machineId, probeId } = useParams<UrlParams>()
  const { selectedGroup, selectedMachine, selectedProbe } = selectGroupMachineAndProbe(groups, machineId, probeId)
  const theme = useTheme()

  const renderContent = () => {
    switch (activePage) {
      case ActivePage.GRAPHS:
        return <GraphsPage />
      case ActivePage.DETAILS:
        return <AssetInfo />
      default:
        return null
    }
  }

  const onSubmitConfigureAlertDialog = (condigureThresholdsUpdated: boolean) => {
    if (condigureThresholdsUpdated) {
      setThresholdsUpdatedAt(DateTime.now())
    }
  }

  return (
    <>
      <Box
        margin={'-0.25rem 0 0'}
        padding="0 1rem 1rem"
        fontWeight={400}
        sx={{
          borderBottom: `2px solid ${theme.palette.SFIGreyLight[300]}`,
          display: 'flex',
          fontSize: '1.5rem',
          marginBottom: '1rem',
        }}
      >
        {selectedGroup?.name} - {selectedMachine?.name || 'Not found'} -{' '}
        <Typography
          fontWeight={400}
          variant="h5"
          display="inline"
          color="secondary"
        >
          {selectedProbe?.name || 'Not found'}
        </Typography>
        {!isAssetInfoPage && thresholdsConfig?.state === 'valid' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 'auto',
              marginTop: '0.25rem',
            }}
          >
            <MuteNotificationsToggle rule={thresholdsConfig.alertRule} />
            <EditThresholdAlertDialog
              onSubmitConfigurationAlertDialog={onSubmitConfigureAlertDialog}
              rule={thresholdsConfig.alertRule}
            />
          </Box>
        )}
      </Box>
      {renderContent()}
    </>
  )
}

export default DetailsContainer
