import { FC } from 'react'
import { Typography, styled, useTheme } from '@mui/material'

import { HelpIcon } from '../../../../Shared/components/icons'
import { IconWrapper, StyledTooltip } from '../../../../Shared/components/MUIComponents/Tooltip'

export const StyledInputFieldHeader = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.25rem',
  color: theme.palette.SFIGreyLight[700],
}))

interface TooltipProps {
  title: string
}

export const StyledTooltipWithIcon: FC<TooltipProps> = ({ title }) => {
  const theme = useTheme()
  return (
    <StyledTooltip title={title}>
      <IconWrapper>
        <HelpIcon sx={{ color: theme.palette.SFIGreyLight[400] }} />
      </IconWrapper>
    </StyledTooltip>
  )
}
