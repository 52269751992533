import { ApexOptions } from 'apexcharts'
import { useMemo } from 'react'
import { useTheme } from '@mui/material'

import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface EnergyBalancePieChartOptionsArgs {
  tooltipFormatter: (chart: any, options?: any) => string
  totalFormatter: (v: string | undefined) => string
  labels: string[]
  onDataPointMouseEnter?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
  onDataPointMouseLeave?: (e: unknown, chart: unknown, { dataPointIndex }: { dataPointIndex: number }) => void
  decimal: number
  loading: boolean
  colors: string[]
}

export const useEnergyBalancePieChartOptions = ({
  tooltipFormatter,
  totalFormatter,
  labels,
  colors,
  loading,
  decimal,
  onDataPointMouseEnter,
  onDataPointMouseLeave,
}: EnergyBalancePieChartOptionsArgs): ApexOptions => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const energyBalancePieChartOptions: ApexOptions = useMemo(
    () => ({
      labels,
      colors,
      noData: {
        text: loading ? i18n.text('app.loading') : i18n.text('analysis.energy-balance.no-data'),
      },
      chart: {
        id: 'energyBalancePie' + Math.random(),
        type: 'donut',
        width: '360',
        events: {
          dataPointMouseEnter: onDataPointMouseEnter,
          dataPointMouseLeave: onDataPointMouseLeave,
        },
      },
      legend: {
        fontFamily: theme.typography.fontFamily,
        position: 'bottom',
        horizontalAlign: 'left',
        fontSize: '14px',
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: tooltipFormatter,
        },
      },
      dataLabels: {
        style: {
          fontSize: '14px',
        },
        formatter: (v: number) => v.toFixed(decimal) + '%',
      },
      plotOptions: {
        pie: {
          expandOnClick: true,
          donut: {
            labels: {
              show: true,
              value: {
                fontSize: '18px',
                formatter: tooltipFormatter,
              },
              total: {
                color: '#000000',
                show: true,
                formatter: totalFormatter,
              },
            },
          },
        },
      },
    }),
    [tooltipFormatter, totalFormatter, labels, colors, onDataPointMouseEnter, onDataPointMouseLeave, loading, decimal]
  )
  return energyBalancePieChartOptions
}
