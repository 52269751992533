import React from 'react'
import { Balance, BarChart } from '@mui/icons-material'
import { Box, Grid, styled as muiStyled } from '@mui/material'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import AnalysisTools from '../../../Shared/components/AnalysisTools'
import AssetList from '../../../Shared/components/AssetList'
import actionCreators from './actions'
import SecondaryNav, { OptionalIconLink } from '../../../Shared/components/MUIComponents/SecondaryNav/SecondaryNav'
import { LocalAsset } from '../../../Shared/types/analysis_types'
import { WaterAnalysis } from './indexAnalysis'
import {
  filterAssetsByAnalysisType,
  getMeasurementTypesByAnalysis,
  getSubRoutes,
} from '../../../Shared/utils/analysis_utils'
import { getGraph } from './waterUtils'
import { showSettings as showSettingsCreator } from '../../../Shared/actions/appActions'

const GraphWrapper = muiStyled(Box)(({ theme }) => ({}))

export class WaterContainer extends WaterAnalysis {
  onItemDetails: $TSFixMe
  pending: $TSFixMe

  renderAssetList(assets: LocalAsset[], hasTotalConsumption: boolean, isDashboard: boolean) {
    const {
      actions: { selectTotalConsumption },
      analysis,
      apolloClient,
      customer,
      hasMultipleCustomers,
      i18n,
      type,
    } = this.props
    const selectedAssets = analysis.get('selectedAssets').toJS()
    const activeAssetTab = analysis.get('activeAssetTab')
    const isLoadingAssets = analysis.get('fetchAssetsPending') as boolean
    const selectedDateRange = analysis.get('selectedDateRange').toJS()
    const totalConsumptionSelected = analysis.get('totalConsumptionSelected', false)
    const relevantAssets = filterAssetsByAnalysisType(assets, type)

    return (
      <AssetList
        activeTab={activeAssetTab}
        assets={relevantAssets}
        hasMultipleCustomers={hasMultipleCustomers}
        hasTotalConsumption={hasTotalConsumption}
        isLoading={isLoadingAssets}
        isDashboard={isDashboard}
        onToggleFamilySelect={this.onToggleFamilySelect}
        onTabNavigate={this.onTabChange}
        onAssetSelect={this.onAssetSelect}
        onTotalConsumptionSelect={() =>
          selectTotalConsumption(apolloClient, selectedDateRange, !totalConsumptionSelected, i18n)}
        selectedAssets={selectedAssets}
        selectedCustomerName={customer.name}
        totalConsumptionSelected={totalConsumptionSelected}
      />
    )
  }

  renderGraph(isDashboard: boolean) {
    const { analysis, customer, type } = this.props
    const { showMean } = this.state
    const measurementType = getMeasurementTypesByAnalysis(type)[0]
    const measurements = analysis.getIn(['data', measurementType, 'measurements']).toJS()
    const consumption = analysis.getIn(['data', measurementType, 'consumption']).toJS()
    const altMeasurements = analysis.getIn(['data', measurementType, 'altMeasurements']).toJS()
    const altConsumption = analysis.getIn(['data', measurementType, 'altConsumption']).toJS()
    const previousPeriodEnabled = analysis.get('previousPeriodEnabled')
    const selectedAssets = analysis.get('selectedAssets').toJS()
    const selectedComponents = analysis.get('selectedComponents').toJS()
    const selectedDateRange = analysis.get('selectedDateRange').toJS()
    const fetchMeasurementsPending = analysis.get('fetchMeasurementsPending', false)
    const totalConsumptionSelected = analysis.get('totalConsumptionSelected', false)
    const yUnit = analysis.get('yUnit')
    // Capitalize so we can use it as a component
    const Graph = getGraph(type)
    if (!Graph) {
      return <GraphWrapper />
    }
    return (
      <GraphWrapper id="analysis-graph">
        <Graph
          key={`graph-${type}`}
          {...this.props}
          altMeasurements={altMeasurements}
          altTotalConsumption={altConsumption}
          customer={customer}
          error={false}
          isDashboard={isDashboard}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
          measurementType={measurementType}
          measurements={measurements}
          onComponentSelect={this.onComponentSelect}
          onTogglePreviousPeriod={this.onTogglePreviousPeriod}
          onToggleShowMean={this.onToggleShowMean}
          onTravelInTime={this.onTravelInTime}
          onYAxisChange={this.onYAxisChange}
          pending={fetchMeasurementsPending}
          previousPeriodEnabled={previousPeriodEnabled}
          selectedAssets={selectedAssets}
          selectedComponents={selectedComponents}
          selectedDateRange={selectedDateRange}
          showMean={showMean}
          totalConsumption={consumption}
          totalConsumptionSelected={totalConsumptionSelected}
          yUnit={yUnit}
          zoomIn={this.zoomIn}
          zoomOut={this.zoomOut}
          zoomOutEnabled
        />
      </GraphWrapper>
    )
  }

  render() {
    const { analysis, i18n, type } = this.props
    const { showInfo, autoRefreshActive, autoRefreshEnabled, isDashboard } = this.state
    const activeRoute =
      `/analysis/${type}` === '/analysis/energy-balance' ? '/analysis/water-balance' : `/analysis/${type}`
    const hasTotalConsumption = activeRoute === '/analysis/power'
    const assets = analysis.get('assets').toJS()
    const assetCount = analysis.get('assetCount').toJS()
    const selectedDateRange = analysis.get('selectedDateRange').toJS()
    const currentPage = type
    const subroutes = getSubRoutes(i18n, assetCount)

    if (!subroutes.find(r => r.to === activeRoute)) {
      return <Redirect to="/water/analysis/volume" />
    }

    const waterPropositionLinks: OptionalIconLink[] = [
      {
        name: 'Volume',
        i18n: i18n.text('router.sub-routes.volume'),
        icon: <BarChart />,
        path: 'volume',
      },
      {
        name: 'Water Balance',
        i18n: i18n.text('router.sub-routes.water-balance'),
        icon: <Balance />,
        path: 'water-balance',
      },
    ]

    return (
      <Grid
        container
        sx={{
          height: '100%',
          alignItems: 'stretch',
        }}
      >
        <Grid
          item
          sx={{ height: '100%' }}
        >
          {this.renderAssetList(assets, hasTotalConsumption, isDashboard)}
        </Grid>
        <Grid
          item
          padding={2}
          sx={{ flex: 1, height: '100%', overflowX: 'auto' }}
        >
          <SecondaryNav links={waterPropositionLinks} />
          <AnalysisTools
            onDatesChange={this.onDateRangeChange}
            startDate={selectedDateRange.startDate}
            endDate={selectedDateRange.endDate}
            i18n={i18n}
            isDashboard={isDashboard}
            autoRefresh={{
              enabled: autoRefreshEnabled,
              active: autoRefreshActive,
              onToggle: this.onToggleAutoRefresh,
            }}
            onToggleDashboard={this.onToggleDashboard}
            analysis={analysis}
          />

          {this.renderGraph(isDashboard)}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = ({ app, water }: $TSFixMe) => ({
  app,
  analysis: water,
})
const mapDispatchToProps = (dispatch: $TSFixMeDispatch) => ({
  actions: bindActionCreators({ ...actionCreators, showSettings: showSettingsCreator }, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(WaterContainer)
