import { Box, Fab, Typography, styled } from '@mui/material'
import { useState } from 'react'

import useAnalytics from '../../../../Shared/hooks/useAnalytics/useAnalytics'
import { ChevronDownIcon, ChevronUpIcon } from '../../../../Shared/components/icons'
import { ContactUs } from './SupportComponents/ContactUs'
import { KnowledgeBaseLink } from './SupportComponents/KnowledgeBaseLink'
import { SupportVideo } from './SupportComponents/SupportVideo'
import { trackOpenKnowledgeBaseEvent } from './trackingEvents'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const StyledFab = styled(Fab)(({ theme }) => ({
  width: '26rem',
  height: 'fit-content',
  justifyContent: 'space-between',
  textTransform: 'none',
  position: 'absolute',
  bottom: '1rem',
  right: '3rem',
  color: theme.palette.SFIGreyLight[900],
  background: theme.palette.SFIBase.white,
  '&:hover': {
    background: theme.palette.SFIGreyLight[50],
  },
  borderRadius: '0.75rem',
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  boxShadow: `0px 12px 16px -4px ${theme.palette.SFIGreyLight[900]}14, 0px 4px 6px -2px ${theme.palette.SFIGreyLight[900]}08`,
  padding: '1.5rem',
}))

const ContentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}))

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '23rem',
  justifyContent: 'space-between',
  gap: '1rem',
}))

export const SupportSection = () => {
  const { i18n } = useI18nContext()
  const knowledgeBaseUrl = i18n.text('paths.knowledge-base.reports')
  const explanationVideoUrl = i18n.text('paths.video.tracking-report')
  const [extended, setExtended] = useState(false)
  const { sendEvent } = useAnalytics()
  const handleSupportClick = () => setExtended(!extended)
  const handleKnowledgeBaseClick = () => trackOpenKnowledgeBaseEvent(sendEvent)

  const renderChevronIcon = extended ? (
    <ChevronUpIcon
      sx={{
        color: theme => theme.palette.SFIGreyLight[400],
      }}
    />
  ) : (
    <ChevronDownIcon
      sx={{
        color: theme => theme.palette.SFIGreyLight[400],
      }}
    />
  )

  return (
    <StyledFab
      variant="extended"
      onClick={handleSupportClick}
      disableFocusRipple
      disableRipple
    >
      <ContentBox>
        <HeaderBox>
          <Typography sx={{ fontSize: '1.125rem', fontWeight: '600' }}>
            {i18n.text(`reports.tracking.support.button`)}
          </Typography>
          {renderChevronIcon}
        </HeaderBox>
        {extended && (
          <>
            <SupportVideo explanationVideoUrl={explanationVideoUrl} />
            <KnowledgeBaseLink
              knowledgeBaseUrl={knowledgeBaseUrl}
              handleKnowledgeBaseClick={handleKnowledgeBaseClick}
            />
            <ContactUs />
          </>
        )}
      </ContentBox>
    </StyledFab>
  )
}
