import React, { useEffect, useMemo, useState } from 'react'
import { ApolloError, ApolloQueryResult } from '@apollo/client'
import { DateTime } from 'luxon'
import { NumberParam, useQueryParams, withDefault } from 'use-query-params'
import { useCallback } from 'react'
import { useLocation } from 'react-router'

import { AlertRuleParseResult, parseThresholdsAlert } from '../containers/GraphsPage/utils'
import { DEFAULT_LOOKBACK_DURATION_MINUTES } from '../utils/constants'
import {
  GraphDataQuery,
  PdmOverviewFragment,
  PdmOverviewQuery,
  useGraphDataLazyQuery,
  usePdmOverviewQuery,
} from '../../Shared/graphql/codegen'
import { LocalDateTime } from '../../Shared/types/types'
import { createCtx } from '../../Shared/utils'
import { createReferenceFrameLocalStorageKey } from '../utils/createLocalStorageKey'
import { isValidInterval } from '../../CompressedAir/utils/utils'
import { useCurrentUser } from '../../Shared/contexts/CurrentUserContext'

interface PredictiveMaintenanceContextProps {
  setQuery: (params: { from?: number; to?: number; zoomXMin: number; zoomXMax: number }) => void
  handleDateChange: (changed: { from: Date; to: Date }) => void
  intervalError: boolean
  from: number
  to: number
  zoomMin: number
  zoomMax: number
  thresholds: null | number[]
  thresholdsUpdatedAt: DateTime
  setThresholdsUpdatedAt: (thresholdsUpdatedAt: DateTime) => void
  getGraphData: (params: {
    variables: { probeId: string; from: LocalDateTime; to: LocalDateTime; resolution: any }
  }) => void
  data: GraphDataQuery | undefined
  dataLoading: boolean
  isDetails: boolean
  isAssetInfoPage: boolean
  referenceFrameLocalStorageKey: string
  dataError: ApolloError | undefined
  thresholdsConfig: AlertRuleParseResult | null
  groups: PdmOverviewFragment['groups']
  refetchAssetOverview: (varaibles: {}) => Promise<ApolloQueryResult<PdmOverviewQuery>>
  errorAssetOverveiew: ApolloError | undefined
  loadingAssetOverview: boolean
  pastReferenceValue: number
  setPastReferenceValue: React.Dispatch<React.SetStateAction<number>>
}
const DEFAULT_FROM = DateTime.now().minus({ days: 60 }).startOf('day').toMillis()
const DEFAULT_TO = DateTime.now().endOf('day').toMillis()
const fromParam = withDefault(NumberParam, DEFAULT_FROM)
const toParam = withDefault(NumberParam, DEFAULT_TO)
const zoomMinParam = withDefault(NumberParam, DEFAULT_FROM)
const zoomMaxParam = withDefault(NumberParam, Date.now())

const [usePredictiveMaintenanceContext, PredictiveMaintenanceContextReactProvider] =
  createCtx<PredictiveMaintenanceContextProps>()

export function PredictiveMaintenanceContextProvider({ children }: { children: React.ReactNode }) {
  const [intervalError, setIntervalError] = useState(false)
  const { pathname } = useLocation()

  const probeId = pathname.split('/')[5]

  const [thresholdsUpdatedAt, setThresholdsUpdatedAt] = useState<DateTime>(DateTime.now())

  const { userId, customerId } = useCurrentUser()
  const referenceFrameLocalStorageKey = createReferenceFrameLocalStorageKey('sort_by_cookie', userId, customerId)
  const [pastReferenceValue, setPastReferenceValue] = useState(
    Number(localStorage.getItem(referenceFrameLocalStorageKey)) || DEFAULT_LOOKBACK_DURATION_MINUTES
  )
  const pastReferenceSeconds = pastReferenceValue * 60

  useEffect(() => {
    localStorage.setItem(referenceFrameLocalStorageKey, pastReferenceValue.toString())
  }, [pastReferenceValue])

  const location = useLocation()
  const isDetails = location.pathname.includes('/machines')
  // Fix for alert button disappeared. @Iskren refactor this isDetails / isAssetInfoPage check
  const isAssetInfoPage = location.pathname.includes('/details')

  const [query, setQuery] = useQueryParams({
    from: fromParam,
    to: toParam,
    zoomXMin: zoomMinParam,
    zoomXMax: zoomMaxParam,
  })

  const { from, to, zoomXMin: zoomMin, zoomXMax: zoomMax } = query

  const handleDateChange = useCallback(
    (changed: { from: Date; to: Date }) => {
      if (isValidInterval(changed.from, changed.to)) {
        setIntervalError(false)
        setQuery({
          from: changed.from.valueOf(),
          to: changed.to.valueOf(),
        })
      } else {
        setIntervalError(true)
      }
    },
    [setQuery]
  )
  const {
    data: groupsData,
    refetch: refetchAssetOverview,
    error: errorAssetOverveiew,
    loading: loadingAssetOverview,
  } = usePdmOverviewQuery({
    variables: {
      lookbackSeconds: pastReferenceSeconds,
    },
  })
  const groups = (groupsData?.myOrg?.groups || []).filter(group => group.machines && group.machines.length)

  const [getGraphData, { data, loading: dataLoading, error: dataError }] = useGraphDataLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const thresholdsConfig = useMemo(() => {
    if (!isDetails) {
      return null
    }
    return parseThresholdsAlert(probeId, data?.myOrg?.thresholdAlertRules)
  }, [probeId, data, isDetails])

  const thresholds = thresholdsConfig?.state === 'valid' ? thresholdsConfig.alertRule.thresholds : null

  return (
    <PredictiveMaintenanceContextReactProvider
      value={{
        setQuery,
        handleDateChange,
        from,
        to,
        zoomMin,
        zoomMax,
        intervalError,
        thresholds,
        thresholdsConfig,
        data,
        dataLoading,
        dataError,
        thresholdsUpdatedAt,
        setThresholdsUpdatedAt,
        getGraphData,
        referenceFrameLocalStorageKey,
        isDetails,
        isAssetInfoPage,
        groups,
        refetchAssetOverview,
        errorAssetOverveiew,
        loadingAssetOverview,
        pastReferenceValue,
        setPastReferenceValue,
      }}
    >
      {children}
    </PredictiveMaintenanceContextReactProvider>
  )
}

export { usePredictiveMaintenanceContext }
