import React, { FC, useState } from 'react'
import { Box, MenuItem, SelectChangeEvent, useTheme } from '@mui/material'

import { ArrowDownIcon } from '../../../../../Shared/components/icons'
import {
  AuthContextQueryDocument,
  Oee_Production_Speed_Unit_Enum,
  ProductionSpeedUnitInput,
  useSetCustomerSpeedUnitMutation,
} from '../../../../../Shared/graphql/codegen'
import { ID } from '../../../../../Shared/types/types'
import { StyledSelect } from '../../../../../Shared/components/MUIComponents/update/Selectv1/StyledSelect'
import { useCurrentUser } from '../../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../../Shared/contexts/ToastContext'

const PreferredSpeedUnit: FC = () => {
  const [isSelectDisabled, setIsSelectDisabled] = useState(false)

  const theme = useTheme()
  const { i18n } = useI18nContext()

  const { showToast } = useToastContext()
  const { selectedCustomer } = useCurrentUser()

  const speedUnit =
    selectedCustomer?.oeePreferences?.productionSpeedUnit ?? Oee_Production_Speed_Unit_Enum.CyclesPerHour

  const [updateCustomerSpeedUnit] = useSetCustomerSpeedUnitMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: AuthContextQueryDocument }],
    onCompleted: () => {
      setIsSelectDisabled(false)
      showToast(i18n.text('oee.speed-unit.update.success'), 'success', 'Success!')
    },
    onError: error => {
      setIsSelectDisabled(false)
      showToast(error.message, 'error', 'Error')
    },
  })

  const savePreferredSpeedUnit: (unit: ProductionSpeedUnitInput) => Promise<void> = async unit => {
    await updateCustomerSpeedUnit({
      variables: {
        customer: selectedCustomer.id as ID,
        productionSpeedUnit: unit,
      },
    })
  }

  const ProductionSpeedUnit = {
    [Oee_Production_Speed_Unit_Enum.CyclesPerHour]: 'oee.production-speed.units.cycles-per-hour',
    [Oee_Production_Speed_Unit_Enum.CyclesPerMinute]: 'oee.production-speed.units.cycles-per-minute',
    [Oee_Production_Speed_Unit_Enum.CycleTimeSeconds]: 'oee.production-speed.units.cycle-time',
  }

  const handleSpeedUnitChange = (event: SelectChangeEvent) => {
    setIsSelectDisabled(true)
    savePreferredSpeedUnit(event.target.value as ProductionSpeedUnitInput)
  }

  const speedUnits = Object.keys(Oee_Production_Speed_Unit_Enum) as Array<keyof typeof Oee_Production_Speed_Unit_Enum>

  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <StyledSelect
        labelId="speed-unit-select-label"
        id="speed-unit-select"
        value={speedUnit}
        size="small"
        onChange={handleSpeedUnitChange}
        disabled={isSelectDisabled}
        IconComponent={() => (
          <ArrowDownIcon
            sx={{
              display: 'block',
              color: theme.palette.SFIGreyLight[500],
              width: '1rem',
              height: '1rem',
              position: 'absolute',
              right: '0.88rem',
            }}
          />
        )}
        sx={{ width: '15rem' }}
        MenuProps={{
          sx: {
            '.MuiMenu-paper': {
              border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
              boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
            },
          },
        }}
      >
        {speedUnits.map(key => (
          <MenuItem
            key={key}
            value={Oee_Production_Speed_Unit_Enum[key]}
          >
            {i18n.text(ProductionSpeedUnit[Oee_Production_Speed_Unit_Enum[key]])}
          </MenuItem>
        ))}
      </StyledSelect>
    </Box>
  )
}

export default PreferredSpeedUnit
