import { Box } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { PlusIcon } from '../../../../Shared/components/icons'
import { RecipientDataProps, RecipientListProps } from './types'
import { RecipientRow } from './RecipientRow'
import { StyledTextButton } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

export const RecipientsList: FC<RecipientListProps> = ({ recipients, onChange, onDelete, onAdd }) => {
  const [recipientsItems, setRecipientsItems] = useState(recipients)
  const { i18n } = useI18nContext()
  useEffect(() => {
    setRecipientsItems(recipients)
  }, [recipients])

  const _onChange = (index: number, recipient: RecipientDataProps) => {
    const updatedRecipients = [...recipients]
    updatedRecipients.splice(index, 1, recipient)
    onChange(updatedRecipients)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
      {recipientsItems &&
        recipientsItems.map((recipient, index) => (
          <RecipientRow
            key={index}
            currentName={recipient.name}
            currentEmailAddress={recipient.destination}
            currentEnabled={recipient.enabled}
            currentId={recipient.id}
            handlerecipientchange={_onChange.bind(null, index)}
            onDelete={onDelete}
            index={index}
          ></RecipientRow>
        ))}

      <StyledTextButton
        onClick={onAdd}
        sx={{ height: 'fit-content', width: 'fit-content' }}
        startIcon={
          <PlusIcon
            sx={{
              width: '1.25rem',
              height: '1.25rem',
            }}
          />
        }
      >
        {i18n.text('reports.tracking.configuration.reports.recipientsAdd')}
      </StyledTextButton>
    </Box>
  )
}
