import React from 'react'
import { FC } from 'react'
import { Interval } from 'luxon'

import { CsvDataMapper, CsvHeaderMapper, CsvHeaders, ElectricityCsvExporter } from './ElectricityCsvExporter'
import { SummedEnergyBalanceSeries } from '../types'

interface EnergyBalanceCsvExporterProps {
  data: SummedEnergyBalanceSeries
  interval: Interval
  filename?: string
  unit?: string
  showDialog?: boolean
}

const EnergyBalanceCsvExporter: FC<EnergyBalanceCsvExporterProps> = ({
  data,
  interval,
  unit: unitProp,
  filename,
  showDialog = false,
}) => {
  const unit = unitProp || 'Wh'
  const headers: CsvHeaders = ['from', 'to', 'assetName', 'value', 'unit']
  const formattedStartDate = interval.start.toISO({ includeOffset: false })
  const formattedEndDate = interval.end.toISO({ includeOffset: false })

  const mapHeaders: CsvHeaderMapper = (headers, { valueSeparator }) => {
    return headers.join(valueSeparator)
  }

  const mapDataToHeaders: CsvDataMapper<SummedEnergyBalanceSeries> = (data, { valueSeparator, decimalSeparator }) => {
    const result = []
    for (const series of data) {
      const value = series.total || 0
      const formattedValue = value.toString().replace('.', decimalSeparator)
      const name = series.name
      const row = `${formattedStartDate}${valueSeparator}${formattedEndDate}${valueSeparator}${name}${valueSeparator}${formattedValue}${valueSeparator}${unit}`
      result.push(row)
    }
    return result.join('\n')
  }

  return (
    <ElectricityCsvExporter
      headers={headers}
      mapHeaders={mapHeaders}
      data={data}
      mapDataToHeaders={mapDataToHeaders}
      interval={interval}
      filename={filename}
      forceShowDialog={showDialog}
    />
  )
}

export { EnergyBalanceCsvExporter }
