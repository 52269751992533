import { FC } from 'react'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material'

import {
  SFIBase,
  SFIBlue,
  SFIBlueDark,
  SFIBlueLight,
  SFIBrand,
  SFICyan,
  SFIError,
  SFIFuchsia,
  SFIGradient,
  SFIGreen,
  SFIGreenLight,
  SFIGreyBlue,
  SFIGreyCool,
  SFIGreyDark,
  SFIGreyIron,
  SFIGreyLight,
  SFIGreyModern,
  SFIGreyNeutral,
  SFIGreyTrue,
  SFIGreyWarm,
  SFIIndigo,
  SFIMoss,
  SFIOrange,
  SFIOrangeDark,
  SFIPink,
  SFIPurple,
  SFIRose,
  SFISuccess,
  SFITeal,
  SFIViolet,
  SFIWarning,
  SFIYellow,
} from './SFIndigoColors'

export const STANDBY_COLOR = '#F46036'
export const OOP_COLOR = '#FFD700'
export const FONT_FAMILY = ['Inter', 'Roboto', 'Helvetica', 'sans-serif'].join(',')

export const SFIndigoMuiTheme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiContainer: {
        defaultProps: {
          maxWidth: false,
        },
      },
    },
    shape: {
      borderRadius: 6,
    },
    typography: {
      fontFamily: FONT_FAMILY,
    },
    palette: {
      primary: {
        main: SFIGreyLight[800],
      },
      secondary: {
        main: SFIBrand[700],
      },
      success: {
        light: SFISuccess[50],
        main: SFISuccess[500],
        contrastText: SFISuccess[600],
        dark: SFISuccess[700],
      },
      error: {
        light: SFIError[400],
        main: SFIError[500],
        contrastText: SFIError[600],
        dark: SFIError[700],
      },
      warning: {
        light: SFIWarning[50],
        main: SFIWarning[500],
        contrastText: SFIWarning[600],
        dark: SFIWarning[700],
      },
      SFIBase,
      SFIBlue,
      SFIBlueDark,
      SFIBlueLight,
      SFIBrand,
      SFICyan,
      SFIError,
      SFIFuchsia,
      SFIGreen,
      SFIGreenLight,
      SFIGreyBlue,
      SFIGreyCool,
      SFIGreyDark,
      SFIGreyIron,
      SFIGreyLight,
      SFIGreyModern,
      SFIGreyNeutral,
      SFIGreyTrue,
      SFIGreyWarm,
      SFIIndigo,
      SFIMoss,
      SFIOrange,
      SFIOrangeDark,
      SFIPink,
      SFIPurple,
      SFIRose,
      SFISuccess,
      SFITeal,
      SFIViolet,
      SFIYellow,
      SFIWarning,
      SFIGradient,
    },
  }),
  {
    breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    factor: 5,
  }
)
const MuiThemeProvider: FC = ({ children }) => {
  return <ThemeProvider theme={SFIndigoMuiTheme}>{children}</ThemeProvider>
}

export default MuiThemeProvider
