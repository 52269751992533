import React, { FC } from 'react'
import { Box, OutlinedTextFieldProps, outlinedInputClasses, styled } from '@mui/material'

import { StyledTextField } from './StyledTextField'

interface IStyledUnitTextField extends OutlinedTextFieldProps {
  unitName: string
  maxWidth?: string
}

export const StyledUnitTextField: FC<IStyledUnitTextField> = ({ unitName, maxWidth, ...props }) => {
  const getUnitBoxWidth = () => {
    const unitSymbol = unitName.split(' / ')[0]
    return unitSymbol.length > 1 ? '7rem' : '5.5rem'
  }

  return (
    <StyledUnitTextFieldWrapper sx={{ maxWidth, gridTemplateColumns: `${getUnitBoxWidth()} 1fr` }}>
      <UnitBox
        sx={{
          width: getUnitBoxWidth(),
        }}
      >
        {unitName}
      </UnitBox>
      <StyledTextField
        {...props}
        sx={{
          maxWidth: '100%',
          input: {
            borderLeft: 'none',
            borderRadius: '0 0.5rem 0.5rem 0',
            height: '2.75rem',
            boxSizing: 'border-box',
            '&:invalid': {
              borderLeft: 'none',
              borderRadius: '0 0.5rem 0.5rem 0',
            },
          },
          [`.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
            borderRadius: '0 0.5rem 0.5rem 0',
          },
        }}
      />
    </StyledUnitTextFieldWrapper>
  )
}

const StyledUnitTextFieldWrapper = styled(Box)({
  display: 'grid',
  alignItems: 'start',
})

const UnitBox = styled(Box)(({ theme }) => ({
  padding: '0.625rem 0.75rem',
  borderRadius: '0.5rem 0rem 0rem 0.5rem',
  border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  boxShadow: '0px 1px 2px 0px #1018280d',
  maxHeight: '2.75rem',
  height: '100%',
  color: theme.palette.SFIGreyLight[600],
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
}))
