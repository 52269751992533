import React, { FC } from 'react'
import { Box } from '@mui/material'

import { OutlinedStyledButton } from '../../../../Shared/components/MUIComponents/update/OutlinedStyledButton'
import { UploadIcon } from '../../../../Shared/components/icons/UploadIcon'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

interface IUploadButtonProps {
  itemId?: string
  openUploadImageModal: (id: string) => void
}

export const UploadButton: FC<IUploadButtonProps> = ({ itemId, openUploadImageModal }) => {
  const { i18n } = useI18nContext()
  return (
    <Box sx={{ margin: '0 auto', width: 'fit-content' }}>
      <OutlinedStyledButton
        disableRipple
        sx={{
          flexDirection: 'row',
          gap: '0.25rem',
          color: theme => theme.palette.SFIGreyLight[700],
          fontSize: '0.875rem',
          fontWeight: 600,
          textWrap: 'nowrap',
        }}
        onClick={() => {
          if (itemId) openUploadImageModal(itemId)
        }}
      >
        <UploadIcon
          sx={{
            height: '1.25rem',
            width: '1.25rem',
          }}
        />
        {i18n.text('compressed-air.asset-information.upload-image')}
      </OutlinedStyledButton>
    </Box>
  )
}
