import { FC } from 'react'
import { useHistory, useLocation } from 'react-router'

import { AssetTreeChildNode } from '../../../Shared/components/MUIComponents/update/AssetTree/StyledAssetTreeChildNode'
import { AssetTreeParentNode } from '../../../Shared/components/MUIComponents/update/AssetTree/StyledAssetTreeParentNode'
import { TemperatureAssetFragment } from '../../../Shared/graphql/codegen'
import { useTemperatureContext } from '../../context/TemperatureContext'

type AssetTreeGroupProps = {
  asset: TemperatureAssetFragment
}

const AssetTreeGroup: FC<AssetTreeGroupProps> = ({ asset }) => {
  const location = useLocation()
  const history = useHistory()
  const { selectedLocationId } = useTemperatureContext()

  const { temperatureSensorLocations: sensorLocations } = asset
  const isSelected =
    asset.temperatureSensorLocations.some(location => selectedLocationId === location.id) &&
    !location.pathname.includes('overview')

  const isSensorLocationSelected = (id: string) => selectedLocationId === id

  const handleClick = (locationId: string) => {
    history.push(`/temperature/flow/${locationId}`)
  }

  return (
    <AssetTreeParentNode
      parent={asset}
      isSelected={isSelected}
    >
      {sensorLocations.map(location => (
        <AssetTreeChildNode
          key={location.id}
          item={location}
          isSelected={isSensorLocationSelected(location.id)}
          handleClick={() => handleClick(location.id)}
        />
      ))}
    </AssetTreeParentNode>
  )
}

export default AssetTreeGroup
