import * as z from 'zod'
import { Vibration_Foundation_Types_Enum, Vibration_Speed_Control_Types_Enum } from '../../../Shared/graphql/codegen'

export const ComponentType = ['AC_MOTOR', 'DC_MOTOR', 'PUMP', 'BEARING', 'COMPRESSOR', 'GEARBOX', 'BLOWER_OR_FAN', 'OTHER'] as const
export type ComponentType = typeof ComponentType[number]

export const vibrationSensorLocationInfoSchema = z.object({
  id: z.string(),
  componentType: z.enum(ComponentType).nullable(),
  foundation: z.nativeEnum(Vibration_Foundation_Types_Enum).nullable(),
  speedControl: z.nativeEnum(Vibration_Speed_Control_Types_Enum).nullable(),
  machinePower: z.number().nullable(),
  rpm: z.number().nullable(),
  setSpeed: z.number().nullable(),
})
export type VibrationSensorLocationInfo = z.infer<typeof vibrationSensorLocationInfoSchema>
