import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { FC, useState } from 'react'

import { ArrowIcon } from '../../../Shared/components/icons'
import { TableBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'

type CollapsibleTableProps = {
  title: string
  isCollapsed?: boolean
}

const CollapsibleTable: FC<CollapsibleTableProps> = ({ title, isCollapsed = false, children }) => {
  const [expanded, setExpanded] = useState(!isCollapsed)

  const handleAccordionArrowClick = () => {
    setExpanded(!expanded)
  }

  return (
    <TableBoxWrapper>
      <Accordion
        expanded={expanded}
        disableGutters
        elevation={0}
        data-testid="tree-asset-accordion"
      >
        <AccordionSummary
          sx={{ padding: '0.5rem 1rem', margin: 0, alignItems: 'center' }}
          onClick={handleAccordionArrowClick}
          expandIcon={
            <ArrowIcon
              fontSize="inherit"
              sx={{ color: theme => theme.palette.SFIGreyLight[400] }}
            />
          }
        >
          <Typography
            sx={{ padding: 0, margin: 0 }}
            variant="h6"
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0, overflow: 'hidden' }}>{children}</AccordionDetails>
      </Accordion>
    </TableBoxWrapper>
  )
}

export default CollapsibleTable
