import React, { ReactNode } from 'react'
import { Box, useTheme } from '@mui/material'
import { Redirect } from 'react-router-dom'
import { Route, Switch, useRouteMatch } from 'react-router'
import { useFeature } from 'flagged'

import AssetOverview from '../AssetOverview/AssetOverview'
import Error from '../../../Shared/components/MUIComponents/Error'
import MUILoader from '../../../Shared/components/MUIComponents/Loader'
import MachineView from '../MachineView/MachineView'
import ProductionAnalysis from '../ProductionAnalysis/ProductionAnalysis'
import SecondaryNavContainer from '../SecondaryNavContainer/SecondaryNavContainer'
import ShiftComparison from '../ShiftComparison/ShiftComparison'
import SpeedAnalysis from '../SpeedAnalysis/SpeedAnalysis'
import StopAnalysis from '../StopAnalysis/StopAnalysis'
import { LinkInfo } from '../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'
import { OEE_SHIFT_COMPARISON, OEE_STOP_LOGGING_DISABLED } from '../../utils/constants'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type NavRoute = {
  key: string
  path: string
  component: ReactNode
}

const AppLayout = () => {
  const theme = useTheme()
  const { assetsError, assetsLoading, assets, selectedMachine } = useOEEContext()
  const { path } = useRouteMatch()
  const { i18n } = useI18nContext()

  const stopLoggingFeatureDisabled = !!useFeature(OEE_STOP_LOGGING_DISABLED)
  const shiftComparisonFeatureEnabled = !!useFeature(OEE_SHIFT_COMPARISON)

  if (assetsLoading) {
    return <MUILoader />
  }

  if (!assets || assets.length === 0 || assetsError) {
    return <Error />
  }

  const getNavRoutes = (): NavRoute[] => [
    {
      key: 'asset-overview',
      path: `${path}/asset-overview`,
      component: <AssetOverview />,
    },
    {
      key: 'machine',
      path: `${path}/machine/:machineId`,
      component: <MachineView />,
    },
    {
      key: 'speed-analysis',
      path: `${path}/speed-analysis`,
      component: <SpeedAnalysis />,
    },
    {
      key: 'production-analysis',
      path: `${path}/production-analysis`,
      component: <ProductionAnalysis />,
    },
    ...(!stopLoggingFeatureDisabled
      ? [
          {
            key: 'stop-analysis',
            path: `${path}/stop-analysis`,
            component: <StopAnalysis />,
          },
        ]
      : []),
    ...(shiftComparisonFeatureEnabled
      ? [
          {
            key: 'shift-comparison',
            path: `${path}/shift-comparison`,
            component: <ShiftComparison />,
          },
        ]
      : []),
  ]

  const secondaryLinks: LinkInfo[] = [
    {
      name: 'Asset Overview',
      i18n: i18n.text('Asset Overview'),
      path: `${path}/asset-overview`,
    },
    {
      name: 'Machine View',
      i18n: i18n.text('oee.secondary-nav.machine-view'),
      path: `${path}/machine`,
    },
    {
      name: 'Speed Analysis',
      i18n: i18n.text('oee.secondary-nav.speed-analysis'),
      path: `${path}/speed-analysis`,
    },
    {
      name: 'Production Analysis',
      i18n: i18n.text('oee.secondary-nav.production-analysis'),
      path: `${path}/production-analysis`,
    },
    ...(!stopLoggingFeatureDisabled
      ? [
          {
            name: 'Stop Analysis',
            i18n: i18n.text('oee.secondary-nav.stop-analysis'),
            path: `${path}/stop-analysis`,
          },
        ]
      : []),
    ...(shiftComparisonFeatureEnabled
      ? [
          {
            name: 'Shift Comparison',
            i18n: i18n.text('oee.shift-comparison.title'),
            path: `${path}/shift-comparison`,
          },
        ]
      : []),
  ]

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.SFIGreyLight[50],
        width: '100%',
        height: 'calc(100vh - 64px)',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflow: 'hidden',
      }}
    >
      <SecondaryNavContainer links={secondaryLinks} />
      <Switch>
        {getNavRoutes().map(navRoute => (
          <Route
            key={navRoute.key}
            exact
            path={navRoute.path}
            render={() => navRoute.component}
          />
        ))}
        <Route
          path={`${path}/machine/:machineId`}
          component={MachineView}
        />
        <Redirect
          exact
          from={`${path}/machine`}
          to={`${path}/machine/${selectedMachine}`}
        />
        <Redirect to={`${path}`} />
      </Switch>
    </Box>
  )
}

export default AppLayout
