import { isNullish, isNumber, isOfShape, isOneOf, isString } from 'type-guards'

import { BridgesInstallationAssetFragment, BridgesInstallationQuery } from '../../../../Shared/graphql/codegen'

type Customer = NonNullable<BridgesInstallationQuery['myOrg']>

export type BridgeGql = Customer['pressacBridges'][number]

export const isBridgeSensor = isOfShape({
  id: isString,
  signal: isOneOf(isNumber, isNullish),
})

export type AssetGql = BridgesInstallationAssetFragment
