import { Box, Table, TableBody, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { FC, useMemo } from 'react'

import StopReasonsStatisticsRow from './StopReasonsStatisticsRow'
import { StopReasonSummary } from '../../types/Assets.types'
import { StyledTableHeadCell } from '../StyledTable'
import { TableBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { getCategoryDataSummaryTableHead } from '../../utils/tableHeads'
import { transformReasonStatisticsData } from '../../utils/dataTransformations'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type StopReasonsStatisticsProps = {
  stops: Record<string, StopReasonSummary>
}

const StopReasonsStatistics: FC<StopReasonsStatisticsProps> = ({ stops }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { selectedStopReasons } = useOEEContext()

  const tableHead = getCategoryDataSummaryTableHead()

  const tableData = useMemo(() => {
    return transformReasonStatisticsData(stops)
  }, [stops])

  const filteredTableData = useMemo(
    () => tableData.filter(row => selectedStopReasons.includes(row.id)),
    [selectedStopReasons, tableData]
  )

  const sortedTableData = useMemo(
    () => filteredTableData.sort((a, b) => b.totalDuration - a.totalDuration),
    [filteredTableData]
  )

  return (
    <TableBoxWrapper>
      <Typography
        variant="h6"
        sx={{ padding: theme.spacing(2) }}
      >
        {i18n.text('oee.stop-analysis.categories-table.title')}
      </Typography>
      <TableContainer
        component={Box}
        sx={{ overflowX: 'auto', maxHeight: 'calc(400px - 4rem)' }}
      >
        <Table
          stickyHeader
          size="small"
          aria-label={i18n.text('oee.stop-analysis.categories-table.title')}
        >
          <TableHead>
            <TableRow>
              {tableHead.map(headCell => (
                <StyledTableHeadCell
                  key={headCell.id}
                  align={headCell.align}
                >
                  {i18n.text(headCell.label)}
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedTableData.map(row => (
              <StopReasonsStatisticsRow
                key={row.id}
                row={row}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableBoxWrapper>
  )
}

export default StopReasonsStatistics
