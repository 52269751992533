import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6667 1.83301V5.49967M7.33333 1.83301V5.49967M2.75 9.16634H19.25M4.58333 3.66634H17.4167C18.4292 3.66634 19.25 4.48715 19.25 5.49967V18.333C19.25 19.3455 18.4292 20.1663 17.4167 20.1663H4.58333C3.57081 20.1663 2.75 19.3455 2.75 18.333V5.49967C2.75 4.48715 3.57081 3.66634 4.58333 3.66634Z"
          stroke="#667085"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
