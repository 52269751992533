import React, { FC, useEffect, useRef, useState } from 'react'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Box, Popover, useTheme } from '@mui/material'
import { DateTime, Info, Interval } from 'luxon'
import { LocalizationProvider, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers'

import { BaseAnchorButtonProps, IDateTimeRangePickerProps } from './types'
import { CalendarHeader } from './CalendarHeader'
import { CustomLayout } from './CustomLayout'
import { DateTimeRangePickerAnchorButton } from './DateTimeRangePickerAnchorButton'
import { DateTimeRangePickerContextProvider, useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'
import { renderStyledDay } from './CustomDay'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

/**
 * @description - DateTimeRangePicker with customizable shortcuts and compare dates functionality.
 * @param {DateTime | null} startDate - Initial start date
 * @param {DateTime | null} endDate - Initial end date
 * @param {StartEndDatesValues} compareFromToValues - Initial compared start and end dates.
 * @param {DateFormat} dateFormat - Preferred date format visible on the DateTimeRangePickerAnchorButton.
 * @param {boolean} isCompareDatesOn - Enables working with compared start and end dates if true.
 * @param {boolean} isTimePicker - Allows users to select time along with dates if true.
 * @param {boolean} isOpen - Controls state of the timepicker pane. If passed, onToggleOpen is also required.
 * @param {PopoverOrigin | undefined} - Enables customizing the position of the timepicker pane.
 * @param {boolean} isAutomaticCompareDates - If true, compared end date is selected automatically
 * based on the main start and end dates interval
 * @param {boolean} hasPrevNextPeriodButtons - Renders left and right arrow buttons next to the anchor button if true.
 * @param {boolean} showCompareDatesButton - Renders the "Compare dates switch" if true.
 * @param {DateRangePickerShortCut[]} shortcutsItems - List of customized shorcuts
 * @param {function(selection: DateRange, zooming?: boolean): void} onDatesChange - Called when start and end dates are applied.
 * @param {function(selection: DateRange, zooming?: boolean): void} onCompareDatesChange - Called when compared start and end dates
 * are applied.
 * @param {function(isSwitchedOn: boolean): void} onCompareDatesToggle - Called when the user switches the 'Compare dates'
 * switch on or off.
 * @param {function(isOpen: boolean): void} onToggleOpen - Called when the timepicker pane state is toggled, with the new state as an argument. If passed, isOpen is also required.
 * @param {React.FC<T>} AnchorButton - Custom anchor button component.
 * @param {T} anchorButtonProps - Props for the custom anchor button component.
 * @param {boolean} disableClickingAfterCompareSelect - If true, once compare interval is selected, users cannot click on other day unless they focus on an input
 */

const MuiDateTimeRangePicker = <T extends BaseAnchorButtonProps>({
  AnchorButton,
  anchorButtonProps,
  disableClickingAfterCompareSelect = true,
  ...props
}: IDateTimeRangePickerProps<T>) => {
  return (
    <DateTimeRangePickerContextProvider {...props}>
      <DateTimePickerContent
        AnchorButton={AnchorButton}
        anchorButtonProps={anchorButtonProps}
        disableClickingAfterCompareSelect={disableClickingAfterCompareSelect}
      />
    </DateTimeRangePickerContextProvider>
  )
}

interface DateTimePickerContentProps<T extends BaseAnchorButtonProps> {
  AnchorButton?: FC<T>
  anchorButtonProps?: T
  disableClickingAfterCompareSelect: boolean
}

const DateTimePickerContent = <T extends BaseAnchorButtonProps>({
  AnchorButton,
  anchorButtonProps,
  disableClickingAfterCompareSelect
}: DateTimePickerContentProps<T>) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const {
    dateFormat,
    isCompareDatesOn,
    isTimePicker,
    isAutomaticCompareDates,
    fromDate,
    toDate,
    compareFromToValues,
    fromToValues,
    isOpen,

    settingFromDate,
    initialFromTo,
    initialCompareFromTo,
    currentDate,
    compareStatusesRef,
    customDateRangeButtonLabel,
    anchorOrigin,
    setError,
    onDatesChange,
    onCompareDatesChange,
    setFromDateStatus,
    setActiveShortcutIndex,

    setInitialValues,
    setInitialCompareValues,
    handleStartEndDates,
    handleCompareStartEndDates,
    onToggleOpen,
  } = useDateTimeRangePickerContext()

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null)
  const openButtonRef = useRef<HTMLButtonElement>(null)

  const _isOpen = typeof isOpen !== 'undefined' ? isOpen : Boolean(anchorElement)

  useEffect(() => {
    if (!_isOpen) {
      setActiveShortcutIndex(null)
      setAnchorElement(null)
      resetCompareStatuses(true)
    } else {
      setAnchorElement(openButtonRef.current)
    }
  }, [_isOpen])

  // This function is used to style date range
  const renderDay = ({ day, ...other }: PickersDayProps<DateTime>) => {
    return renderStyledDay({
      day,
      fromDate,
      toDate,
      comparedFromDate: compareFromToValues.startDate,
      comparedToDate: compareFromToValues.endDate,
      settingFromDate,
      settingCompareDates: compareStatusesRef.current,
      isCompareDatesOn,
      handleChange: isCompareDatesOn ? handleCompareChange : handleChange,
      ...other,
    })
  }

  const openDatePickerCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onToggleOpen) return onToggleOpen(true)
    return setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    if (onToggleOpen) return onToggleOpen(false)
    return setAnchorElement(null)
  }

  const handleChange = (date: DateTime | null) => {
    if (!date || !date.isValid) return null
    setError('')
    setActiveShortcutIndex(null)
    if (settingFromDate || (fromDate && date.startOf('day') < fromDate.startOf('day'))) {
      setFromDateStatus(false)
      handleStartEndDates({
        startDate: date.startOf('day'),
        endDate: null,
      })
    } else {
      setFromDateStatus(true)
      handleStartEndDates({
        startDate: fromDate,
        endDate: date.endOf('day'),
      })
      if (!isTimePicker) {
        applyDates(fromDate, date.endOf('day'))
      }
    }
  }

  const handleCompareChange = (date: DateTime | null) => {
    if (!date || !date.isValid) return null
    setError('')
    setActiveShortcutIndex(null)
    if (
      compareStatusesRef.current.from ||
      (compareStatusesRef.current.to && fromDate && date.startOf('day') < fromDate.startOf('day'))
    ) {
      compareStatusesRef.current.from = false
      handleStartEndDates({
        startDate: date.startOf('day'),
        endDate: null,
      })
    } else if (compareStatusesRef.current.to) {
      compareStatusesRef.current.to = false
      handleStartEndDates({
        startDate: fromDate,
        endDate: date.endOf('day'),
      })
    } else if (
      compareStatusesRef.current.compareFrom ||
      (!isAutomaticCompareDates &&
        compareFromToValues.startDate &&
        date.startOf('day') < compareFromToValues.startDate.startOf('day'))
    ) {
      compareStatusesRef.current.compareFrom = false
      if (handleCompareStartEndDates) {
        handleCompareStartEndDates({
          startDate: date.startOf('day'),
          endDate: null,
        })
      }
      // Set Compare 'to' date automatically
      if (isAutomaticCompareDates && !!fromDate && !!toDate) {
        compareStatusesRef.current.compareTo = false
        const durationBetweenFromAndTo = Interval.fromDateTimes(fromDate, toDate)

        const newCompareToDate = date.plus(durationBetweenFromAndTo.toDuration())
        resetCompareStatuses()
        if (handleCompareStartEndDates) {
          handleCompareStartEndDates({
            startDate: date.startOf('day'),
            endDate: newCompareToDate,
          })
        }
      }
    } else if (!isAutomaticCompareDates && compareStatusesRef.current.compareTo) {
      compareStatusesRef.current.compareTo = false
      if (handleCompareStartEndDates) {
        handleCompareStartEndDates({
          startDate: compareFromToValues.startDate,
          endDate: date.endOf('day'),
        })
      }
      resetCompareStatuses()
    } else if (compareStatusesRef.current.compareTo) {
      compareStatusesRef.current.compareTo = false
      if (handleCompareStartEndDates) {
        handleCompareStartEndDates({
          startDate: compareFromToValues.startDate,
          endDate: date.endOf('day'),
        })
      }
      resetCompareStatuses()

      // Set Compare 'from' date automatically
      if (!!fromDate && !!toDate) {
        const durationBetweenFromAndTo = Interval.fromDateTimes(fromDate, toDate)

        const newCompareFromDate = date.endOf('day').minus(durationBetweenFromAndTo.toDuration())
        if (handleCompareStartEndDates) {
          handleCompareStartEndDates({
            startDate: newCompareFromDate,
            endDate: date.endOf('day'),
          })
        }
      }
    }
  }

  const closeCalendar = () => {
    handleStartEndDates({
      startDate: initialFromTo.startDate,
      endDate: initialFromTo?.endDate,
    })
    if (isCompareDatesOn && handleCompareStartEndDates) {
      handleCompareStartEndDates({
        startDate: initialCompareFromTo?.startDate ?? null,
        endDate: initialCompareFromTo?.endDate ?? null,
      })
    }
    resetCompareStatuses()
    handleClose()
  }

  const applyDates = (from?: DateTime | null, to?: DateTime | null) => {
    if (!fromDate && !from) {
      setError(i18n.text('datetimepicker.validation.error1'))
    } else if (!toDate && !to && isCompareDatesOn) {
      setError(i18n.text('datetimepicker.validation.error2'))
    } else if ((fromDate && toDate && toDate < fromDate) || (to && from && to < from)) {
      setError(i18n.text('datetimepicker.validation.error3'))
    } else if (isCompareDatesOn && compareFromToValues.startDate && !compareFromToValues.endDate) {
      setError(i18n.text('datetimepicker.validation.error4'))
    } else if (
      isCompareDatesOn &&
      compareFromToValues.startDate &&
      compareFromToValues.endDate &&
      compareFromToValues.endDate < compareFromToValues.startDate
    ) {
      setError(i18n.text('datetimepicker.validation.error5'))
    } else if (
      (from && to && (!from.isValid || !to?.isValid)) ||
      (!from && !to && (!fromDate?.isValid || !toDate?.isValid)) ||
      (isCompareDatesOn && (!compareFromToValues.startDate?.isValid || !compareFromToValues.endDate?.isValid))
    ) {
      setError(i18n.text('datetimepicker.validation.error6'))
    } else if (
      isAutomaticCompareDates &&
      fromDate &&
      toDate &&
      isCompareDatesOn &&
      compareFromToValues.startDate &&
      compareFromToValues.endDate &&
      Interval.fromDateTimes(fromDate, toDate).toDuration().milliseconds !==
        Interval.fromDateTimes(compareFromToValues.startDate, compareFromToValues.endDate).toDuration().milliseconds
    ) {
      setError(i18n.text('datetimepicker.validation.error7'))
    } else if ((fromDate && toDate) || (fromDate && !toDate && !isCompareDatesOn) || (from && to)) {
      const isFromDateTimeType = from && DateTime.isDateTime(from)
      const newFromDate = isFromDateTimeType ? from : fromDate
      const newToDate = to || toDate || fromDate?.endOf('day') || null

      setFromDateStatus(true)
      if (newFromDate && newToDate) {
        onDatesChange({
          startDate: newFromDate,
          endDate: newToDate,
        })
        setInitialValues({ startDate: newFromDate, endDate: newToDate })
      }
      if (compareFromToValues.startDate && compareFromToValues.endDate && onCompareDatesChange) {
        onCompareDatesChange({
          startDate: compareFromToValues.startDate,
          endDate: compareFromToValues.endDate,
        })
        setInitialCompareValues(compareFromToValues)
      }
      handleClose()
    }
  }

  function resetCompareStatuses(isReset = false) {
    if (!disableClickingAfterCompareSelect || isReset) {
      compareStatusesRef.current = {
        from: true,
        to: true,
        compareFrom: true,
        compareTo: true
      }
    }
  }

  const defaultAnchorButton = DateTimeRangePickerAnchorButton as unknown as FC<T>
  const AnchorButtonToRender: any = AnchorButton || defaultAnchorButton

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box>
        <AnchorButtonToRender
          {...anchorButtonProps}
          fromDate={fromToValues.startDate}
          toDate={fromToValues.endDate}
          customDateRangeButtonLabel={customDateRangeButtonLabel}
          compareFromDate={compareFromToValues?.startDate || null}
          compareToDate={compareFromToValues?.endDate || null}
          dateFormat={dateFormat}
          applyDates={applyDates}
          ref={openButtonRef}
          onClick={openDatePickerCalendar}
        />
        <Popover
          data-testid="date-time-range-picker-popover"
          anchorEl={anchorElement}
          onClose={handleClose}
          open={_isOpen}
          anchorOrigin={anchorOrigin}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={3}
        >
          <Box
            sx={{
              maxWidth: isTimePicker ? theme.spacing(72.5) : theme.spacing(65),
              width: isTimePicker ? theme.spacing(72.5) : theme.spacing(65),
              display: 'grid',
            }}
          >
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              dayOfWeekFormatter={(_day: string, date: DateTime) => {
                const luxonDay = (date.weekday + 6) % 7
                return Info.weekdays('short')[luxonDay]
              }}
              autoFocus={false}
              openTo="day"
              value={currentDate}
              disableFuture={false}
              disableHighlightToday={false}
              views={['day']}
              slots={{
                day: renderDay,
                calendarHeader: () => <CalendarHeader />,
                layout: props => (
                  <CustomLayout
                    {...props}
                    saveDates={applyDates}
                    closeCalendar={closeCalendar}
                  />
                ),
              }}
            />
          </Box>
        </Popover>
      </Box>
    </LocalizationProvider>
  )
}

export default MuiDateTimeRangePicker
