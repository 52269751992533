import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { DateTime } from 'luxon'
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers'
import { FC, useCallback, useEffect } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import {
  AnnotationDataFragment,
  AnnotationTagTypeInput,
  AnnotationTagType_Enum,
  AnnotationTypeInput,
  AnnotationType_Enum,
  CreateAnnotationInput,
  UpdateAnnotationInput,
  useCreateAnnotationMutation,
  useDeleteAnnotationMutation,
  useUpdateAnnotationMutation,
} from '../../../../Shared/graphql/codegen'
import { StyledCheckbox as Checkbox } from '../../../../Shared/components/MUIComponents/update/Checkbox/StyledCheckBox'
import { ID, ISODateTime } from '../../../../Shared/types/types'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const FormContainer = styled(Box)(({ theme }) => ({
  padding: '0.5rem',
}))

const tagsEnumValues = Object.values(AnnotationTagType_Enum)

const annotationSchema = z
  .object({
    name: z.string().min(1, 'Name is required'),
    description: z.string().nullish(),
    from: z.string({ required_error: 'From date is required' }),
    to: z.string().nullish(),
    type: z.nativeEnum(AnnotationType_Enum),
    tags: z.array(z.nativeEnum(AnnotationTagType_Enum)).nullish(),
  })
  .refine(
    input => {
      if (input.to && input.type === 'INTERVAL') {
        const _from = DateTime.fromISO(input.from)
        const _to = DateTime.fromISO(input.to)
        return _from < _to
      }
      return true
    },
    {
      message: 'The end date "to" value must come after "from".',
    }
  )
  .refine(
    input => {
      if (input.to) {
        return input.type === 'INTERVAL'
      }
      return true
    },
    {
      message: 'The annotation has a "to" value set. Its type must be "INTERVAL" ',
    }
  )
  .refine(
    input => {
      if (!input.to) {
        return input.type === 'MOMENT'
      }
      return true
    },
    {
      message: 'The annotation has no end date set. Set a "to" date or change its type to "Moment" ',
    }
  )

type AnnotationFormData = z.infer<typeof annotationSchema>

const emptyAnnotation: AnnotationFormData & { id: string } = {
  id: 'NEW',
  name: '',
  description: '',
  type: AnnotationType_Enum.Moment,
  from: DateTime.local().toISO(),
  to: null,
  tags: [] as AnnotationTagType_Enum[],
}

type EditAnnotationFormProps = {
  assetId: string
  initialValue?: AnnotationDataFragment | null
  renderClock?: boolean
  onClose?: () => void
  handleMutationCompleted: () => void
}

export const EditAnnotationForm: FC<EditAnnotationFormProps> = ({
  onClose,
  initialValue,
  handleMutationCompleted,
  assetId,
  renderClock = true,
}) => {
  const { i18n } = useI18nContext()
  const { userId, getHasuraRoleHeader } = useCurrentUser()
  let defaultValues = emptyAnnotation

  if (initialValue) {
    defaultValues = {
      id: initialValue.id,
      name: initialValue.name || '',
      description: initialValue.description || '',
      type: initialValue.type,
      from: initialValue.from,
      to: initialValue.to,
      tags: [...initialValue.tags.map(t => t.tag)],
    }
  }

  const { control, handleSubmit, reset, watch, setValue, formState } = useForm<AnnotationFormData>({
    mode: 'onChange',
    resolver: zodResolver(annotationSchema),
    defaultValues,
  })

  useEffect(() => reset(defaultValues), [initialValue, reset])

  const closeAndResetForm = useCallback(() => {
    reset(emptyAnnotation)
    onClose && onClose()
  }, [onClose, reset])

  const onCompleted = useCallback(() => {
    closeAndResetForm()
    handleMutationCompleted()
  }, [handleMutationCompleted, closeAndResetForm])

  const [callDeleteMutation, { loading: deleteLoading, error: deleteError }] = useDeleteAnnotationMutation({
    onCompleted,
    context: {
      headers: {
        'x-hasura-role': getHasuraRoleHeader(),
      },
    },
  })
  const [callUpdateMutation, { loading: updateLoading, error: updateError }] = useUpdateAnnotationMutation({
    onCompleted,
    context: {
      headers: {
        'x-hasura-role': getHasuraRoleHeader(),
      },
    },
  })
  const [callCreateMutation, { loading: createLoading, error: createError }] = useCreateAnnotationMutation({
    onCompleted,
    context: {
      headers: {
        'x-hasura-role': getHasuraRoleHeader(),
      },
    },
  })

  const currentTypeValue = watch('type')

  // reset To when toggling type back to MOMENT
  useEffect(() => {
    if (currentTypeValue === AnnotationType_Enum.Moment) {
      setValue('to', null)
    }
  }, [currentTypeValue, setValue])

  const { errors } = formState

  const handleFormSubmit = async (data: AnnotationFormData) => {
    if (data.tags?.length === 0) {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm('Are you sure you want to submit this annotation without any tags?')) {
        return
      }
    }

    if (defaultValues.id && defaultValues.id !== emptyAnnotation.id) {
      // we're updating an existing annotation
      const input: UpdateAnnotationInput = {
        ...data,
        tags: data.tags as unknown as AnnotationTagTypeInput[],
        type: data.type as unknown as AnnotationTypeInput,
        from: data.from as ISODateTime,
        to: data.to as ISODateTime,
        id: defaultValues.id as ID,
      }
      await callUpdateMutation({ variables: { updateAnnotationInput: input } }).catch(console.error)
    } else {
      // we're creating a new annotation
      const input: CreateAnnotationInput = {
        ...data,
        tags: data.tags as unknown as AnnotationTagTypeInput[],
        type: data.type as unknown as AnnotationTypeInput,
        from: data.from as ISODateTime,
        to: data.to as ISODateTime,
        userId,
        assetId: assetId as ID,
      }
      await callCreateMutation({ variables: { createAnnotationInput: input } }).catch(console.error)
    }
  }

  const handleDelete = async () => {
    const id = defaultValues.id as ID
    if (id && id !== emptyAnnotation.id) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete this annotation')) {
        await callDeleteMutation({ variables: { id } }).catch(console.error)
      }
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormContainer>
        <Box
          sx={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight={400}
            mb={1}
          >
            {/* {i18n.text("pdm.graphs.edit-annotation-form")} */}
            {`${initialValue ? 'Edit' : 'Create'} annotation`}
          </Typography>
          <Button
            sx={theme => ({
              color: theme.palette.SFIGreyLight[600],
              fontWeight: 400,
              fontSize: '1.2em',
            })}
            onClick={closeAndResetForm}
          >
            X
          </Button>
        </Box>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Name"
                required
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />

          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <RadioGroup
                row
                {...field}
              >
                <FormControlLabel
                  label="Interval"
                  control={<Radio value={AnnotationType_Enum.Interval} />}
                />
                <FormControlLabel
                  label="Moment"
                  control={<Radio value={AnnotationType_Enum.Moment} />}
                />
              </RadioGroup>
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                fullWidth
                multiline
                rows={3}
                margin="normal"
              />
            )}
          />

          <Controller
            name="from"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                {...field}
                value={new Date(field.value)}
                ampm={false}
                label="From"
                onChange={date => {
                  if (date) {
                    const isoString = DateTime.fromJSDate(date as unknown as Date).toISO()
                    field.onChange(isoString)
                  } else {
                    field.onChange(null)
                  }
                }}
                slotProps={{
                  textField: {
                    sx: { marginTop: '1rem' },
                    fullWidth: true,
                    error: !!errors.from,
                    required: true,
                    helperText: errors.from?.message,
                  },
                }}
                viewRenderers={
                  renderClock
                    ? {
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }
                    : {}
                }
              />
            )}
          />
          {currentTypeValue === AnnotationType_Enum.Interval && (
            <Controller
              name="to"
              control={control}
              shouldUnregister
              render={({ field }) => (
                <>
                  <DateTimePicker
                    {...field}
                    value={field.value ? new Date(field.value) : null}
                    ampm={false}
                    label="To"
                    onChange={date => {
                      if (date) {
                        const isoString = DateTime.fromJSDate(date as unknown as Date).toISO()
                        field.onChange(isoString)
                      } else {
                        field.onChange(null)
                      }
                    }}
                    slotProps={{
                      textField: {
                        sx: { marginTop: '1rem' },
                        fullWidth: true,
                        error: !!errors.to,
                        required: true,
                        helperText: errors.to?.message,
                      },
                    }}
                    viewRenderers={
                      renderClock
                        ? {
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }
                        : {}
                    }
                  />
                </>
              )}
            />
          )}
          <FormControl margin="normal">
            <FormLabel component="legend">Tags</FormLabel>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <FormGroup row>
                  {tagsEnumValues.map(tag => (
                    <FormControlLabel
                      key={tag}
                      control={
                        <Checkbox
                          checked={field.value?.includes(tag)}
                          onChange={e => {
                            if (e.target.checked) {
                              field.value ? field.onChange([...field.value, tag]) : field.onChange(null)
                            } else {
                              field.onChange(field.value?.filter((t: string) => t !== tag))
                            }
                          }}
                        />
                      }
                      label={i18n.text(`pdm.annotations.tags.value.${tag.toLowerCase()}`)}
                    />
                  ))}
                </FormGroup>
              )}
            />
          </FormControl>

          {Object.keys(errors).length > 0 && (
            <Box
              mt={2}
              mb={2}
            >
              <Alert severity="error">
                {Object.values(errors).map((error, index) => (
                  <p key={index}>{error.message}</p>
                ))}
              </Alert>
            </Box>
          )}
          {(createError || updateError || deleteError) && (
            <Alert severity="error">
              <p>{createError?.message || updateError?.message || deleteError?.message}</p>
            </Alert>
          )}
          <Box
            sx={{
              marginTop: '0.5rem',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={() => ({
                minWidth: '200px',
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '0.9rem',
                marginRight: '1em',
                color: 'white !important',
              })}
            >
              {i18n.text('generic.save')}
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={handleDelete}
              sx={() => ({
                minWidth: '200px',
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '0.9rem',
                marginRight: '1em',
                color: 'white',
              })}
            >
              {i18n.text('pdm.graphs.annotations.delete-annotation-button')}
            </Button>
          </Box>
          <Box
            sx={{
              marginTop: '1.25rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={closeAndResetForm}
              sx={() => ({
                minWidth: '100px',
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '0.9rem',
                marginRight: '1em',
                color: 'white',
              })}
            >
              {i18n.text('generic.cancel')}
            </Button>
          </Box>
        </form>
      </FormContainer>
    </LocalizationProvider>
  )
}
