import { createSvgIcon } from '@mui/material'

export const BellOffIcon = createSvgIcon(
  <svg
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.4748 2.27528C6.21469 1.77367 7.09324 1.5 8 1.5C9.19347 1.5 10.3381 1.97411 11.182 2.81802C12.0259 3.66193 12.5 4.80653 12.5 6C12.5 7.57558 12.7026 8.81341 12.9863 9.77431M3.694 4.69293C3.56649 5.11295 3.5 5.55304 3.5 6C3.5 8.31764 2.91535 9.90447 2.26225 10.9541C1.71135 11.8394 1.43589 12.2821 1.44599 12.4056C1.45718 12.5423 1.48615 12.5944 1.59633 12.6762C1.69584 12.75 2.14444 12.75 3.04164 12.75H11.75M6.01563 15.75C6.54448 16.2168 7.23917 16.5 8 16.5C8.76083 16.5 9.45552 16.2168 9.98437 15.75M14.75 15.75L1.25 2.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'bell-on'
)
