export function getBase64(file: File, callback: (result: string) => void) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    if (typeof reader.result !== 'string') {
      return
    }
    callback(reader.result)
  }
  reader.onerror = error => {
    console.log('Error: ', error)
  }
  return reader.result
}
