import { CompressedAirAssetFragment } from '../../Shared/graphql/codegen'

export const getAssetsWithSelectedSensors = (
  allAssets: readonly CompressedAirAssetFragment[],
  ids: string[]
): CompressedAirAssetFragment[] => {
  return allAssets
    .map(asset => ({
      ...asset,
      sensorLocations: asset.sensorLocations.filter(sensorLocation => ids.includes(sensorLocation.id)),
    }))
    .filter(asset => asset.sensorLocations.length > 0)
}
