import styled from 'styled-components'

import MUILoader from '../../../../Shared/components/MUIComponents/Loader'
import PressacBridge from '../../../components/PressacBridge'
import TeltonikaBridge from '../../../components/TeltonikaBridge'
import mapBridgeToSensorData from './utils'
import { ApolloErrorResult } from '../../../../Shared/components/GenericError/ApolloErrorResult'
import { NotFoundResult } from '../../../../Shared/components/GenericError/NotFoundResult'
import { themeColors } from '../../../../Shared/utils'
import { useBridgesInstallationQuery } from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const LocalWrapper = styled.div`
  display: flex;
`
const MainContainer = styled.div`
  padding: 1em;
  width: 100%;
  text-align: left;
  background-color: ${themeColors.white};
  min-height: 100vh;
`
const BridgeList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`
const Bridges = () => {
  const { i18n } = useI18nContext()
  const { data, loading, error } = useBridgesInstallationQuery({
    fetchPolicy: 'no-cache',
  })

  if (error) {
    // TODO: Investigate this error
    console.error(error)
    return <ApolloErrorResult error={error} />
  }
  if (loading) {
    return <MUILoader />
  }
  if (!data || !data.myOrg) {
    return <NotFoundResult />
  }

  const { assets, pressacBridges, teltonikaBridges } = data.myOrg

  return (
    <LocalWrapper>
      <MainContainer>
        <h2>{i18n.text('installation.subroutes.bridges')}</h2>
        <br />
        <BridgeList>
          {pressacBridges.map(bridge => (
            <PressacBridge
              key={bridge.bridgeId}
              bridgeId={bridge.bridgeId}
              network={bridge.bridgify.network}
              sensors={mapBridgeToSensorData(bridge, assets)}
            />
          ))}
          {teltonikaBridges.map(bridge => (
            <TeltonikaBridge
              key={bridge.id}
              serialNumber={bridge.serialNumber}
              updatedAt={bridge.updatedAt}
            />
          ))}
        </BridgeList>
      </MainContainer>
    </LocalWrapper>
  )
}
export default Bridges
