import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'

import { useOEEContext } from '../context/OEEContext'

type LocationParams = {
  machineId: string
}

export const useMachineViewId = () => {
  const { assets, updateSelectedMachine } = useOEEContext()
  const locationParams = useParams<LocationParams>()
  const history = useHistory()

  const potentialId = locationParams.machineId

  useEffect(() => {
    // Check if the ID is a valid non-empty string
    const isValidId = (id: string) => assets.some(asset => asset.machines.some(machine => machine.id === id))

    if ((!potentialId || !isValidId(potentialId)) && !history.location.key) {
      const currentPath = history.location.pathname
      const firstMachineId = assets[0].machines[0]?.id
      const targetPath = `/oee/machine/${firstMachineId}`

      if (currentPath !== targetPath) {
        updateSelectedMachine(firstMachineId)
        history.push(targetPath)
      }
    } else {
      updateSelectedMachine(potentialId)
    }
  }, [assets, history, potentialId, updateSelectedMachine])
}
