import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useState } from 'react'

import MUILoader from '../../../../Shared/components/MUIComponents/Loader'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

interface IntegrationDeleteAlertProps {
  loading: boolean
  id: string
  handleDeleteUserApiIntegration: (id: string) => void
}

export const IntegrationDeleteAlert = ({
  id,
  loading,
  handleDeleteUserApiIntegration,
}: IntegrationDeleteAlertProps) => {
  const [open, setOpen] = useState(false)
  const { i18n } = useI18nContext()
  const isMounted = useRef(true)

  const handleClickOpen = (id: string) => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = async () => {
    await handleDeleteUserApiIntegration(id)
    if (isMounted.current) {
      setOpen(false)
    }
  }

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <>
      <Button
        size="medium"
        onClick={() => handleClickOpen(id)}
      >
        {i18n.text('delete')}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{i18n.text('integrations.delete.confirmation')}</DialogTitle>
        <DialogContent>
          {loading ? (
            <MUILoader
              height={5}
              margin={'0 auto'}
            />
          ) : (
            <Alert
              severity="error"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {i18n.text('integrations.delete.alert')}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{i18n.text('integrations.delete.cancel')}</Button>
          <Button onClick={handleDelete}>{i18n.text('integrations.delete.delete')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
