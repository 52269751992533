/**
 * sortMeasurements - sort measurments on bases of time
 *
 * @param  {array} measurements a collection of measurments
 * @return {array}              a sorted collection of measurments
 */
export function sortMeasurements(measurements: $TSFixMe[]) {
  return measurements.sort(({ time: timeA }: $TSFixMe, { time: timeB }: $TSFixMe) => {
    const valueOfA = new Date(timeA).valueOf()
    const valueOfB = new Date(timeB).valueOf()
    if (valueOfA <= valueOfB) return -1
    if (valueOfA >= valueOfB) return 1
    return 0
  })
}
