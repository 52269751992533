import React from 'react'
import { Switch, styled as muiStyled } from '@mui/material'
import { isEmpty } from 'ramda'

import Interval, { IntervalType } from '../../../Shared/components/Interval'
import { defaultInterval } from '../../containers/RuleEditor/utils'
import { swapArrayElements } from './utils'

const AlwaysOnWrapper = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  borderRadius: theme.spacing(0.5),
}))

const IntervalEditorWrapper = muiStyled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& > ol': {
    marginTop: '2em',
  },
}))

const StyledLabel = muiStyled('label')(({ theme }) => ({
  fontSize: '15px',
  display: 'block',
}))

interface CustomInterval extends IntervalType {
  default?: boolean
}

interface IntervalEditorProps {
  i18n: $I18FixMe
  onChange: (selection: IntervalType[]) => void
  selected: CustomInterval[]
}

const IntervalEditor = ({ i18n, onChange, selected }: IntervalEditorProps): JSX.Element => {
  const intervals = isEmpty(selected) ? [defaultInterval] : selected

  const alwaysOn = _evaluateAlwaysOn(intervals)

  function _evaluateAlwaysOn(_intervals: CustomInterval[]): boolean {
    return _intervals.some(({ alwaysOn: isAlwaysOn }) => isAlwaysOn)
  }

  const onIntervalUpdate = (index: number, interval: CustomInterval | undefined) => {
    const updatedIntervals = [...intervals]

    if (interval) updatedIntervals.splice(index, 1, interval)
    else updatedIntervals.splice(index, 1)

    updatedIntervals.forEach(i => {
      delete i.alwaysOn
    })
    updatedIntervals[0].alwaysOn = alwaysOn
    onChange(updatedIntervals)
  }

  const onChangeAlwaysOn = () => {
    const updatedIntervals = [...intervals]
    updatedIntervals.forEach(interval => {
      delete interval.alwaysOn
    })
    updatedIntervals[0].alwaysOn = !alwaysOn
    onChange(updatedIntervals)
  }

  const onChangeOrder = (index: number, direction: 1 | -1) => {
    if (index === 0 && direction === -1) return
    if (index === intervals.length - 1 && direction === 1) return

    const reorderedIntervals = swapArrayElements(intervals, index, index + direction)

    reorderedIntervals.forEach(interval => {
      delete interval.alwaysOn
    })
    reorderedIntervals[0].alwaysOn = alwaysOn

    onChange(reorderedIntervals)
  }

  const onClone = (index: number) => {
    const updatedIntervals = [...intervals]
    updatedIntervals.splice(index, 0, intervals[index])

    updatedIntervals.forEach(interval => {
      delete interval.alwaysOn
    })
    updatedIntervals[0].alwaysOn = alwaysOn

    onChange(updatedIntervals)
  }

  return (
    <IntervalEditorWrapper>
      <AlwaysOnWrapper>
        <StyledLabel>{i18n.text('rules.headers.always-on')}</StyledLabel>
        <Switch
          key={`${alwaysOn}`}
          checked={alwaysOn}
          color="secondary"
          onChange={onChangeAlwaysOn}
        />
      </AlwaysOnWrapper>
      {!alwaysOn && (
        <ol id="intervals">
          {intervals.map((interval, i) => (
            <li key={`interval-editor-li-${i}-${interval.from.time}-${interval.from.day}`}>
              <Interval
                key={`interval-editor-${i}-${interval.from.time}-${interval.from.day}`}
                changeOrderDisabled={intervals.length === 1}
                disabled={alwaysOn}
                i18n={i18n}
                index={i}
                interval={interval}
                onChange={onIntervalUpdate}
                onChangeOrder={onChangeOrder}
                onClone={onClone}
                removeDisabled={intervals.length === 1}
              />
            </li>
          ))}
        </ol>
      )}
    </IntervalEditorWrapper>
  )
}

export default IntervalEditor
