import { createSvgIcon } from '@mui/material'

export const NegativeTrendIcon = createSvgIcon(
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 3.5L8.5 8.5M8.5 8.5V3.5M8.5 8.5H3.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'negative-trend'
)
