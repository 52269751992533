import React from 'react'

export enum AssetStatus {
  Success = 'success',
  Warning = 'warning',
  NoData = 'nodata',
  Disabled = 'disabled',
  Off = 'off',
  Error = 'error',
}

export interface AssetStatusLabel {
  icon?: JSX.Element
  text?: string
  customStatusComponent?: React.ReactNode
}

export interface AssetCardProps {
  assetStatusColor: AssetStatus
  assetStatusLabel: AssetStatusLabel
  hasAlerts?: boolean
  alerts?: boolean
  lastMeasurement?: string
  primaryName?: string
  secondaryName?: string
  assetNameOverflow?: boolean
  isCollapsible?: boolean
  expandAll?: boolean
  isExpanded?: boolean
  isCardaExpanded?: boolean
  onExpandedChange?: (expanded: boolean) => void
  onClick?: () => void
  children?: React.ReactNode
}
