import { FC } from 'react'

import { AssetTreeChildNode } from '../../../Shared/components/MUIComponents/update/AssetTree/StyledAssetTreeChildNode'
import { AssetTreeComponent, AssetTreeMachine } from '../../types'
import { ElectricityAssetTreeInfoTooltip } from './ElectricityAssetTreeInfoTooltip'
import { ElectricityMachineWithComponents } from './ElectricityMachineWithComponents'
import { useElectricityContext } from '../../context/ElectricityContext'

interface ElectricityAssetTreeMachineProps {
  machine: AssetTreeMachine
  components: AssetTreeComponent[]
  onSelect: (assetId: string) => void
  searchTerm: string
}

const ElectricityAssetTreeMachine: FC<ElectricityAssetTreeMachineProps> = ({
  machine,
  components,
  searchTerm,
  onSelect,
}) => {
  const { selectedAssets } = useElectricityContext()
  if (components?.length > 0) {
    return (
      <ElectricityMachineWithComponents
        machine={machine}
        components={components}
        onSelect={onSelect}
        searchTerm={searchTerm}
        iconSlot={<ElectricityAssetTreeInfoTooltip machine={machine} />}
      />
    )
  } else {
    const isSelected = !!selectedAssets[machine.id]
    return (
      <AssetTreeChildNode
        hasCheckbox={true}
        isSelected={isSelected}
        item={{ id: machine.id, name: machine.name }}
        handleClick={() => onSelect(machine.id)}
        iconSlot={<ElectricityAssetTreeInfoTooltip machine={machine} />}
      />
    )
  }
}

export { ElectricityAssetTreeMachine }
