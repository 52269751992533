import React from 'react'
import { DateTime } from 'luxon'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers'
import { isSameDay } from 'date-fns'
import { styled } from '@mui/material'

const BORDER_ONLY_RIGHT_ROUNDED = '0 20px 20px 0'
const BORDER_ONLY_LEFT_ROUNDED = '20px 0 0 20px'

export interface CustomDayProps extends PickersDayProps<DateTime> {
  customDayConditions: {
    isSelected: boolean
    isCompareSelected: boolean
    isStart: boolean
    isCompareStart: boolean
    isCompareEnd: boolean
    isEnd: boolean
    isToday: boolean
    isFirstSelectedAfterStart: boolean
    isFirstSelectedAfterCompareStart: boolean
    isFirstCompareSelectedBeforeStart: boolean
    isFirstSelectedBeforeEnd: boolean
    isFirstCompareSelectedAfterEnd: boolean
    isFirstSelectedBeforeCompareStart: boolean
    isFirstSelectedAfterCompareEnd: boolean
    notSelectedCompareToDate: boolean
  }
}

interface renderStyledDayProps extends PickersDayProps<DateTime> {
  day: DateTime
  fromDate: DateTime | null
  toDate: DateTime | null
  comparedFromDate: DateTime | null
  comparedToDate: DateTime | null
  settingFromDate: boolean
  isCompareDatesOn: boolean
  handleChange: (date: DateTime | null) => void
  settingCompareDates: {
    from: boolean
    to: boolean
    compareFrom: boolean
  }
}

export const getIsSelected = (
  firstDate: DateTime | null,
  secondDate: DateTime | null,
  checkDate: DateTime,
  includingStartEnd: boolean
) =>
  !!firstDate &&
  !!secondDate &&
  ((!includingStartEnd &&
    checkDate.startOf('day') > firstDate.startOf('day') &&
    checkDate.startOf('day') < secondDate.startOf('day')) ||
    (includingStartEnd &&
      checkDate.startOf('day') >= firstDate.startOf('day') &&
      checkDate.startOf('day') <= secondDate.startOf('day')))

export const getIsStart = (firstDate: DateTime | null, checkDate: DateTime) =>
  !!firstDate && isSameDay(firstDate.toJSDate(), checkDate.toJSDate())

export const getIsEnd = (secondDate: DateTime | null, checkDate: DateTime) =>
  !!secondDate && isSameDay(secondDate.toJSDate(), checkDate.toJSDate())

export const getIsFirstSelectedAfterStart = (firstDate: DateTime | null, checkDate: DateTime) =>
  !!firstDate && isSameDay(firstDate.plus({ days: 1 }).toJSDate(), checkDate.toJSDate())
export const getIsFirstSelectedBeforeEnd = (secondDate: DateTime | null, checkDate: DateTime) =>
  !!secondDate && isSameDay(secondDate.minus({ days: 1 }).toJSDate(), checkDate.toJSDate())
export const getIsFirstSelectedAfterEnd = (secondDate: DateTime | null, checkDate: DateTime) =>
  !!secondDate && isSameDay(secondDate.plus({ days: 1 }).toJSDate(), checkDate.toJSDate())
export const getIsFirstSelectedBeforeStart = (firstDate: DateTime | null, checkDate: DateTime) =>
  !!firstDate && isSameDay(firstDate.minus({ days: 1 }).toJSDate(), checkDate.toJSDate())

export const renderStyledDay = ({
  day,
  fromDate,
  toDate,
  comparedFromDate,
  comparedToDate,
  settingFromDate,
  settingCompareDates,
  isCompareDatesOn,
  handleChange,
  ...other
}: renderStyledDayProps) => {
  const isToday = isSameDay(new Date(), day.toJSDate())
  const notSelectedCompareToDate = !comparedToDate
  const isSelected = getIsSelected(fromDate, toDate, day, false)
  const isCompareSelected = getIsSelected(comparedFromDate, comparedToDate, day, true)
  const isStart = getIsStart(fromDate, day)
  const isCompareStart = getIsStart(comparedFromDate, day)
  const isEnd = getIsEnd(toDate, day)
  const isCompareEnd = getIsEnd(comparedToDate, day)

  const isFirstSelectedAfterStart = getIsFirstSelectedAfterStart(fromDate, day)
  const isFirstSelectedAfterCompareStart = getIsFirstSelectedAfterStart(comparedFromDate, day)
  const isFirstCompareSelectedBeforeStart = getIsFirstSelectedBeforeStart(fromDate, day)
  const isFirstSelectedBeforeCompareStart = getIsFirstSelectedBeforeStart(comparedFromDate, day)
  const isFirstSelectedBeforeEnd = getIsFirstSelectedBeforeEnd(toDate, day)
  const isFirstCompareSelectedAfterEnd = getIsFirstSelectedAfterEnd(toDate, day)
  const isFirstSelectedAfterCompareEnd = getIsFirstSelectedAfterEnd(comparedToDate, day)

  const customDayConditions = {
    isSelected,
    isCompareSelected,
    isStart,
    isCompareStart,
    isEnd,
    isCompareEnd,
    isFirstSelectedAfterStart,
    isFirstCompareSelectedBeforeStart,
    isFirstSelectedBeforeCompareStart,
    isFirstSelectedAfterCompareStart,
    isFirstSelectedBeforeEnd,
    isFirstCompareSelectedAfterEnd,
    isFirstSelectedAfterCompareEnd,
    isToday,
    notSelectedCompareToDate,
  }

  return (
    <CustomDay
      {...other}
      onClick={() => handleChange(day)}
      day={day}
      customDayConditions={customDayConditions}
    />
  )
}

export const CustomDay = styled(PickersDay, {
  shouldForwardProp: prop => prop !== 'customDayConditions',
})<CustomDayProps>(
  ({
    theme,
    customDayConditions: {
      isToday,
      isSelected,
      isCompareSelected,
      isStart,
      isCompareEnd,
      isCompareStart,
      isEnd,
      isFirstSelectedAfterStart,
      isFirstSelectedAfterCompareStart,
      isFirstCompareSelectedBeforeStart,
      isFirstSelectedBeforeEnd,
      isFirstCompareSelectedAfterEnd,
      isFirstSelectedBeforeCompareStart,
      isFirstSelectedAfterCompareEnd,
      notSelectedCompareToDate,
    },
  }) => ({
    '&.Mui-selected': {
      background: isCompareStart
        ? theme.palette.SFIMoss?.[300]
        : isSelected
        ? theme.palette.SFIBrand?.[300]
        : isToday
        ? theme.palette.SFIGreyLight[200]
        : theme.palette.SFIBase.white,
      color: theme.palette.SFIGreyLight[700],
      fontWeight: isToday ? 500 : 400,
      '&:hover': {
        backgroundColor: isCompareStart ? theme.palette.SFIMoss?.[300] : theme.palette.SFIBrand?.[300],
        fontWeight: 500,
        borderRadius: !isCompareSelected && !isSelected ? '50%' : 'none',
      },
      '&:focus': {
        backgroundColor: isCompareStart ? theme.palette.SFIMoss?.[300] : theme.palette.SFIBrand?.[300],
        fontWeight: 500,
        borderRadius: !isCompareSelected && !isSelected ? '50%' : 'none',
      },
    },
    fontSize: '14px',
    lineHeight: '1.25rem',
    fontWeight: 400,
    margin: 0,
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.SFIGreyLight[700],
    transition: 'none',
    '&:hover': {
      backgroundColor: theme.palette.SFIBrand?.[300],
      fontWeight: 500,
      borderRadius: !isCompareSelected && !isSelected ? '50%' : 'none',
    },
    ...(isToday && {
      backgroundColor: theme.palette.SFIGreyLight[100],
      color: theme.palette.SFIGreyLight[700],
      fontWeight: 500,
      border: 'none!important',
    }),

    ...((isSelected || isCompareSelected || (isCompareStart && notSelectedCompareToDate)) && {
      background:
        isCompareSelected && isSelected
          ? `${theme.palette.SFIGradient.light}!important`
          : isCompareSelected || (isCompareStart && notSelectedCompareToDate)
          ? `${theme.palette.SFIMoss?.[300]}!important`
          : theme.palette.SFIBrand?.[300],
      fontWeight: 500,
      borderRadius: 0,
      position: 'relative',
      zIndex: 2,
      '&:hover': {
        borderRadius: 'none',
        backgroundColor: isCompareStart ? theme.palette.SFIMoss?.[300] : theme.palette.SFIBrand?.[300],
      },
      '&:last-of-type': {
        borderRadius:
          (isCompareSelected && isCompareStart && !isSelected) || (isCompareStart && notSelectedCompareToDate)
            ? '50%'
            : BORDER_ONLY_RIGHT_ROUNDED,
      },
      '&:first-of-type': {
        borderRadius: BORDER_ONLY_LEFT_ROUNDED,
      },
      '&:only-of-type': {
        borderRadius: '50%',
      },
    }),
    ...(isCompareEnd && {
      color: `${theme.palette.SFIGreyLight[700]}!important`,
    }),
    ...(isCompareStart &&
      !isFirstCompareSelectedAfterEnd && {
        borderRadius: BORDER_ONLY_LEFT_ROUNDED,
      }),
    ...(isCompareStart && {
      zIndex: 3,
      color: isStart ? `${theme.palette.SFIBase.white}` : `${theme.palette.SFIGreyLight[700]}!important`,
      '&:last-of-type': {
        borderRadius: isFirstSelectedAfterStart ? BORDER_ONLY_RIGHT_ROUNDED : '50%',
        '&:after': {
          display: 'none',
        },
      },
    }),
    ...(isCompareEnd &&
      !isFirstCompareSelectedBeforeStart && {
        borderRadius: BORDER_ONLY_RIGHT_ROUNDED,
      }),
    ...(((isCompareStart && isFirstSelectedAfterStart) || (isCompareEnd && isFirstSelectedBeforeEnd)) && {
      borderRadius: '0',
    }),

    ...(((isFirstSelectedAfterStart && isSelected && !isEnd) ||
      (isFirstSelectedAfterStart && isCompareSelected) ||
      (isFirstCompareSelectedAfterEnd && isCompareSelected) ||
      (isSelected && isFirstSelectedAfterCompareStart && notSelectedCompareToDate) ||
      (isSelected && isFirstSelectedAfterCompareEnd)) && {
      '&:before': {
        content: '""',
        position: 'absolute',
        left: `-${theme.spacing(2.5)}`,
        width: theme.spacing(2.5),
        height: theme.spacing(5),
        background:
          isCompareSelected && isSelected
            ? theme.palette.SFIGradient.light
            : isCompareSelected
            ? theme.palette.SFIMoss?.[300]
            : theme.palette.SFIBrand?.[300],
        zIndex: 1,
      },
      '&:first-of-type': {
        borderRadius: BORDER_ONLY_LEFT_ROUNDED,
        '&:before': {
          display: 'none',
        },
      },
    }),
    ...(((isFirstSelectedAfterStart && isEnd) ||
      (isFirstSelectedAfterCompareStart && notSelectedCompareToDate && isEnd)) && {
      zIndex: isCompareSelected || notSelectedCompareToDate ? '3!important' : '1!important',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: `-${theme.spacing(2.5)}`,
        width: theme.spacing(5),
        height: theme.spacing(5),
        background: theme.palette.SFIBrand?.[300],
        zIndex: -1,
        display: isFirstSelectedAfterCompareStart && isEnd && notSelectedCompareToDate ? 'none' : 'block',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        width: theme.spacing(5),
        height: theme.spacing(5),
        background: theme.palette.SFIBrand[900],
        zIndex: -1,
        borderRadius: '50%',
      },
      '&:first-of-type': {
        '&:before': {
          display: 'none',
        },
      },
    }),
    ...(((isFirstSelectedBeforeEnd && isSelected && !isStart) ||
      (isFirstCompareSelectedBeforeStart && isCompareSelected) ||
      (isSelected && isFirstSelectedBeforeCompareStart)) && {
      '&:after': {
        content: '""',
        position: 'absolute',
        right: `-${theme.spacing(2.5)}`,
        width: theme.spacing(2.5),
        height: theme.spacing(5),
        background:
          isCompareSelected && isSelected
            ? theme.palette.SFIGradient.light
            : isCompareSelected
            ? theme.palette.SFIMoss?.[300]
            : theme.palette.SFIBrand?.[300],
        zIndex: 1,
      },
      '&:last-of-type': {
        borderRadius: isCompareStart && !isSelected ? '50%' : BORDER_ONLY_RIGHT_ROUNDED,
        '&:after': {
          display: 'none',
        },
      },
    }),

    ...(isCompareStart &&
      isFirstSelectedAfterStart && {
        '&:first-of-type': {
          borderRadius: isCompareEnd ? '50%' : BORDER_ONLY_LEFT_ROUNDED,
          '&:before': {
            display: 'none',
          },
        },
      }),
    ...(isCompareEnd &&
      isFirstSelectedBeforeEnd && {
        '&:last-of-type': {
          borderRadius: isCompareStart ? '50%' : BORDER_ONLY_RIGHT_ROUNDED,
          '&:after': {
            display: 'none',
          },
        },
      }),
    ...((isStart || isEnd) &&
      isCompareSelected && {
        borderRadius: '50%',
      }),
    ...(isCompareStart &&
      isFirstCompareSelectedAfterEnd && {
        borderRadius: BORDER_ONLY_LEFT_ROUNDED,
        '&:before': {
          display: 'none',
        },
      }),
    ...(isCompareStart &&
      isFirstSelectedAfterStart &&
      notSelectedCompareToDate && {
        '&:before': {
          display: 'none',
        },
      }),
    ...(isCompareEnd && {
      zIndex: isFirstCompareSelectedAfterEnd ? '2!important' : 3,
      '&:first-of-type': {
        borderRadius: '50%',
        '&:before': {
          display: 'none',
        },
      },
    }),
    ...(isCompareEnd &&
      isFirstCompareSelectedBeforeStart && {
        color: `${theme.palette.SFIGreyLight[700]}!important`,
        '&:after': {
          display: 'none',
        },
      }),
    ...((isEnd || isStart) &&
      isCompareStart && {
        zIndex: '4!important',
      }),
    ...(isCompareStart &&
      notSelectedCompareToDate && {
        borderRadius: '50%',
        '&:after': {
          display: 'none',
        },
      }),
    ...(isCompareEnd &&
      isCompareStart &&
      isFirstSelectedAfterStart &&
      !isFirstSelectedBeforeEnd && {
        borderRadius: BORDER_ONLY_RIGHT_ROUNDED,
      }),
    ...(isCompareEnd &&
      isFirstCompareSelectedBeforeStart && {
        borderRadius: BORDER_ONLY_RIGHT_ROUNDED,
      }),
    ...(isCompareEnd &&
      isCompareStart &&
      !isFirstSelectedAfterStart &&
      isFirstSelectedBeforeEnd && {
        borderRadius: BORDER_ONLY_LEFT_ROUNDED,
      }),
    ...(isCompareEnd &&
      isCompareStart &&
      ((!isFirstSelectedAfterStart && !isFirstSelectedBeforeEnd) ||
        (isFirstSelectedAfterStart && isFirstCompareSelectedAfterEnd)) && {
        borderRadius: '50%!important',
      }),
    ...((isStart || isEnd) && {
      color: `${theme.palette.common.white}!important`,
      background: !isCompareSelected
        ? `${theme.palette.SFIBrand[900]}!important`
        : `${theme.palette.SFIGradient.main}!important`,
      fontWeight: 500,
      zIndex: isFirstSelectedAfterStart && !isCompareSelected ? 1 : '4!important',
      borderRadius: '50%!important',
      '&:hover': {
        color: theme.palette.SFIBase.white,
      },
    }),
    ...(isStart &&
      isFirstSelectedBeforeEnd && {
        zIndex: '5!important',
      }),
  })
) as React.ComponentType<CustomDayProps>
