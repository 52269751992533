import { createSvgIcon } from '@mui/material'

export const MoneyIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8.00004 11.3333C8.00004 13.1743 9.49243 14.6667 11.3334 14.6667C13.1743 14.6667 14.6667 13.1743 14.6667 11.3333C14.6667 9.49238 13.1743 8 11.3334 8C9.49243 8 8.00004 9.49238 8.00004 11.3333ZM8.00004 11.3333C8.00004 10.5828 8.2481 9.89017 8.66671 9.33301V3.33333M8.00004 11.3333C8.00004 11.8836 8.13336 12.4027 8.36946 12.8601C7.80782 13.3345 6.5106 13.6667 5.00004 13.6667C2.975 13.6667 1.33337 13.0697 1.33337 12.3333V3.33333M8.66671 3.33333C8.66671 4.06971 7.02508 4.66667 5.00004 4.66667C2.975 4.66667 1.33337 4.06971 1.33337 3.33333M8.66671 3.33333C8.66671 2.59695 7.02508 2 5.00004 2C2.975 2 1.33337 2.59695 1.33337 3.33333M1.33337 9.33333C1.33337 10.0697 2.975 10.6667 5.00004 10.6667C6.45938 10.6667 7.71959 10.3567 8.3098 9.90785M8.66671 6.33333C8.66671 7.06971 7.02508 7.66667 5.00004 7.66667C2.975 7.66667 1.33337 7.06971 1.33337 6.33333"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'money-icon'
)
