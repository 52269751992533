import React, { FC, useMemo } from 'react'
import { Box, Chip, Typography, useTheme } from '@mui/material'

import Error from '../../../Shared/components/MUIComponents/Error'
import Loader from '../../../Shared/components/MUIComponents/Loader'
import OEEMetrics from '../OEEMetrics/OEEMetrics'
import ProductionSpeed from '../ProductionSpeed/ProductionSpeed'
import StopsOverviewTable from '../StopsOverviewTable/StopsOverviewTable'
import { AssetProductionSpeed } from '../../types/Assets.types'
import {
  CycleTime,
  Measurement,
  OeeProductionStop,
  Oee_Production_Speed_Unit_Enum,
  useGetOeeProductionShiftDetailsQuery,
  useGetOeeSensorLocationCriticalStopTimeQuery,
} from '../../../Shared/graphql/codegen'
import { DEFAULT_TIMEZONE } from '../../../Shared/constants/timezone'
import { ID } from '../../../Shared/types/types'
import { OeeMetricsResponse } from '../../types/OeeMetrics.type'
import { ShiftInterval } from '../../types/ShiftComparison.types'
import { StyledDialogModal } from '../../../Shared/components/MUIComponents/StyledDialogModal'
import { TableBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { convertFromCycleTimeSeconds, round } from '../../utils/unitConversions'
import { getFormattedInterval, getHasPerformanceWarnings, getProductionSpeedSeriesData } from '../../utils/helpers'
import { transformOeeMetrics, transformProductionStopsData } from '../../utils/dataTransformations'
import { useAuthContext } from '../../../Shared/contexts/AuthContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type ShiftDetailsModalProps = {
  locationId: string
  machineName: string
  shiftInterval: ShiftInterval
  isOpen: boolean
  onClose: () => void
}

const ShiftDetailsModal: FC<ShiftDetailsModalProps> = ({ locationId, machineName, shiftInterval, isOpen, onClose }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { units } = useOEEContext()
  const { currentCustomer } = useAuthContext()

  const timeZone = currentCustomer?.timeZone ?? DEFAULT_TIMEZONE
  const defaultStopReason = i18n.text('oee.stop-analysis.categories.not-categorized')

  const { data, loading, error } = useGetOeeProductionShiftDetailsQuery({
    variables: {
      customerId: currentCustomer?.id as ID,
      locationId: locationId as ID,
      start: shiftInterval.start,
      end: shiftInterval.end,
    },
  })

  const { data: criticalStopTimeData } = useGetOeeSensorLocationCriticalStopTimeQuery({
    variables: {
      sensorLocationId: locationId,
    },
  })

  const oeeMetrics = data?.productionShiftDetails?.oeeMetrics as OeeMetricsResponse
  const productionSpeeds = data?.productionShiftDetails?.productionSpeeds
  const stopsData = data?.productionShiftDetails?.stops

  const isCycleTime = units.speed === Oee_Production_Speed_Unit_Enum.CycleTimeSeconds

  const targetTooltipLineName = i18n.text(
    isCycleTime ? 'oee.speed-graph.tooltip.target-cycle-time' : 'oee.speed-graph.tooltip.target-speed'
  )

  const transformedOeeMetrics = useMemo(() => transformOeeMetrics(oeeMetrics), [oeeMetrics])

  const targetCycleTimes = useMemo(
    () => (data?.productionShiftDetails?.targetCycleTimes ?? []) as Partial<CycleTime>[],
    [data?.productionShiftDetails?.targetCycleTimes]
  )

  const productionSpeedSeries = useMemo(
    () => ({
      name: machineName ?? '',
      data: productionSpeeds?.map(productionSpeed =>
        getProductionSpeedSeriesData(
          productionSpeed as Partial<Measurement>,
          units.speed,
          criticalStopTimeData?.enoceanPulseCounterSensorLocationById?.criticalStopTimeSeconds ?? 0
        )
      ),
    }),
    [
      criticalStopTimeData?.enoceanPulseCounterSensorLocationById?.criticalStopTimeSeconds,
      machineName,
      productionSpeeds,
      units.speed,
    ]
  )

  const targetCycleTimeSeries = useMemo(() => {
    return {
      name: targetTooltipLineName,
      data: targetCycleTimes.map(targetCycleTime => {
        return {
          x: targetCycleTime?.time,
          y: round(convertFromCycleTimeSeconds(targetCycleTime?.valueInSeconds ?? 0, units.speed), 1),
        }
      }),
    }
  }, [targetCycleTimes, targetTooltipLineName, units.speed])

  const series = [productionSpeedSeries, targetCycleTimeSeries]

  const hasPerformanceWarning = getHasPerformanceWarnings(targetCycleTimes)

  const productionStops = useMemo(() => {
    const stops = (stopsData?.map(stop => stop?.stop) ?? []) as OeeProductionStop[]
    return transformProductionStopsData(stops, machineName, timeZone, defaultStopReason)
  }, [defaultStopReason, machineName, stopsData, timeZone])

  const formattedInterval = getFormattedInterval(shiftInterval.start, shiftInterval.end, i18n.locale.replace('_', '-'))

  const customOptions = {
    chart: {
      type: 'line' as ApexChart['type'],
    },
    stroke: {
      curve: ['straight', 'stepline'] as ApexStroke['curve'],
      width: [3, 2],
      dashArray: [0, 5],
    },
    tooltip: {
      shared: true,
    },
    colors: [theme.palette.secondary.main, theme.palette.SFIOrangeDark[600]],
  }

  const renderFallback = () => {
    if (loading) return <Loader />
    if (error) return <Error />
    return null
  }

  const renderTitle = () => {
    return (
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '1.125rem',
            fontWeight: 600,
            color: theme.palette.SFIGreyLight[800],
            lineHeight: '1.75rem',
          }}
        >
          {machineName}
        </Typography>
        <Chip
          sx={{
            backgroundColor: theme.palette.SFIBase.white,
            border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
            borderRadius: '6px',
            width: 'fit-content',
            height: 'auto',
            boxShadow: `0px 1px 2px 0px ${theme.palette.SFIGreyLight[300]}`,
            padding: '0.13rem 0.5rem',
            '& .MuiChip-label': {
              padding: 0,
              fontWeight: 500,
            },
          }}
          size="small"
          label={`${formattedInterval.start} - ${formattedInterval.end}`}
        />
      </Box>
    )
  }

  return (
    <StyledDialogModal
      customTitle={renderTitle()}
      isOpen={isOpen}
      isLoading={false}
      onClose={onClose}
      height="responsive"
      maxWidth="lg"
    >
      {data ? (
        <Box sx={{ display: 'grid', rowGap: '1.25rem' }}>
          <OEEMetrics
            oeeMetrics={transformedOeeMetrics}
            warnings={{ performance: hasPerformanceWarning }}
          />
          <Box id="chart-wrapper">
            <ProductionSpeed
              productionSpeedData={series as AssetProductionSpeed[]}
              shouldShowQuickIntervals={false}
              chartHeight={350}
              isToolbarDisabled={true}
              customOptions={customOptions}
            />
          </Box>
          <TableBoxWrapper>
            <Typography
              sx={{ padding: '1rem' }}
              variant="h6"
            >
              {i18n.text('oee.stop-analysis.stops-overview.title')}
            </Typography>
            <StopsOverviewTable stops={productionStops} />
          </TableBoxWrapper>
        </Box>
      ) : (
        renderFallback()
      )}
    </StyledDialogModal>
  )
}

export default ShiftDetailsModal
