import LineChart from '@sensorfactdev/nucleus/LineChart'
import Loader from '@sensorfactdev/nucleus/Loader'
import styled from 'styled-components'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { curryN, flatten, isEmpty, propOr } from 'ramda'

import CenterMessage from '../../../../Shared/components/CenterMessage'
import ComponentButton from '../../../../Shared/components/ComponentButton'
import GenericError from '../../../../Shared/components/GenericError'
import { CurrentCustomer } from '../../../../Shared/types/types'
import { DateRange } from '../../../../Shared/types/analysis_types'
import { LocalStoreState } from '../../../types'
import { getAssetById } from '../../../../Shared/utils'
import { getLargestMeasurement } from '../../../../Shared/utils'
import { getPowerOrderOfMagnitude } from '../../../../Shared/utils'
import { getTickSize } from '../../../../Shared/utils'
import { isDateOutsideOfRange } from '../../../../Shared/utils'
import { prepareGraphData, prepareStateData } from './utils'
import { themeColors } from '../../../../Shared/utils'

const LocalWrapper = styled.div`
  display: flex;
`
const MainContainer = styled.div<{ isDashboard?: boolean }>`
  width: 100%;
  text-align: left;
  background-color: ${themeColors.white};
  position: relative;
`
const ComponentListContainer = styled.div<{ isDashboard?: boolean }>`
  text-align: left;
  margin: 1em 0 0 2em;
  visibility: ${({ isDashboard }) => (isDashboard ? 'hidden' : 'visible')};
`
const ComponentList = styled.div`
  padding-top: 1em;
`
const SelectComponent = styled.p`
  margin: 0;
  color: ${themeColors.primaryLight};
`
const ChartContainer = styled.div``
const LoaderWrapper = styled.div<{ height?: number }>`
  height: ${({ height }) => height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const prepareGraphMeasurements = (
  pending: $TSFixMe,
  allAssets: $TSFixMe,
  selectedAssets: $TSFixMe,
  selectedComponents: $TSFixMe,
  measurements: $TSFixMe,
  altMeasurements: $TSFixMe,
  totalConsumptionSelected: $TSFixMe,
  totalConsumption: $TSFixMe,
  altTotalConsumption: $TSFixMe,
  yAxisLockInfo: $TSFixMe
) => {
  // Prepare data for the graph
  const _prepareGraphData = curryN(
    2,
    prepareGraphData
  )({
    assets: allAssets,
    selectedAssets,
    selectedComponents,
  })
  // Prepare measurements
  const _rawMeasurements: $TSFixMe[] = pending
    ? []
    : // @ts-expect-error ts-migrate(2554) FIXME: Expected 0-1 arguments, but got 2.
    (_prepareGraphData(measurements, 'measurements') as $TSFixMe).concat(
      totalConsumptionSelected ? [totalConsumption.map((t: $TSFixMe) => ({ ...t, total: true }))] : []
    )

  // Prepare altMeasurements
  const _rawAltmeasurements: $TSFixMe[] = pending
    ? []
    : // @ts-expect-error ts-migrate(2554) FIXME: Expected 0-1 arguments, but got 2.
    (_prepareGraphData(altMeasurements, 'altMeasurements') as $TSFixMe).concat(
      totalConsumptionSelected ? [altTotalConsumption.map((t: $TSFixMe) => ({ ...t, total: true }))] : []
    )

  const largestMeasurement: number = propOr(0, 'value', getLargestMeasurement(_rawMeasurements))

  const valuesModifier = yAxisLockInfo || getPowerOrderOfMagnitude(largestMeasurement)

  const graphMeasurements = _rawMeasurements.map(m =>
    m.map(({ value, ...measurement }: $TSFixMe) => ({
      ...measurement,
      value: value / valuesModifier.decimal,
      unit: valuesModifier.unit,
    }))
  )

  const graphAltmeasurements = _rawAltmeasurements.map(m =>
    m.map(({ value, ...measurement }: $TSFixMe) => ({
      ...measurement,
      value: value / valuesModifier.decimal,
      unit: valuesModifier.unit,
    }))
  )

  return {
    graphMeasurements,
    graphAltmeasurements,
    graphUnitDecimal: valuesModifier.decimal,
    graphUnitLabel: valuesModifier.unit,
  }
}
type OwnPowerProps = {
  app: $TSFixMe
  analysis: $TSFixMe
  customer: CurrentCustomer
  params: {
    id?: string
  }
  measurements: $TSFixMe
  altMeasurements: $TSFixMe
  i18n: $I18FixMe
  selectedDateRange: DateRange
  onTravelInTime: $TSFixMeFunction
  onComponentSelect: $TSFixMeFunction
  onTogglePreviousPeriod: $TSFixMeFunction
  previousPeriodEnabled?: boolean
  selectedAssets: string[][] | string[]
  selectedComponents: string[]
  pending: boolean
  error: boolean
  isDashboard: boolean
  linkToDashboard?: string
  zoomIn: $TSFixMeFunction
  zoomOut: $TSFixMeFunction
  zoomOutEnabled: boolean
  totalConsumption: $TSFixMe[]
  altTotalConsumption: $TSFixMe[]
  totalConsumptionSelected?: boolean
  onToggleShowMean: $TSFixMeFunction
  showMean?: boolean
  zoomInEnabled?: boolean
}

type PowerState = $TSFixMe
type PowerProps = OwnPowerProps & typeof Power.defaultProps

class Power extends Component<PowerProps, PowerState> {
  static defaultProps = {
    linkToDashboard: '',
    totalConsumptionSelected: false,
    previousPeriodEnabled: false,
    showMean: false,
    zoomInEnabled: true,
  }
  chartContainer: $TSFixMe

  constructor(props: PowerProps) {
    super(props)
    this.state = {
      containerWidth: window.innerWidth - 370,
      yAxisLock: null,
      graphMeasurements: [],
      graphAltmeasurements: [],
      graphUnitDecimal: 1,
      graphUnitLabel: 'W',
    }
    this.onTriggerResize = this.onTriggerResize.bind(this)
    this.setYAxisLock = this.setYAxisLock.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.onTriggerResize)
    if (this.chartContainer) {
      this.onTriggerResize()
    }
  }

  componentDidUpdate() {
    const { measurements, altMeasurements } = this.props
    if (measurements || altMeasurements) {
      this.onTriggerResize()
    }
  }

  static getDerivedStateFromProps(props: $TSFixMe, state: $TSFixMe) {
    const {
      analysis,
      measurements,
      altMeasurements,
      totalConsumption,
      altTotalConsumption,
      totalConsumptionSelected,
      selectedComponents,
      selectedAssets,
      pending,
    } = props
    const { graphUnitDecimal: unitDecimal, graphUnitLabel: unitLabel, yAxisLock } = state
    const yAxisLockInfo =
      yAxisLock != null
        ? {
          decimal: unitDecimal,
          unit: unitLabel,
        }
        : undefined
    const allAssets = analysis.get('assets').toJS()
    const { graphMeasurements, graphAltmeasurements, graphUnitDecimal, graphUnitLabel } = prepareGraphMeasurements(
      pending,
      allAssets,
      selectedAssets,
      selectedComponents,
      measurements,
      altMeasurements,
      totalConsumptionSelected,
      totalConsumption,
      altTotalConsumption,
      yAxisLockInfo
    )
    return {
      graphMeasurements,
      graphAltmeasurements,
      graphUnitDecimal,
      graphUnitLabel,
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onTriggerResize)
  }

  onTriggerResize() {
    const { isDashboard } = this.props
    const { containerWidth: currentWidth } = this.state
    let containerWidth: $TSFixMe
    if (this.chartContainer) {
      containerWidth = this.chartContainer.offsetWidth - 10
    } else if (isDashboard) {
      containerWidth = window.innerWidth - 50
    } else {
      containerWidth = window.innerWidth - 360
    }
    if (currentWidth !== containerWidth) {
      this.setState(() => ({
        containerWidth,
      }))
    }
  }

  setYAxisLock(yAxisLock: $TSFixMe) {
    this.setState(() => ({
      yAxisLock,
    }))
  }

  render() {
    const {
      analysis,
      customer,
      i18n,
      measurements,
      selectedDateRange,
      selectedComponents,
      selectedAssets,
      pending,
      error,
      onComponentSelect,
      isDashboard,
      onTogglePreviousPeriod,
      onToggleShowMean,
      onTravelInTime,
      previousPeriodEnabled,
      zoomIn,
      zoomInEnabled,
      zoomOut,
      zoomOutEnabled,
      showMean,
    } = this.props
    const { containerWidth, yAxisLock, graphMeasurements, graphAltmeasurements } = this.state
    const allAssets = (analysis as $TSFixMe).get('assets').toJS()
    const multipleMachinesSelected = flatten(selectedAssets).length > 1
    const deltaDuration =
      selectedDateRange.startDate === selectedDateRange.endDate
        ? selectedDateRange.endDate.endOf('day').diff(selectedDateRange.startDate.startOf('day'))
        : selectedDateRange.endDate.diff(selectedDateRange.startDate)
    const ticks = getTickSize(deltaDuration.toMillis())
    // Find the first filled selected asset collection/family
    // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
    const firstFilledCategory = selectedAssets.find((collection: $TSFixMe) => !isEmpty(collection))
    // When there is an asset selected in a category; look up the first asset of that family and return it
    const firstSelectedAsset = firstFilledCategory ? measurements[firstFilledCategory[0]] : null
    const stateModels = prepareStateData({
      assets: allAssets,
      selectedAssets,
      selectedComponents,
    })
    const graphHeight = isDashboard ? global.window.innerHeight - 150 : global.window.innerHeight * 0.72
    if (error) {
      return (
        <LocalWrapper>
          <MainContainer
            isDashboard={isDashboard}
            style={{ textAlign: 'center', flexBasis: '100%', width: '100%' }}
          >
            <GenericError message={error.toString()} />
          </MainContainer>
        </LocalWrapper>
      )
    }
    const altData = graphAltmeasurements && flatten(graphAltmeasurements).length > 0 ? graphAltmeasurements : [[]]
    return (
      <LocalWrapper>
        <MainContainer
          ref={el => {
            this.chartContainer = el
          }}
          isDashboard={isDashboard}
        >
          {
            /* eslint-disable no-nested-ternary */
            measurements && flatten(graphMeasurements).length > 0 ? (
              <ChartContainer id="LineChartContainer">
                <LineChart
                  altData={altData}
                  data={graphMeasurements}
                  height={graphHeight}
                  i18n={i18n}
                  timeZone={customer.timeZone}
                  locale={(i18n as $TSFixMe).locale}
                  models={stateModels}
                  onTogglePreviousPeriod={onTogglePreviousPeriod}
                  onToggleShowMean={onToggleShowMean}
                  onTravelInTime={onTravelInTime}
                  pending={pending}
                  previousPeriodEnabled={previousPeriodEnabled}
                  yAxisLock={yAxisLock}
                  setYAxisLock={this.setYAxisLock}
                  showLegend
                  showMean={showMean}
                  ticks={ticks}
                  timeTravelDisabled={isDateOutsideOfRange(selectedDateRange.endDate)}
                  width={containerWidth}
                  zoomEnabled
                  zoomIn={zoomIn}
                  zoomOut={zoomOut}
                  zoomInEnabled={zoomInEnabled}
                  zoomOutEnabled={zoomOutEnabled}
                />
              </ChartContainer>
            ) : pending ? (
              <LoaderWrapper height={graphHeight}>
                <Loader />
              </LoaderWrapper>
            ) : (
              <CenterMessage>
                <h2>{i18n.text('analysis.energy-balance.no-data')}</h2>
                <br />
                <p>{i18n.text('analysis.energy-balance.no-data.description')}</p>
              </CenterMessage>
            )
          }
          {firstSelectedAsset && firstSelectedAsset.assets != null && firstSelectedAsset.assets.length !== 0 && (
            <ComponentListContainer isDashboard={isDashboard}>
              <SelectComponent>
                {!multipleMachinesSelected
                  ? i18n.text('graph.measurements.components.select')
                  : i18n.text('graph.measurements.components.select.not-allowed')}
              </SelectComponent>
              <ComponentList>
                {firstSelectedAsset.assets.map((component: $TSFixMe) => (
                  <ComponentButton
                    key={component.id}
                    color={(getAssetById(allAssets, component.id) as $TSFixMe).color}
                    onClick={onComponentSelect(component)}
                    selected={selectedComponents.includes(component.id)}
                    disabled={multipleMachinesSelected}
                  >
                    {component.name}
                  </ComponentButton>
                ))}
              </ComponentList>
            </ComponentListContainer>
          )}
        </MainContainer>
      </LocalWrapper>
    )
  }
}

const mapStateToProps = ({ water }: LocalStoreState) => ({
  analysis: water,
})
export default connect(mapStateToProps, null)(Power)
