import React, { FC } from 'react'
import { Interval } from 'luxon'

import SimpleCsvExporter, {
  CsvDataMapper,
  CsvHeaderMapper,
  CsvHeaders,
} from '../../../Shared/components/MUIComponents/ChartTools/CsvExporter'

interface IBalanceData {
  name: string
  isPrevious: boolean
  data: number[]
}

interface CABalanceCsvExporterProps {
  data: ApexAxisChartSeries
  interval: Interval
  previousInterval?: Interval
  filename?: string
  unit?: string
  decimal: number
}

const CABalanceCsvExporter: FC<CABalanceCsvExporterProps> = ({
  data,
  interval,
  unit: unitProp,
  filename,
  decimal,
  previousInterval,
}) => {
  const unit = unitProp || 'Wh'
  const headers: CsvHeaders = ['from', 'to', 'assetName', 'value', 'unit']

  const mapHeaders: CsvHeaderMapper = (headers, { valueSeparator }) => {
    return headers.join(valueSeparator)
  }

  const mapDataToHeaders: CsvDataMapper<IBalanceData[]> = (data, { valueSeparator, decimalSeparator }) => {
    const result = []
    for (const series of data) {
      const value = (series.data[0] as number) || 0
      const formattedValue = `${value.toFixed(decimal)}`.replace('.', decimalSeparator)
      const formattedStartDate = !series.isPrevious
        ? interval.start.toISO({ includeOffset: false })
        : previousInterval?.start.toISO({ includeOffset: false })
      const formattedEndDate = !series.isPrevious
        ? interval.end.toISO({ includeOffset: false })
        : previousInterval?.end.toISO({ includeOffset: false })
      const name = series.name
      const row = `${formattedStartDate}${valueSeparator}${formattedEndDate}${valueSeparator}${name}${valueSeparator}${formattedValue}${valueSeparator}${unit}`
      result.push(row)
    }
    return result.join('\n')
  }

  return (
    <SimpleCsvExporter
      headers={headers}
      mapHeaders={mapHeaders}
      data={data}
      mapDataToHeaders={mapDataToHeaders}
      interval={interval}
      filename={filename}
      slotProps={{
        button: {
          sx: {
            background: 'none',
            padding: 0,
            '&:hover': {
              background: 'none',
            },
          },
        },
      }}
    />
  )
}

export { CABalanceCsvExporter }
