import React, { FC } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'

import MuiSecondaryNav, {
  LinkInfo,
  SecondaryNavLayout,
} from '../../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'
import { CurrencyAndPrice } from './CurrencyAndPrice'
import { LanguageAndRegion } from './LanguageAndRegion'
import { Privacy } from './Privacy'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

type NavRoute = {
  key: string
  path: string
  component: React.ReactNode
}

export const GeneralSettings: FC = () => {
  const { i18n } = useI18nContext()
  const { path } = useRouteMatch()

  const secondaryLinks: LinkInfo[] = [
    {
      name: 'Currency and price',
      i18n: i18n.text('general-settings.currency-price.title'),
      path: `${path}/price`,
    },
    {
      name: 'Language and region',
      i18n: i18n.text('general-settings.language-region.title'),
      path: `${path}/region`,
    },
    {
      name: 'Privacy',
      i18n: i18n.text('general-settings.privacy.title'),
      path: `${path}/privacy`,
    },
  ]

  const navRoutes = (): NavRoute[] => [
    {
      key: 'price',
      path: `${path}/price`,
      component: <CurrencyAndPrice />,
    },
    {
      key: 'region',
      path: `${path}/region`,
      component: <LanguageAndRegion />,
    },
    {
      key: 'privacy',
      path: `${path}/privacy`,
      component: <Privacy />,
    },
  ]

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: '2.6rem', paddingTop: '1.25rem' }}>
      <MuiSecondaryNav
        layout={SecondaryNavLayout.Vertical}
        links={secondaryLinks}
      />
      <Switch>
        {navRoutes().map(route => (
          <Route
            key={route.key}
            exact
            path={route.path}
            render={() => route.component}
          />
        ))}
        <Redirect to={`${path}/price`} />
      </Switch>
    </Box>
  )
}

export const GridBoxRow = styled(Box)(({ theme }) => ({
  display: 'grid',
  alignItems: 'flex-start',
  gridTemplateColumns: '17.5rem 1fr',
  gap: '2rem',
  padding: '1.25rem 0',
  borderBottom: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  '&:last-of-type': {
    borderBottom: 'none',
  },
  '&:first-of-type': {
    paddingTop: 0,
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[700],
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1.25rem',
}))

export const ExplanationText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
}))
