import { Box, styled, useTheme } from '@mui/material'
import { FC } from 'react'

import { Insight } from '../../../components/EnergyOverview.tsx/v1/InsightBox'
import { RegularLightXLText, SemiboldDarkMdDisplayText } from '../../../components/Common/v1/index'
import { RoundBlueIconBox } from '../../../components/Common/v1/StyledComponents'
import { ZapFastIcon } from '../../../../Shared/components/icons'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4rem',
  padding: '6rem 0',
  alignItems: 'center',
  maxWidth: theme.breakpoints.values.xl,
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const InsightsBox = styled(Box)(({ theme }) => ({
  width: '70%',
  display: 'flex',
  flexDirection: 'row',
  gap: '0.375rem',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  rowGap: '3rem',
}))

const IconHeadersBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1.25rem',
  alignSelf: 'stretch',
}))

type InputProps = {
  numSensors: number
  totalConsumption: number
  measuredConsumption: number
  energyPrice: number
  totalEnergyCosts: number
  emissionFactor: number
}

export const EnergyOverview: FC<InputProps> = ({
  numSensors,
  totalConsumption,
  measuredConsumption,
  energyPrice,
  totalEnergyCosts,
  emissionFactor,
}) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()

  const insights = [
    //TODO numbers from JSON
    //TODO decide which of the 6 insights to show based on JSON
    {
      metric: numSensors,
      unitText: i18n.text(`scan.insights.energyoverview.data1.unit`),
      titleText: i18n.text(`scan.insights.energyoverview.data1.title`),
    },
    {
      metric: totalConsumption,
      unitText: i18n.text(`scan.insights.energyoverview.data2.unit`),
      titleText: i18n.text(`scan.insights.energyoverview.data2.title`),
    },
    {
      metric: measuredConsumption,
      unitText: i18n.text(`scan.insights.energyoverview.data3.unit`),
      titleText: i18n.text(`scan.insights.energyoverview.data3.title`),
    },
    {
      metric: energyPrice,
      unitText: i18n.text(`scan.insights.energyoverview.data4.unit`),
      titleText: i18n.text(`scan.insights.energyoverview.data4.title`),
    },
    {
      metric: totalEnergyCosts,
      unitText: i18n.text(`scan.insights.energyoverview.data5.unit`),
      titleText: i18n.text(`scan.insights.energyoverview.data5.title`),
    },
    {
      metric: emissionFactor,
      unitText: i18n.text(`scan.insights.energyoverview.data6.unit`),
      titleText: i18n.text(`scan.insights.energyoverview.data6.title`),
    },
  ]

  return (
    <ContentBox>
      <IconHeadersBox>
        <RoundBlueIconBox>
          <ZapFastIcon sx={{ color: theme.palette.SFIBrand[900] }} />
        </RoundBlueIconBox>
        <SemiboldDarkMdDisplayText>{i18n.text(`scan.insights.energyoverview.title`)}</SemiboldDarkMdDisplayText>
        <RegularLightXLText>{i18n.text(`scan.insights.energyoverview.subtitle`)}</RegularLightXLText>
      </IconHeadersBox>
      <InsightsBox>
        {insights.map((insight, index) => (
          <Insight
            key={index}
            metric={insight.metric}
            unitText={insight.unitText}
            titleText={insight.titleText}
          />
        ))}
      </InsightsBox>
    </ContentBox>
  )
}
