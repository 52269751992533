import { DateTime, DateTimeFormatOptions } from 'luxon'
import { path, uniq } from 'ramda'

import { AlertMessage } from './types'
import type { SupportedLocale } from '../../../Shared/utils/supportedLocales'
import { VibrationThresholdAlertEvent } from '../../../Shared/graphql/codegen'
import { YEAR_MONTH_ABBR_DAY_FORMAT } from './constants'
import { isoLocales } from '../../../Shared/utils'

export function groupMessagesPerDay(timezone: string, messages: AlertMessage[]) {
  const dateFormat = 'YYYYMMDD'

  const sortedMessages = messages.sort((a, b) => (new Date(a.time).valueOf() < new Date(b.time).valueOf() ? 1 : -1))

  const daysInCollection = uniq(
    sortedMessages.map(({ time }) => DateTime.fromISO(time).setZone(timezone).toFormat(dateFormat))
  )

  return daysInCollection.map(day =>
    sortedMessages.filter(({ time }) => DateTime.fromISO(time).setZone(timezone).toFormat(dateFormat) === day)
  )
}

export function groupMessagesUnderRelativeDayLabel(
  timezone: string,
  locale: SupportedLocale,
  messages: AlertMessage[][]
) {
  const localeFormattingOptions: DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }
  const result: { [key: string]: AlertMessage[] } = {}
  messages.forEach(messagesPerDay => {
    const [first] = messagesPerDay
    const day = DateTime.fromISO(first.time).setZone(timezone)
    const yesterday = DateTime.now().setZone(timezone).minus({ days: 1 }).startOf('day')
    const isBeforeYesterday = day < yesterday

    let dayString = day.toRelativeCalendar() || ''
    const isoLocale = isoLocales[locale]
    if (isBeforeYesterday) dayString = day.setLocale(isoLocale).toLocaleString(localeFormattingOptions)

    result[dayString] = [...messagesPerDay]
  })

  return result
}

interface GroupedEvents {
  [key: string]: VibrationThresholdAlertEvent[]
}

export function groupThresholdEventsUnderRelativeDayLabel(
  timezone: string,
  events: VibrationThresholdAlertEvent[]
): { [key: string]: VibrationThresholdAlertEvent[] } {
  if (!events) {
    return {}
  }

  const sortedEvents = [...events].sort((a, b) =>
    new Date(a.createdAt).valueOf() < new Date(b.createdAt).valueOf() ? 1 : -1
  )

  const groups: GroupedEvents = {}

  sortedEvents.forEach(event => {
    const createdAt = DateTime.fromISO(event.createdAt).setZone(timezone)
    const relativeDayLabel = createdAt.toFormat(YEAR_MONTH_ABBR_DAY_FORMAT)

    if (!groups[relativeDayLabel]) {
      groups[relativeDayLabel] = []
    }

    groups[relativeDayLabel].push(event)
  })

  return groups
}
/**
 * Groups an array of VibrationThresholdAlertEvent objects by asset name.
 * @param events An array of VibrationThresholdAlertEvent objects to group.
 * @returns An object with asset names as keys and arrays of corresponding events as values.
 */
export function groupThresholdEventsPerAsset(events: VibrationThresholdAlertEvent[]): {
  [key: string]: VibrationThresholdAlertEvent[]
} {
  if (!events) return {}
  const result: { [key: string]: VibrationThresholdAlertEvent[] } = {}
  events.forEach(event => {
    event.config.conditions.forEach(condition => {
      const assetName = condition.sensorLocation.asset.name
      if (!result[assetName]) result[assetName] = []
      result[assetName].push(event)
    })
  })
  return result
}

export function getTableCols(timezone: string, messages: $TSFixMe[], i18n: $TSFixMe) {
  const baseCols = [
    {
      label: i18n.text('messages.headers.assets'),
      key: 'assets',
    },
    {
      label: i18n.text('messages.headers.message'),
      key: 'message',
    },

    // Empty for space
    { label: '', key: 'empty' },
  ]

  const messageTime = DateTime.fromISO(path([0, 'time'], messages) as string).setZone(timezone)
  const today = DateTime.now().setZone(timezone)
  let datePrefix = ''

  if (messageTime.hasSame(today, 'day')) {
    datePrefix = `${i18n.text('messages.headers.relative.today')} - `
  } else if (messageTime.hasSame(today.minus({ days: 1 }), 'day')) {
    datePrefix = `${i18n.text('messages.headers.relative.yesterday')} - `
  }

  return [
    {
      key: 'date',
      label: `${datePrefix}${messageTime.toFormat(YEAR_MONTH_ABBR_DAY_FORMAT)}`,
    },
    ...baseCols,
  ]
}
