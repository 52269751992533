import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  styled as muiStyled,
  useTheme,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'

import useAnalytics from '../../../../Shared/hooks/useAnalytics/useAnalytics'
import { DownloadMimeTypes, DownloadPageParams, DownloadType } from '../../DownloadPage/types'
import { ExportIcon, PdfIcon } from '../../../../Shared/components/icons'
import { ReportDocument } from '../types'
import { StyledPrimaryButton } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { StyledTooltip } from '../../../../Shared/components/MUIComponents/Tooltip'
import { isSensorfactEmail } from '../../../../Shared/utils'
import { trackStartNewReportEvent } from '../../EnergyReports/ReportSettings/trackingEvents'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'

const Container = muiStyled(Box)(({ theme }) => ({
  height: '100%',
  maxWidth: '550px',
  border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  borderRadius: '0.75rem',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
}))

const ReportEntryLink = muiStyled('a')(({ theme }) => ({
  padding: '0 1.25rem ',
  color: theme.palette.primary.main,
  textDecoration: 'none',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  '&:visited': {
    color: theme.palette.SFIGreyLight[800],
  },
}))

const Header = muiStyled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.125rem',
  lineHeight: '1.75rem',
  color: theme.palette.SFIGreyLight[800],
}))

const Description = muiStyled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  color: theme.palette.SFIGreyLight[600],
}))

const HeaderBox = muiStyled(Box)(({ theme }) => ({
  padding: '1.25rem 1.5rem 1rem 1.5rem',
}))

const newReportUrl = `/reports/configuration/tracking/new/general`

type OwnProps = {
  i18n: $I18FixMe
  heading: string
  description?: string
  documents: ReportDocument[]
  preview?: boolean
}

const RenderPreview = (i18n: $I18FixMe) => {
  const history = useHistory()
  const { sendEvent } = useAnalytics()
  const handleNewReportClick = () => {
    trackStartNewReportEvent('no_reports_new_report_button', sendEvent)
    history.push(newReportUrl)
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1.25rem ', alignItems: 'center' }}>
      <Typography
        sx={{
          marginBottom: '1.5rem',
          color: theme => theme.palette.SFIGreyLight[600],
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: '1.25rem',
          textAlign: 'center',
        }}
      >
        {i18n.text('reports.tracking.documents.noTrackingReportsSetUp')}
      </Typography>
      <StyledPrimaryButton
        sx={{ textTransform: 'none', width: 'fit-content', height: 'fit-content' }}
        onClick={handleNewReportClick}
      >
        {i18n.text(`reports.tracking.configuration.reports.newReport`)}
      </StyledPrimaryButton>
    </Box>
  )
}

const DocumentGroup = ({ heading, documents, i18n, description = '', preview }: OwnProps) => {
  const user = useCurrentUser()
  const theme = useTheme()
  const createDownloadUrl = (doc: ReportDocument) => {
    const url = '/download'
    const params: DownloadPageParams = {
      gaEvent: DownloadType.report,
      file: btoa(doc.s3Url),
      mimeType: DownloadMimeTypes.pdf,
      fileName:
        doc.config?.title === 'ENERGY_REPORT' ||
        doc.config?.title === 'DC_SUMMARY' ||
        doc.config?.title === 'RA_SUMMARY'
          ? doc.s3Url.replace('https://export-energy-scans-prod.s3.eu-west-1.amazonaws.com/', '')
          : doc.s3Url.replace('https://export-reports.sensorfact.nl/', ''),
      origin: 'webapp',
      userId: user.userId,
      internalUser: isSensorfactEmail(user.user.email),
      customerId: user.customerId,
      reportType:
        doc.config?.id === '__insights__'
          ? 'insights'
          : doc.config?.title === 'ENERGY_REPORT' ||
            doc.config?.title === 'DC_SUMMARY' ||
            doc.config?.title === 'RA_SUMMARY'
          ? (doc.config.title as DownloadPageParams['reportType'])
          : 'tracking',
      reportId: doc.config?.id || '__insights__',
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return `${url}?${Object.keys(params).reduce((str, key) => (str += `${key}=${(params as any)[key]}&`), '')}`
  }

  const RenderDocuments = (documents: ReportDocument[]) => (
    <List dense>
      {documents.map((doc, index) => {
        const downloadUrl = createDownloadUrl(doc)
        const key = doc.configId ? doc.configId.concat(doc.from.toString()) : doc.config.id.concat(doc.from.toString())

        return (
          <Box
            key={key}
            sx={{
              background: theme => (index % 2 === 0 ? theme.palette.SFIGreyLight[50] : theme.palette.SFIBase.white),
              borderTop: theme => `1px solid ${theme.palette.SFIGreyLight[200]}}`,
            }}
          >
            <Link
              key={key}
              to={downloadUrl}
              component={ReportEntryLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ width: '100%' }}
            >
              <ListItem
                key={doc.from.toString()}
                disableGutters
              >
                <ListItemIcon>
                  <PdfIcon />
                </ListItemIcon>

                <ListItemText
                  primary={doc.displayInfo.name}
                  secondary={doc.displayInfo.date}
                />
                <StyledTooltip title={i18n.text(`reports.documents.download.tooltip`)}>
                  <ExportIcon />
                </StyledTooltip>
              </ListItem>
            </Link>
          </Box>
        )
      })}
    </List>
  )
  return (
    <Container>
      <HeaderBox>
        <Header>{heading}</Header>
        <Description>{description}</Description>
      </HeaderBox>

      {preview ? RenderPreview(i18n) : RenderDocuments(documents)}
    </Container>
  )
}

export default DocumentGroup
