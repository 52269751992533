import React, { FC } from 'react'
import { Add, CalendarMonth } from '@mui/icons-material'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

import { ParsedSchedule } from './types'

type ProductionScheduleListProps = {
  schedules: ParsedSchedule[]
  selectedSchedule: ParsedSchedule | null
  onSelect: (schedule: ParsedSchedule) => void
  onNew: () => void
}
const ProductionSchedulesList: FC<ProductionScheduleListProps> = ({ schedules, selectedSchedule, onSelect, onNew }) => {
  return (
    <Box>
      <List>
        <ListItem>
          <ListItemButton onClick={onNew}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="New production schedule" />
          </ListItemButton>
        </ListItem>
        {schedules.map(s => (
          <ListItem key={s.id}>
            <ListItemButton selected={selectedSchedule?.id === s.id}>
              <ListItemIcon>
                <CalendarMonth />
              </ListItemIcon>
              <ListItemText
                primary={s.name}
                onClick={() => onSelect(s)}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export { ProductionSchedulesList }
