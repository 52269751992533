import { createSvgIcon } from '@mui/material'

export const MinusIcon = createSvgIcon(
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33325 8H12.6666"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'minus-icon'
)
