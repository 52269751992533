import { useState } from "react";
import { Popover } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
interface MUIPopoverProps {
  children?: React.ReactNode;
}

export default function MUIPopover({ children }: MUIPopoverProps): JSX.Element {

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorEl(e.target as HTMLButtonElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <InfoOutlinedIcon fontSize='large' onClick={(e)=>handleClick(e)}/>
      <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
      >
        {children}
      </Popover>
    </>
  )
}
