import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { FC } from 'react'

import StopOverviewTableRow from './StopOverviewTableRow'
import { AssetStop } from '../../types/Assets.types'
import { StyledTableHeadCell } from '../StyledTable'
import { getStopsOverviewTableHead } from '../../utils/tableHeads'
import { sortProductionStopsByStartTime } from '../../utils/dataTransformations'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type StopsOverviewTableProps = {
  stops: AssetStop[]
}

const StopsOverviewTable: FC<StopsOverviewTableProps> = ({ stops }) => {
  const { i18n } = useI18nContext()
  const { selectedStopReasons } = useOEEContext()

  const tableHead = getStopsOverviewTableHead()

  const filteredStops =
    selectedStopReasons.length > 0 ? stops.filter(stop => selectedStopReasons.includes(stop.reason)) : stops
  const sortedAssetsStops = sortProductionStopsByStartTime(filteredStops)

  return (
    <TableContainer
      component={Box}
      sx={{ overflowX: 'auto' }}
    >
      <Table
        stickyHeader
        aria-label={i18n.text('oee.stop-analysis.stops-overview.title')}
      >
        <TableHead>
          <TableRow>
            {tableHead.map(headCell => (
              <StyledTableHeadCell
                key={headCell.id}
                align={headCell.align}
              >
                {i18n.text(headCell.label)}
              </StyledTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAssetsStops.map(stop => (
            <StopOverviewTableRow
              key={stop.id}
              stop={stop}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default StopsOverviewTable
