import { Box, useTheme } from '@mui/material'
import { FC } from 'react'

const GraphContainer: FC = ({ children }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'grid',
        gridRowGap: theme.spacing(2),
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </Box>
  )
}

export default GraphContainer
