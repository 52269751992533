import { FormSubmitData } from '../types'
import { HourOfDay, MinuteOfHour } from '../../../../Shared/types/types'
import { transformFromDate } from './productionSchedulesDateTransform'

export function formatProductionTimes(formProductionTimes: FormSubmitData['productionTimes']) {
  return formProductionTimes.map(time => {
    const { fromDay, toDay } = time
    // might not be valid dates so we safeguard with min = 0 hour = 0

    const { hour: fromHour, minute: fromMinute } =
      !time.fromMinuteHourDate || isNaN(time.fromMinuteHourDate as any)
        ? { hour: 0 as HourOfDay, minute: 0 as MinuteOfHour }
        : transformFromDate(time.fromMinuteHourDate!)

    const { hour: toHour, minute: toMinute } =
      !time.toMinuteHourDate || isNaN(time.toMinuteHourDate as any)
        ? { hour: 0 as HourOfDay, minute: 0 as MinuteOfHour }
        : transformFromDate(time.toMinuteHourDate!)

    return { fromMinute, fromHour, fromDay, toMinute, toHour, toDay }
  })
}
