import { equals } from 'ramda'
import { useRef, useState } from 'react'

export type LoaderError = {
  message: string
}

const useIncrementalDataLoader = <Data, Params>(props: {
  initialState: {
    ids: string[]
    params: Params
    refetchAll?: boolean
  }
  /**
   * The function that will be used to fetch Entity time series data in batches by Entity id.
   * The function must either successfully fetch and return the time series data for all
   * ids passed in for the specified interval, or throw an error when it failed to do so.
   * @param {string[]} ids - The list of Entity ids that time series data should be fetched for.
   * @param {Params} params - The parameters that should be used when fetching a batch of data.
   */
  entityBatchFetcher: (ids: string[], params: Params) => Promise<{ id: string; data: Data }[]>
  /**
   * An optional function that will be used to check if the parameters changed.
   * If not provided, the default ramda.equals function will be used.
   */
  paramsEqualChecker?: (a: Params, b: Params) => boolean
}) => {
  type FetchVariables = typeof props.initialState

  const { initialState: initialStateProps, entityBatchFetcher } = props

  const [initialState] = useState<FetchVariables>(initialStateProps)
  const [error, setError] = useState<LoaderError | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selection, setSelection] = useState(initialState.ids)
  const [fetchParameters, setFetchParams] = useState(initialState.params)

  const dataMapRef = useRef(new Map<string, Data>())
  const cacheRef = useRef(new Map<string, Data>())
  const loadingRef = useRef<boolean>(false)
  const queuedRef = useRef<FetchVariables | null>(null)
  const triggeredInitialFetchRef = useRef<boolean>(false)

  // use provided params equality checker function or default to ramda.equals
  const checkParamsEqual = props.paramsEqualChecker ?? equals

  const updateSelection = (ids: string[]) => {
    if (queuedRef.current) {
      if (sameMembers(queuedRef.current.ids, ids)) {
        return
      }
    } else if (sameMembers(selection, ids)) {
      return
    }

    setSelection(ids)
    fetchEntityBatch({ ids, params: fetchParameters }, loadingRef, queuedRef)
  }

  const updateParams = (value: Params) => {
    if (checkParamsEqual(fetchParameters, value)) {
      return
    }

    setFetchParams(value)
    fetchEntityBatch({ ids: selection, params: value, refetchAll: true }, loadingRef, queuedRef)
  }

  const fetchEntityBatch = async (
    fetchVariables: FetchVariables,
    loadingRef: React.MutableRefObject<boolean | null>,
    queuedRef: React.MutableRefObject<FetchVariables | null>,
    clearQueue = false
  ) => {
    if (loadingRef.current && !clearQueue) {
      queuedRef.current = fetchVariables
      return
    }

    const ids = [...fetchVariables.ids]
    const { params, refetchAll } = fetchVariables

    if (loadingRef.current && clearQueue) {
      queuedRef.current = null
    }

    if (refetchAll) {
      cacheRef.current.clear()
    }

    const newIds = ids.filter(id => !Array.from(cacheRef.current.keys()).includes(id))

    if (newIds.length === 0) {
      updateDataMap(ids, dataMapRef, cacheRef)
      return
    }

    loadingRef.current = true
    setIsLoading(true)
    setError(null)

    try {
      const result = await entityBatchFetcher(newIds, params)

      if (queuedRef.current) {
        return
      }

      // update cache
      result.forEach(entity => {
        cacheRef.current.set(entity.id, entity.data)
      })

      updateDataMap(ids, dataMapRef, cacheRef)
    } catch (e) {
      setError({ message: (e as Error).message })
    } finally {
      if (queuedRef.current) {
        fetchEntityBatch(queuedRef.current, loadingRef, queuedRef, true)
      } else {
        loadingRef.current = false
        setIsLoading(false)

        /**
         * Since we useRefs for some internal state which does not trigger a
         * re-render when they change, we need to trigger a re-render ourselves
         * to make sure that the props the hook exposes are up-to-date.
         */
        setSelection(current => [...current])
      }
    }
  }

  // refectch all selected id's
  const refetch = async (params: FetchVariables) => {
    fetchEntityBatch(params, loadingRef, queuedRef)
  }

  if (initialState.ids.length > 0 && !triggeredInitialFetchRef.current) {
    triggeredInitialFetchRef.current = true
    refetch(initialState)
  }

  return {
    updateSelection,
    updateParams,
    loading: loadingRef.current || isLoading,
    refetch,
    error,
    dataMap: error ? null : dataMapRef.current,
  }
}

const updateDataMap = <T>(
  ids: string[],
  dataMapRef: React.MutableRefObject<Map<string, T>>,
  cacheRef: React.MutableRefObject<Map<string, T>>
) => {
  dataMapRef.current.clear()

  const _dataMap = new Map<string, T>()

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i]
    const entity = cacheRef.current.get(id)

    if (!entity) {
      throw new Error('entity cache error.')
    }

    _dataMap.set(id, entity)
  }

  dataMapRef.current = _dataMap
}

const containsAll = (arr1: string[], arr2: string[]) => arr2.every(arr2Item => arr1.includes(arr2Item))
const sameMembers = (arr1: string[], arr2: string[]) => containsAll(arr1, arr2) && containsAll(arr2, arr1)

export default useIncrementalDataLoader
