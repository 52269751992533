import * as z from 'zod'
import { EmailRecipientSchema, WhatsappRecipientSchema, Recipient } from "../../../components/Recipient/types";

export const StandbyAlertRecipientsSchema = z.array(
  z.union([EmailRecipientSchema, WhatsappRecipientSchema])
)

export interface StandbyAlert {
  id: string
  enabled: boolean
  recipients: Recipient[]
  resolvedEnabled: boolean
  assetId: string
  alertAfterMinutes: number
  silenceHours: number
  message: string
}

export interface StandbyAlertWithAsset extends StandbyAlert {
  asset: {
    id: string
    name: string
  }
}
