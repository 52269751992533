import { Autocomplete, Box, TextField, Theme, Typography } from '@mui/material'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'

import { CustomerUsersQuery } from '../../../../../../Shared/graphql/codegen'
import { FormInputs } from '../EditAlertDialog'
import { isSensorfactEmail } from '../../../../../../Shared/utils'
import { useI18nContext } from '../../../../../../Shared/contexts/i18nContext/I18nContext'

type RecipientsProps = {
  theme: Theme
  errors: FieldErrorsImpl<FormInputs>
  data: CustomerUsersQuery
  control: Control<FormInputs>
}
export default function RecipientsInput({ data, theme, control, errors }: RecipientsProps) {
  const { i18n } = useI18nContext()
  const reciepients = data.myOrg?.users
  const filteredReciepients = reciepients?.flatMap(user => user.user).filter(user => !isSensorfactEmail(user.email))

  return (
    <Box
      sx={{
        paddingBottom: theme.spacing(2),
        margin: theme.spacing(2),
      }}
    >
      <Box>
        <Controller
          name="reciepients"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ...rest } }) => {
            const reciepientEmails = value?.flatMap(reciepient => reciepient.destination)
            return (
              <Autocomplete
                {...rest}
                id="email-tabs"
                multiple
                onKeyDown={e => {
                  e.preventDefault()
                }}
                value={reciepientEmails ? [...reciepientEmails] : []}
                options={(filteredReciepients && filteredReciepients?.map(user => user.email)) || []}
                onChange={(event, value, reason) => {
                  const recipients = value.map(recipient => ({
                    name: reciepients?.find(reciepient => reciepient.user.email === recipient)?.user.name,
                    deliveryType: 'email',
                    destination: reciepients?.find(reciepient => reciepient.user.email === recipient)?.user.email,
                  }))
                  onChange(recipients)
                }}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      label={i18n.text('pdm.configure.alert.dialog.recipients.label')}
                      size="medium"
                      placeholder="Emails"
                      onChange={onChange}
                      error={!!errors.reciepients}
                    />
                  )
                }}
              />
            )
          }}
        />
      </Box>
      <Typography
        variant="caption"
        color="error"
        sx={{
          marginLeft: theme.spacing(2),
        }}
      >
        {errors.reciepients && errors.reciepients.message}
      </Typography>
    </Box>
  )
}
