/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useState } from 'react'
import { Noop } from 'react-hook-form'
import { TimePicker, renderTimeViewClock } from '@mui/x-date-pickers'
import { format } from 'date-fns'

interface HourMinutePickerProps {
  date: Date | null
  onChange?: (date: Date | null) => void
  onBlur?: Noop
  inputRef?: React.Ref<HTMLInputElement>
  name?: string
  inputProps?: {
    [key: string]: string | number
  }
  renderClock?: boolean
}

const HourMinutePicker: FC<HourMinutePickerProps> = props => {
  const { date, onChange, onBlur, inputRef, name, renderClock = true } = props
  const [current, setCurrent] = useState<Date | null>(date || new Date())

  const handleChange = (date: Date | null) => {
    setCurrent(date)
    if (onChange) {
      onChange(date)
    }
  }

  useEffect(() => {
    if (date !== undefined) setCurrent(date)
  }, [date])

  return (
    <TimePicker
      {...props}
      ampm={false}
      inputRef={inputRef}
      value={current}
      onChange={handleChange}
      viewRenderers={
        renderClock
          ? {
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }
          : {}
      }
      slotProps={{
        textField: {
          name,
          onBlur,
        },
      }}
    />
  )
}

export default HourMinutePicker

/**
 * Converts a date to MUI TimePicker / HourMinutePicker "HH:mm" 24-hour string format.
 * Can be used to set date in the input as a string or to check the displayed string in the DOM is the correct hour / minute value
 * @returns a string of a date's hour and minute values. Examples: "23:45", "12:00"
 */
export const toTimePickerFormat = (date: Date) => {
  return format(date, 'HH:mm').toLowerCase()
}
