import React, { FC, useEffect, useMemo, useState } from 'react'
import { Typography } from '@mui/material'

import Error from '../../../../Shared/components/MUIComponents/Error'
import MUILoader from '../../../../Shared/components/MUIComponents/Loader'
import { AnnouncementBanner } from '../../../../Shared/components/MUIComponents/update/AnnouncementBanner/AnnouncementBanner'
import { CAAssetAccordion } from '../../../components/CompressedAirSettings/CAAssetAccordion'
import { CAAssetAccordionDetails } from '../../../components/CompressedAirSettings/CAAssetAccordionDetails'
import {
  CompressedAirAssetTreeFragment,
  useCompressedAirAssetTreeQuery,
  useGetCompressedAirSystemAndLocationImagesQuery,
  useGetFreeCompressorsQuery,
} from '../../../../Shared/graphql/codegen'
import { IExtendedCompressedAirAsset, ImagesCollectionName } from '../../../../CompressedAir/types/compressed-air_types'
import { LearnMoreAboutEfficiencyModal } from '../../../components/CompressedAirSettings/LearnMoreModal.tsx'
import { browserStorage } from '../../../../Shared/utils'
import { mapCompressedAirSystems } from '../../../utils/compressedAirSettings'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const ANNOUNCEMENT_BANNER_COMPRESSORS_SETTINGS = 'sf-announcement-compressors-settings'
const CONSULTANCY_EMAIL = 'support@sensorfact.nl'

export const CompressedAirSettings: FC = () => {
  const { i18n } = useI18nContext()
  const { customerId } = useCurrentUser()
  const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] = useState(false)
  const [shouldShowAnnouncementBanner, setShoulsShowAnnouncementBanner] = useState(true)
  const { data, loading: isLoading, error: loadAssetTreeError, refetch: refetchData } = useCompressedAirAssetTreeQuery()
  const {
    data: freeCompressorsData,
    loading: gettingFreeCompressors,
    refetch: refetchFreeCompressors,
  } = useGetFreeCompressorsQuery({
    variables: { customerId },
  })

  const announcementBannerTexts = i18n.text('settings.efficiency.announcement-banner').split('$email$')

  const allAssetsOfFilteredGroups = useMemo(() => {
    if (!data?.myOrg?.groups) {
      return []
    }
    return mapCompressedAirSystems(data.myOrg.groups as CompressedAirAssetTreeFragment[])
  }, [data])

  const assetImagesIds = useMemo(() => {
    return allAssetsOfFilteredGroups?.flatMap(asset => asset.images)
  }, [allAssetsOfFilteredGroups])

  const { data: assetImagesData } = useGetCompressedAirSystemAndLocationImagesQuery({
    variables: {
      ids: assetImagesIds ?? [],
      collectionName: ImagesCollectionName.SYSTEM,
    },
  })

  const compressorsOptions = useMemo(() => {
    if (!freeCompressorsData) {
      return []
    }
    return freeCompressorsData.unlinkedAirCompressors.map(compressor => ({
      id: compressor.id,
      label: compressor.name,
    }))
  }, [freeCompressorsData])

  useEffect(() => {
    const wasAnnouncementBannerSeen = browserStorage.get<boolean>(ANNOUNCEMENT_BANNER_COMPRESSORS_SETTINGS).value
    if (wasAnnouncementBannerSeen) {
      setShoulsShowAnnouncementBanner(false)
    }
  }, [])

  const onDismissAnnouncementBanner = () => {
    browserStorage.set(ANNOUNCEMENT_BANNER_COMPRESSORS_SETTINGS, true)
    setShoulsShowAnnouncementBanner(false)
  }

  const onLearnMoreModalClose = () => {
    setIsLearnMoreModalOpen(false)
  }

  const onLearnMoreModalOpen = () => {
    setIsLearnMoreModalOpen(true)
  }

  function getItemImages(item: IExtendedCompressedAirAsset | undefined) {
    if (!item || !assetImagesData?.getSecureMediaUrls) {
      return []
    }
    return assetImagesData?.getSecureMediaUrls?.items.filter(img => item.images.includes(img.id))
  }

  function refetchSystems(refetchCompressors?: boolean) {
    refetchData()
    if (refetchCompressors) {
      refetchFreeCompressors({
        customerId,
      })
    }
  }

  if (loadAssetTreeError) {
    return <Error error={loadAssetTreeError} />
  }

  if (isLoading || gettingFreeCompressors) {
    return <MUILoader />
  }

  return (
    <>
      {shouldShowAnnouncementBanner && (
        <AnnouncementBanner
          onDismiss={onDismissAnnouncementBanner}
          customTextSlot={
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontWeight: '400',
                lineHeight: '1.25rem',
                color: theme => theme.palette.SFIGreyLight[600],
              }}
            >
              {announcementBannerTexts[0]}
              <Typography
                component="span"
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: '400',
                  lineHeight: '1.25rem',
                  color: theme => theme.palette.SFIBrand[700],
                }}
              >
                {CONSULTANCY_EMAIL}
              </Typography>{' '}
              {announcementBannerTexts[1]}
            </Typography>
          }
        />
      )}
      {!isLoading &&
        !gettingFreeCompressors &&
        allAssetsOfFilteredGroups?.map((asset, index) => (
          <CAAssetAccordion
            key={asset.id}
            title={asset.name}
            isDefaultExpanded={index === 0}
          >
            <CAAssetAccordionDetails
              asset={asset}
              images={getItemImages(asset)}
              refetchSystems={refetchSystems}
              compressorsOptions={compressorsOptions}
              openLearnMoreModal={onLearnMoreModalOpen}
            />
          </CAAssetAccordion>
        ))}
      <LearnMoreAboutEfficiencyModal
        isOpen={isLearnMoreModalOpen}
        handleModalClose={onLearnMoreModalClose}
      />
    </>
  )
}
