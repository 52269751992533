import { Auth as Cognito } from 'aws-amplify'

function getPrefix(env: 'production' | 'development') {
  switch (env) {
    case 'production':
      return 'prod'
    case 'development':
      return 'dev'
    default:
      return 'dev'
  }
}

export const getMediaUploadSignatureEndpoint = () => {
  return `${getCloudAPIPath(process.env.REACT_APP_API_ENV)}/auth/sign_media_upload`
}

export function getCloudAPIPath(env: any): string {
  return `https://gateway.${getPrefix(env)}.sensorfact.cloud`
}

export const getCustomCognitoHeaders = async () => {
  let jwtToken = ''
  try {
    const session = await Cognito.currentSession()
    const idToken = session.getIdToken()
    jwtToken = idToken.getJwtToken()
  } catch {
    console.log('No jwt token found')
  }
  return {
    Authorization: `Bearer ${jwtToken}`,
  }
}
