import { MenuItem, styled } from '@mui/material'
export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '1.5rem',
  margin: '0 0.375rem 0.063rem 0.375rem',
  '&:focus': {
    background: theme.palette.SFIGreyLight[100],
    borderRadius: '0.375rem ',
  },
  '&:hover': {
    background: theme.palette.SFIGreyLight[100],
    borderRadius: '0.375rem ',
  },
  '&:selected': {
    background: theme.palette.SFIGreyLight[100],
    borderRadius: '0.375rem ',
  },
})) as typeof MenuItem
