import React, { FC } from 'react'
import { Box, Typography, styled } from '@mui/material'

import { DatePickersRow } from './DatePickersRow'
import { useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'
import { StartEndDatesValues } from './types'

interface ICompareDateFormProps {
  fromToValues: StartEndDatesValues
  comparedFromToValues: StartEndDatesValues
}

const CompareTypography = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '1.25rem',
  margin: theme.spacing(1.5, 0),
}))

export const CompareDatesForm: FC<ICompareDateFormProps> = ({ fromToValues, comparedFromToValues }) => {
  const { i18n } = useI18nContext()
  const { handleStartEndDates, handleCompareStartEndDates } =
    useDateTimeRangePickerContext()
  return (
    <Box>
      <DatePickersRow
        values={fromToValues}
        setValues={handleStartEndDates}
      />
      <CompareTypography>{i18n.text('contract-benchmark.step-1.compare')}</CompareTypography>
      <DatePickersRow
        isCompare
        values={comparedFromToValues}
        setValues={handleCompareStartEndDates}
      />
    </Box>
  )
}
