import { DataPointWithPrevTime, LegacyDataPoint, LegacyDataPointWithPrevTime, OOPMoment } from '../types'
import { NonNegativeFloat } from '../../Shared/types/types'
import { StandbyMoment } from '../../Shared/graphql/codegen'

export function mapMeasurementsToXY(
  measurements: LegacyDataPointWithPrevTime[] | [] | undefined
): [] | DataPointWithPrevTime[] {
  if (!measurements || !measurements.length) return []
  return measurements.map((d: LegacyDataPointWithPrevTime) => ({
    x: d.time,
    y: d.value,
    prevTime: d.prevTime ? d.prevTime : undefined,
  }))
}

export function mapStandbyToXYPower(standbySeries: StandbyMoment[]) {
  if (!standbySeries || !standbySeries.length) return []
  return standbySeries.map((sm: StandbyMoment) => ({ x: sm.time, y: sm.power }))
}

/**
 *
 * Due to changes in the API the power values are now just 1 or null for standby moments, so we fake them for use in the chart,
 * by using the actual power measurements for the same time.
 */
export function mapStandbyToXYFakePower(
  standbySeries: StandbyMoment[] | OOPMoment[],
  powerMeasurements: LegacyDataPoint[]
) {
  if (!standbySeries || !standbySeries.length) return []
  return standbySeries.map((sm: StandbyMoment | OOPMoment) => {
    const powerMeasurement = powerMeasurements.find((pm: LegacyDataPoint) => pm.time === sm.time)
    return { x: sm.time, y: sm.power ? (powerMeasurement ? powerMeasurement.value : (0 as NonNegativeFloat)) : null }
  })
}

export function mapStandbyToXYEnergy(standbySeries: StandbyMoment[] | OOPMoment[] | null | undefined) {
  if (!standbySeries || !standbySeries.length) return []
  return standbySeries.map((sm: StandbyMoment | OOPMoment) => ({ x: sm.time, y: sm.interval_energy }))
}
