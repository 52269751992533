import { Box, TableCell, TableRow, styled } from '@mui/material'

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.SFIGreyLight[50],
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'transparent',
  },
}))

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.SFIGreyLight[700],
  lineHeight: '1.25rem',
  backgroundColor: theme.palette.SFIBase.white,
  borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  borderBottom: 'none',
  padding: `0.75rem`,
  whiteSpace: 'pre-line',
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '1rem',
  fontSize: '0.875rem',
  color: theme.palette.SFIGreyLight[950],
  borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  borderBottom: `none`,
}))

export const StyledTableCellContentWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  alignContent: 'center',
}))

export const StyledTableAccentCell = styled(StyledTableCell)({
  fontWeight: '500',
})
