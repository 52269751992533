import { Box } from '@mui/material'
import { Redirect, Route, Switch } from 'react-router'

import Assets from './Assets'
import ProductionSchedulesPage from './ProductionSchedulesPage'
import SecondaryNav, { OptionalIconLink } from '../../../Shared/components/MUIComponents/SecondaryNav/SecondaryNav'

const Configuration = () => {
  const secondaryLinks: OptionalIconLink[] = [
    {
      name: 'Assets',
      i18n: 'router.multi.settings.configuration.assets',
      path: 'assets',
    },
    {
      name: 'Production Schedules',
      i18n: 'router.multi.settings.configuration.production-schedules',
      path: 'production-schedules',
    },
  ]

  return (
    <Box>
      <SecondaryNav links={secondaryLinks} />
      <Switch>
        <Route
          path="/settings/configuration/production-schedules"
          exact
        >
          <ProductionSchedulesPage />
        </Route>
        <Route
          path="/settings/configuration/assets"
          exact
        >
          <Assets />
        </Route>
        <Redirect to="/settings/configuration/assets" />
      </Switch>
    </Box>
  )
}

export default Configuration
