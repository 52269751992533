import React from 'react'
import { Box, BoxProps, TableCell, TableHead, TableRow, Typography, styled } from '@mui/material'

import { ICustomTableColumn, ItemsAlignment } from '../../../../../types/types'
import { InfoIcon, LongArrowLeftIcon } from '../../../../icons'
import { StyledTooltip } from '../../../Tooltip'
import { TableHeaderProps } from '../../../../../types/customTableTypes'
import { useI18nContext } from '../../../../../contexts/i18nContext/I18nContext'

/**
 * @description - CustomTableHeader is a table header that holds several table's properties
 * @param {ICustomTableColumn<T>} columns - A list of columns
 * @param {string} title - A title of a table. It is placed on top of columns' names
 * @param {string} subtitle - A subtitle of a table. It is placed under the title
 * @param {React.ReactNode} toolsSlot - A slot for filtering, sorting and other stuff required for the table data
 * @param {React.ReactNode} headerButtonSlot - A button slot that is on the right from the title
 * @param {Object} slotProps - Additional props in case a developer wants to add something extra to the table header
 */

interface ICustomTableHeaderProps<T> extends TableHeaderProps {
  columns: ICustomTableColumn<T>[]
}

function CustomTableHeader<T extends { id: string }>({
  columns,
  title,
  subtitle,
  toolsSlot,
  headerButtonSlot,
  slotProps,
}: ICustomTableHeaderProps<T>) {
  const { i18n } = useI18nContext()
  const getColumnAlignment = (column: ICustomTableColumn<T>) =>
    column.align === 'right'
      ? ItemsAlignment.END
      : column.align === 'left'
      ? ItemsAlignment.START
      : ItemsAlignment.CENTER

  return (
    <TableHead {...slotProps?.tableHead}>
      {!!title && (
        <TableRow {...slotProps?.tableTitle}>
          <Title
            align="left"
            colSpan={columns.length}
          >
            <TitleWrapper>
              <Box>
                {title}
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
              </Box>
              {headerButtonSlot}
            </TitleWrapper>
          </Title>
        </TableRow>
      )}
      {!!toolsSlot && (
        <TableRow>
          <TableCell
            colSpan={columns.length}
            {...slotProps?.toolsSlot}
          >
            {toolsSlot}
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        {columns.map(column => (
          <ColumnName
            key={column.id as string}
            align={column.align ?? ItemsAlignment.CENTER}
            style={{ minWidth: column.minWidth, width: column.width }}
            {...slotProps?.tableColumn}
            onClick={column.onSortToggle}
          >
            <InfoLabelWrapper alignment={getColumnAlignment(column)}>
              {i18n.text(column.label)}
              {column.tooltipText && (
                <StyledTooltip title={i18n.text(column.tooltipText)}>
                  <InfoIconWrapper>
                    <InfoIcon />
                  </InfoIconWrapper>
                </StyledTooltip>
              )}
              {column.onSortToggle && (
                <LongArrowLeftIcon
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    transform: column.isSorted ? `rotate(-90deg)` : `rotate(90deg)`,
                  }}
                />
              )}
            </InfoLabelWrapper>
          </ColumnName>
        ))}
      </TableRow>
    </TableHead>
  )
}

const Title = styled(TableCell)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[900],
  fontSize: '1.125rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
  background: theme.palette.SFIBase.white,
  border: 'none',
}))

const TitleWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
}))

const ColumnName = styled(TableCell)(({ theme }) => ({
  textWrap: 'nowrap',
  background: theme.palette.SFIGreyLight[50],
  borderBottom: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.75rem',
  fontWeight: 500,
  lineHeight: '1.125rem',
}))

interface IInfoLabelWrapperProps extends BoxProps {
  alignment: ItemsAlignment
}

export const InfoLabelWrapper = styled((props: IInfoLabelWrapperProps) => {
  const { alignment, ...other } = props
  return <Box {...other} />
})(({ alignment }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  justifyContent: alignment,
}))

const InfoIconWrapper = styled(Box)({
  display: 'flex',
  fontSize: '1rem',
  cursor: 'pointer',
})

export default CustomTableHeader
