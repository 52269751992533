import { clone, isEmpty, isNil, pathOr, toLower, uniq } from 'ramda'

import { AlertRuleCondition, AlertRulePayloadType, AlertRuleType, GetRuleQuery } from '../../../Shared/graphql/codegen'
import {
  Asset,
  AssetComponent,
  AssetGroup,
  AssetMachine,
  Commodity,
  DurationUnit,
  GqlAssetComponent,
  GqlAssetGroup,
  GqlAssetMachine,
  GqlAssetSensor,
  InputValues,
  PowerThresholdRuleWithAssetIds,
  RuleWithAssetIds,
  VolumeThresholdRuleWithAssetIds,
} from './types'
import { EmailRecipient, Recipient, SupportedMessageType } from '../../components/Recipient/types'
import { ID } from '../../../Shared/types/types'
import { IntervalType } from '../../../Shared/components/Interval'
import {
  NotificationPreference,
  PowerThresholdRulePayload,
  RuleType,
  SupportedRuleType,
  ThresholdInfo,
  ThresholdRuleCondition,
  VolumeThresholdRulePayload,
} from '../Rules/types'
import { Sensor, SensorType } from '../../components/AssetSelector/types'
import { TriggerData } from './AssetTrigger'
import { getDuration, parseRecipients, validateRecipients } from '../../containers/Rules/utils'
import { isSensorType } from '../../components/AssetSelector/utils'

/* eslint-enable */
interface ValidationSuccess {
  status: 'success'
  rule: RuleWithAssetIds
}

interface ValidationError {
  status: 'failed'
  errors: string[]
}

type ValidationResult = ValidationSuccess | ValidationError

// Dropdowns require a unique key for every option, use this key when a 'none' option is required
export const NONE_KEY = '--NONE--' as ID
const MINUTE = 60 * 1000
const HOUR = MINUTE * 60
const DAY = HOUR * 24
const WEEK = DAY * 7

export const defaultInterval: IntervalType = {
  from: {
    day: 'Monday',
    time: '12:00',
  },
  to: {
    day: 'Monday',
    time: '12:00',
  },
}

export const defaultRecipient: EmailRecipient = {
  name: '',
  destination: '',
  transport: 'email',
}

export const defaultRule: RuleWithAssetIds = {
  id: NONE_KEY,
  name: '',
  type: 'threshold' as AlertRuleType,
  notificationPreferences: [],
  payload: {
    type: AlertRulePayloadType.Power,
    duration: 5,
    thresholdsV2: [
      {
        asset: NONE_KEY as ID,
        condition: 'above' as AlertRuleCondition,
        values: [0],
      },
    ],
    intervals: [defaultInterval],
  },
  assets: [NONE_KEY],
}

export const getRandomId = () => Math.random().toString(20).substr(2, 6) as ID

export function parseIntervals(rule: RuleWithAssetIds): IntervalType[] {
  const intervals = pathOr([defaultInterval], ['payload', 'intervals'], rule)

  return intervals.map(interval => ({
    from: interval.from,
    to: interval.to,
    alwaysOn: interval.alwaysOn,
  }))
}

function serializeRecipients(recipients: Recipient[]) {
  return recipients
    .filter(rec => rec.destination) // only serialize recipients with a valid destination
    .map(rec => {
      // Lowercase email in case of stupidity
      if (rec.transport === 'email') {
        return {
          ...rec,
          destination: toLower(rec.destination),
        }
      }
      return rec
    })
}

export function getMs(duration: number, unit: DurationUnit) {
  const units = {
    minutes: MINUTE,
    hours: HOUR,
    days: DAY,
    weeks: WEEK,
  }

  return duration * units[unit]
}

function getSelectedAssetId(group: ID, machine: ID, component: ID): ID | null {
  if (!isNil(component) && component !== NONE_KEY) return component
  if (!isNil(machine) && machine !== NONE_KEY) return machine
  if (group === NONE_KEY) return null
  return group
}

function findInAssetTree(groups: AssetGroup[], assetId: ID) {
  let group = NONE_KEY
  let machine = NONE_KEY
  let component = NONE_KEY

  groups.some(({ id: groupId, machines }) => {
    if (groupId === assetId) {
      group = groupId
      return true
    }
    return machines.some(({ id: machineId, components }) => {
      if (machineId === assetId) {
        group = groupId
        machine = machineId
        return true
      }
      return components.some(({ id: componentId }) => {
        if (componentId === assetId) {
          group = groupId
          machine = machineId
          component = componentId
          return true
        }
        return false
      })
    })
  })

  return {
    groupId: group,
    machineId: machine,
    componentId: component,
  }
}

function getRuleName(thresholdInfos: ThresholdInfo[], intervals: IntervalType[], assetIds: ID[]): string {
  const assetId = assetIds[0]
  const thresholds = thresholdInfos[0].values
  const _intervals = intervals.reduce(
    (acc: $TSFixMe, { from, to }: $TSFixMe) =>
      `${acc.length ? `${acc}-` : acc}from-${from.day}-${from.time}-to-${to.day}-${to.time}`,
    ''
  )

  return `${assetId}-${thresholds.join('-')}-${_intervals}`
}

export function scrollToTop() {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

export function ruleFromState(
  ruleId: ID,
  ruleType: RuleType,
  formState: InputValues,
  aggregation: number,
  muted: boolean
): ValidationResult {
  const assets: ID[] = []
  const thresholdInfos: ThresholdInfo[] = []

  formState.triggers.forEach(triggerData => {
    const { groupId, machineId, componentId } = triggerData.assetSelection
    const assetId = getSelectedAssetId(groupId, machineId, componentId)

    if (!assetId) return

    assets.push(assetId)

    const { condition, values } = triggerData.trigger

    thresholdInfos.push({
      asset: assetId,
      condition,
      values,
    })
  })

  if (thresholdInfos.length < 1) {
    return {
      status: 'failed',
      errors: ['rules.errors.asset'],
    }
  }

  const notificationPreferences: NotificationPreference[] = [
    {
      recipients: serializeRecipients(formState.recipients),
      message: formState.message,
      condition: thresholdInfos[0].condition,
      muted,
    },
  ]

  const payloadBase = {
    duration: getMs(formState.duration, formState.durationUnit),
    intervals: formState.intervals,
    thresholdsV2: thresholdInfos,
  }

  let rule: RuleWithAssetIds
  if (ruleType === 'volumeThreshold') {
    rule = {
      id: ruleId,
      name: getRuleName(thresholdInfos, formState.intervals, assets),
      type: 'threshold' as AlertRuleType,
      assets,
      notificationPreferences,
      payload: { ...payloadBase, type: AlertRulePayloadType.Volume, aggregation },
    }
  } else if (ruleType === 'productionThreshold') {
    rule = {
      id: ruleId,
      name: getRuleName(thresholdInfos, formState.intervals, assets),
      type: 'threshold' as AlertRuleType,
      assets,
      notificationPreferences,
      payload: {
        ...payloadBase,
        type: AlertRulePayloadType.ProductionSpeed,
      },
    }
  } else {
    rule = {
      id: ruleId,
      name: getRuleName(thresholdInfos, formState.intervals, assets),
      type: 'threshold' as AlertRuleType,
      assets,
      notificationPreferences,
      payload: {
        ...payloadBase,
        type: AlertRulePayloadType.Power,
      },
    }
  }

  const formErrors = validateRule(rule, formState.triggers.length, formState.messageType)

  if (formErrors.length > 0) {
    return {
      status: 'failed',
      errors: formErrors,
    }
  }

  return {
    status: 'success',
    rule,
  }
}

export function stateFromRule(assets: AssetGroup[], rule: RuleWithAssetIds): InputValues {
  const durationMs = pathOr(10, ['payload', 'duration'], rule)
  const { time: duration, unit: durationUnit } = getDuration(durationMs)
  const message = pathOr('', ['notificationPreferences', 0, 'message'], rule)

  const triggers: TriggerData[] = []

  rule.payload.thresholdsV2.forEach(thresholdInfo => {
    // check that asset ID is in assets list
    const selectedAsset = rule.assets.find(id => id === thresholdInfo.asset)

    // parse group/machine/component
    const assetSelection = selectedAsset
      ? findInAssetTree(assets, selectedAsset)
      : {
          groupId: NONE_KEY,
          machineId: NONE_KEY,
          componentId: NONE_KEY,
        }

    // add to triggers list
    triggers.push({
      id: getRandomId(),
      assetSelection,
      trigger: {
        condition: thresholdInfo.condition,
        values: thresholdInfo.values,
      },
    })
  })

  const ruleIntervals = parseIntervals(rule)
  const ruleRecipients = rule.notificationPreferences.reduce<Recipient[]>(
    (acc: Recipient[], pref: NotificationPreference) => {
      acc.push(...pref.recipients)
      return acc
    },
    []
  )

  return {
    duration,
    durationUnit,
    message,
    messageType: getMessageType(message),
    triggers,
    recipients: ruleRecipients,
    intervals: ruleIntervals,
    default: rule.id === NONE_KEY,
  }
}

export function getMessageType(msg: string): SupportedMessageType {
  let type: SupportedMessageType = 'text'
  try {
    const json = JSON.parse(msg)
    if (json && json instanceof Object) {
      type = 'json'
    }
  } catch {
    // if parse failed we consider the type to be 'text'
  }
  return type
}

export function validateRule(rule: RuleWithAssetIds, assetCount: number, messageType: SupportedMessageType): string[] {
  const { assets, notificationPreferences, payload } = rule
  const { message, recipients } = notificationPreferences[0]
  const thresholdInfos = rule.payload.thresholdsV2
  const uniqueAssets = uniq(assets)
  const errors = []

  if (isNil(assets) || isEmpty(assets) || assets.length !== assetCount) {
    errors.push('rules.errors.asset')
  }
  if (uniqueAssets.length !== assets.length) {
    errors.push('rules.errors.unique-asset')
  }
  if (isNil(message) || isEmpty(message)) {
    errors.push('rules.errors.message')
  }
  if (isNil(recipients) || isEmpty(recipients)) {
    errors.push('rules.errors.recipients')
  }
  const recipientErrors = validateRecipients(recipients)
  if (recipientErrors.length > 0) errors.push(...recipientErrors)
  if (messageType === 'json' && getMessageType(message) !== 'json') {
    errors.push('rules.errors.json')
  }
  if (payload.type === 'volume' && !payload.aggregation) {
    errors.push('rules.errors.volume-aggregation')
  }
  if (payload.duration > 6048e5) {
    // Max 7 days as of PLA-1183
    errors.push('rules.errors.duration')
  }

  // for OEE we want minimum duration to be 2 minutes
  if (payload.type === 'productionSpeed' && payload.duration < MINUTE * 2) {
    errors.push('rules.errors.oee-min-duration')
  }

  for (const thresholdInfo of thresholdInfos) {
    if (thresholdInfo.condition === 'between') {
      const [threshold0, threshold1] = thresholdInfo.values

      if (threshold0 >= threshold1) {
        errors.push('rules.errors.threshold-values')
        break
      }
    }
  }

  return errors
}

function isValidSensor(type: SensorType, sensor: Sensor) {
  return sensor.types?.includes(type)
}

function getSensorTypeByCommodity(commodity: Commodity): SensorType {
  switch (commodity) {
    case 'electric-current':
      return 'current'
    default:
      return 'counter-cumulative'
  }
}

export function getAvailableCommodityTypes(assets: AssetGroup[]) {
  return assets.reduce<Set<Commodity>>((acc, curr) => {
    const { machines } = curr
    if (machines == null || isEmpty(machines)) return acc

    machines.forEach(machine => {
      if (machine.payload?.commodityType) acc.add(machine.payload.commodityType)
      machine.components?.forEach(component => {
        if (component.payload?.commodityType) acc.add(component.payload.commodityType)
      })
    })
    return acc
  }, new Set())
}

export function getSelectedCommodityTypeByAssetId(
  assets: AssetGroup[],
  ruleType: SupportedRuleType,
  dataType: AlertRulePayloadType,
  id: ID
): Commodity {
  const allAssets: (Asset & { commodities: Set<Commodity> })[] = []
  assets.forEach(_group => {
    const group = { ..._group, commodities: new Set<Commodity>() }
    group.machines.forEach(_machine => {
      const machine = { ..._machine, commodities: new Set<Commodity>() }
      machine.components.forEach(_component => {
        const component = { ..._component, commodities: new Set<Commodity>() }
        if (component.payload?.commodityType) component.commodities.add(component.payload.commodityType)
        component.commodities.forEach(value => machine.commodities.add(value))
        allAssets.push(component)
      })
      if (machine.payload?.commodityType) machine.commodities.add(machine.payload.commodityType)
      machine.commodities.forEach(value => group.commodities.add(value))
      allAssets.push(machine)
    })
    if (group.payload?.commodityType) group.commodities.add(group.payload.commodityType)
    allAssets.push(group)
  })
  const selectedAsset = allAssets.find(asset => asset.id === id)
  if (selectedAsset == null || selectedAsset.commodities.size === 0 || ruleType !== 'threshold')
    return 'electric-current'

  if (selectedAsset.commodities.size === 1) return selectedAsset.commodities.values().next().value

  if (dataType === 'power' && selectedAsset.commodities.has('electric-current')) return 'electric-current'
  if (dataType === 'volume' && selectedAsset.commodities.has('natural-gas')) return 'natural-gas'

  return selectedAsset.commodities.values().next().value
}

export function filterAssetsByCommodity(commodity: Commodity, assets: AssetGroup[]): AssetGroup[] {
  const sensorType = getSensorTypeByCommodity(commodity)
  const _assets = clone(assets)

  return _assets.reduce<AssetGroup[]>((acc, curr) => {
    const { machines } = curr
    if (machines == null || isEmpty(machines)) return acc

    curr.machines = machines.filter(machine => {
      const validSensors =
        machine.payload?.commodityType === commodity
          ? machine.sensors?.filter(sensor => isValidSensor(sensorType, sensor)) ?? []
          : []
      const eligibleComponents =
        machine.components?.filter(
          component =>
            component.sensors.some(sensor => isValidSensor(sensorType, sensor)) &&
            (component.payload?.commodityType == null || component.payload.commodityType === commodity)
        ) ?? []

      machine.components = eligibleComponents
      return validSensors.length > 0 || eligibleComponents.length > 0
    })

    if (curr.machines.length > 0) acc.push(curr)
    return acc
  }, [])
}

function toLocalSensor(sensor: GqlAssetSensor): Sensor {
  return {
    id: sensor.id as ID,
    types: sensor.types.filter(isSensorType),
  }
}

function createAssetComponents(assets: Readonly<GqlAssetComponent[]>): AssetComponent[] {
  const components = assets.map(asset => ({
    id: asset.id as ID,
    name: asset.name as string,
    payload: asset.payload?.commodityType ? asset.payload : { commodityType: 'electric-current' as Commodity },
    sensors: asset.sensors.map(toLocalSensor),
  }))
  return components
}

function createAssetMachines(assets: Readonly<GqlAssetMachine[]>): AssetMachine[] {
  const machines = assets.map(asset => ({
    id: asset.id as ID,
    name: asset.name as string,
    payload: asset.payload?.commodityType ? asset.payload : { commodityType: 'electric-current' as Commodity },
    sensors: asset.sensors.map(toLocalSensor),
    components: createAssetComponents(asset.components),
  }))
  return machines
}

export function createAssetGroups(assets: GqlAssetGroup[]): AssetGroup[] {
  const groups = assets.reduce<AssetGroup[]>((acc, curr) => {
    const group = {
      id: curr.id as ID,
      name: curr.name as string,
      payload: curr.payload,
      machines: createAssetMachines(curr.machines),
    }
    acc.push(group)
    return acc
  }, [])
  return groups
}

export function normalizeRule(gqlRule: NonNullable<GetRuleQuery['rule']>): RuleWithAssetIds {
  const ruleAssets = gqlRule.assets?.map(asset => asset.asset.id as ID) ?? []
  const notificationPreferences = gqlRule.notificationPreferences.map(pref => ({ ...pref }))
  const prefs = notificationPreferences.map(pref => ({
    condition: pref.condition as ThresholdRuleCondition,
    message: pref.message,
    muted: pref.muted,
    recipients: parseRecipients(pref.recipients),
  }))
  const baseRule = {
    id: gqlRule.id,
    name: gqlRule.name,
    type: 'threshold',
    assets: ruleAssets,
    notificationPreferences: prefs,
  }

  if (gqlRule.payload.type === 'power') {
    const payload = gqlRule.payload as PowerThresholdRulePayload
    return { ...baseRule, payload } as PowerThresholdRuleWithAssetIds
  }
  const payload = gqlRule.payload as VolumeThresholdRulePayload
  return { ...baseRule, payload } as VolumeThresholdRuleWithAssetIds
}

export function getRuleType(rule: RuleWithAssetIds): RuleType {
  const dataType = rule.payload.type
  switch (dataType) {
    case 'volume':
      return 'volumeThreshold'
    default:
      return 'powerThreshold'
  }
}

export const isVolumeThresholdRule = (rule: RuleWithAssetIds): rule is VolumeThresholdRuleWithAssetIds => {
  return rule.payload.type === 'volume'
}
