import { Box, FormControl, IconButton, InputAdornment, SelectChangeEvent, styled } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import { DateTime } from 'luxon'

import MuiDateTimeRangePicker from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker'
import { DateRange } from '../../../Shared/types/analysis_types'
import { EBUBasicEnergyAsset } from '../../types'
import { EBUTimepickerButton } from './EBUTimepickerButton'
import { FormHelperWrapper } from './FormHelperWrapper'
import { StyledFormHelperText } from './StyledFormHelperText'
import { StyledHelpIconAndTooltip } from './StyledHelpIconAndTooltip'
import { StyledInput } from '../../../Shared/components/MUIComponents/update/styledComponents/StyledInput'
import { StyledSelect } from '../../../Shared/components/MUIComponents/update/styledComponents/StyledSelect'
import { StyledSelectMenuItem } from '../../../Shared/components/MUIComponents/update/styledComponents/StyledSelectMenuItem'
import { TrashIcon } from '../../../Shared/components/icons/TrashIcon'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const StyledRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(6),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

interface EnergyAssetFormRowProps {
  uniqueKey: string
  machines: EBUBasicEnergyAsset[]
  selectedAssetId: string
  startDate: DateTime
  endDate: DateTime
  contribution: number
  onAssetChange: (key: string, event: SelectChangeEvent<string>) => void
  onDateChange: (key: string, startDate: DateTime, endDate: DateTime) => void
  onContributionChange: (key: string, event: ChangeEvent<HTMLInputElement>) => void
  onAssetRemove: (key: string) => void
}

export const EnergyAssetFormRow: FC<EnergyAssetFormRowProps> = ({
  uniqueKey,
  machines,
  selectedAssetId,
  startDate,
  endDate,
  contribution,
  onAssetChange,
  onDateChange,
  onContributionChange,
  onAssetRemove,
}) => {
  const { i18n, userLocale } = useI18nContext()

  const handleAssetChange = (event: SelectChangeEvent<string>) => {
    return onAssetChange(uniqueKey, event)
  }

  const handleDateChange = (selection: DateRange) => {
    return onDateChange(uniqueKey, selection.startDate, selection.endDate)
  }

  const handleContributionChange = (event: ChangeEvent<HTMLInputElement>) => {
    return onContributionChange(uniqueKey, event)
  }

  const handleAssetRemove = () => {
    return onAssetRemove(uniqueKey)
  }

  return (
    <StyledRowContainer>
      <FormControl>
        <StyledFormHelperText>{i18n.text('ebu.form.select-assets')}</StyledFormHelperText>
        <StyledSelect
          value={selectedAssetId}
          onChange={handleAssetChange}
          sx={{ width: '250px' }}
        >
          {machines.map((machine: any) => (
            <StyledSelectMenuItem
              key={machine.id}
              value={machine.id}
            >
              {machine.name}
            </StyledSelectMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      <Box>
        <StyledFormHelperText>{i18n.text('ebu.form.date')}</StyledFormHelperText>
        <MuiDateTimeRangePicker
          startDate={startDate}
          endDate={endDate}
          onDatesChange={handleDateChange}
          isCompareDatesOn={false}
          hasPrevNextPeriodButtons={false}
          showCompareDatesButton={false}
          isTimePicker={true}
          AnchorButton={EBUTimepickerButton}
          anchorButtonProps={{ startDate, endDate, locale: userLocale }}
        />
      </Box>
      <FormControl>
        <FormHelperWrapper>
          <StyledFormHelperText>% {i18n.text('ebu.form.contribution')}</StyledFormHelperText>
          <StyledHelpIconAndTooltip title={i18n.text('ebu.form.contribution.tooltip')} />
        </FormHelperWrapper>
        <StyledInput
          type="number"
          value={contribution}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          onChange={handleContributionChange}
          disableUnderline
          sx={{ width: '135px' }}
          inputProps={{
            min: 0,
            max: 100,
            step: 10,
          }}
        />
      </FormControl>
      <IconButton
        sx={{ marginTop: '1.25rem', width: '20px', height: '20px' }}
        onClick={handleAssetRemove}
      >
        <TrashIcon />
      </IconButton>
    </StyledRowContainer>
  )
}
