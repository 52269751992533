import { createSvgIcon } from '@mui/material'

export const FileDownloadIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M11.6668 2.39063V5.83274C11.6668 6.29945 11.6668 6.53281 11.7577 6.71107C11.8376 6.86787 11.965 6.99535 12.1218 7.07525C12.3001 7.16608 12.5335 7.16608 13.0002 7.16608H16.4423M7.50016 12.9993L10.0002 15.4993M10.0002 15.4993L12.5002 12.9993M10.0002 15.4993L10.0002 10.4993M11.6668 2.16602H7.3335C5.93336 2.16602 5.2333 2.16602 4.69852 2.4385C4.22811 2.67818 3.84566 3.06063 3.60598 3.53104C3.3335 4.06582 3.3335 4.76588 3.3335 6.16602V14.8327C3.3335 16.2328 3.3335 16.9329 3.60598 17.4677C3.84566 17.9381 4.22811 18.3205 4.69852 18.5602C5.2333 18.8327 5.93336 18.8327 7.3335 18.8327H12.6668C14.067 18.8327 14.767 18.8327 15.3018 18.5602C15.7722 18.3205 16.1547 17.9381 16.3943 17.4677C16.6668 16.9329 16.6668 16.2328 16.6668 14.8327V7.16602L11.6668 2.16602Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'file-download-icon'
)
