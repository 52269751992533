import React, { FC, useState } from 'react'
import { Box } from '@mui/material'
import { Auth as Cognito } from 'aws-amplify'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import MUILoader from '../../../../Shared/components/MUIComponents/Loader'
import useQueryParams from '../../../../Shared/hooks/UseQueryParamsHook'
import { AuthApi } from '../../../../Shared/services/AuthApi'
import { FlowStates } from '../../../types/types'
import { IntroTextWrapper, StyledLabel } from '../SetPasswordPage.styled'
import { StyledPrimaryButton } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { StyledTextField } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../Shared/contexts/ToastContext'

type TypeEmailViewProps = {
  onFlowStateChange: (type: string) => void
  onEmailChange: (email: string) => void
}

type EmailFormValues = {
  email: string
}

export const TypeEmailView: FC<TypeEmailViewProps> = ({ onEmailChange, onFlowStateChange }) => {
  const { i18n } = useI18nContext()
  const params = useQueryParams()
  const { showToast } = useToastContext()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const welcomeParam = params.get('welcome')
  const isNewUser = welcomeParam === 'true'

  const formSchema: z.Schema<EmailFormValues> = z.object({
    email: z.string().email(),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailFormValues>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  })

  const getIntroText = () => (isNewUser ? i18n.text('setpassword.welcome-user') : i18n.text('setpassword.enter-email'))

  const getButtonText = () => (isNewUser ? i18n.text('tour.steps.next') : i18n.text('forms.reset-password.submit'))

  async function handleFormSubmit(data: EmailFormValues) {
    const { email } = data

    setIsLoading(true)

    // call SF Auth api to migrate user to cognito if necessary
    const resetReqResult = await AuthApi.forgotPassword(email)

    if (resetReqResult.code === 'RESET') {
      try {
        await Cognito.forgotPassword(data.email)
        onEmailChange(data.email)
        onFlowStateChange(FlowStates.CONFIRM)
      } catch (error) {
        showToast(i18n.text('forms.submit.fetching-error'), 'error', 'Error')
      }
    }
    
    if (resetReqResult.code === 'ERROR') {
      setError(resetReqResult.message)
    }

    setIsLoading(false)
  }

  return (
    <>
      <IntroTextWrapper>{getIntroText()}</IntroTextWrapper>
      {isLoading && <MUILoader margin="0" />}
      {!isLoading && (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <StyledLabel>Email*</StyledLabel>
            <Controller
              name={'email'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StyledTextField
                  value={value}
                  placeholder={i18n.text('feedback.input.email')}
                  size="small"
                  variant="outlined"
                  error={!!errors.email || !!error}
                  helperText={errors.email?.message || error}
                  onChange={(e) => {
                    setError('')
                    onChange(e)
                  }}
                  sx={{
                    maxWidth: '100%',
                  }}
                />
              )}
            ></Controller>
            <StyledPrimaryButton
              type="submit"
              sx={{
                marginTop: '1.5rem',
              }}
            >
              {getButtonText()}
            </StyledPrimaryButton>
          </Box>
        </form>
      )}
    </>
  )
}
