import React, { FC } from 'react'
import { Interval } from 'luxon'

import SimpleCsvExporter, {
  CsvDataMapper,
  CsvHeaderMapper,
  CsvHeaders,
} from '../../../Shared/components/MUIComponents/ChartTools/CsvExporter'
import { SwitchUnit } from '../../types/compressed-air_types'

interface CAEfficiencyCsvExporterProps {
  mainSeries: ApexAxisChartSeries
  powerSeries: ApexAxisChartSeries
  flowSeries: ApexAxisChartSeries
  interval: Interval
  filename?: string
  decimal: number
}

const CAEfficiencyCsvExporter: FC<CAEfficiencyCsvExporterProps> = ({
  interval,
  filename,
  mainSeries,
  powerSeries,
  flowSeries,
  decimal,
}) => {
  const headers: CsvHeaders = ['date', 'type', 'value', 'unit']

  const mapHeaders: CsvHeaderMapper = (headers, { valueSeparator }) => {
    return headers.join(valueSeparator)
  }

  const mapDataToHeaders: CsvDataMapper<ApexAxisChartSeries> = (data, { valueSeparator, decimalSeparator }) => {
    const result: string[] = []
    mapData(data, { valueSeparator, decimalSeparator }, result, SwitchUnit.kwhm3)
    mapData(powerSeries, { valueSeparator, decimalSeparator }, result, SwitchUnit.kw)
    mapData(flowSeries, { valueSeparator, decimalSeparator }, result, SwitchUnit.m3m)
    return result.join('\n')
  }

  function mapData(
    data: ApexAxisChartSeries,
    { valueSeparator, decimalSeparator }: { valueSeparator: string; decimalSeparator: string },
    result: string[],
    unitName?: SwitchUnit
  ) {
    data.forEach(asset => {
      asset.data.forEach(measurement => {
        const notNumberMeasurement = typeof measurement !== 'number' ? measurement : null
        if (
          typeof notNumberMeasurement === 'object' &&
          notNumberMeasurement &&
          'x' in notNumberMeasurement &&
          'y' in notNumberMeasurement
        ) {
          const { x, y } = notNumberMeasurement
          const formattedValue = typeof y === 'number' ? `${y?.toFixed(decimal)}`.replace('.', decimalSeparator) : null
          if (formattedValue !== null) {
            const unit = unitName
            const row = `${x}${valueSeparator}${asset.name}${valueSeparator}${formattedValue}${valueSeparator}${unit}`
            result.push(row)
          }
        }
      })
    })
  }

  return (
    <SimpleCsvExporter
      headers={headers}
      mapHeaders={mapHeaders}
      data={mainSeries}
      mapDataToHeaders={mapDataToHeaders}
      interval={interval}
      filename={filename}
      slotProps={{
        button: {
          sx: {
            background: 'none',
            padding: 0,
            '&:hover': {
              background: 'none',
            },
          },
        },
      }}
    />
  )
}

export { CAEfficiencyCsvExporter }
