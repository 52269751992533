import { AnalysisTypeEnum, AssetsProps, DaysOfWeekIncludeProps } from './types'
import {
  CustomerAssetProductionSchedulesQuery,
  CustomerDailyShiftSchedulesQuery,
  CustomerElectricityAssetStandbyQuery,
  CustomerProtocolAssetsQuery,
  TrSupportedAssetsQuery,
} from '../../../../Shared/graphql/codegen'

// a function to help with reordering the drag-and-drop result of the module overview
export const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

//a function to check whether an emailaddress is valid
export const validateEmail = (email: String) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

// a function to fetch all indexes of trues in an array of booleans
export const getAllIndexes = (arr: boolean[], val: boolean) => {
  const indexes: number[] = []
  let i = 0
  for (i = 0; i < arr.length; i++) if (arr[i] === val) indexes.push(i)
  return indexes
}

// a function to calculate how many bars a barchart would have with the current selection of module settings
export function getNumBars(
  selectedAssets: AssetsProps,
  assetGroupingLevel: string,
  timeGroupingLevel: string,
  daysOfWeekInclude: DaysOfWeekIncludeProps,
  referenceInclude: boolean
) {
  const numWeeks = referenceInclude ? 5 + 1 : 5
  const numMonths = referenceInclude ? 3 + 1 : 3
  const daysOfWeekLength = referenceInclude
    ? getAllIndexes(Object.values(daysOfWeekInclude), true).length + 1
    : getAllIndexes(Object.values(daysOfWeekInclude), true).length

  const numSelectedAssets = selectedAssets?.length || 0

  const numSelectedGroups = selectedAssets ? new Set(selectedAssets.map(asset => asset.groupId)).size : 0

  const numSelectedGroupings =
    assetGroupingLevel === 'machine' ? numSelectedAssets : assetGroupingLevel === 'group' ? numSelectedGroups : 1

  if (timeGroupingLevel === 'day') {
    return daysOfWeekLength * numSelectedGroupings
  } else if (timeGroupingLevel === 'week') {
    return numWeeks * numSelectedGroupings
  } else if (timeGroupingLevel === 'month') {
    return numMonths * numSelectedGroupings
  }
}
//a function to get assets into the right format to use as input
export function getAssetsInput(assetsData: TrSupportedAssetsQuery | undefined) {
  const assets = assetsData?.myOrg?.assets

  const assetsInput: AssetsProps =
    assets && Array.isArray(assets)
      ? assets.reduce((acc, asset) => {
          if (asset.parent !== undefined) {
            acc.push({
              assetId: asset.value,
              assetName: asset.label,
              groupId: asset.parent?.groupValue ? asset.parent?.groupValue : 'Other',
              groupName: asset?.parent?.groupLabel ? asset?.parent?.groupLabel : 'Other',
              type: asset?.enoceanPulseCounterSensorLocations[0]
                ? asset.enoceanPulseCounterSensorLocations[0].commodityType
                : 'electricity',
            })
          }
          return acc
        }, [])
      : []

  return assetsInput
}

//a function to show the right bits of text to explain which assets are filtered
export function getFilteredAssetsHelperText(
  analysisType: string,
  shiftSchedulesData: CustomerDailyShiftSchedulesQuery | undefined
) {
  const shiftSchedules = shiftSchedulesData?.dailyShiftSchedules || []
  if (analysisType === AnalysisTypeEnum.peakPower) {
    return 'reports.tracking.configuration.helperText.peakPowerAssets'
  } else if (analysisType === AnalysisTypeEnum.productionTimes) {
    return 'reports.tracking.configuration.helperText.productionAssets'
  } else if (analysisType === AnalysisTypeEnum.standby || analysisType === AnalysisTypeEnum.timeSpentPerState) {
    return 'reports.tracking.configuration.helperText.standbyAssets'
  } else if (analysisType === AnalysisTypeEnum.protocolDeviation) {
    return 'reports.tracking.configuration.helperText.protocolAssets'
  } else if (analysisType === AnalysisTypeEnum.shiftEnergyUsage && shiftSchedules.length < 1) {
    return 'reports.tracking.configuration.helperText.noShiftSchedule'
  } else {
    return ''
  }
}

// a function to filter which assets can be selected based on the chosen analysis type
export function getFilteredAssets(
  analysisType: string,
  shiftSchedulesData: CustomerDailyShiftSchedulesQuery | undefined,
  assetsData: TrSupportedAssetsQuery | undefined,
  assetsProductionSchedulesData: CustomerAssetProductionSchedulesQuery | undefined,
  assetsProtocolsData: CustomerProtocolAssetsQuery | undefined,
  assetsWithStandbyData: CustomerElectricityAssetStandbyQuery | undefined
) {
  const assetsInput: AssetsProps = getAssetsInput(assetsData)

  const assetsProductionSchedules = assetsProductionSchedulesData?.asset_active_production_schedule
    ? assetsProductionSchedulesData?.asset_active_production_schedule.map(asset => ({
        assetId: asset.assetId,
      }))
    : []

  const assetsProtocols = assetsProtocolsData?.productionProtocols
    ? assetsProtocolsData?.productionProtocols.map(asset => ({
        assetId: asset.asset?.id,
      }))
    : []

  const shiftSchedules = shiftSchedulesData?.dailyShiftSchedules || []
  const assetsWithStandby = assetsWithStandbyData?.sfMlHasura?.stateDetectionLatestStateStandbyMappingValidated.filter(
    (asset1, i, arr) => arr.findIndex(asset2 => asset2.assetId === asset1.assetId) === i
  )

  const filteredAssetsInput: AssetsProps = assetsInput?.sort((a, b) => {
    const nameComparison = a.groupName.localeCompare(b.groupName)
    if (nameComparison !== 0) return nameComparison
    return a.assetName.localeCompare(b.assetName)
  })

  if (analysisType === AnalysisTypeEnum.peakPower) {
    return filteredAssetsInput?.filter(asset => asset.type !== 'natural-gas')
  } else if (analysisType === AnalysisTypeEnum.productionTimes) {
    return filteredAssetsInput?.filter(asset =>
      assetsProductionSchedules?.some(asset2 => asset.assetId == asset2.assetId)
    )
  } else if (analysisType === AnalysisTypeEnum.standby || analysisType === AnalysisTypeEnum.timeSpentPerState) {
    return filteredAssetsInput?.filter(asset => assetsWithStandby?.some(asset2 => asset.assetId == asset2.assetId))
  } else if (analysisType === AnalysisTypeEnum.shiftEnergyUsage && shiftSchedules.length < 1) {
    return []
  } else if (analysisType === AnalysisTypeEnum.protocolDeviation) {
    return filteredAssetsInput?.filter(asset => assetsProtocols?.some(asset2 => asset.assetId == asset2.assetId))
  } else {
    return filteredAssetsInput
  }
}
