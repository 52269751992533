import React, { useMemo } from 'react'
import { ApexOptions } from 'apexcharts'
import { DateTime, Interval } from 'luxon'
import { FC } from 'react'

import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import { AirTemplateGraph } from './TemplateGraph'
import { ApexWrapper } from '../ApexWrapper'
import { ChartToolsItem } from '../../../Shared/components/MUIComponents/update/ChartToolsDropdown'
import { FitHeightBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { GraphId, SwitchUnit } from '../../types/compressed-air_types'
import { getIsWeeklyAggregation } from '../../utils/utils'
import { setToolbarItems } from '../../utils/setToolbarItems'
import { useCAChartToolsContext } from '../../context/CAChartToolsContext'
import { useCompressedAirContext } from '../../context/CompressedAirContext'
import { useConsumptionOptions } from '../../hooks/useConsumptionOptions'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOnZoomChange } from '../../hooks/useOnZoomChange'
import { useFeature } from 'flagged'

export const AirConsumptionGraph: FC = () => {
  const { i18n } = useI18nContext()
  const {
    queryParams,
    series: { consumption: consumptionSeries },
    previousPeriodSeries,
    switchUnit,
    fromToValues,
    previousPeriodFromToValues,
    outsideOperatingHoursConsumptionSeries,
    fetching,
    seriesData: { consumption: consumptionTimeSeriesData },
    isMoreThanMonth,
    handleDatesChange,
    handleCompareDatesChange,
  } = useCompressedAirContext()
  const { decimal, onDecimalToggle } = useCAChartToolsContext()
  const { from, to, sid } = queryParams
  const { sendEvent } = useAnalytics()

  // TODO (Sabina): Remove when not needed
  const isOutsideOperatingHoursVisible = !!useFeature('HAS_OUTSIDE_OPERATING_HOURS')
  
  const isWeekly = getIsWeeklyAggregation(from, to)
  const { showOutsideOperatingHoursInsights, onShowOutsideOperatingHoursInsightsToggle } = useCAChartToolsContext()

  const previousPeriodConsumptionSeries = useMemo(
    () => (previousPeriodSeries ? previousPeriodSeries.consumption : []),
    [previousPeriodSeries]
  )

  const interval: Interval =
    fromToValues.startDate && fromToValues.endDate
      ? Interval.fromDateTimes(fromToValues.startDate, fromToValues.endDate)
      : Interval.fromDateTimes(DateTime.now().startOf('day'), DateTime.now().endOf('day'))

  const isShowOutsideOperatingHoursAllowed = sid.length === 1 && !previousPeriodConsumptionSeries.length  && !isMoreThanMonth
  const hasShowOutsideOperatingHoursEnabled = showOutsideOperatingHoursInsights && isShowOutsideOperatingHoursAllowed

  const toolbarItems: ChartToolsItem[] = useMemo(
    () =>
      setToolbarItems({
        isOutsideOperatingHoursVisible,
        outsideOperatingHours: {
          isChecked: hasShowOutsideOperatingHoursEnabled,
          isDisabled: !isShowOutsideOperatingHoursAllowed,
          isMoreThanMonth,
          isCompareDatesOn: previousPeriodConsumptionSeries.length > 0,
          onToggle: () => {
            onShowOutsideOperatingHoursInsightsToggle(!showOutsideOperatingHoursInsights)
          },
        },
        decimalChangeArgs: {
          selected: decimal,
          label: `${i18n.text('chart.tools.decimal-label')} : ${decimal}`,
          onToggle: (value: number) => {
            onDecimalToggle(value)
          },
        },
      }),
    [
      hasShowOutsideOperatingHoursEnabled,
      isShowOutsideOperatingHoursAllowed,
      showOutsideOperatingHoursInsights,
      previousPeriodConsumptionSeries,
      decimal,
    ]
  )

  // Get max total of stacked bar charts
  const maxValue = useMemo(() => {
    let yAxisMaxValue = 0
    const maxValues: number[] = []
    consumptionSeries.forEach((series, index) => {
      series.data?.forEach((item, itemIndex: number) => {
        // Helps to avoid typescript errors in console
        const previousItem = previousPeriodConsumptionSeries[index]?.data?.[itemIndex]
        const outsideOperatingHoursItem =
          hasShowOutsideOperatingHoursEnabled && !fetching
            ? outsideOperatingHoursConsumptionSeries[index]?.data?.[itemIndex]
            : null
        const itemOfProperType = typeof item !== 'number' && !Array.isArray(item) && item?.y ? item : null
        const previousItemOfProperType =
          previousItem && typeof previousItem !== 'number' && !Array.isArray(previousItem) ? previousItem : null
        const outsideOperatingHoursItemOfProperType =
          outsideOperatingHoursItem &&
          typeof outsideOperatingHoursItem !== 'number' &&
          !Array.isArray(outsideOperatingHoursItem)
            ? outsideOperatingHoursItem
            : null

        if (!itemOfProperType) {
          return
        }

        let maxValue = previousItemOfProperType
          ? itemOfProperType.y > previousItemOfProperType.y
            ? itemOfProperType.y
            : previousItemOfProperType.y
          : itemOfProperType.y

        if (outsideOperatingHoursItemOfProperType) {
          maxValue += outsideOperatingHoursItemOfProperType.y
        }

        if (maxValues[itemIndex]) {
          maxValues[itemIndex] += Math.ceil(maxValue)
        } else {
          maxValues[itemIndex] = Math.ceil(maxValue)
        }
      })
    })
    yAxisMaxValue = Math.max(...maxValues.filter(val => !!val))
    return yAxisMaxValue
  }, [
    consumptionSeries,
    previousPeriodConsumptionSeries,
    outsideOperatingHoursConsumptionSeries,
    hasShowOutsideOperatingHoursEnabled,
    fetching,
  ])

  const series: ApexAxisChartSeries = useMemo(
    () =>
      hasShowOutsideOperatingHoursEnabled && !fetching
        ? [...consumptionSeries, ...previousPeriodConsumptionSeries, ...outsideOperatingHoursConsumptionSeries]
        : [...consumptionSeries, ...previousPeriodConsumptionSeries],
    [
      consumptionSeries,
      previousPeriodConsumptionSeries,
      outsideOperatingHoursConsumptionSeries,
      hasShowOutsideOperatingHoursEnabled,
      fetching,
    ]
  )

  const onZoomChange = useOnZoomChange({
    fromToValues,
    previousPeriodFromToValues,
    handleDatesChange,
    handleCompareDatesChange,
    sendEvent,
  })

  // Testing bar chart options
  const airConsumptionOptions: ApexOptions = useConsumptionOptions(
    from,
    to,
    consumptionSeries[0]?.data?.length,
    switchUnit,
    maxValue,
    consumptionTimeSeriesData,
    onZoomChange,
    interval,
    decimal
  )

  return (
    <FitHeightBoxWrapper>
      <ApexWrapper>
        <AirTemplateGraph
          chartData={{
            id: GraphId.CONSUMPTION,
            series,
            options: airConsumptionOptions,
            type: 'bar',
          }}
          texts={{
            errorKey: 'error.sensor.data-s',
            header: 'air.graphs.air-consumption-header',
            tooltip: 'compressed-air.nm3-info',
          }}
          unitSwitchData={{
            units: [SwitchUnit.m3, SwitchUnit.cf],
          }}
          isLoading={fetching}
          isToolbarMargined={!isWeekly}
          toolbarItems={toolbarItems}
        />
      </ApexWrapper>
    </FitHeightBoxWrapper>
  )
}
