import * as Sentry from '@sentry/react'
import moment from 'moment'
import React, { PropsWithChildren, useContext, useEffect, useMemo } from 'react'
import { getI18n } from '@sensorfactdev/i18n'
import { isNotNullOrUndefined } from 'type-guards'

import fallbackTranslations from './fallbackTranslations'
import { SupportedLocale, browserStorage, defaultLocale, supportedLocales } from '../../utils'
import { useAuthContext } from '../AuthContext'
import { useTranslationsQuery } from '../../graphql/codegen'

const updateMomentLocale = (locale: SupportedLocale) => {
  const _locale = locale.replace(/-/gi, '_')
  const lang = _locale === 'en_GB' ? _locale.replace('_', '-').toLowerCase() : _locale.substr(0, 2)

  import(`moment/locale/${lang}`).then(
    () => {
      moment.locale(lang)
    },
    err => {
      Sentry.captureException(err)
    }
  )
}

export const getStoredUserLocale = (userLocale?: SupportedLocale) => {
  let locale = userLocale ?? browserStorage.get<SupportedLocale>('sf-pref-locale').value ?? defaultLocale

  if (!supportedLocales.includes(locale)) {
    locale = defaultLocale
  }

  if (userLocale && userLocale !== locale) {
    browserStorage.set('sf-pref-locale', locale)
  }

  return locale
}

function enrichI18nText(i18n: $I18FixMe) {
  i18n.plainText = i18n.text
  i18n.text = (key: $TSFixMe, value: $TSFixMe) =>
    i18n.plainText(key, {
      b: (chunks: $TSFixMe) => <strong>{chunks}</strong>,
      br: <br />,
      strong: (chunks: $TSFixMe) => <strong>{chunks}</strong>,
      ...value,
    })
  return i18n
}

interface I18nContextT {
  i18n: $I18FixMe
  userLocale: SupportedLocale
}

type I18nProviderProps = PropsWithChildren<unknown>

export const I18nContext = React.createContext({} as I18nContextT)
export const useI18nContext = () => useContext(I18nContext)

export const I18nProvider = ({ children }: I18nProviderProps) => {
  const { user } = useAuthContext()
  // const [ userLocale, setUserLocale ] = useState(getStoredUserLocale())
  const userLocale = getStoredUserLocale(user?.locale)

  const { data } = useTranslationsQuery({
    errorPolicy: 'ignore',
    skip: !user,
  })

  const fetchedTranslations = data?.translations ?? null

  const i18n = useMemo(() => {
    const remote = fetchedTranslations?.filter(isNotNullOrUndefined)
    const i18nTranslations = remote && remote.length > 0 ? remote : fallbackTranslations
    return enrichI18nText(getI18n(i18nTranslations, userLocale))
  }, [fetchedTranslations, userLocale])

  useEffect(() => {
    updateMomentLocale(userLocale)
  }, [userLocale])

  return <I18nContext.Provider value={{ i18n, userLocale }}>{children}</I18nContext.Provider>
}
