import React, { FC } from 'react'
import { Button, styled } from '@mui/material'

interface IBrandContainedButtonProps {
  children: React.ReactNode
  onClick: () => void
}

const StyledActionBarButton = styled(Button)(({ theme }) => ({
  boxShadow: `0px 1px 2px 0px ${theme.palette.SFIGreyLight[900]}0D`,
  borderRadius: '6px',
  height: '40px',
  width: '110px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 600,
  textTransform: 'capitalize',
  background: theme.palette.SFIBrand[900],
  border: `1px solid ${theme.palette.SFIBrand?.[600]}`,
  color: theme.palette.SFIBase.white,
}))

export const BrandContainedButton: FC<IBrandContainedButtonProps> = ({ children, onClick }) => {
  return (
    <StyledActionBarButton
      variant="contained"
      color="secondary"
      onClick={onClick}
    >
      {children}
    </StyledActionBarButton>
  )
}
