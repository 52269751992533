import { Box, IconButton, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { RecipientRowProps } from './types'
import { SmallCustomSwitch } from '../../../../Shared/components/MUIComponents/update/NewDesignStyledSwitch'
import { StyledInputFieldHeader } from './Common'
import { StyledTextField } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { TrashIcon } from '../../../../Shared/components/icons'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { validateEmail } from './utils'

export const RecipientRow: FC<RecipientRowProps> = ({
  currentName,
  currentEmailAddress,
  currentEnabled,
  currentId,
  handlerecipientchange,
  onDelete,
  index,
}) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const recipient = {
    name: currentName,
    destination: currentEmailAddress,
    enabled: currentEnabled,
    id: currentId,
  }

  const _onNameChange = (change: string) => {
    const updatedRecipient = recipient
    updatedRecipient.name = change
    handlerecipientchange(updatedRecipient)
  }

  const _onDestinationChange = (change: string) => {
    const updatedRecipient = recipient
    updatedRecipient.destination = change
    handlerecipientchange(updatedRecipient)
  }

  const _onEnabledChange = (change: boolean) => {
    const updatedRecipient = recipient
    updatedRecipient.enabled = change
    handlerecipientchange(updatedRecipient)
  }

  const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    _onEnabledChange(event.target.checked)
    setEnabled(event.target.checked)
  }

  const handleDeleteClick = () => {
    onDelete(recipient.id, index)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    _onDestinationChange(event.target.value)
    setEmailAddress(event.target.value)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    _onNameChange(event.target.value)
    setName(event.target.value)
  }

  const [emailAddress, setEmailAddress] = useState(currentEmailAddress)
  const [name, setName] = useState(currentName)
  const [enabled, setEnabled] = useState(currentEnabled)

  const invalidEmail = emailAddress ? validateEmail(emailAddress) === null : false

  useEffect(() => {
    setEmailAddress(currentEmailAddress)
    setName(currentName)
    setEnabled(currentEnabled)
  }, [recipient])

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '42% 42% 8% 8%',
          gap: '1.625rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.375rem',
          }}
        >
          <StyledInputFieldHeader>{i18n.text(`reports.tracking.configuration.recipient.name`)}</StyledInputFieldHeader>
          <StyledTextField
            size="small"
            value={name}
            onChange={handleNameChange}
            sx={{ maxWidth: '30rem', width: '100%' }}
            placeholder={i18n.text('reports.tracking.configuration.placeholder.recipientName')}
          ></StyledTextField>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.375rem',
          }}
        >
          <StyledInputFieldHeader>{i18n.text(`reports.tracking.configuration.recipient.email`)}</StyledInputFieldHeader>
          <StyledTextField
            size="small"
            value={emailAddress}
            onChange={handleEmailChange}
            error={invalidEmail}
            helperText={invalidEmail && i18n.text(`reports.tracking.configuration.recipient.emailInvalid`)}
            sx={{ maxWidth: '30rem', width: '100%' }}
            placeholder={i18n.text('reports.tracking.configuration.placeholder.recipientEmail')}
          ></StyledTextField>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.375rem',
          }}
        >
          <StyledInputFieldHeader>
            {i18n.text(`reports.tracking.configuration.recipient.enabled`)}
          </StyledInputFieldHeader>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '2.625rem',
              width: '100%',
            }}
          >
            <SmallCustomSwitch
              inputProps={{ 'aria-label': 'controlled' }}
              color="secondary"
              checked={enabled}
              onChange={handleEnabledChange}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.375rem',
          }}
        >
          <StyledInputFieldHeader>
            {i18n.text(`reports.tracking.configuration.recipient.delete`)}
          </StyledInputFieldHeader>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '2.625rem',
              width: 'fit-content',
              '&:hover': {
                borderRadius: '0.375rem',
                backgroundColor: theme.palette.SFIError[50],
              },
            }}
          >
            <IconButton
              disableRipple
              onClick={handleDeleteClick}
            >
              <TrashIcon sx={{ color: theme.palette.error.dark }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  )
}
