import { createSvgIcon } from '@mui/material'

export const HumidityIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3 18C3 18 4.19041 18.4695 5 18.6979C7.94412 19.5286 10.4383 18.4649 13 17.6853M21 12C21 12 19.8096 11.5305 19 11.3021C13.8797 9.85739 10.1203 14.1426 5 12.6979C4.19041 12.4695 3 12 3 12M21 6C21 6 19.8096 5.53048 19 5.30206C13.8797 3.85739 10.1203 8.14261 5 6.69794C4.19041 6.46952 3 6 3 6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8146 22.8543C19.5821 22.8543 20.3181 22.5495 20.8608 22.0068C21.4034 21.4641 21.7083 20.7281 21.7083 19.9606C21.7083 19.1339 21.2949 18.3484 20.4681 17.687C19.6414 17.0256 19.0213 16.0335 18.8146 15C18.6079 16.0335 17.9878 17.0256 17.1611 17.687C16.3343 18.3484 15.9209 19.1339 15.9209 19.9606C15.9209 20.7281 16.2258 21.4641 16.7684 22.0068C17.3111 22.5495 18.0471 22.8543 18.8146 22.8543Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'humidity-icon'
)
