import { Box, styled, useTheme } from '@mui/material'

import backGroundLines from './Images/BackgroundLines.svg'
import {
  ChartBreakoutSquareIcon,
  MessageSmileCircleIcon,
  RocketIcon,
  SearchRefractionIcon,
} from '../../../../Shared/components/icons'
import { ProcessStepBlock } from '../../../components/Process/v1/ProcessStepBlock'
import { SemiboldDarkMdDisplayText } from '../../../components/Common/v1/index'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.SFIGreyNeutral[50],
  width: '100%',
}))

const ContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.SFIGreyNeutral[50],
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4rem',
  padding: '6rem 0',
  maxWidth: theme.breakpoints.values.xl,
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const LineBox = styled(Box)(() => ({
  backgroundImage: `url(${backGroundLines})`,
  display: 'grid',
  gridTemplateColumns: '22% 22% 22% 22%',
  alignItems: 'top',
  columnGap: '4%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 1.563rem',
  backgroundSize: '80%',
  width: '80%',
}))

export const Process = () => {
  const theme = useTheme()
  const { i18n } = useI18nContext()

  const features = [
    {
      icon: <RocketIcon sx={{ color: theme.palette.SFIGreyLight[700] }} />,
      title: i18n.text(`scan.process.kickOff`),
      sx: { backgroundColor: theme.palette.SFIGreyNeutral[50] },
      sxtitle: { textAlign: 'center', color: theme.palette.SFIGreyLight[900] },
    },
    {
      icon: <SearchRefractionIcon sx={{ color: theme.palette.SFIGreyLight[700] }} />,
      title: i18n.text(`scan.process.collect-data`),
      sx: { backgroundColor: theme.palette.SFIGreyNeutral[50] },
      sxtitle: { textAlign: 'center', color: theme.palette.SFIGreyLight[900] },
    },
    {
      icon: <ChartBreakoutSquareIcon sx={{ color: theme.palette.SFIBrand[900] }} />,
      title: i18n.text(`scan.process.insights`),
      sx: {
        backgroundColor: theme.palette.SFIGreyNeutral[50],
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(29, 122, 253, 0.24)',
      },
      sxtitle: { textAlign: 'center', color: theme.palette.SFIBrand[900] },
    },
    {
      icon: <MessageSmileCircleIcon sx={{ color: theme.palette.SFIGreyLight[700] }} />,
      title: i18n.text(`scan.process.continuous`),
      sx: {
        backgroundColor: theme.palette.SFIGreyNeutral[50],
      },
      sxtitle: { textAlign: 'center', color: theme.palette.SFIGreyLight[900] },
    },
  ]

  return (
    <BackgroundBox>
      <ContentBox>
        <SemiboldDarkMdDisplayText>{i18n.text(`scan.process.process.title`)}</SemiboldDarkMdDisplayText>
        <LineBox>
          {features.map((feature, index) => (
            <ProcessStepBlock
              key={index}
              title={feature.title}
              icon={feature.icon}
              sx={feature.sx}
              sxtitle={feature.sxtitle}
            />
          ))}
        </LineBox>
      </ContentBox>
    </BackgroundBox>
  )
}
