import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Menu, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'

import AddStopReasonsDropdownForm from './AddStopReasonsDropdownForm'
import { AssetWithAssignedStopReasons } from '../../../../types/Assets.types'
import { OeeCustomerProductionStopReason } from '../../../../../Shared/graphql/codegen'
import { PlusIcon } from '../../../../../Shared/components/icons/PlusIcon'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

type AnchorProps = Partial<ButtonProps> & {
  sx?: SxProps<Theme> | undefined
}

type AddStopReasonsDropdownProps = {
  stopReasons: OeeCustomerProductionStopReason[]
  selectedAssets: AssetWithAssignedStopReasons[]
  onStopReasonsUpdate: (updatedAssets: AssetWithAssignedStopReasons[]) => void
  isLoading: boolean
  disabled?: boolean
  anchor?: AnchorProps
}

const AddStopReasonsDropdown: FC<AddStopReasonsDropdownProps> = ({
  stopReasons,
  selectedAssets,
  anchor,
  isLoading,
  disabled,
  onStopReasonsUpdate,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [defaultCheckedReasons, setDefaultCheckedReasons] = useState<string[]>([])
  const [defaultIndeterminateReasons, setDefaultIndeterminateReasons] = useState<string[]>([])

  const { i18n } = useI18nContext()
  const theme = useTheme()

  const isOpen = Boolean(anchorEl)

  const getIsReasonChecked = useCallback(
    (reasonId: string) => {
      return selectedAssets.every(selectedAsset => selectedAsset.customerProductionStopReasonIds.includes(reasonId))
    },
    [selectedAssets]
  )

  const getIsReasonIndeterminate = useCallback(
    (reasonId: string) => {
      return (
        !getIsReasonChecked(reasonId) &&
        selectedAssets.some(selectedAsset => selectedAsset.customerProductionStopReasonIds.includes(reasonId))
      )
    },
    [getIsReasonChecked, selectedAssets]
  )

  useEffect(() => {
    const defaultCheckedReasons = stopReasons.reduce<string[]>((acc, stopReason) => {
      if (getIsReasonChecked(stopReason.id)) {
        acc.push(stopReason.id)
      }
      return acc
    }, [])

    const defaultIndeterminateReasons = stopReasons.reduce<string[]>((acc, stopReason) => {
      if (getIsReasonIndeterminate(stopReason.id)) {
        acc.push(stopReason.id)
      }
      return acc
    }, [])

    setDefaultCheckedReasons(defaultCheckedReasons)
    setDefaultIndeterminateReasons(defaultIndeterminateReasons)
  }, [getIsReasonChecked, getIsReasonIndeterminate, stopReasons])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleStopReasonsUpdate = (checkedReasons: string[], indeterminateReasons: string[]) => {
    const updatedAssets = selectedAssets.map(selectedAsset => {
      const assetsIndeterminateReasons = selectedAsset.customerProductionStopReasonIds.filter((reasonId: string) => {
        return indeterminateReasons.includes(reasonId)
      })

      return {
        locationId: selectedAsset.locationId,
        customerProductionStopReasonIds: [...checkedReasons, ...assetsIndeterminateReasons],
      }
    })

    onStopReasonsUpdate(updatedAssets)
    handleClose()
  }

  return (
    <>
      <Button
        sx={{
          display: 'flex',
          alignItems: 'center',
          textTransform: 'none',
          fontWeight: 600,
          ...anchor?.sx,
        }}
        disabled={disabled}
        variant={anchor?.variant ?? 'outlined'}
        color={anchor?.color ?? 'secondary'}
        onClick={handleClick}
        startIcon={
          <PlusIcon
            sx={{
              width: '1.25rem',
              height: '1.25rem',
            }}
          />
        }
        {...anchor}
      >
        {i18n.text('settings.oee-settings.stop-reasons.add-stop-reasons')}
      </Button>
      <Menu
        id="add-stop-reason-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        sx={{
          '.MuiMenu-paper': {
            width: '30rem',
            borderRadius: '0.5rem',
            border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
            boxShadow: '0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03)',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {stopReasons.length > 0 ? (
          <AddStopReasonsDropdownForm
            stopReasons={stopReasons}
            defaultCheckedReasons={defaultCheckedReasons}
            defaultIndeterminateReasons={defaultIndeterminateReasons}
            isLoading={isLoading}
            onSubmit={handleStopReasonsUpdate}
            onClose={handleClose}
          />
        ) : (
          <Typography
            sx={{
              fontSize: '0.875rem',
            }}
          >
            {i18n.text('settings.oee-settings.stop-reasons.add-stop-reasons.fallback')}
          </Typography>
        )}
      </Menu>
    </>
  )
}

export default AddStopReasonsDropdown
