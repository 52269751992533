import React from 'react'
import html2canvas, { Options } from 'html2canvas'

import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import { AnalyticsEventTypes, DownloadPNG } from '../../../Shared/hooks/useAnalytics/analyticsTypes'
import { StyledChartToolsButton } from '../../../Shared/components/MUIComponents/ChartTools/StyledChartToolsButton'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../Shared/contexts/ToastContext'

type PngFlowExportProps = {
  elements?: Array<HTMLElement | null>
  fileNames?: string[]
  options?: Partial<Options>
}

const PngFlowExporter: React.FC<PngFlowExportProps> = ({ elements, fileNames, options }) => {
  const { showToast } = useToastContext()
  const { sendEvent } = useAnalytics()
  const { i18n } = useI18nContext()

  function onClickExportButton() {
    if (!elements) {
      return showToast(
        i18n.text('analysis.png-export.error.message.body'),
        'warning',
        i18n.text('analysis.png-export.error.message.title')
      )
    }

    sendEvent<DownloadPNG>(AnalyticsEventTypes.TRACKED_DOWNLOAD, { target: 'png' })

    elements.forEach((element, index) => {
      if (!element) {
        return
      }
      html2canvas(element, options).then(canvas => {
        const root = document.body
        canvas.style.display = 'none'
        const image = canvas.toDataURL('png')
        const workElement = document.createElement('a')
        workElement.setAttribute('download', `${fileNames?.[index]}.png`)
        workElement.setAttribute('href', image)
        root.appendChild(workElement)
        workElement.click()
        root.removeChild(workElement)
      })
    })
  }

  return (
    <>
      <StyledChartToolsButton
        onClick={onClickExportButton}
        sx={{
          background: 'none',
          padding: 0,
          '&:hover': {
            background: 'none',
          },
        }}
      >
        {i18n.text('tooltip.png-export-button')}
      </StyledChartToolsButton>
    </>
  )
}

export default PngFlowExporter
