import { DateTime } from 'luxon'

import { DateRangePickerShortCut } from '../components/MUIComponents/update/MuiDateTimeRangePicker/types'

export const defaultShortcutsItems: (i18n: $I18FixMe) => DateRangePickerShortCut[] = i18n => {
  const today = DateTime.now()

  return [
    {
      label: i18n.text('graph.quick-filter.yesterday'),
      from: today.minus({ days: 1 }).startOf('day'),
      to: today.minus({ days: 1 }).endOf('day'),
    },
    {
      label: i18n.text('graph.quick-filter.day'),
      from: today.startOf('day'),
      to: today.endOf('day'),
    },
    {
      label: i18n.text('graph.quick-filter.last-week'),
      from: today.minus({ days: 7 }).startOf('week'),
      to: today.minus({ days: 7 }).endOf('week'),
    },
    {
      label: i18n.text('graph.quick-filter.week'),
      from: today.startOf('week'),
      to: today.endOf('week'),
    },
    {
      label: i18n.text('graph.quick-filter.last-month'),
      from: today.startOf('month').minus({ days: 1 }).startOf('month'),
      to: today.startOf('month').minus({ days: 1 }).endOf('month'),
    },
    {
      label: i18n.text('graph.quick-filter.month'),
      from: today.startOf('month'),
      to: today.endOf('month'),
    },
    {
      label: i18n.text('graph.quick-filter.last-year'),
      from: today.minus({ years: 1 }).startOf('year'),
      to: today.minus({ years: 1 }).endOf('year'),
    },
    {
      label: i18n.text('graph.quick-filter.year'),
      from: today.startOf('year'),
      to: today.endOf('year'),
    },
  ]
}
