import { styled } from '@mui/material'

const TooltipHeader = styled('div')(({ theme }) => ({
  background: theme.palette.SFIGreyLight[200],
  borderBottom: `1px solid ${theme.palette.SFIGreyLight[300]}`,
  padding: theme.spacing(0.75),
  fontSize: theme.spacing(1.5),
}))

const TooltipTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.25),
  fontSize: theme.spacing(1.5),
}))

const TooltipBody = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(1)} 0`,
  margin: `0 ${theme.spacing(1.25)}`,
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1.5),
  alignItems: 'center',
}))

const TooltipContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  fontSize: theme.spacing(1.5),
}))

const TooltipMarker = styled('div')(({ theme }) => ({
  minWidth: theme.spacing(1.5),
  minHeight: theme.spacing(1.5),
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
  borderRadius: '50%',
  display: 'flex',
}))

const BoldSpan = styled('span')(() => ({
  fontWeight: 500,
}))

export { TooltipBody, TooltipContent, TooltipHeader, TooltipMarker, TooltipTitle, BoldSpan }
