import React, { FC } from 'react'
import { Box, BoxTypeMap, Button } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Modal } from '@mui/material'

export type LightboxProps = {
  open: string | boolean
  handleClose: (e: React.SyntheticEvent<HTMLButtonElement>) => void
  imageProps?: BoxTypeMap['props']
}

const Lightbox: FC<LightboxProps> = ({ open, handleClose, imageProps }) => {
  return (
    <Modal
      open={!!open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4rem',
      }}
    >
      <Box
        sx={{
          maxWidth: '90vw',
          maxHeight: '90vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
        }}
      >
        <Box
          component="img"
          src={open}
          {...imageProps}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            maxHeight: 'calc(100vh - 64px)',
          }}
          data-testid="lightbox-image"
        />
        <Button
          onClick={handleClose}
          data-testid="lightbox-button-close"
          sx={theme => ({
            backgroundColor: theme.palette.SFIBase.white,
            position: 'absolute',
            top: '1rem',
            right: '1rem',
          })}
        >
          <Close color="primary" />
        </Button>
      </Box>
    </Modal>
  )
}

export default Lightbox
