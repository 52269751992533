import {
  Air,
  Bolt,
  CheckCircle,
  HandymanOutlined,
  LocalFireDepartmentOutlined,
  LocationCity,
  NotificationsOutlined,
  PersonOutline,
  SettingsOutlined,
  ShoppingCart,
  SpeedOutlined,
  TextSnippetOutlined,
  WaterOutlined,
  Thermostat
} from '@mui/icons-material'

import { CurrentPropositions } from '../../../Shared/hooks/usePropositions/usePropositions'
import { LinkInfo } from '../../../Shared/components/MUIComponents/SecondaryNav/types'

export const settingsMenuLinks: LinkInfo[] = [
  {
    name: 'Account',
    i18n: 'router.multi.settings.account',
    icon: <PersonOutline />,
    path: '/settings/account',
  },
  {
    name: 'Status',
    i18n: 'router.multi.settings.status',
    icon: <CheckCircle />,
    path: '/installation',
  },
  {
    name: 'Facility',
    i18n: 'router.multi.settings.facility',
    icon: <LocationCity />,
    path: '/settings/facility',
  },
  {
    name: 'Preferences',
    i18n: 'router.multi.settings.preferences',
    icon: <SettingsOutlined />,
    path: '/settings/preferences',
  },
]

export const primaryLinks: LinkInfo[] = [
  {
    name: CurrentPropositions.ELECTRICITY,
    i18n: 'router.multi.electricity',
    icon: <Bolt />,
    path: `/${CurrentPropositions.ELECTRICITY}`,
  },
  {
    name: CurrentPropositions.GAS,
    i18n: 'router.multi.gas',
    icon: <LocalFireDepartmentOutlined />,
    path: `/${CurrentPropositions.GAS}`,
  },
  {
    name: CurrentPropositions['COMPRESSED-AIR'],
    i18n: 'router.multi.air',
    icon: <Air />,
    path: `/${CurrentPropositions['COMPRESSED-AIR']}`,
  },
  {
    name: CurrentPropositions.WATER,
    i18n: 'router.multi.water',
    icon: <WaterOutlined />,
    path: `/${CurrentPropositions.WATER}`,
  },
  {
    name: CurrentPropositions['PREDICTIVE-MAINTENANCE'],
    i18n: 'router.multi.maintenance',
    icon: <HandymanOutlined />,
    path: `/${CurrentPropositions['PREDICTIVE-MAINTENANCE']}`,
  },
  {
    name: CurrentPropositions['OVERALL-EQUIPMENT-EFFECTIVENESS'],
    i18n: 'router.multi.oee',
    icon: <SpeedOutlined />,
    path: `/${CurrentPropositions['OVERALL-EQUIPMENT-EFFECTIVENESS']}`,
  },
  {
    name: CurrentPropositions.TEMPERATURE,
    i18n: 'router.multi.temperature',
    icon: <Thermostat />,
    path: `/${CurrentPropositions['TEMPERATURE']}`,
  },
]

export const secondaryLinks: LinkInfo[] = [
  {
    name: 'Reports',
    i18n: 'router.routes.reports',
    icon: <TextSnippetOutlined />,
    path: '/reports',
  },
  {
    name: 'Alerts',
    i18n: 'router.routes.notifications',
    icon: <NotificationsOutlined />,
    path: '/alerts',
  },
  {
    name: 'Shop',
    i18n: 'router.multi.shop',
    icon: <ShoppingCart />,
    path: 'paths.webshop',
  },
]
