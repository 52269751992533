import { Button, TableCell, TableRow, styled } from '@mui/material'
import { FC } from 'react'

import PercentageBar from '../../../Shared/components/PercentageBar/PercentageBar'
import { ShiftComparisonDataItem, ShiftComparisonShift, ShiftInterval } from '../../types/ShiftComparison.types'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type ShiftComparisonTableRowProps = {
  machine: ShiftComparisonDataItem
  isHighlighted: boolean
  onDetailsOpen: (locationId: string, machineName: string, shiftInterval: ShiftInterval) => void
}

const ShiftComparisonTableRow: FC<ShiftComparisonTableRowProps> = ({ machine, isHighlighted, onDetailsOpen }) => {
  const { i18n } = useI18nContext()

  const renderEmptyShift = () => (
    <StyledTableRow isHighlighted={isHighlighted}>
      <StyledTableCell sx={{ fontWeight: '500' }}>{machine.machineName}</StyledTableCell>
      <StyledTableCell>{i18n.text('oee.shift-comparison.table.fallback.no-shifts')}</StyledTableCell>
      <StyledTableCell align="center">-</StyledTableCell>
      <StyledTableCell align="center">-</StyledTableCell>
      <StyledTableCell align="center">-</StyledTableCell>
      <StyledTableCell align="center">-</StyledTableCell>
      <StyledTableCell align="center">-</StyledTableCell>
    </StyledTableRow>
  )

  const handleDetailsOpen = (locationId: string, shiftInterval: ShiftInterval) => {
    onDetailsOpen(locationId, machine.machineName, shiftInterval)
  }

  const renderShift = (shift: ShiftComparisonShift, index: number) => (
    <StyledTableRow
      key={`${shift.name}-${index}`}
      isHighlighted={isHighlighted}
    >
      <StyledTableCell sx={{ fontWeight: '500' }}>{index === 0 && machine.machineName}</StyledTableCell>
      <StyledTableCell>{shift.name}</StyledTableCell>
      <StyledTableCell align="center">
        <PercentageBar value={Math.round(shift.oee)} />
      </StyledTableCell>
      <StyledTableCell align="center">{Math.round(shift.availability)}%</StyledTableCell>
      <StyledTableCell align="center">{Math.round(shift.performance)}%</StyledTableCell>
      <StyledTableCell align="center">{i18n.number(shift.totalPieces)}</StyledTableCell>
      <StyledActionCell align="center">
        <Button
          variant="text"
          color="secondary"
          onClick={() => handleDetailsOpen(shift.locationId, { start: shift.start, end: shift.end })}
        >
          {i18n.text('oee.shift-comparison.table.see-details')}
        </Button>
      </StyledActionCell>
    </StyledTableRow>
  )

  return <>{machine.shifts.length > 0 ? machine.shifts.map(renderShift) : renderEmptyShift()}</>
}

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: propName => propName !== 'isHighlighted',
})<{ isHighlighted: boolean }>(({ theme, isHighlighted }) => ({
  backgroundColor: !isHighlighted ? theme.palette.SFIBase.white : theme.palette.SFIGreyLight[100],
  borderBottom: 'none',
  borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  padding: '1rem',
}))

const StyledActionCell = styled(StyledTableCell)(({ theme }) => ({
  padding: '0.5rem 1rem',
  '& button': {
    textTransform: 'none',
    lineHeight: 'inherit',
  },
}))

export default ShiftComparisonTableRow
