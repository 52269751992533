import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Box, styled } from '@mui/material'
import { FC } from 'react'

import MuiThemeProvider from '../../theme/MuiThemeProvider'
import { ZoomInIcon } from '../icons/ZoomInIcon'
import { ZoomOutIcon } from '../icons/ZoomOutIcon'

export const StyledApexWrapper = styled(Box)(({ theme }) => ({
  '.apexcharts-toolbar': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'none',
    height: theme.spacing(5),
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    borderRadius: theme.shape.borderRadius,
    padding: 0,
  },
  '.apexcharts-zoom-icon': {
    display: 'none',
  },
  '.apexcharts-zoomin-icon': {
    height: '100%',
    width: '44px',
    transform: 'none',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.SFIGreyLight[700],
    cursor: 'pointer',
    '&:hover': {
      'svg.zoom-in-icon': {
        svg: {
          fill: 'none',
        },
      },
    },
    svg: {
      fill: 'none',
      height: '1.25rem',
      width: '1.25rem',
    },
  },
  '.apexcharts-zoomout-icon': {
    height: '100%',
    width: '44px',
    transform: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    color: theme.palette.SFIGreyLight[700],
    cursor: 'pointer',
    '&:hover': {
      'svg.zoom-out-icon': {
        svg: {
          fill: 'none',
        },
      },
    },
    svg: {
      fill: 'none',
      height: '1.25rem',
      width: '1.25rem',
    },
  },
}))

const ApexWrapper: FC = ({ children }) => {
  return <StyledApexWrapper id="chart-wrapper">{children}</StyledApexWrapper>
}

const CustomZoomInIcon = () => (
  <ZoomInIcon
    sx={{ fontSize: '1.25rem' }}
    className="zoom-in-icon"
  />
)

const CustomZoomOutIcon = () => (
  <ZoomOutIcon
    sx={{ fontSize: '1.25rem' }}
    className="zoom-out-icon"
  />
)

const zoomInIconHTML = ReactDOMServer.renderToString(
  <MuiThemeProvider>
    <CustomZoomInIcon />
  </MuiThemeProvider>
)

const zoomOutIconHTML = ReactDOMServer.renderToString(
  <MuiThemeProvider>
    <CustomZoomOutIcon />
  </MuiThemeProvider>
)

export { ApexWrapper, zoomInIconHTML, zoomOutIconHTML }
