import React, { useEffect, useState } from 'react'
import { Alert, Box, useTheme } from '@mui/material'
import { DateTime } from 'luxon'

import AssetTreeLayout from '../Layouts/AssetTreeLayout'
import CollapsibleTable from '../CollapsibleTable/CollapsibleTable'
import DataExporter from '../../components/DataExporter/DataExporter'
import Loader from '../../../Shared/components/MUIComponents/Loader'
import QuickIntervalButtons from '../../components/QuickIntervalButtons/QuickIntervalButtons'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import StopReasonsFilter from '../../components/StopReasonsFilter/StopReasonsFilter'
import StopReasonsStatistics from '../../components/StopReasonsStatistics/StopReasonsStatistics'
import StopTimeByCategory from '../../components/StopTimeByCategory/StopTimeByCategory'
import StopsOverviewTable from '../../components/StopsOverviewTable/StopsOverviewTable'
import useAssetTreeMode from '../../hooks/useAssetTreeMode'
import { Asset, OeeProductionStop, useGetOeeProductionStopsQuery } from '../../../Shared/graphql/codegen'
import { AssetStop, AssetTreeMode, StopReasonSummary } from '../../types/Assets.types'
import { DEFAULT_TIMEZONE } from '../../../Shared/constants/timezone'
import {
  EXPORT_DATE_FORMAT,
  EXPORT_FILENAME_DATE_FORMAT,
  PRODUCTION_STATISTICS_DATE_FORMAT,
} from '../../utils/constants'
import { aggregateStopsByReason } from '../../utils/stopAnalysisCalculations'
import { getDefaultQuickIntervals } from '../../utils/quickIntervals'
import { getFormattedInterval, setTimeZone } from '../../utils/helpers'
import { transformProductionStopsData } from '../../utils/dataTransformations'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

const StopAnalysis = () => {
  const { interval, selectedMachines, selectedStopReasons, setNewStopReasons } = useOEEContext()
  const theme = useTheme()
  const { i18n } = useI18nContext()

  const { selectedCustomer } = useCurrentUser()
  const timeZone = selectedCustomer?.timeZone ?? DEFAULT_TIMEZONE

  const [assetsStops, setAssetsStops] = useState<AssetStop[]>([])
  const [stopsByReason, setStopsByReason] = useState<Record<string, StopReasonSummary>>({})

  const defaultStopReason = i18n.text('oee.stop-analysis.categories.not-categorized')

  useAssetTreeMode(AssetTreeMode.MULTI_SELECT)

  const {
    data: stopsData,
    loading: stopsLoading,
    error: stopsError,
  } = useGetOeeProductionStopsQuery({
    variables: {
      assetIds: selectedMachines,
      from: setTimeZone(interval.start, timeZone),
      to: setTimeZone(interval.end, timeZone),
    },
  })

  useEffect(() => {
    const assets = (stopsData?.myOrg?.assets as Asset[]) ?? []

    const stops = assets.flatMap(asset => {
      const productionStopsData =
        (asset?.enoceanPulseCounterSensorLocations?.[0].productionStops as OeeProductionStop[]) ?? []
      return transformProductionStopsData(productionStopsData, asset.name, timeZone, defaultStopReason)
    })

    const stopsByReason = aggregateStopsByReason(assets as Asset[], defaultStopReason)
    const allReasons = Object.keys(stopsByReason)

    setNewStopReasons(allReasons)
    setStopsByReason(stopsByReason)
    setAssetsStops(stops)
  }, [defaultStopReason, setNewStopReasons, stopsData?.myOrg?.assets, timeZone])

  const getCSVFormattedDate = (date: string) => {
    return DateTime.fromFormat(date, PRODUCTION_STATISTICS_DATE_FORMAT).toFormat(EXPORT_DATE_FORMAT)
  }

  const formattedInterval = getFormattedInterval(interval?.start, interval?.end, i18n.locale.replace('_', '-'))

  const exportFilenameInterval = `${DateTime.fromISO(interval?.start).toFormat(
    EXPORT_FILENAME_DATE_FORMAT
  )}-${DateTime.fromISO(interval?.end).toFormat(EXPORT_FILENAME_DATE_FORMAT)}`

  const noData = !stopsLoading && !stopsError && assetsStops.length === 0
  const noSelectedReasons = !stopsLoading && !stopsError && assetsStops.length !== 0 && selectedStopReasons.length === 0

  const csvExportData = assetsStops.map(assetStop => {
    return {
      machineName: assetStop.assetName,
      stopReason: assetStop.reason,
      stopStart: getCSVFormattedDate(assetStop.startTime),
      stopEnd: assetStop.endTime ? getCSVFormattedDate(assetStop.endTime) : '-',
      stopDuration: assetStop.duration,
      operatorInput: assetStop.feedback,
    }
  })

  return (
    <AssetTreeLayout>
      <Box sx={{ display: 'grid', gridTemplateRows: 'min-content 1fr', gridRowGap: theme.spacing(2) }}>
        <SectionHeader
          title={`${i18n.text('oee.secondary-nav.stop-analysis')}`}
          chipContent={`${formattedInterval.start} - ${formattedInterval.end}`}
          exporter={
            <DataExporter
              customFileName={`Stops-${exportFilenameInterval}.csv`}
              data={csvExportData}
            />
          }
        >
          <StopReasonsFilter />
          <QuickIntervalButtons quickIntervals={getDefaultQuickIntervals()} />
        </SectionHeader>
        {stopsLoading && <Loader margin="0" />}
        {stopsError && <Alert severity="error">{i18n.text('oee.stop-logging.fetching-error')}</Alert>}
        {noData && <Alert severity="info">{i18n.text('oee.stop-logging.no-data')}</Alert>}
        {noSelectedReasons && (
          <Alert severity="error">{i18n.text('oee.stop-analysis.filter.no-reasons-selected')}</Alert>
        )}
        {!stopsLoading && !stopsError && assetsStops.length > 0 && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateRows: 'auto 1fr',
              gridRowGap: theme.spacing(2),
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
                gap: theme.spacing(2),
              }}
            >
              <StopTimeByCategory stops={stopsByReason} />
              <StopReasonsStatistics stops={stopsByReason} />
            </Box>
            <CollapsibleTable
              title={i18n.text('oee.stop-analysis.stops-overview.title')}
              isCollapsed={true}
            >
              <StopsOverviewTable stops={assetsStops} />
            </CollapsibleTable>
          </Box>
        )}
      </Box>
    </AssetTreeLayout>
  )
}

export default StopAnalysis
