import { Box, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { FC, useMemo } from 'react'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router'
import { useFeature } from 'flagged'

import DetailsContainer from '../../containers/DetailsPageContainer/DetailsPageContainer'
import MuiDateTimeRangePicker from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker'
import OverviewContainer from '../../containers/OverviewPageContainer/OverviewPageContainer'
import PdMAssetTree from '../PdMAssetTree/PdMAssetTree'
import MuiSecondaryNav, {
  LinkInfo,
} from '../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'
import { PdmOverviewFragment } from '../../../Shared/graphql/codegen'
import { getVibrationChartIntervals } from '../../containers/GraphsPage/utils'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { usePredictiveMaintenanceContext } from '../../context/PredictiveMaintenanceContext'

export enum ActivePage {
  CURRENT_VIBRATION_STATUS = 'current-vibration-status',
  ASSET_HEALTH_REPORT = 'asset-health-report',
  GRAPHS = 'graphs',
  DETAILS = 'asset-details',
}

type LayoutProps = {
  groups: PdmOverviewFragment['groups']
}

const Layout: FC<LayoutProps> = ({ groups }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { path } = useRouteMatch()

  const { handleDateChange, from, to, isDetails } = usePredictiveMaintenanceContext()
  const { pathname } = useLocation()

  const isAnalysisPage = pathname.includes('/graphs')
  const isHealthReport = pathname.includes('/asset-health-report')

  // Retrieve machineId and probeId from the URL and if not found, use the first ids in the groups array
  const { machineId, probeId } = useMemo(() => {
    const pathParts = pathname.split('/')
    const machineIdIndex = pathParts.indexOf('machines') + 1
    const probeIdIndex = pathParts.indexOf('probes') + 1

    let machineId = pathParts[machineIdIndex]
    let probeId = pathParts[probeIdIndex]

    // If machineId or probeId are not in the URL, use the first available ones
    if (!machineId || !probeId) {
      const firstMachine = groups[0]?.machines[0]
      const firstProbe = firstMachine?.probes[0]
      machineId = machineId || firstMachine?.id
      probeId = probeId || firstProbe?.id
    }

    return { machineId, probeId }
  }, [pathname, groups])

  const pdmAssetOverview = !!useFeature('ASSET_OVERVIEW_V2')

  const getVibrationPage = (activePage: ActivePage) => {
    switch (activePage) {
      case ActivePage.GRAPHS:
        return (
          <DetailsContainer
            activePage={ActivePage.GRAPHS}
            groups={groups}
          />
        )
      case ActivePage.DETAILS:
        return (
          <DetailsContainer
            activePage={ActivePage.DETAILS}
            groups={groups}
          />
        )
      case ActivePage.CURRENT_VIBRATION_STATUS:
        return <OverviewContainer activePage={ActivePage.CURRENT_VIBRATION_STATUS} />
      case ActivePage.ASSET_HEALTH_REPORT:
        return <OverviewContainer activePage={ActivePage.ASSET_HEALTH_REPORT} />
    }
  }

  const getPredictiveMaintenenceRoutes = (): {
    key: string
    path: string
    component: React.ReactNode
  }[] => {
    return [
      {
        key: 'current-vibration-status',
        path: `${path}/current-vibration-status`,
        component: getVibrationPage(ActivePage.CURRENT_VIBRATION_STATUS),
      },
      {
        key: 'asset-health-report',
        path: `${path}/asset-health-report`,
        component: getVibrationPage(ActivePage.ASSET_HEALTH_REPORT),
      },
      {
        key: 'graphs',
        path: `${path}/machines/:machineId/probes/:probeId/graphs`,
        component: getVibrationPage(ActivePage.GRAPHS),
      },
      {
        key: 'details',
        path: `${path}/machines/:machineId/probes/:probeId/details`,
        component: getVibrationPage(ActivePage.DETAILS),
      },
    ]
  }
  const datePickerShortcuts = getVibrationChartIntervals(i18n)

  const secondaryLinks: LinkInfo[] = [
    {
      name: 'current-vibration-status',
      i18n: i18n.text('pdm.subnav.current-vibration-status'),
      path: `${path}/current-vibration-status`,
    },
    {
      name: 'asset-health-report',
      i18n: i18n.text('pdm.subnav.asset-health-report'),
      path: `${path}/asset-health-report`,
    },
    {
      name: 'graphs',
      i18n: i18n.text('pdm.subnav.vibration.graph'),
      path: `${path}/machines/${machineId}/probes/${probeId}/graphs`,
    },
    {
      name: 'details',
      i18n: i18n.text('pdm.subnav.asset.info'),
      path: `${path}/machines/${machineId}/probes/${probeId}/details`,
    },
  ]

  const onDateRangeChange = ({ startDate, endDate }: any) => {
    startDate = startDate.toJSDate()
    endDate = endDate.toJSDate()
    handleDateChange({ from: startDate, to: endDate })
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.SFIGreyLight[50],
          width: '100%',
          height: 'calc(100vh - 64px)',
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.SFIBase.white,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.SFIGreyLight[300]}`,
            padding: '1.5rem 1.5rem 0',
            '& > div': {
              borderBottom: 'none',
            },
          }}
        >
          <MuiSecondaryNav
            links={secondaryLinks}
            hideBorderBottom={false}
          />

          {isAnalysisPage && (
            <Box
              sx={{
                position: 'relative',
                top: '-0.5rem',
              }}
            >
              <MuiDateTimeRangePicker
                shortcutsItems={datePickerShortcuts}
                onDatesChange={onDateRangeChange}
                startDate={DateTime.fromJSDate(new Date(from))}
                endDate={DateTime.fromJSDate(new Date(to))}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            backgroundColor: theme.palette.background.paper,
            gridTemplateColumns: isDetails || isHealthReport ? 'minmax(300px, 1fr) 5fr' : '', // Change this line
            padding: '2rem',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {(isDetails || isHealthReport) && (
            <Box
              sx={{
                borderRight: 'solid 2px rgba(0,0,0,0.1)',
                minWidth: '300px',
                overflow: 'auto',
                marginLeft: '-0.5rem',
              }}
            >
              <PdMAssetTree groups={groups} />
            </Box>
          )}
          <Box sx={{ overflow: 'auto' }}>
            <Switch>
              {getPredictiveMaintenenceRoutes().map(navRoute => (
                <Route
                  key={navRoute.key}
                  exact
                  path={navRoute.path}
                  render={() => navRoute.component}
                />
              ))}
              <Redirect
                from={`${path}`}
                to={`${path}/current-vibration-status`}
                exact
              />
            </Switch>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Layout
