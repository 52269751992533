import { de, enGB, es, fr, it, nl } from 'date-fns/locale'

import { UserLocale_Enum } from '../graphql/codegen'

export const supportedLocales = [
  UserLocale_Enum.EnGb,
  UserLocale_Enum.NlNl,
  UserLocale_Enum.DeDe,
  UserLocale_Enum.ItIt,
  UserLocale_Enum.EsEs,
  UserLocale_Enum.FrFr,
] as const
export const defaultLocale = UserLocale_Enum.EnGb

export type SupportedLocale = UserLocale_Enum

export const dateFnsLocales = {
  [UserLocale_Enum.EnGb]: enGB,
  [UserLocale_Enum.NlNl]: nl,
  [UserLocale_Enum.DeDe]: de,
  [UserLocale_Enum.ItIt]: it,
  [UserLocale_Enum.EsEs]: es,
  [UserLocale_Enum.FrFr]: fr,
}

export const supportedLocalesStrings = {
  [UserLocale_Enum.EnGb]: 'English',
  [UserLocale_Enum.NlNl]: 'Nederlands',
  [UserLocale_Enum.DeDe]: 'Deutsch',
  [UserLocale_Enum.ItIt]: 'Italiano',
  [UserLocale_Enum.EsEs]: 'Español',
  [UserLocale_Enum.FrFr]: 'Français',
}

export const isoLocales = {
  [UserLocale_Enum.EnGb]: 'en-GB',
  [UserLocale_Enum.NlNl]: 'nl-NL',
  [UserLocale_Enum.DeDe]: 'de-DE',
  [UserLocale_Enum.ItIt]: 'it-IT',
  [UserLocale_Enum.EsEs]: 'es-ES',
  [UserLocale_Enum.FrFr]: 'fr-FR',
}

export const shortLocalesNames = {
  [UserLocale_Enum.EnGb]: 'en',
  [UserLocale_Enum.NlNl]: 'nl',
  [UserLocale_Enum.DeDe]: 'de',
  [UserLocale_Enum.ItIt]: 'it',
  [UserLocale_Enum.EsEs]: 'es',
  [UserLocale_Enum.FrFr]: 'fr',
}
