import { Box, useTheme } from '@mui/material'
import { useLayoutEffect, useState } from 'react'

import reportData from './example.json'
import versionMapping from './version_mapping.json'
import { EnergyOverview } from './containers/EnergyOverview/v1/EnergyOverview'
import { Introduction } from './containers/Introduction/v1/Introduction'
import { MeetingGoals } from './containers/Meeting goals/v1/MeetingGoals'
import { Methodology } from './containers/Methodology/v1/Methodology'
import { PeakShaving } from './containers/PeakShaving/v1/PeakShaving'
import { PressureReduction as PressureReductionV1 } from './containers/PressureReduction/v1/PressureReduction'
import { PressureReduction as PressureReductionV2 } from './containers/PressureReduction/v2/PressureReduction'
import { Process } from './containers/Process/v1/Process'
import { TypographyExamples } from './containers/Temp/TypographyExamples'

const savingsInputs = reportData.data
export const EnergyScanContainer = () => {
  const theme = useTheme()
  const versionOfReport = reportData.version
  const versionModules = versionMapping.find(item => item.version === versionOfReport)?.module_versions
  const versionTemplate = versionMapping.find(item => item.version === versionOfReport)?.template_versions

  const [tableOpenGlobal, setTableOpenGlobal] = useState(false)
  const [responsiveWidth, setResponsiveWidth] = useState(window.innerWidth)

  useLayoutEffect(() => {
    const handleResize = () => {
      setResponsiveWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setResponsiveWidth])

  const CHART_WIDTH_RATIO = 0.4
  const chartWidth =
    responsiveWidth > theme.breakpoints.values.xl
      ? theme.breakpoints.values.xl * CHART_WIDTH_RATIO
      : responsiveWidth * CHART_WIDTH_RATIO

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {' '}
      <Introduction
        customer_name={'Test customer'} //TODO from json
      ></Introduction>
      <Process />
      <MeetingGoals />
      <Methodology
        CO2_value={323} //TODO from json
        source="Electricity Maps ApS 2023" //TODO from json
      />
      <EnergyOverview
        numSensors={30} //TODO from json
        totalConsumption={3645} //TODO from json
        measuredConsumption={2345} //TODO from json
        energyPrice={130} //TODO from json
        totalEnergyCosts={2938} //TODO from json
        emissionFactor={244} //TODO from json
      />
      <Box
        sx={{
          display: 'grid',
          padding: '3rem 3rem',
          gap: '6rem',
          maxWidth: theme.breakpoints.values.xl,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {versionModules?.pressure_reduction === 'v1' && (
          <PressureReductionV1
            input={savingsInputs.pressure_reduction}
            chartWidth={chartWidth}
            tableOpenGlobal={tableOpenGlobal}
            versionTemplate={versionTemplate}
          />
        )}
        {versionModules?.pressure_reduction === 'v2' && (
          <PressureReductionV2
            input={savingsInputs.pressure_reduction}
            chartWidth={chartWidth}
            tableOpenGlobal={tableOpenGlobal}
            versionTemplate={versionTemplate}
          />
        )}
        <PeakShaving
          input={savingsInputs.peak_shaving}
          chartWidth={chartWidth}
          versionTemplate={versionTemplate}
        />
        <TypographyExamples //This is a temporary container for development purposes to easily figure which typography to pick
        />
      </Box>
    </Box>
  )
}
