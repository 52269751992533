import React, { FC } from 'react'
import { Box, Typography, dialogClasses, styled } from '@mui/material'

import { ModalFooter, StyledDialogModal } from '../../StyledDialogModal'
import { StyledDangerButton, StyledOutlinedButton } from '../styledComponents/StyledButtons'
import { TrashIcon } from '../../../icons/TrashIcon'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

/**
 * @description - DeleteImageModal is a modal that is used to confirm whether a user is sure that he/she wants to delete an image or not
 * @param {string} title - Title of the DeleteImageModal
 * @param {string} subtitle - Subtitle of the DeleteImageModal
 * @param {boolean} isOpen - Status of the DeleteImageModal, it states whether the modal is open or not
 * @param {function(): void} onClose - A function is called when Dialog's (in StyledDialogModal component) onClose event is triggered
 * @param {function(): void} onDelete - A function is called when a user clicks on the 'Delete' button
 */

interface IDeleteImageModalProps {
  title: string
  subtitle?: string
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}

export const DeleteImageModal: FC<IDeleteImageModalProps> = ({ title, subtitle, isOpen, onClose, onDelete }) => {
  const { i18n } = useI18nContext()

  return (
    <StyledDialogModal
      isOpen={isOpen}
      title=""
      isLoading={false}
      onClose={onClose}
      maxWidth="xs"
      height="responsive"
      icon={
        <TrashIconWrapper>
          <TrashIcon
            sx={{
              width: '1.5rem',
              height: '1.5rem',
            }}
          />
        </TrashIconWrapper>
      }
      sx={{
        [`.${dialogClasses.paper}`]: {
          maxWidth: '25rem',
        },
      }}
    >
      <ContentWrapper>
        <Title>{title}</Title>
        {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
      </ContentWrapper>
      <ModalFooter
        sx={{
          gap: '0.75rem',
          marginTop: '1rem',
          overflow: 'visible',
        }}
      >
        <StyledOutlinedButton
          disableRipple
          onClick={onClose}
        >
          {i18n.text('generic.cancel')}
        </StyledOutlinedButton>
        <StyledDangerButton
          disableRipple
          onClick={onDelete}
        >
          {i18n.text('integrations.delete.delete')}
        </StyledDangerButton>
      </ModalFooter>
    </StyledDialogModal>
  )
}

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
})

const TrashIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '3rem',
  height: '3rem',
  padding: '0.75rem',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  background: theme.palette.SFIOrange[100],
  color: theme.palette.error.dark,
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: 600,
  color: theme.palette.SFIGreyLight[800],
  lineHeight: '1.75rem',
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
}))
