import {
  AnalyticsEventTypes,
  AnalyticsIntent,
  AnalyticsPropositionType,
} from '../../Shared/hooks/useAnalytics/analyticsTypes'
import {
  SwithEMTabEvent,
  ToolbarButtonClick,
  ToolbarToogleElectricityEvent,
  TrackAssetSelectionEvent,
  ZoomGraphElectricityEvent,
} from './analyticsTypes'

export function sendToolbarToggleEventElectricity(
  sendEvent: (name: string, params: ToolbarToogleElectricityEvent) => void
) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.ELECTRICITY,
    intent: AnalyticsIntent.TOOGLE_TOOLBAR,
    target: 'toolbar_button_toggle',
  })
}

export function sendToolbarButtonClickElectricity(
  target: ToolbarButtonClick['target'],
  sendEvent: (name: string, params: ToolbarButtonClick) => void
) {
  const intentMapping: {
    [key: string]:
      | AnalyticsIntent.TOGGLE_TOTAL
      | AnalyticsIntent.TOGGLE_MEAN
      | AnalyticsIntent.TOGGLE_STANDBY_WASTE
      | undefined
  } = {
    toolbar_button_total: AnalyticsIntent.TOGGLE_TOTAL,
    toolbar_button_mean: AnalyticsIntent.TOGGLE_MEAN,
    toolbar_button_standby_waste: AnalyticsIntent.TOGGLE_STANDBY_WASTE,
  }
  const intent = intentMapping[target]

  if (intent)
    sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
      proposition: AnalyticsPropositionType.ELECTRICITY,
      intent,
      target,
    })
}

export function sendSwitchAssetGroupTabEventElectricity(sendEvent: (name: string, params: SwithEMTabEvent) => void) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.ELECTRICITY,
    intent: AnalyticsIntent.SWITCH_EM_GROUP_ASSET_TAB,
    target: 'em_group_asset_tab',
  })
}

export function sendZoomEventElectricity(sendEvent: (name: string, params: ZoomGraphElectricityEvent) => void) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.ELECTRICITY,
    intent: AnalyticsIntent.ZOOM_GRAPH,
    target: 'zoom_icon_button',
  })
}

export function sendAssetSelectionEventElectricity(
  sendEvent: (name: string, params: TrackAssetSelectionEvent) => void
) {
  sendEvent(AnalyticsEventTypes.TRACKED_BUTTON_CLICK, {
    proposition: AnalyticsPropositionType.ELECTRICITY,
    intent: AnalyticsIntent.SELECT_ASSET,
    target: 'asset_tree_item',
  })
}
