import { StandbyAlertRecipientsSchema, StandbyAlertWithAsset } from './types'
import { GetStandbyAlertFragment } from '../../../../Shared/graphql/codegen'
import { defaultStandbyAlert } from '../../StandbyAlertEditor/utils'
import { Recipient } from '../../../components/Recipient/types'

export function parseRecipients(recs: unknown): Recipient[] {
  const res = StandbyAlertRecipientsSchema.safeParse(recs)
  if (!res.success) return []
  return res.data
}

// TODO: fix Postgres types to make fields non-nullable
export function normalizeStandbyAlert(rule: GetStandbyAlertFragment): StandbyAlertWithAsset {
  return {
    ...rule,
    recipients: parseRecipients(rule.recipients),
    enabled: rule.enabled !== null ? rule.enabled : defaultStandbyAlert.enabled,
    resolvedEnabled: rule.resolvedEnabled !== null ? rule.resolvedEnabled : defaultStandbyAlert.resolvedEnabled,
    alertAfterMinutes: rule.alertAfterMinutes !== null ? rule.alertAfterMinutes : defaultStandbyAlert.alertAfterMinutes,
    silenceHours: rule.silenceHours !== null ? rule.silenceHours : defaultStandbyAlert.silenceHours,
    message: rule.message !== null ? rule.message : defaultStandbyAlert.message,
    asset: rule.asset !== null ? rule.asset : defaultStandbyAlert.asset,
  }
}
