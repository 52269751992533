import apexDe from 'apexcharts/dist/locales/de.json'
import apexEn from 'apexcharts/dist/locales/en.json'
import apexEs from 'apexcharts/dist/locales/es.json'
import apexFr from 'apexcharts/dist/locales/fr.json'
import apexIt from 'apexcharts/dist/locales/it.json'
import apexNl from 'apexcharts/dist/locales/nl.json'
import { ApexOptions } from 'apexcharts'
import { Interval } from 'luxon'
import { useMemo } from 'react'
import { useTheme } from '@mui/material'

import { IChartData, SeriesNames } from '../types'
import { renderCustomTooltip } from '../components/CustomTooltip/CustomTooltip'
import { shortLocalesNames } from '../../Shared/utils'
import { useApexBeforeZoom } from './useApexBeforeZoom'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'
import { zeroAfterDecimalFormatOptions } from '../../Shared/constants/numberFormatOptions'
import { zoomInIconHTML, zoomOutIconHTML } from '../../Shared/components/ApexWrapper'

const apexLocales = [apexEn, apexDe, apexEs, apexIt, apexFr, apexNl]

interface IFlowOptionsArgs {
  isMinMaxEnabled: boolean
  hasHumidity: boolean
  chartData: IChartData
  annotations: YAxisAnnotations[]
  from: number
  to: number
  temperatureMaxValue: number
  humidityMaxValue: number
  interval: Interval
  handleZoomChange: (chart: unknown, changed: { xaxis: { min: number; max: number } }) => void
}

export const useFlowOptions = (args: IFlowOptionsArgs): ApexOptions => {
  const {
    isMinMaxEnabled,
    hasHumidity,
    chartData,
    annotations,
    from,
    to,
    temperatureMaxValue,
    humidityMaxValue,
    interval,
    handleZoomChange,
  } = args
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const beforeZoom = useApexBeforeZoom(interval)
  const isAggregatedByMinute = to - from > 3600000 //millis in 1 hr

  const defaultYAxises = [
    {
      seriesName: 'Temperature',
      max: temperatureMaxValue,
      title: {
        text: `${i18n.text('temperature.main.title')} (°C)`,
        style: {
          fontSize: '0.875rem',
        },
      },
      labels: {
        style: {
          fontSize: '13px',
        },
        formatter: (value: number) => (value ? `${i18n.number(value, zeroAfterDecimalFormatOptions)}` : '0'),
      },
    },
    {
      show: false,
      seriesName: 'Temperature',
      max: temperatureMaxValue,
    },
    {
      show: hasHumidity,
      seriesName: SeriesNames.humidity,
      max: humidityMaxValue,
      title: {
        text: `${i18n.text('temperature.name.humidity')} (%)`,
        style: {
          fontSize: '0.875rem',
        },
      },
      opposite: true,
      labels: {
        style: {
          fontSize: '13px',
        },
        formatter: (value: number) => (value ? `${i18n.number(value, zeroAfterDecimalFormatOptions)}` : '0'),
      },
    },
    {
      show: false,
      seriesName: 'Humidity',
      max: humidityMaxValue,
    },
  ]

  const getYAxises = () => {
    if (isMinMaxEnabled && !hasHumidity) {
      return [defaultYAxises[0], defaultYAxises[1]]
    } else if (!isMinMaxEnabled && hasHumidity) {
      return [defaultYAxises[0], defaultYAxises[2]]
    } else if (isMinMaxEnabled && hasHumidity) {
      return defaultYAxises
    } else {
      return [defaultYAxises[0]]
    }
  }

  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        id: chartData.id,
        type: 'rangeArea',
        locales: apexLocales,
        defaultLocale: shortLocalesNames[i18n.locale],
        events: {
          beforeZoom,
          zoomed: handleZoomChange,
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        animations: {
          enabled: false,
        },
        toolbar: {
          offsetY: -40,
          offsetX: 3,
          autoSelected: 'zoom',
          tools: {
            reset: false,
            zoomin: zoomInIconHTML,
            zoomout: zoomOutIconHTML,
            download: false,
            pan: false,
            selection: false,
            zoom: '<div width=0 height=0></div>',
          },
        },
      },
      grid: {
        padding: {
          top: 64,
        },
      },
      annotations: {
        yaxis: annotations,
      },
      xaxis: {
        type: 'datetime',
        min: isAggregatedByMinute ? from : undefined,
        max: isAggregatedByMinute ? to : undefined,
        labels: {
          datetimeUTC: false,
          style: {
            fontSize: '13px',
          },
        },
      },
      yaxis: getYAxises(),
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      noData: {
        text: 'No data',
      },
      tooltip: {
        shared: true,
        intersect: false,
        custom: ({ seriesIndex, dataPointIndex }) => {
          return renderCustomTooltip({
            seriesIndex,
            dataPointIndex,
            seriesData: chartData.seriesData,
            i18n,
          })
        },
      },
      stroke: {
        width: !isMinMaxEnabled ? 3 : [3, 0, 3, 0],
      },
      fill: {
        opacity: !isMinMaxEnabled ? 1 : [1, 0.4, 1, 0.4],
      },
    }),
    [
      theme,
      i18n,
      annotations,
      from,
      to,
      chartData,
      defaultYAxises,
      humidityMaxValue,
      temperatureMaxValue,
      isMinMaxEnabled,
      hasHumidity,
    ]
  )

  return options
}
