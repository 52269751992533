import { useMemo } from 'react'

import { AlertTypes, Propositions, Recipient } from '../types/alertTypes'
import {
  GetAlertConfigurationRulesQuery,
  GetAlertRulesQuery,
  GetOldStandbyAlertsQuery,
} from '../../Shared/graphql/codegen'
import { createAlertsRowData } from '../utils/alertRules'
import { AlertType } from '../../Shared/types/types'

interface IUseAlertConfigurationRowsArgs {
  selectedProposition: string
  selectedAlertTypeCategory: string
  propositions: Propositions
  newAlertRulesData?: GetAlertRulesQuery
  oldAlertRulesData?: GetAlertConfigurationRulesQuery
  standbyAlertsRulesData?: GetOldStandbyAlertsQuery
}

export const useAlertConfigurationRows = ({
  selectedAlertTypeCategory,
  selectedProposition,
  propositions,
  newAlertRulesData,
  oldAlertRulesData,
  standbyAlertsRulesData,
}: IUseAlertConfigurationRowsArgs) => {
  const getRecipientDestination = (destination: string | { dialCode: string; phoneNumber: string }) =>
    typeof destination === 'string' ? destination : `${destination?.dialCode}${destination?.phoneNumber}`

  const mapRecipients = (recipients: Recipient[]) =>
    recipients.flatMap((recipient, index: number) => ({
      label: getRecipientDestination(recipient.destination),
      id: `recipient${index}`,
    }))

  const rows = useMemo(() => {
    switch (selectedProposition) {
      case propositions.electricity.name:
      case propositions.oee.name: {
        if (selectedAlertTypeCategory === AlertType.STANDBY_ALERT) {
          const standbyRows = standbyAlertsRulesData?.myOrg?.assets.flatMap(asset => asset.standbyAlertRules) || []
          return standbyRows.map(row =>
            createAlertsRowData({
              id: row.id,
              asset: row.asset?.name || '',
              message: row.message || '',
              category: AlertTypes[AlertType.STANDBY_ALERT],
              product: selectedProposition,
              recipient: mapRecipients(row.recipients),
              action: '',
            })
          )
        } else {
          const rows = oldAlertRulesData?.myOrg?.alertRules || []
          const category =
            selectedProposition === propositions.electricity.name
              ? AlertTypes['POWER_ALERT']
              : AlertTypes['DOWNTIME_ALERT']
          return rows.map(row =>
            createAlertsRowData({
              id: row.id,
              asset: row.assets[0].asset.name || '',
              message: row.notificationPreferences[0].message || '',
              category: category,
              product: selectedProposition,
              recipient: mapRecipients(row.notificationPreferences[0].recipients),
              action: '',
            })
          )
        }
      }
      case propositions['water'].name:
      case propositions['gas'].name: {
        const rows = oldAlertRulesData?.myOrg?.alertRules || []
        const category =
          selectedProposition === propositions.gas.name ? AlertTypes['GAS_ALERT'] : AlertTypes['WATER_ALERT']
        return rows.map(row =>
          createAlertsRowData({
            id: row.id,
            asset: row.assets[0].asset.name || '',
            message: row.notificationPreferences[0].message || '',
            category: category,
            product: selectedProposition,
            recipient: mapRecipients(row.notificationPreferences[0].recipients),
            action: '',
          })
        )
      }
      case propositions['compressed-air'].name: {
        const rows = newAlertRulesData?.generalAlertRules || []
        return rows.map(rule =>
          createAlertsRowData({
            id: rule.id,
            asset: rule.compressedAirFlowGeneralAlertThresholdCondition?.sensorLocation.name || '',
            message: rule.message as string,
            category: AlertTypes[rule.category],
            product: selectedProposition,
            recipient: mapRecipients(rule.recipients as unknown as Recipient[]),
            action: '',
          })
        )
      }
      default:
        return []
    }
  }, [selectedProposition, selectedAlertTypeCategory, newAlertRulesData, oldAlertRulesData, standbyAlertsRulesData])
  return rows
}
