import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

type SectionTitleProps = {
  title: string
  description?: string
}

const SettingsPageTitle: FC<SectionTitleProps> = ({ title, description }) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'grid', rowGap: '0.25rem' }}>
      <Typography
        sx={{
          color: theme.palette.SFIGreyLight[800],
          fontSize: '1.125rem',
          fontWeight: 600,
          lineHeight: '1.75rem',
        }}
      >
        {title}
      </Typography>
      {description && (
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '1.25rem',
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  )
}

export default SettingsPageTitle
