import createReducer from 'create-reducer-map'
import { fromJS } from 'immutable'
import { path, prop } from 'ramda'

import { ASSETS_FETCHED, BRIDGES_FETCHED, FETCH_ASSETS, FETCH_BRIDGES, SENSOR_UPDATED } from './constants'

const initialState = fromJS({
  assets: {
    assetTree: [],
    flatAssets: [],
  },
  bridges: [],
  error: null,
  assetsPending: true,
  bridgesPending: true,
  passwordUpdated: false,
})

export default createReducer(initialState, {
  [FETCH_ASSETS]: (state: $TSFixMe) => state.set('assetsPending', true).set('error', null),
  [ASSETS_FETCHED]: {
    next: (state: $TSFixMe, { assets }: $TSFixMe) =>
      state.set('assetsPending', false).set('assets', fromJS(assets)).set('error', null),
    throw: (state: $TSFixMe, { error }: $TSFixMe) => state.set('error', error).set('assetsPending', false),
  },
  [FETCH_BRIDGES]: (state: $TSFixMe) => state.set('bridgesPending', true).set('error', null),
  [BRIDGES_FETCHED]: {
    next: (state: $TSFixMe, { bridges }: $TSFixMe) =>
      state.set('bridgesPending', false).set('bridges', fromJS(bridges)).set('error', null),
    throw: (state: $TSFixMe, { error }: $TSFixMe) => state.set('error', error).set('bridgesPending', false),
  },
  [SENSOR_UPDATED]: {
    next: (state: $TSFixMe, { asset }: $TSFixMe) => {
      const parentIndex = state.get('assets').findIndex((x: $TSFixMe) => x.get('id') === path(['parent', 'id'], asset))
      const assetIndex = state
        .getIn(['assets', parentIndex, 'assets'])
        .findIndex((x: $TSFixMe) => x.get('id') === prop('id', asset))

      const storedAsset = state.getIn(['assets', parentIndex, 'assets', assetIndex]).toJS()
      const updatedAsset = { ...storedAsset, ...asset }

      return state.setIn(['assets', parentIndex, 'assets', assetIndex], fromJS(updatedAsset))
    },
  },
})
