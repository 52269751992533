import { ID } from "../../../Shared/types/types";

export interface Asset {
  id: ID
  name: string
}

export interface AssetGroup extends Asset {
  machines: AssetMachine[]
}

export interface AssetMachine extends Asset {
  sensors: Sensor[]
  components: AssetComponent[]
  hasAvoidableStandby?: boolean
}

export interface AssetComponent extends Asset {
  sensors: Sensor[]
}

export type SensorType = 'current' | 'counter-cumulative'
export interface Sensor {
  id: ID
  types: SensorType[]
}

export const NONE_KEY = '--NONE--' as ID
