import * as Sentry from '@sentry/react'
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useMount } from 'ahooks'

import type { CurrentCustomer, CurrentUser, CurrentUserContext, CustomerCommodityPrices, CustomerCurrency, ID } from '../../types/types'
import { SupportedLocale, browserStorage, createCtx } from '../../utils'
import { WIW_202312_a1 } from '../../../App/constants/slideshowKeys'
import { useAuthContext } from '../AuthContext'
import { useI18nContext } from '../i18nContext/I18nContext'
import { useStandbyAlertsRulesQuery, useUpdateMySettingsMutation } from '../../graphql/codegen'
import { useToastContext } from '../ToastContext'

const [useCurrentUser, CurrentUserReactContext] = createCtx<CurrentUserContext>()

/**
 * CurrentUserContext guarantees and provides:
 * - user is defined
 * - selectedCustomer is defined
 */
export const CurrentUserContextProvider = ({
  children,
  user,
  currentCustomer,
}: PropsWithChildren<{
  user: CurrentUser
  currentCustomer: CurrentCustomer
}>) => {
  const { allowedRoles, refreshSession, isSensorfactEmployee } = useAuthContext()
  const { userLocale } = useI18nContext()
  const { showToast } = useToastContext()
  const [introSlideshowKey, setIntroSlideshowKey] = useState<null | string>(null)

  /** Checks whether user has admin role on jwt */
  const isInternalUser = useCallback(() => allowedRoles.includes('employee-admin'), [allowedRoles])

  useMount(() => {
    // if location contains 'customer' query param, check if we should switch customer
    const customerId = new URLSearchParams(window.location.search).get('customer')
    if (customerId && currentCustomer.id !== customerId && user.customers.some(c => c.id === customerId)) {
      switchCustomer(customerId, window.location.href)
    }
  })

  const getHasuraRoleHeader = useCallback(() => (isInternalUser() ? 'employee-admin' : 'webclient'), [isInternalUser])

  // TODO Update settings should be moved to CurrentUserContext
  // but i18nContext currently relies on this existing at a higher level
  const [updateMySettingsMutation] = useUpdateMySettingsMutation({
    onError: e => {
      showToast('An error occurred while updating user settings, please try again later.', 'error', 'Uh-oh')
      Sentry.captureException(e)
    },
  })

  const updateMySettings = useCallback<CurrentUserContext['updateMySettings']>(
    async patch => {
      const locale = patch.locale ?? user.locale
      const selectedCustomerId = patch.selectedCustomerId ?? currentCustomer.id

      return updateMySettingsMutation({
        variables: {
          locale: locale,
          reportSubscription: {
            email: patch.reportSubscription?.email ?? user.reportSubscription.email,
            sms: patch.reportSubscription?.sms ?? user.reportSubscription.sms ?? false,
          },
          selectedCustomerId: selectedCustomerId as ID,
        },
      })
    },
    [updateMySettingsMutation, user, currentCustomer]
  )

  const updateUserLocale = useCallback(
    (requestedLocale: SupportedLocale) => {
      if (requestedLocale === userLocale) {
        return Promise.resolve()
      }
      browserStorage.set('sf-pref-locale', requestedLocale)

      return updateMySettings({
        locale: requestedLocale,
      })
    },
    [userLocale, updateMySettings]
  )

  const switchCustomer = useCallback(
    async (customerId: string, route?: string) => {
      if (customerId === currentCustomer?.id) {
        return
      }

      try {
        await updateMySettings({
          selectedCustomerId: customerId,
        })

        await refreshSession()

        // new session tokens generated, reload the page
        const path = route ? route : window.location.origin
        window.location.replace(path)
      } catch (e) {
        const error = e as Error
        Sentry.captureException(error)
        showToast(error.message ?? 'Unexpected server response', 'error', 'Uh-oh')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCustomer]
  )

  const {
    error: fetchStandbyAlertsError,
    loading: fetchStandbyAlertsLoading,
    data: fetchedStandbyAlertsRules,
  } = useStandbyAlertsRulesQuery()

  const hasElectricityStandbyEnabled = useMemo(() => {
    const standbyAlertRules = fetchedStandbyAlertsRules?.myOrg?.assets.flatMap(asset => asset.standbyAlertRules) ?? []
    return standbyAlertRules.length > 0
  }, [fetchedStandbyAlertsRules])

  useEffect(() => {
    hasElectricityStandbyEnabled && setIntroSlideshowKey(WIW_202312_a1)
  }, [hasElectricityStandbyEnabled])

  return (
    <CurrentUserReactContext
      value={{
        userId: user.id as ID,
        user,
        isInternalUser,
        isSensorfactEmployee,
        hasElectricityStandbyEnabled,
        INTRO_SLIDESHOW_KEY: introSlideshowKey,
        customerId: currentCustomer.id as ID,
        selectedCustomer: currentCustomer,
        customerCurrency: currentCustomer.currency as CustomerCurrency,
        updateMySettings,
        updateUserLocale,
        switchCustomer,
        getHasuraRoleHeader,
        commodityPrices: currentCustomer.commodityPrices as unknown as CustomerCommodityPrices[],
      }}
    >
      {children}
    </CurrentUserReactContext>
  )
}

export { useCurrentUser, CurrentUserReactContext }
