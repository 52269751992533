import { DataPoint } from '../types'

export function getMaxInSeries(series: DataPoint[], start = 0) {
  if (!series || !series.length) return start
  return series.reduce((acc, cur) => {
    if (!cur.y) return acc
    if (cur.y > acc) acc = cur.y
    return acc
  }, start)
}
