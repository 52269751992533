import { Asset } from '../../Shared/graphql/codegen'

export const getAssignedStopReasons = (selectedAsset: Asset) => {
  const productionStopReasons = selectedAsset.enoceanPulseCounterSensorLocations.map(
    sensorLocation => sensorLocation.productionStopReasons
  )

  return productionStopReasons.flatMap(productionStopReason => {
    return productionStopReason.map(stopReason => {
      return stopReason.customerProductionStopReason.id
    })
  })
}
