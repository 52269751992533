import { Box, Button, Typography, styled } from '@mui/material'

import { OutlinedStyledButton } from '../../../Shared/components/MUIComponents/update/OutlinedStyledButton'

export const Block = styled(Box)(({ theme }) => ({
  display: 'grid',
  alignItems: 'flex-start',
  gap: '1rem 2rem',
  flexWrap: 'wrap',
  gridTemplateColumns: '17.5rem 1fr',
  paddingBottom: '1.25rem',
  p: {
    color: theme.palette.SFIGreyLight[700],
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const ImageWrapper = styled(Box)(({ theme }) => ({
  width: '6.25rem',
  height: '6.25rem',
  overflow: 'hidden',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  '> img': {
    width: 'auto',
    height: '100%',
  },
}))

export const UploadButton = styled(OutlinedStyledButton)(({ theme }) => ({
  flexDirection: 'row',
  gap: '0.25rem',
  color: theme.palette.SFIGreyLight[700],
  fontSize: '0.875rem',
  fontWeight: 600,
  textWrap: 'nowrap',
}))

export const EfficiencyTextWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1.25rem',
}))

export const EfficiencyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))

export const LearnMoreButton = styled(Button)(({ theme }) => ({
  padding: 0,
  color: theme.palette.SFIBrand[700],
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1.25rem',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
}))

export const BlockInfo = styled('span')(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
}))

export const MainPipesWrapper = styled(Box)({
  gap: '0.75rem',
  display: 'flex',
  flexDirection: 'column',
})

export const CompressorsText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[700],
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '1.25rem',
  marginBottom: '0.35rem',
}))

export const CompressorsAutocompleteWrapper = styled(Box)({
  display: 'grid',
  gap: '1rem',
  gridTemplateColumns: '20rem repeat(2, auto)',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

export const CompressorsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: 'fit-content',
})

export const TrashIconButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  width: 'fit-content',
  minWidth: 0,
  color: theme.palette.SFIGreyLight[600],
  '&:hover': {
    background: theme.palette.SFIGreyLight[50],
  },
  '&:focus': {
    boxShadow: '0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
  },
}))
