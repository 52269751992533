import { DateTime } from 'luxon'
import type { Mutable } from 'utility-types'

import {
  ElectricityAssetFragment,
  ElectricityAssetMeasurementsQuery,
  ElectricityAssetOutsideProductionQuery,
  ElectricityAssetStandbyQuery,
  ElectricityAssetsQuery,
  Measurement,
  OutsideProduction,
  StandbyMoment,
} from '../Shared/graphql/codegen'
import { ISODateTime, LocalDateTime } from '../Shared/types/types'
import type { getMarkerShapeByCommodityType } from '../Shared/utils/analysis_utils'

export const ElectricityAnalysisTypes = ['power', 'energy', 'energy-balance'] as const
export type ElectricityAnalysisType = (typeof ElectricityAnalysisTypes)[number]

export function isAnalysisType(value?: string): value is ElectricityAnalysisType {
  return ElectricityAnalysisTypes.includes(value as ElectricityAnalysisType)
}

export type AssetLevel0 = NonNullable<ElectricityAssetsQuery['myOrg']>['assets'][number]
export type FetchedAssetWithMeasurements = NonNullable<ElectricityAssetMeasurementsQuery['myOrg']>['assets'][number]
export type FetchedAssetWithStandby = NonNullable<ElectricityAssetStandbyQuery['myOrg']>['assets'][number]
export type FetchedAssetWithOop = NonNullable<ElectricityAssetOutsideProductionQuery['myOrg']>['assets'][number]
export type FullGroupAsset = Mutable<AssetLevel0>
export type AssetLevel1 = AssetLevel0['assets'][number]
export type AssetLevel2 = AssetLevel1['assets'][number]
export type Asset = AssetLevel0 | AssetLevel1 | AssetLevel2

export type LocalAsset = Mutable<ElectricityAssetFragment> & {
  color: string
  markerShape?: ReturnType<typeof getMarkerShapeByCommodityType>
  assets: LocalAsset[]
}

export type DateRange = {
  startDate: DateTime
  endDate: DateTime
}

export type LegacyDataPoint = {
  __typename?: 'Measurement'
  time: LocalDateTime
  value: number
}

export type LegacyDataPointWithPrevTime = LegacyDataPoint & withPrevTime

export type LegacyReduxMeasurements = {
  // [key: ID]
  [key: string]: {
    name: string
    assets?: string[]
    measurements?: LegacyDataPoint[]
  }
}

export type DataPoint = {
  x: LocalDateTime
  y: number | null
}

export type OOPMoment = {
  time: OutsideProduction['time']
  power: OutsideProduction['power']
  interval_energy: OutsideProduction['outside_production_energy']
}

export type withPrevTime = {
  prevTime?: LocalDateTime
}

export type DataPointWithPrevTime = DataPoint & withPrevTime

export type WithColor = {
  color: string
}

export type WithStandby = {
  standbyEnabled: boolean
}

export type WithStandbyValidationType = {
  standbyValidationType?: StandbyValidationType
}

export type FlatAsset = FullGroupAsset & WithColor & WithStandby & WithStandbyValidationType

export type ElectricityAssetStandby = {
  id: string
  name: string
  standby: Mutable<StandbyMoment>[]
}

export type ElectricityAssetMeasurement = WithColor & {
  id: string
  name: string
  measurements: Mutable<Measurement>[]
  standby?: Mutable<StandbyMoment>[]
  oop?: Mutable<OOPMoment>[]
}

export type ElectricityAssetOop = {
  id: string
  name: string
  outside_production: Mutable<OOPMoment>[]
}

export type SingleElectricityDataSeries = ApexAxisChartSeries[number] & {
  assetId: string
  name: string
  data: DataPoint[]
  mean?: number
  isPreviousPeriod?: boolean
  isWaste?: boolean
  isOop?: boolean
}

export type SingleSummedEnergyBalanceSeries = SingleElectricityDataSeries & {
  standby: DataPoint[]
  standbyTotal: number
  standbyCost?: number
  total: number
  cost?: number
  oop: DataPoint[]
  oopTotal: number
  oopCost?: number
}

export type SummedEnergyBalanceSeries = SingleSummedEnergyBalanceSeries[]

export type ElectricityDataSeries = Array<SingleElectricityDataSeries>

export type SelectedAssets = Array<Array<string> | []>

export type ValuesModifier = {
  unit: string
  decimal: number
}

export type YAxisUnits = 'energy' | 'cost'

export type StandbyValidationType = 'DC' | 'RA' | null

export type StandbyValidationEntry = {
  assetId: string
  validationType: StandbyValidationType
  validatedAt: ISODateTime
}

export type StandbyValidationEntries = StandbyValidationEntry[]

export enum EnergyByUnitOutputMetricUnits {
  UNITS = 'units',
  UNITS1000 = 'units1000',
  KILOGRAMS = 'kilograms',
}

export interface EnergyAssetRowState {
  key: string
  selectedAssetId: string
  startDate: DateTime
  endDate: DateTime
  contribution: number
  percentageContributionValue: number
}

export interface OeeAssetRowState {
  key: string
  selectedAssetId: string
  startDate: DateTime
  endDate: DateTime
  conversionRatio: number
}

export interface EBUBasicOeeAsset {
  id: string
  name: string
}
export interface EBUBasicEnergyAsset {
  id: string
  name: string
}

export type AssetId = string

export type AssetData = {
  id: AssetId
  name: string
  measurements?: {
    time: string
    value: number
    cost: number
  }[]
  productionVolume?: {
    time: string
    value: number
  }[]
}

export type AssetDataCollection = {
  myOrg: {
    // Keys are IDs prefixed with 'id'
    [key: string]: AssetData[]
  }
}

export type ProcessedAssetData = {
  assetId: AssetId
  name: string
  isElectricityAsset: boolean
  proportionalEnergy: number
  proportionalCost: number
  totalEnergy: number
  totalCost: number
  totalVolume: number
}

export type ProcessedAssetDataPerUnit = ProcessedAssetData & {
  energyPerUnit: number
  costPerUnit: number
}

export type EnergyByUnitElectricityUnit = 'energy' | 'cost'

export type AssetTreeComponent = {
  id: string
  name: string
  commodityType?: string
  voltage?: string
  powerFactor?: string
  scale?: string
}

export type AssetTreeMachine = {
  id: string
  name: string
  components?: AssetTreeComponent[]
  commodityType?: string
  voltage?: string
  powerFactor?: string
  scale?: string
}

export type AssetTreeGroup = {
  id: string
  name: string
  machines: AssetTreeMachine[]
}
export type AssetTree = {
  groups: AssetTreeGroup[]
}

export enum AssetTreeTab {
  MACHINE = 'MACHINE',
  GROUP = 'GROUP',
}

export type NavLikeToolbarItem = {
  title: string
  value: string
  isActive: boolean
}
