import React, { FC } from 'react'
import { Box, styled } from '@mui/material'

import { CustomDateTimePicker } from './CustomDateTimePicker'
import { StartEndDatesValues } from './types'
import { useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

interface IDateTimePickersFormProps {
  values: StartEndDatesValues
}

const DateTimePickersWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacing(3)}`,
}))

export const DateTimePickersForm: FC<IDateTimePickersFormProps> = ({ values }) => {
  const { i18n } = useI18nContext()
  const { handleStartEndDates, setFromDateStatus } = useDateTimeRangePickerContext()

  return (
    <DateTimePickersWrapper>
      <CustomDateTimePicker
        values={values}
        setValues={handleStartEndDates}
        labelText={i18n.text('datetimepicker.start-date')}
        type="startDate"
        onFocus={() => {
          setFromDateStatus(true)
        }}
        error={(values.startDate && values.endDate && values.startDate > values.endDate) || undefined}
      />
      <CustomDateTimePicker
        values={values}
        setValues={handleStartEndDates}
        labelText={i18n.text('datetimepicker.end-date')}
        type="endDate"
        onFocus={() => {
          setFromDateStatus(false)
        }}
        error={(values.startDate && values.endDate && values.startDate > values.endDate) || undefined}
      />
    </DateTimePickersWrapper>
  )
}
