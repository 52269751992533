const scheme = [
  '#00BF6F',
  '#FF0000',
  '#FFD300',
  '#04AAFF',
  '#617F98',
  '#A0B708',
  '#7800FF',
  '#005CFF',
  '#00D0CE',
  '#FC6921',
  '#235B32',
  '#F404C1',
  '#859BFF',
  '#5B234C',
  '#8E1F0C',
  '#003865',
  '#FFB784',
  '#DDACF5',
  '#434663',
  '#41DA53',
  '#00A25E',
  '#67410B',
  '#008665',
  '#430C8E',
  '#6BA4B8',
  '#CC0000',
  '#2FC4BD',
  '#FF1066',
  '#5400B3',
  '#339FD3',
  '#960F4C',
  '#0087C8',
  '#00305F',
  '#634356',
  '#33CB8B',
  '#1C3E6E',
  '#636043',
  '#007DA6',
  '#578E0C',
  '#546004',
  '#B30086',
  '#FF7900',
  '#004700',
  '#00ECB8',
  '#00B70F',
  '#B30000',
  '#004EBF',
  '#B35003',
  '#84DFB9',
  '#27104E',
  '#516F92',
  '#FFB906',
  '#B75782',
  '#463722',
  '#6EA282',
  '#365386',
  '#FF991E',
  '#436350',
  '#18C515',
  '#701CAA',
  '#FF6900',
]

export function getAssetColor(index: $TSFixMe) {
  return scheme[index % scheme.length]
}
