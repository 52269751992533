import * as z from 'zod'

import { ISODateTime } from '../../Shared/types/types'

/*
 * This is the schema for the maintenance asset health report
 * that is used to validate the data that is returned from the
 * backend.
 * 
 * Steps to update the schema:
 * 
 * 1. get a copy of the JSONSchema from the repository (TODO: add link)
 * 2. Convert the JSONSchema to a zod schema using the following tool: https://combokit.net/json-schema-to-zod
 * 3. Copy the generated zod schema here (replacing the existing schema)
 * 4. Make any necessary modifications to the schema to match the expected data structure, like ISDODateTimeSchema for fields that should be parsed as ISODateTime
*/

const ISODateTimeSchema = z
.string()
.datetime({ offset: true })
.transform(val => val as ISODateTime)

export const maintenanceAssetHealthReportSchema = z.object({
  version: z.literal("1.0.0"),
  data: z.object({
    fromDate: ISODateTimeSchema,
    toDate: ISODateTimeSchema,
    customer: z.object({
      id: z.string(),
      name: z.string(),
      timeZone: z.string()
    }),
    content: z.object({
      assetGroups: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          assets: z.array(
            z.object({
              id: z.string(),
              name: z.string(),
              vibrationSensorLocations: z.array(
                z.object({
                  id: z.string(),
                  name: z.string(),
                  status: z.object({
                    type: z.enum(["good", "issue-detected"])
                  }),
                  sensorStates: z.object({
                    good: z.number().optional(),
                    warning: z.number().optional(),
                    alarm: z.number().optional(),
                    offline: z.number().optional()
                  }),
                  vibrationTrend: z
                    .object({
                      value: z.number(),
                      indicator: z.enum(["neutral", "down", "up"])
                    })
                    .optional(),
                  issues: z
                    .array(
                      z.object({
                        type: z.enum([
                          "high-vibration",
                          "increasing-vibration",
                          "offline",
                          "other"
                        ]),
                        timestamp: ISODateTimeSchema.optional()
                      })
                    )
                    .optional()
                })
              )
            })
          )
        })
      )
    })
  })
})

export type MaintenanceAssetHealthReport = z.infer<typeof maintenanceAssetHealthReportSchema>
export type VibrationSensorLocation = MaintenanceAssetHealthReport["data"]["content"]["assetGroups"][number]["assets"][number]["vibrationSensorLocations"][number]
