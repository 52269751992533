const signatures: {
  [key: string]: string
} = {
  JVBERi0: 'pdf',
  R0lGODdh: 'gif',
  R0lGODlh: 'gif',
  iVBORw0KGgo: 'png',
  '/9j/': 'jpg',
}

export function detectMimeType(base64: string) {
  for (const s in signatures) {
    if (base64.indexOf(s) === 0) {
      return signatures[s]
    }
  }
}
