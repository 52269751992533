import { FC, useState } from 'react'
import { useLocation } from 'react-router'

import { TOOLBAR_DECIMAL_KEY } from '../constants'
import { YAxisUnits } from '../types'
import { browserStorage, createCtx } from '../../Shared/utils'

type ChartToolsContextType = {
  showTotal: boolean
  setShowTotal: React.Dispatch<React.SetStateAction<boolean>>
  showMean: boolean
  setShowMean: React.Dispatch<React.SetStateAction<boolean>>
  showWaste: boolean
  setShowWaste: React.Dispatch<React.SetStateAction<boolean>>
  yAxisUnit: YAxisUnits
  setYAxisUnit: React.Dispatch<React.SetStateAction<YAxisUnits>>
  showTable: boolean
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>
  decimal: number
  energyTableContainerHeight: number
  setEnergyTableContainerHeight: React.Dispatch<React.SetStateAction<number>>
  setDecimal: (value: number) => void
  showOop: boolean
  setShowOop: React.Dispatch<React.SetStateAction<boolean>>
}
const [useChartToolsContext, ChartToolsContextReactProvider] = createCtx<ChartToolsContextType>()

/**
 * Each container is responsible for its own tools and
 * validating state against its own rules, but state itself is shared
 *
 */
const ChartToolsContextProvider: FC = ({ children }) => {
  const location = useLocation()

  const initialShowWaste = new URLSearchParams(location.search).get('tstandby') === '1'
  const savedDecimal = browserStorage.get<string>(TOOLBAR_DECIMAL_KEY).value
  const initialDecimal = savedDecimal !== null ? parseInt(savedDecimal) : 1

  const [showTotal, setShowTotal] = useState<boolean>(false)

  const [showMean, setShowMean] = useState<boolean>(false)
  const [showWaste, setShowWaste] = useState<boolean>(initialShowWaste)
  const [showOop, setShowOop] = useState<boolean>(initialShowWaste)
  const [yAxisUnit, setYAxisUnit] = useState<YAxisUnits>('energy')
  const [showTable, setShowTable] = useState(false)
  const [decimal, _setDecimal] = useState<number>(initialDecimal)
  const [energyTableContainerHeight, setEnergyTableContainerHeight] = useState(0)

  const setDecimal = (value: number) => {
    browserStorage.set(TOOLBAR_DECIMAL_KEY, value)
    _setDecimal(value)
  }

  return (
    <ChartToolsContextReactProvider
      value={{
        showTotal,
        setShowTotal,
        showMean,
        setShowMean,
        showWaste,
        setShowWaste,
        yAxisUnit,
        setYAxisUnit,
        showTable,
        setShowTable,
        decimal,
        setDecimal,
        energyTableContainerHeight,
        setEnergyTableContainerHeight,
        showOop,
        setShowOop,
      }}
    >
      {children}
    </ChartToolsContextReactProvider>
  )
}

export { useChartToolsContext, ChartToolsContextProvider }
