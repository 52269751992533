import { ApexOptions } from 'apexcharts'
import { Theme } from '@mui/material'

import { AllSeriesData, GraphId, SwitchUnit } from '../types/compressed-air_types'
import { DownloadGraphCA } from './analyticsTypes'
import { StartEndDatesValues } from '../../Shared/components/MUIComponents/update/MuiDateTimeRangePickerControlled/types'
import { defaultOptions } from './defaultOptions'
import { numberFormatOptions } from '../../Shared/constants/numberFormatOptions'
import { renderCustomTooltip } from '../components/CustomTooltip/CustomTooltip'
import { sendCATrackedDownloadEvent } from './analyticsEvents'
import { shortLocalesNames } from '../../Shared/utils'

interface IGetFlowOptionsArgs {
  i18n: $I18FixMe
  theme: Theme
  graphId: GraphId
  isFlow: boolean
  unitName: SwitchUnit
  type: 'flow' | 'pressure' | 'temperature'
  shouldUseRangeArea: boolean
  hasShowMinMaxRangeEnabled: boolean
  hasShowOutsideOperatingHoursInsightsEnabled: boolean
  from: number
  to: number
  seriesData: AllSeriesData
  previousPeriodFromToValues: StartEndDatesValues
  annotations: YAxisAnnotations[]
  decimal: number
  sendEvent: (name: string, params?: DownloadGraphCA) => void
  beforeZoom: (chart: any, options?: any) => void
  onZoomChange: (chart: any, options?: any) => void
}

export function getFlowOptions({
  graphId,
  isFlow,
  unitName,
  type,
  i18n,
  from,
  to,
  seriesData,
  previousPeriodFromToValues,
  theme,
  hasShowMinMaxRangeEnabled,
  hasShowOutsideOperatingHoursInsightsEnabled,
  shouldUseRangeArea,
  annotations,
  decimal,
  sendEvent,
  beforeZoom,
  onZoomChange,
}: IGetFlowOptionsArgs): ApexOptions {
  const apexOptions = defaultOptions as ApexOptions
  return {
    ...apexOptions,
    chart: {
      id: graphId,
      type: 'rangeArea',
      defaultLocale: shortLocalesNames[i18n.locale],
      events: {
        click: e => sendCATrackedDownloadEvent(e, 'flow', sendEvent),
        beforeZoom: beforeZoom ?? undefined,
        zoomed: onZoomChange,
      },
      ...apexOptions.chart,
      toolbar: {
        ...apexOptions.chart?.toolbar,
        show: isFlow,
      },
    },
    legend: {
      ...apexOptions.legend,
      fontFamily: theme.typography.fontFamily,
      show: isFlow,
    },
    annotations: {
      yaxis: annotations,
    },
    xaxis: {
      ...apexOptions.xaxis,
      min: from,
      max: to,
    },
    yaxis: {
      ...apexOptions.yaxis,
      labels: {
        formatter: value => {
          return value ? `${i18n.number(value, numberFormatOptions)}` : '0'
        },
      },
    },
    tooltip: {
      ...apexOptions.tooltip,
      custom: ({ seriesIndex, dataPointIndex }) =>
        renderCustomTooltip({
          seriesIndex,
          dataPointIndex,
          seriesData: seriesData[type],
          shouldUseFirstDataIndex: hasShowMinMaxRangeEnabled || hasShowOutsideOperatingHoursInsightsEnabled,
          i18n,
          switchUnit: unitName,
          decimal,
        }),
    },
    stroke: {
      width: hasShowMinMaxRangeEnabled ? [2, 0, 1] : 2,
      dashArray: hasShowMinMaxRangeEnabled
        ? [0, 0, 3]
        : previousPeriodFromToValues.startDate && previousPeriodFromToValues.endDate
        ? [
            ...seriesData[type].slice(0, seriesData[type].length / 2).map(() => 0),
            ...seriesData[type].slice(0, seriesData[type].length / 2).map(() => 3),
          ]
        : 0,
    },
    fill: {
      opacity: shouldUseRangeArea ? [1, 0.2, 1] : 1,
    },
  }
}
