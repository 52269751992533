import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { DateTime } from 'luxon'
import { Description } from '@mui/icons-material'
import { isNil, pathOr } from 'ramda'

import StyledButton from '../AnalysisTools/StyledButton'
import useAnalytics from '../../hooks/useAnalytics/useAnalytics'
import { AnalyticsEventTypes, DownloadCSV } from '../../hooks/useAnalytics/analyticsTypes'
import { formatCsvData } from './utils'
import { getAssetById } from '../../utils'
import { getMeasurementTypesByAnalysis } from '../../utils/analysis_utils'
import { useCurrentUser } from '../../contexts/CurrentUserContext'

function _download(text: $TSFixMe, name: $TSFixMe, type: $TSFixMe) {
  const root = document.body
  const workElement = document.createElement('a')
  workElement.style.display = 'block'
  workElement.style.visibility = 'hidden'
  const file = new Blob([text], { type })
  workElement.setAttribute('href', URL.createObjectURL(file))
  workElement.setAttribute('target', '_blank')
  root.appendChild(workElement)
  workElement.setAttribute('download', name)
  workElement.click()
  root.removeChild(workElement)
}
type Props = {
  analysis: {
    get: $TSFixMeFunction
    getIn: $TSFixMeFunction
  }
  i18n: $I18FixMe
}
/** @deprecated do not use */
const CsvExport: React.FC<Props> = ({ analysis, i18n }) => {
  const theme = useTheme()
  const [assets, setAssets] = useState<$TSFixMe[]>([])
  const [selectedAssets, setSelectedAssets] = useState<string[]>([])
  const [decimalSeparator, setDecimalSeparator] = useState('.')
  const [decimalSeparatorError, setDecimalSeparatorError] = useState<string | null>(null)
  const [valueSeparator, setValueSeparator] = useState(',')
  const [valueSeparatorError, setValueSeparatorError] = useState<string | null>(null)
  const [showDialog, setShowDialog] = useState(false)
  const { sendEvent } = useAnalytics()
  const { customerId, userId, isInternalUser } = useCurrentUser()
  const exampleHeading = `time${valueSeparator}name${valueSeparator}value`
  const exampleLine = `2021-08-03T12:00:44.000${valueSeparator}${pathOr(
    'Asset name',
    ['name'],
    getAssetById(assets, selectedAssets[0])
  )}${valueSeparator}3${decimalSeparator}243`
  function onClickExportButton() {
    setAssets(analysis.get('assets').toJS())
    setSelectedAssets(
      analysis
        .get('selectedAssets')
        .toJS()
        .reduce((acc: $TSFixMe, curr: $TSFixMe) => acc.concat(...curr), [])
    )
    setShowDialog(true)
  }
  useEffect(() => {
    if (decimalSeparator.length === 1) {
      setDecimalSeparatorError(null)
    } else {
      setDecimalSeparatorError(i18n.text('export.error-decimal-separator'))
    }
  }, [decimalSeparator, i18n, setDecimalSeparatorError])
  useEffect(() => {
    if (valueSeparator.length === 1) {
      setValueSeparatorError(null)
    } else {
      setValueSeparatorError(i18n.text('export.error-value-separator'))
    }
  }, [valueSeparator, i18n, setValueSeparatorError])
  const onDownload = () => {
    const analysisType = analysis.get('analysisType')
    const measurementType = getMeasurementTypesByAnalysis(analysisType)[0]
    const measurements: Record<string, { measurements: { time: $TSFixMe; value: $TSFixMe }[] }> = analysis
      .getIn(['data', measurementType, 'measurements'])
      .toJS()
    const startDate = analysis.getIn(['selectedDateRange', 'startDate']) as DateTime
    const endDate = analysis.getIn(['selectedDateRange', 'endDate']) as DateTime
    const fileName = `${startDate.toFormat('yyyy-MM-dd')}-${endDate.toFormat('yyyy-MM-dd')}.csv`
    const data = Object.keys(measurements)
      .filter(id => selectedAssets.includes(id))
      .filter(id => !isNil(measurements[id].measurements))
      .flatMap(id => {
        const asset = getAssetById(assets, id)
        if (!asset) {
          return []
        }
        const { name } = asset
        return measurements[id].measurements.map(({ time, value }) => ({
          time,
          name,
          value,
        }))
      })

    const csv = (
      formatCsvData(analysisType, startDate, endDate, data, valueSeparator, decimalSeparator) as $TSFixMe
    ).join('\n')
    _download(csv, fileName, 'text/csv')

    sendEvent<DownloadCSV>(AnalyticsEventTypes.TRACKED_DOWNLOAD, { target: 'csv' })
    setShowDialog(false)
  }
  return (
    <>
      <StyledButton
        appearance="secondary"
        className="ExportButton"
        intent={showDialog ? 'active' : undefined}
        onClick={onClickExportButton}
        tooltip={i18n.text('tooltip.export-button')}
        sx={{ color: 'white', textTransform: 'none' }}
      >
        <Description
          fontSize="small"
          sx={{ marginRight: theme.spacing(1) }}
        />
        <Typography sx={{ textTransform: 'none', fontSize: theme.spacing(1.5) }}>
          {i18n.text('tooltip.export-button')}
        </Typography>
      </StyledButton>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <DialogContent>
          <Box>
            <Typography variant="h5">{i18n.text('export.dialog-title')}</Typography>
            <Typography my={1}>{i18n.text('export.instructions')}</Typography>
          </Box>
          <Box my={2}>
            <Stack direction="column">
              <TextField
                label={i18n.text('export.decimal-separator')}
                value={decimalSeparator}
                size="small"
                variant="standard"
                helperText={decimalSeparatorError}
                error={Boolean(decimalSeparatorError)}
                onChange={(e: $TSFixMe) => setDecimalSeparator(e.target.value)}
              />
              <TextField
                label={i18n.text('export.value-separator')}
                value={valueSeparator}
                size="small"
                variant="standard"
                helperText={valueSeparatorError}
                error={Boolean(valueSeparatorError)}
                onChange={(e: $TSFixMe) => setValueSeparator(e.target.value)}
              />
            </Stack>
          </Box>
          <Box my={1}>
            <Typography mb={1}>{i18n.text('export.example-output')}:</Typography>
            <Typography>{exampleHeading}</Typography>
            <Typography>{exampleLine}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={decimalSeparatorError != null || valueSeparatorError != null}
            onClick={onDownload}
            variant="contained"
          >
            {i18n.text('export.download-button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
CsvExport.defaultProps = {}
export default CsvExport
