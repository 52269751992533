import { Box } from '@mui/material'
import { FC } from 'react'

import { CancelEditModuleDialog } from '../Dialogs/CancelEditModuleDialog'
import { SaveCancelButtonsProps } from '../types'
import {
  StyledOutlinedButton,
  StyledPrimaryButton,
} from '../../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { StyledTooltip } from '../../../../../Shared/components/MUIComponents/Tooltip'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const SaveCancelButtons: FC<SaveCancelButtonsProps> = ({
  handleOpenCancelDialog,
  handleCloseCancelDialog,
  openCancelDialog,
  handleSave,
  isSaveDisabled,
}) => {
  const { i18n } = useI18nContext()

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          justifyContent: 'flex-end',
          width: '100%',
          gap: '0.75rem',
        }}
      >
        <StyledOutlinedButton
          sx={{ height: 'fit-content' }}
          onClick={handleOpenCancelDialog}
        >
          {i18n.text(`reports.tracking.configuration.module.cancel`)}
        </StyledOutlinedButton>
        <StyledTooltip title={isSaveDisabled ? i18n.text('reports.tracking.configuration.tooltip.saveDisabled') : ''}>
          <Box>
            <StyledPrimaryButton
              sx={{ height: 'fit-content' }}
              onClick={handleSave}
              disabled={isSaveDisabled}
            >
              {i18n.text(`reports.tracking.configuration.general.save`)}
            </StyledPrimaryButton>
          </Box>
        </StyledTooltip>
      </Box>
      <CancelEditModuleDialog
        handleCloseCancelDialog={handleCloseCancelDialog}
        openCancelDialog={openCancelDialog}
      />
    </>
  )
}
