import React from 'react'
import styled from 'styled-components'
import { Box } from '@mui/material'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { styled as muiStyled } from '@mui/material/styles'

import Messages from '../Messages'
import RuleEditor from '../RuleEditor'
import Rules from '../Rules'
import StandbyAlertEditor from '../StandbyAlertEditor'
import SecondaryNav, { OptionalIconLink } from '../../../Shared/components/MUIComponents/SecondaryNav/SecondaryNav'
import { themeColors } from '../../../Shared/utils'

const Wrapper = muiStyled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
}))

const LocalLayout = styled.div`
  height: 100%;
  min-height: 30vh;
  margin: 0;
  color: ${themeColors.primaryDark};
  overflow-y: auto;
`

const LayoutInner = styled.div`
  padding: 1.8em;
  background-color: ${themeColors.white};
  height: 10%;

  h1 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 1em;
    color: ${themeColors.secondary};

    small {
      color: ${themeColors.greyLight};
      font-size: 16px;
      font-weight: 300;
      display: block;
      text-transform: none;
    }
  }

  h2 {
    font-size: 1.3em;
    font-weight: 500;
    margin-bottom: 1em;
    color: ${themeColors.secondary};
  }
`

export const navRoutes = {
  messages: '/alerts/messages',
  rules: '/alerts/rules',
  standby: '/alerts/rules/standby',
}

const secondaryLinks: OptionalIconLink[] = [
  {
    name: 'Messages',
    i18n: 'notifications.subroutes.messages',
    path: navRoutes.messages,
  },
  {
    name: 'Rules',
    i18n: 'notifications.subroutes.rules',
    path: navRoutes.rules,
  },
]
const Alerts = (props: RouteComponentProps): JSX.Element => {
  return (
    <Wrapper>
      <LocalLayout>
        <Box sx={{ marginBottom: 1 }}>
          <SecondaryNav links={secondaryLinks} />
        </Box>
        <Box>
          <LayoutInner>
            <Switch>
              <Route
                exact
                path="/alerts"
                render={() => <Redirect to={navRoutes.messages} />}
              />
              <Route
                path={navRoutes.messages}
                component={Messages}
              />
              <Route
                exact
                path={navRoutes.rules}
                render={() => <Rules {...props} />}
              />
              <Route
                exact
                path={`${navRoutes.rules}/:ruleId`}
                render={() => <RuleEditor {...props} />}
              />
              <Route
                exact
                path={`${navRoutes.standby}/:standbyAlertId`}
                render={() => <StandbyAlertEditor {...props} />}
              />
              <Redirect to="/alerts" />
            </Switch>
          </LayoutInner>
        </Box>
      </LocalLayout>
    </Wrapper>
  )
}

export default Alerts
