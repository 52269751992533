import { DateTime } from 'luxon'

import { Asset } from '../../Shared/graphql/codegen'
import { StopReasonSummary } from '../types/Assets.types'

const calculateDuration = (start: string, end: string | null): number => {
  const startTime = DateTime.fromISO(start)
  const endTime = end ? DateTime.fromISO(end) : DateTime.now()
  return endTime.diff(startTime, 'minutes').minutes
}

const updateSummary = (
  summary: Record<string, StopReasonSummary>,
  reason: string,
  id: string,
  duration: number
): void => {
  if (!summary[reason]) {
    summary[reason] = {
      reasonId: id,
      reasonName: reason,
      totalDurationInMinutes: 0,
      count: 0,
      averageDurationInMinutes: 0,
    }
  }
  summary[reason].totalDurationInMinutes += duration
  summary[reason].count += 1
}

const calculateAverages = (summary: Record<string, StopReasonSummary>): void => {
  Object.keys(summary).forEach(reason => {
    const data = summary[reason]
    data.averageDurationInMinutes = data.count > 0 ? data.totalDurationInMinutes / data.count : 0
  })
}

export const aggregateStopsByReason = (
  assets: Asset[],
  defaultStopReason: string
): Record<string, StopReasonSummary> => {
  const summary: Record<string, StopReasonSummary> = {}

  assets.forEach(asset => {
    const productionStopsData = asset?.enoceanPulseCounterSensorLocations?.[0]?.productionStops ?? []
    productionStopsData.forEach(stop => {
      const duration = calculateDuration(stop.startTime, stop.endTime)

      if (stop.productionStopReasonLogs.length === 0) {
        updateSummary(summary, defaultStopReason, 'default', duration)
      } else {
        stop.productionStopReasonLogs.forEach(log => {
          const reasonName = log.customerProductionStopReason?.text ?? defaultStopReason
          const reasonId = log.customerProductionStopReason?.text ? log.customerProductionStopReason?.id : 'default'
          updateSummary(summary, reasonName, reasonId, duration)
        })
      }
    })
  })

  calculateAverages(summary)

  return summary
}
