import React, { useMemo } from 'react'
import { Box, IconButton, MenuItem, SelectProps } from '@mui/material'
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

import {
  AddButton,
  AddButtonText,
} from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { AlertInterval, Recipient, RecipientDestination } from '../../../types/alertTypes'
import { GeneralAlertRuleRecipientChannelsEnum } from '../../../../Shared/graphql/codegen'
import { PlusIcon, TrashIcon } from '../../../../Shared/components/icons'
import { StyledFlexBox, StyledLabel } from '../AlertConfigurationModal.styled'
import { StyledSelect } from '../../../../Shared/components/MUIComponents/update/Selectv1/StyledSelect'
import { StyledTextField } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

interface IRecipientsFormSectionProps<T extends FieldValues> {
  name: Path<T>
  selectProps: SelectProps<string>
  control: Control<T>
  errors: FieldErrorsImpl<T>
  watch: UseFormWatch<T>
  setValue: UseFormSetValue<T>
}

type RecipientError = {
  name?: FieldError
  channel?: FieldError
  destination?: FieldError
}

export const RecipientsFormSection = <T extends FieldValues>({
  name,
  control,
  errors,
  selectProps,
  setValue,
  watch,
}: IRecipientsFormSectionProps<T>) => {
  const emptyRecipient: Recipient = {
    id: '',
    channel: GeneralAlertRuleRecipientChannelsEnum.Email,
    name: '',
    destination: '',
  }
  const existingRecipients: AlertInterval[] = watch(name)

  const { i18n } = useI18nContext()

  // For now, we have only email available for ca
  const channels = [
    { key: RecipientDestination.EMAIL, option: i18n.text('rules.recipients.email') },
    // { key: RecipientDestination.WHATSAPP, option: i18n.text('rules.recipients.whatsapp') },
    // { key: RecipientDestination.WEBHOOK, option: i18n.text('rules.recipients.webhook') },
  ]

  const recipientsErrors = useMemo(() => (errors.recipients || []) as RecipientError[], [errors])

  const addRecipient = () => {
    if (existingRecipients) {
      setValue(name, [...existingRecipients, emptyRecipient] as PathValue<T, Path<T>>)
    }
  }

  const removeRecipient = (index: number) => {
    const newRecipients: AlertInterval[] = existingRecipients?.filter((recipient, idx: number) => idx !== index)
    setValue(name, newRecipients as PathValue<T, Path<T>>)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        borderTop: theme => `1px solid ${theme.palette.SFIGreyLight[200]}`,
        paddingTop: '1rem',
      }}
    >
      <StyledLabel>{i18n.text('rules.headers.recipients')}</StyledLabel>
      {existingRecipients?.map((recipient: AlertInterval, index: number) => (
        <Box
          key={index}
          sx={{
            display: 'grid',
            gridTemplateColumns: '0.75fr 1fr 1fr auto',
            gap: '2rem',
          }}
        >
          <StyledFlexBox>
            {index === 0 && <StyledLabel>{i18n.text('rules.headers.recipients.type')}</StyledLabel>}

            <Controller
              name={`${name}.${index}.channel` as Path<T>}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <StyledSelect
                  {...selectProps}
                  size="small"
                  labelId="asset-label"
                  id="asset-select"
                  defaultValue={GeneralAlertRuleRecipientChannelsEnum.Email}
                  onChange={onChange}
                >
                  {channels.map(channel => (
                    <MenuItem
                      key={channel.key}
                      value={channel.key}
                    >
                      {channel.option}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
            />
          </StyledFlexBox>
          <StyledFlexBox>
            {index === 0 && <StyledLabel>{i18n.text('rules.recipients.name')}</StyledLabel>}
            <Controller
              name={`${name}.${index}.name` as Path<T>}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StyledTextField
                  id={`recipient-name-input-${index}`}
                  size="small"
                  placeholder={i18n.text('rules.placeholders.recipients.name')}
                  error={!!recipientsErrors[index]?.name}
                  helperText={recipientsErrors[index]?.name?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </StyledFlexBox>
          <StyledFlexBox>
            {index === 0 && <StyledLabel>{i18n.text('rules.recipients.destination')}</StyledLabel>}
            <Controller
              name={`${name}.${index}.destination` as Path<T>}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StyledTextField
                  id={`recipient-destination-input-${index}`}
                  size="small"
                  placeholder={i18n.text('rules.placeholders.recipients.email')}
                  error={!!recipientsErrors[index]?.destination}
                  helperText={recipientsErrors[index]?.destination?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </StyledFlexBox>
          <StyledFlexBox sx={{ alignItems: 'center' }}>
            {index === 0 && <StyledLabel>{i18n.text('integrations.delete')}</StyledLabel>}
            <IconButton
              disabled={index === 0 && existingRecipients.length === 1}
              onClick={() => removeRecipient(index)}
            >
              <TrashIcon sx={{ color: theme => theme.palette.SFIOrange[700] }} />
            </IconButton>
          </StyledFlexBox>
        </Box>
      ))}
      <AddButton
        disableRipple
        onClick={addRecipient}
      >
        <Box sx={{ display: 'flex' }}>
          <PlusIcon
            sx={{
              width: '1.25rem',
              height: '1.25rem',
            }}
          />
        </Box>
        <AddButtonText>{i18n.text('reports.tracking.configuration.reports.recipientsAdd')}</AddButtonText>
      </AddButton>
    </Box>
  )
}
