import React, { FC, useState } from 'react'
import { Box, dialogActionsClasses, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import {
  DateTimePicker,
  TimePicker,
  multiSectionDigitalClockSectionClasses,
  pickersDayClasses,
} from '@mui/x-date-pickers'

import { ClockIcon } from '../../../icons/ClockIcon'
import { FromToValues } from './types'
import { useDateTimeRangePickerContext } from './context/DateTimeRangePickerContext'

interface IDateTimePickersFormProps {
  values: FromToValues
}

interface ICustomDateTimePickerProps extends IDateTimePickersFormProps {
  type: 'from' | 'to'
  labelText: string
  error?: boolean
  onFocus: () => void
  setValues: (fromToValues: FromToValues) => void
}

export const CustomDateTimePicker: FC<ICustomDateTimePickerProps> = ({
  values,
  type,
  labelText,
  error,
  onFocus,
  setValues,
}) => {
  const [timeValue, setTimeValue] = useState<DateTime | null>(null)
  const [dateValue, setDateValue] = useState<DateTime | null>(values[type])
  const { isCompareDatesOn } = useDateTimeRangePickerContext()
  const dateTimePickerFormat = 'dd/MM/yyyy HH:mm'
  const theme = useTheme()

  const textFieldStyle = {
    sx: {
      height: theme.spacing(6),
      borderRadius: `${theme.shape.borderRadius}px`,
      color: theme.palette.SFIGreyLight[600],
      width: '100%',
      [`.MuiMultiSectionDigitalClockSection-root`]: {
        '&:after': {
          display: 'none',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.SFIGreyLight[600]}`,
      },
      '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `3px solid ${theme.palette.SFIBrand[900]}`,
        },
      },
      '& input': {
        height: theme.spacing(6),
        width: '100%',
        padding: isCompareDatesOn ? theme.spacing(0, 0, 0, 1) : theme.spacing(0, 0, 0, 2),
        color: theme.palette.SFIGreyLight[600],
        fontSize: '14px',
      },
      '& label': {
        color: theme.palette.SFIGreyLight[600],
        '&.Mui-focused': {
          color: theme.palette.SFIBrand[900],
        },
      },
    },
  }

  const handleTimeValue = (newValue: DateTime | null) => {
    const newTime =
      values[type]?.set({
        hour: newValue?.hour,
        minute: newValue?.minute,
      }) || null
    setTimeValue(newTime)
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <TimePicker
        value={values.from}
        onChange={handleTimeValue}
        onClose={() => setValues({ ...values, [type]: timeValue })}
        closeOnSelect={false}
        format={dateTimePickerFormat}
        ampm={false}
        sx={{
          position: 'absolute',
          opacity: 0,
          zIndex: 2,
          width: isCompareDatesOn ? theme.spacing(3.75) : theme.spacing(6.25),
          top: 0,
          right: 0,
          '& input': {
            paddingLeft: 0,
            paddingRight: 0,
            width: 0,
          },
          '& button': {
            width: '100%',
            padding: isCompareDatesOn ? 0 : theme.spacing(1),
          },
        }}
        slotProps={{
          popper: { placement: 'bottom-end' },
          layout: {
            sx: {
              width: theme.spacing(16.5),
              [`.${pickersDayClasses.selected}`]: {
                backgroundColor: `${theme.palette.SFIBrand[900]}!important`,
              },
              [`& .${multiSectionDigitalClockSectionClasses.root}`]: {
                width: theme.spacing(8.25),
              },
              [`& .${multiSectionDigitalClockSectionClasses.item}`]: {
                width: theme.spacing(7.25),
              },
              [`.${multiSectionDigitalClockSectionClasses.item}.Mui-selected`]: {
                backgroundColor: theme.palette.SFIBrand[900],
              },
              [`& .${dialogActionsClasses.root}`]: {
                justifyContent: 'center',
              },
              [`.${multiSectionDigitalClockSectionClasses.root}`]: {
                '&:after': {
                  display: 'none',
                },
              },
            },
          },
        }}
      />
      <DateTimePicker
        label={labelText}
        value={dateValue}
        format={dateTimePickerFormat}
        disableOpenPicker
        ampm={false}
        slotProps={{
          textField: { ...textFieldStyle, error, onFocus, onBlur: () => setValues({ ...values, [type]: dateValue }) },
        }}
        onChange={newValue => {
          setDateValue(newValue)
        }}
      />
      <ClockIcon
        sx={{
          position: 'absolute',
          top: 14,
          right: isCompareDatesOn ? 4 : 14,
          width: isCompareDatesOn ? '0.8em' : '1em',
        }}
      />
    </Box>
  )
}
