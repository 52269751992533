import { FC, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, styled } from '@mui/material'

import ShiftComparisonTableRow from './ShiftComparisonTableRow'
import ShiftDetailsModal from '../ShiftDetailsModal/ShiftDetailsModal'
import { ShiftComparisonDataItem, ShiftInterval } from '../../types/ShiftComparison.types'
import { getShiftComparisonHeadCells } from '../../utils/tableHeads'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type ShiftComparisonTableProps = {
  shiftComparisonTableData: ShiftComparisonDataItem[]
}

const ShiftComparisonTable: FC<ShiftComparisonTableProps> = ({ shiftComparisonTableData }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false)
  const [currentLocationId, setCurrentLocationId] = useState<string>('')
  const [currentMachineName, setCurrentMachineName] = useState<string>('')
  const [currentShiftInterval, setCurrentShiftInterval] = useState<ShiftInterval | null>(null)

  const { i18n } = useI18nContext()

  const getIsEven = (index: number) => index % 2 === 0

  const handleDetailsOpen = (locationId: string, machineName: string, shiftInterval: ShiftInterval) => {
    setCurrentLocationId(locationId)
    setCurrentMachineName(machineName)
    setCurrentShiftInterval(shiftInterval)
    setIsDetailsOpen(true)
  }

  const handleDetailsClose = () => {
    setIsDetailsOpen(false)
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {getShiftComparisonHeadCells().map(headCell => (
              <StyledTableHeadCell
                key={headCell.id}
                align={headCell.align}
              >
                {`${i18n.text(headCell.label)} ${headCell.unit ? headCell.unit : ''}`}
              </StyledTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {shiftComparisonTableData.map((shiftComparisonTableDataItem, index) => (
            <ShiftComparisonTableRow
              key={`${shiftComparisonTableDataItem.machineName}-${index}`}
              machine={shiftComparisonTableDataItem}
              isHighlighted={getIsEven(index)}
              onDetailsOpen={handleDetailsOpen}
            />
          ))}
        </TableBody>
      </Table>
      {isDetailsOpen && currentLocationId && currentShiftInterval && (
        <ShiftDetailsModal
          locationId={currentLocationId}
          shiftInterval={currentShiftInterval}
          machineName={currentMachineName}
          isOpen={isDetailsOpen}
          onClose={handleDetailsClose}
        />
      )}
    </>
  )
}

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  backgroundColor: theme.palette.SFIGreyLight[50],
  padding: '0.8rem 1rem',
  fontSize: '0.75rem',
  fontWeight: '500',
  lineHeight: '1.125rem',
  color: theme.palette.SFIGreyLight[600],
}))

export default ShiftComparisonTable
