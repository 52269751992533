import React from 'react'
import { createSvgIcon } from '@mui/material'

export const ArrowRight = createSvgIcon(
  <svg
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33325 6H14.6666M14.6666 6L9.66659 1M14.6666 6L9.66659 11"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'arrow-right'
)
