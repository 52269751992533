import { createSvgIcon } from '@mui/material'

export const FileTypeIcon = createSvgIcon(
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
      stroke="#D0D5DD"
      strokeWidth="1.5"
    />
    <path
      d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5"
      stroke="#D0D5DD"
      strokeWidth="1.5"
    />
    <path
      d="M1 20C1 18.8954 1.89543 18 3 18H25C26.1046 18 27 18.8954 27 20V32C27 33.1046 26.1046 34 25 34H3C1.89543 34 1 33.1046 1 32V20Z"
      fill="#4793FF"
    />
    <path
      d="M6.38947 22.7273V30H4.85183V22.7273H6.38947ZM7.65456 22.7273H9.55087L11.5537 27.6136H11.6389L13.6418 22.7273H15.5381V30H14.0466V25.2663H13.9862L12.1041 29.9645H11.0885L9.20641 25.2486H9.14604V30H7.65456V22.7273ZM21.6274 25.0781C21.5777 24.9053 21.5078 24.7526 21.4179 24.62C21.3279 24.4851 21.2178 24.3714 21.0876 24.2791C20.9598 24.1844 20.813 24.1122 20.6473 24.0625C20.4839 24.0128 20.3028 23.9879 20.104 23.9879C19.7323 23.9879 19.4056 24.0803 19.1238 24.2649C18.8445 24.4496 18.6267 24.7183 18.4704 25.071C18.3142 25.4214 18.2361 25.8499 18.2361 26.3565C18.2361 26.8632 18.313 27.294 18.4669 27.6491C18.6208 28.0043 18.8386 28.2753 19.1203 28.4624C19.402 28.647 19.7346 28.7393 20.1182 28.7393C20.4662 28.7393 20.7633 28.6778 21.0095 28.5547C21.2581 28.4292 21.4475 28.2528 21.5777 28.0256C21.7103 27.7983 21.7765 27.5296 21.7765 27.2195L22.089 27.2656H20.214V26.108H23.2574V27.0241C23.2574 27.6634 23.1224 28.2126 22.8525 28.6719C22.5827 29.1288 22.211 29.4815 21.7375 29.7301C21.264 29.9763 20.7219 30.0994 20.1111 30.0994C19.4292 30.0994 18.8303 29.9491 18.3142 29.6484C17.7981 29.3454 17.3956 28.9157 17.1068 28.3594C16.8203 27.8007 16.6771 27.1378 16.6771 26.3707C16.6771 25.7812 16.7623 25.2557 16.9328 24.794C17.1056 24.33 17.3471 23.937 17.6572 23.6151C17.9674 23.2931 18.3284 23.0481 18.7403 22.88C19.1523 22.7119 19.5985 22.6278 20.0791 22.6278C20.491 22.6278 20.8746 22.6882 21.2297 22.8089C21.5848 22.9273 21.8997 23.0954 22.1743 23.3132C22.4513 23.531 22.6773 23.7902 22.8525 24.0909C23.0277 24.3892 23.1402 24.7183 23.1899 25.0781H21.6274Z"
      fill="white"
    />
  </svg>,
  'file-type-icon'
)
