import { CreateGeneralAlertRuleRecipientsInput } from '../../Shared/graphql/codegen'
import { CurrentPropositions, PropositionInfo } from '../../Shared/hooks/usePropositions/usePropositions'
import { AutocompleteOption } from '../../Shared/types/types'

export enum AlertTypes {
  'CA_FLOW' = 'Air flow alert',
  'CA_CONSUMPTION' = 'Air consumption alert',
  'CA_PRESSURE' = 'Air pressure alert',
  'CA_TEMPERATURE' = 'Air temperature alert',
  'POWER_ALERT' = 'Power alerts',
  'STANDBY_ALERT' = 'Standby alerts',
  'DOWNTIME_ALERT' = 'Downtime alert',
  'THRESHOLD_ALERT' = 'Vibration threshold alert',
  'GAS_ALERT' = 'Gas consumption alert',
  'WATER_ALERT' = 'Water consumption alert',
}

export type Propositions = { [key in CurrentPropositions]: PropositionInfo }

export enum SearchType {
  PRODUCTION_SPEED = 'productionSpeed',
  POWER = 'power',
  VOLUME = 'volume',
}

export interface Recipient extends CreateGeneralAlertRuleRecipientsInput {
  id?: string
}

export interface IAlertRuleData {
  id: string
  asset: string
  message: string
  category: string
  recipient: string[] | AutocompleteOption[]
  action: string
  product: string
}

export interface Recipient extends CreateGeneralAlertRuleRecipientsInput {
  id?: string
}

export type AlertInterval = {
  id?: string
  fromDay: string
  fromTime: string
  toDay: string
  toTime: string
}

export enum ThresholdOperatorType {
  LESS = 'LESS',
  GREATER = 'GREATER',
  BETWEEN = 'BETWEEN',
}

export enum RecipientDestination { 
  EMAIL = 'EMAIL',
  WEBHOOK = 'WEBHOOK',
  WHATSAPP = 'WHATSAPP'
}
