import React from 'react'

import ThresholdRuleEditor from '../ThresholdRuleEditor'
import { AlertRulePayloadType } from '../../../../Shared/graphql/codegen'
import { AssetGroup, ThresholdRuleOptions, ThresholdRuleSelection } from '../types'
import { styled } from '@mui/material'
import { AssetSelection, AssetSelector } from '../../../components/AssetSelector'
import { ID } from '../../../../Shared/types/types'

export interface TriggerData {
  id: ID
  assetSelection: AssetSelection
  trigger: ThresholdRuleSelection
}

const Container = styled('div')(({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#f5f5f5',
  borderRadius: '4px'
}))

const FormSectionInner = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '1em'
})

interface AssetTriggerProps {
  assets: AssetGroup[]
  i18n: $I18FixMe
  id: ID
  selectedAsset: AssetSelection
  triggerOptions: ThresholdRuleOptions
  trigger: ThresholdRuleSelection
  onChange: (value: TriggerData) => void
  type: AlertRulePayloadType
}

const AssetTrigger = ({
  i18n,
  assets,
  triggerOptions,
  id,
  selectedAsset,
  trigger,
  type = AlertRulePayloadType.Volume,
  onChange,
}: AssetTriggerProps): JSX.Element => {
  const onAssetChange = (data: AssetSelection) => {
    onChange({
      id,
      trigger,
      assetSelection: data,
    })
  }

  const onThresholdChange = (data: ThresholdRuleSelection) => {
    onChange({
      id,
      trigger: data,
      assetSelection: selectedAsset,
    })
  }

  return (
    <Container>
      <FormSectionInner>
        <AssetSelector
          key={`asset-selector-${id}`}
          assets={assets}
          triggerType={type}
          i18n={i18n}
          onChange={onAssetChange}
          selected={selectedAsset}
        />
      </FormSectionInner>
      <FormSectionInner>
        <ThresholdRuleEditor
          key={`threshold-rule-editor-${id}`}
          type={type}
          i18n={i18n}
          selected={trigger}
          options={triggerOptions}
          onChange={onThresholdChange}
        />
      </FormSectionInner>
    </Container>
  )
}

export default AssetTrigger
