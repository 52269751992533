import ReactDOMServer from 'react-dom/server'
import { Box, styled } from '@mui/material'
import { FC } from 'react'

import MuiThemeProvider from '../../../Shared/theme/MuiThemeProvider'
import { ExportIcon } from '../../../Shared/components/icons/ExportIcon'
import { PanIcon } from '../../../Shared/components/icons/PanIcon'
import { StyledApexWrapper } from '../../../Shared/components/ApexWrapper'
import { ZoomIcon } from '../../../Shared/components/icons/ZoomIcon'
import { ZoomInIcon } from '../../../Shared/components/icons/ZoomInIcon'
import { ZoomOutIcon } from '../../../Shared/components/icons/ZoomOutIcon'

const StyledApexWrapperPDM = styled(StyledApexWrapper)(({ theme }) => ({
  '.apexcharts-toolbar': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'none',
    height: theme.spacing(5),
    border: `none`,
    width: `${theme.spacing(30)} !important`,
    maxWidth: 'none !important',
    gap: 0,
    padding: 1,
  },
  '.apexcharts-canvas': {
    cursor: 'move',
  },
  '.apexcharts-menu-icon': {
    height: '100%',
    width: '44px',
    transform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.SFIGreyLight[700],
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.SFIGreyLight[200],
      'svg.apexcharts-menu-icon': {
        svg: {
          fill: 'none !important',
        },
      },
    },
    svg: {
      fill: 'none !important',
    },
  },
  // '.apexcharts-toolbar-custom-icon': {
  '.apexcharts-pan-icon': {
    height: '100%',
    width: '44px',
    transform: 'none',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0.75),
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    color: theme.palette.SFIGreyLight[700],
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.SFIGreyNeutral[50],
    },
    '&.apexcharts-selected': {
      backgroundColor: theme.palette.SFIGreyLight[200], // Example style
    },
    svg: {
      fill: 'none',
    },
  },
  'apexcharts-selected': {
    backgroundColor: theme.palette.SFIGreyLight[200],
  },
  '.apexcharts-zoom-icon': {
    height: '100%',
    width: '44px',
    transform: 'none',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0.75),
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    color: theme.palette.SFIGreyLight[700],
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.SFIGreyNeutral[50],
    },
    '&.apexcharts-selected': {
      backgroundColor: theme.palette.SFIGreyLight[200], // Example style
    },
    svg: {
      fill: 'none !important',
      height: '1.25rem',
      width: '1.25rem',
    },
  },
  '.apexcharts-zoomin-icon': {
    borderRadius: '6px 0px 0px 6px',
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.SFIGreyLight[200],
      'svg.zoom-in-icon': {
        svg: {
          fill: 'none',
        },
      },
    },
  },
  '.apexcharts-zoomout-icon': {
    borderRadius: '0px 6px 6px 0px',
    border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.SFIGreyLight[200],
      'svg.zoom-out-icon': {
        svg: {
          fill: 'none !important',
        },
      },
    },
    svg: {
      fill: 'none !important',
    },
  },
}))

const ApexWrapper: FC = ({ children }) => {
  return <StyledApexWrapperPDM id="chart-wrapper">{children}</StyledApexWrapperPDM>
}

const CustomZoomInIcon = () => (
  <ZoomInIcon
    sx={{ fontSize: '1.25rem' }}
    className="zoom-in-icon"
  />
)

const CustomZoomOutIcon = () => (
  <ZoomOutIcon
    sx={{ fontSize: '1.25rem' }}
    className="zoom-out-icon"
  />
)

const CustomPanElement = ({ i18n }: { i18n: $TSFixMe }) => {
  return (
    <MuiThemeProvider>
      <Box
        data-testid="pan-tooltip"
        sx={{
          display: 'flex',
          position: 'relative',
          '> .tooltip': {
            opacity: 0,
            top: '-3.3rem',
            right: '-2.6rem',
            width: '108px',
            boxShadow: `0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);`,
            backgroundColor: theme => theme.palette.SFIBase.white,
            color: theme => theme.palette.SFIGreyLight[600],
            borderRadius: theme => theme.spacing(1),
            padding: theme => theme.spacing(1.5),
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: theme => theme.typography.pxToRem(18),
            position: 'absolute',
            transition: 'all 200ms linear',
            '::after': {
              content: '""',
              position: 'absolute',
              width: 0,
              height: 0,
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderTop: '8px solid white',
              bottom: '-8px',
              right: '50%',
              transform: 'translateX(50%)',
            },
          },
          '&:hover': {
            '> .tooltip': {
              opacity: 1,
            },
          },
        }}
      >
        <PanIcon
          className="pan-icon"
          sx={{ fontSize: '1.75rem', position: 'relative', top: '2px', left: '3px' }}
        />
        <Box className="tooltip">{i18n.text('pdm.graphs.toolbar.pan.tooltip')}</Box>
      </Box>
    </MuiThemeProvider>
  )
}

const CustomExportIcon = () => (
  <ExportIcon
    sx={{ fontSize: '1.25rem', position: 'relative', top: '2px', left: '3px' }}
    className="exportIcon-icon"
  />
)

const zoomInIconHTML = ReactDOMServer.renderToString(
  <MuiThemeProvider>
    <CustomZoomInIcon />
  </MuiThemeProvider>
)

const zoomOutIconHTML = ReactDOMServer.renderToString(
  <MuiThemeProvider>
    <CustomZoomOutIcon />
  </MuiThemeProvider>
)

const zoomIconHTML = ReactDOMServer.renderToString(
  <MuiThemeProvider>
    <ZoomIcon />
  </MuiThemeProvider>
)

const customPanIcon = (i18n: $TSFixMe) => {
  return ReactDOMServer.renderToString(<CustomPanElement i18n={i18n} />)
}

const exportIconHTML = ReactDOMServer.renderToString(
  <MuiThemeProvider>
    <CustomExportIcon />
  </MuiThemeProvider>
)

export { ApexWrapper, zoomInIconHTML, zoomOutIconHTML, customPanIcon, exportIconHTML, zoomIconHTML }
