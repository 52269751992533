import { Box, styled } from '@mui/material'

import { HelpIcon } from '../../../Shared/components/icons/HelpIcon'
import { StyledTooltip } from '../../../Shared/components/MUIComponents/Tooltip'

const TooltipInnerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  marginLeft: theme.spacing(0.5),
}))

export const StyledHelpIconAndTooltip = ({ title, ...props }: { title: string }) => (
  <StyledTooltip title={title}>
    <TooltipInnerContainer>
      <HelpIcon sx={theme => ({ fontSize: '1rem', color: theme.palette.SFIGreyLight[400] })} />
    </TooltipInnerContainer>
  </StyledTooltip>
)
