import { useMediaQuery, useTheme } from '@mui/material'

import { useChartToolsContext } from '../context/ChartToolsContext'

export const useEnergyBalancePngExporterOptions = () => {
  const theme = useTheme()
  const isBelowLg = useMediaQuery(theme.breakpoints.down('lg'))
  const { showTable, energyTableContainerHeight } = useChartToolsContext()

  const pngExporterOptions = (function () {
    if (!isBelowLg && showTable)
      return {
        height: energyTableContainerHeight,
        onclone: (document: Document) => {
          const tableContainer = document.querySelector('#analysis-table')
          if (tableContainer) {
            ;(tableContainer as any).style['overflow-y'] = 'visible'
          }
        },
      }
    return {}
  })()
  return pngExporterOptions
}
