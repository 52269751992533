import { Box, RadioGroup } from '@mui/material'
import { FC } from 'react'

import { StyledFormControlLabel } from '../../../../../Shared/components/MUIComponents/update/Radio/StyledFormControlLabel'
import { StyledInputFieldHeader, StyledTooltipWithIcon } from '../Common'
import { StyledRadio } from '../../../../../Shared/components/MUIComponents/update/Radio/StyledRadio'
import { TimeGroupingSelectProps } from '../types'
import { useGetAvailableTimeGroupingLevelsQuery } from '../../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const TimeGroupingSelect: FC<TimeGroupingSelectProps> = ({
  timeGroupingLevel,
  handleTimeGroupingChange,
  frequency,
}) => {
  const { i18n } = useI18nContext()

  const { data: availableTimeGroupingData } = useGetAvailableTimeGroupingLevelsQuery()
  const availableTimeGroupingLevels =
    availableTimeGroupingData?.trackingReportTimeGroupingLevels.map(timeGroupingLevel => timeGroupingLevel.value) || []

  function getFilteredTimeGroupingLevels(frequency: string) {
    return frequency === 'weekly'
      ? availableTimeGroupingLevels.filter(grouping => grouping !== 'month')
      : availableTimeGroupingLevels.filter(grouping => grouping !== 'day')
  }
  const filteredTimeGroupingLevels = getFilteredTimeGroupingLevels(frequency)

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '30% 70%',
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.125rem', paddingRight: '1rem' }}
        >
          <StyledInputFieldHeader sx={{ alignContent: 'center' }}>
            {i18n.text(`reports.tracking.configuration.module.aggregation`)}
          </StyledInputFieldHeader>
          <StyledTooltipWithIcon
            title={i18n.text(`reports.tracking.configuration.tooltip.timeGroupingSelect.${frequency}`)}
          ></StyledTooltipWithIcon>
        </Box>
        <RadioGroup
          row
          value={timeGroupingLevel}
          onChange={handleTimeGroupingChange}
        >
          {' '}
          {filteredTimeGroupingLevels &&
            filteredTimeGroupingLevels.map((timeGroupingLevel, index) => (
              <StyledFormControlLabel
                key={index}
                value={timeGroupingLevel}
                control={<StyledRadio />}
                label={i18n.text(`reports.tracking.configuration.module.${timeGroupingLevel}`)}
              />
            ))}
        </RadioGroup>
      </Box>
    </>
  )
}
