import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TypographyProps,
  accordionSummaryClasses,
  styled,
  useTheme,
} from '@mui/material'
import { AccordionProps, AccordionSummaryProps } from '@mui/material'

import { ArrowIcon } from '../../../icons'
import { StyledCheckbox as Checkbox } from '../Checkbox/StyledCheckBox'

type ParentNode = {
  readonly id: string
  readonly name: string
}

interface IParentNodeProps<T> {
  parent: Readonly<T>
  isSelected: boolean
  children: React.ReactNode
  isAllSelected?: boolean
  hasCheckbox?: boolean
  hasExpandIcon?: boolean
  disableExpand?: boolean
  isExpanded?: boolean
  isIndeterminate?: boolean
  onCheckboxToggle?: () => void
}

interface IStyledAccordionSummaryProps extends AccordionSummaryProps {
  isSelected: boolean
}

interface IStyledAccordionSummaryTitleProps extends TypographyProps {
  isSelected: boolean
}

export function AssetTreeParentNode<T extends ParentNode>({
  parent,
  isAllSelected,
  hasCheckbox,
  isSelected,
  children,
  isExpanded = true,
  hasExpandIcon = true,
  disableExpand = false,
  isIndeterminate,
  onCheckboxToggle,
}: IParentNodeProps<T>) {
  const [expanded, setExpanded] = useState(disableExpand ? disableExpand : isExpanded)
  const theme = useTheme()

  const handleParentClick = () => {
    if (disableExpand) return
    setExpanded(!expanded)
  }

  const handleCheckboxChange = () => {
    if (!onCheckboxToggle) return
    onCheckboxToggle()
  }

  useEffect(() => {
    if (isSelected) {
      !disableExpand && setExpanded(true)
    }
  }, [isSelected])

  const isCheckboxIndeterminate = () => {
    return isIndeterminate ?? (!isAllSelected && isSelected)
  }
  return (
    <StyledAccordion
      expanded={disableExpand ? disableExpand : expanded}
      disableGutters
      elevation={0}
      data-testid="tree-asset-accordion"
    >
      <StyledAccordionSummary
        expandIcon={
          hasExpandIcon ? (
            <ArrowIcon
              fontSize="inherit"
              sx={{ color: theme.palette.SFIGreyLight[400] }}
            />
          ) : null
        }
        aria-controls="tree-asset-content"
        id="tree-asset-header"
        isSelected={!!(isSelected || isAllSelected)}
        onClick={handleParentClick}
      >
        {hasCheckbox && (
          <Checkbox
            checked={isAllSelected}
            indeterminate={isCheckboxIndeterminate()}
            color="secondary"
            size="small"
            sx={{
              padding: 0,
            }}
            onClick={e => {
              e.stopPropagation()
            }}
            onChange={handleCheckboxChange}
          />
        )}
        <StyledAccordionSummaryTitle
          variant="body1"
          isSelected={!!(isSelected || isAllSelected)}
        >
          {parent.name}
        </StyledAccordionSummaryTitle>
      </StyledAccordionSummary>
      <AccordionDetails sx={{ padding: 0, marginBottom: expanded ? theme.spacing(1) : 0 }}>{children}</AccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordion = styled(Accordion)<AccordionProps>(({ theme }) => ({
  border: 'none',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
}))

const StyledAccordionSummary = ({ isSelected, children, ...props }: IStyledAccordionSummaryProps) => (
  <AccordionSummary
    sx={{
      background: theme => (isSelected ? theme.palette.SFIBrand?.[100] : 'initial'),
      borderBottom: 'none',
      transform: 'rotate(0deg)',
      transition: 'transform 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      margin: theme => theme.spacing(0, 0, 1),
      padding: theme => theme.spacing(1, 1.5),
      borderRadius: '6px',
      [`.${accordionSummaryClasses.content}`]: {
        margin: 0,
        display: 'flex',
        width: '100%',
        overflow: 'visible',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        paddingRight: theme => theme.spacing(1.5),
        gap: theme => theme.spacing(1.5),
      },
    }}
    {...props}
  >
    {children}
  </AccordionSummary>
)

const StyledAccordionSummaryTitle = ({ isSelected, children, ...props }: IStyledAccordionSummaryTitleProps) => (
  <Typography
    sx={{
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '24px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme => (isSelected ? theme.palette.SFIBrand?.[900] : theme.palette.SFIGreyLight[700]),
    }}
  >
    {children}
  </Typography>
)
