import { createSvgIcon } from '@mui/material'

export const MessageSmileCircleIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M9.49962 14C9.49962 14 10.8121 15.5 12.9996 15.5C15.1871 15.5 16.4996 14 16.4996 14M15.7496 9H15.7596M10.2496 9H10.2596M12.9996 20C17.694 20 21.4996 16.1944 21.4996 11.5C21.4996 6.80558 17.694 3 12.9996 3C8.3052 3 4.49962 6.80558 4.49962 11.5C4.49962 12.45 4.65547 13.3636 4.943 14.2166C5.05119 14.5376 5.10529 14.6981 5.11505 14.8214C5.12469 14.9432 5.1174 15.0286 5.08728 15.1469C5.05677 15.2668 4.98942 15.3915 4.85472 15.6408L3.21906 18.6684C2.98575 19.1002 2.86909 19.3161 2.8952 19.4828C2.91794 19.6279 3.00337 19.7557 3.1288 19.8322C3.2728 19.9201 3.51692 19.8948 4.00517 19.8444L9.12619 19.315C9.28127 19.299 9.35881 19.291 9.42949 19.2937C9.499 19.2963 9.54807 19.3029 9.61586 19.3185C9.68478 19.3344 9.77145 19.3678 9.94478 19.4345C10.8928 19.7998 11.9228 20 12.9996 20ZM16.2496 9C16.2496 9.27614 16.0258 9.5 15.7496 9.5C15.4735 9.5 15.2496 9.27614 15.2496 9C15.2496 8.72386 15.4735 8.5 15.7496 8.5C16.0258 8.5 16.2496 8.72386 16.2496 9ZM10.7496 9C10.7496 9.27614 10.5258 9.5 10.2496 9.5C9.97348 9.5 9.74962 9.27614 9.74962 9C9.74962 8.72386 9.97348 8.5 10.2496 8.5C10.5258 8.5 10.7496 8.72386 10.7496 9Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'message-smile-circle-icon'
)
