import ReactGA from 'react-ga4'
import debounce from 'lodash.debounce'
import { useCallback, useMemo, useRef } from 'react'

import { AnalyticsEventTypes } from './analyticsTypes'
import { isTrackingEnabled, logInDev } from '../../utils'
import { useAuthContext } from '../../contexts/AuthContext'

const useAnalytics = () => {
  const { user, currentCustomer, isSensorfactEmployee } = useAuthContext()

  const userId = user?.id ?? null
  const customerId = currentCustomer?.id ?? null
  const isInternal = isSensorfactEmployee()

  const initRef = useRef(false)
  const isInit = () => initRef.current
  const setInit = (value: boolean) => (initRef.current = value)

  const init = useCallback(() => {
    if (isTrackingEnabled()) {
      try {
        ReactGA.initialize(process.env.REACT_APP_GA_4_MEASUREMENT_ID as string)
        setInit(true)
      } catch (e) {
        setInit(false)
        logInDev((e as Error).message)
      }
    } else {
      setInit(false)
    }
  }, [])

  const checkInit = () => {
    if (!isInit()) {
      init()
    }
    if (!isInit()) {
      logInDev(`Analytics is not ${isTrackingEnabled() ? 'initialized' : 'enabled'} `)
      return false
    }
    return true
  }

  /**
   * Events include by default the timestamp, the current pathname and,
   * if available, the current userId, customerId and internalUser boolean.
   * sendEvent is debounced to 50ms
   * @param name The name of the event, either GA4 or custom AnalyticsEventTypes
   * @param params Key value pairs of parameters to send with the event, see analyticsTypes.ts
   */
  function sendEvent<T extends {}>(name: string, params?: T) {
    if (!checkInit()) return
    ReactGA.event(name, {
      timestamp: Date.now(),
      pathname: window.location.pathname,
      internalUserId: userId,
      customerId: customerId,
      isInternal,
      ...params,
    })
  }

  /**
   * sendPageview is debounced to 1000ms
   */
  const sendPageview = () => {
    sendEvent(AnalyticsEventTypes.TRACKED_PAGE_VIEW)
  }

  return useMemo(
    () => ({
      sendEvent: debounce(sendEvent, 50) as typeof sendEvent,
      sendPageview: debounce(sendPageview, 1000) as typeof sendPageview,
    }),
    []
  )
}

export default useAnalytics
