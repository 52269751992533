import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Box, FormControl, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { Typography } from 'antd'
import { useState } from 'react'

import MuiDateTimeRangePickerControlled from '../../../../../Shared/components/MUIComponents/update/MuiDateTimeRangePickerControlled'
import { ChevronDownIcon } from '../../../../../Shared/components/icons/ChevronDownIcon'
import { ID, ISODateTime } from '../../../../../Shared/types/types'
import { LoadingIcon } from '../../../../../Shared/components/icons/LoadingIcon'
import {
  PdmAssetHealthReport,
  PdmAssetHealthReportQuery,
  PdmAssetHealthReportsListQuery,
  Pdm_Asset_Health_Report_Status_Enum,
} from '../../../../../Shared/graphql/codegen'
import { StartEndDatesValues } from '../../../../../Shared/components/MUIComponents/update/MuiDateTimeRangePickerControlled/types'
import { StyledMenu } from '../../../../../Shared/components/MUIComponents/update/StyledMenu'
import { StyledMenuItem } from '../../../../../Shared/components/MUIComponents/update/StyledMenu/StyledMenuItem'
import { getHealthReporShortcutIntervals, getLocaleString, transformHealthReportListData } from '../utils'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

interface SelectHealthReportIntervalProps {
  fromDate: ISODateTime
  toDate: ISODateTime
  sheduledReportsList: PdmAssetHealthReportsListQuery
  report?: PdmAssetHealthReportQuery
  loadingHealthReport: boolean
  handleDateChange: (from: DateTime, to: DateTime) => void
  selectReportId: (id?: ID) => void
}

type SheduledReportIntervalType = { from?: string; to?: string } | null
export const SelectHealthReportInterval = ({
  sheduledReportsList,
  report,
  selectReportId,
  handleDateChange,
}: SelectHealthReportIntervalProps) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const [selectedInterval, setSelectedInterval] = useState<SheduledReportIntervalType>(null)

  const allReports: PdmAssetHealthReport['data'] = [
    ...(sheduledReportsList?.pdmAssetHealthReports || []),
    ...(report?.report ? [report.report] : []),
  ]

  const reportsList = transformHealthReportListData(allReports, i18n)

  const [startEndDatesValues, setStartEndDatesValues] = useState<StartEndDatesValues>({
    startDate: DateTime.now().minus({ days: 1 }).startOf('day'),
    endDate: DateTime.now(),
  })

  const selectedIntervals = {
    from: startEndDatesValues.startDate?.toLocaleString({ day: 'numeric', month: 'long' }),
    to: startEndDatesValues.endDate?.toLocaleString({ day: 'numeric', month: 'long' }),
  }
  const handleMenuClick = (fromDate: string, toDate: string, id: ID) => {
    setSelectedInterval({ from: fromDate, to: toDate })
    selectReportId(id)
  }
  const handleStartEndDates = ({ startDate, endDate }: StartEndDatesValues): void => {
    setStartEndDatesValues({ startDate, endDate })
  }

  const handleDateRangeChange = () => {
    handleDateChange(
      startEndDatesValues.startDate || DateTime.now(),
      startEndDatesValues.endDate || DateTime.now().minus({ days: 7 }).startOf('day')
    )
    setStartEndDatesValues({
      startDate: startEndDatesValues.startDate || DateTime.now(),
      endDate: startEndDatesValues.endDate || DateTime.now().minus({ days: 7 }).startOf('day'),
    })
    setSelectedInterval(selectedIntervals)
  }

  const datePickerShortcuts = getHealthReporShortcutIntervals(i18n)

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <FormControl>
        <Box
          sx={{
            position: 'relative',
            bottom: '0.2rem',
          }}
        >
          <StyledMenu
            key={report?.report?.id || ''}
            menuPosition="right"
            menuButtonContent={
              !selectedInterval ? (
                <>
                  {i18n.text('pdm.health.report.select.interval')}
                  <ChevronDownIcon
                    sx={{
                      position: 'relative',
                    }}
                  />
                </>
              ) : (
                <Typography
                  style={{
                    margin: theme.spacing(0, 3),
                  }}
                >
                  {report?.report?.status === Pdm_Asset_Health_Report_Status_Enum.Pending && (
                    <LoadingIcon
                      size="small"
                      sx={{
                        position: 'absolute',
                        left: 'calc(100% - 2.5rem)',
                        top: 'calc(100% - 1.9rem)',
                        marginLeft: '0.6rem',
                      }}
                    />
                  )}
                  {selectedInterval?.from} / {selectedInterval?.to}
                </Typography>
              )
            }
          >
            <Box
              sx={{
                maxHeight: theme.spacing(30),
                overflowY: 'auto',
              }}
            >
              {reportsList.map((report, index) => {
                const { fromDate, toDate } = getLocaleString(report.fromDate, report.toDate)
                return (
                  <StyledMenuItem
                    key={index}
                    value={report.id}
                    onClick={() => handleMenuClick(fromDate, toDate, report.id as ID)}
                    sx={{
                      fontSize: theme.typography.pxToRem(16),
                      fontWeight: 500,
                      position: 'relative',
                      lineHeight: theme.typography.pxToRem(24),
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {report.custom} {fromDate} / {toDate}
                  </StyledMenuItem>
                )
              })}
            </Box>
            <Box
              sx={{
                borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
              }}
            ></Box>
            <Box
              data-testid="custom-date-range"
              sx={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                margin: '10px 25px 10px 16px',
                left: '1rem',
                backgroundColor: theme.palette.SFIBase.white,
              }}
            >
              <MuiDateTimeRangePickerControlled
                fromToValues={startEndDatesValues}
                anchorOrigin={{
                  vertical: -250,
                  horizontal: 195,
                }}
                shortcutsItems={datePickerShortcuts}
                onDatesChange={handleDateRangeChange}
                handleStartEndDates={handleStartEndDates}
                customDateRangeButtonLabel={i18n.text('pdm.health.report.data.picker.button')} //i18n
                hasPrevNextPeriodButtons={false}
                showCompareDatesButton={false}
                isTimePicker={true}
              />
            </Box>
          </StyledMenu>
        </Box>
      </FormControl>
    </LocalizationProvider>
  )
}
