import React, { useState } from 'react'
import { Box, Button, Typography, styled, useTheme } from '@mui/material'

import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics'
import useCSVExport from '../../../../hooks/useCSVExport'
import { AnalyticsEventTypes, DownloadCSV } from '../../../../hooks/useAnalytics/analyticsTypes'
import { DownloadIcon } from '../../../icons'
import { OutlinedStyledButton } from '../OutlinedStyledButton'
import { StyledDialogModal } from '../../StyledDialogModal'
import { StyledFormControl, StyledInputLabel, StyledTextField } from '../styledComponents/StyledTextField'
import { useI18nContext } from '../../../../contexts/i18nContext/I18nContext'

type CSVExportModalProps<T> = {
  data: T[]
  onClose: () => void
  headers?: (keyof T)[]
  isOpen?: boolean
  customFileName?: string
}

const DEFAULT_FILENAME = 'data.csv'
const DEFAULT_DECIMAL_SEPARATOR = '.'
const DEFAULT_VALUE_SEPARATOR = ','

function CSVExportModal<T>({ data, headers, isOpen, onClose, customFileName }: CSVExportModalProps<T>) {
  const [filename, setFilename] = useState<string>(customFileName ?? DEFAULT_FILENAME)
  const [filenameError, setFilenameError] = useState<string | null>(null)
  const [decimalSeparator, setDecimalSeparator] = useState<string>(DEFAULT_DECIMAL_SEPARATOR)
  const [decimalSeparatorError, setDecimalSeparatorError] = useState<string | null>(null)
  const [valueSeparator, setValueSeparator] = useState<string>(DEFAULT_VALUE_SEPARATOR)
  const [valueSeparatorError, setValueSeparatorError] = useState<string | null>(null)

  const theme = useTheme()
  const { i18n } = useI18nContext()
  const { sendEvent } = useAnalytics()
  const downloadCSV = useCSVExport<T>()

  const exampleHeading = `time${valueSeparator}name${valueSeparator}value`
  const exampleLine = `2024-08-03T12:00:44.000${valueSeparator}AssetName${valueSeparator}3${decimalSeparator}243`

  const handleFilenameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim().length === 0) {
      setFilenameError(i18n.text('export.error-filename'))
      return
    }

    setFilenameError(null)
    setFilename(event.target.value)
  }

  const handleDecimalSeparatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim().length !== 1) {
      setDecimalSeparatorError(i18n.text('export.error-decimal-separator'))
      return
    }

    setDecimalSeparatorError(null)
    setDecimalSeparator(event.target.value)
  }

  const handleValueSeparatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim().length !== 1) {
      setValueSeparatorError(i18n.text('export.error-value-separator'))
      return
    }

    setValueSeparatorError(null)
    setValueSeparator(event.target.value)
  }

  const handleDownloadButtonClick = () => {
    if (filenameError || decimalSeparatorError || valueSeparatorError) return

    downloadCSV({ data, filename, headers, valueSeparator, decimalSeparator })
    sendEvent<DownloadCSV>(AnalyticsEventTypes.TRACKED_DOWNLOAD, { target: 'csv' })
    onClose()
  }

  const handleCancelButtonClick = () => {
    setFilename(customFileName ?? DEFAULT_FILENAME)
    setDecimalSeparator(DEFAULT_DECIMAL_SEPARATOR)
    setValueSeparator(DEFAULT_VALUE_SEPARATOR)
    onClose()
  }

  return (
    <StyledDialogModal
      title=""
      onClose={onClose}
      isOpen={!!isOpen}
      height="responsive"
      maxWidth="xs"
      icon={
        <StyledIconWrapper>
          <DownloadIcon
            sx={{
              width: '1.5rem',
              height: '1.5rem',
            }}
          />
        </StyledIconWrapper>
      }
    >
      <Box sx={{ display: 'grid', rowGap: '1.25rem' }}>
        <Box sx={{ display: 'grid', rowGap: '0.25rem' }}>
          <Typography
            sx={{
              fontSize: '1.125rem',
              fontWeight: 600,
              color: theme.palette.grey[800],
            }}
          >
            {i18n.text('export.dialog-title')}
          </Typography>
          <StyledSecondaryText>{i18n.text('export.instructions')}</StyledSecondaryText>
        </Box>
        <Box sx={{ display: 'grid', rowGap: '0.75rem' }}>
          <StyledFormControl fullWidth>
            <StyledInputLabel
              shrink={false}
              htmlFor="export-filename"
            >
              {i18n.text('Filename')}
            </StyledInputLabel>
            <StyledTextField
              id="export-filename"
              name="filename"
              defaultValue={filename}
              onChange={handleFilenameChange}
              error={Boolean(filenameError)}
              helperText={filenameError ?? ''}
            />
          </StyledFormControl>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem', alignItems: 'start' }}>
            <StyledFormControl>
              <StyledInputLabel
                htmlFor="export-decimal-separator"
                shrink={false}
              >
                {i18n.text('export.decimal-separator')}
              </StyledInputLabel>
              <StyledTextField
                id="export-decimal-separator"
                name="decimalSeparator"
                defaultValue={decimalSeparator}
                onChange={handleDecimalSeparatorChange}
                error={Boolean(decimalSeparatorError)}
                helperText={decimalSeparatorError ?? ''}
              />
            </StyledFormControl>
            <StyledFormControl>
              <StyledInputLabel
                shrink={false}
                htmlFor="export-value-separator"
              >
                {i18n.text('export.value-separator')}
              </StyledInputLabel>
              <StyledTextField
                id="export-value-separator"
                name="valueSeparator"
                defaultValue={valueSeparator}
                onChange={handleValueSeparatorChange}
                error={Boolean(valueSeparatorError)}
                helperText={valueSeparatorError ?? ''}
              />
            </StyledFormControl>
          </Box>
        </Box>
        <Box sx={{ marginBottom: '0.5rem' }}>
          <Typography sx={{ fontSize: '0.875rem', lineHeight: '1.25rem', fontWeight: 600 }}>
            {i18n.text('export.example-output')}:
          </Typography>
          <StyledSecondaryText>{exampleHeading}</StyledSecondaryText>
          <StyledSecondaryText>{exampleLine}</StyledSecondaryText>
        </Box>
      </Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '1rem' }}>
        <OutlinedStyledButton onClick={handleCancelButtonClick}>Cancel</OutlinedStyledButton>
        <Button
          sx={{
            boxShadow: 'none',
            textTransform: 'none',
          }}
          variant="contained"
          color="secondary"
          disabled={Boolean(filenameError || decimalSeparatorError || valueSeparatorError)}
          onClick={handleDownloadButtonClick}
        >
          {i18n.text('export.download-button')}
        </Button>
      </Box>
    </StyledDialogModal>
  )
}

const StyledSecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
}))

const StyledIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '3rem',
  height: '3rem',
  padding: '0.75rem',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  background: theme.palette.grey[100],
  color: theme.palette.grey[500],
}))

export default CSVExportModal
