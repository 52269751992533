import { Box, Button, Popover, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { FC, MouseEvent, useState } from 'react'

import { FilterIcon } from '../../../Shared/components/icons'
import { getStopsFilterIntervals } from '../../utils/quickIntervals'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

type ProductionStopsDrawerFilterProps = {
  onIntervalChange: (start: DateTime, end: DateTime) => void
}

const ProductionStopsDrawerFilter: FC<ProductionStopsDrawerFilterProps> = ({ onIntervalChange }) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null)
  const [anchorLabel, setAnchorLabel] = useState<string>('oee.stop-logging.drawer.filter.anchor-label')

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  const open = Boolean(anchorElement)

  const handleIntervalChange = (start: DateTime, end: DateTime, label: string) => {
    onIntervalChange(start, end)
    setAnchorLabel(label)
    handleClose()
  }

  return (
    <>
      <Button
        sx={{
          width: '100%',
          border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
          textTransform: 'none',
          justifyContent: 'flex-start',
          gap: theme.spacing(1),
        }}
        variant="outlined"
        onClick={handleClick}
      >
        <FilterIcon sx={{ width: '1.25rem', height: '1.25rem', color: theme.palette.SFIGreyLight[500] }} />
        {i18n.text(anchorLabel)}
      </Button>
      <Popover
        open={open}
        sx={{ zIndex: 2001, '.MuiPopover-paper': { width: '12rem' } }}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            paddingY: '1rem',
          }}
        >
          {getStopsFilterIntervals().map(interval => (
            <Button
              sx={{
                textTransform: 'none',
                justifyContent: 'flex-start',
                width: '100%',
                padding: '0.5rem 1rem',
                borderRadius: 0,
                '&:hover': { backgroundColor: theme.palette.SFIBrand[100], color: theme.palette.SFIBrand[900] },
              }}
              key={interval.label}
              onClick={() => handleIntervalChange(interval.start, interval.end, interval.label)}
            >
              {i18n.text(interval.label)}
            </Button>
          ))}
        </Box>
      </Popover>
    </>
  )
}

export default ProductionStopsDrawerFilter
