import { SlideshowImageInfo } from './IntroSlideshow'
import { UserLocale_Enum } from '../../../Shared/graphql/codegen'

const imagesByLocale: { [key in UserLocale_Enum]: SlideshowImageInfo[] } = {
  [UserLocale_Enum.EnGb]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635475/production/intro_slideshow_waste_in_webclient_202312/pyfr9khkaisr8o4u63yk.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635937/production/intro_slideshow_waste_in_webclient_202312/xr2cjszkuvvfwknncamc.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635481/production/intro_slideshow_waste_in_webclient_202312/mmubj4kc3b1pq1hfiw6y.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635484/production/intro_slideshow_waste_in_webclient_202312/o9y0bdqkbiwwh1dfz97w.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635939/production/intro_slideshow_waste_in_webclient_202312/l4d5s7icwhsm7dl0eef6.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635490/production/intro_slideshow_waste_in_webclient_202312/g4ets0bmnwrrffwrjc3z.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635493/production/intro_slideshow_waste_in_webclient_202312/zfl2k265kmwbvadc7a33.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635487/production/intro_slideshow_waste_in_webclient_202312/vwanrufbmjl3ihbu6wqh.png',
    },
  ],
  [UserLocale_Enum.NlNl]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635475/production/intro_slideshow_waste_in_webclient_202312/pyfr9khkaisr8o4u63yk.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635937/production/intro_slideshow_waste_in_webclient_202312/xr2cjszkuvvfwknncamc.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635481/production/intro_slideshow_waste_in_webclient_202312/mmubj4kc3b1pq1hfiw6y.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635484/production/intro_slideshow_waste_in_webclient_202312/o9y0bdqkbiwwh1dfz97w.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635939/production/intro_slideshow_waste_in_webclient_202312/l4d5s7icwhsm7dl0eef6.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635490/production/intro_slideshow_waste_in_webclient_202312/g4ets0bmnwrrffwrjc3z.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635493/production/intro_slideshow_waste_in_webclient_202312/zfl2k265kmwbvadc7a33.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635487/production/intro_slideshow_waste_in_webclient_202312/vwanrufbmjl3ihbu6wqh.png',
    },
  ],
  [UserLocale_Enum.DeDe]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635475/production/intro_slideshow_waste_in_webclient_202312/pyfr9khkaisr8o4u63yk.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635937/production/intro_slideshow_waste_in_webclient_202312/xr2cjszkuvvfwknncamc.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635481/production/intro_slideshow_waste_in_webclient_202312/mmubj4kc3b1pq1hfiw6y.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635484/production/intro_slideshow_waste_in_webclient_202312/o9y0bdqkbiwwh1dfz97w.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635939/production/intro_slideshow_waste_in_webclient_202312/l4d5s7icwhsm7dl0eef6.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635490/production/intro_slideshow_waste_in_webclient_202312/g4ets0bmnwrrffwrjc3z.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635493/production/intro_slideshow_waste_in_webclient_202312/zfl2k265kmwbvadc7a33.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635487/production/intro_slideshow_waste_in_webclient_202312/vwanrufbmjl3ihbu6wqh.png',
    },
  ],
  [UserLocale_Enum.EsEs]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635475/production/intro_slideshow_waste_in_webclient_202312/pyfr9khkaisr8o4u63yk.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635937/production/intro_slideshow_waste_in_webclient_202312/xr2cjszkuvvfwknncamc.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635481/production/intro_slideshow_waste_in_webclient_202312/mmubj4kc3b1pq1hfiw6y.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635484/production/intro_slideshow_waste_in_webclient_202312/o9y0bdqkbiwwh1dfz97w.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635939/production/intro_slideshow_waste_in_webclient_202312/l4d5s7icwhsm7dl0eef6.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635490/production/intro_slideshow_waste_in_webclient_202312/g4ets0bmnwrrffwrjc3z.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635493/production/intro_slideshow_waste_in_webclient_202312/zfl2k265kmwbvadc7a33.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635487/production/intro_slideshow_waste_in_webclient_202312/vwanrufbmjl3ihbu6wqh.png',
    },
  ],
  [UserLocale_Enum.FrFr]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635475/production/intro_slideshow_waste_in_webclient_202312/pyfr9khkaisr8o4u63yk.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635937/production/intro_slideshow_waste_in_webclient_202312/xr2cjszkuvvfwknncamc.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635481/production/intro_slideshow_waste_in_webclient_202312/mmubj4kc3b1pq1hfiw6y.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635484/production/intro_slideshow_waste_in_webclient_202312/o9y0bdqkbiwwh1dfz97w.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635939/production/intro_slideshow_waste_in_webclient_202312/l4d5s7icwhsm7dl0eef6.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635490/production/intro_slideshow_waste_in_webclient_202312/g4ets0bmnwrrffwrjc3z.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635493/production/intro_slideshow_waste_in_webclient_202312/zfl2k265kmwbvadc7a33.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635487/production/intro_slideshow_waste_in_webclient_202312/vwanrufbmjl3ihbu6wqh.png',
    },
  ],
  [UserLocale_Enum.ItIt]: [
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635475/production/intro_slideshow_waste_in_webclient_202312/pyfr9khkaisr8o4u63yk.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635937/production/intro_slideshow_waste_in_webclient_202312/xr2cjszkuvvfwknncamc.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635481/production/intro_slideshow_waste_in_webclient_202312/mmubj4kc3b1pq1hfiw6y.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635484/production/intro_slideshow_waste_in_webclient_202312/o9y0bdqkbiwwh1dfz97w.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635939/production/intro_slideshow_waste_in_webclient_202312/l4d5s7icwhsm7dl0eef6.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635490/production/intro_slideshow_waste_in_webclient_202312/g4ets0bmnwrrffwrjc3z.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635493/production/intro_slideshow_waste_in_webclient_202312/zfl2k265kmwbvadc7a33.png',
    },
    {
      src: 'https://res.cloudinary.com/dj4ypr91s/image/upload/v1702635487/production/intro_slideshow_waste_in_webclient_202312/vwanrufbmjl3ihbu6wqh.png',
    },
  ],
}

export default imagesByLocale
