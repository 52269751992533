import createReducer from 'create-reducer-map'
import moment from 'moment'
import { Map, fromJS } from 'immutable'
import { path, prop } from 'ramda'

import {
  ASSETS_FETCHED,
  ASSET_TAB_CHANGE,
  ASSET_UPDATED,
  DESELECT_ALL_ASSETS,
  FETCH_ASSETS,
  FETCH_MEASUREMENTS,
  MEASUREMENTS_FETCHED,
  RESET_SELECTED_ASSETS,
  SELECT_ASSET,
  SELECT_COMPONENT,
  SELECT_DATE_RANGE,
  SELECT_TOTAL_CONSUMPTION,
  SET_ANALYSIS_TYPE,
  TOGGLE_PREVIOUS_PERIOD,
  Y_AXIS_CHANGE,
} from './constants'
import type { DateRange, LocalAsset } from '../../../Shared/types/analysis_types'
import { MACHINE } from '../../../Shared/constants/tabTypes'
import { UPDATED_SETTING } from '../../../Shared/constants/appConstants'
import { getDefaultDateRange, getMachineCountByAnalysis } from '../../../Shared/utils/analysis_utils'

const initialState = {
  exports: [],
  assets: [] as LocalAsset[],
  assetCount: {
    analysisType: {
      power: 0,
      volume: 0,
      energy: 0,
    },
  },
  analysisType: 'volume',
  data: {
    power: {
      measurements: {},
      altMeasurements: {},
      consumption: [],
      altConsumption: [],
    },
    volume: {
      measurements: {},
      altMeasurements: {},
      consumption: [],
      altConsumption: [],
    },
    energy: {
      measurements: {},
      altMeasurements: {},
      consumption: [],
      altConsumption: [],
    },
  },
  fetchExportListPending: false,
  fetchMeasurementsPending: false,
  fetchAssetsPending: false,

  errorFetchingExportList: false,
  errorFetchingMeasurements: false,
  errorFetchingAssets: false,

  selectedComponents: [],
  selectedAssets: [[]],
  selectedDateRange: getDefaultDateRange(),
  yUnit: 'currency',
  activeAssetTab: MACHINE,
  totalConsumptionSelected: false,
  previousPeriodEnabled: false,
}

export type AnalysisState = Map<keyof typeof initialState, $TSFixMe>

export default createReducer<AnalysisState>(fromJS(initialState), {
  [FETCH_ASSETS]: state => state.set('fetchAssetsPending', true),
  [ASSETS_FETCHED]: {
    next: (state, { assets }: $TSFixMe) => {
      const analysisTypeCounts = {
        power: getMachineCountByAnalysis(assets, 'power'),
        volume: getMachineCountByAnalysis(assets, 'volume'),
        energy: getMachineCountByAnalysis(assets, 'energy'),
      }

      return state
        .set('assets', fromJS(assets))
        .setIn(['assetCount', 'analysisType'], fromJS(analysisTypeCounts))
        .set('fetchAssetsPending', false)
    },
    throw: (state, { error }: $TSFixMe) => state.set('errorFetchingAssets', error).set('fetchAssetsPending', false),
  },
  [SET_ANALYSIS_TYPE]: (state, { type }: $TSFixMe) => state.set('analysisType', type),
  [FETCH_MEASUREMENTS]: state => state.set('fetchMeasurementsPending', true).set('errorFetchingMeasurements', false),
  [MEASUREMENTS_FETCHED]: {
    next: (state, { measurements, altMeasurements, consumption, altConsumption, type }: $TSFixMe) =>
      state
        .set('fetchMeasurementsPending', false)
        .setIn(['data', type.toLowerCase(), 'measurements'], fromJS(measurements))
        .setIn(['data', type.toLowerCase(), 'altMeasurements'], fromJS(altMeasurements))
        .setIn(['data', type.toLowerCase(), 'consumption'], fromJS(consumption))
        .setIn(['data', type.toLowerCase(), 'altConsumption'], fromJS(altConsumption))
        .set('errorFetchingMeasurements', false),
    throw: (state, { error }: $TSFixMe) =>
      state.set('errorFetchingMeasurements', error).set('fetchMeasurementsPending', false),
  },
  [TOGGLE_PREVIOUS_PERIOD]: (state, { previousPeriodEnabled }: $TSFixMe) =>
    state.set('previousPeriodEnabled', !previousPeriodEnabled),
  [DESELECT_ALL_ASSETS]: state => state.set('selectedAssets', fromJS([])),
  [SELECT_ASSET]: (state, { id, index }: $TSFixMe) =>
    state.withMutations((s: $TSFixMe) => {
      let selectedAssets = s.get('selectedAssets')
      const family = selectedAssets.get(index, fromJS([]))
      const currentIndex = family.findIndex((a: $TSFixMe) => a === id)
      const set = currentIndex === -1 ? family.push(id) : family.delete(currentIndex)

      // Fill up empty slots when needed with empty lists
      ;[...Array(index)].forEach((_, i) => {
        const possibleEmptySlot = selectedAssets.get(i)
        if (!possibleEmptySlot || possibleEmptySlot.isEmpty()) {
          selectedAssets = selectedAssets.set(i, fromJS([]))
        }
      })

      selectedAssets = selectedAssets.set(index, fromJS(set))

      return s.set('selectedAssets', selectedAssets)
    }),
  [RESET_SELECTED_ASSETS]: state => {
    const activeTab = state.get('activeAssetTab')
    return activeTab === MACHINE
      ? state
        .set('activeAssetTab', activeTab)
        .set('selectedAssets', fromJS([[state.getIn(['assets', '0', 'assets', '0', 'id'])]]))
      : state.set('activeAssetTab', activeTab).set('selectedAssets', fromJS([[state.getIn(['assets', '0', 'id'])]]))
  },
  [SELECT_COMPONENT]: (state, { id }: $TSFixMe) =>
    state.withMutations((s: $TSFixMe) => {
      const selected = s.get('selectedComponents')
      const index = selected.findIndex((c: $TSFixMe) => c === id)
      const toSet = index === -1 ? selected.concat(id) : selected.filter((c: $TSFixMe) => c !== id)

      return s.set('selectedComponents', toSet)
    }),
  [SELECT_DATE_RANGE]: (state, { startDate, endDate }: DateRange) =>
    state.set('selectedDateRange', fromJS({ startDate, endDate })),
  [Y_AXIS_CHANGE]: state => state.set('yUnit', state.get('yUnit') === 'currency' ? 'kWh' : 'currency'),
  [ASSET_TAB_CHANGE]: (state, { activeTab }: $TSFixMe) => state.set('activeAssetTab', activeTab),
  [SELECT_TOTAL_CONSUMPTION]: (state, { totalConsumptionSelected }: $TSFixMe) =>
    state.set('totalConsumptionSelected', totalConsumptionSelected),
  [ASSET_UPDATED]: (state, { asset }: $TSFixMe) => {
    const parentIndex = state.get('assets').findIndex((x: $TSFixMe) => x.get('id') === path(['parent', 'id'], asset))
    const assetIndex = state
      .getIn(['assets', parentIndex, 'assets'])
      .findIndex((x: $TSFixMe) => x.get('id') === prop('id', asset))
    const storedAsset = state.getIn(['assets', parentIndex, 'assets', assetIndex]).toJS()
    const updatedAsset = { ...storedAsset, ...asset }

    return state.setIn(['assets', parentIndex, 'assets', assetIndex], fromJS(updatedAsset))
  },
  [UPDATED_SETTING]: {
    next: (state, { setting }: $TSFixMe) => {
      if (setting === 'locale') {
        const startDate = state.getIn(['selectedDateRange', 'startDate'])
        const endDate = state.getIn(['selectedDateRange', 'endDate'])

        return state.set(
          'selectedDateRange',
          fromJS({
            startDate: moment(startDate.valueOf()),
            endDate: moment(endDate.valueOf()),
          })
        )
      }

      return state
    },
  },
})
