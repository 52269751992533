import { Box, Button, FormControl, SelectChangeEvent, Typography, styled } from '@mui/material'
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'

import { EBUBasicOeeAsset, EnergyAssetRowState, EnergyByUnitOutputMetricUnits, OeeAssetRowState } from '../../types'
import { EMPTY_OPTION_VALUE } from './constants'
import { EnergyForm } from './EnergyForm'
import { OeeForm } from './OEEForm'
import { PlusIcon } from '../../../Shared/components/icons/PlusIcon'
import { StyledSelect } from '../../../Shared/components/MUIComponents/update/styledComponents/StyledSelect'
import { StyledSelectMenuItem } from '../../../Shared/components/MUIComponents/update/styledComponents/StyledSelectMenuItem'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface EnergyByUnitFormProps {
  outputMetricUnit: EnergyByUnitOutputMetricUnits
  onOutputMetricUnitChange: (event: SelectChangeEvent<EnergyByUnitOutputMetricUnits>) => void
  machines: any[]
  oeeMachines: EBUBasicOeeAsset[]
  onCalculate: (energyAssetRows: EnergyAssetRowState[], oeeAssetRows: OeeAssetRowState[]) => void
}

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

const LabelText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.SFIGreyLight[700],
  fontWeight: 500,
  lineHeight: '1.25rem',
}))

const LabelTextSecondary = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.SFIGreyLight[600],
  fontWeight: 400,
  lineHeight: '1.25rem',
}))

const OutputMetricUnitBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  paddingRight: '3rem',
}))

const FakeBorder = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '0px',
  borderBottom: 'solid 1px',
  borderBottomColor: theme.palette.SFIGreyLight[300],
  gridColumn: '1/4',
  marginTop: '14px',
  marginBottom: '14px',
}))

const SectionHeader = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
  color: theme.palette.SFIGreyLight[800],
}))

const AddAssetButton = styled(Button)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1.25rem',
  color: theme.palette.SFIGreyLight[800],
  padding: '0.5rem 1rem',
  textTransform: 'none',
  width: 'fit-content',
}))

const AddAssetButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
}))

const FormControlsContrainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(2),
}))

const CalculateButton = styled(Button)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1.25rem',
  padding: '10px 14px',
  textTransform: 'none',
}))

export const EnergyByUnitForm: FC<EnergyByUnitFormProps> = ({
  outputMetricUnit,
  onOutputMetricUnitChange,
  machines,
  oeeMachines,
  onCalculate,
}) => {
  const { i18n } = useI18nContext()
  const [initialCalculation, setInitialCalculation] = useState(false)

  const initialEnergyAssetRow = useMemo(
    () => ({
      key: Math.random() + '',
      selectedAssetId: machines[0]?.id || EMPTY_OPTION_VALUE,
      startDate: DateTime.now().startOf('day'),
      endDate: DateTime.now().endOf('day'),
      contribution: 100,
      percentageContributionValue: 1,
    }),
    [machines]
  )

  const initialOeeAssetRow = useMemo(
    () => ({
      key: Math.random() + '',
      selectedAssetId: oeeMachines[0]?.id || EMPTY_OPTION_VALUE,
      startDate: DateTime.now().startOf('day'),
      endDate: DateTime.now().endOf('day'),
      conversionRatio: 1,
    }),
    [oeeMachines]
  )

  const [energyAssetRows, setEnergyAssetRows] = useState<EnergyAssetRowState[]>([initialEnergyAssetRow])
  const [oeeAssetRows, setOeeAssetRows] = useState<OeeAssetRowState[]>([initialOeeAssetRow])

  const onEnergyAssetChange = (key: string, event: SelectChangeEvent<string>) => {
    const { value } = event.target
    const newEnergyAssetRows = energyAssetRows.map(ear => {
      if (ear.key === key) {
        return {
          ...ear,
          selectedAssetId: value,
        }
      }
      return ear
    })
    setEnergyAssetRows(newEnergyAssetRows)
  }

  const onEnergyDateChange = (key: string, startDate: DateTime, endDate: DateTime) => {
    const newEnergyAssetRows = energyAssetRows.map(ear => {
      if (ear.key === key) {
        return {
          ...ear,
          startDate,
          endDate,
        }
      }
      return ear
    })
    setEnergyAssetRows(newEnergyAssetRows)
  }

  const onEnergyContributionChange = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const boundedValue = Math.min(100, Math.max(0, parseFloat(value)))
    const newEnergyAssetRows = energyAssetRows.map(ear => {
      if (ear.key === key) {
        return {
          ...ear,
          contribution: boundedValue,
          percentageContributionValue: parseFloat((boundedValue / 100).toFixed(2)),
        }
      }
      return ear
    })
    setEnergyAssetRows(newEnergyAssetRows)
  }

  const onAddEnergyAsset = () => {
    const newEnergyAssetRow = {
      key: Math.random() + '',
      selectedAssetId: machines[0].id,
      startDate: DateTime.now().startOf('day'),
      endDate: DateTime.now().endOf('day'),
      contribution: 100,
      percentageContributionValue: 1,
    }
    setEnergyAssetRows([...energyAssetRows, newEnergyAssetRow])
  }
  const onRemoveEnergyAsset = (key: string) => {
    const newEnergyAssetRows = energyAssetRows.filter(ear => ear.key !== key)
    if (newEnergyAssetRows.length === 0) {
      return
    }
    setEnergyAssetRows(newEnergyAssetRows)
  }

  const onOeeAssetChange = (key: string, event: SelectChangeEvent<string>) => {
    const { value } = event.target
    const newOeeAssetRows = oeeAssetRows.map(oar => {
      if (oar.key === key) {
        return {
          ...oar,
          selectedAssetId: value,
        }
      }
      return oar
    })
    setOeeAssetRows(newOeeAssetRows)
  }

  const onOeeDateChange = (key: string, startDate: DateTime, endDate: DateTime) => {
    const newOeeAssetRows = oeeAssetRows.map(oar => {
      if (oar.key === key) {
        return {
          ...oar,
          startDate,
          endDate,
        }
      }
      return oar
    })
    setOeeAssetRows(newOeeAssetRows)
  }

  const onOeeConverstionRatioChange = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const boundedValue = Math.max(0, parseFloat(value))
    const newOeeAssetRows = oeeAssetRows.map(oar => {
      if (oar.key === key) {
        return {
          ...oar,
          conversionRatio: boundedValue,
        }
      }
      return oar
    })
    setOeeAssetRows(newOeeAssetRows)
  }

  const onAddOeeAsset = () => {
    const newOeeAssetRow = {
      key: Math.random() + '',
      selectedAssetId: oeeMachines[0].id || EMPTY_OPTION_VALUE,
      startDate: DateTime.now().startOf('day'),
      endDate: DateTime.now().endOf('day'),
      conversionRatio: 1,
    }
    setOeeAssetRows([...oeeAssetRows, newOeeAssetRow])
  }

  const onRemoveOeeAsset = (key: string) => {
    const newOeeAssetRows = oeeAssetRows.filter(ear => ear.key !== key)
    if (newOeeAssetRows.length === 0) {
      return
    }
    setOeeAssetRows(newOeeAssetRows)
  }

  // Fetch data for the default assets so the page graph is not empty
  useEffect(() => {
    if (!initialCalculation) {
      onCalculate(energyAssetRows, oeeAssetRows)
      setInitialCalculation(true)
    }
  }, [])

  return (
    <Box sx={{ overflow: 'auto' }}>
      <OutputMetricUnitBox id="output-metric-unit">
        <Box>
          <LabelText>{i18n.text('ebu.set-unit')}</LabelText>
          <LabelTextSecondary>{i18n.text('ebu.set-unit.description')}</LabelTextSecondary>
        </Box>
        <FormControl>
          <StyledSelect
            value={outputMetricUnit}
            onChange={onOutputMetricUnitChange}
          >
            <StyledSelectMenuItem value={EnergyByUnitOutputMetricUnits.UNITS}>
              {i18n.text('ebu.set-unit.units.units')}
            </StyledSelectMenuItem>
            <StyledSelectMenuItem value={EnergyByUnitOutputMetricUnits.UNITS1000}>
              {i18n.text('ebu.set-unit.units.units1000')}
            </StyledSelectMenuItem>
            <StyledSelectMenuItem value={EnergyByUnitOutputMetricUnits.KILOGRAMS}>
              {i18n.text('ebu.set-unit.units.kilograms')}
            </StyledSelectMenuItem>
          </StyledSelect>
        </FormControl>
        <FakeBorder />
      </OutputMetricUnitBox>
      <FormContainer id="energy-assets">
        <SectionHeader>{i18n.text('ebu.header.energy')}</SectionHeader>
        <EnergyForm
          machines={machines}
          energyAssetRows={energyAssetRows}
          onAssetChange={onEnergyAssetChange}
          onDateChange={onEnergyDateChange}
          onContributionChange={onEnergyContributionChange}
          onAssetRemove={onRemoveEnergyAsset}
        />
        <AddAssetButtonContainer>
          <AddAssetButton
            onClick={onAddEnergyAsset}
            startIcon={<PlusIcon />}
          >
            {i18n.text('ebu.form.add-asset')}
          </AddAssetButton>
        </AddAssetButtonContainer>
        <FakeBorder />
      </FormContainer>
      <FormContainer id="oee-assets">
        <SectionHeader>{i18n.text('ebu.header.oee')}</SectionHeader>
        <OeeForm
          oeeMachines={oeeMachines}
          oeeAssetRows={oeeAssetRows}
          onAssetChange={onOeeAssetChange}
          onDateChange={onOeeDateChange}
          onConversionRatioChange={onOeeConverstionRatioChange}
          onAssetRemove={onRemoveOeeAsset}
          outputMetric={outputMetricUnit}
        />
        <AddAssetButtonContainer>
          <AddAssetButton
            onClick={onAddOeeAsset}
            startIcon={<PlusIcon />}
          >
            {i18n.text('ebu.form.add-asset')}
          </AddAssetButton>
        </AddAssetButtonContainer>
        <FakeBorder />
      </FormContainer>

      <FormControlsContrainer>
        <CalculateButton
          onClick={() => onCalculate(energyAssetRows, oeeAssetRows)}
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
        >
          {i18n.text('ebu.form.calculate')}
        </CalculateButton>
      </FormControlsContrainer>
    </Box>
  )
}
