import React from 'react'

const EthernetConnector = () => (
  <svg
    width={48}
    height={48}
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <rect
      x="186"
      y="27"
      width="170"
      height="150"
      fill="transparent"
      stroke="#04aaff"
      strokeWidth="40"
      strokeLinejoin="round"
    />
    <rect
      x="315"
      y="327"
      width="170"
      height="150"
      fill="transparent"
      stroke="#04aaff"
      strokeWidth="40"
      strokeLinejoin="round"
    />
    <rect
      x="53"
      y="327"
      width="170"
      height="150"
      fill="transparent"
      stroke="#04aaff"
      strokeWidth="40"
      strokeLinejoin="round"
    />
    <rect
      x="0.5"
      y="234.5"
      width="511"
      height="39"
      rx="19.5"
      fill="#04aaff"
      stroke="#04aaff"
      strokeLinejoin="round"
    />
    <rect
      x="251.5"
      y="250.5"
      width="72"
      height="39"
      transform="rotate(-90 251.5 250.5)"
      fill="#04aaff"
      stroke="#04aaff"
    />
    <rect
      x="380.5"
      y="326.5"
      width="72"
      height="39"
      transform="rotate(-90 380.5 326.5)"
      fill="#04aaff"
      stroke="#04aaff"
    />
    <rect
      x="118.5"
      y="326.5"
      width="72"
      height="39"
      transform="rotate(-90 118.5 326.5)"
      fill="#04aaff"
      stroke="#04aaff"
    />
  </svg>
)

export default EthernetConnector
