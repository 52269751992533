import React, { FC } from 'react'
import { Interval } from 'luxon'

import SimpleCsvExporter, {
  CsvDataMapper,
  CsvHeaderMapper,
  CsvHeaders,
} from '../../../Shared/components/MUIComponents/ChartTools/CsvExporter'
import { SeriesNames, UnitName } from '../../types'

interface TemperatureCsvExporterProps {
  data: ApexAxisChartSeries
  interval: Interval
  filename?: string
  unit?: string
}

const TemperatureCsvExporter: FC<TemperatureCsvExporterProps> = ({ data, interval, unit: unitProp, filename }) => {
  const headers: CsvHeaders = ['date', 'type', 'value', 'unit']

  const mapHeaders: CsvHeaderMapper = (headers, { valueSeparator }) => {
    return headers.join(valueSeparator)
  }

  const mapDataToHeaders: CsvDataMapper<ApexAxisChartSeries> = (data, { valueSeparator, decimalSeparator }) => {
    const result = []
    for (const asset of data) {
      for (const measurement of asset.data) {
        const { x, y } = measurement as any
        const formattedValue = y.toString().replace('.', decimalSeparator)
        const unit = asset.name === SeriesNames.temperature ? 'Celsius' : UnitName.percentage
        const row = `${x}${valueSeparator}${asset.name}${valueSeparator}${formattedValue}${valueSeparator}${unit}`
        result.push(row)
      }
    }
    return result.join('\n')
  }

  return (
    <SimpleCsvExporter
      headers={headers}
      mapHeaders={mapHeaders}
      data={data}
      mapDataToHeaders={mapDataToHeaders}
      interval={interval}
      filename={filename}
      slotProps={{
        button: {
          sx: {
            background: 'none',
            padding: 0,
            '&:hover': {
              background: 'none',
            },
          },
        },
      }}
    />
  )
}

export { TemperatureCsvExporter }
