import { FC } from 'react'
import { Input, InputProps, TableCell, TableCellProps, useTheme } from '@mui/material'

export interface CustomTableCellInputProps {
  inputProps: InputProps
  cellProps?: TableCellProps
}
/**
 * CustomTableCellInput component for input content
 */
export const CustomTableCellInput: FC<CustomTableCellInputProps> = ({ inputProps, cellProps }) => {
  const theme = useTheme()
  return (
    <TableCell
      sx={{
        fontSize: '1rem',
        padding: '0.5rem 0.75rem',
        minWidth: 'max-content',
        textAlign: 'center',
        border: `1px solid ${theme.palette.grey[200]}`,
        borderBottom: 'none',
      }}
      {...cellProps}
    >
      <Input
        disableUnderline
        {...inputProps}
        sx={{
          fontSize: '1rem',
          margin: 0,
          '& input': {
            padding: '0.5rem 0.75rem',
            width: '46px',
            textAlign: 'center',
            border: 'solid 1px transparent',
            '&:hover': {
              border: `solid 1px ${theme.palette.grey[300]}`,
              borderRadius: '6px',
            },
            '&:focus': {
              border: `solid 1px ${theme.palette.SFIBrand[300]}`,
              borderRadius: '6px',
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(29, 122, 253, 0.24)',
            },
          },
        }}
      />
    </TableCell>
  )
}
