export const SUBMIT_FEEDBACK = Symbol('SUBMIT_FEEDBACK')
export const LOGOUT = Symbol('LOGOUT')
export const SHOW_TOAST = Symbol('SHOW_TOAST')
export const SHOW_SETTINGS = Symbol('SHOW_SETTINGS')
export const DISMISS_SETTINGS = Symbol('DISMISS_SETTINGS')
export const DISMISS_TOAST = Symbol('DISMISS_TOAST')
export const DISMISS_MOTD = Symbol('DISMISS_MOTD')
export const PREFERENCE_CHANGE = Symbol('PREFERENCE_CHANGE')
export const UPDATED_SETTING = Symbol('UPDATED_SETTING')
export const UPDATE_NETWORK_STATUS = Symbol('UPDATE_NETWORK_STATUS')
