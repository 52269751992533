import { Box, styled, useMediaQuery, useTheme } from '@mui/material'
import { Route, Switch } from 'react-router'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import MUILoader from '../../../Shared/components/MUIComponents/Loader'
import MuiSecondaryNav from '../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'
import usePropositions, { CurrentPropositions } from '../../../Shared/hooks/usePropositions/usePropositions'
import { ANNOUNCEMENT_BANNER_TRACKING_REPORTS } from '../../constants'
import { AnnouncementBanner } from '../../../Shared/components/MUIComponents/update/AnnouncementBanner/AnnouncementBanner'
import { ChartToolsContextProvider } from '../../context/ChartToolsContext'
import { DateTimePickerContainer } from './DateTimePickerContainer'
import { ElectricityAssetTree } from '../../components/ElectricityAssetTree/ElectricityAssetTree'
import { EnergyBalanceContainer } from '../EnergyBalanceContainer/EnergyBalanceContainer'
import { EnergyByUnitContainer } from '../EnergyByUnitContainer/EnergyByUnitContainer'
import { EnergyChartContainer } from '../EnergyChartContainer'
import { ExportMenuContainer } from './ExportMenuContainer'
import { OptionalIconLink } from '../../../Shared/components/MUIComponents/SecondaryNav/SecondaryNav'
import { PowerChartContainer } from '../PowerChartContainer'
import { browserStorage } from '../../../Shared/utils'
import { useElectricityContext } from '../../context/ElectricityContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  width: '100%',
  backgroundColor: 'rgb(245, 245, 245)',
  gridTemplateColumns: 'auto minmax(50%, 1fr)',
  gridTemplateRows: '1fr 1fr',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}))

const ElectricityContainer = () => {
  const { i18n } = useI18nContext()
  const history = useHistory()
  const [shouldShowAnnouncementBanner, setShoulsShowAnnouncementBanner] = useState(true)
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false)

  const onToggleTimePicker = (newState: boolean) => setIsTimePickerOpen(newState)

  const onAnnouncementBannerCtaClick = () => {
    history.push('/reports/configuration/tracking')
  }

  const { analysisType, fetchAssetsLoading, assetTree, toggleAssetSelect, toggleSelectAll, setShowCompare } =
    useElectricityContext()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { hasProposition } = usePropositions()

  const customerHasOEE = hasProposition(CurrentPropositions['OVERALL-EQUIPMENT-EFFECTIVENESS'])

  const electricityPropositionLinks: OptionalIconLink[] = [
    {
      name: 'Power',
      i18n: i18n.text('machine-details.power'),
      path: 'power',
    },
    {
      name: 'Energy',
      i18n: i18n.text('graph.measurements.energy'),
      path: 'energy',
    },
    {
      name: 'Energy Balance',
      i18n: i18n.text('router.sub-routes.energy-balance'),
      path: 'energy-balance',
    },
  ]

  if (customerHasOEE) {
    electricityPropositionLinks.push({
      name: 'Energy by unit',
      i18n: i18n.text('router.sub-routes.energy-by-unit'),
      path: 'energy-by-unit',
    })
  }

  const legacyNoOp = useCallback(() => {
    return
  }, [])

  const announcementBannerPrimaryText = i18n.text('reports.tracking.announcement.primaryText')
  const announcementBannerSecondaryText = i18n.text('reports.tracking.announcement.secondaryText')
  const announcementBannerCtaText = i18n.text('reports.tracking.announcement.buttonText')

  const renderAnnouncementBanner = () => (
    <AnnouncementBanner
      primaryText={announcementBannerPrimaryText}
      secondaryText={announcementBannerSecondaryText}
      showCtaButton={true}
      ctaText={announcementBannerCtaText}
      onCtaButtonClick={onAnnouncementBannerCtaClick}
      onDismiss={onDismissAnnouncementBanner}
      sx={{ marginBottom: theme.spacing(2) }}
    />
  )

  const renderGraph = () => {
    if (analysisType === 'power') {
      return (
        <Box>
          {shouldShowAnnouncementBanner && renderAnnouncementBanner()}
          <PowerChartContainer />
        </Box>
      )
    }
    if (analysisType === 'energy') {
      return (
        <Box>
          {shouldShowAnnouncementBanner && renderAnnouncementBanner()}
          <EnergyChartContainer />
        </Box>
      )
    }
    if (analysisType === 'energy-balance') {
      return (
        <Box>
          {shouldShowAnnouncementBanner && renderAnnouncementBanner()}
          <EnergyBalanceContainer />
        </Box>
      )
    }
  }

  const onDismissAnnouncementBanner = () => {
    browserStorage.set(ANNOUNCEMENT_BANNER_TRACKING_REPORTS, true)
    setShoulsShowAnnouncementBanner(false)
  }

  // Hide announcement banner if it was previously dismissed
  useEffect(() => {
    const wasAnnouncementBannerSeen = browserStorage.get<boolean>(ANNOUNCEMENT_BANNER_TRACKING_REPORTS).value
    if (wasAnnouncementBannerSeen) {
      setShoulsShowAnnouncementBanner(false)
    }
  }, [])

  return (
    <Switch>
      {customerHasOEE && (
        <Route
          path="/electricity/analysis/energy-by-unit"
          exact
        >
          <Box
            sx={theme => ({
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              justifyContent: 'center',
              alignItems: isSmallScreen ? 'center' : 'stretch',
              gap: isSmallScreen ? theme.spacing(1) : 0,
              flexWrap: 'wrap',
              backgroundColor: theme.palette.SFIBase.white,
              padding: theme.spacing(2, 3, 0),
              borderBottom: `1px solid ${theme.palette.SFIGreyLight[300]}`,
            })}
          >
            <MuiSecondaryNav
              links={electricityPropositionLinks}
              hideBorderBottom={true}
            />
          </Box>
          <EnergyByUnitContainer />
        </Route>
      )}
      <Route path="/electricity/analysis/:type">
        <ChartToolsContextProvider>
          <Box
            sx={theme => ({
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              justifyContent: 'center',
              alignItems: isSmallScreen ? 'center' : 'stretch',
              gap: isSmallScreen ? theme.spacing(1) : 0,
              flexWrap: 'wrap',
              backgroundColor: theme.palette.SFIBase.white,
              marginBottom: theme.spacing(2),
              padding: theme.spacing(2, 3, 0),
              borderBottom: `1px solid ${theme.palette.SFIGreyLight[300]}`,
            })}
          >
            <MuiSecondaryNav
              links={electricityPropositionLinks}
              hideBorderBottom={true}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '0.62rem',
              }}
            >
              <DateTimePickerContainer
                isOpen={isTimePickerOpen}
                onToggleOpen={onToggleTimePicker}
              />
              <ExportMenuContainer analysisType={analysisType} />
            </Box>
          </Box>
          <StyledBox>
            <ElectricityAssetTree
              groups={assetTree.groups}
              onSelect={toggleAssetSelect}
              onSelectAll={toggleSelectAll}
            />
            {fetchAssetsLoading ? <MUILoader /> : renderGraph()}
          </StyledBox>
        </ChartToolsContextProvider>
      </Route>
    </Switch>
  )
}

export default ElectricityContainer
