import InfoIcon from '@mui/icons-material/Info'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { GraphQLError } from 'graphql'
import { Button as MuiButton, IconButton, TextField, Tooltip, styled as muiStyled, useTheme } from '@mui/material'
import { RouteComponentProps, withRouter } from 'react-router'
import { isEmpty } from 'ramda'

import RecipientEditor from '../../components/RecipientEditor'
import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import DurationPicker, { DurationSelection } from '../../components/DurationPicker'
import { AnalyticsPropositionType } from '../../../Shared/hooks/useAnalytics/analyticsTypes'
import { AssetSelection, AssetSelector } from '../../components/AssetSelector'
import { DropdownOption } from '../../../Shared/types/types'
import { GqlAssetGroup, InputValues, RuleEditorMatchParams } from './types'
import { I18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import {
  NONE_KEY,
  createAssetGroups,
  defaultStandbyAlert,
  scrollToTop,
  standbyAlertFromState,
  stateFromStandbyAlert,
} from './utils'
import { Recipient, SupportedTransport } from '../../components/Recipient/types'
import { navRoutes } from '../Alerts'
import { normalizeStandbyAlert } from '../Rules/standby/utils'
import { sendCreateAlertEvent } from '../../utils/trackingEvents'
import {
  useCreateStandbyAlertMutation,
  useGetStandbyAlertQuery,
  useGetStandbyAlertsQuery,
  useStandbyAlertEditorAllAssetsQuery,
  useUpdateStandbyAlertMutation,
} from '../../../Shared/graphql/codegen'
import { useToastContext } from '../../../Shared/contexts/ToastContext'

const StyledMuiButton = muiStyled(MuiButton)(({ theme }) => ({
  height: '100%',
  minHeight: theme.spacing(5),
  fontSize: theme.spacing(2),
  fontWeight: 400,
  padding: '0 1em',
  letterSpacing: '0.047em',
  lineHeight: '1.5',
  textTransform: 'uppercase',
  borderRadius: '0.25em',
}))

const StyledSubheading = muiStyled('small')(({ theme }) => ({
  '&&': {
    color: theme.palette.SFIGreyLight[800],
  },
}))
const LocalWrapper = muiStyled('div')(() => ({
  textAlign: 'left',
}))

const InnerWrapper = muiStyled('div')(() => ({}))

const StyledLabel = muiStyled('label')(() => ({
  fontSize: '15px',
  display: 'block',
}))

const FormSection = muiStyled('div')(() => ({}))

const FormSectionInner = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  backgroundColor: theme.palette.SFIGreyLight[100],
  padding: '1em',
  borderRadius: '4px',
  marginBottom: '1em',
}))

const FormSectionInnerError = muiStyled(FormSectionInner)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.error.main}`,
  color: theme.palette.error.main,
}))

const ErrorContainer = muiStyled('ul')(({ theme }) => ({
  listStyleType: 'circle',
  listStylePosition: 'inside',
}))

const ErrorMessage = muiStyled('li')(({ theme }) => ({
  fontSize: '15px',
}))

const Group = muiStyled('div')(({ theme }) => ({
  margin: '0em 1em 0 0',

  '& > label': {
    fontSize: '13px',
    marginBottom: '0.3em',
  },

  '& > div button': {
    display: 'block',
    backgroundColor: theme.palette.SFIBase.white,
    border: 'none',
    outline: 'none',
    borderRadius: '4px',
    padding: '1em',
    fontSize: '13px',
    textAlign: 'left',
  },
}))

const StyledTextField = muiStyled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBase.white,
  borderRadius: 0,
  fontSize: '0.875em',
  '& input': {
    borderRadius: 0,
    border: 'none',
    height: '40px',
    padding: 0,
    paddingLeft: '1em',
    '&:focus': {
      outline: 'none',
    },
  },
  '& .MuiInputBase-root': {
    '&.Mui-focused': {
      outline: 'none',
      border: 'none',
    },
  },
  '.MuiInputBase-root::after': {
    borderBottom: '1px solid #04AAFF',
    transition: 'none',
  },
  '& .MuiInputBase-root::before': {
    display: 'none',
  },
}))

const BreakGroup = muiStyled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}))

const CenterVertical = muiStyled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
}))

const ButtonWrapper = muiStyled('div')(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  margin: '1.3em auto 0 0',
  float: 'right',
  '& button': {
    display: 'inline-block',
    '&:first-of-type': {
      marginRight: '1em',
    },
  },
}))

const TriggerSeparator = muiStyled('p')(() => ({
  margin: '0.5em 0em 1.2em 0em',
}))

const EMPTY: GqlAssetGroup[] = []
const defaultInputValues: InputValues = {
  duration: defaultStandbyAlert.alertAfterMinutes,
  durationUnit: 'minutes' as InputValues['durationUnit'],
  message: '',
  recipients: [],
  asset: { groupId: NONE_KEY, machineId: NONE_KEY, componentId: NONE_KEY },
  default: true,
  silenceHours: defaultStandbyAlert.silenceHours,
}

const AssetSelectorFormSectionInner = muiStyled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '1em',
}))

const StandbyAlertEditor = ({ history, match }: RouteComponentProps<RuleEditorMatchParams>): JSX.Element => {
  const { standbyAlertId } = match.params

  const { i18n } = useContext(I18nContext)
  const { sendEvent } = useAnalytics()
  const { showToast } = useToastContext()
  const theme = useTheme()

  const [createStandbyAlertMutation, { loading: createLoading }] = useCreateStandbyAlertMutation()
  const [updateStandbyAlertMutation, { loading: updateLoading }] = useUpdateStandbyAlertMutation()

  const {
    data: standbyAlertsData,
    loading: standbyAlertsPending,
    refetch: refetchGetStandbyAlerts,
  } = useGetStandbyAlertsQuery()
  const gqlStandbyAlerts = standbyAlertsData?.myOrg?.assets.flatMap(asset => asset.standbyAlertRules) ?? []
  const allStandbyAlerts = gqlStandbyAlerts.map(normalizeStandbyAlert)

  const { data: getAssetsData } = useStandbyAlertEditorAllAssetsQuery()

  const transportOptions: SupportedTransport[] = ['email', 'whatsapp']

  const { data: getStandbyAlertData, loading: standbyAlertLoading } = useGetStandbyAlertQuery({
    variables: { id: standbyAlertId },
    skip: standbyAlertId === 'new',
  })

  const durationUnitOptions: DropdownOption[] = [
    { key: 'minutes', option: i18n.text('rules.time.unit.minutes') },
    { key: 'hours', option: i18n.text('rules.time.unit.hours') },
    { key: 'days', option: i18n.text('rules.time.unit.days') },
  ]

  const [inputValues, setInputValues] = useState<InputValues>(defaultInputValues)
  const [formErrors, setFormErrors] = useState<string[]>([])

  const standbyAlert = useMemo(() => {
    const standbyGqlRule = getStandbyAlertData?.standbyAlert ?? null
    return standbyGqlRule ? normalizeStandbyAlert(standbyGqlRule) : defaultStandbyAlert
  }, [getStandbyAlertData?.standbyAlert])

  const assetGroups = useMemo(() => {
    const allAssets = getAssetsData?.myOrg?.assets ?? EMPTY
    return createAssetGroups([...allAssets])
  }, [getAssetsData?.myOrg?.assets])

  useEffect(() => {
    if (isEmpty(assetGroups) || isEmpty(standbyAlert)) return

    const inputValues = stateFromStandbyAlert(assetGroups, standbyAlert)
    setInputValues(inputValues)
  }, [assetGroups, standbyAlert])

  const { duration, durationUnit, message, asset, recipients, silenceHours } = inputValues

  function setInputValue<K extends keyof InputValues>(key: K, value: InputValues[K]) {
    setInputValues({
      ...inputValues,
      [key]: value,
    })
  }

  function onInputChange(key: keyof InputValues) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setInputValue(key, value)
    }
  }

  function onSilenceHoursChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValue('silenceHours', +e.target.value)
  }

  function onAssetSelectionChange(asset: AssetSelection) {
    setInputValue('asset', asset)
  }

  function onDurationChange({ duration, durationUnit }: DurationSelection) {
    setInputValues(values => ({
      ...values,
      duration,
      durationUnit: durationUnit as InputValues['durationUnit'],
    }))
  }

  function onRecipientsChange(recipients: Recipient[]) {
    setInputValue('recipients', recipients)
  }

  async function onSaveStandbyAlert() {
    const parseResult = standbyAlertFromState(standbyAlert.id, inputValues, assetGroups)

    if (parseResult.status === 'failed') {
      setFormErrors(parseResult.errors)
      scrollToTop()
      return
    }

    setFormErrors([])

    let hasError: GraphQLError | undefined = undefined
    if (standbyAlertId === 'new') {
      try {
        const { id: _, ...standbyAlertUpdate } = parseResult.standbyAlert
        const standbyAlertsForAsset = allStandbyAlerts.filter(alert => alert.assetId === standbyAlertUpdate.assetId)
        if (standbyAlertsForAsset.length > 0) {
          const enabledStandbyAlerts = standbyAlertsForAsset.some(alert => alert.enabled)
          const disabledStandbyAlert = standbyAlertsForAsset.find(alert => !alert.enabled)
          if (enabledStandbyAlerts) {
            throw Error(i18n.text('alerts.standby.save-duplicate-error'))
          } else if (disabledStandbyAlert) {
            // if there is a disabled standby alert, we update it
            const variables = {
              id: disabledStandbyAlert.id,
              _standbyAlert: {
                ...standbyAlertUpdate,
              },
            }
            await updateStandbyAlertMutation({ variables })
          } else {
            // this line should never be reached, either some standby alerts for asset are enabled or there is a disabled standby alert
            throw Error(i18n.text('alerts.standby.save-unexpected-error'))
          }
        } else {
          const variables = {
            standbyAlert: {
              ...standbyAlertUpdate,
            },
          }
          sendCreateAlertEvent('create_standby_alert_rule_form', AnalyticsPropositionType.ELECTRICITY, sendEvent)
          await createStandbyAlertMutation({ variables })
        }
      } catch (e) {
        hasError = e as GraphQLError
      }
    } else {
      try {
        const { id: _, ...standbyAlertUpdate } = parseResult.standbyAlert
        const variables = {
          id: parseResult.standbyAlert.id,
          _standbyAlert: {
            ...standbyAlertUpdate,
          },
        }
        await updateStandbyAlertMutation({ variables })
      } catch (e) {
        hasError = e as GraphQLError
      }
    }

    if (hasError) {
      showToast(hasError.message, 'error', i18n.text('alerts.standby.save-error.title'))
    } else {
      showToast(i18n.text('alerts.standby.save-success'), 'success', i18n.text('alerts.standby.save-success.title'))
      history.push(navRoutes.rules)
    }
  }

  if (standbyAlertId !== 'new' && (inputValues.default || standbyAlertLoading)) {
    return (
      <LocalWrapper>
        <InnerWrapper>
          <p>{i18n.text('app.loading')}</p>
        </InnerWrapper>
      </LocalWrapper>
    )
  }

  if (isEmpty(assetGroups)) {
    return (
      <LocalWrapper>
        <InnerWrapper>
          <p>{i18n.text('notifications.rules.no-assets')}</p>
        </InnerWrapper>
      </LocalWrapper>
    )
  }

  return (
    <LocalWrapper>
      <h1>
        {standbyAlertId === 'new' ? i18n.text('alerts.standby.create-title') : i18n.text('alerts.standby.edit-title')}
        <StyledSubheading>{i18n.text('alerts.standby.list.description')}</StyledSubheading>
      </h1>
      <InnerWrapper>
        {!isEmpty(formErrors) && (
          <FormSectionInnerError>
            <ErrorContainer>
              {formErrors.map(err => (
                <ErrorMessage key={err}>{i18n.text(err)}</ErrorMessage>
              ))}
            </ErrorContainer>
          </FormSectionInnerError>
        )}
        <FormSection>
          <StyledLabel>{i18n.text('rules.headers.trigger-conditions')}</StyledLabel>
          <FormSectionInner>
            <div>
              <AssetSelectorFormSectionInner>
                <AssetSelector
                  key={'asset-selector'}
                  assets={assetGroups}
                  i18n={i18n}
                  onChange={onAssetSelectionChange}
                  selected={asset}
                  info={
                    <Tooltip title={i18n.text('alerts.standby.asset-selector.info')}>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  }
                />
              </AssetSelectorFormSectionInner>
              <TriggerSeparator>{i18n.text('alerts.standby.condition.standby-for')}</TriggerSeparator>
              <DurationPicker
                i18n={i18n}
                selected={{ duration, durationUnit }}
                onChange={onDurationChange}
                durationUnitOptions={durationUnitOptions}
              />
            </div>
          </FormSectionInner>
        </FormSection>
        <FormSection>
          <StyledLabel>{i18n.text('alerts.standby.min-interval.header')}</StyledLabel>
          <FormSectionInner>
            <Group>
              <StyledTextField
                id="silence-input"
                key="standby-alert-silence-hours"
                type="number"
                size="small"
                variant="standard"
                inputProps={{
                  min: 0,
                  max: 24,
                }}
                value={silenceHours}
                onChange={onSilenceHoursChange}
                sx={{
                  maxWidth: '7.8em',
                  display: 'inline-block',
                  maxHeight: '40px',
                }}
              />
            </Group>
            <Group>
              <CenterVertical>
                <p>hours</p>
              </CenterVertical>
            </Group>
          </FormSectionInner>
        </FormSection>
        <FormSection>
          <StyledLabel>{i18n.text('rules.headers.message')}</StyledLabel>
          <FormSectionInner>
            <BreakGroup>
              <Group>
                {/* <StyledInput
                  id="message-input"
                  key={`message-input-${standbyAlert.id}`}
                  type="text"
                  value={message}
                  placeholder={i18n.text('rules.placeholders.message')}
                  onChange={onInputChange('message')}
                /> */}
                <StyledTextField
                  id="message-input"
                  key={`message-input-${standbyAlert.id}`}
                  type="text"
                  value={message}
                  placeholder={i18n.text('rules.placeholders.message')}
                  variant="standard"
                  onChange={onInputChange('message')}
                  sx={{
                    width: '38.3em',
                  }}
                />
              </Group>
            </BreakGroup>
          </FormSectionInner>
        </FormSection>
        <FormSection>
          <StyledLabel>{i18n.text('rules.headers.recipients')}</StyledLabel>
          <FormSectionInner>
            <RecipientEditor
              key={`recipient-editor-${standbyAlert.id}`}
              i18n={i18n}
              selected={recipients}
              onChange={onRecipientsChange}
              transportOptions={transportOptions}
            />
          </FormSectionInner>
        </FormSection>
        <ButtonWrapper>
          <div>
            <StyledMuiButton
              id="standby-rule-cancel-button"
              onClick={() => history.go(-1)}
              size="large"
              sx={{
                background: 'rgb(245, 245, 245)',
                color: 'rgb(17, 17, 17)',
                '&:hover': {
                  background: 'rgb(220, 220, 220)',
                },
              }}
            >
              {i18n.text('rules.form.cancel')}
            </StyledMuiButton>
          </div>
          <div>
            <StyledMuiButton
              color="secondary"
              variant="contained"
              id="standby-rule-save-button"
              onClick={onSaveStandbyAlert}
              size="large"
              sx={{
                color: theme.palette.SFIBase.white,
                '&:hover': {
                  background: 'rgb(0, 84, 149)',
                },
              }}
            >
              {createLoading || updateLoading ? i18n.text('generic.saving') : i18n.text('rules.form.save')}
            </StyledMuiButton>
          </div>
        </ButtonWrapper>
      </InnerWrapper>
    </LocalWrapper>
  )
}

export default withRouter(StandbyAlertEditor)
