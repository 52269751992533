import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Add, Check, Edit, RemoveCircle } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, List, ListItem, TextField, Typography } from '@mui/material'
import {
  Controller,
  FieldArrayWithId,
  FormProvider,
  Resolver,
  ResolverError,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { FC, useEffect } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'

import DayOfWeekSelect from './DayOfWeekSelect/DayOfWeekSelect'
import HourMinutePicker from './HourMinutePicker/HourMinutePicker'
import validateProductionTimesInput from './validateProductionTimesInput'
import { DayOfWeek } from '../../../Shared/types/types'
import { FormSubmitData, ParsedSchedule } from './types'
import { formatProductionTimes } from './utils/formatProductionTimes'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

export const emptyProductionTime: ParsedSchedule['productionTimes'][number] = {
  id: 'new',
  fromDay: 1 as DayOfWeek,
  toDay: 7 as DayOfWeek,
  fromMinuteHourDate: new Date('2022-01-10T00:00:00.000'),
  toMinuteHourDate: new Date('2022-01-10T00:59:59.000'),
}

export type ProductionScheduleFormProps = {
  productionSchedule?: ParsedSchedule
  onSubmit: (data: FormSubmitData) => void
  onDelete: () => void
}

const resolver: Resolver<FormSubmitData> = values => {
  // if there's no production schedule selected this resolver still runs and crashes if we don't guard against it
  if (!values.productionTimes?.length) return { values, errors: {} }

  const errors: ResolverError<FormSubmitData>['errors'] = {}

  const formattedProductionTimes = formatProductionTimes(values.productionTimes)
  const { success, errorAtIndex, message } = validateProductionTimesInput(formattedProductionTimes)

  // TODO : There's an issue where despite the form resolver being called the updated error state is not reflected in formState.errors
  // console.log("Validation: ", { success, errorAtIndex, type, message });

  if (!(values.name?.trim().length > 0)) {
    errors.name = { type: 'required', message: 'A name should not be empty spaces' }
  }
  if (!success && errorAtIndex) {
    errorAtIndex.forEach(index => {
      errors.productionTimes = { ...errors.productionTimes }
      errors.productionTimes[index] = errors.productionTimes[index] || {}
      errors.productionTimes[index]!.message = message
    })
  }

  return {
    values,
    errors,
  }
}

type ProductionTimeIntervalProps = {
  productionTime: FieldArrayWithId<FormSubmitData, 'productionTimes', 'id'>
  index: number
  onDelete: (index: number) => void
}

const ProductionTimeInterval: FC<ProductionTimeIntervalProps> = ({ productionTime, index, onDelete }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormSubmitData>()
  return (
    <ListItem
      key={productionTime.id}
      sx={{
        position: 'relative',
        borderBottom: 'solid 1px lightgray',
        padding: '2em',
        border: errors.productionTimes && errors.productionTimes[index] ? 'solid 2px red' : 'none',
        marginBottom: '1rem',
        '& > .MuiIconButton-root': {
          visibility: 'hidden',
        },
      }}
      data-testid="production-time-container"
    >
      {errors.productionTimes && errors.productionTimes[index] ? (
        <Typography
          data-testid="production-time-helper-text"
          variant={'body2'}
          sx={{
            position: 'absolute',
            top: '-0.75em',
            left: '2em',
            backgroundColor: 'white !important',
            color: 'red',
            padding: '0 3px',
          }}
        >
          {errors.productionTimes[index]?.message}
        </Typography>
      ) : null}
      <Grid
        item
        xs={6}
      >
        <Box>
          {/* FROM FIELD */}
          <Controller
            name={`productionTimes.${index}.fromDay`}
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <DayOfWeekSelect
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                inputRef={ref}
                value={value as DayOfWeek}
                inputProps={{
                  'data-testid': 'production-time-select-from-input',
                }}
                SelectDisplayProps={{ 'data-testid': 'production-time-select-from-button' } as {}}
              />
            )}
          />
          <Controller
            name={`productionTimes.${index}.fromMinuteHourDate`}
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <HourMinutePicker
                date={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                name={name}
                renderClock={false}
                inputProps={{
                  'data-testid': 'production-time-hour-input-from',
                }}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Box>
          {/* TO FIELD */}
          <Controller
            name={`productionTimes.${index}.toDay`}
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <DayOfWeekSelect
                onChange={onChange}
                name={name}
                inputRef={ref}
                value={value as DayOfWeek}
                inputProps={{
                  'data-testid': 'production-time-select-to-input',
                }}
                SelectDisplayProps={{ 'data-testid': 'production-time-select-to-button' } as {}}
              />
            )}
          />
          <Controller
            name={`productionTimes.${index}.toMinuteHourDate`}
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <HourMinutePicker
                date={value}
                onBlur={onBlur}
                onChange={onChange}
                inputRef={ref}
                name={name}
                renderClock={false}
                inputProps={{
                  'data-testid': 'production-time-hour-input-to',
                }}
              />
            )}
          />
        </Box>
      </Grid>
      <IconButton
        aria-label="delete"
        data-testid="production-time-delete"
        title="Remove interval"
        sx={theme => ({ '.MuiSvgIcon-root:hover': { color: theme.palette.error.main } })}
        onClick={() => onDelete(index)}
      >
        <RemoveCircle />
      </IconButton>
    </ListItem>
  )
}
const ProductionScheduleForm: FC<ProductionScheduleFormProps> = ({ productionSchedule, onSubmit, onDelete }) => {
  const { i18n } = useI18nContext()

  const formMethods = useForm<FormSubmitData>({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: productionSchedule,
    resolver,
  })

  const { register, handleSubmit, reset, control, formState, getValues, setValue } = formMethods

  const { fields, append, remove } = useFieldArray<FormSubmitData, 'productionTimes'>({
    control,
    name: 'productionTimes',
  })

  useEffect(() => {
    reset(productionSchedule)
  }, [productionSchedule, reset])

  // useEffect(() => {
  // TODO : There's an issue where despite the form resolver being called the updated error state is not reflected in formState.errors and formState.valid
  //   console.log(
  //     "Form state changed. ",
  //     "form is valid: ",
  //     formState.isValid,
  //     "is validating: ",
  //     formState.isValidating,
  //     "errors: ",
  //     formState.errors
  //   );
  // }, [formState]);

  const onFormSubmit = (data: FormSubmitData) => {
    const didFormChange = true
    if (didFormChange) {
      onSubmit(data)
    }
  }
  const { errors, isDirty } = formState

  enum ProductionTimeTemplate {
    '24/7' = '24/7',
    '24/5' = '24/5', // Sunday 22:00 - Friday 22:00
    '9/5' = '9/5', // Monday - Friday 09:00 - 17:00
  }

  const productionTimeTemplates: {
    [key in ProductionTimeTemplate]: Array<FormSubmitData['productionTimes'][number] & { id: string }>
  } = {
    [ProductionTimeTemplate['24/7']]: [
      {
        id: 'new',
        fromDay: 1 as DayOfWeek,
        toDay: 7 as DayOfWeek,
        fromMinuteHourDate: new Date('2022-01-09T23:00:00.000Z'),
        toMinuteHourDate: new Date('2022-01-10T22:59:59.000Z'),
      },
    ],
    [ProductionTimeTemplate['24/5']]: [
      {
        id: 'new',
        fromDay: 7 as DayOfWeek,
        toDay: 7 as DayOfWeek,
        fromMinuteHourDate: new Date('2022-01-10T21:00:00.000Z'),
        toMinuteHourDate: new Date('2022-01-10T22:59:59.000Z'),
      },
      {
        id: 'new',
        fromDay: 1 as DayOfWeek,
        toDay: 5 as DayOfWeek,
        fromMinuteHourDate: new Date('2022-01-09T23:00:00.000Z'),
        toMinuteHourDate: new Date('2022-01-10T21:00:59.000Z'),
      },
    ],
    [ProductionTimeTemplate['9/5']]: [
      {
        id: 'new',
        fromDay: 1 as DayOfWeek,
        toDay: 1 as DayOfWeek,
        fromMinuteHourDate: new Date('2022-01-10T08:00:00.000Z'),
        toMinuteHourDate: new Date('2022-01-10T16:00:59.000Z'),
      },
      {
        id: 'new',
        fromDay: 2 as DayOfWeek,
        toDay: 2 as DayOfWeek,
        fromMinuteHourDate: new Date('2022-01-10T08:00:00.000Z'),
        toMinuteHourDate: new Date('2022-01-10T16:00:59.000Z'),
      },
      {
        id: 'new',
        fromDay: 3 as DayOfWeek,
        toDay: 3 as DayOfWeek,
        fromMinuteHourDate: new Date('2022-01-10T08:00:00.000Z'),
        toMinuteHourDate: new Date('2022-01-10T16:00:59.000Z'),
      },
      {
        id: 'new',
        fromDay: 4 as DayOfWeek,
        toDay: 4 as DayOfWeek,
        fromMinuteHourDate: new Date('2022-01-10T08:00:00.000Z'),
        toMinuteHourDate: new Date('2022-01-10T16:00:59.000Z'),
      },
      {
        id: 'new',
        fromDay: 5 as DayOfWeek,
        toDay: 5 as DayOfWeek,
        fromMinuteHourDate: new Date('2022-01-10T08:00:00.000Z'),
        toMinuteHourDate: new Date('2022-01-10T16:00:59.000Z'),
      },
    ],
  }

  const handleAppendTemplate = (template: ProductionTimeTemplate) => {
    append(productionTimeTemplates[template])
  }

  const handleAppend = () => {
    const schedule = getValues()
    const [firstProductionTime] = schedule.productionTimes
    append({
      ...firstProductionTime,
      id: emptyProductionTime.id,
    } as unknown as FormSubmitData['productionTimes'][number])
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {!productionSchedule && (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            color="gray"
            mt="-2rem"
          >
            {' '}
            Create a production schedule or select one to edit it.
          </Typography>
        </Box>
      )}
      {productionSchedule && (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <TextField
                fullWidth
                autoComplete="off"
                size="small"
                sx={{ border: 'none' }}
                inputProps={{ 'data-testid': 'name-input' }}
                InputProps={{
                  endAdornment: <Edit sx={{ marginRight: '0.5rem', visibility: 'hidden' }} />,
                  sx: theme => ({
                    color: theme.palette.SFIBrand[900],
                    fontSize: '1.75rem',
                    width: '50vw',
                    marginBottom: '0.5rem',
                    '&:hover': {
                      '.MuiSvgIcon-root': { visibility: 'visible' },
                    },
                  }),
                }}
                {...register('name')}
                onBlur={e => setValue('name', e.target.value.trim())}
                error={!!errors.name}
                helperText={errors?.name?.message}
              />
              <Button
                variant="text"
                color="error"
                sx={{ cursor: 'pointer', flex: 1 }}
                onClick={onDelete}
                data-testid="delete-schedule"
              >
                Delete schedule
              </Button>
            </Box>
            <Typography variant="h5">{i18n.text('configuration.production-schedule.production-intervals')}</Typography>
            <hr />
            <Box textAlign="right">
              <Typography
                fontWeight="400"
                display="inline-block"
              >
                Templates
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                sx={{ marginLeft: '1rem' }}
                onClick={() => handleAppendTemplate(ProductionTimeTemplate['24/7'])}
                title="On throughout the week."
              >
                24/7
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                sx={{ marginLeft: '0.5rem' }}
                onClick={() => handleAppendTemplate(ProductionTimeTemplate['24/5'])}
                title="Sunday 22:00 - Friday 22:00"
              >
                24/5
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                sx={{ marginLeft: '0.5rem' }}
                onClick={() => handleAppendTemplate(ProductionTimeTemplate['9/5'])}
                title="Monday - Friday 09:00 - 17:00"
              >
                9/5
              </Button>
            </Box>
            <List
              sx={{
                '& .MuiListItem-root:hover': {
                  '& .MuiIconButton-root': {
                    visibility: 'visible',
                  },
                },
              }}
            >
              <Grid container>
                <ListItem>
                  <Grid
                    item
                    xs={6}
                    gap={1}
                  >
                    <Typography variant="h5">{i18n.text('configuration.production-schedule.from')}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    gap={1}
                  >
                    <Typography variant="h5">{i18n.text('configuration.production-schedule.to')}</Typography>
                  </Grid>
                </ListItem>
                {fields.map((productionTime, index) => (
                  <ProductionTimeInterval
                    key={productionTime.id}
                    productionTime={productionTime}
                    index={index}
                    onDelete={remove}
                  />
                ))}
                <ListItem sx={{ paddingLeft: 0 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    sx={{ padding: 1.5 }}
                    startIcon={<Add />}
                    data-testid="production-time-add"
                    onClick={handleAppend}
                  >
                    Add production interval
                  </Button>
                </ListItem>
              </Grid>
              <Box
                display="flex"
                justifyContent="space-around"
                sx={theme => ({
                  padding: '1em',
                })}
              >
                {/* FORM CONTROLS */}
                <Button
                  type="submit"
                  startIcon={<Check />}
                  variant="contained"
                  color="primary"
                  sx={{ color: 'white', fontWeight: 400, minWidth: '10vw' }}
                  data-testid="form-submit"
                >
                  Save changes
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ color: 'white', fontWeight: 400, minWidth: '10vw' }}
                  onClick={() => reset()}
                  data-testid="form-reset"
                  disabled={!isDirty}
                >
                  Reset
                </Button>
              </Box>
            </List>
          </form>
        </FormProvider>
      )}
    </LocalizationProvider>
  )
}

export default ProductionScheduleForm
