import { Box, Typography } from '@mui/material'
import { FC } from 'react'

import VibrationCard from '../../../components/VibrationCard/VibrationCard'
import { AssetCardData, GroupedCards } from '../types'
import { AssetGrid } from '../styled/StyledOverviewPage'
import { ProbeLocationThresholdAlertRuleFragment } from '../../../../Shared/graphql/codegen'

interface AssetCardLayoutProps {
  cards: AssetCardData[] | GroupedCards
  thresholdAlertRules: ProbeLocationThresholdAlertRuleFragment[]
}

export const CurrentVibrationStatusCards: FC<AssetCardLayoutProps> = ({ cards, thresholdAlertRules }) => {
  return (
    <Box>
      {Array.isArray(cards) ? (
        <AssetGrid data-testid="asset-grid">
          {cards.map((asset: AssetCardData) => (
            <VibrationCard
              thresholdAlertRules={thresholdAlertRules}
              asset={asset}
              key={asset.probeId}
            />
          ))}
        </AssetGrid>
      ) : (
        Object.entries(cards).map(([groupName, cards], index) => {
          return (
            <Box key={index}>
              <Typography
                color={'#16161D'} // TODO: Hardcoded color
                fontFamily={'Inter, Arial, sans-serif'}
                fontSize={'1.6rem'}
                fontWeight={600}
                marginTop={'1rem'}
                marginBottom={'1rem'}
                lineHeight={'2rem'}
              >
                {groupName}
              </Typography>
              <AssetGrid data-testid="asset-grid">
                {Array.isArray(cards) &&
                  cards.map((card: AssetCardData) => (
                    <VibrationCard
                      thresholdAlertRules={thresholdAlertRules}
                      asset={card}
                      key={card.probeId}
                    />
                  ))}
              </AssetGrid>
            </Box>
          )
        })
      )}
    </Box>
  )
}
