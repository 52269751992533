import React, { FC } from 'react'
import { useTheme } from '@mui/material'

import { MuiStyledChip } from '../../../Shared/components/MUIComponents/update/StyledChips'
import { Propositions } from '../../types/alertTypes'

interface IStyledMessageChipProps {
  item: string
  propositions: Propositions
}

export const StyledMessageChip: FC<IStyledMessageChipProps> = ({ item, propositions }) => {
  const theme = useTheme()
  const productProps = getProductStyle()
  const { backgroundColor, borderColor, color } = productProps

  function getProductStyle() {
    switch (item) {
      case propositions.electricity.name:
        return {
          backgroundColor: theme.palette.SFIWarning[50],
          color: theme.palette.SFIWarning[700],
          borderColor: theme.palette.SFIWarning[200],
        }
      case propositions['compressed-air'].name:
        return {
          backgroundColor: theme.palette.SFIGreyNeutral[50],
          color: theme.palette.SFIGreyNeutral[700],
          borderColor: theme.palette.SFIGreyNeutral[200],
        }
      case propositions.gas.name:
        return {
          backgroundColor: theme.palette.SFIBlue[50],
          color: theme.palette.SFIBlue[700],
          borderColor: theme.palette.SFIBlue[200],
        }
      case propositions.water.name:
        return {
          backgroundColor: theme.palette.SFIBrand[50],
          color: theme.palette.SFIBrand[700],
          borderColor: theme.palette.SFIBrand[200],
        }
      case propositions.temperature.name:
        return {
          backgroundColor: theme.palette.SFIOrange[50],
          color: theme.palette.SFIOrange[700],
          borderColor: theme.palette.SFIOrange[200],
        }
      case propositions.oee.name:
        return {
          backgroundColor: theme.palette.SFIGreen[50],
          color: theme.palette.SFIGreen[700],
          borderColor: theme.palette.SFIGreen[200],
        }
      default:
        return {
          name: '',
          backgroundColor: '',
          color: '',
          borderColor: '',
        }
    }
  }
  return (
    <MuiStyledChip
      item={item}
      slotProps={{
        sx: {
          background: backgroundColor,
          borderColor,
          color,
          width: 'fit-content',
          borderRadius: '625rem',
          fontSize: '0.75rem',
          fontWeight: 500,
          lineHeight: '1.125rem',
        },
      }}
    />
  )
}
