import { Box } from '@mui/material'
import { useMemo } from 'react'

import { EnergyByUnitChart } from './EnergyByUnitChart'
import { EnergyByUnitElectricityUnit, EnergyByUnitOutputMetricUnits, ProcessedAssetDataPerUnit } from '../../types'
import { GraphTitleToolbar } from './GraphTitleToolbar'
import { adjustMeasurementToMagnitude, getPowerOrderOfMagnitude } from '../../../Shared/utils'

function adjustCostToFullUnit(costInTenthOfCents: number) {
  return costInTenthOfCents / 1000
}

function adjustDataByDisplaySettings(
  showingCosts: boolean,
  outputMetric: EnergyByUnitOutputMetricUnits,
  data: ProcessedAssetDataPerUnit[]
): { data: number[]; name: string }[] {
  return data
    .map(asset => {
      if (!asset.isElectricityAsset) {
        return
      }
      if (showingCosts) {
        // as opposed to tenths of cents
        const costInFullDenomination = adjustCostToFullUnit(+asset.costPerUnit)

        const costPerUnitInOutputMetric =
          costInFullDenomination * (outputMetric === EnergyByUnitOutputMetricUnits.UNITS1000 ? 1000 : 1) || 0.001 // even if 0 we still want to display something in the chart
        return {
          name: asset.name,
          data: [costPerUnitInOutputMetric],
        }
      } else {
        const energyPerUnitInOutputMetric =
          asset.energyPerUnit * (outputMetric === EnergyByUnitOutputMetricUnits.UNITS1000 ? 1000 : 1)
        return {
          name: asset.name,
          data: [energyPerUnitInOutputMetric],
        }
      }
    })
    .filter(Boolean) as { data: number[]; name: string }[]
}

export const EnergyByUnitChartContainer = ({
  isLoading,
  processedAssetData,
  selectedUnit,
  outputMetric,
  onUnitChange,
}: {
  isLoading: boolean
  processedAssetData: ProcessedAssetDataPerUnit[]
  selectedUnit: EnergyByUnitElectricityUnit
  outputMetric: EnergyByUnitOutputMetricUnits
  onUnitChange: (unit: EnergyByUnitElectricityUnit) => void
}) => {
  const showingCosts = selectedUnit === 'cost'

  const largestMeasurement = useMemo(
    () =>
      processedAssetData.reduce((acc, { energyPerUnit }) => {
        const energyPerUnitInOutPutMetric =
          energyPerUnit * (outputMetric === EnergyByUnitOutputMetricUnits.UNITS1000 ? 1000 : 1)
        return Math.max(acc, energyPerUnitInOutPutMetric)
      }, 0),
    [outputMetric, processedAssetData]
  )

  const series = useMemo(
    () => adjustDataByDisplaySettings(showingCosts, outputMetric, processedAssetData),
    [showingCosts, outputMetric, processedAssetData]
  )

  const valuesModifier = useMemo(() => getPowerOrderOfMagnitude(largestMeasurement, true), [largestMeasurement])

  const adjustedEnergySeries = useMemo(
    () =>
      series.map(serie => {
        const adjustedEnergyMeasurement = adjustMeasurementToMagnitude(+serie.data[0]!, valuesModifier.decimal) || 0
        return {
          ...serie,
          data: [adjustedEnergyMeasurement],
        }
      }),
    [series, valuesModifier.unit]
  )

  const displayedSeries = showingCosts ? series : adjustedEnergySeries

  return (
    <Box sx={{ minHeight: '450px' }}>
      <GraphTitleToolbar
        valuesModifierUnit={valuesModifier.unit}
        selectedUnit={selectedUnit}
        outputMetric={outputMetric}
        onUnitChange={onUnitChange}
      />
      <EnergyByUnitChart
        series={displayedSeries}
        isLoading={isLoading}
        showingCosts={showingCosts}
      />
    </Box>
  )
}
