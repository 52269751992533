import { DateTime } from 'luxon'
import { intervalToDuration, isDate } from 'date-fns'

import {
  AnnotationDataFragment,
  AnnotationTagType_Enum,
  DataPointVibration,
  Measurement_Types_Enum,
  PdmOverviewFragment,
  ProbeLocationThresholdAlertRuleFragment,
} from '../../../Shared/graphql/codegen'
import { ApexXAxisAnnotationData } from './types'
import { LocalDateTime } from '../../../Shared/types/types'
import { SFIndigoMuiTheme } from '../../../Shared/theme/MuiThemeProvider'

export type ThresholdAlertRecipient = {
  id: string
  name: string
  deliveryType: string
  destination: string
}

export type ParsedAlertRule = {
  id: string
  name: string
  assetId: string
  evaluationMode: string
  duration: number
  measurementCount: number | null
  eventCooldownSeconds: number
  minimumNotifyLevel: number
  muteNotifications: boolean
  recipients: ThresholdAlertRecipient[]
  thresholds: number[]
}

export const mockParsedAlertRule: ParsedAlertRule = {
  id: 'mock-id',
  name: 'mock-name',
  assetId: 'mock-asset-id',
  evaluationMode: 'mock-evaluation-mode',
  duration: 0,
  measurementCount: 0,
  eventCooldownSeconds: 0,
  minimumNotifyLevel: 0,
  muteNotifications: false,
  recipients: [],
  thresholds: [],
}

export type AlertRuleParseResult =
  | {
      state: 'valid'
      alertRule: ParsedAlertRule
    }
  | {
      state: 'invalid'
      reason: string
    }
  | {
      state: 'not_configured'
    }

export type SelectedAlertFrequencyIntervalType = 'hourly' | 'daily' | 'weekly'
export type SelectDurationIntervalType = 'minutes' | 'hours' | undefined
export type SelectEventCooldownIntervalType = 'hour' | 'day' | 'week' | 'no-limit'
export type SelectMeasurementCountIntervalType = 'hour' | 'day' | 'week' | undefined

export const parseThresholdsAlert = (
  assetId: string,
  assetThresholdAlertRules: readonly ProbeLocationThresholdAlertRuleFragment[] | undefined
): AlertRuleParseResult => {
  if (!assetThresholdAlertRules || assetThresholdAlertRules.length === 0) {
    return {
      state: 'not_configured',
    }
  }

  if (assetThresholdAlertRules.length > 1) {
    return {
      state: 'invalid',
      reason: 'Multiple threshold rules configured for the same asset.',
    }
  }

  const alertRule = assetThresholdAlertRules[0]

  const conditions = alertRule.config.conditions.filter(
    c => c.assetId === assetId && c.measurementType === Measurement_Types_Enum.VelocityRms
  )

  if (conditions.length !== 1) {
    return {
      state: 'invalid',
      reason: 'Multiple velocity_rms threshold conditions configured for the same asset.',
    }
  }
  const { evaluationMode, duration, measurementCount, thresholds } = conditions[0]
  const { eventCooldownSeconds, muteNotifications, minimumNotifyLevel, recipients } = alertRule.config

  if (thresholds.length !== 2) {
    return {
      state: 'invalid',
      reason: `Invalid amount of thresholds [${thresholds.length}] configured on condition.`,
    }
  }

  return {
    state: 'valid',
    alertRule: {
      id: alertRule.id,
      name: alertRule.name,
      evaluationMode,
      duration,
      measurementCount,
      eventCooldownSeconds,
      minimumNotifyLevel,
      muteNotifications,
      recipients: recipients.map(r => ({
        id: r.id,
        name: r.name,
        deliveryType: r.deliveryType,
        destination: r.destination,
      })),
      assetId,
      thresholds: thresholds as number[],
    },
  }
}

export const getMaxValue = (series: DataPointVibration[]) => {
  return series.reduce((max, cur) => {
    return Math.max(max, cur.x, cur.y, cur.z)
  }, 0)
}

/**
 * Checks if valid interval and < 12.1 months
 */
export const isValidInterval = (from: Date | null, to: Date | null) => {
  if (!from || !to) return false
  if (!isDate(from) && !isDate(to)) return false

  const interval = intervalToDuration({
    start: from,
    end: to,
  })
  // 12.1 months limit in the API.
  if (!interval.years) return true
  if (interval.years === 1) {
    return !interval.months && !interval.weeks && !interval.days
  }
  return false
}

/**
 * API expects date as ISO format yyyy-mm-dd in local time but without timezone indicator (LocalDateTime)
 * @param {number} unix timestamp (miliseconds)
 * @returns {LocalDateTime} Formatted LocalDateTime
 * @example 1675182440629 => 2023-01-31
 */
export const formatAPIDate = (timestamp: number): LocalDateTime =>
  DateTime.fromMillis(timestamp).toISO({ includeOffset: false }) as LocalDateTime

const styleAnnotation = (a: AnnotationDataFragment, parsedAnnotation: ApexXAxisAnnotationData, isActive: boolean) => {
  // get styles from TAG and type
  const firstTag = a.tags[0]?.tag
  let result: Partial<ApexXAxisAnnotationData> = {
    label: {
      style: {
        fontFamily: 'inherit',
      },
    },
  }
  switch (firstTag) {
    case AnnotationTagType_Enum.Fault:
      result = {
        ...parsedAnnotation,
        fillColor: '#D30000',
        label: {
          ...parsedAnnotation.label,
          orientation: 'horizontal',
          textAnchor: 'middle',
          position: 'top',
          style: {
            color: '#808080',
            fontSize: '1rem',
          },
        },
      }
      break
    case AnnotationTagType_Enum.Maintenance:
      result = {
        ...parsedAnnotation,
        fillColor: '#BEBEBE',
        label: {
          ...parsedAnnotation.label,
          orientation: 'horizontal',
          textAnchor: 'middle',
          position: 'top',
          style: {
            color: '#808080',
            fontSize: '1rem',
          },
        },
      }
      break
    case AnnotationTagType_Enum.ProcessChange:
      result = {
        ...parsedAnnotation,
        fillColor: '#006ee6',
        label: {
          ...parsedAnnotation.label,
          orientation: 'horizontal',
          textAnchor: 'middle',
          position: 'top',
          style: {
            color: '#808080',
            fontSize: '1rem',
          },
        },
      }
      break
    default:
      result = {
        ...parsedAnnotation,
        fillColor: '#BEBEBE',
        label: {
          ...parsedAnnotation.label,
          orientation: 'horizontal',
          textAnchor: 'middle',
          position: 'top',
          style: {
            color: '#808080',
            fontSize: '1rem',
          },
        },
      }
  }
  if (isActive) {
    result = {
      ...result,
      label: {
        ...result.label,
        style: {
          ...result.label?.style,
          cssClass: 'chart-annotation-label__active',
          color: SFIndigoMuiTheme.palette?.common?.white,
          background: SFIndigoMuiTheme.palette?.secondary?.main,
        },
      },
    }
  }
  return result
}

export const parseAnnotations = ({
  annotations,
  onLabelClick,
  selectedAnnotationId,
}: {
  annotations: readonly AnnotationDataFragment[] | undefined
  onLabelClick?: (id: string) => void
  selectedAnnotationId: string | null
}): ApexXAxisAnnotationData[] => {
  if (!annotations) return []
  return annotations.map(a => {
    const unstyledAnnotation = {
      id: a.id,
      label: {
        text: a.name,
        click: (a: ApexXAxisAnnotationData) => onLabelClick && onLabelClick(a.id),
      },
      x: new Date(a.from).valueOf(),
      x2: a.to ? new Date(a.to).valueOf() : undefined,
    }
    return styleAnnotation(a, unstyledAnnotation, a.id === selectedAnnotationId) as ApexXAxisAnnotationData
  })
}

export const filterAnnotations = (
  annotations: AnnotationDataFragment[],
  filters: AnnotationTagType_Enum[]
): AnnotationDataFragment[] => {
  return annotations.filter(a => {
    return a.tags.some(t => filters.includes(t.tag)) || a.tags.length === 0
  })
}

export const getVibrationChartIntervals = (i18n: $TSFixMe) => [
  {
    label: i18n.text('pdm.timepicker.intervals.yesterday'),
    from: DateTime.local().minus({ hours: 24 }).startOf('day'),
    to: DateTime.local(),
  },
  {
    label: i18n.text('pdm.timepicker.intervals.last_7_days'),
    from: DateTime.now().minus({ days: 7 }).startOf('day'),
    to: DateTime.local(),
  },
  {
    label: i18n.text('pdm.datetimepicker.predefined-ranges.last.month'),
    from: DateTime.local().minus({ months: 1 }).startOf('month'),
    to: DateTime.local(),
  },
  {
    label: i18n.text('pdm.timepicker.intervals.last_90_days'),
    from: DateTime.local().minus({ months: 3 }).startOf('month'),
    to: DateTime.local(),
  },
  {
    label: i18n.text('pdm.timepicker.intervals.last_6_months'),
    from: DateTime.local().minus({ months: 6 }).startOf('month'),
    to: DateTime.local(),
  },
  {
    label: i18n.text('pdm.timepicker.intervals.last_year'),
    from: DateTime.local().minus({ months: 12 }),
    to: DateTime.local(),
  },
]

export const getMeasurementCountSeconds = (type: SelectMeasurementCountIntervalType) => {
  switch (type) {
    case 'hour':
      return 3600
    case 'day':
      return 86400
    case 'week':
      return 604800
    default:
      return 3600
  }
}

export const getEventCooldownSeconds = (type: SelectEventCooldownIntervalType) => {
  switch (type) {
    case 'hour':
      return 3600 // 1 hour
    case 'day':
      return 86400 // 1 day
    case 'week':
      return 604800 // 1 week
    case 'no-limit':
      return 0 // Value for now will be 0.
  }
}

export const getCooldownIntervalFromSeconds = (seconds: number): SelectEventCooldownIntervalType => {
  switch (seconds) {
    case 0:
      return 'no-limit'
    case 3600:
      return 'hour'
    case 86400:
      return 'day'
    case 604800:
      return 'week'
    default:
      return 'hour'
  }
}

export const getDurationSeconds = (type: SelectDurationIntervalType, duration: number) => {
  switch (type) {
    case 'minutes':
      return duration * 60
    case 'hours':
      return duration * 60 * 60
    default:
      return duration
  }
}

export const getCalculatedDuration = (type: SelectDurationIntervalType, duration: number) => {
  switch (type) {
    case 'minutes':
      return duration / 60
    case 'hours':
      return duration / 60 / 60
    default:
      return duration
  }
}

export const getTemperatureDataSeries = (series: ApexAxisChartSeries): ApexAxisChartSeries => {
  if (series.some(series => series.name === 'temperature')) {
    return series.filter(series => series.name === 'temperature')
  } else {
    return series
  }
}

// TODO: Refactor method when switching to NDM, after quries are updated.
export const selectGroupMachineAndProbe = (
  groups: PdmOverviewFragment['groups'],
  machineId: string | undefined,
  probeId: string | undefined
) => {
  // Try to select based on provided IDs
  const selectedGroup = groups?.find(group => group.machines.some(machine => machine.id === machineId)) || groups?.[0]

  const selectedMachine =
    selectedGroup?.machines?.find(
      machine => machine.id === machineId && machine.probes.some(probe => probe.id === probeId)
    ) || selectedGroup?.machines?.[0]

  const selectedProbe = selectedMachine?.probes?.find(probe => probe.id === probeId) || selectedMachine?.probes?.[0]
  // Fallback: If no match found, select the first available
  if (!selectedGroup || !selectedMachine || !selectedProbe || probeId !== selectedProbe?.id) {
    const firstGroup = groups?.[0]
    const firstMachine = firstGroup?.machines?.[0]
    const firstProbe = firstMachine?.probes?.[0]

    return {
      selectedGroup: firstGroup,
      selectedMachine: firstMachine,
      selectedProbe: firstProbe,
    }
  }

  return { selectedGroup, selectedMachine, selectedProbe }
}
