import moment from 'moment'

function createRange(begin: number, end: number) {
  return [begin].concat([...Array(end - begin)].map((empty, i) => begin + (i + 1)))
}

/**
 * calculateTickSize - Calculated the amount of ticks that need to be placed
 *
 * @param  {('minutes'|'hours'|'days'|'months'|'years')} breakType
 * @return {function}           Should be called with start and end timestamp
 */
export function calculateTickSize(breakType: 'minutes' | 'hours' | 'days' | 'months' | 'years') {
  /**
   * * calculateTickSizeInner - Calculated the amount of ticks that need to be placed
   * @param  {number} start     Start time in unix timestamp
   * @param  {number} end       End time in unix timestamp
   */
  return (start: number, end: number) => {
    const delta = end - start
    return Math.round(moment.duration(delta).as(breakType))
  }
}

const timeIntervals = [
  {
    days: createRange(0, 1),
    ticks: {
      format: '%H:%M',
      size: 1,
      unit: 'hour',
      viewKey: 'graph.range.view.hours',
      calculateInterval: (start: number, end: number) => {
        const originalFunc = calculateTickSize('hours')
        const { matches: isSmallScreen } = global.window.matchMedia('(max-width: 1440px)')
        return originalFunc(start, end) / (isSmallScreen ? 2 : 1)
      },
    },
  },
  {
    days: createRange(2, 3),
    ticks: {
      format: '%H:%M',
      size: 2,
      unit: 'hour',
      viewKey: 'graph.range.view.hours',
      calculateInterval: (start: number, end: number) => {
        const originalFunc = calculateTickSize('hours')
        return originalFunc(start, end) / 2
      },
    },
  },
  {
    days: createRange(4, 14),
    ticks: {
      format: '%a',
      size: 1,
      unit: 'day',
      viewKey: 'graph.range.view.days',
      calculateInterval: calculateTickSize('days'),
    },
  },
  {
    days: createRange(15, 31),
    ticks: {
      format: '%e',
      size: 1,
      unit: 'day',
      viewKey: 'graph.range.view.days',
      calculateInterval: calculateTickSize('days'),
    },
  },
  {
    days: createRange(32, 9999999),
    ticks: {
      format: '%b',
      size: 1,
      unit: 'month',
      viewKey: 'graph.range.view.months',
      calculateInterval: calculateTickSize('months'),
    },
  },
]

export default timeIntervals

export const MILLIS_IN_ONE_DAY = 86400000