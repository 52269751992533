import Box from '@mui/material/Box'
import { Button, InputBase, MenuItem, Select, Typography, styled, useTheme } from '@mui/material'
import { FilterList } from '@mui/icons-material'

import { SearchCard } from '../../../Shared/components/MUIComponents/SearchBox'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

const StyledInput = styled(InputBase)(({ theme }) => ({
  marginLeft: 2,
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  borderRadius: theme.shape.borderRadius,
  '& > .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    border: `solid 1px ${theme.palette.SFIGreyLight[300]}`,
  },
  '& > .MuiInputBase-input': {
    padding: '0.35rem 1rem',
    color: 'inherit',
    fontFamily: theme.typography.fontFamily,
    borderColor: theme.palette.SFIGreyLight[300],
    '&:focus': {
      borderColor: theme.palette.SFIGreyLight[300],
    },
  },
}))

enum TimeInterval {
  MINUTES_30 = 30,
  HOUR_1 = 60,
  HOUR_3 = 180,
  HOUR_6 = 360,
  HOUR_12 = 720,
  HOUR_24 = 1440,
}

type SortOption = 'name' | 'status' | 'group'
interface SearchAssetComponentProps {
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  sortBy: string
  setSortBy: React.Dispatch<React.SetStateAction<string>>
  sortAsc: boolean
  setSortAsc: React.Dispatch<React.SetStateAction<boolean>>
  checkUpdateValue: number
  setCheckUpdateValue: React.Dispatch<React.SetStateAction<number>>
}

export const SearchAndSortAssetsComponent = ({
  sortBy,
  sortAsc,
  checkUpdateValue,
  setSortBy,
  setSortAsc,
  handleSearch,
  setCheckUpdateValue,
}: SearchAssetComponentProps) => {
  const theme = useTheme()
  const { i18n } = useI18nContext()

  const sortOptions: { value: SortOption; label: string }[] = [
    { value: 'name', label: 'pdm.overview.sort.name' },
    { value: 'status', label: 'pdm.overview.sort.status' },
    { value: 'group', label: 'pdm.overview.sort.group' },
  ]

  const pastReferenceFrameOptions: { value: TimeInterval; label: string }[] = [
    { value: TimeInterval.MINUTES_30, label: 'pdm.overview.past.label.minutes' },
    { value: TimeInterval.HOUR_1, label: 'pdm.overview.past.label.hour' },
    { value: TimeInterval.HOUR_3, label: 'pdm.overview.past.label.hours' },
    { value: TimeInterval.HOUR_6, label: 'pdm.overview.past.label.hours' },
    { value: TimeInterval.HOUR_12, label: 'pdm.overview.past.label.hours' },
    { value: TimeInterval.HOUR_24, label: 'pdm.overview.past.label.hours' },
  ]

  const renderMenuItems = (options: { value: SortOption; label: string }[]) => {
    return options.map(option => (
      <MenuItem
        key={option.value}
        data-testid="sort-menu-option"
        value={option.value}
      >
        {i18n.text(option.label)}
      </MenuItem>
    ))
  }

  const renderPastReferenceFrameMenuItems = (options: { value: TimeInterval; label: string }[]) => {
    return options.map(option => (
      <MenuItem
        key={option.value}
        data-testid="past-update-menu-option"
        value={option.value}
      >
        {option.value === TimeInterval.MINUTES_30 ? option.value : option.value / 60}&nbsp;
        {i18n.text(option.label)}
      </MenuItem>
    ))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: '2rem',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto auto auto',
          gridColumnGap: '0.5rem',
          padding: '0.875rem 0 0',
          alignItems: 'center',
          fontWeight: 600,
          fontSize: theme.typography.pxToRem(24),
          whiteSpace: 'pre-line',
        }}
      >
        {i18n.text('pdm.overview.title')}
      </Typography>
      <Box display={{ xs: 'block', md: 'flex' }}>
        <Box>
          <SearchCard
            cardProps={{
              sx: {
                flex: 1,
                margin: '0 1rem 0 0',
                width: '375px',
                [theme.breakpoints.down('xl')]: {
                  width: '345px',
                },
              },
            }}
            onChange={handleSearch}
            inputProps={{ placeholder: i18n.text('pdm.overview.search-placeholder') }}
          />
        </Box>
        <Box
          sx={{
            width: 'calc(100% - 645px - 2rem )',
            [theme.breakpoints.down('xl')]: {
              width: '345px',
            },
          }}
        />
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            sx={theme => ({
              display: 'inline-block',
              color: theme.palette.SFIGreyLight[700],
              fontWeight: 400,
              minWidth: '60px',
            })}
          >
            {i18n.text('pdm.overview.sort.by')}
          </Typography>
          <Select
            value={sortBy}
            MenuProps={{ sx: { padding: '0rem' } }}
            input={<StyledInput />}
            onChange={e => setSortBy(e.target.value)}
            data-testid="sort-menu-select"
          >
            {renderMenuItems(sortOptions)}
          </Select>
          <Typography
            sx={theme => ({
              display: 'inline-block',
              marginLeft: '1rem',
              color: theme.palette.SFIGreyLight[700],
              fontWeight: 400,
              minWidth: '40px',
            })}
          >
            {i18n.text('pdm.overview.past.label')}
          </Typography>
          <Select
            value={checkUpdateValue}
            MenuProps={{ sx: { margin: '1rem' } }}
            input={<StyledInput />}
            onChange={e => setCheckUpdateValue(Number(e.target.value))}
            data-testid="past-update-menu-select"
          >
            {renderPastReferenceFrameMenuItems(pastReferenceFrameOptions)}
          </Select>
          <Button
            onClick={() => setSortAsc(prev => !prev)}
            data-testid="sort-order-button"
          >
            <FilterList sx={{ transform: sortAsc ? 'rotate(180deg)' : '' }} />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
