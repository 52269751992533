import { Checkbox, CheckboxProps, styled } from '@mui/material'

import checkedDisabledIcon from './checkboxIcons/checkedDisabledIcon.svg'
import checkedIcon from './checkboxIcons/checkedIcon.svg'
import indeterminateDisabledIcon from './checkboxIcons/indeterminateDisabledIcon.svg'
import indeterminateIcon from './checkboxIcons/indeterminateIcon.svg'

export const CheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: '30%',
  width: '1.25rem',
  height: '1.25rem',
  border: `1px solid var(--Colors-Border-border-primary, ${theme.palette.SFIGreyLight[300]})`,
  'input:hover ~ &': {
    boxShadow: '0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.SFIGreyLight[100],
  },
}))

export const CheckboxIndeterminateIcon = styled('span')(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  borderRadius: '30%',
  width: '1.25rem',
  height: '1.25rem',
  backgroundColor: theme.palette.SFIBrand[600],
  backgroundImage: `url(${indeterminateIcon})`,
  border: `3px solid var(--Colors-Border-border-primary, ${theme.palette.SFIBrand[600]})`,
  'input:hover ~ &': {
    boxShadow: '0px 0px 0px 4px rgba(29, 122, 253, 0.24)',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.SFIGreyLight[50],
    border: `1px solid var(--Colors-Border-border-primary, ${theme.palette.SFIGreyLight[300]})`,
    backgroundImage: `url(${indeterminateDisabledIcon})`,
    backgroundRepeat: 'no-repeat',
  },
}))

export const CheckboxCheckedIcon = styled('span')(({ theme }) => ({
  backgroundImage: `url(${checkedIcon})`,
  backgroundRepeat: 'no-repeat',
  borderRadius: '30%',
  width: '1.25rem',
  height: '1.25rem',
  backgroundColor: theme.palette.SFIBrand[600],
  border: `3px solid var(--Colors-Border-border-primary, ${theme.palette.SFIBrand[600]})`,
  'input:hover ~ &': {
    boxShadow: '0px 0px 0px 4px rgba(29, 122, 253, 0.24)',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.SFIGreyLight[50],
    border: `1px solid var(--Colors-Border-border-primary, ${theme.palette.SFIGreyLight[300]})`,
    backgroundImage: `url(${checkedDisabledIcon})`,
    backgroundRepeat: 'no-repeat',
  },
}))

export function StyledCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<CheckboxCheckedIcon />}
      icon={<CheckboxIcon />}
      indeterminateIcon={<CheckboxIndeterminateIcon />}
      {...props}
    />
  )
}
