import { TableCell, styled } from '@mui/material'

export const CustomeHeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  fontSize: '0.75rem',
  fontWeight: '500',
  lineHeight: '1.125rem',
  padding: '5px 1.5rem',
  minWidth: 'max-content',
  textAlign: 'center',
}))
