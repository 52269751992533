import React, { useEffect, useState } from 'react'
import { Box, IconButton, Typography, styled, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { FC } from 'react'
import { useHistory } from 'react-router'

import useAnalytics from '../../../../Shared/hooks/useAnalytics/useAnalytics'
import { AnalyticsIntent } from '../../../../Shared/hooks/useAnalytics/analyticsTypes'
import { CopyIcon, EditIcon } from '../../../../Shared/components/icons'
import { DeleteReportDialog } from './Dialogs/DeleteReportDialog'
import { ISODateTime } from '../../../../Shared/types/types'
import { Report, ReportInputProps, ReportRowProps } from './types'
import { SmallCustomSwitch } from '../../../../Shared/components/MUIComponents/update/NewDesignStyledSwitch'
import { TrashIcon } from '../../../../Shared/components/icons/TrashIcon'
import { omitProperties } from '../../../../Shared/utils/omitProperties'
import {
  trackCancelDeleteReportEvent,
  trackConfirmDeleteReportEvent,
  trackDeleteReportEvent,
  trackDuplicateReportEvent,
  trackEditReportEvent,
  trackToggleReportEvent,
} from './trackingEvents'
import { useCurrentUser } from '../../../../Shared/contexts/CurrentUserContext'
import {
  useDeleteTrackingReportMutation,
  useGetReportByIdQuery,
  useGetReportsByCustomerIdQuery,
  useInsertReportMutation,
  useUpdateReportEnableMutation,
} from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../Shared/contexts/ToastContext'

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '1.25rem',
  color: theme.palette.SFIGreyLight[600],
}))

export const ReportRow: FC<ReportRowProps> = ({
  reportId,
  label,
  timeframe,
  modules,
  recipients,
  language,
  enabled,
  index,
  numReports,
}) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const history = useHistory()
  const { sendEvent } = useAnalytics()
  const { showToast } = useToastContext()
  const { isInternalUser } = useCurrentUser()
  const { customerId } = useCurrentUser()
  const { refetch } = useGetReportsByCustomerIdQuery({ variables: { id: customerId } })

  const [deleteReportMutation] = useDeleteTrackingReportMutation({
    onCompleted: async response => {
      showToast(
        i18n.text(`reports.tracking.configuration.reports.deletedSuccess`),
        'success',
        i18n.text(`reports.tracking.configuration.module.success`)
      )
    },
    onError: async response =>
      showToast(
        i18n.text(`reports.tracking.configuration.reports.deletedFail`),
        'error',
        i18n.text(`reports.tracking.configuration.module.error`)
      ),
  })

  const { data: wholeReportData } = useGetReportByIdQuery({ variables: { id: reportId } })
  const [insertReportMutation] = useInsertReportMutation({
    onCompleted: async response => {
      showToast(i18n.text('report.tracking.configuration.duplicatedSuccess'), 'success', 'Success!')
    },
    onError: async response => showToast(i18n.text('report.tracking.configuration.duplicatedFail'), 'error', 'Error'),
  })
  const [updateReportEnableMutation] = useUpdateReportEnableMutation()

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(enabled || false)
  }, [enabled, checked])

  const handleCloneReport = async () => {
    const wholeReport = wholeReportData?.TrackingReportReportConfigById
    const wholeReportWritable: Report = wholeReport && JSON.parse(JSON.stringify(wholeReport))

    const reportWithoutId = omitProperties(wholeReportWritable, ['id', '__typename']) as Report

    const { recipients, analysisModules, ...generalReport } = reportWithoutId

    const newAnalysisModules = reportWithoutId.analysisModules.map(module => ({
      ...omitProperties(module, ['id', '__typename']),
      visualisations: {
        data: module.visualisations.map(visualisation => ({ ...omitProperties(visualisation, ['id', '__typename']) })),
      },
    }))

    const newRecipients = reportWithoutId.recipients.map(recipient => omitProperties(recipient, ['id', '__typename']))

    const reportInput: ReportInputProps = {
      input: {
        ...generalReport,
        recipients: {
          data: newRecipients,
        },
        analysisModules: {
          data: newAnalysisModules,
        },
      },
    }

    await insertReportMutation({
      variables: {
        ...reportInput,
      },
    })
    trackDuplicateReportEvent(sendEvent)
    refetch()
  }

  const handleToggleReport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    const enableReportParameters = {
      reportId: reportId,
      enableReport: !enabled,
    }

    await updateReportEnableMutation({
      variables: {
        ...enableReportParameters,
      },
    })
    refetch()
    if (event.target.checked) {
      trackToggleReportEvent(AnalyticsIntent.TR_ENABLE_REPORT, sendEvent)
    } else {
      trackToggleReportEvent(AnalyticsIntent.TR_DISABLE_REPORT, sendEvent)
    }
  }

  const handleEditReportClick = () => {
    trackEditReportEvent(sendEvent)
    history.push(`/reports/configuration/tracking/${reportId}/general`)
  }
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
    trackDeleteReportEvent(sendEvent)
  }
  const handleCancelDelete = () => {
    setOpenDeleteDialog(false)
    trackCancelDeleteReportEvent(sendEvent)
  }
  const handleDeleteReport = async (id: string | undefined) => {
    if (id) {
      const deleteParameters = { reportId: id, deletedAt: DateTime.now().toISO() as ISODateTime }

      await deleteReportMutation({
        variables: deleteParameters,
      })
    }
    setOpenDeleteDialog(false)
    trackConfirmDeleteReportEvent(sendEvent)
    refetch()
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'inline-grid',
            gridTemplateColumns: '25% 9% 15% 9% 15% 12% 15% ',
            width: '100%',
            alignItems: 'center',
            background: index % 2 === 0 ? theme.palette.SFIGreyNeutral[50] : theme.palette.SFIBase.white,
            padding: '1rem 1.5rem 1rem 1.5rem',
            borderTop: `1px solid ${theme.palette.SFIGreyLight[200]}`,
            borderBottomLeftRadius: index === numReports - 1 ? '12px' : 0,
            borderBottomRightRadius: index === numReports - 1 ? '12px' : 0,
          }}
        >
          <StyledTypography sx={{ fontWeight: '500' }}>{label}</StyledTypography>
          <StyledTypography>{i18n.text(`reports.tracking.configuration.language.${language}`)}</StyledTypography>
          <StyledTypography sx={{ textAlign: 'center' }}>{modules}</StyledTypography>
          <StyledTypography>{i18n.text(`reports.tracking.configuration.general.${timeframe}`)}</StyledTypography>
          <StyledTypography sx={{ textAlign: 'center' }}>{recipients}</StyledTypography>
          <SmallCustomSwitch
            color="secondary"
            checked={enabled}
            onChange={handleToggleReport}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <IconButton
              disableRipple
              sx={{ color: theme.palette.SFIGreyLight[600] }}
              onClick={handleCloneReport}
            >
              <CopyIcon sx={{ fontSize: '1.25rem' }} />
            </IconButton>

            {isInternalUser() && (
              <IconButton
                disableRipple
                sx={{ color: theme.palette.SFIGreyLight[600] }}
                onClick={handleOpenDeleteDialog}
              >
                <TrashIcon sx={{ fontSize: '1.25rem' }} />
              </IconButton>
            )}
            <IconButton
              disableRipple
              sx={{ color: theme.palette.SFIGreyLight[600] }}
              onClick={handleEditReportClick}
            >
              <EditIcon sx={{ fontSize: '1.25rem' }} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <DeleteReportDialog
        openDeleteDialog={openDeleteDialog}
        reportId={reportId}
        handleDeleteReport={handleDeleteReport}
        handleCancelDelete={handleCancelDelete}
      />
    </>
  )
}
