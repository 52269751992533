import { Typography, styled } from '@mui/material'

export const SemiboldBrandLightXlDisplayText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIBrand[600],
  fontSize: '3.75rem',
  fontWeight: 600,
  lineHeight: '4.375rem',
}))

export const SemiBoldDarkLgDisplayText = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: '600',
  lineHeight: '3.75rem',
  color: theme.palette.SFIGreyLight[900],
}))

export const SemiboldDarkMdDisplayText = styled(Typography)(({ theme }) => ({
  fontSize: '2.25rem',
  fontWeight: 600,
  lineHeight: '2.75rem',
  letterSpacing: '-0.72px',
  textAlign: 'left',
  color: theme.palette.SFIGreyLight[900],
}))

export const SemiboldBrandMdDisplayText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIBrand[600],
  fontSize: '2.25rem',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '2.75rem',
  letterSpacing: '-0.045rem',
}))

export const SemiboldBrandExtraDarkXsDisplayText = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: '2rem',
  color: theme.palette.SFIBrand[900],
}))

export const SemiboldBrandExtraDarkXlText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIBrand[900],
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: '1.875rem',
}))

export const SemiboldDarkXlText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[900],
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: '1.875rem',
}))

export const SemiboldLightXlText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: '1.875rem',
}))

export const RegularDarkXlText = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 400,
  lineHeight: '1.875rem',
  color: theme.palette.SFIGreyLight[900],
}))

export const RegularLightXLText = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 400,
  lineHeight: '1.875rem',
  color: theme.palette.SFIGreyLight[600],
}))

export const SemiboldBrandLightLgText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIBrand[600],
  fontSize: '1.125rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
}))

export const SemiboldDarkLgText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[900],
  fontSize: '1.125rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
}))

export const SemiboldLightLgText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '1.125rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
}))

export const SemiboldDarkMdText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[900],
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '1.5rem',
}))

export const SemiboldLightMdText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '1.5rem',
}))

export const SemiboldBrandDarkMdText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIBrand[700],
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
}))

export const SemiboldBrandLightMdText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIBrand[600],
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '1.5rem',
}))

export const RegularDarkMdText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[900],
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
}))

export const RegularLightMdText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[600],
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
}))

export const RegularDarkSmText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  textTransform: 'none',
  color: theme.palette.SFIGreyLight[900],
}))

export const RegularLightSmText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  textTransform: 'none',
  color: theme.palette.SFIGreyLight[600],
}))
