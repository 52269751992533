import React, { FC, useMemo, useRef } from 'react'
import { styled, useTheme } from '@mui/material'

import { AirTemplateGraph } from './TemplateGraph'
import {
  AnalyticsEventTypes,
  AnalyticsIntent,
  AnalyticsPropositionType,
} from '../../../Shared/hooks/useAnalytics/analyticsTypes'
import { BalanceTable } from './BalanceTable'
import { ChartToolsItem } from '../../../Shared/components/MUIComponents/update/ChartToolsDropdown'
import { FitHeightBoxWrapper } from '../../../Shared/components/MUIComponents/update/styledComponents/SectionBoxWrapper'
import { GraphId, SwitchUnit } from '../../types/compressed-air_types'
import { HoverOnTitleInfoButtonCA } from '../../utils/analyticsTypes'
import { ISODateTime } from '../../../Shared/types/types'
import { setToolbarItems } from '../../utils/setToolbarItems'
import { useAirBalanceOptions, useBalanceBarChartOptions } from '../../hooks/useBalanceOptions'
import { useCAChartToolsContext } from '../../context/CAChartToolsContext'
import { useCompressedAirContext } from '../../context/CompressedAirContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useTrackHoveredElement } from '../../../Shared/hooks/useTrackHoveredElement'
import { useFeature } from 'flagged'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  overflowY: 'auto',
}))

const Section = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '24px',
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    overflowY: 'auto',
  },
}))

export const AirBalanceGraph: FC = () => {
  const { i18n } = useI18nContext()
  const { decimal, onDecimalToggle } = useCAChartToolsContext()
  const theme = useTheme()
  
  // TODO (Sabina): Remove when not needed
  const isOutsideOperatingHoursVisible = !!useFeature('HAS_OUTSIDE_OPERATING_HOURS')

  const elementRef = useRef<HTMLElement>()
  const {
    dataMap,
    fetching,
    series: {
      balance: { series, labels, items, colors },
    },
    assets,
    queryParams,
    switchUnit,
    previousPeriodSeries,
    previousPeriodFromToValues,
    outsideOperatingHoursConsumptionSeries,
    isMoreThanMonth,
  } = useCompressedAirContext()
  const { sid } = queryParams
  useTrackHoveredElement<HoverOnTitleInfoButtonCA>({
    eventName: AnalyticsEventTypes.TRACKED_BUTTON_HOVER,
    eventParams: {
      proposition: AnalyticsPropositionType.COMPRESSED_AIR,
      intent: AnalyticsIntent.READ_INFO,
      target: 'hover_info_button',
    },
    elementRef,
    isLoading: fetching,
    duration: 10000,
  })
  const { showOutsideOperatingHoursInsights, onShowOutsideOperatingHoursInsightsToggle } = useCAChartToolsContext()

  const previousPeriodBalanceData = useMemo(
    () =>
      previousPeriodSeries
        ? previousPeriodSeries.balance
        : {
            colors: [],
            labels: [],
            items: {},
            series: [],
          },
    [previousPeriodSeries]
  )

  const isPrevious = useMemo(
    () =>
      !!(
        previousPeriodBalanceData.series &&
        previousPeriodFromToValues.startDate &&
        previousPeriodFromToValues.endDate
      ),
    [previousPeriodBalanceData, previousPeriodFromToValues]
  )
  const isShowOutsideOperatingHoursAllowed = sid.length === 1 && !isPrevious && !isMoreThanMonth
  const hasShowOutsideOperatingHoursEnabled = isShowOutsideOperatingHoursAllowed && showOutsideOperatingHoursInsights
  const isNotBarChart =
    !isPrevious &&
    (!showOutsideOperatingHoursInsights || (showOutsideOperatingHoursInsights && !isShowOutsideOperatingHoursAllowed))

  const toolbarItems: ChartToolsItem[] = useMemo(
    () =>
      setToolbarItems({
        isOutsideOperatingHoursVisible,
        outsideOperatingHours: {
          isChecked: hasShowOutsideOperatingHoursEnabled,
          isDisabled: !isShowOutsideOperatingHoursAllowed,
          isCompareDatesOn: isPrevious,
          isMoreThanMonth,
          onToggle: () => {
            onShowOutsideOperatingHoursInsightsToggle(!showOutsideOperatingHoursInsights)
          },
        },
        decimalChangeArgs: {
          selected: decimal,
          label: `${i18n.text('chart.tools.decimal-label')} : ${decimal}`,
          onToggle: (value: number) => {
            onDecimalToggle(value)
          },
        },
      }),
    [
      hasShowOutsideOperatingHoursEnabled,
      isShowOutsideOperatingHoursAllowed,
      showOutsideOperatingHoursInsights,
      isPrevious,
    ]
  )

  const outsideOperatingHoursBalanceValue = useMemo(() => {
    if (
      !outsideOperatingHoursConsumptionSeries[0]?.data ||
      !Array.isArray(outsideOperatingHoursConsumptionSeries[0]?.data)
    ) {
      return 0
    }
    return (outsideOperatingHoursConsumptionSeries[0].data as { x: ISODateTime | undefined; y: number }[]).reduce(
      (acc: number, measurement) => {
        const outsideOperatingHoursItemOfProperType =
          measurement && typeof measurement !== 'number' && !Array.isArray(measurement) ? measurement : null
        return outsideOperatingHoursItemOfProperType ? acc + +outsideOperatingHoursItemOfProperType.y : acc
      },
      0
    )
  }, [outsideOperatingHoursConsumptionSeries])

  const maxXaxisValue = useMemo(() => {
    let result = Math.max(...series)
    if (isPrevious) {
      result = Math.max(result, ...previousPeriodBalanceData.series)
    }
    if (hasShowOutsideOperatingHoursEnabled) {
      result += outsideOperatingHoursBalanceValue
    }
    return +result.toPrecision(2) + 500
  }, [
    series,
    isPrevious,
    previousPeriodBalanceData.series,
    hasShowOutsideOperatingHoursEnabled,
    outsideOperatingHoursBalanceValue,
  ])

  const airBalanceOptions = useAirBalanceOptions({
    labels,
    colors,
    switchUnit,
    decimal,
    isPrevious: false,
    hideLegend: isPrevious,
  })
  const airBalanceBarChartOptions = useBalanceBarChartOptions({
    labels,
    switchUnit,
    decimal,
    maxXaxisValue,
    isStacked: hasShowOutsideOperatingHoursEnabled,
  })

  const previousPeriodBarChartSeries = useMemo(
    () => [
      { name: `${i18n.text('app.graphs.period')} 1`, data: series, color: theme.palette.SFIBrand?.[600] },
      {
        name: `${i18n.text('app.graphs.period')} 2`,
        data: previousPeriodBalanceData.series,
        color: theme.palette.SFIGreen?.[500],
      },
    ],
    [series, previousPeriodBalanceData]
  )

  const outsideOperatingHoursSeries = useMemo(() => {
    return [
      {
        name: labels[0],
        data: series,
        color: colors[0],
      },
      {
        name: i18n.text('compressed-air.outside-operating-hours'),
        data: [outsideOperatingHoursBalanceValue],
        color: theme.palette.SFIYellow[400],
      },
    ]
  }, [i18n, series, outsideOperatingHoursBalanceValue])

  return (
    <Container>
      <Section
        sx={{
          flexDirection: isPrevious || hasShowOutsideOperatingHoursEnabled ? 'column-reverse' : 'row',
        }}
      >
        {dataMap && dataMap.size !== 0 && !fetching && (
          <BalanceTable
            assets={assets}
            items={items}
            previousPeriodItems={previousPeriodBalanceData.items}
            switchUnit={switchUnit}
          />
        )}
        <FitHeightBoxWrapper
          sx={{
            width: '100%',
            overflowY: 'visible',
          }}
        >
          {isNotBarChart && (
            <AirTemplateGraph
              chartData={{
                id: GraphId.BALANCE,
                options: airBalanceOptions,
                type: 'donut',
                series,
                height: isPrevious ? 300 : undefined,
                wrapperMinHeight: '360px',
              }}
              texts={{
                errorKey: 'error.sensor.data-s',
                header: 'air.graphs.air-balance-header',
              }}
              isLoading={fetching}
              unitSwitchData={
                isPrevious
                  ? undefined
                  : {
                      units: [SwitchUnit.m3, SwitchUnit.cf],
                    }
              }
              isToolbarMargined={false}
              toolbarItems={toolbarItems}
            />
          )}
          {!isNotBarChart && (
            <AirTemplateGraph
              chartData={{
                id: GraphId.BALANCE_PREVIOUS,
                options: airBalanceBarChartOptions,
                type: 'bar',
                series: isPrevious ? previousPeriodBarChartSeries : outsideOperatingHoursSeries,
                wrapperMinHeight: '370px',
              }}
              texts={{
                errorKey: 'error.sensor.data-s',
                header: 'air.graphs.air-balance-header',
                tooltip: 'compressed-air.nm3-info',
              }}
              toolbarItems={toolbarItems}
              unitSwitchData={{
                units: [SwitchUnit.m3, SwitchUnit.cf],
              }}
              isLoading={fetching}
              isToolbarMargined={false}
            />
          )}
        </FitHeightBoxWrapper>
      </Section>
    </Container>
  )
}
