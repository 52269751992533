import { Box, Collapse, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material'
import { useCallback, useState } from 'react'

import StatusOverviewChart from '../../../../components/Charts/StatusOverviewChart'
import VibrationTrendIndicator from '../VibrationTrendIndicator/VibrationTrendIndicator'
import { AssetCard } from '../../../../../Shared/components/AssetCard'
import { AssetStatus } from '../../../../../Shared/components/AssetCard/utils/types'
import { SuccessIcon } from '../../../../../Shared/components/icons/SuccessIcon'
import { VibrationIssueType } from '../../types'
import { VibrationSensorLocation } from '../../../../../Shared/types/maintenanceAssetHealthReportTypes'
import { WarningIcon } from '../../../../../Shared/components/icons/WarningIcon'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

interface AssetHealthReportGroupProps {
  assetId: string
  name: string
  expandAll: boolean
  vibrationSensorLocations: VibrationSensorLocation
}

const AssetHealthReportCard = ({
  name,
  expandAll,
  vibrationSensorLocations: vibrationSensorLocation,
}: AssetHealthReportGroupProps) => {
  const { i18n } = useI18nContext()
  const [expanded, setExpanded] = useState(false)
  const vibrationSensorLocationName = vibrationSensorLocation.name
  const vibrationTrend = vibrationSensorLocation.vibrationTrend
  const sensorStates = vibrationSensorLocation.sensorStates
  const theme = useTheme()

  const statusSeriesLables: string[] = []
  const statusSeriesValues: number[] = []

  Object.entries(sensorStates).forEach(([key, value]) => {
    statusSeriesLables.push(key)
    statusSeriesValues.push(value)
  })

  const vibrationIssueDetected = vibrationSensorLocation.issues
  const vibrationIssueDetectedLength = vibrationIssueDetected && vibrationIssueDetected.length > 0

  const vibrationIssueType: VibrationIssueType[] | undefined = vibrationSensorLocation.issues?.map(
    issue => issue.type as VibrationIssueType
  )

  // TODO: i18n
  const getHealthReportMessage = (type: VibrationIssueType) => {
    switch (type) {
      case VibrationIssueType.Offline:
        return {
          text: i18n.text('pdm.asset.health.report.vibration.issue.type.offline'),
          label: 'Status overview',
        }
      case VibrationIssueType.HighVibration:
        return {
          text: i18n.text('pdm.asset.health.report.vibration.issue.type.highVibration'),
          label: 'Vibration trend',
        }
      case VibrationIssueType.IncreasingVibration:
        return {
          text: i18n.text('pdm.asset.health.report.vibration.issue.type.increasingVibration'),
          label: 'Vibration trend',
        }
      case VibrationIssueType.Other:
        return {
          text: i18n.text('pdm.asset.health.report.vibration.issue.type.other'),
          label: 'Status overview',
        }
    }
  }

  const handleExpandedChange = useCallback(expanded => {
    setExpanded(expanded)
  }, [])

  const getStatusStyles = (status: boolean | undefined) => {
    if (!status) {
      return {
        color: AssetStatus.Success,
        vibrationStatusComponent: (
          <Box
            data-testid="asset-vibration-no-issue-detected"
            sx={{
              display: 'flex',
              position: 'relative',
              backgroundColor: theme.palette.success.light,
              borderRadius: '0.5rem',
              height: theme.spacing(5),
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <SuccessIcon
              sx={{
                color: theme.palette.SFIGreen[500],
                fontSize: theme.typography.pxToRem(18),
                margin: theme.spacing(0, 0, 0, 1),
              }}
            />
            <Typography
              fontStyle={'normal'}
              fontWeight={600}
              color={theme.palette.SFIGreen[600]}
              fontSize={theme.typography.pxToRem(14)}
            >
              {i18n.text('pdm.health.report.status.good')}
            </Typography>
          </Box>
        ),
      }
    } else {
      return {
        color: AssetStatus.Warning,
        vibrationStatusComponent: (
          <Box
            data-testid="asset-vibration-issue-detected"
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '0.5rem',
              backgroundColor: theme.palette.warning.light,
              alignItems: 'flex-start',
              fontSize: theme.typography.pxToRem(12),
              padding: theme.spacing(0.5, 0),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                margin: theme.spacing(0.5, 0),
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  display: 'flex',
                  backgroundColor: theme.palette.SFIWarning[50],
                  color: theme.palette.SFIWarning[700],
                  fontWeight: 500,
                  alignItems: 'center',
                }}
              >
                <WarningIcon
                  style={{
                    fontSize: theme.typography.pxToRem(18),
                    margin: theme.spacing(0, 1),
                    color: theme.palette.SFIWarning[600],
                  }}
                />
                {i18n.text('pdm.health.report.status.issues.detected')}
              </Typography>

              <Typography
                sx={{
                  position: 'relative',
                  right: '10px',
                }}
                fontStyle={'normal'}
                fontWeight={600}
                color={theme.palette.SFIWarning[700]}
                fontSize={theme.typography.pxToRem(14)}
                border={`1px solid ${theme.palette.SFIWarning[700]}`}
                borderRadius={theme.spacing(0.5)}
                padding={theme.spacing(0, 1)}
              >
                {vibrationIssueDetected?.length}
              </Typography>
            </Box>
            <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit
              easing={'ease-in'}
            >
              <Box
                padding={theme.spacing(1, 0)}
                sx={{
                  width: '100%',
                  fontSize: theme.typography.pxToRem(12),
                }}
              >
                <List disablePadding>
                  {vibrationIssueType &&
                    vibrationIssueType?.map((type: VibrationIssueType, index: number) => {
                      return (
                        <ListItem key={index}>
                          <ListItemText
                            sx={{
                              fontSize: theme.typography.pxToRem(12),
                              padding: theme.spacing(0),
                              margin: theme.spacing(0),
                              '.MuiTypography-root': {
                                fontSize: theme.typography.pxToRem(12),
                                lineHeight: theme.typography.pxToRem(0),
                                padding: theme.spacing(0),
                                margin: theme.spacing(0),
                                '&::before': { content: '"•"', position: 'absolute', left: '7px', top: '7px' },
                                '&::after': {
                                  content: `" | ${getHealthReportMessage(type).label}"`,
                                  fontSize: theme.typography.pxToRem(10),
                                  fontWeight: 500,
                                  color: '#475467',
                                },
                              },
                            }}
                          >
                            {getHealthReportMessage(type).text}
                          </ListItemText>
                        </ListItem>
                      )
                    })}
                </List>
              </Box>
            </Collapse>
          </Box>
        ),
      }
    }
  }

  const { color, vibrationStatusComponent } = getStatusStyles(vibrationIssueDetectedLength)
  return (
    <AssetCard
      isCollapsible={true}
      hasAlerts={false}
      alerts={false}
      isCardaExpanded={expandAll}
      primaryName={name}
      secondaryName={vibrationSensorLocationName}
      assetNameOverflow={true}
      assetStatusLabel={{
        customStatusComponent: vibrationStatusComponent,
      }}
      onExpandedChange={handleExpandedChange}
      assetStatusColor={color}
    >
      <StatusOverviewChart
        vibrationSensorLocationLabels={statusSeriesLables}
        vibrationSensorLocationSeries={statusSeriesValues}
      />
      {vibrationTrend && <VibrationTrendIndicator vibrationTrend={vibrationTrend} />}
    </AssetCard>
  )
}

export default AssetHealthReportCard
