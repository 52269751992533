import React, { FC, useMemo, useState } from 'react'
import { Box } from '@mui/material'

import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import {
  GetCompressedAirSystemAndLocationImagesQuery,
  ImageTypeInput,
  useCreateCompressedAirImageMutation,
  useCreateSensorLocationImageMutation,
  useDeleteCompressedAirFlowSystemImageMutation,
  useDeleteSensorLocationImageMutation,
  useGetCompressedAirSystemAndLocationImagesQuery,
} from '../../../Shared/graphql/codegen'
import { ID } from '../../../Shared/types/types'
import {
  IExtendedAirSensorLocation,
  IExtendedCompressedAirAsset,
  ImagesCollectionName,
  SelectedAssetType,
} from '../../types/compressed-air_types'
import { CustomTable } from '../../../Shared/components/MUIComponents/update/CustomTable'
import { UploadImageModal } from '../../../Shared/components/MUIComponents/update/UploadImageModal'
import { ViewImagesModal } from '../../../Shared/components/MUIComponents/update/ViewImageModal'
import { createCASensorLocationTableRowData, createSystemTableRowData } from '../../utils/createTableRowData'
import { getBase64 } from '../../../Shared/utils/getBase64FromFile'
import { getCASensorLocationColumns, getSystemInformationColumns } from '../../utils/getSystemInformationColumns'
import { sendCADeleteImageEvent, sendCAUploadImageEvent } from '../../utils/analyticsEvents'
import { useCompressedAirContext } from '../../context/CompressedAirContext'
import { useCurrentUser } from '../../../Shared/contexts/CurrentUserContext'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../Shared/contexts/ToastContext'

export const SystemInformation: FC = () => {
  const { allAssets, refetchAssetTree } = useCompressedAirContext()
  const { i18n } = useI18nContext()
  const { showToast } = useToastContext()
  const { sendEvent } = useAnalytics()
  const { customerId } = useCurrentUser()
  const [isViewImagesModalOpen, setIsViewImagesModalOpen] = useState(false)
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState<{ id: string; type: SelectedAssetType } | null>(null)
  const [selectedFiles, setSelectedFiles] = useState<File[]>()
  const [uploadedFileIndex, setUploadedFileIndex] = useState<number>(0)

  const systemInfoColumns = getSystemInformationColumns(
    id => handleOpenViewImagesModal(id, SelectedAssetType.SYSTEM),
    id => handleOpenUploadImageModal(id, SelectedAssetType.SYSTEM)
  )
  const locationInfoColumns = getCASensorLocationColumns(
    i18n,
    id => handleOpenViewImagesModal(id, SelectedAssetType.LOCATION),
    id => handleOpenUploadImageModal(id, SelectedAssetType.LOCATION)
  )

  const tableMaxHeight = allAssets.length > 1 ? '440px' : 'max-content'

  const systemImagesIds = useMemo(() => {
    return allAssets.flatMap(system => system.images)
  }, [allAssets])

  const sensorLocationImagesIds = useMemo(() => {
    return allAssets.flatMap(system => system.sensorLocations.flatMap(location => location.images))
  }, [allAssets])

  const onError = () => showToast(i18n.text('file-modal.image-upload.error'), 'error', 'Error')

  const onCompleteCreateImage = () => {
    setUploadedFileIndex(uploadedFileIndex - 1)
    if (uploadedFileIndex - 1 === 0) {
      showToast(i18n.text('file-modal.image-upload.success'), 'success', 'Success!')
      refetchAssetTree()
      setIsUploadImageModalOpen(false)
    }
  }

  const onCompleteDeleteImage = () => {
    showToast(i18n.text('file-modal.image-delete.success'), 'success', 'Success!')
    refetchAssetTree()
  }

  const { data: systemImagesData, loading: gettingAssetImagesLoading } =
    useGetCompressedAirSystemAndLocationImagesQuery({
      variables: {
        ids: systemImagesIds,
        collectionName: ImagesCollectionName.SYSTEM,
      },
    })

  const { data: sensorLocationImagesData, loading: gettingSensorLocationImagesLoading } =
    useGetCompressedAirSystemAndLocationImagesQuery({
      variables: {
        ids: sensorLocationImagesIds,
        collectionName: ImagesCollectionName.LOCATION,
      },
    })

  const [createCompressedAirImage, { loading: createImageLoading }] = useCreateCompressedAirImageMutation({
    onCompleted: onCompleteCreateImage,
    onError,
  })

  const [deleteCompressedAirImage, { loading: deleteImageLoading }] = useDeleteCompressedAirFlowSystemImageMutation({
    onCompleted: onCompleteDeleteImage,
    onError,
  })

  const [createSensorLocationImage, { loading: createSensorLocationImageLoading }] =
    useCreateSensorLocationImageMutation({
      onCompleted: onCompleteCreateImage,
      onError,
    })

  const [deleteSensorLocationImage, { loading: deleteSensorLocationImageLoading }] =
    useDeleteSensorLocationImageMutation({
      onCompleted: onCompleteDeleteImage,
      onError,
    })

  const selectedItem = useMemo(() => {
    let item: IExtendedAirSensorLocation | IExtendedCompressedAirAsset | undefined = allAssets.find(
      system => system.id === selectedItemId?.id
    )
    if (!item) {
      const sensorLocations = allAssets.flatMap(system => system.sensorLocations)
      item = sensorLocations.find(location => location.id === selectedItemId?.id)
    }
    return {
      name: item?.name,
      images: getItemImages(item, selectedItemId?.type),
    }
  }, [selectedItemId, allAssets, systemImagesData, sensorLocationImagesData])

  const selectedImages = useMemo(() => {
    return (
      selectedItem.images?.map(img => ({
        id: img.id,
        url: img.url,
      })) || []
    )
  }, [selectedItem, allAssets])

  const getViewImagesModalSubtitle = () =>
    selectedItemId?.type === SelectedAssetType.SYSTEM
      ? i18n.text('compressed-air.asset-information.upload-system-image')
      : i18n.text('compressed-air.asset-information.upload-location-image')

  function getSystemRows(system: IExtendedCompressedAirAsset) {
    return [
      createSystemTableRowData({
        id: system.id,
        image: getSystemLocationImage(system, systemImagesData),
        pressure: system.compressedAirFlowSystem?.pressure,
        compressors:
          system.compressedAirFlowSystem?.compressedAirSystemLinkedAssets?.flatMap(location => location.asset.name) ||
          [],
        pipes: system.sensorLocations.reduce<string[]>((acc, curr) => {
          if (curr.isSelectedForEnergyEfficiency) {
            acc.push(curr.name)
          }
          return acc
        }, []),
      }),
    ]
  }

  function getSensorLocationRows(system: IExtendedCompressedAirAsset) {
    return system.sensorLocations.map(location =>
      createCASensorLocationTableRowData({
        id: location.id,
        name: location.name,
        inner_diameter: location.innerDiameter,
        outer_diameter: location.outerDiameter,
        image: getSystemLocationImage(location, sensorLocationImagesData),
      })
    )
  }

  function getSystemLocationImage(
    target: IExtendedCompressedAirAsset | IExtendedAirSensorLocation,
    data?: GetCompressedAirSystemAndLocationImagesQuery
  ) {
    const imageId = target.images[0]
    return data?.getSecureMediaUrls?.items.find(secureLink => secureLink.id === imageId)?.url
  }

  function getItemImages(
    item: IExtendedAirSensorLocation | IExtendedCompressedAirAsset | undefined,
    type?: SelectedAssetType
  ) {
    if (item && type) {
      if (type === SelectedAssetType.SYSTEM) {
        return systemImagesData?.getSecureMediaUrls?.items.filter(img => item.images.includes(img.id))
      } else {
        return sensorLocationImagesData?.getSecureMediaUrls?.items.filter(img => item.images.includes(img.id))
      }
    }
    return []
  }

  function handleOpenViewImagesModal(id: string | undefined, type: SelectedAssetType) {
    if (id) {
      setSelectedItemId({ id, type })
      setIsViewImagesModalOpen(true)
    }
  }

  function handleOpenUploadImageModal(id: string, type: SelectedAssetType) {
    setSelectedItemId({ id, type })
    setIsUploadImageModalOpen(true)
  }

  function uploadImage() {
    if (selectedFiles && selectedFiles.length > 0 && selectedItemId?.id) {
      selectedFiles.forEach(imageFile => {
        getBase64(imageFile, async (result: string) => {
          const base64string = result.split(';base64,')[1]
          const sendVariables = {
            customerId,
            image: {
              base64String: base64string,
              type: imageFile.type.replace('image/', '') as ImageTypeInput,
            },
          }
          if (selectedItemId.type === SelectedAssetType.SYSTEM) {
            const asset = allAssets.find(asset => asset.id === selectedItemId.id)
            if (asset) {
              await createCompressedAirImage({
                variables: {
                  ...sendVariables,
                  systemId: asset.compressedAirFlowSystem?.id as string,
                },
              })
              sendCAUploadImageEvent('asset', sendEvent)
            }
          } else {
            await createSensorLocationImage({
              variables: {
                ...sendVariables,
                locationId: selectedItemId?.id,
              },
            })
            sendCAUploadImageEvent('location', sendEvent)
          }
        })
      })
    }
  }

  function deleteImage(imageId: string) {
    if (selectedItemId?.type === SelectedAssetType.SYSTEM) {
      deleteCompressedAirImage({ variables: { id: imageId as ID } })
      sendCADeleteImageEvent('asset', sendEvent)
    } else {
      deleteSensorLocationImage({ variables: { id: imageId as ID } })
      sendCADeleteImageEvent('location', sendEvent)
    }
  }

  function handleViewImagesModalOpenState(isOpen: boolean) {
    setIsViewImagesModalOpen(isOpen)
  }

  function handleUploadImagesModalOpenState(isOpen: boolean) {
    setIsUploadImageModalOpen(isOpen)
  }

  function handleSelectedFiles(files: File[]) {
    setSelectedFiles(files)
    setUploadedFileIndex(files.length)
  }

  return (
    <Box>
      {allAssets.map(system => (
        <Box
          key={system.id}
          sx={{
            marginBottom: theme => theme.spacing(3),
          }}
        >
          <CustomTable
            columns={systemInfoColumns}
            rows={getSystemRows(system)}
            header={{
              title: system.name
            }}
          />
          <CustomTable
            columns={locationInfoColumns}
            rows={getSensorLocationRows(system)}
            maxHeight={tableMaxHeight}
          />
        </Box>
      ))}
      <ViewImagesModal
        title={selectedItem?.name ?? ''}
        subtitle={getViewImagesModalSubtitle()}
        isOpen={isViewImagesModalOpen}
        images={selectedImages}
        isLoading={deleteImageLoading || deleteSensorLocationImageLoading}
        uploadModal={{
          subtitle: i18n.text('compressed-air.asset-information.upload-system-image'),
          isOpen: isUploadImageModalOpen,
          isLoading: createImageLoading || createSensorLocationImageLoading,
          onToggleModal: handleUploadImagesModalOpenState,
        }}
        onToggleModal={handleViewImagesModalOpenState}
        onSelectFiles={handleSelectedFiles}
        onUpload={uploadImage}
        onDelete={deleteImage}
      />
      <UploadImageModal
        title={selectedItem?.name ?? ''}
        isOpen={isUploadImageModalOpen && !isViewImagesModalOpen}
        isLoading={createImageLoading || createSensorLocationImageLoading}
        onToggleModal={handleUploadImagesModalOpenState}
        onSelectFiles={handleSelectedFiles}
        onUpload={uploadImage}
      />
    </Box>
  )
}
