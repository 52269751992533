import React from 'react'
import { Box, MenuItem, Select, SelectChangeEvent, Typography, styled as muiStyled } from '@mui/material'
import { isNil } from 'ramda'

import { AlertRulePayloadType } from '../../../Shared/graphql/codegen'
import { AssetGroup, NONE_KEY } from './types'
import { ID } from '../../../Shared/types/types'

const StyledSelect = muiStyled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.SFIBase.white,
  width: '15em',
  borderRadius: 0,
  border: 'none',
  height: theme.spacing(5),
  background: theme.palette.SFIBase.white,
  '&::after, &::before': {
    display: 'none',
  },
  '& > .MuiSelect-select:focus': {
    background: theme.palette.SFIBase.white,
  },
  '& > .MuiSelect-select': {
    paddingLeft: theme.spacing(0.5),
    paddingBottom: 0,
  },
}))

const StyledTypography = muiStyled(Typography)(({ theme }) => ({
  fontSize: '15px',
  display: 'block',
}))

const StyledGroup = muiStyled(Box)(({ theme }) => ({
  margin: '0em 1em 0 0',
  '& > .MuiTypography-root': {
    fontSize: '13px',
    marginBottom: '0.3em',
  },
  '& > div button': {
    display: 'block',
    backgroundColor: theme.palette.SFIBase.white,
    border: 'none',
    outline: 'none',
    borderRadius: theme.spacing(0.5),
    padding: '1em',
    fontSize: '13px',
    textAlign: 'left',
  },
}))

const InfoGroup = muiStyled(StyledGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
}))

export interface AssetSelection {
  groupId: ID
  machineId: ID
  componentId: ID
}

interface AssetSelectorProps {
  assets: AssetGroup[]
  i18n: $I18FixMe
  onChange: (selected: AssetSelection) => void
  selected: AssetSelection
  info?: JSX.Element
  triggerType?: AlertRulePayloadType
}

const toOptions = <A extends { id: ID; name: string }>(assets: A[]) => [
  { key: NONE_KEY, option: 'None' },
  ...assets.map(a => ({
    key: a.id,
    option: a.name,
  })),
]

export const AssetSelector = ({
  assets,
  i18n,
  triggerType,
  onChange,
  selected: { groupId, machineId, componentId },
  info,
}: AssetSelectorProps): JSX.Element => {
  const availableMachines = assets.find(a => a.id === groupId)?.machines ?? []
  const availableComponents = availableMachines.find(m => m.id === machineId)?.components ?? []

  function _onGroupInputChange(e: SelectChangeEvent<unknown>) {
    const {
      target: { value },
    } = e
    onChange({ groupId: value as ID, machineId: NONE_KEY, componentId: NONE_KEY })
  }

  function _onMachineInputChange(e: SelectChangeEvent<unknown>) {
    const {
      target: { value },
    } = e
    onChange({ groupId, machineId: value as ID, componentId: NONE_KEY })
  }

  function _onComponentInputChange(e: SelectChangeEvent<unknown>) {
    const {
      target: { value },
    } = e
    onChange({ groupId, machineId, componentId: value as ID })
  }

  return (
    <>
      <StyledGroup
        id="asset-selector-group"
        key="asset-selector-group"
      >
        <StyledTypography>{i18n.text('assetselector.headers.group')}</StyledTypography>
        <StyledSelect
          id="asset-selector-group-dropdown"
          value={groupId}
          onChange={_onGroupInputChange}
          size="small"
          variant="standard"
        >
          {toOptions(assets).map(option => (
            <MenuItem
              key={option.key}
              value={option.key}
            >
              {option.option}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledGroup>
      <StyledGroup
        id="asset-selector-machine"
        key="asset-selector-machine"
      >
        <StyledTypography>{i18n.text('assetselector.headers.machine')}</StyledTypography>
        <StyledSelect
          id="asset-selector-machine-dropdown"
          value={machineId}
          disabled={isNil(groupId) || groupId === NONE_KEY}
          onChange={_onMachineInputChange}
          size="small"
          variant="standard"
        >
          {toOptions(availableMachines).map(option => (
            <MenuItem
              key={option.key}
              value={option.key}
            >
              {option.option}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledGroup>
      {triggerType !== AlertRulePayloadType.ProductionSpeed && (
        <StyledGroup
          id="asset-selector-component"
          key="asset-selector-component"
        >
          <StyledTypography>{i18n.text('assetselector.headers.component')}</StyledTypography>
          <StyledSelect
            id="asset-selector-component-dropdown"
            value={componentId}
            disabled={isNil(machineId) || machineId === NONE_KEY || availableComponents.length === 0}
            onChange={_onComponentInputChange}
            size="small"
            variant="standard"
          >
            {toOptions(availableComponents).map(option => (
              <MenuItem
                key={option.key}
                value={option.key}
              >
                {option.option}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledGroup>
      )}
      {info && (
        <InfoGroup
          id="asset-selector-info"
          key="asset-selector-info"
        >
          {info}
        </InfoGroup>
      )}
    </>
  )
}
