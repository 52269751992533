import React, { FC } from 'react'
import { useHistory } from 'react-router'

import { IntroTextWrapper } from '../SetPasswordPage.styled'
import { StyledPrimaryButton } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

export const SuccessView: FC = () => {
  const { i18n } = useI18nContext()
  const history = useHistory()

  const handleClick = () => history.push('/login')

  return (
    <>
      <IntroTextWrapper>{i18n.text('setpassword.success')}</IntroTextWrapper>
      <StyledPrimaryButton
        sx={{
          marginTop: '1.5rem',
        }}
        onClick={handleClick}
      >
        {i18n.text('forms.login.submit')}
      </StyledPrimaryButton>
    </>
  )
}
