const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,

    cookieStorage: {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      secure: true,
      sameSite: 'none',
      path: '/',
      expires: 60,
    },

    // TODO - check if we need this
    mandatorySignIn: false,
  },
}

export default awsConfig
