import { createSvgIcon } from '@mui/material'

export const LightbulbIcon = createSvgIcon(
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="lightbulb-02"
      clipPath="url(#clip0_1523_446)"
    >
      <path
        id="Icon"
        d="M6.66684 12.2721V13.833C6.66684 14.5694 7.2638 15.1663 8.00018 15.1663C8.73656 15.1663 9.33351 14.5694 9.33351 13.833V12.2721M8.00018 1.83301V2.49967M2.00018 8.49967H1.33351M3.66684 4.16634L3.26678 3.76628M12.3335 4.16634L12.7337 3.76628M14.6668 8.49967H14.0002M12.0002 8.49967C12.0002 10.7088 10.2093 12.4997 8.00018 12.4997C5.79104 12.4997 4.00018 10.7088 4.00018 8.49967C4.00018 6.29054 5.79104 4.49967 8.00018 4.49967C10.2093 4.49967 12.0002 6.29054 12.0002 8.49967Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1523_446">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.000183105 0.5)"
        />
      </clipPath>
    </defs>
  </svg>,
  'lightbulb-icon'
)
