import { ChartToolsItem } from '../../Shared/components/MUIComponents/update/ChartToolsDropdown'

interface ISetToolbarItemsArgs {
  isOutsideOperatingHoursVisible: boolean
  outsideOperatingHours: {
    isChecked: boolean
    isDisabled: boolean
    isMoreThanMonth?: boolean,
    isCompareDatesOn?: boolean
    onToggle: () => void
  }
  decimalChangeArgs: {
    selected: number
    label: string
    onToggle: (value: number) => void
  }
  minMaxRange?: {
    isChecked: boolean
    isDisabled: boolean
    onToggle: () => void
  }
  showMean?: {
    isChecked: boolean
    onToggle: () => void
  }
}

// TODO (Sabina): Uncomment when needed
export const setToolbarItems: (args: ISetToolbarItemsArgs) => ChartToolsItem[] = ({
  isOutsideOperatingHoursVisible,
  outsideOperatingHours,
  minMaxRange,
  showMean,
  decimalChangeArgs,
}) => {
  const getOutsideOperatingHoursTooltipText = () => {
    if (outsideOperatingHours.isCompareDatesOn) {
      return 'compressed-air.tools.outside-operating-hours.disabled-text2'
    }
    
    if (outsideOperatingHours.isMoreThanMonth) {
      return 'compressed-air.tools.outside-operating-hours.disabled-text3'
    }

    return 'compressed-air.tools.outside-operating-hours.disabled-text1'
  }

  const result: ChartToolsItem[] = [
    {
      label: decimalChangeArgs.label,
      values: [0, 1, 2, 3, 4],
      onValueToggle: decimalChangeArgs.onToggle,
      selectedValue: decimalChangeArgs.selected,
    },
    // {
    //   label: 'compressed-air.outside-operating-hours',
    //   isChecked: outsideOperatingHours.isChecked,
    //   isDisabled: outsideOperatingHours.isDisabled,
    //   tooltip: {
    //     text: getOutsideOperatingHoursTooltipText(),
    //     align: 'right',
    //   },
    //   onToggle: outsideOperatingHours.onToggle,
    // },
  ]

  if (isOutsideOperatingHoursVisible) {
    result.push({
      label: 'compressed-air.outside-operating-hours',
      isChecked: outsideOperatingHours.isChecked,
      isDisabled: outsideOperatingHours.isDisabled,
      tooltip: {
        text: getOutsideOperatingHoursTooltipText(),
        align: 'right',
      },
      onToggle: outsideOperatingHours.onToggle,
    })
  }

  if (minMaxRange) {
    result.push({
      label: 'air.graphs.min-max-range',
      isChecked: minMaxRange.isChecked,
      isDisabled: minMaxRange.isDisabled,
      tooltip: {
        text: 'compressed-air.tools.min-max.disabled-text',
        align: 'right',
      },
      onToggle: minMaxRange.onToggle,
    })
  }

  if (showMean) {
    result.push({
      label: 'graph.button.tools.show-mean',
      isChecked: showMean.isChecked,
      onToggle: showMean.onToggle,
    })
  }

  return result.reverse()
}
