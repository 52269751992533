import { Building, UsersPlus, FileSettings, Modem } from '../../Shared/components/icons'
import { IStage, OnboardingPath } from '../models'

export const STAGES: IStage[] = [
  {
    key: OnboardingPath.COMPANY_DETAILS,
    icon: Building,
    title: 'onboarding.stages.title.company-details',
    subtitle: 'onboarding.stages.subtitle.company-details',
  },
  {
    key: OnboardingPath.INVITE_MEMBERS,
    icon: UsersPlus,
    title: 'onboarding.stages.title.invite-your-team',
    subtitle: 'onboarding.stages.subtitle.invite-your-team',
  },
  {
    key: OnboardingPath.TECHNICAL_INFO,
    icon: FileSettings,
    title: 'onboarding.stages.title.technical-info',
    subtitle: 'onboarding.stages.subtitle.technical-info',
  },
  {
    key: OnboardingPath.INSTALLATION,
    icon: Modem,
    title: 'onboarding.stages.title.install-prep',
    subtitle: 'onboarding.stages.subtitle.install-prep',
  },
];

export const OnboardingWorkflow = STAGES.map(stage => stage.key);

export const ONBOARDING_STAGE_STORAGE = 'onboarding-stage'
