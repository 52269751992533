import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ArrowDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        viewBox="0 0 12 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 0.75L6 5.25L10.5 0.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
