import React, { FC, useMemo } from 'react'
import { Box, BoxProps, Tooltip, Typography, styled, useTheme } from '@mui/material'
import { useHistory } from 'react-router'

import { HumidityIcon } from '../../../../Shared/components/icons/HumidityIcon'
import { ITemperatureSensorLocation, Insights, UnitName } from '../../../types'
import { ThermometerIcon } from '../../../../Shared/components/icons/ThermometerIcon'
import { getRelevantValueForToday } from '../../../utils/getRelevantValueForToday'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

interface IOverviewCardProps {
  sensorLocation: ITemperatureSensorLocation
  assetName: string
  insights: Insights
}

type TodayValues = {
  temperature: string | number | null
  humidity: string | number | null
}

const OverviewCardWrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.SFIGreyLight[200]}`,
  cursor: 'pointer',
  position: 'relative',
  transition: 'all 0.2s linear',
  background: theme.palette.SFIBase.white,
  width: '290px',
  height: 'fit-content',
  '&:hover': {
    boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
  },
}))

interface IStampProps extends BoxProps {
  isHumidity?: boolean
  isNegative?: boolean
}

const OverviewCardBody = styled(Box)(({ theme }) => ({
  padding: '1.25rem 1rem',
}))

const Stamp = styled((props: IStampProps) => {
  const { isHumidity, isNegative, ...other } = props
  return <Box {...other} />
})(({ theme, isNegative, isHumidity }) => ({
  borderRadius: '50%',
  width: '3rem',
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: '0.875rem',
  textAlign: 'center',
  background: isHumidity
    ? theme.palette.SFIBrand[100]
    : isNegative
    ? theme.palette.SFIPurple[50]
    : theme.palette.SFIOrange[100],
  color: isHumidity
    ? theme.palette.SFIBrand[900]
    : isNegative
    ? theme.palette.SFIPurple[700]
    : theme.palette.SFIOrange[600],
}))

const FlexWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  justifyContent: 'center',
  alignItems: 'center',
}))

const StampTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[500],
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '18px',
}))

const NameTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[800],
  fontSize: '0.875rem',
  fontWeight: 600,
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const ValueText = styled('span')(({ theme }) => ({
  color: theme.palette.SFIGreyLight[800],
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: '1.875rem',
  whiteSpace: 'nowrap',
}))

const OverviewCardContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2.75),
  marginTop: '1.25rem',
}))

export const OverviewCard: FC<IOverviewCardProps> = ({ sensorLocation, assetName, insights }) => {
  const theme = useTheme()
  const history = useHistory()
  const { i18n } = useI18nContext()

  const todayValues: TodayValues = useMemo(() => {
    if (insights) {
      const temperatureMeasurements =
        (insights.timeWindow?.temperatureMeasurements?.map(item => item.value) as number[]) ?? []
      const humidityMeasurements =
        (insights.timeWindow?.humidityMeasurements?.map(item => item.value) as number[]) ?? []
      return {
        temperature: getRelevantValueForToday(temperatureMeasurements, 1),
        humidity: getRelevantValueForToday(humidityMeasurements),
      }
    }
    return {
      temperature: null,
      humidity: null,
    }
  }, [sensorLocation, insights])

  const isTemperatureValueNegative = !!todayValues.temperature && +todayValues.temperature < 0

  function openSensorLocationGraph() {
    history.push({
      pathname: `/temperature/flow/${sensorLocation.id}`,
    })
  }

  return (
    <OverviewCardWrapper onClick={openSensorLocationGraph}>
      <OverviewCardBody>
        <Tooltip title={`${assetName} - ${sensorLocation?.name}`}>
          <NameTypography>{`${assetName} - ${sensorLocation?.name}`}</NameTypography>
        </Tooltip>
        <OverviewCardContent>
          <FlexWrapper>
            <Stamp isNegative={isTemperatureValueNegative}>
              <ThermometerIcon />
            </Stamp>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <StampTitle>{i18n.text('temperature.main.title')}</StampTitle>
              <ValueText>{todayValues.temperature ? `${todayValues.temperature} ${UnitName.celsius}` : '-'}</ValueText>
            </Box>
          </FlexWrapper>
          {todayValues.humidity !== null && (
            <FlexWrapper>
              <Stamp isHumidity>
                <HumidityIcon />
              </Stamp>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <StampTitle>{i18n.text('temperature.name.humidity')}</StampTitle>
                <ValueText>{todayValues.humidity ? `${todayValues.humidity} ${UnitName.percentage}` : '-'}</ValueText>
              </Box>
            </FlexWrapper>
          )}
        </OverviewCardContent>
      </OverviewCardBody>
    </OverviewCardWrapper>
  )
}
