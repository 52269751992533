import { StandbyValidationEntries, StandbyValidationType } from '../types'

const getAssetValidationType: (
  validationEntries: StandbyValidationEntries
) => StandbyValidationType = validationEntries => {
  if (validationEntries.length === 0) {
    return null
  }
  const hasRA = validationEntries.some(entry => entry.validationType === 'RA')

  if (hasRA) {
    return 'RA'
  }

  return 'DC'
}

export default getAssetValidationType
