/** A function that converts polar coordinates (centerX, centerY, radius, angleInDegrees) to Cartesian coordinates (x, y) using trigonometry formulas
 *  @param {number} centerX - The x-coordinate of the center of the circle.
 *  @param {number} centerY - The y-coordinate of the center of the circle.
 *  @param {number} radius - The radius of the circle.
 *  @param {number} angleInDegrees - The angle in degrees.
 * **/
export const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
  const angleInRadians = (angleInDegrees * Math.PI) / 180

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}
