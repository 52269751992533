import { Box } from '@mui/material'

import PngExporter from '../../../Shared/components/MUIComponents/ChartTools/PngExporter'
import { DownloadIcon } from '../../../Shared/components/icons/DownloadIcon'
import { ElectricityAnalysisType } from '../../types'
import { StyledMenu } from '../../../Shared/components/MUIComponents/update/StyledMenu'
import { StyledMenuItem } from '../../../Shared/components/MUIComponents/update/ChartToolsDropdown/StyledMenuItem'
import { useElectricityContext } from '../../context/ElectricityContext'
import { useEnergyBalancePngExporterOptions } from '../../hooks/useEnergyBalancePngExporterOptions'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

interface ExportMenuContainerProps {
  analysisType: ElectricityAnalysisType
}
export const ExportMenuContainer = ({ analysisType }: ExportMenuContainerProps) => {
  const { i18n } = useI18nContext()
  const pngExporterOptions = useEnergyBalancePngExporterOptions()
  const { setCsvExportRequest } = useElectricityContext()

  const slotProps = {
    button: {
      sx: {
        background: 'none',
        padding: 0,
        '&:hover': {
          background: 'none',
        },
      },
    },
  }

  const renderPngExporter = () => {
    if (analysisType === 'power') {
      return (
        <PngExporter
          element={document.getElementById('analysis-graph')}
          fileName={`Sensorfact Energy Analysis - ${new Date().toLocaleDateString()}`}
          slotProps={slotProps}
        />
      )
    }
    if (analysisType === 'energy') {
      return (
        <PngExporter
          element={document.getElementById('analysis-graph')}
          fileName={`Sensorfact Energy Analysis - ${new Date().toLocaleDateString()}`}
          slotProps={slotProps}
        />
      )
    }
    if (analysisType === 'energy-balance') {
      return (
        <PngExporter
          element={document.getElementById('analysis-graph')}
          fileName={`Sensorfact Energy Analysis - ${new Date().toLocaleDateString()}`}
          options={pngExporterOptions}
          slotProps={slotProps}
        />
      )
    }
  }

  return (
    <Box ml={2}>
      <StyledMenu
        menuButtonContent={
          <>
            <DownloadIcon
              sx={{
                width: '15px',
                height: '15px',
              }}
            />
            {i18n.text('graph.button.tools.export')}
          </>
        }
      >
        <StyledMenuItem>{renderPngExporter()}</StyledMenuItem>
        <StyledMenuItem onClick={() => setCsvExportRequest(analysisType)}>
          {i18n.text('tooltip.export-button')}
        </StyledMenuItem>
      </StyledMenu>
    </Box>
  )
}
