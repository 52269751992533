import React from 'react'
import { Box, BoxProps, SxProps, Theme, Typography, TypographyProps, styled, useTheme } from '@mui/material'

import { StyledCheckbox as Checkbox } from '../Checkbox/StyledCheckBox'

type ChildNode = {
  id: string
  name: string
}

interface IChildNodeProps<T> {
  item: T
  isSelected: boolean
  hasCheckbox?: boolean
  handleClick: () => void
  wrapperSxProps?: SxProps<Theme>
  iconSlot?: React.ReactElement
}

interface IAssetTreeChildNodeWrapperProps extends BoxProps {
  isSelected: boolean
  wrapperSxProps?: SxProps<Theme>
}

interface IAssetTreeChildNodeLabelProp extends TypographyProps {
  isSelected: boolean
}

const IconSlotWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
}))

const CheckboxLabelWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'visible',
  width: '100%',
}))

const AssetTreeChildNodeWrapper = ({
  isSelected,
  children,
  wrapperSxProps,
  ...props
}: IAssetTreeChildNodeWrapperProps) => {
  const theme = useTheme()
  const spreadableStyles = typeof wrapperSxProps === 'function' ? wrapperSxProps(theme) : wrapperSxProps
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme => theme.spacing(1.5),
        padding: theme => theme.spacing(1, 3.25),
        borderRadius: '6px',
        color: theme => theme.palette.SFIGreyLight[700],
        marginBottom: theme => theme.spacing(0.5),
        backgroundColor: theme => (isSelected ? theme.palette.SFIBrand?.[100] : 'initial'),
        '&:last-of-type': {
          marginBottom: 0,
        },
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme => theme.palette.SFIBrand?.[100],
          color: theme => theme.palette.SFIBrand?.[800],
        },
        ...spreadableStyles,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

const AssetTreeChildNodeLabel = ({ isSelected, children, ...props }: IAssetTreeChildNodeLabelProp) => (
  <Typography
    sx={{
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
      underline: 'none',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: theme => (isSelected ? theme.palette.SFIBrand?.[800] : 'inherit'),
    }}
    {...props}
  >
    {children}
  </Typography>
)

export function AssetTreeChildNode<T extends ChildNode>({
  item,
  isSelected,
  hasCheckbox,
  handleClick,
  wrapperSxProps,
  iconSlot,
}: IChildNodeProps<T>) {
  return (
    <AssetTreeChildNodeWrapper
      isSelected={isSelected}
      data-testid="asset-tree-child-node"
      onClick={handleClick}
      wrapperSxProps={wrapperSxProps}
    >
      <CheckboxLabelWrapper>
        {hasCheckbox && (
          <Checkbox
            checked={isSelected}
            color="secondary"
            size="small"
            sx={{
              padding: 0,
            }}
          />
        )}
        <AssetTreeChildNodeLabel
          isSelected={isSelected}
          color="primary"
        >
          {item.name}
        </AssetTreeChildNodeLabel>
      </CheckboxLabelWrapper>
      <IconSlotWrapper>{iconSlot && iconSlot}</IconSlotWrapper>
    </AssetTreeChildNodeWrapper>
  )
}
