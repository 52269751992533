import React, { ChangeEvent, FC, useState } from 'react'
import { Box, Checkbox, styled } from '@mui/material'

import { ExplanationText, GridBoxRow, Label } from '..'
import { isTrackingEnabled, setTracking } from '../../../../../Shared/utils'
import { useI18nContext } from '../../../../../Shared/contexts/i18nContext/I18nContext'

export const Privacy: FC = () => {
  const { i18n } = useI18nContext()
  const trackingEnabled = isTrackingEnabled()
  const [trackingStatus, setTrackingStatus] = useState(trackingEnabled)

  const handleChange: (event: ChangeEvent<HTMLInputElement>) => void = (event) => {
    setTrackingStatus(event.target.checked)
    setTracking(event.target.checked)
  }

  return (
    <Box>
      <GridBoxRow>
        <Box>
          <Label>In-app</Label>
          <ExplanationText>{i18n.text('policies.tracking.enable')}</ExplanationText>
        </Box>
        <CheckBoxWrapper>
          <Box sx={{ gridArea: 'a' }}>
            <Checkbox
              id="in-app-tracking-checkbox"
              color="secondary"
              size="small"
              checked={trackingStatus}
              sx={{ padding: 0, marginRight: '0.5rem' }}
              onChange={handleChange}
            />
          </Box>
          <Label sx={{ fontWeight: 500, gridArea: 'b' }}>{i18n.text('general-settings.in-app-tracking')}</Label>
          <ExplanationText sx={{ gridArea: 'c', maxWidth: '30rem' }}>
            {i18n.text('general-settings.privacy.text')}
          </ExplanationText>
        </CheckBoxWrapper>
      </GridBoxRow>
    </Box>
  )
}

const CheckBoxWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  justifyContent: 'flex-start',
  gridTemplateAreas: `'a b'
                      'a c'`,
}))
