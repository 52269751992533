import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

// An icon from the Untitled UI icons library.
// It is wrapped into SvgIcon component from mui/material to be used as its icon.

export const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
      fontSize="inherit"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3451_28471)">
          <path
            d="M7.99998 10.6666V7.99998M7.99998 5.33331H8.00665M14.6666 7.99998C14.6666 11.6819 11.6819 14.6666 7.99998 14.6666C4.31808 14.6666 1.33331 11.6819 1.33331 7.99998C1.33331 4.31808 4.31808 1.33331 7.99998 1.33331C11.6819 1.33331 14.6666 4.31808 14.6666 7.99998Z"
            stroke="#98A2B3"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3451_28471">
            <rect
              width="16"
              height="16"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}
