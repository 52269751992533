import { isEmpty } from 'ramda'

import { sortMeasurements } from './sortMeasurements'

/**
 * formattedMeasurements - Strips unneeded properties from assets to only use
 * the measurements
 *
 * @param  {array} assets List of assets
 * @return {object}       by ID mapped Map
 */
export function formatMeasurements(
  groups: $TSFixMe[] = [],
  { acc = {}, measurementKey = 'measurements', skipFirstLevel = false }
) {
  ;[...groups].forEach(({ id, name, [measurementKey]: measurements, assets }) => {
    if (!skipFirstLevel) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [x: string]: any[]; name: never; timeFetch... Remove this comment to see the full error message
      acc[id] = {
        name,
        assets,
        [measurementKey]: sortMeasurements([...measurements]),
      }
    } else {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: never; timeFetched: never; assets: n... Remove this comment to see the full error message
      acc[id] = {
        name,
        assets,
      }
    }

    if (assets && !isEmpty(assets)) {
      formatMeasurements(assets, { measurementKey, acc })
    }
  })

  return acc
}
