import { FC, useState } from 'react'

import {
  BackGroundBox,
  GraphMainInfoBox,
  MainSavingsInfoBox,
  OpenTableButton,
} from '../../../components/Common/v1/index'
import { PressureReductionContainerProps } from '../../../types'
import { PressureReductionGraph } from '../../../components/PressureReduction/v1/PressureReductionGraph'
import { PressureReductionTable } from '../../../components/PressureReduction/v2/PressureReductionTable'

export const PressureReduction: FC<PressureReductionContainerProps> = ({
  input,
  chartWidth,
  tableOpenGlobal,
  versionTemplate,
}) => {
  const savingName = 'pressure_reduction'
  const [tableOpen, setTableOpen] = useState(tableOpenGlobal)
  const handleTableOpen = () => setTableOpen(!tableOpen)
  const note = 'Pressure reducing is saving energy. We found that this should be done in your case' //todo from json

  return (
    <BackGroundBox>
      <GraphMainInfoBox>
        <PressureReductionGraph
          input={input}
          chartWidth={chartWidth}
        />
        <MainSavingsInfoBox
          savingName={savingName}
          costSaving={input.savings_outputs_total.costs_saved}
          energySaving={input.savings_outputs_total.energy_saved}
          emissionsSaving={input.savings_outputs_total.emissions_saved}
          version={'v1'}
          note={note}
          numInsights={1}
          numActions={3}
          webappTip={true}
          trTip={false}
          alertTip={true}
        />
      </GraphMainInfoBox>
      <OpenTableButton
        tableOpen={tableOpen}
        handleOnClick={handleTableOpen}
      />
      {tableOpen && <PressureReductionTable savingsOutputs={input.savings_outputs} />}
    </BackGroundBox>
  )
}
