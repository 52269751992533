import { FC, useEffect, useState } from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { DayOfWeek } from '../../../../Shared/types/types'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { Noop } from 'react-hook-form'

interface DayOfWeekSelectProps {
  value: DayOfWeek
  onChange: (e: SelectChangeEvent<number>) => void | undefined
  onBlur?: Noop
  inputRef?: React.Ref<HTMLInputElement>
  name?: string
  inputProps?: {
    [key: string]: string | number
  }
  SelectDisplayProps?: any
}

const DayOfWeekSelect: FC<DayOfWeekSelectProps> = props => {
  const { i18n } = useI18nContext()
  const { value, onChange } = props
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])

  const handleChange = (e: SelectChangeEvent<DayOfWeek>): void => {
    setCurrentValue(+e.target.value as DayOfWeek)
    onChange(e)
  }

  return (
    <Select
      {...props}
      ref={props.inputRef}
      value={currentValue}
      onChange={handleChange}
    >
      <MenuItem value={1}>{i18n.text('dayofweek.Monday')}</MenuItem>
      <MenuItem value={2}>{i18n.text('dayofweek.Tuesday')}</MenuItem>
      <MenuItem value={3}>{i18n.text('dayofweek.Wednesday')}</MenuItem>
      <MenuItem value={4}>{i18n.text('dayofweek.Thursday')}</MenuItem>
      <MenuItem value={5}>{i18n.text('dayofweek.Friday')}</MenuItem>
      <MenuItem value={6}>{i18n.text('dayofweek.Saturday')}</MenuItem>
      <MenuItem value={7}>{i18n.text('dayofweek.Sunday')}</MenuItem>
    </Select>
  )
}

export default DayOfWeekSelect
