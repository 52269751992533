//Creates a row with visualization settings. Note: each module can have multiple visualizations.
//I.e. the same data will be shown in both a bar chart and a table.
import { Box, IconButton, SelectChangeEvent, Typography, styled, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { MenuItemContentCheckmark } from '../../../../Shared/components/MUIComponents/update/Selectv1/MenuItemContentCheckmark'
import { StyledInputFieldHeader } from './Common'
import { StyledMenuItem } from '../../../../Shared/components/MUIComponents/update/Selectv1/StyledMenuItem'
import { StyledSelect } from '../../../../Shared/components/MUIComponents/update/Selectv1/StyledSelect'
import { TrashIcon } from '../../../../Shared/components/icons'
import { VisualisationRowProps } from './types'
import { getNumBars } from './utils'
import { useGetAllowedAnalysisUnitsQuery } from '../../../../Shared/graphql/codegen'
import { useGetAllowedAnalysisVisualisationsQuery } from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const PlaceholderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[500],
  fontSize: '1rem',
  fontWeight: 400,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

export const VisualisationRow: FC<VisualisationRowProps> = ({
  currentVisualisationType,
  currentUnit,
  analysisType,
  currentId,
  currentPosition,
  handleVisualisationChange,
  assetGroupingLevel,
  timeGroupingLevel,
  selectedAssets,
  daysOfWeekInclude,
  maxNumBars,
  referenceInclude,
  onDelete,
  index,
}) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { data: allowedUnitsData } = useGetAllowedAnalysisUnitsQuery()
  const { data: allowedVisualisationsData } = useGetAllowedAnalysisVisualisationsQuery()
  const allowedVisualisationTypes =
    allowedVisualisationsData?.trackingReportAllowedAnalysisVisualisations
      .filter(type => type.analysisType === analysisType)
      .map(type => type.visualisationType) || []

  const allowedUnits = allowedUnitsData?.trackingReportAllowedAnalysisUnits
    .filter(unit => unit.analysisType === analysisType)
    .map(unit => unit.visualisationUnit)

  function getFilteredVisualisationTypes(assetGroupingLevel: string, analysisType: string) {
    return assetGroupingLevel === 'group' && analysisType !== 'peakPower'
      ? allowedVisualisationTypes.filter(grouping => grouping !== 'incidentsTable')
      : allowedVisualisationTypes
  }
  const filteredVisualisationTypes = getFilteredVisualisationTypes(assetGroupingLevel, analysisType)

  const visualisation = {
    visualisationType: currentVisualisationType,
    unit: currentUnit,
    analysisType: analysisType,
    id: currentId,
    position: currentPosition,
  }
  const [visualisationType, setVisualisationType] = useState(currentVisualisationType)
  const [unit, setUnit] = useState(currentUnit)

  const onTypeChange = (change: string) => {
    const updatedVisualisation = visualisation
    updatedVisualisation.visualisationType = change
    handleVisualisationChange(updatedVisualisation)
  }

  const onUnitChange = (change: string) => {
    const updatedVisualisation = visualisation
    updatedVisualisation.unit = change
    handleVisualisationChange(updatedVisualisation)
  }

  useEffect(() => {
    setVisualisationType(currentVisualisationType)
    setUnit(currentUnit)
  }, [visualisation])

  const numBars =
    getNumBars(selectedAssets, assetGroupingLevel, timeGroupingLevel, daysOfWeekInclude, referenceInclude) || 0

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '42% 42% 10%',
        gap: '1.625rem',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '0.5rem',
        }}
      >
        <StyledInputFieldHeader>
          {i18n.text(`reports.tracking.configuration.visualisation.type`)}
        </StyledInputFieldHeader>
        <StyledSelect
          displayEmpty
          value={visualisationType}
          size="small"
          onChange={(event: SelectChangeEvent<string>) => {
            onTypeChange(event.target.value)
            setVisualisationType(event.target.value)
          }}
          renderValue={selected =>
            selected !== '' ? (
              i18n.text(`reports.tracking.configuration.visualisation.${selected}`)
            ) : (
              <PlaceholderText>{i18n.text('reports.tracking.configuration.placeholder.selectType')}</PlaceholderText>
            )}
        >
          {filteredVisualisationTypes?.map((visualisationTypeOption, index) => (
            <StyledMenuItem
              key={index}
              value={visualisationTypeOption}
              disabled={visualisationTypeOption === 'barChart' && numBars > maxNumBars}
            >
              {visualisationTypeOption === visualisationType ? (
                <MenuItemContentCheckmark
                  i18n={`reports.tracking.configuration.visualisation.${visualisationTypeOption}`}
                />
              ) : (
                i18n.text(`reports.tracking.configuration.visualisation.${visualisationTypeOption}`)
              )}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '0.5rem',
        }}
      >
        <StyledInputFieldHeader>
          {i18n.text(`reports.tracking.configuration.visualisation.unit`)}
        </StyledInputFieldHeader>
        <StyledSelect
          displayEmpty
          value={unit}
          size="small"
          onChange={(event: SelectChangeEvent<string>) => {
            onUnitChange(event.target.value)
            setUnit(event.target.value)
          }}
          renderValue={selected =>
            selected !== '' ? (
              i18n.text(`reports.tracking.configuration.visualisation.unit.${selected}`)
            ) : (
              <PlaceholderText>{i18n.text('reports.tracking.configuration.placeholder.selectUnit')}</PlaceholderText>
            )}
        >
          {allowedUnits?.map((unitOption, index) => (
            <StyledMenuItem
              key={index}
              value={unitOption}
            >
              {unitOption === unit ? (
                <MenuItemContentCheckmark i18n={`reports.tracking.configuration.visualisation.unit.${unitOption}`} />
              ) : (
                i18n.text(`reports.tracking.configuration.visualisation.unit.${unitOption}`)
              )}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.375rem',
        }}
      >
        <StyledInputFieldHeader>
          {i18n.text('reports.tracking.configuration.module.visualisation.delete')}
        </StyledInputFieldHeader>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '2.625rem',
            width: 'fit-content',
            '&:hover': {
              borderRadius: '0.375rem',
              backgroundColor: theme.palette.SFIError[50],
            },
          }}
        >
          <IconButton
            onClick={() => {
              onDelete(visualisation.id, index)
            }}
            disableRipple
          >
            <TrashIcon sx={{ color: theme.palette.error.dark }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
