import { FC } from 'react'

import { CustomTable } from '../../../../Shared/components/MUIComponents/update/CustomTable'
import { ICustomTableColumn } from '../../../../Shared/types/types'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

type PressureReductionInputProps = {
  savingsOutputs: {
    event_id: string
    energy_saved: number
    costs_saved: number
    emissions_saved: number
    scenario: string
    unit: string
  }[]
}

type PressureReductionTableData = {
  energy_saved: string
  costs_saved: string
  emissions_saved: string
  scenario: string
  unit: string
  id: string
}

export const PressureReductionTable: FC<PressureReductionInputProps> = ({ savingsOutputs }) => {
  const { i18n, userLocale } = useI18nContext()
  const userLocaleFormat = userLocale?.replace('_', '-')
  const tableColumns: ICustomTableColumn<PressureReductionTableData>[] = [
    { id: 'unit', label: i18n.text('scan.savings.pressure_reduction.v1.table.system-name'), align: 'left' },
    { id: 'scenario', label: i18n.text('scan.savings.pressure_reduction.v1.table.scenario') },
    { id: 'energy_saved', label: i18n.text('scan.savings.pressure_reduction.v1.table.energy-saving') },
    { id: 'costs_saved', label: i18n.text('scan.savings.pressure_reduction.v1.table.cost-saving') },
    { id: 'emissions_saved', label: i18n.text('scan.savings.pressure_reduction.v1.table.emissions-saving') },
  ]

  const units = savingsOutputs.map(item => item.unit)
  const tableUnits: string[] = units.map(unit => (tableUnits.includes(unit) ? ' ' : unit))

  const tableRowsFormatted: PressureReductionTableData[] = savingsOutputs.map((row: any, index: number) => ({
    ...row,
    energy_saved: row.energy_saved.toLocaleString(userLocaleFormat, { maximumFractionDigits: 0 }),
    costs_saved: row.costs_saved.toLocaleString(userLocaleFormat, { maximumFractionDigits: 0 }),
    emissions_saved: row.emissions_saved.toLocaleString(userLocaleFormat, { maximumFractionDigits: 0 }),
    unit: tableUnits[index],
  }))

  return (
    <CustomTable
      columns={tableColumns}
      rows={tableRowsFormatted}
    />
  )
}
