import { ApolloError } from '@apollo/client'
import { DateTime } from 'luxon'
import { FC, useCallback, useMemo, useState } from 'react'

import { AssetTreeMode, Group } from '../types/Assets.types'
import { GraphInterval } from '../types/Graph.types'
import { OEEUnits } from '../types/Units.types'
import { Oee_Production_Speed_Unit_Enum, useGetOeeAssetsByCommodityTypeQuery } from '../../Shared/graphql/codegen'
import { createCtx, getAssetColor, toLocalDateTime } from '../../Shared/utils'
import { transformAssetsData } from '../utils/dataTransformations'
import { useAuthContext } from '../../Shared/contexts/AuthContext'

interface OEEContextProps {
  assets: Group[]
  assetColors: Record<string, string>
  assetsError: ApolloError | undefined
  assetsLoading: boolean
  selectedMachine: string | null
  updateSelectedMachine: (id: string) => void
  interval: GraphInterval
  updateInterval: (newInterval: Partial<GraphInterval>) => void
  assetTreeMode: AssetTreeMode
  updateAssetTreeMode: (mode: AssetTreeMode) => void
  selectedMachines: string[]
  addSelectedMachine: (id: string) => void
  removeSelectedMachine: (id: string) => void
  stopReasons: string[]
  selectedStopReasons: string[]
  setNewStopReasons: (reasons: string[]) => void
  selectStopReason: (reason: string) => void
  deselectStopReason: (reason: string) => void
  units: OEEUnits
}

const [useOEEContext, OEEContextReactProvider] = createCtx<OEEContextProps>()

const OEEContextProvider: FC = ({ children }) => {
  const [selectedMachine, setSelectedMachine] = useState<string | null>(null)
  const [selectedMachines, setSelectedMachines] = useState<string[]>([])
  const [stopReasons, setStopReasons] = useState<string[]>([])
  const [selectedStopReasons, setSelectedStopReasons] = useState<string[]>([])
  const [interval, setInterval] = useState<GraphInterval>({
    start: toLocalDateTime(DateTime.local().startOf('week')),
    end: toLocalDateTime(DateTime.local().endOf('week')),
  })
  const [assetTreeMode, setAssetTreeMode] = useState<AssetTreeMode>(AssetTreeMode.SINGLE_SELECT)

  const { currentCustomer } = useAuthContext()

  const {
    data: fetchedAssets,
    error: fetchAssetsError,
    loading: fetchAssetsLoading,
  } = useGetOeeAssetsByCommodityTypeQuery({
    variables: { commodityType: 'production-cycles' },
  })

  const units: OEEUnits = {
    speed: currentCustomer?.oeePreferences?.productionSpeedUnit ?? Oee_Production_Speed_Unit_Enum.CyclesPerHour,
  }

  const assets = useMemo(() => {
    const rawAssets = fetchedAssets?.myOrg?.assets || []
    const transformedAssetData = transformAssetsData([...rawAssets])

    if (transformedAssetData.length > 0) {
      setSelectedMachine(transformedAssetData[0].machines[0].id)
    }

    return transformedAssetData
  }, [fetchedAssets])

  const assetColors = useMemo<Record<string, string>>(() => {
    let colorIndex = 0
    return assets.reduce((colorMap: Record<string, string>, group) => {
      group.machines.forEach(machine => {
        colorMap[machine.id] = getAssetColor(colorIndex++)
      })
      return colorMap
    }, {})
  }, [assets])

  const updateAssetTreeMode = useCallback((mode: AssetTreeMode) => {
    setAssetTreeMode(mode)
  }, [])

  const updateSelectedMachine = useCallback((id: string) => {
    setSelectedMachine(id)
  }, [])

  const addSelectedMachine = useCallback((id: string) => {
    setSelectedMachines(prevState => [...prevState, id])
  }, [])

  const removeSelectedMachine = useCallback((id: string) => {
    setSelectedMachines(prevState => prevState.filter(machineId => machineId !== id))
  }, [])

  const setNewStopReasons = useCallback(
    (reasons: string[]) => {
      if (reasons.length === stopReasons.length && reasons.every(reason => stopReasons.includes(reason))) return

      setStopReasons(reasons)
      setSelectedStopReasons(reasons)
    },
    [stopReasons]
  )

  const selectStopReason = useCallback((reason: string) => {
    setSelectedStopReasons(prevState => [...prevState, reason])
  }, [])

  const deselectStopReason = useCallback((reason: string) => {
    setSelectedStopReasons(prevState => prevState.filter(stopReason => stopReason !== reason))
  }, [])

  const updateInterval = useCallback((newInterval: Partial<GraphInterval>) => {
    setInterval(prevState => {
      return {
        start: newInterval.start ?? prevState.start,
        end: newInterval.end ?? prevState.end,
      }
    })
  }, [])

  return (
    <OEEContextReactProvider
      value={{
        assetTreeMode,
        updateAssetTreeMode,
        assets,
        assetColors,
        assetsError: fetchAssetsError,
        assetsLoading: fetchAssetsLoading,
        selectedMachine,
        updateSelectedMachine,
        interval,
        updateInterval,
        selectedMachines,
        addSelectedMachine,
        removeSelectedMachine,
        stopReasons,
        selectedStopReasons,
        setNewStopReasons,
        selectStopReason,
        deselectStopReason,
        units,
      }}
    >
      {children}
    </OEEContextReactProvider>
  )
}

export { useOEEContext }
export default OEEContextProvider
