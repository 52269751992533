import 'whatwg-fetch'

import Button from '@sensorfactdev/nucleus/Button'
import ButtonLabel from '@sensorfactdev/nucleus/ButtonLabel'
import React from 'react'
import styled, { keyframes } from 'styled-components'
// @ts-expect-error ts-migrate(2305) FIXME: Module '"prop-types"' has no exported member 'Prop... Remove this comment to see the full error message
import { PropTypes as p } from 'prop-types'

import logo from '../../assets/images/logo.svg'
import { themeColors } from '../../utils'

const boom = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`

const Wrapper = styled.div`
  background-color: ${themeColors.primary};
  color: ${themeColors.white};
  width: 100%;
  min-height: 100vh;
  animation: 0.5s ${boom} ease-in;
  padding: 1em;
`

const Inner = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  pre {
    text-align: left;
    font-family: monospace;
    background-color: ${themeColors.white};
    opacity: 0.7;
    color: ${themeColors.black};
    padding: 2em;
    border-radius: 4px;
    margin: 1em 0;
    overflow: scroll;
  }

  a {
    color: ${themeColors.dangerDark};
  }
`

const Emoji = styled.span`
  font-size: 200px;
  margin: 1em 0;
`

const Logo = styled.div`
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 7em;
`

const ReloalWrapper = styled.div`
  text-align: center;

  button {
    display: inline-block;
  }
`

type Props = {
  error: {
    toString: $TSFixMeFunction
  }
  componentStack: string | null
}

/** @deprecated do not use */
const MassiveFooBar = ({ error, componentStack }: Props) => (
  <Wrapper>
    <Inner>
      <Logo />
      <h1>Uh-oh</h1>
      <p>Something went very wrong</p>
      <Emoji>
        <span
          role="img"
          aria-label="uh-oh-cat"
        >
          🙀
        </span>
      </Emoji>
      <ReloalWrapper>
        <Button onClick={() => global.window.location.reload()}>
          <ButtonLabel>Reload application</ButtonLabel>
        </Button>
      </ReloalWrapper>
      <h4>This is the error we could catch</h4>
      <p>
        Please report this issue by creating a screenshot and sending it to
        <a href="mailto:support@sensorfact.nl">&nbsp;support@sensorfact.nl</a>
      </p>
      <pre>
        {error.toString()}
        {componentStack}
      </pre>
    </Inner>
  </Wrapper>
)

export default MassiveFooBar
