import * as z from "zod";

export const PhoneNumberSchema = z.strictObject({
  dialCode: z.string(),
  phoneNumber: z.string(),
})
export type PhoneNumber = z.infer<typeof PhoneNumberSchema>

export const EmailRecipientSchema = z.strictObject({
  name: z.string(),
  destination: z.string().email(),
  transport: z.literal('email'),
})
export type EmailRecipient = z.infer<typeof EmailRecipientSchema>

export const WebhookRecipientSchema = z.strictObject({
  name: z.string(),
  destination: z.string(),
  transport: z.literal('webhook'),
})
export type WebhookRecipient = z.infer<typeof WebhookRecipientSchema>

export const WhatsappRecipientSchema = z.strictObject({
  name: z.string(),
  destination: PhoneNumberSchema,
  transport: z.literal('whatsapp'),
})
export type WhatsappRecipient = z.infer<typeof WhatsappRecipientSchema>

export type SupportedTransport = Recipient['transport']
export type Recipient = EmailRecipient | WebhookRecipient | WhatsappRecipient
export type SupportedMessageType = 'json' | 'text'
