import styled from 'styled-components'
import React, { useContext } from 'react'

import { I18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { themeColors } from '../../../../Shared/utils'

const LocalWrapper = styled.div`
  display: flex;
`

const MainContainer = styled.div`
  padding: 1em;
  width: 100%;
  text-align: left;
  background-color: ${themeColors.white};
  border: 1px solid ${themeColors.primaryLight};
  border-radius: 4px;
`

const List = styled.ul`
  list-style: circle;
  padding-left: 2em;
`

const Disclaimer = () => {
  const { i18n } = useContext(I18nContext)

  return (
    <LocalWrapper>
      <MainContainer>
        <h2>{i18n.text('installation.disclaimer.title')}</h2>
        <p>{i18n.text('installation.disclaimer.intro')}</p>
        <br />
        <List>
          <li>{i18n.text('installation.disclaimer.li-refresh')}</li>
          <li>{i18n.text('installation.disclaimer.li-connection-strength')}</li>
          <li>{i18n.text('installation.disclaimer.li-info')}</li>
        </List>
        <br />
        <p>{i18n.text('installation.disclaimer.feedback')}</p>
      </MainContainer>
    </LocalWrapper>
  )
}

export default Disclaimer
