import { DateTime } from 'luxon'
import { groupBy } from 'ramda'

import { toLocalDateTime } from '../../utils'

type MinCsvData = {
  name: string
  value: $TSFixMe
}

export const formatCsvData = <D extends MinCsvData>(
  analysisType: $TSFixMe,
  startDate: DateTime,
  endDate: DateTime,
  data: D[],
  valueSeparator: string,
  decimalSeparator: string
) => {
  const headers = getHeaders(analysisType).join(valueSeparator)
  let dataRows

  if (analysisType === 'energy-balance') {
    // for energy balance data we return 1 entry with totals per asset
    const assetMeassurements = groupByAsset(data)
    const assetTotals = Object.keys(assetMeassurements)
      .map(key => aggregateAssetMeasurements(assetMeassurements[key], startDate.toJSDate(), endDate.toJSDate()))
      .map(asset => ({
        ...asset,
        value: asset.value.toString().replace('.', decimalSeparator),
      }))

    dataRows = assetTotals.map(x => Object.values(x).join(valueSeparator))
  } else {
    dataRows = data
      .map(x => ({
        ...x,
        value: x.value.toString().replace('.', decimalSeparator),
      }))
      .map(x => Object.values(x).join(valueSeparator))
  }

  return [headers, ...dataRows]
}

const groupByAsset = groupBy(<M extends { name: string }>(measurement: M) => measurement.name)

const aggregateAssetMeasurements = <M extends { name: string }>(measurements: M[], startDate: Date, endDate: Date) => {
  const result = {
    from: toLocalDateTime(DateTime.fromJSDate(startDate)),
    to: toLocalDateTime(DateTime.fromJSDate(endDate)),
    name: measurements[0].name,
    value: measurements.reduce((total: $TSFixMe, current: $TSFixMe) => total + current.value, 0),
  }
  return result
}

const getHeaders = (analysisType: $TSFixMe) => {
  if (analysisType === 'energy-balance') {
    return ['from', 'to', 'assetName', 'value']
  }
  return ['date', 'assetName', 'value']
}
