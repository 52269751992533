import { Box } from '@mui/material'
import { useLocation } from 'react-router'

import useAnalytics from '../../../Shared/hooks/useAnalytics/useAnalytics'
import { AssetTreeChildNode } from '../../../Shared/components/MUIComponents/update/AssetTree/StyledAssetTreeChildNode'
import { AssetTreeParentNode } from '../../../Shared/components/MUIComponents/update/AssetTree/StyledAssetTreeParentNode'
import { sendCASensorLocationSelectEvent } from '../../utils/analyticsEvents'
import { useCompressedAirContext } from '../../context/CompressedAirContext'

type SensorLocationInfo = {
  readonly id: string
  readonly name: string
}

type Asset = {
  readonly id: string
  readonly name: string
  readonly sensorLocations: ReadonlyArray<SensorLocationInfo>
}

type AssetItemProps = {
  asset: Readonly<Asset>
  selectedIds: (string | null)[] | never[]
  toggleSelectedId: (id: string) => void
  onSelectionUpdate: (ids: string[]) => void
}

export const AssetItem = ({ asset, selectedIds, toggleSelectedId, onSelectionUpdate }: AssetItemProps) => {
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()
  const { queryParams, sensorLocationIds, setUpdatedOptionsFlag } = useCompressedAirContext()
  const { sid } = queryParams
  const selectedIdsAsString = selectedIds as string[]

  const isAssetInformationPage = pathname?.includes('asset-information')
  const isAllSelected =
    asset.sensorLocations.every(location => selectedIdsAsString.includes(location.id)) && !isAssetInformationPage
  const isSelected =
    asset.sensorLocations.some(location => selectedIdsAsString.includes(location.id)) &&
    !isAllSelected &&
    !isAssetInformationPage
  const isSensorLocationSelected = (id: string) => selectedIdsAsString.includes(id) && !isAssetInformationPage

  const handleClick = (id: string) => {
    if (isAssetInformationPage) {
      return
    }
    sendCASensorLocationSelectEvent(sendEvent)
    toggleSelectedId(id)
    setUpdatedOptionsFlag(true)
  }

  const selectLocations = () => {
    if (isAssetInformationPage) {
      return
    }
    const sensorocationsOfAsset = asset.sensorLocations.map(location => location.id)
    const selectedLocationsOfAsset = sensorocationsOfAsset.filter(id => selectedIdsAsString.includes(id))
    if (isAllSelected) {
      let newIds = (sid as string[]).filter(id => !selectedLocationsOfAsset.includes(id as string))
      newIds = newIds.length > 0 ? newIds : [sensorLocationIds[0]]
      onSelectionUpdate(newIds)
    } else {
      let newIds = (sid as string[]).concat(sensorocationsOfAsset)
      newIds = Array.from([...new Set(newIds)])
      onSelectionUpdate(newIds)
    }
    setUpdatedOptionsFlag(true)
  }

  return (
    <Box>
      <AssetTreeParentNode
        parent={asset}
        isSelected={isSelected}
        isAllSelected={isAllSelected}
        hasCheckbox={true}
        onCheckboxToggle={selectLocations}
      >
        {asset.sensorLocations.map(location => (
          <AssetTreeChildNode
            key={location.id}
            item={location}
            isSelected={isSensorLocationSelected(location.id)}
            handleClick={() => handleClick(location.id)}
            hasCheckbox={true}
          />
        ))}
      </AssetTreeParentNode>
    </Box>
  )
}
