import { Box, BoxProps, MenuItem, Select, styled } from '@mui/material'

export const StyledSelectInterval = styled(Select)(({ theme }) => ({
  width: '15rem',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  padding: '0.5rem',
  alignItems: 'center',
  alignSelf: 'center',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
}))

export const AssetGrid = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.typography.pxToRem(14),
  overflowY: 'auto',
  maxHeight: '80vh',
  justifyContent: 'start',
  alignItems: 'start',
  paddingBottom: '200px',
  '& > *': {
    flex: '0 1 260px',
    height: 'auto',
  },
  [theme.breakpoints.down('md')]: {
    '& > *': {
      flex: '0 1 260px',
    },
  },
}))
