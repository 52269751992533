import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import { CompressedAirAlertFormVars } from '../types/compressed-air_types'
import { DEFAULT_ALERT_RULE_WEEKDAY } from '../../App/utils/alertTimeManipulation'
import { DEFAULT_MIDDAY_TIME } from '../../Shared/constants/timezone'
import { GeneralAlertRuleCategoriesEnum, GeneralAlertRuleRecipientChannelsEnum } from '../../Shared/graphql/codegen'
import { ThresholdOperatorType } from '../../App/types/alertTypes'
import { useI18nContext } from '../../Shared/contexts/i18nContext/I18nContext'
import { validateThresholdValueBetweenCase, validateThresholdValueRequiredCase } from '../utils/alertFormValidation'

export const useCompressedAirAlertForm = () => {
  const { i18n } = useI18nContext()

  const formSchema: z.Schema<CompressedAirAlertFormVars> = z.object({
    assetId: z.string(),
    isAlwaysOn: z.boolean(),
    locationId: z.string().refine(
      value => {
        return !value ? false : true
      },
      { message: i18n.text('alerts.configuration.required-error') }
    ),
    message: z.string().refine(value => !!value, { message: i18n.text('alerts.configuration.message.error') }),
    category: z.enum([GeneralAlertRuleCategoriesEnum.CaFlow]),
    thresholdOperatorType: z.enum([
      ThresholdOperatorType.LESS,
      ThresholdOperatorType.GREATER,
      ThresholdOperatorType.BETWEEN,
    ]),
    lowerThresholdValue: z
      .number()
      .nonnegative()
      .optional()
      .refine(value => validateThresholdValueRequiredCase({ value, thresholdType: 'GREATER', watch }), {
        message: i18n.text('alerts.configuration.required-error'),
      })
      .refine(
        value => validateThresholdValueBetweenCase({ value, pathName: 'upperThresholdValue', isLess: true, watch }),
        { message: i18n.text('alerts.configuration.lower-threshold.error') }
      ),
    upperThresholdValue: z
      .number()
      .nonnegative()
      .optional()
      .refine(value => validateThresholdValueRequiredCase({ value, thresholdType: 'LESS', watch }), {
        message: i18n.text('alerts.configuration.required-error'),
      })
      .refine(
        value => validateThresholdValueBetweenCase({ value, pathName: 'lowerThresholdValue', isLess: false, watch }),
        { message: i18n.text('alerts.configuration.upper-threshold.error') }
      ),
    recipients: z.array(
      z.object({
        id: z.string().optional(),
        name: z.string().min(1),
        channel: z.enum([
          GeneralAlertRuleRecipientChannelsEnum.Email,
          GeneralAlertRuleRecipientChannelsEnum.Webhook,
          GeneralAlertRuleRecipientChannelsEnum.Whatsapp,
        ]),
        destination: z.string().min(1).email(),
      })
    ),
    alertIntervals: z.array(
      z.object({
        id: z.string().optional(),
        fromDay: z.string(),
        fromTime: z.string(),
        toDay: z.string(),
        toTime: z.string(),
      })
    ),
  })

  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm<CompressedAirAlertFormVars>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      assetId: '',
      message: '',
      category: GeneralAlertRuleCategoriesEnum.CaFlow,
      isAlwaysOn: false,
      recipients: [
        {
          channel: GeneralAlertRuleRecipientChannelsEnum.Email,
          name: '',
          destination: '',
        },
      ],
      alertIntervals: [
        {
          fromDay: DEFAULT_ALERT_RULE_WEEKDAY,
          fromTime: DEFAULT_MIDDAY_TIME,
          toDay: DEFAULT_ALERT_RULE_WEEKDAY,
          toTime: DEFAULT_MIDDAY_TIME,
        },
      ],
      alertAfterMinutes: 2,
      locationId: '',
      lowerThresholdValue: 0,
      upperThresholdValue: 0,
      thresholdOperatorType: ThresholdOperatorType.GREATER,
    },
  })

  return {
    control,
    errors,
    watch,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
  }
}
