import React, { FC } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { Redirect, Route, Switch } from 'react-router'

import MuiSecondaryNav from '../../../Shared/components/MUIComponents/update/MuiSecondaryNav/MuiSecondaryNav'
import { ConfigurationPage } from './ConfigurationPage'
import { MessagesPage } from './MessagesPage'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'

export const AlertsPage: FC = () => {
  const { i18n } = useI18nContext()
  
  const secondaryLinks = [
    {
      name: 'Messages',
      i18n: 'notifications.subroutes.messages',
      path: 'messages',
    },
    {
      name: 'Configuration',
      i18n: 'router.multi.settings.configuration',
      path: 'configuration',
    },
  ]

  return (
    <AlertsContainer>
      <AlertsTitle>{i18n.text('scan.action-plan.alerts.title')}</AlertsTitle>
      <MuiSecondaryNav links={secondaryLinks} />
      <Switch>
        <Route
          path="/alerts2/messages"
          exact
        >
          <MessagesPage />
        </Route>
        <Route
          path="/alerts2/configuration"
          exact
        >
          <ConfigurationPage />
        </Route>
        <Redirect to="/alerts2/messages" />
      </Switch>
    </AlertsContainer>
  )
}

const AlertsContainer = styled(Box)(({ theme }) => ({
  padding: '1.5rem 7rem',
  height: '100%',
  overflowY: 'auto',
  [theme.breakpoints.down('lg')]: {
    padding: '1.5rem',
  },
}))

const AlertsTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.SFIGreyLight[800],
  fontSize: '1.875rem',
  fontWeight: 600,
  lineHeight: '2.375rem',
  marginBottom: '0.625rem',
}))
