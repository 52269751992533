import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import CollapsibleAssetTreeWrapper from '../../../Shared/components/MUIComponents/update/AssetTree/CollapsibleAssetTreeWrapper'
import { AssetItem } from './AssetItem'
import { useCompressedAirContext } from '../../context/CompressedAirContext'

export type CompressedAirTreeProps = {
  sid: (string | null)[] | never[]
  onSelectionUpdate: (ids: string[]) => void
}

export const CompressedAirTree: FC<CompressedAirTreeProps> = ({ sid, onSelectionUpdate }: CompressedAirTreeProps) => {
  const { allAssets, sensorLocationIds } = useCompressedAirContext()
  const location = useLocation()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const selectedIds = sid && sid.length > 0 ? sid : [sensorLocationIds[0]]

  const toggleSelectedId = (id: string) => {
    const ids = [...selectedIds] as string[]
    const index = ids.indexOf(id)

    if (index > -1 && ids.length > 1) {
      ids.splice(index, 1)
    } else if (index === -1) {
      ids.push(id)
    }
    onSelectionUpdate(ids)
  }

  useEffect(() => {
    const isAssetInformationPage = location.pathname.includes('asset-information')
    if (isAssetInformationPage) {
      setIsCollapsed(false)
    } else {
      setIsCollapsed(true)
    }
  }, [location])

  const handleIsAssetTreeOpen = (val: boolean) => setIsCollapsed(val)

  return (
    <CollapsibleAssetTreeWrapper
      handleIsOpen={handleIsAssetTreeOpen}
      isOpen={isCollapsed}
    >
      {allAssets
        .filter(asset => asset.sensorLocations.length > 0)
        .map(asset => (
          <AssetItem
            key={asset.id}
            asset={asset}
            selectedIds={selectedIds}
            toggleSelectedId={toggleSelectedId}
            onSelectionUpdate={onSelectionUpdate}
          />
        ))}
    </CollapsibleAssetTreeWrapper>
  )
}
