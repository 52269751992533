import { Stack, Typography, Select, MenuItem, Theme } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { FormInputs } from '../EditAlertDialog';
import { useI18nContext } from '../../../../../../Shared/contexts/i18nContext/I18nContext';
import { SelectEventCooldownIntervalType } from '../../../utils';

type SelectFrequencyProps = {
  theme: Theme
  defaultValue: SelectEventCooldownIntervalType;
  control: Control<FormInputs, any>;
};

export default function SelectAlertFrequency({ theme, defaultValue, control }: SelectFrequencyProps) {
  const { i18n } = useI18nContext();
  return (
    <Stack direction="row" sx={{ margin: theme.spacing(2) }}>
      <Typography sx={{ margin: theme.spacing(1.5) }}>{i18n.text('pdm.configure.alert.dialog.frequency.text')}</Typography>
      <Controller
        name="eventCooldownInterval"
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => {
          return (
            <Select
            {...field}
            defaultValue={defaultValue}
            labelId="select alert frequency"
            size='small'
            variant='outlined'
            sx={{
              width: '7rem',
              marginLeft: theme.spacing(2),
            }}
            >
              <MenuItem value="hour">{i18n.text('pdm.configure.alert.dialog.frequency.hour')}</MenuItem>
              <MenuItem value="day">{i18n.text('pdm.configure.alert.dialog.frequency.day')}</MenuItem>
              <MenuItem value="week">{i18n.text('pdm.configure.alert.dialog.frequency.week')}</MenuItem>
              <MenuItem value="no-limit">{i18n.text('pdm.configure.alert.dialog.frequency.no-limit')}</MenuItem>
            </Select>
          )
        }}
      />
    </Stack>
  );
}
