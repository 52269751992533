import React, { ChangeEvent, FC, useState } from 'react'
import { Auth as Cognito } from 'aws-amplify'
import { Controller, useForm } from 'react-hook-form'
import { IconButton, InputAdornment, useTheme } from '@mui/material'
import { useHistory } from 'react-router'
import { zodResolver } from '@hookform/resolvers/zod'

import MUILoader from '../../../../Shared/components/MUIComponents/Loader'
import { EyeIcon } from '../../../../Shared/components/icons'
import { EyeOffIcon } from '../../../../Shared/components/icons/EyeOffIcon'
import { StyledFlexBox, StyledFormWrapper } from './NewPasswordValidationView.styled'
import { IntroTextWrapper, StyledLabel } from '../SetPasswordPage.styled'
import { StyledPrimaryButton } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { StyledTextField } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledTextField'
import { ValidationCasesBox } from './ValidationCasesBox/ValidationCasesBox'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'
import { useToastContext } from '../../../../Shared/contexts/ToastContext'
import { SetPasswordFormData, SetPasswordFormSchema } from '../../../utils/setPasswordFormSchema'

type NewPasswordValidationViewProps = {
  email: string
  onFlowStateChange: (type: string) => void
}

export const NewPasswordValidationView: FC<NewPasswordValidationViewProps> = ({ email, onFlowStateChange }) => {
  const { i18n } = useI18nContext()
  const { showToast } = useToastContext()
  const theme = useTheme()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [showValidationCases, setShowValidationCases] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<SetPasswordFormData>({
    mode: 'onChange',
    resolver: zodResolver(SetPasswordFormSchema),
    defaultValues: {
      code: '',
      password: '',
      confirmPassword: '',
    },
  })

  const code = watch('code')
  const password = watch('password')
  const confirmPassword = watch('confirmPassword')

  const isFormComplete = !!code && !!password && !!confirmPassword && isValid

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()

  const renderEyeIcon = (show: boolean) =>
    show ? (
      <EyeOffIcon sx={{ color: theme.palette.SFIGreyLight[700] }} />
    ) : (
      <EyeIcon sx={{ color: theme.palette.SFIGreyLight[700] }} />
    )

  const handlePasswordChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  ) => {
    if (!showValidationCases) {
      setShowValidationCases(true)
    }
    onChange(e)
  }

  const handleFormSubmit = async (data: SetPasswordFormData) => {
    if (!email) {
      return history.replace('/')
    }

    setIsLoading(true)
    const code = data.code.trim()

    try {
      await Cognito.forgotPasswordSubmit(email, code, data.password)
      onFlowStateChange('completed')
    } catch (e) {
      const error = e as Error
      if (error.name === 'ExpiredCodeException') {
        showToast(i18n.text('forms.submit.security-code-expired-error'), 'error', 'Error')
      } else {
        showToast(i18n.text('forms.submit.security-code-invalid-error'), 'error', 'Error')
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <IntroTextWrapper>{i18n.text('setpassword.enter-code-header')}</IntroTextWrapper>
      {isLoading && <MUILoader margin="0" />}
      {!isLoading && (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <StyledFormWrapper>
            <StyledFlexBox>
              <StyledLabel>{i18n.text('setpassword2.code')}*</StyledLabel>
              <Controller
                name={'code'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StyledTextField
                    value={value}
                    placeholder={i18n.text('setpassword2.code')}
                    size="small"
                    variant="outlined"
                    error={!!errors.code}
                    helperText={i18n.text(errors.code?.message)}
                    onChange={onChange}
                    autoComplete="off"
                    sx={{
                      maxWidth: '100%',
                    }}
                  />
                )}
              ></Controller>
            </StyledFlexBox>
            <StyledFlexBox>
              <StyledLabel>{i18n.text('forms.profile.new-password')}*</StyledLabel>
              <Controller
                name={'password'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StyledTextField
                    value={value}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={i18n.text('forms.profile.new-password')}
                    error={!!errors.password}
                    helperText={i18n.text(errors.password?.message)}
                    onChange={e => handlePasswordChange(e, onChange)}
                    size="small"
                    variant="outlined"
                    InputProps={{
                      autoComplete: 'new-password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {renderEyeIcon(showPassword)}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      maxWidth: '100%',
                    }}
                  />
                )}
              ></Controller>
            </StyledFlexBox>
            {showValidationCases && <ValidationCasesBox watch={watch} />}
            <StyledFlexBox>
              <StyledLabel>{i18n.text('forms.profile.confirm-password')}*</StyledLabel>
              <Controller
                name={'confirmPassword'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StyledTextField
                    value={value}
                    placeholder={i18n.text('forms.profile.confirm-password')}
                    type={showConfirmPassword ? 'text' : 'password'}
                    error={!!errors.confirmPassword}
                    helperText={i18n.text(errors.confirmPassword?.message)}
                    autoComplete="off"
                    size="small"
                    variant="outlined"
                    InputProps={{
                      autoComplete: 'new-password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {renderEyeIcon(showConfirmPassword)}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={onChange}
                    sx={{
                      maxWidth: '100%',
                    }}
                  />
                )}
              ></Controller>
            </StyledFlexBox>
            <StyledPrimaryButton
              type="submit"
              disabled={!isFormComplete}
              sx={{
                marginTop: '1.5rem',
              }}
            >
              {i18n.text('forms.profile.save-new-password')}
            </StyledPrimaryButton>
          </StyledFormWrapper>
        </form>
      )}
    </>
  )
}
