import { SvgIcon, SxProps, Theme } from '@mui/material'

interface LoadingIcon {
  size: 'small' | 'medium' | 'large'
}

interface LoadingIcon {
  size: 'small' | 'medium' | 'large'
  sx?: SxProps<Theme> | undefined
}

export const LoadingIcon = ({ size, sx }: LoadingIcon) => (
  <SvgIcon
    viewBox="0 0 20 20"
    fontSize={size}
    sx={sx}
  >
    <g id="loading-01">
      <path
        id="Icon"
        d="M10 1.875V3.95833M10 15V18.3333M4.79167 10H1.875M17.7083 10H16.4583M15.3809 15.3809L14.7917 14.7917M15.5535 4.51316L14.375 5.69167M4.10131 15.8987L6.45833 13.5417M4.2739 4.34057L6.04167 6.10833"
        stroke="#1D7AFD"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
)
