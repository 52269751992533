import styled from 'styled-components'
import React, { useContext } from 'react'

import { I18nContext } from '../../contexts/i18nContext/I18nContext'
import { themeColors } from '../../utils'

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: ${themeColors.white};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Yes this is a very, very high z-index */
  /* Highest supported z-index for Safari */
  /* https://stackoverflow.com/a/25461690/2340238 */
  z-index: 16777271;
`

const Inner = styled.div`
  text-align: center;
`

const Message = styled.h1`
  font-weight: 400;
`

const Hint = styled.p``

/** @deprecated do not use */
export default function OfflineMessage() {
  const { i18n } = useContext(I18nContext)

  return (
    <Wrapper>
      <Inner>
        <Message>{i18n.text('update.status.browser-offline.header')}</Message>
        <Hint>{i18n.text('update.status.browser-offline.hint')}</Hint>
      </Inner>
    </Wrapper>
  )
}
