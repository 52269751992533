import { AssetCardData, VibrationStatus } from '../containers/OverviewPageContainer/types'

export const compareByStatus = (prev: AssetCardData, cur: AssetCardData, asc: boolean) => {
  const getStatusOrder = (asset: AssetCardData) => {
    switch (asset.vibrationStatus) {
      case VibrationStatus.Alarm:
        return 1
      case VibrationStatus.Warning:
        return 2
      case VibrationStatus.Offline:
        return 3
      case VibrationStatus.NoData:
      case VibrationStatus.NotConfigured:
        return 4
      case VibrationStatus.Good:
        return 5
      default:
        return 4
    }
  }

  const prevStatus = getStatusOrder(prev)
  const curStatus = getStatusOrder(cur)

  return asc ? curStatus - prevStatus : prevStatus - curStatus
}
