import { Box, styled } from '@mui/material'

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  minHeight: '100%',
}))

// will remove on the next MR
export const StyledContent = styled(Box)(() => ({}));
