import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

import { PlusIcon } from '../../../../Shared/components/icons'
import { StyledTextButton } from '../../../../Shared/components/MUIComponents/update/styledComponents/StyledButtons'
import { VisualisationDataProps, VisualisationListProps } from './types'
import { VisualisationRow } from './VisualisationRow'
import { getNumBars } from './utils'
import { useGetAllowedAnalysisVisualisationsQuery } from '../../../../Shared/graphql/codegen'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

export const VisualisationList: FC<VisualisationListProps> = ({
  visualisations,
  onChange,
  onDelete,
  onAdd,
  analysisType,
  assetGroupingLevel,
  timeGroupingLevel,
  selectedAssets,
  daysOfWeekInclude,
  maxNumBars,
  referenceInclude,
}) => {
  const { i18n } = useI18nContext()
  const theme = useTheme()
  const { data: allowedVisualisationsData } = useGetAllowedAnalysisVisualisationsQuery()

  const _onChange = (index: number, visualisation: VisualisationDataProps) => {
    const updatedVisualisations = [...visualisations]
    updatedVisualisations.splice(index, 1, visualisation)
    onChange(updatedVisualisations)
  }
  const numBars =
    getNumBars(selectedAssets, assetGroupingLevel, timeGroupingLevel, daysOfWeekInclude, referenceInclude) || 0
  const barChartAllowedVisualisation = allowedVisualisationsData?.trackingReportAllowedAnalysisVisualisations
    .filter(type => type.analysisType === analysisType)
    .map(type => type.visualisationType)
    .includes('barChart') //|| []

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
        {visualisations &&
          visualisations.map((visualisation, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <VisualisationRow
                currentPosition={visualisation.position}
                currentId={visualisation.id}
                currentVisualisationType={visualisation.visualisationType}
                currentUnit={visualisation.unit}
                analysisType={analysisType || 'standby'}
                numAssets={1}
                handleVisualisationChange={_onChange.bind(null, index)}
                assetGroupingLevel={assetGroupingLevel}
                timeGroupingLevel={timeGroupingLevel}
                selectedAssets={selectedAssets}
                daysOfWeekInclude={daysOfWeekInclude}
                maxNumBars={maxNumBars}
                referenceInclude={referenceInclude}
                index={index}
                onDelete={onDelete}
              ></VisualisationRow>
            </Box>
          ))}
      </Box>
      {numBars > maxNumBars && barChartAllowedVisualisation && (
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: 400,
            color: theme.palette.SFIGreyLight[600],
            marginRight: '1rem',
            maxWidth: '45%',
          }}
        >
          {i18n.text('reports.tracking.configuration.visualisation.barChart.helperText')}
        </Typography>
      )}

      <StyledTextButton
        sx={{ height: 'fit-content', width: 'fit-content', mt: '0.5rem' }}
        onClick={onAdd}
        startIcon={
          <PlusIcon
            sx={{
              width: '1.25rem',
              height: '1.25rem',
            }}
          />
        }
      >
        {i18n.text('reports.tracking.configuration.module.visualisationsAdd')}
      </StyledTextButton>
    </Box>
  )
}
