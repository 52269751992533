import React, { FC, ReactNode, useCallback } from 'react'
import { Box, Chip, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'

import MuiDateTimeRangePicker from '../../../Shared/components/MUIComponents/update/MuiDateTimeRangePicker'
import { toLocalDateTime } from '../../../Shared/utils'
import { useI18nContext } from '../../../Shared/contexts/i18nContext/I18nContext'
import { useOEEContext } from '../../context/OEEContext'

type SectionHeaderProps = {
  title: string
  chipContent?: string
  children?: ReactNode
  shouldShowDatePicker?: boolean
  exporter?: ReactNode
}

const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  chipContent,
  shouldShowDatePicker = true,
  exporter,
  children,
}) => {
  const { interval, updateInterval } = useOEEContext()
  const { i18n } = useI18nContext()
  const theme = useTheme()

  const formattedInterval = {
    start: DateTime.fromISO(interval.start),
    end: DateTime.fromISO(interval.end),
  }

  const handleProductionSpeedIntervalChange = useCallback(
    selection => {
      updateInterval({ start: toLocalDateTime(selection.startDate), end: toLocalDateTime(selection.endDate) })
    },
    [updateInterval]
  )

  const getDatePickerShortcuts = () => [
    {
      label: i18n.text('oee.datetimepicker.predefined-ranges.last-hour'),
      from: DateTime.local().minus({ hours: 1 }),
      to: DateTime.local(),
    },
    {
      label: i18n.text('oee.datetimepicker.predefined-ranges.last-3-hours'),
      from: DateTime.local().minus({ hours: 3 }),
      to: DateTime.local(),
    },
    {
      label: i18n.text('oee.datetimepicker.predefined-ranges.last-6-hours'),
      from: DateTime.local().minus({ hours: 6 }),
      to: DateTime.local(),
    },
    {
      label: i18n.text('oee.datetimepicker.predefined-ranges.this-week'),
      from: DateTime.local().startOf('week'),
      to: DateTime.local().endOf('week'),
    },
    {
      label: i18n.text('oee.datetimepicker.predefined-ranges.this-month'),
      from: DateTime.local().startOf('month'),
      to: DateTime.local().endOf('month'),
    },
    {
      label: i18n.text('oee.datetimepicker.predefined-ranges.last-month'),
      from: DateTime.local().startOf('month').minus({ months: 1 }),
      to: DateTime.local().endOf('month').minus({ months: 1 }),
    },
  ]

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        gap: '2rem',
      }}
    >
      <Typography
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          padding: '0.875rem 0 0',
          gap: '0.5rem',
          alignItems: 'center',
          fontWeight: 500,
          whiteSpace: 'nowrap',
        }}
        variant="h5"
      >
        {title}
        {chipContent && (
          <Chip
            sx={{
              backgroundColor: theme.palette.SFIBase.white,
              border: `1px solid ${theme.palette.SFIGreyLight[300]}`,
              borderRadius: '6px',
              width: 'fit-content',
              height: 'auto',
              boxShadow: `0px 1px 2px 0px ${theme.palette.SFIGreyLight[300]}`,
              padding: '0.13rem 0.5rem',
              '& .MuiChip-label': {
                padding: 0,
              },
            }}
            label={chipContent}
          />
        )}
      </Typography>
      {(children || shouldShowDatePicker || !!exporter) && (
        <Box
          sx={{
            display: 'flex',
            gap: '0.5rem',
            paddingTop: '0.875rem',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          {children}
          {shouldShowDatePicker && (
            <MuiDateTimeRangePicker
              startDate={formattedInterval.start}
              endDate={formattedInterval.end}
              onDatesChange={handleProductionSpeedIntervalChange}
              isTimePicker={true}
              hasPrevNextPeriodButtons={true}
              showCompareDatesButton={false}
              shortcutsItems={getDatePickerShortcuts()}
            />
          )}
          {!!exporter && exporter}
        </Box>
      )}
    </Box>
  )
}

export default SectionHeader
