import React from 'react'
import styled from 'styled-components'
import { Box, Typography, styled as muiStyled, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { Refresh } from '@mui/icons-material'

import CsvExport from '../CsvExport'
import DatePicker from '../DatePicker'
import PngExport from '../PngExport'
import StyledButton from './StyledButton'
import { toLocalDateTime } from '../../utils'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  .rdrDateDisplayWrapper {
    display: none;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    margin-right: 0.5em;
  }
`

const SubNavWrapper = muiStyled(Box)(({ theme }) => ({
  marginLeft: '15.8em',
  backgroundColor: theme.palette.SFIGreyLight[50],
  // paddingLeft: theme.spacing(2),
  // paddingRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',

  // [theme.breakpoints.up('xl')]: {
  //   margin: '0 0 0 0',
  // },
}))

const pngExportIgnoredIds = ['TimeTravel']
type Props = {
  analysis: $TSFixMe
  autoRefresh?: {
    onToggle: $TSFixMeFunction
    enabled?: boolean
    active?: boolean
  }
  className?: string
  endDate: DateTime
  i18n: $I18FixMe
  isDashboard?: boolean
  linkToDashboard?: string
  onDatesChange: $TSFixMeFunction
  onToggleDashboard: $TSFixMeFunction
  startDate: DateTime
}
/** @deprecated do not use */
const AnalysisTools: React.FC<Props> = ({
  i18n,
  onDatesChange,
  startDate,
  endDate,
  autoRefresh,
  isDashboard,
  className,
  onToggleDashboard,
  analysis,
  ...props
}) => {
  const theme = useTheme()
  return (
    <SubNavWrapper>
      <Wrapper
        {...props}
        className={`${className} AnalysisTools`}
      >
        <ButtonWrapper>
          {autoRefresh && (
            <StyledButton
              id="auto-refresh-data"
              appearance="secondary"
              onClick={autoRefresh.onToggle}
              tooltip={
                autoRefresh.enabled
                  ? i18n.text('graph.analysis.auto-refresh')
                  : i18n.text('graph.analysis.auto-refresh-disabled')
              }
              disabled={!autoRefresh.enabled}
              intent={autoRefresh.active ? 'active' : 'default'}
            >
              <Refresh
                fontSize="small"
                sx={{ marginRight: theme.spacing(1) }}
              />
              <Typography sx={{ textTransform: 'none', fontSize: theme.spacing(1.5) }}>
                {i18n.text('graph.analysis.auto-refresh-title')}
              </Typography>
            </StyledButton>
          )}
          {!isDashboard && (
            <>
              <CsvExport
                analysis={analysis}
                i18n={i18n}
              />
              <PngExport
                element={document.getElementById('analysis-graph')}
                fileName={`${toLocalDateTime(startDate)}-${toLocalDateTime(endDate)}-graph`}
                options={{
                  ignoreElements: (element: $TSFixMe) => pngExportIgnoredIds.includes(element.id),
                }}
                i18n={i18n}
              />
            </>
          )}
          {/* <div>
          <Button
            id="DashboardButton"
            appearance="secondary"
            onClick={onToggleDashboard}
            intent={isDashboard ? 'active' : 'default'}
            tooltip={i18n.text('tooltip.dashboard-button')}
          >
            <Icon
              icon="fullscreen"
              size="medium"
              appearance="secondary"
            />
          </Button>
        </div> */}
        </ButtonWrapper>
        <p style={{ marginBottom: '7px', fontSize: '12px' }}>{i18n.text('Date')}</p>
        <DatePicker
          onDatesChange={onDatesChange}
          startDate={startDate}
          endDate={endDate}
          i18n={i18n}
        />
      </Wrapper>
    </SubNavWrapper>
  )
}
AnalysisTools.defaultProps = {
  autoRefresh: undefined,
  linkToDashboard: undefined,
  isDashboard: false,
  className: '',
}

export default AnalysisTools
