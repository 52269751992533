import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { useTheme } from '@mui/material'

import Error from '../../Shared/components/MUIComponents/Error'
import Loader from '../../Shared/components/MUIComponents/Loader'
import { ChartToolsContextProvider } from '../context/ChartToolsContext'
import { TemperatureContextProvider, useTemperatureContext } from '../context/TemperatureContext'
import { TemperatureLayout } from '../components/Layout'

const TemperatureContent = () => {
  const theme = useTheme()
  const { dataLoading, loadAssetTreeError } = useTemperatureContext()

  if (dataLoading) {
    return <Loader />
  }

  if (loadAssetTreeError) {
    return <Error error={loadAssetTreeError} />
  }

  return <TemperatureLayout />
}

const TemperatureContainer = () => {
  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <ChartToolsContextProvider>
        <TemperatureContextProvider>
          <TemperatureContent />
        </TemperatureContextProvider>
      </ChartToolsContextProvider>
    </QueryParamProvider>
  )
}

export default TemperatureContainer
