import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

import { StyledTooltip } from '../MUIComponents/Tooltip'

type PercentageBarProps = {
  value: number
  color?: string
  backgroundColor?: string
  width?: number
  height?: number
  rx?: number
  includeTextValue?: boolean
}

/** A component that displays a percentage bar. It is used to display the progress of a task or a process.
 * @param {number} value - The percentage value to display
 * @param {string} [color=theme.palette.SFIBrand[900]] - The color of the progress bar
 * @param {string} [backgroundColor=theme.palette.SFIGreyLight[300]] - The color of the background bar
 * @param {number} [width=118] - The width of the bar
 * @param {number} [height=8] - The height of the bar
 * @param {number} [rx=4] - The border radius of bars
 * @param {boolean} [includeTextValue=true] - If true, includes the percentage text value next to the bar
 * */
const PercentageBar: FC<PercentageBarProps> = ({
  value,
  color,
  backgroundColor,
  width = 118,
  height = 8,
  rx = 4,
  includeTextValue = true,
}) => {
  const theme = useTheme()

  const bgColor = backgroundColor ?? theme.palette.SFIGreyLight[300]
  const progressColor = color ?? theme.palette.SFIBrand[900]

  const percentageValue = (width / 100) * value

  return (
    <StyledTooltip title={`${value}%`}>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', alignItems: 'center' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
        >
          <rect
            width={width}
            height={height}
            rx={rx}
            fill={bgColor}
          />
          <rect
            width={percentageValue}
            height={height}
            rx={rx}
            fill={progressColor}
          />
        </svg>
        {includeTextValue && (
          <Typography
            sx={{ minWidth: '4ch' }}
            variant="body2"
          >{`${value}%`}</Typography>
        )}
      </Box>
    </StyledTooltip>
  )
}

export default PercentageBar
