import React, { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { PopoverOrigin } from '@mui/material'

import {
  BaseAnchorButtonProps,
  ComparingDatesStatuses,
  DateFormat,
  DateRangePickerShortCut,
  IDateTimeRangePickerProps,
  StartEndDatesValues,
} from '../types'
import { DateRange } from '../../../../../types/analysis_types'
import { createCtx } from '../../../../../utils'
import { defaultShortcutsItems } from '../../../../../constants/defaultDateRangePickerShortcuts'
import { useI18nContext } from '../../../../../contexts/i18nContext/I18nContext'

const defaultDateFormat = {
  day: '2-digit',
  month: 'short',
  year: '2-digit',
}

interface IDateTimeRangePickerContextProps<T extends BaseAnchorButtonProps> extends IDateTimeRangePickerProps<T> {
  children: React.ReactNode
  anchorOrigin?: PopoverOrigin
}

interface IDateTimeRangePickerContextValues {
  initialFromTo: StartEndDatesValues
  initialCompareFromTo?: StartEndDatesValues
  fromDate: DateTime | null
  toDate: DateTime | null
  fromToValues: StartEndDatesValues
  compareFromToValues: StartEndDatesValues
  currentDate: DateTime | null
  isTimePicker: boolean
  isCompareDatesOn: boolean
  isOpen?: boolean
  activeShortcutIndex: number | null
  settingFromDate: boolean
  dateFormat: DateFormat
  isAutomaticCompareDates: boolean
  hasPrevNextPeriodButtons: boolean
  showCompareDatesButton: boolean
  shortcutsItems: DateRangePickerShortCut[]
  anchorOrigin?: PopoverOrigin
  error: string
  compareStatusesRef: React.MutableRefObject<ComparingDatesStatuses>
  customDateRangeButtonLabel?: string
  onDatesChange: (selection: DateRange, zooming?: boolean) => void
  onCompareDatesChange?: (selection: StartEndDatesValues, zooming?: boolean) => void
  onCompareDatesToggle?: (isSwitchedOn?: boolean) => void
  handleStartEndDates: (dates: StartEndDatesValues) => void
  handleCompareStartEndDates?: (dates: StartEndDatesValues) => void
  setActiveShortcutIndex: React.Dispatch<React.SetStateAction<number | null>>
  setCurrentDate: React.Dispatch<React.SetStateAction<DateTime | null>>
  setFromDateStatus: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<React.SetStateAction<string>>
  setInitialValues: React.Dispatch<React.SetStateAction<StartEndDatesValues>>
  setInitialCompareValues: React.Dispatch<React.SetStateAction<StartEndDatesValues | undefined>>
  onToggleOpen?: (isOpen: boolean) => void
}

const [useDateTimeRangePickerContext, DateTimeRangePickerContextReactProvider] =
  createCtx<IDateTimeRangePickerContextValues>()

export function DateTimeRangePickerContextProvider<T extends BaseAnchorButtonProps>({
  children,
  fromToValues,
  compareFromToValues,
  dateFormat,
  isTimePicker,
  isCompareDatesOn,
  isOpen,
  isAutomaticCompareDates = true,
  hasPrevNextPeriodButtons = true,
  showCompareDatesButton = true,
  customDateRangeButtonLabel,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  shortcutsItems,
  handleStartEndDates,
  handleCompareStartEndDates,
  onDatesChange,
  onCompareDatesChange,
  onCompareDatesToggle,
  onToggleOpen,
}: IDateTimeRangePickerContextProps<T>) {
  const today = DateTime.now()
  const aWeekAgo = today.minus({ days: 7 })
  const { i18n } = useI18nContext()
  const [error, setError] = useState('')
  const [initialValues, setInitialValues] = useState<StartEndDatesValues>(
    fromToValues.startDate && fromToValues.endDate
      ? { startDate: fromToValues.startDate, endDate: fromToValues.endDate }
      : { startDate: aWeekAgo, endDate: today }
  )
  const [initialCompareValues, setInitialCompareValues] = useState<StartEndDatesValues | undefined>(compareFromToValues)
  const [currentDate, setCurrentDate] = useState<DateTime | null>(
    fromToValues.endDate ? fromToValues.endDate : fromToValues.startDate ?? null
  )
  const [activeShortcutIndex, setActiveShortcutIndex] = useState<number | null>(null)
  const [settingFromDate, setFromDateStatus] = useState(true)

  const compareStatusesRef = useRef<ComparingDatesStatuses>({
    from: true,
    to: true,
    compareFrom: true,
    compareTo: true
  })

  useEffect(() => {
    setError('')
    setFromDateStatus(true)
    compareStatusesRef.current = {
      from: true,
      to: true,
      compareFrom: true,
      compareTo: true
    }
    if (handleCompareStartEndDates) {
      handleCompareStartEndDates({
        startDate: null,
        endDate: null,
      })
    }
    setInitialCompareValues({
      startDate: null,
      endDate: null,
    })
  }, [isCompareDatesOn])

  return (
    <DateTimeRangePickerContextReactProvider
      value={{
        initialFromTo: initialValues,
        initialCompareFromTo: initialCompareValues,
        fromToValues,
        isOpen,

        fromDate: fromToValues.startDate ?? null,
        toDate: fromToValues.endDate ?? null,
        compareFromToValues: {
          startDate: compareFromToValues?.startDate || null,
          endDate: compareFromToValues?.endDate || null,
        },
        currentDate,
        isTimePicker: !!isTimePicker,
        isCompareDatesOn: !!isCompareDatesOn,
        activeShortcutIndex,
        settingFromDate,
        isAutomaticCompareDates,
        dateFormat: dateFormat || defaultDateFormat,
        hasPrevNextPeriodButtons: !!hasPrevNextPeriodButtons,
        showCompareDatesButton: !!showCompareDatesButton,
        shortcutsItems: shortcutsItems || defaultShortcutsItems(i18n),
        error,
        anchorOrigin,
        compareStatusesRef,
        customDateRangeButtonLabel,
        setInitialValues,
        setInitialCompareValues,
        setError,
        onDatesChange,
        onCompareDatesChange,
        onCompareDatesToggle,
        setActiveShortcutIndex,
        setCurrentDate,
        setFromDateStatus,
        handleStartEndDates,
        handleCompareStartEndDates,
        onToggleOpen,
      }}
    >
      {children}
    </DateTimeRangePickerContextReactProvider>
  )
}

export { useDateTimeRangePickerContext }
