import { Box, Typography, useTheme } from '@mui/material'

import PreferredSpeedUnit from './PreferredSpeedUnit/PreferredSpeedUnit'
import SettingsPageTitle from '../SettingsPageTitle'
import { useI18nContext } from '../../../../Shared/contexts/i18nContext/I18nContext'

const Configuration = () => {
  const { i18n } = useI18nContext()
  const theme = useTheme()

  return (
    <Box sx={{ display: 'grid', padding: '0 2rem', alignItems: 'start', rowGap: '2rem' }}>
      <SettingsPageTitle
        title={i18n.text('Configuration')}
        description={i18n.text('settings.oee-settings.configuration.description')}
      />
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', alignItems: 'center', gap: '2rem' }}>
        <Box>
          <Typography
            sx={{
              color: theme.palette.SFIGreyLight[800],
              fontSize: '0.875rem',
              fontWeight: 600,
              lineHeight: '1.25rem',
            }}
          >
            {i18n.text('settings.customer.preferred-speed-unit')}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '1.25rem',
            }}
          >{`${i18n.text('settings.customer.preferred-speed-unit.description')}:`}</Typography>
        </Box>
        <PreferredSpeedUnit />
      </Box>
    </Box>
  )
}

export default Configuration
